import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export interface Library extends PublicResource {
  content_tree: string;
  relationships: {
    node: Related<'content-tree-node'>;
    workspace: Related<'workspace'>;
  };
}

export const library: RawModel = {
  type: 'library',
  plural: 'libraries',
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {
    workspaces: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'libraries',
      required: [false, 1]
    },
    nodes: {
      type: 'content-tree-node',
      binding: ['many', 'one'],
      reverse: 'library',
      required: true
    }
  },
  JSONApi: true
};
