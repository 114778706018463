import React, { useMemo, useCallback, useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useShareDownloads } from '../hooks/useShareDownloads';

import {
  downloadErrors,
  downloadWarnings
} from '@cube3/ui/src/library/Downloads/downloadErrors';
import DownloadFormatsList from '../prefabs/DownloadFormatsList';
import { useRenderPresets } from '../hooks/useRenderPresets';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import MaybeTooltip from '@cube3/ui/src/Tooltip/MaybeTooltip';
import Button from '@cube3/ui/src/Buttons/Button';
import { useCurrentShareLink } from '@cube3/state/src/redux/components/Hooks/useCurrentShareLink';
import { Typography } from '@cube3/ui/src/typography/Typography';

type Props = ModalReceiverProps;

const requiredPermissions = [
  'DOWNLOAD_TYPE_ORIGINAL',
  'DOWNLOAD_TYPE_PREVIEW'
] as Privileges[];

const DownloadItemsModal: React.FunctionComponent<Props> = props => {
  const { modalContext: selection } = props;
  const { previousModal } = useModalActions();
  const share = useCurrentShareLink();

  // separate folders from assets
  const { assets, folders, mimeTypes } = useMemo(() => {
    // selection is a single asset in detail overview page
    const files = Array.isArray(selection) ? selection : [selection];
    const assets = files?.filter(s => s.type === 'asset') || [];
    const folders = files?.filter(s => s.type === 'folder') || [];
    const mimeTypes = assets?.map(a => a.mime_type) || [];

    const deduped = mimeTypes.filter(
      (t, idx) => mimeTypes.findIndex(m => m === t) === idx
    );
    return { folders, assets, mimeTypes: deduped };
  }, [selection]);

  const {
    groupedPresets,
    availablePresets,
    allPresetsCount,
    loading: presetsLoading
  } = useRenderPresets(assets, !!folders?.length, mimeTypes);

  const {
    sharedDownloadFormats,
    loading: sharedDownloadFormatsLoading
  } = useShareDownloads(share?.id);

  const [canDownloadOriginal, canDownloadPreview] = useWorkspacePermissions(
    requiredPermissions
  );

  // Advanced variant: list more formats for download
  // Show all formats directly in share view
  const [showFormats, setShowFormats] = useState(!share);
  const onClickShowFormats = useCallback(() => {
    setShowFormats(false);
  }, []);

  const noOtherFormatsInShare =
    share && (!sharedDownloadFormats?.length || !allPresetsCount);

  const disableShowFormats =
    (!assets?.length && !folders?.length) ||
    allPresetsCount - groupedPresets['default']?.length <= 0;

  /** we want to check if user has permission to download preview/original files in workspaces */
  const noPermission = useCallback(
    preset => {
      if (!preset) return '';
      const key =
        preset.attributes.display_name === 'Original' && !canDownloadOriginal
          ? 'original'
          : preset.attributes.display_name !== 'Original' && !canDownloadPreview // all other presets fall under preview privilege
          ? 'preview'
          : '';

      return downloadErrors.no_permission[key];
    },
    [canDownloadOriginal, canDownloadPreview]
  );

  const multiply = share
    ? noOtherFormatsInShare
      ? 2
      : 5
    : showFormats
    ? 3
    : 8;

  const loading = presetsLoading || sharedDownloadFormatsLoading;

  return (
    <ModalMenuUI
      showCloseButton={true}
      title={'Download file'}
      multiplyContentSpace={multiply}
      loading={loading}
      onCloseEvent={previousModal}
    >
      {noOtherFormatsInShare ? (
        <Typography typographyStyle="body1">
          No download formats are available for this Asset type
        </Typography>
      ) : (
        <>
          {canDownloadOriginal || canDownloadPreview || share ? (
            <DownloadFormatsList
              allPresets={groupedPresets?.['default']}
              presets={availablePresets}
              assets={assets}
              folders={folders}
              loading={loading}
              noPermission={!share && noPermission}
            />
          ) : null}
          {/* If no formats are available, disable the button and show a tooltip explaining why: */}
          {showFormats ? (
            <div style={{ marginLeft: '-16px', paddingTop: '10px' }}>
              <MaybeTooltip
                disabled={!disableShowFormats}
                placement={'right-end'}
                title={
                  noOtherFormatsInShare
                    ? downloadErrors.disabled_in_share_all
                    : downloadWarnings.no_other_formats
                }
              >
                <Button
                  colorVariant={'ghost1'}
                  disabled={disableShowFormats}
                  text={'Show more formats...'}
                  onClick={onClickShowFormats}
                />
              </MaybeTooltip>
            </div>
          ) : null}

          {!showFormats ? (
            <>
              {Object.keys(groupedPresets).length
                ? Object.keys(groupedPresets).map((groupName, idx) =>
                    groupName === 'default' ? null : (
                      <DownloadFormatsList
                        key={idx}
                        groupName={groupName}
                        presets={availablePresets}
                        allPresets={groupedPresets[groupName]}
                        assets={assets}
                        folders={folders}
                        loading={loading}
                      />
                    )
                  )
                : null}
            </>
          ) : null}
        </>
      )}
    </ModalMenuUI>
  );
};

export default DownloadItemsModal;
