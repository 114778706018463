import { schema } from '@cube3/common/model/schema';
import {
  urlStructureFileRequest,
  urlStructureReview
} from '../../../../../main/src/components/app/routing/routingPaths';
import { actions as requestStatusActions } from '../../ducks/request-status';
import { actions as nodeActions } from '../../ducks/resource-nodes';
import {
  clearReviewChanges,
  setReviewChanges
} from '../../ducks/review-changes';
import { matchPath } from 'react-router-dom';
import { environment } from '../../../utils/environment';
import { getResourceById } from '../../ducks/request-status/utils/nodeHelpers';
import { Share } from '@cube3/common/model/schema/resources/share';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';

const TIMEOUT = environment === 'production' ? 30 * 60 * 1000 : 10 * 1000;

const reviewPath = matchPath(location.pathname, urlStructureReview);
const fileRequestPath = matchPath(location.pathname, urlStructureFileRequest);

const getMode = () => {
  return reviewPath ? 'review' : fileRequestPath ? 'fileRequest' : undefined;
};

const mode = getMode();

const variants = {
  review: {
    token: reviewPath?.params['token'],
    beaconUrl: '/api/v1/legacy?method=DoLeaveWeblink&read_body=true',
    relevantResources: ['comment', 'approval'],
    buildMessage: (changedResources, _, token) => {
      if (relevantResources.length < 1) {
        return;
      }
      return {
        headers: {},
        msg: relevantResources.reduce(
          (acc, val) => {
            acc[schema.getPlural(val)] = changedResources
              .filter((res) => res.resourceType === val)
              ?.map((res) => res.id);

            return acc;
          },
          {
            token: token
          }
        )
      };
    }
  },

  fileRequest: {
    token: fileRequestPath?.params['linkHash'],
    beaconUrl: (share) => `/api/v1/shares/${share.id}/client-actions/`,
    relevantResources: ['file-upload'],
    buildMessage: (
      changedResources,
      share: Share,
      token,
      uploads: Upload[]
    ) => {
      if (uploads.length < 1) {
        return;
      }
      return {
        headers: {
          type: 'application/vnd.api+json',
          'X-Cube3-ShareID': token
        },
        data: {
          type: 'client-actions',
          attributes: {
            token,
            email: new URLSearchParams(location.href.split('?')[1]).get('email')
          },
          relationships: {
            share: { data: { id: share.id, type: 'share' } },

            created_resources: {
              data: Object.keys(uploads)
                .map((k) => uploads[k])
                .filter((u) => u.progress === 100)
                .map((u: Upload) => ({
                  id: u.asset_id,
                  type: 'asset'
                }))
            }
          }
        }
      };
    }
  }
};

const { token, beaconUrl, relevantResources, buildMessage } =
  variants[mode] || {};

/**
 * maps new resources with temp IDs to their real IDs
 */
const mappedData = (
  state: {
    temporaryId?: string;
    resourceId?: string;
    id?: string;
    resourceType: string;
  }[],
  tempIds: { [key: string]: string }
) => {
  if (!state?.length) return undefined;
  const mapped = state.map((res) => {
    const id =
      res.temporaryId && tempIds
        ? tempIds[res.temporaryId]
        : res.resourceId || res.id;
    return { ...res, id };
  });

  return mapped;
};

export const reviewChangesMiddleware = ({ dispatch, getState }) => {
  if (!mode) {
    return (next) => (action) => next(action);
  }

  if (token) {
    const sendData = () => {
      if (!getMode()) {
        console.warn(
          'not sending beacon data: not on review or file-request path '
        );
        return;
      }
      const reviewChanges = getState().reviewChanges;
      if (!reviewChanges.length) return;
      const tempIds = getState().tempIdLookup;

      const share = getResourceById(
        getState().resourceNodes,
        'share',
        getState().session.sharelink
      );

      const uploads = getState().resourceNodes['file-upload'];

      const changedResources = mappedData(reviewChanges, tempIds);

      const msgObject = buildMessage(changedResources, share, token, uploads);

      if (!msgObject) {
        return;
      }

      const { headers, ...message } = msgObject;

      const blob = new Blob([JSON.stringify(message)], headers);
      const url =
        typeof beaconUrl === 'function' ? beaconUrl(share) : beaconUrl;
      navigator.sendBeacon(url, blob);
      dispatch(clearReviewChanges());
    };

    //NOTE: on mobile, the browser will not fire `beforeunload` event
    addEventListener('beforeunload', sendData);

    let timer;
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        if (!getMode()) {
          console.warn(
            'not starting beacon timer: not on review or file-request path '
          );
          return;
        }
        timer = setTimeout(sendData, TIMEOUT);
      } else if (timer) {
        clearTimeout(timer);
      }
    });
  }

  return (next) => (action) => {
    switch (action.type) {
      case requestStatusActions.MARK_SUCCESS:
        switch (action.payload?.action.type) {
          case nodeActions.CREATE_RESOURCE_BY_ANCESTOR:
          case nodeActions.CREATE_RESOURCE:
          case nodeActions.MUTATE_RESOURCE:
          case nodeActions.DELETE_RESOURCE:
            if (
              relevantResources.includes(
                action.payload.action.meta?.apiClient?.resourceType
              )
            ) {
              dispatch(setReviewChanges(action.payload.action.meta.apiClient));
            }

            break;
          default:
            break;
        }
        break;

      // file-upload resources never get sent to the backend, so we need to intercept them differently
      case nodeActions.RECEIVE_RESOURCE:
        if (action.payload.resourceType === 'file-upload') {
          if (relevantResources.includes('file-upload')) {
            dispatch(setReviewChanges(action.payload.data));
          }
        }
        break;

      default:
        break;
      // Call the next dispatch method in the middleware chain.
    }

    const results = next(action);
    return results;
  };
};
