import {
  ExportBatch,
  ExportStateType,
  ExportsParameters
} from '@cube3/common/model/schema/resources/export-batch';
import { useMemo } from 'react';

const capitalize = (str: string) =>
  str && str.charAt(0).toLocaleUpperCase() + str.slice(1);

// look up for labels
const labels = {
  success: 'Export done',
  failed: 'Export failed',
  rendering: 'Converting...',
  pending: 'Waiting to export',
  processing: 'Transferring...'
};

const getExportStateLabel = (state: ExportStateType, abbreviated?: boolean) => {
  if (state === 'failed') {
    return abbreviated
      ? 'Export failed'
      : 'Export failed' +
          '. Please try again later or contact support@cube-cloud.com';
  }
  const l: string = labels[state];

  return l || capitalize(state);
};

/** parse the export state into the same shape as `MediaQueueTile` state type (status, label, progess)
 *  @abbreviated: show an abbreviated error message, default to true
 */
export const useExportStatus = (
  resource: ExportBatch | { attributes: ExportsParameters },
  abbreviated = true
) => {
  return useMemo(() => {
    const { state } = resource.attributes;
    const progress = (resource as ExportBatch).attributes.progress;
    return {
      ...resource.attributes,
      status: state,
      label: getExportStateLabel(state, abbreviated),
      progress:
        state === 'pending' && progress === 0
          ? 100
          : progress === 0
          ? undefined
          : progress
    };
  }, [resource, abbreviated]);
};
