import * as React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Popover,
  Fade
} from '@material-ui/core';

import { useSurfaceClasses } from '../../Surface/Surface';
import { fixBackgroundClick } from '../../helpers/SimulateContextClick';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      // // set the background color on the underlying material UI paper to none, otherwise a border shows
      // backgroundColor: 'none',
      // // set the border radius at the same value as the Popover surface.
      // borderRadius: '8px',
      width: 'max-content' // the width of the menu is sized to its contents.
    }
  })
);

export interface ContextualMenuPopoverUIProps {}

interface ContextMenuContext {
  menuOpen: boolean;
  clientX: number;
  clientY: number;
  handleClose(e: React.MouseEvent): void;
  handleClearAll(e: React.MouseEvent): void;
  onContextMenu?(e: React.MouseEvent): void;
}

export const contextMenuContext = React.createContext<ContextMenuContext>({
  menuOpen: false,
  clientX: 0,
  clientY: 0,
  handleClose: (e) => {},
  handleClearAll: (e) => {},
  onContextMenu: (e) => {}
});

export const ContextualMenuPopoverUI: React.FC<
  React.PropsWithChildren<ContextualMenuPopoverUIProps>
> = (props) => {
  const { children } = props;

  const { menuOpen, clientX, clientY, handleClose, handleClearAll } =
    useContext(contextMenuContext);

  const classes = useStyles(props);

  // get CSS styles for floating surface.
  const surfaceStyles = useSurfaceClasses({
    floating: true,
    corners: 'rounded',
    gradient: 'lamp',
    background: 'base2',
    cornerRadius: 'lowEmphasis',
    floatingHeight: 24
  });

  return (
    <Popover
      key={clientX + clientY} // Important: Give the popover key so will be triggered on changed on rmb + rmb
      open={menuOpen}
      PaperProps={{ className: `${surfaceStyles} ${classes.paper}` }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: clientY - 5, left: clientX - 5 }}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClose={handleClose}
      onClick={(e) => {
        handleClose(e);
        e.stopPropagation();
        e.preventDefault();
      }}
      onContextMenu={(e) => {
        handleClose(e);
        e.stopPropagation();
        e.preventDefault();
        fixBackgroundClick(e, e.clientX, e.clientY, handleClearAll);
      }}
    >
      {children}
    </Popover>
  );
};
