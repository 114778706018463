import React, { useEffect, useState } from 'react';

import { withRouter, RouteProps } from 'react-router-dom';

import { createConsentSubscriber } from '@cube3/state/src/redux/middleware/cookie-consent-middleware';
import { getEnvVar } from './getEnvVar';

declare global {
  interface Window {
    _paq: any;
  }
}

export const initMatomo = () => {
  const _paq = window._paq || [];

  // require user consent before processing data
  _paq.push(['requireConsent']);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);

  window._paq = _paq;

  (function () {
    let u = `//${getEnvVar('MATOMO_HOST') || `localhost:8020/`}`;
    if (u[u.length - 1] !== '/') {
      u += '/';
    }
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', getEnvVar('MATOMO_SITE_ID') || '1']);
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    if (!s) {
      return; // otherwise tests break
    }
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();
};

export const trackPageview = () => {
  window._paq.push(['setCustomUrl', window.location.pathname]);
  window._paq.push(['trackPageView']);
};

export const trackConsent = () => {
  window._paq.push(['rememberConsentGiven']);
};
export const rejectConsent = () => {
  window._paq.push(['forgetConsentGiven']);
};

export const Analytics: React.ComponentType<RouteProps> = withRouter(
  ({ location }) => {
    useEffect(() => {
      initMatomo();
      const unsub = createConsentSubscriber(['analytics'])(
        () => {
          trackConsent();
        },
        () => rejectConsent()
      );
      return () => {
        unsub();
      };
    }, []);

    const [currentPathname, setCurrentPathname] = useState(location.pathname);

    if (location.pathname !== currentPathname) {
      trackPageview();
      setCurrentPathname(location.pathname);
    }

    return null;
  }
);
