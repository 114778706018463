import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  ExportChannelsPicker,
  useExportChannelsStyles
} from '@cube3/ui/src/exports/ExportChannelsPicker';
import {
  MetaAdAccount,
  MetaConfig,
  YoutubeChannelType,
  YouTubeConfig
} from '@cube3/common/model/resource-types';
import { ExportTarget } from '@cube3/common/model/schema/resources/export-target';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { useModalActions } from '../../Modals/modalActions';

interface ExportAccountType extends MetaConfig {
  id: string;
  display_name: string;
}

const emptyArray = [];

export const MetaAdAccountsModal = (props) => {
  const { modalContext } = props;
  const { exportTargetTypeId } = modalContext;

  const [selectedAccount, setSelectedAccount] =
    useState<ExportAccountType>(null);
  const [selectedAdAccount, setSelectedAdAccount] =
    useState<MetaAdAccount>(null);

  const { previousModal, openModal } = useModalActions();
  const [currentAccountId] = useCurrentAccount();

  const params = useMemo(() => {
    return {
      filter: {
        exportTargetTypeId: exportTargetTypeId // NOTE: not implemented
      }
    };
  }, [exportTargetTypeId]);

  const exportTargets = useResourceList__ALPHA({
    resourceId: currentAccountId,
    resourceType: 'account',
    edgeType: 'export-target',
    edgeLabel: 'export-targets',
    params,
    strategy: 'fetch-on-mount'
  }).resources as ExportTarget<'meta'>[];

  const accounts = useMemo(() => {
    if (!exportTargets) return [];
    return exportTargets.map((res) => {
      return {
        id: res.id,
        display_name: res.attributes.display_name,
        adAccounts: res.attributes.integration_config.adAccounts
      };
    });
  }, [exportTargets]);

  /** the adAccounts of the selected account */
  const adAccounts =
    (selectedAccount && selectedAccount.adAccounts) || emptyArray;

  const handleNext = useCallback(() => {
    openModal(
      'social_export_options',
      {
        adAccount: selectedAdAccount,
        exportTargetId: selectedAccount?.id,
        exportTargetTypeId: exportTargetTypeId,
        platform: 'Meta'
      },
      false
    );
  }, [openModal, selectedAccount, selectedAdAccount, exportTargetTypeId]);

  const handleManageIntegration = useCallback(() => {
    // open modal version of Account’s Integrations page
    openModal('connected_accounts_modal', null, false);
  }, [openModal]);

  const handleSelectAccount = useCallback((account) => {
    setSelectedAccount(account);
    setSelectedAdAccount(
      account.channels && account.channels.length === 1
        ? account.channels[0]
        : undefined
    );
  }, []);

  useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setSelectedAccount(accounts[0]);
    }
    if (
      selectedAccount &&
      !accounts.find((ac) => ac.id === selectedAccount?.id)
    ) {
      setSelectedAccount(null);
    }
  }, [accounts, selectedAccount]);

  useEffect(() => {
    if (adAccounts.length === 1) {
      setSelectedAdAccount(adAccounts[0]);
    }
    if (adAccounts.length === 0) {
      setSelectedAdAccount(null);
    }
  }, [adAccounts]);

  const classes = useExportChannelsStyles();

  return (
    <ModalMenuUI
      title="Export to Meta • Select Ad Account"
      onCloseEvent={previousModal}
      classes={{ contents: classes.modalContents }}
      wide={true}
      footerLeftComponent={
        <Button
          text={'Manage integration'}
          onClick={handleManageIntegration}
          colorVariant={'ghost1'}
        />
      }
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={previousModal}
            disabled={false}
          />
          <Button
            disabled={!selectedAdAccount}
            colorVariant={'filled1'}
            text={'Next'}
            onClick={handleNext}
          />
        </>
      }
    >
      <ExportChannelsPicker
        title="Ad Account"
        noChannelsMessage="This Meta account does not have any Ad Account associated."
        accounts={accounts}
        channels={adAccounts}
        selectedAccount={selectedAccount}
        selectedChannel={selectedAdAccount}
        onManageIntegration={handleManageIntegration}
        onSelectAccount={handleSelectAccount}
        onSelectChannel={setSelectedAdAccount}
      />
    </ModalMenuUI>
  );
};
