import React, { useEffect } from 'react';

import { AttachedContractsLayout } from '@cube3/ui/src/Layout/AttachedContractsLayout';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { ContractsList } from '../prefabs/ContractList';
import {
  RelatedContractsNotice,
  ProjectUrlParams
} from '../prefabs/RelatedContractsNotice';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { Padding } from '../prefabs/Padding';
import {
  ContractSelectionProvider,
  useContractSelection
} from '../hooks/useContractSelection';
import { ContractView } from './ContractView';
import { AttachedContractsCommandBar } from '../prefabs/command-bars/AttachedContractsCommandBar';
import { useRouteMatch } from 'react-router';
import { urlStructureProject } from '../../../routing/routingPaths';

interface Props {
  parentResource?: ResourceIdentifier;
}

const AttachedContractsViewBase: React.ComponentType<Props> = ({
  parentResource
}) => {
  const attachedContracts = useResourceList__ALPHA({
    resourceType: parentResource.type,
    resourceId: parentResource.id,
    edgeType: 'contract',
    edgeLabel: 'contracts',
    params: {
      page: {
        size: 1
      },
      sort: 'expires_at'
    }
  });

  const { projectId } =
    useRouteMatch<ProjectUrlParams>(urlStructureProject)?.params || {};

  const projectContracts = useResourceList__ALPHA({
    resourceType: 'project',
    resourceId: parentResource.type === 'asset' && projectId,
    edgeType: 'contract',
    edgeLabel: 'contracts',
    params: {
      page: {
        size: 1
      }
    }
  });

  const [selection, setSelection] = useContractSelection();

  const contractCount = attachedContracts.pageCounts?.itemCount;

  useEffect(() => {
    if (attachedContracts.first) {
      setSelection(attachedContracts.first);
    }
    if (contractCount === 0 || attachedContracts.loading) {
      setSelection(null);
    }
  }, [
    attachedContracts.first,
    setSelection,
    contractCount,
    attachedContracts.loading
  ]);

  return (
    <AttachedContractsLayout
      loading={attachedContracts.loading || projectContracts.loading}
      menuElement={
        <AttachedContractsCommandBar
          contract={selection as ResourceIdentifier}
          parentResource={parentResource}
        />
      }
      noticeElement={
        parentResource.type === 'asset' && <RelatedContractsNotice />
      }
      listElement={<ContractsList all={true} parentResource={parentResource} />}
      detailElement={
        selection ? (
          <ContractView contractId={selection.id} />
        ) : (
          <Padding>
            <Typography typographyStyle="heading2" color="contrast2">
              No contracts attached to this {parentResource.type}
            </Typography>
            <Typography typographyStyle="body2" color="contrast3">
              Use the menu to attach existing contracts or create a new one
            </Typography>
          </Padding>
        )
      }
      listHeaderElement={
        <Padding>
          <Typography>
            {(attachedContracts.pageCounts?.itemCount || 0) +
              (projectContracts.pageCounts?.itemCount || 0)}{' '}
            contracts
          </Typography>
        </Padding>
      }
    />
  );
};

export const AttachedContractsView = ({ parentResource }) => {
  return (
    <ContractSelectionProvider>
      <AttachedContractsViewBase parentResource={parentResource} />
    </ContractSelectionProvider>
  );
};
