import React, { useState, useCallback } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import GeneralSVG, {
  GeneralSVGProperties,
  iconTimes
} from '../icons/GeneralSVG';
import { Typography } from '../typography/Typography';
import { useClassName } from '../utils/useClassName';
import TruncatedText from '../human/Truncate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 32,
      paddingLeft: 12,
      paddingRight: 12,
      marginRight: 4,
      marginBottom: 4,
      display: 'inline-block',
      backgroundColor: theme.customPalette.primary.light,
      borderRadius: 6,
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      fontFamily: theme.typography.fontFamily,
      verticalAlign: 'middle',
      userSelect: 'none',
      ...theme.typographyStyles.body2
    },
    interactive: {
      cursor: 'pointer',
      '&:active': {
        opacity: 0.8
      }
    },
    containerHover: {
      backgroundColor: '#545454'
      // backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
      //   theme.customPalette.primary.light,
      //   'primary',
      //   ['hover']
      // )
    },
    containerError: {
      backgroundColor: theme.customPalette.dangerError,
      '&$containerHover': {
        backgroundColor: '#FF4D59'
      }
    },
    text: {
      paddingRight: 2
    },
    innerContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    button: {
      height: 20,
      border: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      marginRight: -8,
      outline: 'none',
      borderRadius: theme.surfaceCorners.circular,
      color: theme.customPalette.secondary.contrastColor,
      ...theme.typographyStyles.body2,
      '&:not(&:disabled)': {
        cursor: 'pointer',

        '&:focus, &:hover, &:active': {
          backgroundColor: theme.customPalette.backgroundAccent.whiteAccent2
        }
      }
    },
    badge: {
      padding: '0 4px',
      backgroundColor: theme.customPalette.backgroundAccent.whiteAccent2,
      borderRadius: 8,
      margin: '0 6px',
      height: '16px',
      lineHeight: '16px'
    }
  })
);

interface ChipProps {
  text: string | JSX.Element;
  onClick?(ev: React.MouseEvent): void;
  onCrossClick?(): void;
  error?: boolean;
  disabled?: boolean;
  icon?: string;
  highlight?: boolean;
  maxWidth?: number;
  badge?: string;
  extraSVGProps?: GeneralSVGProperties;
}
const emptyObj = {};

/** Displays a small clickable element that can contain text, an icon, and a badge. Used to represent a filter or tag. */
export const Chip: React.FunctionComponent<ChipProps> = (props) => {
  const [isHovered, setHoverState] = useState(false);
  const setHoverToTrue = () => setHoverState(true);
  const setHoverToFalse = () => setHoverState(false);
  const {
    text,
    onCrossClick,
    onClick,
    error = false,
    disabled = false,
    highlight = false,
    icon,
    maxWidth,
    badge,
    extraSVGProps = emptyObj
  } = props;

  const classes = useStyles(props);

  const handleClick = useCallback(
    (e) => {
      if (onCrossClick) {
        e.stopPropagation();
        onCrossClick();
      }
    },
    [onCrossClick]
  );

  const interactive = !!onClick;

  return (
    <div
      data-pw="chip"
      className={useClassName(
        classes.container,
        error && classes.containerError,
        isHovered && classes.containerHover,
        interactive && classes.interactive
      )}
      onMouseEnter={() => setHoverToTrue()}
      onMouseLeave={() => setHoverToFalse()}
      onClick={onClick}
    >
      <div className={classes.innerContainer}>
        {icon && (
          <GeneralSVG
            path={icon}
            color={disabled ? 'contrast3' : highlight ? 'brand2' : 'contrast1'}
            {...extraSVGProps}
          />
        )}
        <Typography
          color={disabled ? 'contrast3' : highlight ? 'brand2' : 'contrast1'}
          className={classes.text}
        >
          {maxWidth && typeof text === 'string' ? (
            <TruncatedText
              str={text as string}
              widthLimit={maxWidth}
              toolTip={true}
            />
          ) : (
            text
          )}
        </Typography>
        {badge && (
          <Typography
            color="contrast2"
            typographyStyle="body3"
            className={classes.badge}
          >
            {badge}
          </Typography>
        )}
        {onCrossClick && (
          <button
            disabled={disabled}
            type="button"
            onClick={handleClick}
            className={classes.button}
          >
            {
              <GeneralSVG
                path={iconTimes}
                color={!disabled ? 'contrast1' : 'contrast3'}
              />
            }
          </button>
        )}
      </div>
    </div>
  );
};

interface Chipdata {
  value: string;
  id?: string;
}
export interface ChipFieldProps {
  input: Chipdata;
  removeItem: (value: string, data: Chipdata) => void;
  onClick?(data: Chipdata): void;
  error?: boolean;
  disabled: boolean;
  maxWidth?: number;
  badge?: string;
}

export const ChipField: React.FunctionComponent<ChipFieldProps> = (props) => {
  const { removeItem, input, error, disabled, onClick, maxWidth, badge } =
    props;

  const handleCrossClick = useCallback(() => {
    if (removeItem) {
      removeItem(input.value, input);
    }
  }, [removeItem, input]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(input);
    }
  }, [onClick, input]);

  return (
    <Chip
      maxWidth={maxWidth}
      onCrossClick={removeItem && handleCrossClick}
      onClick={onClick && handleClick}
      text={input.value}
      error={error}
      disabled={disabled}
      badge={badge}
    />
  );
};

export default ChipField;
