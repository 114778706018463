import { ns } from './config';
import { SelectionItemInterface, SelectionItemsType } from './types';
import { normalizeSelectionItems } from './utils';

// action definitions
export const SELECT_ITEMS = `${ns}/SELECT_ITEMS`;
export const TOGGLE_SELECTION = `${ns}/TOGGLE_SELECTION`;
export const ADD_ITEMS = `${ns}/ADD_ITEMS`;
export const REMOVE_ITEMS = `${ns}/REMOVE_ITEMS`;
export const CLEAR_ITEMS = `${ns}/CLEAR_ITEMS`;
export const SET_ACTIVE_ITEM = `${ns}/SET_ACTIVE_ITEM`;
export const CLEAR_ACTIVE_ITEM = `${ns}/CLEAR_ACTIVE_ITEM`;
export const UNSET_ACTIVE_ITEM = `${ns}/UNSET_ACTIVE_ITEM`;
export const SET_VISITING_ITEM = `${ns}/SET_VISITING_ITEM`;
export const UNSET_VISITING_ITEM = `${ns}/UNSET_VISITING_ITEM`;
export const SET_INVERTED = `${ns}/SET_INVERTED`;

export const actions = {
  SELECT_ITEMS,
  TOGGLE_SELECTION,
  ADD_ITEMS,
  REMOVE_ITEMS,
  CLEAR_ITEMS,
  SET_ACTIVE_ITEM,
  CLEAR_ACTIVE_ITEM,
  UNSET_ACTIVE_ITEM,
  SET_VISITING_ITEM,
  UNSET_VISITING_ITEM,
  SET_INVERTED
} as const;

// action creators
export const selectItems = (items: SelectionItemsType) => ({
  type: SELECT_ITEMS,
  payload: normalizeSelectionItems(items)
});

export const addItems = (items: SelectionItemsType) => ({
  type: ADD_ITEMS,
  payload: normalizeSelectionItems(items)
});

export const removeItems = (items: SelectionItemsType) => ({
  type: REMOVE_ITEMS,
  payload: normalizeSelectionItems(items)
});

export const clearItems = () => ({
  type: CLEAR_ITEMS
});

export const setActiveItem = (item: SelectionItemInterface) => ({
  type: SET_ACTIVE_ITEM,
  payload: { type: item.type, id: item.id }
});

export const unsetActiveItem = (item: SelectionItemInterface) => ({
  type: UNSET_ACTIVE_ITEM,
  payload: { type: item.type, id: item.id }
});

export const clearActiveItem = () => ({
  type: CLEAR_ACTIVE_ITEM
});

export const setVisitingItem = (item: SelectionItemInterface) => ({
  type: SET_VISITING_ITEM,
  payload: { type: item.type, id: item.id }
});

export const unsetVisitingItem = (item: SelectionItemInterface) => ({
  type: UNSET_VISITING_ITEM,
  payload: { type: item.type, id: item.id }
});

export const toggleSelectItem = (item: SelectionItemInterface) => ({
  type: TOGGLE_SELECTION,
  payload: item
});

export const setInvertedSelection = (setInverted?: boolean) => ({
  type: SET_INVERTED,
  payload: setInverted
});

export const actionCreators = {
  selectItems,
  addItems,
  removeItems,
  clearItems,
  setActiveItem,
  unsetActiveItem,
  clearActiveItem,
  setVisitingItem,
  unsetVisitingItem,
  toggleSelectItem,
  setInvertedSelection
} as const;
