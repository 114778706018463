import { ColorVariant } from '../../atoms/Color';

/** initial create tag fields */
export const initialUiTag = {
  new_0: {
    text: '',
    color: 'yellow',
    error: undefined,
    id: undefined,
    deleted: false,
    categoryName: ''
  } as const
};

export type CreateTagRowUI = {
  text: string;
  color: ColorVariant;
  /** the display name of the category to which the tag belongs */
  categoryName?: string;
  /** true if text/color/category has changed */
  dirty?: boolean;
  error?: string;
  /** used to distinguish existing tags from new tags.
   * - new tag has no id
   */
  id?: string;
};
