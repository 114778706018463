import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { UserInfo } from '../../User/UserTypes';
import UserRow from '../../User/UserRow';
import InteractableSurface from '../../Surface/InteractableSurface';
import ManageUserMenuUI from '../../menus/ManageUserMenuUI';
import { iconCheck } from '../../icons/GeneralSVG';
import { Typography } from '../../typography/Typography';
import {
  MenuItemsRenderer,
  MenuItemType
} from '../../Workspace/subComponents/MenuItemsRenderer';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: theme.sizing[56]
    },
    menuRoot: {
      marginLeft: 'auto',
      justifySelf: 'flex-end',
      marginRight: 10
    },
    menuContents: {
      width: '200px'
    },
    menuItemSelected: {
      marginBottom: '6px',
      marginLeft: '-4px',
      marginRight: ' 12px'
    },
    menuItem: {
      paddingLeft: '38px'
    },
    teamRow: {
      padding: '0 20px'
    }
  })
);

interface Properties {
  userInfo?: UserInfo;
  isMember?: boolean;
  isAdmin?: boolean;
  isCurrentAccount?: boolean;
  onRoleChange(role: { role: 'Member' | 'Admin' }): void;
  onRemoveMemberClick: () => void;
  canEdit: boolean;
  memberDetailComponent?: JSX.Element;
}

export const ProjectMemberUserDetailUI = (props: Properties) => {
  const classes = useStyles(props);
  const {
    userInfo,
    isAdmin,
    isMember,
    onRoleChange,
    isCurrentAccount,
    onRemoveMemberClick,
    canEdit,
    memberDetailComponent
  } = props;

  const menuItems: MenuItemType[] = React.useMemo(
    () => [
      {
        text: 'Admin',
        disableMenu: !canEdit,
        colorVariant: isAdmin ? 'ghost1' : 'ghost2',
        pathLeftSvg: isAdmin ? iconCheck : '',
        extraSVGProps: {
          className: isAdmin ? classes.menuItemSelected : ''
        },
        className: !isAdmin ? classes.menuItem : '',
        onClick: () => onRoleChange({ role: 'Admin' })
      },
      {
        text: 'Member',
        disableMenu: !canEdit,
        colorVariant: isMember ? 'ghost1' : 'ghost2',
        pathLeftSvg: isMember ? iconCheck : '',
        extraSVGProps: {
          className: isMember ? classes.menuItemSelected : ''
        },
        className: !isMember ? classes.menuItem : '',
        onClick: () => onRoleChange({ role: 'Member' })
      },
      {
        colorVariant: 'danger',
        disableMenu: isCurrentAccount ? false : !canEdit,
        text: !isCurrentAccount ? 'Remove member' : 'Leave Project',
        onClick: onRemoveMemberClick
      }
    ],
    [
      canEdit,
      classes,
      isAdmin,
      isCurrentAccount,
      isMember,
      onRemoveMemberClick,
      onRoleChange
    ]
  );

  if (!userInfo && !memberDetailComponent) {
    return <Shimmer weight="medium" height={56} margin="medium" />;
  }

  return (
    <InteractableSurface
      highlightOnHover={canEdit || isCurrentAccount}
      surfaceProps={{ corners: 'rounded' }}
      classes={{ root: classes.root }}
    >
      {memberDetailComponent ? (
        memberDetailComponent
      ) : (
        <UserRow userInfo={userInfo} />
      )}
      {isAdmin && <Typography color="contrast3">Admin</Typography>}
      {canEdit || isCurrentAccount ? (
        <ManageUserMenuUI
          userInfo={userInfo}
          menuItems={<MenuItemsRenderer menuItems={menuItems} />}
        />
      ) : (
        <div style={{ width: 52 }}></div> //necessary for layout purposes
      )}
    </InteractableSurface>
  );
};
