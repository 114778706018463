import React, { useCallback, useEffect } from 'react';

import { Asset } from '@cube3/common/model/schema/resources/asset';
import { Validation } from '@cube3/common/model/schema/resources/validation';
import { Validator } from '@cube3/common/model/schema/resources/validator';
import { useTVBroadcastQualityCheck } from '../hooks/useTVBroadcastQualityCheck';

// UI
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ExportSingleAssetInfo } from './ExportAssetsInfo';
import {
  CheckPassedWithWarnings,
  CheckFailed,
  CheckPassed,
  Checking
} from '@cube3/ui/src/exports/subcomponents/BroadcastQualityCheckStatusUI';
import { makeClipboardReport } from '../utils/makeClipboardReport';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.customPalette.line.onBase1.contrast2}`,
      borderRadius: theme.surfaceCorners.mediumEmphasis,
      margin: '20px 0'
    }
  })
);

export interface AssetValidationType {
  allPassed: boolean;
  allFailed: boolean;
  success: Validation[];
  failed: Validation[];
  inProgress: Validation[];
  errors: string[];
  warnings: string[];
}

export interface ReceivedProps {
  asset: Asset;
  validators: Validator[];
  updateStates?: Function;
}

const TVadExportQualityCheckListItem = ({
  asset,
  validators,
  updateStates
}: ReceivedProps) => {
  const classes = useStyles();

  const validation = useTVBroadcastQualityCheck({ asset, validators });

  useEffect(() => {
    if (validation && asset && updateStates) {
      updateStates(asset.id, validation);
    }
  }, [asset, updateStates, validation]);

  const checkPassed = validation?.allPassed;
  const checkFailed = validation?.allDone && validation?.someFailed;

  const onCopy = useCallback(() => {
    const report = makeClipboardReport(validation, asset);
    navigator.clipboard.writeText(report);
  }, [validation, asset]);

  return (
    <div className={classes.root}>
      <ExportSingleAssetInfo selection={asset} rootStyles={{ padding: 20 }} />
      <Divider />

      <div style={{ padding: 12 }}>
        {checkPassed ? (
          validation?.warnings.length ? (
            <CheckPassedWithWarnings
              warnings={validation?.warnings}
              onCopy={onCopy}
            />
          ) : (
            <CheckPassed text="Check passed" />
          )
        ) : checkFailed ? (
          <CheckFailed
            errors={validation?.errors}
            serverError={validation?.failedToStart}
            warnings={validation.warnings}
            onCopy={onCopy}
          />
        ) : (
          <Checking />
        )}
      </div>
    </div>
  );
};

export default TVadExportQualityCheckListItem;
