/* React imports */
import React from 'react';
import { createRoot } from 'react-dom/client';

// import { ConnectedRouter } from 'react-router-redux';
import { BrowserRouter } from 'react-router-dom';
import { BrowserRouter as BrowserRouterState } from '@cube3/state/src/index';
import { Provider } from 'react-redux';

import { configureStore } from '@cube3/state/src/redux/store';
import Preloader from './components/preloader';
import * as Sentry from '@sentry/browser';
import beforeSend from './utils/sentryHelpers';
import { createConsentSubscriber } from '@cube3/state/src/redux/middleware/cookie-consent-middleware';
import { getEnvVar, injectEnvVars } from './utils/getEnvVar';
import { Store } from 'redux';
import './index.css';
import { environment, legacyHost } from '@cube3/state/src/utils/environment';
import Client from '@cube3/state/src/wrapped-cube-client';
import Color from 'color';

import { MergedThemeProvider } from './utils/MergedThemeProvider';
// import { registerJaegerWorker } from './utils/registerJaegerWorker';

export function init() {
  console.log('REAL APP');
  injectEnvVars('/env/setEnvVars.js').then(() => {
    const rootStore = configureStore({
      cookieConsentVersions: getEnvVar('COOKIE_CONSENT_VERSIONS'),
      rewriteUUIDS: !!getEnvVar('REDIRECT_FAKE_UUIDS'),
      offlineDetection: true
    }) as Store;

    const releaseHash = getEnvVar('RELEASE_VERSION');

    const ioclientUrl = getEnvVar('IOCLIENT_URL') || legacyHost;

    Client.auth.setIOClientUrl(ioclientUrl);

    const hashColor = (hash) => {
      const h = hash.slice(1, 7); // six chars
      const n = parseInt(h, 16) || 0; // parse as hex (for non hash values)
      const s = `000000${n.toString(16)}`; // pad with zeroes render back to hex
      const c = `#${s.slice(-6)}`; //
      return c;
    };

    const hashIsDark = Color(hashColor(releaseHash || 'unknown')).isDark();
    // eslint-disable-next-line no-console
    console.info(
      `%cCUBE 3 version: %c${releaseHash || 'unknown'}`,
      'background-color: #282828; color: #f9d646 ; padding: 5px; border-radius:  999px 0px 0px 999px; border: solid 2px #282828',
      `background-color: ${
        hashIsDark ? '#e8e8e8' : '#282828'
      }; color: ${hashColor(
        releaseHash || 'unknown'
      )} ; padding: 5px; border-radius:  0px 999px 999px 0px; border: solid 2px #282828;
  } `
    );

    if (environment !== 'development') {
      let initialized;
      createConsentSubscriber(['functional', 'analytics'])(
        () => {
          initialized = true;
          Sentry.init({
            dsn: 'https://d250bfd62f619e8609df35cb38a7e064@o4508013773258752.ingest.de.sentry.io/4508092776251472',
            release: releaseHash,
            environment: environment,
            beforeSend: beforeSend,
            maxBreadcrumbs: 50,
            debug: environment === 'staging'
          });
        },
        () => initialized && Sentry.close()
      );
    }

    const basename = getEnvVar('ROUTER_BASENAME');

    let container = document.getElementById('root');
    if (!container) {
      container = document.createElement('div');
      container.setAttribute('id', 'root');
      document.body.appendChild(container);
    }

    // prevent files droped outside of folders from changing browser location
    window.addEventListener('drop', (ev) => {
      ev.preventDefault();
    });
    window.addEventListener('dragover', (ev) => {
      ev.preventDefault();
    });

    // allow adding jaeger trace id's to requests
    // NOTE: DOESNT WORK CURRENTLY
    //registerJaegerWorker();

    const root = createRoot(container);
    root.render(
      <BrowserRouterState>
        <BrowserRouter basename={basename}>
          <Provider store={rootStore}>
            <MergedThemeProvider>
              <Preloader />
            </MergedThemeProvider>
          </Provider>
        </BrowserRouter>
      </BrowserRouterState>
    );

    // expose store when run in Cypress
    if ((window as any).Cypress) {
      (window as any).store = rootStore;
    }
  });
}

export default true;

export { Provider as Provider, configureStore as configureStore };
