import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { TabsLayout } from '@cube3/ui/src/Tabs/TabsLayout';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { TabView } from '@cube3/ui/src/Tabs/TabView';
import { RevokeConnectedAccountsView } from '../RevokeConnectedAccountsView';

import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { ConfigureIntegrationView } from '../ConfigureIntegrationView';
import { useState } from 'react';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useExportTargetDefaults } from '../hooks/useExportTargetDefaults';
import { useCurrentUser } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { ReqeuestAccessNudge } from '../prefabs/RequestAccessNudge';
import {
  DESCRIPTIONS,
  makeGenericTagline,
  TAGLINES
} from '../config/export-target-types';

interface Props {
  modalContext: {
    exportTargetTypeId: string;
    disabled?: boolean;
  };
}

// const requiredFeatures = {
//   features: ['/WORKSPACE/EXPORTS/YOUTUBE'] as Feature[]
// };

export const ManageIntegrationModal: React.FC<Props> = props => {
  // const [youtubeFeature] = useFeatures(requiredFeatures);

  const { previousModal, openModal, goBackModals } = useModalActions();

  const { modalContext } = props;
  const { exportTargetTypeId, disabled } = modalContext;

  const integration = useResource__ALPHA({
    resourceId: exportTargetTypeId,
    resourceType: 'export-target-type'
  });

  const [workspaceId, workspace] = useCurrentWorkspace();

  const membersParams = useMemo(() => {
    return {
      filter: {
        exportTargetTypeId: exportTargetTypeId // NOTE: not implemented
      }
    };
  }, [exportTargetTypeId]);

  const members = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'export-target',
    edgeLabel: 'export-targets',
    params: membersParams,
    strategy: 'fetch-on-mount'
  }); // TODO: use unique accounts number

  const membersCount = useMemo(() => {
    if (!members.resources) {
      return;
    }
    const ids = members.resources?.map(e => e.relationships.belongs_to?.id);
    return new Set(ids).size;
  }, [members.resources]);

  const hasAccounts = integration.first?.attributes.integration_types.includes(
    'account'
  );

  const [config, setConfig] = useState<{ dirty: boolean }>(null);

  const [
    defaults,
    defaultsStatus,
    handleChange,
    changeStatus
  ] = useExportTargetDefaults(exportTargetTypeId);

  const handleSaveButtonClick = useCallback(() => {
    handleChange(config);
  }, [config, handleChange]);

  const pendingChanges = config?.dirty;

  const tabs = useMemo(
    () => [
      {
        id: 'general',
        label: 'General',
        disabled: disabled,
        disabledReason: "Integration isn't active"
      },
      {
        id: 'members',
        label: `Authorized members${membersCount ? ` (${membersCount})` : ''}`,
        disabled: disabled,
        disabledReason: "Integration isn't active",
        visible: hasAccounts
      },
      { id: 'info', label: 'Info' }
    ],
    [membersCount, disabled, hasAccounts]
  );

  const defaultsRef = useRef(defaults);

  useEffect(() => {
    defaultsRef.current = { ...defaults };
  }, [defaults]);

  const handleTabChange = useCallback(
    (tab, tabIdx, methods) => {
      if (pendingChanges) {
        openModal(
          'integration_discard_config_changes',
          {
            onConfirm: () => {
              methods.resume();
              previousModal();
              setConfig(null);
            },
            onCancel: previousModal
          },
          false
        );
        methods.cancel();
      }
    },
    [pendingChanges, previousModal, openModal, setConfig]
  );

  const handleClose = useCallback(() => {
    if (pendingChanges) {
      openModal(
        'integration_discard_config_changes',
        {
          onConfirm: () => {
            goBackModals(2);
          },
          onCancel: previousModal
        },
        false
      );
    } else {
      previousModal();
    }
  }, [previousModal, goBackModals, openModal, pendingChanges]);

  const name = integration.first?.attributes.display_name || '...';
  const description =
    integration.first?.attributes.description ||
    DESCRIPTIONS[name] ||
    TAGLINES[name] ||
    makeGenericTagline(integration.first);

  const user = useCurrentUser()[1];

  return (
    <ModalMenuUI
      multiplyContentSpace={8}
      noScroll={true}
      title={`Manage ${name} integration`}
      loading={
        changeStatus === statuses.IN_FLIGHT ||
        !defaultsStatus ||
        defaultsStatus === statuses.IN_FLIGHT
      }
      footerRightComponent={
        <>
          {pendingChanges && <Button onClick={handleClose}>Cancel</Button>}
          <Button
            colorVariant="filled1"
            onClick={pendingChanges ? handleSaveButtonClick : handleClose}
          >
            {pendingChanges ? 'Save' : 'Done'}
          </Button>
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          position: 'absolute',
          height: 'calc(100% + 48px)',
          width: 'calc( 100% + 64px)',
          top: 0,
          left: 0,
          flexShrink: 1,
          flexGrow: 1,
          marginTop: -24,
          marginBottom: -24,
          marginRight: -32,
          marginLeft: -32
        }}
      >
        {disabled && (
          <ReqeuestAccessNudge name={name} workspace={workspace} user={user} />
        )}
        <div
          style={{
            position: 'relative',
            flexGrow: 1,
            flexShrink: 1,
            marginLeft: 32
          }}
        >
          <TabsLayout
            tabs={tabs}
            autoHeightScroll={false}
            initialTab={!disabled ? tabs[0] : tabs[2]}
            marginCollapse={true}
            onTabChange={handleTabChange}
          >
            <TabView forTab="general">
              <ConfigureIntegrationView
                // categories={categories}
                defaults={defaults}
                onFormChange={setConfig}
                exportTargetTypeId={exportTargetTypeId}
              />
            </TabView>

            <TabView forTab="members">
              <div style={{ paddingRight: 32 }}>
                <RevokeConnectedAccountsView
                  exportTargetTypeId={exportTargetTypeId}
                />
              </div>
            </TabView>

            <TabView forTab="info">
              <div style={{ paddingRight: 32 }}>
                <Typography typographyStyle="heading2" gutter={true}>
                  Description
                </Typography>
                {description.split(/\n/).map((p, idx) => (
                  <Typography
                    key={idx}
                    typographyStyle="body2"
                    color="contrast2"
                    gutter={true}
                  >
                    {p}
                  </Typography>
                ))}
              </div>
            </TabView>
          </TabsLayout>
        </div>
      </div>
    </ModalMenuUI>
  );
};
