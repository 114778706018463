import React from 'react';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { compose } from '@cube3/state/src/utils/component-helpers';
import Cube3Link, {
  Cube3AnchorLink
} from '../components/app/routing/Cube3Link';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  actionCreators,
  setableCookieTypes,
  selectors
} from '@cube3/state/src/redux/ducks/cookie-consent';
import { CookieCategory } from '@cube3/state/src/redux/ducks/cookie-consent/state';
import { CookieConsentUI } from '@cube3/ui/src/Cookies/CookieConsentUI';
import { CookiePolicyUpdatedUI } from '@cube3/ui/src/Cookies/CookiePolicyUpdatedUI';
import { createPortal } from 'react-dom';
import { useModalActions } from '../components/app/layout/Modals/modalActions';
import { matchPath, useLocation } from 'react-router-dom';
import {
  urlStructureFigmaLogin,
  urlStructureFigmaRoot
} from '../components/app/integrations/figma/urlStructureFigma';

const stp = (state, ownProps) => {
  return {
    hasChosen: selectors.hasChosen(state),
    versionMismatch: selectors.isVersionMismatch(state)
  };
};

const dtp = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      handleConsent: () =>
        actionCreators.giveConsent(
          Object.keys(setableCookieTypes) as CookieCategory[]
        ),
      updateConsent: () => actionCreators.updateConsent(),
      handleReject: () =>
        actionCreators.rejectConsent(
          Object.keys(setableCookieTypes) as CookieCategory[]
        )
    },
    dispatch
  );
};

interface CookieConsentProps {
  hasChosen: boolean;
  versionMismatch: boolean;
  handleConsent(): void;
  handleReject(): void;
}

const CookieConsentBase: React.ComponentType<CookieConsentProps> = (props) => {
  const { hasChosen, versionMismatch, handleConsent, handleReject } = props;

  const cookieStatementUrl = '/legal/policies/cookie-statement';

  const modalActions = useModalActions();

  const location = useLocation();
  const onFigmaRoute =
    matchPath(urlStructureFigmaRoot, location.pathname) ||
    matchPath(urlStructureFigmaLogin, location.pathname);

  if (onFigmaRoute) {
    return null;
  }

  if (!hasChosen) {
    return (
      <>
        {createPortal(
          <CookieConsentUI
            onClickAccept={() => handleConsent()}
            onClickReject={() => handleReject()}
            onClickSettings={() => modalActions.openModal('cookie_settings')}
            statementLinkComponent={
              <Cube3AnchorLink
                href={cookieStatementUrl}
                underline={false}
                target="_blank"
              >
                <Typography display="inline" color={'brand2'}>
                  Cookie Statement
                </Typography>
              </Cube3AnchorLink>
            }
          />,
          document.getElementById('consent-bar') ||
            document.body.appendChild(document.createElement('div'))
        )}

        {/* Create room for the portal above (the portal above is an overlay for z-index reasons so we will push the content away here) */}
        <div style={{ height: 176, width: '100%' }} />
      </>
    );
  }

  if (versionMismatch) {
    return (
      <>
        {createPortal(
          <CookiePolicyUpdatedUI
            onClickAccept={() => handleConsent()}
            onClickReject={() => handleReject()}
            cookiesPageLinkcomponent={
              <Cube3Link
                pathname="/account/privacy/cookie-statement"
                underline={true}
              >
                <Typography display="inline">Cookie Statement</Typography>
              </Cube3Link>
            }
          />,
          document.getElementById('consent-bar') ||
            document.body.appendChild(document.createElement('div'))
        )}

        {/* Create room for the portal above (the portal above is an overlay for z-index reasons so we will push the content away here) */}
        <div style={{ height: 75, width: '100%' }} />
      </>
    );
  }

  return null;
};

export const CookieConsent = compose(CookieConsentBase)(connect(stp, dtp));
