/* eslint-disable eqeqeq */

/**
 * @class Point
 * Point, a single point in 2d space.
 * Note: points define a timestamp ts which together with the distance between
 * two points may be used to derive velocity on a curve.
 *
 * @constructor
 * @param {Float} x the horizontal position
 * @param {Float} y the vertical position
 */
export class Point {
  x: number;
  y: number;
  rawX: number;
  rawY: number;
  ts: number;
  length: number;
  speed: number;
  type: 'point';
  isPoint: boolean;
  moved: boolean;

  constructor(x, y, rawX?, rawY?, ts?) {
    this.x = x || 0;
    this.y = y || 0;
    this.rawX = rawX || x || 0;
    this.rawY = rawY || y || 0;
    this.length = 0;
    this.speed = 0;
    this.ts = ts || performance.now();

    this.type = 'point';
    this.isPoint = true;

    this.moved = false;
  }

  isRelative() {
    return this.x != this.rawX;
  }

  getScale() {
    return (
      1 /
      Math.sqrt(
        Math.pow(this.rawX / this.x, 2) + Math.pow(this.rawY / this.y, 2)
      )
    );
  }

  /**
   * takes a point p2 and returns the distance from us to p2
   * @return {Array} the distance
   */
  distance(p2) {
    return Math.sqrt(Math.pow(this.x - p2.x, 2) + Math.pow(this.y - p2.y, 2));
  }

  /**
   * takes a point p2 and returns the x and y offset from this to p2.
   * @return {Array} the x and y offset
   */
  offset(p2) {
    return [p2.x - this.x, p2.y - this.y];
  }

  // TODO: may be used to calculate stroke velocity at some point
  angle(p2) {
    return (Math.atan2(p2.y - this.y, p2.x - this.x) * 180) / Math.PI;
  }

  moveTowards(p2, t) {
    var offset = this.offset(p2);
    this.x += offset[0] * t;
    this.y += offset[1] * t;
    this.moved = true;
    return this;
  }

  /**
   * This method is here to maintain equal signatures accross all segments and
   * simply returns itself.
   * @return {Point} point
   */
  pointAtTravelDistance() {
    return this;
  }

  /**
   * This function is here to maintain equal signatures between all segment types.
   */
  needsUpdate() {
    return this.moved;
  }

  /**
   * update this component when we have changed its properties. This function is here to
   * maintain equal signatures between all segment types.
   */
  update(val?) {
    return this;
  }

  toSVG(prevElement, width, height) {
    width = width || 1;
    height = height || 1;

    var round = function (n, d?) {
      d = d == undefined ? 3 : d;
      return (
        Math.round((n + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d)
      );
    };

    return `<circle cx="${round(this.x * width)}" cy="${round(
      this.y * height
    )}" r="2" fill="red"/>`;
  }
}
