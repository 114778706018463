import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { mutations } from '@cube3/state/src/redux/ducks/resource-edges';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';

export const RemoveTeamMemberConfirmModal = (props) => {
  const { team, member, workspaceId } = props.modalContext;
  const { previousModal } = useModalActions();

  const parentResource = useMemo(() => {
    return { type: 'team' as const, id: team.id };
  }, [team.id]);

  const [deleteMember, deleteMemberStatus] = useMutateRelationship__ALPHA({
    ancestor: parentResource,
    relationship: 'members',
    cacheInvalidator: useCallback(
      (res, mut, anc) => [
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'accounts'
        },
        { type: anc.type, id: anc.id }
      ],
      [workspaceId]
    )
  });

  const onClickRemove = useCallback(() => {
    deleteMember({
      resource: member,
      mutationType: mutations.MUTATION_REMOVE
    });
  }, [deleteMember, member]);

  useEffect(() => {
    if (deleteMemberStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [deleteMemberStatus, previousModal]);

  const loading = deleteMemberStatus && !hasResolved(deleteMemberStatus);
  useGlobalSubmit(onClickRemove, loading);

  return (
    <ModalNoticeUI
      title="Delete Team Member"
      loading={loading}
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button text="Cancel" colorVariant="ghost2" onClick={previousModal} />
          <Button
            text="Remove"
            colorVariant="filledDanger"
            onClick={onClickRemove}
            disabled={false}
          />
        </>
      }
    >
      <Typography color="contrast1" typographyStyle="body2">
        Are you sure you want to remove this member from {team.display_name}?
      </Typography>
    </ModalNoticeUI>
  );
};
