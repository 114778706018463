import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '../../../typography/Typography';
import Scrollbar from '../../../Scrollbar/Scrollbar';

/// @Author : Simon @ AmbassadorsLAB
/// <Summary>
/// Template for a page with settings such as the General settings or Workspace settings
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'calc(100% - 32px)',
      display: 'flex',
      flexDirection: 'column'
    },
    titleSection: {
      display: 'flex',
      alignItems: 'center',
      padding: '32px 0 0 32px'
    },
    undertitle: {
      padding: '20px 0 20px 32px'
    },
    contents: {
      marginTop: '20px',
      flexGrow: 1,
      padding: '0 4px 0 32px'
    },
    contentsInner: ({ fullWidth = false }: any) => ({
      maxWidth: fullWidth ? 'unset' : 800,
      height: '100%',
      marginRight: 20
    })
  })
);

interface Properties {
  title: string;
  undertitle?: string;
  subButton?: JSX.Element;
  fullWidth?: boolean;
  renderContent?: () => JSX.Element;
  commandBar?: JSX.Element;
}

const SettingsPage: React.FC<React.PropsWithChildren<Properties>> = (props) => {
  const {
    renderContent,
    title,
    undertitle,
    subButton,
    children,
    fullWidth,
    commandBar
  } = props;

  const classes = useStyles({ fullWidth });

  return (
    <div className={classes.root} title={title}>
      <div className={classes.titleSection}>
        <Typography typographyStyle={'heading1'}>{title}</Typography>
        {subButton}
      </div>

      {undertitle && (
        <Typography className={classes.undertitle} typographyStyle={'body1'}>
          {undertitle}
        </Typography>
      )}
      {commandBar}
      {/* Render subpage */}
      <div className={classes.contents}>
        <Scrollbar>
          <div className={classes.contentsInner}>
            {children}
            {renderContent && renderContent()}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default SettingsPage;
