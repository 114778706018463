import { ResourceBase } from '@cube3/common/model/resource-types';

const LEGACY_API_RESOURCE_LIST = 'LEGACY_API_RESOURCE_LIST';
const LEGACY_API_RESOURCE = 'LEGACY_API_RESOURCE';

export const legacyApiDataKeys = {
  LEGACY_API_RESOURCE,
  LEGACY_API_RESOURCE_LIST
};

type GenericResource<T> = ResourceBase;

export interface APIResource<T> {
  [LEGACY_API_RESOURCE]?: GenericResource<T>;
  data?: any;
  meta?: any;
}

export type APIResourceResponse<T> = Promise<APIResource<T>>;

export interface APIResourceList<T> {
  [LEGACY_API_RESOURCE_LIST]?: GenericResource<T>[];
  data?: any;
  meta?: any;
}

export type APIResourceListResponse<T> = Promise<APIResourceList<T>>;
