import { OrderDirection, QueueTab } from './types';

export const getResourceId = (resource) => (resource ? resource.id : '');

export const UPLOADS = 'UPLOADS';
export const EXPORTS = 'EXPORTS';
export const FEED = 'FEED';

export const queueTabs = {
  UPLOADS,
  EXPORTS,
  FEED
} as const;

export const ASC = 'asc';
export const DESC = 'desc';

export const orderDirection = {
  ASC,
  DESC
} as const;
export interface UiScreenState {
  fullscreen: boolean;
  fullscreenRequested: boolean;
  gridView: boolean;
  maxGridItems: number;
  maxColumns: number;
  sorting: {
    library: {
      orderBy: string;
      orderDirection: OrderDirection;
    };
    projects: {
      orderBy: string;
      orderDirection: OrderDirection;
    };
    trash: {
      orderBy: string;
      orderDirection: OrderDirection;
    };
    favorites: {
      orderBy: string;
      orderDirection: OrderDirection;
    };
    sharelinkView: {
      orderBy: string;
      orderDirection: OrderDirection;
    };
    sharelinkViewNoDates: {
      orderBy: string;
      orderDirection: OrderDirection;
    };
    projectTemplates: { orderBy: string; orderDirection: OrderDirection };
    search: {
      orderBy: string;
      orderDirection: OrderDirection;
    };
  };
  infoPaneToggled: boolean;
  mediaQueueToggled: boolean;
  mediaQueueTab: QueueTab;
  mediaQueueLastOpened: number;
  uploadQueueBusy: boolean;
  // Review-link view
  // reviewSelectedAsset: any;
  tour: boolean;
}

export const UiScreenDefaultState: UiScreenState = {
  fullscreen: false,
  fullscreenRequested: false,
  gridView: true,
  maxGridItems: null,
  maxColumns: null,
  sorting: {
    library: {
      orderBy: 'display_name',
      orderDirection: ASC
    },
    projects: {
      orderBy: 'updated_at',
      orderDirection: DESC
    },
    trash: {
      orderBy: 'removed_date',
      orderDirection: ASC
    },
    sharelinkView: {
      orderBy: 'display_name',
      orderDirection: ASC
    },
    favorites: { orderBy: 'display_name', orderDirection: ASC },
    sharelinkViewNoDates: {
      orderBy: 'display_name',
      orderDirection: ASC
    },
    projectTemplates: {
      orderBy: 'updated_at',
      orderDirection: DESC
    },
    search: {
      orderBy: 'display_name',
      orderDirection: ASC
    }
  },
  infoPaneToggled: false,
  mediaQueueToggled: false,
  mediaQueueTab: queueTabs.UPLOADS,
  mediaQueueLastOpened: undefined,
  uploadQueueBusy: false,
  tour: false
};

export type SortableList = keyof typeof UiScreenDefaultState.sorting;
