import { GenericFormFieldClasses } from '../../forms/GenericFormField';
import React from 'react';
import Cube3TextField from '../../forms/textfields/Cube3TextField';
import { MetadataFormFieldRow } from '../../metadata/MetadataFormField';

export interface ExportOptionRowProps {
  input?: any;
  meta?: { error: string; touched: boolean; warning: string };
  disabled?: boolean;
  label?: string;
  type?: string;
  required?: boolean;
  classes?: GenericFormFieldClasses;
  value?: any;
  component?: JSX.Element;
  placeholder?: string;
  onChange?: Function;
  showError?: boolean;
}

const staticValue = {
  input: {},
  meta: { touched: false, error: '', warning: '' }
};

export const ExportOptionRow: React.FC<ExportOptionRowProps> = (props) => {
  const {
    input = staticValue.input,
    meta = staticValue.meta,
    disabled = false,
    value,
    label,
    type = 'text',
    placeholder,
    onChange,
    component = (
      <Cube3TextField
        value={value || ''}
        placeholder={placeholder}
        fullWidth={true}
        onChange={onChange}
      />
    ),
    required = false,
    showError = false,

    classes
  } = props;

  return (
    <div>
      <MetadataFormFieldRow
        type={type}
        label={label}
        required={required}
        input={input}
        meta={meta}
        disabled={disabled}
        classes={classes}
        showError={showError}
        alwaysShowRequiredError={false}
      >
        {component}
      </MetadataFormFieldRow>
    </div>
  );
};
