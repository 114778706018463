import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';

import MenuListComposition from './MenuListComposition/MenuListComposition';
import IconButton from '../Buttons/IconButton';
import { iconElepsis } from '../icons/GeneralSVG';
import { UserInfo } from '../User/UserTypes';
import { useSurfaceClasses } from '../Surface/Surface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuRoot: {
      marginLeft: 'auto',
      justifySelf: 'flex-end'
    },
    menuIcon: {
      margin: `0px ${theme.customSpacing.margin[12]} 0px ${theme.customSpacing.margin[8]}`,
      opacity: 0
    },
    menuIconVisible: {
      opacity: 1
    },
    menuContents: {
      width: '225px',
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  })
);

interface ManageAccessUserProps {
  hovered?: boolean;
  userInfo: UserInfo;
  menuItems: React.ReactElement;
}

const ManageUserMenu = (props: ManageAccessUserProps) => {
  const classes = useStyles(props);
  const importedClasses = useSurfaceClasses({});
  const { hovered, menuItems } = props;

  return (
    <div className={classes.menuRoot}>
      <MenuListComposition
        toggleButton={(handleToggle, anchorEl, open) => (
          <IconButton
            data-pw={iconElepsis}
            path={iconElepsis}
            extraIconButtonProps={{
              ref: anchorEl as React.RefObject<HTMLButtonElement>
            }}
            colorVariant="ghost2"
            onClick={handleToggle}
            className={[
              classes.menuIcon,
              (open || hovered) && classes.menuIconVisible
            ].join(' ')}
          />
        )}
        menuItems={menuItems}
        PopoverProps={{
          PaperProps: {
            classes: {
              root: [importedClasses, classes.menuContents].join(' ')
            }
          }
        }}
      />
    </div>
  );
};

export default ManageUserMenu;
