/** helper that handles the logic for starting new array entries, e.g. when enter is pressed */
const defaultKeysListeners = ['Tab', ',', ';', 'Enter', ' '];
export const handleKeyDown = ({
  evt,
  inputValue,
  values,
  onClear,
  onAdd,
  onDelete,
  isValid = true,
  keysListeners = defaultKeysListeners
}) => {
  if (!inputValue && values.length === 0) return;

  if (evt.type === 'blur' && isValid) {
    evt.preventDefault();
    if (inputValue) {
      onClear();
      onAdd(inputValue);
      return;
    }
  }

  if (evt.key === 'Backspace' && !inputValue) {
    onDelete(values[values.length - 1]);
  } else if (keysListeners.includes(evt.key) && inputValue && isValid) {
    evt.preventDefault();
    if (inputValue) {
      onClear();
      onAdd(inputValue);
    }
  }
};
