import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import CustomRadioButton from '../../Buttons/RadioButtonStateless';
import { ExportOptionRow, ExportOptionRowProps } from './ExportOptionRow';

const useStyles = makeStyles(() =>
  createStyles({
    checkboxRoot: {
      marginRight: '10px'
    }
  })
);
interface Props extends ExportOptionRowProps {
  value: string;
  radios: any[];
  flexDirection?: 'row' | 'column';
  onChange: (e) => void;
}

export const ExportInputRadios = (props: Props) => {
  const { value, onChange, radios, flexDirection = 'column' } = props;
  const classes = useStyles();
  return (
    <ExportOptionRow
      {...props}
      component={
        <div style={{ display: 'flex', flexDirection }}>
          {radios.map((val) => (
            <CustomRadioButton
              key={val}
              checked={value?.toLowerCase() === val.toLowerCase()}
              text={val}
              onChange={() => onChange(val)}
              className={classes.checkboxRoot}
            />
          ))}
        </div>
      }
    />
  );
};
