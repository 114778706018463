import React, { useContext } from 'react';
// UI
import { makeStyles, createStyles, Theme, Snackbar } from '@material-ui/core';
import { Typography } from '../typography/Typography';
import ReactDOM from 'react-dom';
import { ContentContainerContext } from '../Layout/WorkspaceLayoutTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbarRoot: {
      position: 'absolute',
      backgroundColor: theme.customPalette.surface.base2
    },
    anchorOriginBottomCenter: {
      bottom: '60px'
    },
    snackbarContent: {
      justifyContent: 'center',
      backgroundColor: theme.customPalette.surface.base2
      // borderRadius: theme.surfaceCorners.circular
    },
    snackbarText: {
      textAlign: 'center'
    },
    snackbarPortal: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      overflow: 'hidden',
      pointerEvents: 'none'
    }
  })
);

interface SnackbarLibraryProps {
  disabled: boolean | string;
  shouldShowSnackbar?: boolean | 'error';
  isDragActive: boolean;
  message: string;
}

const SnackbarItem = ({ open, message, disabled }) => {
  const classes = useStyles({});
  return (
    <Snackbar
      classes={{
        root: classes.snackbarRoot,
        anchorOriginBottomCenter: classes.anchorOriginBottomCenter
      }}
      open={open}
      ContentProps={{
        classes: { root: classes.snackbarContent }
      }}
      message={
        <Typography
          typographyStyle={'body1'}
          color={disabled ? 'danger1' : 'contrast1'}
          className={classes.snackbarText}
        >
          {message}
        </Typography>
      }
      transitionDuration={{ enter: 0, exit: 0 }}
    />
  );
};

const SnackbarLibrary: React.FC<SnackbarLibraryProps> = ({
  shouldShowSnackbar,
  disabled,
  isDragActive,
  message
}) => {
  const container = useContext(ContentContainerContext);

  return (
    shouldShowSnackbar &&
    ReactDOM.createPortal(
      <div id="snackbarPortal">
        <SnackbarItem
          open={isDragActive}
          disabled={disabled}
          message={message}
        />
      </div>,
      container?.current || document.body
    )
  );
};

export default SnackbarLibrary;
