import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';
import { APIPrivileges } from '../../../../state/src/redux/components/Hooks/privileges';

export interface Privilege extends ResourceBase {
  key: APIPrivileges;
  display_name: string;
  description: string;
}

export const privilege: RawModel = {
  type: 'privilege',
  plural: 'privileges',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    key: 'string',
    display_name: 'string',
    description: 'string'
  },
  relationships: {}
};
