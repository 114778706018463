import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import React from 'react';

export const withMembers = Wrapped => props => {
  const [createResource, createResourceStatus] = useCreateResource__ALPHA({
    resourceType: 'invitation',
    actionDecorators: [
      addFormId('inviteMember', {
        formState: undefined,
        useRequestStatus: true,
        delegate: true
      })
    ],
    cacheInvalidator: () => [
      {
        type: 'workspace',
        id: props.currentWorkspaceId,
        relationship: 'invitations'
      }
    ]
  });
  return (
    <Wrapped
      {...props}
      addMembers={createResource}
      addMembersStatus={createResourceStatus}
    />
  );
};
