import React from 'react';
import { ModalWindowUI } from '@cube3/ui/src/Modal/ModalWindowUI';
import { useModalActions } from '../../Modals/modalActions';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import ModuleLoader from '@cube3/ui/src/Prefabs/Loaders/ModuleLoader';
import { useLegacyToken } from '@cube3/state/src/Hoocs/useLegacyToken';
import { BroadcastLegacyIframe } from '../prefabs/BroadcastLegacyIframe';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { GenericFailure } from '@cube3/ui/src/Prefabs/errors-handling/GenericFailure';

interface Props {
  modalContext: {
    assetIds: ResourceIdentifier[];
  };
}

export const ExportBroadcastLegacyModal: React.FunctionComponent<Props> = (
  props
) => {
  const modalActions = useModalActions();
  const { assetIds } = props.modalContext;

  const { token, loading, error, getToken } = useLegacyToken();

  const assetResourceIdentifiers = assetIds;
  // const assetResourceIdentifiers = useMemo(
  //   () => assetIds.map(a => ({ id: a, type: 'asset' as 'asset' })),
  //   [assetIds]
  // );

  const assets = useResource__ALPHA({
    resourceIdentifiers: assetResourceIdentifiers
  });

  return (
    <ModalWindowUI
      title="Export to TV Broadcast"
      onCloseEvent={modalActions.closeAllModals}
    >
      {loading && <ModuleLoader />}
      {token && assets.resources && (
        <BroadcastLegacyIframe assetIds={assetIds} token={token} />
      )}

      <GenericFailure
        message="Could not authenticate"
        onRetry={getToken}
        retryLabel="Retry"
        show={!!error}
      />
    </ModalWindowUI>
  );
};
