import { useMemo, useRef } from 'react';
import { RecipientSuggestion, UseSuggestionsResponse } from '../types';
import {
  useEmailSuggestionsBase,
  useExtendedRecipients
} from '../../../../ShareLink/hooks/useEmailSuggestions';
import { useThrottledQuery } from '../../../../ShareLink/hooks/useThrottledQuery';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

const emptyArray = [];

export const useContractRecipientSuggestions = (
  config
): UseSuggestionsResponse<RecipientSuggestion> => {
  const { query: directQuery, selected } = config;

  const query = useThrottledQuery(directQuery);

  const recipients = useEmailSuggestionsBase(query);

  const { recipients: extended, loading: extendedLoading } =
    useExtendedRecipients(recipients.resources);

  const typed = !!directQuery;
  const suggestions =
    useMemo((): UseSuggestionsResponse<RecipientSuggestion> => {
      const loading =
        extendedLoading ||
        recipients.loading ||
        (typed && !recipients.resources);

      if (!extended) {
        return [emptyArray, emptyArray, loading];
      }

      const sugs: RecipientSuggestion[] = extended
        .filter((r, idx, arr) => arr.findIndex((r2) => r2.id === r.id) === idx)
        .map((r) => {
          return {
            ...r,
            imageType: r.userId ? 'avatar' : undefined
          };
        }) as RecipientSuggestion[];

      return [
        sugs
          .filter((s) => !selected.some((sel) => sel.id === s.id))
          .filter((s) => !query || s.email_address.includes(query))
          .concat(sugs.filter((s) => selected.some((sel) => sel.id === s.id)))
          .slice(0, 8),
        sugs.filter((s) => selected.some((sel) => sel.id === s.id)),
        loading
      ];
    }, [
      recipients.resources,
      extended,
      extendedLoading,
      selected,
      query,
      recipients.loading,
      typed
    ]);

  return suggestions;
};
