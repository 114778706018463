import React, { useCallback } from 'react';

import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { clearFields, getFormValues } from 'redux-form';
import { ContractAssetsPicker } from './variants/ContractAssetsPicker';

import { Props as MultipleResourcePickerProps } from './MultipleResourcePicker';
import { ContractProjectsPicker } from './variants/ContractProjectsPicker';
import { ContractRecipientsPicker } from './variants/ContractRecipientsPicker';
import { useDispatch } from 'react-redux';
import { PublicResource } from '@cube3/common/model/resource-types';

const emptyArray = [];

interface Props extends MultipleResourcePickerProps {
  variant: 'contract-assets' | 'contract-projects' | 'contract-recipients';
  lockedResources?: PublicResource[];
}

export const MultipleResourcePickerSmart: React.FunctionComponent<Props> = (
  props
) => {
  const { variant, meta, fields, lockedResources } = props;

  const { form } = meta;
  const query = useTypedSelector((state) => {
    return (
      getFormValues(form)(state) &&
      getFormValues(form)(state)[`${fields.name}_inputValue`]
    );
  });

  const dispatch = useDispatch();
  const clearInput = useCallback(
    () => dispatch(clearFields(form, true, true, `${fields.name}_inputValue`)),
    [fields.name, form, dispatch]
  );

  const selected = fields.getAll() ? fields.getAll() : emptyArray;

  switch (variant) {
    case 'contract-assets':
      return (
        <ContractAssetsPicker
          {...props}
          selected={selected}
          query={query}
          clearInput={clearInput}
          lockedResources={lockedResources}
        />
      );
    case 'contract-projects':
      return (
        <ContractProjectsPicker
          {...props}
          selected={selected}
          query={query}
          clearInput={clearInput}
          lockedResources={lockedResources}
        />
      );
    case 'contract-recipients':
      return (
        <ContractRecipientsPicker
          {...props}
          form={form}
          selected={selected}
          query={query}
          clearInput={clearInput}
        />
      );
    default:
      return <div>No valid picker variant selected</div>;
  }
};
