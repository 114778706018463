import { createStyles, makeStyles } from '@material-ui/core';
import CheckBoxStateless from '../../../Buttons/CheckBoxStateless';
import React, { useCallback } from 'react';
import { Divider } from '../../../Surface/Divider';
import MaybeTooltip from '../../../Tooltip/MaybeTooltip';
import { generateFileNameTooltip } from '../../../library/Downloads/DownloadItemRowUI';

const useStyles = makeStyles(() =>
  createStyles({
    formatRow: {
      paddingTop: 8,
      paddingBottom: 4
    }
  })
);

const ShareDownloadCheckBoxWithTooltip = ({
  preset,
  group,
  selectedFormats,
  setSelectedFormats,
  showDivider = true,
  rootClass = ''
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (group: string, preset) => {
      const isChecked = !!selectedFormats[group]?.find(
        (n) => n.id === preset.id
      );

      if (isChecked) {
        setSelectedFormats((prev) => ({
          ...prev,
          [group]: prev[group].filter((v) => v.id !== preset.id)
        }));
      } else {
        setSelectedFormats((prev) => {
          if (!prev[group]) {
            return {
              ...prev,
              [group]: [preset]
            };
          }
          return {
            ...prev,
            [group]: [...prev[group], preset]
          };
        });
      }
    },
    [selectedFormats, setSelectedFormats]
  );
  return (
    <MaybeTooltip
      key={preset.id}
      disabled={group === 'default'}
      title={generateFileNameTooltip(preset)}
      placement={'bottom'}
    >
      <div style={{ width: '100%' }} className={rootClass}>
        <CheckBoxStateless
          marginCollapse={'left'}
          classes={{ root: classes.formatRow }}
          checked={!!selectedFormats[group]?.find((f) => f?.id === preset.id)}
          text={preset.attributes.display_name}
          typographyProps={{
            typographyStyle: 'body2',
            color: 'contrast1'
          }}
          onChange={() => handleChange(group, preset)}
        />
        {showDivider && <Divider />}
      </div>
    </MaybeTooltip>
  );
};

export default ShareDownloadCheckBoxWithTooltip;
