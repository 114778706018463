import { useEffect, useMemo, useRef, useState } from 'react';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import projectImage from '@cube3/ui/src/assets/images/Library/project_new.svg?url';
import { UseSuggestionsResponse, ProjectSuggestion } from '../types';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { uuidParser } from '@cube3/state/src/utils/uuidparser';

const emptyArray = [];

export const useContractProjectSuggestions = (
  config
): UseSuggestionsResponse<ProjectSuggestion> => {
  const { query: directQuery, selected } = config;

  const [query, setQuery] = useState();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  // debounce the input a bit (until meili is ready)
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setQuery(directQuery);
    }, 300);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [directQuery]);

  const suggestionType = 'project';

  const [workspaceId] = useCurrentWorkspace();

  /** overfetch initially */
  const pageSize = 30;

  /* start with one page, load more if needed */
  const [pages, setPages] = useState([1]);

  /* if the query changes reset the number of pages to retrieve */
  useEffect(() => {
    setPages([1]);
  }, [query]);

  /* TODO: replace after meili search has landed */
  const params = useMemo(() => {
    return {
      display_name: query,
      search: {
        query: suggestionType, // folder, video etc...
        searchInId: undefined, // id of folder
        searchType: undefined // metadata of name
      },
      page: { size: pageSize }
    };
  }, [query, suggestionType]);

  // get the search results.
  // TODO: needs small refactor after meili search lands
  const searchResults = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: query ? workspaceId : undefined,
    edgeType: 'search-result',
    edgeLabel: 'search-results',
    params,
    pages
  });

  // for the visible suggestions only, we load the associated project,
  // so we can check if it is archived (and should be disabled)
  const preloadProjects = useMemo(() => {
    return (searchResults.resources || [])
      .map(
        (s) =>
          ({
            id: uuidParser(s.resource_id),
            type: 'project'
          } as ResourceIdentifier<'project'>)
      )
      .filter((s) => !selected.filter((sel) => sel.id === s.id).length) // filter out the already selected projects, then only show 8 suggestion
      .slice(0, 8);
  }, [searchResults.resources, selected]);

  const projects = useResource__ALPHA({ resourceIdentifiers: preloadProjects });

  const [projectsLoading, setProjectsLoading] = useState(false);

  // wait for the loaded projects to match the preloadProjects array
  // First we set it to loading when the preloadProjects array changes
  useEffect(() => {
    setProjectsLoading(true);
  }, [preloadProjects]);

  // Then when the loaded projects change, we check if they now match the preloadProjects array
  useEffect(() => {
    if (projectsAreLoaded(projects.resources, preloadProjects)) {
      setProjectsLoading(false);
    }
  }, [projects.resources, preloadProjects]);

  const typed = !!directQuery;

  /* parse the results and filter out those that are already selected in ui */
  const suggestions = useMemo((): UseSuggestionsResponse<ProjectSuggestion> => {
    const loading =
      searchResults.loading ||
      (typed && !searchResults.resources) ||
      projects.loading ||
      projectsLoading;

    if (!searchResults.resources) {
      return [emptyArray, emptyArray, loading];
    }

    // transform into shape accepted by ui components
    const sugs = searchResults.resources.map((c) => {
      const id = uuidParser(c.resource_id);
      return {
        display_name: c.display_name,
        display_image: c.display_image || projectImage,
        id: id,
        type: c.resource_type,
        imageType: 'asset' as const
      };
    });

    // returns two arrays:
    // - first is suggestions
    // - second is a list of suggestions that should be visible but not allowed
    return [
      // suggestions
      sugs.filter((s) => !selected.find((sel) => sel.id === s.id)).slice(0, 8), // filter out the already selected projects, then only show 8 suggestion
      // disabled suggestions
      sugs.filter((s) =>
        projects.resources?.find(
          (p) => p.id === s.id && p.project_status === 'ProjectStatusArchived' // archived projects are read-only and cannot be added or removed
        )
      ),
      loading
    ];
  }, [
    typed,
    selected,
    searchResults.resources,
    searchResults.loading,
    projects.resources,
    projects.loading,
    projectsLoading
  ]);

  useEffect(() => {
    if (
      searchResults.resources?.length &&
      searchResults.resources.length % pageSize === 0 &&
      Math.floor(searchResults.resources.length / pageSize) === pages.length
    ) {
      if (suggestions[0].length === 0) {
        setPages([...pages, pages.length + 1]);
      }
    }
  }, [pages, setPages, searchResults.resources, suggestions]);

  return suggestions;
};

// compares if two arrays with resource identifiers match
const projectsAreLoaded = (p1, p2) => {
  return (
    p1 &&
    p2 &&
    p1.length === p2.length &&
    !p1.filter((p, idx) => {
      return p2[idx].id !== p.id;
    }).length
  );
};
