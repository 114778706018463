// function to handle key down on input field

import { useCallback } from 'react';

export const useKeyDownHandler = (
  enabledSuggestions,
  disabledSuggestionKeys,
  addItemToSelectionArray,
  removeItemFromSelectionArray,
  inputValue,
  clearInputField,
  clearOn,
  selectedSuggestions,
  newSuggestionPrimaryKey = 'primaryText',
  keysListenersArrayToAddNewSuggestions = ['Tab', ',', ';', 'Enter'],
  eventTypeArrayToAddNewSuggestions = ['blur'],
  allowAddingOfNewSuggestions = true,
  lockedResources
) =>
  useCallback(
    ({
      event,
      isOpen,
      openMenu,
      selectHighlightedItem,
      highlightedIndex,
      reset,
      valid = false,
      filteredSuggestions
    }) => {
      /*   check if backspace is pressed and is so delete last item van selectionsArray  */
      if (event.key === 'Backspace' && !event.target.value) {
        /* check if is deleting a locked item */
        if (lockedResources && lockedResources.length > 0) {
          const isLockedItem = lockedResources.find(
            item =>
              item.id === selectedSuggestions[selectedSuggestions.length - 1].id
          );
          if (isLockedItem) {
            return;
          }
        }
        removeItemFromSelectionArray(selectedSuggestions.length - 1);

        /*   check if one of the following keys are pressed or if blur event is fired */
      } else if (
        keysListenersArrayToAddNewSuggestions.includes(event.key) ||
        eventTypeArrayToAddNewSuggestions.includes(event.type)
      ) {
        if (event.key) {
          event.preventDefault();
        }
        /*   
      check if there is a highlighted item and check if there are still suggestions. 
      If true add the highlightedItem and clear input field.
      */
        if (
          isOpen &&
          highlightedIndex !== null &&
          enabledSuggestions &&
          keysListenersArrayToAddNewSuggestions.includes(event.key)
        ) {
          /*   
      check if the highlighted item is not already selected or excluded
      */
          if (
            disabledSuggestionKeys.indexOf(
              filteredSuggestions[highlightedIndex][newSuggestionPrimaryKey]
            ) === -1
          ) {
            selectHighlightedItem();
            addItemToSelectionArray(filteredSuggestions[highlightedIndex]);

            if (clearInputField && clearOn.includes('add-suggestion')) {
              clearInputField();
            } else {
              window.getSelection().empty();
              event.target.focus();
              event.target.select();
            }

            reset();
          }

          /*   if input is valid and it is allowed to add new items, 
        then add the new item to the selectionsArray, and clear input field  */
        } else if (
          (valid && allowAddingOfNewSuggestions && inputValue) ||
          (eventTypeArrayToAddNewSuggestions.includes(event.type) &&
            valid &&
            allowAddingOfNewSuggestions &&
            inputValue)
        ) {
          addItemToSelectionArray({
            [newSuggestionPrimaryKey]: inputValue,
            value: inputValue
          });
          if (clearInputField && clearOn.includes('add-new')) {
            clearInputField();
          }
          reset();
        }
      }

      if (
        event.type === 'blur' &&
        clearInputField &&
        clearOn.includes('blur')
      ) {
        clearInputField();
      }
    },
    [
      enabledSuggestions,
      disabledSuggestionKeys,
      addItemToSelectionArray,
      allowAddingOfNewSuggestions,
      clearInputField,
      clearOn,
      eventTypeArrayToAddNewSuggestions,
      inputValue,
      keysListenersArrayToAddNewSuggestions,
      newSuggestionPrimaryKey,
      removeItemFromSelectionArray,
      selectedSuggestions,
      lockedResources
    ]
  );

/*  function to handle click on suggestion item.  */

export const useClickHandler = (
  addItemToSelectionArray,
  clearInputField,
  clearOn
) =>
  useCallback(
    ({ openMenu, getItemProps, index, suggestion, reset }) => {
      /*   add item to array  */
      addItemToSelectionArray(suggestion);
      getItemProps({ item: suggestion, index }).onClick(suggestion);

      /*   clear input fields  */
      reset();
      clearInputField &&
        clearOn.includes('add-suggestion') &&
        clearInputField();

      /*   open menu  */
      openMenu();
    },
    [addItemToSelectionArray, clearInputField, clearOn]
  );
