import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import { Typography } from '../typography/Typography';
import { ThumbnailRatioType } from './Thumbnail';
import { FillVariant } from './Thumbnail-image-container';
import { ratios } from './ThumbnailVariants';

interface ThumbnailGroupProps {
  thumbnails: string[];
  ratio: ThumbnailRatioType;
  width?: number;
  fillVariant?: FillVariant;
  cornerRadius?: 'lowEmphasis' | 'mediumEmphasis' | 'highEmphasis' | 'none';
}

const defaultWidth = 160;
const defaultPadding = 8;
const maxColumn = 3;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({
      width = defaultWidth,
      height,
      cornerRadius = 'lowEmphasis'
    }: any) => ({
      display: 'flex',
      borderRadius: theme.surfaceCorners[cornerRadius],
      width: width,
      height: height,
      gap: `${defaultPadding}px`,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& > img': {
        borderRadius: theme.surfaceCorners[cornerRadius],
        objectFit: 'contain',
        maxHeight: '100%',
        ...theme.customMixins.surfaceShadows({ elevation: 10 })
      }
    }),
    dark: {
      background: theme.customPalette.backgroundAccent.blackAccent2
    },
    light: {
      background: theme.customPalette.backgroundAccent.whiteAccent1
    },
    main: {
      background: theme.customPalette.backgroundAccent.blackAccent1
    },
    overlay: ({ cornerRadius }) => ({
      position: 'absolute',
      height: '100%',
      top: 0,
      right: 0,
      background: theme.customPalette.backgroundAccent.blackAccent2,
      borderRadius: theme.surfaceCorners[cornerRadius]
    }),
    text: ({ width = defaultWidth }) => ({
      fontSize: `${20 * (width / defaultWidth)}px`,
      position: 'absolute',
      top: '50%',
      right: '50%',
      transform: 'translate(50%,-50%)'
    })
  })
);

export const ThumbnailGroup = (props: ThumbnailGroupProps) => {
  const {
    thumbnails,
    ratio,
    width = defaultWidth,
    fillVariant = 'dark'
  } = props;

  const columnsCount = Math.min(maxColumn, thumbnails.length);
  const classes = useStyles({
    ...props,
    height: width / ratios[ratio]
  });

  return (
    <div className={`${classes.root} ${classes[fillVariant]}`}>
      {thumbnails.slice(0, maxColumn).map((src, index) => (
        <img
          key={index}
          src={src}
          alt="thumbnails"
          style={{
            maxWidth:
              (width - defaultPadding * (columnsCount - 1)) / columnsCount
          }}
        />
      ))}
      {thumbnails.length > maxColumn && (
        <div
          className={classes.overlay}
          style={{
            width: (width - defaultPadding * (maxColumn - 1)) / maxColumn
          }}
        >
          <Typography
            color="contrast1"
            typographyStyle={'heading1'}
            className={classes.text}
          >
            +{thumbnails.length - maxColumn}
          </Typography>
        </div>
      )}
    </div>
  );
};
