import { statuses } from '../../../ducks/request-status';

export const shouldRetrieve = ({
  strategy,
  mounting,
  resourceList,
  requestStatus
}) => {
  const busy =
    requestStatus &&
    ![statuses.INVALIDATED, statuses.SUCCESS, statuses.FAILED].includes(
      requestStatus
    );

  const invalid = requestStatus === statuses.INVALIDATED;

  const requiresFetch =
    invalid ||
    (requestStatus !== statuses.SUCCESS &&
      requestStatus !== statuses.FAILED &&
      !resourceList);

  switch (strategy) {
    case 'cache-only':
      // never generate an API call
      return false;
    case 'prefer-cache':
      // generate an API call only if there are no resources or they are invalid
      if (resourceList && !invalid) {
        return false;
      }
      break;
    case 'prefer-exact':
      // only use cache if it's origin is exactly the API request that would be generated
      return !busy && (invalid || requestStatus !== statuses.SUCCESS);

    case 'fetch-on-mount':
      // always generate an API request on mount (if config valid)
      if (mounting && !busy) {
        return true;
      }
      break;
    case 'fetch-on-update':
      // always generate an API request when hook is called (if config valid)

      if (!busy) {
        return true;
      }
      break;
    case 'fetch-when-needed':
    default:
      // generate an API request when there is no cache or cache is invalid, or request status is not successful
      return !busy && requiresFetch;
  }
  return !busy && requiresFetch;
};
