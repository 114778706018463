import { Related } from '../../resource-types';
import { RawModel } from '../schema';

export interface ValidationError {
  display_name: string;
  description: string;
}

/** TVad: represents the result of the check */
export interface Validation {
  type: 'validation';
  id: string;
  attributes: {
    created_at: string;
    updated_at: string;
    finished_at: string;
    status: 'pending' | 'analyzing' | 'done' | 'failed' | 'server_error';
    errors: ValidationError[];
    warnings: ValidationError[];
  };
  relationships: {
    asset: Related<'asset'>;
    validator: Related<'validator'>;
  };
}

export const validation: RawModel = {
  type: 'validation',
  plural: 'validations',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    finished_at: 'string',
    status: 'string'
  },
  relationships: {
    asset: {
      type: 'asset',
      binding: ['many', 'many'],
      required: true
    },
    validator: {
      type: 'validator',
      binding: ['many', 'many'],
      required: true
    }
  }
};
