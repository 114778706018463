import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { UseStyles } from '../UseStylesHelper/UseStylesHelper';
import Button from '../Buttons/Cube3Button';
import { Typography } from '../typography/Typography';
import Surface from '../Surface/Surface';
import { Divider } from '../Surface/Divider';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '75px',
            width: '100%',
            alignItems: 'center',
        },
        contents: {
            margin: 'auto',
            marginTop: '24px',
            marginBottom: '24px'
        },
        txt: {
            marginBottom: '0px'
        },
        button: {
            marginLeft: theme.customSpacing.margin[12]
        },
    }));

interface Properties extends UseStyles<typeof useStyles> {
    onClickReject: () => void;
    onClickAccept: () => void;
    cookiesPageLinkcomponent: JSX.Element;
}

export const CookiePolicyUpdatedUI: React.FunctionComponent<Properties> = props => {

    const classes = useStyles(props);

    const {
        onClickReject,
        onClickAccept,
        cookiesPageLinkcomponent
    } = props;

    return (
        <>
            <Divider background={'base1'} color={'contrast2'} />
            <Surface background={'base1'} className={classes.root}>

                <div className={classes.contents}>
                    {/* State pricay policy */}
                    <Typography
                        className={classes.txt}
                        typographyStyle={'body2'}
                        typographyProps={{ gutterBottom: true }}
                    >
                        Our cookie policy has been updated. Please agree to the new term, or edit you settings on our{' '} {cookiesPageLinkcomponent}
                        <Button className={classes.button} onClick={() => onClickReject()} colorVariant={'ghost1'} text="Revoke consent" key="reject" />
                        <Button className={classes.button} onClick={() => onClickAccept()} colorVariant={'filled1'} text="Ok" key="ok" />
                    </Typography>
                </div>
            </Surface>
        </>
    );
}