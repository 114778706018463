import * as React from 'react';
import { Field } from 'redux-form';
import { makeStyles, Theme } from '@material-ui/core';

/* Import components */
import { Typography } from '../../../typography/Typography';
import Time from '../../../human/Time';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { EmailMessageBoxFormProps } from '../../Forms/EmailMessageBox';
import { VerifyType } from '@cube3/common/model/resource-types';
import {
  VerfiyUserFieldsetProps,
  VerifyUserFieldset
} from '../../shareLink/Forms/VerifyUserFieldset';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  expirationContainer: {},
  expiryText: {
    margin: `${theme.customSpacing.margin[8]} 0px 0px 0px`
  },
  setExpirationDate: {
    marginBottom: theme.customSpacing.margin[8]
  },
  label: {
    marginBottom: '12px'
  }
}));

// import this interface in your component when you would like to get the redux form values.
export interface FileRequestFormProps
  extends EmailMessageBoxFormProps,
    VerfiyUserFieldsetProps {
  expiryDate?: string;
}

interface PublicProps {
  expiryDate: string;
  verifyUser: boolean;
  verifyType: VerifyType;
  allowRequestAccess: boolean;
  hasVerifyUserFeature?: boolean;
  /** The absolute maximum date that can be chosen by the user, defaults to `6 months` from today, local time */
  maxDate?: string;
}

type Properties = FileRequestFormProps & PublicProps;

const FileRequestLinkSettingsFormC: React.FC<Properties> = (props) => {
  const {
    verifyUser,
    verifyType,
    allowRequestAccess,
    hasVerifyUserFeature,
    expiryDate,
    maxDate = moment().add(6, 'month')
  } = props;

  const classes = useStyles();

  const isValidDate: boolean = !Number.isNaN(Date.parse(expiryDate));

  return (
    <form className={classes.form}>
      <div className={classes.expirationContainer}>
        <VerifyUserFieldset
          verifyUser={verifyUser}
          verifyType={verifyType}
          allowRequestAccess={allowRequestAccess}
          hasVerifyUserFeature={hasVerifyUserFeature}
        />

        <Typography
          className={classes.label}
          typographyStyle="body2"
          color="contrast1"
        >
          Deadline
        </Typography>

        <Field
          name="expiryDate"
          type="date"
          format={(date) => (date ? moment(date) : undefined)}
          component={(fieldProps) => (
            <C3DatePicker
              {...fieldProps}
              autoOk={true}
              initialFocusedDate={moment(expiryDate)}
              minDate={moment().add(1, 'day')}
              maxDate={maxDate}
              inputVariant="outlined"
              fullWidth={true}
              disablePast={true}
              variant="inline"
            />
          )}
        />

        {isValidDate && expiryDate && (
          <Typography
            component="span"
            typographyStyle={'body2'}
            className={classes.expiryText}
            color="contrast2"
          >
            {`This link ${
              new Date(expiryDate) < new Date() ? 'expired ' : 'will expire '
            }`}
            <Time
              date={expiryDate}
              fullForm={false}
              toolTip={true}
              placement="bottom"
              relativeTime={true}
            />
          </Typography>
        )}
      </div>
    </form>
  );
};

const C3DatePicker = (props) => {
  const {
    input,
    initialFocusedDate,
    inputVariant,
    autoOk,
    disablePast,
    minDate,
    fullWidth,
    maxDate
  } = props;

  const pickerProps = {
    initialFocusedDate,
    inputVariant,
    disablePast,
    autoOk,
    minDate,
    fullWidth
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        key="expiration-date"
        {...pickerProps}
        value={input.value || initialFocusedDate}
        maxDate={maxDate}
        onChange={(moment) => input.onChange(moment.toDate())}
      />
    </MuiPickersUtilsProvider>
  );
};

/**
 * @summary Form used in the file request settings modal and the new file request modal, based on `LinkSettingsForm` for New Share Link.
 * @author Simon
 */
const FileRequestLinkSettingsForm =
  FileRequestLinkSettingsFormC as React.ComponentType<PublicProps>;

export { FileRequestLinkSettingsForm };
