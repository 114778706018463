import Color from 'color';

const LEGACY_COLORS = {
  '#f0b4fa': 'violet',
  '#90d3fb': 'blue',
  '#b7f058': 'green',
  '#ffef5a': 'yellow',
  '#ff888b': 'red'
};

export const TAG_COLORS = {
  RED: '#F17884',
  ORANGE: '#F59E6C',
  YELLOW: '#FFE887',
  GREEN: '#6AF0A4',
  BLUE: '#6EB4F8',
  VIOLET: '#B587E2',
  GHOST: 'transparent',
  GREY: '#c3c3c3',
  INDIGO: '#5682e6',
  WHITE: '#FFFFFF',
  BLACK: '#000000'
} as const;

const isHexColor = /[abcdef0-9]{6}/;

const cache = {};

const tagCache = Object.keys(TAG_COLORS).reduce((c, k) => {
  c[k] = Color(TAG_COLORS[k]).object();
  return c;
}, {});

export const lookupColor = (color) => {
  if (cache[color]) {
    return cache[color];
  }

  if (color === '') {
    return 'ghost';
  }
  if (!isHexColor.test(color)) {
    return color;
  }

  if (LEGACY_COLORS[`#${color}`]) {
    return LEGACY_COLORS[`#${color}`];
  }

  const c = Color(`#${color}`).object();

  const closest = Object.keys(TAG_COLORS).reduce(
    (closest: { dist: number; k: string }, k) => {
      const tc = tagCache[k];
      const dist =
        Math.pow(tc.r - c.r, 2) +
        Math.pow(tc.g - c.g, 2) +
        Math.pow(tc.b - c.b, 2);

      if (!closest || dist < closest.dist) {
        return { dist, k };
      }

      return closest;
    },
    null
  );

  const colorString = closest.k.toLowerCase();

  cache[color] = colorString;

  return colorString;
};
