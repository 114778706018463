import React from 'react';

import { EditSettingModal } from './EditSettingsModal';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback } from 'react';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useEffect } from 'react';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { makeValidator } from '../../../../../../forms/helpers/validators';
import { capitalize } from '@material-ui/core';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

interface Props {
  modalContext: 'first' | 'second' | 'third';
}

const validator = (type: string, min: number, max: number) => {
  const callback = (input) => {
    if (Number.isNaN(input)) return 'Field can not be empty';
    switch (type) {
      case 'first':
        return input <= min
          ? 'Should be greater than the second reminder value'
          : input > 366
          ? 'Should be less than 1 year'
          : undefined;

      case 'second':
        return input <= min
          ? 'Should be greater than the third reminder value'
          : input >= max
          ? 'Should be lower than the first reminder value'
          : undefined;
      case 'third':
        return input > max
          ? 'Should be lower than the second reminder value'
          : input < 1
          ? 'Should be 1 day or more'
          : undefined;
      default:
        break;
    }
  };
  return makeValidator(callback);
};

export const EditContractExpirationModal: React.FC<Props> = (props) => {
  const attri = props.modalContext;
  const reminder = `${attri}_contract_reminder` as const;
  const workspace = useCurrentWorkspace()[1];
  const formId = `edit_default_contract_expiration_${reminder}`;

  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    actionDecorators: [addFormId(formId, { delegate: true })],
    cacheInvalidator: null
  });

  const { previousModal } = useModalActions();

  const handleSave = useCallback(
    (data) => {
      mutate({
        type: 'workspace',
        id: workspace?.id,
        ...data
      });
    },
    [mutate, workspace]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  const min =
    attri === 'first'
      ? workspace.second_contract_reminder
      : attri === 'second'
      ? workspace.third_contract_reminder
      : 1;
  const max =
    attri === 'first'
      ? 366
      : attri === 'second'
      ? workspace.first_contract_reminder
      : workspace.second_contract_reminder;
  return (
    <EditSettingModal
      onSave={handleSave}
      loading={!workspace || mutateStatus === statuses.IN_FLIGHT}
      form={formId}
      initialValues={{
        [reminder]: workspace[reminder]
      }}
      config={{
        fields: [
          {
            type: 'number',
            name: reminder,
            value_type: 'number',
            validate: validator(attri, min, max),
            label: 'Days before expiration',
            parse: (val) => parseInt(val.toString())
          }
        ]
      }}
      title={`${capitalize(attri)} reminder`}
    />
  );
};
