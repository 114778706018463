import React from 'react';

import Button from '@cube3/ui/src/Buttons/Button';
import { useModalActions } from '../../../../app/layout/Modals/modalActions';
import Chip from '@cube3/ui/src/chips/Chip';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

export const AttachmentsField = (props) => {
  const modalActions = useModalActions();

  const { input, metadataForResource } = props;

  const attachments = useResource__ALPHA<'asset'>({
    resourceIdentifiers: input.value?.map((a) => ({ type: 'asset', id: a }))
  });

  if (input.value?.length && attachments.status !== statuses.SUCCESS) {
    return <Shimmer height={10} weight="light" />;
  }

  return (
    <div>
      <div>
        {attachments.resources?.map((asset, idx) => {
          return (
            <Chip
              key={asset.id}
              input={{ value: asset.display_name, id: asset.id }}
              removeItem={(value, data) => {
                input.onChange(input.value.filter((v) => v !== data.id));
              }}
              disabled={false}
              onClick={(data) => {
                modalActions.openModal(
                  'attachment_preview',
                  {
                    assetId: data.id
                  },
                  false
                );
              }}
            />
          );
        })}
      </div>
      <Button
        colorVariant="ghost1"
        text="Upload files..."
        onClick={() => {
          input.onFocus();
          modalActions.openModal(
            'attachments_field',
            {
              input: input,
              metadataForResource
            },
            false
          );
        }}
      />
    </div>
  );
};
