import * as React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  StyledComponentProps
} from '@material-ui/core';
import MaterialUITypography, {
  TypographyProps as MaterialUITypographyProps
} from '@material-ui/core/Typography';
import { useClassName } from '../../utils/useClassName';

const VARIANTS = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'body1',
  'body2',
  'body3',
  'link1',
  'link2'
] as const;

export type variants = typeof VARIANTS[number];

const COLORS = [
  'selected',
  'warning',
  'contrast1',
  'contrast2',
  'contrast3',
  'brand1',
  'brand2',
  'danger1',
  'success'
] as const;

export type colors = typeof COLORS[number];

const SURFACES = ['onBase', 'onBrand', 'onDanger'] as const;

export type surfaces = typeof SURFACES[number];

/** Check in custom-theme object if the color exists. If not, returns `pink` for debugging */
const getColorForTxt = (
  surface: surfaces,
  color: colors,
  theme: Theme
): string => {
  const baseKey = theme.customPalette.text[surface];
  let foundColor = 'pink'; // fallback color, for easy recognition of bad keys.

  if (baseKey) {
    const targetColor = baseKey[color];

    if (targetColor) {
      foundColor = targetColor;
    } else {
      console.warn(
        'Error in Typography Requested color ',
        color,
        ' for surface ',
        surface,
        ' is not known in theme'
      );
    }
  } else {
    console.warn(
      'Error in Typograpy: Attempted to access a background ',
      surface,
      ' which is not known in theme'
    );
  }

  return foundColor;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        userSelect: 'text'
      },
      ...VARIANTS.reduce((acc, variant) => {
        acc[`variant-${variant}`] = { ...theme.typographyStyles[variant] };
        return acc;
      }, {}),
      ...COLORS.reduce((acc, color) => {
        SURFACES.forEach(surface => {
          acc[`color-${surface}-${color}`] = {
            color: getColorForTxt(surface, color, theme)
          };
        });
        return acc;
      }, {})
    }),
  { name: 'C3Typography' }
);

interface Properties {
  /** The `background` of the `Surface` the component `Typography` is used on, defaults to `onBase` */
  surface?: surfaces;

  /** Defaults to `body2`   */
  typographyStyle?: variants;
  variant?: variants;

  /** Color applied to the text. Defaults to `contrast1`. The color also changes based on property `surface` */
  color?: colors;

  className?: string;

  /** Component tag used, defaults to `div` */
  /* eslint-disable-next-line */
  component?: any;

  /** Props spread on the Material UI typography component. */
  typographyProps?: MaterialUITypographyProps;

  /** Display style of Material UI typography component   */
  display?: 'initial' | 'inline' | 'block';
  gutter?: boolean;
}

/**
 * @description: Typography for Cube based on the Material UI component.
 */
export const Typography: React.FunctionComponent<React.PropsWithChildren<
  Properties
>> = React.memo(props => {
  const {
    className,
    component = 'div',
    typographyProps,
    display = 'block',
    gutter = false
  } = props;

  const {
    variant = props.typographyStyle || 'body2',
    color = 'contrast1',
    surface = 'onBase'
  } = props;

  const classes = useStyles({
    typographyStyle: variant,
    variant,
    color,
    surface
  });

  const cn = useClassName(
    className,
    classes.root,
    classes[`variant-${variant}`],
    classes[`color-${surface}-${color}`]
  );

  return (
    <MaterialUITypography
      component={component}
      display={display}
      className={cn}
      gutterBottom={gutter}
      {...typographyProps}
    >
      {props.children}
    </MaterialUITypography>
  );
});

export type TypographyProps = Properties & StyledComponentProps;
