import React from 'react';
import MaybeTooltip, { TooltipReceivedProps } from '../../Tooltip/MaybeTooltip';
import { Cube3ButtonProps } from '../../Buttons/Cube3Button';
import MenuItem from '../../Buttons/MenuItem';

export interface MenuItemType extends Cube3ButtonProps, TooltipReceivedProps {
  disableTooltip?: boolean;
  tooltip?: string;
  disableMenu?: boolean;
  hidden?: boolean;
  text: string;
  onClick: () => void;
}
interface Props {
  menuItems: MenuItemType[];
}
export const MenuItemsRenderer = ({ menuItems }: Props) => {
  return menuItems?.length === 0 ? null : (
    <>
      {menuItems.map((item, index) =>
        !item.hidden ? (
          <MaybeTooltip
            key={index}
            disabled={item.disableTooltip || !item.disableMenu}
            title={item.tooltip}
            placement={item.placement || 'bottom-end'}
          >
            <MenuItem
              {...item}
              disabled={item.disableMenu}
              colorVariant={item.colorVariant || 'ghost2'}
              text={item.text}
              onClick={item.onClick}
            />
          </MaybeTooltip>
        ) : null
      )}
    </>
  );
};
