/**
 *  supossedly this fixes an issue, but I'm not sure what
 */
export function fixBackgroundClick(
  e: React.SyntheticEvent,
  clientX: number,
  clientY: number,
  handleClearAll: Function
) {
  const elementsArray = document.elementsFromPoint(
    clientX,
    clientY
  ) as HTMLElement[];

  const element = elementsArray.filter(
    node => node.dataset.item === 'list-item'
  );

  if (element.length === 1) {
    handleClearAll();
  }
}
