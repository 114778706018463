import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import MuiTabs, { TabsProps } from '@material-ui/core/Tabs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsIndicator: {
      backgroundColor: theme.customPalette.secondary.main,
      bottom: 20
    },

    flexContainer: {
      marginBottom: 20
    },

    tabsIndicatorDense: {
      backgroundColor: theme.customPalette.secondary.main,
      bottom: 0
    },

    flexContainerDense: {
      marginBottom: 0
    }
  })
);

interface Props extends TabsProps {
  gutter?: boolean;
}

export const Tabs: React.FunctionComponent<Props> = props => {
  const { onChange, gutter = true, ...rest } = props;
  const classes = useStyles();

  return (
    <MuiTabs
      {...rest}
      onChange={onChange as TabsProps['onChange']}
      classes={{
        indicator: gutter ? classes.tabsIndicator : classes.tabsIndicatorDense,
        flexContainer: gutter
          ? classes.flexContainer
          : classes.flexContainerDense
      }}
    ></MuiTabs>
  );
};
