import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ModalMenuUI } from '../../../Modal/ModalMenuUI';
import { UserInfo } from '../../../User/UserTypes';
import ManageAccessUserDetail from '../Subcomponents/ManageAccessUserDetail';

import Cube3Button from '../../../Buttons/Cube3Button';
import { iconAdd } from '../../../icons/GeneralSVG';
import { Divider } from '../../../Surface/Divider';
import InteractableSurface from '../../../Surface/InteractableSurface';
import Button from '../../../Buttons/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    addUser: {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset'
      }
    },
    addUserIcon: {
      margin: '0px 22px'
    },
    newContainer: {
      display: 'flex',
      alignItems: 'center',
      height: theme.sizing[56]
    },
    divider: {
      margin: `${theme.customSpacing.margin[4]} 0px`
    }
  })
);

interface Properties {
  onCloseEvent: () => void;
  onClickAddUser: () => void;
  onRemoveAccess: (emailAddress: string) => void;
  onResendLink: (emailAddress: string) => void;
  email_addresses: string[];
  authEmailAddress?: string;
  loading?: boolean;
}

const ManageAccess: React.FC<Properties> = (props) => {
  const {
    onClickAddUser,
    onCloseEvent,
    onRemoveAccess,
    onResendLink,
    email_addresses = [],
    authEmailAddress,
    loading
  } = props;

  const classes = useStyles();

  return (
    <ModalMenuUI
      title={'Manage access'}
      onCloseEvent={() => onCloseEvent()}
      loading={loading}
      footerRightComponent={
        <Button
          text={'Done'}
          onClick={() => onCloseEvent()}
          colorVariant={'filled1'}
        />
      }
    >
      <InteractableSurface
        highlightOnHover={true}
        onClickEvent={() => onClickAddUser()}
        surfaceProps={{ corners: 'rounded' }}
        classes={{ root: classes.newContainer }}
      >
        <Cube3Button
          text="Add user"
          pathLeftSvg={iconAdd}
          colorVariant="ghost1"
          disableHover={true}
          className={classes.addUser}
          marginCollapse={'left'}
          extraSVGProps={{ className: classes.addUserIcon }}
        />
      </InteractableSurface>

      <Divider className={classes.divider} />

      {!loading && email_addresses?.length
        ? email_addresses.map((item, index) => {
            // minimal infomation needed to make user avatars work
            let userInfo: UserInfo = {
              email_address: item
            };

            return (
              <div data-cy={'user'} key={item + index}>
                <ManageAccessUserDetail
                  userInfo={userInfo}
                  onResendLinkClick={(info: UserInfo) =>
                    onResendLink(info.email_address)
                  }
                  onRemoveAccessClick={(info: UserInfo) =>
                    onRemoveAccess(info.email_address)
                  }
                  isAuth={authEmailAddress === userInfo.email_address}
                />
              </div>
            );
          })
        : undefined}
    </ModalMenuUI>
  );
};

/**
 * @summary `Modal Menu` that shows `ManageAccessUserDetail` and provides the ability to resend links, remove users, invite more users
 * @author Simon
 */
export default ManageAccess;
