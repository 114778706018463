import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';
import { ResumableUpload } from '../../../../state/src/wrapped-cube-client/uploader/urlStorage';
import { UploadShape } from '../../../../state/src/wrapped-cube-client/uploader/extendedupload';

export interface Upload extends ResourceBase {
  active: boolean;
  asset_id: string;
  display_name: string;
  progress: number;
  failed: boolean;
  paused: boolean;
  file: File;
  size: number;
  id?: string;
  tusUpload?: UploadShape;
  tusUrlStorage?: ResumableUpload;
  initiated_at?: string;
}

export const fileUpload: RawModel = {
  type: 'file-upload',
  plural: 'file-uploads',
  noEndpoint: true,
  attributes: {},
  relationships: {}
};
