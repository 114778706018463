import React from 'react';
import { MultipleResourcePicker } from '../MultipleResourcePicker';
import { useContractProjectSuggestions } from './useContractProjectSuggestions';

const CLEAR_ON = ['blur', 'add-new'];

export const ContractProjectsPicker = props => {
  const { selected, query, ...restProps } = props;

  const [
    suggested,
    excluded,
    suggestionsLoading
  ] = useContractProjectSuggestions({
    context: undefined,
    selected,
    query
  });

  return (
    <MultipleResourcePicker
      {...restProps}
      clearOn={CLEAR_ON}
      suggestedResources={suggested}
      excludedResources={excluded}
      placeholder="Start typing a project name..."
      suggestionsLoading={suggestionsLoading}
      showDisabled={true}
      excludedReasonText={'Project is archived'}
    />
  );
};
