import { ExportsParameters } from '@cube3/common/model/schema/resources/export-batch';

export const getExportsState = (exports: ExportsParameters[]) => {
  if (exports?.length > 0) {
    return exports.reduce(
      (acc, exp) => {
        return {
          success:
            exp.state === 'success' ? [...acc.success, exp] : acc.success,
          failed: exp.state === 'failed' ? [...acc.failed, exp] : acc.failed,
          inProgress:
            exp.state !== 'success' && exp.state !== 'failed'
              ? [...acc.inProgress, exp]
              : acc.inProgress
        };
      },
      { success: [], failed: [], inProgress: [] }
    );
  }
};
