import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useMemo } from 'react';
import { useAccountsUser } from './useAccountsUser';

export const useTeamSuggestions = (teamId: string) => {
  const teamAccounts = useResourceList__ALPHA({
    resourceType: 'team',
    resourceId: teamId,
    edgeType: 'account',
    edgeLabel: 'members',
    strategy: 'fetch-on-mount'
  });

  const teamAccountsWithUserInfo = useAccountsUser(teamAccounts.resources);

  return useMemo(
    () => ({
      members: teamAccountsWithUserInfo.resources,
      loading: teamAccountsWithUserInfo.loading || teamAccounts.loading
    }),
    [teamAccounts, teamAccountsWithUserInfo]
  );
};
