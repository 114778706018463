import { useState, useCallback, useEffect } from 'react';

const getPages = (focusPage, minPages, maxPages) => {
  let pages = [focusPage - 1, focusPage, focusPage + 1];

  return pages.filter(p => p >= minPages && p <= maxPages);
};

export const usePagination = (
  initialFocusPage = 1,
  onFocusPageChange = (page: number) => null
) => {
  // page currently in view
  const [focusPage, setFocusPage] = useState<number>(initialFocusPage);

  // cant take this directly from useResources because we need it for stuff upfront
  const [maxPages, setMaxPages] = useState(focusPage || 1);

  // cant take this directly from useResources because we need it for stuff upfront
  const [minPages, setMinPages] = useState(focusPage || 1);

  // pages to load
  const [pagesArray, setPagesArray] = useState(
    getPages(focusPage, minPages, maxPages)
  );

  useEffect(() => {
    const newPages = getPages(focusPage, minPages, maxPages);

    for (let i = 0; i < newPages.length; i++) {
      if (newPages[i] !== pagesArray[i]) {
        setPagesArray(newPages);
        break;
      }
    }
  }, [pagesArray, setPagesArray, minPages, maxPages, focusPage]);

  const handleFocusPageChange = useCallback(
    page => {
      const newPages = getPages(focusPage, minPages, maxPages);

      for (let i = 0; i < newPages.length; i++) {
        if (newPages[i] !== pagesArray[i]) {
          setPagesArray(newPages);
          break;
        }
      }

      onFocusPageChange(page);
      setFocusPage(page);
    },
    [
      focusPage,
      setFocusPage,
      pagesArray,
      setPagesArray,
      minPages,
      maxPages,
      onFocusPageChange
    ]
  );

  return [
    focusPage,
    pagesArray,
    maxPages,
    setMaxPages,
    minPages,
    setMinPages,
    handleFocusPageChange
  ] as [
    number,
    number[],
    number,
    (max: number) => void,
    number,
    (min: number) => void,
    (page: number) => void
  ];
};
