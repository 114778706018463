import { ns } from './config';

export const OPEN_MODAL = `${ns}/OPEN_MODAL`;
export const CLOSE_MODAL = `${ns}/CLOSE_MODAL`;
export const NEXT_PAGE = `${ns}/NEXT_PAGE`;
export const PREVIOUS_PAGE = `${ns}/PREVIOUS_PAGE`;
export const GO_BACK_PAGES = `${ns}/GO_BACK_PAGES`;
export const DELETE_MODAL_ITEM = `${ns}/DELETE_MODAL_ITEM`;
export const BLOCK_MODAL = `${ns}/BLOCK_MODAL`;
export const UNBLOCK_MODAL = `${ns}/UNBLOCK_MODAL`;

export const actions = {
  OPEN_MODAL,
  CLOSE_MODAL,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  GO_BACK_PAGES,
  DELETE_MODAL_ITEM,
  BLOCK_MODAL,
  UNBLOCK_MODAL
};

// eslint-disable-next-line
export function openModal(
  modal: string,
  context?: any,
  hideBelow: boolean = true
) {
  return {
    type: OPEN_MODAL,
    payload: {
      page: modal,
      resource: context,
      hideBelow
    }
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}

export function previousModal(resource: {
  type: string;
  id: string;
  additionalResourceProps?: Object;
}) {
  return {
    type: PREVIOUS_PAGE,
    payload: {
      resource: resource?.type === 'click' ? null : resource
    }
  };
}

export function goBackModals(amountOfPages: number) {
  return {
    type: GO_BACK_PAGES,
    payload: {
      amountOfPages
    }
  };
}

export function blockModal() {
  return {
    type: BLOCK_MODAL
  };
}

export function unblockModal(revertToSnapshot: boolean = false) {
  return {
    type: UNBLOCK_MODAL,
    payload: {
      revertToSnapshot
    }
  };
}

export const actionCreators = {
  openModal,
  closeModal,
  previousModal,
  goBackModals,
  blockModal,
  unblockModal
};
