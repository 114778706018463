import React, { useMemo, useReducer } from 'react';
import { getFieldErrors } from './utils/getFieldErrors';
import { validate } from './utils/validate';
import { makeRequiredValidator } from './utils/makeRequiredValidator';
import {
  ActionTypes,
  genericExportOptionsReducer
} from './genericExportOptionsReducer';

interface Option<V> {
  id: string;
  display_name: string;
  value: V;
}

type ExtractStringType<T> = T extends `${infer U}` ? U : never;

export interface Field<O = unknown, T extends FieldType = FieldType> {
  /** Identifier, could also be used for "name" attribute of input elements */
  id: string;
  /** human readable name that a field would be labeled with */
  label: string;
  /** input type */
  type: ExtractStringType<T>;
  /** whether the field is required (can be used to automatically generate validator in hook config) */
  required?: boolean;
  /** placeholder text that would be shown when input is considered empty */
  placeholder?: string;
  /** help text that would be displayed to explain input requirements */
  help?: string;
  /** option items in case of radio, checkbox, select, multiselect like components */
  options?: Readonly<Option<O>[]>;
  /** whether the field is disabled  */
  disabled?: boolean;
}

/** type of value each input type can accept / return */
export type ValueTypes = Readonly<{
  string: string;
  multistring: Array<string>;
  number: number;
  select: string | number;
  multiselect: Array<string | number>;
  checkbox: Array<string>;
  radio: string;
}>;

/** field types based on the keys of the ValueTypes lookup */
export type FieldType = keyof ValueTypes;

export interface Value<V = ValueTypes[keyof ValueTypes]> {
  id: string;
  value: V;
}

export type Validator = (values: Value[], fields: Field[]) => Error[];

export interface Error {
  /** id of the field */
  field: string;
  /** machine readable error code (e.g. from an enum ) */
  code: string;
  /** human readable error message */
  message: string;
  /** whether the error should be considered blocking (error) or advisory (warning) */
  type: 'error' | 'warning';
}

interface Config {
  /** array with Field configs  */
  fields: readonly Field[];
  /** array with initial value objects */
  initialValues: Value[];
  /** array with validator functions */
  validators: Validator[];
  /** whether to automatically generate validators based on `Field.required` property  */
  makeRequiredValidators?: boolean;
}

/** object with common properties that can be used as props for input components */
interface FieldProps<T> extends Field {
  onChange(
    val:
      | T
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>
  ): void;
  value: T;
  errors: Error[] | undefined;
  touched: boolean;
}

const defaultState = {
  fields: [] as readonly Field[],
  values: [],
  valid: true,
  validators: [],
  errors: undefined,
  touched: undefined
};

/**
 *
 * Hook
 *
 */

export const useGenericExportOptions = ({
  fields,
  initialValues,
  validators,
  makeRequiredValidators = true
}: Config) => {
  // optionally generate "required" validators
  const extendedValidators = makeRequiredValidators
    ? [
        ...(validators || []),
        ...fields
          .map((f) => (f.required ? makeRequiredValidator(f.id) : undefined))
          .filter(Boolean)
      ]
    : validators;

  // run validators at initial run
  const initialErrors = extendedValidators
    ? validate(extendedValidators, initialValues, fields)
    : undefined;

  const initialState = {
    ...defaultState,
    fields: fields || defaultState.fields,
    values: initialValues || defaultState.values,
    validators: extendedValidators,
    valid: initialErrors ? !initialErrors?.length : defaultState.valid,
    errors: initialErrors ? initialErrors : defaultState.errors,
    touched: []
  };

  // set up reducer
  const [{ values, valid, errors, touched }, dispatch] = useReducer(
    genericExportOptionsReducer,
    initialState
  );

  // build fieldProps objects for each field
  const fieldProps: { [key: string]: FieldProps<any> } = useMemo(() => {
    return fields.reduce((acc, field) => {
      const fieldErrors = getFieldErrors(errors, field.id);

      const value = values.find((v) => v.id === field.id)?.value;

      return {
        ...acc,
        [field.id]: {
          // change handler, accepts actual value or Event for convenience
          onChange: (val) =>
            dispatch({
              type: ActionTypes.CHANGE_VALUE,
              payload: {
                id: field.id,
                value:
                  typeof val === 'object' &&
                  !Array.isArray(val) &&
                  Object.hasOwn(val, 'target')
                    ? val.target.value
                    : val // in case the value is actually an event
              }
            }),
          // field value
          value,
          // any errors for field
          errors: fieldErrors?.length ? fieldErrors : undefined,
          // whether the field is considered touched (e.g. value is non-default)
          touched: touched.includes(field.id),
          ...field
        } as FieldProps<typeof value>
      } as const;
    }, {});
  }, [fields, values]);

  return {
    fieldProps,
    values,
    valid,
    errors,
    fields
  };
};
