import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';
import { Role } from './role';

export interface Account extends PublicResource {
  user_id: string;
  role?: Role;
  full_name: string;
  email_address: string;

  // data needs to be removed once resource parsing resolved properly
  relationships: {
    workspace: Related<'workspace'>;
    user: Related<'user'>;
    role: Related<'role'>;
  };
}

export const account: RawModel = {
  type: 'account',
  plural: 'accounts',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID'
  },
  relationships: {
    user: {
      type: 'user',
      binding: ['one', 'many'],
      reverse: 'accounts'
    },
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'accounts'
    },
    role: {
      type: 'role',
      binding: ['one', 'many'],
      reverse: 'accounts'
    }
  }
};
