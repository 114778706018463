import React from 'react';
import { CommandBarBaseUI } from './CommandBarBaseUI';
import { CommandBarButtonsUI } from './CommandBarButtonsUI';
import { MenuEntry } from './types';

interface GenericProps {
  primaryButtonsConfig?: MenuEntry[];
  secondaryButtonsConfig?: MenuEntry[];
}

export const CommandBarGenericUI: React.FC<GenericProps> = props => {
  const { primaryButtonsConfig, secondaryButtonsConfig } = props;

  return (
    <CommandBarBaseUI
      primaryButtons={
        primaryButtonsConfig && (
          <CommandBarButtonsUI config={primaryButtonsConfig} />
        )
      }
      secondaryButtons={
        secondaryButtonsConfig && (
          <CommandBarButtonsUI config={secondaryButtonsConfig} />
        )
      }
    />
  );
};
