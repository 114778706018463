import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { TeamDetail } from './TeamDetail';
import { AvatarList } from '../Workspace/subComponents/AvatarList';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: '54px'
    },
    avatarList: { flexShrink: 0, justifySelf: 'flex-end' }
  })
);

const emptyObj = {};
const TeamSugggestionItemRowUI = ({
  team,
  members,
  loading = false,
  style = emptyObj,
  maxCount = 3
}) => {
  const classes = useStyles();

  return (
    <div className={classes.row} style={style}>
      <TeamDetail
        team={team}
        loading={loading}
        membersCount={members?.length || 0}
      />

      <div className={classes.avatarList}>
        {loading ? (
          <div style={{ height: '2em' }}>
            <Shimmer />
          </div>
        ) : (
          members?.length > 0 && (
            <AvatarList users={members} maxCount={maxCount} />
          )
        )}
      </div>
    </div>
  );
};

export default TeamSugggestionItemRowUI;
