import {
  ResourceBase,
  ResourceIdentifier,
  ResourceType
} from '../../resource-types';
import { RawModel } from '../schema';

export interface ChangeRequest extends ResourceBase {
  key: string;
  value: unknown;
  confirmed: boolean;
  status: 'requested' | 'pending_confirmation' | 'confirmed' | 'rejected';
  target_resource: ResourceIdentifier<ResourceType>;
}

export const changeRequest: RawModel = {
  type: 'change-request',
  plural: 'change-requests',
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
