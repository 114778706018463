import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';
import { ExportStateType } from './export-batch';

export interface RenderBatch extends ResourceBase {
  type: 'render-batch';
  id: string;
  attributes: {
    dry_run: boolean;
    state: ExportStateType;
  };
  relationships: {
    'render-preset': Related<'render-preset'>;
    assets: Related<'asset'>[];
  };
}

export const renderBatch: RawModel = {
  type: 'render-batch',
  plural: 'render-batches',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    dry_run: 'boolean'
  },
  relationships: {
    'render-preset': {
      type: 'render-preset',
      binding: ['one', 'one'],
      reverse: 'render-batches'
    },
    assets: {
      type: 'asset',
      binding: ['one', 'many'],
      reverse: 'render-batches'
    }
  }
};
