/**
 *  Little helper component for the DevConsole
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { HARDCODED_FEATURES } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { ALL_FEATURES } from '@cube3/common/model/resource-types';

const emptyArray = [];

export const FeatureFlagsPanel = () => {
  const [workspaceId] = useCurrentWorkspace();

  const workspaceFeatures = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'feature-flag',
    edgeLabel: 'feature-flags'
  });

  const [values, setValues] = useState([
    ...HARDCODED_FEATURES,

    ...(workspaceFeatures.resources || emptyArray).map((f) => f.feature),
    ...(JSON.parse(localStorage.getItem('PRE_RELEASE_FEATURES')) || emptyArray)
  ]);

  useEffect(() => {
    if (workspaceFeatures.resources) {
      setValues([
        ...HARDCODED_FEATURES,
        ...(workspaceFeatures.resources || emptyArray)
          .filter((f) => f.enabled)
          .map((f) => f.feature),
        ...(JSON.parse(localStorage.getItem('PRE_RELEASE_FEATURES')) ||
          emptyArray)
      ]);
    }
  }, [workspaceFeatures.resources]);

  const handleChange = useCallback(
    (ev) => {
      const f = ev.target.name;
      const c = values.indexOf(f) > -1;

      const newValues = [...values].filter((v) => v !== f);

      if (!c) {
        newValues.push(f);
      }

      const localValues = newValues.filter((v) => {
        return !(
          (workspaceFeatures.resources || emptyArray)
            .filter((f) => f.enabled)
            .map((f) => f.feature)
            .indexOf(v) > -1 || HARDCODED_FEATURES.indexOf(v) > -1
        );
      });

      localStorage.setItem('PRE_RELEASE_FEATURES', JSON.stringify(localValues));

      setValues([
        ...HARDCODED_FEATURES,
        ...(workspaceFeatures.resources || emptyArray)
          .filter((f) => f.enabled)
          .map((f) => f.feature),
        ...localValues
      ]);
    },
    [values, setValues, workspaceFeatures.resources]
  );

  const r = useMemo(
    () => (
      <ul>
        {ALL_FEATURES.map((f, idx, all) => {
          const [cat, name] = getDisplay(f);

          const [lastcat] = idx > 0 ? getDisplay(all[idx - 1]) : emptyArray;

          return (
            <React.Fragment key={f}>
              {(!lastcat || lastcat !== cat) && (
                <li>
                  <h4>{cat}</h4>
                </li>
              )}
              <li key={f}>
                <label>
                  <input
                    type="checkbox"
                    disabled={
                      (workspaceFeatures.resources || emptyArray)
                        .filter((f) => f.enabled)
                        .map((f) => f.feature)
                        .indexOf(f) > -1 || HARDCODED_FEATURES.indexOf(f) > -1
                    }
                    name={f}
                    onChange={handleChange}
                    checked={values.indexOf(f) > -1}
                  />
                  {name}
                </label>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    ),
    [values, handleChange, workspaceFeatures.resources]
  );

  return r;
};

const getDisplay = (f) => {
  const segments = f.split('/');

  const cat = segments.length > 1 ? segments.slice(0, -1).join(' ') : 'GENERAL';
  const name = segments.length > 1 ? segments.slice(-1)[0] : segments[0];

  return [cat.toLowerCase(), name.toLowerCase()];
};
