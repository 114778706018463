import React from 'react';
// UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import GeneralSVG, {
  iconKeyboardArrowRight,
  iconElepsis
} from '../../../icons/GeneralSVG';
import { Typography } from '../../../typography/Typography';
import UseElementResize from '../../../helpers/hooks/UseElementResize';
import TruncatedText from '../../../human/Truncate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbsContainer: {
      display: 'flex',
      minWidth: '100%',
      alignItems: 'center'
    },
    crumb: {
      display: 'flex',
      flexShrink: 0,
      flexGrow: 1,
      alignItems: 'center'
    },
    itemName: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    itemNameSpan: {}
  })
);

interface SharedLinkOverviewBreadcrumbsProps {
  crumbs: { name: string; pathname: string }[];
  maxLength?: number;
  truncate?: number;
}

function SharedLinkOverviewBreadcrumbs(
  props: SharedLinkOverviewBreadcrumbsProps
) {
  const container = UseElementResize();
  const { crumbs, maxLength = 2, truncate } = props;

  const breadcrumbSpan = UseElementResize();

  const classes = useStyles({});
  const { breadcrumbsContainer, itemName, itemNameSpan } = classes;

  const startIndex = Math.max(0, crumbs.length - maxLength);
  return (
    <div className={breadcrumbsContainer} ref={container.elementRef}>
      <GeneralSVG path={iconElepsis} color="contrast2" />
      {crumbs.slice(startIndex).map((crumb, index) => {
        return index < maxLength ? (
          <div key={crumb.name + index} className={classes.crumb}>
            <GeneralSVG path={iconKeyboardArrowRight} color="contrast2" />
            <div className={itemNameSpan} ref={breadcrumbSpan.elementRef}>
              <Typography
                typographyStyle={'body2'}
                color="contrast2"
                className={itemName}
                typographyProps={{
                  style: {
                    maxWidth:
                      container.width / Math.min(crumbs.length, maxLength)
                  }
                }}
              >
                {truncate ? (
                  <TruncatedText str={crumb.name} widthLimit={truncate} />
                ) : (
                  crumb.name
                )}
              </Typography>
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}

export default SharedLinkOverviewBreadcrumbs;
