import { PublicResource, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

interface MeiliHit {
  _federation: {
    indexUid: string;
    queriesPosition: number;
    weightedRankingScore: number;
  };
  _formatted: {
    contract_values: [];
    description: string;
    display_name: string;
    id: string;
    metadata_values: [];
    parent: string;
    tags: [];
    type: 'image' | 'video';
    url: null | string;
  };
  contract_values: [];
  description: string;
  display_name: string;
  id: string;
  metadata_values: [];
  parent: string;
  tags: [];
  type: 'image' | 'video';
  url: null | string;
}

export interface SearchResult2 extends PublicResource {
  meili_hit: MeiliHit;
  relationships: {
    asset: Related<'asset'>;
  };
}

export const searchResult2: RawModel = {
  type: 'search-result-2',
  plural: 'search-results',
  useWorker: true,
  JSONApi: true,
  canonicalPath: 'search',
  attributes: {
    id: 'UUID',
    meili_hit: 'meili_hit'
    // display_image: 'URL'
  },
  relationships: {
    asset: {
      type: 'asset',
      reverse: 'search-results',
      binding: ['one', 'many']
    }
  }
};
