import { useCallback } from 'react';

export const useScrollbarEvents = ({
  onScrollFrame,
  setScrollingBoolean,
  scrolling,
  onScroll,
  rememberScroll,
  setScrollPosition,
  containerRef,
  setScrolling
}) => {
  const handleScrollFrame = useCallback(
    (values) => onScrollFrame?.(undefined, values),
    [onScrollFrame]
  );

  const handleScrollStop = useCallback(() => {
    if (!setScrollingBoolean) {
      return;
    }
    if (scrolling) {
      setScrolling(false);
    }
  }, [scrolling, setScrollingBoolean]);

  const handleScrolling = useCallback(
    (ev) => {
      if (onScroll) {
        onScroll(ev);
      }
      if (rememberScroll !== true && rememberScroll !== 'save') {
        return;
      }
      const scrollPos = containerRef?.getScrollTop();

      setScrollPosition(scrollPos);
    },
    [setScrollPosition, containerRef, rememberScroll, onScroll]
  );

  const handleScrollStart = useCallback(() => {
    if (!setScrollingBoolean) {
      return;
    }

    if (!scrolling) {
      setScrolling(true);
    }
  }, [setScrollingBoolean, scrolling]);

  return {
    handleScrollFrame,
    handleScrollStart,
    handleScrolling,
    handleScrollStop
  };
};
