import { createStyles, makeStyles, Slider, Theme } from '@material-ui/core';
import React from 'react';
import { Typography } from '../../../typography/Typography';

interface Props {
  containerWidth: number;
  containerHeight: number;
  disabled?: boolean;
  imageSrc: string;
  min: number;
  max: number;
  cropperRef: any;
  imageRef: any;
  styles: {
    left: number;
    top: number;
    radius: number;
  };
  onMouseDown: (e: React.MouseEvent) => void;
  onLoad?: React.EventHandler<any>;
  onMouseMove: (e: React.MouseEvent) => void;
  onWheel: (e: React.MouseEvent) => void;
  onRangeChange: (v: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ containerWidth, containerHeight }: Props) => ({
      position: 'relative',
      overflow: 'hidden',
      width: containerWidth,
      height: containerHeight,
      margin: 'auto'
    }),
    imageOverlay: {
      position: 'absolute',
      zIndex: 9,
      width: 'inherit',
      height: '100%',
      backgroundColor: `${theme.customPalette.primary.dark}`,
      opacity: 0.7
    },

    originImage: ({ containerWidth, containerHeight }: Props) => ({
      display: 'block',
      maxWidth: containerWidth,
      maxHeight: containerHeight,
      objectFit: 'contain',
      position: 'relative',
      pointerEvents: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    }),
    blur: {
      opacity: 0.2
    },
    hidden: {
      visibility: 'hidden'
    },
    canvas: {
      display: 'none'
    },
    cropper: ({ disabled = false }: Props) => {
      return {
        position: 'absolute',

        zIndex: disabled ? 0 : 99,
        borderRadius: '50%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: `2px ${theme.customPalette.secondary.light} solid`,
        '&::selection': {
          background: 'transparent'
        },
        '&:hover, &:active': {
          cursor: 'move'
        },
        '& img': {
          position: 'absolute',

          visibility: 'visible',
          transform: 'none'
        }
      };
    },
    text: {
      textAlign: 'center',
      paddingBottom: 10
    },
    rangeContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px auto 0'
    },
    slider: {
      marginLeft: 24,
      width: 150,
      color: theme.customPalette.line.onBase1.contrast4,
      '$mobile &': {
        margin: 0,
        width: theme.sizing[32]
      }
    },
    sliderTrack: {
      height: '4px',
      backgroundColor: theme.customPalette.surface.brand1,
      borderRadius: theme.surfaceCorners.mediumEmphasis
    },
    thumb: {
      backgroundColor: theme.customPalette.surface.brand1,
      boxShadow: 'none !important',
      transformOrigin: '50% 50%',
      marginTop: '-4px'
    },
    rail: {
      opacity: 1,
      height: 4
    }
  })
);

export const ImageCropperUI = (props: Props) => {
  const {
    min,
    max,
    containerWidth,
    containerHeight,
    styles,
    imageRef,
    cropperRef,
    imageSrc,
    onMouseDown,
    onLoad,
    onMouseMove,
    onWheel,
    onRangeChange
  } = props;

  const classes = useStyles(props);
  const center_left = (containerWidth - 4 - imageRef.current?.clientWidth) / 2;
  const center_top = (containerHeight - 4 - imageRef.current?.clientHeight) / 2;

  return (
    <>
      <div
        className={classes.container}
        onMouseMove={onMouseMove}
        onLoad={onLoad}
      >
        <div className={classes.imageOverlay}></div>
        <img src={imageSrc} alt="origin" className={`${classes.originImage}`} />
        {styles && (
          <div
            id="cropper"
            style={{
              left: styles.left,
              top: styles.top,
              width: styles.radius,
              height: styles.radius
            }}
            className={classes.cropper}
            onMouseDown={onMouseDown}
            onWheel={onWheel}
            ref={cropperRef}
          >
            <img
              src={imageSrc}
              ref={imageRef}
              alt="target"
              style={{
                top: -styles.top + center_top,
                left: -styles.left + center_left
              }}
              className={`${classes.originImage} ${classes.hidden}`}
            />
          </div>
        )}
      </div>

      <div className={classes.rangeContainer}>
        <Typography color="contrast2" typographyStyle="body3">
          Resize via control or mouse wheel
        </Typography>
        <Slider
          onChange={(event: React.ChangeEvent, value: number) => {
            onRangeChange(value);
          }}
          min={min}
          max={max}
          step={2}
          classes={{
            root: classes.slider,
            track: classes.sliderTrack,
            thumb: classes.thumb,
            rail: classes.rail
          }}
          value={styles ? styles.radius : min}
          defaultValue={150}
        />
      </div>
    </>
  );
};
