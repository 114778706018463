import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ModalNoticeUI } from '../Modal/ModalNoticeUI';
import Button from '../Buttons/Button';
import Cube3TextField from '../forms/textfields/Cube3TextField';
import { Typography } from '../typography/Typography';

interface Props {
  title: string;
  loading?: boolean;
  onCloseEvent: () => void;
  defaultValue?: string;
  onSubmit: (name: string) => void;
  error?: string;
  submitButtonLabel?: string;
  placeholder?: string;
}

export const RenameModalStatelessUI: React.FC<Props> = (props) => {
  const {
    loading,
    onCloseEvent,
    defaultValue,
    onSubmit,
    error,
    submitButtonLabel,
    placeholder,
    title
  } = props;

  const [errorMessage, setErrorMessage] = useState(error);

  const [displayName, setDisplayName] = useState<string>(defaultValue);

  const onChange = useCallback(
    (e) => {
      if (errorMessage) {
        setErrorMessage(undefined);
      }
      setDisplayName(e.target.value);
    },
    [setDisplayName, errorMessage]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(displayName);
    },
    [onSubmit, displayName]
  );

  useEffect(() => {
    setErrorMessage(error);
  }, [error, setErrorMessage]);

  return (
    <ModalNoticeUI
      title={title}
      onCloseEvent={onCloseEvent}
      component={'form'}
      extraComponentProps={{ onSubmit: handleSubmit }}
      loading={loading}
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            onClick={onCloseEvent}
          />
          {defaultValue && displayName === defaultValue ? (
            <Button
              text={'Done'}
              colorVariant={'filled1'}
              onClick={onCloseEvent}
            />
          ) : (
            <Button
              text={submitButtonLabel || 'Rename'}
              disabled={!displayName || !!errorMessage}
              colorVariant={'filled1'}
              onClick={handleSubmit}
            />
          )}
        </>
      }
    >
      <Cube3TextField
        value={displayName}
        placeholder={placeholder || 'Enter template name'}
        fullWidth={true}
        autoFocus={true}
        onChange={onChange}
      />
      {displayName && errorMessage && (
        <Typography color="danger1">{errorMessage}</Typography>
      )}
    </ModalNoticeUI>
  );
};
