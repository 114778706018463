import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useClassName } from '../utils/useClassName';
import GeneralSVG from '../icons/GeneralSVG';
import MaybeTooltip from '../Tooltip/MaybeTooltip';
import Color from 'color';
import { Typography } from '../typography/Typography';

export type StatusColor =
  | 'success'
  | 'warning'
  | 'danger'
  | 'neutral'
  | 'neutral-light'
  | 'cool'
  | 'inherrit';

interface Props {
  color?: StatusColor;
  skeuomorphic?: boolean;
  glimmer?: boolean;
  size?: 'big' | 'medium' | 'small' | 'tiny';
  icon?: string;
  iconViewBox?: number;
  tooltip?: string;
  withShade?: boolean;
  text?: string | number;
  style?: React.CSSProperties;
}

const sizes = {
  big: 24,
  medium: 20,
  small: 10,
  tiny: 8
};

const getCssColor = (theme: Theme, color: StatusColor) => {
  let c = theme.customPalette.backgroundAccent.whiteAccent2;

  switch (color) {
    case 'inherrit':
      c = 'inherrit';
      break;
    case 'danger':
      c = theme.customPalette.dangerError;
      break;
    case 'warning':
      c = theme.customPalette.warning;
      break;
    case 'success':
      c = theme.customPalette.success;
      break;
    case 'cool':
      c = theme.customPalette.success;
      break;
    case 'neutral-light': // cubicle theme: theme.color.secondary
      c = '#C3C3C3';
      break;
    case 'neutral':
    default:
      break;
  }

  let shadeColor = Color(c);
  shadeColor = shadeColor.alpha(0.08);

  return {
    color: c,
    shade: shadeColor.rgb().string()
  };
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  '@keyframes glimmer': {
    '0%': {
      transform: 'translate(-100%,0) skewX(20deg)'
    },
    '5%': {
      transform: 'translate(100%,0) skewX(-20deg)'
    },
    '100%': {
      transform: 'translate(100%,0)'
    }
  },

  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 999,
    width: ({ size = 'small' }) => sizes[size],
    height: ({ size = 'small' }) => sizes[size],
    color: ({ color }) => getCssColor(theme, color).color,
    backgroundColor: 'currentcolor',
    boxShadow: ({ withShade = false, color, size = 'small' }) =>
      withShade
        ? `0 0 0 ${sizes[size] - 1}px ${getCssColor(theme, color).shade}`
        : 'none',
    margin: ({ size = 'small', withShade = false }) =>
      withShade ? sizes[size] : 0
  },
  glimmer: {
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      backgroundColor: 'white',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      animation: '$glimmer 5s infinite 10s ',
      animationFillMode: 'both',
      opacity: 0.7
    }
  },
  skeuomorphic: {
    boxShadow:
      'inset 1px 2px 4px -3px white, inset -1px -2px 4px -2px black, 0 0 10px -2px currentcolor'
  },
  tooltip: {
    width: ({ size = 'small' }) => sizes[size],
    height: ({ size = 'small' }) => sizes[size]
  },
  text: {
    marginLeft: 2
  }
}));

const emptyObj = {};
export const StatusBlip: React.FunctionComponent<Props> = (props) => {
  const {
    skeuomorphic = false,
    glimmer = false,
    icon,
    iconViewBox = 24,
    tooltip,
    text = undefined,
    style = emptyObj
  } = props;
  const classes = useStyles(props);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: 4,
        ...style
      }}
    >
      <div
        className={useClassName(
          classes.root,
          glimmer && classes.glimmer,
          skeuomorphic && classes.skeuomorphic
        )}
      >
        <MaybeTooltip
          disabled={!tooltip}
          title={tooltip}
          className={classes.tooltip}
        >
          {icon && (
            <GeneralSVG
              color="contrast2"
              surface="onBrand"
              path={icon}
              svgProps={{ viewBox: `0 0 ${iconViewBox} ${iconViewBox}` }}
            />
          )}
        </MaybeTooltip>
      </div>
      {text && (
        <Typography className={classes.text} color={'contrast2'}>
          {text}
        </Typography>
      )}
    </div>
  );
};
