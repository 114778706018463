import { useEffect, useMemo, useRef } from 'react';
import {
  PROJECT_ROLE_TYPES,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { useCurrentAccount } from '../../Administration/withCurrentUserAccount';
import { privileges, Privileges } from '../privileges';
import { useCurrentShareLink } from '../useCurrentShareLink';
import { usePrivilege } from '../usePrivilege';
import { useProjectRole, useRole } from '../useRole';

interface UsePermissionConfig {
  accountId?: string;
  privileges: Privileges[];
  targetResource?: ResourceIdentifier;
  contextResource?: ResourceIdentifier<'project' | 'workspace' | 'share'>;
  hasTeamFeature?: boolean;
}

const emptyArray = [];

export const usePermission = ({
  accountId: requestAccountId,
  privileges: requestPrivileges,
  targetResource = { type: undefined, id: undefined },
  contextResource = { type: undefined, id: undefined },
  hasTeamFeature
}: UsePermissionConfig) => {
  const currentAccount = useCurrentAccount(true)[0];
  const accountId = requestAccountId || currentAccount;

  const { type: targetType = undefined, id: targetId = undefined } =
    targetResource;

  const { type: contextType = targetType, id: contextId = targetId } =
    contextResource;

  const projectId = contextType === 'project' && contextId;
  const workspaceId = contextType === 'workspace' && contextId;
  // const shareId = contextType === 'share' && contextId;
  // const fileRequestId = contextType === 'file-request' && contextId;

  const requestPrivilegesMemo = useRef(requestPrivileges);

  useEffect(() => {
    if (
      requestPrivileges.length !== requestPrivilegesMemo.current.length ||
      requestPrivileges.find(
        (p, idx) => requestPrivilegesMemo.current[idx] !== p
      )
    ) {
      requestPrivilegesMemo.current = requestPrivileges;
    }
  }, [requestPrivileges]);

  const role = useRole({ accountId, workspaceId });

  const projectRole = useProjectRole({ accountId, projectId, hasTeamFeature });

  const defaultPermissions = usePrivilege({
    roleId: role.first?.id,
    requestedPrivs: requestPrivilegesMemo.current
  });

  // TODO: add useShareRole
  const isProjectAdmin =
    projectRole.resource &&
    projectRole.resource.project_member_role === PROJECT_ROLE_TYPES.Admin;

  const projectPermissions = useMemo(() => {
    if (projectRole.resource) {
      return privileges.checkPermission(
        isProjectAdmin ? 'PROJECT_ADMIN' : 'PROJECT_MEMBER',
        requestPrivilegesMemo.current
      );
    }
    return requestPrivilegesMemo.current.map((r) => false);
  }, [isProjectAdmin, projectRole]);

  // TODO: make sharelinks not expect unresolved permissions state
  const sharelink = useCurrentShareLink();

  const permissions =
    contextType === 'project' ? projectPermissions : defaultPermissions;

  return sharelink || role.loading || projectRole.loading
    ? emptyArray
    : permissions;
};
