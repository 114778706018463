import React, { useEffect } from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from './../../Modals/ModalManager';
import { ModalActionProps, useModalActions } from '../../Modals/modalActions';

// UI
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useLogout } from '../hooks/useLogout';

/// @Author :
/// <Summary>
/// A notice displaying
/// </Summary>

export const LogoutMultiple: React.FC = () => {
  const { closeAllModals } = useModalActions();

  const { endAllSessions, endThisSession, sessions, retrieveActiveSessions } =
    useLogout();

  useEffect(() => {
    retrieveActiveSessions();
  }, []);

  return (
    <ModalNoticeUI
      loading={sessions === null}
      title={'Logout multipe sessions?'}
      onCloseEvent={() => closeAllModals()}
      footerLeftComponent={
        <Button
          text={'Cancel'}
          colorVariant={'ghost1'}
          onClick={() => closeAllModals()}
        />
      }
      footerRightComponent={
        <>
          <Button
            text={'Only here'}
            colorVariant={'ghost1'}
            onClick={endThisSession}
          />
          <Button
            text={'Everywhere'}
            colorVariant={'filled1'}
            onClick={endAllSessions}
          />
        </>
      }
    >
      <Typography gutter={true}>Do you want to log out everywhere?</Typography>
      <Typography color="contrast2" variant="body2">
        There are {sessions ? sessions.length : '...'} active sessions for your
        account.
      </Typography>
    </ModalNoticeUI>
  );
};
