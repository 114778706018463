import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Snapshot extends ResourceBase {
  timestamp: number;
  relationships: {
    asset: Related<'asset'>;
    source_asset: Related<'asset'>;
  };
}

export const snapshot: RawModel = {
  JSONApi: true,
  type: 'snapshot',
  plural: 'snapshots',
  attributes: {
    id: 'UUID',
    timestamp: 'number'
    // display_image: 'URL'
  },
  relationships: {
    asset: {
      type: 'asset',
      reverse: 'snapshot',
      binding: ['one', 'one']
    },
    source_asset: {
      type: 'asset',
      reverse: 'snapshot',
      binding: ['one', 'many']
    }
  }
};
