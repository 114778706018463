import { createPortal } from 'react-dom';
import { ReactNode } from 'react';

/** Create portals on main document body, with a specific z-index, useful for overlays and tooltips alike */
export const createBodyPortal = (
  /** child elements */
  children: ReactNode,
  /** identifier used as id for element that (optionally) gets added to body */
  id: string,
  /** key */
  key?: null | string,
  /** z-index */
  z?: null | number
) => {
  let el =
    document.getElementById(id) ||
    document.body.appendChild(document.createElement('div'));
  el.setAttribute('id', id);
  el.setAttribute('style', `position: fixed; z-index:${z};`);

  return createPortal(children, el, key);
};
