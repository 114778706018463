import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import Tooltip from '@material-ui/core/Tooltip';
import { PopperPlacementType } from '@material-ui/core/Popper';
import { UserInfo } from '../UserTypes';

import { getInitials, getColor, getContrast } from '../../utils/avatarHelpers';
import { ColorProperty, BackgroundColorProperty } from 'csstype';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

/// <Summary>
/// Displays user profile picture centered in a container
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    inline: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    large: {
      width: '45px',
      height: '45px'
    },
    medium: {
      width: '32px',
      height: '32px'
    },
    small: {
      width: '24px',
      height: '24px'
    },
    avatar: {
      width: '100%',
      height: '100%',
      fontSize: theme.typographyStyles.body1.fontSize,
      textTransform: 'uppercase'
    }
  })
);

interface Properties {
  classes?: ClassNameMap;
  userInfo?: UserInfo;
  textPrimary?: string;
  textSecondary?: string;
  size?: 'large' | 'medium' | 'small';
  injectedStyle?: {};
  tooltipPlacement?: PopperPlacementType;
  tooltipDisabled?: boolean;
  color?: ColorProperty;
  avatarStyles?: string;
  inline?: boolean;
}

const UserAvatar: React.FC<Properties> = (props) => {
  const {
    userInfo = {},
    size = 'medium',
    inline,
    injectedStyle,
    tooltipPlacement,
    tooltipDisabled,
    textPrimary,
    textSecondary,
    color,
    avatarStyles
  } = props;

  const { email_address } = userInfo;

  const classes = useStyles(props);

  // fall back to first letter of email address when all other information is unavailable.
  const emailInitial: string = email_address && email_address.charAt(0);
  const initials: string =
    userInfo.full_name && getInitials(userInfo.full_name);
  const primary = textPrimary || initials || emailInitial || '?';
  const secondary =
    textSecondary || userInfo.full_name || email_address || '...';
  const col =
    color ||
    getColor(
      userInfo.full_name || email_address || textPrimary || textSecondary
    );
  const contrast = getContrast(col);

  return (
    <div
      className={`${classes.root}${inline ? ` ${classes.inline}` : ''} ${
        classes[size]
      }`}
      style={injectedStyle}
    >
      <Tooltip
        title={secondary}
        disableFocusListener={tooltipDisabled}
        disableHoverListener={tooltipDisabled}
        disableTouchListener={tooltipDisabled}
        placement={tooltipPlacement || 'bottom'}
      >
        <div className={classes.avatar}>
          {userInfo.profile_picture ? (
            /* Note this has to be wrapped inside a div
                          because using tenary in tooltip is not allowed */
            <Avatar
              classes={{ root: [classes.avatar, avatarStyles].join(' ') }}
              src={userInfo.profile_picture}
            />
          ) : (
            <Avatar
              className={[classes.avatar, avatarStyles].join(' ')}
              style={{
                color: contrast,
                backgroundColor: col as BackgroundColorProperty
              }}
            >
              {primary}
            </Avatar>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default UserAvatar;
