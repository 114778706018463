import React from 'react';
import { Activity } from '@cube3/common/model/schema/resources/activities';

import { useActivityContext } from '../hooks/useActivityContext';
import { Typography } from '@cube3/ui/src/typography/Typography';

interface Properties {
  projectActivity: Activity;
}

const makePreposition = (resource): string => {
  console.log('makePreposition', resource);
  switch (resource?.type) {
    case 'folder':
      return 'in';
    case 'render-preset':
      return 'as';
    case 'export-target':
      return 'to';
    default:
      return 'to';
  }
};

export const ActivityItemWithoutPath: React.FunctionComponent<Properties> = (
  props
) => {
  const { projectActivity } = props;

  const { related_resources: context } = projectActivity.relationships;

  const { name: contextName, resource: resource } = useActivityContext(context);

  const preposition = makePreposition(resource);

  return (
    <div>
      <Typography display={'inline'} color="contrast2">
        {preposition} <Typography display={'inline'}>{contextName}</Typography>
      </Typography>
    </div>
  );
};
