import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import ShareDownloadCheckBoxWithTooltip from '@cube3/ui/src/Prefabs/shareLink/Subcomponents/ShareDownloadCheckBoxWithTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      padding: '12px 0'
    }
  })
);

export type SelectedFormatsType = {
  [groupName: string]: RenderPreset[];
};
interface Props {
  modalContext: {
    allPresets: SelectedFormatsType;
    setDownloadFormats: Function;
    downloadFormats: SelectedFormatsType;
  };
}

const ShareDownloadAdvancedFormatsModal = (props: Props) => {
  const { allPresets, downloadFormats, setDownloadFormats } =
    props.modalContext;

  const classes = useStyles();
  const [selectedFormats, setSelectedFormats] = useState<SelectedFormatsType>(
    downloadFormats || {}
  );

  const { previousModal } = useModalActions();

  const handleSave = useCallback(() => {
    setDownloadFormats(selectedFormats);
    previousModal();
  }, [previousModal, selectedFormats, setDownloadFormats]);

  const disableSave = Object.values(selectedFormats).every(
    (arr) => !arr.length
  );

  return (
    <ModalMenuUI
      title="Download formats"
      onCloseEvent={previousModal}
      multiplyContentSpace={8}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant="ghost2"
            onClick={() => previousModal()}
          />
          <Button
            text={'Save'}
            colorVariant={'filled1'}
            onClick={handleSave}
            disabled={disableSave}
          />
        </>
      }
    >
      {disableSave && (
        <Typography color="danger1">
          You need to select at least one format
        </Typography>
      )}
      {allPresets.default?.map((val: RenderPreset) => (
        // make sure 'original/preview' formats are always on the top of list
        <ShareDownloadCheckBoxWithTooltip
          key={val.id}
          preset={val}
          setSelectedFormats={setSelectedFormats}
          group={'default'}
          selectedFormats={selectedFormats}
        />
      ))}
      {!isEmptyObject(allPresets)
        ? Object.keys(allPresets).map((group: string, idx) =>
            group === 'default' ? null : (
              <div key={group + idx} style={{ paddingBottom: '24px' }}>
                {allPresets[group].length ? (
                  <Typography
                    color="contrast3"
                    typographyStyle="body1"
                    className={classes.title}
                  >
                    {group}
                  </Typography>
                ) : null}
                {allPresets[group].map((val: RenderPreset) => (
                  <ShareDownloadCheckBoxWithTooltip
                    key={val.id}
                    preset={val}
                    setSelectedFormats={setSelectedFormats}
                    group={group}
                    selectedFormats={selectedFormats}
                  />
                ))}
              </div>
            )
          )
        : null}
    </ModalMenuUI>
  );
};

const isEmptyObject = (obj: Object) => {
  return obj
    ? Object.keys(obj).length === 0 || Object.values(obj).every((v) => !v)
    : true;
};

export default ShareDownloadAdvancedFormatsModal;
