/** map through a nested object to delete the certain key and returns a new object*/
export const deleteKey = (value: {}, keyToDelete: string) => {
  if (!value) return value;
  const object = { ...value };

  for (const key in object) {
    if (Object.hasOwn(object, key)) {
      if (key === keyToDelete) {
        delete object[key];
      } else if (typeof object[key] === 'object') {
        object[key] = deleteKey(object[key], keyToDelete);
      }
    }
  }
  return object;
};
