import React, { useCallback } from 'react';

import CustomIconButton from '../../Buttons/IconButton';

import {
  iconPlay,
  iconPauseLeft,
  iconReplay,
  iconRepeatArrow
} from '../../icons/GeneralSVG';

import { Rewind10 } from '../../icons/Rewind10';

interface Props {
  finished: boolean;
  playing: boolean;
  loop: boolean;
  jumpBack(): void;
  toggleLoop(): void;
  replay(): void;
  playPause(): void;
  shortCutTips?: any;
}

export const PlaybackButtons: React.ComponentType<Props> = React.memo(props => {
  const {
    finished,
    playing,
    loop,
    jumpBack,
    toggleLoop,
    replay,
    playPause,
    shortCutTips
  } = props;

  const handlePlayButtonClick = useCallback(() => {
    finished ? replay() : playPause();
  }, [finished, replay, playPause]);

  return (
    <>
      <CustomIconButton
        aria-label={playing ? 'pause' : 'play'}
        colorVariant="ghost3"
        size={'regular'}
        path={finished ? iconReplay : playing ? iconPauseLeft : iconPlay}
        onClick={handlePlayButtonClick}
        svgProperties={{
          svgProps: { viewBox: '0 0 32 32' },
        }}
        title={
          shortCutTips &&
          `${finished ? 'Replay' : playing ? 'Pause' : 'Play'} (${
            shortCutTips.play
          })`
        }
      />
      <CustomIconButton
        aria-label={'jump back'}
        colorVariant="ghost3"
        size={'regular'}
        path={''}
        svgProperties={{
          svgProps: {
            component: Rewind10,
            color: 'inherit',
            viewBox: '0 0 32 32'
          }
        }}
        onClick={jumpBack}
        title={shortCutTips && `Go back 10 seconds (${shortCutTips.jumpBack})`}
      />
      <CustomIconButton
        aria-label="toggle loop"
        colorVariant="ghost3"
        size={'regular'}
        onClick={toggleLoop}
        path={iconRepeatArrow}
        active={loop}
        svgProperties={{
          svgProps: { viewBox: '0 0 32 32' },
        }}
        title={shortCutTips && `Loop (${shortCutTips.loop})`}
      />{' '}
    </>
  );
});
