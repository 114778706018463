import { useCallback, useEffect, useRef, useState } from 'react';
import { TabsProps } from '@material-ui/core';

export const useTabs = ({
  tabs,
  initialTab = undefined,
  onTabChange = undefined
}) => {
  const initialTabIndex =
    typeof initialTab === 'number'
      ? initialTab
      : initialTab
      ? Math.max(0, tabs.indexOf(initialTab))
      : 0;

  const [value, setValue] = useState<number>(initialTabIndex);

  let canceled = useRef(null);
  let resume = useRef(setValue);
  resume.current = setValue;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, val: any) => {
      if (typeof onTabChange === 'function') {
        canceled.current = false;
        onTabChange(tabs[val], val, {
          cancel: () => {
            canceled.current = true;
          },
          resume: () => {
            resume.current(val);
          }
        });

        if (canceled.current === true) {
          // prevent navigation
          return;
        }
      }
      setValue(val);
    },
    [setValue, tabs, onTabChange]
  );

  useEffect(() => {
    if (!tabs[value] || tabs[value]?.visible === false) {
      handleChange(
        null,
        tabs.findIndex(t => t.visible !== false)
      );
    }
  }, [tabs, value, handleChange]);

  const actionCallback = useCallback(actions => {
    if (actions) {
      setTimeout(() => actions.updateIndicator(), 200);
    }
  }, []);

  return {
    activeTab: value,
    handleTabChange: handleChange as TabsProps['onChange'],
    actionCallback
  };
};
