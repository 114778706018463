import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

/* Import components */
import Button from '../../Buttons/Button';
import { Typography } from '../../typography/Typography';
import { ModalNoticeUI } from '../../Modal/ModalNoticeUI';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';

/**
 * @summary Delete forever prompt for trashbin
 * @author Simon
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      minHeight: 200
    },
    deleteButton: {
      marginLeft: theme.customSpacing.padding[12] // margin between 2 buttons
    }
  })
);

interface DeleteForeverPromptUIProps {
  multipleFiles: boolean;
  allFiles: boolean;
  onClickDelete: () => void;
  onCloseEvent: () => void;
}

const DeleteForeverPromptUI: React.ComponentType<DeleteForeverPromptUIProps> = (
  props
) => {
  const { onClickDelete, onCloseEvent, multipleFiles, allFiles } = props;

  const classes = useStyles();

  useGlobalSubmit(onClickDelete, false);

  return (
    <ModalNoticeUI
      onCloseEvent={() => onCloseEvent()}
      title={'Delete?'}
      footerRightComponent={
        <>
          <Button onClick={() => onCloseEvent()} text="Cancel" />

          <Button
            onClick={() => onClickDelete()}
            colorVariant={'filledDanger'}
            text="Delete"
            extraButtonProperties={{ classes: { root: classes.deleteButton } }}
          />
        </>
      }
    >
      <Typography color="contrast2">
        {allFiles
          ? 'All items '
          : multipleFiles
          ? 'These items '
          : 'This item '}
        will be deleted permanently.
        <br />
        You cannot undo this action.
      </Typography>
    </ModalNoticeUI>
  );
};

export default DeleteForeverPromptUI;
