import { mapCookiesToState } from '../../middleware/cookie-consent-middleware';
import {
  SetableCookieConsentState,
  ConsentState,
  consentOptions,
  cookieTypesShort,
  CookieConsentState,
  setableCookieTypes
} from './state';
import { compareConsentVersions, getCurrentConsentVersion } from './utils';
import { FSA } from '../../flux-standard-action';
import { actions } from './actions';

import { selectors } from './selectors';

const fake = () => ({} as SetableCookieConsentState);

const mc2s = () => (mapCookiesToState ? mapCookiesToState() : fake());

const essentialState: ConsentState = {
  state: consentOptions.REQUIRED,
  version: undefined,
  timestamp: undefined
};

const getInitialState = () => {
  return {
    [cookieTypesShort.ESSENTIAL]: essentialState,
    ...mc2s(),
    currentVersion: null
  } as CookieConsentState;
};

export const reducer = (
  stateKey = 'cookieConsent',
  consentVersion = undefined
) => {
  const getStateSlice = state => {
    const slice: CookieConsentState = state[stateKey];
    if (!slice) {
      throw new Error('could get cookie consent state slice');
    }
    return slice;
  };

  const isVersionMismatch = state => {
    const slice = getStateSlice(state);

    return (
      Object.keys(setableCookieTypes).filter(cat => {
        return (
          // !slice[cat] ||
          // !!slice[cat].version !== !!slice.currentVersion ||
          // NOTE: only compare versions if there are versions
          slice[cat].version &&
          compareConsentVersions(slice[cat].version, slice.currentVersion) !== 0
        );
      }).length > 0
    );
  };

  const hasChosen = state => {
    const slice = getStateSlice(state);

    let chosen =
      Object.keys(setableCookieTypes).filter(cat => {
        return !slice[cat] || slice[cat].state === consentOptions.UNSET;
      }).length === 0;

    return chosen;
  };

  const getConsentState = state => {
    const slice = getStateSlice(state);
    return slice;
  };

  Object.assign(selectors, {
    isVersionMismatch,
    hasChosen,
    getConsentState
  });

  //   const setConsentState = (
  //     oldState,
  //     categories,
  //     choice = undefined,
  //     timestamp
  //   ) => {
  //     return categories.reduce((acc, category) => {
  //       return {
  //         ...acc,
  //         [category]: {
  //           state: choice || oldState[category].state,
  //           version: oldState.currentVersion,
  //           timestamp: timestamp
  //         }
  //       };
  //     }, oldState);
  //   };

  const currentVersion = getCurrentConsentVersion(consentVersion);

  const configuredInitialState = {
    ...getInitialState(),
    currentVersion
  };

  const reducer = (
    state: CookieConsentState = configuredInitialState,
    action: FSA
  ) => {
    const { type, payload = {} } = action;

    switch (type) {
      case actions.SYNCHRONIZE_CONSENT:
        return {
          ...state,
          ...payload
        };
      // these are handled in the middleware
      case actions.GIVE_CONSENT:
      case actions.REJECT_CONSENT:
      case actions.CLEAR_CONSENT:
      case actions.UPDATE_CONSENT_VERSION:
      default:
        return state;
    }
  };

  return { [stateKey]: reducer };
};
