import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import {
  countWords,
  getLongestWord,
  getWords,
  reverseObject,
  reverseString,
  TextsType,
  wordsToString
} from '../truncateHelper';
interface InterfaceUseTruncateProps {
  str: string;
  fontsLoaded?: boolean;
  widthLimit?: number;
  lines: number;
  suffixComponentRef?: React.RefObject<HTMLSpanElement>;
}

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

function UseTruncateText(props: InterfaceUseTruncateProps) {
  const [stringsArray, setstringsArray] = React.useState<string[]>([]);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { str, fontsLoaded, widthLimit, lines, suffixComponentRef } = props;

  /* method to get the styles of the container element */
  const getStyles = React.useCallback(() => {
    const style = window.getComputedStyle(containerRef.current, null);

    const font = [
      style['font-weight'],
      style['font-style'],
      style['font-size'],
      style['font-family']
    ].join(' ');
    const padding =
      parseInt(style['padding-right'].replace(/\D/g, ''), 10) +
      parseInt(style['padding-left'].replace(/\D/g, ''), 10);

    return { font, padding };
  }, []);

  /* method to get the total width in pixels of the string that needs to be truncated */
  const getWidthOfText = React.useCallback((fullStr: string, font: string) => {
    ctx.font = font;
    return Math.ceil(ctx.measureText(fullStr).width);
  }, []);

  /* method to get the container width minus padding 
    - if the container has a width limit, truncate the text only when the limit is reached
   */
  const getContainerWidth = React.useCallback(
    (padding: number) => {
      const suffixWidth =
        (suffixComponentRef.current &&
          suffixComponentRef.current.clientWidth) ||
        0;

      /** TODO: the suffix should only take up the width of the last line */
      const flexibleWidth =
        containerRef.current.clientWidth - padding - suffixWidth;

      return widthLimit ? widthLimit - padding : flexibleWidth;
    },
    [suffixComponentRef, widthLimit]
  );

  /** truncate a text to display on a single line */
  const truncateText = React.useCallback(
    (fullStr: string, maxWidth: number, font) => {
      const ellipsis = '…';
      const textWidth = getWidthOfText(fullStr, font);

      if (textWidth > maxWidth) {
        let frontStr = '';
        let backStr = '';
        let newStr = '';
        for (let i = 0; i < fullStr.length; i++) {
          frontStr += fullStr[i];
          backStr += fullStr[fullStr.length - i - 1];
          newStr = frontStr + ellipsis + backStr;

          if (getWidthOfText(newStr, font) > maxWidth) {
            do {
              backStr = backStr.slice(0, -1);
              newStr = frontStr + ellipsis + backStr;
            } while (
              getWidthOfText(newStr, font) > maxWidth &&
              backStr.length > 0
            );
            break;
          }
        }

        return frontStr + ellipsis + reverseString(backStr);
      }
      return fullStr;
    },
    [getWidthOfText]
  );

  /** truncate a text to display on multiple lines
   * - convert full string to an array of strings where each line fits into the container
   * - word-break: break-word
   */
  const truncateTextMultiple = React.useCallback(
    (words: string[], maxWidth: number, font) => {
      const ellipsis = '…';

      /* - truncate full string to display on multiple lines:
       * the first row: add words from start -> end
       * the remaining rows: add words from end -> start
       */
      let firstRow: string[] = [];
      /** store the remaining rows in reverse order  */
      let remainingRows: TextsType = {};

      for (let row = 0; row < lines; row++) {
        let startIndex = countWords(remainingRows);
        let newRow = [];

        // generate the first row
        if (row === 0) {
          for (let w = 0; w < words.length; w++) {
            words[w] && firstRow.push(words[w]);
            // break if the newRow width > maxWidth
            if (getWidthOfText(wordsToString(firstRow), font) > maxWidth) {
              do {
                firstRow.pop();
              } while (
                getWidthOfText(wordsToString(firstRow), font) > maxWidth
              );
              break;
            }
          }
        } else {
          // generate the remaining rows
          const restWords = words.slice(firstRow.length).reverse();
          if (restWords.length === 0) break;

          for (let w = 0; w < restWords.length - startIndex; w++) {
            const word = restWords[w + startIndex];
            word && newRow.push(word);
            if (getWidthOfText(wordsToString(newRow), font) > maxWidth) {
              do {
                newRow.pop();
                startIndex = Math.max(0, startIndex - 1);
              } while (getWidthOfText(wordsToString(newRow), font) > maxWidth);
              remainingRows[row] = newRow.reverse();
              break;
            }

            // if the loop is done and `newRow`'s width < maxWidth, should save the `newRow`
            if (w + startIndex === restWords.length - 1 && newRow.length > 0) {
              remainingRows[row] = newRow.reverse();
              break;
            }
          }
        }
      }

      const remainingRowsLength = Object.keys(remainingRows).length;
      const combined = {
        ...remainingRows,
        [remainingRowsLength + 1]: firstRow
      };
      const allRows = reverseObject(combined);

      /** covert the texts object to an array of strings
       * - add ellipsis if
       *    - it is the last row
       *    - the fullStr has been truncated or the last row width > container width
       */
      const stringsArray = Object.keys(allRows).reduce(
        (acc: string[], k: string) => {
          if (
            !!allRows[Number(k) - 1] &&
            !allRows[Number(k) + 1] &&
            (countWords(allRows) < words.length ||
              getWidthOfText(wordsToString(allRows[k]) + ellipsis, font) >
                maxWidth)
          ) {
            do {
              allRows[k] = allRows[k].slice(1);
            } while (
              getWidthOfText(wordsToString(allRows[k]) + ellipsis, font) >
              maxWidth
            );

            return [...acc, ellipsis + wordsToString(allRows[k])];
          }
          return [...acc, wordsToString(allRows[k])];
        },
        []
      );
      //console.log('all lines:', stringsArray);

      return stringsArray;
    },
    [getWidthOfText, lines]
  );

  // try to detect containerWidths when component rerenders
  const [containerWidth, setContainerWidth] = useState(null);
  // NOTE: this needs to run after every render
  // eslint-disable-next-line
  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  });

  /* method that get's called when screen is resized to update the truncated string */
  const forcedUpdate = React.useCallback(() => {
    const fullStr = str ? str.toString().trim() : '';
    const style = getStyles();
    const containerWidth = getContainerWidth(style.padding);
    /** if the end-of-line space is too small for a letter, the letter wraps to the next line, this width should be in the calculation */
    const maxWidth = containerWidth - getWidthOfText('W', style.font);
    const longestWord = getLongestWord(fullStr);
    let truncated: string[];
    if (lines === 1) {
      truncated = [truncateText(fullStr, maxWidth, style.font)];
    } else if (getWidthOfText(longestWord, style.font) > maxWidth) {
      truncated = truncateTextMultiple(fullStr.split(''), maxWidth, style.font);
    } else {
      truncated = truncateTextMultiple(getWords(fullStr), maxWidth, style.font);
    }

    setstringsArray(truncated);
  }, [
    getContainerWidth,
    getStyles,
    getWidthOfText,
    lines,
    str,
    truncateText,
    truncateTextMultiple
  ]);

  React.useEffect(() => {
    forcedUpdate();
    window.addEventListener('resize', forcedUpdate, true);
    return () => {
      window.removeEventListener('resize', forcedUpdate, true);
    };
  }, [str, fontsLoaded, forcedUpdate, containerWidth]);

  return { containerRef, stringsArray };
}

export default UseTruncateText;
