import React from 'react';
import { ExportOptionRow, ExportOptionRowProps } from './ExportOptionRow';
import CheckBoxStateless from '../../Buttons/CheckBoxStateless';

interface Props extends ExportOptionRowProps {
  value: { id: string; display_name: string }[];
  options: { id: string; display_name: string }[];
  onChange: (e) => void;
}

export const ExportMultiSelectCheckBoxes = (props: Props) => {
  const { value, onChange, options } = props;

  return (
    <ExportOptionRow
      {...props}
      component={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '175px', // can show about 5.5 playlists
            overflow: 'scroll'
          }}
        >
          {options.map((opt) => (
            <CheckBoxStateless
              key={opt.id}
              checked={value.findIndex((v) => v.id === opt.id) > -1}
              text={opt.display_name}
              onChange={() => onChange(opt)}
            />
          ))}
        </div>
      }
    />
  );
};
