import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import { actionTypes, initialState, KeyConfig, reducer } from './reducer';
import { makeIdentifier } from './utils';
export { isMacOs } from 'react-device-detect';
import { usePasteUrlFromClipboard } from './usePasteUrlFromClipboard';

interface ShortCutRootContext {
  registerKey(config: KeyConfig, path?: string): void;
  unregisterKey(config: KeyConfig, path?: string): void;
  registerBoundary(
    el: HTMLElement,
    path?: string,
    grabFocus?: boolean,
    isolate?: 'down'
  ): void;
  unregisterBoundary(
    el: HTMLElement,
    path?: string,
    returnFocus?: string
  ): void;
  keys: { [key: string]: { [key: string]: KeyConfig } };
}
export const shortCutRootContext =
  React.createContext<ShortCutRootContext>(null);

type ShortCutsRootProviderProps = {};

export const ShortCutsRootProvider: React.FC<
  React.PropsWithChildren<ShortCutsRootProviderProps>
> = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   console.info('DEBUG state', state);
  // }, [state]);

  const { keys, boundaries, isolate } = state;

  const [keyboardTarget, setKeyboardTarget] = useState(null);

  // console.info('DEBUG keyboardTarget', keyboardTarget);

  useEffect(() => {
    if (!keyboardTarget && Object.keys(boundaries).length) {
      setKeyboardTarget(Object.keys(boundaries)[0]);
    }
  }, [keyboardTarget, setKeyboardTarget, boundaries]);

  const registerBoundary = useCallback(
    (el, path, grabFocus, isolate) => {
      dispatch({
        type: actionTypes.REGISTER_BOUNDARY,
        payload: {
          boundary: path,
          el,
          isolate
        }
      });

      if (grabFocus) {
        setKeyboardTarget((keyboardTarget) =>
          !keyboardTarget?.includes(path) ? path : keyboardTarget
        );
      }
    },
    [dispatch, setKeyboardTarget]
  );

  const unregisterBoundary = useCallback(
    (el, path, returnFocus) => {
      dispatch({
        type: actionTypes.UNREGISTER_BOUNDARY,
        payload: {
          boundary: path,
          el
        }
      });
      if (returnFocus) {
        setKeyboardTarget((keyboardTarget) =>
          path === keyboardTarget ? returnFocus || null : keyboardTarget
        );
      }
    },

    [dispatch, setKeyboardTarget]
  );

  useEffect(() => {
    if (
      boundaries &&
      keyboardTarget &&
      !Object.keys(boundaries).includes(keyboardTarget)
    ) {
      // console.info('DEBUG keyboardtarget', keyboardTarget);
      const inTree = Object.keys(boundaries)
        .filter((p) => keyboardTarget.includes(boundaries[p]))
        .sort((p1, p2) => p2.length - p1.length);

      if (inTree[0]) {
        setKeyboardTarget(inTree[0]);
      } else {
        setKeyboardTarget(null);
      }
    }
  }, [keyboardTarget, setKeyboardTarget, boundaries]);

  const { clipboardUrl, onClearClipboardUrl } = usePasteUrlFromClipboard();
  const handleKeyPress = useCallback(
    (ev) => {
      const focusEl = document.activeElement;

      if (
        focusEl &&
        ev.code !== 'Escape' &&
        ['TEXTAREA', 'SELECT', 'INPUT'].includes(focusEl.nodeName) &&
        focusEl.getAttribute('type') !== 'checkbox'
      ) {
        // console.warn('Skipping shortcuts system... focused element is input-like', focusEl?.nodeName, { focusEl });
        return;
      }

      const { ctrlKey: ctrl, shiftKey: shift, altKey: alt, metaKey: meta } = ev;
      const id = makeIdentifier(ev.code, { ctrl, shift, alt, meta });
      const shortcuts = keys[id];

      // console.info('DEBUG shortcut:ev', {
      //   ev,
      //   id,
      //   keys: state.keys,
      //   shortcuts,
      //   keyboardTarget,
      //   isolate
      // });

      if (id === 'KeyC__ctrl') {
        onClearClipboardUrl();
      }
      // track if it's pasting a url from clipboard, if so should skip action to paste assets
      if (id === 'KeyV__ctrl' && clipboardUrl.current) {
        console.info('DEBUG - KeyV__ctrl url', clipboardUrl.current);
        return;
      }

      const inTree =
        shortcuts &&
        Object.keys(shortcuts)
          .filter((p) => {
            return keyboardTarget?.startsWith(p);
          })
          .reduce((acc, val) => {
            acc[val] = shortcuts[val];
            return acc;
          }, {});

      if (inTree && Object.keys(inTree).length > 0) {
        // prevent blocking `paste` event
        if (id !== 'KeyV__ctrl') {
          ev.preventDefault();
        }
        ev.stopPropagation();
        ev.stopImmediatePropagation();

        const all = Object.keys(inTree)
          ?.sort((p1, p2) => p2.split('.').length - p1.split('.').length)
          .filter((p) => {
            return !isolate.length || p.includes(isolate[0]);
          });

        // console.info('DEBUG ev', { inTree, all });

        const makeNext = (sc) => {
          return () => {
            let p = sc[0];
            if (p) {
              shortcuts[p].action?.(ev, makeNext(sc.slice(1)));
            }
          };
        };

        // wait till clipboard get cleared
        if (id === 'KeyV__ctrl' && !clipboardUrl.current) {
          // the reason to set a timer here is: `keydown` take priority over `paste` event,
          // so when copy a url after copied some files,
          // `keydown` event should wait till clipboardUrl get stored first in `paste` event handler
          setTimeout(() => {
            if (!clipboardUrl.current) {
              console.log('timer pasting files');
              makeNext(all)();
            }
          }, 50);
        } else {
          makeNext(all)();
        }
      }
    },
    [keys, keyboardTarget, isolate]
  );

  const handleFocus = useCallback(
    (ev) => {
      const ancestors = [];
      let node = ev.target;
      while (node && node !== window.document.body) {
        ancestors.push(node);
        node = node.parentNode;
      }

      const hardCoded = ancestors.find((a) => {
        return a.dataset?.['shortcutboundary'];
      })?.dataset['shortcutboundary'];

      if (hardCoded) {
        setKeyboardTarget(hardCoded);
        return;
      }

      const inTree = Object.keys(boundaries)
        .filter((p) => ancestors.includes(boundaries[p]))
        .sort((p1, p2) => p2.length - p1.length);

      setKeyboardTarget((keyboardTarget) =>
        ev.type !== 'blur' || !keyboardTarget?.includes(inTree[0])
          ? inTree[0]
          : keyboardTarget
      );
    },
    [boundaries, setKeyboardTarget]
  );

  useEffect(() => {
    const keyListenerOptions = { capture: true };
    const clickListenerOptions = { capture: true };

    window.addEventListener('keydown', handleKeyPress, keyListenerOptions);
    window.addEventListener('click', handleFocus, clickListenerOptions);
    window.addEventListener('blur', handleFocus, clickListenerOptions);
    return () => {
      window.removeEventListener('keydown', handleKeyPress, keyListenerOptions);
      window.removeEventListener('click', handleFocus, clickListenerOptions);
      window.removeEventListener('blur', handleFocus, clickListenerOptions);
    };
  }, [handleKeyPress, handleFocus]);

  const unregisterKey = useCallback(
    (keyConfig, path) => {
      dispatch({
        type: actionTypes.UNREGISTER_KEY,
        payload: { path, keyConfig }
      });
    },
    [dispatch]
  );

  const registerKey = useCallback(
    (keyConfig, path) => {
      dispatch({
        type: actionTypes.REGISTER_KEY,
        payload: { path, keyConfig }
      });
    },
    [dispatch]
  );

  const value = useMemo(() => {
    return {
      registerBoundary,
      unregisterBoundary,
      registerKey,
      unregisterKey,
      keys
    };
  }, [registerBoundary, unregisterBoundary, registerKey, unregisterKey, keys]);

  return (
    <shortCutRootContext.Provider value={value}>
      {children}
    </shortCutRootContext.Provider>
  );
};
