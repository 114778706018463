import React, { forwardRef, useRef } from 'react';

import mergeRefs from '../../../helpers/MergeRefs';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// Convienience wrapper for scrollbar. Typings for scrollbar.
/// Check out storybook for implementation.
/// Scrollbars has more properties and functions so feel free to extend this
/// as needed. Check for props https://github.com/malte-wessel/react-custom-scrollbars
/// </Summary>

let globalScrollbarWidth = null;

// Here we modify the negative margins,
// So when the user zooms the offset is recalculated
export const InnerView: React.ComponentType<{
  style: any;
  ref?: React.Ref<HTMLDivElement> | React.LegacyRef<HTMLDivElement>;
}> = forwardRef<HTMLDivElement, { style: any }>((props, ref) => {
  const containerRef = useRef(null);

  /*    
    The scrollbar component calculates the marginRight and marginBotom based on scrollbarWidth = (div.offsetWidth - div.clientWidth). The scrollbars are hidden by setting a negative margin:
      marginRight: scrollbarWidth ? -scrollbarWidth : 0,
      marginBottom: scrollbarWidth ? -scrollbarWidth : 0.
    When zooming in/out these margins are only updated on refresh, as workaround we calculate them ourselves. 
    */
  const [scrollbarWidth, setScrollbarWidth] =
    React.useState(globalScrollbarWidth);

  // original styles passed in by Scrollbars renderprops
  const style = { ...props.style };
  // hookup listener for when user zoom (and window size changes)
  const onResize = React.useCallback(
    (ev) => {
      setScrollbarWidth(
        // sometimes the margin is 2px off, maybe has to do with roudings.
        containerRef.current.offsetWidth + 2 - containerRef.current.clientWidth
      );
    },
    [setScrollbarWidth]
  );

  React.useEffect(() => {
    window.addEventListener('resize', onResize);
    if (containerRef.current) {
      globalScrollbarWidth =
        containerRef.current.offsetWidth + 2 - containerRef.current.clientWidth;
      setScrollbarWidth(
        // sometimes the margin is 2px off, maybe has to do with roudings.
        globalScrollbarWidth
      );
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, containerRef]);

  // memoize the margins
  const offset = React.useMemo(() => {
    return scrollbarWidth ? -scrollbarWidth : 0;
  }, [scrollbarWidth]);

  // overwrite marginRight/marginBottom
  style.marginRight = offset;
  style.marginBottom = offset;

  return <div {...props} style={style} ref={mergeRefs(ref, containerRef)} />;
});
