import React from 'react';
import { isMobile } from 'react-device-detect';

import Container from './ContainerPreview';
import { FullScreen } from './fullscreen';

const customStyles = {
  display: 'flex',
  flex: '1 1 0%',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0
};

export interface PreviewHOCProps {
  /* callback handler for when full screen button is pressed */
  toggleFullscreenAction(): void;
  // /* callback handler for when theater mode button is pressed */
  /* current full screen state */
  fullscreen: boolean;
  fullscreenRequested: boolean;
}

export function previewWrapper<P, Q extends P & PreviewHOCProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Q> {
  return (props) => {
    const { fullscreen, fullscreenRequested, toggleFullscreenAction } = props;
    return (
      <Container height="100%" width="100%">
        <FullScreen
          enabled={fullscreen}
          requested={fullscreenRequested}
          style={isMobile ? {} : customStyles}
          onUncontrolledFullscreenLeave={toggleFullscreenAction}
        >
          <WrappedComponent {...props} />
        </FullScreen>
      </Container>
    );
  };
}
