import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import base2BG from '../assets/images/theme/Base2.jpg';
import base1BG from '../assets/images/theme/Base1.jpg';
import { toDataUrl } from '../utils/toDataUrl';
import { useClassName } from '../utils/useClassName';

/**
 * @summary Displays a div with custom settings, the backgroundcolor used defines the theme for the component and children
 * @author Simon, Machiel, Michiel, Lorenzo
 */

/**
You can use this to get the styles of the surface component instead of having to render the component. 
It's a workaround for some MU problems. 

Usage:

`const surfaceClasses = useSurfaceClasses({floating: true});`

`<div className = {surfaceClasses}>`
*/
export function useSurfaceClasses(props: SurfaceProperties): string {
  const {
    background = 'base2',
    floating = false,
    floatingHeight = 10,
    cornerRadius = 'mediumEmphasis',
    gradient = 'none'
  } = props;
  const styles = useStyles(props);
  return `${styles.root} ${styles[`bg-${background}`]} ${floating &&
    styles.floating} ${floating && styles[`floating-${floatingHeight}`]} ${
    styles[`borderRadius-${cornerRadius}`]
  } ${gradient === 'lamp' ? styles[`gradient-lamp-${background}`] : undefined}`;
}

// warm the cache
toDataUrl(base1BG);
toDataUrl(base2BG);

const backgroundImages = {
  base1: `url('${toDataUrl(base1BG)}')`,
  base2: `url('${toDataUrl(base2BG)}')`
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      // base
      root: {
        minHeight: 20,
        overflow: 'hidden',
        boxSizing: 'content-box', // important for double border rendering with jpg gradient.
        position: 'relative' // used to render the overlay_dark correctly
      },
      // #region background colors
      ...Object.keys(theme.customPalette.surface).reduce((acc, val) => {
        acc[`bg-${val}`] = {
          backgroundColor: theme.customPalette.surface[val]
        };
        return acc;
      }, {}),
      // #endregion
      /**
       *
       */
      floating: {
        border: `1px solid ${theme.customPalette.line.onBase1.shadow1}`,
        // apply a new inside border line.
        '&::after': {
          content: '""', // important in order to display the element
          // #region position the element
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          zIndex: 1,
          // #endregion
          pointerEvents: 'none',
          // inside border
          border: `1px solid ${theme.customPalette.line.onBase1.contrast1}`
        }
      },
      ...[1, 2, 10, 24].reduce((acc, val) => {
        acc[`floating-${val}`] = {
          ...theme.customMixins.surfaceShadows({
            elevation: val
          })
        };
        return acc;
      }, {}),
      /**
       *
       */
      border: {
        border: `1px solid ${theme.customPalette.line.onBase1.contrast3}`,
        boxSizing: 'border-box'
      },
      borderAppended: {
        border: `1px solid ${theme.customPalette.line.onBase1.contrast3}`,
        boxSizing: 'border-box',
        borderTop: 'none'
      },
      // border radius
      ...Object.keys(theme.surfaceCorners).reduce((acc, val) => {
        acc[`borderRadius-${val}`] = {
          borderRadius: theme.surfaceCorners[val],
          '&::after': {
            borderRadius: theme.surfaceCorners[val]
          }
        };
        return acc;
      }, {}),
      rounded: { '&$borderRadius': {} },
      roundedBottom: {
        '&$borderRadius': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          '&::after': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }
        }
      },
      roundedTop: {
        '&$borderRadius': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          '&::after': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }
        }
      },
      squared: {
        '&$borderRadius': {
          borderRadius: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          '&::after': {
            borderRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }
        }
      },
      // #endregion
      // #region overlay
      overlay_dark: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: theme.customPalette.backgroundAccent.blackAccent1,
        pointerEvents: 'none'
      },
      // gradient lamp
      ...Object.keys(backgroundImages).reduce((acc, val) => {
        acc[`gradient-lamp-${val}`] = {
          backgroundPosition: 'top',
          backgroundClip: 'padding-box',
          backgroundRepeat: 'no-repeat',
          backgroundImage: backgroundImages[val]
        };
        return acc;
      }, {})
      // #endregion
    }),
  { name: 'C3Surface' }
);

export interface SurfaceProperties {
  /** Background for `Surface`. Defaults to `TODO`, overwrite the class    */
  background?:
    | 'base1'
    | 'base2'
    | 'black1'
    | 'shade1'
    | 'shade2'
    | 'shade3'
    | 'shade4 '
    | 'transparent';

  /** determines the roundness of the corners, when prop `cornerstyle` is set, defaults to `mediumEmphasis` */
  cornerRadius?: 'lowEmphasis' | 'mediumEmphasis' | 'highEmphasis';

  corners?: 'rounded' | 'squared' | 'roundedTop' | 'roundedBottom';

  /** Librarydeselectoverride prevent event handlers to deselect items in the library, for instance when clicking an item in the commandbar, we would prefer not to deselect the assets   */
  dataType?: 'librarydeselectoverride' | 'none';

  /** When true, draws shadows around the `Surface` and mimics a bevel by drawing a double border  */
  floating?: boolean;

  /** When true, a thin semitransparent border  */
  border?: boolean | 'appended';

  /** Determines length of shadow, when the `floating` prop is true, defaults to 10 */
  floatingHeight?: 2 | 10 | 24;

  /** Gradient image applied to the surface. When using lamp, a baked image will be used based on the `background` property.
   *
   * *Warning!* : Gradient `lamp` (jpg) overwrites backgroundcolor.    */
  gradient?: 'lamp' | 'none';

  /** Overlaying div for all children. */
  overlay?: 'dark' | 'none';

  /** Css Classes applied to the root `div` element */
  className?: string;

  style?: React.CSSProperties;
}

const SurfaceBase: React.FC<React.PropsWithChildren<SurfaceProperties>> = React.memo(props => {
  const classes = useStyles(props);
  const {
    className = '',
    dataType = '',
    floating = false,
    floatingHeight = 10,
    overlay = 'none',
    border = false,
    corners = 'squared',
    background = 'base2',
    cornerRadius = 'mediumEmphasis',
    gradient = 'none',
    style
  } = props;

  const cn = useClassName(
    className,
    classes.root,
    classes[`bg-${background}`],
    gradient === 'lamp' && classes[`gradient-lamp-${background}`],
    floating && classes.floating,
    floating && classes[`floating-${floatingHeight}`],
    border === true
      ? classes.border
      : border === 'appended'
      ? classes.borderAppended
      : undefined,

    classes[`borderRadius-${cornerRadius}`],
    classes[corners]
  );

  return (
    <div style={style} data-type={dataType} className={cn}>
      {overlay !== 'none' && <div className={classes[`overlay_${overlay}`]} />}

      {props.children}
    </div>
  );
});

/** By using this wrapper we make sure that classes used in the Surface component, also reflect the injected theme */
export const Surface: React.FC<React.PropsWithChildren<SurfaceProperties>> = SurfaceBase;

// export the wrapper
export default Surface;
