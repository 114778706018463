import { ProjectRoleType, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface ProjectMember extends ResourceBase {
  project_member_role: ProjectRoleType;

  relationships: {
    account: Related<'account'>;
    project: Related<'project'>;
  };
}

export const projectMember: RawModel = {
  type: 'project-member',
  plural: 'project-members',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    project_member_role: 'string'
    // display_image: 'URL'
  },
  relationships: {
    project: {
      type: 'project',
      binding: ['one', 'many'],
      reverse: 'members',
      required: [true, false]
    },
    account: {
      type: 'account',
      binding: ['one', 'many'],
      reverse: 'project-members',
      required: [true, false]
    }
  }
};
