import { Comment } from '@cube3/common/model/schema/resources/comment';
import { useMemo } from 'react';

/** group comments by `start` time */
export const useGroupReviewComments = (comments: Comment[], range: number) => {
  const groupedByStart = useMemo(() => {
    if (!comments?.length) return undefined;
    return comments.reduce((acc, comment) => {
      const start = comment.start;
      if (start !== undefined) {
        if (!acc[start]) {
          acc[start] = [];
        }
        // to solve float number issue: start at 1.9199999989999998 and start at 1.919999998 should be in the same group
        const existedKey = Object.keys(acc).find(
          (k) =>
            k !== undefined &&
            start < Number(k) + range &&
            start >= Number(k) - range
        );
        if (existedKey) {
          (acc[existedKey] || []).push(comment);
        } else {
          acc[start].push(comment);
        }
      }

      return acc;
    }, {});
  }, [comments]);

  return groupedByStart;
};
/** length = 3 by default */
// export const keepToWantedDecimalPlaces = (
//   value: number | string,
//   length = 3
// ) => {
//   const [integer, decimal] = value.toString().split('.');
//   return Number(integer + (decimal?.slice(0, length) || 0));
// };
