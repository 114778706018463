import Client from '../../../../wrapped-cube-client';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { ContractKeyGroup } from '@cube3/common/model/schema/resources/contract-key-group';
import { ContractValue } from '@cube3/common/model/schema/resources/contract-value';
import { getFormInitialValues, getFormValues } from 'redux-form';
import { dehydrateValue } from '../../../components/Hooks/metadataHooks';

const isEmpty = (val, arrayCheck = false) => {
  return (
    (val ?? null) === null || (arrayCheck && Array.isArray(val) && !val.length)
  );
};
const isNotEmpty = (val, arrayCheck = false) => !isEmpty(val, arrayCheck);

export const handleContractFieldChanges = ({
  dirtyGroups,
  state,
  contractId,
  invalidated
}) => {
  return (res) => {
    const groupPromises = dirtyGroups.map((dg) => {
      const values = getFormValues(dg.id)(state);
      const initialValues = getFormInitialValues(dg.id)(state);

      const affectedValues = Array.from(
        new Set([...Object.keys(values), ...Object.keys(initialValues)])
      );

      const fields = Object.keys(
        state.resourceNodes['contract-key-group'] || {}
      )
        .map((k) => state.resourceNodes['contract-key-group'][k])
        .filter((g) => g.id === dg.id)
        .flatMap((g: ContractKeyGroup) => g.keys);

      const contractValues = Object.keys(
        state.resourceNodes['contract-value'] || {}
      )
        .map((k) => state.resourceNodes['contract-value'][k])
        .filter(
          (v: ContractValue) =>
            v.contract_id === contractId &&
            affectedValues.includes(`key_${v.key_id}`)
        );

      const createValues = [];
      const updateValues = [];
      const deleteValues = [];

      Object.keys(values).forEach((v) => {
        const field = fields.filter((f) => `key_${f.id}` === v)[0];

        if (!field) {
          console.warn(`skipping field ${v}`);
          return;
        }

        // NOTE use of of ?? nulish operator, normalizes undefined and null to null
        if (isNotEmpty(values[v], true) && isEmpty(initialValues[v])) {
          createValues.push({
            type: 'contract-value',
            key_id: v.replace(/(?:field_|key_)/, ''),
            value: dehydrateValue(
              values[v],
              field.value_type,
              field.json_schema
            )
          });
          return;
        }

        if (isEmpty(values[v], true) && isNotEmpty(initialValues[v])) {
          const contractValue = {
            type: 'contract-value',
            id: contractValues.filter((cv) => v === `key_${cv.key_id}`)[0].id
          };
          deleteValues.push(contractValue);
          invalidated.push(contractValue as ResourceIdentifier);
          return;
        }

        if (isNotEmpty(values[v], true) && isNotEmpty(initialValues[v])) {
          if (
            dehydrateValue(values[v], field.value_type, field.json_schema) !==
            dehydrateValue(
              initialValues[v],
              field.value_type,
              field.json_schema
            )
          ) {
            const contractValue = {
              type: 'contract-value',
              id: contractValues.filter((cv) => v === `key_${cv.key_id}`)[0].id,
              key_id: v.replace(/(?:field_|key_)/, ''),
              value: dehydrateValue(
                values[v],
                field.value_type,
                field.json_schema
              )
            };
            updateValues.push(contractValue);
            invalidated.push(contractValue as ResourceIdentifier);
            return;
          }
        }
      });

      const deletePromises = deleteValues.map((v) =>
        Client.delete(v.type, v.id)
      );

      const createPromises = createValues.map((v) =>
        Client.post(v.type, v, {
          type: 'contract',
          id: contractId,
          field: 'contract-values'
        })
      );

      const updatePromises = updateValues.map((v) => Client.update(v.type, v));

      return Promise.all([
        ...deletePromises,
        ...createPromises,
        ...updatePromises
      ]);
    });

    return Promise.all(groupPromises);
  };
};
