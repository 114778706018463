import React from 'react';
import ImageLoader from './ImageLoader';

const Image = ({
  imagePath = undefined,
  src = undefined,
  background = undefined,
  fallBackImage = undefined,
  alt = undefined,
  placeholder = undefined,
  dimensions = undefined,
  fullSize = false,
  floatingEffect = false,
  className = undefined
}) => {
  return (
    <ImageLoader
      src={src || imagePath}
      fallback={fallBackImage}
      alt={alt || 'no alt text provided'}
      background={background}
      peekUrl={placeholder}
      dimensions={dimensions}
      fullSize={fullSize}
      floatingEffect={floatingEffect}
      className={className}
    />
  );
};

export default Image;
