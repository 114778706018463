import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { actionCreators } from '../../ducks/resource-nodes';
import { uuidv4 } from '../../../utils/uuid';
import { RequestStatuses, selectors } from '../../ducks/request-status';
import { FSA } from '../../flux-standard-action';
import { addRequestStatusHash } from '../../middleware/request-status-middleware';
import { FormCompatibleError } from '../../../wrapped-cube-client';

type ActionDecorator<T extends FSA> = (action: T) => T;

interface UseMutateResourceConfig<D extends FSA> {
  cacheInvalidator: (
    mutatedResource: ResourceIdentifier
  ) => Array<ResourceIdentifier> | null;
  actionDecorators?: ActionDecorator<D>[];
}
const emptyArray = [];
export const useMutateResource__ALPHA = ({
  cacheInvalidator,
  actionDecorators = emptyArray
}: UseMutateResourceConfig<any>) => {
  // const [creating, setCreating] = React.useState();
  const [watchAction, setWatchAction] = React.useState();

  const dispatch = useDispatch();

  const mutateManyResources = React.useCallback(
    (resources) => {
      const action = actionCreators.mutateManyResources(
        resources.map((r) => ({
          resource: r,
          invalidatesCache: cacheInvalidator ? cacheInvalidator(r) : emptyArray
        }))
      );
      const hash = `${action.type}__${uuidv4()}`;

      const decoratedAction = [
        ...actionDecorators,
        addRequestStatusHash(hash)
      ].reduce((a, dec) => dec(a), action);

      dispatch(decoratedAction);
      setWatchAction(decoratedAction);
      return hash;
    },
    [actionDecorators, cacheInvalidator, dispatch, setWatchAction]
  );

  const mutateStatus = useSelector(
    ({ requestStatus: state }: { requestStatus: {} }) =>
      selectors.getStatus(state, watchAction)
  );

  const createError = useSelector(
    ({ requestStatus: state }: { requestStatus: {} }) =>
      selectors.getErrors(state, watchAction)
  );

  const mutateHandler = React.useCallback(
    <T = any>(resource: Partial<T> | Partial<T>[]) => {
      const resources = [].concat(resource);

      if (!resource || resources.length < 1) {
        console.warn('Nothing to mutate');
        return;
      }

      return mutateManyResources(resources);
    },
    [mutateManyResources]
  );

  return [mutateHandler, mutateStatus, createError] as [
    typeof mutateHandler,
    RequestStatuses,
    FormCompatibleError
  ];
};
