import * as React from 'react';

// helpers
import { compose } from '../../../../../utils/component-helpers';
import { reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import moment from 'moment';
import { FieldArray } from 'redux-form';
// interfaces

import { ModalReceiverProps } from '../../../layout/Modals/ModalManager';

// constants
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

// main
import { EmailSuggestionsContainer } from '../../../../forms/EmailSuggestionContainer';
import LinkSettingsForm from '@cube3/ui/src/Prefabs/shareLink/Forms/LinkSettingsForm';

// ui
import {
  EmailMessagebox,
  EmailMessageBoxFormProps
} from '@cube3/ui/src/Prefabs/Forms/EmailMessageBox';

import ReviewLinkNewModalUI from '@cube3/ui/src/Prefabs/Reviewlink/Modals/ReviewLinkNewModalUI';

// HOC
import { useState } from 'react';

import { useModalActions } from '../../../layout/Modals/modalActions';
import { dateNormalizer } from '@cube3/ui/src/helpers/FieldNormalizers';

import {
  validRecipients,
  makeValidator,
  ComplexErrors
} from '../../../../forms/helpers/validators';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';

import { uuidv4 } from '@cube3/state/src/utils/uuid';

import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';

import withConnectedResources, {
  WithResourcesProps
} from '../../ShareLink/HOCs/withConnectedShares';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ReviewLinkSettingFormProps } from './ReviewLinkSettingsModal';
import { Feature, VerifyType } from '@cube3/common/model/resource-types';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useShareVerifyDefaults } from '../../ShareLink/hooks/useShareVerifyDefaults';

export interface FormRecipient {
  email_address: string;
  id: string;
}

interface AllFormFields
  extends EmailMessageBoxFormProps,
    ReviewLinkSettingFormProps {
  message_enabled: false;
  message: string;
}

const validateReviewlink = (values, props) => {
  return {
    emails: makeValidator([validRecipients])(
      values.emails,
      values,
      props,
      'emails'
    )
  };
};

const withReviewlinkValues = (Wrapped) => (props) => {
  const formId = React.useRef(uuidv4()).current;
  const values = useTypedSelector((state) => getFormValues(formId)(state));

  const workspace = useCurrentWorkspace()[1];

  const { verifyUser, verifyType, allowRequestAccess } =
    useShareVerifyDefaults();

  const initialSettingsFormvalues: AllFormFields = React.useMemo(
    () => ({
      expires: workspace?.default_shares_expiration_enabled,
      message_enabled: false,
      message: '',
      verifyUser,
      verifyType,
      allowRequestAccess,
      expiryDate: moment()
        .add(
          workspace ? workspace.default_shares_expiration_duration : 30,
          'day'
        )
        .toISOString()
    }),
    [workspace, verifyUser, verifyType, allowRequestAccess]
  );

  return (
    <Wrapped
      {...props}
      {...values}
      form={formId}
      initialValues={initialSettingsFormvalues}
      emailSearchInput={undefined}
    />
  );
};

export interface PublicProps {
  emailSearchInput?: string;
}

type InjectedProps = ModalReceiverProps &
  WithResourcesProps &
  PublicProps &
  AllFormFields;

type Properties = InjectedProps &
  InjectedFormProps<AllFormFields, InjectedProps, ComplexErrors>;

const emptyArray = [];

const REQUIRED_FEATURES = {
  features: [
    '/WORKSPACE/SHARING/REVIEWS',
    '/WORKSPACE/SHARING/MAGIC_LINK'
  ] as Feature[]
};

const ReviewLinkNewModalC: React.FC<Properties> = (props) => {
  const [createReviewLinkInProgress, setCreateReviewLinkInProgress] =
    useState(false);

  const modalActions = useModalActions();

  const {
    reset,
    // email message form
    valid,
    message,
    emails = emptyArray,
    emailSearchInput,
    // link setting form
    verifyUser,
    verifyType,
    allowRequestAccess,
    expires,
    expiryDate,
    // with resource
    form,
    currentUser,
    clearEmailInput,
    emailSuggestions,
    dirty
  } = props;

  const [workspaceId] = useCurrentWorkspace();
  const [hasReviewsFeature, hasVerifyUserFeature] =
    useFeatures(REQUIRED_FEATURES);

  const [createReviewLink, reviewLinkCreateStatus] = useCreateResource__ALPHA({
    resourceType: 'share',
    ancestor: props.modalContext,
    cacheInvalidator: React.useCallback(
      (res, anc) => [
        {
          type: anc.type,
          id: anc.id,
          relationship: 'shares'
        },
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'shares'
        }
      ],
      [workspaceId]
    )
  });

  const createdReviewlinkId = useMappedId__ALPHA(form);
  const reviewlink = useResource__ALPHA({
    resourceId: createdReviewlinkId,
    resourceType: 'share'
  });

  // when we create a review-link we will recieve updates on reviewLinkCreateStatus
  // when successfull, go to next page.
  React.useEffect(() => {
    if (reviewLinkCreateStatus === statuses.SUCCESS && reviewlink.first) {
      modalActions.openModal('confirm_link_sent', {
        intent: hasReviewsFeature ? 'review' : 'review-legacy',
        token: reviewlink.first.token
      });
    }
  }, [
    createdReviewlinkId,
    modalActions,
    hasReviewsFeature,
    reviewLinkCreateStatus
  ]);

  const create = React.useCallback(() => {
    const expirydate = moment(expiryDate).toISOString();
    // format emails array to be accepted by backend.
    const parsedUsers: string[] = emails.map((element) => {
      return element.email_address;
    });

    const reviewLink = {
      type: 'share' as const,
      verify: !verifyUser ? VerifyType.never : verifyType,
      allow_request_access: allowRequestAccess,
      expires: expires,
      expires_at: expires ? dateNormalizer(expirydate) : undefined,
      message_enabled: !!message,
      temporaryId: form,
      intent: hasReviewsFeature ? 'review' : 'review-legacy', // TODO: need to get from props
      email_addresses: parsedUsers,
      message: message,
      relationships: {
        node: {
          data: { id: props.modalContext.id, type: 'node' }
        }
      }
    };
    createReviewLink(reviewLink);
    // set loading boolean to true
    setCreateReviewLinkInProgress(true);
  }, [
    createReviewLink,
    emails,
    verifyUser,
    verifyType,
    allowRequestAccess,
    expires,
    expiryDate,
    form,
    message,
    props.modalContext
  ]);

  const handleClickCancel = React.useCallback(() => {
    // if no prior review link
    if (props.modalContext.first) {
      modalActions.goBackModals(2);
    } else {
      modalActions.previousModal();
    }
    reset();
  }, [modalActions, props.modalContext.first, reset]);

  return (
    <ReviewLinkNewModalUI
      dirty={dirty}
      loading={createReviewLinkInProgress}
      onCloseEvent={handleClickCancel}
      onClickCreate={create}
      submitDisabled={!valid}
      renderPropsEmailBox={() => (
        <EmailMessagebox
          message={message}
          EmailFieldsComponent={
            <FieldArray
              name="emails"
              props={{
                emailSuggestions,
                clearEmailInput,
                form: form,
                emailSearchInput,
                mandatoryEmail: currentUser.email_address
              }}
              component={EmailSuggestionsContainer}
            />
          }
        />
      )}
      renderPropsLinkSettings={() => (
        <LinkSettingsForm
          expiryDate={expiryDate}
          expires={expires}
          verifyUser={verifyUser}
          verifyType={verifyType}
          allowRequestAccess={allowRequestAccess}
          hasVerifyUserFeature={hasVerifyUserFeature}
        />
      )}
    />
  );
};

const ReviewLinkNewModal = compose(ReviewLinkNewModalC)(
  withConnectedResources,
  (Wrapped) => (props) =>
    !props.loading ? <Wrapped {...props} /> : <ModalMenuUI loading={true} />,
  withReviewlinkValues,
  reduxForm<AllFormFields, Properties, ComplexErrors>({
    validate: validateReviewlink,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })
);

export { ReviewLinkNewModal };
