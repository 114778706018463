import { Related, ResourceBase, ResourceType } from '../../resource-types';
import { RawModel } from '../schema';

export interface Activity extends ResourceBase {
  verb: 'VerbCreated' | 'VerbDeleted' | 'VerbUpdated';
  action: string;
  created_at: string;
  relationships: {
    source: Related<'project'>;
    /** The object that was modified, for example a folder */
    object: Related<'folder' | 'asset'>;
    subject: Related<ResourceType>;
    /** The actor, doing the change, usually an account (person) */
    actor?: Related<'account' | 'user'>;
    /** The context the event occured in, for example a project */
    related_resources?: Related<'project' | 'folder' | 'workspace'>;
  };
}

export const activity: RawModel = {
  type: 'activity',
  plural: 'activities',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID',
    verb: 'string',
    created_at: 'date'
    // display_image: 'URL'
  },
  relationships: {
    project: {
      type: 'project',
      binding: ['one', 'many'],
      reverse: 'members',
      required: [true, false]
    },
    account: {
      type: 'account',
      binding: ['one', 'many'],
      reverse: 'project-members',
      required: [true, false]
    },
    source: {
      type: 'project',
      binding: ['one', 'many'],
      reverse: 'main_activities', // TODO: figure out if this makes sense
      required: [true, false]
    },
    object: {
      type: ['folder', 'asset'],
      binding: ['one', 'many'],
      reverse: 'activities', // TODO: figure out if this makes sense
      required: [true, false]
    },
    subject: {
      type: ['folder', 'asset'],
      binding: ['one', 'many'],
      reverse: 'activities', // TODO: figure out if this makes sense
      required: [true, false]
    },
    actor: {
      type: 'account',
      binding: ['one', 'many'],
      reverse: 'activities', // TODO: figure out if this makes sense
      required: [false, false]
    },
    context: {
      type: 'project',
      binding: ['one', 'many'],
      reverse: 'related_activities', // TODO: figure out if this makes sense
      required: [false, false]
    }
  }
};
