import { actionCreators, selectors } from '../../redux/ducks/resource-nodes';
import { actions as sessionActions } from '../../redux/ducks/session';
import { FSA } from '../../redux/flux-standard-action';
import { defaultMemoize } from 'reselect';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';
import { KeyValStorage } from './storage';

export class ReduxUploadStorage implements KeyValStorage {
  private state: Upload[];
  private dispatch: (action: FSA) => void;
  public readyPromise;
  private setReady;

  constructor() {
    this.readyPromise = new Promise((res) => {
      this.setReady = () => {
        res(true);
      };
    });
  }

  getUpload(key) {
    return this.state.filter((item) => {
      return item.tusUrlStorage?.urlStorageKey === key;
    })[0];
  }

  getItem(key) {
    return this.getUpload(key)?.tusUrlStorage;
  }

  setItem(key, item, override = {}) {
    const existing = this.getUpload(key) || this.buildUpload(item);
    const newItem = {
      ...existing,
      ...override,
      tusUrlStorage: item
    };
    this.dispatch(actionCreators.receiveResource('file-upload', newItem));
  }
  getAllMemo = defaultMemoize((state = []) => {
    return state.filter((i) => !!i.tusUrlStorage).map((i) => i.tusUrlStorage);
  });

  getAll() {
    if (!this.state) {
      throw new Error(
        'redux upload storage NOT initialized, wait on this.readyPromise '
      );
    }
    return this.getAllMemo(this.state);
  }

  getState() {
    return this.state;
  }

  removeItem(key) {
    const existing = this.getUpload(key);

    // NOTE: we do some extra checks cause we don't want to remove uploads
    // when an initial resume request gets a locked response (status 423)
    // This is an oversight in the tus-js-client library as of may 2021
    // https://github.com/tus/tus-js-client/blob/ba50a8a2c629584273c52dcd133759709ad5e261/lib/upload.js#L606
    //
    if (
      existing &&
      (existing.tusUpload?._aborted ||
        existing.progress === 100 ||
        existing.failed)
    ) {
      this.dispatch(
        actionCreators.disposeResource('file-upload', existing.id, true)
      );
    }
  }

  buildUpload(item) {
    const existing =
      this.state.filter((u) => u.id === item.metadata.id)[0] || {};
    return {
      id: item.metadata.id,
      progress: 0,
      // file: file.raw,
      display_name: item.metadata.filename,
      tusUpload: undefined,
      asset_id: null,
      ...existing,
      active: false,
      failed: false,
      tusUrlStorage: item,
      updated_at: new Date().toUTCString()
    };
  }

  selectState = selectors.makeGetResourcesByType('file-upload');

  getEnhancer() {
    return (store) => {
      this.dispatch = store.dispatch;

      this.state = this.selectState(store.getState());
      this.setReady();

      return (next) => (action) => {
        next(action);
        const nextState = this.selectState(store.getState());
        if (nextState !== this.state) {
          this.state = nextState;
        }
        if (action.type === sessionActions.LOGOUT) {
          this.state
            .filter((u) => u.tusUpload)
            .forEach((u) => u.tusUpload.abort(false));
        }
      };
    };
  }
}
