import React from 'react';

import { compose } from '../../../../../../utils/component-helpers';
import EditMetadataFormUI from '@cube3/ui/src/metadata/EditMetadataFormUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import {
  useFormatMetadataCategory,
  useFormatMetadataValues,
  dehydrateValue
} from '@cube3/state/src/redux/components/Hooks/metadataHooks';
import { reduxForm, Field, FieldArray, setSubmitSucceeded } from 'redux-form';
import { MetadataFormFieldRow } from '@cube3/ui/src/metadata/MetadataFormField';

import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';

import {
  addFormId,
  formStates
} from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { MetadataCategory } from '@cube3/common/model/schema/resources/metadata-category';
import { ContractValue } from '@cube3/common/model/schema/resources/contract-value';
import { MetadataValue } from '@cube3/common/model/schema/resources/metadata-value';
import { ContractKeyGroup } from '@cube3/common/model/schema/resources/contract-key-group';

import { getFieldConfig } from '../../../../../forms/utils/getFieldConfig';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

export const WrappedFieldComponent = (props) => {
  const { component: FieldComponent, ...fieldProps } = props;
  return (
    <MetadataFormFieldRow {...fieldProps} required={fieldProps.required}>
      <FieldComponent {...fieldProps} showErrors={false} />
    </MetadataFormFieldRow>
  );
};

export interface FieldConfig {
  /** value type (e.g. provided by api) */
  value_type: string;
  /** field key identifier */
  name: string;
  /** subtype? TODO: figure out */
  type?: string;
  /** displayed name for the field */
  label: string;
  /** field should be disabled  */
  disabled?: boolean;
  /** validator function */
  validate?: Function;
  /** formatter function to present normalized data */
  format?: Function;
  /** parser function to parse into normalized data */
  parse?: Function;
  /** field that is required */
  required?: boolean;
}

interface EditMetadataFormProps {
  metadataForResource?: ResourceIdentifier;
  metadataFields: FieldConfig[];
  metadataForm: MetadataCategory | ContractKeyGroup;
  metadataFormId?: string;
  loading?: boolean;
  handleSubmit(): void;
  requiredFieldsOnly?: boolean;
  prefilledValues?: { [id: string]: unknown };
  initialValues?: { [field_id: string]: unknown };
  form?: string;
  useDefault?: boolean;
}

export const EditMetadataForm = (props: EditMetadataFormProps) => {
  const {
    metadataFields,
    metadataForm,
    loading,
    handleSubmit,
    metadataForResource,
    requiredFieldsOnly = false,
    initialValues
    // form
    // submitting,
    // submitSucceeded,
    // submitFailed,
    // ...rest
  } = props;
  const fieldComponents = React.useMemo(() => {
    if (!metadataFields || metadataFields.length === 0) {
      return;
    }

    // only render required fields or fields with prefilled values
    let metadataFieldsToMap = metadataFields;
    if (requiredFieldsOnly) {
      metadataFieldsToMap = metadataFieldsToMap.filter(
        (field) =>
          !!field.required ||
          (initialValues && Object.keys(initialValues).includes(field.name))
      );
    }
    // if no required fields,  don't show
    if (metadataFieldsToMap.length === 0) return null;

    return metadataFieldsToMap.map((field) => {
      const { component: FieldComponent, props: extraProps } =
        getFieldConfig(field);
      // Motiv number will be dropped
      if (field.value_type === 'generated') return null;

      if (
        field &&
        (field.value_type === 'multiselect' ||
          field.value_type === 'resource_picker')
      ) {
        return (
          <FieldArray
            name={field.name}
            key={field.name}
            props={{
              ...extraProps,
              metadataForResource,
              component: FieldComponent,
              type: field.type,
              label: field.label,
              formFieldType: field.type,
              disabled: field.disabled ? field.disabled : false,
              required: field.required
            }}
            component={WrappedFieldComponent}
            formFieldType={field.type}
            validate={field.validate}
          />
        );
      }

      return (
        <Field
          name={field.name}
          type={field.type}
          key={field.name}
          props={{
            ...extraProps,
            metadataForResource,
            component: FieldComponent,
            required: field.required
          }}
          component={WrappedFieldComponent}
          label={field.label}
          formFieldType={field.type}
          disabled={field.disabled ? field.disabled : false}
          validate={field.validate}
          format={field.format}
          parse={field.parse}
        />
      );
    });
  }, [metadataFields, requiredFieldsOnly, initialValues, metadataForResource]);

  if (loading || !metadataForm) {
    return <Shimmer height={500} />;
  }

  if (fieldComponents) {
    return (
      <EditMetadataFormUI
        title={metadataForm.display_name}
        onSubmit={handleSubmit}
      >
        {fieldComponents}
      </EditMetadataFormUI>
    );
  } else {
    return (
      <Typography>
        {requiredFieldsOnly ? 'No fields required' : 'No metadata available'}
      </Typography>
    );
  }
};

const withSaveHandlers = (Wrapped) => (props) => {
  const [createResource, createResourceStatus] = useCreateResource__ALPHA({
    ancestor: props.metadataForResource,
    resourceType:
      props.metadataForResource?.type === 'contract'
        ? 'contract-value'
        : 'metadata-value',
    relationship:
      props.metadataForResource?.type === 'contract'
        ? 'contract-values'
        : 'metadata-values',
    actionDecorators: [
      addFormId(props.form, {
        formState: undefined,
        useRequestStatus: true,
        delegate: true,
        data: (action) => {
          return (
            action.meta.apiClient.data && { ...action.meta.apiClient.data }
          );
        },
        errorFormatter: (error, data, form, action) => {
          return {
            [`field_${data.field_id}`]: error['_error'].map(
              (e) => e.code || e.detail || e
            )
          };
        }
      })
    ],
    cacheInvalidator: (res, anc, rel) => [
      {
        type: anc.type,
        id: anc.id,
        relationship: rel
      }
    ]
  });

  const [mutateResource, mutateResourceStatus] = useMutateResource__ALPHA({
    actionDecorators: [
      addFormId(props.form, {
        formState: undefined,
        useRequestStatus: true,
        delegate: true,
        data: (action) => {
          return { ...action.payload.data };
        },
        errorFormatter: (error, data, form, action) => {
          return {
            [`field_${data.field_id}`]: error['_error'].map(
              (e) => e.code || e.detail || e
            )
          };
        }
      })
    ],
    cacheInvalidator: (r) => [
      {
        type: props.metadataForResource?.type,
        id: props.metadataForResource?.id,
        relationship:
          props.metadataForResource?.type === 'contract'
            ? 'contract-values'
            : 'metadata-values'
      }
    ]
  });

  const [deleteResource, deleteResourceStatus] = useDeleteResource__ALPHA({
    actionDecorators: [
      addFormId(props.form, {
        formState: undefined,
        useRequestStatus: true,
        delegate: true,
        data: (action) => {
          return { ...action.payload.data };
        },
        errorFormatter: (error, data, form, action) => {
          return {
            [`field_${data.field_id}`]: error['_error'].map(
              (e) => e.code || e.detail
            )
          };
        }
      })
    ],
    cacheInvalidator: null
  });

  return (
    <Wrapped
      {...props}
      saveValues={mutateResource}
      saveValuesStatus={mutateResourceStatus}
      addValues={createResource}
      addValuesStatus={createResourceStatus}
      deleteValues={deleteResource}
      deleteValuesStatus={deleteResourceStatus}
    />
  );
};

const withFormConfig = (Wrapped) => (props) => {
  const {
    metadataFormId,
    metadataForm,
    retrievedValues,
    retrievedValuesStatus,
    prefilledValues,
    metadataForResource,
    useDefault,
    ...restProps
  } = props;
  const metadataFields = useFormatMetadataCategory(metadataForm);
  let initialValues = retrievedValues;

  // prefill fields based on your current location in the project navigator
  if (prefilledValues && Object.keys(prefilledValues).length > 0) {
    const formatted = Object.keys(prefilledValues)
      .filter(
        (key) =>
          key !== '__status__' &&
          key !== '__access__' &&
          prefilledValues[key] !== undefined
      )
      .map((key) => {
        return {
          field_id: key,
          value: prefilledValues[key]
        };
      });
    initialValues = [...formatted];
  }
  const values = useFormatMetadataValues(
    initialValues,
    metadataForm,
    useDefault
  );

  // prevent initializing new contract form before initial values are ready
  if (metadataForResource.id && retrievedValuesStatus !== statuses.SUCCESS) {
    return (
      <Shimmer
        margin="large"
        fadeIn={true}
        weight="feather"
        width={'100%'}
        height={400}
      />
    );
  }

  return (
    <Wrapped
      {...restProps}
      metadataForResource={metadataForResource}
      retrievedValuesStatus={retrievedValuesStatus}
      metadataForm={metadataForm}
      retrievedValues={retrievedValues}
      form={metadataFormId}
      initialValues={values}
      metadataFields={metadataFields}
    />
  );
};

const withRetrievedValues = (Wrapped) =>
  React.memo<EditMetadataFormProps>((props) => {
    const { metadataForResource, metadataFormId } = props;

    const metadataForm = useResource__ALPHA({
      resourceId: metadataFormId,
      resourceType:
        metadataForResource?.type === 'contract'
          ? 'contract-key-group'
          : 'metadata-category'
    });

    const retrievedValues = useResourceList__ALPHA({
      resourceId: metadataForResource?.id,
      resourceType: metadataForResource?.type,
      edgeType:
        metadataForResource?.type === 'contract'
          ? 'contract-value'
          : 'metadata-value',
      edgeLabel:
        metadataForResource?.type === 'contract'
          ? 'contract-values'
          : 'metadata-values',
      strategy: 'fetch-on-mount'
    });
    return (
      <Wrapped
        {...props}
        loading={
          props.loading || metadataForm.loading || retrievedValues.loading
        }
        metadataForm={metadataForm.resource}
        retrievedValues={retrievedValues.resources}
        retrievedValuesStatus={retrievedValues.status}
      />
    );
  });
/**
 * @summary Fetches metadata and provides UI selection components based on the type of the metadata. Make sure you format the data from your selection component with in order to properly validate your form in `metadataHooks` `validate` function so it matches the JSON schema!
 */
export const EditMetadataFormSmart = compose(EditMetadataForm)(
  withRetrievedValues,
  withFormConfig,
  withSaveHandlers,
  reduxForm<
    {},
    {
      metadataForResource: ResourceIdentifier;
      retrievedValues: Array<MetadataValue | ContractValue>;
      metadataFields: FieldConfig[];
      addValues: Function;
      saveValues: Function;
      deleteValues: Function;
    }
  >({
    destroyOnUnmount: false,
    onSubmit: (values, dispatch, props) => {
      const valueType =
        props.metadataForResource?.type === 'contract'
          ? 'contract-value'
          : 'metadata-value';

      const fieldString =
        props.metadataForResource?.type === 'contract' ? 'key' : 'field';

      const newValues = Object.keys(values)
        .filter((f) => !isEmptyValue(values[f]))
        .filter((field) => {
          // not in existing values
          return !props.retrievedValues?.filter(
            (v) =>
              `${fieldString}_${v.field_id || (v as ContractValue).key_id}` ===
              field
          ).length;
        })
        .filter((field) => {
          const { owner } =
            props.metadataFields.filter((f) => f.id === field)[0] || {};

          if (owner.owner !== props.form) {
            return false;
          }
          return true;
        })
        .map((field) => {
          const { value_type: fieldType, json_schema: fieldSchema } =
            props.metadataFields.filter((f) => f.id === field)[0] || {};

          return {
            type: valueType,
            [`${fieldString}_id`]: field.replace(/(?:field_|key_)/, ''),
            value: dehydrateValue(values[field], fieldType, fieldSchema)
          };
        });

      const updateValues = !props.retrievedValues
        ? []
        : Object.keys(values)
            .filter((f) => !isEmptyValue(values[f]))
            .filter((field) => {
              const current = props.retrievedValues?.filter(
                (v) =>
                  `${fieldString}_${
                    v.field_id || (v as ContractValue).key_id
                  }` === field
              )[0];
              const {
                value_type: fieldType,
                json_schema: fieldSchema,
                owner
              } = props.metadataFields.filter((f) => f.id === field)[0] || {};

              if (owner.owner !== props.form) {
                return false;
              }

              return (
                current &&
                dehydrateValue(values[field], fieldType, fieldSchema) !==
                  current.value
              );
            })
            .map((field) => {
              const current = props.retrievedValues.filter(
                (v) =>
                  `${fieldString}_${
                    v.field_id || (v as ContractValue).key_id
                  }` === field
              )[0];
              const { value_type: fieldType, json_schema: fieldSchema } =
                props.metadataFields.filter((f) => f.id === field)[0] || {};

              return {
                id: current.id,
                type: valueType,
                field_id: field.replace(/(?:field_|key_)/, ''),
                value: dehydrateValue(values[field], fieldType, fieldSchema)
              };
            });

      const deleteValues = !props.retrievedValues
        ? []
        : Object.keys(values)
            .filter((f) => isEmptyValue(values[f]))
            .filter((field) => {
              const current = props.retrievedValues.filter(
                (v) =>
                  `${fieldString}_${
                    v.field_id || (v as ContractValue).key_id
                  }` === field
              )[0];

              const {
                value_type: fieldType,
                json_schema: fieldSchema,
                owner
              } = props.metadataFields.filter((f) => f.id === field)[0] || {};

              if (owner.owner !== props.form) {
                return false;
              }

              return (
                current &&
                dehydrateValue(values[field], fieldType, fieldSchema) !==
                  current.value
              );
            })
            .map((field) => {
              const current = props.retrievedValues.filter(
                (v) =>
                  `${fieldString}_${
                    v.field_id || (v as ContractValue).key_id
                  }` === field
              )[0];

              return {
                id: current.id,
                type: valueType
              };
            });

      if (newValues.length) {
        props.addValues(newValues);
      }

      if (updateValues.length) {
        props.saveValues(updateValues);
      }

      if (deleteValues.length) {
        props.deleteValues(deleteValues);
      }

      if (!newValues.length && !updateValues.length && !deleteValues.length) {
        dispatch(
          addFormId(props.form, {
            formState: formStates.SUCCESS,
            useRequestStatus: true,
            delegate: true
          })(setSubmitSucceeded(props.form))
        );
      }
    }
  })
);

const isEmptyValue = (val) => val === undefined || val === null || val === '';
