import { defaultMemoize } from 'reselect';
import { statuses } from './state';
import { hasAction } from './utils/nodeHelpers';
import { serialize } from './utils/serialize';

// TODO: write better memoize, that serializes input action for check
const getStatus = defaultMemoize((state, action) => {
  if (!action) {
    return;
  }

  if (hasAction(state.inFlight, action)) {
    return statuses.IN_FLIGHT;
  }
  if (hasAction(state.success, action)) {
    return statuses.SUCCESS;
  }
  if (hasAction(state.failed, action)) {
    return statuses.FAILED;
  }
  if (hasAction(state.invalidated, action)) {
    return statuses.INVALIDATED;
  }
  // return PRE_FLIGHT;
  // TODO: enable this but make sure all components are ok
});

const getErrors = defaultMemoize((state, action) => {
  if (!action) {
    return;
  }
  const id = serialize(action);
  const { errors } = state;
  return errors && errors[id];
});

export const selectors = {
  getStatus,
  getErrors
};
