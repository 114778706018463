
import { ReactComponent as Add } from './cleaned_svg/add.svg';
import { ReactComponent as Archive_fill } from './cleaned_svg/archive-fill.svg';
import { ReactComponent as Archive } from './cleaned_svg/archive.svg';
import { ReactComponent as Arrow_down } from './cleaned_svg/arrow-down.svg';
import { ReactComponent as Arrow_left } from './cleaned_svg/arrow-left.svg';
import { ReactComponent as Arrow_right } from './cleaned_svg/arrow-right.svg';
import { ReactComponent as Arrow_up } from './cleaned_svg/arrow-up.svg';
import { ReactComponent as Artist_fill } from './cleaned_svg/artist-fill.svg';
import { ReactComponent as Artist } from './cleaned_svg/artist.svg';
import { ReactComponent as Attachment } from './cleaned_svg/attachment.svg';
import { ReactComponent as Auto_delete_fill } from './cleaned_svg/auto-delete-fill.svg';
import { ReactComponent as Auto_delete } from './cleaned_svg/auto-delete.svg';
import { ReactComponent as Block } from './cleaned_svg/block.svg';
import { ReactComponent as Calendar_add_fill } from './cleaned_svg/calendar-add-fill.svg';
import { ReactComponent as Calendar_add } from './cleaned_svg/calendar-add.svg';
import { ReactComponent as Calendar_edit_fill } from './cleaned_svg/calendar-edit-fill.svg';
import { ReactComponent as Calendar_edit } from './cleaned_svg/calendar-edit.svg';
import { ReactComponent as Calendar_fill } from './cleaned_svg/calendar-fill.svg';
import { ReactComponent as Calendar_upcoming_fill } from './cleaned_svg/calendar-upcoming-fill.svg';
import { ReactComponent as Calendar_upcoming } from './cleaned_svg/calendar-upcoming.svg';
import { ReactComponent as Calendar } from './cleaned_svg/calendar.svg';
import { ReactComponent as Camera_add_fill } from './cleaned_svg/camera-add-fill.svg';
import { ReactComponent as Camera_add } from './cleaned_svg/camera-add.svg';
import { ReactComponent as Camera_off_fill } from './cleaned_svg/camera-off-fill.svg';
import { ReactComponent as Camera_off } from './cleaned_svg/camera-off.svg';
import { ReactComponent as Camera } from './cleaned_svg/camera.svg';
import { ReactComponent as Cameria_fill } from './cleaned_svg/cameria-fill.svg';
import { ReactComponent as Cast_fill } from './cleaned_svg/cast-fill.svg';
import { ReactComponent as Cast } from './cleaned_svg/cast.svg';
import { ReactComponent as Chat_bubble_fill } from './cleaned_svg/chat-bubble-fill.svg';
import { ReactComponent as Chat_bubble_notification_fill } from './cleaned_svg/chat-bubble-notification-fill.svg';
import { ReactComponent as Chat_bubble_notification } from './cleaned_svg/chat-bubble-notification.svg';
import { ReactComponent as Chat_bubble } from './cleaned_svg/chat-bubble.svg';
import { ReactComponent as Chat_bubbles_fill } from './cleaned_svg/chat-bubbles-fill.svg';
import { ReactComponent as Chat_bubbles } from './cleaned_svg/chat-bubbles.svg';
import { ReactComponent as Check_all } from './cleaned_svg/check-all.svg';
import { ReactComponent as Check } from './cleaned_svg/check.svg';
import { ReactComponent as Chevron_down } from './cleaned_svg/chevron-down.svg';
import { ReactComponent as Chevron_left } from './cleaned_svg/chevron-left.svg';
import { ReactComponent as Chevron_right } from './cleaned_svg/chevron-right.svg';
import { ReactComponent as Chevron_up } from './cleaned_svg/chevron-up.svg';
import { ReactComponent as Clipboard } from './cleaned_svg/clipboard.svg';
import { ReactComponent as Close } from './cleaned_svg/close.svg';
import { ReactComponent as Collapse_content } from './cleaned_svg/collapse-content.svg';
import { ReactComponent as Compare } from './cleaned_svg/compare.svg';
import { ReactComponent as Contract_delete_fill } from './cleaned_svg/contract-delete-fill.svg';
import { ReactComponent as Contract_delete } from './cleaned_svg/contract-delete.svg';
import { ReactComponent as Contract_edit_fill } from './cleaned_svg/contract-edit-fill.svg';
import { ReactComponent as Contract_edit } from './cleaned_svg/contract-edit.svg';
import { ReactComponent as Contract_fill } from './cleaned_svg/contract-fill.svg';
import { ReactComponent as Contract } from './cleaned_svg/contract.svg';
import { ReactComponent as Cookie_fill } from './cleaned_svg/cookie-fill.svg';
import { ReactComponent as Cookie_off_fill } from './cleaned_svg/cookie-off-fill.svg';
import { ReactComponent as Cookie_off } from './cleaned_svg/cookie-off.svg';
import { ReactComponent as Cookie } from './cleaned_svg/cookie.svg';
import { ReactComponent as Copy_fill } from './cleaned_svg/copy-fill.svg';
import { ReactComponent as Copy } from './cleaned_svg/copy.svg';
import { ReactComponent as Create_new_folder_fill } from './cleaned_svg/create-new-folder-fill.svg';
import { ReactComponent as Create_new_folder } from './cleaned_svg/create-new-folder.svg';
import { ReactComponent as Cut } from './cleaned_svg/cut.svg';
import { ReactComponent as Delete_fill } from './cleaned_svg/delete-fill.svg';
import { ReactComponent as Delete_forever_fill } from './cleaned_svg/delete-forever-fill.svg';
import { ReactComponent as Delete_forever } from './cleaned_svg/delete-forever.svg';
import { ReactComponent as Delete } from './cleaned_svg/delete.svg';
import { ReactComponent as Download_off } from './cleaned_svg/download-off.svg';
import { ReactComponent as Download } from './cleaned_svg/download.svg';
import { ReactComponent as Draft_fill } from './cleaned_svg/draft-fill.svg';
import { ReactComponent as Draft } from './cleaned_svg/draft.svg';
import { ReactComponent as Drag_handle } from './cleaned_svg/drag-handle.svg';
import { ReactComponent as Drag_indicator } from './cleaned_svg/drag-indicator.svg';
import { ReactComponent as Edit_fill } from './cleaned_svg/edit-fill.svg';
import { ReactComponent as Edit } from './cleaned_svg/edit.svg';
import { ReactComponent as Email } from './cleaned_svg/email.svg';
import { ReactComponent as Encrypted_fill } from './cleaned_svg/encrypted-fill.svg';
import { ReactComponent as Encrypted } from './cleaned_svg/encrypted.svg';
import { ReactComponent as Erase_fill } from './cleaned_svg/erase-fill.svg';
import { ReactComponent as Erase_off_fill } from './cleaned_svg/erase-off-fill.svg';
import { ReactComponent as Erase_off } from './cleaned_svg/erase-off.svg';
import { ReactComponent as Erase } from './cleaned_svg/erase.svg';
import { ReactComponent as Error_fill } from './cleaned_svg/error-fill.svg';
import { ReactComponent as Error } from './cleaned_svg/error.svg';
import { ReactComponent as Event_repeat_fill } from './cleaned_svg/event-repeat-fill.svg';
import { ReactComponent as Event_repeat } from './cleaned_svg/event-repeat.svg';
import { ReactComponent as Expand_content } from './cleaned_svg/expand-content.svg';
import { ReactComponent as Export } from './cleaned_svg/export.svg';
import { ReactComponent as Extension_fill } from './cleaned_svg/extension-fill.svg';
import { ReactComponent as Extension_off_fill } from './cleaned_svg/extension-off-fill.svg';
import { ReactComponent as Extension_off } from './cleaned_svg/extension-off.svg';
import { ReactComponent as Extension } from './cleaned_svg/extension.svg';
import { ReactComponent as Fast_forward } from './cleaned_svg/fast-forward.svg';
import { ReactComponent as Fast_rewind } from './cleaned_svg/fast-rewind.svg';
import { ReactComponent as Filter_off } from './cleaned_svg/filter-off.svg';
import { ReactComponent as Filter } from './cleaned_svg/filter.svg';
import { ReactComponent as Folder_copy_fill } from './cleaned_svg/folder-copy-fill.svg';
import { ReactComponent as Folder_copy } from './cleaned_svg/folder-copy.svg';
import { ReactComponent as Folder_fill } from './cleaned_svg/folder-fill.svg';
import { ReactComponent as Folder_move_fill } from './cleaned_svg/folder-move-fill.svg';
import { ReactComponent as Folder_move } from './cleaned_svg/folder-move.svg';
import { ReactComponent as Folder_off_fill } from './cleaned_svg/folder-off-fill.svg';
import { ReactComponent as Folder_off } from './cleaned_svg/folder-off.svg';
import { ReactComponent as Folder_open_fill } from './cleaned_svg/folder-open-fill.svg';
import { ReactComponent as Folder_open } from './cleaned_svg/folder-open.svg';
import { ReactComponent as Folder } from './cleaned_svg/folder.svg';
import { ReactComponent as Forward_10 } from './cleaned_svg/forward-10.svg';
import { ReactComponent as Forward_30 } from './cleaned_svg/forward-30.svg';
import { ReactComponent as Forward_5 } from './cleaned_svg/forward-5.svg';
import { ReactComponent as Gavel } from './cleaned_svg/gavel.svg';
import { ReactComponent as Gesture } from './cleaned_svg/gesture.svg';
import { ReactComponent as Group_add_fill } from './cleaned_svg/group-add-fill.svg';
import { ReactComponent as Group_add } from './cleaned_svg/group-add.svg';
import { ReactComponent as Group_fill } from './cleaned_svg/group-fill.svg';
import { ReactComponent as Group_off_fill } from './cleaned_svg/group-off-fill.svg';
import { ReactComponent as Group_off } from './cleaned_svg/group-off.svg';
import { ReactComponent as Group_remove_fill } from './cleaned_svg/group-remove-fill.svg';
import { ReactComponent as Group_remove } from './cleaned_svg/group-remove.svg';
import { ReactComponent as Group } from './cleaned_svg/group.svg';
import { ReactComponent as Groups_fill } from './cleaned_svg/groups-fill.svg';
import { ReactComponent as Groups } from './cleaned_svg/groups.svg';
import { ReactComponent as Guide_fill } from './cleaned_svg/guide-fill.svg';
import { ReactComponent as Guide } from './cleaned_svg/guide.svg';
import { ReactComponent as Hard_drive_fill } from './cleaned_svg/hard-drive-fill.svg';
import { ReactComponent as Hard_drive } from './cleaned_svg/hard-drive.svg';
import { ReactComponent as Help_fill } from './cleaned_svg/help-fill.svg';
import { ReactComponent as Help } from './cleaned_svg/help.svg';
import { ReactComponent as History } from './cleaned_svg/history.svg';
import { ReactComponent as Home_fill } from './cleaned_svg/home-fill.svg';
import { ReactComponent as Home } from './cleaned_svg/home.svg';
import { ReactComponent as Image_fill } from './cleaned_svg/image-fill.svg';
import { ReactComponent as Image } from './cleaned_svg/image.svg';
import { ReactComponent as Inbox_all_fill } from './cleaned_svg/inbox-all-fill.svg';
import { ReactComponent as Inbox_all } from './cleaned_svg/inbox-all.svg';
import { ReactComponent as Inbox_fill } from './cleaned_svg/inbox-fill.svg';
import { ReactComponent as Inbox_move_fill } from './cleaned_svg/inbox-move-fill.svg';
import { ReactComponent as Inbox_move } from './cleaned_svg/inbox-move.svg';
import { ReactComponent as Inbox_settings_fill } from './cleaned_svg/inbox-settings-fill.svg';
import { ReactComponent as Inbox_settings } from './cleaned_svg/inbox-settings.svg';
import { ReactComponent as Inbox } from './cleaned_svg/inbox.svg';
import { ReactComponent as Indeterminate } from './cleaned_svg/indeterminate.svg';
import { ReactComponent as Info_fill } from './cleaned_svg/info-fill.svg';
import { ReactComponent as Info } from './cleaned_svg/info.svg';
import { ReactComponent as Key_fill } from './cleaned_svg/key-fill.svg';
import { ReactComponent as Key_off_fill } from './cleaned_svg/key-off-fill.svg';
import { ReactComponent as Key_off } from './cleaned_svg/key-off.svg';
import { ReactComponent as Key } from './cleaned_svg/key.svg';
import { ReactComponent as Keyboard_fill } from './cleaned_svg/keyboard-fill.svg';
import { ReactComponent as Keyboard } from './cleaned_svg/keyboard.svg';
import { ReactComponent as Label_fill } from './cleaned_svg/label-fill.svg';
import { ReactComponent as Label } from './cleaned_svg/label.svg';
import { ReactComponent as Link_add } from './cleaned_svg/link-add.svg';
import { ReactComponent as Link_off } from './cleaned_svg/link-off.svg';
import { ReactComponent as Link } from './cleaned_svg/link.svg';
import { ReactComponent as Lock_fill } from './cleaned_svg/lock-fill.svg';
import { ReactComponent as Lock_open_fill } from './cleaned_svg/lock-open-fill.svg';
import { ReactComponent as Lock_open } from './cleaned_svg/lock-open.svg';
import { ReactComponent as Lock } from './cleaned_svg/lock.svg';
import { ReactComponent as Login } from './cleaned_svg/login.svg';
import { ReactComponent as Logout } from './cleaned_svg/logout.svg';
import { ReactComponent as Loop } from './cleaned_svg/loop.svg';
import { ReactComponent as Lyrics_fill } from './cleaned_svg/lyrics-fill.svg';
import { ReactComponent as Lyrics } from './cleaned_svg/lyrics.svg';
import { ReactComponent as Mail_fill } from './cleaned_svg/mail-fill.svg';
import { ReactComponent as Mail_move_fill } from './cleaned_svg/mail-move-fill.svg';
import { ReactComponent as Mail_move } from './cleaned_svg/mail-move.svg';
import { ReactComponent as Mail_notification_fill } from './cleaned_svg/mail-notification-fill.svg';
import { ReactComponent as Mail_notification } from './cleaned_svg/mail-notification.svg';
import { ReactComponent as Mail } from './cleaned_svg/mail.svg';
import { ReactComponent as Markdown_fill } from './cleaned_svg/markdown-fill.svg';
import { ReactComponent as Markdown } from './cleaned_svg/markdown.svg';
import { ReactComponent as Menu } from './cleaned_svg/menu.svg';
import { ReactComponent as Metadata } from './cleaned_svg/metadata.svg';
import { ReactComponent as More } from './cleaned_svg/more.svg';
import { ReactComponent as Music } from './cleaned_svg/music.svg';
import { ReactComponent as Notifications_active_fill } from './cleaned_svg/notifications-active-fill.svg';
import { ReactComponent as Notifications_active } from './cleaned_svg/notifications-active.svg';
import { ReactComponent as Notifications_add_fill } from './cleaned_svg/notifications-add-fill.svg';
import { ReactComponent as Notifications_add } from './cleaned_svg/notifications-add.svg';
import { ReactComponent as Notifications_edit_fill } from './cleaned_svg/notifications-edit-fill.svg';
import { ReactComponent as Notifications_edit } from './cleaned_svg/notifications-edit.svg';
import { ReactComponent as Notifications_fill } from './cleaned_svg/notifications-fill.svg';
import { ReactComponent as Notifications_off_fill } from './cleaned_svg/notifications-off-fill.svg';
import { ReactComponent as Notifications_off } from './cleaned_svg/notifications-off.svg';
import { ReactComponent as Notifications } from './cleaned_svg/notifications.svg';
import { ReactComponent as Open_in_new } from './cleaned_svg/open-in-new.svg';
import { ReactComponent as Package_fill } from './cleaned_svg/package-fill.svg';
import { ReactComponent as Package } from './cleaned_svg/package.svg';
import { ReactComponent as Pause } from './cleaned_svg/pause.svg';
import { ReactComponent as Person_add_fill } from './cleaned_svg/person-add-fill.svg';
import { ReactComponent as Person_add } from './cleaned_svg/person-add.svg';
import { ReactComponent as Person_fill } from './cleaned_svg/person-fill.svg';
import { ReactComponent as Person_off_fill } from './cleaned_svg/person-off-fill.svg';
import { ReactComponent as Person_off } from './cleaned_svg/person-off.svg';
import { ReactComponent as Person_project_fill } from './cleaned_svg/person-project-fill.svg';
import { ReactComponent as Person_project } from './cleaned_svg/person-project.svg';
import { ReactComponent as Person_remove_fill } from './cleaned_svg/person-remove-fill.svg';
import { ReactComponent as Person_remove } from './cleaned_svg/person-remove.svg';
import { ReactComponent as Person_search_fill } from './cleaned_svg/person-search-fill.svg';
import { ReactComponent as Person_search } from './cleaned_svg/person-search.svg';
import { ReactComponent as Person_settings_fill } from './cleaned_svg/person-settings-fill.svg';
import { ReactComponent as Person_settings } from './cleaned_svg/person-settings.svg';
import { ReactComponent as Person } from './cleaned_svg/person.svg';
import { ReactComponent as Placeholder } from './cleaned_svg/placeholder.svg';
import { ReactComponent as Play } from './cleaned_svg/play.svg';
import { ReactComponent as Preview_fill } from './cleaned_svg/preview-fill.svg';
import { ReactComponent as Preview_off } from './cleaned_svg/preview-off.svg';
import { ReactComponent as Preview } from './cleaned_svg/preview.svg';
import { ReactComponent as Project_alert_fill } from './cleaned_svg/project-alert-fill.svg';
import { ReactComponent as Project_alert } from './cleaned_svg/project-alert.svg';
import { ReactComponent as Project_download_fill } from './cleaned_svg/project-download-fill.svg';
import { ReactComponent as Project_download } from './cleaned_svg/project-download.svg';
import { ReactComponent as Project_fill } from './cleaned_svg/project-fill.svg';
import { ReactComponent as Project_history_fill } from './cleaned_svg/project-history-fill.svg';
import { ReactComponent as Project_history } from './cleaned_svg/project-history.svg';
import { ReactComponent as Project } from './cleaned_svg/project.svg';
import { ReactComponent as Queue } from './cleaned_svg/queue.svg';
import { ReactComponent as Redo } from './cleaned_svg/redo.svg';
import { ReactComponent as Refresh } from './cleaned_svg/refresh.svg';
import { ReactComponent as Remove } from './cleaned_svg/remove.svg';
import { ReactComponent as Render } from './cleaned_svg/render.svg';
import { ReactComponent as Replay_10 } from './cleaned_svg/replay-10.svg';
import { ReactComponent as Replay_30 } from './cleaned_svg/replay-30.svg';
import { ReactComponent as Replay_5 } from './cleaned_svg/replay-5.svg';
import { ReactComponent as Replay } from './cleaned_svg/replay.svg';
import { ReactComponent as Reply_all } from './cleaned_svg/reply-all.svg';
import { ReactComponent as Reply } from './cleaned_svg/reply.svg';
import { ReactComponent as Restore_from_trash_fill } from './cleaned_svg/restore-from-trash-fill.svg';
import { ReactComponent as Restore_from_trash } from './cleaned_svg/restore-from-trash.svg';
import { ReactComponent as Schedule_fill } from './cleaned_svg/schedule-fill.svg';
import { ReactComponent as Schedule } from './cleaned_svg/schedule.svg';
import { ReactComponent as Search } from './cleaned_svg/search.svg';
import { ReactComponent as Settings_fill } from './cleaned_svg/settings-fill.svg';
import { ReactComponent as Settings } from './cleaned_svg/settings.svg';
import { ReactComponent as Share } from './cleaned_svg/share.svg';
import { ReactComponent as Shield_check_fill } from './cleaned_svg/shield-check-fill.svg';
import { ReactComponent as Shield_check } from './cleaned_svg/shield-check.svg';
import { ReactComponent as Shield_fill } from './cleaned_svg/shield-fill.svg';
import { ReactComponent as Shield_off_fill } from './cleaned_svg/shield-off-fill.svg';
import { ReactComponent as Shield_off } from './cleaned_svg/shield-off.svg';
import { ReactComponent as Shield } from './cleaned_svg/shield.svg';
import { ReactComponent as Skip_next } from './cleaned_svg/skip-next.svg';
import { ReactComponent as Skip_previous } from './cleaned_svg/skip-previous.svg';
import { ReactComponent as Sort } from './cleaned_svg/sort.svg';
import { ReactComponent as Sparkles_filled } from './cleaned_svg/sparkles-filled.svg';
import { ReactComponent as Sparkles } from './cleaned_svg/sparkles.svg';
import { ReactComponent as Spatial_audio_fill } from './cleaned_svg/spatial-audio-fill.svg';
import { ReactComponent as Spatial_audio } from './cleaned_svg/spatial-audio.svg';
import { ReactComponent as Speed_0_5 } from './cleaned_svg/speed-0-5.svg';
import { ReactComponent as Speed_1_5 } from './cleaned_svg/speed-1-5.svg';
import { ReactComponent as Speed_2x } from './cleaned_svg/speed-2x.svg';
import { ReactComponent as Star_fill } from './cleaned_svg/star-fill.svg';
import { ReactComponent as Star } from './cleaned_svg/star.svg';
import { ReactComponent as Stop } from './cleaned_svg/stop.svg';
import { ReactComponent as Switch_account_fill } from './cleaned_svg/switch-account-fill.svg';
import { ReactComponent as Switch_account } from './cleaned_svg/switch-account.svg';
import { ReactComponent as Sync } from './cleaned_svg/sync.svg';
import { ReactComponent as Template_fill } from './cleaned_svg/template-fill.svg';
import { ReactComponent as Template_off_fill } from './cleaned_svg/template-off-fill.svg';
import { ReactComponent as Template_off } from './cleaned_svg/template-off.svg';
import { ReactComponent as Template } from './cleaned_svg/template.svg';
import { ReactComponent as Test_icon } from './cleaned_svg/test_icon.svg';
import { ReactComponent as Thumb_down_fill } from './cleaned_svg/thumb-down-fill.svg';
import { ReactComponent as Thumb_down } from './cleaned_svg/thumb-down.svg';
import { ReactComponent as Thumb_up_fill } from './cleaned_svg/thumb-up-fill.svg';
import { ReactComponent as Thumb_up } from './cleaned_svg/thumb-up.svg';
import { ReactComponent as Tune } from './cleaned_svg/tune.svg';
import { ReactComponent as Typography } from './cleaned_svg/typography.svg';
import { ReactComponent as Unarchive_fill } from './cleaned_svg/unarchive-fill.svg';
import { ReactComponent as Unarchive } from './cleaned_svg/unarchive.svg';
import { ReactComponent as Undo } from './cleaned_svg/undo.svg';
import { ReactComponent as Unfold_less } from './cleaned_svg/unfold-less.svg';
import { ReactComponent as Unfold_more } from './cleaned_svg/unfold-more.svg';
import { ReactComponent as Upcoming_fill } from './cleaned_svg/upcoming-fill.svg';
import { ReactComponent as Upcoming } from './cleaned_svg/upcoming.svg';
import { ReactComponent as Upload_off } from './cleaned_svg/upload-off.svg';
import { ReactComponent as Upload } from './cleaned_svg/upload.svg';
import { ReactComponent as View_grid_fill } from './cleaned_svg/view-grid-fill.svg';
import { ReactComponent as View_grid } from './cleaned_svg/view-grid.svg';
import { ReactComponent as View_list_fill } from './cleaned_svg/view-list-fill.svg';
import { ReactComponent as View_list } from './cleaned_svg/view-list.svg';
import { ReactComponent as View_sidebar_left_fill } from './cleaned_svg/view-sidebar-left-fill.svg';
import { ReactComponent as View_sidebar_left } from './cleaned_svg/view-sidebar-left.svg';
import { ReactComponent as View_sidebar_right_fill } from './cleaned_svg/view-sidebar-right-fill.svg';
import { ReactComponent as View_sidebar_right } from './cleaned_svg/view-sidebar-right.svg';
import { ReactComponent as Visibility_fill } from './cleaned_svg/visibility-fill.svg';
import { ReactComponent as Visibility_lock_fill } from './cleaned_svg/visibility-lock-fill.svg';
import { ReactComponent as Visibility_lock } from './cleaned_svg/visibility-lock.svg';
import { ReactComponent as Visibility_off_fill } from './cleaned_svg/visibility-off-fill.svg';
import { ReactComponent as Visibility_off } from './cleaned_svg/visibility-off.svg';
import { ReactComponent as Visibility } from './cleaned_svg/visibility.svg';
import { ReactComponent as Volume_down } from './cleaned_svg/volume-down.svg';
import { ReactComponent as Volume_mute } from './cleaned_svg/volume-mute.svg';
import { ReactComponent as Volume_none } from './cleaned_svg/volume-none.svg';
import { ReactComponent as Volume_off } from './cleaned_svg/volume-off.svg';
import { ReactComponent as Volume_up } from './cleaned_svg/volume-up.svg';
import { ReactComponent as Warning_fill } from './cleaned_svg/warning-fill.svg';
import { ReactComponent as Warning } from './cleaned_svg/warning.svg';
import { ReactComponent as Workspaces_filled } from './cleaned_svg/workspaces-filled.svg';
import { ReactComponent as Workspaces } from './cleaned_svg/workspaces.svg';
import { ReactComponent as Zoom_in } from './cleaned_svg/zoom-in.svg';
import { ReactComponent as Zoom_out } from './cleaned_svg/zoom-out.svg';
import { ReactComponent as Zoom_reset } from './cleaned_svg/zoom-reset.svg';

export const iconFiles = {
  add: Add,
  archive_fill: Archive_fill,
  archive: Archive,
  arrow_down: Arrow_down,
  arrow_left: Arrow_left,
  arrow_right: Arrow_right,
  arrow_up: Arrow_up,
  artist_fill: Artist_fill,
  artist: Artist,
  attachment: Attachment,
  auto_delete_fill: Auto_delete_fill,
  auto_delete: Auto_delete,
  block: Block,
  calendar_add_fill: Calendar_add_fill,
  calendar_add: Calendar_add,
  calendar_edit_fill: Calendar_edit_fill,
  calendar_edit: Calendar_edit,
  calendar_fill: Calendar_fill,
  calendar_upcoming_fill: Calendar_upcoming_fill,
  calendar_upcoming: Calendar_upcoming,
  calendar: Calendar,
  camera_add_fill: Camera_add_fill,
  camera_add: Camera_add,
  camera_off_fill: Camera_off_fill,
  camera_off: Camera_off,
  camera: Camera,
  cameria_fill: Cameria_fill,
  cast_fill: Cast_fill,
  cast: Cast,
  chat_bubble_fill: Chat_bubble_fill,
  chat_bubble_notification_fill: Chat_bubble_notification_fill,
  chat_bubble_notification: Chat_bubble_notification,
  chat_bubble: Chat_bubble,
  chat_bubbles_fill: Chat_bubbles_fill,
  chat_bubbles: Chat_bubbles,
  check_all: Check_all,
  check: Check,
  chevron_down: Chevron_down,
  chevron_left: Chevron_left,
  chevron_right: Chevron_right,
  chevron_up: Chevron_up,
  clipboard: Clipboard,
  close: Close,
  collapse_content: Collapse_content,
  compare: Compare,
  contract_delete_fill: Contract_delete_fill,
  contract_delete: Contract_delete,
  contract_edit_fill: Contract_edit_fill,
  contract_edit: Contract_edit,
  contract_fill: Contract_fill,
  contract: Contract,
  cookie_fill: Cookie_fill,
  cookie_off_fill: Cookie_off_fill,
  cookie_off: Cookie_off,
  cookie: Cookie,
  copy_fill: Copy_fill,
  copy: Copy,
  create_new_folder_fill: Create_new_folder_fill,
  create_new_folder: Create_new_folder,
  cut: Cut,
  delete_fill: Delete_fill,
  delete_forever_fill: Delete_forever_fill,
  delete_forever: Delete_forever,
  delete: Delete,
  download_off: Download_off,
  download: Download,
  draft_fill: Draft_fill,
  draft: Draft,
  drag_handle: Drag_handle,
  drag_indicator: Drag_indicator,
  edit_fill: Edit_fill,
  edit: Edit,
  email: Email,
  encrypted_fill: Encrypted_fill,
  encrypted: Encrypted,
  erase_fill: Erase_fill,
  erase_off_fill: Erase_off_fill,
  erase_off: Erase_off,
  erase: Erase,
  error_fill: Error_fill,
  error: Error,
  event_repeat_fill: Event_repeat_fill,
  event_repeat: Event_repeat,
  expand_content: Expand_content,
  export: Export,
  extension_fill: Extension_fill,
  extension_off_fill: Extension_off_fill,
  extension_off: Extension_off,
  extension: Extension,
  fast_forward: Fast_forward,
  fast_rewind: Fast_rewind,
  filter_off: Filter_off,
  filter: Filter,
  folder_copy_fill: Folder_copy_fill,
  folder_copy: Folder_copy,
  folder_fill: Folder_fill,
  folder_move_fill: Folder_move_fill,
  folder_move: Folder_move,
  folder_off_fill: Folder_off_fill,
  folder_off: Folder_off,
  folder_open_fill: Folder_open_fill,
  folder_open: Folder_open,
  folder: Folder,
  forward_10: Forward_10,
  forward_30: Forward_30,
  forward_5: Forward_5,
  gavel: Gavel,
  gesture: Gesture,
  group_add_fill: Group_add_fill,
  group_add: Group_add,
  group_fill: Group_fill,
  group_off_fill: Group_off_fill,
  group_off: Group_off,
  group_remove_fill: Group_remove_fill,
  group_remove: Group_remove,
  group: Group,
  groups_fill: Groups_fill,
  groups: Groups,
  guide_fill: Guide_fill,
  guide: Guide,
  hard_drive_fill: Hard_drive_fill,
  hard_drive: Hard_drive,
  help_fill: Help_fill,
  help: Help,
  history: History,
  home_fill: Home_fill,
  home: Home,
  image_fill: Image_fill,
  image: Image,
  inbox_all_fill: Inbox_all_fill,
  inbox_all: Inbox_all,
  inbox_fill: Inbox_fill,
  inbox_move_fill: Inbox_move_fill,
  inbox_move: Inbox_move,
  inbox_settings_fill: Inbox_settings_fill,
  inbox_settings: Inbox_settings,
  inbox: Inbox,
  indeterminate: Indeterminate,
  info_fill: Info_fill,
  info: Info,
  key_fill: Key_fill,
  key_off_fill: Key_off_fill,
  key_off: Key_off,
  key: Key,
  keyboard_fill: Keyboard_fill,
  keyboard: Keyboard,
  label_fill: Label_fill,
  label: Label,
  link_add: Link_add,
  link_off: Link_off,
  link: Link,
  lock_fill: Lock_fill,
  lock_open_fill: Lock_open_fill,
  lock_open: Lock_open,
  lock: Lock,
  login: Login,
  logout: Logout,
  loop: Loop,
  lyrics_fill: Lyrics_fill,
  lyrics: Lyrics,
  mail_fill: Mail_fill,
  mail_move_fill: Mail_move_fill,
  mail_move: Mail_move,
  mail_notification_fill: Mail_notification_fill,
  mail_notification: Mail_notification,
  mail: Mail,
  markdown_fill: Markdown_fill,
  markdown: Markdown,
  menu: Menu,
  metadata: Metadata,
  more: More,
  music: Music,
  notifications_active_fill: Notifications_active_fill,
  notifications_active: Notifications_active,
  notifications_add_fill: Notifications_add_fill,
  notifications_add: Notifications_add,
  notifications_edit_fill: Notifications_edit_fill,
  notifications_edit: Notifications_edit,
  notifications_fill: Notifications_fill,
  notifications_off_fill: Notifications_off_fill,
  notifications_off: Notifications_off,
  notifications: Notifications,
  open_in_new: Open_in_new,
  package_fill: Package_fill,
  package: Package,
  pause: Pause,
  person_add_fill: Person_add_fill,
  person_add: Person_add,
  person_fill: Person_fill,
  person_off_fill: Person_off_fill,
  person_off: Person_off,
  person_project_fill: Person_project_fill,
  person_project: Person_project,
  person_remove_fill: Person_remove_fill,
  person_remove: Person_remove,
  person_search_fill: Person_search_fill,
  person_search: Person_search,
  person_settings_fill: Person_settings_fill,
  person_settings: Person_settings,
  person: Person,
  placeholder: Placeholder,
  play: Play,
  preview_fill: Preview_fill,
  preview_off: Preview_off,
  preview: Preview,
  project_alert_fill: Project_alert_fill,
  project_alert: Project_alert,
  project_download_fill: Project_download_fill,
  project_download: Project_download,
  project_fill: Project_fill,
  project_history_fill: Project_history_fill,
  project_history: Project_history,
  project: Project,
  queue: Queue,
  redo: Redo,
  refresh: Refresh,
  remove: Remove,
  render: Render,
  replay_10: Replay_10,
  replay_30: Replay_30,
  replay_5: Replay_5,
  replay: Replay,
  reply_all: Reply_all,
  reply: Reply,
  restore_from_trash_fill: Restore_from_trash_fill,
  restore_from_trash: Restore_from_trash,
  schedule_fill: Schedule_fill,
  schedule: Schedule,
  search: Search,
  settings_fill: Settings_fill,
  settings: Settings,
  share: Share,
  shield_check_fill: Shield_check_fill,
  shield_check: Shield_check,
  shield_fill: Shield_fill,
  shield_off_fill: Shield_off_fill,
  shield_off: Shield_off,
  shield: Shield,
  skip_next: Skip_next,
  skip_previous: Skip_previous,
  sort: Sort,
  sparkles_filled: Sparkles_filled,
  sparkles: Sparkles,
  spatial_audio_fill: Spatial_audio_fill,
  spatial_audio: Spatial_audio,
  speed_0_5: Speed_0_5,
  speed_1_5: Speed_1_5,
  speed_2x: Speed_2x,
  star_fill: Star_fill,
  star: Star,
  stop: Stop,
  switch_account_fill: Switch_account_fill,
  switch_account: Switch_account,
  sync: Sync,
  template_fill: Template_fill,
  template_off_fill: Template_off_fill,
  template_off: Template_off,
  template: Template,
  test_icon: Test_icon,
  thumb_down_fill: Thumb_down_fill,
  thumb_down: Thumb_down,
  thumb_up_fill: Thumb_up_fill,
  thumb_up: Thumb_up,
  tune: Tune,
  typography: Typography,
  unarchive_fill: Unarchive_fill,
  unarchive: Unarchive,
  undo: Undo,
  unfold_less: Unfold_less,
  unfold_more: Unfold_more,
  upcoming_fill: Upcoming_fill,
  upcoming: Upcoming,
  upload_off: Upload_off,
  upload: Upload,
  view_grid_fill: View_grid_fill,
  view_grid: View_grid,
  view_list_fill: View_list_fill,
  view_list: View_list,
  view_sidebar_left_fill: View_sidebar_left_fill,
  view_sidebar_left: View_sidebar_left,
  view_sidebar_right_fill: View_sidebar_right_fill,
  view_sidebar_right: View_sidebar_right,
  visibility_fill: Visibility_fill,
  visibility_lock_fill: Visibility_lock_fill,
  visibility_lock: Visibility_lock,
  visibility_off_fill: Visibility_off_fill,
  visibility_off: Visibility_off,
  visibility: Visibility,
  volume_down: Volume_down,
  volume_mute: Volume_mute,
  volume_none: Volume_none,
  volume_off: Volume_off,
  volume_up: Volume_up,
  warning_fill: Warning_fill,
  warning: Warning,
  workspaces_filled: Workspaces_filled,
  workspaces: Workspaces,
  zoom_in: Zoom_in,
  zoom_out: Zoom_out,
  zoom_reset: Zoom_reset,
} as const;