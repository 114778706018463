import { UploadUIStates } from './types';

/** The states translated in user understandable language. */
export const uploadStateMessages: { [key in UploadUIStates]: string } = {
  uploading: 'Uploading file',
  upload_pending: 'Waiting to upload file',
  processing: 'Generating previews',
  composing: 'Rendering asset',
  processing_pending: 'Waiting to generate previews',
  upload_failed: 'Failed to upload file',
  upload_initialization_failed: 'Failed to create upload',
  upload_missing: `Upload hasn't finished`,
  processing_failed: 'Processing failed',
  paused: 'Upload paused',
  processing_complete: undefined
};

type RequiredClasses = {
  uploadText: string;
  defaultText: string;
  uploadTextError: string;
};
export function getTextClass<C extends RequiredClasses>(
  isHovered,
  uploadState,
  classes: C
): string {
  let textClass = classes.uploadText;

  if (
    uploadState === 'upload_failed' ||
    uploadState === 'upload_initialization_failed' ||
    uploadState === 'upload_missing' ||
    uploadState === 'paused'
  ) {
    textClass = classes.uploadTextError;
  }

  if (isHovered && uploadState === 'uploading') {
    textClass = classes.defaultText;
  }

  if (isHovered && uploadState === 'paused') {
    textClass = classes.defaultText;
  }

  if (isHovered && uploadState === 'upload_pending') {
    textClass = classes.defaultText;
  }

  return textClass;
}

/** Returns additional information underlaying the title of the popup   */
export function getPopupSubText(
  isHovered,
  uploadState,
  canResume = false
): string {
  let text = uploadStateMessages[uploadState];

  if (isHovered && uploadState === 'uploading') {
    text = 'Click to pause upload';
  }

  if (isHovered && uploadState === 'paused') {
    text = 'Click to resume upload';
  }

  if (isHovered && uploadState === 'upload_pending') {
    text = 'Click to start upload';
  }

  if (isHovered && uploadState === 'upload_failed' && canResume) {
    text = 'Click to retry upload';
  }

  return text;
}

/* 
    function to return a boolean based on upload state
    */

export function getDisabledBoolean(uploadState: UploadUIStates) {
  return uploadState === 'uploading' ||
    uploadState === 'upload_pending' ||
    uploadState === 'upload_failed' ||
    uploadState === 'upload_initialization_failed' ||
    uploadState === 'upload_missing'
    ? true
    : false;
}

/* 
    function to return a boolean based on upload state
    */

export function isUploadProcessingOrPending(uploadState: UploadUIStates) {
  return uploadState === 'processing' ||
    uploadState === 'processing_pending' ||
    uploadState === 'composing'
    ? true
    : false;
}
