import { makeStyles, createStyles } from '@material-ui/core';
import React from 'react';
import { UseStyles } from '../UseStylesHelper/UseStylesHelper';

const styles = makeStyles(theme =>
  createStyles({
    root: {
      width: 'auto',
      height: '100%',
      position: 'relative'
    },
    back: {
      position: 'absolute',
      width: '100%',
      height: 'auto',
      top: 0,
      left: 0
    },
    front: {
      position: 'absolute',
      width: '100%',
      height: 'auto',
      top: 0,
      left: 0,
      zIndex: 1
    }
  })
);

export interface LibraryItemOverlayProps extends UseStyles<typeof styles> {
  back: any;
  front?: any;
}

export const LibraryItemOverlay: React.FunctionComponent<LibraryItemOverlayProps> = (
  props: LibraryItemOverlayProps
) => {
  const { front, back } = props;
  const classes = styles(props);

  return (
    <div className={classes.root}>
      {front && <div className={classes.front}>{front}</div>}
      <div className={classes.back}>{back}</div>
    </div>
  );
};

LibraryItemOverlay.displayName = 'LibraryItemOverlay';
