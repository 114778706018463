import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React, { useEffect, useMemo } from 'react';
import { mergeExportTargets } from '../utils/mergeExportTargets';
import { ExportQueueListItem } from '../prefabs/ExportQueueListItem';
import { TVadExportQueueTile } from '../prefabs/TVadExportQueueTile';
import { getExportsState } from '../utils/getExportState';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

interface Props {
  modalContext: {
    exportBatchId: string;
    /** modal title */
    title?: string;
  };
}

export const ExportStatusModal: React.FC<Props> = (props) => {
  const { title, exportBatchId } = props.modalContext;
  const { closeAllModals } = useModalActions();

  const { resource: exportBatch, retrieve } = useResource__ALPHA({
    resourceType: 'export-batch',
    resourceId: exportBatchId,
    strategy: 'fetch-on-mount'
  });

  const exportsResources = useMemo(() => {
    return exportBatch?.attributes.exports;
  }, [exportBatch]);

  const isTVad = title === 'TV ad';

  const exportsStatus = getExportsState(exportsResources);

  /** polling export/exports resources */
  useEffect(() => {
    if (exportsStatus && exportsStatus.inProgress.length > 0) {
      const timer = setTimeout(() => {
        retrieve();
      }, 3000);
      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [retrieve, exportsStatus]);

  const merged = mergeExportTargets(exportsResources);

  const exportsCount = exportsResources?.length;

  return (
    <ModalMenuUI
      wide={true}
      title={`Export to ${title || 'YouTube'} status`}
      onCloseEvent={closeAllModals}
      loading={!merged}
      footerRightComponent={
        <Button text="Done" colorVariant="filled1" onClick={closeAllModals} />
      }
    >
      {exportsCount > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {exportsCount > 1 ? (
              <>
                <Typography color="contrast2" typographyStyle="body2">
                  {exportsStatus.inProgress.length > 0
                    ? 'Exports are in progress...'
                    : 'Exports have finished.'}
                </Typography>

                <div style={{ width: '100%', height: 16 }}></div>

                {exportsStatus.success.length > 0 &&
                  exportsStatus.inProgress.length > 0 && (
                    <Typography color="contrast2" typographyStyle="body2">
                      {`${exportsStatus.success.length} out of ${exportsCount} exports have been successful.`}
                    </Typography>
                  )}

                {exportsStatus.failed.length > 0 &&
                  exportsStatus.inProgress.length > 0 && (
                    <Typography color="danger1" typographyStyle="body2">
                      {`${exportsStatus.failed.length} out of ${exportsCount} exports failed.`}
                    </Typography>
                  )}
                {exportsStatus.success.length === exportsCount && (
                  <Typography color="success" typographyStyle="body2">
                    All exports have been successful.
                  </Typography>
                )}
                {exportsStatus.failed.length === exportsCount && (
                  <Typography color="danger1" typographyStyle="body2">
                    All exports failed.
                  </Typography>
                )}

                <div style={{ width: '100%', height: 32 }}></div>
                <Divider />
              </>
            ) : null}
          </div>
          {Object.keys(merged).map((assetId, index) => (
            <>
              {isTVad ? (
                <TVadExportQueueTile
                  exports={merged[assetId]}
                  key={index}
                  createdAt={exportBatch?.attributes.created_at}
                  gradient={'none'}
                />
              ) : (
                <ExportQueueListItem
                  key={index}
                  gradient={'none'}
                  exportBatch={exportBatch}
                  assetId={assetId}
                />
              )}
              {index < exportsCount - 1 && <Divider />}
            </>
          ))}
        </>
      ) : null}
    </ModalMenuUI>
  );
};
