import { Upload } from './components/withResourceEdges';
import { initialState } from './ducks/resource-edges/state';
import reducer, { actionCreators } from './ducks/resource-edges';

// memoization
let lastUploads;
let lastGeneratedState;
// used to prevent unnecessary "receive edges" actions, because those can be quite slow on large sets when "merge" option is active, since deduplication needs to happen
// TODO: the code below could probably be optimized a bit by batching the receive edges calls where possible (e.g. the workspace relationship)

const assetLinked = new Set();
const workspaceLinked = new Set();
const targetLinked = new Set();
const ancestorLinked = new Set();

/**
 * Helper function for use in local-storage redux middleware.
 * We only store uploads related nodes and edges.
 */
export const saveUploads = (state) => {
  // memoization to prevent costly ops
  const workspace = state.session.workspace;
  if (!workspace) {
    return;
  }

  if (location.pathname.includes('/file-request')) {
    return;
  }

  const uploadNodes = state.resourceNodes['file-upload'];

  if (lastUploads === uploadNodes) {
    return { [workspace]: lastGeneratedState };
  }

  lastUploads = uploadNodes;

  /** we store file-uploads to repopulate the upload queue and allow resuming */
  const resourceNodes = uploadNodes && {
    'file-upload': Object.keys(uploadNodes).reduce((acc, val) => {
      const upload: Upload = uploadNodes[val];

      acc[val] = {
        ...upload,
        active: false,
        failed: upload.progress < 100,
        paused: undefined
      };

      return acc;
    }, {})
  };

  /** we generate edges based on the valid uploads we've gathered in the previous step */
  const resourceEdges =
    resourceNodes &&
    Object.keys(resourceNodes['file-upload']).reduce((state, uploadId) => {
      const upload: Upload = resourceNodes['file-upload'][uploadId];

      // we build up a resource-edges state using the actual reducer + actions
      let newState = state;

      // For uploads that don't have a tusUrlStorage field (upload was queued but not started)
      // we use the asset_id field to generate an edge
      if (upload.asset_id) {
        if (!assetLinked.has(uploadId)) {
          newState = reducer(
            newState,
            actionCreators.receiveResourceEdges(
              upload.asset_id,
              'asset',
              'file-uploads',
              'file-upload',
              [
                {
                  id: upload.id,
                  type: 'file-upload'
                }
              ]
            )
          );
          assetLinked.add(uploadId);
        }
      }

      if (workspace) {
        if (!workspaceLinked.has(uploadId)) {
          newState = reducer(
            newState,
            actionCreators.receiveResourceEdges(
              workspace,
              'workspace',
              'file-uploads',
              'file-upload',
              [
                {
                  id: upload.id,
                  type: 'file-upload'
                }
              ],
              { merge: true }
            )
          );
          workspaceLinked.add(uploadId);
        }
      }

      // if there is a tusUrlStorage field, we can use the metadata to determine edges
      if (upload.tusUrlStorage) {
        const { metadata } = upload.tusUrlStorage;

        /** folder a file is uploaded to */
        if (metadata.ancestorResourceType && metadata.ancestorResourceId) {
          if (!ancestorLinked.has(uploadId)) {
            newState = reducer(
              newState,
              actionCreators.receiveResourceEdges(
                metadata.ancestorResourceId,
                metadata.ancestorResourceType,
                'file-uploads',
                'file-upload',
                [
                  {
                    id: upload.id,
                    type: 'file-upload'
                  }
                ],
                { merge: true }
              )
            );
            ancestorLinked.add(uploadId);
          }
        }
        /** resource a file is upload for (usually asset) */
        if (metadata.targetResourceType && metadata.targetResourceId) {
          if (!targetLinked.has(uploadId)) {
            reducer(
              newState,
              actionCreators.receiveResourceEdges(
                metadata.targetResourceId,
                metadata.targetResourceType,
                'file-uploads',
                'file-upload',
                [
                  {
                    id: upload.id,
                    type: 'file-upload'
                  }
                ],
                { merge: true }
              )
            );
            targetLinked.add(uploadId);
          }
        }
      }

      return newState;
    }, initialState);

  lastGeneratedState = {
    resourceNodes,
    resourceEdges
  };
  // return the generated state to local-storage middleware for saving
  return { [workspace]: lastGeneratedState };
};
