import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

/* UI */
import { ModalNoticeUI } from '../Modal/ModalNoticeUI';
import { Typography } from '../typography/Typography';
import Cube3Button, { ColorVariants } from '../Buttons/Cube3Button';
import Cube3TextField from '../forms/textfields/Cube3TextField';
import Errors from '../../../main/src/components/forms/helpers/errors';

export interface GenericNoticeModalUIProps {
  onClose: () => void;
  title: string;
  text: string;
  textSecondary?: string;
  buttonText?: string;
  secondButtonText?: string;
  buttonColorVariant?: ColorVariants;
  secondButtonColorVariant?: ColorVariants;
  secondButtonClickHandler?(): void;
  submitSecondButton?: boolean;
  renderTwoButtons?: boolean;
  input?: {
    value: string;
    onChange(ev: ChangeEvent): void;
    label?: string;
    placeholder?: string;
    validator?(val: string): undefined | string;
  };
  loading?: boolean;
}

export default function GenericNoticeModalUI(
  props: GenericNoticeModalUIProps
): JSX.Element {
  const {
    onClose,
    title,
    text,
    textSecondary,
    buttonText = 'Done',
    renderTwoButtons = false,
    buttonColorVariant = renderTwoButtons ? 'ghost1' : 'filled1',
    secondButtonText,
    secondButtonColorVariant = 'filled1',
    secondButtonClickHandler,
    submitSecondButton,
    loading,
    input
  } = props;

  const [value, setValue] = useState(input?.value);
  const [dirty, setDirty] = useState(input?.value);

  const handleChange = useCallback(
    (ev) => {
      setValue(ev.target.value);
      if (input?.onChange) {
        input.onChange(ev);
      }
    },
    [setValue, input?.onChange]
  );

  const error = useMemo(() => {
    if (input?.validator) {
      return input.validator(value);
    }
  }, [value, input?.validator]);

  const handleSubmit = useCallback(
    (ev) => {
      ev.preventDefault();
      if (!error) {
        if (submitSecondButton) {
          secondButtonClickHandler();
        } else {
          onClose();
        }
      }
    },
    [error, onClose, secondButtonClickHandler, submitSecondButton]
  );

  return (
    <ModalNoticeUI
      loading={loading}
      onCloseEvent={onClose}
      title={title}
      footerRightComponent={
        <div data-cy={'notice'}>
          <Cube3Button
            onClick={onClose}
            colorVariant={buttonColorVariant}
            text={buttonText}
            disabled={!!error}
          />
          {renderTwoButtons && (
            <Cube3Button
              onClick={secondButtonClickHandler}
              colorVariant={secondButtonColorVariant}
              text={secondButtonText}
            />
          )}
        </div>
      }
    >
      <Typography
        color={'contrast1'}
        typographyProps={{ gutterBottom: !!textSecondary }}
      >
        {text}
      </Typography>
      {textSecondary && (
        <Typography typographyStyle="body2" color={'contrast3'}>
          {textSecondary}
        </Typography>
      )}
      {input && (
        <>
          <form onSubmit={handleSubmit}>
            <Cube3TextField
              value={value}
              onChange={handleChange}
              label={input.label}
              placeholder={input.placeholder}
              fullWidth={true}
              error={dirty && error}
              onBlur={setDirty}
              autoFocus={true}
            />
            {dirty && error && <Errors errors={error} />}
          </form>
        </>
      )}
    </ModalNoticeUI>
  );
}

interface GenericModalNoticeProps {
  modalContext: GenericNoticeModalUIProps;
}

export const GenericModalNotice: React.FC<GenericModalNoticeProps> = (
  props
) => {
  const { modalContext } = props;

  return <GenericNoticeModalUI {...modalContext} />;
};
