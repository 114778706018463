import React from 'react';
import DetailViewTabTemplate from '../detailView/Templates/DetailViewTabTemplate';
import { MasterDetail } from './MasterDetail';

interface Props {
  loading: boolean;
  menuElement: React.ReactNode;
  noticeElement: React.ReactNode;
  listElement: React.ReactNode;
  detailElement: React.ReactNode;
  listHeaderElement: React.ReactNode;
}

export const AttachedContractsLayout: React.ComponentType<Props> = ({
  loading,
  menuElement,
  listElement,
  detailElement,
  noticeElement,
  listHeaderElement
}) => {
  return (
    <DetailViewTabTemplate
      disableContentMargin={true}
      loading={loading}
      renderPropsCommandBar={() => <>{menuElement}</>}
      renderPropsContents={() => (
        <MasterDetail
          listElement={listElement}
          detailElement={detailElement}
          noticeElement={noticeElement}
          listHeaderElement={listHeaderElement}
        />
      )}
    />
  );
};
