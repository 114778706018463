import { uuidv4 } from '../../../utils/uuid';

/**
/**
 * normalize files and add id's
 * @param files
 */
export const normalizeFiles = (files, metadata) => {
  const batch = uuidv4();

  return files.map((f) => {
    return {
      name: f.name,
      size: f.size,
      type: f.type,
      raw: f,
      id: uuidv4(),
      lastModified: f.lastModified,
      slice: f.slice,
      arrayBuffer: f.arrayBuffer,
      stream: f.stream,
      text: f.text,
      batch,
      metadata
    };
  });
};
