import React, { forwardRef } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { withCubicleFallback } from '../../../utils/CubicleFallback';
import { useClassName } from '../../../utils/useClassName';
import { SizeVariants, Theme } from '../../../theme/themes';
import { Color } from 'csstype';
import { stackColors } from '../../../theme/utils/stackColors';

/**
 * Generic panel component that can be used to make layout
 * sections visually distinct. It provideds some "shorthand"
 * props that make it easier to adhere to theme / token based styling.
 * The internal element is a div, and any "div" attributes passed
 * as props will be forwarded to it.
 */
export interface PanelProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  /**
   * Color of the one pixel border.
   * If no color is set there is no border.
   * A callback function can also be provided, which will get called with the theme object, making it convenient to return theme conformant colors.
   */
  borderColor?: Color | ((theme: Theme) => Color);
  /**
   * Background color of the panel
   * If no color is set a default color is used (`theme.color.background.gray.main`).
   * To make a panel transparent set the background color to `transparent`
   * A callback function can also be provided, which will get called with the theme object, making it convenient to return theme conformant colors.
   */
  backgroundColor?: Color[] | Color | ((theme: Theme) => Color | Color[]);
  /**
   * Background color of the panel when hovered
   * If no color is set a default color is used (`theme.color.background.gray.main`).
   * To make a panel transparent set the background color to `transparent`
   * A callback function can also be provided, which will get called with the theme object, making it convenient to return theme conformant colors.
   */
  hoverBackgroundColor?: Color[] | Color | ((theme: Theme) => Color | Color[]);
  /**
   * Set padding inside the panel to a multiple of the theme unit size.
   * An array of values can also be provided.
   * When this contains 2 entries, they will be used for horizontal, then vertical padding.
   * If there are 4 entries, those will be used in order: left, top, right, bottom.
   */
  padding?: number | [number, number] | [number, number, number, number];
  /**
   * Set rounded corners on the panel, using a SizeVariant or 'none' to disable
   * Defaults to SizeVariants.sm
   */
  cornerRadius?:
    | 'none'
    | SizeVariants.sm
    | SizeVariants.md
    | SizeVariants.lg
    | SizeVariants.xl
    | (typeof SizeVariants)['2xl']
    | (typeof SizeVariants)['3xl'];
  /**
   * Enable / disable rounding for individual corners.
   * Accepts an array with 4 boolean entries that respectively represent: top-left, top-right, bottom-right, bottom-left
   *
   */
  corners?: [boolean, boolean, boolean, boolean];
  /** Background color will be gradient is set to true. `true` by default */
  gradient?: boolean;
}

const cornerRadiusMapping = {
  [SizeVariants.sm]: 'sm',
  [SizeVariants.md]: 'md',
  [SizeVariants.lg]: 'lg',
  [SizeVariants.xl]: 'xl',
  [SizeVariants['2xl']]: '2xl',
  [SizeVariants['3xl']]: '3xl'
};

const useCSS = makeCSS(
  ({
    theme,
    backgroundColor,
    hoverBackgroundColor,
    borderColor,
    corners,
    padding,
    gradient = true
  }: PanelProps & { theme: Theme }) => {
    const unit = parseInt(theme.spacing['1']);

    return {
      root: {
        boxSizing: 'border-box'
      },
      background: {
        background: stackColors(
          (typeof backgroundColor === 'function'
            ? backgroundColor(theme)
            : backgroundColor) || theme.color.background.gray.main,
          gradient
        )
      },
      hoverBackground: {
        '&:hover': {
          background: stackColors(
            (typeof hoverBackgroundColor === 'function'
              ? hoverBackgroundColor(theme)
              : hoverBackgroundColor) || theme.color.background.gray.main
          )
        }
      },
      padding: {
        padding: Array.isArray(padding)
          ? padding.map((p) => `${p * unit}px`).join(' ')
          : typeof padding == 'number'
          ? `${padding * unit}px`
          : padding
      },

      border: {
        border: `1px solid ${
          typeof borderColor === 'function' ? borderColor(theme) : borderColor
        }`
      },

      // generate corner-radius classes
      ...Object.keys(cornerRadiusMapping).reduce((acc, val) => {
        acc[`corner-radius-${val}`] = {
          borderRadius: theme.borderRadius[cornerRadiusMapping[val]]
        };
        return acc;
      }, {}),

      'rounded-corners': {
        borderTopLeftRadius: corners?.[0] !== false ? undefined : '0px',
        borderTopRightRadius: corners?.[1] !== false ? undefined : '0px',
        borderBottomRightRadius: corners?.[2] !== false ? undefined : '0px',
        borderBottomLeftRadius: corners?.[3] !== false ? undefined : '0px'
      }
    };
  }
);

const Panel = forwardRef<HTMLButtonElement, PanelProps>(function (props, ref) {
  const {
    children,
    borderColor,
    padding,
    cornerRadius = SizeVariants.sm,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    backgroundColor, // not used but shouldn't be in divProps
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hoverBackgroundColor,
    corners, // not used but shouldn't be in divProps
    ...divProps
  } = props;

  const classes = useCSS(props);

  return (
    <div
      {...divProps}
      className={useClassName(
        divProps.className,
        classes.root,
        classes.background,
        hoverBackgroundColor && classes.hoverBackground,
        padding && classes.padding,
        borderColor && classes.border,
        cornerRadius && classes['rounded-corners'],
        classes[`corner-radius-${cornerRadius}`]
      )}
    >
      {children}
    </div>
  );
});

const Default = Panel;
export { Default as Panel };
export default Default;
