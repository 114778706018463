import { useState, useMemo, useCallback } from 'react';

/**
 *
 * helper hook for useResourceList, that regenerates memoized params when pageSize changes
 * @param originalParams params object that will be extended with a page size definition
 * @param initialPageSize initial page size (optional)
 *
 */
export const useGetParamsFromPagesize = (
  originalParams,
  initialPageSize = undefined
) => {
  // items per page
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const handlePageSize = useCallback(
    ({ pageSize: size, itemsPerRow, rowsPerPage } : {pageSize?: number, itemsPerRow?: number, rowsPerPage?: number} = {}) => {
      setPageSize(size);
    },
    [setPageSize]
  );
  const paramsWithpages = useMemo(() => {
    if (!originalParams) {
      return undefined;
    }

    return {
      ...originalParams,
      page: { size: pageSize }
    };
  }, [pageSize, originalParams]);
  return [paramsWithpages, handlePageSize];
};
