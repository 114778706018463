import React, { useMemo } from 'react';
import { useFormatMetadataCategory } from '@cube3/state/src/redux/components/Hooks/metadataHooks';
import { EditMetadataForm } from '../../ContentPane/DetailView/MetaData/EditMetadataFormSmart';
import { compose } from '../../../../../utils/component-helpers';

import { Fields } from '../hooks/useGeneralFormConfig';
import { useGeneralValues } from '../hooks/useGeneralValues';

import { useGeneralSubmit } from '../hooks/useGeneralSubmit';

import { reduxForm } from 'redux-form';

const GeneralForm = props => {
  const {
    category,
    handleSubmit,
    contractId,
    loading,
    initialValues,
    lockedResource
  } = props;

  let fieldsRaw = useFormatMetadataCategory(category);

  const fields = useMemo(() => {
    let lockedProjects = [];
    let lockedAssets = [];

    const archivedProjects = initialValues.key_attached_projects.filter(
      project => project.project_status === 'ProjectStatusArchived'
    );

    if (lockedResource && lockedResource.type === 'asset') {
      lockedAssets = [lockedResource];
      fieldsRaw.filter(
        f => f.id === 'key_attached_assets'
      )[0].data['lockedResources'] = lockedAssets;
    }

    if (lockedResource && lockedResource.type === 'project') {
      lockedProjects = [lockedResource];
    }

    if (archivedProjects.length > 0) {
      lockedProjects = lockedProjects.concat(archivedProjects);
    }

    if (lockedProjects.length > 0) {
      fieldsRaw.filter(
        f => f.id === 'key_attached_projects'
      )[0].data['lockedResources'] = lockedProjects;
    }

    return fieldsRaw;
  }, [lockedResource, fieldsRaw, initialValues]);

  return (
    <EditMetadataForm
      loading={loading}
      metadataForm={category}
      metadataFields={fields}
      handleSubmit={handleSubmit}
      metadataForResource={{ type: 'contract', id: contractId }}
    />
  );
};

const withGeneralValues = Wrapped => props => {
  const values = useGeneralValues(props.contractId);

  if (!values) {
    return null;
  }

  return <Wrapped {...props} initialValues={values} />;
};
const withGeneralSubmit = Wrapped => props => {
  const generalSubmit = useGeneralSubmit(props);

  return <Wrapped {...props} generalSubmit={generalSubmit} />;
};

export const GeneralFormSmart = compose(GeneralForm)(
  withGeneralValues,
  withGeneralSubmit,
  reduxForm<
    Fields,
    { category: { display_name: string }; generalSubmit(values: Fields): void }
  >({
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit: (values, dispatch, props) => {
      props.generalSubmit(values);
    }
  })
);
