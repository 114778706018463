import { FSA } from '../flux-standard-action';
import { SelectionItemInterface } from './selections';

const initialState = {
  items: [],
  actionIntent: undefined
};

const ns = 'cube3/clipboard';

// Actions
const COPY_ITEMS = `${ns}/COPY_ITEMS`;
const MOVE_ITEMS = `${ns}/MOVE_ITEMS`;
const PASTE_ITEMS = `${ns}/PASTE_ITEMS`;
const CLEAR_ITEMS = `${ns}/CLEAR_ITEMS`;
//
const COPY_INTENT = `${ns}/intents/COPY_INTENT`;
const MOVE_INTENT = `${ns}/intents/MOVE_INTENT`;

export interface ClipboardState {
  actionIntent: keyof typeof actionIntents;
  items: SelectionItemInterface[];
}

export const actions = {
  COPY_ITEMS,
  MOVE_ITEMS,
  PASTE_ITEMS,
  CLEAR_ITEMS
};

export const actionIntents = {
  COPY_INTENT,
  MOVE_INTENT
};

const copyItems = items => ({
  type: actions.COPY_ITEMS,
  payload: { items }
});

const moveItems = items => ({
  type: actions.MOVE_ITEMS,
  payload: { items }
});

const pasteItems = targetFolder => ({
  type: actions.PASTE_ITEMS,
  payload: { type: targetFolder.type, id: targetFolder.id },
  meta: {
    apiClient: {
      targetFolder
    }
  }
});

const clearItems = items => ({
  type: actions.CLEAR_ITEMS,
  payload: null
});

export const actionCreators = {
  copyItems,
  moveItems,
  pasteItems,
  clearItems
};

const getClipboardItems = state => state.items;
const getClipboardIntent = state => state.actionIntent;
const isOnClipboard = (items, item) =>
  !!items.filter(i => i.id === item.id && i.type === item.type).length;

export const selectors = {
  getClipboardItems,
  getClipboardIntent,
  isOnClipboard
};

export const clipboardReducer = (state = initialState, action: FSA) => {
  const { type, payload } = action;

  switch (type) {
    case actions.COPY_ITEMS:
      return {
        items: [...payload.items],
        actionIntent: actionIntents.COPY_INTENT
      };

    case actions.MOVE_ITEMS:
      return {
        items: [...payload.items],
        actionIntent: actionIntents.MOVE_INTENT
      };

    case actions.PASTE_ITEMS:
      return {
        items: state.items,
        actionIntent: actionIntents.COPY_INTENT
      };

    case actions.CLEAR_ITEMS:
      return {
        items: [],
        actionIntent: undefined
      };

    default:
      return state;
  }
};

export default clipboardReducer;
