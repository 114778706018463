import React, { useMemo } from 'react';

import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';

export const withCreateProjectMember = Wrapped => props => {
  const config = useMemo(() => {
    return {
      resourceType: 'project-member' as 'project-member',
      ancestor: { type: 'project' as 'project', id: props.retrievedProject.id },
      relationship: 'members',
      actionDecorators: [
        addFormId('ProjectMemberPicker', {
          formState: undefined,
          useRequestStatus: true,
          delegate: true
        })
      ],
      cacheInvalidator: (res, anc, rel) => [
        {
          type: anc.type,
          id: anc.id,
          relationship: rel
        }
      ]
    };
  }, [props.retrievedProject.id]);

  const [createResource, createResourceStatus] = useCreateResource__ALPHA(
    config
  );

  return (
    <Wrapped
      {...props}
      addMembers={createResource}
      addMembersStatus={createResourceStatus}
    />
  );
};
