import React, { forwardRef, useCallback, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { useSurfaceClasses } from '../Surface/Surface';
import checkerboardImage from '../assets/images/checkerboard.svg?url';
import { isMobile } from 'react-device-detect';
import { useClassName } from '../utils/useClassName';
import assetImage from '../assets/images/Library/file.svg?url';

interface Props {
  bordered: boolean;
  showCheckerboard?: boolean;
  widthLargerThanHeight?: boolean;
  src?: string;
  onContextMenu?: (event: React.SyntheticEvent) => void;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      mobile: {},
      root: {
        display: 'block',
        boxSizing: 'content-box', // important for double border rendering with jpg gradient.
        '&$mobile': {
          position: 'absolute'
        }
      },
      widthLargerThanHeightStyle: {
        height: 'none',
        width: '100%'
      },
      heightLargerThanWidthStyle: {
        height: '100%',
        width: 'none'
      },
      noCheckerBoardStyle: {
        backgroundImage: 'none',
        position: 'initial'
      },
      checkerBoardStyle: {
        backgroundImage: `url('${checkerboardImage}')`,
        backgroundRepeat: 'repeat',
        position: 'absolute'
      }
    }),
  { name: 'C3ImageThumbnail' }
);

const noop = () => {};

const surfaceProps = {
  floating: true,
  corners: 'rounded' as 'rounded',
  floatingHeight: 2 as 2,
  cornerRadius: 'lowEmphasis' as 'lowEmphasis'
};

export const ImageThumbnail = forwardRef<HTMLImageElement, Props>(
  (props: Props, ref) => {
    const {
      root,
      widthLargerThanHeightStyle,
      heightLargerThanWidthStyle,
      noCheckerBoardStyle,
      checkerBoardStyle,
      mobile
    } = useStyles();

    const borderedStyles = useSurfaceClasses(surfaceProps);

    const {
      showCheckerboard = true,
      widthLargerThanHeight,
      src,
      onContextMenu = noop,
      bordered
    } = props;

    const [error, setError] = useState(false);
    const handleError = useCallback(() => {
      setError(true);
    }, [setError]);

    if (error) {
      return (
        <img
          loading="lazy"
          decoding="async"
          alt={src}
          src={assetImage}
          ref={ref}
          onContextMenu={onContextMenu}
          className={useClassName(
            root,
            showCheckerboard ? checkerBoardStyle : noCheckerBoardStyle,
            bordered && borderedStyles,
            widthLargerThanHeight
              ? widthLargerThanHeightStyle
              : heightLargerThanWidthStyle,
            isMobile && mobile
          )}
        />
      );
    }

    return (
      <img
        loading="lazy"
        decoding="async"
        ref={ref}
        draggable="false"
        onContextMenu={onContextMenu}
        alt={src}
        src={src}
        className={useClassName(
          root,
          showCheckerboard ? checkerBoardStyle : noCheckerBoardStyle,
          bordered && borderedStyles,
          widthLargerThanHeight
            ? widthLargerThanHeightStyle
            : heightLargerThanWidthStyle,
          isMobile && mobile
        )}
        onError={handleError}
      />
    );
  }
);

export default ImageThumbnail;
