import React from 'react';

export const useGlobalSubmit = (
  /** action to perform as submit */
  onClick: Function,
  /** boolean or function that returns a boolean */
  disabled: boolean | (() => boolean)
) => {
  const handleKeypress = React.useCallback(
    (ev: KeyboardEvent) => {
      if (
        ev.key === 'Enter' &&
        (typeof disabled === 'function' ? !disabled() : !disabled)
      ) {
        ev.preventDefault();
        onClick(ev);
      }
    },
    [onClick, disabled]
  );

  React.useEffect(() => {
    window.addEventListener('keyup', handleKeypress, true);
    return () => window.removeEventListener('keyup', handleKeypress, true);
  }, [handleKeypress]);
};
