import { uuidv4 } from '../../../utils/uuid';
import { Uploader } from '../../../wrapped-cube-client';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import {
  actionCreators as edgeActionCreators,
  actions as edgeActions,
  mutations as mutationTypes,
  selectors__unoptimized as selectors
} from '../../ducks/resource-edges';
import {
  actionCreators as nodeActionCreators,
  actions as nodeActions,
  selectors as nodeSelectors
} from '../../ducks/resource-nodes';
import { actions, selectors as uploadSelectors } from '../../ducks/uploads';
import { addFileStub } from './addFileStub';
import { handleAssetDelete, handleFolderDelete } from './handleDelete';
import { handleFilesPostCreate } from './handleFilesPostCreate';
import { handleFilesPreCreate } from './handleFilesPreCreate';
import { handleTree } from './handleTree';
import { normalizeFiles } from './normalizeFiles';

/**
 * Upload-Middleware takes files from the action and runs a set of steps
 * required to update the ui and stay compatible with legacy cube upload-server
 *
 */
export const uploadsMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const workspaceId = getState().session.workspace;

    switch (action.type) {
      case actions.UPLOAD_WITH_FOLDER_STRUCTURE: {
        handleTree(
          action.payload.parentFolderId,
          action.payload.tree,
          action.payload.metadata,
          dispatch
        );
        break;
      }
      case actions.UPLOAD_FILES_TO_FOLDER: {
        // iterate over the files
        const files = normalizeFiles(
          action.payload.files,
          action.payload.metadata
        );
        const parentFolder = {
          type: 'folder',
          id: action.payload.parentFolderId
        } as ResourceIdentifier;
        const now = Date.now();

        files
          .map((f, idx) => {
            addFileStub(
              f,
              parentFolder,
              dispatch,
              getState,
              now + idx * 400,
              workspaceId
            );

            return f;
          })
          .forEach((file, idx) =>
            // this is just a placeholder,
            // will get overriden once the actual tus download is created

            // we check for resumable uploads
            Uploader.getResumableFromParent(file, {
              targetId: 'fake',
              targetType: 'fake',
              ancestorId: parentFolder.id,
              ancestorType: parentFolder.type,
              resumeContext: 'ancestor',
              workspace: getState().session.workspace
            }).then((resumables: { metadata: any }[]) => {
              let anyResumed;

              resumables.forEach(({ metadata }, idx) => {
                let asset, upload;

                /** Try to find the asset matching the resumable upload
                 *  NOTE: only works if assets are already retrieved (so when visiting folder)
                 */
                if (metadata) {
                  const assets = selectors.getResourceEdgesByLabel(
                    getState(),
                    metadata.ancestorResourceId,
                    metadata.ancestorResourceType,
                    'assets',
                    'asset'
                  );
                  asset = assets.find(
                    (a) => a.id === metadata.targetResourceId
                  );

                  upload = nodeSelectors.getResourceById(
                    getState(),
                    'file-upload',
                    metadata.id
                  );
                }

                if (metadata && asset && !upload?.tusUpload) {
                  // if there's a resumable upload + asset resource pair
                  // we can skip creating a new asset
                  continueNestedUpload(
                    { ...file, id: metadata.id },
                    metadata,
                    parentFolder,
                    dispatch,
                    getState,
                    idx
                  );
                  dispatch(
                    nodeActionCreators.disposeResource(
                      'file-upload',
                      file.id,
                      true
                    )
                  );
                  anyResumed = true;
                }
              });

              if (!anyResumed) {
                // no existing resumable + asset pair

                setTimeoutMaybe(() => {
                  createNestedUpload(
                    file,
                    parentFolder,
                    dispatch,
                    'assets',
                    workspaceId
                  );
                }, idx * 400);
              }
            })
          );

        return next(action);
      }

      case actions.UPLOAD_FILES_TO_PARENT_RESOURCE: {
        // iterate over the files
        const files = normalizeFiles(
          action.payload.files,
          action.payload.metadata
        );
        const parentResource = action.payload.parentResource;

        files.forEach((file) => {
          // this is just a placeholder,
          // will get overriden once the actual tus download is created
          addFileStub(
            file,
            parentResource,
            dispatch,
            getState,
            undefined,
            workspaceId
          );

          // we check for resumable uploads
          Uploader.getResumableFromParent(file, {
            targetId: 'fake',
            targetType: 'fake',
            ancestorId: parentResource.id,
            ancestorType: parentResource.type,
            resumeContext: 'ancestor',
            workspace: getState().session.workspace
          }).then((resumables: { metadata: any }[]) => {
            let anyResumed;

            resumables.forEach(({ metadata }) => {
              let asset;

              /** Try to find the asset matching the resumable upload
               *  NOTE: only works if assets are already retrieved (so when visiting folder)
               */
              if (metadata) {
                const assets = selectors.getResourceEdgesByLabel(
                  getState(),
                  metadata.ancestorResourceId,
                  metadata.ancestorResourceType,
                  'attachments',
                  'asset'
                );
                asset = assets.filter(
                  (a) => a.id === metadata.targetResourceId
                )[0];
              }

              if (metadata && asset) {
                // if there's a resumable upload + asset resource pair
                // we can skip creating a new asset
                continueNestedUpload(
                  { ...file, id: metadata.id },
                  metadata,
                  parentResource,
                  dispatch,
                  getState
                );
                dispatch(
                  nodeActionCreators.disposeResource(
                    'file-upload',
                    file.id,
                    true
                  )
                );
                anyResumed = true;
              }
            });

            if (!anyResumed) {
              const workspaceId = getState().session.workspace;

              // no existing resumable + asset pair
              createNestedUpload(
                file,
                parentResource,
                dispatch,
                'attachments',
                workspaceId
              );
            }
          });
        });

        return next(action);
      }

      case actions.CLEAR_PARENT_RESOURCE_UPLOADS:
        {
          const state = getState();
          const parentResource = action.payload.parentResource;

          const uploads = uploadSelectors.getUploadsByParentResource(
            state,
            parentResource
          );

          uploads.forEach((u) => {
            dispatch(
              nodeActionCreators.disposeResource('file-upload', u.id, true)
            );
          });
        }
        return next(action);

      case edgeActions.MUTATE_MANY_RESOURCE_EDGES: {
        const { mutations = [] } = action.meta?.apiClient || {};

        mutations.forEach((m) => {
          const { relationshipLabel, resourceType } = m;

          if (
            resourceType === 'workspace' &&
            relationshipLabel === 'deleted-nodes'
          ) {
            mutations.forEach((m) => {
              const { mutationType, resource } = m;
              if (
                mutationType === mutationTypes.MUTATION_ADD ||
                mutationType === 'add'
              ) {
                const nodeResource =
                  nodeSelectors.getResourceById(
                    getState(),
                    'asset',
                    resource.id
                  ) ||
                  nodeSelectors.getResourceById(
                    getState(),
                    'folder',
                    resource.id
                  );
                const nodeType = nodeResource?.type || resource?.type;

                if (nodeType === 'asset') {
                  handleAssetDelete({
                    resourceType: 'asset',
                    resourceId: resource.id,
                    getState
                  });
                } else if (nodeType === 'folder') {
                  handleFolderDelete({
                    resourceId: resource.id,
                    getState,
                    dispatch
                  });
                }
              }
            });
          }
        });
        return next(action);
      }

      case nodeActions.DISPOSE_RESOURCE: {
        const { resourceType, resourceId } = action.meta?.apiClient || {};
        if (resourceType === 'file-upload') {
          const state = getState();
          const upload = nodeSelectors.getResourceById(
            state,
            resourceType,
            resourceId
          );
          if (upload) {
            Uploader.cancelUpload(upload, false);
          }
        }
        if (resourceType === 'folder') {
          handleFolderDelete({ resourceId, getState, dispatch });
        }

        if (resourceType === 'asset') {
          handleAssetDelete({ resourceId, resourceType, getState });
        }
        return next(action);
      }
      default:
        // Call the next dispatch method in the middleware chain.
        return next(action);
    }
  };

/** continue resumable upload nested in a parentResource */
const continueNestedUpload = (
  file,
  metadata,
  parentResource: ResourceIdentifier,
  dispatch,
  getState,
  idx = 0
) => {
  const { uploadIds } = handleFilesPreCreate(
    dispatch,
    getState
  )({
    files: [file],
    ancestorId: parentResource.id,
    ancestorType: parentResource.type
  });

  handleFilesPostCreate(dispatch, getState, idx)(
    {
      resourceType: 'asset',
      ancestorId: parentResource.id,
      ancestorType: parentResource.type,
      files: [file]
    },
    {
      id: metadata.targetResourceId,
      type: metadata.targetResourceType
    },
    uploadIds,
    false
  );
  // NOTE: we can ignore the promise because we
  // don't have to set requeststatus for any action here

  dispatch(
    edgeActionCreators.receiveResourceEdges(
      metadata.targetResourceId,
      'asset',
      'file-uploads',
      'file-upload',
      [
        {
          id: file.id,
          type: 'file-upload'
        }
      ]
      // { merge: true }
    )
  );
};

/** start resumable upload nested in a parentResource */
const createNestedUpload = (
  file,
  parentResource: ResourceIdentifier,
  dispatch,
  assetLabel = 'assets',
  workspaceId
) => {
  // already add this upload resource to the parent folder
  // so we can show something as soon as the user drops the files
  dispatch(
    edgeActionCreators.receiveResourceEdges(
      parentResource.id,
      parentResource.type,
      'file-uploads',
      'file-upload',
      [
        {
          id: file.id,
          type: 'file-upload'
        }
      ],
      { merge: true }
    )
  );

  dispatch(
    edgeActionCreators.receiveResourceEdges(
      workspaceId,
      'workspace',
      'file-uploads',
      'file-upload',
      [
        {
          id: file.id,
          type: 'file-upload'
        }
      ],
      { merge: true }
    )
  );

  // legacy cube requires an asset to be created,
  // before we can upload something
  // The upload needs to pass along the asset ID in meta data
  // create-middleware handles this process for us by accepting files as
  // a parameter for creating an asset.
  dispatch(
    nodeActionCreators.createResourceByAncestor(
      'asset',
      uuidv4(),
      { display_name: file.name, file_size: file.size },
      parentResource.type,
      parentResource.id,
      assetLabel,
      [file],
      []
    )
  );
};

export default uploadsMiddleware;

// If window.Worker is available it means we are creating
// the asset resources in a WebWorker, so they run
// in the background and we don't need the timeouts
// to prevent the ui-loop from blocking
const setTimeoutMaybe = (cb, duration) => {
  if (window.Worker) {
    cb();
  } else {
    setTimeout(cb, duration);
  }
};
