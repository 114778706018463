import React, { CSSProperties } from 'react';
import { makeCSS } from '../../../../utils/makeCSS';
import { cx } from '@emotion/css';

const useCSS = makeCSS({
  item: { position: 'relative', flexGrow: 0, flexShrink: 0 },
  grow: { flexGrow: 1 },
  shrink: { flexShrink: 1 }
});

interface ItemProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  // allow flex growing
  grow?: boolean;
  // allow flex shrinking
  shrink?: boolean;
  // style overrides
  style?: CSSProperties;
}

// Flex Item component can be added to Flex Container
export const Item = (props: ItemProps) => {
  const { children, grow, shrink, style, ...divProps } = props;

  const classes = useCSS(props);

  return (
    <div
      className={cx(
        classes.item,
        { [classes.grow]: grow },
        { [classes.shrink]: shrink }
      )}
      style={style}
      {...divProps}
    >
      {children}
    </div>
  );
};

// Item variant with flex growing allowed
export const Grow = (props) => Item({ ...props, grow: true });

// Item variant with flex shrinking allowed
export const Shrink = (props) => Item({ ...props, shrink: true });

// Item variant with flex growing and shrinking allowed
export const Free = (props) => Item({ ...props, grow: true, shrink: true });
