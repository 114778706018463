import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface User extends ResourceBase {
  email_address?: string;
  full_name: string;
  profile_picture?: string;
  newsletter_accepted?: boolean;
  marketing_accepted?: boolean;
  newsletter_accepted_at?: string;
  marketing_accepted_at?: string;
  HasOAuth2Provider: boolean;
}

export const user: RawModel = {
  type: 'user',
  plural: 'users',
  useWorker: true,
  attributes: {
    id: 'UUID',
    // display_name: 'string',
    // display_image: 'URL',
    email_address: 'string',
    full_name: 'string',
    profile_picture: 'string',
    newsletter_accepted: 'boolean',
    marketing_accepted: 'boolean',
    newsletter_accepted_at: 'date',
    marketing_accepted_at: 'date',
    HasOAuth2Provider: 'boolean'
  },
  relationships: {}
};
