import React from 'react';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { QuestionMarkCircle } from '@cube3/ui/src/exports/subcomponents/QuestionMarkCircle';

interface Props {
  disabled: boolean;
  title: string;
}

export const DisabledTitle = React.memo((props: Props) => {
  const { disabled, title } = props;
  return (
    <div style={{ marginTop: 10 }}>
      <Typography color="contrast1" typographyStyle="body2">
        (Same as file name){' '}
        <QuestionMarkCircle disabled={disabled} title={title} />
      </Typography>
    </div>
  );
});
