// create a props comparisson function, that can compare nested subobjects, like a "classes" prop
// for use with React.Memo( MyComponen, compareProps(['classes']) )
export const compareProps = (subObjectKeys = undefined) => (
  prevProps,
  nextProps
) => {
  if (typeof nextProps !== typeof prevProps) {
    return false;
  }
  const nk = Object.keys(nextProps);
  const pk = Object.keys(prevProps);
  if (nk.length !== pk.length) {
    return false;
  }

  return nk.every(k => {
    if (subObjectKeys?.includes(k)) {
      return compareProps()(nextProps[k], prevProps[k]);
    } else {
      return nextProps[k] === prevProps[k];
    }
  });
};
