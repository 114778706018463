import { Project } from '@cube3/common/model/schema/resources/project';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useCallback, useState } from 'react';

const config = { cacheInvalidator: (r) => [r] };

export const useSetProjectLabeling = (project: Project) => {
  const [mutateProject] = useMutateResource__ALPHA(config);

  const onSaveLabeling = useCallback(
    (labeling) => {
      mutateProject({
        type: 'project',
        id: project?.id,
        allow_labeling: labeling
      });
    },
    [mutateProject, project]
  );

  return {
    onSaveLabeling
  };
};
