import React, { useCallback } from 'react';
// UI
import CheckBoxStateless from '@cube3/ui/src/Buttons/CheckBoxStateless';
import SelectComponent from '@cube3/ui/src/select/SelectComponent';
import { Typography } from '@cube3/ui/src/typography/Typography';
import MenuItem from '@material-ui/core/MenuItem';

const emptyArray: string[] = [];

// for creating the placeholder message
const lut = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten'
};

// helper to turn number into word using above look up table (LUT)
const n2s = (number) => {
  return lut[number] || number;
};

export const SelectLevel2 = (props) => {
  const {
    parentInput,
    options,
    classes,
    selectProps,
    showLabel,
    label,
    showClearButton
  } = props;

  const category = parentInput.value?.input?.level1; // the main category selected in the first dropdown
  const formValue = parentInput.value?.input?.level2; // the value in the form state
  const subOptions = options[category as string];

  const value =
    !formValue && !category
      ? emptyArray
      : subOptions?.filter((o) => formValue?.includes(o)); // verify that value is valid option

  // trigger an update on the parent value, so the rest of the metadata parsing gets run correctly
  const onChange = useCallback(
    (val) =>
      parentInput.onChange({
        ...parentInput.value,
        input: { ...parentInput.value.input, level2: val } // keep the level 1 value
      }),
    [parentInput.onChange, parentInput.value]
  );

  // some categories don't require a subcategory to be selected (described in the the apid data for metadata -> json_schema)
  const placeholder = subOptions?.optional
    ? 'Optional'
    : `Select ${n2s(subOptions.minItems)} or more`;

  return (
    <SelectComponent
      showClearButton={!!formValue?.length && showClearButton}
      showLabel={showLabel}
      labelName={label || props.input.name}
      value={value}
      onFocus={props.input.onFocus}
      onBlur={props.input.onBlur}
      onChange={onChange}
      placeholder={placeholder}
      selectProps={selectProps}
      multiple={true}
      disabled={!category}
      data-cy={props['data-cy']}
    >
      {category &&
        subOptions?.map((option) => (
          <MenuItem
            classes={{ root: classes.menuItem }}
            value={option}
            key={option}
          >
            <span style={{ pointerEvents: 'none' }}>
              {formValue?.includes(option) ? (
                <CheckBoxStateless checked={true} />
              ) : (
                <CheckBoxStateless checked={false} />
              )}
            </span>
            <Typography typographyStyle={'body2'} color="contrast2">
              {option}
            </Typography>
          </MenuItem>
        ))}
    </SelectComponent>
  );
};
