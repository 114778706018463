import Color from 'color';
// import {customTheme} from '../themes/custom-theme';


// turn name into initials
export const getInitials = (name: string) => {
  if (!name || name === '?') {
    return;
  }
  const splitName = name.split(' ').filter(seg => !!seg);
  if (splitName.length === 2) {
    return splitName.map(seg => seg[0]).join('').toUpperCase();
  } else {
    return name.slice(0, 2).toUpperCase();
  }
};

// simple random color generation using name as seed
export const getColor = (name: string) => {
  if (!name || name === ' '  || name === '?') { // illegal character
    return;
  }
  const parsedName = parseInt( name.replace(/[^a-zA-Z0-9]/g, ''), 36);
  
  if( Number.isNaN(parsedName) ) {
    return;
  }
  
  let col = Color((parseInt('1000000', 16) +
                   (parsedName %
                    parseInt('FFFFFF', 16)))
                      .toString(16)
                      .replace(/^\d/, '#'))
                .lighten(0.3)
                .desaturate(.6);
  return col;
};

// simple random color generation using name as seed
export const getContrast = color => {
  return Color(color).luminosity() > 0.4 ? 'rgba(0,0,0,0.6)' :
                                           'rgba(255,255,255,0.6)';
};
