import React from 'react';

/// @Author : Simon @ AmbassadorsLAB
/// <Summary>
/// Simple component that renders an error text
/// </Summary>

// define props that can be given to the component.
interface ComponentProps {
  errorText: string;
}

class ErrorText extends React.PureComponent<ComponentProps> {
  // Renders the error text.
  render() {
    return <span> {this.props.errorText} </span>;
  }
}

export default ErrorText;
