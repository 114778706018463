import Client from '../wrapped-cube-client';
import { useEffect, useState, useMemo } from 'react';
import { useCallback } from 'react';
/**
 * return a cube2 legacy UserHash token that is user/customer scoped
 */
export const useLegacyToken = () => {
  const [token, setToken] = useState();
  const [error, setError] = useState();

  const getToken = useCallback(() => {
    setToken(undefined);
    setError(undefined);
    Client.auth
      .getLegacyToken()
      .then(res => {
        setToken(res);
      })
      .catch(err => setError(err));
  }, []);

  useEffect(() => {
    getToken();
    return () => {
      setToken(undefined);
      setError(undefined);
    };
  }, [getToken]);

  return useMemo(() => {
    return { loading: !token && !error, token, error, getToken };
  }, [token, error, getToken]);
};
