import React, { useMemo } from 'react';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { useModalActions } from '../../Modals/modalActions';
import Preview from '../../ContentPane/Preview';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import {
  CommandBarGenericUI,
  MenuEntry
} from '@cube3/ui/src/library/CommandBar/CommandBarUI';
import { DownloadActionsProps } from '@cube3/state/src/redux/components/withDownloadActions';
import { iconDownload } from '@cube3/ui/src/icons/GeneralSVG';

interface Props extends ModalReceiverProps, DownloadActionsProps {
  modalContext: {
    assetId: string;
  };
}

export const AttachmentPreviewModal: React.FunctionComponent<Props> = (
  props
) => {
  const { modalContext } = props;
  const { assetId } = modalContext;
  const modalActions = useModalActions();
  const { resource: asset } = useResource__ALPHA({
    resourceId: assetId,
    resourceType: 'asset',
    strategy: 'fetch-on-mount'
  });

  const buttons: MenuEntry[] = useMemo(() => {
    return [
      {
        icon: iconDownload,
        title: 'Download',
        visible: true,
        onClick: () => modalActions.openModal('download_assets', asset, false)
      }
    ];
  }, [asset, modalActions]);

  return (
    <ModalMenuUI
      loading={!asset}
      wide={true}
      showCloseButton={true}
      title={`Contract attachment - ${asset?.display_name}`}
      onCloseEvent={modalActions.previousModal}
      topBarComponent={<CommandBarGenericUI primaryButtonsConfig={buttons} />}
    >
      <Preview asset={asset} disableSnapshot={true} />
    </ModalMenuUI>
  );
};
