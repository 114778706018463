import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '../typography/Typography';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      borderSpacing: '20px 8px',
      margin: '0 -20px'
    },
    cell: {},
    row: {
      paddingTop: '2px',
      paddingBottom: '2px',
      backgroundColor: 'inherit',
      verticalAlign: 'top'
    },
    key: {
      overflow: 'hidden',
      minWidth: '100px',
      wordBreak: 'break-word'
    },
    value: {
      minWidth: '150px',
      wordBreak: 'break-word'
    }
  })
);

interface Props {
  classes?: ClassNameMap;
  propertyKey: string;
  propertyValue:
    | object
    | string
    | number
    | object[]
    | string[]
    | number[]
    | JSX.Element;
}

const renderType = (value) => {
  switch (typeof value) {
    case 'object':
      if (!value) {
        return value;
      }
      if (Array.isArray(value)) {
        return value.map(renderType).join(', ');
      } else if (value.id && value.value) {
        return value.value;
      }
      return value;
    default:
      return value;
  }
};

const KeyValueRow: React.ComponentType<Props> = (props) => {
  // returns a div with the key and the chlidren aligned left and right
  const { propertyKey, propertyValue } = props;

  const classes = useStyles(props);

  const valueString = React.useMemo(
    () => renderType(propertyValue),
    [propertyValue]
  );

  /* TODO: better solution for nested objects like with PDF's */
  if (propertyKey === 'Pages') {
    return null;
  }

  return (
    <tr className={classes.row} data-text-selectable>
      <td className={[classes.cell, classes.key].join(' ')}>
        <Typography typographyStyle={'body2'} color="contrast2">
          {propertyKey}
        </Typography>
      </td>

      <td className={[classes.cell, classes.value].join(' ')}>
        <Typography typographyStyle={'body2'} color="contrast1">
          {valueString}
        </Typography>
      </td>
    </tr>
  );
};

const KeyValueTable = ({ children = undefined }) => {
  const classes = useStyles();

  return (
    <table className={classes.table}>
      <tbody>{children}</tbody>
    </table>
  );
};

const KeyValueTableStyled = KeyValueTable;

export { KeyValueTableStyled as KeyValueTable };

export default KeyValueRow;
