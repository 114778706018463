import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

/* import icons */
import {
  iconMinimizeVector,
  iconMaximizeVector,
} from '../../icons/GeneralSVG';
import * as Zoom from '../../icons/ZoomIcons';
import CustomIconButton from '../../Buttons/IconButton';
import CustomFade from '../../helpers/CustomFade';
import { PanZoomContext } from './PanZoomContext';
import {
  shortCutFullscreen,
  shortCutZoomIn,
  shortCutZoomOut,
  shortCutZoomReset,
  useImagePreviewKeyboardShortCuts
} from './hooks/useImagePreviewKeyboardShortCuts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingBottom: theme.customSpacing.padding[20],
      paddingLeft: theme.customSpacing.padding[24],
      paddingRight: theme.customSpacing.padding[24],
      paddingTop: theme.customSpacing.padding[32],
      '&$mobile': {
        padding: theme.customSpacing.padding[8]
      }
    },
    scrim: {
      pointerEvents: 'none',
      position: 'absolute',
      height: '92px', // 20 bottom , 40 middle (content), 32 top
      left: 0,
      bottom: 0,
      right: 0,
      background: theme.customPalette.surfaceAccent.onBase.scrim3,
      zIndex: -1,
      '$mobile &': {
        height: theme.sizing[48]
      }
    },
    barGroup: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%'
    },
    zoomButtonContainer: {
      display: 'flex',
      margin: 'auto',
      justifySelf: 'center',
      width: theme.sizing[144],
      justifyContent: 'space-between',
      paddingLeft: theme.customSpacing.padding[40]
    },
    fullScreenbutton: {
      justifySelf: 'flex-end',
      color: theme.customPalette.primary.textWhiteHighEmphasis
    },

    transparentBackground: {
      '&:hover': {
        backgroundColor: theme.customPalette.primary.lineWhiteMediumEmphasis
      }
    }
  }));

interface ImagePreviewControlsProps {
  toggleFullscreenAction: () => void;
  fullscreen: boolean;
  renderFullscreenControls?: () => React.ReactNode;
}

const ImagePreviewControls: React.FC<ImagePreviewControlsProps> = props => {
  const {
    toggleFullscreenAction,
    fullscreen,
    renderFullscreenControls
  } = props;

  const {
    zoomIn,
    zoomOut,
    zoomReset,
    canZoomIn,
    canZoomOut,
    canFitZoom
  } = React.useContext(PanZoomContext);

  useImagePreviewKeyboardShortCuts({
    zoomIn,
    zoomOut,
    zoomReset,
    canZoomIn,
    canZoomOut,
    canFitZoom,
    toggleFullscreenAction
  });

  const classes = useStyles();

  return (
    <div className={`${classes.container} ${isMobile ? classes.mobile : ''}`}>
      {fullscreen && renderFullscreenControls()}

      <div className={classes.scrim} />
      <div className={classes.barGroup}>
        <div className={classes.zoomButtonContainer}>
          <CustomIconButton
            path={''}
            colorVariant="ghost3"
            size={'regular'}
            svgProperties={{
              svgProps: {
                component: Zoom.ZoomOut,
                color: 'inherit',
                viewBox: '0 0 32 32'
              }
            }}
            disabled={!canZoomOut}
            onClick={zoomOut}
            title={makeTip(shortCutZoomOut)}
          />

          <CustomIconButton
            path={''}
            colorVariant="ghost3"
            size={'regular'}
            disabled={!canZoomIn}
            onClick={zoomIn}
            svgProperties={{
              svgProps: {
                component: Zoom.ZoomIn,
                color: 'inherit',
                viewBox: '0 0 32 32'
              }
            }}
            title={makeTip(shortCutZoomIn)}
          />

          <CustomIconButton
            path={''}
            colorVariant="ghost3"
            size={'regular'}
            onClick={zoomReset}
            disabled={!canFitZoom}
            svgProperties={{
              svgProps: {
                component: Zoom.ZoomReset,
                color: 'inherit',
                viewBox: '0 0 32 32'
              }
            }}
            title={makeTip(shortCutZoomReset)}
          />
        </div>

        <>
          <CustomIconButton
            colorVariant="ghost3"
            size={'regular'}
            path={fullscreen ? iconMinimizeVector : iconMaximizeVector}
            svgProperties={{
              svgProps: { viewBox: '0 0 32 32' },
            }}
            onClick={() => toggleFullscreenAction()}
            title={makeTip(shortCutFullscreen)}
          />
        </>
      </div>
    </div>
  );
};

export const makeTip = sc => `${sc.help} (${sc.tip})`;

export default CustomFade(ImagePreviewControls);
const ImagePreviewControlsMobile = ImagePreviewControls;
export { ImagePreviewControlsMobile };
