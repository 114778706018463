import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelection } from '@cube3/state/src/redux/components/useSelection';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import Button from '@cube3/ui/src/Buttons/Button';
import {
  ExportMultiAssetsInfo,
  ExportSingleAssetInfo
} from '../prefabs/ExportAssetsInfo';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { useExportOptions } from '../hooks/useExportOptions';
import { Padding } from '../../Contracts/prefabs/Padding';
import { useExportTargetDefaults } from '../../AdminCenter/views/WorkspaceIntegrationsView/hooks/useExportTargetDefaults';
import { useDispatch } from 'react-redux';
import { queueTabs, toggleMediaqueue } from '@cube3/state/src/redux/ducks/ui';
import {
  CM360Parameters,
  CM360Profile
} from '@cube3/common/model/resource-types';
import {
  GenericExportOptionsForm,
  ReducedExportOptionsFormState
} from '../prefabs/GenericExportOptionsForm';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';
import { Validator } from '../state/useGenericExportOptions';
import { validEmailaddress } from '../../../../forms/helpers/validators';
import { DisabledTitle } from '../prefabs/DisabledTitle';

/** validate email inputs */
const validators: Validator[] = [
  (values) => {
    const value = values?.find((v) => v.id === 'recipients')?.value as string[];

    const errors = value
      ?.map((v) => validEmailaddress(v, null, null, null))
      .filter(Boolean);
    return errors?.length > 0
      ? errors.map((e, idx) => ({
          field: 'recipients',
          message: 'Invalid email address',
          code: e,
          type: 'error',
          path: idx
        }))
      : undefined;
  },
  (values) => {
    const value = values?.find((v) => v.id === 'recipients')?.value as string[];
    const errors = value?.findIndex((v, idx) => value.indexOf(v) !== idx);
    return errors && errors !== -1
      ? [
          {
            field: 'recipients',
            message: 'Duplicate email address',
            code: `ERROR_DUPLICATE_ENTRY`,
            type: 'error',
            path: errors
          }
        ]
      : undefined;
  }
];

// add divider before recipients field
const dividers = ['recipients'];

// show when multiple items are selected
const title = () => (
  <DisabledTitle
    disabled={false}
    title="You cannot change the title when exporting multiple videos at once. Export the video one by one if you need a different title."
  ></DisabledTitle>
);

const disabledComponents = { display_name: title };

interface Props {
  modalContext: {
    /** selected profile from previous step */
    profile: CM360Profile;
    /** selected connected account from previous step */
    exportTargetId: string;
    /** target type used for retrieving default values  */
    exportTargetTypeId: string;
  };
}

/**
 * Dynamically render options form
 */
export const CM360ExportOptionsModal: React.FC<Props> = (props) => {
  const { profile, exportTargetId, exportTargetTypeId } = props.modalContext;

  const { selection: initialSelection } = useSelection();
  const { current: selection } = useRef(initialSelection);
  const fields = useMemo(() => {
    return [
      {
        id: 'display_name',
        label: 'Title',
        type: 'string' as const,
        disabled: selection.length > 1,
        required: selection.length === 1
      },
      {
        id: 'advertiser_id',
        label: 'Advertiser',
        type: 'radio' as const,
        options: profile.advertisers.map((a) => ({
          ...a,
          value: a.id
        })),
        required: true
      },
      {
        id: 'recipients',
        label: 'Send export notifications to',
        type: 'multistring'
      }
    ] as const;
  }, [selection, profile]);

  const [formState, setFormState] =
    useState<ReducedExportOptionsFormState<typeof fields>>();
  const { previousModal, closeAllModals, goBackModals } = useModalActions();
  const { createExportStatus, handleCreateExport, exportBatchId } =
    useExportOptions<CM360Parameters>({
      selection,
      exportTargetId
    });

  const handleSubmit = useCallback(() => {
    const { recipients, invalid, ...rest } = formState;
    handleCreateExport({
      recipients: recipients as string[],
      integration_parameters: {
        ...rest,
        profile_id: profile.id
      }
    });
  }, [handleCreateExport, formState, profile]);

  const dispatch = useDispatch();

  // open export status view modal
  useEffect(() => {
    if (exportBatchId && createExportStatus === statuses.SUCCESS) {
      closeAllModals();
      dispatch(toggleMediaqueue(true, queueTabs.EXPORTS));
    }
  }, [
    profile,
    createExportStatus,
    exportBatchId,
    formState,
    closeAllModals,
    dispatch
  ]);

  const [defaults, defaultsStatus] =
    useExportTargetDefaults(exportTargetTypeId);

  const handleCancel = useCallback(() => {
    goBackModals(2);
  }, [goBackModals]);

  const initialValues = useMemo(() => {
    return [
      {
        id: 'display_name',
        value: selection.length === 1 ? selection[0]['display_name'] : undefined
      },
      ...Object.keys(defaults || {}).map((k) => {
        return { id: k, value: defaults[k] };
      })
    ];
  }, [selection, defaults]);

  return (
    <ModalMenuUI
      title="Export to CM360 • Options"
      wide={true}
      multiplyContentSpace={10}
      loading={
        defaultsStatus !== statuses.SUCCESS ||
        (createExportStatus &&
          createExportStatus !== statuses.SUCCESS &&
          createExportStatus !== statuses.FAILED)
      }
      onCloseEvent={handleCancel}
      footerLeftComponent={
        <Button
          text={'Previous'}
          onClick={previousModal}
          colorVariant={'ghost2'}
        />
      }
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={handleCancel}
            disabled={false}
          />
          <Button
            disabled={formState?.invalid}
            colorVariant={'filled1'}
            text={'Export'}
            onClick={handleSubmit}
          />
        </>
      }
    >
      <Padding>
        {selection.length > 0 ? (
          <>
            {selection.length > 1 ? (
              <ExportMultiAssetsInfo selection={selection} />
            ) : (
              <ExportSingleAssetInfo selection={selection?.[0]} />
            )}
            <Divider gutter={true} />

            <Padding>
              {hasResolved(defaultsStatus) && initialValues && (
                <GenericExportOptionsForm
                  fields={fields}
                  disabledComponents={disabledComponents}
                  initialValues={initialValues}
                  validators={validators}
                  onFormChange={setFormState}
                  dividers={dividers}
                />
              )}
            </Padding>
          </>
        ) : null}
      </Padding>
    </ModalMenuUI>
  );
};
