import React, { useCallback, useEffect } from 'react';

import Button from '@cube3/ui/src/Buttons/Button';
import { MasterDetail } from '@cube3/ui/src/Layout/MasterDetail';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { useState } from 'react';

// helpers
import { useModalActions } from '../../Modals/modalActions';
import { useBroadcastActions } from '../hooks/useTVBroadcastActions';
import { useBroadcastMetadataForms } from '../hooks/useTVBroadcastMetadataForms';
import { statuses } from '@cube3/state/src/redux/ducks/request-status/state';
import { useDispatch } from 'react-redux';
import { queueTabs, toggleMediaqueue } from '@cube3/state/src/redux/ducks/ui';
import { useTVBroadcastMetadataConfig } from '../hooks/useTVBroadcastMetadataConfig';

// UI
import { ExportSingleAssetInfo } from '../prefabs/ExportAssetsInfo';
import { NotificationRecipientsField } from '../../../layout/Exports/prefabs/NotificationRecipientsInput';
import TVadExportDataForm from '../prefabs/TVadExportDataForm';
import { BroadcastModalContextProps } from './TVadExportQualityCheckModal';
import { Typography } from '@cube3/ui/src/typography/Typography';
import Scrollbar from '@cube3/ui/src/Scrollbar/Scrollbar';
import ProgressBadgeRenderer from '@cube3/ui/src/exports/subcomponents/ProgressBadgeRenderer';
import SupportEmailComponent from '@cube3/ui/src/Email/SupportEmailComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContents: {
      margin: 0,
      height: '700px',
      maxHeight: 'calc(100vh - 192px)'
    },
    listElement: {
      cursor: 'pointer',
      padding: '16px 16px 8px 24px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },

    active: {
      background: theme.customPalette.surfaceAccent.onBase.selected2
    },
    failedRequest: {
      paddingLeft: '24px'
    }
  })
);

interface Props {
  modalContext: BroadcastModalContextProps;
}

const TVadExportDataModal = (props: Props) => {
  const { assets, exportTargetType, exportTargets } = props.modalContext;
  const classes = useStyles();
  const [recipients, setRecipients] = useState([]);
  const { previousModal, goBackModals, closeAllModals } = useModalActions();

  const { allFormsValid, destroyAllForms, formSubmitting, allFormErrors } =
    useBroadcastMetadataForms(assets);
  const metadataConfig = useTVBroadcastMetadataConfig();

  const [selectedAsset, setSelectedAsset] = useState(assets[0]);

  const { createBatchStatus, createBatch } = useBroadcastActions({
    assets,
    exportTargets
  });

  const onSave = useCallback(() => {
    createBatch({ recipients });
  }, [createBatch, recipients, exportTargets]);

  const onCancel = useCallback(() => {
    goBackModals(3);
    destroyAllForms();
  }, [goBackModals, destroyAllForms]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (createBatchStatus === statuses.SUCCESS) {
      destroyAllForms();
      closeAllModals();
      dispatch(toggleMediaqueue(true, queueTabs.EXPORTS));
    }
  }, [
    createBatchStatus,
    closeAllModals,
    destroyAllForms,
    dispatch,
    toggleMediaqueue
  ]);

  const loading =
    metadataConfig.loading ||
    //  firstExport.loading ||
    formSubmitting ||
    (createBatchStatus &&
      createBatchStatus !== statuses.SUCCESS &&
      createBatchStatus !== statuses.FAILED);

  return (
    <ModalMenuUI
      extraWide={true}
      classes={{
        contents: classes.modalContents
      }}
      title="Export TV ad • Export data"
      onCloseEvent={previousModal}
      loading={loading}
      footerLeftComponent={
        <Button text={'Cancel'} colorVariant={'ghost2'} onClick={onCancel} />
      }
      footerRightComponent={
        <>
          <Button
            text={'Previous'}
            onClick={previousModal}
            colorVariant={'ghost2'}
          />
          <Button
            disabled={!allFormsValid.allValid}
            colorVariant={'filled1'}
            text={'Export'}
            onClick={onSave}
          />
        </>
      }
    >
      {createBatchStatus === statuses.FAILED && (
        <Typography color="danger1" className={classes.failedRequest}>
          Something went wrong with your request, please try again later or
          contact <SupportEmailComponent />
          ...
        </Typography>
      )}
      {!loading && assets?.length > 1 && (
        <>
          <div style={{ margin: '24px', width: '70%' }}>
            <NotificationRecipientsField
              recipients={recipients}
              setRecipients={setRecipients}
            />
          </div>
          <Divider />
        </>
      )}
      {assets && assets.length > 1 && !metadataConfig.loading && (
        <MasterDetail
          listElement={
            <Scrollbar>
              <div style={{ paddingTop: 24 }}>
                {assets.map((asset) => (
                  <div
                    key={asset.id}
                    onClick={() => setSelectedAsset(asset)}
                    className={`${classes.listElement} ${
                      asset.id === selectedAsset.id && classes.active
                    }`}
                  >
                    <ExportSingleAssetInfo
                      selection={asset}
                      hideText={true}
                      width={60}
                    />
                    <ProgressBadgeRenderer
                      form={allFormErrors.errors[asset.id]}
                    />
                  </div>
                ))}
              </div>
            </Scrollbar>
          }
          detailElement={
            <div style={{ marginTop: '32px' }}>
              {assets.map((asset) => (
                <TVadExportDataForm
                  key={asset.id}
                  asset={asset}
                  visible={asset.id === selectedAsset?.id}
                  exportTargetType={exportTargetType}
                  exportTargets={exportTargets}
                  //  batchId={batchId}
                  recipients={recipients}
                  disableSubmit={true}
                  {...metadataConfig}
                />
              ))}
            </div>
          }
        />
      )}

      {assets && assets.length === 1 && !metadataConfig.loading && (
        <>
          {loading ? null : (
            <div style={{ margin: '16px 32px', paddingBottom: 16 }}>
              <ExportSingleAssetInfo
                selection={assets[0]}
                rootStyles={{ margin: 16 }}
              />
              <Divider />
              <div style={{ height: 24 }}></div>
              <TVadExportDataForm
                visible={true}
                exportTargetType={exportTargetType}
                exportTargets={exportTargets}
                asset={assets[0]}
                recipients={recipients}
                disableSubmit={true}
                {...metadataConfig}
              />
              <div style={{ height: 8 }}></div>
              <Divider />
              <div style={{ marginTop: '24px' }}>
                <NotificationRecipientsField
                  setRecipients={setRecipients}
                  recipients={recipients}
                  {...metadataConfig}
                />
              </div>
            </div>
          )}
        </>
      )}
    </ModalMenuUI>
  );
};

export default TVadExportDataModal;
