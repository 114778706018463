import React from 'react';

export const List = props => {
  const { className } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30 8H16V10H30V8Z" className={className} />
      <path d="M30 22H16V24H30V22Z" className={className} />
      <path
        d="M10 14H4C3.46975 13.9994 2.9614 13.7885 2.58646 13.4135C2.21152 13.0386 2.00061 12.5302 2 12V6C2.00061 5.46975 2.21152 4.9614 2.58646 4.58646C2.9614 4.21152 3.46975 4.00061 4 4H10C10.5302 4.00061 11.0386 4.21152 11.4135 4.58646C11.7885 4.9614 11.9994 5.46975 12 6V12C11.9994 12.5302 11.7885 13.0386 11.4135 13.4135C11.0386 13.7885 10.5302 13.9994 10 14ZM4 6V12H10.0012L10 6H4Z"
        className={className}
      />
      <path
        d="M10 28H4C3.46975 27.9994 2.9614 27.7885 2.58646 27.4135C2.21152 27.0386 2.00061 26.5302 2 26V20C2.00061 19.4698 2.21152 18.9614 2.58646 18.5865C2.9614 18.2115 3.46975 18.0006 4 18H10C10.5302 18.0006 11.0386 18.2115 11.4135 18.5865C11.7885 18.9614 11.9994 19.4698 12 20V26C11.9994 26.5302 11.7885 27.0386 11.4135 27.4135C11.0386 27.7885 10.5302 27.9994 10 28ZM4 20V26H10.0012L10 20H4Z"
        className={className}
      />
    </svg>
  );
};
