import React, { useState, useCallback, useRef } from 'react';
/* UI */
import { ModalNoticeUI } from '../../Modal/ModalNoticeUI';
import Button from '../../Buttons/Button';
import { Typography } from '../../typography/Typography';
import Cube3TextField from '../../forms/textfields/Cube3TextField';
import { Divider } from '../../Surface/Divider';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';
import { uuidv4 } from '../../../../state/src/utils/uuid';

/**
 * @summary: Renders the UI for deleting a project
 * @author Simon
 */

interface Properties {
  onCloseEvent: () => void;
  onClickDelete: () => void;
  projectName: string;
  projectCount: number;
}

const charsLut = ['a', 's', 'd', 'q', 'w', 'e', 'z', 'c', '1', '2', '3'];

const DeleteProjectModalUI: React.FunctionComponent<Properties> = (props) => {
  const { onCloseEvent, onClickDelete, projectName, projectCount } = props;

  const [checkText, setCheckText] = useState<string>('');
  const handleChange = useCallback(
    (ev) => {
      setCheckText(ev.target.value);
    },
    [setCheckText]
  );
  const { current: passphrase } = useRef(
    uuidv4()
      .slice(0, 4)

      .split('')
      .map((c) => charsLut[parseInt(c, 16) % 8])
      .join('')
      .toUpperCase()
  );

  const invalid = checkText?.toUpperCase() !== passphrase?.toUpperCase();

  useGlobalSubmit(onClickDelete, invalid);

  return (
    <ModalNoticeUI
      title={
        projectName
          ? `Delete Project "${projectName}"?`
          : 'Delete multiple projects'
      }
      onCloseEvent={() => onCloseEvent()}
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            onClick={() => onCloseEvent()}
          />
          <Button
            text={'Delete'}
            disabled={invalid}
            colorVariant="filledDanger"
            onClick={() => onClickDelete()}
          />
        </>
      }
    >
      {projectCount > 1 && (
        <Typography gutter={true} color="warning">
          You are about to delete {projectCount} Projects
        </Typography>
      )}
      <Typography>
        {projectName
          ? 'Are you really sure you want to delete this Project?'
          : 'Are you really sure you want to delete these Projects?'}
      </Typography>

      <Typography typographyProps={{ gutterBottom: true }} color="contrast2">
        <ul>
          <li>{`You will no longer be able to access ${
            projectName ? 'it' : 'them'
          }`}</li>
          <li>Neither will other Project Members</li>
          <li>You can undo this from the Bin (within 20 days) </li>
        </ul>
      </Typography>

      <Divider gutter={true} />

      <Cube3TextField
        autoFocus={true}
        fullWidth={true}
        label={
          <>
            <Typography color="contrast2" display="inline">
              Type{' '}
            </Typography>
            <Typography color="contrast1" display="inline">
              {passphrase}
            </Typography>
            <Typography color="contrast2" display="inline">
              {' '}
              to proceed
            </Typography>
          </>
        }
        onChange={handleChange}
        error={invalid}
      />
    </ModalNoticeUI>
  );
};

export default DeleteProjectModalUI;
