import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import GeneralSVG, {
  GeneralSVGProperties,
  iconKeyboardArrowDown
} from '../icons/GeneralSVG';
import { SizeVariations } from '../themes/custom-theme';
import IconButton, {
  IconButtonProps
} from '@material-ui/core/IconButton/IconButton';
import {
  getSurfaceColor,
  getIconStyles,
  ColorVariants,
  getDisabledStyles
} from './Cube3Button';
import UseHover from '../helpers/hooks/UseHover';
import MaybeTooltip, { TooltipReceivedProps } from '../Tooltip/MaybeTooltip';

/// @author: Simon@AmbassadorsLAB
/// <Summary>
/// Simple icon wih large display of icon and some size and color options
/// Be careful changing out color variable names since the css depends on those variable names
/// and are not strong typed.
/// </Summary

type Variants = 'regular' | 'withDropdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: ({
      colorVariant = 'filled1',
      disableHover = false,
      size = 'small'
    }: Cube3IconButtonProps) => ({
      cursor: 'pointer',
      padding: '0px',
      backgroundColor: getSurfaceColor(colorVariant, theme).color,
      width: theme.sizes.iconbutton[size],
      height: theme.sizes.iconbutton[size],
      '&:hover': {
        backgroundColor: disableHover
          ? getSurfaceColor(colorVariant, theme).color
          : theme.customPalette.surfaceState.getSurfaceStateColor(
              getSurfaceColor(colorVariant, theme).color,
              getSurfaceColor(colorVariant, theme).base,
              ['hover1']
            )
      },
      '&:active': {
        opacity: 0.5
      },
      // '&:focus': {
      //   boxShadow: `0px 0px 0px 1px ${
      //     getOutlineColor(colorVariant, theme).color
      //   }`
      // },
      borderRadius: theme.surfaceCorners.lowEmphasis
    }),
    disabledClass: ({ colorVariant = 'filled1' }: Cube3IconButtonProps) => ({
      opacity: theme.customPalette.opacity.disabled1,
      cursor: 'default',
      backgroundColor: getDisabledStyles(colorVariant, theme),
      '&:hover': {
        backgroundColor: getDisabledStyles(colorVariant, theme)
      }
    }),
    withDropdown: () => ({
      paddingLeft: theme.customSpacing.padding[8],
      width: 'auto'
    }),
    dropdownSvgStyling: ({ colorVariant = 'filled1' }) => ({
      width: theme.sizing[24],
      opacity: colorVariant === 'covertDropdown' ? 0 : 'initial'
    }),
    dropdownSvgStylingConvert: () => ({
      opacity: 'initial'
    })
  })
);

export interface Cube3IconButtonProps extends TooltipReceivedProps {
  variant?: Variants;
  colorVariant?: ColorVariants;
  path?: string;
  onFocus?: (ev: React.FocusEvent) => void;
  onClick?: (ev: React.MouseEvent) => void;
  onMouseDown?: (ev: React.MouseEvent) => void;
  size?: SizeVariations;
  disabled?: boolean;
  svgProperties?: GeneralSVGProperties;
  extraIconButtonProps?: IconButtonProps;
  active?: boolean;
  disableHover?: boolean;
  className?: string;
}
function Cube3IconButton(props: Cube3IconButtonProps) {
  const [hoverRef, isHovered] = UseHover();
  const classes = useStyles(props);
  const {
    buttonRoot,
    withDropdown,
    dropdownSvgStyling,
    dropdownSvgStylingConvert,
    disabledClass
  } = classes;
  const {
    path,
    onClick: onClickIcon,
    onMouseDown,
    onFocus,
    size = 'regular',
    disabled = false,
    svgProperties,
    extraIconButtonProps,
    colorVariant,
    variant,
    active,
    className,
    title,
    placement
  } = props;

  const iconStyles = getIconStyles(colorVariant, disabled, active);

  return (
    <MaybeTooltip disabled={!title} title={title} placement={placement}>
      <IconButton
        data-pw={path}
        aria-label={props['aria-label']}
        disabled={disabled}
        onFocus={onFocus}
        onClick={onClickIcon}
        onMouseDown={onMouseDown}
        className={`${buttonRoot} ${className} ${
          variant === 'withDropdown' && withDropdown
        } ${disabled && disabledClass}`}
        disableRipple={true}
        ref={hoverRef}
        {...extraIconButtonProps}
      >
        <GeneralSVG
          size={size}
          path={path}
          {...svgProperties} // spread any extra given svg properties
          {...iconStyles}
        />
        {variant === 'withDropdown' && (
          <GeneralSVG
            className={`${dropdownSvgStyling} ${
              isHovered && dropdownSvgStylingConvert
            }`}
            path={iconKeyboardArrowDown}
            {...svgProperties}
            {...iconStyles}
          />
        )}
      </IconButton>
    </MaybeTooltip>
  );
}

export default Cube3IconButton;
