import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import Button from '../../Buttons/Button';
import Bytes from '../../human/Bytes';
import React, { useCallback, useState } from 'react';
import { Typography } from '../../typography/Typography';
import GeneralSVG from '../../icons/GeneralSVG';
import MaybeTooltip from '../../Tooltip/MaybeTooltip';
import { Divider } from '../../Surface/Divider';
import { downloadErrors } from './downloadErrors';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      display: 'grid',
      gridTemplateColumns: '2fr 160px 1fr', // use the width of 'Download started' as the max-width
      alignItems: 'center',
      marginTop: '12px',
      marginBottom: '6px'
    },
    info: {
      marginLeft: '12px'
    }
  })
);

export type DownloadStatusType =
  | 'Preparing download...'
  | 'Preparing format...'
  | 'Download started'
  | 'Not available'
  | 'Download'
  | '';

interface RowProps {
  title: string;
  fileCount?: number;
  loading?: boolean;
  disabled: boolean;
  /** When hovering the file name, a tooltip with the Format details will be shown
   * - container type
   * - codec type
   * - dimensions:
   *    - single file or multi files with same dimensions: show width and height
   *    - when the selection has files that have various dimensions: show `various dimensions`
   */
  fileNameTooltip?: string;
  /** when hovering the warning icon, show warn/error message */
  warning?: string | React.ReactElement;
  downloadSize?: string | number;
  downloadStatus?: DownloadStatusType;
  onDownload: () => void;
  onHoverWarning?: () => void;
}

export const DownloadItemRowUI: React.FunctionComponent<RowProps> = (props) => {
  const {
    loading,
    title,
    fileCount,
    disabled,
    warning,
    fileNameTooltip,
    downloadSize,
    downloadStatus,
    onDownload,
    onHoverWarning
  } = props;
  const classes = useStyles();
  const [downloadStarted, setDownloadStarted] = useState('');

  const onClickDownload = useCallback(() => {
    onDownload();
    if (!disabled) {
      setDownloadStarted('Download started');
    }
  }, [disabled, onDownload]);

  const downloadButtonText = downloadStatus || downloadStarted || 'Download';
  return loading ? (
    <Shimmer width={500} height={30} />
  ) : (
    <>
      <div className={classes.row}>
        <MaybeTooltip
          disabled={!fileNameTooltip}
          title={fileNameTooltip}
          placement="bottom"
        >
          <Typography color={disabled ? 'contrast3' : 'contrast1'}>
            {title}
          </Typography>
        </MaybeTooltip>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: warning ? '-12px' : 0,
            justifySelf: 'self-end'
          }}
        >
          {(warning || downloadStatus === 'Not available') && (
            <Tooltip
              title={warning || downloadErrors.server_error}
              placement="bottom"
            >
              <span
                style={{ height: 16, marginRight: '-8px' }}
                onMouseEnter={onHoverWarning}
              >
                {/* Please note that sometimes you will have to put a custom element in a span or div to make the tooltip work */}
                <GeneralSVG path="iconWarn" size="big" color="warning" />
              </span>
            </Tooltip>
          )}
          <Button
            colorVariant={'ghost1'}
            disabled={disabled || downloadButtonText !== 'Download'}
            text={downloadButtonText}
            extraButtonProperties={{
              'data-cy': `download${title === 'Preview' ? 'Preview' : 'Source'}`
            }}
            onClick={onClickDownload}
          />
        </div>

        {
          !disabled ? (
            <Typography className={classes.info}>
              {fileCount ? `${fileCount} file${fileCount > 1 ? 's' : ''}` : '-'}
              {downloadSize && ' • '}
              {downloadSize && (
                <Bytes
                  size={downloadSize?.toString() || ''}
                  toolTip={true}
                  fullForm={false}
                  placement={'bottom'}
                />
              )}
            </Typography>
          ) : (
            <div></div>
          ) /** necessary for layout purposes */
        }
      </div>
      <Divider />
    </>
  );
};

/** Get dimensions of the original type:
 * - when the selection has files that have various dimensions, instead of the dimensions, it will say: various dimensions
 */
export const getAssetsDimensions = (preset, files) => {
  if (!files?.length) return '';
  // should 'various dimensions' if there's folder selected
  if (files.filter((f) => f.type === 'folder')?.length) {
    return 'various dimensions';
  }
  // otherwise check if all the assets have the same sizes
  const dimensions: string[] = [];
  files?.forEach((a) => {
    if (a.type === 'asset' && a.media) {
      const media = a.media.find((m) => m.type === 'source');
      if (media) {
        const { width, height } = media;
        const str = `${width} x ${height}`;
        if (width && height && !dimensions.includes(str)) {
          dimensions.push(str);
        }
      }
    }
  });
  return dimensions.length > 1
    ? 'various dimensions'
    : dimensions.length === 1
    ? dimensions[0]
    : '';
};

/** Get dimensions from `preset` */
export const getPresetDimensions = (preset) => {
  if (
    !preset ||
    !preset.width ||
    !preset.width ||
    preset.width < 0 ||
    preset.height < 0
  )
    return '';
  return `${preset.width} x ${preset.height}`;
};

/* Container type • codec type • dimension */
export const generateFileNameTooltip = (preset, files? /**  */) => {
  if (!preset) return '';

  const { display_name, group } = preset.attributes;
  const dimensions =
    display_name === 'Original'
      ? getAssetsDimensions(preset, files)
      : getPresetDimensions(preset);

  return [group, display_name, dimensions].filter(Boolean).join(' • ');
};
