import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

// ui
import { ModalMenuUI } from '../../../../Modal/ModalMenuUI';
import SharedLinkUI from '../../Subcomponents/SharedLinkUI'; // TODO MOVE THIS ASWELL
import { iconAdd } from '../../../../icons/GeneralSVG';
import { Divider } from '../../../../Surface/Divider';
import InteractableSurface from '../../../../Surface/InteractableSurface';

/* Import Interface */
import { SharedLinkUIProps } from '../../Subcomponents/SharedLinkUI';
import Cube3Button from '../../../../Buttons/Cube3Button';

/// <Summary>
/// UI component for share link overview
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    sharedLinkContainer: {
      padding: '8px 16px'
    },
    newLinkButton: {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset'
      }
    },
    newLinkContainer: {
      display: 'flex',
      padding: '8px 16px',
      marginTop: 20,
      marginBottom: 20,
      alignItems: 'center'
    }
  }));

interface ShareLinkOverviewProps {
  loading?: boolean;
  onClickNewShare?: () => void;
  onCloseEvent: () => void;
  sharedlinks: SharedLinkUIProps[];
}

const ShareLinkOverview: React.FC<ShareLinkOverviewProps> = props => {
  const {
    onClickNewShare,
    onCloseEvent,
    sharedlinks = [],
    loading = false
  } = props;

  const classes = useStyles();

  return (
    <ModalMenuUI
      title={'Links'}
      onCloseEvent={() => onCloseEvent()}
      loading={loading}
      footerRightComponent={
        <>
          <Cube3Button
            text={'Done'}
            colorVariant={'filled1'}
            onClick={() => onCloseEvent()}
          />
        </>
      }
    >
      <InteractableSurface
        classes={{ root: classes.newLinkContainer }}
        onClickEvent={() => onClickNewShare()}
        highlightOnHover={true}
        surfaceProps={{
          background: 'transparent',
          corners: 'rounded'
        }}
      >
        <Cube3Button
          text="New Link"
          pathLeftSvg={iconAdd}
          colorVariant="ghost1"
          disableHover={true}
          data-cy="newLinkButton"
          className={classes.newLinkButton}
          marginCollapse={'left'}
        />
      </InteractableSurface>

      <Divider />

      {sharedlinks.map((item, index) => {
        return (
          <SharedLinkUI
            key={index}
            classes={{ container: classes.sharedLinkContainer }}
            created_at={item.created_at}
            email_addresses={item.email_addresses}
            expires_at={item.expires_at}
            expires={item.expires}
            expired={item.expired}
            onClickShare={() => item.onClickShare()}
          />
        );
      })}
    </ModalMenuUI>
  );
};

export default ShareLinkOverview;
