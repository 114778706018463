import { Asset } from '@cube3/common/model/schema/resources/asset';
import { Url } from '@cube3/common/model/schema/resources/url';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { useCallback, useMemo } from 'react';

const cacheInvalidator = (r) => [r];
export const useMutateUrlAsset = (asset: Asset, url: Url, form: string) => {
  const [mutateResource] = useMutateResource__ALPHA(
    useMemo(
      () => ({
        cacheInvalidator,
        actionDecorators: [
          addFormId(form, {
            formState: undefined,
            useRequestStatus: true,
            delegate: true
          })
        ]
      }),
      [addFormId, form]
    )
  );

  const onMutate = useCallback(
    (name: string, location: string) => {
      if (url && url.location !== location) {
        mutateResource({
          type: 'url',
          id: url.id,
          location: location
        });
      }
      if (asset && asset.display_name !== name) {
        mutateResource({
          type: 'asset',
          id: asset.id,
          display_name: name
        });
      }
    },
    [mutateResource, asset, url]
  );

  return onMutate;
};
