import React from 'react';
import { ModalReceiverProps } from '../../../layout/Modals/ModalManager';
import { useModalActions } from '../../../layout/Modals/modalActions';
import { ProjectActivityModalUI } from '@cube3/ui/src/Projects/Modals/ProjectActivityModalUI';

import { ProjectActivity } from '../../ActvityFeeds/ProjectActivity';

type Properties = ModalReceiverProps;

export const ProjectActivityModal: React.FunctionComponent<Properties> = (
  props
) => {
  const modalActions = useModalActions();

  const projectId: string = props.modalContext.id;

  return (
    <ProjectActivityModalUI
      onClickClose={() => {
        modalActions.closeAllModals();
      }}
    >
      <ProjectActivity projectId={projectId} />
    </ProjectActivityModalUI>
  );
};
