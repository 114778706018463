import React, { useCallback, useMemo } from 'react';
//
import {
  normalizeResourceConfig,
  parseResourceConfig,
  WithResourceConfig
} from './configHelpers';

import { statuses } from '../ducks/request-status';
import { useResource__ALPHA } from './Hooks/useResource';

export const withResource = <P extends {}>(config: WithResourceConfig<P>) => {
  // need to convert to any so I can check for deprecated fields
  // eslint-disable-next-line
  const checkConfig = config as any;

  if (
    !checkConfig.resourceId ||
    (!checkConfig.resourceType && checkConfig.id && checkConfig.type)
  ) {
    console.warn(`
      withResource config uses deprecated syntax...
      Please use "resourceId" instead of "Id"
      and "resourceType" instead of "type"
    `);
    config.resourceId = checkConfig.id;
    config.resourceType = checkConfig.type;
  }

  // // we make sure the config is in a predictable shape
  const normalizedConfig = normalizeResourceConfig<P>(
    config as WithResourceConfig<P>
  );

  const withConnectedResource = (WrappedComnent) => (props) => {
    const parsedConfig = parseResourceConfig(normalizedConfig, props);

    // parse and map retrieved state based on config
    const mapDataToProps = useCallback(
      (data) => {
        const { mapper: mapping, parser } = parsedConfig;
        const parsed = parser(data);
        if (typeof mapping === 'string') {
          // simple mapping to string (legacy way)
          // loading prop is set for convenience
          // NOTE: we now also inject <string>Status and <string>Retrieve
          const stringMapped = {
            [mapping]: parsed.resource,
            [`${mapping}Retrieve`]: parsed.retrieve,
            [`${mapping}Status`]: parsed.status,
            loading: parsed.status === statuses.IN_FLIGHT || parsed.loading
          };
          return stringMapped;
        } else if (typeof mapping === 'object') {
          // map parsed data fields to propsnames via an Object
          // see configHelpers for explanation
          return Object.keys(mapping)
            .map((k) => {
              return { [mapping[k]]: parsed[k] };
            })
            .reduce((acc, val) => {
              return { ...acc, ...val };
            }, {});
        } else {
          // default mapping
          // NOTE: if you parse but don't map,
          // you might get undefineds
          const defaultMapped = {
            resource: parsed.resource,
            retrieve: parsed.retrieve,
            status: parsed.status,
            loading: parsed.status === statuses.IN_FLIGHT || parsed.loading
          };

          return defaultMapped;
        }
      },
      [normalizedConfig]
    );

    const resource = useResource__ALPHA(parsedConfig);
    const mappedProps = useMemo(() => {
      return mapDataToProps(resource);
    }, [mapDataToProps, resource]);

    return <WrappedComnent {...props} {...mappedProps} />;
  };

  return withConnectedResource;

  // const enhancerFunction = compose(
  //   // withResourceState: regular redux connect function,
  //   // but we generate our STP and DTP
  //   // via a function that has access to the normalized
  //   // config (so we can access own props)
  //   withResourceState(normalizedConfig),
  //   // withResourceLifeCycle: the innermost wrapping adds
  //   // lifecycle hooks, so state gets retrieved
  //   // when the component mounts. Here the retrieved state
  //   // also gets parsed and mappped
  //   withResourceLifeCycle(normalizedConfig)
  // );

  // // withResource returns an enhancer function
  // // call it on your component
  // return enhancerFunction;
};
