import React from 'react';

import { Field, WrappedFieldArrayProps, getFormValues } from 'redux-form';

import {
  makeValidator,
  validEmailaddress
} from '../../../../../forms/helpers/validators';

// UI
import SuggestionsContainer from '@cube3/ui/src/inputChipPicker/SuggestionsContainer';
import { ResourceSuggestionItem } from '@cube3/ui/src/inputChipPicker/accountPicker/ResourceSuggestionItem';
import WrappedSuggestionsInputField from '@cube3/ui/src/forms/WrappedSuggestionsInputField';
import {
  PublicResource,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { RecipientChip } from './RecipientChip';
import { ClearOn } from '@cube3/ui/src/inputChipPicker/SuggestionsContainer/SuggestionsContainer';

// allow empty input or valid input
const inputValidator = makeValidator([
  (value, all, props, errors) => {
    return value && validEmailaddress(value, all, props, errors);
  }
]);

export type SuggestionResource = PublicResource;

export interface Props extends WrappedFieldArrayProps<NotificationRecipient> {
  clearInput(): void;
  form: string;
  controlledInputValue?: string;
  suggestedResources?: SuggestionResource[];
  excludedResources?: SuggestionResource[];
  showDisabled: boolean;
  placeholder?: string;
  excludedReasonText?: string;
  suggestionsLoading?: boolean;
  clearOn?: ClearOn;
}

interface NotificationRecipient extends ResourceIdentifier {
  email_address: string;
}

const emptyArray: NotificationRecipient[] = [];

const itemToString = (i) => (i ? `${i.type}-${i.email_address}` : '');
const isSelected = (suggestion, selected) => {
  return (
    selected.filter(
      (s) =>
        s.type === suggestion.type &&
        s.email_address === suggestion.email_address
    ).length > 0
  );
};

export const MultipleRecipientPicker: React.FunctionComponent<Props> =
  React.memo((props) => {
    const {
      clearInput,
      form,
      fields,
      suggestedResources = emptyArray,
      controlledInputValue,
      excludedResources = emptyArray,
      placeholder = `Start typing an email address...`,
      excludedReasonText = 'Already selected',
      showDisabled = false,
      suggestionsLoading,
      clearOn
    } = props;

    const selectedSuggestions = fields.getAll() || emptyArray;
    const excludedSuggestions = excludedResources;

    const inputValues = useTypedSelector((state) => {
      return (
        getFormValues(form)(state) &&
        getFormValues(form)(state)[`${fields.name}_inputValue`]
      );
    });

    return (
      <SuggestionsContainer
        inputValue={controlledInputValue || inputValues}
        allSuggestions={suggestedResources}
        selectedSuggestions={selectedSuggestions}
        excludedSuggestions={excludedSuggestions}
        suggestionsLoading={suggestionsLoading}
        excludedReasonText={excludedReasonText}
        showDisabled={!!(controlledInputValue || inputValues) || showDisabled}
        isSelectedFn={isSelected}
        keysListenersArrayToAddNewSuggestions={['Tab', ',', ';', 'Enter', ' ']}
        addItemToSelectionArray={(item) =>
          fields.push({
            id: item.id,
            type: item.type,
            email_address: item.email_address
          })
        }
        removeItemFromSelectionArray={(index) => fields.remove(index)}
        allowAddingOfNewSuggestions={true}
        newSuggestionPrimaryKey={'email_address'}
        clearOn={clearOn}
        clearInputField={clearInput}
        itemToString={itemToString}
        renderSelectedItem={(selectedSuggestion, index) => {
          return (
            <Field
              key={itemToString(selectedSuggestion)}
              name={itemToString(selectedSuggestion)}
              type="text"
              component={RecipientChip}
              removeItem={() => {
                fields.remove(index);
              }}
              index={index}
              props={{
                resource: {
                  id: selectedSuggestion.id,
                  type: selectedSuggestion.type,
                  email_address: selectedSuggestion.email_address
                }
              }}
            />
          );
        }}
        renderInputChipPicker={(
          handleInputKeyDown,
          selectHighlightedItem,
          highlightedIndex,
          isOpen,
          reset,
          inputValue,
          inputField,
          getInputProps,
          filteredSuggestions,
          setHighlightedIndex,
          openMenu,
          closeMenu
        ) => (
          <Field
            name={`${fields.name}_inputValue`}
            type="inputValue"
            validate={inputValidator}
            component={WrappedSuggestionsInputField}
            props={{
              handleInputKeyDown,
              selectHighlightedItem,
              highlightedIndex,
              isOpen,
              reset,
              inputValue,
              inputField,
              getInputProps,
              filteredSuggestions,
              inputFieldPlaceholder:
                inputValue || selectedSuggestions.length > 0 ? '' : placeholder,
              setHighlightedIndex,
              openMenu,
              closeMenu
            }}
          />
        )}
        renderSuggestionItem={(suggestion) => (
          <ResourceSuggestionItem
            resource={suggestion}
            primaryText={suggestion.email_address}
            secondaryText={suggestion.full_name}
            imageUrl={suggestion.profile_picture}
            imageType={suggestion.image_type}
          />
        )}
      />
    );
  });
