import React from 'react';

import { EditSettingModal } from './EditSettingsModal';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback } from 'react';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useEffect } from 'react';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

interface Props {}

const formId = 'edit_default_shares_password';

export const EditSharePasswordModal: React.FC<Props> = () => {
  const workspace = useCurrentWorkspace()[1];
  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    actionDecorators: [addFormId(formId, { delegate: true })],
    cacheInvalidator: null
  });

  const { previousModal } = useModalActions();
  const preferencesId = workspace?.id;
  const handleSave = useCallback(
    (data) => {
      mutate({
        type: 'workspace',
        id: preferencesId,
        ...data
      });
    },
    [mutate, preferencesId]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  return (
    <EditSettingModal
      onSave={handleSave}
      loading={!workspace || mutateStatus === statuses.IN_FLIGHT}
      form={formId}
      initialValues={{
        default_shares_password_enabled:
          workspace.default_shares_password_enabled
      }}
      config={{
        fields: [
          {
            type: 'boolean',
            name: 'default_shares_password_enabled',
            value_type: 'boolean',
            // validate: validator,
            label: 'Turn on Password'
          }
        ]
      }}
      title={'Set password default'}
    />
  );
};
