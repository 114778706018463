import { connect } from 'react-redux';
import { compose } from '../../../utils/component-helpers';
import withCurrentUserAccount, {
  WithCurrentUserAccountProps
} from './withCurrentUserAccount';
import { bindActionCreators } from 'redux';
import { actionCreators as nodeActionCreators } from '../../ducks/resource-nodes';
import { addFormId } from '../../middleware/redux-form-middleware';
import { withResource } from '../withResource';
import { functionize } from '../configHelpers';
import { Account } from '@cube3/common/model/schema/resources/account';
import { actionCreators as sessionActionCreators } from '../../ducks/session';

/// @TODO deactivate account endpoint is not working yet

/**
 * @summary: Convienience wrapper for account actions, which injects some methods for wrapping component
 * @author Simon & Machiel
 */

// #region redux
interface DispatcherProps {
  changeFullName: (target: string, password: string, formId?: string) => void;
  changePassword: (oldPass: string, newPass: string, formId: string) => void;
  requestEmailChange: (
    newEmail: string,
    password: string,
    formId?: string
  ) => void;
  confirmEmailChange: (token: string, formId: string) => void;
  deactivateAccount: (password: string, formId?: string) => void;
}

type Properties = DispatcherProps &
  WithCurrentUserAccountProps &
  DispatcherProps &
  ResourceInjected;

interface ResourceInjected {
  account?: Account;
}

// import this interface if you want to make your wrapped component typed.
export type AccountActionsProps = DispatcherProps;

const mapDispatchToProps = (dispatch, ownProps: Properties) => {
  const mapperObj: DispatcherProps = {
    changeFullName: (targetName: string, password, formId) =>
      addFormId(formId, { formState: undefined, useRequestStatus: true })(
        nodeActionCreators.mutateResource(
          'user',
          ownProps.currentUser.id,
          {
            full_name: targetName,
            password: password
          },
          []
        )
      ),
    changePassword: (oldPass, newPass, formId) =>
      addFormId(formId, { formState: undefined, useRequestStatus: true })(
        nodeActionCreators.mutateResource(
          'user',
          ownProps.currentUser.id,
          {
            old_password: oldPass,
            password: newPass
          },
          []
        )
      ),
    requestEmailChange: (newEmail, password, formId) =>
      addFormId(formId, { formState: undefined, useRequestStatus: true })(
        sessionActionCreators.changeEmail({
          email: newEmail,
          password: password,
          userId: ownProps.currentUser.id
        })
      ),
    confirmEmailChange: (token, formId) =>
      addFormId(formId, { formState: undefined, useRequestStatus: true })(
        sessionActionCreators.confirmEmail({
          token
        })
      ),
    deactivateAccount: (password, formId) =>
      addFormId(formId, { formState: undefined, useRequestStatus: true })(
        nodeActionCreators.deleteResource(
          'account',
          ownProps.currentAccount.id,
          {
            password: password
          }
        )
      )
  };

  return bindActionCreators({ ...mapperObj }, dispatch);
};
// #endregion

export default (config) => {
  const accountIdGetter = config.accountId
    ? functionize(config.accountId)
    : undefined;

  const userIdGetter = config.userId ? functionize(config.userId) : undefined;

  return (WrappedComponent) => {
    return compose(WrappedComponent)(
      withCurrentUserAccount,
      withResource({
        resourceType: 'account',
        resourceId: (props: Properties) =>
          accountIdGetter
            ? accountIdGetter(props)
            : props.currentAccount && props.currentAccount.id,
        mapper: 'account'
      }),
      withResource({
        resourceType: 'user',
        resourceId: (props: Properties) =>
          userIdGetter
            ? userIdGetter(props)
            : props.currentUser && props.currentUser.id,
        mapper: 'user'
      }),
      connect(null, mapDispatchToProps)
    );
  };
};
