import React from 'react';

import { makeValidator } from '../../../../../../forms/helpers/validators';
import { EditSettingModal } from './EditSettingsModal';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback } from 'react';
import { useModalActions } from '../../../../Modals/modalActions';
import { useEffect } from 'react';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { getFormValues } from 'redux-form';
import { VerifyType } from '@cube3/state/types';

type Props = {};

const formId = 'edit_default_shares_require_verification';

export const EditShareRequireVerificationModal: React.FC<Props> = () => {
  const workspace = useCurrentWorkspace()[1];
  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    actionDecorators: [addFormId(formId, { delegate: true })],
    cacheInvalidator: null
  });

  const { previousModal } = useModalActions();

  const handleSave = useCallback(
    (data) => {
      const update = {
        type: 'workspace',
        id: workspace?.id,
        // default_shares_verify_user:
        //   data.default_shares_require_verification_enabled ||
        //   data.default_shares_require_verification_type !== 'never',
        default_shares_verify_type:
          data.default_shares_require_verification_enabled
            ? data.default_shares_require_verification_type
            : VerifyType.never
      };

      // delete data.default_shares_verify_user;

      mutate(update);
    },
    [mutate, workspace]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  const values = useTypedSelector(
    (state) =>
      getFormValues(formId)(state) as {
        default_shares_require_verification_enabled: boolean;
      }
  );

  return (
    <EditSettingModal
      onSave={handleSave}
      loading={!workspace || mutateStatus === statuses.IN_FLIGHT}
      form={formId}
      initialValues={{
        default_shares_require_verification_enabled:
          workspace.default_shares_verify_user ||
          workspace.default_shares_verify_type !== VerifyType.never
            ? true
            : false,
        default_shares_require_verification_type:
          workspace.default_shares_verify_type !== VerifyType.never
            ? workspace.default_shares_verify_type
            : VerifyType.everyone
      }}
      config={{
        fields: [
          {
            type: 'boolean',
            name: 'default_shares_require_verification_enabled',
            value_type: 'boolean',
            label: 'Require email verification'
          },
          {
            type: 'select',
            name: 'default_shares_require_verification_type',
            value_type: 'boolean',
            label: 'Who can access?',
            parse: (val) => val.id,
            format: (val) => options.find((o) => o.id === val),
            extraProps: {
              options,
              disabled:
                !values?.default_shares_require_verification_enabled ||
                !Object.hasOwn(workspace, 'default_shares_verify_type')
            }
          }
        ]
      }}
      title={'Require email verification default'}
    />
  );
};

const options = [
  { display_name: 'Anyone with link', id: 'everyone' },
  { display_name: 'Recipients only', id: 'recipients_only' }
];
