import { State, initialState } from './state';
import { actions } from './actions';
import {
  serializeLabel,
  upsertResource,
  upsertRelation,
  upsertPage,
  getPage,
  dedupe,
  updatePaginationCounts,
  addCursor
} from './utils';
import { createSlice } from '../../utils/createSlice';
import { ns } from './config';

const { slice } = createSlice<State, typeof ns>(() => {
  return {
    handlers: {
      /**
       *
       */
      [actions.RECEIVE_RESOURCE_EDGES]: (
        draft,
        {
          payload,
          meta: {
            apiClient,
            options: { merge }
          }
        }
      ) => {
        const { resourceType: type, resourceId: id } = payload;
        const label = serializeLabel(payload.edgeLabel, payload.params);
        const resource = upsertResource(draft, { id, type });
        const relation = upsertRelation(resource, label);

        let edges = payload.edges;
        if (merge) {
          const { edges: oldEdges = [] } =
            getPage(relation, payload.params?.page?.number) || {};
          edges = dedupe([...oldEdges, ...edges]);
        }
        upsertPage(relation, {
          pageNumber: payload.params?.page?.number,
          edges: edges
        });
      },
      /**
       *
       */
      [actions.RECEIVE_RELATIONS]: (
        draft,
        {
          payload,
          meta: {
            apiClient,
            options: { merge }
          }
        }
      ) => {
        payload.forEach((rel) => {
          const {
            edgeLabel,
            pageNumber,
            edgeType,
            edgeId,
            resourceType,
            resourceId
          } = rel;
          const resource = upsertResource(draft, {
            type: resourceType,
            id: resourceId
          });

          const relation = upsertRelation(resource, edgeLabel);
          const { edges = [] } = getPage(relation, pageNumber) || {};

          const cleanedEdges = edges.filter(
            (e, idx) =>
              !(e.__stale__ || (e.id === edgeId && e.type === edgeType))
          );

          const newEdges = (
            edges.length === cleanedEdges.length ? edges : cleanedEdges
          ).concat({ type: edgeType, id: edgeId });

          upsertPage(relation, {
            pageNumber,
            edges: newEdges
          });
        });
      },
      /**
       *
       */
      [actions.RECEIVE_PAGINATION_SIZE]: (draft, { payload }) => {
        const { resourceType: type, resourceId: id, totalItems } = payload;
        const label = serializeLabel(payload.edgeLabel, payload.params);
        const pageSize = payload.params?.page?.size;
        const resource = upsertResource(draft, { id, type });
        const relation = upsertRelation(resource, label);
        updatePaginationCounts(
          relation,
          totalItems,
          pageSize ? Math.ceil(totalItems / pageSize) : undefined
        );
      },
      /**
       *
       */
      [actions.RECEIVE_NEXT_CURSOR]: (draft, { payload }) => {
        const {
          resourceType: type,
          resourceId: id,

          params: {
            page: { number = 1 }
          },

          cursor
        } = payload;

        const label = serializeLabel(payload.edgeLabel, payload.params);
        const resource = upsertResource(draft, { id, type });
        const relation = upsertRelation(resource, label);
        addCursor(relation, number + 1, cursor);
      },
      /**
       *
       */
      [actions.SET_RELATION_STALE]: (draft, { payload }) => {
        const label = serializeLabel(payload.edgeLabel, payload.params);
        const { resourceType: type, resourceId: id } = payload;

        const resource = upsertResource(draft, { type, id });
        const relation = upsertRelation(resource, label);

        for (const nr in relation.pages) {
          if (relation.pages.hasOwnProperty(nr)) {
            const page = relation.pages[nr];

            page.edges.forEach((e) => {
              e.__stale__ = true;
            });
          }
        }
      }
    },
    initialState,
    namespace: ns
  };
});

export const reducer = slice[ns];
