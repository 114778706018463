/** filter out the forms that unrelated to metadata/contract */
export const selectRelatedForms = (form) => {
  if (!form) return {};
  const ids = Object.keys(form);
  return ids.reduce((acc, id) => {
    const fields = form.id?.registeredFields;
    const isRelatedForm =
      fields &&
      !!Object.keys(fields).find(
        (k) => k.includes('field_') || k.includes('key_')
      );
    if (isRelatedForm) {
      return { ...acc, [id]: form.id };
    }
    return acc;
  }, {});
};
