import * as React from 'react';
//UI
import { ModalMenuUI } from '../Modal/ModalMenuUI';
import Cube3Button from '../Buttons/Cube3Button';

interface WorkspaceMembersLayoutUIProps {
  closeAllModals: () => void;
  openModal: (modal: any, context?: any) => void;
  children: React.ReactNode;
}

function WorkspaceMembersLayoutUI(props: WorkspaceMembersLayoutUIProps) {
  const { closeAllModals /*openModal*/ } = props;
  return (
    <ModalMenuUI
      title={'Workspace members'}
      onCloseEvent={closeAllModals}
      multiplyContentSpace={10}
      footerRightComponent={
        <Cube3Button text="Done" onClick={closeAllModals} />
      }
    >
      {props.children}
    </ModalMenuUI>
  );
}

export default WorkspaceMembersLayoutUI;
