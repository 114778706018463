import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { UseStyles } from '../UseStylesHelper/UseStylesHelper';
import Button from '../Buttons/Cube3Button';
import { Typography } from '../typography/Typography';
import Surface from '../Surface/Surface';
import { Divider } from '../Surface/Divider';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) =>
  createStyles({
    mobile: {},
    root: {
      display: 'flex',
      height: '174px',
      width: '100%',
      '&$mobile': {
        height: '100%',
        top: 0,
        position: 'fixed'
      }
    },
    contents: {
      width: '784px',
      margin: 'auto',
      marginTop: '24px',
      marginBottom: '24px',
      '$mobile &': {
        marginLeft: theme.customSpacing.margin[8],
        marginRight: theme.customSpacing.margin[8]
      }
    },
    alignLeft: {
      marginRight: 'auto'
    },
    alignRight: {
      marginLeft: 'auto',
      marginRight: theme.customSpacing.margin[12]
    },
    buttonMobile: {
      marginRight: 'unset',
      marginLeft: 'unset',
      display: 'flex',
      justifyContent: 'center',
      margin: '8px 0px'
    },
    snackbar: {
      display: 'flex',
      width: '100%',
      '$mobile &': {
        flexDirection: 'column-reverse'
      }
    },
    mainTxt: {
      marginBottom: 16
    }
  })
);

interface Properties extends UseStyles<typeof useStyles> {
  onClickReject: () => void;
  onClickAccept: () => void;
  onClickSettings: () => void;
  statementLinkComponent: JSX.Element;
}

export const CookieConsentUI: React.FunctionComponent<Properties> = (props) => {
  const classes = useStyles(props);

  const {
    onClickReject,
    onClickAccept,
    onClickSettings,
    statementLinkComponent
  } = props;

  return (
    <>
      <Divider background={'base1'} color={'contrast2'} />
      <Surface
        background={'base1'}
        className={`${classes.root} ${isMobile ? classes.mobile : ' '}`}
      >
        <div className={`${classes.contents} `}>
          {/* Show header */}
          <Typography typographyStyle={'heading2'}>We use cookies</Typography>

          <br></br>

          {/* State privacy policy */}
          <Typography
            className={classes.mainTxt}
            typographyStyle={'body2'}
            typographyProps={{ gutterBottom: true }}
          >
            Cube would like to use cookies to enhance your experience and learn
            about how you use our site. Let us know if we may use these cookies.
            You can read more on how we use cookies in our{' '}
            {statementLinkComponent}.
          </Typography>

          <div className={classes.snackbar}>
            <Button
              marginCollapse={'left'}
              className={`${classes.alignLeft} ${
                isMobile ? classes.buttonMobile : ' '
              }`}
              colorVariant={'ghost1'}
              text="Cookie settings"
              onClick={() => onClickSettings()}
            />
            <Button
              className={`${classes.alignRight} ${
                isMobile ? classes.buttonMobile : ' '
              }`}
              onClick={() => onClickReject()}
              colorVariant={'ghost1'}
              text="Only Required"
              key="reject"
            />
            <Button
              onClick={() => onClickAccept()}
              colorVariant={'filled1'}
              text="Accept cookies"
              key="ok"
              className={isMobile ? classes.buttonMobile : ''}
            />
          </div>
        </div>
      </Surface>
    </>
  );
};
