import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React from 'react';

interface Props {
  modalContext: {
    mainText: string;
  };
}

const AddedToProjectsConfirmationModal: React.FunctionComponent<Props> = props => {
  const { mainText } = props.modalContext;
  const { closeAllModals } = useModalActions();

  return (
    <ModalNoticeUI
      title="Added to your active projects"
      onCloseEvent={closeAllModals}
      footerRightComponent={
        <Button text="Done" colorVariant="filled1" onClick={closeAllModals} />
      }
    >
      <Typography>{mainText}</Typography>
    </ModalNoticeUI>
  );
};

export default AddedToProjectsConfirmationModal;
