import React, { ReactNode, useState } from 'react';
import { GetMenuPropsOptions, GetPropsCommonOptions } from 'downshift';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { makeStyles, createStyles, Theme, Popover } from '@material-ui/core';
import { useClassName } from '../utils/useClassName';
import { Typography } from '../typography/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    suggestionsListRoot: {
      width: '100%',
      backgroundColor: theme.customPalette.primary.light,
      marginTop: 0,
      marginBottom: 0,
      borderBottomRightRadius: 6,
      borderBottomLeftRadius: 6,
      paddingTop: 12,
      paddingBottom: 8
    },
    suggestionsListRootEmpty: {
      backgroundColor: 'inherit',
      marginTop: 0
    },
    suggestionList: {
      padding: 0
    },
    suggestionListTitle: {
      margin: theme.customSpacing.margin[8]
    },
    scrollContainer: {
      borderBottomRightRadius: 6,
      borderBottomLeftRadius: 6
    },
    popover: {
      pointerEvents: 'none'
    },
    suggestionPaperRoot: {
      pointerEvents: 'none',
      background: 'transparent',
      borderBottomRightRadius: 6,
      borderBottomLeftRadius: 6,
      ...theme.customMixins.surfaceShadows({
        elevation: 2
      }),
      '& > * ': {
        pointerEvents: 'all'
      }
    }
  })
);

interface SuggestionsListProps {
  downshiftMenuProps?: (
    options?: GetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => any;
  title?: string;
  children: ReactNode;
}

export const SuggestionsList: React.FC<
  React.PropsWithChildren<SuggestionsListProps>
> = (props) => {
  const { downshiftMenuProps, title } = props;

  const [anchorRef, setAnchorRef] = useState(null);

  const downShiftMenu = downshiftMenuProps();

  return (
    <div {...downShiftMenu} style={{ height: 0 }}>
      <div ref={setAnchorRef} style={{ height: 0 }}>
        <SuggestionPopover
          anchorEl={anchorRef}
          open={React.Children.count(props.children) !== 0}
        >
          <SuggestionsListInner title={title} width={anchorRef?.clientWidth}>
            {props.children}
          </SuggestionsListInner>
        </SuggestionPopover>
      </div>
    </div>
  );
};

interface SuggestionPopoverProps {
  anchorEl: HTMLDivElement;
  align?: 'left' | 'right' | 'center';
  open?: boolean;
}

export const SuggestionPopover: React.FC<
  React.PropsWithChildren<SuggestionPopoverProps>
> = (props) => {
  const classes = useStyles();

  const { anchorEl, align = 'left', open = true } = props;

  return (
    <Popover
      classes={{ root: classes.popover }}
      disableRestoreFocus={true}
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: align
      }}
      PaperProps={{
        square: true,
        elevation: 0,
        classes: { root: classes.suggestionPaperRoot },
        onMouseDown: (ev) => ev.preventDefault()
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: align
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      {props.children}
    </Popover>
  );
};

interface SuggestionsListInnerProps {
  width?: number;
  title?: string;
}

export const SuggestionsListInner: React.FC<
  React.PropsWithChildren<SuggestionsListInnerProps>
> = (props) => {
  const { width, children, title } = props;

  const classes = useStyles();

  return (
    <div
      style={{
        width: width
      }}
      className={useClassName(
        classes.suggestionsListRoot,
        React.Children.count(props.children) === 0 &&
          classes.suggestionsListRootEmpty
      )}
    >
      {title && (
        <div className={classes.suggestionListTitle}>
          <Typography
            typographyStyle="heading4"
            color="contrast2"
            typographyProps={{ gutterBottom: true }}
          >
            {title}
          </Typography>
        </div>
      )}
      <Scrollbars autoHeight className={classes.scrollContainer}>
        <ul className={classes.suggestionList}>{children}</ul>
      </Scrollbars>
    </div>
  );
};

export default SuggestionsList;
