import React from 'react';

// types and the HOCstuff
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { compose } from '@cube3/state/src/utils/component-helpers';
// UI
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';
import { ModalReceiverProps } from '../../../../../../app/layout/Modals/ModalManager';

interface RevokeInvitationConfirmationProps extends ModalReceiverProps {
  revokeInvitation: Function;
}

function RevokeInvitationConfirmation(
  props: RevokeInvitationConfirmationProps
): JSX.Element {
  const modalActions = useModalActions();
  const { revokeInvitation, modalContext } = props;

  return (
    <GenericNoticeModalUI
      renderTwoButtons={true}
      onClose={() => {
        modalActions.previousModal({ currentTab: 1 });
      }}
      title="Revoke invitation?"
      text="Are you sure you want to revoke this invitation?"
      buttonText="Cancel"
      buttonColorVariant="ghost2"
      secondButtonText="Revoke"
      secondButtonColorVariant="filledDanger"
      secondButtonClickHandler={() => {
        revokeInvitation({ id: modalContext.id, type: 'invitation' });
        modalActions.previousModal({ currentTab: 1 });
      }}
    />
  );
}

export default compose(RevokeInvitationConfirmation)(Wrapped => props => {
  const [revokeInvitation, revokeInvitationStatus] = useDeleteResource__ALPHA({
    actionDecorators: [
      addFormId('delete-invitation', {
        formState: undefined,
        useRequestStatus: true,
        delegate: false,
        data: action => {
          return { ...action.payload.data };
        }
      })
    ],
    cacheInvalidator: (r, wsId) => [
      {
        type: 'workspace',
        id: wsId,
        relationship: 'invitations'
      }
    ]
  });
  return (
    <Wrapped
      {...props}
      revokeInvitation={revokeInvitation}
      revokeInvitationStatus={revokeInvitationStatus}
    />
  );
});
