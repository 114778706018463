import { ResourceBase, SVGImage } from '../../resource-types';
import { RawModel } from '../schema';

export interface Annotation extends ResourceBase {
  id: string;
  library_item_id: number;
  source_version_id: number;
  origin_type: string;
  origin: string;
  start_at: string;
  end_at: string;
  user_id: number;
  user_name: string;
  user_colorcode: string;
  state: object;

  svgimage: SVGImage;
  //   comments: [Comment];
}

export const annotation: RawModel = {
  type: 'annotation',
  plural: 'annotations',

  attributes: {
    library_item_id: 'number',
    source_version_id: 'number',
    origin_type: 'string',
    origin: 'string',
    start_at: 'string',
    end_at: 'string',
    user_id: 'number',
    user_name: 'string',
    user_colorcode: 'string',
    state: 'object',

    svgimage: 'SVGImage'
  },
  relationships: {}
};
