import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const MIN_SIZE_RATIO = 0.9;

export type PanZoomWheelOptions = boolean | 'x' | 'y';

export const usePanZoom = (
  zoom: number,
  setFitZoom: (zoom: number) => void,
  useWheel: PanZoomWheelOptions = false
) => {
  const [dragging, setDragging] = useState(false);
  const [zooming, setZooming] = useState(false);

  const [initial, setInitial] = useState<boolean | number>(null);
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement>(null);

  const [zoomRef, setZoomRef] = useState<HTMLDivElement>(null);
  const handleZoomRef = useCallback(
    (node) => {
      if (node) {
        node.style.transform = `scale(1) translate3d(-50%,-50%,0)`;
      }
      setZoomRef(node);
    },
    [setZoomRef]
  );

  const [panRef, setPanRef] = useState<HTMLDivElement>(null);
  const handlePanRef = useCallback(
    (node) => {
      if (node) {
        node.style.transform = `translate3d(0,0,0)`;
      }
      setPanRef(node);
    },
    [setPanRef]
  );

  useEffect(() => {
    if (initial === false) {
      zoomRef.style.visibility = 'visible';
      zoomRef.style.opacity = '1';
    }
    if (!zoomRef || initial !== null) {
      return;
    }
    zoomRef.style.visibility = 'hidden';
    zoomRef.style.opacity = '0';

    let raf;
    const loop = () => {
      if (
        zoomRef.getBoundingClientRect().width &&
        zoomRef.getBoundingClientRect().height
      ) {
        setInitial(true);
      } else {
        raf = requestAnimationFrame(loop);
      }
    };

    raf = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(raf);
  }, [zoomRef, setInitial, initial]);

  const autoFitZoom = useMemo(() => {
    if (initial !== null && wrapperRef && zoomRef) {
      const { clientWidth: wrapperWidth, clientHeight: wrapperHeight } =
        wrapperRef;
      const { offsetWidth: contentWidth, offsetHeight: contentHeight } =
        zoomRef;

      const wrapperDim = {
        w: wrapperWidth,
        h: wrapperHeight,
        r: wrapperWidth / wrapperHeight
      };
      const contentDim = {
        w: contentWidth,
        h: contentHeight,
        r: contentWidth / contentHeight
      };

      if (!contentDim.w || !contentDim.h) {
        return;
      }

      if (contentDim.r >= wrapperDim.r) {
        return wrapperDim.w / (contentDim.w + 2); // not sure why the +2 is needed but it doesn't fit correctly otherwise
      } else {
        return wrapperDim.h / (contentDim.h + 2); // not sure why the +2 is needed but it doesn't fit correctly otherwise
      }
    }
  }, [wrapperRef, zoomRef, initial]);

  const scale = useRef<{ zoom: number }>(null);

  const translation = useRef({ x: 0, y: 0 });

  /* Previous x,y position of mouse (starting with start pos).*/
  const startCoordsRef = useRef({ x: 0, y: 0 });

  const handleTranslate = useCallback(
    (offset, limit = true) => {
      if (panRef) {
        const zoomRect = zoomRef.getBoundingClientRect();
        const wrapperRect = wrapperRef.getBoundingClientRect();

        // fix to prevent weird interacting with multipage scrolling (pdf preview)
        if (limit && useWheel === 'x') {
          offset.y = 0;
        }

        const overlap = {
          top: wrapperRect.top - zoomRect.top,
          bottom: wrapperRect.bottom - zoomRect.bottom,
          left: wrapperRect.left - zoomRect.left,
          right: wrapperRect.right - zoomRect.right
        };

        const limits = {
          top: Math.min(Math.min(0, overlap.top), Math.min(0, overlap.bottom)),
          bottom: Math.max(
            Math.max(0, overlap.bottom),
            Math.max(0, overlap.top)
          ),
          left: Math.min(Math.min(0, overlap.left), Math.min(0, overlap.right)),
          right: Math.max(Math.max(0, overlap.right), Math.max(0, overlap.left))
        };

        if (zoomRect.width < wrapperRect.width) {
          limits.left = 0;
          limits.right = 0;
        }

        if (zoomRect.height < wrapperRect.height) {
          limits.top = 0;
          limits.bottom = 0;
        }

        panRef.style.width = zoomRect.width + 'px';
        panRef.style.height = zoomRect.height + 'px';

        const moveX =
          offset.x < limits.left
            ? limits.left
            : offset.x > limits.right
            ? limits.right
            : offset.x;
        const moveY =
          offset.y < limits.top
            ? limits.top
            : offset.y > limits.bottom
            ? limits.bottom
            : offset.y;

        let newX;
        let newY;
        if (limit) {
          newX = translation.current.x + moveX;
          newY = translation.current.y + moveY;
        } else {
          newX = translation.current.x + offset.x;
          newY = translation.current.y + offset.y;
        }

        panRef.style.transform = `translate3d( ${newX}px, ${newY}px, 0px )`;

        translation.current.x = newX;
        translation.current.y = newY;
      }
    },
    [panRef, zoomRef, wrapperRef, useWheel]
  );

  const handleZoom = useCallback(
    (zoom) => {
      if (zoomRef && panRef) {
        const oldZoom = scale.current?.zoom || 1;

        scale.current = scale.current || {
          zoom: null
        };
        scale.current.zoom = zoom;

        if (oldZoom === 1 && zoom === 1) {
          return;
        }

        const zoomDir = oldZoom < zoom ? 'in' : 'out';

        const zoomRect = zoomRef.getBoundingClientRect();
        const panRect = panRef.getBoundingClientRect();
        const wrapperRect = wrapperRef.getBoundingClientRect();

        if (initial) {
          zoomRef.style.transform = `translate3d(-50%, -50%, 0) scale(${zoom}) `;
          setInitial(false);
          handleTranslate({ x: 0, y: 0 });
        } else {
          setZooming(true);

          let originX, originY;

          if (zoomDir === 'in') {
            const centerX = wrapperRect.width * 0.5;
            const contentX = wrapperRect.x - zoomRect.x + centerX;
            originX = contentX / zoomRect.width;

            const centerY = wrapperRect.height * 0.5;
            const contentY = wrapperRect.y - zoomRect.y + centerY;
            originY = contentY / zoomRect.height;

            // console.log('DEBUG zoom in ', {
            //   centerX,
            //   contentX,
            //   originX,
            //   centerY,
            //   contentY,
            //   originY,
            //   zoomRect,
            //   wrapperRect
            // });
          } else if (zoomDir === 'out') {
            const rangeX = zoomRect.width - wrapperRect.width;
            const offsetX = wrapperRect.x - panRect.x;
            originX =
              rangeX > 0 ? Math.max(0, Math.min(1, offsetX / rangeX)) : 0.5;

            if (zoom * zoomRef.clientWidth <= wrapperRect.width) {
              // console.info('DEBUG zoom x smaller');

              const wrapperCenterX = wrapperRect.x + wrapperRect.width * 0.5;
              const panX = panRect.x;

              const w1 = zoomRect.width;
              const w2 = zoom * zoomRef.clientWidth;

              const x1 = panX;
              const x2 = wrapperCenterX - w2 * 0.5;

              // https://stackoverflow.com/questions/13837655/calculate-transform-origin-from-original-and-scaled-boxes
              const tx = (w2 / (w2 - w1)) * (x1 - (x2 * w1) / w2);

              originX = (tx - panX) / panRect.width;
            }

            const rangeY = zoomRect.height - wrapperRect.height;
            const offsetY = wrapperRect.y - panRect.y;
            originY =
              rangeY > 0 ? Math.max(0, Math.min(1, offsetY / rangeY)) : 0.5;

            if (zoom * zoomRef.clientHeight <= wrapperRect.height) {
              // console.info('DEBUG zoom y smaller');

              const wrapperCenterY = wrapperRect.y + wrapperRect.height * 0.5;
              const panY = panRect.y;

              const h1 = zoomRect.height;
              const h2 = zoom * zoomRef.clientHeight;

              const y1 = panY;
              const y2 = wrapperCenterY - h2 * 0.5;

              // https://stackoverflow.com/questions/13837655/calculate-transform-origin-from-original-and-scaled-boxes
              const ty = (h2 / (h2 - h1)) * (y1 - (y2 * h1) / h2);

              originY = (ty - panY) / panRect.height;
            }
          }

          // console.info('DEBUG zoom', {
          //   zoomDir,
          //   zoom,
          //   zoomRect,
          //   wrapperRect,
          //   panRect,
          //   oldZoom,
          //   originX,
          //   originY
          // });

          const compX = -0.5 + (originX * 2 - 1) * 0.5 * (oldZoom - 1);
          const compY = -0.5 + (originY * 2 - 1) * 0.5 * (oldZoom - 1);

          zoomRef.style.transition = `none`;

          zoomRef.style.transformOrigin = `${originX * 100}% ${originY * 100}%`;

          zoomRef.style.transform = `translate3d( ${compX * 100}%, ${
            compY * 100
          }%, 0px) scale(${oldZoom}) `;

          zoomRef.getBoundingClientRect();

          const handleZoomEnd = (ev) => {
            zoomRef.removeEventListener('transitionend', handleZoomEnd);
            const newZoomRect = zoomRef.getBoundingClientRect();
            panRef.style.width = newZoomRect.width + 'px';
            panRef.style.height = newZoomRect.height + 'px';
            const newPanRect = panRef.getBoundingClientRect();

            handleTranslate(
              {
                x: newZoomRect.x - newPanRect.x,
                y: newZoomRect.y - newPanRect.y
              },
              false
            );
            zoomRef.style.transition = `none`;

            zoomRef.style.transformOrigin = `${0.5 * 100}% ${0.5 * 100}%`;
            zoomRef.style.transform = `translate3d( ${-0.5 * 100}%, ${
              -0.5 * 100
            }%, 0px) scale(${zoom}) `;
            setZooming(false);
          };

          zoomRef.addEventListener('transitionend', handleZoomEnd);

          zoomRef.style.transition = initial
            ? `transform 0.01s ease`
            : `transform 0.5s ease`;
          zoomRef.style.transform = `translate3d( ${compX * 100}%, ${
            compY * 100
          }%, 0px) scale(${zoom}) `;
        }
      }
    },
    [
      zoomRef,
      panRef,
      wrapperRef,
      initial,
      setInitial,
      setZooming,
      handleTranslate
    ]
  );

  const canDrag = useMemo(() => {
    if (!wrapperRef || !zoomRef || zooming) return false;
    const { width: wrapperWidth, height: wrapperHeight } =
      wrapperRef.getBoundingClientRect();
    const { width: contentWidth, height: contentHeight } =
      zoomRef.getBoundingClientRect();

    if (contentHeight > wrapperHeight || contentWidth > wrapperWidth) {
      return true;
    }

    return false;
  }, [zoomRef, wrapperRef, zooming]);

  const setPrevMousePosition = useCallback((pos) => {
    startCoordsRef.current = pos;
  }, []);

  const onDragStart = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!event) return;
      event.preventDefault();
      setDragging(true);
      setPrevMousePosition({ x: event.clientX, y: event.clientY });
    },
    [setPrevMousePosition]
  );

  const onDragging = useCallback(
    (event) => {
      if (!event || !canDrag) return;

      if (event.type === 'wheel' && useWheel) {
        const { x, y } = startCoordsRef.current;

        const dx = useWheel === true || useWheel === 'x' ? -event.deltaX : 0;
        const dy = useWheel === true || useWheel === 'y' ? -event.deltaY : 0;

        handleTranslate({ x: dx, y: dy });
        setPrevMousePosition({ x: x + dx, y: y + dy });
      } else {
        const { x, y } = startCoordsRef.current;
        handleTranslate({ x: event.clientX - x, y: event.clientY - y });
        setPrevMousePosition({ x: event.clientX, y: event.clientY });
      }
    },
    [canDrag, setPrevMousePosition, handleTranslate, useWheel]
  );

  useEffect(() => {
    if (initial) {
      if (autoFitZoom) {
        // smaller than viewPort?
        const isSmaller = autoFitZoom > 1.0 / MIN_SIZE_RATIO;
        if (isSmaller) {
          scale.current.zoom = 0.1; // hack to make the calculation trigger
          setFitZoom(1);
        } else {
          setFitZoom(autoFitZoom);
        }
      }
    }
  }, [autoFitZoom, initial, setFitZoom]);

  // should update x, y when zoom in/out after panning
  useEffect(() => {
    if (zoom !== scale.current?.zoom) {
      handleZoom(zoom);
    }
  }, [handleZoom, zoom]);

  // handle drag end
  useEffect(() => {
    const stopDrag = () => setDragging(false);
    if (dragging) {
      window.addEventListener('mousemove', onDragging);

      window.addEventListener('mouseup', stopDrag);
      window.addEventListener('blur', stopDrag, true);

      return () => {
        window.removeEventListener('mouseup', stopDrag);
        window.removeEventListener('mousemove', onDragging);
        window.removeEventListener('blur', stopDrag, true);
      };
    }
  }, [dragging, onDragging]);

  useEffect(() => {
    if (!wrapperRef || !useWheel) {
      return;
    }
    wrapperRef.addEventListener('wheel', onDragging, true);
    return () => {
      wrapperRef.addEventListener('wheel', onDragging, true);
    };
  }, [wrapperRef, onDragging, useWheel]);

  return {
    onDragStart,
    canDrag,
    isZooming: zooming,
    panRef: handlePanRef,
    zoomRef: handleZoomRef,
    wrapperRef: setWrapperRef,
    zoom
  };
};
