import React from 'react';

export const ZoomIn = props => {
  const { className } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19 13H15V9H13V13H9V15H13V19H15V15H19V13Z"
        className={className}
      />
      <path
        d="M22.45 21C24.098 19.0399 25.0011 16.5609 25 14C25 11.8244 24.3549 9.69767 23.1462 7.88873C21.9375 6.07979 20.2195 4.66989 18.2095 3.83733C16.1995 3.00477 13.9878 2.78693 11.854 3.21137C9.72022 3.6358 7.76021 4.68345 6.22183 6.22183C4.68345 7.76021 3.6358 9.72022 3.21137 11.854C2.78693 13.9878 3.00477 16.1995 3.83733 18.2095C4.66989 20.2195 6.07979 21.9375 7.88873 23.1462C9.69767 24.3549 11.8244 25 14 25C16.5609 25.0011 19.0399 24.098 21 22.45L28.59 30L30 28.59L22.45 21ZM14 23C12.22 23 10.4799 22.4722 8.99987 21.4832C7.51983 20.4943 6.36628 19.0887 5.68509 17.4442C5.0039 15.7996 4.82567 13.99 5.17294 12.2442C5.5202 10.4984 6.37737 8.89472 7.63604 7.63604C8.89472 6.37737 10.4984 5.5202 12.2442 5.17294C13.99 4.82567 15.7996 5.0039 17.4442 5.68509C19.0887 6.36628 20.4943 7.51983 21.4832 8.99987C22.4722 10.4799 23 12.22 23 14C23 16.387 22.0518 18.6761 20.364 20.364C18.6761 22.0518 16.387 23 14 23Z"
        className={className}
      />
    </svg>
  );
};

export const ZoomOut = props => {
  const { className } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19 13H9V15H19V13Z" className={className} />
      <path
        d="M22.45 21C24.098 19.0399 25.0011 16.5609 25 14C25 11.8244 24.3549 9.69767 23.1462 7.88873C21.9375 6.07979 20.2195 4.66989 18.2095 3.83733C16.1995 3.00477 13.9878 2.78693 11.854 3.21137C9.72022 3.6358 7.76021 4.68345 6.22183 6.22183C4.68345 7.76021 3.6358 9.72022 3.21137 11.854C2.78693 13.9878 3.00477 16.1995 3.83733 18.2095C4.66989 20.2195 6.07979 21.9375 7.88873 23.1462C9.69767 24.3549 11.8244 25 14 25C16.5609 25.0011 19.0399 24.098 21 22.45L28.59 30L30 28.59L22.45 21ZM14 23C12.22 23 10.4799 22.4722 8.99987 21.4832C7.51983 20.4943 6.36628 19.0887 5.68509 17.4442C5.0039 15.7996 4.82567 13.99 5.17294 12.2442C5.5202 10.4984 6.37737 8.89472 7.63604 7.63604C8.89472 6.37737 10.4984 5.5202 12.2442 5.17294C13.99 4.82567 15.7996 5.0039 17.4442 5.68509C19.0887 6.36628 20.4943 7.51983 21.4832 8.99987C22.4722 10.4799 23 12.22 23 14C23 16.387 22.0518 18.6761 20.364 20.364C18.6761 22.0518 16.387 23 14 23Z"
        className={className}
      />
    </svg>
  );
};

export const ZoomReset = props => {
  const { className } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.448 21C24.098 19.0409 25.002 16.5614 25.0002 14C25.0021 11.7963 24.3415 9.64296 23.1041 7.81948C21.8668 5.996 20.1098 4.58661 18.0613 3.77431C16.0128 2.962 13.7674 2.78429 11.6166 3.26425C9.46583 3.74421 7.50902 4.85966 6.00021 6.4658V2H4.00021V10H12.0002V8H7.33221C8.68118 6.50915 10.4916 5.51432 12.4733 5.17494C14.455 4.83555 16.4933 5.17127 18.2615 6.12829C20.0297 7.08531 21.4254 8.60821 22.225 10.4529C23.0246 12.2977 23.1817 14.3574 22.6712 16.3021C22.1607 18.2468 21.0122 19.9638 19.4097 21.178C17.8071 22.3922 15.8434 23.0332 13.8331 22.9984C11.8228 22.9636 9.88245 22.2549 8.32292 20.9859C6.7634 19.717 5.67502 17.9612 5.23221 16H3.19141C3.66065 18.5252 4.99787 20.807 6.97168 22.4505C8.9455 24.094 11.4317 24.9959 14.0002 25C16.5616 25.0018 19.0411 24.0978 21.0002 22.4478L28.5861 30L30.0002 28.5859L22.448 21Z"
        className={className}
      />
    </svg>
  );
};
