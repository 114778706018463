import React, { useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { PanZoomContext } from './PanZoomContext';

interface PanZoomProps {
  offset?: {
    top: number | string;
    right: number | string;
    bottom: number | string;
    left: number | string;
  };
  children?: any;
}

const debug = false;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      display: 'flex'
    },
    wrapper: (
      offset: PanZoomProps['offset'] = { top: 0, right: 0, bottom: 0, left: 0 }
    ) => ({
      width: '100%',
      height: '100%',
      position: 'absolute',
      alignItems: 'center',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      bottom: offset.bottom,
      top: offset.top,
      left: offset.left,
      right: offset.right
    })
  })
);

export const PanZoom = (props: PanZoomProps) => {
  const classes = useStyles(props.offset);
  const { wrapperRef, panRef, zoomRef, canDrag, onDragStart } = useContext(
    PanZoomContext
  );

  return (
    <div className={classes.container}>
      <div className={classes.wrapper} ref={wrapperRef}>
        <div
          style={{
            cursor: canDrag ? 'move' : 'unset',
            position: 'absolute',
            touchAction: 'manipulation',
            background: debug ? 'green' : undefined
          }}
          onMouseDown={onDragStart}
          ref={panRef}
        >
          <div
            style={{
              position: 'absolute',
              opacity: debug ? 0.2 : 1,
              top: '50%',
              left: '50%'
            }}
            ref={zoomRef}
          >
            {props.children}
            {debug && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  background: 'red'
                }}
              />
            )}
          </div>
          {debug && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: 2,
                height: 2,
                background: 'red'
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PanZoom;
