import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '../typography/Typography';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import {
  SectionScrollingProvider,
  useSectionScrollingArea,
  useSectionScrollingNav
} from '../helpers/hooks/useSectionScrolling';
import MenuItem from '../Buttons/MenuItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'stretch',
      height: 'calc( 100% - 64px)',
      flexGrow: 1,
      marginRight: 8
    },

    formContent: {
      flexGrow: 1,
      paddingTop: 24,
      paddingBottom: 8
    },
    categoryContent: {
      width: 350,
      paddingBottom: 20,
      paddingTop: 44,
      paddingLeft: 24
    },
    categoryTitle: {
      fontWeight: 'bold',
      marginBottom: 16
    },
    activeCategoryTitle: {
      color: theme.customPalette.secondary.main
    },
    categories: {
      cursor: 'pointer'
    },
    headerClassName: {
      borderBottom: `1px solid ${theme.customPalette.primary.lineWhiteLowEmphasis}`,
      paddingBottom: 24
    },
    headerClassNameNoMetadata: {
      paddingBottom: 24
    },
    footerClassName: {
      minHeight: 90,
      marginBottom: 4
    },
    extraMargin: {
      marginBottom: '70vh'
    },
    scrollwrapper: {
      width: '100%',
      height: '100%',
      position: 'relative',
      flexGrow: 1
    },
    categoryForm: {
      paddingTop: 8
    }
  })
);

export interface Category {
  id: string;
  display_name: string;
}

export type CategoryRenderer = (
  cateegory: Category,
  active?: boolean
) => React.ReactNode;

interface Props {
  navMenu?: JSX.Element;
  categories: Category[];
  renderCategory: CategoryRenderer;
}

export const CategoryScrollingLayout: React.FunctionComponent<Props> = props => {
  const classes = useStyles(props);
  const { categories, renderCategory, navMenu } = props;

  return (
    <SectionScrollingProvider>
      <div className={classes.root}>
        <Nav categories={categories} />
        <div className={classes.scrollwrapper}>
          <Scrollbar>
            {navMenu}
            <Categories
              categories={categories}
              renderCategory={renderCategory}
            />
          </Scrollbar>
        </div>
      </div>
    </SectionScrollingProvider>
  );
};

const Nav = props => {
  const { categories } = props;
  const classes = useStyles(props);

  const {
    handleClick,
    activeSection,
    targetSection
  } = useSectionScrollingNav();

  return (
    <div className={classes.categoryContent}>
      <Typography
        className={classes.categoryTitle}
        typographyStyle={'body2'}
        color="contrast3"
      >
        CATEGORIES
      </Typography>
      {categories.map(form => (
        <MenuItem
          key={form.id}
          text={form.display_name}
          className={classes.categories}
          active={
            targetSection
              ? targetSection === form.id
              : activeSection === form.id
          }
          colorVariant="ghost1"
          onClick={() => handleClick(form.id)}
          marginCollapse="left"
        />
      ))}
    </div>
  );
};

const Categories = props => {
  const { categories, renderCategory } = props;

  const classes = useStyles(props);

  const { activeSection, refs } = useSectionScrollingArea({
    sections: categories
  });

  return (
    <div 
    className={classes.formContent}
    data-cy={`category-form`}
    >
      {categories.map((category, index) => {
        return (
          <div
            className={classes.categoryForm}
            ref={refs[category.id]}
            key={category.id}
            data-cy={`category-content-${index}`}
          >
            {renderCategory(category, category.id === activeSection)}
          </div>
        );
      })}
    </div>
  );
};
