import { createTheme, PaletteType } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { getSurfaceStateColor } from './getSurfaceStateColor';

import {
  Breakpoints,
  BreakpointsOptions
} from '@material-ui/core/styles//createBreakpoints';
import { Mixins, MixinsOptions } from '@material-ui/core/styles//createMixins';
import {
  Palette,
  PaletteOptions
} from '@material-ui/core/styles//createPalette';
import {
  Typography,
  TypographyOptions
} from '@material-ui/core/styles/createTypography';
import { Shadows } from '@material-ui/core/styles//shadows';
import { Shape, ShapeOptions } from '@material-ui/core/styles//shape';
// import { Spacing, SpacingOptions } from '@material-ui/core/spacing';
import {
  Transitions,
  TransitionsOptions
} from '@material-ui/core/styles//transitions';
import { ZIndex, ZIndexOptions } from '@material-ui/core/styles//zIndex';
import { Overrides } from '@material-ui/core/styles//overrides';
import { ComponentsProps } from '@material-ui/core/styles//props';

import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { MuiThemeProvider } from '@material-ui/core/styles/';

export type Direction = 'ltr' | 'rtl';

interface DefaultThemeOptions {
  shape?: ShapeOptions;
  breakpoints?: BreakpointsOptions;
  direction?: Direction;
  mixins?: MixinsOptions;
  overrides?: Overrides;
  palette?: PaletteOptions;
  props?: ComponentsProps;
  shadows?: Shadows;
  // spacing?: SpacingOptions;
  transitions?: TransitionsOptions;
  typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
  zIndex?: ZIndexOptions;
}

interface DefaultTheme {
  shape: Shape;
  breakpoints: Breakpoints;
  direction: Direction;
  mixins: Mixins;
  overrides?: Overrides;
  palette: Palette;
  props?: ComponentsProps;
  shadows: Shadows;
  // spacing: Spacing;
  transitions: Transitions;
  typography: Typography;
  zIndex: ZIndex;
}

/* 
NOTE: 
When you would like to add a new object to this file please declare the
structure first in the mudule-declaration.tsx
*/

const defaultOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    type: 'dark' as PaletteType,
    primary: {
      light: '#FAEBAD',
      main: '#F9D649',
      dark: '#E9C639'
    },
    secondary: {
      light: '#FAEBAD',
      main: '#F9D649',
      dark: '#E9C639'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: 15
  },
  overrides: {
    MuiTypography: {
      root: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale'
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#303030'
      }
    }
  }
};

export const customTheme = {
  customPalette: {
    surface: {
      transparent: 'transparent',
      base1: '#181818',
      base2: '#2C2C2C',
      brand1: '#F9D649',
      danger1: '#F9496C',
      white1: '#FFFFFF',
      black1: '#000000',
      shade1: 'rgba(0, 0, 0, 0.06)',
      shade2: 'rgba(0, 0, 0, 0.16)',
      shade3: 'rgba(255, 255, 255, 0.02)',
      shade4: 'rgba(255, 255, 255, 0.02)'
    },
    surfaceAccent: {
      onBase: {
        shade1: 'rgba(0, 0, 0, 0.06)',
        shade2: 'rgba(0, 0, 0, 0.16)',
        shade3: 'rgba(255, 255, 255, 0.02)',
        shade4: 'rgba(255, 255, 255, 0.24)',
        lamp1:
          'radial-gradient(50% 100% at 50% 0%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%)',
        scrim1: 'rgba(0, 0, 0, 0.8)',
        scrim2:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)',
        scrim3:
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)',
        hover1: 'rgba(255, 255, 255, 0.04)',
        selected1: 'rgba(249, 214, 73, 0.12)',
        selected2: 'rgba(249, 214, 73, 0.24)'
      },
      onBrand: {
        shade1: 'rgba(0, 0, 0, 0.06)',
        shade2: 'rgba(0, 0, 0, 0.16)',
        shade3: 'rgba(255, 255, 255, 0.02)',
        lamp1:
          'radial-gradient(50% 100% at 50% 0%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%)',
        hover1: 'rgba(255, 255, 255, 0.08)',
        selected1: 'rgba(255, 255, 255, 0.32)'
      },
      onDanger: {
        shade1: 'rgba(0, 0, 0, 0.06)',
        shade2: 'rgba(0, 0, 0, 0.16)',
        shade3: 'rgba(255, 255, 255, 0.02)',
        lamp1:
          'radial-gradient(50% 100% at 50% 0%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%)',
        hover1: '#FFFFFF',
        selected1: 'rgba(255, 255, 255, 0.32)'
      }
    },
    blendColors: {
      onBase: {
        hover1: 'rgba(255, 255, 255, 0.04)',
        selected1: 'rgba(249, 214, 73, 0.12)'
      },
      onBrand: {
        hover1: 'rgba(255, 255, 255, 0.08)',
        selected1: 'rgba(255, 255, 255, 0.32)'
      },
      onDanger: {
        hover1: 'rgba(255, 255, 255, 0.04)',
        interaction2: 'rgba(249, 214, 73, 0.16);'
      }
    },
    text: {
      onBase: {
        contrast1: 'rgba(255, 255, 255, 0.9)',
        contrast2: 'rgba(255, 255, 255, 0.6)',
        contrast3: ' rgba(255, 255, 255, 0.4)',
        brand1: '#F9D649',
        brand2: '#FAEBAD',
        danger1: '#F9496C',
        warning: '#FAA480',
        success: '#4DFFB4',
        selected: 'rgba(255, 255, 255, 0.8)',
        disabled: 'rgba(0, 0, 0, 0.6)'
      },
      onBrand: {
        contrast1: '#000000',
        contrast2: 'rgba(0, 0, 0, 0.6)',
        contrast3: 'rgba(0, 0, 0, 0.4)',
        brand1: '#F9D649',
        brand2: '#FAEBAD',
        danger1: '#F9496C',
        warning: '#FAA480',
        success: '#4DFFB4',
        selected: 'rgba(0, 0, 0, 0.8)'
      },
      onDanger: {
        contrast1: '#000000',
        contrast2: 'rgba(0, 0, 0, 0.6)',
        contrast3: 'rgba(0, 0, 0, 0.4)',
        brand1: '#F9D649',
        brand2: '#FAEBAD',
        danger1: '#F9496C',
        warning: '#FAA480',
        success: '#4DFFB4',
        selected: 'rgba(0, 0, 0, 0.8)'
      }
    },
    icon: {
      onBase: {
        contrast1: 'rgba(255, 255, 255, 0.9)',
        contrast2: 'rgba(255, 255, 255, 0.6)',
        contrast3: 'rgba(255, 255, 255, 0.4)',
        brand1: '#F9D649',
        brand2: '#FAEBAD',
        danger1: '#F9496C'
      },
      onBrand: {
        contrast1: '#000000',
        contrast2: 'rgba(0, 0, 0, 0.6)',
        contrast3: 'rgba(0, 0, 0, 0.4)'
      },
      onDanger: {
        contrast1: '#000000',
        contrast2: 'rgba(0, 0, 0, 0.6)',
        contrast3: 'rgba(0, 0, 0, 0.4)'
      }
    },
    line: {
      onBase1: {
        contrast1: 'rgba(255, 255, 255, 0.04)',
        contrast2: 'rgba(255, 255, 255, 0.06)',
        contrast3: 'rgba(255, 255, 255, 0.32)',
        contrast4: 'rgba(255, 255, 255, 0.16)',
        shadow1: 'rgba(0, 0, 0, 0.24)',
        brand1: '#F9D649',
        brand2: 'rgba(250, 225, 128, 0.24)',
        danger1: '#FA8098',
        danger2: 'rgba(250, 128, 152, 0.24)',
        warning1: '#FAA480',
        warning2: 'rgba(250, 164, 128, 0.24)'
      },
      onBase2: {
        contrast1: 'rgba(255, 255, 255, 0.04)',
        contrast2: 'rgba(255, 255, 255, 0.06)',
        contrast3: 'rgba(255, 255, 255, 0.16)',
        contrast4: 'rgba(255, 255, 255, 0.2)',
        shadow1: 'rgba(0, 0, 0, 0.24)',
        brand1: '#F9D649',
        brand2: 'rgba(250, 225, 128, 0.24)',
        danger1: '#FA8098',
        danger2: 'rgba(250, 128, 152, 0.24)',
        warning1: '#FAA480',
        warning2: 'rgba(250, 164, 128, 0.24)'
      },
      onBrand: {
        contrast1: 'rgba(0, 0, 0, 0.5)',
        contrast2: 'rgba(0, 0, 0, 0.5)',
        contrast3: 'rgba(0, 0, 0, 0.5)',
        contrast4: '#FFFFFF',
        shadow1: 'rgba(0, 0, 0, 0.24)',
        brand1: '#FAE180',
        brand2: 'rgba(250, 225, 128, 0.24)',
        danger1: '#FA8098',
        danger2: 'rgba(250, 128, 152, 0.24)',
        warning1: '#FAA480',
        warning2: 'rgba(250, 164, 128, 0.24)'
      }
    },
    primary: {
      dark: '#161616',
      main: '#303030',
      light: '#4D4D4D',
      contrastColor: 'rgba(255, 255, 255, 0.9)',

      textWhiteHighEmphasis: 'rgba(255, 255, 255, 0.9)',
      textWhiteMediumEmphasis: 'rgba(255, 255, 255, 0.6)',
      textWhiteLowEmphasis: 'rgba(255, 255, 255, 0.4)',

      lineWhiteHighEmphasis: 'rgba(255, 255, 255, 0.36)',
      lineWhiteMediumEmphasis: 'rgba(255, 255, 255, 0.24)',
      lineWhiteLowEmphasis: 'rgba(255, 255, 255, 0.08)',

      lineBlackHighEmphasis: 'rgba(0, 0, 0, 0.48)',
      lineBlackMediumEmphasis: 'rgba(0, 0, 0, 0.24)',
      lineBlackLowEmphasis: 'rgba(0, 0, 0, 0.12)',

      iconWhiteHighEmphasis: 'rgba(255, 255, 255, 0.36)',
      iconWhiteMediumEmphasis: 'rgba(255, 255, 255, 0.24)',
      iconWhiteLowEmphasis: 'rgba(255, 255, 255, 0.08)'
    },
    backgroundAccent: {
      blackAccent1: 'rgba(0, 0, 0, 0.16)',
      blackAccent2: 'rgba(0, 0, 0, 0.24)',
      blackAccent3: 'rgba(0, 0, 0, 0.32)',
      whiteAccent1: 'rgba(255, 255, 255, 0.08)',
      whiteAccent2: 'rgba(255, 255, 255, 0.16)'
    },
    secondary: {
      light: '#FAEBAD',
      main: '#F9D649',
      dark: '#C9A619',
      contrastColor: 'rgb(255, 255, 255)'
    },
    dangerError: '#F9496C',
    warning: '#FFD84D',
    success: '#4DFFB4',
    surfaceState: {
      getSurfaceStateColor
    },
    opacity: {
      disabled1: 0.4
    }
  },
  customShadows: {
    umbraDropShadow: 'hsla(0, 0%, 0%, .2)',
    penumbraDropShadow: 'hsla(0, 0%, 0%, .14)',
    ambientDropShadow: 'hsla(0, 0%, 0%, .12)'
  },
  layout: {
    workspaceHeaderHeight: 90 - 24,
    scopeHeaderHeight: 80,
    workspaceNavDrawerWideWidth: 208 + 24 * 2,
    workspaceNavDrawerNarrowWidth: 136,
    workspaceInfopaneWidth: 350,
    workspacePadding: '24px 24px 0 24px',
    workspaceHorizontalMargin: 24,

    detailview: {
      infoPaneWidth: '360px',
      navBarWidth: '200px',
      margin: '15px',
      commandbarHeight: '48px'
    }
  },
  surfaceCorners: {
    none: '0px',
    lowEmphasis: '4px',
    mediumEmphasis: '8px',
    highEmphasis: '16px',
    circular: '999px'
  },
  typographyStyles: {
    heading1: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.01em'
    },
    heading2: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.01em'
    },
    heading3: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.01em'
    },
    heading4: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.08em',
      'text-transform': 'uppercase' // typescript has problems implementing the typed variant.
    },
    body1: {
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.02em'
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.02em'
    },
    body3: {
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.01em'
    },
    link1: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.02em'
    },
    link2: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.02em'
    }
  },
  // proposal: Remove this
  icons: {
    small: '20px',
    regular: '24px',
    big: '32px'
  },
  // proposal: add sizes key and specify sizes for buttons / icons.
  sizes: {
    iconbutton: {
      small: '32px',
      regular: '40px',
      big: '48px'
    },
    icons: {
      small: '17px',
      regular: '20px',
      big: '24px'
    }
  },
  customSpacing: {
    padding: {
      4: '4px',
      8: '8px',
      12: '12px',
      16: '16px',
      20: '20px',
      24: '24px',
      32: '32px',
      40: '40px'
    },
    margin: {
      4: '4px',
      8: '8px',
      12: '12px',
      16: '16px',
      20: '20px',
      24: '24px',
      32: '32px'
    }
  },
  sizing: {
    8: '8px',
    16: '16px',
    24: '24px',
    32: '32px',
    40: '40px',
    48: '48px',
    56: '56px',
    64: '64px',
    92: '92px',
    96: '96px',
    104: '104px',
    128: '128px',
    144: '144px',
    168: '168px',
    192: '192px',
    232: '232px'
  },
  buttons: {
    regular: {
      small: {
        height: '32px',
        // width: '67px',
        padding: '0px 16px'
      },
      regular: {
        height: '32px',
        // width: '67px',
        padding: '0px 16px'
      },
      big: {
        height: '32px',
        // width: '67px',
        padding: '0px 16px'
      }
    },
    withIcon: {
      small: {
        height: '32px',
        // width: '87px',
        padding: '0px 16px 0px 12px'
      },
      regular: {
        height: '32px',
        // width: '87px',
        padding: '0px 16px 0px 12px'
      },
      big: {
        height: '32px',
        // width: '87px',
        padding: '0px 16px 0px 12px'
      }
    },
    dropdown: {
      small: {
        height: '32px',
        // width: '73px',
        padding: '0px 0px 0px 16px'
      },
      regular: {
        height: '32px',
        // width: '73px',
        padding: '0px 0px 0px 16px'
      },
      big: {
        height: '32px',
        // width: '73px',
        padding: '0px 0px 0px 16px'
      }
    },
    dropdownWithIcon: {
      small: {
        height: '32px',
        // width: '95px',
        padding: '0px 0px 0px 12px'
      },
      regular: {
        height: '32px',
        // width: '95px',
        padding: '0px 0px 0px 12px'
      },
      big: {
        height: '32px',
        // width: '95px',
        padding: '0px 0px 0px 12px'
      }
    },
    navMenuItem: {
      small: {
        height: '40px',
        // width: '95px',
        padding: '0px 12px'
        //
      },
      regular: {
        height: '40px',
        // width: '95px',
        padding: '0px 12px'
        //
      },
      big: {
        height: '40px',
        // width: '95px',
        padding: '0px 12px'
        //
      }
    }
  },
  menu: {
    regularWidth: '200px'
  },
  customMixins: {
    surfaceShadows: ({ elevation }) => {
      const umbra = `0px ${Math.ceil(elevation / 2)}px ${Math.ceil(
        elevation / 1.5
      )}px ${-Math.ceil(elevation / 4)}px`;
      const penumbra = `0px ${Math.ceil(elevation)}px ${Math.ceil(
        elevation * 1.5
      )}px ${Math.floor(elevation / 8)}px`;
      const ambient = `0px ${Math.ceil(elevation / 3)}px ${Math.ceil(
        elevation * 2
      )}px ${Math.floor(elevation / 3)}px`;

      return {
        boxShadow: [
          `${umbra} ${theme.customShadows.umbraDropShadow}`,
          `${penumbra} ${theme.customShadows.penumbraDropShadow}`,
          `${ambient} ${theme.customShadows.ambientDropShadow}`
        ].join(', ')
      };
    }
  }
};

// customTheme.overrides = {
//   MuiButton: {
//     root: {
//       borderRadius: customTheme.surfaceCorners.circular
//     }
//   },
//   MuiPaper: {
//     root: {
//       borderRadius: customTheme.surfaceCorners.highEmphasis
//     }
//   }
// }

export const theme = createTheme({ ...defaultOptions, ...customTheme });

type CT = typeof customTheme;

export interface CustomTheme extends DefaultTheme, CT {}

type Options<T> = { [P in keyof T]: T[P] };

interface GenericOptions {
  [key: string]: Object;
}

type CustomPaletteOptions = Options<typeof customTheme.customPalette>;
type CustomShadowsOptions = Options<typeof customTheme.customShadows>;
type LayoutOptions = Options<typeof customTheme.layout>;
type SurfaceCornersOptions = Options<typeof customTheme.surfaceCorners>;
type TypographyStylesOptions = Options<typeof customTheme.typographyStyles>;
type IconsOptions = Options<typeof customTheme.icons>;
type MenuOptions = Options<typeof customTheme.menu>;
type CustomSpacing = Options<typeof customTheme.customSpacing>;
type CustomSizing = Options<typeof customTheme.sizing>;
type Buttons = Options<typeof customTheme.buttons>;

interface CustomMixinOptions {
  surfaceShadows?(config: { elevation?: number; theme?: Theme }): CSSProperties;
}

export interface CustomThemeOptions extends DefaultThemeOptions {
  customPalette?: CustomPaletteOptions;
  customShadows?: CustomShadowsOptions;
  layout?: LayoutOptions;
  surfaceCorners?: SurfaceCornersOptions;
  typographyStyles?: TypographyStylesOptions;
  icons?: IconsOptions;
  menu?: MenuOptions;
  customMixins?: CustomMixinOptions;
  customSpacing?: CustomSpacing;
  sizing?: CustomSizing;
  buttons?: Buttons;
}

// proposal
export type SizeVariations = 'small' | 'regular' | 'big';
export type ButtonTypes =
  | 'regular'
  | 'withIcon'
  | 'dropdown'
  | 'dropdownWithIcon'
  | 'navMenuItem';

export type colorVariationsElements =
  | 'lowEmphasis'
  | 'mediumEmphasis'
  | 'highEmphasis'
  | 'error'
  | 'contrastColor'
  | 'selected'
  | 'contrast1'
  | 'contrast2'
  | 'contrast3'
  | 'brand1'
  | 'brand2'
  | 'danger1'
  | 'warning'
  | 'disabled';

//  button helpers
/*   Button color variants    */
export type ColorVariants =
  | 'filled1'
  | 'filled2'
  | 'filledDanger'
  | 'ghost1'
  | 'ghost2'
  | 'covertDropdown';

export { MuiThemeProvider };
