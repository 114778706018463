import React, { useCallback } from 'react';

export const useScrollbarThumbs = ({ classes, colorScrollbar }) => {
  const renderThumbVertical = useCallback(
    () => (
      <div
        // {...rest}
        className={`
              ${classes.thumbStyle} 
              ${classes.thumbStyleVertical}
              ${classes['color_' + colorScrollbar]}
            `}
      />
    ),
    [classes, colorScrollbar]
  );

  const renderThumbHorizontal = useCallback(
    () => (
      <div
        // {...rest}
        className={`
            ${classes.thumbStyle} 
            ${classes.thumbStyleHorizontal}
            ${classes['color_' + colorScrollbar]}
          `}
      />
    ),
    [classes, colorScrollbar]
  );

  return { renderThumbVertical, renderThumbHorizontal };
};
