import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useModalActions } from '../../Modals/modalActions';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { capitalize } from '@material-ui/core';
import Cube3TextField from '@cube3/ui/src/forms/textfields/Cube3TextField';
import { CreateTags } from '@cube3/cubicle/src/core/molecules/TagManagement/CreateTagsList';
import { initialUiTag } from '@cube3/cubicle/src/core/molecules/TagManagement/types';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';

const invalideCategories = () => [
  { type: undefined, id: undefined, relationship: 'tag-categories' }
];

const MAX_CHA_LEN = 32;

export const CreateTagCategoryModal = () => {
  const { previousModal } = useModalActions();
  const [workspaceId] = useCurrentWorkspace();

  const [category, setCategory] = useState<string>(undefined);
  const [tags, setTags] = useState({ ...initialUiTag });

  const [createCategory, createCategoryStatus] = useCreateResource__ALPHA({
    resourceType: 'tag-category',
    cacheInvalidator: invalideCategories
  });
  const tempId = useRef(uuidv4()).current;
  const categoryId = useMappedId__ALPHA(tempId);

  //#region retrieve the tags from the newly created category, used to determine the loading state
  const { pageCounts: newlyCreatedTags, status } = useResourceList__ALPHA(
    useMemo(
      () => ({
        resourceType: 'workspace',
        resourceId: categoryId ? workspaceId : undefined,
        edgeType: 'tag',
        edgeLabel: 'tags',
        params: {
          filter: {
            category_id: categoryId
          }
        }
      }),
      [workspaceId, categoryId]
    )
  );

  const toCreateLength = Object.keys(tags).filter(
    (index) => !tags[index].deleted
  ).length;
  const creatingTagsLoading =
    status && toCreateLength && newlyCreatedTags.itemCount !== toCreateLength;
  //# region

  const [createTag] = useCreateResource__ALPHA(
    useMemo(
      () => ({
        ancestor: {
          type: 'workspace',
          id: workspaceId
        },
        resourceType: 'tag',
        cacheInvalidator: () => [
          ...invalideCategories(),
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'tags'
          }
        ]
      }),
      [workspaceId]
    )
  );
  const { resources: categories } = useResourceList__ALPHA({
    edgeType: 'tag-category',
    edgeLabel: 'tag-categories',
    params: {
      filter: {
        workspace: workspaceId
      }
    }
  });

  const setCategoryName = useCallback(
    (e) => {
      setCategory(e.target.value);
    },
    [setCategory]
  );

  const onSave = useCallback(() => {
    // step 1: create tag category
    if (category) {
      createCategory({
        type: 'tag-category',
        display_name: capitalize(category),
        temporaryId: tempId,
        relationships: {
          workspace: {
            data: {
              type: 'workspace',
              id: workspaceId
            }
          }
        }
      });
    }
  }, [createCategory, category, workspaceId]);

  // step 2: create tags and attach them to the new created category
  const clicked = useRef(false);
  useEffect(() => {
    if (!clicked.current && categoryId && Object.keys(tags).length) {
      Object.keys(tags).forEach((key) => {
        const newTag = tags[key];
        if (newTag.deleted) return;
        createTag({
          ...newTag,
          type: 'tag',
          relationships: {
            category: {
              data: {
                type: 'tag-category',
                id: categoryId
              }
            }
          }
        });
      });
      clicked.current = true;
    }
  }, [categoryId, tags]);

  // close modal after all the tags have been created
  useEffect(() => {
    if (
      createCategoryStatus === statuses.SUCCESS &&
      toCreateLength === newlyCreatedTags.itemCount
    ) {
      previousModal();
    }
  }, [
    createCategoryStatus,
    previousModal,
    newlyCreatedTags.itemCount,
    toCreateLength
  ]);

  const categoryInputError = (() => {
    const conflictingName =
      !categoryId &&
      !!categories?.find(
        (c) => c.display_name.toLowerCase() === category?.toLowerCase()
      );
    switch (true) {
      case category?.length > MAX_CHA_LEN:
        return `Must be ${MAX_CHA_LEN} charactors or less.`;
      case conflictingName:
        return 'This name is already in use.';
      default:
        return '';
    }
  })();

  const formValid =
    category &&
    Object.keys(tags).every((k) => !tags[k].error) &&
    !categoryInputError;

  const loading =
    (createCategoryStatus && !hasResolved(createCategoryStatus)) ||
    creatingTagsLoading;

  useGlobalSubmit(onSave, !formValid || loading);
  return (
    <ModalMenuUI
      title="Create tag category"
      onCloseEvent={previousModal}
      loading={loading}
      footerRightComponent={
        <>
          <Button
            label="Cancel"
            onClick={previousModal}
            background="secondary"
            buttonStyle="ghost"
          />
          <Button
            label="Save"
            disabled={!formValid}
            background="primary"
            buttonStyle="solid"
            onClick={onSave}
          />
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 48,
          gap: '8px'
        }}
      >
        <Typography variant="heading3">Title</Typography>
        <Cube3TextField
          value={category}
          onChange={setCategoryName}
          placeholder="New category"
          fullWidth={true}
        />
        {!categoryInputError ? null : (
          <Typography color="danger1">{categoryInputError}</Typography>
        )}

        <div style={{ height: 8 }}></div>
        <Typography variant="heading3">New Tags</Typography>
        <CreateTags tags={tags} setTags={setTags} />
      </div>
    </ModalMenuUI>
  );
};
