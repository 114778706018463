import * as React from 'react';
import Button from '../../Buttons/Cube3Button';

/* UI */
import { ModalMenuUI } from '../../Modal/ModalMenuUI';

interface Properties {
  onClickClose(): void;
}

export const ProjectActivityModalUIC: React.FC<
  React.PropsWithChildren<Properties>
> = (props) => {
  const { onClickClose } = props;

  return (
    <ModalMenuUI
      multiplyContentSpace={8}
      title={'Project Activity'}
      onCloseEvent={() => onClickClose()}
      footerRightComponent={
        <Button
          text={'Done'}
          colorVariant={'filled1'}
          onClick={() => onClickClose()}
        />
      }
    >
      {props.children}
    </ModalMenuUI>
  );
};

/** UI Shell for project activity items */
export const ProjectActivityModalUI = ProjectActivityModalUIC;
