import React, { useCallback } from 'react';
import { Field } from 'redux-form';

// UI
import SuggestionsContainer from '@cube3/ui/src/inputChipPicker/SuggestionsContainer';
import WrappedSuggestionsInputField from '@cube3/ui/src/forms/WrappedSuggestionsInputField';

export interface MultipleResourcePickerGeneralProps {
  clearInput: Function;
  form: string;
  fields: any;
  allSuggestion?: any[];
  excludedSuggestion?: any[];
  placeholder?: string;
  autoFocus?: boolean;
  excludedReasonText?: string;
  searchInput?: string;
  suggestionsLoading?: boolean;
}

interface ExtraProps {
  fieldName: string;
  renderSuggestionItem: (suggestion) => React.ReactElement;
  renderSelectedItem: (item, index) => React.ReactElement;
}

type Props = MultipleResourcePickerGeneralProps & ExtraProps;

const itemToString = i => {
  return i ? (i.name === i.email ? i.name : `${i.name} (${i.email})`) : '';
};

const keysListenersArrayToAddNewSuggestions = ['Tab', ',', ';', 'Enter'];
const emptyArray = [];

const MultipleResourcePickerBase = (props: Props) => {
  const {
    clearInput,
    form,
    fields,
    allSuggestion = emptyArray,
    searchInput,
    excludedSuggestion = emptyArray,
    placeholder,
    autoFocus = true,
    excludedReasonText,
    fieldName,
    suggestionsLoading,
    renderSuggestionItem,
    renderSelectedItem
  } = props;

  const selectedSuggestions = fields.getAll() || emptyArray;

  const clearInputField = useCallback(
    () => clearInput(form, true, true, `${fieldName}`),
    [clearInput, form, fieldName]
  );

  return (
    <SuggestionsContainer
      inputValue={searchInput}
      allSuggestions={allSuggestion}
      selectedSuggestions={selectedSuggestions}
      suggestionsLoading={suggestionsLoading}
      excludedSuggestions={excludedSuggestion}
      excludedReasonText={excludedReasonText || 'Already added'}
      showDisabled={!!searchInput}
      keysListenersArrayToAddNewSuggestions={
        keysListenersArrayToAddNewSuggestions
      }
      addItemToSelectionArray={item => fields.push(item)}
      removeItemFromSelectionArray={index => fields.remove(index)}
      allowAddingOfNewSuggestions={false}
      newSuggestionPrimaryKey={'id'}
      itemToString={itemToString}
      clearInputField={clearInputField}
      clearOn="all"
      renderSelectedItem={renderSelectedItem}
      renderInputChipPicker={(
        handleInputKeyDown,
        selectHighlightedItem,
        highlightedIndex,
        isOpen,
        reset,
        inputValue,
        inputField,
        getInputProps,
        filteredSuggestions,
        setHighlightedIndex,
        openMenu,
        closeMenu
      ) => (
        <Field
          name={fieldName}
          type={'inputValue'}
          component={WrappedSuggestionsInputField}
          props={{
            handleInputKeyDown,
            selectHighlightedItem,
            highlightedIndex,
            isOpen,
            reset,
            inputValue,
            inputField,
            getInputProps,
            filteredSuggestions,
            inputPlaceholder:
              searchInput || selectedSuggestions.length > 0 ? '' : placeholder,
            setHighlightedIndex,
            openMenu,
            closeMenu,
            autoFocus
          }}
        />
      )}
      renderSuggestionItem={renderSuggestionItem}
    />
  );
};

export default MultipleResourcePickerBase;
