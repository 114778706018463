import React from 'react';

import CustomIconButton from '../../Buttons/IconButton';

import {
  iconMinimizeVector,
  iconMaximizeVector,
} from '../../icons/GeneralSVG';

interface Props {
  fullscreen: boolean;
  shortCutTip?: string;
  toggleFullscreenAction(): void;
}

export const FullscreenButton: React.FC<Props> = React.memo(props => {
  const { fullscreen, shortCutTip, toggleFullscreenAction } = props;

  return fullscreen ? (
    <CustomIconButton
      aria-label="leave fullscreen"
      colorVariant="ghost3"
      size={'regular'}
      path={iconMinimizeVector}
      svgProperties={{
        svgProps: { viewBox: '0 0 32 32' },
      }}
      onClick={toggleFullscreenAction}
      title={shortCutTip && `Exit full screen (${shortCutTip})`}
    />
  ) : (
    <CustomIconButton
      aria-label="enter fullscreen"
      colorVariant="ghost3"
      size={'regular'}
      path={iconMaximizeVector}
      svgProperties={{
        svgProps: { viewBox: '0 0 32 32' },
      }}
      onClick={toggleFullscreenAction}
      title={shortCutTip && `Full screen (${shortCutTip})`}
    />
  );
});
