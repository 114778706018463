import {
  actionCreators as nodeActionCreators,
  selectors as nodeSelectors
} from '../../ducks/resource-nodes';

export const updateUpload = (id, body, dispatch, getState) => {
  if (!id) {
    console.error('Id missing');
  }
  const state = getState();

  const existing =
    nodeSelectors.getResourceById(state, 'file-upload', id) || {};

  dispatch(
    nodeActionCreators.receiveResource('file-upload', {
      id,
      ...existing,
      updated_at: new Date().toUTCString(),
      ...body
    })
  );
};
