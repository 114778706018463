import { useRef, useMemo } from 'react';

/**
 * A hook to combine class name strings, filtering out conditionally undefined ones
 * @param args argument array of class name strings (or falsy values)
 */
export const useClassName = (...args) => {
  const lastClasses = useRef(args);

  // console.time('useClassName complex');

  let should;

  switch (true) {
    case args.length !== lastClasses.current.length:
      should = true;
      break;

    case !!args.filter((c, idx) => lastClasses.current[idx] !== c).length:
      should = true;
      break;

    default:
      break;
  }

  if (should) {
    lastClasses.current = args;
  }

  let lastclassesRef = lastClasses.current;

  const retVal = useMemo(() => {
    return lastclassesRef
      ? lastclassesRef.filter(c => !!c && typeof c === 'string').join(' ')
      : '';
  }, [lastclassesRef]);
  // console.timeEnd('useClassName complex');

  // // console.time('useClassName simple');
  // const retVal2 = args
  //   ? args.filter(c => !!c && typeof c === 'string').join(' ')
  //   : '';
  // // console.timeEnd('useClassName simple');

  // return [retVal, retVal2][Math.round(Math.random())];
  return retVal;
};
