import React from 'react';
import { useModalActions, ModalActionProps } from '../Hooks/useModalActions';

/** @author: Simon @AmbassadorsLAB
 * <Summary>
 * Higher order functions for modal actions.
 * Convienience wrapper of useModalActions hook.
 * For use in class based components
 * </Summary>
 */

export const withModalActions = <
  M extends string,
  P extends Partial<ModalActionProps<M>>
>(
  Component: React.ComponentType<P>
) => props => {
  const injected = useModalActions<M>();

  return <Component {...props} {...injected} />;
};
