import * as React from 'react';
import {
  Field,
  reduxForm,
  getFormValues,
  InjectedFormProps,
  FormSubmitHandler
} from 'redux-form';
import { connect } from 'react-redux';

// Main
import GenericFieldWithErrors from '../../../forms/helpers/fields/GenericFieldWithErrors';
import { compose } from '../../../../utils/component-helpers';

// State
import { ResourceType } from '@cube3/common/model/resource-types';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { actionCreators as nodeActionCreators } from '@cube3/state/src/redux/ducks/resource-nodes';

import {
  isRequired,
  maxCharacters,
  noSpaceAtStart,
  makeValidator
} from '../../../forms/helpers/validators'; // TODO add maxCharacters

// UI
import { hasError } from '@cube3/state/src/wrapped-cube-client';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { useModalActions } from '../Modals/modalActions';
import withCurrentWorkspace from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

/*
TODO: 

- Add a validation to check for characters that could be rejected by the backend.
- Highlighting the text before the extension  

*/

interface FormValues {
  name: string;
}

const mapStateToProps = (state, ownProps) => {
  const { id, type, display_name } = ownProps.modalContext;
  const formId = type + '_' + id;
  const values = getFormValues(formId)(state);

  const initialValues: FormValues = {
    name: display_name
  };

  return {
    form: formId,
    targetResourceId: id,
    initialValues,
    ...values
  };
};

type ModalReceivedProps = {
  modalContext?: {
    type: string;
    id: string;
    display_name?: string;
    modalTitle?: string;
    /** file-request: have to send current emails, otherwise it will be null */
    extraAttributes?: any;
  };
};
interface HOCMappedProps {
  onSubmit: FormSubmitHandler<FormValues>;
  loading: boolean;
}

interface Props
  extends ModalReceivedProps,
    HOCMappedProps,
    InjectedFormProps<FormValues> {
  name: string;
}

// define validators
const maxChars = (value: string) => maxCharacters(value, 256);
const stackedErrors = makeValidator([maxChars, isRequired, noSpaceAtStart]);

function RenamePrompt(props: Props) {
  const {
    handleSubmit,
    onSubmit,
    submitSucceeded,
    loading,
    valid,
    submitting,
    error,
    modalContext,
    pristine
  } = props;
  const { modalTitle } = modalContext;
  const { previousModal } = useModalActions();

  React.useEffect(() => {
    if (submitSucceeded) {
      previousModal();
    }
  }, [previousModal, submitSucceeded]);

  const conflict = hasError(error, 'HTTP_CONFLICT');

  //  const pristine = initialValues.name === name

  return (
    <ModalNoticeUI
      onCloseEvent={previousModal}
      title={modalTitle ? modalTitle : 'Rename'}
      loading={loading}
      component="form"
      footerRightComponent={
        <>
          <Button onClick={previousModal} text="Cancel" colorVariant="ghost2" />

          <Button
            text={'Save'}
            disabled={!valid || submitting || !!error || pristine}
            extraButtonProperties={{
              'data-type': 'librarydeselectoverride',
              type: 'submit'
            }}
            colorVariant={'filled1'}
          />
        </>
      }
      extraComponentProps={
        //important for forms
        {
          onSubmit: handleSubmit(onSubmit)
        }
      }
    >
      <Field
        component={GenericFieldWithErrors}
        validate={stackedErrors}
        name="name"
        type="name"
        inputProps={{ autoFocus: true }}
        startSelected={true}
      />

      {conflict && (
        <div style={{ marginTop: 8 }}>
          <Typography typographyStyle="body2" color="danger1">
            This name is already in use
          </Typography>
        </div>
      )}
    </ModalNoticeUI>
  );
}

const RenamePromptForm = compose(RenamePrompt)(
  withCurrentWorkspace,
  connect(mapStateToProps),
  reduxForm<FormValues, Props>({
    onSubmit: (values, dispatch, props) => {
      const { id, type, extraAttributes } = props.modalContext;
      dispatch(
        addFormId(props.form, {
          formState: undefined,
          useRequestStatus: true
        })(
          nodeActionCreators.mutateResource(
            type as ResourceType,
            id,
            {
              display_name: values.name,
              ...extraAttributes
            },
            [
              { type, id },
              {
                type: 'workspace',
                id: props.currentWorkspaceId,
                relationship: 'shares'
              }
            ]
          )
        )
      );
    }
  })
);

export default RenamePromptForm;
