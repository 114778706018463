import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { useClassName } from '../utils/useClassName';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        maxWidth: '100%',
        maxHeight: '100%',
        '& > img': {
          borderRadius: theme.surfaceCorners.lowEmphasis
        }
      },
      dark: {
        background: theme.customPalette.backgroundAccent.blackAccent2
      },
      light: {
        background: theme.customPalette.backgroundAccent.whiteAccent1
      },
      main: {
        background: theme.customPalette.backgroundAccent.blackAccent1
      },
      shadow: {
        '& > img': {
          ...theme.customMixins.surfaceShadows({ elevation: 10 })
        }
      }
    }),
  { name: 'C3ThumbnailImageContainer' }
);

export type FillVariant = 'dark' | 'light' | 'main' | 'none';

interface ThumbnailImageContainerProps {
  fillVariant?: FillVariant;
  addShadow?: boolean;
}

const ThumbnailImageContainer: React.FC<React.PropsWithChildren<ThumbnailImageContainerProps>> = ({
  fillVariant,
  addShadow = false,

  children
}) => {
  const classes = useStyles();

  return (
    <div
      className={useClassName(
        classes.root,
        classes[fillVariant],
        addShadow && classes.shadow
      )}
    >
      {children}
    </div>
  );
};

export default ThumbnailImageContainer;
