import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Folder extends ResourceBase {
  src: string;
  disabled: boolean;
  display_name: string;
  display_image?: string;
  relationships: {
    assets: Related<'asset'>[];
    folders: Related<'folder'>[];
    ancestors: Related<'folder'>[];
    node: Related<'content-tree-node'>;
    parent: Related<'content-tree-node'>;
    tags: Related<'tag'>[];
  };
}

export const folder: RawModel = {
  type: 'folder',
  plural: 'folders',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    display_image: 'URL'
  },
  relationships: {
    assets: {
      type: 'asset',
      binding: ['many', 'one'],
      required: true
    },
    folders: {
      type: 'folder',
      binding: ['many', 'one'],
      required: true
    },
    ancestors: {
      type: 'folder',
      binding: ['many', 'many'],
      required: true
    },
    node: {
      type: 'content-tree-node',
      binding: ['one', 'one']
    },
    parent: {
      type: 'content-tree-node',
      binding: ['one', 'many'],
      reverse: 'children'
    },
    tags: {
      type: 'tag',
      binding: ['many', 'one'],
      reverse: 'assets'
    }
  }
};
