const FORCE_LOGGER = false;
const loggerMiddleware = ({ getState }) => {
  return next => action => {
    // Call the next dispatch method in the middleware chain.
    let returnValue = next(action);

    setTimeout(() => {
      if (
        FORCE_LOGGER ||
        (!!JSON.parse(localStorage.getItem('ENABLE_REDUX_LOGGING')) !==
          !!JSON.parse(localStorage.getItem('DISABLE_REDUX_LOGGING')) &&
          (!!JSON.parse(localStorage.getItem('ENABLE_REDUX_LOGGING')) ||
            JSON.parse(localStorage.getItem('DISABLE_REDUX_LOGGING')) ===
              false))
      ) {
        // eslint-disable-next-line
        console.log(
          `%c<<< Dispatch: "${action.type}"`,
          'color: #426669;',
          action
        );
        // eslint-disable-next-line
        console.log('%c>>> New state:', 'color: #316E4D;', getState());
      }
    });
    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
};
export default loggerMiddleware;
