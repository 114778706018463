import React, { useCallback, useState } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import SelectComponent from '../../../../../ui/src/select/SelectComponent';
import { MenuItem } from '@material-ui/core';
import Cube3TextField from '../../../../../ui/src/forms/textfields/Cube3TextField';
import { Button } from '../../atoms/Button';
import { Tag as TagResource } from '@cube3/common/model/schema/resources/tag';
import { useClassName } from '@cube3/common/utils/useClassName';
import { TagCategory } from '@cube3/common/model/schema/resources/tag-category';
import { Typography } from '../../atoms/Typography';
import { CreateTagRowUI } from './types';
import { COLORS, ColorVariant } from '../../atoms/Color';
import { Theme } from '../../../theme/themes';
import { Color } from '../../atoms/Color';

const useCSS = makeCSS(({ theme, color }: { theme: Theme; color: string }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  row: {
    display: 'grid',
    gap: '8px',
    alignItems: 'center',
    gridTemplateColumns: '336px 144px 32px'
  },
  fourColumns: {
    gridTemplateColumns: '160px 144px 210px 32px'
  },
  colorSpan: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '100%',
    marginRight: 8,
    backgroundColor:
      color === 'black' || color === 'white'
        ? theme.color.background[color]
        : theme.color.background[color]?.main,
    border:
      color === 'ghost'
        ? `1px solid ${theme.color.foreground.secondary}`
        : 'none'
  }
}));

export type ChangeTagConfig = {
  text?: string;
  color?: string;
  categoryId?: string;
};

export interface TagRowUIProps {
  tag?: CreateTagRowUI | TagResource;
  categories?: TagCategory[];
  menuComponent?: JSX.Element;
  error?: string;
  disabled?: boolean;
  updateTags?: (config: Partial<CreateTagRowUI>) => void;
  deleteTag?: () => void;
  /** used in `Tag management` page: auto update tag when
   * - lose focus on text field
   * - select different color
   */
  mutateTag?: (config: ChangeTagConfig) => void;
}

const mapColor: { [c: string]: ColorVariant } = {
  purple: 'violet',
  blue: 'indigo'
};
const CreateTagRow = (props: TagRowUIProps) => {
  const {
    tag,
    categories,
    menuComponent,
    error,
    disabled,
    deleteTag,
    updateTags,
    mutateTag
  } = props;

  const [text, setText] = useState<string>(tag?.text || '');
  const [color, setColor] = useState<ColorVariant>(
    ((mapColor[tag?.color] || tag?.color) as ColorVariant) || 'ghost'
  );
  const [categoryName, setCategory] = useState<string>(
    (tag as CreateTagRowUI)?.categoryName || undefined
  );

  const classes = useCSS();

  const setTagName = useCallback(
    (e) => {
      const val = e ? e.target.value : e;
      setText(val);
      if (updateTags) {
        updateTags({ text: val });
      }
    },
    [setText, updateTags]
  );

  const onFocusOut = useCallback(() => {
    if (mutateTag) {
      mutateTag({ text });
    }
  }, [mutateTag, text]);

  const onClickColor = useCallback(
    (c) => {
      setColor(c);
      if (updateTags) {
        updateTags({ color: c });
      }
      if (mutateTag) {
        mutateTag({ color: c });
      }
    },
    [mutateTag, setColor, updateTags]
  );

  const onClickCategory = useCallback(
    (name: string) => {
      setCategory(name);
      if (updateTags) {
        updateTags({ categoryName: name });
      }
      const categoryId = categories?.find((c) => c.display_name === name)?.id;
      // only change tag's category if a different category is selected
      if (mutateTag && categoryId) {
        mutateTag({
          categoryId
        });
      }
    },
    [mutateTag, setCategory, categories, updateTags]
  );

  const showError = tag?.['error'] || error;

  return (
    <div className={classes.root}>
      <div
        className={useClassName(
          classes.row,
          categories ? classes.fourColumns : ''
        )}
      >
        <Cube3TextField
          value={text}
          onChange={setTagName}
          placeholder="New tag"
          error={showError}
          onBlur={onFocusOut}
          disabled={disabled}
        />

        {/** select color */}
        <SelectComponent
          labelName="tag-color"
          value={color}
          onChange={onClickColor}
          disabled={disabled}
          width={{ input: 144 }}
          renderValue={(c) => (c === 'ghost' ? 'no color' : c)}
          startAdornment={
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                width: '0.862em' /** 8px  */,
                height: '0.533em' /** 8px  */,
                paddingRight: 8
              }}
            >
              <Color color={color} />
            </span>
          }
        >
          {COLORS.map((c) => (
            <MenuItem key={c} value={c}>
              <Color color={c} />
              <div style={{ width: 8 }} />
              {c === 'ghost' ? 'no color' : c}
            </MenuItem>
          ))}
        </SelectComponent>

        {/** select category  */}
        {categories?.length ? (
          <SelectComponent
            labelName="select-category"
            value={categoryName}
            onChange={onClickCategory}
            placeholder="Category"
            disabled={disabled}
          >
            {categories.map((c) => (
              <MenuItem key={c.id} value={c.display_name}>
                {c.display_name}
              </MenuItem>
            ))}
          </SelectComponent>
        ) : null}

        {deleteTag ? (
          <Button
            iconLeft="close"
            background="secondary"
            buttonStyle="ghost"
            onClick={deleteTag}
          />
        ) : null}
        {menuComponent ? menuComponent : null}
      </div>
      {showError && <Typography customColor="#da3143">{showError}</Typography>}
    </div>
  );
};

export default CreateTagRow;
