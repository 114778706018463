import React, { EventHandler, useEffect, useState, useRef } from 'react';
import { makeCSS } from '../../utils/makeCSS';
import { withCubicleFallback } from '../../utils/CubicleFallback';
import { Typography } from './Typography';
import { Icon } from './Icon';
import { iconFiles } from './Icon/files';
import { SizeVariants } from '../../theme/themes';
import { cx } from '@emotion/css';

/**
 * The MenuItem component represents an option that the user
 * an select from the Menu.
 */
/**
 * Note: some icons don't have a fill version, in that case
 * the activeIcon prop stays null.
 */
export interface MenuItemProps {
  /**The value of the menu item */
  value?: string;
  /**The default icon we want to display*/
  itemIcon?: keyof typeof iconFiles;
  /**When the state is active we should use the fill version of the default icon */
  activeIcon?: keyof typeof iconFiles;
  /**When MenuItem is chosen or the page of the item is displayed */
  selected?: boolean;
  /** */
  onClick?: EventHandler<React.MouseEvent>;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      display: 'flex',
      flexShrink: 0,
      borderRadius: theme.borderRadius.lg,
      alignItems: 'center',
      cursor: 'pointer',
      width: '100%',
      height: 40,
      paddingLeft: 12,
      paddingRight: 12,
      gap: 8,
      outline: 'none',
      background: 'none',
      border: 'none',
      '&:hover': {
        background: theme.color.overlay.hovered
      },
      '&:focus-visible': {
        outline: `1px solid ${theme.color.foreground.primary}`,
        boxShadow: `0px 0px 0px 3px rgba(255, 212, 35, 0.12)`
      }
    },
    selected: {
      background: theme.color.overlay.focused
    }
  };
});

const MenuItem = (props: MenuItemProps) => {
  const {
    value,
    itemIcon,
    onClick,
    activeIcon = null,
    selected = false
  } = props;

  const classes = useCSS();
  const [clicked, setClicked] = useState(selected);
  const [focus, setFocus] = useState(false);
  const colorVariant = focus ? '01' : '02';

  const finalIcon = clicked && activeIcon ? activeIcon : itemIcon;

  const firstRender = useRef(true);

  useEffect(() => {
    /**
     * checks if  its the first time the component gets rendered and sets the focus equal to the selected.
     * we only need this for the first time
     */
    if (firstRender.current) {
      setFocus(selected);
    }
    firstRender.current = false;
    setClicked(selected || false);
  }, [selected]);

  //we dont want it to be unselected by clicking
  const handleSelected = (event: React.MouseEvent) => {
    setClicked(!clicked);
    focus ? setFocus(false) : setFocus(true);
    onClick(event);
  };

  return (
    <button
      type="button"
      onClick={handleSelected}
      className={cx(classes.root, clicked ? classes.selected : '')}
    >
      <Icon
        icon={finalIcon}
        colorVariant={colorVariant}
        size={SizeVariants.md}
      />
      <Typography variant="md" weight="body" colorVariant={colorVariant}>
        {value}
      </Typography>
    </button>
  );
};

const Default = withCubicleFallback(MenuItem);
export { Default as MenuItem };
