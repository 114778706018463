import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { capitalize } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { PROJECT_ROLE_TYPES } from '@cube3/common/model/types';

const config = {
  cacheInvalidator: (r) => [r]
};
export const ChangeProjectTeamRoleModal = (props) => {
  const { role, projectTeamId } = props.modalContext;
  const { previousModal } = useModalActions();

  const [mutateProjectTeam, mutateProjectTeamStatus] =
    useMutateResource__ALPHA(config);

  const onChangeRole = useCallback(() => {
    const newRole = PROJECT_ROLE_TYPES[role.role];

    mutateProjectTeam({
      type: 'project-team-membership',
      id: projectTeamId,
      project_member_role: newRole
    });
  }, [mutateProjectTeam, projectTeamId, role.role]);

  useEffect(() => {
    if (mutateProjectTeamStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateProjectTeamStatus, previousModal]);

  const loading =
    mutateProjectTeamStatus &&
    mutateProjectTeamStatus !== statuses.SUCCESS &&
    mutateProjectTeamStatus !== statuses.FAILED;

  return (
    <ModalNoticeUI
      title="Change team role?"
      onCloseEvent={previousModal}
      loading={loading}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={() => previousModal()}
          />
          <Button
            disabled={loading}
            text={'Yes'}
            colorVariant="filled1"
            onClick={onChangeRole}
          />
        </>
      }
    >
      <Typography color="contrast1" typographyStyle="body2">
        {"Are you sure you want to change this team's role to "}
        {capitalize(role.role)}?
      </Typography>
    </ModalNoticeUI>
  );
};
