import React from 'react';
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';

export const DiscardConfigChangesPrompt = props => {
  const { modalContext } = props;
  const { onConfirm, onCancel } = modalContext;
  return (
    <GenericNoticeModalUI
      title={`Discard changes?`}
      text={`Continue without saving changes?`}
      renderTwoButtons={true}
      secondButtonClickHandler={onConfirm}
      buttonText="Cancel"
      secondButtonText="Discard"
      secondButtonColorVariant="filledDanger"
      onClose={onCancel}
    />
  );
};
