import {
  RelationshipIdentifier,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { ns } from './config';

const MARK_UNKNOWN = `${ns}/MARK_UNKNOWN`;
const MARK_IN_FLIGHT = `${ns}/MARK_IN_FLIGHT`;
const MARK_SUCCESS = `${ns}/MARK_SUCCESS`;
const MARK_FAILED = `${ns}/MARK_FAILED`;
const MARK_INVALIDATED = `${ns}/MARK_INVALIDATED`;
const INVALIDATE_RESOURCE = `${ns}/INVALIDATE_RESOURCE`;
const INVALIDATE_RELATIONSHIP = `${ns}/INVALIDATE_RELATIONSHIP`;
const CANCEL_RETRIEVE = `${ns}/CANCEL_RETRIEVE`;

export const actions = {
  MARK_UNKNOWN,
  MARK_IN_FLIGHT,
  MARK_SUCCESS,
  MARK_FAILED,
  MARK_INVALIDATED,
  INVALIDATE_RESOURCE,
  INVALIDATE_RELATIONSHIP,
  CANCEL_RETRIEVE
};

const markInFlight = (action) => ({
  type: MARK_IN_FLIGHT,
  payload: {
    action
  },
  meta: {
    requestStatus: action.meta && action.meta.requestStatus
  }
});

const markUnknown = (action) => ({
  type: MARK_UNKNOWN,
  payload: {
    action
  },
  meta: {
    requestStatus: action.meta && action.meta.requestStatus
  }
});

const markSuccess = (action) => {
  const reqAction = {
    type: MARK_SUCCESS,
    payload: {
      action
    },
    meta: {
      requestStatus: action.meta && action.meta.requestStatus
    }
  };
  return reqAction;
};

const markFailed = (action, errors = {}) => {
  const reqAction = {
    type: MARK_FAILED,
    payload: {
      action
    },
    meta: {
      requestStatus: action.meta && action.meta.requestStatus
    },
    error: errors
  };
  return reqAction;
};

const invalidateResource = (resource: ResourceIdentifier) => ({
  type: INVALIDATE_RESOURCE,
  payload: resource
});
const invalidateRelationship = (resource: RelationshipIdentifier) => ({
  type: INVALIDATE_RELATIONSHIP,
  payload: resource
});

const markInvalid = ({ resourceType, resourceId }) => ({
  type: actions.MARK_INVALIDATED,
  payload: {
    resourceType,
    resourceId
  }
});

const markInFlightByHash = (hash) => {
  const reqAction = {
    type: MARK_IN_FLIGHT,
    payload: null,
    meta: {
      requestStatus: {
        hash,
        resource: {}
      }
    }
  };
  return reqAction;
};
const markSuccessByHash = (hash) => {
  const reqAction = {
    type: MARK_SUCCESS,
    payload: null,
    meta: {
      requestStatus: {
        hash,
        resource: {}
      }
    }
  };
  return reqAction;
};

const markFailedByHash = (hash, errors) => {
  const reqAction = {
    type: MARK_FAILED,
    payload: null,
    meta: {
      requestStatus: {
        hash,
        resource: {} // workaround because the hashes
      }
    },
    error: errors
  };
  return reqAction;
};

const cancelRetrieve = (action) => {
  const reqAction = {
    type: CANCEL_RETRIEVE,
    payload: {
      action
    },
    meta: { apiClient: {} }
  };
  return reqAction;
};

export const actionCreators = {
  markUnknown,
  markInFlight,
  markSuccess,
  markFailed,
  markInvalid,
  invalidateResource,
  invalidateRelationship,
  markInFlightByHash,
  markSuccessByHash,
  markFailedByHash,
  cancelRetrieve
};
