import React from 'react';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { Project } from '@cube3/common/model/schema/resources/project';

import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import { AttachedContractsView } from '../pages/AttachedContractsView';
import { ModalWindowUI } from '@cube3/ui/src/Modal/ModalWindowUI';

interface Props extends ModalReceiverProps {
  modalContext: Project;
}

export const ProjectContractsModal = (props: Props) => {
  const project = props.modalContext;
  const modalActions = useModalActions();
  return (
    <ModalWindowUI
      title="Project Contracts"
      onCloseEvent={modalActions.previousModal}
      showCloseButton={true}
    >
      <AttachedContractsView parentResource={project as ResourceIdentifier} />;
    </ModalWindowUI>
  );
};
