import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useLayoutEffect
} from 'react';
// contexts

// modules
import { Stroke } from '../modules/sketchPad/stroke';
// types

import DrawingToolbar from './DrawingToolbar/DrawingToolBar';
import { SketchPad } from '../modules/sketchPad/sketchpad';
import { DrawingCanvasContext } from '../contexts/annotationContexts';
import {
  CustomizedBrushConfig,
  defaultBrushConfig
} from './DrawingToolbar/Brushes';
import { FullscreenButton } from '@cube3/ui/src/previews/VideoPreview/FullscreenButton';
import { AnotationCanvasFormat } from '@cube3/ui/src/Hooks/useVideoActualSize';
import { drawArrow } from './utils/drawArrow';
// import { useCanvasBoundingStyles } from '../hooks/useCanvasBoundingStyles';

interface Props {
  saveAnnotation: (svg) => void;
  playedSeconds: number;
  format: AnotationCanvasFormat;
  annotationToggled: boolean;
  drawnSvg?: string;
  fullscreen?: boolean;
  toggleDrawingBar?: (open?: boolean) => void;
  toggleFullscreenAction?: () => void;
}

const DrawingCanvas = (props: Props) => {
  const {
    saveAnnotation,
    format,
    annotationToggled,
    drawnSvg,
    playedSeconds,
    fullscreen,
    toggleFullscreenAction,
    toggleDrawingBar
  } = props;

  const [brushConfig, setBrushConfig] =
    useState<CustomizedBrushConfig>(defaultBrushConfig);
  // reset brush when exit edit mode;
  useEffect(() => {
    setBrushConfig(defaultBrushConfig);
  }, [annotationToggled, setBrushConfig]);

  // hooks
  const [drawing, setDrawing] = useState(false);

  const stroke = useRef<Stroke>(null);

  //// region: initialize skatchpad
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement>(null);
  const [sketchPad, setSketchPad] = useState<SketchPad>(null);
  useEffect(() => {
    if (canvasElement) {
      setSketchPad(new SketchPad(canvasElement, { values: 'relative' }));
    }
  }, [canvasElement]);
  /// endof region

  // if jump to different play time, should reset canvas
  useEffect(() => {
    if (!sketchPad) return;
    sketchPad.clear();
    sketchPad.reset();
  }, [playedSeconds, sketchPad]);

  const onMouseDown = useCallback(() => {
    setDrawing(true);
    const brush = sketchPad?.newBrush(brushConfig);
    stroke.current = brush && sketchPad?.newStroke(brush);
  }, [sketchPad, brushConfig]);

  const onMouseUp = useCallback(
    (e) => {
      if (drawing) {
        const event = normalizeEvent(e, canvasElement);

        const brush = sketchPad?.newBrush(brushConfig);
        if (stroke?.current?.length === 0) {
          // Mouse didn't move, so it was a click. Add one point and draw it.
          const p = sketchPad.newPointFromEvent(event, true);

          sketchPad.addPointToStroke(
            stroke.current,
            [event.offsetX, event.offsetY],
            true
          );
          sketchPad.drawStroke(stroke.current, brush, false);
        } else if (brushConfig.drawArrow) {
          drawArrow(sketchPad, stroke, brush, format);
        } else {
          sketchPad.drawStroke(stroke.current, brush, false);
        }

        saveAnnotation(sketchPad.toSVG());
        setDrawing(false);
      }
    },
    [brushConfig, drawing, saveAnnotation, sketchPad, canvasElement, format]
  );

  const onMouseMove = useCallback(
    (e) => {
      if (drawing) {
        const event = normalizeEvent(e, canvasElement);
        const p = sketchPad.newPointFromEvent(event, true);
        const brush = sketchPad?.newBrush(brushConfig);
        sketchPad.addPointToStroke(stroke.current, p, true);
        sketchPad.drawStroke(stroke.current, brush, false);
      }
    },
    [brushConfig, drawing, sketchPad, stroke, canvasElement]
  );

  // add the eventListeners to the window, so we can track strokes that go outside the canvas
  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mouseleave', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mouseleave', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseUp, onMouseMove]);

  // should display drawn/cached image
  const init = useRef(true);
  useEffect(() => {
    const brush = sketchPad?.newBrush();
    if (init.current && brush && sketchPad) {
      if (drawnSvg) {
        sketchPad.fromSVG(drawnSvg);
      }
      sketchPad.draw();
      init.current = false;
    }
  }, [sketchPad, drawnSvg, annotationToggled]);

  // handle resize
  useLayoutEffect(() => {
    if (!sketchPad || !format) return;
    // sketchPad.canvas.style.visibility = 'hidden';
    const f = setTimeout(() => {
      sketchPad.resize(format.width, format.height);
      // sketchPad.canvas.style.visibility = 'visible';
    }, 50);
  }, [format, sketchPad]);

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <canvas
          id={'drawing_canvas'}
          ref={setCanvasElement}
          onMouseDown={onMouseDown}
          style={{
            width: `${format?.width}px`,
            height: format && format.height + 'px'
          }}
          width={format && format.width}
          height={format && format.height}
        />
      </div>
      {/* <DrawingToolbar /> */}
      {/** hide DrawingToolbar when drawing*/}
      {drawing ? null : (
        <DrawingCanvasContext.Provider value={{ sketchPad }}>
          <DrawingToolbar
            brushConfig={brushConfig}
            setBrushConfig={setBrushConfig}
            onSave={saveAnnotation}
            closeDrawingBar={() => toggleDrawingBar(false)}
          />
        </DrawingCanvasContext.Provider>
      )}
      {/** in fullscreen view: add fullscreen toggle to the bottom right side */}
      <div style={{ position: 'absolute', bottom: 20, right: 24 }}>
        {fullscreen && toggleFullscreenAction && (
          <FullscreenButton
            fullscreen={true}
            toggleFullscreenAction={toggleFullscreenAction}
          />
        )}
      </div>
    </>
  );
};

export default DrawingCanvas;

// we want to track events outside the canvas, so we need
// to listen on the window, then map window coords to canvas local coords
const normalizeEvent = (
  e: MouseEvent | React.MouseEvent,
  canvas: HTMLCanvasElement
) => {
  const event = (e as React.MouseEvent).nativeEvent || e;
  const bb = canvas.getBoundingClientRect();
  return {
    timeStamp: event.timeStamp,
    offsetX: event.clientX - bb.x,
    offsetY: event.clientY - bb.y,
    target: canvas
  };
};
