import React from 'react';

/**
 * @summary : Component that wraps Modal Component
 * @description : Provides info to sub-tree of components the info about modals current index
 *                and boolean type info about whether this component renders in the activeModal.
 */

export interface ModalContextValue {
  isActiveModal: boolean;
  currentModalndex: number;
};

export const ModalContext = React.createContext<ModalContextValue>({
  isActiveModal: false,
  currentModalndex: null,
});

export const useModalInfo  = () => React.useContext(ModalContext);

interface Properties {
  children: JSX.Element;
  isActiveModal: boolean;
  currentModalndex: number;
}

export const ModalContextProvider = ({children, isActiveModal, currentModalndex}: Properties) => {

  return <ModalContext.Provider value={{ isActiveModal, currentModalndex }}>
        {children}
    </ModalContext.Provider>

};

export default ModalContextProvider;