import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

/* Import components */
import { ModalNoticeUI } from './ModalNoticeUI';
import Button from '../Buttons/Button';
import { Typography } from '../typography/Typography';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      minHeight: 200
    },
    deleteButton: {
      marginLeft: 10
    }
  })
);

interface DeletePromptUIProps {
  amountOfFiles: number;
  onClickDelete: () => void;
  onClickClose: () => void;
}

const DeletePromptUI: React.ComponentType<DeletePromptUIProps> = (props) => {
  const { amountOfFiles, onClickClose, onClickDelete } = props;

  const classes = useStyles();

  useGlobalSubmit(onClickDelete, false);

  return (
    <ModalNoticeUI
      onCloseEvent={() => onClickClose()}
      title={'Delete?'}
      footerRightComponent={
        <>
          <Button
            onClick={() => onClickClose()}
            colorVariant={'ghost2'}
            text="Cancel"
          />

          <Button
            onClick={onClickDelete}
            colorVariant={'filledDanger'}
            text="Delete"
            extraButtonProperties={{
              'data-cy': 'deletepromptdelete',
              classes: { root: classes.deleteButton }
            }}
          />
        </>
      }
      extraModalProps={{
        open: true,
        classes: { root: classes.modal },
        children: null
      }}
    >
      <Typography color="contrast2">
        People will no longer have access{' '}
        {amountOfFiles > 1 ? 'to these items' : 'to this item'}.
      </Typography>
    </ModalNoticeUI>
  );
};

export default DeletePromptUI;
