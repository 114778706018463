import React, { useEffect, useLayoutEffect, useState } from 'react';
import { SketchPad } from './modules/sketchPad/sketchpad';
import { Annotation, Comment } from '@cube3/state/types';

// import { useCanvasBoundingStyles } from '../hooks/useCanvasBoundingStyles';

interface Props {
  annotation?: Comment;
  format?: {
    width: number;
    height: number;
  };
}
const Annotation = (props: Props) => {
  const { annotation, format } = props;
  // hooks
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement>(null);
  const [sketchPad, setSketchPad] = useState<SketchPad>(null);

  useEffect(() => {
    if (canvasElement) {
      setSketchPad(
        new SketchPad(canvasElement, { values: 'relative', cursor: 'default' })
      );
    }
  }, [canvasElement]);

  const brush = sketchPad?.newBrush();

  //   const canvasStyles = useCanvasBoundingStyles(canvasElement);

  useLayoutEffect(() => {
    // TODO: extend if statement to check if value is an SVG rather than undefined or not...
    if (sketchPad && brush && format) {
      if (annotation?.drawing_svg) {
        sketchPad.fromSVG(annotation.drawing_svg);
      }
      // sketchPad.canvas.style.visibility = 'hidden';
      const f = setTimeout(() => {
        sketchPad.draw();
        // sketchPad.canvas.style.visibility = 'visible';
      }, 50);
      return () => clearTimeout(f);
    }
  }, [sketchPad, annotation, format]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <canvas
        ref={setCanvasElement}
        style={{
          width: `${format?.width}px`,
          height: format && format.height + 'px'
        }}
        width={format && format.width}
        height={format && format.height}
      />
    </div>
  );
};

export default Annotation;
