import * as React from 'react';
/* UI */
import { ModalNoticeUI } from './ModalNoticeUI';
import Button from '../Buttons/Button';
import { Typography } from '../typography/Typography';

interface Properties {
  onClickClose(): void;
}

const ExampleModalC: React.FunctionComponent<Properties> = props => {
  const { onClickClose } = props;

  return (
    <ModalNoticeUI
      title={'Example Modal'}
      footerRightComponent={
        <Button
          text={'Done'}
          colorVariant={'filled1'}
          onClick={() => onClickClose()}
        />
      }
    >
      <Typography>This is an example modal.</Typography>
    </ModalNoticeUI>
  );
}

/** Example Modal UI */
export const ExampleModalUI = ExampleModalC;