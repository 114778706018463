import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      margin: `${theme.customSpacing.margin[24]} 0px`,
      // borderBottom: `1px solid ${theme.customPalette.primary.lineWhiteLowEmphasis}`,
      padding: '10px 0px 10px 30px'
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }
  }));

interface LinkSettingsContentProps {
  renderLeftColumn?: () => React.ReactNode
  containerStyles?: string;
}

const LinkSettingsContent: React.FC<React.PropsWithChildren<LinkSettingsContentProps>> = props => {
  const { renderLeftColumn, containerStyles } = props;

  const classes = useStyles();

  return (
    <div className={[classes.container, containerStyles].join(' ')}>
      {renderLeftColumn && renderLeftColumn()}
      <div className={classes.innerContainer}>{props.children}</div>
    </div>
  );
};

export default LinkSettingsContent;
