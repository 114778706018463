import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

// ui
import { ModalMenuUI } from '../../../Modal/ModalMenuUI';
import { iconAdd } from '../../../icons/GeneralSVG';
import { Divider } from '../../../Surface/Divider';
import InteractableSurface from '../../../Surface/InteractableSurface';
import Button from '../../../Buttons/Cube3Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    sharedLinkContainer: {
      padding: '8px 16px'
    },
    newLinkButton: {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset'
      }
    },
    newLinkContainer: {
      display: 'flex',
      padding: '8px 16px',
      marginTop: 20,
      marginBottom: 20,
      alignItems: 'center'
    }
  }));

interface Properties {
  loading?: boolean;
  onClickNewFileRequest?: () => void;
  onCloseEvent: () => void;
}

const FileRequestOverviewModalUIC: React.FC<React.PropsWithChildren<Properties>> = props => {
  const {
    onClickNewFileRequest,
    onCloseEvent,
    loading = false
  } = props;

  const classes = useStyles();

  return (
    <ModalMenuUI
      title={'File Requests'}
      onCloseEvent={() => onCloseEvent()}
      loading={loading}
      footerRightComponent={
        <>
          <Button
            text={'Done'}
            colorVariant={'filled1'}
            onClick={() => onCloseEvent()}
          />
        </>
      }
    >
      <InteractableSurface
        classes={{ root: classes.newLinkContainer }}
        onClickEvent={() => onClickNewFileRequest()}
        highlightOnHover={true}
        surfaceProps={{
          background: 'transparent',
          corners: 'rounded'
        }}
      >
        <Button
          text="New file request"
          pathLeftSvg={iconAdd}
          colorVariant="ghost1"
          disableHover={true}
          data-cy="newLinkButton"
          className={classes.newLinkButton}
          marginCollapse={'left'}
        />
      </InteractableSurface>

      <Divider />

      {props.children}
    </ModalMenuUI>
  );
};

/** Draws a `Modal` with an overview of filerequests, and the ability to move to make new filerequest   */
const FileRequestOverviewModalUI = FileRequestOverviewModalUIC;
export { FileRequestOverviewModalUI };
