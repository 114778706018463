import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useClassName } from '../../utils/useClassName';
import { Typography } from '../../typography/Typography';
import { TagsTooltip } from '../../tags/TagList/TagsTooltip';
import { Avatar } from '../../../../cubicle/src/core/atoms/Avatar/Avatar';
import { SizeVariants } from '../../../../cubicle/src/theme/themes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    listItem: {
      display: 'inline-block'
    },
    'size-sm': {},
    'size-md': {},
    'size-lg': {},
    lastBite: {},
    bite: {
      marginLeft: 0,
      '&$size-lg': {
        clipPath: `path('M0,0 L49,0 A24,24,0,0,0,50,45 L0,600')`,
        marginRight: -10
      },
      '&$size-md': {
        // clipPath: `path('M0,0 L60,1 A12,12,0,0,0,30,30 L6,600')`,
        clipPath: `path('M0,0 L60,0 A12,12,0,0,0,30,32 L6,600')`,
        marginRight: -8
      },
      '&$size-sm': {
        clipPath: `path('M0,0 L24,-1 A12,14,0,0,0,22,24 L0,600')`,
        marginRight: -6
      },
      '&$lastBite': {
        marginRight: 0,
        clipPath: 'none'
      }
    },
    'font-lg': {
      fontSize: '24px',
      lineHeight: '43px'
    },
    'font-md': {
      fontSize: '18px',
      lineHeight: '30px'
    },
    'font-sm': {
      fontSize: '12px',
      lineHeight: '22px' // exclude border
    },
    counter: {
      display: 'inline-block',
      borderRadius: '50%',
      border: '1px solid rgba(255,255,255,0.6)',
      textAlign: 'center'
    },
    'counter-lg': {
      minWidth: '43px', // exclude border
      height: '43px'
    },
    'counter-md': {
      minWidth: '30px',
      height: '30px'
    },
    'counter-sm': {
      minWidth: '22px',
      height: '22px'
    }
  })
);

interface AvatarListProps {
  users: any[];
  maxCount?: number;
  bite?: boolean;
  size?: 'lg' | 'md' | 'sm';
  disableTooltip?: boolean;
  counterStyles?: string;
  withPlus?: boolean;
  onClickUser?: (event?) => void;
  onClickCounter?: () => void;
}

const OverflowAvatarList = ({ users }) => {
  return users.map((user) => (
    <div
      key={user.id}
      style={{
        display: 'flex',
        flexFlow: 'column nowrap'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0px' }}>
        <Avatar
          tooltip={user.full_name}
          size={SizeVariants.sm}
          userInitial={user.full_name}
          imageUrl={user.profile_picture}
        />
        <div style={{ width: 8 }}></div>
        <Typography color="contrast1" typographyStyle="body3">
          {user.full_name}
        </Typography>
      </div>
    </div>
  ));
};

const noop = () => null;
export const AvatarList: React.FC<AvatarListProps> = (props) => {
  const {
    users,
    maxCount = 8,
    size = 'sm',
    bite = true,
    disableTooltip = false,
    counterStyles,
    withPlus = true,
    onClickCounter = noop,
    onClickUser = noop
  } = props;
  const classes = useStyles();
  const [counterRef, setCounterRef] = useState(null);

  const list = useMemo(() => {
    return {
      toShow: users?.slice(0, maxCount),
      toHide: users?.slice(maxCount)
    };
  }, [users, maxCount]);

  const overflowCount = users?.length - maxCount;

  const getListItemClasses = useCallback(
    (lastBite) => {
      return [
        classes.listItem,
        classes[`size-${size}`],
        bite && classes.bite,
        lastBite && classes.lastBite
      ].join(' ');
    },
    [bite, classes, size]
  );

  const counterClassName = useClassName(
    classes.counter,
    classes[`counter-${size}`],
    counterStyles || ''
  );

  return (
    list.toShow.length > 0 && (
      <div className={classes.root}>
        {list.toShow.map((user, index) => (
          <span
            key={user.id}
            onClick={onClickUser}
            className={getListItemClasses(
              index === list.toShow.length - 1 && overflowCount <= 0
            )}
          >
            <Avatar
              tooltip={user.full_name}
              size={SizeVariants[size]}
              userInitial={user.full_name[0]}
              imageUrl={user.profile_picture}
            />
          </span>
        ))}
        {overflowCount > 0 && (
          <>
            <span
              className={counterClassName}
              ref={setCounterRef}
              onClick={onClickCounter}
            >
              <Typography color="contrast1" className={classes[`font-${size}`]}>
                {withPlus ? '+' : ''}
                {overflowCount}
              </Typography>
            </span>
            {!disableTooltip ? (
              <TagsTooltip
                trigger={counterRef}
                disabled={overflowCount <= 0}
                delay={200}
                side={'left'}
              >
                <OverflowAvatarList users={list.toHide} />
              </TagsTooltip>
            ) : null}
          </>
        )}
      </div>
    )
  );
};
