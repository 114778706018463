import React from 'react';
import { Activity } from '@cube3/common/model/schema/resources/activities';
import { ActivityItem } from './ActivityItem';
import { Typography } from '@cube3/ui/src/typography/Typography';

interface Properties {
  title: string;
  activities: Activity[];
  onClickLink(): void;
}

/** Renders a group with a `title` and `ProjectActivityItem` when an array with a length greater than 0 is given */
export const ActivityGroup: React.FunctionComponent<Properties> = (props) => {
  const { title, activities, onClickLink } = props;

  return activities.length > 0 ? (
    <>
      <Typography typographyStyle={'heading4'}> {title} </Typography>

      {activities.map((item) => {
        return (
          <ActivityItem
            key={item.id}
            onClickLink={onClickLink}
            projectActivity={item}
          />
        );
      })}
    </>
  ) : null;
};
