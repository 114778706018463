import * as React from 'react';

// interfaces
import {
  Feature,
  Reviewlink,
  VerifyType
} from '@cube3/common/model/resource-types';
import { ModalReceiverProps } from '../../Modals/ModalManager';

import { reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
('@cube3/state/src/Hoocs/Routing/withOpenInternalLink');

// helpers
import { compose } from '../../../../../utils/component-helpers';
import { dateNormalizer } from '@cube3/ui/src/helpers/FieldNormalizers';
import moment from 'moment';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';

// ui
import LinkSettingsForm, {
  LinkSettingsFormProperties
} from '@cube3/ui/src/Prefabs/shareLink/Forms/LinkSettingsForm';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { useLinkSettingsActions } from '../../FileRequest/hooks/useLinkSettingsActions';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ShareIntentType } from '@cube3/state/types';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';
import LinkSettingsUI from '@cube3/ui/src/Prefabs/shareLink/Modals/LinkSettings/LinkSettingsUI';
import { useCurrentUser } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';

/// <Summary>
/// Functional component that retrieves the review link for a resource (asset etc) and displays them
/// in a settings screen with options
/// </Summary>

const mapStateToProps = (state, ownProps: Properties) => {
  const values = getFormValues(ownProps.modalContext.id)(state);
  const reviewLink: Reviewlink = ownProps.reviewLink;

  // map the values of the retrieved review link to the form values to prefill the form.
  const initialFormValues: LinkSettingsFormProperties = {
    verifyUser:
      reviewLink.verify_user || reviewLink.verify !== VerifyType.never,
    verifyType:
      reviewLink.verify !== VerifyType.never
        ? reviewLink.verify
        : VerifyType.recipients_only,
    allowRequestAccess: reviewLink.allow_request_access,
    expiryDate: reviewLink?.expires
      ? moment(reviewLink.expires_at).endOf('day').toISOString()
      : moment()
          .add(
            ownProps.workspace
              ? ownProps.workspace?.default_shares_expiration_duration
              : 30,
            'day'
          )
          .toISOString(),
    expires: reviewLink?.expires
  };

  return {
    form: ownProps.modalContext.id,
    initialValues: {
      ...initialFormValues
    },
    ...values
  };
};

export interface ReviewLinkSettingFormProps {
  expires?: boolean;
  expiryDate?: string;
  verifyUser?: boolean;
  verifyType: VerifyType;
  allowRequestAccess: boolean;
}
interface HOCMapped {
  workspace: Workspace;
  reviewLink: Reviewlink;
  mutateResource: Function;
  mutateStatus: string;
}

type Properties = HOCMapped &
  ModalReceiverProps<{ id: string; intent: ShareIntentType }> &
  ReviewLinkSettingFormProps &
  InjectedFormProps;

const REQUIRED_FEATURES = {
  features: ['/WORKSPACE/SHARING/MAGIC_LINK'] as Feature[]
};

export const ReviewLinkSettingsModalC: React.FunctionComponent<Properties> = (
  props
) => {
  const {
    reviewLink,
    verifyUser,
    verifyType,
    allowRequestAccess,
    expires,
    expiryDate,
    dirty,
    reset,
    mutateResource,
    mutateStatus
  } = props;

  const { id, intent = 'review-legacy' } = props.modalContext;

  const [hasVerifyUserFeature] = useFeatures(REQUIRED_FEATURES);

  const { onCloseEvent, ...linkSettingActions } = useLinkSettingsActions({
    id,
    intent,
    token: reviewLink?.token
  });

  const onClickSubmit = React.useCallback(() => {
    mutateResource({
      type: 'share',
      id,
      verify: !verifyUser ? VerifyType.never : verifyType,
      allow_request_access: allowRequestAccess,
      expires,
      expires_at: expires ? dateNormalizer(expiryDate) : undefined,
      // Have to send it because the API is currently not able to distinguish between attributes that are not set and attributes that are empty
      email_addresses: reviewLink?.email_addresses
    });
  }, [
    id,
    verifyUser,
    verifyType,
    allowRequestAccess,
    expires,
    expiryDate,
    reviewLink,
    dateNormalizer
  ]);

  React.useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      onCloseEvent();
    }
  }, [mutateStatus, onCloseEvent]);

  const currentUser = useCurrentUser()[1];
  const showLink =
    reviewLink?.email_addresses &&
    currentUser &&
    reviewLink.email_addresses.includes(currentUser.email_address);

  return (
    <LinkSettingsUI
      dirty={dirty}
      onClickSaveChanges={onClickSubmit}
      onCloseEvent={onCloseEvent}
      {...linkSettingActions}
      onReset={reset}
      email_addresses={reviewLink?.email_addresses}
      createdDate={reviewLink?.created_at}
      showLink={showLink}
      linkSettingsForm={
        <LinkSettingsForm
          expiryDate={expiryDate}
          expires={expires}
          verifyUser={verifyUser}
          verifyType={verifyType}
          allowRequestAccess={allowRequestAccess}
          hasVerifyUserFeature={hasVerifyUserFeature}
        />
      }
    />
  );
};

const withConnectedReviewlink = (Wrapped) => (props) => {
  const id = props.modalContext.id;
  const reviewLink = useResource__ALPHA({
    resourceId: id,
    resourceType: 'share'
  });

  const [workspaceId, workspace] = useCurrentWorkspace();

  const [mutateResource, mutateStatus] = useMutateResource__ALPHA(
    React.useMemo(
      () => ({
        cacheInvalidator: (r) => [
          r,
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'shares'
          }
        ],
        actionDecorators: [
          addFormId(id, {
            formState: undefined,
            useRequestStatus: true
          })
        ]
      }),
      [workspaceId]
    )
  );

  const loading = !workspace || !reviewLink.resource || reviewLink.loading;

  return loading ? (
    <ModalMenuUI loading={true} />
  ) : (
    <Wrapped
      {...props}
      mutateResource={mutateResource}
      mutateStatus={mutateStatus}
      workspace={workspace}
      reviewLink={reviewLink.resource}
    />
  );
};

const ReviewLinkSettingsModal = compose(ReviewLinkSettingsModalC)(
  withConnectedReviewlink,
  connect(mapStateToProps),
  reduxForm<LinkSettingsFormProperties, Properties>({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })
);

export { ReviewLinkSettingsModal };
