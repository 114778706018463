import React, { useState, useRef } from 'react';
import { createStyles, makeStyles, Popover } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu/Menu';
import MenuItem from '../Buttons/MenuItem';
import { useSurfaceClasses } from '../Surface/Surface';
import { ButtonTypes } from '../themes/custom-theme';
import { useClassName } from '../utils/useClassName';
import { PopoverMenuClassType } from './PopOverMenu/PopoverMenu';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    popper: {
      pointerEvents: 'none'
    },
    paper: {
      pointerEvents: 'auto',
      width: 'max-content'
    },
    menuButtonStyling: {
      color: theme.customPalette.primary.iconWhiteMediumEmphasis,
      alignSelf: 'center'
    }
  })
);

interface Properties {
  text: string;
  leftSvgPath?: string;
  menuIconPath?: string;
  extraMenuProps?: MenuProps;
  variant?: ButtonTypes;
  childrenRenderProps: (closeFn: Function, isOpen: boolean) => JSX.Element;
  classProps?: PopoverMenuClassType;
}

/** Renders an activator button `MenuItem` and its children through a prop. The children are given a closeFn in order to manually trigger a menu close event.  */
export const Submenu: React.FunctionComponent<Properties> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLButtonElement>(undefined);
  const classes = useStyles(props);

  // get CSS styles for floating surface.
  const surfaceStyles = useSurfaceClasses({
    floating: true,
    corners: 'rounded',
    gradient: 'lamp',
    background: 'base2',
    cornerRadius: 'lowEmphasis',
    floatingHeight: 24
  });

  const handleMenuClose = (): void => {
    setOpen(false);
  };

  const {
    text,
    childrenRenderProps,
    leftSvgPath,
    variant = 'navMenuItem',
    classProps
  } = props;

  const submenuWidth = 225;

  const availableSpace =
    window.innerWidth - anchorEl.current?.getBoundingClientRect().right;
  const shouldRenderSubmenuOnLeft = submenuWidth > availableSpace;

  return (
    <div
      className={useClassName(classes.root, classProps?.root)}
      onMouseLeave={() => setOpen(false)}
      onMouseOver={() => setOpen(true)}
    >
      {/* Activator */}
      <MenuItem
        className={useClassName(
          classes.menuButtonStyling,
          classProps?.menuButton
        )}
        variant={variant}
        text={text}
        onClick={() => handleMenuClose()}
        path={leftSvgPath}
        hasSubItems={true}
        extraButtonProps={{
          ref: anchorEl
        }}
        fullWidth={true}
      />

      {/* Contents */}
      <Popover
        className={classes.popper}
        id="simple-popper"
        classes={{
          paper: `${surfaceStyles} ${classes.paper} ${classProps?.paper}`
        }}
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleMenuClose}
        // To align submenu right from open button
        anchorOrigin={{
          vertical: 'top',
          horizontal: shouldRenderSubmenuOnLeft ? 'left' : 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: shouldRenderSubmenuOnLeft ? 'right' : 'left'
        }}
      >
        {/* Pass the close function so we can acces the close function when implementing the component. */}
        {childrenRenderProps(handleMenuClose, open)}
      </Popover>
    </div>
  );
};
