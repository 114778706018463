import React, { useState } from 'react';

import { ChangeWorkspaceRoleModalUI } from '@cube3/ui/src/Workspace/Modals/ChangeWorkspaceRoleModalUI';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import { useWorkspaceLimits } from '../../AdminCenter/views/WorkspaceMembersView/modals/hooks/useWorkspaceLimits';

import { SeatSuggestions } from '../../AdminCenter/views/WorkspaceMembersView/modals/WorkspaceLimitsModal';
import { Padding } from '../../Contracts/prefabs/Padding';
import { Account } from '@cube3/common/model/schema/resources/account';
import { Role } from '@cube3/common/model/schema/resources/role';
import { User } from '@cube3/common/model/schema/resources/user';

interface Props {
  modalContext: {
    account: Account;
    user: User;
    currentRole: Role;
    roles: Role[];
    isCurrentAccount: boolean;
  };
}

const ChangeWorkspaceRoleModal: React.FunctionComponent<Props> = (props) => {
  const { currentRole, roles, account, isCurrentAccount, user } =
    props.modalContext;

  const modalActions = useModalActions();

  const [newRole, setNewRole] = useState(currentRole);

  const { billableRoleSelected, workspaceLimitsError } = useWorkspaceLimits(
    roles,
    newRole,
    1
  );

  const conflict = billableRoleSelected && !currentRole.billable;

  return (
    <ChangeWorkspaceRoleModalUI
      newRole={newRole}
      onRoleChange={(roleName) =>
        setNewRole(roles.find((r) => r.display_name === roleName))
      }
      onRoleConfirm={(role) =>
        modalActions.openModal('change_role_confirmation', {
          selectedRole: role.display_name,
          account,
          user
        })
      }
      onCloseEvent={modalActions.previousModal}
      currentRole={currentRole}
      roles={roles}
      userName={user.full_name}
      isCurrentAccount={isCurrentAccount}
      errorMessage={conflict && workspaceLimitsError}
    >
      {conflict && workspaceLimitsError && (
        <Padding>
          <SeatSuggestions />
        </Padding>
      )}
    </ChangeWorkspaceRoleModalUI>
  );
};

export default ChangeWorkspaceRoleModal;
