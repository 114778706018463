import { usePrivilege } from '@cube3/state/src/redux/components/Hooks/usePrivilege';
import { projectRoles } from '@cube3/state/src/redux/components/Hooks/useProjectMembershipsList';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

const requestedPrivs = ['PROJECT_WRITE'];

export const useAddMemberToAllProjects = (
  workspaceRoleId: string,
  projectRoleId?: string
) => {
  const dispatch = useDispatch();

  const [canWriteProject] = usePrivilege({
    roleId: workspaceRoleId,
    requestedPrivs
  });

  const availableProjectRoles = useMemo(() => {
    return canWriteProject
      ? projectRoles
      : projectRoles.filter(r => r.display_name !== 'Admin');
  }, [canWriteProject]);

  const handleChangeProjectRole = useCallback(
    handler => {
      const role = availableProjectRoles[0].display_name;
      if (
        availableProjectRoles.length === 1 &&
        projectRoleId &&
        projectRoleId !== role
      ) {
        dispatch(change('inviteMember', 'addToProjectsRole', role));
      }
      return handler;
    },
    [availableProjectRoles, projectRoleId, dispatch]
  );

  return {
    canWriteProject,
    availableProjectRoles,
    handleChangeProjectRole
  };
};
