import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  destroy,
  getFormSyncErrors,
  hasSubmitSucceeded,
  isSubmitting,
  isValid,
  submit
} from 'redux-form';
import { generateBroadcastFormId } from './useTVBroadcastActions';

export const useBroadcastMetadataForms = assets => {
  const dispatch = useDispatch();

  const forms = useMemo(() => {
    return assets.map(asset => ({
      ...asset,
      form: generateBroadcastFormId(asset.id)
    }));
  }, [assets]);

  const allFormsValid = useSelector(state => {
    return forms.reduce(
      (acc, form) => {
        const valid = isValid(form.form)(state);

        return {
          ...acc,
          validForms: valid ? [...acc.validForms, form.id] : acc.validForms,
          allValid: !valid ? false : acc.allValid
        };
      },
      { validForms: [], allValid: true }
    );
  }) as { validForms: string[]; allValid: boolean };

  const formSubmitting = useSelector(state => {
    return forms.reduce(
      (acc, form) => {
        const submitting = isSubmitting(form.form)(state);

        return {
          ...acc,
          [form.id]: submitting,
          anySubmitting: acc.anySubmitting ? true : submitting
        };
      },
      { anySubmitting: false }
    );
  });

  const {
    allSucceeded: allFormsSucceeded,
    anySucceeded: anyFormsSucceeded
  } = useSelector(state => {
    return forms.reduce(
      (acc, form) => {
        const success = hasSubmitSucceeded(form.form)(state);

        return {
          ...acc,
          [form.id]: success,
          anySucceeded: acc.anySucceeded ? true : success,
          allSucceeded: !success ? false : acc.allSucceeded
        };
      },
      { allSucceeded: true, anySucceeded: false }
    );
  });

  const allFormErrors = useSelector(state => {
    return forms.reduce(
      (acc, form) => {
        const errors = getFormSyncErrors(form.form)(state);
        return {
          ...acc,
          errors: { ...acc.errors, [form.id]: errors },
          hasErrors: Object.keys(errors).length ? true : acc.hasErrors
        };
      },
      { hasErrors: false, errors: {} }
    );
  });

  /** submit all forms except the first export, because it has been created seperately */
  const submitAllForms = useCallback(() => {
    forms.forEach(asset => {
      if (allFormsValid.allValid) {

        dispatch(submit(asset.form));
      }
    });
  }, [forms, allFormsValid.allValid, dispatch]);

  /** Maps over the retrieved forms and dispatches a destroy event per form */
  const destroyAllForms = useCallback(() => {
    forms.forEach(form => {
      requestAnimationFrame(() => {
        dispatch(destroy(form.form));
      });
    });
  }, [dispatch, forms]);

  return {
    allFormsValid,
    submitAllForms,
    allFormsSucceeded: allFormsSucceeded && anyFormsSucceeded,
    formSubmitting: formSubmitting.anySubmitting,
    destroyAllForms,
    allFormErrors
  };
};
