import * as Sentry from '@sentry/browser';

function requestInterceptor(req) {
  return req;
}
function requestErrorInterceptor(error) {
  return Promise.reject(error);
}
function responseInterceptor(res) {
  return res;
}
function responseErrorInterceptor(error) {
  if (error.response?.status >= 500) {
    Sentry.withScope(function (scope) {
      scope.setLevel('error');

      scope.setContext('character', {
        requestURL: error.config.url,
        requestMethod: error.config.method,
        responseStatus: error.response?.status
        //   responseData: error.response.data,
        //   requestData: !JSON.stringify(error.config.data).match(
        //     /(:?password|token|email|username)/i
        //   )
        //     ? error.config.data
        //     : { redacted: 'REDACTED' }
      });

      // The exception has the event level set by the scope (info).
      Sentry.captureException(new Error('c2-gateway API error'));
    });
  }

  if (error.isAxiosError) {
    return error;
  }
  return Promise.reject(error);
}

export const sentryInterceptor = {
  requestInterceptor,
  requestErrorInterceptor,
  responseInterceptor,
  responseErrorInterceptor
};
