import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import ModalHeader from './ModalHeader';
import { ModalFooterUI } from './ModalFooter';
import { Modal } from '@material-ui/core';
import { ModalWithHeaderFooterProperties } from './types';
import ScrollBar from '../Scrollbar/Scrollbar';
import Surface from '../Surface/Surface';
import { ModalCookieContext } from './ModalCookieContext';
import { isMobile } from 'react-device-detect';
import ModalMobileSurfaceUI from './ModalMobileSurfaceUI';
import { useClassName } from '../utils/useClassName';

import { CircularProgress } from '../Progress/CircularProgress';
import { useModalContainer } from './ModalManagerUI';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    modalScrim: {
      backgroundColor: 'rgba(0, 0, 0, 0.8) !important'
    },
    cookieHeight: {
      height: 'calc(100vh - 174px)'
    },
    centered: {
      outline: 'none',
      margin: 'auto'
    },
    wide: {},
    extraWide: {},
    surface: {
      display: 'flex',
      flexDirection: 'column',
      width: '640px', // this is different
      maxWidth: '100vw',
      '&$wide': {
        width: '940px' // this is different
      },
      '&$extraWide': {
        width: '90vw',
        maxWidth: '1280px'
      }
    },

    // The default value for margins is allMargins
    // provide a string property defineMargins to set other class for margins
    allMargins: {
      margin: '24px 32px'
    },
    noMargins: {
      margin: 0
    },
    sideMargins: {
      margin: '0 32px'
    },

    contents: (props: Properties) => ({
      // use the multiplier given in props or default to 5
      height:
        (props.multiplyContentSpace
          ? props.multiplyContentSpace * 64
          : (props.extraWide ? 10 : props.wide ? 8 : 5) * 64) + 'px',
      // 64px both on bottom and on top - 40 headerHeight - 64 footerHeight - 64 some stuff scrolbar takes???
      maxHeight: 'calc(100vh - 296px)',
      position: 'relative'
    }),
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      width: 'auto',
      background: theme.customPalette.backgroundAccent.blackAccent3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

interface Properties extends ModalWithHeaderFooterProperties {
  /** Dictates on how many times the content should be multiplied by 64px,
   * so the modal has a predefined height. Defaults to 5 */
  multiplyContentSpace?: number;
  // because we use an arrow function for CSS contents,
  // we mess up withstyles, so we will use a manual classes object
  classes?: {
    root?: string;
    centered?: string;
    surface?: string;
    wide?: string;
    contents?: string;
    loadingOverlay?: string;
    modalScrim?: string;
    cookieHeight?: string;
    extraWide?: string;
  };
  wide?: boolean;
  extraWide?: boolean;
  noScroll?: boolean;
}

const ModalMenu: React.FC<React.PropsWithChildren<Properties>> = (props) => {
  const {
    title,
    footerLeftComponent,
    footerRightComponent,
    onCloseEvent = undefined,
    // in notice and menu defaults to false.
    showCloseButton = false,
    extraComponentProps,
    extraModalProps,
    loading = false,
    component: Component = 'div',
    extraSurfaceProperties,
    topBarComponent,
    wide,
    extraWide,
    noScroll = false,
    defineMargins = 'allMargins'
  } = props;

  // use context provider to check if we should resize the modal if the cookie banner is active.
  const modalCookieContext = useContext(ModalCookieContext);

  const classes = useStyles(props);

  const surfaceClasses = useClassName(
    classes.surface,
    wide && classes.wide,
    extraWide && classes.extraWide
  );
  const container = useModalContainer();

  if (!container) {
    return null;
  }

  return (
    <Modal
      container={container}
      open={true}
      className={`${classes.root} ${
        modalCookieContext.hasConsented === false ? classes.cookieHeight : ''
      }`}
      onClose={onCloseEvent ? () => onCloseEvent() : undefined}
      BackdropProps={{ className: classes.modalScrim }}
      {...extraModalProps}
    >
      <Component
        className={classes.centered}
        data-type={'librarydeselectoverride'}
        {...extraComponentProps}
      >
        {isMobile ? (
          <ModalMobileSurfaceUI
            extraSurfaceProperties={extraSurfaceProperties}
            onCloseEvent={onCloseEvent}
            showCloseButton={showCloseButton}
            footerLeftComponent={footerLeftComponent}
            footerRightComponent={footerRightComponent}
            loading={loading}
            title={title}
          >
            {props.children}
          </ModalMobileSurfaceUI>
        ) : (
          <Surface
            className={surfaceClasses}
            background={'base2'}
            corners={'rounded'}
            floating={true}
            gradient={'lamp'}
            floatingHeight={24}
            {...extraSurfaceProperties} // override surface properties from parent.
          >
            <ModalHeader
              onClickClose={onCloseEvent ? () => onCloseEvent() : undefined}
              showCloseButton={showCloseButton}
              title={title}
              topBarComponent={topBarComponent}
            />

            {noScroll ? (
              <div className={`${classes.contents} ${classes[defineMargins]}`}>
                {props.children}
              </div>
            ) : (
              <ScrollBar autoHeight={true} autoHeightMax={3000}>
                <div
                  className={`${classes.contents} ${classes[defineMargins]}`}
                >
                  {props.children}
                </div>
              </ScrollBar>
            )}

            {(footerLeftComponent || footerRightComponent) && (
              <ModalFooterUI
                footerLeftComponent={footerLeftComponent}
                footerRightComponent={footerRightComponent}
              />
            )}

            {/* Show circular progress, blocking interaction when in loading state */}
            {loading && (
              <div className={classes.loadingOverlay}>
                <CircularProgress />
              </div>
            )}
          </Surface>
        )}
      </Component>
    </Modal>
  );
};

/**
 * @summary This variant is has a fixed height, in multiplications of 64px, which you can pass via props,
 * @author Simon, Michiel, Machiel, Lorenzo
 */
export const ModalMenuUI = ModalMenu;
