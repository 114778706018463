import { useResource__ALPHA, UseResourceResponse } from './useResource';
import { PROJECT_ROLE_TYPES } from '@cube3/common/model/resource-types';

import { useResourceList__ALPHA } from './useResourceList';
import { useMemo } from 'react';
import { statuses } from '../../../redux/ducks/request-status';
import { Role } from '@cube3/common/model/schema/resources/role';

type MaybeRole = Partial<UseResourceResponse<Role>>;

export const useRole = ({ accountId, workspaceId }): MaybeRole => {
  const account = useResource__ALPHA({
    resourceId: accountId,
    resourceType: 'account'
  });

  const accountWorkspaces = useResourceList__ALPHA({
    resourceType: 'account',
    resourceId: accountId,
    edgeType: 'workspace',
    edgeLabel: 'workspaces'
  });

  const role = useResource__ALPHA({
    resourceId: account.resource?.relationships?.role?.id,
    resourceType: 'role'
  });

  const accountHasWorkspace = accountWorkspaces.resources?.find((ws) => {
    return ws?.id === workspaceId;
  });

  const workspaceAccessPending =
    workspaceId && accountWorkspaces.status !== statuses.SUCCESS;
  const rolePending = accountId && role.status !== statuses.SUCCESS;

  if (workspaceAccessPending || rolePending) {
    return {
      loading: true,
      status: statuses.IN_FLIGHT
    };
  }

  if (workspaceId && !accountHasWorkspace) {
    return { loading: false, status: statuses.FAILED };
  }

  return role;
};

export const useProjectRole = ({ accountId, projectId, hasTeamFeature }) => {
  const {
    resources: members,
    status,
    configValid
  } = useResourceList__ALPHA({
    resourceType: 'project',
    resourceId: projectId,
    edgeType: 'project-member',
    edgeLabel: 'members'
  });

  const projectMembersLoading = projectId
    ? status !== statuses.SUCCESS
    : undefined;

  const [adminInTeamsCount, membersInTeams, projectTeamRoleloading] =
    useProjectTeamRole({
      accountId,
      projectId
    });

  const projectRole = useMemo(() => {
    const role = members?.filter((m) => {
      if (!m.relationships || !m.relationships.account) {
        console.warn('malformed membership: no relationship to account');
        return undefined;
      }
      return m.relationships.account.id === accountId;
    })[0];

    const project_member_role = hasTeamFeature
      ? adminInTeamsCount
        ? PROJECT_ROLE_TYPES.Admin
        : !role && membersInTeams && membersInTeams.length
        ? PROJECT_ROLE_TYPES.Member
        : role?.project_member_role
      : role?.project_member_role;
    return {
      status: status,
      loading: projectMembersLoading || projectTeamRoleloading,
      resource: {
        ...role,
        project_member_role
      },
      configValid: configValid
    };
  }, [
    accountId,
    adminInTeamsCount,
    configValid,
    hasTeamFeature,
    projectMembersLoading,
    projectTeamRoleloading,
    members,
    membersInTeams,
    status
  ]);

  return projectRole;
};

/**
 * @returns [adminInTeamsCount,membersInTeams] as [number, string[]]
 * - 0: Number of teams that have an admin role in this project + the current account is a member of
 * - 1: List of team ids that are members of this project + the current account is in
 */
export const useProjectTeamRole = ({
  accountId,
  projectId
}): [number, string[], boolean] => {
  const params = useMemo(() => {
    return {
      filter: { project: projectId }
    };
  }, [projectId]);

  const {
    resources: projectTeamMemberships,
    status: projectTeamMembershipsStatus
  } = useResourceList__ALPHA({
    edgeType: projectId ? 'project-team-membership' : undefined,
    edgeLabel: 'project-team-memberships',
    params
  });

  /** The teams that this account is a member of */
  const { resources: accountTeams, status: accountTeamsStatus } =
    useResourceList__ALPHA({
      resourceType: 'account',
      resourceId: accountId,
      edgeType: 'team',
      edgeLabel: 'teams'
    });

  const loading =
    projectTeamMembershipsStatus !== statuses.SUCCESS ||
    accountTeamsStatus !== statuses.SUCCESS;

  /** The teams that are members of the visiting project */
  const { admins: projectTeamsAsAdmins, all } = useMemo(() => {
    const admins: string[] = [];
    let all: string[] = [];

    if (projectTeamMemberships && projectTeamMemberships.length) {
      all = projectTeamMemberships.map((res) => {
        const teamId =
          res.relationships.team?.id || res.relationships.team?.data.id;
        if (res.project_member_role === PROJECT_ROLE_TYPES.Admin) {
          admins.push(teamId);
        }
        return teamId;
      });
    }
    return { admins, all };
  }, [projectTeamMemberships]);

  return useMemo(() => {
    if (!projectId) {
      return [undefined, undefined, undefined];
    }
    /** check if the current account is an admin in this project via teams */
    const adminInTeamsCount =
      accountTeams?.length &&
      projectTeamsAsAdmins.length &&
      accountTeams.filter((team) => projectTeamsAsAdmins.includes(team.id))
        ?.length;

    /** An array of team names that added to this project and this account is a member of */
    const membersInTeams =
      all.length &&
      accountTeams?.length &&
      accountTeams
        .filter((team) => all.includes(team.id))
        ?.map((team) => team.display_name);
    return [adminInTeamsCount, membersInTeams, loading];
  }, [accountTeams, all, projectTeamsAsAdmins, loading, projectId]);
};
