import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { Typography, TypographyProps } from '../typography/Typography';
import Radio, { RadioProps } from '@material-ui/core/Radio/Radio';
import GeneralSVG, { iconRadioBorder, iconRadioDot } from '../icons/GeneralSVG';
import { colors } from './ButtonBase';
import MaybeTooltip from '../Tooltip/MaybeTooltip';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// Checkbox with optional text property. Due to the need of being able to click the text aswell,
/// this version is stateless, which means it only sends the next state when clicked but does not
/// toggle when clicked (must be done from a controlling component)
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    enabledClass: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    labelRoot: {
      display: 'inline-flex',
      alignItems: 'center',
      alignContent: 'center'
    },
    radioRoot: {
      width: theme.sizing[32],
      height: theme.sizing[32],
      padding: 0,
      color: theme.customPalette.icon.onBase.contrast2,
      '&:hover': {
        backgroundColor: 'inherit'
      }
    },
    checkedCheckboxContainer: {
      width: '20px',
      height: '20px',
      position: 'relative'
    },
    checkIcon: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%,0)',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      width: '20px',
      height: '20px'
    },
    typographyBox: {
      alignSelf: 'center',
      marginLeft: theme.customSpacing.margin[8],
      userSelect: 'none' // prevent highlighting of text
    },
    typographyBoxDisabled: {
      alignSelf: 'center',
      marginLeft: theme.customSpacing.margin[8],
      userSelect: 'none', // prevent highlighting of text
      opacity: '0.4'
    }
  })
);

interface Properties {
  checked: boolean;
  text?: string;
  disabled?: boolean;
  disabledReason?: string;
  radioColor?: colors;
  onChange?: (checked: boolean) => void;
  extraRadioButtonProperties?: RadioProps;
  typographyProps?: TypographyProps;
  classes?: { root: string };
  className?: string;
}

function CustomRadioButton(props: Properties) {
  const {
    text,
    disabled = false,
    extraRadioButtonProperties,
    typographyProps,
    checked,
    onChange,
    className,
    disabledReason
  } = props;
  const classes = useStyles(props);
  const {
    radioRoot,
    labelRoot,
    enabledClass,
    checkedCheckboxContainer,
    typographyBox,
    typographyBoxDisabled,
    checkIcon
  } = classes;

  return (
    <label onChange={() => onChange(!checked)}>
      <MaybeTooltip
        disabled={!disabled}
        title={disabledReason}
        placement="right"
        className={`${labelRoot} ${className} ${!disabled ? enabledClass : ''}`}
      >
        <>
          <Radio
            classes={{ root: `${radioRoot}` }}
            disabled={disabled}
            color={'default'}
            checked={checked}
            disableRipple={true}
            {...extraRadioButtonProperties}
            icon={
              <GeneralSVG
                path={iconRadioBorder}
                svgProps={{ viewBox: '0 0 32 32' }}
                color={disabled ? 'disabled' : 'contrast2'}
                surface="onBase"
              />
            }
            checkedIcon={
              <span className={checkedCheckboxContainer}>
                <GeneralSVG
                  size={'regular'}
                  path={iconRadioBorder}
                  color={disabled ? 'disabled' : 'brand1'}
                  surface="onBase"
                  svgProps={{ viewBox: '0 0 32 32' }}
                  className={checkIcon}
                />
                <GeneralSVG
                  size={'regular'}
                  path={iconRadioDot}
                  color={disabled ? 'disabled' : 'brand1'}
                  surface="onBase"
                  svgProps={{ viewBox: '0 0 32 32' }}
                  className={checkIcon}
                />
              </span>
            }
          />

          {text && (
            <Typography
              color="contrast1"
              className={disabled ? typographyBoxDisabled : typographyBox}
              {...typographyProps}
            >
              {text}
            </Typography>
          )}
        </>
      </MaybeTooltip>
    </label>
  );
}

export default CustomRadioButton;
