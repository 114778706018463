import React, { useCallback } from 'react';

import { Tag } from '@cube3/common/model/schema/resources/tag';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { useEffect } from 'react';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import Cube3TextField from '@cube3/ui/src/forms/textfields/Cube3TextField';
import { TagsInputColorpicker } from '../prefabs/TagsInputColorpicker';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useMemo } from 'react';
import { normalizeTagsInput } from '../prefabs/TagsInput';
import { TagsInputCategoryPicker } from '../prefabs/TagsInputCategoryPicker';
import { useTagCategories } from '../../TagManagement/hooks/useTagCategories';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';

interface Props {
  modalContext: {
    tag: EditableTag;
    onSave?(changes: EditableTag): void;
    excluded?: Partial<Tag>[];
  };
}

export const EditTagModal: React.FunctionComponent<Props> = (props) => {
  const { modalContext } = props;
  const { tag, onSave, excluded } = modalContext;

  const { previousModal } = useModalActions();

  const [workspaceId] = useCurrentWorkspace();

  const allTags = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'tag',
    edgeLabel: 'tags',
    strategy: 'fetch-on-mount',
    params: {
      page: {
        size: -1
      }
    }
  });

  const [text, setText] = useState(tag.text);
  const [color, setColor] = useState(tag.color);
  /** selected category name */
  const [category, setCategory] = useState<{
    id?: string;
    display_name: string;
  }>(tag.category);

  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    cacheInvalidator: (r) => [r]
  });

  const { categories } = useTagCategories();

  /** editable categories */
  const items = useMemo(() => {
    return categories
      ?.filter((ct) => ct.editable)
      .map((c) => ({
        id: c.id,
        display_name: c.display_name
      }));
  }, [categories]);

  const onSaveClick = useCallback(() => {
    if (onSave) {
      onSave({ text, color, category } as any);
    }
    mutate({
      type: 'tag',
      id: tag.id,
      text,
      color,
      relationships: {
        category: {
          data: {
            type: 'tag-category',
            id: category.id
          }
        }
      }
    });
    previousModal();
  }, [text, color, onSave, mutate, previousModal, tag]);

  const onSelectCategory = useCallback(
    (val: string) => setCategory(items.find((i) => i.display_name === val)),
    [items, setCategory]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  const valid = useMemo(() => {
    if (
      !text || // required
      (text !== tag.text &&
        allTags.resources.find(
          (t) =>
            t.text === text && t.relationships.category.id === tag.category.id
        )) || // tag already exists on server
      (excluded && text !== tag.text && excluded.find((t) => t.text === text)) // tag already exists in set of tags to create
    ) {
      return false;
    } else {
      return true;
    }
  }, [tag, text, allTags.resources, excluded]);

  const changes =
    text !== tag.text ||
    !(color === tag.color || (color === 'ghost' && !tag.color)) ||
    category !== tag.category;

  const error = !valid
    ? !text
      ? 'Label cannot be empty'
      : 'Label already exists'
    : undefined;

  const showError =
    !!error &&
    mutateStatus !== statuses.SUCCESS &&
    mutateStatus !== statuses.IN_FLIGHT;

  return (
    <ModalNoticeUI
      title="Modify tag"
      loading={
        allTags.status !== statuses.SUCCESS ||
        mutateStatus === statuses.IN_FLIGHT
      }
      footerRightComponent={
        <>
          {changes ? (
            <Button colorVariant="ghost1" onClick={previousModal}>
              Cancel
            </Button>
          ) : null}
          <Button
            colorVariant="filled1"
            disabled={!valid}
            onClick={changes ? onSaveClick : previousModal}
          >
            {changes ? 'Save' : 'Done'}
          </Button>
        </>
      }
    >
      <div
        style={{
          display: 'grid',
          justifyContent: 'start',
          alignItems: 'start',
          gridTemplateColumns: '1fr 52px auto',
          gridTemplateRows: '1fr',
          gap: 8,
          marginBottom: 8
        }}
      >
        <Cube3TextField
          error={showError}
          helperText={(showError && error) || ' '}
          placeholder="Search or create tags"
          fullWidth={true}
          autoFocus={true}
          value={text}
          onChange={(ev) => setText(normalizeTagsInput(ev.target.value))}
        />
        <div style={{ marginTop: 10 }}>
          <TagsInputColorpicker value={color} onChange={setColor} />
        </div>
        <div style={{ marginTop: 10 }}>
          <TagsInputCategoryPicker
            value={category.display_name}
            items={items}
            onChange={onSelectCategory}
          />
        </div>
      </div>

      <Typography>
        Changing the label or color of the tag will change this for all items it
        is applied to.
      </Typography>
    </ModalNoticeUI>
  );
};
