const SET_REVIEW_CHANGES = 'cube3-client/review-changes/SET_REVIEW_CHANGES';
const CLEAR_REVIEW_CHANGES = 'cube3-client/review-changes/CLEAR_REVIEW_CHANGES';

export const setReviewChanges = (data) => ({
  type: SET_REVIEW_CHANGES,
  payload: data
});
export const clearReviewChanges = () => ({
  type: CLEAR_REVIEW_CHANGES
});
const initialState = [];

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REVIEW_CHANGES:
      return [...state, payload];
    case CLEAR_REVIEW_CHANGES:
      return [];
    default:
      return state;
  }
};

export default reducer;
