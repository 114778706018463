import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export interface Tag extends PublicResource {
  color: string;
  text: string;
  relationships: {
    nodes: Related<'content-tree-node'>[];
    workspace: Related<'workspace'>;
    category: Related<'tag-category'>;
  };
}

export const tag: RawModel = {
  type: 'tag',
  plural: 'tags',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID',
    text: 'string',
    color: 'string,'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'tags'
    },
    nodes: {
      type: 'content-tree-node',
      binding: ['many', 'many'],
      reverse: 'tags'
    },
    category: {
      type: 'tag-category',
      binding: ['one', 'many'],
      reverse: 'tags'
    }
  }
};
