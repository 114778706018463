import { PublicResource } from '../../resource-types';
import { RawModel } from '../schema';
import { MetadataFormField } from './metadata-category';

export interface ContractKeyGroup extends PublicResource {
  keys: MetadataFormField[];
}

export const contractKeyGroup: RawModel = {
  type: 'contract-key-group',
  plural: 'contract-key-groups',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
