import { makeSelector } from '@taskworld.com/rereselect';
import { Modal } from './state';

export const getActiveModal = state => {
  const modalStack = state.modals.blocked ? state.modals.snapshot : state.modals.modalStack;

  if (modalStack) {
    return !!modalStack.length && modalStack[modalStack.length - 1];
  }
};

const emptyArray = [];

export const getVisibleModalStack = makeSelector<Modal[]>(query => {
  const stack = query(state => {
    const modalStack = state.modals.blocked ? state.modals.snapshot : state.modals.modalStack;
    return modalStack || emptyArray;
  });

  const index = query(state => {
    const modalStack = state.modals.blocked ? state.modals.snapshot : state.modals.modalStack;
    const lastSolo = [...modalStack].reverse().find(val => val.hideBelow);
    return modalStack.indexOf(lastSolo);
  });

  return index > -1 ? stack.slice(index) : stack;
});

export const selectors = {
  getActiveModal,
  getVisibleModalStack
};
