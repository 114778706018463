import React, { useMemo } from 'react';
// Main
import { compose } from '../../../../../../../utils/component-helpers';
import WorkspaceMembersRowSmart from '../../../../../../app/layout/AdminCenter/views/WorkspaceMembersView/prefabs/WorkspaceMembersRowSmart';
// State
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';
import { withResource } from '@cube3/state/src/redux/components/withResource';
import {
  ModalActionProps,
  useModalActions
} from '../../../../Modals/modalActions';
import { Account } from '@cube3/common/model/schema/resources/account';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';
import { Invitation } from '@cube3/common/model/schema/resources/invitation';
// UI
import WorkspaceTabs from '@cube3/ui/src/Workspace/WorkspaceTabs';
import { WorkspaceMemberRowShimmer } from '@cube3/ui/src/Workspace/WorkSpaceMemberRow';
import WorkspacePendingInvitesRow from '@cube3/ui/src/Workspace/WorkspacePendingInvitesRow';
import { expiredCheck } from '@cube3/common/utils/expiredCheck';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';

import WorkspaceMembersLayoutUI from '@cube3/ui/src/Workspace/WorkspaceMembersLayoutUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useEditTeamsCheck } from '../../WorkspaceTeamsView/hooks/useEditTeamsCheck';

interface WorkspaceMembersLayoutProps extends ModalActionProps {
  modalContext: string;
  accounts: Account[];
  invitations: Invitation[];
  workspace: Workspace;
  activeModal?: any;
  workspaceId: string;
  currentTab: number;
}

function WorkspaceMembersLayoutBase(props: WorkspaceMembersLayoutProps) {
  const { accounts, workspace, currentTab = 0 } = props;

  const { openModal } = useModalActions();

  const [canInvite, canChangeRole] = useWorkspacePermissions([
    'USER_WRITE',
    'USER_WRITE'
  ]);
  const [hasTeamFeature] = useEditTeamsCheck();

  const {
    resources: invitations
    // status: invitationStatus
  } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspace?.id,
    edgeType: 'invitation',
    edgeLabel: 'invitations',
    strategy: 'fetch-on-mount'
  });

  const invites = useMemo(
    () =>
      invitations
        ?.filter((invitation) => !expiredCheck(invitation.expires_at))
        .filter((invitation) => !invitation.accepted_at),
    [invitations]
  );

  const [currentAccountId] = useCurrentAccount(true);

  const sortedAccounts = useMemo(() => {
    return accounts
      ? [
          accounts.find((a) => a.id === currentAccountId),
          ...accounts.filter((a) => a.id !== currentAccountId)
        ].filter(Boolean)
      : undefined;
  }, [accounts, currentAccountId]);

  return (
    <WorkspaceTabs
      currentTab={currentTab}
      disableInvite={!canInvite}
      handleOpenInvitePeople={(currentTab) =>
        openModal('workspace_inviteMember', {
          workspace,
          currentTab,
          accounts,
          hasTeamFeature
        })
      }
      workspaceMembers={
        sortedAccounts ? (
          sortedAccounts.map((account) => (
            <WorkspaceMembersRowSmart
              id={account.id}
              key={account.id}
              workspace={workspace}
              disableEdit={!canChangeRole}
              roleId={
                account.relationships.role.data
                  ? account.relationships.role.data.id
                  : account.relationships.role.id
              }
              userId={
                // some accounts have the id directly on user
                // for now added this check but this should be one shape
                account.relationships.user.data
                  ? account.relationships.user.data.id
                  : account.relationships.user.id
              }
            />
          ))
        ) : (
          <>
            <WorkspaceMemberRowShimmer />
            <WorkspaceMemberRowShimmer />
            <WorkspaceMemberRowShimmer />
          </>
        )
      }
      workspacePendingInvites={
        invites?.length > 0 ? (
          invites.map((invitation) => (
            <WorkspacePendingInvitesRow
              key={invitation.id}
              fullName={invitation.email}
              emailAddress={invitation.email}
              displayImage={''}
              invitationDate={invitation.created_at}
              disableEdit={!canInvite}
              revokeInvitation={() =>
                openModal('revoke_invitation', { id: invitation.id })
              }
            />
          ))
        ) : invites === undefined ? (
          <>
            <WorkspaceMemberRowShimmer />
            <WorkspaceMemberRowShimmer />
            <WorkspaceMemberRowShimmer />
          </>
        ) : (
          <Typography color="contrast2">No pending invites</Typography>
        )
      }
    />
  );
}

export const WorkspaceMembersLayout = compose(WorkspaceMembersLayoutBase)(
  withResource({
    resourceType: 'workspace',
    resourceId: (props: WorkspaceMembersLayoutProps) => props.workspaceId,
    mapper: 'workspace'
  }),
  withResourceEdges({
    resourceType: 'workspace',
    resourceId: (props: WorkspaceMembersLayoutProps) => props.workspaceId,
    edgeType: 'account',
    edgeLabel: 'accounts',
    mapper: 'accounts',
    strategy: 'prefer-cache'
  })
);

export const WorkspaceMembersModal = (props) => {
  const { openModal, activeModal, closeAllModals } = useModalActions();

  const currentTab = activeModal.resource?.currentTab || 0;

  return (
    <WorkspaceMembersLayoutUI
      openModal={openModal}
      closeAllModals={() => {
        closeAllModals();
      }}
    >
      <WorkspaceMembersLayout
        {...props}
        currentTab={currentTab}
        workspaceId={props.modalContext.workspaceId}
      />
    </WorkspaceMembersLayoutUI>
  );
};

export default WorkspaceMembersModal;
