/**
 * @description A custom hook used calculate if annotation resource should be visible or not
 * depending on where the user is on the timeline of the video asset containing the annotation
 */

import { Comment } from '@cube3/state/types';

/**
 * NOTE:
 * This is fine for a simple proof of concept, but it wont scale well, because hooks cannot be called conditionally. So let's say we have a list of annotations, we can't use an Array.map, to apply the hook on every annotation.
 * At that point, we'd want a hook that takes the entire list as an argument, and returns the annotation to show for a specific timestamp
 */

const isTimeString = (ts: string) => {
  const regex = /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/g;

  return ts.match(regex) ? true : false;
};

const timestringToSeconds = (timestring: string) => {
  if (!timestring || !isTimeString(timestring)) return undefined;
  const hms = timestring.split(':');

  // hh:mm:ss
  if (hms.length === 3) {
    return +hms[0] * 3600 + +hms[1] * 60 + +hms[2];
  }

  // mm:ss
  if (hms.length === 2) {
    return +hms[0] * 60 + +hms[1];
  }

  // ss
  if (hms.length === 1) {
    return +hms[0];
  }
};

export const showAnnotation = (
  annotation: Comment,
  currentTime: number,
  range: number
) => {
  if (!annotation) return false;
  const timeRange = {
    start: annotation?.start - range,
    end: annotation?.start + range
  };

  return currentTime >= timeRange.start && timeRange.end > currentTime;
};
