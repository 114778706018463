import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { Feature } from '@cube3/common/model/resource-types';

const privileges = ['GROUP_READ', 'GROUP_WRITE'] as Privileges[];
const requestedFeatures = {
  features: ['/WORKSPACE/TEAMS'] as Feature[]
};

export const useEditTeamsCheck = () => {
  const [canEditTeam] = useWorkspacePermissions(privileges);
  const [hasTeamFeature] = useFeatures(requestedFeatures);

  return [hasTeamFeature, canEditTeam];
};
