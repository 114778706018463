import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useModalActions } from '../../Modals/modalActions';

export const RemoveProjectTeamModal = (props) => {
  const { team, projectTeamId } = props.modalContext;
  const { previousModal, closeAllModals } = useModalActions();

  const routerHistory = useHistory();

  const [workspaceId] = useCurrentWorkspace();

  const [deleteProjectTeam, deleteProjectTeamStatus] = useDeleteResource__ALPHA(
    {
      cacheInvalidator: useCallback(
        (r) => [
          r,
          { type: 'workspace', id: workspaceId, relationship: 'projects' },
          {
            type: undefined,
            id: undefined,
            relationship: 'project-team-memberships'
          }
        ],
        [workspaceId, team]
      )
    }
  );

  const onRemoveProjectTeam = useCallback(() => {
    deleteProjectTeam({
      type: 'project-team-membership',
      id: projectTeamId
    });
  }, [deleteProjectTeam, projectTeamId]);

  useEffect(() => {
    if (deleteProjectTeamStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [
    closeAllModals,
    deleteProjectTeamStatus,
    previousModal,
    routerHistory,
    workspaceId
  ]);

  const loading =
    deleteProjectTeamStatus &&
    deleteProjectTeamStatus !== statuses.SUCCESS &&
    deleteProjectTeamStatus !== statuses.FAILED;

  return (
    <ModalNoticeUI
      title="Remove member?"
      loading={loading}
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={() => previousModal()}
          />
          <Button
            disabled={loading}
            text={'Remove'}
            colorVariant="filledDanger"
            onClick={onRemoveProjectTeam}
          />
        </>
      }
    >
      <Typography color="contrast1" typographyStyle="body2">
        Are you sure you want to remove team{' '}
        <strong>{team?.display_name}</strong>?
      </Typography>
      <div style={{ height: 10 }}></div>
      <Typography>
        People in this team will no longer have access to this project or be
        able to modify its contents.
      </Typography>
    </ModalNoticeUI>
  );
};
