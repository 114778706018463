import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ReceivedProps } from '../prefabs/TVadExportQualityCheckListItem';

const params = {
  sort: '-created_at',
  filter: {
    distinct: 'type'
  }
};
const defaultStatus = {
  success: [],
  failed: [],
  inProgress: [],
  errors: [],
  warnings: []
};

/** check validation status for every asset */
export const useTVBroadcastQualityCheck = ({
  asset,
  validators
}: ReceivedProps) => {
  const {
    /** @validationResources: the most recent validation per validator */
    resources: validationResources,
    status: validationStatus,
    retrieve
  } = useResourceList__ALPHA({
    resourceType: 'asset',
    resourceId: asset?.id,
    edgeType: 'validation',
    edgeLabel: 'validations',
    params
  });

  /** For retry flow: to store the previous failed validation id,
   * - used to force poll if retries should be made but have not started yet
   * - reason: after the retry is complete, if it still fails and returns server_error, `failedIds` will be the retried validation id, and then they will be filtered out in line `127`, this will again result in polling. So we want to use a ref to make sure that the one that fails is always the previous one that failed.
   */
  const memorizedFailedIdsRef = useRef<string[]>(null);

  const { shouldRetry, prevFailedValidatorIds, failedIds } = useMemo(() => {
    const failed = validationResources?.filter(
      v => v.attributes.status === 'server_error'
    );
    return {
      /** if any validation returns `server_error` status */
      shouldRetry: !!failed?.length,
      prevFailedValidatorIds: failed?.map(f => f.relationships.validator.id),
      failedIds: failed?.map(f => f.id)
    };
  }, [validationResources]);

  const [createValidation, createValidationStatus] = useCreateResource__ALPHA({
    resourceType: 'validation',
    cacheInvalidator: useCallback(
      r => [r, { type: 'asset', id: asset?.id, relationship: 'validation' }],
      [asset]
    )
  });

  // if still loading validationResources, should not update memorizedFailedIdsRef,
  // otherwise it will be an empty array and cause polling to stop

  if (
    validationStatus === statuses.SUCCESS &&
    memorizedFailedIdsRef.current === null &&
    failedIds
  ) {
    memorizedFailedIdsRef.current = failedIds;
  }

  /** create a new validation for each required validator
   * - if there’s no existed validation for the required validators,
   * - or should retry if validation returns with a `server_error` state
   */
  useEffect(() => {
    if (
      validationStatus === statuses.SUCCESS &&
      (!validationResources?.length || shouldRetry) &&
      !createValidationStatus &&
      validators?.length &&
      asset
    ) {
      /** retry the failed one only */
      const validatorsToMap = prevFailedValidatorIds?.length
        ? prevFailedValidatorIds.map(id => ({ id }))
        : validators;

      validatorsToMap
        .map(v => ({ type: 'validator', id: v.id }))
        .forEach(validator => {
          createValidation({
            type: 'validation',
            relationships: {
              asset: { data: { type: 'asset', id: asset.id } },
              validator: {
                data: validator
              }
            }
          });
        });
    }
  }, [
    asset,
    createValidation,
    createValidationStatus,
    validationResources,
    validators,
    validationStatus,
    prevFailedValidatorIds,
    shouldRetry
  ]);

  const validation = useMemo(() => {
    /** Goal: in retry flow, should not show old/existed errors before it starts to retry.
     * - case 1: still retrieving validationResource,
     * - case 2: before retrying, the `validationResources` still contains the old failed validation with status `server_error`, they should be filtered out so that `validation.allFailed` becomes false and continue polling,
     * - case 3: no retry happens
     */
    const resources = !memorizedFailedIdsRef.current // case 1
      ? undefined
      : !!memorizedFailedIdsRef.current.length // case 2
      ? validationResources?.filter(
          v => !memorizedFailedIdsRef.current.includes(v.id)
        )
      : validationResources; // case 3

    /** Checking.... */
    if (!resources) {
      return {
        ...defaultStatus,
        allFailed: false,
        allPassed: false,
        allDone: false,
        someFailed: false,
        failedToStart: false
      };
    }

    let anyServerError = false;
    const mapped = resources.reduce((acc, val) => {
      const errMessages =
        val && val.attributes.errors
          ? val.attributes.errors.map(err => err.description)
          : [];
      const warningMessages =
        val && val.attributes.warnings
          ? val.attributes.warnings.map(war => war.description)
          : [];

      const server_error = val.attributes.status === 'server_error';

      // server error, should retry validation next time
      if (server_error) {
        anyServerError = true;
      }

      return {
        success:
          (val.attributes.status === 'done' || val.attributes.finished_at) &&
          !errMessages.length &&
          !server_error
            ? [...acc.success, val]
            : acc.success,
        failed:
          val.attributes.status === 'failed' ||
          server_error ||
          !!errMessages.length
            ? [...acc.failed, val]
            : acc.failed,

        inProgress:
          !val.attributes.finished_at && !errMessages.length && !server_error
            ? [...acc.inProgress, val]
            : acc.inProgress,

        errors: errMessages.length
          ? [...acc.errors, ...errMessages]
          : acc.errors,

        warnings: warningMessages.length
          ? [...acc.warnings, ...warningMessages]
          : acc.warnings
      };
    }, defaultStatus);

    return {
      ...mapped,
      allDone:
        mapped.success.length + mapped.failed.length ===
          validationResources.length && !mapped.inProgress.length,
      allPassed:
        mapped.success.length &&
        mapped.success.length === validationResources.length,
      allFailed:
        mapped.failed.length &&
        mapped.failed.length === validationResources.length,
      someFailed: !!mapped.failed.length,
      failedToStart:
        (mapped.failed.length && !mapped.errors.length) || anyServerError
    };
  }, [validationResources]);

  /** poll validation untill it's finished */
  useEffect(() => {
    if (
      !validationResources?.length ||
      validation?.inProgress.length ||
      (!validation.allFailed && !validation.allPassed)
    ) {
      const timer = setTimeout(() => {
        retrieve();
      }, 3000);
      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [
    createValidationStatus,
    retrieve,
    shouldRetry,
    validation,
    validationResources
  ]);

  return validation;
};
