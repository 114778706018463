// NOTE: had to hardcode namespace typescript doesn't like dynamics
const ESSENTIAL = `cube3/cookie-consent/cookie-types/ESSENTIAL`;
const FUNCTIONAL = `cube3/cookie-consent/cookie-types/FUNCTIONAL`;
const ANALYTICS = `cube3/cookie-consent/cookie-types/ANALYTICS`;
// const MARKETING = `cube3/cookie-consent/cookie-types/MARKETING`;

export const cookieTypesShort = {
  ESSENTIAL,
  FUNCTIONAL,
  ANALYTICS
};

export const cookieTypes = {
  [ESSENTIAL]: ESSENTIAL,
  [FUNCTIONAL]: FUNCTIONAL,
  [ANALYTICS]: ANALYTICS
  //[MARKETING]: MARKETING // not implemented (yet)
} as const;

export const setableCookieTypes = {
  [FUNCTIONAL]: FUNCTIONAL,
  [ANALYTICS]: ANALYTICS
  //[MARKETING]: MARKETING // not implemented (yet)
} as const;

export type CookieCategory = keyof typeof cookieTypes;

const REQUIRED = `cube3/cookie-consent/state/REQUIRED`;
const CONSENTED = `cube3/cookie-consent/state/CONSENTED`;
const REJECTED = `cube3/cookie-consent/state/REJECTED`;
const UNSET = `cube3/cookie-consent/state/UNSET`;

export const consentOptions = {
  REQUIRED,
  CONSENTED,
  REJECTED,
  UNSET
} as const;

export type ConsentOption =
  | typeof CONSENTED
  | typeof REJECTED
  | typeof UNSET
  | typeof REQUIRED;

export type ConsentVersion = [number, number, number];

export interface ConsentState {
  state: ConsentOption;
  version?: ConsentVersion;
  timestamp?: Date;
}

export interface SetableCookieConsentState {
  [FUNCTIONAL]: ConsentState;
  [ANALYTICS]: ConsentState;
  // [MARKETING]: ConsentState;
}

export interface CookieConsentState extends SetableCookieConsentState {
  [ESSENTIAL]: ConsentState;
  currentVersion: ConsentVersion;
}

export interface CookieConsentSelectors {
  isVersionMismatch?(state: any): boolean;
  hasChosen?(state: any): boolean;
  getConsentState?(state: any): CookieConsentState;
}
