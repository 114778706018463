import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ScrollObserverContext } from '../contexts/scrollObserverContext';

/** NOTE: each list item should have an `id` defined */
export const useCreateIntersectionObserver = (rootRef: HTMLElement) => {
  const [visibleElements, setVisibleElements] = useState<HTMLElement[]>([]);

  const options = useMemo(
    () => ({
      root: rootRef,
      rootMargin: '0px',
      threshold: 0
    }),
    [rootRef]
  );

  const handleIntersect = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleElements((prev) => {
            const alreadyShown = prev.find(
              (t) => t.id && t.id === entry.target.id
            );

            if (!alreadyShown) {
              return [...prev, entry.target];
            }
            return prev;
          });
        }
      });
    },
    [setVisibleElements]
  );

  const [observer, setObserver] = useState<IntersectionObserver>(null);

  useEffect(() => {
    const io = new IntersectionObserver(handleIntersect, options);
    setObserver(io);

    return () => io.disconnect();
  }, [handleIntersect, options]);

  const isVisible = useCallback(
    (el) => {
      return visibleElements.includes(el);
    },
    [visibleElements]
  );

  return useMemo(
    () => ({
      isVisible,
      observer
    }),
    [isVisible, observer]
  );
};

/** Hook for child component to manage whether they get observed */
export const useIntersectionObserver = (target: Element) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { observer, isVisible } = useContext(ScrollObserverContext) || {};

  useEffect(() => {
    if (target && observer) {
      observer.observe(target);
      return () => observer.unobserve(target);
    }
  }, [target, observer]);

  useEffect(() => {
    if (!isVisible) {
      return setVisible(true);
    }
    if (target) {
      setVisible(isVisible(target));
    } else {
      setVisible(false);
    }
  }, [isVisible, target]);

  return visible;
};
