import ChipField from '@cube3/ui/src/chips/Chip';
import AccountSuggestionItem from '@cube3/ui/src/inputChipPicker/accountPicker/AccountSuggestionItem';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { TeamSugggestionItemRow } from '../../AdminCenter/views/WorkspaceTeamsView/prefabs/TeamSuggestionItemRow';
import MultipleResourcePicker, {
  MultipleResourcePickerGeneralProps
} from '../../AdminCenter/views/WorkspaceTeamsView/subComponents/multipleResourcePicker/MultipleResourcePickerBase';

const makeSelectedItem = (suggestion, index, fields, fieldName?) => {
  if (!suggestion) return null;
  switch (suggestion.type) {
    case 'account':
      return (
        <Field
          key={`${index}_${suggestion.email_address}`}
          name={fieldName || suggestion.email_address}
          type="text"
          component={ChipField}
          removeItem={() => {
            fields.remove(index);
          }}
          index={index}
          props={{ input: { value: suggestion.full_name } }}
        />
      );

    case 'team':
      return (
        <Field
          key={`${index}_${suggestion.email_address}`}
          name={fieldName}
          type="text"
          component={ChipField}
          removeItem={() => {
            fields.remove(index);
          }}
          index={index}
          props={{
            input: { value: suggestion.display_name },
            badge: 'Team'
          }}
        />
      );

    default:
      return null;
  }
};
const makeSuggestionItem = suggestion => {
  if (!suggestion) return null;
  switch (suggestion.type) {
    case 'account':
      return (
        <AccountSuggestionItem
          primaryText={suggestion.full_name}
          secondaryText={suggestion.email_address}
          avatarUrl={suggestion.profile_picture}
        />
      );
    case 'team':
      return (
        <TeamSugggestionItemRow
          team={suggestion}
          maxCount={8}
          style={{ marginRight: 8 }}
        />
      );
    default:
      return null;
  }
};
/** make suggestion of teams and accounts in the current worksapce */
const AccountsTeamsMixSuggestionContainer: React.FC<MultipleResourcePickerGeneralProps> = props => {
  const { fields } = props;
  const fieldName = 'accountSearchInput';

  const renderSelectedItem = useCallback(
    (suggestion, index) => {
      return makeSelectedItem(suggestion, index, fields, fieldName);
    },
    [fields]
  );

  const renderSuggestionItem = useCallback(suggestion => {
    return makeSuggestionItem(suggestion);
  }, []);

  return (
    <MultipleResourcePicker
      {...props}
      fieldName={fieldName}
      renderSuggestionItem={renderSuggestionItem}
      renderSelectedItem={renderSelectedItem}
    />
  );
};

export default AccountsTeamsMixSuggestionContainer;
