import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import ImageLoader from '../images/ImageLoader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 50,
      height: 18
      // marginLeft: 10,
      // marginTop: 8
    },
    image: {
      width: 'auto',
      height: '100%',
      '& img': {
        minHeight: '100%'
      }
    }
  }));

// TODO: update peekplaceholder once we have something in place
const WorkspaceLogoUI = ({
  src,
  alt,
  injectedStyles,
  onClick
}: any) => {

  const classes = useStyles();

  return (
    <div className={classes.root} style={injectedStyles} onClick={onClick}>
      <ImageLoader
        className={classes.image}
        src={src}
        alt={alt}
        peekPlaceholder="rgba(125,125,125,0.4)"
        // fallback={`https://placecage.com/c/90/30/`}
      />
    </div>
  );
};

export default WorkspaceLogoUI;
