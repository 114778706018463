import { BrowserStorage } from '../../wrapped-cube-client/uploader/browserStorage';

/**
 *  This middleware allows you to store part of your redux
 *  state to browser localStorage.
 *  The params to the save function describe the part you want
 *  to save, and what you might want to redact.
 */

/** recursively visits the state and excludes keys defined as redactor */
const redact = (node, redactors) => {
  if (node && typeof node === 'object' && !Array.isArray(node)) {
    return Object.keys(node).reduce(
      (acc, key) =>
        redactors.indexOf(key) < 0
          ? { ...acc, [key]: redact(node[key], redactors) }
          : acc,
      {}
    );
  } else {
    return node;
  }
};

interface GenericState {
  [key: string]: any;
}

interface LoadConfig {
  namespace?: string;
  selector?(state: GenericState, loadedState: GenericState): GenericState;
}

/**
 *  State loaders can be used for prepopulating redux start at start.
 *  - Each loader has a namespace that determines which slice to get from browserstorage
 *  - Loaders can be combined
 *  - Loaders can provide a selector function to limit the part of the stored state that is loaded
 *  - Selector function get the namespace state and the current accumulation of state from previous loaders as args
 */
export const load =
  ({ namespace, selector = undefined }: LoadConfig) =>
  (loadedState) => {
    const storage = BrowserStorage.getInstance(namespace).getItem('state');
    const state = storage ? storage : undefined;
    return !selector ? state : selector(state, loadedState);
  };

/**
 *  helper function for merging loaders,
 *  injects accumulated state into next loader
 */
export const mergeLoaders = (loaders) => {
  return loaders.reduce((acc, val) => {
    return { ...acc, ...val(acc) };
  }, {});
};

/**
 * Save handler used by the save middleware
 * This is the part that stores state in browser storage
 * parst of state can be specifically selected (inclusive)
 * or redacted (exclusive)
 * as test function can be provided to determine whether state should be saved at all
 * this test function get the current state and BrowserStorage singleton as arg
 */
const makeHandler = (getState, selectors, redactors, testFn, storage) => () => {
  const newState = getState();
  if (!newState || (testFn && !testFn(newState, storage))) {
    storage.removeItem('state');
  }

  // select state (inclusive)
  const storeState = [].concat(selectors).reduce(
    // eslint-disable-next-line array-callback-return
    (acc, sel, id) => {
      if (typeof sel === 'string') {
        // console.info(`grabbing state ${sel}`, newState[sel]);
        const selectedState = newState[sel];
        return {
          ...acc,
          [sel]: selectedState
        };
      } else if (typeof sel === 'function') {
        return {
          ...acc,
          ...sel(newState)
        };
      }
    },
    {}
  );

  // redact state (exclusive)
  const cleanState = redact(storeState, redactors);
  // check test function
  const testPassed = !testFn || testFn(newState);

  if (cleanState && testPassed) {
    storage.setItem('state', cleanState);
  }
};

type SelectorFN = (state: GenericState) => GenericState;
interface SaveConfig {
  selectors?: Array<string | SelectorFN>;
  redactors?: string[];
  testFn?(state: GenericState, storage: BrowserStorage): boolean;
  namespace?: string;
  throttleDuration?: number;
}

/**
 * save middleware
 * accepts a config object
 */
export const save = ({
  selectors = [],
  redactors = [],
  testFn = undefined,
  namespace = 'save-middleware',
  throttleDuration = 5000
}: SaveConfig = {}) => {
  return ({ getState }) => {
    const throttle = makeThrottler(throttleDuration);
    const storage = BrowserStorage.getInstance(namespace);
    const handler = makeHandler(
      getState,
      selectors,
      redactors,
      testFn,
      storage
    );
    window.addEventListener('beforeunload', handler, { capture: true });

    return (next) => (action) => {
      const returnVal = next(action);
      throttle(handler);
      return returnVal;
    };
  };
};

/** helper function to create a throttler */
const makeThrottler = (interval) => {
  let timeout = null;
  let tick = Date.now();
  let initialSave = 1;

  return (fn) => {
    const duration = initialSave > 0 ? 10 : interval;
    if (initialSave > 0) {
      initialSave -= 1;
    }

    const now = Date.now();
    if (timeout) {
      clearTimeout(timeout);
    }
    const time = Math.max(0, tick - now);
    timeout = setTimeout(() => {
      tick = Date.now() + duration;
      timeout = null;
      fn();
    }, time);
  };
};
