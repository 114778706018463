/**
 *
 * API privileges
 *
 */
const API_PRIVS = [
  'USER_READ',
  'USER_WRITE',
  'GROUP_READ',
  'GROUP_WRITE',
  'LIBRARY_READ',
  'LIBRARY_WRITE',
  'PROJECT_READ',
  'PROJECT_WRITE',
  'METADATA_READ',
  'METADATA_WRITE',
  'APPLY_TAGS',
  'CONTRACT_READ',
  'CONTRACT_WRITE',
  'DOWNLOAD_TYPE_ORIGINAL',
  'DOWNLOAD_TYPE_PREVIEW',
  'EXPORT_BROADCAST',
  'EXPORT_YOUTUBE',
  'EXPORT_META',
  'EXPORT_FACEBOOK',
  'EXPORT_FTP',
  'SHARE_LINK',
  'WORKSPACE_WRITE',
  'WORKSPACE_READ',
  'UNKNOWN'
] as const;

/**
 *
 *  PROJECT
 *
 * */

const PROJECT = [
  'VIEW_PROJECT',
  'EDIT_PROJECT',
  'REMOVE_PROJECT',
  //
  'INVITE_PROJECT_MEMBER',
  'SET_PROJECT_MEMBER',
  'SET_PROJECT_ADMIN',
  'REMOVE_PROJECT_MEMBER',
  //
  'VIEW_PROJECT_FOLDER',
  'ADD_PROJECT_FOLDER',
  'EDIT_PROJECT_FOLDER',
  'REMOVE_PROJECT_FOLDER',
  'SHARE_PROJECT_FOLDER',
  //
  'VIEW_PROJECT_ASSET',
  'ADD_PROJECT_ASSET',
  'EDIT_PROJECT_ASSET',
  'REMOVE_PROJECT_ASSET',
  'SHARE_PROJECT_ASSET',
  //
  'VIEW_PROJECT_ASSET_METADATA',
  'ADD_PROJECT_ASSET_METADATA',
  'EDIT_PROJECT_ASSET_METADATA',
  'DELETE_PROJECT_ASSET_METADATA',
  //
  'VIEW_PROJECT_ASSET_PROPERTIES',
  //
  'DOWNLOAD_PROJECT_ASSET_PREVIEW',
  'DOWNLOAD_PROJECT_ASSET_SOURCE',
  //
  'VIEW_PROJECT_METADATA',
  'ADD_PROJECT_METADATA',
  'EDIT_PROJECT_METADATA',
  'REMOVE_PROJECT_METADATA'
] as const;

const ALL = {
  PROJECT,
  API_PRIVS
};

const DEFAULT = [...API_PRIVS] as const;

const PROJECT_ADMIN = [...PROJECT] as const;

const PROJECT_MEMBER = [
  'VIEW_PROJECT',
  //
  //
  'VIEW_PROJECT_FOLDER',
  'ADD_PROJECT_FOLDER',
  'EDIT_PROJECT_FOLDER',
  'REMOVE_PROJECT_FOLDER',
  'SHARE_PROJECT_FOLDER',
  //
  'VIEW_PROJECT_ASSET',
  'ADD_PROJECT_ASSET',
  'EDIT_PROJECT_ASSET',
  'REMOVE_PROJECT_ASSET',
  'SHARE_PROJECT_ASSET',
  //
  'VIEW_PROJECT_ASSET_METADATA',
  'ADD_PROJECT_ASSET_METADATA',
  'EDIT_PROJECT_ASSET_METADATA',
  'DELETE_PROJECT_ASSET_METADATA',
  //
  'VIEW_PROJECT_ASSET_PROPERTIES',
  //
  'DOWNLOAD_PROJECT_ASSET_PREVIEW',
  'DOWNLOAD_PROJECT_ASSET_SOURCE',
  //
  'VIEW_PROJECT_METADATA',
  'ADD_PROJECT_METADATA',
  'EDIT_PROJECT_METADATA',
  'REMOVE_PROJECT_METADATA'
] as const;

const ROLES = {
  PROJECT_ADMIN,
  PROJECT_MEMBER,
  DEFAULT
};

interface RawPrivileges {
  [key: string]: readonly string[];
}

interface FlatMapped<T extends RawPrivileges, K extends keyof T>
  extends Array<string> {
  [key: number]: T[K][number];
}

type RoleKeys<R> = Array<keyof R>;

class PrivilegesManager<T extends RawPrivileges, R> {
  private raw: T;
  public index;
  private roles: R;

  constructor(rawPrivileges: T, roles: R) {
    this.raw = rawPrivileges;
    this.index = Object.keys(this.raw).reduce((acc, key) => {
      acc[key] = this.raw[key].reduce((a, v) => {
        a[v] = v;
        return a;
      }, {});
      return acc;
    }, {});

    this.roles = roles;
  }

  checkPermission(role: keyof R, privileges) {
    return privileges.map(
      (p) => (this.roles[role] as unknown as Array<string>).indexOf(p) > -1
    );
  }

  getFlat(): FlatMapped<T, keyof T> {
    return Object.keys(this.raw).flatMap((k) => this.raw[k]);
  }

  getRoles(): RoleKeys<R> {
    return Object.keys(this.roles) as RoleKeys<R>;
  }
}

export const privileges = new PrivilegesManager(ALL, ROLES);

const flat = privileges.getFlat();
export type Privileges = (typeof flat)[number];
const roles = privileges.getRoles();
export type Roles = (typeof roles)[number];
export type APIPrivileges = (typeof API_PRIVS)[number];
