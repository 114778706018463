import React, { useState, useRef } from 'react';
import { createStyles, Fade, makeStyles, Popover } from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover/Popover';
import MenuButton from '../../Buttons/MenuButton';
import IconButton, { Cube3IconButtonProps } from '../../Buttons/IconButton';
import { useSurfaceClasses } from '../../Surface/Surface';
import { iconElepsis } from '../../icons/GeneralSVG';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    paper: {
      width: 'max-content' // the width of the menu is sized to its contents.
      // consider using padding here if the
    },
    menuButtonStyling: {
      color: theme.customPalette.primary.iconWhiteMediumEmphasis,
      alignSelf: 'center'
    },
    iconButton: {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  })
);
export interface PopoverMenuClassType {
  root?: string;
  paper?: string;
  iconButton?: string;
  menuButton?: string;
}
interface Properties {
  menuIconPath?: string;
  extraPopOverProps?: PopoverProps;
  buttonText?: string;
  extraButtonProps?: Cube3IconButtonProps;
  childrenRenderProps: (closeFunction: Function, open: boolean) => JSX.Element;
  classProps?: PopoverMenuClassType;
}

/**
* @summary Shows a button that when clicked, shows a menu with items
The items are the children of this component, so you can
map or define those items in your own components.
* @author Simon 
*/
export const PopoverMenu: React.FunctionComponent<Properties> = props => {
  const refContainer = useRef<HTMLElement>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const classes = useStyles(props);

  // get CSS styles for floating surface.
  const surfaceStyles = useSurfaceClasses({
    floating: true,
    corners: 'rounded',
    gradient: 'lamp',
    background: 'base2',
    cornerRadius: 'lowEmphasis',
    floatingHeight: 24
  });

  const closeMenu = () => {
    setOpen(false);
  };

  const {
    menuIconPath,
    extraPopOverProps,
    extraButtonProps,
    buttonText,
    childrenRenderProps,
    classProps
  } = props;

  return (
    <div className={classes.root}>
      {buttonText ? (
        <MenuButton
          isDropDown={true}
          onClick={() => setOpen(true)}
          path={menuIconPath}
          text={buttonText}
          extraButtonProperties={{
            buttonRef: refContainer,
            ...extraButtonProps
          }}
          className={[classes.menuButtonStyling, classProps?.menuButton].join(
            ' '
          )}
        />
      ) : (
        <IconButton
          onClick={() => setOpen(true)}
          path={menuIconPath ? menuIconPath : iconElepsis}
          extraIconButtonProps={{
            buttonRef: refContainer,
            classes: { root: classes.iconButton }
          }}
          colorVariant="ghost2"
          {...extraButtonProps}
        />
      )}

      <Popover
        open={open}
        PaperProps={{ className: surfaceStyles }}
        classes={{ paper: [classes.paper, classProps?.paper].join(' ') }}
        anchorEl={refContainer.current}
        onClose={() => setOpen(false)}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        {...extraPopOverProps}
      >
        {childrenRenderProps(closeMenu, open)}
      </Popover>
    </div>
  );
};
