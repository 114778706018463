import React from 'react';

export const LinkIcon = props => {
  const { className } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          className={className}
          d="M27.0103 12.2312C28.1819 11.0596 28.1819 9.16012 27.0103 7.98854C25.8387 6.81697 23.9392 6.81697 22.7677 7.98854L21.3534 9.40276L19.9392 7.98854L21.3534 6.57433C23.3061 4.62171 26.4719 4.62171 28.4245 6.57433C30.3771 8.52695 30.3771 11.6928 28.4245 13.6454L19.9392 22.1307C17.9866 24.0833 14.8208 24.0833 12.8682 22.1307C10.9155 20.1781 10.9155 17.0122 12.8682 15.0596L13.5753 14.3525L14.9895 15.7667L14.2824 16.4738C13.1108 17.6454 13.1108 19.5449 14.2824 20.7165C15.454 21.888 17.3534 21.888 18.525 20.7165L27.0103 12.2312Z"
          fill="black"
        />
        <path
          className={className}
          d="M17.7174 11.2816C16.5459 10.11 14.6464 10.11 13.4748 11.2816L4.98952 19.7669C3.81795 20.9384 3.81795 22.8379 4.98952 24.0095C6.16109 25.1811 8.06059 25.1811 9.23216 24.0095L10.6464 22.5953L12.0606 24.0095L10.6464 25.4237C8.69375 27.3763 5.52793 27.3763 3.57531 25.4237C1.62268 23.4711 1.62268 20.3053 3.57531 18.3526L12.0606 9.86736C14.0132 7.91474 17.179 7.91474 19.1317 9.86736C21.0843 11.82 21.0843 14.9858 19.1317 16.9384L18.4245 17.6455L17.0103 16.2313L17.7174 15.5242C18.889 14.3526 18.889 12.4531 17.7174 11.2816Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath>
          <path d="M0 0H32V32H0V0Z" className={className} />
        </clipPath>
      </defs>
    </svg>
  );
};
