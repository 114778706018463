// #region imports
import React from 'react';

// helpers
import { compose } from '../../../../../utils/component-helpers';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';
import EditMetadataModal from '@cube3/ui/src//Modal/EditMetadataModal';

// Types
import { MetadataForm } from '@cube3/common/model/schema/resources/metadata-category';

import { EditMetadataFormSmart } from '../../ContentPane/DetailView/MetaData/EditMetadataFormSmart';

// HOCS
import { useModalActions } from '../../../layout/Modals/modalActions';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import withCurrentWorkspace, {
  WorkspaceProps
} from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

import { useMultiForm } from '../../MetaData/hooks/useMultiForm';

import { useCopyPasteMetadata } from '../../MetaData/hooks/useCopyPasteMetadata';

type Properties = ModalReceiverProps &
  RouteComponentProps &
  WorkspaceProps &
  ProjectMetadata;

interface ProjectMetadata {
  retrievedFormsStatus: string;
  retrievedForms: MetadataForm[];
}

function EditMetadata(props: Properties): JSX.Element {
  const { retrievedForms = [], retrievedFormsStatus, modalContext } = props;

  const modalActions = useModalActions();

  const metadataFor = React.useRef({
    type: modalContext.type,
    id: modalContext.id
  });

  const {
    allFormsValid,
    submitAllForms,
    allFormsSucceeded,
    formSubmitting,
    allFormsPristine,
    destroyForms
  } = useMultiForm(retrievedForms);

  const { pasteDisabled, copyDisabled, onCopy, onPaste } = useCopyPasteMetadata(
    { categories: retrievedForms, type: 'metadata' }
  );

  return (
    <EditMetadataModal
      onCloseEvent={() => {
        // if the forms submission succeeded or nothing changed to the form...
        if (allFormsSucceeded || allFormsPristine) {
          modalActions.previousModal();
          // destroy the forms so the next time we will open them things like submitsucceeded are reset.
          destroyForms();
        } else {
          // show a confirmation dialog and pass the destroy function
          modalActions.openModal(
            'metadata_confirmDiscardChanges',
            destroyForms,
            false
          );
        }
      }}
      loading={retrievedFormsStatus !== statuses.SUCCESS || formSubmitting}
      retrievedForms={retrievedForms}
      allFormsValid={allFormsValid}
      allFormsPristine={allFormsPristine}
      submitAllForms={submitAllForms}
      allFormsSucceeded={allFormsSucceeded}
      onCopy={onCopy}
      onPaste={onPaste}
      copyDisabled={copyDisabled}
      pasteDisabled={pasteDisabled}
      renderMetaDataForm={(form) => (
        <EditMetadataFormSmart
          metadataFormId={form.id}
          metadataForResource={metadataFor.current}
          metadataForm={form}
        />
      )}
    />
  );
}

export default compose(EditMetadata)(
  withRouter,
  withCurrentWorkspace,
  withResourceEdges({
    resourceType: ({ modalContext }) => modalContext && modalContext.type,
    resourceId: ({ modalContext }) => modalContext && modalContext.id,
    edgeType: 'metadata-category',
    edgeLabel: 'metadata-categories',
    mapper: 'retrievedForms'
  })
);
