import React, { useCallback } from 'react';
// UI
import SelectComponent from '@cube3/ui/src/select/SelectComponent';
import { Typography } from '@cube3/ui/src/typography/Typography';
import MenuItem from '@material-ui/core/MenuItem';

export const SelectLevel1 = (props) => {
  const {
    parentInput,
    options,
    classes,
    placeholder,
    selectProps,
    disabled,
    showLabel,
    label,
    showClearButton
  } = props;

  const formValue = parentInput.value?.input?.level1;

  // options is an object where each key describes an array of suboptions
  const optionKeys = Object.keys(options);

  const value = !formValue
    ? undefined
    : optionKeys.find((o) => o === formValue); // make sure the value is actually one of the options

  // trigger an update on the parent value, so the rest of the metadata parsing gets run correctly
  const onChange = useCallback(
    (val) =>
      parentInput.onChange({
        ...parentInput.value,
        input: { level1: val, level2: undefined } // clear the level2 values as well
      }),
    [parentInput.value, parentInput.onChange]
  );

  return (
    <SelectComponent
      showClearButton={showClearButton}
      showLabel={showLabel}
      labelName={label || props.input.name}
      value={value}
      onFocus={props.input.onFocus}
      onBlur={props.input.onBlur}
      onChange={onChange}
      placeholder={placeholder}
      selectProps={selectProps}
      disabled={disabled}
      data-cy={props['data-cy']}
      clearButtonInside={true}
    >
      {optionKeys.map((option) => (
        <MenuItem
          classes={{ root: classes.menuItem }}
          value={option}
          key={option}
        >
          <Typography typographyStyle={'body2'} color="contrast2">
            {option}
          </Typography>
        </MenuItem>
      ))}
    </SelectComponent>
  );
};
