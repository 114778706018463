import React, { useEffect, useMemo } from 'react';

import PreviewHoc from './PreviewHoc';

import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList/useResourceList';
import { useVideoSnapshot } from '@cube3/state/src/Hoocs/useVideoSnapshot';
import { useDispatch } from 'react-redux';
import { actionCreators } from '@cube3/state/src/redux/ducks/request-status';
import {
  usePermission,
  useWorkspacePermissions
} from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useCurrentProject } from '@cube3/state/src/redux/components/Hooks/useCurrentProject';
import { assetProcessingStates } from '@cube3/common/model/types';
import { useEditTeamsCheck } from '../AdminCenter/views/WorkspaceTeamsView/hooks/useEditTeamsCheck';

function previewVideoWrapper<P extends {}>(
  WrappedComponent: React.ComponentType<P>
) {
  const FullscreenWrapped = PreviewHoc(WrappedComponent);

  return (props) => {
    const dispatch = useDispatch();
    const { error, snapshots, createSnapshot } = useVideoSnapshot(props.id);

    const videoProperties = useResourceList__ALPHA({
      resourceId: props.id,
      resourceType: 'asset',
      edgeType: 'properties',
      edgeLabel: 'properties'
    });

    // get framerate from video properties
    const videoFields =
      videoProperties &&
      videoProperties.resources &&
      (
        videoProperties.resources.find(
          (res) => res.display_name === 'Video Properties'
        ) || videoProperties.resources[0]
      )?.fields;
    const frameRate =
      videoFields && videoFields.filter((f) => f.key === 'Framerate')[0]?.value;

    const ancestors = useResourceList__ALPHA({
      resourceId: props.id,
      resourceType: 'asset',
      edgeType: 'folder',
      edgeLabel: 'ancestors'
    });
    // check privileges
    const { project } = useCurrentProject();
    const [hasTeamFeature] = useEditTeamsCheck();
    const [canEditProject] = usePermission({
      targetResource: { type: 'project', id: project?.id },
      privileges: ['ADD_PROJECT_ASSET'],
      hasTeamFeature
    });
    const [canWriteLibrary] = useWorkspacePermissions(['LIBRARY_WRITE']);

    const allowedToTakeSnapshot = project
      ? canEditProject && canWriteLibrary
      : canWriteLibrary;
    const canTakeSnapshot = !props.disableSnapshot && allowedToTakeSnapshot;

    const parent = ancestors.resources?.slice(-1)[0];
    const parentFolderAssets = useResourceList__ALPHA({
      resourceId: parent?.id,
      resourceType: 'folder',
      edgeType: 'asset',
      edgeLabel: 'assets'
    });

    const snapshotsResource = useMemo(() => {
      if (!snapshots) return [];

      // extend snapshots with thumnail and capture time
      const formattedItems = snapshots.map((item) => {
        const matchedAsset = parentFolderAssets.resources?.find(
          (a) => a.id === item.id
        );
        return {
          id: item.id,
          processing: matchedAsset
            ? !(
                matchedAsset.processing_status ===
                  assetProcessingStates.COMPLETE ||
                matchedAsset.processing_status === assetProcessingStates.FAILED
              )
            : true,
          failed:
            matchedAsset?.processing_status === assetProcessingStates.FAILED,
          display_image: matchedAsset?.display_image,
          captured_at: item.captured_at,
          thumbnail: item.thumbnail
        };
      });

      return formattedItems;
    }, [parentFolderAssets.resources, snapshots]);

    useEffect(() => {
      if (!parent) return;
      dispatch(
        actionCreators.invalidateRelationship({
          id: parent?.id,
          type: 'folder',
          relationship: 'assets'
        })
      );
    }, [dispatch, parent, snapshots]);

    // polling the snapshots every 3 seconds
    useEffect(() => {
      if (!snapshotsResource) return;
      const processingItem = snapshotsResource.find((item) => item.processing);
      if (processingItem) {
        const timer = setTimeout(() => {
          dispatch(
            actionCreators.invalidateRelationship({
              id: parent?.id,
              type: 'folder',
              relationship: 'assets'
            })
          );
        }, 3000);
        return () => {
          window.clearTimeout(timer);
        };
      }
    }, [dispatch, parent, snapshotsResource]);

    return (
      <FullscreenWrapped
        frameRate={frameRate}
        canTakeSnapshot={canTakeSnapshot}
        createSnapshot={createSnapshot}
        snapshots={snapshotsResource}
        error={error}
        {...props}
      />
    );
  };
}

export default previewVideoWrapper;
