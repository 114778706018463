import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actionCreators, selectors } from '../ducks/modals';
import { useTypedSelector } from '../components/Hooks/useTypedSelector';
import { Modal } from '../ducks/modals/state';

/**
 * @summary: Dispatcher helper functions for redux, to reduce overhead in actual components.
 * @author Simon
 */

/** Returns hook to use modal actions directly

Usage:
```
const modalActions = useModalActions();
```
In a button or when you would like to call the event:
```
modalActions.openModal('asset_rename', '12345');
modalActions.previousModal();
```
*/

export interface ModalActionProps<M extends string> {
  /**
   * Open a modal by name
   * @param modal name of the modal as set in modalIndex
   * @param context freeform contextual data passed into the modal component as modalContext props
   * @param hideBelow should previous modals on the stack be shown behind this one? defaults to true
   *
   */
  openModal(modal: M, context?: any, hideBelow?: boolean): void;
  /** close all modals */
  closeAllModals: () => void;
  /** close top most modal
   * @param context: optionally push a new context for the previous modal
   */
  previousModal: (context?: any) => void;
  /** close n number of modals from the top of the stack
   * @param amountOfPages: n amount of modals to close
   */
  goBackModals: (amountOfpages: number) => void;
  /** currently active modal, topmost on the stack */
  activeModal?: Modal;
  modalStack: Modal[];

  blockModal: () => void;
  unblockModal: (revertToSnapshot?: boolean) => void;
}

export function useModalActions<M extends string>(): ModalActionProps<M> {
  const dispatch = useDispatch();

  const openModal = useCallback(
    (modal: M, contextualInfo?: any, hideBelow?: boolean) =>
      dispatch(actionCreators.openModal(modal, contextualInfo, hideBelow)),
    [dispatch]
  );

  const previousModal = useCallback(
    (contextualInfo?: any) => {
      dispatch(actionCreators.previousModal(contextualInfo));
    },
    [dispatch]
  );

  const closeAllModals = useCallback(
    () => {
      dispatch(actionCreators.closeModal());
    },
    [dispatch]
  );

  const goBackModals = useCallback(
    (amount: number) => dispatch(actionCreators.goBackModals(amount)),
    [dispatch]
  );

  const activeModal = useTypedSelector(state =>
    selectors.getActiveModal(state)
  );

  const modalStack = useTypedSelector(state => state.modals.modalStack);

  const blockModal = useCallback(
    () => {
      dispatch(actionCreators.blockModal())
    },
    [dispatch]
  );

  const unblockModal = useCallback(
    (revertToSnapshot: boolean = false) => {
      dispatch(actionCreators.unblockModal(revertToSnapshot))
    },
    [dispatch]
  );


  return {
    openModal,
    previousModal,
    closeAllModals,
    goBackModals,
    activeModal,
    modalStack,
    blockModal,
    unblockModal
  };
}