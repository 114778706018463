import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { Asset } from '@cube3/common/model/schema/resources/asset';
import SharedLinkOverviewBreadcrumbs from '@cube3/ui/src/Prefabs/shareLink/Pages/SharedLinkOverviewBreadcrumbs';
import Cube3Link from '../../..//routing/Cube3Link';
import React, { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

interface Props {
  asset?: Asset;
  deleted?: boolean;
}

export const UploadBreadcrumbs: React.FunctionComponent<Props> = ({
  asset,
  deleted
}) => {
  const [waited, setWaited] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setWaited(true);
    }, 2500);

    return () => {
      clearTimeout(t);
      setWaited(false);
    };
  }, [setWaited, asset]);

  const ancestors = useResourceList__ALPHA({
    resourceType: 'folder',
    resourceId: asset?.relationships.parent.id,
    edgeType: 'folder',
    edgeLabel: 'ancestors',
    strategy: waited ? 'prefer-cache' : 'cache-only'
  });

  const { resource: parentFolder } = useResource__ALPHA({
    resourceType: 'folder',
    resourceId: asset?.relationships.parent.id,
    strategy: waited ? 'prefer-cache' : 'cache-only'
  });

  const ancestorsList = ancestors.resources;

  const [workspaceId] = useCurrentWorkspace();

  const binCrumbs = useRef([
    { pathname: `/workspace/${workspaceId}/bin/`, name: 'bin' }
  ]);

  const baseCrumb = useMemo(
    () =>
      asset
        ? [
            {
              pathname: `/workspace/${workspaceId}/node/${asset?.id}/parent`,
              name: 'Visit folder'
            }
          ]
        : [],
    [asset, workspaceId]
  );

  const crumbs = useMemo(() => {
    return !ancestorsList?.length
      ? baseCrumb
      : [
          ...ancestorsList.map((a) => {
            return {
              pathname: `/workspace/${workspaceId}/node/${a.id}`,
              name: a.display_name
            };
          }),
          {
            pathname: `/workspace/${workspaceId}/node/${parentFolder?.id}/`,
            name: parentFolder?.display_name || '...'
          }
        ];
  }, [workspaceId, ancestorsList, baseCrumb, parentFolder]);

  if (deleted) {
    return (
      <Cube3Link pathname={`/workspace/${workspaceId}/bin/`} underline="hover">
        <SharedLinkOverviewBreadcrumbs crumbs={binCrumbs.current} />
      </Cube3Link>
    );
  }

  return (
    (crumbs.slice(-1)[0] && (
      <Cube3Link pathname={crumbs.slice(-1)[0].pathname} underline="hover">
        <SharedLinkOverviewBreadcrumbs crumbs={crumbs} />
      </Cube3Link>
    )) || (
      <Shimmer
        width={200}
        height={25}
        weight="feather"
        margin="medium"
        fadeIn={false}
      />
    )
  );
};
