/** remove the `included` parameter for every edge */
export const removeIncludedParamFromEdgeLabel = (
  edges: any[],
  included: string
) => {
  if (!edges.length) return [];
  return edges.map((e) => {
    if (e.label && e.label.match('?included=')) {
      return {
        ...e,
        label: e.label.replace(`included=${included}&`, '')
      };
    }
    return e;
  });
};

/** insert the `included` parameter for every edge */
export const insertIncludedParamIntoEdgeLabel = (
  edges: any[],
  included: string
) => {
  if (!edges.length) return [];

  return edges.map((e) => {
    if (e.label && e.name) {
      const arr = e.label.replace('?', '&').split(e.name);
      const newLabel = [e.name, `?included=${included}`, arr[1]].join('');
      return {
        ...e,
        label: newLabel
      };
    }
    return e;
  });
};
