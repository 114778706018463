import * as React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Tabs,
  Tab
} from '@material-ui/core';
import Cube3Button from '../Buttons/Cube3Button';
import ScrollBar from '../Scrollbar/Scrollbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      display: 'flex',
      flexFlow: 'column nowrap',
      height: '100%',
    },
    buttonWrapper: {
      flexGrow: 0,
      flexShrink: 0
    },
    listTitle: {
      display: 'grid',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.customPalette.line.onBase1.contrast2}`,
      height: '54px',
      gridTemplateColumns: 'auto auto 36px', // 20(ellipsis width) + 16(margin)
      '& span': {
        fontWeight: theme.typography.fontWeightBold
      }
    },
    listWrapper: {
      flexGrow: 1,
      flexShrink: 1
    },
    invitePeopleButton: {
      margin: `${theme.customSpacing.margin[4]} 0px ${theme.customSpacing.margin[24]} 0px`
    },
    tabsIndicator: {
      backgroundColor: theme.customPalette.secondary.main,
      bottom: 20
    },
    tabRoot: {
      textTransform: 'initial',
      minWidth: 72,
      marginRight: theme.spacing(0.5),
      fontFamily: theme.typography.fontFamily,
      ...theme.typographyStyles.body2,
      fontWeight: 'bold',
      '&$tabSelected': {
        color: theme.customPalette.secondary.main
      }
    },
    contents: () => ({
      // use the multiplier given in props or default to 5
      height: 500,
      maxHeight: 'calc(100vh - 296px)'
    }),
    flexContainer: {
      marginBottom: 20
    },
    tabSelected: {}
  }));

interface WorkspaceTabsProps {
  handleOpenInvitePeople: any;
  disableInvite: boolean;
  workspaceMembers: React.ReactNode;
  workspacePendingInvites?: React.ReactNode;
  currentTab: number;
}

function WorkspaceTabs(props: WorkspaceTabsProps) {
  const [value, setValue] = React.useState(props.currentTab);
  const {
    handleOpenInvitePeople,
    disableInvite,
    workspaceMembers,
    workspacePendingInvites
  } = props;

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.buttonWrapper}>
        <Cube3Button
          className={classes.invitePeopleButton}
          text="Invite People"
          marginCollapse="left"
          onClick={() => handleOpenInvitePeople(value)}
          disabled={disableInvite}
        />
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: classes.tabsIndicator,
            flexContainer: classes.flexContainer
          }}
        >
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Directory"
          />
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Pending invites"
          />
        </Tabs>
      </div>
      {value === 0 && (
        <div className={classes.listTitle}>
          <span>Name</span>
          <span>Role</span>
          <span></span>
        </div>
      )}
      <div className={classes.listWrapper}>
        <ScrollBar>
          <div className={classes.contents}>
            {value === 0 && workspaceMembers}
            {value === 1 && workspacePendingInvites}
          </div>
        </ScrollBar>
      </div>
    </div>
  );
}

export default WorkspaceTabs;
