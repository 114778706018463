import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { UserInfo } from '../../../User/UserTypes';
import UserRow from '../../../User/UserRow';
import InteractableSurface from '../../../Surface/InteractableSurface';
import ManageUserMenuUI from '../../../menus/ManageUserMenuUI';
import {
  MenuItemsRenderer,
  MenuItemType
} from '../../../Workspace/subComponents/MenuItemsRenderer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: theme.sizing[56]
    }
  }));

interface Properties {
  userInfo: UserInfo;
  onResendLinkClick: (info: UserInfo) => void;
  onRemoveAccessClick: (info: UserInfo) => void;
  isAuth?: boolean;
}

const ManageAccessUserDetail: React.FC<Properties> = props => {
  const {
    userInfo,
    onResendLinkClick,
    onRemoveAccessClick,
    isAuth
  } = props;

  const menuItems: MenuItemType[] = React.useMemo(
    () => [
      {
        text: 'Resend personal link',
        hidden: isAuth,
        onClick: () => {
          onResendLinkClick(userInfo);
        }
      },
      {
        colorVariant: 'danger',
        text: 'Remove access',
        onClick: () => {
          onRemoveAccessClick(userInfo);
        }
      }
    ],
    [isAuth, onRemoveAccessClick, onResendLinkClick, userInfo]
  );

  const classes = useStyles();

  return (
    <InteractableSurface
      highlightOnHover={true}
      surfaceProps={{ corners: 'rounded' }}
      classes={{ root: classes.root }}
    >
      <UserRow userInfo={userInfo} />
      <ManageUserMenuUI
        userInfo={userInfo}
        menuItems={<MenuItemsRenderer menuItems={menuItems} />}
      />
    </InteractableSurface>
  );
};
/**
 * @summary Shows info about a `User` and provides the ability to reshare a link or delete a user from a link.
 */
export default ManageAccessUserDetail;
