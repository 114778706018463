import { useMemo } from 'react';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';

/**
 *
 *
 *
 */

export const usePaginatedContracts = (
  futureParams,
  pastParams,
  pagesArray,
  parentResource = undefined
) => {
  const [workspaceId] = useCurrentWorkspace();

  const futurePagesNeeded = useMemo(
    () => pagesArray.filter(p => p < 1).map(p => Math.abs(p) + 1),
    [pagesArray]
  );

  const pastPagesNeeded = useMemo(() => pagesArray.filter(p => p > 0), [
    pagesArray
  ]);

  const futureContracts = useResourceList__ALPHA({
    resourceType: parentResource?.type || 'workspace',
    resourceId: futureParams?.page.size
      ? parentResource?.id || workspaceId
      : undefined,
    edgeType: 'contract',
    edgeLabel: 'contracts',
    params: futureParams,
    pages: futurePagesNeeded,
    strategy: 'fetch-on-mount'
  });
  const pastContracts = useResourceList__ALPHA({
    resourceType: parentResource?.type || 'workspace',
    resourceId: pastParams?.page.size
      ? parentResource?.id || workspaceId
      : undefined,
    edgeType: 'contract',
    edgeLabel: 'contracts',
    params: pastParams,
    pages: pastPagesNeeded,
    strategy: 'fetch-on-mount'
  });

  const joinedPages = useMemo(() => {
    if (
      (futureParams && !futureContracts.pages) ||
      (pastParams && !pastContracts.pages)
    ) {
      return;
    }
    return [
      ...(futureContracts.pages || [])
        // ignore page without number to prevent duplicate entries
        .filter(p => p.pageNumber !== undefined)
        .map(p => ({
          ...p,
          pageNumber: p.pageNumber * -1 + 1
        })),
      ...(pastContracts.pages || [])
    ];
  }, [futureContracts.pages, pastContracts.pages, futureParams, pastParams]);

  return [
    joinedPages,
    futureContracts?.pageCounts?.pageCount
      ? futureContracts.pageCounts.pageCount * -1 + 1
      : undefined,
    futureContracts?.pageCounts?.itemCount
      ? futureContracts.pageCounts.itemCount * -1
      : undefined,
    pastContracts?.pageCounts?.pageCount,
    pastContracts?.pageCounts?.itemCount
  ] as [typeof joinedPages, number, number, number, number];
};
