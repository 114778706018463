import { compose as reduxCompose } from 'redux';

interface Wfunction extends Function {
  with?: Function;
}

const compose = (component: Function, ...decorators: Function[]): Wfunction => {
  let preloaded: Wfunction = function(...onlyDecorators: Function[]): Function {
    return reduxCompose(...onlyDecorators)(component) as Function;
  };
  preloaded.with = preloaded;
  return decorators.length < 1
    ? preloaded
    : (reduxCompose(...decorators)(component) as Function);
};

export { compose };
