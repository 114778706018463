import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Download extends ResourceBase {
  download_url?: string;
  size?: number;
  file_count?: number;
  relationships: {
    assets: Related<'asset'>;
    folders: Related<'folder'>;
    preset: Related<'render-preset'>;
  };
}

export const download: RawModel = {
  type: 'download',
  plural: 'downloads',
  JSONApi: true,
  attributes: {
    download_url: 'string',
    excluded: 'any',
    size: 'number',
    file_count: 'number'
  },
  relationships: {
    assets: {
      type: 'asset',
      binding: ['many', 'one'],
      reverse: 'downloads'
    },
    folders: {
      type: 'folder',
      binding: ['many', 'one'],
      reverse: 'downloads'
    },
    preset: {
      type: 'render-preset',
      binding: ['one', 'one'],
      reverse: 'downloads'
    }
  }
};
