import { RawModel } from '../schema';
import { MetadataValue } from './metadata-value';

export interface ContractValue extends MetadataValue {
  key_id: string;
  contract_id: string;
  value: 'any';
}

export const contractValue: RawModel = {
  type: 'contract-value',
  plural: 'contract-values',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
