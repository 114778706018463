const handler = {
  get: function (target, key) {
    if (target[key] && Object.hasOwnProperty.call(target[key], 'value')) {
      return target[key].value;
    }
    // eslint-disable-next-line prefer-rest-params
    return Reflect.get(target, key);
  }
};

type ValueAcces<V extends { value: unknown }> = V['value'];

export type Proxified<O> = {
  [k in keyof O]: O[k] extends { value: unknown }
    ? ValueAcces<O[k]>
    : Proxified<O[k]>;
};

export const proxifyTheme = <O extends object>(obj: O): Proxified<O> => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const keys = Object.keys(obj);

  const shouldProxy = keys.some((k) => {
    return obj[k] && Object.hasOwnProperty.call(obj[k], 'value');
  });

  keys.forEach((k) => {
    obj[k] = proxifyTheme(obj[k]);
  });

  if (shouldProxy) {
    return new Proxy(obj, handler);
  }
  return obj;
};
