import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export interface TagCategory extends PublicResource {
  is_general: boolean;
  editable: boolean;
  relationships: {
    tags: Related<'tag'>[];
    workspace: Related<'workspace'>;
  };
}

export const tagCategory: RawModel = {
  type: 'tag-category',
  plural: 'tag-categories',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    is_general: 'boolean,'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'tags'
    },
    tags: {
      type: 'tag',
      binding: ['many', 'one'],
      reverse: 'category'
    }
  }
};
