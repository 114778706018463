/**
 *
 * @function NormalizeSchema
 * @description Normalize a schema object, by normalizing some field types and reverse mapping relationships
 * @param {Object} schema: raw schema definition
 * @returns {NormalizedSchema}: a normalized version of the schema
 */

import { RawSchema, NormalizedSchema } from './schema';

export const normalizeSchema = (schema: RawSchema): NormalizedSchema => {
  schema.resources.forEach(r => {
    if (!r.plural) throw new Error(`plural of '${r.type}' is undefined`);

    r.relationships = r.relationships || {};

    Object.keys(r.relationships).forEach(k => {
      r.relationships[k] = {
        name: k,
        ...r.relationships[k],
        type: [].concat(r.relationships[k].type),
        required: [].concat(r.relationships[k].required)[0]
      };
    });

    Object.keys(r.relationships || {})
      .map(k => r.relationships[k])
      .forEach(rel => {
        const revTypes = rel.type;
        const revResources = schema.resources.filter(
          r => revTypes.indexOf(r.type) !== -1
        );
        const revKey = rel.reverse || `${rel.name}_reverse`;

        revResources.forEach(revResource => {
          if (!revResource.relationships[revKey]) {
            revResource.relationships[revKey] = {
              name: revKey,
              type: [r.type],
              binding: [rel.binding[1], rel.binding[0]],
              reverse: rel.name,
              required: [].concat(rel.required)[1]
            };
          }
        });
      });
  });
  return schema as NormalizedSchema;
};
