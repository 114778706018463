import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';
import mergeRefs from '../helpers/MergeRefs';
import { calculateScrollBarWidth } from './utils/calculateScrollBarWidth';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// Convienience wrapper for scrollbar. Typings for scrollbar.
/// Check out storybook for implementation.
/// Scrollbars has more properties and functions so feel free to extend this
/// as needed. Check for props https://github.com/malte-wessel/react-custom-scrollbars
/// </Summary>

let globalScrollbarWidth = calculateScrollBarWidth();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    disableXscroll: {
      '& :first-child': {
        overflowX: 'hidden !important'
      }
    },
    thumbStyle: {
      borderRadius: '12px'
    },
    thumbStyleHorizontal: {
      transform: 'translate3d(0, -50%, 0)',
      width: '100%'
    },
    thumbStyleVertical: {
      transform: 'translate3d(-50%, 0, 0)',
      height: '100%',
      background: theme.customPalette.primary.light
    },
    // #region colors
    color_light: {
      background: theme.customPalette.primary.light
    },
    color_main: {
      background: theme.customPalette.primary.main
    },
    color_dark: {
      background: theme.customPalette.primary.dark
    }
    // #endregion
  })
);

// Here we modify the negative margins,
// So when the user zooms the offset is recalculated
const InnerView: React.ComponentType<{
  style: any;
  ref?: React.Ref<HTMLDivElement> | React.LegacyRef<HTMLDivElement>;
}> = forwardRef<HTMLDivElement, { style: any }>((props, ref) => {
  const containerRef = useRef(null);

  /*    
  The scrollbar component calculates the marginRight and marginBotom based on scrollbarWidth = (div.offsetWidth - div.clientWidth). The scrollbars are hidden by setting a negative margin:
    marginRight: scrollbarWidth ? -scrollbarWidth : 0,
    marginBottom: scrollbarWidth ? -scrollbarWidth : 0.
  When zooming in/out these margins are only updated on refresh, as workaround we calculate them ourselves. 
  */
  const [scrollbarWidth, setScrollbarWidth] =
    React.useState(globalScrollbarWidth);

  // original styles passed in by Scrollbars renderprops
  const style = { ...props.style };
  // hookup listener for when user zoom (and window size changes)
  const onResize = React.useCallback(
    (ev) => {
      setScrollbarWidth(
        // sometimes the margin is 2px off, maybe has to do with roudings.
        containerRef.current.offsetWidth + 2 - containerRef.current.clientWidth
      );
    },
    [setScrollbarWidth]
  );

  React.useEffect(() => {
    window.addEventListener('resize', onResize);

    if (containerRef.current) {
      globalScrollbarWidth =
        containerRef.current.offsetWidth + 2 - containerRef.current.clientWidth;
      setScrollbarWidth(
        // sometimes the margin is 2px off, maybe has to do with roudings.
        globalScrollbarWidth
      );
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, containerRef]);

  // memoize the margins
  const offset = React.useMemo(() => {
    return scrollbarWidth
      ? -scrollbarWidth
      : globalScrollbarWidth
      ? -globalScrollbarWidth
      : 0;
  }, [scrollbarWidth]);

  // overwrite marginRight/marginBottom
  style.marginRight = offset;
  style.marginBottom = offset;

  return <div {...props} style={style} ref={mergeRefs(ref, containerRef)} />;
});

// Needed an extra wrapping component because of
// the way renderView is used with cloneElement in the scrollbars packages
const RenderView: React.ComponentType<{ style: any }> = (props) => {
  return <InnerView {...props} />;
};

interface Properties
  extends Pick<React.HTMLProps<'div'>, 'style' | 'className'> {
  autoHeight?: boolean;
  autoHeightMin?: number;
  autoHeightMax?: number;
  colorScrollbar?: 'light' | 'main' | 'dark';
  disableXscroll?: boolean;
  rememberScroll?: boolean | 'save' | 'load';
  resetScroll?: boolean;
  setScrollingBoolean?: boolean;
  width?: number | string;
  height?: number | string;
  disableInteraction?: boolean;
  onUpdate?(values: {}): void;
  onScrollFrame?(ev: Event, values: {}): void;
  onScroll?(ev: Event): void;
}

export interface ScrollBarsLike {
  container: HTMLElement;
  view: HTMLElement;
  scrollTop(pos: number): void;
  getScrollTop(): number;
}

type ScrollbarsWithView = Scrollbars & { view: HTMLElement };

type ScrollParent = ScrollBarsLike;

export const ScrollParentContext = React.createContext<ScrollParent[]>([]);

// export const ScrollPositionContext = React.createContext(0);

export const ScrollPositionContext = React.createContext({
  scrollPosition: 0,
  scrolling: false
});

const ScrollbarBase = forwardRef<
  HTMLElement,
  React.PropsWithChildren<Properties>
>((props, ref) => {
  const [containerRef, setContainerRef] = useState<ScrollBarsLike>(null);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [scrolling, setScrolling] = useState(false);

  const {
    className = '',
    colorScrollbar = 'light',
    disableXscroll = false,
    rememberScroll = false,
    setScrollingBoolean = false,
    width,
    height,
    resetScroll = false,
    disableInteraction = false,
    onScrollFrame,
    onScroll,
    ...rest
  } = props;

  const classes = useStyles(props);
  const location = useLocation();

  const scrollKey = location.pathname;

  const handleScrollFrame = useCallback(
    (values) => onScrollFrame?.(undefined, values),
    [onScrollFrame]
  );

  useEffect(() => {
    if (disableInteraction && containerRef) {
      if (scrolling) {
        (
          containerRef.container.firstElementChild
            .firstElementChild as HTMLDivElement
        ).style.pointerEvents = 'none';
      } else {
        (
          containerRef.container.firstElementChild
            .firstElementChild as HTMLDivElement
        ).style.pointerEvents = undefined;
      }
    }
  }, [scrolling, containerRef, disableInteraction]);

  useEffect(() => {
    if (resetScroll && rememberScroll) {
      throw new Error('conflicting props: resetScroll, rememberScroll');
    }
    if (resetScroll) {
      containerRef?.scrollTop(0);
      return;
    }
    if (rememberScroll !== true && rememberScroll !== 'load') {
      return;
    }

    const scrollStore = JSON.parse(
      sessionStorage.getItem('scrollPositions') || 'null'
    );

    const loadedPosition =
      scrollStore &&
      parseInt(
        scrollStore.find((s) => s.split('::')[0] === scrollKey)?.split('::')[1]
      );

    if (loadedPosition) {
      containerRef?.scrollTop(loadedPosition);
    }
  }, [scrollKey, containerRef, rememberScroll, resetScroll]);

  const setRef = useCallback(
    (node: ScrollbarsWithView) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(node?.view);
        } else {
          ref.current = node?.view;
        }
      }
      setContainerRef(node);
    },
    [setContainerRef, ref]
  );

  const handleScrollStop = useCallback(() => {
    if (!setScrollingBoolean) {
      return;
    }
    if (scrolling) {
      setScrolling(false);
    }
  }, [scrolling, setScrollingBoolean]);

  const handleScrolling = useCallback(
    (ev) => {
      if (onScroll) {
        onScroll(ev);
      }
      if (rememberScroll !== true && rememberScroll !== 'save') {
        return;
      }
      const scrollPos = containerRef?.getScrollTop();

      setScrollPosition(scrollPos);
    },
    [setScrollPosition, containerRef, rememberScroll, onScroll]
  );

  const lastPosition = useRef(scrollPosition);
  lastPosition.current = scrollPosition;

  useEffect(() => {
    const store = () => {
      if (rememberScroll !== true && rememberScroll !== 'save') {
        return;
      }
      const scrollPos = lastPosition.current;

      const scrollStore =
        JSON.parse(sessionStorage.getItem('scrollPositions') || 'null') || [];

      if (scrollPos !== undefined && scrollPos !== null) {
        // value 0 just clears the item from storage
        if (scrollPos === 0) {
          sessionStorage.setItem(
            'scrollPositions',
            JSON.stringify(
              [
                ...scrollStore.filter((s) => s.split('::')[0] !== scrollKey)
              ].slice(0, 10)
            )
          );
        } else {
          sessionStorage.setItem(
            'scrollPositions',
            JSON.stringify(
              [
                `${scrollKey}::${scrollPos?.toString()}`,
                ...scrollStore.filter((s) => s.split('::')[0] !== scrollKey)
              ].slice(0, 10)
            )
          );
        }
      }
    };

    window.addEventListener('beforeunload', store);
    return () => {
      store();
      window.removeEventListener('beforeunload', store);
    };
  }, [rememberScroll, scrollKey, containerRef]);

  const handleScrollStart = useCallback(() => {
    if (!setScrollingBoolean) {
      return;
    }

    if (!scrolling) {
      setScrolling(true);
    }
  }, [setScrollingBoolean, scrolling]);

  const renderThumbVertical = useCallback(
    () => (
      <div
        // {...rest}
        className={`
            ${classes.thumbStyle} 
            ${classes.thumbStyleVertical}
            ${classes['color_' + colorScrollbar]}
          `}
      />
    ),
    [classes, colorScrollbar]
  );

  const renderThumbHorizontal = useCallback(
    () => (
      <div
        // {...rest}
        className={`
          ${classes.thumbStyle} 
          ${classes.thumbStyleHorizontal}
          ${classes['color_' + colorScrollbar]}
        `}
      />
    ),
    [classes, colorScrollbar]
  );

  const dimensions = useMemo(() => {
    if (width || height) {
      const d: { width?: number | string; height?: number | string } = {};
      d.width =
        typeof width === 'number' || typeof width === 'string'
          ? width
          : undefined;
      d.height =
        typeof height === 'number' || typeof height === 'string'
          ? height
          : undefined;
      return d;
    }
  }, [width, height]);

  return (
    <ScrollParentContext.Consumer>
      {(scrolParents) => (
        <ScrollParentContext.Provider value={[...scrolParents, containerRef]}>
          <Scrollbars
            {...rest}
            hideTracksWhenNotNeeded={true}
            onScrollFrame={handleScrollFrame}
            onScrollStop={handleScrollStop}
            onScroll={handleScrolling}
            onScrollStart={handleScrollStart}
            data-scroll-parent="scroll-parent"
            renderView={RenderView} // used to compensate for zooming, keeps browser scrollbars hidden
            ref={setRef}
            style={dimensions}
            className={[
              classes.root,
              disableXscroll ? classes.disableXscroll : '',
              className
            ].join(' ')}
            renderThumbVertical={renderThumbVertical}
            renderThumbHorizontal={renderThumbHorizontal}
          >
            <ScrollPositionContext.Provider
              value={{ scrollPosition: scrollPosition || 0, scrolling }}
            >
              {props.children}
            </ScrollPositionContext.Provider>
          </Scrollbars>
        </ScrollParentContext.Provider>
      )}
    </ScrollParentContext.Consumer>
  );
});

export const Scrollbar = ScrollbarBase;

export default Scrollbar;
