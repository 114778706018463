import React, { useCallback } from 'react';
import { ModalWindowUI } from '@cube3/ui/src/Modal/ModalWindowUI';
import { useModalActions } from '../../Modals/modalActions';
import { ModalFooterUI } from '@cube3/ui/src/Modal/ModalFooter';
import Button from '@cube3/ui/src/Buttons/Button';
import { AccountSettingsIntegrations } from '../../AccountPages/SubComponents/AccountSettingsIntegrations';
import { useHistory } from 'react-router-dom';
import { urlStructureAccountSettings } from '../../../routing/routingPaths';

export const ConnectedAccountsModal = () => {
  const { previousModal, closeAllModals } = useModalActions();

  const history = useHistory();

  const handleClose = useCallback(() => {
    previousModal();
  }, [previousModal]);

  const handleAccountSettingsClick = useCallback(() => {
    closeAllModals();
    history.push(`${urlStructureAccountSettings}/connected-accounts`);
  }, [closeAllModals, history]);

  return (
    <ModalWindowUI
      title={'Manage connected accounts'}
      onCloseEvent={handleClose}
      showCloseButton={true}
    >
      <div style={{ height: 'calc( 100% - 64px)' }}>
        <AccountSettingsIntegrations />
      </div>
      <div style={{ marginTop: 'auto' }}>
        <ModalFooterUI
          footerLeftComponent={
            <Button colorVariant="ghost2" onClick={handleAccountSettingsClick}>
              Visit account settings
            </Button>
          }
          footerRightComponent={
            <>
              <Button
                colorVariant={'filled1'}
                text={'Done'}
                onClick={handleClose}
              />
            </>
          }
        />
      </div>
    </ModalWindowUI>
  );
};
