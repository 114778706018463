import Button from '../../../Buttons/Button';
import { Typography } from '../../../typography/Typography';
import React from 'react';
import { capitalize, createStyles, makeStyles } from '@material-ui/core';
import ShareDownloadCheckBoxWithTooltip from './ShareDownloadCheckBoxWithTooltip';
import { CircularProgress } from '../../../Progress/CircularProgress';
import { downloadWarnings } from '../../../library/Downloads/downloadErrors';
import MaybeTooltip from '../../../Tooltip/MaybeTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    displayName: {
      paddingLeft: 16,
      paddingBottom: 8
    },
    defaultPresets: {
      marginLeft: 15
    }
  })
);

interface DownloadOptionsProps {
  visible: boolean;
  selectedFormats: any[];
  setSelectedFormats: Function;
  /** original / preview type */
  basicDownloadTypes: Array<{ id: string; display_name: string }>;
  onClick: () => void;
  loading?: boolean;
  noOtherFormats: boolean;
  watermark?: boolean;
}

export const ShareDownloadOptions = (props: DownloadOptionsProps) => {
  const {
    visible,
    selectedFormats,
    setSelectedFormats,
    basicDownloadTypes,
    loading,
    onClick,
    noOtherFormats,
    watermark
  } = props;

  const classes = useStyles();

  const selectedFormatNames = selectedFormats?.map((f) => f?.display_name);

  const more = selectedFormatNames?.length - 3;
  const formatsString = arrayToString(selectedFormatNames);

  return visible ? (
    loading ? (
      <CircularProgress size="1em" />
    ) : (
      <div style={{ marginLeft: 18, marginBottom: 9 }}>
        <Typography
          color={formatsString ? 'contrast2' : 'danger1'}
          className={classes.displayName}
        >
          {formatsString || 'You need to select at least one format'}
          {more > 0 && `, and ${more} more`}
        </Typography>

        {basicDownloadTypes?.map((preset) => (
          <ShareDownloadCheckBoxWithTooltip
            key={preset.id}
            preset={preset}
            group={'default'}
            showDivider={false}
            rootClass={classes.defaultPresets}
            selectedFormats={{ default: selectedFormats }}
            setSelectedFormats={setSelectedFormats}
          />
        ))}

        <MaybeTooltip
          disabled={!noOtherFormats}
          title={downloadWarnings.no_other_formats}
          placement={'bottom'}
        >
          {
            <Button
              colorVariant={'ghost1'}
              disabled={noOtherFormats}
              text={'Choose formats...'}
              onClick={onClick}
            />
          }
        </MaybeTooltip>
      </div>
    )
  ) : null;
};

const arrayToString = (arr: Array<string>, length = 3) => {
  if (!arr?.length) return '';
  return arr
    .slice(0, length)
    .map((opt) => opt && capitalize(opt))
    .join(', ');
};
