import React from 'react';
import Chip, { ChipFieldProps } from '@cube3/ui/src/chips/Chip';
import { RecipientSuggestion } from './types';

interface Props extends ChipFieldProps {
  resource: RecipientSuggestion;
}

export const RecipientChip: React.FunctionComponent<Props> = props => {
  const { resource } = props;

  return <Chip {...props} input={{ value: resource.email_address }} />;
};
