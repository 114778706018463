import React from 'react';
import { makeCSS } from '../../utils/makeCSS';
import { CubeAnchorLink } from '../atoms/CubeAnchorLink';
import { Typography } from '../atoms/Typography/Typography';
import { Button } from '../atoms/Button';

const useCSS = makeCSS(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    background: '#1C1C1C', //`theme.color.background.neutral`
    borderRadius: 16,
    border: `1px solid rgba(255,255,255,0.08)`, // theme.color.line['03']
    maxWidth: 240,
    boxShadow: '0px 4px 4px rgba(0,0,0,0.2)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8
  },
  startStep: { marginTop: 160, maxWidth: 320 },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 8,
    marginBottom: 8
  },
  contentText: {
    padding: '8px 0'
  },
  anchor: {
    width: 'fit-content',
    margin: '8px 0'
  },
  dots: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16
  },
  dotContainer: {
    cursor: 'pointer',
    width: 20,
    height: 20,
    position: 'relative'
  },
  dot: {
    position: 'absolute',
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: theme.color.white[12] || 'rgba(255,255,255,0.12)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  active: {
    background: theme.color.white[48] || 'rgba(255,255,255,0.48)'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0'
  }
}));

export const TourStep = (props) => {
  const {
    continuous,
    index,
    step,
    // backProps,
    skipProps,
    closeProps,
    primaryProps,
    tooltipProps,
    size,
    setStepIndex
  } = props;

  const classes = useCSS();
  const dots = createDots(size);

  return (
    <div
      className={`${classes.root} ${index === 0 ? classes.startStep : ''}`}
      {...tooltipProps}
    >
      <div className={classes.header}>
        <Typography variant={'lg'}>{step.title}</Typography>
        <Button
          {...skipProps}
          iconLeft={'close'}
          background="secondary"
          buttonStyle="ghost"
        />
      </div>

      <div className={classes.content}>
        {typeof step.extraContent === 'object' && step.extraContent.image ? (
          <img src={step.extraContent.image} className={classes.contentImage} />
        ) : null}
        {typeof step.extraContent === 'object' && step.extraContent.video ? (
          <video controls autoPlay>
            <source src={step.extraContent.video} type="video/mp4" />
          </video>
        ) : null}
        {typeof step.extraContent === 'object' && step.extraContent.link ? (
          <CubeAnchorLink
            href={step.extraContent.link.href}
            target={step.extraContent.link.target}
            underline={true}
            injectedClassName={classes.anchor}
          >
            <Typography>{step.extraContent.link.text}</Typography>
          </CubeAnchorLink>
        ) : null}
        {(typeof step.extraContent === 'object' && step.extraContent.text) ||
        typeof step.content === 'string' ? (
          <Typography
            //  className={classes.contentText}
            colorVariant="02"
            color="dark"
          >
            {step.extraContent?.text || step.content}
          </Typography>
        ) : (
          step.content
        )}
      </div>
      <div className={classes.footer}>
        <div className={classes.dots}>
          {dots.map((d, idx) => (
            // to expand clickable area
            <div
              key={idx}
              className={classes.dotContainer}
              onClick={() => setStepIndex(idx)}
            >
              <span
                className={`${classes.dot} ${
                  index === idx ? classes.active : ''
                }`}
              />
            </div>
          ))}
        </div>
        {continuous && index < size - 1 && (
          <Button
            {...primaryProps}
            label="Next"
            colorVariant="filled1"
            iconRight="iconArrowRightWards"
            extraSVGProps={{ svgProps: { viewBox: '-8 -2 24 24' } }}
          />
        )}
        {index === size - 1 && (
          <Button {...closeProps} label="Close" colorVariant="filled1" />
        )}
      </div>
    </div>
  );
};

const createDots = (length: number) => {
  const arr = [];
  for (let index = 0; index < length; index++) {
    arr.push('•');
  }
  return arr;
};
