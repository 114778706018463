import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

/* UI */
import { ModalMenuUI } from '../../../Modal/ModalMenuUI';
import Button from '../../../Buttons/Button';
import { Typography } from '../../../typography/Typography';

/// <Summary>
/// UI for Change email modal
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    fieldSpacing: {
      marginTop: '20px',
      marginBottom: '20px'
    }
  }));

interface Properties {
  submitDisabled: boolean;
  onCloseEvent: () => void;
  onSubmit: (ev: React.FormEvent) => void;
  newEmailRenderProps: () => JSX.Element;
  confirmEmailRenderProps: () => JSX.Element;
  passwordRenderProps?: () => JSX.Element;
  loading: boolean;
  submitError: string;
}

const ChangeEmailModalUI: React.FC<Properties> = ( props ) => {
  const {
    onCloseEvent,
    onSubmit,
    submitDisabled,
    newEmailRenderProps,
    confirmEmailRenderProps,
    passwordRenderProps,
    loading,
    submitError
  } = props;

  const classes = useStyles();

  return (
    <ModalMenuUI
      title={'Change email'}
      loading={loading}
      multiplyContentSpace={7}
      component="form" //important for forms
      extraComponentProps={
        //important for forms
        {
          onSubmit
        }
      }
      onCloseEvent={() => onCloseEvent()}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={() => onCloseEvent()}
          />
          <Button
            disabled={submitDisabled}
            text={'Confirm'}
            colorVariant={'filled1'}
            extraButtonProperties={{ type: 'submit' }} //important for forms
          />
        </>
      }
    >
      <div className={classes.fieldSpacing}>{newEmailRenderProps()}</div>

      <div className={classes.fieldSpacing}>{confirmEmailRenderProps()}</div>

      {passwordRenderProps && (
        <div className={classes.fieldSpacing}>{passwordRenderProps()}</div>
      )}

      {submitError && (
        <Typography color={'danger1'} className={classes.fieldSpacing}>
          {submitError}
        </Typography>
      )}
    </ModalMenuUI>
  );
};

export default ChangeEmailModalUI;
