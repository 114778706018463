import React from 'react';
import {
  makeStyles,
  StyledComponentProps,
  createStyles,
  Theme,
  capitalize
} from '@material-ui/core';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

import { SizeVariations } from '../../themes/custom-theme';

import { colorVariationsElements } from '../../themes/custom-theme';
import { iconFiles } from './files';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// ..
/// </Summary>

export type surfaces = 'onBase' | 'onBrand' | 'onDanger';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex'
      },
      small: {
        width: theme.sizes.icons.small,
        height: theme.sizes.icons.small
      },
      regular: {
        width: theme.sizes.icons.regular,
        height: theme.sizes.icons.regular
      },
      big: {
        width: theme.sizes.icons.big,
        height: theme.sizes.icons.big
      },
      large: {
        width: theme.sizes.iconbutton.small,
        height: theme.sizes.iconbutton.small,
        marginRight: '3px !important' // used for youtube icon
      },
      svgStyling: {},
      // iconColor: ({ color = 'contrast1', surface = 'onBase' }: CallProps) => ({
      //   color: `${theme.customPalette.text[surface][color]}`
      // }),
      ...Object.keys(theme.customPalette.text).reduce((acc, val) => {
        Object.keys(theme.customPalette.text[val]).forEach((col) => {
          acc[`color-${val}-${col}`] = {
            color: `${theme.customPalette.text[val][col]}`
          };
        });
        return acc;
      }, {})
    }),
  { name: 'C3GeneralSVG' }
);

type SvgIconType = typeof SvgIcon;
interface CustomSvgIcon extends SvgIconType {}

interface ComponentProps {
  color?: colorVariationsElements;
  size?: SizeVariations;
  className?: string;
  surface?: surfaces;
  svgProps?: SvgIconProps<CustomSvgIcon, { component?: CustomSvgIcon }>;
}

// interface for calling the component directly.
interface CallProps extends ComponentProps {
  path: string;
}

const GeneralSVG = React.memo((props: CallProps) => {
  const {
    path,
    className,
    color = 'contrast1',
    surface = 'onBase',
    size = 'regular',
    svgProps
  } = props;
  const classes = useStyles(props);
  const { svgStyling } = classes;
  const iconColor = classes[`color-${surface}-${color}`];

  // work around name differences between the cubicle and legacy `Button`
  const Icon = iconFiles[path] || iconFiles[`icon${capitalize(path)}`];

  return (
    <SvgIcon
      classes={{
        root: `${classes[size]} ${iconColor} ${svgStyling} ${className}`
      }}
      {...svgProps}
      component={svgProps?.component ? svgProps.component : Icon}
    />
  );
});

// common icons

export default GeneralSVG;
// use this interface when using a spread operator to spread these properties over another component using GeneralSVG.
export interface GeneralSVGProperties
  extends ComponentProps,
    StyledComponentProps {
  path?: string;
}
