import React from 'react';
import { ModalReceiverProps } from '../../layout/Modals/ModalManager';
import { useModalActions } from '../../layout/Modals/modalActions';
import { compose } from '../../../../utils/component-helpers';

// UI
import { ExampleModalUI } from '@cube3/ui/src/Modal/ExampleModalUI';

interface Properties extends ModalReceiverProps {}

const ExampleModalC: React.FunctionComponent<Properties> = props => {
  // check if generic info was passed while calling openModal
  if (props.modalContext) {
    console.warn('Generic info', props.modalContext);
  }

  const modalActions = useModalActions();

  return (
    <ExampleModalUI
      onClickClose={() => {
        modalActions.closeAllModals();
      }}
    />
  );
};

/** Example modal */
export const ExampleModal = compose(ExampleModalC)();
// place wrappers here
