import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export type Membership = ResourceBase & {};

export const membership: RawModel = {
  type: 'membership',
  plural: 'memberships',
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
