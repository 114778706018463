import React from 'react';
import GenericFieldWithErrors, {
  GenericFieldWithErrorsProps
} from './GenericFieldWithErrors';

interface SingleLineTextFieldProps extends GenericFieldWithErrorsProps {
  errorMessage?: string;
  patern?: boolean;
  paternName?: string;
}

function TextField(props: SingleLineTextFieldProps) {
  let {
    placeholder = 'Enter text',
    errorMessage,
    patern,
    paternName,
    input,
    meta,
    label,
    type,
    showAsyncValid,
    disabled,
    renderTip,
    showErrors = 'touched',
    inputProps,
    multiline = false
  } = props;

  errorMessage = patern
    ? `This does not match the required format. Please enter a valid ${paternName}.`
    : '';

  return (
    <GenericFieldWithErrors
      input={input}
      meta={meta}
      showAsyncValid={showAsyncValid}
      label={label}
      showLabel={false}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      inputProps={inputProps}
      showErrors={showErrors}
      renderTip={renderTip}
      errorMessage={errorMessage}
      multiline={multiline}
    />
  );
}

export default TextField;

interface Variants {
  types:
    | 'regular'
    | 'singleRightIcon'
    | 'twoRightIcons'
    | 'threeRightIcons'
    | 'withLeftIcon'
    | 'withLeftAndRightIcon'
    | 'withMessage'
    | 'withLabel'
    | 'withLabelAndHelpText'
    | 'withLeftLabel'
    | 'withLeftLabelTwoLines'
    | '';
  singleLine: any;
  multiLine: any;
  dropDown: any;
}
