import { ExportOptionRow } from '@cube3/ui/src/exports/subcomponents/ExportOptionRow';
import React, { useEffect } from 'react';

import {
  Field,
  useGenericExportOptions,
  Validator,
  Value,
  ValueTypes
} from '../state/useGenericExportOptions';

import CheckBoxStateless from '@cube3/ui/src/Buttons/CheckBoxStateless';
import CustomRadioButton from '@cube3/ui/src/Buttons/RadioButtonStateless';

import { Scrollbar } from '@cube3/cubicle/src/core/atoms/Scrollbar';
import { Item, VBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import { MultiStringInput } from '@cube3/ui/src/exports/subcomponents/MultiStringInput';
import { add } from '@cube3/ui/src/exports/subcomponents/MultiStringInput/utils/add';

import { remove } from '@cube3/ui/src/exports/subcomponents/MultiStringInput/utils/remove';
import { Divider } from '@cube3/ui/src/Surface/Divider';

interface ExportOptionsFormProps {
  /**
   * change callback that get called whenever something in the form state changes
   */
  onFormChange: (value) => void;
  /**
   * intial form values
   */
  initialValues: Value[];
  /**
   * array of form field configuration objects
   */
  fields: readonly Field[];
  /**
   * array of validator functions
   */
  validators: Validator[];
  /**
   * object with component overrides where each key is a field id and each value a reactcomponent type
   */
  components?: Record<string, React.ComponentType>;
  /**
   * object with component overrides that only get used when a field is disabled, where each key is a field id and each value a reactcomponent type
   */
  disabledComponents?: Record<string, React.ComponentType>;
  /**
   * array of field ids that a divider should be inserted before
   */
  dividers?: string[];
}

/** some typescript magic so we get correct types for form values */
type ExtractStringType<T> = T extends `${infer U}` ? U : never;
type ValueType<T extends keyof ValueTypes> = ValueTypes[ExtractStringType<T>];
type ValueTypeMap<F extends ReadonlyArray<Field>> = [
  ...{ readonly [I in keyof F]: Record<F[I]['id'], ValueType<F[I]['type']>> }
][number];
/**
 * basically this builds a type that intersects an object interface for each field,
 * where the the object looks like {fieldId: valueType}  so you get some like:
 * {title: string} & {price: number} & {recipients: string[]}
 */
type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (
  x: infer I
) => void
  ? I
  : never;
export type ReducedExportOptionsFormState<F extends ReadonlyArray<Field>> =
  UnionToIntersection<ValueTypeMap<F>> & { invalid: boolean };

/**
 * GenericExportOptionsForm
 * used to render actual form fields for the form state managed by `useGenericExportOptions` hook
 */
export const GenericExportOptionsForm = (props: ExportOptionsFormProps) => {
  const {
    fields,
    onFormChange,
    initialValues,
    validators,
    components,
    disabledComponents,
    dividers
  } = props;

  /** TODO: reinitialize when defaults change  */

  const { fieldProps, values, valid } = useGenericExportOptions({
    fields,
    initialValues,
    validators
  });

  useEffect(() => {
    onFormChange({
      invalid: !valid,
      ...values.reduce((acc, val) => {
        return { ...acc, [val.id]: val.value };
      }, {})
    });
  }, [values, valid]);

  return (
    <div>
      {fields.map((f) => {
        const Component =
          f.disabled && disabledComponents?.[f.id]
            ? disabledComponents?.[f.id]
            : components?.[f.id];

        let row;

        switch (f.type) {
          case 'string':
            row = (
              <ExportOptionRow
                {...makeExportOptionRowProps(f, fieldProps)}
                component={
                  Component ? <Component {...fieldProps} /> : undefined
                }
              />
            );

            break;
          case 'checkbox':
            row = (
              <ExportOptionRow
                {...makeExportOptionRowProps(f, fieldProps)}
                component={
                  Component ? (
                    <Component {...fieldProps} />
                  ) : (
                    <Scrollbar
                      autoHeight={true}
                      autoHeightMax={175} // can show about 5.5 items
                    >
                      <VBox>
                        {f.options.map((o) => (
                          <Item key={o.id}>
                            <CheckBoxStateless
                              disabled={f.disabled}
                              checked={fieldProps[f.id].value?.find(
                                (v) => v === o.value
                              )}
                              text={o.display_name}
                              onChange={(c) =>
                                fieldProps[f.id].onChange(
                                  c
                                    ? add(fieldProps[f.id].value, o.value)
                                    : remove(fieldProps[f.id].value, o.value)
                                )
                              }
                            />
                          </Item>
                        ))}
                      </VBox>
                    </Scrollbar>
                  )
                }
              />
            );

            break;
          case 'radio':
            row = (
              <ExportOptionRow
                {...makeExportOptionRowProps(f, fieldProps)}
                component={
                  Component ? (
                    <Component {...fieldProps} />
                  ) : (
                    <Scrollbar
                      autoHeight={true}
                      autoHeightMax={175} // can show about 5.5 items
                    >
                      <VBox>
                        {f.options?.map((o) => (
                          <Item key={o.id}>
                            <CustomRadioButton
                              disabled={f.disabled}
                              checked={o.value === fieldProps[f.id].value}
                              text={o.display_name}
                              onChange={() =>
                                fieldProps[f.id].onChange(o.value)
                              }
                            />
                          </Item>
                        ))}
                      </VBox>
                    </Scrollbar>
                  )
                }
              />
            );

            break;
          case 'multistring':
            row = (
              <ExportOptionRow
                {...makeExportOptionRowProps(f, fieldProps)}
                component={
                  Component ? (
                    <Component {...fieldProps} />
                  ) : (
                    <MultiStringInput
                      disabled={f.disabled}
                      value={fieldProps[f.id].value}
                      onChange={(val) => fieldProps[f.id].onChange(val)}
                      placeholder={fieldProps[f.id].placeholder}
                      isValid={!fieldProps[f.id].errors?.length}
                    />
                  )
                }
              />
            );
        }
        return (
          <React.Fragment key={f.id}>
            {dividers?.includes(f.id) && <Divider gutter={true} />}
            {row}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const makeExportOptionRowProps = (field, fieldProps) => {
  const f = field;
  const fp = fieldProps[f.id];

  return {
    key: f.id,
    input: fp,
    onChange: fp.onChange,
    value: fp.value,
    placeholder: f.placeholder,
    label: f.label,
    required: f.required,
    disabled: f.disabled,
    meta: fieldPropsToMeta(fp),
    showError: fp.touched && fp.errors?.[0].message !== 'Required'
  };
};

const fieldPropsToMeta = (fieldProps) => {
  return {
    error: fieldProps.errors?.filter((e) => e.type === 'error')[0]?.message,
    warning: fieldProps.errors?.filter((e) => e.type === 'warning')[0]?.message,
    touched: fieldProps.touched
  };
};
