import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import Surface from './Surface/Surface';
import audioFileImage from './assets/images/Library/audio.svg?url';
import archiveFileImage from './assets/images/Library/archive.svg?url';
import composeFileImage from './assets/images/Library/compose.svg?url';
import { useClassName } from './utils/useClassName';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 *
 */
//
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*': {
        userSelect: 'none'
      },
      'input, input *, textarea, textarea *, pre, pre *, code, code *, [data-text-selectable], [data-text-selectable] *': {
        userSelect: 'text'
      },
      [`img[src="${audioFileImage}"], img[src="${composeFileImage}"], img[src="${archiveFileImage}"]`]: {
        filter: 'hue-rotate(185deg) grayscale(0.6)'
      },
      '::selection': {
        background: theme.customPalette.surfaceAccent.onBase.selected2
      }
    },
    root: {
      display: 'flex',
      zIndex: -1,
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      left: 0,
      overflowY: 'hidden',
      overflowX: 'hidden',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box'
    },
    mainChildren: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      height: '100%',
      color: theme.customPalette.text.onBase.contrast2,
      fontFamily: `${theme.typography.fontFamily}, sans-serif`,
      transition: 'transform 0.2s ease-out, height 0s ease-in',
      '&$showVersion': {
        height: 'calc(100% - 50px)',
        transition: 'transform 0.2s ease-out, height 0.2s ease-in'
      }
    },
    appNotification: {
      position: 'absolute',
      top: -56,
      left: 0,
      display: 'flex',
      width: '100%',
      transition: 'transform 0.5s ease-in 0.2s, opacity 0.1s linear',
      zIndex: 100,
      opacity: 0,
      pointerEvents: 'none',
      '&$showVersion': {
        transition: 'transform 0.5s ease-in, opacity 0.2s linear 0.3s',
        opacity: 1,
        pointerEvents: 'all'
      }
    },
    showVersion: {
      transform: 'translate(0px, 50px)'
    },
    cookieConsent: {
      display: 'flex',
      width: '100%'
    },
    // '@keyframes glitch': {
    //   '0%': {
    //     transform: 'skew(0deg, 0deg)'
    //   },
    //   '25%': {
    //     transform: 'skew(-0.2deg, 0deg)'
    //   },
    //   '50%': {
    //     transform: 'skew(0.4deg, 0deg)'
    //   },
    //   '75%': {
    //     transform: 'skew(-0.3deg, 0deg)'
    //   },
    //   '100%': {
    //     transform: 'skew(0deg, 0deg)'
    //   }
    // },
    offline: {
      filter: 'saturate(0.3)',
      opacity: 0.3,
      cursor: 'wait',
      '& * ': {
        pointerEvents: 'none !important'
      },
      // backgroundImage:
      //   'linear-gradient(0deg, rgba(22,22,22,1), rgba(22,22,22,0.9)),repeating-linear-gradient(to right,rgb(0, 255, 255) 0%,rgb(0, 255, 255) 16.6%,rgb(255, 0, 255) 16.6%,rgb(255, 0, 255) 33.2%,rgb(0, 0, 255) 33.2%,rgb(0, 0, 255) 49.8%,rgb(255, 255, 0) 49.8%,rgb(255, 255, 0) 66.4%,rgb(0, 255, 0) 66.4%,rgb(0, 255, 0) 83%,rgb(223, 19, 44) 83%,rgb(223, 19, 44) 100%)',
      // backgroundSize: '25% 100%',
      // transformOrigin: '50% 0%',
      // animation: 'glitch 0.2s infinite',
      // animationName: '$glitch'
      background: '#333',
      position: 'relative',
      '&:after': {
        display: 'block',
        position: 'fixed',
        width: '100%',
        height: '100%',
        content: '""',
        backgroundImage:
          'linear-gradient(0deg, rgba(255,0,0,0.0), rgba(128,128,128,0.8) 100%)',
        backgroundSize: '100% 100%',
        opacity: 0.5,
        mixBlendMode: 'luminosity',
        zIndex: 2500
      }
    }
  })
);

interface DeskProps {
  cookieConsentComponent?: JSX.Element;
  appNotificationComponent?: JSX.Element;
  showVersionBanner?: boolean;
  offline?: boolean;
}

const Desk = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<DeskProps>
>((props, ref) => {
  const {
    offline,
    showVersionBanner,
    cookieConsentComponent,
    appNotificationComponent,
    children
  } = props;

  const classes = useStyles();

  return (
    <Surface
      background={'base1'}
      gradient={'lamp'}
      className={classes.root}
      {...props}
    >
      <div
        className={useClassName(
          classes.appNotification,
          showVersionBanner && classes.showVersion,
          offline && classes.showVersion
        )}
      >
        {appNotificationComponent}
      </div>

      <div
        className={useClassName(
          classes.mainChildren,
          showVersionBanner && classes.showVersion,
          offline && classes.showVersion,
          offline && classes.offline
        )}
        ref={ref}
      >
        {children}
      </div>

      {cookieConsentComponent && (
        <div className={classes.cookieConsent}>{cookieConsentComponent}</div>
      )}
    </Surface>
  );
});

export default Desk;
