import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useTeamSuggestions } from '../../AdminCenter/views/WorkspaceTeamsView/hooks/useTeamSuggestions';
import { PROJECT_ROLE_TYPES } from '@cube3/common/model/types';

type ProjectTeamAdmins = {
  [teamId: string]: number;
};
interface ProjectTeamAdminsContextType {
  /** List of teams with admin role in this project */
  projectTeamAdmins: ProjectTeamAdmins;
  allAdminsCount: number;
  addAdminsViaTeam?: (teamId: string, mmebers: number) => void;
}

export const ProjectTeamAdminsContext =
  createContext<ProjectTeamAdminsContextType>(null);

export const ProjectTeamAdminsContextProvider = (props) => {
  const { projectId, accountAdmins, hasTeamFeature } = props;

  const [projectTeamAdmins, setProjectTeamAdmins] =
    React.useState<ProjectTeamAdmins>({});

  const projectTeamMemberships = useResourceList__ALPHA({
    edgeType: 'project-team-membership',
    edgeLabel: 'project-team-memberships',
    params: {
      filter: { project: projectId }
    },
    strategy: 'fetch-on-mount'
  }).resources;

  const addAdminsViaTeam = useCallback(
    (teamId: string, membersCount: number) => {
      setProjectTeamAdmins((prev) => {
        if (prev && prev[teamId] !== membersCount) {
          if (
            prev[teamId] &&
            (membersCount === null || membersCount === undefined)
          ) {
            const next = { ...prev };
            delete next[teamId];
            return next;
          }

          return {
            ...prev,
            [teamId]: membersCount
          };
        } else {
          return prev;
        }
      });
    },
    [setProjectTeamAdmins]
  );

  const allAdminsCount = hasTeamFeature
    ? accountAdmins +
      (projectTeamAdmins ? Object.keys(projectTeamAdmins).length : 0)
    : accountAdmins;

  const adminsContext = React.useMemo(() => {
    return {
      projectTeamAdmins,
      addAdminsViaTeam,
      allAdminsCount
    };
  }, [addAdminsViaTeam, projectTeamAdmins, allAdminsCount]);

  return (
    <ProjectTeamAdminsContext.Provider value={adminsContext}>
      {props.children}
      {projectTeamMemberships &&
        projectTeamMemberships.map((res) => (
          <TeamMembersDataComponent
            key={res.id}
            teamId={res.relationships.team.id}
            role={res.project_member_role}
          />
        ))}
    </ProjectTeamAdminsContext.Provider>
  );
};

const TeamMembersDataComponent = ({ teamId, role }) => {
  const { members, loading } = useTeamSuggestions(teamId);
  const { addAdminsViaTeam } = useContext(ProjectTeamAdminsContext);

  useEffect(() => {
    if (
      !loading &&
      members &&
      !!members.length &&
      role === PROJECT_ROLE_TYPES.Admin
    ) {
      addAdminsViaTeam(teamId, members?.length);
    }
  }, [addAdminsViaTeam, loading, members, role, teamId]);

  useEffect(() => {
    // do nothing but return clean up function
    return () => {
      addAdminsViaTeam(teamId, null);
    };
  }, [addAdminsViaTeam, teamId]);

  return null;
};
