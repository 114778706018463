import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Organization extends ResourceBase {
  display_name: string;
  relationships: {
    workspace: Related<'workspace'>;
  };
}

export const organization: RawModel = {
  type: 'organization',
  plural: 'organizations',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    display_image: 'URL'
  },
  relationships: {
    workspaces: {
      type: 'workspace',
      binding: ['many', 'one'],
      reverse: 'organization',
      required: [true, 1]
    }
  }
};
