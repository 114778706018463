/** return the resolution of the latest comment */
export const getCommentResolution = (replies) => {
  if (!replies?.length) return undefined;
  const sorted = [...replies].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return sorted[0].resolution;
};
