import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography, TypographyProps } from '../typography/Typography';

/// @Author : Simon @ AmbassadorsLAB
/// <Summary>
/// Renders a Custom typography component with a
/// styled background.
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      ...theme.typographyStyles.heading4,
      display: 'inline-block', // to only take needed width and not stretch over entire div
      backgroundColor: theme.customPalette.primary.light,
      padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px`,
      borderRadius: theme.surfaceCorners.circular,
      fontWeight: 700,
      wordWrap: 'normal',
      wordBreak: 'keep-all',
      marginLeft: 4,
      marginRight: 4
    }
  }));

interface Properties {
  text: string;
  extraTypographyProps?: TypographyProps;
}

const Label: React.FC<Properties> = (props) => {
    const { text, extraTypographyProps } = props;

    const classes = useStyles();

    return (
      <Typography className={classes.label} {...extraTypographyProps}>
        {text}
      </Typography>
    );
  };

export default Label;
