import { mutations } from '../../../ducks/resource-edges';
import Client from '../../../../wrapped-cube-client';
import { getChanges } from './utils/getChanges';

export const handleAttachedResourceChanges = ({
  initialValues,
  values,
  contractId
}) => {
  return (res) => {
    const assetChanges = getChanges(
      initialValues.key_attached_assets,
      values.key_attached_assets
    );
    const projectChanges = getChanges(
      initialValues.key_attached_projects,
      values.key_attached_projects
    );

    const promises = [];

    if (assetChanges?.length) {
      assetChanges.forEach((m) => {
        switch (m.mutationType) {
          case mutations.MUTATION_ADD:
            promises.push(
              Client.addRelated(
                'asset',
                {
                  id: contractId,
                  type: 'contract',
                  field: 'assets'
                },
                { ...m.resource },
                true
              )
            );
            break;
          case mutations.MUTATION_REMOVE:
            promises.push(
              Client.removeRelated(
                'asset',
                {
                  id: contractId,
                  type: 'contract',
                  field: 'assets'
                },
                { ...m.resource },
                true
              )
            );
            break;
          default:
            break;
        }
      });
    }

    if (projectChanges?.length) {
      projectChanges.forEach((m) => {
        switch (m.mutationType) {
          case mutations.MUTATION_ADD:
            promises.push(
              Client.addRelated(
                'project',
                {
                  id: contractId,
                  type: 'contract',
                  field: 'projects'
                },
                { ...m.resource },
                true
              )
            );
            break;
          case mutations.MUTATION_REMOVE:
            promises.push(
              Client.removeRelated(
                'project',
                {
                  id: contractId,
                  type: 'contract',
                  field: 'projects'
                },
                { ...m.resource },
                true
              )
            );
            break;
          default:
            break;
        }
      });
    }
    return Promise.all(promises);
  };
};
