import { createStyles, makeStyles } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import EmailBox from '../../../forms/EmailBox';
import {
  Free,
  HBox,
  Item
} from '@cube3/cubicle/src/core/templates/layout/Flex';
import { Chip } from '../../../chips/Chip';
import Cube3TextField from '../../../forms/textfields/Cube3TextField';
import { add } from './utils/add';
import { remove } from './utils/remove';
import { handleKeyDown } from './utils/handleKeyDown';

// render internal state as json underneath component
const DEBUG = false;

/** Input field for adding multiple string entries to an array.
 *  Valid items added by pressing enter or space, or by removing focus from the field.
 *  They then get displayed as "chips" that can be removed.
 *  Supports validating the entire input including valid segments and the one that hasn't been added yet
 *  Example use would be adding recipient email addresses.
 */
interface MultiStringInputProps {
  /**
   * Array of string entries, including the entry that is currently being typed. The component handles separating the in progress value"
   */
  value: string[];
  /** change handler callback, that expects an array of string as values, including the entry that is currently being typed */
  onChange(val: string[]): void;
  /** placeholder value the input should show when the field is empty */
  placeholder: string;
  /** whether the component should present an error state, i.e. red outline */
  isValid?: boolean;
  /** whether fields should accept input */
  disabled?: boolean;
}

// some tweaks to align the input field inside the container
const useMultiStringInputStyles = makeStyles(() =>
  createStyles({
    inputField: {
      border: 'none',
      background: 'inherit',
      marginTop: '-4px',
      height: 'auto',
      minHeight: 40,
      padding: 8
    }
  })
);

const emptyArray = [];

/** Component */
export const MultiStringInput = (props: MultiStringInputProps) => {
  const {
    value = emptyArray,
    onChange,
    placeholder,
    isValid,
    disabled
  } = props;
  // entry currently being typed
  const [inputValue, setInputValue] = useState('');
  // previous entries that have been added to the array
  const [itemsValue, setItemsValue] = useState([]);

  // callbacks for the handleKeyDown helper
  const onAdd = useCallback(
    (item) => setItemsValue((cur) => add(cur, item)),
    [setItemsValue]
  );
  const onDelete = useCallback(
    (item) => setItemsValue((cur) => remove(cur, item)),
    [setItemsValue]
  );

  /**
   * handles logic for starting new array entry on certain actions
   * like pressing enter or blurring focus
   */
  const handleCommit = useCallback(
    (evt) => {
      return handleKeyDown({
        evt,
        inputValue,
        values: itemsValue || [],
        onClear: () => {
          setInputValue('');
        },
        onAdd,
        onDelete,
        isValid: isValid
      });
    },
    [inputValue, itemsValue, setInputValue, onAdd, onDelete, isValid]
  );

  /**
   * handles regular typing input
   */
  const handleChange = useCallback(
    (e) => {
      e.type !== 'blur' &&
        setInputValue(() => {
          return e.target.value.trim();
        });
    },
    [setInputValue]
  );

  /** trigger the onChange callback */
  useEffect(() => {
    // combine "submited" segments and current input into one array
    const newValue = [...itemsValue, inputValue].filter(Boolean);

    // only trigger handler if new array contents differ from current value
    // NOTE: this prevents an infinite loop between the two effects
    if (
      newValue.length !== value.length ||
      newValue.some((a, i) => a !== value[i])
    ) {
      onChange(newValue);
    }
  }, [itemsValue, inputValue]);

  /** trigger update of internal "submited" segments state when value prop changes */
  useEffect(() => {
    // take last entry in array
    const last = value?.[value.length - 1];

    // if this matches the input value, it should be stripped off
    if (last === inputValue) {
      const slice = value.slice(0, -1);
      // only update the items, if the array contents differ
      // NOTE: this prevents an infinite loop between the two effects
      if (slice.some((a, i) => a !== itemsValue[i])) {
        setItemsValue(slice);
      }
    } else {
      setItemsValue(value);
    }
  }, [value]);

  // styles
  const classes = useMultiStringInputStyles();
  const classesObj = useMemo(() => {
    return { root: classes.inputField };
  }, [classes]);

  return (
    <>
      <EmailBox errors={!isValid}>
        <HBox
          style={{ flexWrap: 'wrap', justifyContent: 'start', width: '100%' }}
        >
          {itemsValue?.map((val, idx, arr) => {
            //component(val, onDelete))
            return (
              <Item key={val}>
                <Chip
                  maxWidth={490}
                  text={val}
                  onCrossClick={() => onDelete(val)}
                />
              </Item>
            );
          })}

          <Free>
            <Cube3TextField
              disabled={disabled}
              value={inputValue}
              placeholder={placeholder}
              fullWidth={true}
              classes={classesObj}
              onChange={handleChange}
              onBlur={handleCommit}
              onKeyDown={handleCommit}
            />
          </Free>
        </HBox>
      </EmailBox>
      {DEBUG && (
        <pre>
          {JSON.stringify(
            {
              inputValue,
              itemsValue,
              value
            },
            null,
            2
          )}
        </pre>
      )}
    </>
  );
};
