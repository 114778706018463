import { ProjectRoleType, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface ProjectTeamMembership extends ResourceBase {
  type: 'project-team-membership';
  project_member_role: ProjectRoleType;
  relationships: {
    project: Related<'project'>;
    team: Related<'team'>;
  };
}

export const projectTeamMembership: RawModel = {
  type: 'project-team-membership',
  plural: 'project-team-memberships',
  JSONApi: true,

  relationships: {
    project: {
      type: 'project',
      binding: ['one', 'many'],
      reverse: 'project-team-membership'
    },
    team: {
      type: 'team',
      binding: ['one', 'many'],
      reverse: 'project-team-membership'
    }
  }
};
