import React, { useCallback } from 'react';

import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { Padding } from '../../../../../../app/layout/Contracts/prefabs/Padding';
import SupportEmailComponent from '@cube3/ui/src/Email/SupportEmailComponent';

export const ReqeuestAccessNudge = props => {
  const { user, workspace, name } = props;

  const handleRequestAccess = useCallback(() => {
    if (!user || !workspace || !name) {
      return;
    }
    window.open(
      encodeURI(
        `mailto:support@cube-cloud.com?subject=Request: ${name} integration for "${workspace.display_name}" workspace&body=Hi Cube support,
    
I would like to request access to the ${name} integration for the ${workspace.display_name} workspace (${workspace.id}).
            
Regards,
${user.full_name}`
      ),
      '_blank',
      'noopener noreferrer'
    );
  }, [name, workspace, user]);

  return (
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        padding: '24px 32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start'
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <Button colorVariant="filled1" size="big" onClick={handleRequestAccess}>
          Request access
        </Button>
      </div>
      <Padding>
        <Typography variant="heading2">Connect to {name}</Typography>
        <Typography variant="body2" color="contrast2">
          Contact <SupportEmailComponent /> to find out options available for your
          workspace.
        </Typography>
      </Padding>
    </div>
  );
};
