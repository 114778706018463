import React from 'react';

import { makeValidator } from '../../../../../../forms/helpers/validators';
import { EditSettingModal } from './EditSettingsModal';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback } from 'react';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useEffect } from 'react';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

type Props = {};

export const expirationValidator = makeValidator((val) =>
  val < 1
    ? 'Should be 1 day or more'
    : val > 366
    ? 'Should be less then 1 year'
    : undefined
);
const formId = 'edit_default_shares_expiration_duration';

export const EditShareExpirationDurationModal: React.FC<Props> = () => {
  const workspace = useCurrentWorkspace()[1];
  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    actionDecorators: [addFormId(formId, { delegate: true })],
    cacheInvalidator: null
  });

  const { previousModal } = useModalActions();

  const handleSave = useCallback(
    (data) => {
      mutate({
        type: 'workspace',
        id: workspace?.id,
        ...data
      });
    },
    [mutate, workspace]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  return (
    <EditSettingModal
      onSave={handleSave}
      loading={!workspace || mutateStatus === statuses.IN_FLIGHT}
      form={formId}
      initialValues={{
        default_shares_expiration_duration:
          workspace.default_shares_expiration_duration
      }}
      config={{
        fields: [
          {
            type: 'number',
            name: 'default_shares_expiration_duration',
            value_type: 'number',
            validate: expirationValidator,
            label: 'Days to expiration',
            parse: (val) => parseInt(val.toString())
          }
        ]
      }}
      title={'Expiration period default'}
    />
  );
};
