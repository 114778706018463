import { generatePath } from 'react-router-dom';
import { statuses } from '../../../redux/ducks/request-status';
import { Project } from '@cube3/common/model/schema/resources/project';
import { useCurrentWorkspace } from '../Administration/withCurrentWorkspace';
import { useCurrentShareLink } from './useCurrentShareLink';
import { useResource__ALPHA } from './useResource';
import { useResourceList__ALPHA } from './useResourceList';
import { urlStructureUnauthorized } from '../../../../../main/src/components/app/routing/routingPaths';
import { ErrorTypes } from '../../../wrapped-cube-client';

interface UseNodePathConfig {
  resourceId: string; // the ID of the resource we are building a path for
  deleted?: boolean; // flag that if set to true build a path to the trash bin
  includeResource?: boolean; // if set to true includes the resource type and ID to the path after the context
  includeDetail?: boolean; // if set to true appends '/detail' to open an asset in detail view
  includeParent?: boolean; // if set to true appends parent folder
  edgeResourceType?: string; // the type of an edge, e.g /workspace/1234/folder/4321/<edgeResourceType>
  edgeResourceId?: string; // the ID of an edge, e.g /workspace/1234/folder/4321/<edgeResourceType>/<edgeResourceId>
}

export const useContextPath = ({
  resourceId = undefined,
  deleted = false,
  includeResource = true,
  includeDetail = true,
  includeParent = false,
  edgeResourceId,
  edgeResourceType
}: UseNodePathConfig) => {
  const [workspaceID] = useCurrentWorkspace();
  const shareId = useCurrentShareLink(true)?.id;
  const {
    first: node,
    status: retrieveNodeStatus,
    errors: retrieveNodeErrors
  } = useResource__ALPHA({
    resourceId: resourceId,
    resourceType: 'content-tree-node'
  });

  const resource = useResource__ALPHA({
    resourceId: includeResource && node?.relationships.resource.id,
    resourceType: node?.relationships.resource.type
  }).first;

  const allPojectTemplates = useResourceList__ALPHA({
    resourceId: workspaceID,
    resourceType: 'workspace',
    edgeType: 'project-template',
    edgeLabel: 'project-templates'
  }).resources;

  const { resources: ancestors, status: parentStatus } = useResourceList__ALPHA(
    {
      resourceId: node?.relationships.resource.id,
      resourceType: node?.relationships.resource.type,
      edgeType: node && includeParent ? 'folder' : undefined,
      edgeLabel: node && includeParent ? 'ancestors' : undefined
    }
  );

  if (retrieveNodeStatus === statuses.FAILED) {
    const path = generatePath(urlStructureUnauthorized, {
      workspaceId: workspaceID
    });
    return [path, retrieveNodeErrors] as [string, ErrorTypes];
  }

  if (
    !node ||
    (includeResource && !resource) ||
    (includeParent && parentStatus !== statuses.SUCCESS) ||
    !allPojectTemplates
  ) {
    return [undefined, undefined] as [undefined, undefined];
  }

  if (deleted) {
    const path = generatePath('/workspace/:workspaceId/bin', {
      workspaceId: workspaceID
    });
    return [path, undefined] as [string, undefined];
  }
  let path = '';

  if (shareId) {
    path = generatePath('/share-link/:shareId/', {
      shareId
    });
  }

  if (workspaceID) {
    // eslint-disable-next-line prefer-const
    let { type: scope, id: scopeId } = node.relationships.context;
    // use library id
    if (scope === 'library' && allPojectTemplates) {
      scopeId = node.relationships.context.id;
    }
    /** if true should redirect to project template view instead of project view */
    const isProjectTemplate =
      scope === 'project' &&
      allPojectTemplates?.find(
        (ty) => ty.relationships.donor_project?.id === scopeId
      );

    path = isProjectTemplate
      ? generatePath(
          '/workspace/:workspaceID/admin-center/project-templates/project/:projectId',
          {
            workspaceID,
            projectId: scopeId
          }
        )
      : generatePath(
          '/workspace/:workspaceID/:scope(project|library)/:scopeId',
          {
            workspaceID,
            scope,
            scopeId
          }
        );
  }

  if (includeResource && node.relationships.ancestors?.length) {
    path = generatePath(`${path}/:resourceType/:resourceId`, {
      resourceType: resource.type === 'project' ? 'folder' : resource.type,
      resourceId:
        resource.type === 'project'
          ? (resource as Project).relationships?.node.id
          : resource.id
    });
  }

  // NOTE: this should not be needed, but folders get wrong ancestors
  const parentOffset = node.relationships.context.type === 'project' ? 1 : 0;

  if (includeParent && ancestors.length > parentOffset) {
    const parent = ancestors.slice(-1)[0];
    if (parent.type === 'folder') {
      path = generatePath(`${path}/folder/:parentId`, {
        parentId: parent.id
      });
    }
  }

  if (edgeResourceType) {
    path = generatePath(`${path}/:edgeType`, {
      edgeType: edgeResourceType
    });
  }

  if (edgeResourceId) {
    path = generatePath(`${path}/:edgeId`, {
      edgeId: edgeResourceId
    });
  }

  if (
    includeDetail &&
    includeResource &&
    node.relationships.resource.type === 'asset'
  ) {
    path = generatePath(`${path}/detail`);
  }
  return [path, undefined] as [string, undefined];
};
