import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const bitesFormat = {
  B: 'bit/s',
  KB: 'kbit/s',
  MB: 'Mbit/s',
  GB: 'Gbit/s',
  TB: 'Tbit/s'
  // PB: 1000e12,
  // EB: 1000e15,
  // ZB: 1000e18,
  // YB: 1000e21
};

const lut = {
  B: 1,
  KB: 1000,
  MB: 1000e3,
  GB: 1000e6,
  TB: 1000e9
  // PB: 1000e12,
  // EB: 1000e15,
  // ZB: 1000e18,
  // YB: 1000e21
};

const lut1024 = {
  KiB: 1024,
  MiB: Math.pow(1024, 2),
  GiB: Math.pow(1024, 3),
  TiB: Math.pow(1024, 4)
  // PiB: Math.pow(1024, 5),
  // EiB: Math.pow(1024, 6),
  // ZiB: Math.pow(1024, 7),
  // YiB: Math.pow(1024, 8)
};

interface BitrateProps {
  toolTip: boolean;
  size: string;
  placement: any;
  fullForm: boolean;
  inFormat: 'B' | 'KB' | 'MB' | 'GB' | 'TB';
}

export default function Bitrate(props: BitrateProps) {
  const { placement, toolTip, size = '0', fullForm, inFormat } = props;

  // try to parse the given value size into a number.
  const bytes = Number(size) * (lut[inFormat] || lut1024[inFormat] || lut.B);

  // define the possible outputs of the component.
  let sizeSmallForm;
  let sizeFullForm;
  let format;

  // check if parseint was succesfull by checking if the number is not NaN.
  if (!Number.isNaN(bytes)) {
    if (!fullForm) {
      if (bytes === 0) {
        format = 'B';
      } else {
        format = Object.keys(lut)
          .filter((k) => lut[k] <= bytes)
          .reverse()[0];
      }
      sizeSmallForm = bytes / lut[format];
      sizeSmallForm = Math.round(sizeSmallForm * 100) / 100;
    }

    sizeFullForm = bytes
      .toString()
      .split('')
      .reverse()
      .join('')
      .split(/(\d{3})/)
      .filter((c) => c)
      .join(' ')
      .split('')
      .reverse()
      .join('')
      .concat(' bit per second');
  } else {
    return <span>Size unavailable</span>;
  }

  return (
    <Tooltip
      title={sizeFullForm}
      placement={placement}
      disableFocusListener={true}
      disableTouchListener={true}
      disableHoverListener={!toolTip}
    >
      {fullForm ? (
        <span>{sizeFullForm}</span>
      ) : (
        <span>{`${sizeSmallForm} ${bitesFormat[format]}`}</span>
      )}
    </Tooltip>
  );
}
