import React, { useEffect, useReducer } from 'react';
import { Typography } from '@cube3/ui/src/typography/Typography';

import { ExportInputRadios } from '@cube3/ui/src/exports/subcomponents/ExportInputRadios';
import { ExportInputPicker } from '@cube3/ui/src/exports/subcomponents/ExportInputPicker';
// import { ExportInputSelect } from '@cube3/ui/src/exports/subcomponents/ExportInputSelect';
import { Tag } from '@cube3/ui/src/tags/Tag';
import {
  ExportOptionsAction,
  exportOptionsReducer,
  initialExportOptionsState,
  visibilities
} from '../../../Exports/state/exportOptionsState';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { validEmailaddress } from '../../../../../forms/helpers/validators';
import { Chip } from '@cube3/ui/src/chips/Chip';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

// TODO: make dynamic form configurations work
const getFormConfig = targetName => {
  switch (targetName) {
    case 'YouTube':
      return 'YouTubeForm';
    default:
      return null;
  }
};

export const ConfigureIntegrationView = ({
  // categories,
  defaults = initialExportOptionsState,
  onFormChange,
  exportTargetTypeId
}) => {
  const exportTargetType = useResource__ALPHA({
    resourceType: 'export-target-type',
    resourceId: exportTargetTypeId
  }).first;

  const form = getFormConfig(exportTargetType?.attributes.display_name);

  const [formValue, dispatch] = useReducer(exportOptionsReducer, defaults);

  useEffect(() => {
    dispatch({
      type: 'REINITIALIZE',
      payload: defaults
    } as ExportOptionsAction<'REINITIALIZE'>);
  }, [defaults, dispatch]);

  useEffect(() => {
    onFormChange({
      ...formValue,
      dirty: JSON.stringify(formValue) !== JSON.stringify(defaults)
    });
  }, [formValue, onFormChange, defaults]);

  const { visibility, tags, recipients } = formValue;

  if (!form) {
    return (
      <>
        <Typography typographyStyle="heading2" gutter={true}>
          Settings
        </Typography>
        <Typography typographyStyle="body2" color="contrast2">
          This integration does not offer any settings
        </Typography>
      </>
    );
  }

  if (form === 'YouTubeForm') {
    return (
      <>
        <Typography typographyStyle="heading2" gutter={true}>
          Default settings
        </Typography>
        <Typography typographyStyle="body2" color="contrast2">
          These settings will be used by default when exporting videos
        </Typography>

        <div style={{ margin: '24px 16px 24px -80px' }}>
          <ExportInputRadios
            type="radio"
            label="Visibility"
            value={visibility}
            radios={visibilities}
            onChange={val =>
              dispatch({
                type: 'CHANGE_VISIBILITY',
                payload: val.toLowerCase()
              })
            }
          />
          <ExportInputPicker
            label="Tags"
            type="multiple input"
            items={tags}
            placeholder="Enter Tags"
            onAdd={val => dispatch({ type: 'ADD_TAG', payload: val })}
            onDelete={val => dispatch({ type: 'DELETE_TAG', payload: val })}
            component={val => (
              <Tag
                text={val}
                onCrossClick={() =>
                  dispatch({ type: 'DELETE_TAG', payload: val })
                }
              />
            )}
          />

          {/* <ExportInputSelect
          label="Category"
          placeholder="Select a category"
          type="select"
          value={category}
          required={true}
          options={categories}
          onChange={val => {
            dispatch({
              type: 'ADD_CATEGORY',
              payload: val
            });
          }}
        /> */}

          <Divider gutter={true} />
          <ExportInputPicker
            label="Notify"
            type="multiple input"
            items={recipients}
            placeholder="Enter email address"
            validator={val => validEmailaddress(val, null, null, null)}
            onAdd={val => dispatch({ type: 'ADD_RECIPIENT', payload: val })}
            onDelete={val =>
              dispatch({ type: 'DELETE_RECIPIENT', payload: val })
            }
            component={val => (
              <Chip
                maxWidth={370}
                text={val}
                onCrossClick={() =>
                  dispatch({ type: 'DELETE_RECIPIENT', payload: val })
                }
              />
            )}
          />
        </div>
      </>
    );
  }
};
