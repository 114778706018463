import { selectors as nodeSelectors } from '../resource-nodes';
import { makeSelector } from '@taskworld.com/rereselect';
import { serializeLabel, getKeys, getPage } from './utils';
import { FLAT } from './state';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { makeParameterizedSelector } from '../../utils/makeParameterizedSelector';

// Selectors
const getResourceEdgesByLabel = (
  state,
  resourceId,
  resourceType,
  edgeLabel,
  edgeType,
  params?
) => {
  const { parentKey, relationKey } = getKeys(
    resourceId,
    resourceType,
    edgeLabel,
    params
  );
  const pages = getPagesByRelation(state, parentKey, relationKey);
  return (
    pages &&
    Object.keys(pages)
      .map((k) => pages[k])
      .flatMap((p) => p && p.edges)
      .filter((e) => !!e)
  );
};

const getResourceEdgesByLabelMemoSelector = makeParameterizedSelector(
  'getResourceEdgesByLabelMemo',
  (resourceId, resourceType, edgeLabel, edgeType, params?) => {
    return (query) => {
      const { parentKey, relationKey } = getKeys(
        resourceId,
        resourceType,
        edgeLabel,
        params
      );

      const pages = query((state) => {
        return getPagesByRelation(state, parentKey, relationKey);
      });

      const edges =
        pages &&
        Object.keys(pages)
          .map((k) => pages[k])
          .flatMap((p) => p && p.edges)
          .filter((e) => !!e);
      return edges;
    };
  },
  (resourceId, resourceType, edgeLabel, edgeType, params?) => {
    const { parentKey, relationKey } = getKeys(
      resourceId,
      resourceType,
      edgeLabel,
      params
    );
    return `${parentKey}-${relationKey}`;
  }
);

const getRelationsCountMemo = makeParameterizedSelector(
  'getRelationsCount',
  (resourceId, resourceType, edgeLabel, params?) => {
    return (query) => {
      const parentKey =
        resourceId && resourceType ? `${resourceType}__${resourceId}` : FLAT;
      const relationKey = serializeLabel(edgeLabel, params);

      if (!relationKey) {
        console.warn('no relationKey');
      }
      const rel = query(
        (state) => state.resourceEdges[parentKey]?.[relationKey]
      );

      const count = rel
        ? {
            itemCount: rel.itemCount,
            pageCount: rel.pageCount
          }
        : undefined;

      return count;
    };
  },
  (resourceId, resourceType, edgeLabel, params?) => {
    return `${resourceId}-${resourceType}-${serializeLabel(edgeLabel, params)}`;
  }
);

export const getResourceEdgesByLabelMemo = (
  state,
  resourceId,
  resourceType,
  edgeLabel,
  edgeType,
  params?
) =>
  getResourceEdgesByLabelMemoSelector(
    resourceId,
    resourceType,
    edgeLabel,
    edgeType,
    params
  )(state);

const getResourceEdgesByRelation = (
  state,
  parentKey = FLAT,
  relationKey,
  pageNumber = undefined
) => {
  if (!relationKey) {
    console.warn('no relationKey');
  }
  const rel = state.resourceEdges[parentKey]?.[relationKey];

  return rel ? getPage(rel, pageNumber)?.edges : undefined;
};

const getPagesByRelation = (state, parentKey = FLAT, relationKey) => {
  if (!relationKey) {
    console.warn('no relationKey');
  }
  const rel = state.resourceEdges[parentKey]?.[relationKey];

  return rel?.pages;
};

const getRelationCounts = (
  state,
  resourceId,
  resourceType,
  edgeLabel,
  params?
) => {
  return getRelationsCountMemo(
    resourceId,
    resourceType,
    edgeLabel,
    params
  )(state);
};

const getCursors = (state, resourceId, resourceType, edgeLabel, params) => {
  return getCursorsMemo(resourceId, resourceType, edgeLabel, params)(state);
};

const getCursorsMemo = makeParameterizedSelector(
  'getCursor',
  (resourceId, resourceType, edgeLabel, params?) => {
    return (query) => {
      const parentKey =
        resourceId && resourceType ? `${resourceType}__${resourceId}` : FLAT;
      const relationKey = serializeLabel(edgeLabel, params);

      if (!relationKey) {
        console.warn('no relationKey');
      }
      const rel = query(
        (state) => state.resourceEdges[parentKey]?.[relationKey]
      );

      return query((state) => rel?.cursors);
    };
  },
  (resourceId, resourceType, edgeLabel, params?) => {
    return `${resourceId}-${resourceType}-${serializeLabel(edgeLabel, params)}`;
  }
);

/** memoize return value for when an edge points to a missing node */
const deletedEdges = new Map();
const getDeletedEdge = (edge) => {
  const key = `${edge.type}-${edge.id}`;
  let de = deletedEdges.get(key);
  if (de) {
    return de;
  }
  console.warn(
    'no stub node for edge - marking as deleted (logged once per edge)',
    JSON.stringify(edge)
  );
  de = { ...edge, __deleted__: true };
  deletedEdges.set(key, de);
  return de;
};

const getResourceEdgeStubs = (state, edges) => {
  const stubs =
    edges &&
    edges
      .map((e) => {
        const node = nodeSelectors.getResourceById(state, e.type, e.id);
        if (!node) {
          return getDeletedEdge(e);
        } else if (node.__deleted__) {
          console.warn('deleted node - removing edge from results');
          return node;
        } else {
          return node;
        }
      })
      .filter((s) => !s.__deleted__);

  return stubs;
};

export const getResourceEdgeStub = (state, edge) => {
  const node = nodeSelectors.getResourceById(state, edge.type, edge.id);
  if (!node) {
    return getDeletedEdge(edge);
  } else if (node.__deleted__) {
    console.warn('deleted node - removing edge from results');
    return node;
  } else {
    return node;
  }
};

const getResourceEdgeStubsByLabel = (
  state,
  resourceId,
  resourceType,
  edgeLabel,
  params?
) => {
  const parentKey =
    resourceId && resourceType ? `${resourceType}__${resourceId}` : FLAT;
  const relationKey = serializeLabel(edgeLabel, params);

  const edges = getResourceEdgesByRelation(state, parentKey, relationKey);
  const result = getResourceEdgeStubs(state, edges);

  return result;
};

// TODO turn into selectorCreator with memoization
const getRelatedResources = (state, queryResource: ResourceIdentifier) => {
  // pretty expensive operation that goes over all the existing
  // edges to see if they lead to the queried resource

  const found = Object.keys(state.resourceEdges)

    // first we deserialize the parent keys and tranform each
    // entry into a object with a shape that helps us return
    // the related resources in the end
    .map((k) => ({
      type: k.split('__')[0],
      id: k.split('__')[1],
      labels: state.resourceEdges[k]
    }))
    // now do some stuff...
    .reduce(
      // prettier-ignore
      (related, resource) => {

          // if the parent is already the queried resource
          // we can go over the labels and merge all the edges 
          // into the result set
          if (resource.id === queryResource.id) {
            const nested = Object.keys(resource.labels)
            .map(k => ({ label: k, resources: Object.keys( resource.labels[k].pages).map( k2 => resource.labels[k].pages[k2]).flatMap(p => p.edges) }))
              .reduce(
                // prettier-ignore
                (all, edge) => {
                  return [
                    ...all,
                    // we also transform the shape to keep the label available 
                    ...edge.resources.map(res => ({ ...res, label: edge.label }))
                  ];
                },
                []
              );
  
            return [...related, ...nested];
          } 
          // if the parent isn't the queried resource
          // we go over the labels, but check whether one 
          // of the edges is the queried resource, and 
          // then push the parent resource into the results 
          else 
          {
            const nested = Object.keys(resource.labels)
            .map(k => ({ label: k, resources: Object.keys( resource.labels[k].pages).map( k2 => resource.labels[k].pages[k2]).flatMap(p => p.edges) }))
            .reduce(
                // prettier-ignore
                (all, edge) => {
                  const containsQueryResource =
                    edge.resources.filter(
                      r => r.type === queryResource.type && r.id === queryResource.id
                    ).length > 0;
                  if (containsQueryResource) {
                    return [
                      ...all,
                      // we also transform the shape to keep the label available,
                      // but since this edges is an inverse relationship
                      // we have to clasify it differently  
                      { id: resource.id, type: resource.type, backLabel: edge.label }
                    ];
                  } else {
                    return all;
                  }
                }, 
                []
              );
  
            return [...related, ...nested];
          }
        },
      []
    );

  return found;
};

const getRelatedResourcesByType = (state, type, resource) => {
  return getRelatedResources(state, resource).filter((r) => r.type === type);
};

export const selectors = {
  getRelationCounts,
  getCursors,
  getResourceEdgesByLabel: getResourceEdgesByLabelMemo,
  getResourceEdgesByLabel__unoptimized: getResourceEdgesByLabel,
  getResourceEdgeStubsByLabel,
  getRelatedResourcesByType,
  getRelatedResources
};

const getResourceEdgeStubsByLabelCreator = (
  resourceId,
  resourceType,
  edgeLabel,
  params,
  showAllPages = false
) => {
  const { parentKey, relationKey } = getKeys(
    resourceId,
    resourceType,
    edgeLabel,
    params
  );

  const pageNumber = params?.page?.number;

  return makeSelector((query) => {
    const edges = showAllPages
      ? query((state) => {
          const pages = getPagesByRelation(state, parentKey, relationKey);
          return (
            pages &&
            Object.keys(pages)
              .map((k) => pages[k])
              .flatMap((p) => p && p.edges)
              .filter((e) => !!e)
          );
        })
      : query((state) => {
          return getResourceEdgesByRelation(
            state,
            parentKey,
            relationKey,
            pageNumber
          );
        });

    const nodes =
      edges &&
      edges
        .map((e) => query((state) => getResourceEdgeStub(state, e)))
        .filter((s) => !s.__deleted__);
    return nodes;
  });
};

const getResourceEdgeStubsByRelationCreator = (
  resourceId,
  resourceType,
  edgeLabel,
  params,
  relationship
) => {
  const { parentKey, relationKey } = getKeys(
    resourceId,
    resourceType,
    edgeLabel,
    params
  );
  const pageNumber = params?.page?.number;

  return makeSelector((query) => {
    const edges = query((state) => {
      return getResourceEdgesByRelation(
        state,
        parentKey,
        relationKey,
        pageNumber
      );
    });

    const nodes =
      edges &&
      edges
        .map((e) => query((state) => getResourceEdgeStub(state, e)))
        .filter((s) => !s.__deleted__)
        .map((resource) =>
          query((state) =>
            getResourceEdgeStub(
              state,
              resource.relationships[relationship].data
                ? resource.relationships[relationship].data
                : resource.relationships[relationship]
            )
          )
        );
    return nodes;
  });
};

export const selectorCreators = {
  // getResourceEdgesByLabel: () => defaultMemoize(getResourceEdgesByLabel),
  getResourceEdgeStubsByLabel: getResourceEdgeStubsByLabelCreator, // TODO: figure out if theres still difference
  getReourceEdgeStubsByRelationship: getResourceEdgeStubsByRelationCreator
  // getRelatedResourcesByType: () => defaultMemoize(getRelatedResourcesByType),
  // getRelatedResources: () => defaultMemoize(getRelatedResources)
};
