import * as React from 'react';

// interfaces
import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Share } from '@cube3/common/model/schema/resources/share';

// HOC
import {
  reduxForm,
  getFormValues,
  InjectedFormProps,
  destroy
} from 'redux-form';
import { connect } from 'react-redux';

// helpers
import { compose } from '../../../../../utils/component-helpers';
import moment from 'moment';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import {
  ComplexErrors,
  makeValidator,
  MIN_SHARE_LINK_PASSWORD_LENGTH,
  passwordValidator,
  strongPassword
} from '../../../../forms/helpers/validators';

// ui
import LinkSettingsForm, {
  LinkSettingsFormProperties,
  LinkSettingsReceivedProps
} from '@cube3/ui/src/Prefabs/shareLink/Forms/LinkSettingsForm';
import LinkSettingsUI from '@cube3/ui/src/Prefabs/shareLink/Modals/LinkSettings/LinkSettingsUI';
import { useModalActions } from '../../Modals/modalActions';
import { generateValidPassword } from '@cube3/ui/src/Prefabs/shareLink/Forms/fields/PasswordFieldComponent';
import {
  withDownloadPermissions,
  WithDownloadPermissionsProps
} from '../HOCs/withConnectedShares';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import {
  RequestStatuses,
  statuses
} from '@cube3/state/src/redux/ducks/request-status';
import { Dispatch } from 'redux';
import { useLinkSettingsActions } from '../../FileRequest/hooks/useLinkSettingsActions';
import {
  ShareDownloadFormatsProps,
  useShareDownloadFormats
} from '../hooks/useShareDownloadFormats';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';

import { useCurrentUser } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useSharesFeatures } from '../hooks/useSharesFeatures';
import { VerifyType } from '@cube3/state/types';

/// <Summary>
/// Functional component that retrieves the share for a resource (asset etc) and displays them
/// in a settings screen with options
/// </Summary>

const validateShareLink = (values, props) => {
  /* validator should ignore empty password field if not modified */
  const disableValidator =
    values.passwordEnabled && values.password === undefined;

  return {
    password:
      !disableValidator &&
      makeValidator([strongPassword])(
        values.password,
        values,
        props,
        'password',
        MIN_SHARE_LINK_PASSWORD_LENGTH
      )
  };
};

const mapStateToProps = (state, ownProps: Properties) => {
  const values = getFormValues(ownProps.modalContext.id)(state);
  const share: Share = ownProps.share;

  const initialPassword = generateValidPassword(passwordValidator);
  // map the values of the retrieved shares to the form values to prefill the form.
  const initialFormValues: LinkSettingsFormProperties = {
    downloadFiles: share.download_enabled,
    expiryDate: share.expires
      ? moment(share.expires_at).endOf('day').toISOString()
      : moment()
          .add(
            ownProps.workspace
              ? ownProps.workspace?.default_shares_expiration_duration
              : 30,
            'day'
          )
          .toISOString(),
    expires: share.expires,
    verifyUser: share.verify_user || share.verify !== VerifyType.never,
    verifyType:
      share.verify !== VerifyType.never
        ? share.verify
        : VerifyType.recipients_only,
    allowRequestAccess: share.allow_request_access,
    passwordEnabled: share.password_enabled,
    password: share.password_enabled ? undefined : initialPassword,
    watermark: share.watermark
  };

  return {
    form: ownProps.modalContext.id,
    initialValues: ownProps.workspace && share ? initialFormValues : undefined,
    ...values,
    formValues: values
  };
};

interface HOCMapped {
  loading?: boolean;
  workspace: Workspace;
  share: Share;
  mutateResource?: Function;
  mutateStatus: RequestStatuses;
  dispatch: Dispatch;
}
interface ModalReceiverProps {
  modalContext: {
    id: string;
    type: string;
    assetId?: string;
  };
}
type Properties = HOCMapped &
  ModalReceiverProps &
  LinkSettingsFormProperties &
  LinkSettingsReceivedProps &
  InjectedFormProps &
  WithDownloadPermissionsProps &
  ShareDownloadFormatsProps & {
    defaultDownloadFormats: RenderPreset[];
  };

export const LinkSettings: React.FunctionComponent<Properties> = (props) => {
  const { hasWatermarkFeature, hasVerifyUserFeature } = useSharesFeatures();

  const {
    modalContext,

    // HOC
    mutateResource,
    mutateStatus,
    share,
    dispatch,
    canDownloadOriginal,
    canDownloadPreview,
    loading,

    // form values
    downloadFiles,
    verifyUser,
    verifyType,
    allowRequestAccess,
    password,
    passwordEnabled,
    watermark,
    expires,
    expiryDate,
    dirty,
    valid,
    reset,

    // downloads
    onClickChooseFormats,
    setSelectedFormats,
    selectedFormats,
    defaultDownloadFormats,
    basicDownloadTypes,
    presetsLoading,
    noOtherFormats
  } = props;
  const { id } = modalContext;
  const { openModal, closeAllModals } = useModalActions();
  const [initialPasswordEnabled] = React.useState(share?.password_enabled);

  const onClickSaveLink = React.useCallback(() => {
    mutateResource({
      id,
      type: 'share',
      email_addresses: share?.email_addresses,
      download_enabled: downloadFiles,
      verify: !verifyUser ? VerifyType.never : verifyType,
      allow_request_access: allowRequestAccess,
      password_enabled: passwordEnabled,
      password: passwordEnabled && password ? password : undefined,
      watermark: watermark,
      expires: expires,
      expires_at: expires ? expiryDate : undefined,
      relationships: {
        // node: {
        //   data: {
        //     id: share.relationships.node['id'],
        //     type: 'node'
        //   }
        // },
        'render-presets': {
          data: downloadFiles
            ? selectedFormats?.map((f) => ({
                id: f.id,
                type: 'render-preset'
              }))
            : []
        }
      }
    });
  }, [
    share,
    id,
    downloadFiles,
    passwordEnabled,
    watermark,
    verifyUser,
    verifyType,
    allowRequestAccess,
    password,
    expires,
    expiryDate,
    mutateResource,
    selectedFormats
  ]);

  const linkSettingActions = useLinkSettingsActions({
    id,
    intent: 'share',
    token: share?.token
  });

  React.useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      if (passwordEnabled && password) {
        openModal('confirm_link_sent', {
          intent: 'share',
          password: password,
          passwordEnabled: initialPasswordEnabled
        });
      } else {
        closeAllModals();
      }
      dispatch(destroy(id));
    }
  }, [
    mutateStatus,
    passwordEnabled,
    password,
    initialPasswordEnabled,
    id,
    dispatch,
    destroy,
    closeAllModals
  ]);

  const isDownloadDirty = downloadFiles
    ? defaultDownloadFormats
      ? defaultDownloadFormats.length !== selectedFormats?.length ||
        !selectedFormats?.every((f) =>
          defaultDownloadFormats.find((df) => df.id === f.id)
        )
      : !!selectedFormats?.length
    : false;

  const filteredBasicTypes = React.useMemo(() => {
    const filtered = watermark
      ? basicDownloadTypes?.filter(
          (format) => format.attributes.display_name == 'Watermarked'
        )
      : basicDownloadTypes;
    return filtered;
  }, [basicDownloadTypes, watermark]);

  const filteredSelectedFormats = React.useMemo(() => {
    const filtered = watermark
      ? selectedFormats?.filter(
          (format) => format.display_name == 'Watermarked'
        )
      : selectedFormats;
    return filtered;
  }, [watermark, selectedFormats]);

  const currentUser = useCurrentUser()[1];
  const showLink =
    share?.email_addresses &&
    currentUser &&
    share.email_addresses.includes(currentUser.email_address);

  return (
    <LinkSettingsUI
      loading={loading}
      dirty={dirty || isDownloadDirty}
      submitDisabled={!valid || (downloadFiles && !selectedFormats?.length)}
      onClickSaveChanges={onClickSaveLink}
      {...linkSettingActions}
      onReset={reset}
      email_addresses={share.email_addresses}
      showLink={showLink}
      createdDate={share.created_at}
      linkSettingsForm={
        <LinkSettingsForm
          // downloads

          basicDownloadTypes={filteredBasicTypes}
          setSelectedFormats={setSelectedFormats}
          onClickChooseFormats={onClickChooseFormats}
          downloadFiles={
            (canDownloadOriginal || canDownloadPreview) && downloadFiles
          }
          noOtherFormats={noOtherFormats}
          presetsLoading={presetsLoading}
          selectedFormats={filteredSelectedFormats}
          canDownloadOriginal={canDownloadOriginal}
          canDownloadPreview={canDownloadPreview}
          // expiration
          expiryDate={expiryDate}
          expires={expires}
          // watermark
          watermark={watermark}
          hasWatermarkFeature={hasWatermarkFeature}
          // verify
          verifyUser={verifyUser}
          verifyType={verifyType}
          allowRequestAccess={allowRequestAccess}
          hasVerifyUserFeature={hasVerifyUserFeature}
          // password
          passwordEnabled={passwordEnabled}
          password={password}
          passwordValidator={passwordValidator}
        />
      }
    />
  );
};

export const withMutateLink = (Wrapped) => (props) => {
  const [workspaceId, workspace] = useCurrentWorkspace();
  const { id, type } = props.modalContext;
  const resource = useResource__ALPHA({
    resourceId: id,
    resourceType: type
  });

  const [mutateResource, mutateStatus] = useMutateResource__ALPHA(
    React.useMemo(
      () => ({
        cacheInvalidator: (r) => [
          r,
          { type: undefined, id: undefined, relationship: 'render-presets' },
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'render-presets'
          },
          { type: 'workspace', id: workspaceId, relationship: 'shares' }
        ],
        actionDecorators: [
          addFormId(id, { formState: undefined, useRequestStatus: true })
        ]
      }),
      [addFormId, id]
    )
  );

  return (
    <Wrapped
      {...props}
      share={resource.resource}
      mutateResource={mutateResource}
      mutateStatus={mutateStatus}
      loading={resource.loading}
      workspace={workspace}
    />
  );
};
export const withShareDownloadFormats = (Wrapped) => (props: Properties) => {
  const { id: shareId } = props.modalContext;

  const {
    sharedDownloadFormats,
    loading,
    onClickChooseFormats,
    downloadFormats,
    selectedFormats,
    basicDownloadTypes,
    setDownloadFormats,
    noOtherFormats
  } = useShareDownloadFormats(
    shareId,
    props.modalContext.assetId,
    props.watermark
  );

  return (
    <Wrapped
      {...props}
      basicDownloadTypes={basicDownloadTypes}
      onClickChooseFormats={onClickChooseFormats}
      downloadFormats={downloadFormats}
      selectedFormats={selectedFormats}
      defaultDownloadFormats={sharedDownloadFormats}
      setSelectedFormats={setDownloadFormats}
      presetsLoading={loading}
      noOtherFormats={noOtherFormats}
    />
  );
};

export default compose(LinkSettings)(
  withMutateLink,
  withDownloadPermissions,
  // Wrapped => props =>
  //   !props.loading ? <Wrapped {...props} /> : <ModalMenuUI loading={true} />,
  connect(mapStateToProps),
  reduxForm<LinkSettingsFormProperties, Properties, ComplexErrors>({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateShareLink
  }),
  withShareDownloadFormats
);
