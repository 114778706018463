import React, { useState, useCallback } from 'react';

// helpers
import { compose } from '../../../../../utils/component-helpers';
import { reduxForm, InjectedFormProps, FieldArray } from 'redux-form';
import moment from 'moment';

// constants
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

// main
import { EmailSuggestionsContainer } from '../../../../forms/EmailSuggestionContainer';

// ui
import {
  EmailMessagebox,
  EmailMessageBoxFormProps
} from '@cube3/ui/src/Prefabs/Forms/EmailMessageBox';
import LinkSettingsForm, {
  LinkSettingsFormProperties
} from '@cube3/ui/src/Prefabs/shareLink/Forms/LinkSettingsForm';

import ShareNewLinkUI from '@cube3/ui/src/Prefabs/shareLink/Modals/ShareNewLink/ShareNewLinkUI';
import { ModalReceiverProps } from '../../Modals/ModalManager';

// HOC
import {
  validRecipients,
  makeValidator,
  ComplexErrors,
  strongPassword,
  MIN_SHARE_LINK_PASSWORD_LENGTH,
  passwordValidator
} from '../../../../forms/helpers/validators';
import { useModalActions } from '../../../layout/Modals/modalActions';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';

import {
  ResourceTypeMap,
  VerifyType
} from '@cube3/common/model/resource-types';
import withConnectedShares, {
  WithResourcesProps
} from '../HOCs/withConnectedShares';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

import {
  ShareLinkFormFields,
  withSharelinkNewValues
} from '../HOCs/withSharelinkNewValues';
import { useShareDownloadFormats } from '../hooks/useShareDownloadFormats';
import { useSharesFeatures } from '../hooks/useSharesFeatures';

/// <Summary>
/// Functional component that retrieves the share for a resource (asset etc) and displays them
/// in a UI
/// </Summary>

export interface FormRecipient {
  email_address: string;
  id: string;
}

const validateShareLink = (values, props) => {
  return {
    emails: makeValidator([validRecipients])(
      values.emails,
      values,
      props,
      'emails'
    ),
    password: makeValidator([strongPassword])(
      values.password,
      values,
      props,
      'password',
      MIN_SHARE_LINK_PASSWORD_LENGTH
    )
  };
};
interface PrivateProps {
  createShare: Function;
  shareCreateStatus: string;
  shareIdTemporary: boolean;
}

export interface PublicProps {
  emailSuggestions: { email_address: string; id: string }[]; // parsed in wrapper
  emailSearchInput?: string;
  watermark: boolean;
}

interface PasswordRef {
  password: string;
  passwordEnabled: boolean;
}

type ModalContext = {
  node: { type: keyof ResourceTypeMap; id: string };
  /* if no prior share link */
  first?: boolean;
};
type InjectedProps = PrivateProps &
  WithResourcesProps &
  PublicProps &
  ModalReceiverProps<ModalContext> &
  LinkSettingsFormProperties &
  EmailMessageBoxFormProps;

type Properties = InjectedProps &
  InjectedFormProps<ShareLinkFormFields, InjectedProps, ComplexErrors>;

const emptyArray = [];
const cacheInvalidator = (res, anc, rel) => [
  {
    type: anc.type,
    id: anc.id,
    relationship: 'shares'
  }
];

const ShareNewLink: React.FunctionComponent<Properties> = React.memo(
  (props) => {
    const [createShareInProgress, setCreateShareInProgress] = useState(false);

    const { hasWatermarkFeature, hasVerifyUserFeature } = useSharesFeatures();

    const {
      reset,

      // form  link settings
      expiryDate,
      downloadFiles,
      expires,
      valid,
      passwordEnabled,
      password,
      verifyUser,
      verifyType,
      allowRequestAccess,
      // email message form
      message,
      emails = emptyArray,
      // with resource
      form,
      loading,
      currentUser,
      clearEmailInput,
      emailSuggestions,
      canDownloadOriginal,
      canDownloadPreview,
      watermark,
      dirty
    } = props;

    const { first, node } = props.modalContext;
    const sharedNodeId = node.id;
    const { previousModal, openModal, goBackModals } = useModalActions();
    const passwordRef = React.useRef<PasswordRef>();

    const {
      basicDownloadTypes,
      onClickChooseFormats,
      selectedFormats,
      setDownloadFormats,
      noOtherFormats
    } = useShareDownloadFormats(
      undefined,
      node.type === 'asset' && node.id,
      watermark
    );

    const shareId = useMappedId__ALPHA(form);
    const share = useResource__ALPHA({
      resourceId: shareId,
      resourceType: 'share'
    });

    const [createShare, createShareStatus] = useCreateResource__ALPHA({
      resourceType: 'share',
      ancestor: node,
      cacheInvalidator
    });

    React.useEffect(() => {
      // when we create a share we will recieve updates on shareCreateStatus
      // when successfull, go to next page.
      if (createShareStatus === statuses.SUCCESS && share.first) {
        openModal('confirm_link_sent', {
          token: share.first.token,
          password: passwordRef.current.passwordEnabled
            ? passwordRef.current.password
            : undefined,
          intent: 'share'
        });
      }
    }, [form, passwordEnabled, openModal, createShareStatus]);

    const createTheShare = useCallback(() => {
      const expirydate = moment(expiryDate).toISOString();
      // format emails array to be accepted by backend.
      const emailStrings = emails.map((element) => element.email_address);
      // initialize passwordRef
      passwordRef.current = {
        password: password,
        passwordEnabled: passwordEnabled
      };
      // download options
      const selectedDownloadFormats = downloadFiles
        ? selectedFormats.map((f) => ({ id: f.id, type: f.type }))
        : undefined;

      // build the information for create share with
      const newShare = {
        type: 'share' as const,
        temporaryId: form,
        email_addresses: emailStrings,
        download_enabled: downloadFiles,
        verify: !verifyUser ? VerifyType.never : verifyType,
        allow_request_access: allowRequestAccess,
        password_enabled: !!passwordEnabled,
        password: passwordEnabled ? password : undefined,
        expires: expires,
        expires_at: expires ? expirydate : undefined,
        message_enabled: message !== undefined,
        message: message,
        watermark: watermark,
        relationships: {
          node: {
            data: {
              id: sharedNodeId,
              type: 'node'
            },
            id: sharedNodeId,
            type: 'node'
          },
          'render-presets': {
            data: selectedDownloadFormats
          }
        }
      };

      createShare(newShare);

      // set loading boolean to true
      setCreateShareInProgress(true);
    }, [
      expiryDate,
      emails,
      verifyUser,
      verifyType,
      allowRequestAccess,
      password,
      passwordEnabled,
      downloadFiles,
      selectedFormats,
      form,
      expires,
      message,
      watermark,
      sharedNodeId,
      createShare
    ]);

    const handleClose = useCallback(() => {
      if (first) {
        goBackModals(2);
      } else {
        previousModal();
      }
      reset();
    }, [first, reset, goBackModals, previousModal]);

    return (
      <ShareNewLinkUI
        dirty={dirty}
        loading={loading || createShareInProgress}
        onCloseEvent={handleClose}
        onClickCreateShare={createTheShare}
        submitDisabled={!valid || (downloadFiles && !selectedFormats?.length)}
        emailBox={
          <>
            <EmailMessagebox
              message={message}
              EmailFieldsComponent={
                <FieldArray
                  name="emails"
                  props={{
                    emailSuggestions: emailSuggestions,
                    clearEmailInput,
                    form: form,
                    mandatoryEmail: currentUser.email_address
                  }}
                  component={EmailSuggestionsContainer}
                />
              }
            />
          </>
        }
        linkSettings={
          <>
            <LinkSettingsForm
              // downloads
              basicDownloadTypes={basicDownloadTypes}
              onClickChooseFormats={onClickChooseFormats}
              noOtherFormats={noOtherFormats}
              downloadFiles={
                (canDownloadOriginal || canDownloadPreview) && downloadFiles
              }
              setSelectedFormats={setDownloadFormats}
              selectedFormats={selectedFormats}
              canDownloadOriginal={canDownloadOriginal}
              canDownloadPreview={canDownloadPreview}
              // password
              passwordEnabled={passwordEnabled}
              password={password}
              passwordValidator={passwordValidator}
              //verify user
              verifyUser={verifyUser}
              verifyType={verifyType}
              allowRequestAccess={allowRequestAccess}
              hasVerifyUserFeature={hasVerifyUserFeature}
              // expire date
              expiryDate={expiryDate}
              expires={expires}
              watermark={watermark}
              hasWatermarkFeature={hasWatermarkFeature}
            />
          </>
        }
      />
    );
  }
);

export default compose(ShareNewLink)(
  withConnectedShares,
  withSharelinkNewValues,
  reduxForm<ShareLinkFormFields, InjectedProps, ComplexErrors>({
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: validateShareLink
  })
);
