import { handleFiles } from './handleFiles';
import { actionCreators as tempActionCreators } from '../../../../ducks/temp-id';

import { copyFormId, formStates } from '../../../redux-form-middleware';

import { actionCreators as reqActionCreators } from '../../../../ducks/request-status';

export const handleCreateBase = ({
  resource,
  action,
  config,
  dispatch,
  uploadIds,
  getState
}) => {
  handleFiles({ resource, config, dispatch, uploadIds, getState })
    .then(() => {
      dispatch(
        tempActionCreators.mapTempIdToRealId(config.temporaryId, resource.id)
      );
      dispatch(
        copyFormId(action)(
          reqActionCreators.markSuccess(action),
          formStates.SUCCESS
        )
      );
    })
    .catch((err) => {
      console.error('handle create base caught an error', err);

      dispatch(
        tempActionCreators.mapTempIdToRealId(config.temporaryId, resource.id)
      );

      dispatch(
        copyFormId(action)(
          reqActionCreators.markFailed(action, err),
          formStates.FAILED
        )
      );
    });
};
