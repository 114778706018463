/** check if two fields has same value */
export const isEqualValue = (oldVal, newVal) => {
  if (oldVal === newVal) {
    return true;
  }
  if (!!newVal !== !!oldVal) {
    return false;
  }

  if (typeof newVal !== typeof oldVal) {
    return false;
  }

  if (newVal && Array.isArray(newVal)) {
    return oldVal
      ? oldVal.length === newVal.length &&
          newVal.every((v) => !!oldVal.find((o) => isEqualValue(o, v)))
      : false;
  }
  if (typeof newVal === 'object' && typeof oldVal === 'object') {
    return (
      Object.keys(newVal).length === Object.keys(oldVal).length &&
      Object.keys(newVal).every((k) => isEqualValue(oldVal[k], newVal[k]))
    );
  }
  return false;
};
