import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { actionCreators } from '../../ducks/resource-nodes';
import { RequestStatuses, selectors } from '../../ducks/request-status';
import { FSA } from '../../flux-standard-action';
import { addRequestStatusHash } from '../../middleware/request-status-middleware';
import { uuidv4 } from '../../../utils/uuid';
import { useCurrentWorkspace } from '../Administration/withCurrentWorkspace';

type ActionDecorator<T extends FSA> = (action: T) => T;

interface UseDeleteResourceConfig<D extends FSA> {
  cacheInvalidator: (
    deletedResource: ResourceIdentifier,
    workspaceId: string
  ) => ResourceIdentifier[] | null;
  actionDecorators?: ActionDecorator<D>[];
}

const emptyArray = [];

export const useDeleteResource__ALPHA = ({
  cacheInvalidator,
  actionDecorators = emptyArray
}: UseDeleteResourceConfig<any>) => {
  // const [creating, setCreating] = React.useState();
  const [watchAction, setWatchAction] = React.useState();
  const [workspaceID] = useCurrentWorkspace();
  const dispatch = useDispatch();

  const deleteManyResources = React.useCallback(
    (resources, disposeOnly = false) => {
      if (disposeOnly) {
        resources.forEach((r) => {
          dispatch(actionCreators.disposeResource(r.type, r.id, true));
        });
        return;
      }

      const action = actionCreators.deleteManyResources(
        resources.map((r) => {
          return {
            resourceType: r.type,
            resourceId: r.id,
            invalidatesCache: cacheInvalidator
              ? cacheInvalidator(r, workspaceID)
              : emptyArray
          };
        }),
        { type: 'workspace', id: workspaceID }
      );
      const hash = `${action.type}__${uuidv4()}`;

      const decoratedAction = [
        ...actionDecorators,
        addRequestStatusHash(hash)
      ].reduce((a, dec) => dec(a), action);

      dispatch(decoratedAction);
      setWatchAction(decoratedAction);
      return hash;
    },
    [actionDecorators, cacheInvalidator, setWatchAction, dispatch, workspaceID]
  );

  const deleteStatus = useSelector(
    ({ requestStatus: state }: { requestStatus: {} }) =>
      selectors.getStatus(state, watchAction)
  );

  const deleteHandler = React.useCallback(
    (
      /** array of resource identifiers */
      resource: ResourceIdentifier | ResourceIdentifier[],
      /** whether to just dispose from redux store, or perform api call flow (default= false) */
      disposeOnly = false
    ) => {
      const resources = [].concat(resource);

      if (!resource || resources.length < 1) {
        console.warn('Nothing to delete');
        return;
      }

      return deleteManyResources(resources, disposeOnly);
    },
    [deleteManyResources]
  );

  return [deleteHandler, deleteStatus] as [
    typeof deleteHandler,
    RequestStatuses
  ];
};
