import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export interface Invitation extends PublicResource {
  email: string;
  created_at: string;
  full_name: string;
  invitation_hash: string;
  password: string;
  user_id: string;
  accepted_at?: string;
  expires_at?: string;
  relationships: {
    role: Related<'role'>;
    inviter: Related<'account'>;
    workspace: Related<'workspace'>;
  };
}

export const invitation: RawModel = {
  type: 'invitation',
  plural: 'invitations',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {
    workspaces: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'invitations'
    },
    role: {
      type: 'role',
      binding: ['one', 'many'],
      reverse: 'invitations'
    }
  }
};
