import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect
} from 'react';

export interface AnotationCanvasFormat {
  width: number;
  height: number;
  scale: number;
}

export const useVideoActualSize = (el: HTMLVideoElement, margin = 0.0) => {
  const [ratio, setRatio] = useState<AnotationCanvasFormat>({
    width: 0,
    height: 0,
    scale: 1
  });

  const getActualSize = useCallback(() => {
    const { clientWidth, clientHeight, videoWidth, videoHeight } = el || {};

    if (!clientWidth || !clientHeight || !videoWidth || !videoHeight) {
      return { width: undefined, height: undefined, scale: undefined };
    }

    const clientRatio = clientWidth / clientHeight;
    const videoRatio = videoWidth / videoHeight;

    if (clientRatio < videoRatio) {
      return {
        width: clientWidth * (1 + margin),
        height: (clientWidth / videoRatio) * (1 + margin),
        scale: clientWidth / videoWidth
      };
    } else {
      return {
        width: clientHeight * videoRatio * (1 + margin),
        height: clientHeight * (1 + margin),
        scale: clientHeight / videoHeight
      };
    }
  }, [el, margin]);

  useEffect(() => {
    const resize = () => setRatio(getActualSize());

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setRatio(getActualSize());
    });

    if (el) {
      resizeObserver.observe(el);
    }

    return () => {
      if (el) {
        resizeObserver.unobserve(el);
      }
    };
  }, [el]);

  useEffect(() => {
    if (!el) {
      return;
    }
    const handle = () => {
      setRatio(getActualSize());
    };
    el.addEventListener('loadedmetadata', handle);

    return () => {
      el.removeEventListener('loadedmetadata', handle);
    };
  }, [el]);

  useLayoutEffect(() => {
    if (el) {
      setRatio(getActualSize());
    }
  }, [getActualSize, el]);

  return ratio;
};
