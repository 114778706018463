import React from 'react';
import { compose } from '@cube3/state/src/utils/component-helpers';
import WorkspaceLogoUI from '@cube3/ui/src/logos/WorkspaceLogoUI';
import { withCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Link, matchPath, useLocation, withRouter } from 'react-router-dom';
import cubeLogo from '@cube3/state/src/wrapped-cube-client/assets/cube_logo.svg?url';

const WorkspaceLogo = ({ currentWorkspace, alt, src }) => {
  const match = matchPath<{ shareToken: string }>(
    useLocation().pathname,
    '/share-link/:shareToken'
  );

  const share = match && match.params.shareToken;

  let path = '/';

  if (currentWorkspace) {
    path = `/workspace/${currentWorkspace.id}/`;
  }

  if (share) {
    path = `/share-link/${share}`;
  }

  return (
    <div>
      <Link to={path}>
        <WorkspaceLogoUI
          alt={
            alt ||
            (currentWorkspace && `${currentWorkspace.display_name} logo`) ||
            'Cube logo'
          }
          src={
            src ||
            (currentWorkspace && currentWorkspace.display_image) ||
            cubeLogo
          }
        />
      </Link>
    </div>
  );
};

export default compose(WorkspaceLogo)(withRouter, withCurrentWorkspace);
