import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useSharesFeatures } from './useSharesFeatures';
import { VerifyType } from '@cube3/state/types';

export const useShareVerifyDefaults = () => {
  const { hasVerifyUserFeature } = useSharesFeatures();
  const workspace = useCurrentWorkspace()[1];

  let verifyUser = false;
  let verifyType = VerifyType.never;
  let allowRequestAccess = false;

  if (hasVerifyUserFeature) {
    verifyUser =
      !!workspace?.default_shares_verify_user ||
      workspace?.default_shares_verify_type !== VerifyType.never;
    verifyType =
      workspace?.default_shares_verify_type !== VerifyType.never
        ? workspace?.default_shares_verify_type
        : VerifyType.recipients_only;
    allowRequestAccess = !!workspace?.default_shares_allow_request_access;
  }

  return {
    verifyUser,
    verifyType,
    allowRequestAccess
  };
};
