import React, { useCallback, useContext } from 'react';
// components
// hooks
import { DrawingCanvasContext } from '../../contexts/annotationContexts';
import MaybeTooltip from '@cube3/ui/src/Tooltip/MaybeTooltip';
import { useShortCut } from '@cube3/ui/src/keyboard-shortcuts/useShortCut';
import { isMacOs } from '@cube3/ui/src/keyboard-shortcuts/ShortCutsRootProvider';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';

type buttonType = 'redo' | 'undo';

interface ButtonProps {
  type: buttonType;
  onSave?: Function;
}

export const RedoUndo = (props: ButtonProps) => {
  const { type, onSave } = props;
  const { sketchPad } = useContext(DrawingCanvasContext);

  const undo = useCallback(() => {
    sketchPad?.undo();
    onSave && onSave(sketchPad?.toSVG());
  }, [sketchPad, onSave]);

  const redo = useCallback(() => {
    sketchPad?.redo();
    onSave && onSave(sketchPad?.toSVG());
  }, [sketchPad, onSave]);

  /**
   * TODO:
   * it is worth discussing the right iconography for the redo/undo buttons
   */

  useShortCut({
    keyCode: 'z',
    [isMacOs ? 'cmd' : 'ctrl']: true,
    action: type === 'redo' ? redo : undo,
    shift: type === 'redo' ? true : false
  });

  return (
    <MaybeTooltip title={type === 'redo' ? 'Redo' : 'Undo'}>
      <Button
        iconLeft={type === 'undo' ? 'undo' : 'redo'}
        background={'secondary'}
        buttonStyle="ghost"
        size={SizeVariants.sm}
        onClick={type === 'redo' ? redo : undo}
      />
    </MaybeTooltip>
  );
};
