export const downloadErrors = {
  DOWNLOAD_NO_FILES:
    'The assets and/or folders you selected have no files that can be downloaded',
  ASSET_NUMBER_ERR:
    'You cannot download more than 400 files at once. Please select fewer assets and folders and try downloading again',
  DOWNLOAD_SIZE_ERR:
    'You cannot download more than 50GB at once. Please select fewer assets and folders and try downloading again',
  no_permission: {
    preview:
      'This account has no permisson to download preview files or generated formats',
    original: 'This account has no permisson to download original files'
  },
  disabled_in_share: 'Sharelink creator has not enabled this download type',
  disabled_in_share_all: 'Sharelink creator has not enabled any other formats',
  server_error:
    'Download service is currently not available, please retry later',
  source_no_file: 'Original downloads are not available for this item'
};

export const DOWNLOAD_ERROR_CODES = {
  RENDER_ALREADY_EXISTS: 'RENDER_ALREADY_EXISTS',
  RENDER_PRESET_UNSUPPORTED: 'RENDER_PRESET_UNSUPPORTED',
  RENDER_ORIGINAL_QUALITY: 'RENDER_ORIGINAL_QUALITY',
  FOLDER_EMPTY: 'FOLDER_EMPTY'
};

export const downloadWarnings = {
  RENDER_ALREADY_EXISTS: 'A render with the same preset already exists.',
  /** No any available formats except preview and original type */
  no_other_formats:
    'No other formats are available because they have not been set up for this workspace. Contact your workspace admin.',
  /** No preview file available */
  preview_no_file: {
    /** One asset selected, No preview file available */
    one:
      "This format isn't available because we currently don’t support preview formats for this media type.",
    /** Multiple assets selected, No preview files available for all selected assets */
    all:
      "This format isn't available for any selected asset because we currently don’t support preview formats for the media types of these assets.",
    /** Multiple assets selected, No preview files available for some selected assets */
    some:
      "This format isn't available for SELECTED_COUNT selected ASSET because we currently don’t support preview formats for the media type of THIS ASSET."
  },
  /** Incompatible media type */
  RENDER_PRESET_UNSUPPORTED: {
    one: "The format isn't compatible with the media type of the asset.",
    /** Multiple assets selected, Incompatible media types for all selected assets */
    all:
      "This format isn't available for any selected asset because the format isn't compatible with the media type of these assets.",
    /** Multiple assets selected, Incompatible media types for some selected assets */
    some:
      "This format isn't available for SELECTED_COUNT selected ASSET because the format isn't compatible with the media type of THIS ASSET."
  },
  /** Original file is of lower quality (acceptable, still allow to download)*/
  RENDER_ORIGINAL_QUALITY:
    'This format may not render well because the quality of the original file is low.',

  /** Original file is of lower quality (un-acceptable, not allow to download)*/
  'RENDER_ORIGINAL_QUALITY_LOW_TV-AD':
    'The quality of the original file is too low to use as a TV Ad.',

  /** Mixed: Incompatible media type OR the original file is of lower quality */
  mixed:
    "This format isn't available for SELECTED_COUNT selected ASSET for one of the following reasons:"
};

export const definedDownloadErrorCodes = Object.keys(downloadWarnings);

/** helper function to replace all template variables with actual value */
export const parseDownloadWarning = (assetsCount: number, error: string) => {
  if (error) {
    const single = assetsCount === 1;
    return error
      .replaceAll('SELECTED_COUNT', assetsCount.toString())
      .replaceAll('IS_OR_ARE', single ? 'is' : 'are')
      .replaceAll('ASSET', single ? 'asset' : 'assets')
      .replaceAll('THIS', single ? 'this' : 'these');
  }
};

export const generateDownloadWarning = (
  errorCodes: string[],
  subKey: 'all' | 'one' | 'some',
  assetsCount: number
) => {
  if (!errorCodes?.length) return '';
  const definedErrors = errorCodes.filter(code => !!downloadWarnings[code]);
  // unknow error
  if (errorCodes.length && !definedErrors.length)
    return downloadErrors.server_error;

  const code = errorCodes.length > 1 ? 'mixed' : errorCodes[0];
  let title = '';
  let reasons;

  switch (true) {
    case code === 'mixed':
      title = parseDownloadWarning(assetsCount, downloadWarnings.mixed);
      reasons = definedErrors.map(
        c => downloadWarnings[c]?.one || downloadWarnings[c]
      );
      break;
    case downloadWarnings[code] && !downloadWarnings[code][subKey]:
      title = downloadWarnings[code] as string;
      break;
    default:
      title = downloadWarnings[code][subKey];
      break;
  }

  if (subKey === 'some') {
    title = parseDownloadWarning(assetsCount, title);
  }

  return { title, reasons };
};
