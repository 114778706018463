import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import ModalHeader, { ModalHeaderProps } from './ModalHeader';
import { ModalFooterUI } from './ModalFooter';
import { Modal } from '@material-ui/core';
import { ModalWithHeaderFooterProperties } from './types';
import ScrollBar from '../Scrollbar/Scrollbar';
import Surface from '../Surface/Surface';
import { ModalCookieContext } from './ModalCookieContext';
import ModalMbileSurfaceUI from './ModalMobileSurfaceUI';
import { useModalContainer } from './ModalManagerUI';

import { CircularProgress } from '../Progress/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    cookieHeight: {
      height: 'calc(100vh - 174px)'
    },
    centered: {
      outline: 'none',
      margin: 'auto'
    },
    surface: {
      display: 'flex',
      flexDirection: 'column',
      width: '480px',
      maxWidth: '100vw'
    },
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      width: 'auto',
      background: theme.customPalette.backgroundAccent.blackAccent3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    // The default value for margins is allMargins
    // provide a string property defineMargins to set other class for margins
    allMargins: {
      margin: '24px 32px'
    },
    noMargins: {
      margin: 0
    },
    sideMargins: {
      margin: '0 32px'
    }
  })
);

type Properties = ModalWithHeaderFooterProperties & ModalHeaderProps;

const ModalNotice: React.FC<React.PropsWithChildren<Properties>> = (props) => {
  const {
    component: Component = 'div',
    title,
    subTitle,
    footerLeftComponent,
    footerRightComponent,
    onCloseEvent = undefined,
    showCloseButton = false, // in notice and menu defaults to false.
    extraComponentProps,
    extraModalProps,
    loading = false,
    extraSurfaceProperties,
    defineMargins = 'allMargins'
  } = props;

  // use context provider to check if we should resize the modal if the cookie banner is active.
  const cookieConsentContext = useContext(ModalCookieContext);

  const classes = useStyles();

  const container = useModalContainer();

  if (!container) {
    return null;
  }

  return (
    <Modal
      container={container}
      open={true}
      className={`${classes.root} ${
        cookieConsentContext.hasConsented === false ? classes.cookieHeight : ''
      }`}
      onClose={onCloseEvent}
      {...extraModalProps}
    >
      <Component
        className={classes.centered}
        data-type={'librarydeselectoverride'}
        {...extraComponentProps}
      >
        {isMobile ? (
          <ModalMbileSurfaceUI
            extraSurfaceProperties={extraSurfaceProperties}
            onCloseEvent={onCloseEvent}
            showCloseButton={showCloseButton}
            footerLeftComponent={footerLeftComponent}
            footerRightComponent={footerRightComponent}
            loading={loading}
          >
            {props.children}
          </ModalMbileSurfaceUI>
        ) : (
          <Surface
            className={classes.surface}
            background={'base2'}
            corners={'rounded'}
            floating={true}
            gradient={'lamp'}
            floatingHeight={24}
            {...extraSurfaceProperties} // override surface properties from parent.
          >
            <ModalHeader
              onClickClose={onCloseEvent ? () => onCloseEvent() : undefined}
              showCloseButton={showCloseButton}
              title={title}
              subTitle={subTitle}
            />

            <ScrollBar autoHeight={true} autoHeightMax={3000}>
              <div className={classes[defineMargins]}>{props.children}</div>
            </ScrollBar>

            <ModalFooterUI
              footerLeftComponent={footerLeftComponent}
              footerRightComponent={footerRightComponent}
            />

            {/* Show circular progress, blocking interaction when in loading state */}
            {loading && (
              <div className={classes.loadingOverlay}>
                <CircularProgress />
              </div>
            )}
          </Surface>
        )}
      </Component>
    </Modal>
  );
};

/**
 * @summary : This variant of the modal is a small notice dialog, grows with content and then uses scrollbars for overflow
 * @author Simon, Michiel, Machiel, Lorenzo
 */
export const ModalNoticeUI = ModalNotice;
