import React from 'react';
// UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Surface from '../Surface/Surface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: PageHeaderProps) => ({
      height: 56,
      alignItems: 'center',
      display: 'flex',
      marginLeft: '36px',
      marginRight: theme.customSpacing.margin[24]
    }),
    workspaceLogoContainer: (props: PageHeaderProps) => ({
      width: props.extraSpaceLogo ? theme.sizing[192] : theme.sizing[96],
      marginRight: '32px'
    }),
    menuButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 12
    },
    breadCrumbsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      flexShrink: 1
    },
    avatarContainer: {
      // width: theme.sizing[80],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: '32px'
    }
  })
);

interface PageHeaderProps {
  menuButtonComponent?: JSX.Element;
  workspaceLogoComponent?: JSX.Element;
  breadCrumbsComponent?: JSX.Element;
  avatarComponent?: JSX.Element;
  extraSpaceLogo?: boolean;
}

function PageHeader(props: PageHeaderProps) {
  const classes = useStyles(props);
  const {
    root,
    workspaceLogoContainer,
    breadCrumbsContainer,
    avatarContainer,
    menuButtonContainer
  } = classes;
  const {
    avatarComponent,
    workspaceLogoComponent,
    breadCrumbsComponent,
    menuButtonComponent
  } = props;

  return (
    <Surface background={'transparent'} className={root}>
      {menuButtonComponent && (
        <div className={menuButtonContainer}>{menuButtonComponent}</div>
      )}

      {workspaceLogoComponent && (
        <div className={workspaceLogoContainer}>{workspaceLogoComponent}</div>
      )}

      {breadCrumbsComponent && (
        <div className={breadCrumbsContainer}>{breadCrumbsComponent}</div>
      )}

      {avatarComponent && (
        <div className={avatarContainer}>{avatarComponent}</div>
      )}
    </Surface>
  );
}

export default PageHeader;
