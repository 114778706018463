import { PublicResource } from '../../resource-types';
import { RawModel } from '../schema';

export type ContractType = PublicResource & {};

export const contractType: RawModel = {
  type: 'contract-type',
  plural: 'contract-types',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
  },
  relationships: {}
};
