import { makeSelector, EnhancedSelector } from '@taskworld.com/rereselect';

// based of  https://github.com/taskworld/rereselect#parameterized-selectors

interface NamedSelector<S, R> extends EnhancedSelector<S, R> {
  displayName?: string;
}

const index = {};
const MAX_CACHE_ENTRIES = 1000;

export function makeParameterizedSelector(
  displayName,
  selectionLogicGenerator,
  makeKey = (...args) => args.join(',')
) {
  const memoized = new Map();
  const selectorFactory = Object.assign(
    function(...args) {
      const key = makeKey(...args);
      if (memoized.has(key)) return memoized.get(key)!;

      const name = `${displayName}(${key})`;
      const selectionLogic = selectionLogicGenerator(...args);
      const selector: NamedSelector<any, unknown> = makeSelector(
        selectionLogic
      );
      selector.displayName = name;
      memoized.set(key, selector);
      if (memoized.size > MAX_CACHE_ENTRIES) {
        console.warn(
          `ParameterizedSelector cache for ${displayName} exceeds ${MAX_CACHE_ENTRIES} entries`
        );
      }
      return selector;
    },
    { displayName }
  );

  index[selectorFactory.displayName] = { selectorFactory, memoized };
  return selectorFactory;
}

window['_selectorIndex'] = index;
