import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { KeyConfig } from './reducer';
import { shortCutRootContext } from './ShortCutsRootProvider';

interface ShortCutContext {
  registerKey(config: KeyConfig, path?: string): void;
  unregisterKey(config: KeyConfig, path?: string): void;
  path?: string;
}

export const shortCutContext = React.createContext<ShortCutContext>({
  // eslint-disable-next-line
  registerKey: console.info,
  // eslint-disable-next-line
  unregisterKey: console.info
});

interface ShortCutsProviderProps {
  name: string;
  boundaryEl?: HTMLElement;
  grabFocus?: boolean;
  returnFocus?: boolean;
  isolate?: 'down';
}

export const ShortCutsProvider: React.FC<React.PropsWithChildren<ShortCutsProviderProps>> = props => {
  const { children, name, boundaryEl, grabFocus, returnFocus, isolate } = props;

  const parent = useContext(shortCutContext);
  const root = useContext(shortCutRootContext);

  const {
    registerBoundary,
    unregisterBoundary,
    registerKey: registerKeyRoot,
    unregisterKey: unregisterKeyRoot
  } = root;

  const path =
    parent?.path && name
      ? `${parent.path}.${name}`
      : name
      ? name
      : parent?.path;

  const shouldRegister = !!name;
  const parentPath = parent?.path;
  const returnFocusTo = returnFocus ? parentPath : undefined;

  useEffect(() => {
    if (shouldRegister && path && boundaryEl) {
      registerBoundary(boundaryEl, path, grabFocus, isolate);
      return () => unregisterBoundary(boundaryEl, path, returnFocusTo);
    }
  }, [
    boundaryEl,
    path,
    registerBoundary,
    unregisterBoundary,
    grabFocus,
    returnFocusTo,
    shouldRegister,
    isolate
  ]);

  const registerKey = useCallback(
    keyConfig => {
      registerKeyRoot(keyConfig, path);
    },
    [path, registerKeyRoot]
  );

  const unregisterKey = useCallback(
    keyConfig => {
      unregisterKeyRoot(keyConfig, path);
    },
    [path, unregisterKeyRoot]
  );

  const value = useMemo(() => {
    return {
      path,
      registerKey,
      unregisterKey
    };
  }, [path, registerKey, unregisterKey]);

  return (
    <shortCutContext.Provider value={value}>
      {children}
    </shortCutContext.Provider>
  );
};
