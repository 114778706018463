import { Feature } from '@cube3/common/model/resource-types';
import { Folder } from '@cube3/common/model/schema/resources/folder';
import { Asset } from '@cube3/common/model/schema/resources/asset';
import {
  DownloadItemRowUI,
  DownloadStatusType,
  generateFileNameTooltip
} from '@cube3/ui/src/library/Downloads/DownloadItemRowUI';
import React, { useCallback, useEffect, useState } from 'react';
import { DownloadWarningMessage } from '@cube3/ui/src/library/Downloads/DownloadWarningMessage';
import { useDownloadFiles } from '../hooks/useDownloadFIles';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { useModalActions } from '../../Modals/modalActions';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { CircularProgress } from '@cube3/ui/src/Progress/CircularProgress';
import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';

export interface DownloadFormatRowProps {
  preset: RenderPreset;
  /** true if selected preset does not support selected file type */
  mimeTypeUnSupported: boolean;
  assets?: Asset[];
  folders?: Folder[];
  /** if true: should add workspace as relation */
  workspaceId?: string;
  /** use for privilege check */
  disabledReason?: string;
}

/** Download flow:
 * - Not all formats may be available for the selected media type(exp: PDF and audio) – list them anyway but add a warning icon.
 * 1. when hovering warning or click download button: create dry run `POST:/render-batches`
 * 2. use create dry run response errors to inform user about potentially not available items
 * 3. filter out the not available assets and create actual source for selected format
 * 4. poll the batch and use its id to create downloads: `POST:/downloads`
 */
export const DownloadFormatRow = (props: DownloadFormatRowProps) => {
  const {
    preset,
    assets,
    folders,
    mimeTypeUnSupported,
    workspaceId,
    disabledReason
  } = props;

  const { openModal } = useModalActions();

  const {
    batchId,
    batchResource,
    waitingForDownload,
    downloadResource,
    errors,
    warnings,
    retrievingFailedAssets,
    serverError,
    createBatchStatus,
    onDryRun,
    onDownload
  } = useDownloadFiles(preset?.id, workspaceId, assets, folders);

  const [loading, setLoading] = useState(false);
  const onClickDownload = useCallback(() => {
    setLoading(true);
    onDownload();
  }, [openModal, onDownload]);

  useEffect(() => {
    if (
      batchId &&
      batchResource &&
      (batchResource.attributes.state === 'success' ||
        batchResource.attributes.state === 'failed')
    ) {
      setLoading(false);
    }
  }, [batchId, batchResource]);

  const downloadStatus = (() => {
    switch (true) {
      // server error: failed to create `POST: /download/` or create batch failed
      case waitingForDownload === 'failed' ||
        batchResource?.attributes.state === 'failed' ||
        serverError:
        return 'Not available';
      case loading || waitingForDownload === true:
        return 'Preparing...';
      case !!downloadResource?.download_url:
        return 'Download started';
      default:
        return 'Download';
    }
  })() as DownloadStatusType;

  const singelAsset = assets?.length === 1 && !folders?.length && assets[0];
  const originalMedia =
    singelAsset &&
    preset?.attributes.display_name === 'Original' &&
    singelAsset.media.find((item) => item.type === 'source');

  const previewMedia =
    singelAsset &&
    preset?.attributes.display_name === 'Preview' &&
    (singelAsset.media.find((item) => item.type === 'preview') ||
      singelAsset.media.find((item) => item.type === 'poster'));

  const fileCount = singelAsset ? 1 : undefined;

  const fileNameTooltip = generateFileNameTooltip(preset, [
    ...(assets || []),
    ...(folders || [])
  ]);

  const anyMessageToShow =
    mimeTypeUnSupported || !!warnings.codes?.length || !!errors.codes?.length;

  const dryRunLoading =
    createBatchStatus &&
    createBatchStatus !== statuses.FAILED &&
    createBatchStatus !== statuses.SUCCESS;

  return (
    <DownloadItemRowUI
      title={preset.attributes.display_name}
      /* disable `Download` button if
       * - there's unknow error 
       * - all selected assets failed to render during dry run
           - always enable if there are folders selected */
      disabled={
        !!disabledReason ||
        !!serverError ||
        (!folders?.length && assets?.length === errors.assetsCount)
      }
      fileCount={downloadResource?.file_count || fileCount}
      downloadSize={
        originalMedia?.size || previewMedia?.size || downloadResource?.size
      }
      warning={
        // we want a pretty spinner when hovering the icon
        mimeTypeUnSupported && dryRunLoading ? (
          <CircularProgress size="1em" />
        ) : (
          disabledReason ||
          serverError ||
          (anyMessageToShow ? (
            <DownloadWarningMessage
              errors={errors}
              warnings={warnings}
              allAssetsCount={assets?.length || 0}
              hasFolders={!!folders?.length}
              loading={retrievingFailedAssets}
            />
          ) : (
            ''
          ))
        )
      }
      fileNameTooltip={fileNameTooltip}
      downloadStatus={downloadStatus}
      onDownload={onClickDownload}
      onHoverWarning={onDryRun}
    />
  );
};
