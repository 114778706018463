import React, { useCallback } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import CreateTagRow, { TagRowUIProps } from './CreateTagRow';
import Button from '../../atoms/Button/Button';
import { CreateTagRowUI, initialUiTag } from './types';
import { Tag } from '@cube3/common/model/schema/resources/tag';

const useCSS = makeCSS(() => ({
  root: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column'
  }
}));

type Props = Pick<TagRowUIProps, 'categories'> & {
  tags: {
    [key: string]: CreateTagRowUI;
  };
  setTags: React.Dispatch<
    React.SetStateAction<{
      [key: string]: CreateTagRowUI;
    }>
  >;
  onChange?: (tag: Tag | CreateTagRowUI) => (tagConfig) => void;
  validator?: (tag) => string;
};

export const CreateTags = (props: Props) => {
  const { categories, tags, setTags, onChange, validator } = props;

  const classes = useCSS();

  /** delete tag from tag list */
  const deleteTag = useCallback(
    (key: string) => {
      return () => {
        setTags((prev) => {
          const res = { ...prev };
          delete res[key];
          return res;
        });
      };
    },
    [setTags]
  );

  /** handle on click `Add tag` */
  const addTag = useCallback(() => {
    setTags((prev) => {
      const id = `new_${Object.keys(prev).length}`;
      const hasEmptyTag = !!Object.keys(prev).find((i) => !prev[i].text);

      return {
        ...(prev || {}),
        [id]: {
          ...initialUiTag.new_0,
          error: hasEmptyTag
            ? ERROR_MESSAGE.conflict
            : validator
            ? validator(initialUiTag.new_0)
            : ''
        }
      };
    });
  }, [setTags]);

  /** A callback for updating `tags` when text/color/category changes */
  const updateTags = useCallback(
    (index: string) => {
      return (config) => {
        setTags((prev) => {
          const current = prev[index];
          const { text, color, categoryName } = config;

          const dirty =
            (text && current.text !== text) ||
            (color && current.color !== color) ||
            (categoryName && categoryName !== current.categoryName);

          return {
            ...prev,
            [index]: {
              ...current,
              ...config,
              error:
                getError(prev, current) || (validator && validator(current)),
              dirty
            }
          };
        });
      };
    },
    [setTags, validator]
  );

  return (
    <div className={classes.root}>
      {Object.keys(tags).length
        ? Object.keys(tags).map((key) => (
            <div key={key}>
              <CreateTagRow
                tag={tags[key]}
                updateTags={updateTags(key)}
                deleteTag={deleteTag(key)}
                categories={categories}
                mutateTag={onChange ? onChange(tags[key]) : undefined}
              />
            </div>
          ))
        : null}
      <Button
        label="Add tag"
        iconLeft="add"
        background="secondary"
        buttonStyle="ghost"
        onClick={addTag}
      />
    </div>
  );
};

const getError = (tags: {}, tag) => {
  const existed = Object.keys(tags).filter(
    (key) => !tags[key].deleted && tags[key].text === tag.text
  );
  switch (true) {
    case tag.text.length > 32:
      return ERROR_MESSAGE.tooLong;
    case existed && existed.length > 1:
      return ERROR_MESSAGE.conflict;

    default:
      return undefined;
  }
};

const ERROR_MESSAGE = {
  conflict: 'This name is already in use',
  tooLong: 'Must be 32 charactors or less'
};
