import React from 'react';
import { ModalReceiverProps } from '../../../../../../app/layout/Modals/ModalManager';
import SubmitCancelModalUI from '@cube3/ui/src/Layout/AccountSettings/Modals/SubmitCancelModalUI';
import { useModalActions } from '../../../../../layout/Modals/modalActions';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import GenericFieldWithErrors from '../../../../../../forms/helpers/fields/GenericFieldWithErrors';
import { FieldConfig } from '../../../../../layout/ContentPane/DetailView/MetaData/EditMetadataFormSmart';
import NumberField from '../../../../../../forms/helpers/fields/NumberField';
import CheckBoxStateless from '@cube3/ui/src/Buttons/CheckBoxStateless';
import SelectFieldComponent from '../../../../../../forms/helpers/fields/SelectFieldComponent';
import { Typography } from '@cube3/ui/src/typography/Typography';
import SupportEmailComponent from '@cube3/ui/src/Email/SupportEmailComponent';

interface Props extends ModalReceiverProps {
  onSave(values: any): void;
  loading: boolean;
  config: {
    fields: (FieldConfig & { extraProps?: any })[];
  };
  title?: string;
  failed?: boolean;
}

export const EditSettingModalBase: React.FC<
  Props & InjectedFormProps<{}, Props>
> = (props) => {
  const {
    valid,
    submitting,
    handleSubmit,
    title = 'Edit setting',
    config,
    loading,
    error,
    pristine
  } = props;

  const { previousModal } = useModalActions();

  return (
    <SubmitCancelModalUI
      title={title}
      onCloseEvent={previousModal}
      submitDisabled={!valid || submitting}
      submitReplaced={pristine}
      onSubmit={handleSubmit}
      loading={loading || submitting}
    >
      {config.fields.map((f) => (
        <Field
          key={f.label}
          label={f.label}
          component={getComponent(f.type)}
          name={f.name}
          type={f.type}
          parse={f.parse}
          format={f.format}
          props={f.extraProps}
          validate={f.validate}
        />
      ))}
      {error && (
        <div style={{ padding: 8 }}>
          <Typography color="danger1">
            Something went wrong with your request, please try again later or
            contact <SupportEmailComponent />
            ...
          </Typography>
        </div>
      )}
    </SubmitCancelModalUI>
  );
};

export const EditSettingModal = reduxForm<{}, Props>({
  onSubmit: (values, dispatch, props) => {
    props.onSave(values);
  }
})(EditSettingModalBase);

/**
 *  Fields
 */

const CheckboxField = (props) => {
  const { input, label } = props;

  return (
    <div style={{ height: 40 }}>
      <CheckBoxStateless
        {...input}
        text={label}
        checked={input.value}
        marginCollapse="left"
      />
    </div>
  );
};

const LabeledNumberField = (props) => {
  return <NumberField {...props} showLabel={true} />;
};

const SelectField = (props) => {
  return <SelectFieldComponent {...props} showLabel={true} />;
};

const getComponent = (type) => {
  switch (type) {
    case 'number':
      return LabeledNumberField;
    case 'select':
      return SelectField;
    case 'boolean':
      return CheckboxField;
    case 'text':
    default:
      return GenericFieldWithErrors;
  }
};
