import NumberField from '../helpers/fields/NumberField';
import TextField from '../helpers/fields/TextField';
import SelectFieldComponent from '../helpers/fields/SelectFieldComponent';
import DatePicker from '../helpers/fields/DatePicker';
import MultipleInputField from '../helpers/fields/MultipleInputField';
import { AttachmentsField } from '../helpers/fields/AttachmentsField/AttachmentsField';
import { MultipleResourcePickerSmart } from '../../app/layout/Contracts/prefabs/MultipleResourcePicker';
import WaterfallSelectFieldComponent from '../helpers/fields/WaterfallSelectFieldComponent';

export const getFieldConfig = (field) => {
  const type: FieldValueType = field.value_type;

  switch (true) {
    case type === 'number':
      return {
        component: NumberField,
        props: {
          ...field.data,
          disabled: field.disabled,
          inputProps: { 'data-cy': 'metadataNumber' }
        }
      };
    case type === 'string':
    case type === 'text':
    case type === 'email':
      return {
        component: TextField,
        props: {
          ...field.data,
          disabled: field.disabled,
          inputProps: { 'data-cy': 'metadataSingleLineField' }
        }
      };
    case type === 'textarea':
      return {
        component: TextField,
        props: {
          ...field.data,
          disabled: field.disabled,
          inputProps: { 'data-cy': 'metadataMultiLineField' },
          multiline: true
        }
      };

    case type === 'date':
      return {
        component: DatePicker,
        props: {
          ...field.data,
          disabled: field.disabled,
          fullWidth: false,
          inputProps: {
            ...field.data,
            disabled: field.disabled,
            'data-cy': 'metadataDateField'
          }
        }
      };

    case type === 'select':
      return {
        component: SelectFieldComponent,
        props: {
          ...field.data,
          disabled: field.disabled,
          required: field.required,
          showClearButton: !field.required,
          selectProps: { 'data-cy': 'metadataSelectField' }
        }
      };

    case type === 'multiselect':
      return {
        component: MultipleInputField,
        props: {
          ...field.data,
          disabled: field.disabled,
          allowAddingOfNewSuggestions: false,
          validator: (val) => field.validate(val),
          clearOn: 'all'
        }
      };

    case type === 'waterfall_select':
      return {
        component: WaterfallSelectFieldComponent,
        props: {
          ...field.data,
          disabled: field.disabled,
          required: field.required,
          showClearButton: !field.required,
          selectProps: { 'data-cy': 'metadataWaterfallSelectField' }
        }
      };

    case type === 'asset':
      return {
        component: AttachmentsField,
        props: {
          ...field.data,
          disabled: field.disabled,
          inputProps: { 'data-cy': 'metadataAttachmentField' }
        }
      };
    case type === 'resource_picker':
      return {
        component: MultipleResourcePickerSmart,
        props: {
          ...field.data,
          disabled: field.disabled,
          inputProps: { 'data-cy': 'metadataResourcePickerField' }
        }
      };

    case type === 'custom':
      return {
        component: field.component,
        props: {
          ...field.data,
          disabled: field.disabled,
          required: field.required,
          'data-cy': 'metadataCustomField'
        }
      };

    default:
      return { component: TextField, props: undefined };
  }
};

export type FieldValueType =
  | 'string'
  | 'text'
  | 'number'
  | 'email'
  | 'textarea'
  | 'date'
  | 'select'
  | 'multiselect'
  | 'waterfall_select'
  | 'asset'
  | 'resource_picker'
  | 'custom';
