import { createSelector } from 'reselect';
import { selectRelatedForms } from './selectRelatedForms';
import { isEmptyForm } from './isEmptyForm';
import { fieldsHaveValues } from './fieldsHaveValues';

export const selectAllFormValues = createSelector(
  (state) => state['form'],
  (form) => selectRelatedForms(form),
  (form) => {
    return Object.keys(form)?.reduce((acc, id) => {
      const { values } = form[id];
      if (!isEmptyForm(values)) {
        return { ...acc, ...fieldsHaveValues(values) };
      }
      return acc;
    }, {});
  }
);
