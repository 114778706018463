import { useMemo, useCallback } from 'react';

/**
 * Create array of strings of the primary key of the selected and excluded suggestions
 * @param selectedSuggestions
 * @param excludedSuggestions
 * @param newSuggestionPrimaryKey
 * @returns keys of all selected and excluded suggestions
 */
export const useDisabledSuggestionKeys = (
  selectedSuggestions,
  excludedSuggestions,
  identifySuggestion
) => {
  return useMemo(() => {
    return [
      ...selectedSuggestions.map(suggestion => identifySuggestion(suggestion)),
      ...excludedSuggestions.map(suggestion => identifySuggestion(suggestion))
    ];
  }, [excludedSuggestions, identifySuggestion, selectedSuggestions]);
};

/**
 * Create array of suggestions filtered by selected and excluded suggestions
 * @param allSuggestions
 * @param selectedSuggestions
 * @param excludedSuggestions
 * @param showDisabled
 * @param allSuggestionsDisabled
 * @param isSelectedFn
 * @param newSuggestionPrimaryKey
 * @returns list of a suggestions that can be shown
 */

export const useFilteredSuggestions = (
  allSuggestions,
  selectedSuggestions,
  excludedSuggestions,
  showDisabled,
  allSuggestionsDisabled,
  isSelectedFn,
  identifySuggestion
) => {
  return useMemo(() => {
    return allSuggestions.filter((suggestion, index) => {
      const inSelected = isSelectedFn
        ? isSelectedFn(suggestion, selectedSuggestions)
        : selectedSuggestions
            .map(suggestion => identifySuggestion(suggestion))
            .indexOf(identifySuggestion(suggestion)) > -1;
      const inExcluded = !!excludedSuggestions.find(
        ex => ex.id === suggestion.id
      );
      const disabled = allSuggestionsDisabled || inSelected || inExcluded;

      if (disabled && !showDisabled) {
        return false;
      } else {
        return true;
      }
    });
  }, [
    allSuggestions,
    selectedSuggestions,
    excludedSuggestions,
    showDisabled,
    allSuggestionsDisabled,
    isSelectedFn,
    identifySuggestion
  ]);
};

/**
 * Create const of only un-selected and un-excluded suggestions array length
 * @param filteredSuggestions
 * @param newSuggestionPrimaryKey
 * @param disabledSuggestionKeys
 * @returns list of enabled suggestions
 */
export const useEnabledSuggestions = (
  filteredSuggestions,
  disabledSuggestionKeys,
  identifySuggestion
) =>
  useMemo(() => {
    return (
      filteredSuggestions.filter(
        item => disabledSuggestionKeys.indexOf(identifySuggestion(item)) === -1
      ).length > 0
    );
  }, [filteredSuggestions, identifySuggestion, disabledSuggestionKeys]);

export const useIdentifySuggestion = newSuggestionPrimaryKey => {
  return useCallback(
    suggestion =>
      newSuggestionPrimaryKey &&
      suggestion &&
      suggestion[newSuggestionPrimaryKey]
        ? suggestion[newSuggestionPrimaryKey]
        : suggestion,
    [newSuggestionPrimaryKey]
  );
};
