import * as React from 'react';

// helpers
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useProjectTeamRole } from '@cube3/state/src/redux/components/Hooks/useRole';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useHistory } from 'react-router-dom';
import { Project } from '@cube3/common/model/schema/resources/project';
import { useModalActions } from '../../Modals/modalActions';

// UI
import { ProjectMembersList } from '../Subcomponents/ProjectMembersList';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Switch } from '@material-ui/core';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { ProjectTeamAdminsContextProvider } from '../contexts/ProjectAdminsCheckContext';
import AddMemberComponent from '../Subcomponents/AddMemberComponentSmart';
import { PROJECT_ROLE_TYPES } from '@cube3/common/model/types';

/**
 * @summary: Modal entrypoint for project members, lists the members, and gives the option to add or remove
 * @author Simon
 */
interface ModalReceiverProps {
  modalContext: {
    project: Project;
    hasTeamFeature?: boolean;
    canEditProject: boolean;
  };
}

type Properties = ModalReceiverProps;

const mutateConfig = { cacheInvalidator: (r) => [r] };

function ProjectMembersModal(props: Properties): JSX.Element {
  const { project, hasTeamFeature, canEditProject } = props.modalContext;

  const { closeAllModals, openModal } = useModalActions();
  const {
    resources: projectMembers,
    status: projectMembersStatus,
    loading: projectMembersStatusLoading
  } = useResourceList__ALPHA({
    resourceType: 'project',
    resourceId: project?.id,
    edgeType: 'project-member',
    edgeLabel: 'members',
    strategy: 'fetch-on-mount'
  });

  const projectId = project?.id;
  const workspace = useCurrentWorkspace()[1];
  const [mutateProjectAccess, mutateProjectAccessStatus] =
    useMutateResource__ALPHA(mutateConfig);

  const [currentAccountId] = useCurrentAccount();

  const [isPublic, setIsPublic] = React.useState(project?.is_public);

  const hasAccessChanged = isPublic !== project?.is_public;

  const handleProjectAccessChange = React.useCallback(
    (isPublic) => {
      if (hasAccessChanged && canEditProject) {
        mutateProjectAccess({
          id: project?.id,
          type: 'project',
          is_public: isPublic
        });
      } else {
        closeAllModals();
      }
    },
    [
      canEditProject,
      closeAllModals,
      hasAccessChanged,
      mutateProjectAccess,
      project
    ]
  );

  const onClickAddMember = React.useCallback(() => {
    if (canEditProject) {
      openModal('projects_addMembers', { projectId, hasTeamFeature }, false);
    }
  }, [canEditProject, openModal, hasTeamFeature, projectId]);

  const accountAdmins = projectMembers?.filter(
    (m) => m.project_member_role === PROJECT_ROLE_TYPES.Admin
  ).length;

  const mutating =
    mutateProjectAccessStatus &&
    mutateProjectAccessStatus !== statuses.SUCCESS &&
    mutateProjectAccessStatus !== statuses.FAILED;

  const loading = !projectMembersStatus || projectMembersStatusLoading;

  React.useEffect(() => {
    if (mutateProjectAccessStatus === statuses.SUCCESS) {
      closeAllModals();
    }
  }, [closeAllModals, mutateProjectAccessStatus]);

  /* if user no long has access to this project after removing the last team that it was a member of,
   * should redirect the user to the project overview page
   */
  const routerHistory = useHistory();
  const memberInTeams = useProjectTeamRole({
    accountId: currentAccountId,
    projectId
  })[1] as string[];

  React.useEffect(() => {
    if (
      !canEditProject &&
      !project?.is_public &&
      !loading &&
      (!memberInTeams || !memberInTeams.length) &&
      !projectMembers?.find(
        (res) => res.relationships.account.id === currentAccountId
      )
    ) {
      closeAllModals();
      routerHistory.push('/workspace/' + workspace?.id + '/projects');
    }
  }, [
    canEditProject,
    closeAllModals,
    currentAccountId,
    memberInTeams,
    projectMembers,
    routerHistory,
    workspace,
    project,
    loading
  ]);

  return (
    <ModalMenuUI
      title={'Project Members & Access'}
      onCloseEvent={closeAllModals}
      loading={mutating}
      footerRightComponent={
        <>
          {hasAccessChanged && (
            <Button
              text="Cancel"
              colorVariant="ghost2"
              onClick={closeAllModals}
            />
          )}
          <Button
            text={hasAccessChanged ? 'Save changes' : 'Done'}
            colorVariant={'filled1'}
            onClick={() => handleProjectAccessChange(isPublic)}
          />
        </>
      }
    >
      {canEditProject && (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <Switch checked={isPublic} onClick={() => setIsPublic(!isPublic)} />
          <div style={{ paddingLeft: 8 }}>
            <Typography typographyStyle="body1">
              {`Make project visible to all ${workspace?.display_name} workspace members`}
            </Typography>
            <Typography typographyStyle="body2" color="contrast2">
              {"They may be able to view and download this project's contents."}
            </Typography>
          </div>
        </div>
      )}
      {canEditProject && <Divider />}
      <ProjectTeamAdminsContextProvider
        projectId={projectId}
        accountAdmins={accountAdmins}
        hasTeamFeature={hasTeamFeature}
      >
        <AddMemberComponent
          loading={loading}
          canEdit={canEditProject}
          onClick={onClickAddMember}
        />
        <ProjectMembersList
          projectId={projectId}
          canEdit={canEditProject}
          projectMembers={projectMembers}
          loading={loading}
          hasTeamFeature={hasTeamFeature}
          currentAccountId={currentAccountId}
          memberInTeams={hasTeamFeature && memberInTeams}
          isProjectPublic={project?.is_public}
        />
      </ProjectTeamAdminsContextProvider>
    </ModalMenuUI>
  );
}

export default ProjectMembersModal;
