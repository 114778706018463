import { enableMapSet, produce as createNextState, Draft } from 'immer';
import { FSA } from '../flux-standard-action';
import { Produced } from 'immer/dist/internal';

enableMapSet();

interface Reducers<S> {
  [key: string]: (state: Draft<S>, action: FSA) => S | Produced<S, S> | void;
}

interface CreateReducerConfig<S> {
  /**
   *  initial state data.
   */
  initialState: S;
  /**
   *  handler definition object.
   */
  handlers: Reducers<S>;
}

function createReducer<S>({ initialState, handlers }: CreateReducerConfig<S>) {
  return function reducer(state: S = initialState, action: FSA) {
    if (handlers.hasOwnProperty(action.type)) {
      return createNextState(state, draft => {
        try {
          const res = handlers[action.type](draft, action);
          return res;
        } catch (err) {
          console.error(err);
        }
      });
    } else {
      return state;
    }
  };
}

interface ReducerDefinition<S, N extends string> {
  handlers: Reducers<S>;
  initialState: S;
  namespace: N;
  [key: string]: any;
}

type DefineReducer<S, N extends string> = () => ReducerDefinition<S, N>;

interface Slice<S, N extends string> {
  slice: {
    [k in N]: (state: S, action: FSA) => S | Produced<S, S>;
  };
}

export const createSlice = <S, N extends string>(
  defineReducer: DefineReducer<S, N>
): Slice<S, N> => {
  const { handlers, initialState, namespace } = defineReducer();
  return {
    slice: {
      [namespace]: createReducer<S>({ handlers, initialState })
    }
  } as Slice<S, N>;
};
