import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import {
  useSelection,
  useSelectionActions
} from '@cube3/state/src/redux/components/useSelection';
import { useCallback } from 'react';
import { useModalActions } from '../../../../Modals/modalActions';

export const useProjectTemplateAction = () => {
  const [worksapceId] = useCurrentWorkspace();
  const [createResource, createResourceStatus] = useCreateResource__ALPHA({
    ancestor: {
      type: 'workspace',
      id: worksapceId
    },
    resourceType: 'project-template',
    cacheInvalidator: (r) => [r]
  });

  const [deleteResource, deletedResourceStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: null
  });

  const onCopyProjectAsTemplate = useCallback(
    (selection: any[]) => {
      if (selection?.length) {
        selection.map((p) => {
          createResource({
            type: 'project-template',
            display_name: p.display_name,
            relationships: {
              donor_project: {
                data: {
                  type: 'project',
                  id: p.id
                }
              }
            }
          });
        });
      }
    },
    [createResource]
  );

  /** delete the `project-template` resource */
  const onDeleteTemplate = useCallback(
    (ids: string[]) => {
      if (!ids?.length) return;
      ids.forEach((id) => {
        deleteResource({
          type: 'project-template',
          id
        });
      });
    },
    [deleteResource]
  );

  return {
    /** copy an exsited project as a template by
     * - first create a `project-template` resource
     * - then use the provided project as its `donor_project`
     */
    onCopy: onCopyProjectAsTemplate,
    onDeleteTemplate,
    // NOTE: these statuses only reflect the last action that was triggered, not necessarily the last action to finish
    createStatus: createResourceStatus,
    deleteStatus: deletedResourceStatus
  };
};

export const useProjectTemplateMenuActions = () => {
  const { selection } = useSelection();
  const { clearActiveItem, clearItems } = useSelectionActions();

  const { openModal } = useModalActions();

  const onCreate = useCallback(() => {
    clearActiveItem();
    clearItems();
    openModal('workspace_project_template_create');
  }, [openModal, clearActiveItem, clearItems]);

  const onDuplicate = useCallback(() => {
    if (selection.length !== 1) return;
    openModal('workspace_project_template_duplicate', {
      projectTemplateId: selection[0].id
    });
  }, [openModal, selection]);

  /** delete the `project-template` resource */
  const onDelete = useCallback(() => {
    if (!selection.length) return;
    openModal('workspace_project_template_delete', {
      projectTemplates: selection
    });
  }, [selection, openModal]);

  const onRename = useCallback(() => {
    if (selection.length !== 1) return;
    openModal('workspace_project_template_rename', {
      projectTemplate: selection[0]
    });
  }, [openModal, selection]);

  return {
    onCreate,
    onDuplicate,
    onDelete,
    onRename
  };
};
