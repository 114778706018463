import { Button } from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import React, { useCallback } from 'react';
import { useModalActions } from '../../../Modals/modalActions';

export const DisclaimerModal = (props) => {
  const url = props.modalContext;
  const { closeAllModals } = useModalActions();

  const onRedirect = useCallback(() => {
    if (!url) return;
    window.open(url, '_self', 'noopener noreferrer');
    closeAllModals();
  }, [url, closeAllModals]);

  const onCancel = useCallback(() => {
    closeAllModals();
    window.close();
  }, [closeAllModals]);

  return (
    <ModalNoticeUI
      title="Warning: Leaving Cube"
      showCloseButton={false}
      footerRightComponent={
        <Button
          label="Continue"
          //colorVariant="filled1"
          background="primary"
          buttonStyle="solid"
          onClick={onRedirect}
        />
      }
      footerLeftComponent={
        <Button
          label="Cancel"
          //colorVariant="ghost2"
          background="secondary"
          buttonStyle="ghost"
          onClick={onCancel}
        />
      }
    >
      <Typography>
        You are about to leave Cube by clicking on an external link.
      </Typography>
      <div style={{ height: 10 }}></div>
      <Typography color="dark" colorVariant="02">
        {
          'Please note that Cube is not responsible for the content or privacy practices of the destination website. We encourage you to review the privacy policies and terms of use of the external site before providing any personal information.'
        }
      </Typography>
      <div style={{ height: 10 }}></div>
      <Typography color="dark" colorVariant="02">
        {
          'By clicking "Continue", you acknowledge that you are leaving Cube and understand that the external site may have different policies. Cube is not liable for any loss or damage arising from your use of the external site.'
        }
      </Typography>
    </ModalNoticeUI>
  );
};
