import React, { useCallback, useEffect } from 'react';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { useModalActions } from '../../Modals/modalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';

interface Props extends ModalReceiverProps {
  modalContext: { contractId: string };
}

export const DeleteContractPrompt: React.FunctionComponent<Props> = (props) => {
  const { contractId } = props.modalContext;

  const modalActions = useModalActions();

  const [deleteContract, deleteContractStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      (r, wsId) => [{ type: 'workspace', id: wsId, relationship: 'contracts' }],
      []
    )
  });

  const handleClick = useCallback(() => {
    deleteContract({ type: 'contract', id: contractId });
  }, [deleteContract, contractId]);

  const handleClose = useCallback(
    (deleted = false) => {
      if (deleted === true) {
        // If user got here via "edit_contract" or "manage_contract" modal, we need to
        // close those as well, because they try to render the deleted contract.
        // "manage_contract" gets priority because "edit_contract" can be opened from there.
        const idx = modalActions.modalStack.indexOf(
          modalActions.modalStack.filter((m) => {
            return m.modal === 'manage_contract' || m.modal === 'edit_contract';
          })[0]
        );

        const backsteps = idx > -1 ? modalActions.modalStack.length - idx : 1;

        modalActions.goBackModals(backsteps);
      } else {
        modalActions.goBackModals(1);
      }
    },
    [modalActions]
  );

  const handleCloseEvent = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  useEffect(() => {
    if (deleteContractStatus === statuses.SUCCESS) {
      handleClose(true);
    }
  }, [deleteContractStatus, modalActions, handleClose]);

  useGlobalSubmit(handleClick, !!deleteContractStatus);
  return (
    <ModalNoticeUI
      title="Delete contract"
      loading={!!deleteContractStatus}
      onCloseEvent={!deleteContractStatus && handleCloseEvent}
      footerRightComponent={
        <>
          <Button
            text="Cancel"
            colorVariant="ghost2"
            onClick={handleCloseEvent}
            disabled={!!deleteContractStatus}
          />
          <Button
            text="Delete"
            colorVariant="filledDanger"
            onClick={handleClick}
            disabled={!!deleteContractStatus}
          />
        </>
      }
    >
      <Typography>
        Are you sure you want to delete this contract? The contract will be
        detached from any projects or assets that may apply. You will also no
        longer receive expiration notifications.
      </Typography>
    </ModalNoticeUI>
  );
};
