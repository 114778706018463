import React from 'react';
import { Link } from 'react-router-dom';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  CubeAnchorLink,
  useCubeAnchorLinkStyles
} from '@cube3/cubicle/src/core/atoms/CubeAnchorLink';

/// <Summary>
/// @Author : Simon @ AmbassadorsLAB
/// Placeholder component for linker elements.
/// Made as bare as possible.
/// Mostly  made as a placeholder but also to
/// prevent unwanted decoration.
/// </Summary>

interface Properties {
  classes?: ClassNameMap;
  pathname?: string;
  onClick?: () => void;
  search?: string;
  hash?: string;
  state?: { __from?: string };
  underline?: boolean | 'hover';
  injectedClassName?: string;
  location?: {
    pathname: string;
  };
  disabled?: boolean;
}
const noop = () => null;
// creates a link with styling, fills a generic object with
const StyledLink: React.FC<React.PropsWithChildren<Properties>> = (props) => {
  const {
    pathname,
    search,
    hash,
    state,
    underline = false,
    onClick = noop,
    injectedClassName,
    location,
    disabled = false
  } = props;

  const classes = useCubeAnchorLinkStyles();

  if (!pathname || pathname === undefined || pathname === null) {
    console.warn('No url given to cube3link component');
  }

  let to = {
    pathname,
    search,
    hash,
    state: state || {} // TODO: define an appwide routers state interface
  };

  to.state.__from = location ? location.pathname : undefined;

  return (
    <Link
      onClick={onClick}
      className={`${classes.root} ${
        classes[`underline-${underline}`]
      } ${injectedClassName} ${disabled ? classes.disabled : ''} 
      `}
      to={to}
    >
      {props.children}
    </Link>
  );
};

export default StyledLink;

export const Cube3AnchorLink = CubeAnchorLink;
