export const configValid = ({
  resourceType,
  resourceId,
  edgeType,
  edgeLabel,
  params
}) => {
  const rootEdges = !resourceType && !resourceId;
  const nestedEdgesConfig = resourceType && resourceId;
  const rootEdgesConfig = !resourceType && !resourceId && rootEdges;

  return !!(edgeType && (nestedEdgesConfig || rootEdgesConfig));
};
