import React, { useCallback } from 'react';
//
import { Slider } from '@material-ui/core';
//
import CustomIconButton from '../../Buttons/IconButton';
import { iconMuteSpeaker, iconMuteSpeakerWave } from '../../icons/GeneralSVG';
import { useStyles } from './VideoPreviewControls';

interface Props {
  muted: boolean;
  volume: number;
  toggleMute(): void;
  setVolume(value: number): void;
  shortCutTip?: string;
}

export const VolumeControls: React.FC<Props> = React.memo((props) => {
  const { muted, volume, toggleMute, setVolume, shortCutTip } = props;

  const classes = useStyles(props);
  const handleClick = useCallback(() => toggleMute(), [toggleMute]);

  return (
    <>
      {muted || volume === 0 ? (
        <CustomIconButton
          aria-label="unmute"
          colorVariant="ghost3"
          size={'regular'}
          path={iconMuteSpeaker}
          svgProperties={{
            svgProps: { viewBox: '0 0 32 32' }
          }}
          onClick={handleClick}
          title={shortCutTip && `Unmute (${shortCutTip})`}
        />
      ) : (
        <CustomIconButton
          aria-label="mute"
          colorVariant="ghost3"
          size={'regular'}
          path={iconMuteSpeakerWave}
          svgProperties={{
            svgProps: { viewBox: '0 0 32 32' }
          }}
          onClick={handleClick}
          title={shortCutTip && `Mute (${shortCutTip})`}
        />
      )}

      <Slider
        onChange={(event: React.ChangeEvent<{}>, value: any) => {
          setVolume(value);
        }}
        classes={{
          root: classes.volumeSlider,
          track: classes.sliderTrack,
          thumb: classes.thumb,
          rail: classes.rail
        }}
        value={muted ? 0 : volume}
      />
    </>
  );
});
