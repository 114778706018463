import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ChangeProfilePictureModalUI } from '@cube3/ui/src/Layout/AccountSettings/Modals/ChangeProfilePictureModalUI';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import { ExtendedUpload } from '@cube3/state/src/wrapped-cube-client/uploader/extendedupload';
import { defaultConfig } from '@cube3/state/src/wrapped-cube-client/uploader/config';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useDispatch } from 'react-redux';
import { actionCreators } from '@cube3/state/src/redux/ducks/request-status';
import Client from '@cube3/state/src/wrapped-cube-client';

interface ModalProps {
  modalContext: {
    userId: string;
  };
}

type PreviewPicture = { name: string; src: any };

const ChangeProfilePictureModal: React.FC<ModalProps> = props => {
  const { userId } = props.modalContext;
  const { previousModal, openModal } = useModalActions();
  const [targetImage, setTargetImage] = useState<PreviewPicture>();
  const [avatar, setAvatar] = useState<string>(undefined);
  const [error, setError] = useState(undefined);
  /** for telling cropper when to crop image */
  const [shouldCrop, setShouldCrop] = useState(false);
  const [readingFile, setReadingFile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [shouldPolling, setShouldPolling] = useState(false);

  const dispatch = useDispatch();
  const user = useResource__ALPHA({
    resourceId: userId,
    resourceType: 'user'
  }).first;

  /** handle drag and drop pictures in Dropzone */
  const handleOnDrop = useCallback(acceptedFile => {
    const file = acceptedFile[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadstart = () => setReadingFile(true);
    reader.onload = e => {
      setTargetImage({
        name: file.name,
        src: e.target.result
      });
      setReadingFile(false);
    };
    reader.readAsDataURL(file);
  }, []);

  const uploadAvatar = React.useCallback(() => {
    const TOKEN = Client.auth.getAccessToken();
    if (!TOKEN) {
      setError("You don't have permission to change profile picture");
      return;
    }
    setUploading(true);
    window
      .fetch(avatar)
      .then(res => res.blob()) // convert the image to be a file
      .then(data => {
        const uploadAvatar = new ExtendedUpload(data, {
          ...defaultConfig,
          metadata: {
            ...defaultConfig.metadata,
            user_id: userId,
            session_token: TOKEN
          },
          onError: err => {
            setError(err.message);
            setUploading(false);
          },
          onSuccess: () => {
            setError(undefined);
            setUploading(false);
            setProcessing(true);
            setShouldPolling(true);
          }
        });
        uploadAvatar.start();
      });
  }, [avatar, userId]);

  //upload avatar
  useEffect(() => {
    if (avatar) {
      setShouldCrop(false);
      uploadAvatar();
    }
  }, [avatar, setShouldCrop, uploadAvatar]);

  const profilePicture = user && user.profile_picture;
  const oldAvatarRef = useRef(user?.profile_picture);

  /** polling user's profile_picture */
  useEffect(() => {
    if (shouldPolling) {
      if (!profilePicture || profilePicture === oldAvatarRef?.current) {
        setProcessing(true);
        const timer = setTimeout(() => {
          dispatch(
            actionCreators.invalidateResource({
              type: 'user',
              id: userId
            })
          );
        }, 3000);
        return () => {
          window.clearTimeout(timer);
        };
      } else {
        setProcessing(false);
        setShouldPolling(false);
        previousModal();
      }
    }
  }, [
    dispatch,
    shouldPolling,
    user,
    userId,
    avatar,
    previousModal,
    profilePicture
  ]);

  useEffect(() => {
    if (error) {
      openModal('accountsettings_ProfilePicture_error', { error }, false);
      setError(undefined);
    }
  }, [error, openModal]);

  return (
    <ChangeProfilePictureModalUI
      onCloseEvent={previousModal}
      loading={uploading || processing}
      image={targetImage}
      onDrop={handleOnDrop}
      onClickSave={() => setShouldCrop(true)}
      setAvatar={setAvatar}
      shouldCrop={shouldCrop}
      readingFile={readingFile}
    />
  );
};

export default ChangeProfilePictureModal;
