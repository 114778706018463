import React from 'react';

import { compose } from '../../../../../../utils/component-helpers';
import { withResource } from '@cube3/state/src/redux/components/withResource';
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@cube3/ui/src/typography/Typography';
import {
  useFormatMetadataCategory,
  useFormatMetadataValues
} from '@cube3/state/src/redux/components/Hooks/metadataHooks';
import { MetadataReadOnlyUI } from '@cube3/ui/src/metadata/MetadataReadonlyUI';
import HumanReadableResolver from '../../../../../helpers/HumanReadableResolver';

const MetadataReadonly = ({
  metadataFormId,
  metadataForm,
  metadataValues = [],
  loading
}) => {
  const formFields = useFormatMetadataCategory(metadataForm);
  const values = useFormatMetadataValues(metadataValues, metadataForm);

  if (loading || !metadataForm) {
    return <LinearProgress />;
  }

  if (formFields) {
    return (
      formFields.length > 0 && (
        <MetadataReadOnlyUI
          formFields={formFields}
          values={values}
          title={metadataForm.display_name}
          resolverComponent={HumanReadableResolver}
        />
      )
    );
  } else {
    return <Typography> No metadata available </Typography>;
  }
};

/**
 * @summary Displays `metadata` that is read only, for instance used in `Detailview`
 */
export const MetadataReadOnlySmart = compose(MetadataReadonly)(
  withResource<{metadataFormId: string}>({
    resourceType: 'metadata-category',
    resourceId: ({ metadataFormId }) => metadataFormId,
    mapper: 'metadataForm'
  }),
  withResourceEdges({
    resourceType: ({ metadataForResource }) =>
      metadataForResource && metadataForResource.type,
    resourceId: ({ metadataForResource }) =>
      metadataForResource && metadataForResource.id,
    edgeType: 'metadata-value',
    edgeLabel: `metadata-values`,
    mapper: 'metadataValues',
    strategy: 'fetch-on-mount'
  })
);
