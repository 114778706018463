import React from 'react';
import { useCallback } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { ProjectMemberUserDetailUI } from '@cube3/ui/src/Projects/Modals/ProjectMemberUserDetailUI';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { PROJECT_ROLE_TYPES } from '@cube3/state/types';

interface ProjectMemberUserDetailProps {
  projectId: string;
  canEdit: boolean;
  account: {
    type: 'account';
    id: string;
    role: string;
    projectMemberId: string;
  };
  membersCount: number;
  adminsCount: number;
  isAdmin?: boolean;
  isCurrentAccount: boolean;
  isMember?: boolean;
  memberInTeams: string[];
  isProjectPublic?: boolean;
}

export const ProjectMemberUserDetail = (
  props: ProjectMemberUserDetailProps
) => {
  const {
    account,
    membersCount,
    canEdit,
    projectId,
    isCurrentAccount,
    adminsCount,
    memberInTeams = false,
    isProjectPublic,
    ...restProps
  } = props;
  const { openModal } = useModalActions();

  const accountResource = useResource__ALPHA({
    resourceType: 'account',
    resourceId: account?.id
  }).resource;

  const userInfo = useResource__ALPHA({
    resourceType: 'user',
    resourceId:
      accountResource &&
      accountResource.relationships &&
      accountResource.relationships.user &&
      (accountResource.relationships.user.id ||
        accountResource.relationships.user.data.id)
  }).resource;

  const askRoleChange = useCallback(
    (role) => {
      if (adminsCount < 2 && role.role !== 'Admin') {
        openModal('projects_last_admin', { remove: false }, false);
      } else {
        openModal('projects_change_role', {
          projectMemberId: account.projectMemberId,
          isYou: isCurrentAccount,
          newRole: role,
          name: userInfo?.full_name
        });
      }
    },
    [adminsCount, isCurrentAccount, account, openModal, userInfo]
  );

  /** click to open RemoveProjectMemberModal / ProjectLastAdminNotice  */
  const onRemoveMemberClick = useCallback(() => {
    const isLastAdmin =
      account.role === PROJECT_ROLE_TYPES.Admin && adminsCount <= 1;
    const isLastMember =
      account.role !== PROJECT_ROLE_TYPES.Member && membersCount <= 1;

    if (!isLastAdmin && !isLastMember) {
      openModal('projects_removemember', {
        projectId,
        projectMemberId: account.projectMemberId,
        isCurrentAccount,
        memberInTeams,
        isProjectPublic
      });
    } else {
      openModal(
        'projects_last_admin',
        { remove: true, lastMember: isLastMember },
        false
      );
    }
  }, [
    account.projectMemberId,
    account.role,
    adminsCount,
    isCurrentAccount,
    membersCount,
    openModal,
    projectId,
    memberInTeams,
    isProjectPublic
  ]);

  return (
    <ProjectMemberUserDetailUI
      {...restProps}
      userInfo={userInfo}
      canEdit={canEdit}
      isCurrentAccount={isCurrentAccount}
      onRoleChange={askRoleChange}
      onRemoveMemberClick={onRemoveMemberClick}
    />
  );
};
