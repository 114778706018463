import React, { useCallback } from 'react';

import { useTitle } from './hooks/useTitle';
import { environment } from '@cube3/state/src/utils/environment';
import { matchPath, useLocation } from 'react-router';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

const titleBase = 'Cube';

/**
 * Component to dynamically modify the document title based
 * on current route. Will also add an environment label if
 * not running in production.
 */
export const DocumentTitle = () => {
  const location = useLocation();

  const m = useCallback(
    (route) => {
      return !!matchPath(location.pathname, route);
    },
    [location]
  );

  switch (true) {
    case m('/account/login'):
      return <BaseTitle label="sign in" />;
    case m('/account/settings'):
      return <BaseTitle label="account settings" />;
    case m('/legal'):
      return <BaseTitle label="legal policies" />;
    case m('/workspace/'):
      return <WorkspaceTitle />;
    case m('/share-link/'):
      return <BaseTitle label="Share-link" />;
    case m('/review/'):
    case m('/review-link/'):
      return <BaseTitle label="Review-link" />;
    case m('/file-request/'):
      return <BaseTitle label="File-request" />;
    default:
      return <BaseTitle />;
  }
};

/**
 * Sub component for rendering workspace names into document title
 */
const WorkspaceTitle = () => {
  const workspace = useCurrentWorkspace()[1];

  useTitle(
    `${titleBase} - ${workspace?.display_name || 'Workspace'}${
      environment !== 'production' ? ` (${environment})` : ''
    }`
  );
  return null;
};

/**
 * Sub component for rendering basic route names into document title
 */
const BaseTitle = ({ label = undefined }) => {
  useTitle(
    `${titleBase}${label ? ` - ${label}` : ''}${
      environment !== 'production' ? ` (${environment})` : ''
    }`
  );
  return null;
};
