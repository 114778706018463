import React, { useCallback, useState, useEffect, useRef } from 'react';
import { ImageCropperUI } from './ImageCropperUI';

interface Props {
  image: any;
  width: number;
  height: number;
  radius: number;
  minRadius?: number;
  scale?: number;
  disabled?: boolean;
  shouldCrop: boolean;
  setAvatar: (image: string) => void;
}
type PrevTouch = {
  crop_left: number;
  crop_top: number;
  mouse_x: number;
  mouse_y: number;
};

export const ImageCropper: React.FunctionComponent<Props> = props => {
  const {
    image,
    width: containerWidth,
    height: containerHeight,
    scale = 1,
    minRadius = 50,
    radius,
    shouldCrop,
    disabled,
    setAvatar
  } = props;

  const [ratio, setRatio] = useState(scale);
  const [dragging, setDragging] = useState(false);
  const [newImage, setNewImage] = useState(undefined);
  const [imageSize, setImageSize] = useState({
    width: undefined,
    height: undefined
  });
  const canvasRef = useRef(null);
  const cropperRef = useRef(null);
  const imageRef = useRef<HTMLImageElement>();
  const prevTouchRef = useRef<PrevTouch>();
  const stylesRef = useRef(null);

  const startDrag = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(true);
      // store position of mouse and cropper
      const cropLeft = e.target.offsetLeft;
      const cropTop = e.target.offsetTop;
      const mouseCoordX = e.clientX + window.scrollX;
      const mouseCoordY = e.clientY + window.scrollY;
      prevTouchRef.current = {
        crop_left: cropLeft,
        crop_top: cropTop,
        mouse_x: mouseCoordX,
        mouse_y: mouseCoordY
      };
    },
    [setDragging]
  );

  const boundaryCalculator = useCallback(
    (r: number) => {
      const min_left = Math.max(0, (containerWidth - imageSize.width) / 2);
      const min_top = Math.max(0, (containerHeight - imageSize.height) / 2);

      const max_left = Math.max(0, containerWidth - min_left - r);
      const max_top = Math.max(0, containerHeight - min_top - r);

      return {
        min_left,
        min_top,
        max_left,
        max_top
      };
    },
    [containerHeight, containerWidth, imageSize]
  );

  /** update css values (left,top,radius) of the cropper */
  const updateStyles = useCallback(() => {
    const cropper: HTMLDivElement = cropperRef.current;
    const cropperStyles = stylesRef?.current;
    const innerImage = imageRef.current;

    if (!cropper || !innerImage || !cropperStyles) return;
    const { left, top, radius } = cropperStyles;

    // update cropper styles
    cropper.style.left = `${left}px`;
    cropper.style.top = `${top}px`;
    cropper.style.width = `${radius}px`;
    cropper.style.height = `${radius}px`;

    // update inner image styles
    const center_left = (containerWidth - 4 - imageSize.width) / 2;
    const center_top = (containerHeight - 4 - imageSize.height) / 2;
    innerImage.style.top = `${-top + center_top}px`;
    innerImage.style.left = `${-left + center_left}px`;
  }, [containerHeight, containerWidth, imageSize]);

  /** update cropper's left and top */
  const updateLeftTop = useCallback(
    (newLeft: number, newTop: number, r: number) => {
      const { min_left, min_top, max_left, max_top } = boundaryCalculator(r);

      // handle reaching boundaries
      const left = Math.max(min_left, Math.min(max_left, newLeft));
      const top = Math.max(min_top, Math.min(max_top, newTop));
      stylesRef.current = {
        left,
        top,
        radius: r
      };
    },

    [boundaryCalculator]
  );

  /** update ratio */
  const updateRatio = useCallback(
    (newR: number) => {
      const ratio = parseFloat((radius / newR).toFixed(2));
      setRatio(ratio);
    },
    [radius]
  );

  const handleDrag = useCallback(
    e => {
      if (!dragging || !e || !prevTouchRef.current) return;
      e.preventDefault();
      e.stopPropagation();
      const currentTouch = { x: e.clientX, y: e.clientY };
      const { mouse_x, mouse_y, crop_left, crop_top } = prevTouchRef.current;

      let left = currentTouch.x - mouse_x + crop_left;
      let top = currentTouch.y - mouse_y + crop_top;
      updateLeftTop(left, top, stylesRef.current?.radius);
      requestAnimationFrame(updateStyles);
    },
    [dragging, updateLeftTop, updateStyles]
  );

  /** handle resizing via control or mouse wheel */
  const handleResize = useCallback(
    (zoom: number, newR: number) => {
      const maxRadius = Math.min(imageSize.width, imageSize.height);
      if (zoom === 0 || newR < minRadius || newR > maxRadius) return;
      const cropper = cropperRef?.current;
      let newLeft = cropper.offsetLeft - zoom / 2;
      let newTop = cropper.offsetTop - zoom / 2;
      updateRatio(newR);
      updateLeftTop(newLeft, newTop, newR);
    },
    [imageSize.height, imageSize.width, minRadius, updateLeftTop, updateRatio]
  );

  /** resize via mouse wheel */
  const handleWheelChange = useCallback(
    e => {
      const deltaY = e.deltaY > 0 ? scale : -scale;
      const zoom = Math.round(deltaY * Math.PI * 2);
      const resizedRadius = stylesRef.current?.radius + zoom;
      handleResize(zoom, resizedRadius);
      requestAnimationFrame(updateStyles);
    },
    [scale, handleResize, updateStyles]
  );

  /** resize via control */
  const handleRangeChange = useCallback(
    value => {
      const v = Number(value);
      handleResize(v - stylesRef.current?.radius, v);
      requestAnimationFrame(updateStyles);
    },
    [handleResize, updateStyles]
  );

  const drawImage = useCallback(() => {
    let canvas = canvasRef?.current;
    if (!canvas) {
      canvas = document.createElement('canvas');
      canvas.setAttribute('width', `${radius}px`);
      canvas.setAttribute('height', `${radius}px`);
      canvasRef.current = canvas;
    }
    const imageEl = new Image();
    // prevent from DOMException error
    imageEl.crossOrigin = 'anonymous';
    imageEl.onload = () => {
      const { width, height } = imageSize;
      const { left, top } = stylesRef.current;
      const l = -left + (containerWidth - width) / 2;
      const t = -top + (containerHeight - height) / 2;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        imageEl,
        l * ratio,
        t * ratio,
        width * ratio,
        height * ratio
      );
      const pngUrl = canvas.toDataURL('image/png', scale);
      setNewImage(pngUrl);
    };
    imageEl.src = image.src;
  }, [
    containerHeight,
    containerWidth,
    image.src,
    imageSize,
    radius,
    ratio,
    scale
  ]);

  // initialize state
  const handleOnLoad = useCallback(
    e => {
      // store image size
      const imageHeight = e.target.offsetHeight;
      const imageWidth = e.target.offsetWidth;
      const initialRadius = Math.min(radius, Math.min(imageHeight, imageWidth));
      setImageSize({
        height: imageHeight,
        width: imageWidth
      });
      stylesRef.current = {
        left: (containerWidth - initialRadius) / 2,
        top: (containerHeight - initialRadius) / 2,
        radius: initialRadius
      };
      updateRatio(initialRadius);
    },
    [containerHeight, containerWidth, radius, updateRatio]
  );

  /** handle click save  */
  useEffect(() => {
    if (shouldCrop) {
      drawImage();
      setAvatar(newImage);
    }
  }, [drawImage, newImage, setAvatar, shouldCrop]);

  /** reset the circle state when dragging in a new image */
  useEffect(() => {
    stylesRef.current = {
      left: (containerWidth - radius) / 2,
      top: (containerHeight - radius) / 2,
      radius: radius
    };
  }, [containerHeight, containerWidth, image, radius]);

  useEffect(() => {
    const stopDrag = () => setDragging(false);
    window.addEventListener('mouseup', stopDrag);
    return () => {
      window.removeEventListener('mouseup', stopDrag);
    };
  }, []);

  return (
    <ImageCropperUI
      imageRef={imageRef}
      imageSrc={image.src || undefined}
      cropperRef={cropperRef}
      min={minRadius}
      max={Math.min(imageSize.width, imageSize.height)}
      styles={stylesRef.current}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      disabled={disabled}
      onLoad={handleOnLoad}
      onMouseDown={startDrag}
      onMouseMove={handleDrag}
      onWheel={handleWheelChange}
      onRangeChange={handleRangeChange}
    />
  );
};
