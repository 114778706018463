import { Validator } from '../useGenericExportOptions';
import { getFieldValue } from './getFieldValue';

export const makeRequiredValidator = (fieldId) => {
  return ((values, fields) => {
    return [null, undefined, ''].includes(
      getFieldValue(values, fieldId)?.value as never
    ) ||
      (Array.isArray(getFieldValue(values, fieldId)?.value) &&
        (getFieldValue(values, fieldId)?.value as never[]).length === 0)
      ? [
          {
            field: fieldId,
            message: 'Required',
            code: 'ERROR_REQUIRED',
            type: 'error'
          }
        ]
      : undefined;
  }) as Validator;
};
