import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { mergeStatuses } from '@cube3/state/src/redux/utils/mergeStatuses';

export const useActivityActor = (actorRID) => {
  const isAccount = actorRID.type === 'account';
  const isUser = actorRID.type === 'user';

  const accountResource = useResource__ALPHA({
    resourceId: isAccount ? actorRID?.id : undefined,
    resourceType: 'account'
  });

  const userResource = useResource__ALPHA({
    resourceId: isUser
      ? actorRID.id
      : accountResource.resource?.relationships.user.id,
    resourceType: 'user'
  });

  const loading = userResource.loading || accountResource.loading;
  const status = mergeStatuses(
    [accountResource.status, userResource.status],
    true
  );

  return {
    name: userResource.resource?.full_name,
    resource: userResource.resource,
    loading,
    status
  };
};
