import { createStore, applyMiddleware, compose } from 'redux';

//
import { rootReducer } from './root';

import loggerMiddleware from './middleware/logger-middleware';
import { uiMiddleware } from './middleware/uiMiddleware';
import {
  load,
  mergeLoaders,
  save
} from './middleware/local-storage-middleware';

import { cubeClientMiddleware } from './middleware/cube-client-middleware';
import { uploadsMiddleware } from './middleware/uploads-middleware';
// import signupSigninMiddleware from './middleware/signup-signin';
import offlineDetectionMiddleware from './middleware/offline-detection-middleware';
import clipboardMiddleware from './middleware/clipboard-middleware';
import reduxFormMiddleware from './middleware/redux-form-middleware';
import requestStatusMiddleware from './middleware/request-status-middleware';
import cookieConsentMiddleware, {
  cookieConsentEnhancer
} from './middleware/cookie-consent-middleware';

import batchedSubscribeEnhancer from './batching/enhancer';
import batchedSubscribeMiddleware from './batching/middleware';

import { Uploader } from '../wrapped-cube-client';

import { saveUploads } from './saveUploads';
import { UiScreenDefaultState } from './ducks/ui';
import { omit, merge } from 'lodash';

import { makeSentryMiddleware } from './middleware/sentry-middleware';
import * as Sentry from '@sentry/browser';
import { fuuidExp, uuidParser } from '../utils/uuidparser';
import { cancelRetrieveMiddleware } from './middleware/cancel-retrieve-middleware';
import { reviewChangesMiddleware } from './middleware/review-changes-middleware';

const enhancer = (config) =>
  compose(
    cookieConsentEnhancer(),
    batchedSubscribeEnhancer,
    applyMiddleware(
      ...[
        batchedSubscribeMiddleware,
        Uploader.getEnhancer(),
        makeSentryMiddleware(Sentry),
        cancelRetrieveMiddleware,
        cookieConsentMiddleware(),
        config.offLineDetection ? offlineDetectionMiddleware : undefined,
        uploadsMiddleware,
        clipboardMiddleware,
        reduxFormMiddleware,
        requestStatusMiddleware,
        reviewChangesMiddleware,
        cubeClientMiddleware,
        // signupSigninMiddleware,
        // routerMiddleware,
        loggerMiddleware,
        uiMiddleware,
        save({
          selectors: ['session', 'ui'],
          redactors: [
            'password',
            'sharelink',
            'magicLink',
            'fullscreen',
            'fullscreenRequested',
            'modalStack',
            'mediaQueueToggled'
          ],
          testFn: (state, storage) => {
            // if the session contains a workspaceId, it is considered a "main" session,
            // with a higher level of importance than "magic-link" sessions
            //  used for shares. That means we never want the magic link session to
            // overwrite the "main" one, but we still want to store it if no session exists
            return (
              state?.session?.workspace ||
              (state.session.auth && !storage?.getItem('auth'))
            );
          },
          namespace: 'session'
        }),
        save({
          selectors: [saveUploads],
          redactors: ['tusUpload'],
          testFn: (state) => {
            return state && state.resourceNodes;
          },
          namespace: 'uploads'
        })
      ].filter(Boolean)
    )
  );

export const rootStore = (config) => {
  return createStore(
    rootReducer(config),
    mergeLoaders([
      load({
        namespace: 'session',
        selector: (state, loadedState) => {
          // make sorting change in ui reducer not mess up app layout
          if (state?.ui?.orderBy && UiScreenDefaultState['sorting']) {
            const oldUI = omit(state?.ui, ['orderBy', 'orderDirection']);
            state.ui = {
              ...oldUI,
              sorting: {
                ...UiScreenDefaultState['sorting'],
                library: {
                  orderBy: state?.ui?.orderBy,
                  orderDirection: state?.ui?.orderDirection
                }
              }
            };
          }

          if (state?.ui) {
            state.ui = merge({}, UiScreenDefaultState, state.ui);
          }

          // make old account fuuids not hang the app
          if (
            config.rewriteUUIDS &&
            state?.session?.account &&
            fuuidExp.test(state?.session?.account)
          ) {
            state.session.account = state.session.account?.replace(
              fuuidExp,
              (uuid) => uuidParser(uuid)
            );
          }

          if (location.pathname.match(/^\/file-request/)) {
            if (state?.session?.workspace) {
              state.session.workspace = null;
            }
          }

          return state;
        }
      }),
      load({
        namespace: 'uploads',
        selector: (state, loadedState) => {
          if (location.pathname.match(/^\/file-request/)) {
            return;
          }

          if (state && config.rewriteUUIDS) {
            state = JSON.parse(
              JSON.stringify(state)?.replace(fuuidExp, (fuuid) =>
                uuidParser(fuuid)
              )
            );
          }

          const ws = loadedState?.session?.workspace;
          return ws && state && state[ws];
        }
      })
    ]),
    enhancer(config)
  );
};

interface Config {
  offlineDetection: boolean;
  cookieConsentVersions: null | undefined;
  rewriteUUIDS: boolean;
}

export const configureStore = (config: Config) => {
  return rootStore(config);
};
