import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface MetadataValue extends ResourceBase {
  field_id: string;
  value: unknown;
}

export const metadataValues: RawModel = {
  type: 'metadata-value',
  plural: 'metadata-values',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    value: 'any',
    field_id: 'UUID'
  },
  relationships: {
    'metdata-values_for': {
      type: ['asset', 'project'],
      binding: ['one', 'many'],
      reverse: 'metadata-values',
      required: false
    },
    'metadata-category': {
      type: 'metadata-category',
      binding: ['one', 'many'],
      reverse: 'metadata-values',
      required: false
    }
  }
};
