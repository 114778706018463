/* eslint-disable eqeqeq, no-throw-literal, no-use-before-define, default-case */

import { Point } from './point';
import { Line } from './line';
import { Bezier } from './bezier';
import { Brush, BrushConfig } from './brush';

/**
 * @class Stroke
 * In classical terms a Stroke describes the path of a
 * brush on a canvas, from the moment that it touches
 * the surface to when it is lifted up again.
 *
 * In technical terms a stroke consists of 1 or more
 * points that describe the path.
 *
 * The interpolation between two points is described as
 * as a curve, which we store as a segment of this stroke
 * in the Stroke.segments array.
 * A segment can be a Point, Line or Bezier and together
 * describe the entire stroke.
 *
 * @constructor
 * @param {Array} points Optional array of points
 */
export class Stroke {
  brushConfig: BrushConfig;
  type: 'stroke';
  isStroke: boolean;
  points: Point[];
  segments: Array<Point | Line>;
  length: number;

  constructor(brush) {
    this.type = 'stroke';
    this.isStroke = true;

    if (!(brush instanceof Brush)) throw 'Stroke requires a brush';

    this.points = [];
    this.segments = [];
    this.length = 0;

    // TODO: we need a setBrushConfig method!
    this.brushConfig = Object.apply({}, brush.config);
  }

  /**
   * Adds a series of points to the Stroke.
   * @param {Array} arr the array of points. Here points can be
   * of type {@link Point Point} or another array
   * in the form of [x, y].
   * @return {Stroke} the Stroke, support method chaining
   */
  addPoints(arr) {
    for (var i = 0, len = arr.length; i < len - 1; i++) {
      this.addPoint(arr[i]);
    }

    return this;
  }

  /**
   * Adds a single point to the Stroke.
   * @param {Point} p the point.
   * @return {Stroke} the Stroke, support method chaining
se */
  addPoint(p) {
    var segment, prevSegment, points, controlPoints;

    // don't add duplicate points
    if (
      this.points.length &&
      this.getPoint(-1).x == p.x &&
      this.getPoint(-1).y == p.y
    ) {
      return;
    }

    // add the point to the points array
    this.points.push(p);

    // apply smoothing to the stroke
    if (
      this.brushConfig.smoothTension > 0 &&
      this.brushConfig.smoothDuration > 0
    ) {
      this.smooth();
    }

    // TODO: we can probably optimise this method by creating Line segments for points that are
    // less than 3 pixels apart for example. This means that the choice for a Cubic or
    // Quadratic Bezier will need to depend on wether the previous segment is a Line
    // or a Bezier curve.

    // Check if we have enough points to form a Bezier curve
    // Note: A Cubic Bezier curve requires a start and end point, and two additional
    // control points that influence the curvature of the line.
    // To derive the control points cp1 and cp2 we need to have at least three points:
    // p1, p2 and p3. The two control points that will be returned will belong to p2,
    // where the first control point describes the curvature on the left of p2, and the
    // second control point the curvature of the right of p2.

    // A Quadratic Bezier curve is very similar to a Cubic Bezier curve, but the
    // line is only influenced by one control point cp1. We will position cp2 at the
    // same position as the end point p2, which effectively renders its influence to none.
    if (this.points.length > 2) {
      // get the last three points
      points = this.points.slice(this.points.length - 3);
      // get the control points for the middle point
      controlPoints = this.getControlPoints(points);
    }

    // grab the previous segment if it exists
    prevSegment = this.getSegment(-1);

    // upgrade any previous segment
    if (prevSegment && prevSegment.isPoint) {
      // upgrade Point to a Line
      prevSegment = this.popSegment();
      prevSegment = new Line(prevSegment, p);
      this.addSegment(prevSegment);
    } else if (prevSegment && prevSegment.isLine) {
      // upgrade Line to a Quadratic Bezier
      prevSegment = this.popSegment();
      prevSegment = new Bezier(
        prevSegment.p1,
        prevSegment.p1,
        controlPoints[0],
        prevSegment.p2
      );
      this.addSegment(prevSegment);
    } else if (prevSegment && prevSegment.isBezier) {
      // update Quadratic Bezier to Cubic Bezier
      prevSegment = this.popSegment();
      prevSegment = new Bezier(
        prevSegment.p1,
        prevSegment.p2,
        controlPoints[0],
        prevSegment.p4
      );
      this.addSegment(prevSegment);
    }

    // add the new segment
    if (controlPoints) {
      // add a new bezier segment
      segment = new Bezier(points[1], controlPoints[1], points[2], points[2]);
      this.addSegment(segment);
    } else if (!prevSegment) {
      // add the Point as the first segment
      this.addSegment(p);
    }

    return this;
  }

  smooth(tension?, duration?) {
    tension = tension || this.brushConfig.smoothTension || 0;
    duration = falloff || this.brushConfig.smoothDuration || 1;

    var falloff = tension / duration,
      t = tension,
      i;

    if (this.points.length > 1) {
      for (i = this.points.length - 2; i >= 0; i--) {
        t -= falloff;
        if (t <= 0) break;
        this.points[i].moveTowards(this.points[i + 1], t * Math.min(1, i / 10));
      }

      // TODO: if we only expect Bezier shapes, we should be able to know
      // how many segments need to be updated, and we don't need to forEach over
      // all of them.

      // re-evaluate all segments
      this.segments.forEach(function (segment) {
        if (segment.needsUpdate()) segment.update(this);
      }, this);
    }
  }

  /**
   * returns a point by index. Note that getPoint supports negative indexes,
   * performing a backwards lookup. To get the last point an index of -1 can be provided.
   * @param {Number} i index as a signed integer
   * @return {Point} the Point requested or undefined
   */
  getPoint(i) {
    if (this.points.length == 0) return undefined;
    i = i < 0 ? this.points.length + i : i;
    return this.points[i];
  }

  /**
   * add a segment to the stroke and update the total length of the stroke.
   * @param {Number} i index as a signed integer
   * @return {Stroke} the Stroke, support method chaining
   */
  addSegment(segment) {
    this.segments.push(segment);

    // update the length of this stroke
    this.length = this.getLength();

    return this;
  }

  /**
   * remove the last curve from the Stroke, and update the length of the Stroke
   * @return {Mixed} the segment. One of [Point, Line, Bezier]
   */
  popSegment() {
    var curve = this.segments.pop();

    // update the length of this stroke
    this.length = this.getLength();

    return curve;
  }

  /**
   * returns a segment by index. Note that getSegment supports negative indexes,
   * performing a backwards lookup. To get the last segment an index of -1 can be provided.
   * @param {Number} i index as a signed integer
   * @return {Mixed} the segment. One of [Point, Line, Bezier]
   */
  getSegment(i) {
    if (this.segments.length == 0) return undefined;
    i = i < 0 ? this.segments.length + i : i;
    return this.segments[i];
  }

  /**
   * return the length of the entire stroke
   * @return {Float} stroke length
   */
  getLength() {
    return this.segments.reduce(function (sum, segment) {
      return (sum += segment.length);
    }, 0);
  }

  /**
   * returns two Bezier control points based on three Points that sit on the Bezier Curve.
   * Note: A Cubic Bezier curve requires a start and end point, and two additional
   * control points that influence the curvature of the line.
   * To derive the control points cp1 and cp2 we need to have at least three points:
   * p1, p2 and p3. The two control points that will be returned will belong to p2,
   * where the first control point describes the curvature on the left of p2, and the
   * second control point the curvature of the right of p2.
   * @param {Point} p1 first point
   * @param {Point} p2 second point, this is the point which the control points will belong to
   * @param {Point} p3 third point
   * @param {Float} tension the Bezier tension for the control points, defaults to 0.33
   * @return {Array} array of two control points {@link CUBE.SketchPad.Point Point}
   */
  getControlPoints(p1, p2?, p3?, tension?) {
    tension = tension == undefined ? 0.33 : tension;

    // support array argument assignment
    if (p1 instanceof Array) {
      p3 = p1[2];
      p2 = p1[1];
      p1 = p1[0];
    }

    var v = p1.offset(p3),
      d12 = p1.distance(p2),
      d23 = p2.distance(p3),
      d123 = d12 + d23,
      cp1 = new Point(
        p2.x - (v[0] * tension * d12) / d123,
        p2.y - (v[1] * tension * d12) / d123
      ),
      cp2 = new Point(
        p2.x + (v[0] * tension * d23) / d123,
        p2.y + (v[1] * tension * d23) / d123
      );

    return [cp1, cp2];
  }

  /**
   * https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
   * SVG commands for <path> nodes. Capitals for absolute positions,
   * lowercase for relative values.
   *
   * - M: move to (x,y)
   * - L: line to (x,y)
   * - H: horizontal line (x)
   * - V: vertical line (x)
   * - Z: close
   *
   * - C: Cubic Bezier (x2,y2,x3,y3,x4,y4)
   * - S: Continued Cubic Bezier (x3,y3,x4,y4) used to string multiple bezier curves together
   * - Q: Quadratic Bezier (x2,y2,x3,y3)
   * - T: Continued Quadratic Bezier (x3,y3)
   */
  toSVG(cfg) {
    cfg = cfg || {};

    // handle paths
    var svg = '',
      width = cfg.width || 1,
      height = cfg.height || 1,
      segment = this.segments[0],
      len = this.segments.length,
      i = 0,
      prevSegment,
      isEraser = this.brushConfig.composite == 'destination-out';

    // handle single point strokes
    if (segment.isPoint && len == 1) {
      svg = `<circle id="${cfg.id}" cx="${(segment.x as number) * width}" cy="${
        (segment.y as number) * height
      }" r="${this.brushConfig.size / 2}" fill="${
        isEraser ? 'black' : this.brushConfig.color
      }"`;
    } else {
      svg = `<path id="${cfg.id}"`;
      // open the data property
      svg += ' d="';

      // process each segment
      for (i; i < len; i++) {
        segment = this.segments[i];
        svg += ` ${segment.toSVG(prevSegment, width, height)}`;
        prevSegment = segment;
      }

      // close the data property
      svg += '"';
      // add the stroke styling properties
      svg += ` fill="${this.brushConfig.color}" fill-opacity="0"`; // make sure open paths are not filled
      svg += ` stroke="${isEraser ? 'black' : this.brushConfig.color}"`;
      // svg += ` opacity="${this.brushConfig.opacity}"`;  // disabled because opacity of a brush works slightly different
      svg += ` stroke-width="${this.brushConfig.size}"`;
      svg += ` stroke-linecap="round" stroke-linejoin="round"`; // rounded ends
    }

    // sketchpad specific properties
    svg += ` brush-softness="${this.brushConfig.softness}"`;
    svg += ` brush-opacity="${this.brushConfig.opacity}"`;
    svg += ` brush-composite="${this.brushConfig.composite}"`;
    svg += ` brush-step="${this.brushConfig.step}"`;
    svg += ` brush-sx="${this.brushConfig.scale[0]}"`;
    svg += ` brush-sy="${this.brushConfig.scale[1]}"`;
    svg += ` brush-cx="${this.brushConfig.center[0]}"`;
    svg += ` brush-cy="${this.brushConfig.center[1]}"`;
    svg += ` brush-rotate="${this.brushConfig.rotate}"`;
    svg += `/>`;

    return svg;
  }

  fromSVG(el) {
    // handle path elements
    if (el.tagName == 'path') {
      var data = el.getAttribute('d'),
        parts = data.split(' '),
        len = parts.length,
        i = 0;

      // setup brush config
      this.brushConfig = {
        size: parseFloat(el.getAttribute('stroke-width')),
        softness: parseFloat(el.getAttribute('brush-softness')),
        color: el.getAttribute('stroke'),
        opacity: parseFloat(el.getAttribute('brush-opacity')),
        composite: el.getAttribute('brush-composite'),
        step: parseFloat(el.getAttribute('brush-step')),
        scale: [
          parseFloat(el.getAttribute('brush-sx')),
          parseFloat(el.getAttribute('brush-sy'))
        ],
        center: [
          parseFloat(el.getAttribute('brush-cx')),
          parseFloat(el.getAttribute('brush-cy'))
        ],
        rotate: parseFloat(el.getAttribute('brush-rotate'))
      };

      for (i; i < len; i++) {
        if (isNaN(parts[i])) {
          // part is an SVG command
          switch (parts[i]) {
            case 'M':
            case 'L':
              // Move to or Line commands both only contain one point
              this.addPoint([
                parseFloat(parts[i + 1]),
                parseFloat(parts[i + 2])
              ]);
              i += 2; // move up two positions
              break;

            case 'S':
              // Continued Cubic Bezier only contains a single control point and the last point. We don't care about
              // the control points, so only add the last point.
              this.addPoint([
                parseFloat(parts[i + 3]),
                parseFloat(parts[i + 4])
              ]);
              i += 4; // move up four positions
              break;

            case 'C':
              // Cubic Bezier contains two control points and two points. We don't care about
              // the control points, so only add the points.
              this.addPoint([
                parseFloat(parts[i + 1]),
                parseFloat(parts[i + 2])
              ]);
              this.addPoint([
                parseFloat(parts[i + 7]),
                parseFloat(parts[i + 8])
              ]);
              i += 8; // move up 8 positions
              break;
          }
        }
      }
    } else if (el.tagName == 'circle') {
      // setup brush config
      this.brushConfig = {
        size: parseFloat(el.getAttribute('r')) * 2,
        softness: parseFloat(el.getAttribute('brush-softness')),
        color: el.getAttribute('fill'),
        opacity: parseFloat(el.getAttribute('brush-opacity')),
        composite: el.getAttribute('brush-composite'),
        step: parseFloat(el.getAttribute('brush-step')),
        scale: [
          parseFloat(el.getAttribute('brush-sx')),
          parseFloat(el.getAttribute('brush-sy'))
        ],
        center: [
          parseFloat(el.getAttribute('brush-cx')),
          parseFloat(el.getAttribute('brush-cy'))
        ],
        rotate: parseFloat(el.getAttribute('brush-rotate'))
      };

      this.addPoint([
        parseFloat(el.getAttribute('cx')),
        parseFloat(el.getAttribute('cy'))
      ]);
    }
  }
}
