import React from 'react';
import { Team } from '@cube3/common/model/schema/resources/team';
import { useTeamSuggestions } from '../hooks/useTeamSuggestions';
import TeamSugggestionItemRowUI from '@cube3/ui/src/Teams/TeamSuggestionRowUI';

interface TeamRowProps {
  team: Team;
  maxCount?: number;
  style?: React.CSSProperties;
}

export const TeamSugggestionItemRow = (props: TeamRowProps) => {
  const { team, ...restProps } = props;

  const { members, loading } = useTeamSuggestions(team?.id);

  return (
    <TeamSugggestionItemRowUI
      team={team}
      members={members}
      loading={loading}
      {...restProps}
    />
  );
};
