import React, { MouseEvent } from 'react';
import { Typography } from '../../typography/Typography';
import { makeStyles, Theme } from '@material-ui/core';
import { StatusBlip, StatusColor } from '../../StatusBlip';
import { useClassName } from '../../utils/useClassName';
import { Chip } from '../../chips/Chip';
import { Time } from '../../human';
import InteractableSurface from '../../Surface/InteractableSurface';
import { iconProjectNew } from '../../icons/GeneralSVG';
import { getStatusTooltip } from '../../StatusBlip/getStatusColor';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

interface Attachment {
  name: string;
  type: string;
  onClick(ev: MouseEvent): void;
}

interface Props {
  statusColor?: StatusColor;
  name: string;
  type: string;
  attachments?: Attachment[];
  selected?: boolean;
  onDoubleClick?(): void;
  onClick?(): void;
  showDate?: boolean;
  showAttachments?: boolean;
  showBorder?: boolean;
  startsDate?: string;
  expiresDate?: string;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    marginTop: 8,
    marginBottom: 8,
    maxWidth: 960
  },
  inner: {
    padding: theme.sizing[16]
  },
  selected: {
    background: theme.customPalette.surfaceAccent.onBase.selected1
  },
  interactive: {
    // opacity: 0.8,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
      // background: theme.customPalette.backgroundAccent.whiteAccent1
    },
    '&:active': {
      background: theme.customPalette.surfaceAccent.onBase.selected2
    }
  },
  chips: {
    marginTop: 8,
    minHeight: 32
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 10
    }
  },
  meta: {}
}));

export const ContractTileUI = React.memo<Props>((props) => {
  const {
    statusColor,
    name,
    type,
    attachments,
    onClick,
    onDoubleClick,
    selected,
    showDate,
    showAttachments,
    showBorder,
    startsDate,
    expiresDate
  } = props;
  const classes = useStyles(props);

  let dateLabel;
  let date;
  if (startsDate || expiresDate) {
    if (startsDate && new Date(startsDate) > new Date()) {
      date = startsDate;
      dateLabel = 'Starting at';
    } else if (expiresDate && new Date(expiresDate) < new Date()) {
      date = expiresDate;
      dateLabel = 'Expired on';
    } else {
      date = expiresDate;
      dateLabel = 'Valid until';
    }
  }

  return (
    <div
      className={useClassName(
        classes.root,
        selected && classes.selected,
        (onClick || onDoubleClick) && classes.interactive
      )}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <InteractableSurface
        highlightOnHover={!!(onClick || onDoubleClick)}
        surfaceProps={{
          corners: showBorder ? 'rounded' : undefined,
          cornerRadius: showBorder ? 'mediumEmphasis' : undefined,
          background: 'transparent',
          border: showBorder
        }}
      >
        <div className={classes.inner}>
          <ContractTitle statusColor={statusColor} name={name} />
          <div className={classes.meta}>
            <Typography
              typographyStyle="body2"
              color="contrast2"
              display="inline"
            >
              {type || '...'}
            </Typography>
            {showDate && date && (
              <>
                <Typography
                  typographyStyle="body2"
                  color="contrast2"
                  display="inline"
                >
                  {' '}
                  •{' '}
                </Typography>
                <Typography
                  typographyStyle="body2"
                  color="contrast2"
                  display="inline"
                >
                  {dateLabel}{' '}
                  <Time
                    date={new Date(date)}
                    noTime={true}
                    toolTip={false}
                    placement="top"
                  />
                </Typography>
              </>
            )}
          </div>

          {showAttachments && (
            <div className={classes.chips}>
              {/* <pre>{JSON.stringify(attachments, null, 2)}</pre> */}
              {!attachments && (
                <Shimmer
                  width={160}
                  height={36}
                  margin="medium"
                  variant="rectangle"
                  weight="light"
                />
              )}
              {attachments?.length > 0 &&
                attachments.map((a) => {
                  return (
                    <Chip
                      key={a.name}
                      text={a.name}
                      onClick={a.onClick}
                      icon={a.type === 'project' ? iconProjectNew : undefined}
                      highlight={a.type === 'project'}
                      extraSVGProps={{ svgProps: { viewBox: '2 -2 24 24' } }}
                    />
                  );
                })}
              {attachments && attachments.length === 0 && (
                <Typography color="contrast3" typographyStyle="body2">
                  Not attached to assets or projects
                </Typography>
              )}
            </div>
          )}
        </div>
      </InteractableSurface>
    </div>
  );
});

export const ContractTitle = (props) => {
  const { statusColor, name } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.header}>
      <StatusBlip color={statusColor} tooltip={getStatusTooltip(statusColor)} />
      <Typography typographyStyle="heading2">{name}</Typography>
    </div>
  );
};
