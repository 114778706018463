import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import UserAvatar from '../User/UserAvatar/UserAvatar';
import UserName from '../User/UserName/UserName';
import { UserInfo } from './UserTypes';
// import { Typography } from '../typography/Typography';

/// <Summary>
/// Combines username and user avatar and displays them in a row
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flex: 'auto'
    },
    avatarContainer: {
      margin: `0px ${theme.customSpacing.margin[16]}`
      //   width: '28px',
      //   height: '28px'
    },
    userNameContainer: {}
  })
);

interface Properties {
  userInfo: UserInfo;
  // role: string;
}

const UserRow: React.FC<Properties> = props => {
  const { userInfo } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <UserAvatar
        classes={{ root: classes.avatarContainer }}
        userInfo={userInfo}
        size="medium"
      />
      <UserName
        classes={{ root: classes.userNameContainer }}
        userInfo={userInfo}
      />
      {/* <Typography color={'contrast3'} typographyStyle="body2">
          {role}
        </Typography> */}
    </div>
  );
};

export default React.memo(UserRow);
