import React from 'react';
import { Typography } from '../../typography/Typography';
import { Time } from '../../human';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useClassName } from '../../utils/useClassName';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 32,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
      marginTop: 24
    },
    slim: {
      marginLeft: 16
    },
    regular: {}
  })
);

interface Props {
  date?: string;
  variant?: 'slim' | 'regular';
  prefix?: string;
}

export const ContractListDateLabel: React.FunctionComponent<Props> = props => {
  const { date, variant = 'regular', prefix } = props;

  const classes = useStyles(props);

  return (
    <div className={useClassName(classes.root, classes[variant])}>
      <Typography color="contrast2">
        {prefix && `${prefix} `}
        {date && (
          <Time
            date={new Date(date)}
            fullForm={true}
            noTime={true}
            toolTip={false}
          />
        )}
        {!date && 'Never expires'}
      </Typography>
    </div>
  );
};
