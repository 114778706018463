import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import GeneralSVG, { iconOpenExternal } from '../../../../icons/GeneralSVG';
import { LinkIcon } from '../../../../icons/Link';
import { Typography } from '../../../../typography/Typography';
import { Time } from '../../../../human/Time';
import Button from '../../../../Buttons/Button';
import { iconDuplicate } from '@cube3/cubicle/src/icons/GeneralSVG';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      height: theme.sizing[32],
      minWidth: theme.sizing[32],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.customSpacing.margin[16],
      borderRadius: '50%',
      backgroundColor: theme.customPalette.surfaceAccent.onBase.shade4
    },
    createdText: {
      marginBottom: theme.customSpacing.margin[4]
    },
    restContainer: {
      width: '100%'
    },
    shareIcon: {
      color: theme.customPalette.icon.onBase.contrast2
    }
  })
);

interface Props {
  showLink?: boolean;
  createdDate: string;
  onClickOpenLink: () => void;
  onCopyLink?: () => void;
}

export const LinkSettingsCreatedDate = (props: Props) => {
  const { createdDate, onClickOpenLink, onCopyLink, showLink = true } = props;

  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.iconContainer}>
        <GeneralSVG
          path=""
          className={classes.shareIcon}
          svgProps={{ component: LinkIcon, viewBox: '0px 0px 32px 32px' }}
        />
      </div>
      <div className={classes.restContainer}>
        <Typography
          component="span"
          color="contrast2"
          typographyStyle={'body2'}
          className={classes.createdText}
        >
          Link created on{' '}
          <Time
            date={createdDate}
            fullForm={false}
            toolTip={true}
            placement="bottom"
          />
        </Typography>
        {showLink ? (
          onClickOpenLink ? (
            <>
              <Button
                path={iconOpenExternal}
                onClick={onClickOpenLink}
                text="Open your personal link"
                colorVariant={'ghost1'}
                marginCollapse={'left'}
              />
              <Button
                size="small"
                path={iconDuplicate}
                onClick={onCopyLink}
                text="Copy link"
                colorVariant={'ghost1'}
              />
            </>
          ) : (
            <Typography color="contrast3">
              Personal link not available
            </Typography>
          )
        ) : null}
      </div>
    </React.Fragment>
  );
};
