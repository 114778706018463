import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { Share } from '@cube3/common/model/schema/resources/share';
import { UseResourceResponse, useResource__ALPHA } from './useResource';
import {
  urlStructureFileRequest,
  urlStructureReview
} from '../../../../../main/src/components/app/routing/routingPaths';

export function useCurrentShareLink<T extends boolean = true>(
  resourceOnly: T = true as T
): T extends true ? Share : UseResourceResponse<Share> {
  const onShareRoute = matchPath(
    useLocation().pathname,
    '/share-link/:shareId'
  );
  const onReviewRoute = matchPath(useLocation().pathname, urlStructureReview);
  const onFileRequestRroute = matchPath(
    useLocation().pathname,
    urlStructureFileRequest
  );

  const first = useRef(true);

  if (
    !onShareRoute &&
    !onReviewRoute &&
    !onFileRequestRroute &&
    first.current
  ) {
    console.warn(
      'called useCurrentShareLink on a non-sharelink or a non-reviewlink route'
    );
    first.current = false;
  }

  const shareId = useSelector<any>((state) => state.session?.sharelink);
  const shareLink = useResource__ALPHA({
    resourceType: 'share',
    resourceId:
      (onShareRoute || onReviewRoute || onFileRequestRroute) && shareId
        ? String(shareId)
        : undefined
  });

  return resourceOnly
    ? (shareLink.first as T extends true ? Share : UseResourceResponse<Share>)
    : (shareLink as T extends true ? Share : UseResourceResponse<Share>);
}

export const withCurrentShareLink = (resourceOnly) => (Wrapped) => (props) => {
  const currentShareLink = useCurrentShareLink(resourceOnly);

  return <Wrapped currentShareLink={currentShareLink} {...props} />;
};
