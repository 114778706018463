import React from 'react';

import {
  createStyles,
  WithStyles,
  withStyles,
  Theme,
  makeStyles
} from '@material-ui/core';

import Image from '../../images/Image';
import fileImage from '../../assets/images/Library/file.svg?url';
import { Typography } from '../../typography/Typography';
import Surface from '../../Surface/Surface';
import Thumbnail from '../../thumbnails/Thumbnail';
import DetailviewFullscreenControls from '../DetailviewFullscreenControls';
import { iconMinimizeVector, iconMaximizeVector } from '../../icons/GeneralSVG';
import CustomIconButton from '../../Buttons/IconButton';
// Fallback component for obscure filetypes in detailview
import { PreviewHOCProps } from '../PreviewWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      backgroundColor: 'unset'
    },
    centered: {
      display: 'flex',
      margin: 'auto',
      flexFlow: 'column nowrap',
      alignItems: 'center'
    },
    feedbackTxt: {
      marginTop: '20px',
      margin: 'auto'
    },
    fullscreenButton: {
      position: 'absolute',
      bottom: 20,
      right: 24
    }
  })
);

interface ComponentProps extends PreviewHOCProps {
  id: string;
  asset_variant: string;
  name: string;
  display_name: string;
  src: string;
  siblings: boolean;
  gotoNextSibling?: () => void;
  gotoPrevSibling?: () => void;
  thumbnail?: string;
  status?: string;
  loading?: boolean;
}

const getMessage = (status) => {
  switch (status) {
    case 'composing':
    case 'processing':
      return 'No preview available yet.';

    default:
      return 'No preview available.';
  }
};
export const FallbackPreview = (props: ComponentProps) => {
  const {
    thumbnail,
    fullscreen,
    toggleFullscreenAction,
    display_name,
    gotoNextSibling,
    gotoPrevSibling,
    siblings,
    loading,
    status
  } = props;

  const message = getMessage(status);

  const classes = useStyles();

  return (
    <Surface className={classes.container}>
      <div className={classes.centered}>
        {!loading && thumbnail ? (
          <Thumbnail
            src={thumbnail}
            cover={true}
            ratio="sixteenToNine"
            fillVariant="main"
            showCheckerboard={true}
          />
        ) : (
          <Image imagePath={fileImage} />
        )}
        {!loading && (
          <Typography className={classes.feedbackTxt}>{message}</Typography>
        )}
        {fullscreen && (
          <CustomIconButton
            colorVariant="ghost3"
            size={'regular'}
            path={fullscreen ? iconMinimizeVector : iconMaximizeVector}
            svgProperties={{
              svgProps: { viewBox: '0 0 32 32' }
            }}
            onClick={() => toggleFullscreenAction()}
            className={classes.fullscreenButton}
          />
        )}
        {fullscreen && (
          <DetailviewFullscreenControls
            gotoNextSibling={gotoNextSibling}
            gotoPrevSibling={gotoPrevSibling}
            assetTitle={display_name}
            siblings={siblings}
          />
        )}
      </div>
    </Surface>
  );
};
