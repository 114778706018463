import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import ModalHeader from './ModalHeader';
import { ModalWithHeaderProperties } from './types';
import { Modal } from '@material-ui/core';
import Surface from '../Surface/Surface';
import { ModalCookieContext } from './ModalCookieContext';
import ModalMbileSurfaceUI from './ModalMobileSurfaceUI';

import { CircularProgress } from '../Progress/CircularProgress';
import { useModalContainer } from './ModalManagerUI';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    root_cookiebanner: {
      height: 'calc(100vh - 174px)'
    },
    centered: {
      outline: 'none',
      margin: 'auto'
    },
    surface: {
      display: 'flex',
      flexDirection: 'column',
      // Width calculation : 100vw - 32px left - 32px right.
      width: 'calc(100vw - 64px)'
    },
    contents: {
      // we have to set the height on the element that has the scrollbar, otherwise it won't function properly.
      // Height calculation: 100vh - 24px top - 24px bottom - 40px headerHeight - 48px margin - 26px scrollbar.
      height: 'calc(100vh - 152px)'
      // do not set margin here so we can set proper footer.
    },
    cookieBannerContents: {
      height: 'calc(100vh - 152px - 174px)'
    },
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      width: 'auto',
      background: theme.customPalette.backgroundAccent.blackAccent3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    // The default value for margins is noMargins
    // provide a string property defineMargins to set other class for margins
    allMargins: {
      margin: '24px 32px',
    },
    noMargins: {
      margin: 0
    },
    sideMargins: {
      margin: '0 32px'
    },
  }));

interface Properties
  extends ModalWithHeaderProperties {}

const ModalWindow: React.FC<React.PropsWithChildren<Properties>> = props => {
  const {
    title,
    onCloseEvent = undefined,
    // should be true by default in window.
    showCloseButton = false,
    extraComponentProps,
    extraModalProps,
    loading = false,
    component: Component = 'div',
    extraSurfaceProperties,
    topBarComponent,
    defineMargins = "noMargins",
  } = props;

  const classes = useStyles();

  // use context provider to check if we should resize the modal if the cookie banner is active.
  const cookiebannerContext = useContext(ModalCookieContext);

  const container = useModalContainer();

  if (!container) {
    return null;
  }

  return (
    <Modal
      container={container}
      open={true}
      className={`${classes.root} ${
        cookiebannerContext.hasConsented === false
          ? classes.root_cookiebanner
          : ''
      }`}
      //  container={containerContext.ref}
      onClose={onCloseEvent ? () => onCloseEvent() : undefined}
      {...extraModalProps}
    >
      <Component
        className={classes.centered}
        data-type={'librarydeselectoverride'}
        {...extraComponentProps}
      >
        {isMobile ? (
          <ModalMbileSurfaceUI
            extraSurfaceProperties={extraSurfaceProperties}
            onCloseEvent={onCloseEvent}
            showCloseButton={showCloseButton}
            loading={loading}
          >
            {props.children}
          </ModalMbileSurfaceUI>
        ) : (
          <Surface
            className={classes.surface}
            background={'base2'}
            corners={'rounded'}
            floating={true}
            gradient={'lamp'}
            floatingHeight={24}
            {...extraSurfaceProperties} // override surface properties from parent.
          >
            <ModalHeader
              onClickClose={onCloseEvent ? () => onCloseEvent() : undefined}
              showCloseButton={showCloseButton}
              title={title}
              topBarComponent={topBarComponent}
            />

            <div
              className={
                cookiebannerContext.hasConsented === false
                  ? classes.cookieBannerContents
                  : `${classes.contents} ${classes[defineMargins]}`
              }
            >
              {props.children}
            </div>

            {/* Show circular progress, blocking interaction when in loading state */}
            {loading && (
              <div className={classes.loadingOverlay}>
                <CircularProgress />
              </div>
            )}
          </Surface>
        )}
      </Component>
    </Modal>
  );
};

/**
 * @summary UI for Modal Window. Window has a fixed width and height, and no footer, therefore, the children do not have a standard scrollbar for its children.
 * @author Simon, Michiel, Machiel, Lorenzo
 */
export const ModalWindowUI = ModalWindow;
