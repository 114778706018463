import * as React from 'react';

// helpers

// import ui
import { useModalActions } from '../../../layout/Modals/modalActions';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { ShareIntentType } from '@cube3/state/types';

interface Properties {
  modalContext: {
    /** share id */
    id: string;
    intent: ShareIntentType;
    email_address: string;
    lastRecipient?: boolean;
    /** use to invalidate cache */
    node: string;
  };
}

const ConfirmationRemoveAccess: React.FunctionComponent<Properties> = (
  props
) => {
  const {
    id,
    intent,
    email_address,
    lastRecipient = false,
    node
  } = props.modalContext;

  const { first: retrievedResource, loading } = useResource__ALPHA({
    resourceId: id,
    resourceType: 'share'
  });
  const [workspaceId] = useCurrentWorkspace();
  const config = React.useMemo(
    () => ({
      cacheInvalidator: (r) => [
        r,
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'shares'
        },
        {
          type: 'folder',
          id: node,
          relationship: 'shares'
        },
        {
          type: 'asset',
          id: node,
          relationship: 'shares'
        },
        {}
      ]
    }),
    [workspaceId, node]
  );
  const [mutateResource, mutateStatus] = useMutateResource__ALPHA(config);

  const { previousModal, closeAllModals } = useModalActions();

  const removeAccess = React.useCallback(() => {
    const filteredEmailArray = retrievedResource.email_addresses.filter(
      (email) => email !== email_address
    );
    mutateResource({
      id,
      type: 'share',
      email_addresses: filteredEmailArray.length > 0 ? filteredEmailArray : null
    });
  }, [retrievedResource, mutateResource]);

  React.useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      if (lastRecipient) {
        closeAllModals();
      } else {
        previousModal();
      }
    }
  }, [mutateStatus, lastRecipient, closeAllModals, previousModal]);

  return (
    <ModalNoticeUI
      title={'Remove access?'}
      loading={loading || mutateStatus === statuses.IN_FLIGHT}
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={previousModal}
          />
          <Button
            text={'Remove'}
            colorVariant="filledDanger"
            onClick={removeAccess}
          />
        </>
      }
    >
      {lastRecipient ? (
        <Typography>
          You are the last recipient, this link will no longer work for anyone.
        </Typography>
      ) : (
        <Typography>This link will no longer work for them.</Typography>
      )}
    </ModalNoticeUI>
  );
};

export default ConfirmationRemoveAccess;
