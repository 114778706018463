import React, { useCallback, useEffect } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { Tag } from '@cube3/common/model/schema/resources/tag';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useTagActions } from '../hooks/useTagActions';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';

interface Props {
  modalContext: Tag;
}
const page = {
  size: 1,
  number: 1
};
export const DeleteTagModal = (props: Props) => {
  const tag = props.modalContext;
  const { previousModal } = useModalActions();

  const { onDeleteTag, deleteResourceStatus } = useTagActions();

  const [workspaceId] = useCurrentWorkspace();
  const { pageCounts, loading: appliedNodesLoading } = useResourceList__ALPHA({
    resourceType: 'tag',
    resourceId: tag?.id,
    edgeType: 'content-tree-node',
    edgeLabel: 'nodes',
    params: {
      filter: {
        workspace: workspaceId
      },
      page
    }
  });

  const onDelete = useCallback(() => {
    onDeleteTag(tag?.id);
  }, [onDeleteTag, tag]);

  useEffect(() => {
    if (deleteResourceStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [deleteResourceStatus, previousModal]);

  const loading =
    appliedNodesLoading ||
    (deleteResourceStatus && !hasResolved(deleteResourceStatus));

  const count = pageCounts.itemCount;

  useGlobalSubmit(onDelete, !!deleteResourceStatus || loading);

  return (
    <ModalNoticeUI
      title={'Delete Tag'}
      onCloseEvent={previousModal}
      loading={loading}
      footerRightComponent={
        <>
          <Button
            label="Cancel"
            onClick={previousModal}
            background="secondary"
            buttonStyle="ghost"
          />
          <Button
            label="Delete"
            disabled={!!deleteResourceStatus}
            background="critical"
            buttonStyle="solid"
            onClick={onDelete}
          />
        </>
      }
    >
      <Typography color="dark" colorVariant="02">{`Tag is used on ${count} ${
        count !== 1 ? 'assets' : 'asset'
      }, are you sure you want to delete Tag?`}</Typography>
    </ModalNoticeUI>
  );
};
