import React from 'react';
import { isMobile } from 'react-device-detect';

// UI
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import ScrollBar from '../../Scrollbar/Scrollbar';
import { Divider } from '../../Surface/Divider';
import { CircularProgress } from '../../Progress/CircularProgress';

/// @Author: Simon @AmbassadorsLab (original component made by michiel)
/// <Summary>
/// Template layout component for detailview.
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    },
    commandBar: {
      display: 'flex',
      paddingLeft: 8,
      paddingRight: 8,
      alignItems: 'center',
      boxSizing: 'border-box',
      width: '100%',
      height: '56px'
    },
    contents: {
      display: 'flex',
      height: '100%',
      margin: ({ disableContentMargin }: Props) =>
        disableContentMargin ? 0 : 40,
      '$mobile &': {
        margin: theme.customSpacing.margin[16]
      }
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    },
    noMetadata: {
      margin: 40
    }
  })
);

interface Props {
  loading: boolean;
  renderPropsContents: () => JSX.Element;
  renderPropsCommandBar?: () => JSX.Element;
  disableContentMargin?: boolean;
}

const DetailViewTabTemplate: React.ComponentType<Props> = props => {
  const { renderPropsContents, renderPropsCommandBar, loading } = props;

  const classes = useStyles(props);

  return (
    <div className={`${classes.root} ${isMobile ? classes.mobile : ''}`}>
      {renderPropsCommandBar && (
        <>
          <div className={classes.commandBar}>{renderPropsCommandBar()}</div>
          <Divider />
        </>
      )}
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.contents}>
          <ScrollBar>{renderPropsContents()}</ScrollBar>
        </div>
      )}
    </div>
  );
};

export default DetailViewTabTemplate;
