/**
 *  get file extension by trying to split the filename on the last dot
 */
export function getExtensionFromString(filename: string): string {
  let result = '';

  if (filename !== undefined && filename.includes('.')) {
    const split: string[] = filename.split('.');

    // get the last piece out of the split
    result = split[split.length - 1];
  }

  return result;
}

export const removeExtensionFromString = (filename: string) => {
  const extension = getExtensionFromString(filename);
  return filename.replace( new RegExp(`\\.${extension}$`), '');
};
