import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useProjectMembershipsList } from '@cube3/state/src/redux/components/Hooks/useProjectMembershipsList';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { ProjectRoleSelectComponent } from '@cube3/ui/src/Layout/AccountSettings/SubComponents/ProjectRoleSelectComponent';
// UI
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React, { useCallback, useEffect } from 'react';
import { useAddMemberToAllProjects } from '../../../../AccountPages/hooks/useAddMemberToAllProjects';
import { useAddExistingToAllProjects } from './hooks/useAddExistingToAllProjects';
import { projectRoles } from '@cube3/state/src/redux/components/Hooks/useProjectMembershipsList';

interface ModalProps {
  modalContext: {
    userName: string;
    targetProjects: string[];
    workspaceId: string;
    accountId: string;
  };
}

type ProjectRoleName = 'Member' | 'Admin';

const WorkspaceMemberAddToProjectsModal: React.FunctionComponent<ModalProps> = props => {
  const { modalContext } = props;
  const { workspaceId, accountId, userName } = modalContext;

  const [currentAccountId] = useCurrentAccount();

  const {
    resources: targetProjects,
    loading: targetProjectsLoading,
    count: targetProjectsCounts,
    status: targetProjectsStatus,
    tooManyProjects
  } = useProjectMembershipsList({
    accountId: currentAccountId,
    projectRole: 'Admin',
    projectStatus: 'All',
    countOnly: false
  });

  const [projectRole, setProjectRole] = React.useState<ProjectRoleName>(
    'Member'
  );

  const { previousModal, openModal } = useModalActions();
  const [createResource, createResourceStatus] = useCreateResource__ALPHA({
    resourceType: 'invitation',
    cacheInvalidator: useCallback(
      () => [
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'invitations'
        }
      ],
      [workspaceId]
    )
  });

  const workspaceRoleId = useResource__ALPHA({
    resourceType: 'account',
    resourceId: accountId
  }).first?.relationships.role.id;

  // create new invitation
  const handleAddToProjects = useCallback(() => {
    const newRole = projectRoles.filter(r => r.display_name === projectRole)[0]
      .value;
    createResource({
      type: 'invitation',
      add_to_projects: true,
      add_to_projects_role: newRole,
      relationships: {
        workspace: {
          data: { type: 'workspace', id: workspaceId }
        },
        account: {
          data: { type: 'account', id: accountId }
        }
      }
    });
  }, [createResource, workspaceId, projectRole, accountId]);

  const { canWriteProject, availableProjectRoles } = useAddMemberToAllProjects(
    workspaceRoleId
  );

  const {
    resources: projectMembershipsMember,
    loading: projectMembershipsMemberLoading,
    tooManyProjects: projectMembershipsMemberTooMany
  } = useProjectMembershipsList({
    accountId,
    projectRole: 'Member',
    projectStatus: 'All'
  });

  const {
    resources: projectMembershipsAdmin,
    loading: projectMembershipsAdminLoading,
    tooManyProjects: projectMembershipsAdminTooMany
  } = useProjectMembershipsList({
    accountId,
    projectRole: 'Admin',
    projectStatus: 'All'
  });

  const loading =
    !targetProjectsStatus ||
    targetProjectsLoading ||
    projectMembershipsMemberLoading ||
    projectMembershipsAdminLoading;

  const noCompare =
    tooManyProjects ||
    projectMembershipsMemberTooMany ||
    projectMembershipsAdminTooMany;

  const {
    confirmedMessage,
    disableAdd,
    affectedMessage
  } = useAddExistingToAllProjects({
    loading,
    targetProjects: targetProjects,
    noCompare,
    projectMembershipsAdmin,
    projectMembershipsMember,
    userName,
    totalProjects: targetProjectsCounts,
    projectRole
  });

  useEffect(() => {
    if (createResourceStatus === statuses.SUCCESS) {
      openModal('workspaceMembers/addedToProjectsConfirmation', {
        mainText: confirmedMessage
      });
    }
  }, [createResourceStatus, openModal, confirmedMessage]);

  return (
    <ModalMenuUI
      title="Add to all projects"
      loading={
        loading ||
        (createResourceStatus && createResourceStatus !== statuses.SUCCESS)
      }
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button text="Cancel" onClick={previousModal} colorVariant="ghost2" />
          <Button
            text="Add"
            disabled={disableAdd}
            colorVariant="filled1"
            onClick={handleAddToProjects}
          />
        </>
      }
    >
      <Typography>
        Add <strong>{userName}</strong> to all projects in which you are an
        admin.
      </Typography>

      <ProjectRoleSelectComponent
        selectedRole={canWriteProject ? projectRole : 'Member'}
        onChange={role => setProjectRole(role)}
        roles={availableProjectRoles}
      />
      <div style={{ height: 16, width: '100%' }}></div>
      <Typography color="contrast2">{affectedMessage}</Typography>
    </ModalMenuUI>
  );
};

export default WorkspaceMemberAddToProjectsModal;
