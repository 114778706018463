import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Typography } from '../../typography/Typography';
import {
  generateDownloadWarning,
  parseDownloadWarning
} from './downloadErrors';
import { CircularProgress } from '../../Progress/CircularProgress';

interface Props {
  allAssetsCount: number;
  loading?: boolean;
  hasFolders?: boolean;
  /** un-acceptable errors */
  errors: {
    codes: string[];
    assets: { display_name: string; [key: string]: any }[];
    assetsCount: number;
  };
  /** acceptable errors */
  warnings?: {
    codes: string[];
    /** list limited amount of assets(5) here */
    assets: { display_name: string; [key: string]: any }[];
    assetsCount: number;
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 4,
      marginBottom: 8
    },
    list: {
      paddingLeft: 16,
      margin: 0
    },
    padding: {
      paddingBottom: 8
    }
  })
);

export const DownloadWarningMessage = (props: Props) => {
  const { errors, warnings, ...sameProps } = props;

  return (
    <React.Fragment>
      <DownloadWarningRenderer
        {...sameProps}
        errorCodes={errors.codes}
        notAvailableAssets={errors.assets}
        notAvailableAssetsCount={errors.assetsCount}
      />
      {warnings.codes?.length ? (
        <DownloadWarningRenderer
          {...sameProps}
          errorCodes={warnings.codes}
          notAvailableAssets={warnings.assets}
          notAvailableAssetsCount={warnings.assetsCount}
        />
      ) : null}
    </React.Fragment>
  );
};
interface WarningRendererProps {
  allAssetsCount: number;
  loading?: boolean;
  hasFolders?: boolean;
  errorCodes: string[];
  notAvailableAssets: { display_name: string; [key: string]: any }[];
  notAvailableAssetsCount: number;
}
export const DownloadWarningRenderer = (props: WarningRendererProps) => {
  const {
    hasFolders,
    loading,
    allAssetsCount,
    errorCodes,
    notAvailableAssets,
    notAvailableAssetsCount
  } = props;
  const classes = useStyles();

  const subKey = hasFolders
    ? 'some'
    : notAvailableAssetsCount === allAssetsCount
    ? notAvailableAssetsCount === 1
      ? 'one'
      : 'all'
    : 'some';

  const warning = generateDownloadWarning(
    errorCodes,
    subKey,
    notAvailableAssetsCount
  );
  const more = Math.max(0, notAvailableAssetsCount - 5);

  return (
    <div className={classes.root}>
      {typeof warning === 'string' ? (
        <Typography>{warning}</Typography>
      ) : (
        <>
          <Typography className={classes.padding}>{warning?.title}</Typography>
          {warning?.reasons?.length ? (
            <ul className={`${classes.list} ${classes.padding}`}>
              {warning.reasons.map((r) => (
                <li key={r}>
                  <Typography>{r}</Typography>
                </li>
              ))}
            </ul>
          ) : null}
          {loading ? (
            <CircularProgress size="1em" />
          ) : notAvailableAssets?.length > 1 ||
            (notAvailableAssets?.length === 1 && subKey === 'some') ? (
            <>
              <Typography>
                {parseDownloadWarning(
                  notAvailableAssets.length,
                  'This applies to the following ASSET:'
                )}
              </Typography>
              <ul className={classes.list}>
                {notAvailableAssets.map((asset, idx) =>
                  asset ? (
                    <li key={asset.display_name + idx}>
                      <Typography>{asset.display_name}</Typography>
                    </li>
                  ) : null
                )}
              </ul>
              {more > 0 ? (
                <Typography>
                  {parseDownloadWarning(more, 'and SELECTED_COUNT more ASSET')}
                </Typography>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
