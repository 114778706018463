import State from './state';

let rafID;
let timeout;

export function notify() {
  rafID = null;
  timeout = null;
  if (State.notify) {
    State.notify();
  }
}

export function rafUpdateBatcher() {
  if (rafID) return; // prevent multiple request animation frame callbacks
  // rafID = setTimeout(notify, 1000 / 60);
  rafID = requestAnimationFrame(notify);
}

export function timeoutUpdateBatcher() {
  if (timeout) return; // prevent multiple request animation frame callbacks
  // rafID = setTimeout(notify, 1000 / 60);
  timeout = setTimeout(notify, 1000 / 10);
}
