import React from 'react';

import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';

export const ChangeProfilePictureErrorNotice = props => {
  const { error } = props.modalContext;
  const { previousModal } = useModalActions();
  return (
    <ModalNoticeUI
      title="Failed to apply..."
      footerRightComponent={
        <Button
          text="Close"
          colorVariant="filledDanger"
          onClick={previousModal}
        />
      }
    >
      <Typography color="danger1">{error}</Typography>
    </ModalNoticeUI>
  );
};
