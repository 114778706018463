export const calculateScrollBarWidth = () => {
  const div1 = document.createElement('div');
  const div2 = document.createElement('div');

  div1.style.width = '100px';
  div2.style.height = '100px';
  div1.style.width = '50px';
  div1.style.height = '200px';

  document.body.appendChild(div1);
  div1.appendChild(div2);

  const w1 = div1.clientWidth;
  div1.style.overflowY = 'scroll';
  const w2 = div1.clientWidth;

  div1.removeChild(div2);
  document.body.removeChild(div1);

  return w1 - w2;
};
