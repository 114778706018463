import { FSA } from '../flux-standard-action';

const initialState = {};

const MAP_TEMP_ID = 'cube3/temp-id/MAP_TEMP_ID';
const DROP_TEMP_ID = 'cube3/temp-id/DROP_TEMP_ID';

const actions = {
  MAP_TEMP_ID,
  DROP_TEMP_ID
};

const getRealIdFromTempId = (state, tempId) => {
  return state[tempId];
};

const selectors = {
  getRealIdFromTempId
};

const mapTempIdToRealId = (tempId, realId) => ({
  type: MAP_TEMP_ID,
  payload: {
    tempId,
    realId
  }
});

const dropTempId = tempId => ({
  type: DROP_TEMP_ID,
  payload: {
    tempId
  }
});

const actionCreators = {
  mapTempIdToRealId,
  dropTempId
};

const reducer = (state = initialState, action: FSA) => {
  const { type, payload } = action;

  switch (type) {
    case MAP_TEMP_ID:
      return {
        ...state,
        [payload.tempId]: payload.realId
      };

    // eslint-disable-next-line no-duplicate-case
    case DROP_TEMP_ID:
      const newState = {
        ...state
      };
      if (newState[payload.tempId]) {
        delete newState[payload.tempId];
      }
      return newState;

    default:
      return state;
  }
};

export { reducer, actions, actionCreators, selectors };
export default reducer;
