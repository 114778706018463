import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useCurrentAccount } from '../../Administration/withCurrentUserAccount';
import { APIPrivileges, Privileges } from '../privileges';
import { useResource__ALPHA } from '../useResource';
import { useResourceList__ALPHA } from '../useResourceList';

const emptyArray = [];

/**
 *  A more optimized way of getting
 */
const permissionsContext = React.createContext<Privileges[]>(null);

export const usePrivilegesRoot = () => {
  const account = useCurrentAccount(true)?.[1];

  const role = useResource__ALPHA({
    resourceId: account?.relationships?.role?.id,
    resourceType: 'role'
  }).first;

  const privs = useResourceList__ALPHA({
    strategy: 'prefer-cache',
    resourceType: 'role',
    resourceId: role?.id,
    edgeType: 'privilege',
    edgeLabel: 'privileges'
  }).resources;

  const permissions = useMemo(() => {
    return privs?.map((p) => p.key) || emptyArray;
  }, [privs]);

  return permissions as APIPrivileges[];
};

export const WorkspacePermissionsProvider = (props) => {
  const { children, bypass } = props;

  const permissions = usePrivilegesRoot();

  return (
    <permissionsContext.Provider
      value={bypass ? null : permissions}
      children={children}
    />
  );
};

export const useWorkspacePermissions = (requestedPrivs: Privileges[]) => {
  const requestPrivilegesMemo = useRef(requestedPrivs);

  const availablePrivs = useContext(permissionsContext);

  useEffect(() => {
    if (
      requestedPrivs.length !== requestPrivilegesMemo.current.length ||
      requestedPrivs.find((p, idx) => requestPrivilegesMemo.current[idx] !== p)
    ) {
      requestPrivilegesMemo.current = requestedPrivs;
    }
  }, [requestedPrivs]);

  const memo = requestPrivilegesMemo.current;

  return useMemo(() => {
    if (!availablePrivs?.length) {
      return emptyArray;
    }
    return memo.map((r) => {
      // if roleId exist at this point, WORKSPACE_READ permision is assumed
      return r === 'WORKSPACE_READ'
        ? true
        : availablePrivs?.filter((a) => a === r).length > 0;
    });
  }, [availablePrivs, memo]);
};
