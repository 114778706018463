import {
  APIResourceListResponse,
  APIResourceResponse,
  legacyApiDataKeys
} from './api-interface';
import { isJSONApiResponse } from './interceptors/JSONApi-interceptors';
const { LEGACY_API_RESOURCE_LIST, LEGACY_API_RESOURCE } = legacyApiDataKeys;

const createListProcessor = (type, parser, endpoints) => {
  return (res) => {
    if (isJSONApiResponse(res)) {
      if (!endpoints[type].parse) {
        return res;
      }
      return parser ? parser(res) : res;
    } else {
      return Promise.resolve(res).then((res) => ({
        [LEGACY_API_RESOURCE_LIST]: (res.data ? [].concat(res.data) : [])
          .map((r) => ({ ...r, type: r.type ? r.type : type }))
          .map((r) => (parser ? parser(r) : r))
      })); // TODO: shouldn't need to do these checks
    }
  };
};

const createResourceProcessor = (type, parser, endpoints) => {
  return (res) => {
    if (isJSONApiResponse(res)) {
      if (!endpoints[type].parse) {
        return res;
      }
      return parser ? parser(res) : res;
    } else {
      return Promise.resolve(res)
        .then((res) => {
          return res;
        })
        .then((res) => (parser ? parser(res.data) : res.data))
        .then((res) => ({ ...res, type: res.type ? res.type : type }))
        .then((res) => ({ [LEGACY_API_RESOURCE]: res }));
    }
  };
};

export const createGenericMethods = (endpoints) => {
  const genericList =
    <T>(type, parser = undefined) =>
    (by, params, abortSignal): APIResourceListResponse<T> => {
      const process = createListProcessor(type, parser, endpoints);

      return by.type && by.id
        ? endpoints[type]
            .listBy(by.type, by.id, by.field, params, abortSignal)
            .then(process) // TODO: shouldn't need to do these checks
        : endpoints[type].list(by.field, params, abortSignal).then(process);
    };

  const genericGet =
    <T>(type, parser = undefined) =>
    (id, abortSignal): APIResourceResponse<T> =>
      endpoints[type]
        .get(id, undefined, abortSignal)
        .then(createResourceProcessor(type, parser, endpoints));

  const genericPut =
    <T>(type, parser = undefined) =>
    (id, body): APIResourceResponse<T> =>
      endpoints[type]
        .put(id, body)
        .then(createResourceProcessor(type, parser, endpoints));

  const genericPatch =
    <T>(type, parser = undefined) =>
    (id, body): APIResourceResponse<T> =>
      endpoints[type]
        .patch(id, body)
        .then(createResourceProcessor(type, parser, endpoints));

  const genericDelete =
    <T>(type, parser = undefined) =>
    (id): APIResourceResponse<T> =>
      endpoints[type].delete(id).then((res) => {
        return res && res.data ? res : { ...res, data: { type, id } };
      });

  const genericPost =
    <T>(type, parser = undefined) =>
    (body, by, options): APIResourceResponse<T> => {
      return by.type && by.id
        ? endpoints[type]
            .postBy(body, by.id, by.type, by.field)
            .then(createResourceProcessor(type, parser, endpoints))
        : endpoints[type]
            .post(body)
            .then(createResourceProcessor(type, parser, endpoints));
    };

  const genericAdd =
    <T>(type) =>
    (edgeResource, by, forceJSONApi): APIResourceResponse<T> => {
      return endpoints[type].addRelated(
        edgeResource,
        by.id,
        by.type,
        by.field,
        forceJSONApi
      );
    };

  const genericRemove =
    <T>(type) =>
    (edgeResource, by, forceJSONApi): APIResourceResponse<T> => {
      return endpoints[type].removeRelated(
        edgeResource,
        by.id,
        by.type,
        by.field,
        forceJSONApi
      );
    };

  /** update relationships */
  const genericReplace =
    <T>(type, parser = undefined) =>
    (id, body, endpointOverride): APIResourceResponse<T> =>
      endpoints[type]
        .replaceRelated(id, body, endpointOverride)
        .then(createResourceProcessor(type, parser, endpoints));

  return {
    genericList,
    genericGet,
    genericPost,
    genericPut,
    genericPatch,
    genericDelete,
    genericAdd,
    genericRemove,
    genericReplace
  };
};
