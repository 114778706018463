import {
  Project,
  ProjectIconType,
  projectIcons
} from '@cube3/common/model/schema/resources/project';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback, useEffect, useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';

const config = { cacheInvalidator: (r) => [r] };

export const useSetProjectIcon = (project: Project) => {
  const { closeAllModals } = useModalActions();

  const [icon, setIcon] = useState<ProjectIconType>(
    projectIcons[project?.icon] || 'Project'
  );

  const [muateProject, mutateProjectStatus] = useMutateResource__ALPHA(config);

  const onSave = useCallback(() => {
    muateProject({
      type: 'project',
      id: project?.id,
      icon: projectIcons.findIndex((i) => i === icon)
    });
  }, [muateProject, project, icon]);

  useEffect(() => {
    if (mutateProjectStatus === statuses.SUCCESS) {
      closeAllModals();
    }
  }, [mutateProjectStatus, closeAllModals]);

  const dirty = icon !== projectIcons[project?.icon];

  return {
    icon,
    setIcon,
    onSave,
    dirty
  };
};
