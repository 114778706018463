import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors, Typography } from '../../../typography/Typography';
import { CircularProgress } from '../../../Progress/CircularProgress';
import { StatusBlip } from '../../../StatusBlip/StatusBlip';
import { iconCheck, iconError } from '../../../icons/GeneralSVG';
import { MediaQueueTileStatus } from '../MediaQueueTile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', padding: 8 * 2 },
    thumb: { flexGrow: 0, flexShrink: 0, width: 8 * 8, marginRight: 8 * 2 },
    content: { flexGrow: 1, flexShrink: 0 },
    faded: { opacity: 0.16 },
    status: {
      display: 'flex',
      justifyContent: 'flex-start',
      // alignItems: 'center',
      '& > *:first-child': {
        marginRight: 8
      }
    },
    label: {
      wordBreak: 'break-word'
    },
    statusSpinnerTypography: {
      lineHeight: 1
    },
    actionButtons: {
      position: 'absolute',
      right: 8,
      top: 8
    },
    highlight: {
      position: 'absolute',
      left: 16,
      bottom: 16,
      zIndex: 100
    }
  })
);

const MediaQueueTileStatusComponent = props => {
  const { status, statusProgress, statusLabel } = props;
  const classes = useStyles();

  return (
    <div className={classes.status}>
      {(status === 'active' || status === 'pending') && (
        <>
          <Typography
            typographyStyle="body2"
            color={getStatusColor(status)}
            className={classes.statusSpinnerTypography}
          >
            <CircularProgress
              size={20}
              color="inherit"
              variant={
                statusProgress !== undefined ? 'determinate' : 'indeterminate'
              }
              value={statusProgress}
            />
          </Typography>
          <Typography color={getStatusColor(status)}>{statusLabel}</Typography>
        </>
      )}
      {status === 'success' && (
        <>
          <StatusBlip
            size="medium"
            color="success"
            icon={iconCheck}
            iconViewBox={32}
          />
          <Typography color="success">{statusLabel}</Typography>
        </>
      )}
      {status === 'failed' && (
        <>
          <StatusBlip size="medium" color="danger" icon={iconError} />
          <Typography color="danger1" className={classes.label}>
            {statusLabel}
          </Typography>
        </>
      )}
    </div>
  );
};

const getStatusColor = (status: MediaQueueTileStatus): colors => {
  switch (status) {
    case 'pending':
    case 'active':
      return 'brand1';
    case 'success':
      return 'success';
    case 'failed':
      return 'danger1';
    default:
      return 'contrast2';
  }
};

export default MediaQueueTileStatusComponent;
