import { useMemo } from 'react';
import { customId } from './utils/customId';

type AvailableKeys = {
  [categoryId: string]: string[];
};

export const useAvailableKeys = ({ categories, type }): AvailableKeys => {
  return useMemo(() => {
    return categories?.reduce((acc, form) => {
      const catId = customId(form.id);
      const fields =
        form[`${type === 'metadata' ? 'metadata_fields' : 'keys'}`];
      return {
        ...acc,
        [catId]: fields.map(
          (f) => `${type === 'metadata' ? 'field' : 'key'}_${f.id}`
        )
      };
    }, {});
  }, [categories, type]);
};
