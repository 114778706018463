import React, { useState, useEffect } from 'react';

function UseDetectScroll() {
  let [scrolled, setScrolled] = useState(false);
  let timer;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setScroll(e) {
    setScrolled(true);
    clearTimeout(timer);
    timer = setTimeout(setScrolledToFalse, 150);
  }

  function setScrolledToFalse() {
    setScrolled(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', setScroll, true);
    return () => {
      window.removeEventListener('scroll', setScroll, true);
      clearTimeout(timer);
    };
    // eslint-disable-next-line:align
  }, [setScroll, timer]);
  return { scrolled, setScrolled };
}

export interface ScrollProperties {
  scrolled: boolean;
}

/** Wrapper to use hook `UseDetectScroll` as a decorator*/
export const WithScrollWrapper = <P extends {}>(
  WrappedComponent: React.ComponentType<P>
) => (props: P) => {
  let { scrolled } = UseDetectScroll();
  return <WrappedComponent {...props} scrolled={scrolled} />;
};

export default UseDetectScroll;
