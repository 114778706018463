import React, { useCallback, useMemo } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';

/* Import components */
import {
  WithScrollWrapper,
  ScrollProperties
} from '../../helpers/hooks/UseDetectScroll';
import UseHover from '../../helpers/hooks/UseHover';

import { ProgressIconButton } from './ProgressIconButton';

import { UploadUIStates } from './types';
import { ProgressPopup } from './ProgressPopup';
import { useClassName } from '../../utils/useClassName';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      display: 'block',
      width: '100%',
      height: 'auto',
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '56.25%',
      textAlign: 'center',
      '&.withBackgroundColor': {
        backgroundColor: theme.customPalette.primary.dark
      }
    },
    innerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%'
    },
    thumbnailContainer: {
      opacity: 0.4,
      width: '100%'
    },
    overlay: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%',
      opacity: 0.8,
      backgroundColor: theme.customPalette.primary.dark
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%',
      pointerEvents: 'auto'
    },
    interactive: {
      cursor: 'pointer'
    },
    textContainer: {
      width: '70%',
      maxHeight: '50%',
      marginLeft: '5px'
    },
    defaultText: {
      textAlign: 'left'
    },
    uploadText: {
      color: theme.customPalette.secondary.main,
      textAlign: 'left'
    },
    uploadTime: {
      color: theme.customPalette.secondary.contrastColor,
      fontSize: '10px',
      textAlign: 'left'
    },
    uploadTextError: {
      color: theme.customPalette.dangerError,
      textAlign: 'left'
    },
    iconButton: {
      position: 'relative',
      zIndex: 1500,
      width: '30px',
      height: '30px',
      padding: 0,
      borderRadius: `${theme.surfaceCorners.highEmphasis} !important`,
      backgroundColor: theme.customPalette.primary.main,
      '&:hover': {
        backgroundColor: theme.customPalette.primary.main
      }
    },
    error: {
      color: theme.customPalette.dangerError
    },
    warning: {
      color: theme.customPalette.warning
    },
    progress: {
      color: `${theme.customPalette.secondary.main} !important`
    }
  })
);

interface PublicProps {
  uploadState: UploadUIStates;
  // eslint-disable-next-line:no-any
  upload?: any; // TODO consider removing upload because this object contains too much information for this simple component.
  gridview?: boolean;
  disabledByUpload?: boolean;
  onClickRetryUpload?(): void;
  onClickRemoveUpload?(): void;
  onClickToggleUploadPausedState?: (shouldPause: boolean) => void;
  canResume?: boolean;
  disableTooltip?: boolean;
}

type Properties = PublicProps & ScrollProperties;

const ProgressWithText: React.FunctionComponent<Properties> = React.memo(
  props => {
    const {
      upload,
      gridview,
      disabledByUpload,
      uploadState,
      onClickToggleUploadPausedState,
      onClickRetryUpload,
      onClickRemoveUpload,
      canResume,
      disableTooltip = false
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>(null);

    const classes = useStyles(props);

    React.useEffect(() => {
      if (props.scrolled && anchorEl) {
        setAnchorEl(null);
      }
    }, [props.scrolled, anchorEl]);

    const handlePopoverOpen = useCallback(
      event => {
        setAnchorEl(event.currentTarget);
      },
      [setAnchorEl]
    );

    const handlePopoverClose = useCallback(
      e => {
        setAnchorEl(null);
      },
      [setAnchorEl]
    );

    const open = Boolean(anchorEl);

    const [hoverRef, isHovered] = UseHover();

    /** Pauses / Resumes an upload or does nothing depending on the uploadstate */
    const handleClick = useMemo(() => {
      switch (uploadState) {
        case 'upload_pending':
        case 'paused':
          return (e: React.MouseEvent) => {
            onClickToggleUploadPausedState(false);
            disabledByUpload && e.stopPropagation();
          };
        case 'upload_failed':
          return (e: React.MouseEvent) => {
            onClickRetryUpload();
            disabledByUpload && e.stopPropagation();
          };
        case 'uploading':
          return (e: React.MouseEvent) => {
            onClickToggleUploadPausedState(true);
            disabledByUpload && e.stopPropagation();
          };
        default:
      }
    }, [
      uploadState,
      onClickToggleUploadPausedState,
      onClickRetryUpload,
      disabledByUpload
    ]);

    /** prevent the asset from opening detailview */
    // TODO: move this behavior up tree
    const blockDoubleClick = useCallback(
      e => {
        if (
          uploadState === 'upload_failed' ||
          uploadState === 'upload_initialization_failed' ||
          uploadState === 'upload_missing'
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [uploadState]
    );

    return (
      <div
        ref={hoverRef}
        className={classes.outerContainer}
        onDoubleClick={blockDoubleClick}
      >
        <div className={classes.innerContainer}>
          {uploadState !== 'processing_failed' && (
            <div className={classes.overlay} />
          )}

          <div
            className={useClassName(
              classes.contentContainer,
              handleClick && classes.interactive
            )}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseOver={e => e.stopPropagation()}
            onMouseEnter={e => e.stopPropagation()}
            onClick={handleClick}
          >
            <ProgressIconButton
              onPopoverOpen={handlePopoverOpen}
              onPopoverClose={handlePopoverClose}
              isHovered={isHovered}
              progress={upload?.progress}
              uploadState={uploadState}
              gridview={gridview}
            />

            {!disableTooltip && (
              <ProgressPopup
                onClickRetryUpload={onClickRetryUpload}
                onClickRemoveUpload={onClickRemoveUpload}
                gridview={gridview}
                isHovered={isHovered}
                anchor={hoverRef.current}
                onPopoverOpen={handlePopoverOpen}
                onPopoverClose={handlePopoverClose}
                canResume={canResume}
                upload={upload}
                uploadState={uploadState}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

ProgressWithText.displayName = 'ProgressWithText';

export default WithScrollWrapper<PublicProps>(ProgressWithText);
