import React from 'react';

import { IconButton, makeStyles, createStyles, Theme } from '@material-ui/core';

import { CircularProgress } from '../../Progress/CircularProgress';

import GeneralSVG, {
  iconError,
  iconPause,
  iconPlay
} from '../../icons/GeneralSVG';
import { UploadUIStates } from './types';

interface Props {
  uploadState: UploadUIStates;
  // eslint-disable-next-line:no-any
  progress?: number; // TODO consider removing upload because this object contains too much information for this simple component.
  gridview?: boolean;
  isHovered: boolean;
  onPopoverOpen(event: React.MouseEvent<HTMLButtonElement>): void;
  onPopoverClose(event: React.MouseEvent<HTMLButtonElement>): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultText: {
      textAlign: 'left'
    },

    uploadTextError: {
      color: theme.customPalette.dangerError,
      textAlign: 'left'
    },
    iconButton: {
      position: 'relative',
      zIndex: 1500,
      width: '30px',
      height: '30px',
      padding: 0,
      borderRadius: `${theme.surfaceCorners.highEmphasis} !important`,
      backgroundColor: theme.customPalette.primary.main,
      '&:hover': {
        backgroundColor: theme.customPalette.primary.main
      }
    },
    error: {
      color: theme.customPalette.dangerError
    },
    warning: {
      color: theme.customPalette.warning
    },
    progress: {
      color: `${theme.customPalette.secondary.main} !important`
    }
  })
);

export const ProgressIconButton: React.FunctionComponent<Props> = props => {
  let {
    gridview,
    progress,
    uploadState,
    isHovered,
    onPopoverOpen,
    onPopoverClose
  } = props;

  const classes = useStyles(props);

  const processingFailed = uploadState === 'processing_failed' ? true : false;
  const uploadFailed =
    uploadState === 'upload_failed' ||
    uploadState === 'upload_initialization_failed'
      ? true
      : false;
  const uploadMissing = uploadState === 'upload_missing' ? true : false;

  switch (uploadState) {
    case 'processing_failed':
    case 'upload_initialization_failed':
    case 'upload_missing':
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={e => e.stopPropagation()}
          onDoubleClick={e => {
            if (uploadMissing || uploadFailed) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <GeneralSVG
            path={iconError}
            className={processingFailed ? classes.warning : classes.error}
          />
        </IconButton>
      );

    case 'upload_pending':
    case 'paused':
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onMouseOver={e =>
            !gridview && (onPopoverOpen(e), e.stopPropagation())
          }
          onMouseLeave={e => !gridview && onPopoverClose(e)}
        >
          {isHovered ? (
            <GeneralSVG
              path={iconPlay}
              size={'regular'}
              svgProps={{ viewBox: '0 0 32 32' }}
            />
          ) : (
            <CircularProgress
              size={25}
              color={'primary'}
              classes={{
                colorPrimary:
                  uploadState === 'paused'
                    ? classes.uploadTextError
                    : classes.defaultText
              }}
              value={progress}
              variant={'determinate'}
            />
          )}
        </IconButton>
      );

    case 'upload_failed':
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onMouseOver={e =>
            !gridview && (onPopoverOpen(e), e.stopPropagation())
          }
          onMouseLeave={e => !gridview && onPopoverClose(e)}
        >
          {isHovered ? (
            <GeneralSVG path="refresh" size={'regular'} />
          ) : (
            <CircularProgress
              size={25}
              color={'primary'}
              classes={{
                colorPrimary: classes.uploadTextError
              }}
              value={progress}
              variant={'determinate'}
            />
          )}
        </IconButton>
      );

    default:
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onMouseOver={e =>
            !gridview && (onPopoverOpen(e), e.stopPropagation())
          }
          onMouseLeave={e => !gridview && onPopoverClose(e)}
        >
          {isHovered && uploadState === 'uploading' ? (
            <GeneralSVG path={iconPause} />
          ) : (
            <CircularProgress
              size={25}
              value={uploadState === 'processing_pending' ? 100 : progress || 0}
              color="primary"
              classes={{ colorPrimary: classes.progress }}
              // because when uploading we want to show the uploadpgrogress we will set the variant
              // to static to show the progress value, but when in other states where we don't
              // have any backend progress yet (like processing), we will show the indeterminate state
              // to indicate the system is  working.
              variant={
                ['uploading', 'processing_pending', 'upload_pending'].indexOf(
                  uploadState
                ) > -1
                  ? 'determinate'
                  : 'indeterminate'
              }
              disableShrink={
                ['uploading', 'processing_pending', 'upload_pending'].indexOf(
                  uploadState
                ) > -1
                  ? false
                  : true
              }
            />
          )}
        </IconButton>
      );
  }
};
