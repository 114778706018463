import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ModalNoticeUI } from '../Modal/ModalNoticeUI';
import { Typography } from '../typography/Typography';

interface ResetPassWordSentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      minHeight: '100px'
    },
    title: {}
  })
);

export default function ResetPassWordSent(props: ResetPassWordSentProps) {
  const classes = useStyles({});
  return (
    <ModalNoticeUI title="Recovery Email has been sent">
      <div className={classes.container}>
        <Typography color="contrast1" typographyStyle={'body2'}>
          Check your email to continue the password reset process.
        </Typography>
        <Typography color="contrast2" typographyStyle={'body2'}>
          A message containing further instructions has been dispatched.
        </Typography>
      </div>
    </ModalNoticeUI>
  );
}
