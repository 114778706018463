import React, { useCallback } from 'react';

// UI
import { GenericFieldWithErrorsProps } from './GenericFieldWithErrors';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { DatePickerFieldUI } from '@cube3/ui/src/datepicker/DatePickerFieldUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { makeDateString } from '@cube3/common/utils/makeDateString';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputComponent: {
      padding: '0px',
      height: '40px',
      background: 'rgba(0, 0, 0, 0.16)',
      ...theme.typographyStyles.body1 // spread the 'default' text body properties.
    },
    notSet: {
      color: 'rgba(255, 255, 255, 0.2)'
    },
    error: {
      marginTop: 10
    },
    textField: {
      width: 210
    }
  })
);

interface DateFieldComponentProps extends GenericFieldWithErrorsProps {
  format: string;
  input: any;
  initialFocusedDate: any;
  inputVariant: any;
  autoOk: any;
  disablePast: any;
  minDate: any;
  fullWidth: any;

  required?: boolean;
  showClearButton?: boolean;
  yearOnly?: boolean;
}

function DatePicker(props: DateFieldComponentProps) {
  const classes = useStyles(props);

  const {
    input,
    meta,
    yearOnly,
    showClearButton = true,
    required,
    initialFocusedDate,
    ...restProps
  } = props;

  const handleChange = useCallback(
    (moment) => {
      const value = moment.startOf('day').toDate();
      input.onChange(value);
    },
    [input]
  );

  const handleClear = useCallback(() => {
    input.onChange('');
  }, [input]);

  const shouldShowClear = showClearButton && !required && input.value;

  return (
    <>
      {yearOnly ? (
        <DatePickerFieldUI
          InputProps={{
            classes: {
              root: `${classes.inputComponent} ${
                !input.value && classes.notSet
              }`
            }
          }}
          textFieldClassName={classes.textField}
          value={input.value || initialFocusedDate}
          onChange={handleChange}
          views={['year']}
          format={'YYYY'}
          showClearButton={shouldShowClear}
          handleClear={handleClear}
          {...restProps}
        />
      ) : (
        <DatePickerFieldUI
          labelFunc={(date) =>
            makeDateString({
              date: date.toISOString(),
              noTime: true,
              fullForm: true
            }).dateString
          }
          InputProps={{
            classes: {
              root: `${classes.inputComponent} ${
                !input.value && classes.notSet
              }`
            }
          }}
          textFieldClassName={classes.textField}
          initialFocusedDate={initialFocusedDate}
          value={input.value || initialFocusedDate}
          onChange={handleChange}
          handleClear={handleClear}
          showClearButton={shouldShowClear}
          data-cy={props['data-cy']}
          {...restProps}
        />
      )}
      {meta.error && (
        <Typography
          color="danger1"
          typographyStyle={'body2'}
          className={classes.error}
        >
          {meta.error}
        </Typography>
      )}
    </>
  );
}

export default DatePicker;
