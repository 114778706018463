import React, { useCallback } from 'react';
// components
// utils
import { BrushConfig } from '../../modules/sketchPad/brush';
import MaybeTooltip from '@cube3/ui/src/Tooltip/MaybeTooltip';
import { useShortCut } from '@cube3/ui/src/keyboard-shortcuts/useShortCut';

import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { Color } from '@cube3/cubicle/src/core/atoms/Color';

export const defaultBrushConfig = {
  size: 9, // size in diameter
  softness: 0, // softness of the edge, 0 for hard, 1 for soft
  color: 'rgb(225, 65, 82)', // brush color
  opacity: 1, // brush opacity
  composite: 'source-over' as const, // see CSS globalCompositeOperation for options
  step: 2, // the distance between two brush stamps on a curve
  scale: [0.4, 1], // the [x,y] scaling of the brush stamp
  center: [0.5, 0.5], // the center of any transformations
  rotate: -32, // the rotation of the brush stamp

  // TODO: add the to toSVG as well?
  // stroke smoothing
  smoothTension: 0.8, // [0-1] the amount of tension applied to stroke points
  smoothDuration: 20, // [0-n] the amount of points to apply smoothing to

  // future config implementations:
  dynamicSize: 0,
  dynamicOpacity: 0
};

const markers = [
  { type: 'marker', color: 'rgb(156, 102, 212)', name: 'Violet' }, // violet
  { type: 'marker', color: 'rgb(225, 65, 82)', name: 'Red' }, // red
  { type: 'marker', color: 'rgb(233, 110, 49)', name: 'Orange' }, // orange
  { type: 'marker', color: 'rgb(255, 212, 35)', name: 'Yellow' } // yellow
];

export type CustomizedBrushConfig = Pick<
  BrushConfig,
  'color' | 'composite' | 'size' | 'smoothDuration'
> & { drawArrow?: boolean };
export interface BrushesRecievedProps {
  brushConfig?: CustomizedBrushConfig;
  setBrushConfig: (config: CustomizedBrushConfig) => void;
}

const Brushes = (props: BrushesRecievedProps) => {
  const { brushConfig, setBrushConfig } = props;

  const onClick = useCallback(
    (marker, arrow = false) => {
      const isEraser = marker === 'eraser';
      setBrushConfig({
        ...brushConfig,
        color: isEraser ? brushConfig.color : marker.color,
        composite: isEraser ? 'destination-out' : 'source-over',
        size: isEraser ? 27 : defaultBrushConfig.size,
        smoothDuration: isEraser ? 0 : defaultBrushConfig.smoothDuration,
        drawArrow: arrow
      });
    },
    [setBrushConfig, brushConfig]
  );

  useShortCut({
    keyCode: 'a',
    action: () => onClick(brushConfig)
  });

  useShortCut({
    keyCode: 's',
    action: () => onClick('eraser')
  });

  useShortCut({
    keyCode: 'd',
    action: () => onClick(brushConfig, true)
  });

  const isEraserSelected = brushConfig.composite === 'destination-out';

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px'
      }}
    >
      <MaybeTooltip title="Draw">
        <Button
          iconLeft={
            !isEraserSelected && !brushConfig.drawArrow ? 'edit_fill' : 'edit'
          }
          background={'secondary'}
          buttonStyle="ghost"
          size={SizeVariants.sm}
          onClick={() => onClick(brushConfig)}
          stateOverride={
            !isEraserSelected && !brushConfig.drawArrow ? 'active' : 'default'
          }
        />
      </MaybeTooltip>

      <MaybeTooltip title="Erase">
        <Button
          iconLeft={isEraserSelected ? 'erase_fill' : 'erase'}
          background={'secondary'}
          buttonStyle="ghost"
          size={SizeVariants.sm}
          onClick={() => onClick('eraser')}
          stateOverride={isEraserSelected ? 'active' : 'default'}
        />
      </MaybeTooltip>

      <MaybeTooltip title="Draw arrows">
        <Button
          iconLeft={'arrow_up'}
          background={'secondary'}
          buttonStyle="ghost"
          size={SizeVariants.sm}
          onClick={() => onClick(brushConfig, true)}
          stateOverride={brushConfig.drawArrow ? 'active' : 'default'}
        />
      </MaybeTooltip>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px'
        }}
      >
        {markers &&
          markers.map((marker) => {
            return (
              <Color
                key={marker.color}
                color={marker.name.toLowerCase() as any}
                onClick={() => onClick(marker, brushConfig.drawArrow)}
                active={marker.color === brushConfig?.color}
                size={SizeVariants.lg}
                outlined={marker.color === brushConfig?.color}
                allowClick={true}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Brushes;
