import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorText from './ErrorText';

const lut = {
  B: 1,
  KB: 1000,
  MB: 1000e3,
  GB: 1000e6,
  TB: 1000e9
  // PB: 1000e12,
  // EB: 1000e15,
  // ZB: 1000e18,
  // YB: 1000e21
};

const lut1024 = {
  KiB: 1024,
  MiB: Math.pow(1024, 2),
  GiB: Math.pow(1024, 3),
  TiB: Math.pow(1024, 4)
  // PiB: Math.pow(1024, 5),
  // EiB: Math.pow(1024, 6),
  // ZiB: Math.pow(1024, 7),
  // YiB: Math.pow(1024, 8)
};

export const Bytes = ({
  size = '0',
  inFormat = 'B',
  fullForm,
  placement,
  toolTip,
  decimalNumbers = 2
}) => {
  if (size === '') {
    size = '0';
  }

  // try to parse the given value size into a number.
  const bytes = Number(size) * (lut[inFormat] || lut1024[inFormat] || lut.B);

  // define the possible outputs of the component.
  let sizeSmallForm: number;
  let sizeFullForm;
  let format;

  // check if parseint was succesfull by checking if the number is not NaN.
  if (!Number.isNaN(bytes)) {
    if (!fullForm) {
      if (bytes === 0) {
        format = 'B';
      } else {
        format = Object.keys(lut)
          .filter((k) => lut[k] <= bytes)
          .reverse()[0];
      }
      sizeSmallForm = bytes / lut[format];
      sizeSmallForm = parseFloat(sizeSmallForm.toFixed(decimalNumbers));
      if (format === 'B') {
        format = 'bytes';
      }
    }

    sizeFullForm = bytes
      .toString()
      .split('')
      .reverse()
      .join('')
      .split(/(\d{3})/)
      .filter((c) => c)
      .join(' ')
      .split('')
      .reverse()
      .join('')
      .concat(' bytes');
  } else {
    return <ErrorText errorText="Size unavailable" />;
  }

  return (
    <Tooltip
      title={sizeFullForm}
      placement={placement}
      disableFocusListener={true}
      disableTouchListener={true}
      disableHoverListener={!toolTip}
    >
      {fullForm ? (
        <span>{sizeFullForm}</span>
      ) : (
        <span>{`${sizeSmallForm} ${format}`}</span>
      )}
    </Tooltip>
  );
};

export default Bytes;
