import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useResource__ALPHA } from './useResource';
import { useProjectRole } from './useRole';
import { useCurrentAccount } from '../Administration/withCurrentUserAccount';
import { useSelection } from '../useSelection';
import { Feature } from '@cube3/common/model/resource-types';
import { useFeatures } from './useFeatures';

const requestedFeatures = {
  features: ['/WORKSPACE/TEAMS'] as Feature[]
};

/** Hook to provide the current project resource */
export const useCurrentProject = () => {
  const location = useLocation();
  const { selection } = useSelection();

  // get current project
  const projectId = React.useMemo(() => {
    const matchedProject = matchPath(location.pathname, {
      path: '/workspace/:workspaceId/project/:projectId'
    });
    if (matchedProject) {
      return matchedProject.params['projectId'];
    }
    return undefined;
  }, [location]);

  const selectedProjectId =
    selection[0]?.type === 'project' ? selection[0].id : undefined;

  const project = useResource__ALPHA({
    resourceType: 'project',
    resourceId: projectId || selectedProjectId
  }).resource;

  // determine current project status and access
  const [accountId] = useCurrentAccount(true);

  const [hasTeamFeature] = useFeatures(requestedFeatures);
  const projectRole = useProjectRole({
    accountId: accountId,
    projectId: project?.id,
    hasTeamFeature
  });

  const { isProjectArchived, isProjectPublic, isProjectRoleReadOnly } =
    React.useMemo(() => {
      let isProjectArchived = false;
      let isProjectPublic = false;
      let isProjectRoleReadOnly = false;

      if (project) {
        const { project_status, is_public } = project;

        isProjectArchived = project_status === 'ProjectStatusArchived';
        isProjectPublic = is_public;

        /** read-only for project non-members */
        isProjectRoleReadOnly = projectRole?.resource?.project_member_role
          ? false
          : true;
      }
      return { isProjectArchived, isProjectPublic, isProjectRoleReadOnly };
    }, [project, projectRole]);

  return {
    projectId: project?.id,
    project,
    isProjectArchived,
    isProjectPublic,
    isProjectRoleReadOnly
  };
};
