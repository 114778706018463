import React from 'react';
import { MultipleRecipientPicker } from '../MultipleRecipientPicker';
import { useContractRecipientSuggestions } from './useContractRecipientSuggestions';

const CLEAR_ON = ['blur', 'add-new'];

export const ContractRecipientsPicker = (props) => {
  const { selected, query } = props;

  const [suggested, excluded, suggestionsLoading] =
    useContractRecipientSuggestions({
      context: undefined,
      selected,
      query
    });

  return (
    <MultipleRecipientPicker
      {...props}
      clearOn={CLEAR_ON}
      suggestedResources={suggested}
      excludedResources={excluded}
      suggestionsLoading={suggestionsLoading}
      showDisabled={true}
    />
  );
};
