import { useMemo } from 'react';
import { AssetValidationType } from '../prefabs/TVadExportQualityCheckListItem';

export interface QualityCheckStatesType {
  [assetId: string]: AssetValidationType;
}
export const useMergeQualityCheckStatus = (states: QualityCheckStatesType) => {
  const merged = useMemo(() => {
    const defaultResult = {
      passed: [],
      failed: []
    };
    if (!states) return defaultResult;
    return Object.keys(states).reduce((acc, assetId) => {
      const validation = states[assetId];

      return {
        passed: validation.allPassed ? [...acc.passed, assetId] : acc.passed,
        failed: validation.allFailed ? [...acc.failed, assetId] : acc.failed
      };
    }, defaultResult);
  }, [states]);

  return merged;
};
