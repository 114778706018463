/* eslint-disable eqeqeq, default-case, no-throw-literal */

import { Point } from './point';
// import { Line } from './line.js';
// import { Bezier } from './bezier.js';
import { Stroke } from './stroke';
import { Brush } from './brush';
import { createCanvas, drawPoint, drawLine, drawBezier } from './canvas';
import { ExtendedCanvasData } from '../../types/context-types';

/**
 * @class SketchPad
 * The SketchPad Library
 *
 * @constructor
 * @param {Canvas} canvas the Canvas Element for drawing
 * @param {Object} cfg config object (not implemented)
 */
export class SketchPad {
  canvas: HTMLCanvasElement & ExtendedCanvasData;
  version: string;

  cache: HTMLCanvasElement & ExtendedCanvasData;
  config: {
    values: 'relative';
    cursor: 'test';
  };
  strokes: Stroke[];
  redoStack: Stroke[];
  debug: {
    drawSplines: boolean;
  };
  currentBrush: Brush;
  animationTimer: ReturnType<typeof requestAnimationFrame>;

  constructor(canvas, cfg) {
    // normalize input
    cfg = cfg || {};

    if (
      !canvas ||
      (typeof canvas === 'object' && canvas.tagName !== 'CANVAS')
    ) {
      throw new Error(
        'SketchPad expects a canvas element as the first argument.'
      );
    }

    this.version = '1.0.0';

    // the display canvas as supplied by the host
    this.canvas = canvas;
    this.canvas.pixelRatio = window.devicePixelRatio || 1;
    this.canvas.ctx = this.canvas.getContext('2d');

    // a caching layer to cache the current canvas when a new stroke
    // is created, such that we can easily redraw the current stroke
    // without having to draw the entire canvas.
    this.cache = createCanvas(canvas.width, canvas.height);
    this.cache.pixelRatio = window.devicePixelRatio || 1;
    this.cache.ctx = this.cache.getContext('2d');

    // TODO: revisit this, it's not working.
    // if (cfg.enableHdpi) {
    //     this.canvas.width = this.canvas.getBoundingClientRect().width * this.canvas.pixelRatio;
    //     this.canvas.height = this.canvas.getBoundingClientRect().height * this.canvas.pixelRatio;
    //     this.canvas.style.width = this.canvas.getBoundingClientRect().width;
    //     this.canvas.style.height = this.canvas.getBoundingClientRect().height;
    //     this.canvas.ctx.setTransform(1 / this.canvas.pixelRatio, 0, 0, 1 / this.canvas.pixelRatio, 0, 0);
    // }

    this.config = this.initConfig(cfg);
    this.strokes = [];
    this.redoStack = [];

    // debug config object
    this.debug = {
      drawSplines: false
    };

    this.init();

    this.initEvents();
  }

  init() {
    // TODO: init app here
  }

  initConfig(cfg) {
    return Object.assign(
      {
        /**
         * Use `absolute` values to use the real canvas x and y positions. This is
         * useful when you may want to dynamically extend the canvas while drawing.
         * Use `relative` values to normalize values between 0 and 1, so we can
         * easily scale the strokes when resizing the canvas.
         */
        values: 'absolute', // [absolute, relative]

        /**
         * Use `brush` to render a dynamic cursor based on the brush properties.
         * Any other value will use the css cursor instead, or use `false` to not
         * handle any cursor rendering
         */
        cursor: 'crosshair' // css cursor
      },
      cfg
    );
  }

  initEvents() {
    // TODO: init events here
  }

  storeCache() {
    this.cache.ctx.clearRect(
      0,
      0,
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    this.cache.ctx.drawImage(this.canvas, 0, 0);
  }

  restoreCache() {
    this.canvas.ctx.clearRect(
      0,
      0,
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    this.canvas.ctx.drawImage(this.cache, 0, 0);
  }

  /**
   * undo the last stroke
   * @return {Bool} wether the undo was successful
   */
  undo() {
    if (this.strokes.length) {
      this.redoStack.push(this.strokes.pop());
      this.clear();
      this.draw();
      return true;
    } else {
      return false;
    }
  }

  /**
   * redo the last undone stroke
   * @return {Bool} wether the redo was successful
   */
  redo() {
    if (this.redoStack.length) {
      var stroke = this.redoStack.pop();
      this.strokes.push(stroke);
      this.drawStroke(stroke, null, true);
      return true;
    } else {
      return false;
    }
  }

  reset() {
    this.strokes.length = 0;
    this.redoStack.length = 0;
    return this;
  }

  /**
   * clears the entire SketchPad canvas
   * @return {SketchPad} the SketchPad, support method chaining
   */
  clear() {
    this.canvas.ctx.clearRect(
      0,
      0,
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    this.cache.ctx.clearRect(
      0,
      0,
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );

    return this;
  }

  resize(width, height) {
    this.canvas.width = width;
    this.canvas.height = height;

    this.cache.width = width;
    this.cache.height = height;

    this.clear();
    this.draw(this.currentBrush);

    return this;
  }

  getSize() {
    return { width: this.canvas.width, height: this.canvas.height };
  }

  /**
   * return the scale factor as measured by the diagonal
   * of the canvas size.
   */
  getScale() {
    return (
      Math.sqrt(2) /
      Math.sqrt(
        Math.pow(this.canvas.width, 2) + Math.pow(this.canvas.height, 2)
      )
    );
  }

  /**
   * creates a new Point and normalises the x and y values if needed.
   * @param {Float} x the horizontal position
   * @param {Float} y the vertical position
   * @return {Point} the point
   */
  newPoint(x, y, makeRelative) {
    makeRelative =
      makeRelative == undefined
        ? this.config.values == 'relative'
        : makeRelative;
    // normalise the input if we're setup for relative values
    if (makeRelative) {
      return new Point(x / this.canvas.width, y / this.canvas.height, x, y);
    }

    return new Point(x, y);
  }

  newPointFromEvent(e, makeRelative) {
    makeRelative =
      makeRelative == undefined
        ? this.config.values == 'relative'
        : makeRelative;
    var x = e.offsetX,
      y = e.offsetY,
      ts = e.timeStamp;

    // normalise the input if we're setup for relative values
    if (makeRelative) {
      return new Point(x / this.canvas.width, y / this.canvas.height, x, y, ts);
    }

    return new Point(x, y, x, y, ts);
  }

  addPointToStroke(stroke, p, makeRelative) {
    if (p instanceof Array) p = this.newPoint(p[0], p[1], makeRelative);
    return stroke.addPoint(p);
  }

  /**
   * sets the current active brush
   */
  setBrush(brush) {
    this.currentBrush = brush;
    this.setCursor(this.config.cursor, brush);
    return this;
  }

  /**
   * creates a new brush
   * @param {Object} cfg the brush config object
   * @return {Brush} the brush
   */
  newBrush(cfg?) {
    var brush = new Brush(cfg);
    this.setBrush(brush);
    return brush;
  }

  /**
   * creates a new stroke
   * @param {Brush} brush
   * @return {Stroke} the stroke
   */
  newStroke(brush?) {
    var stroke = new Stroke(brush || this.currentBrush);
    this.strokes.push(stroke);
    this.redoStack.length = 0;

    // make sure we have a fresh cache for this stroke.
    this.storeCache();
    return stroke;
  }

  // TODO: whats the better concept here:
  // 1. add references to SketchPad from all Classes like Stroke Brush so we can access
  //    config and settings from SketchPad.
  // 2. Keep the classes generic and agnostic, but include proper usage
  //    from within SketchPad... applying the correct setting and configs to the
  //    classes.
  // 3. Approach is more functional, applying several conversions or context sequential.

  // TODO: we could also create a method to simply extract a series of points from a
  // SVG path, and have another method deal with adding those points?

  /**
   * newStrokeFromSVG
   * @param {Element} el SVG element
   * @param {Int} width the original width of the svg viewBox
   * @param {Int} height the original height of the svg viewBox
   */
  newStrokeFromSVG(el, width, height) {
    width = width || 1;
    height = height || 1;

    var stroke = this.newStroke();
    // handle path elements
    if (el.tagName == 'path') {
      var data = el.getAttribute('d'),
        parts = data.split(' '),
        len = parts.length,
        i = 0;

      // setup brush config
      stroke.brushConfig = {
        size: parseFloat(el.getAttribute('stroke-width')),
        softness: parseFloat(el.getAttribute('brush-softness')),
        color: el.getAttribute('stroke'),
        opacity: parseFloat(el.getAttribute('brush-opacity')),
        composite: el.getAttribute('brush-composite'),
        step: parseFloat(el.getAttribute('brush-step')),
        scale: [
          parseFloat(el.getAttribute('brush-sx')),
          parseFloat(el.getAttribute('brush-sy'))
        ],
        center: [
          parseFloat(el.getAttribute('brush-cx')),
          parseFloat(el.getAttribute('brush-cy'))
        ],
        rotate: parseFloat(el.getAttribute('brush-rotate'))
      };

      for (i; i < len; i++) {
        if (isNaN(parts[i])) {
          // part is an SVG command
          switch (parts[i]) {
            case 'M':
            case 'L':
              // Move to or Line commands both only contain one point
              this.addPointToStroke(
                stroke,
                [
                  parseFloat(parts[i + 1]) / width,
                  parseFloat(parts[i + 2]) / height
                ],
                false
              );
              i += 2; // move up two positions
              break;

            case 'S':
              // Continued Cubic Bezier only contains a single control point and the last point. We don't care about
              // the control points, so only add the last point.
              this.addPointToStroke(
                stroke,
                [
                  parseFloat(parts[i + 3]) / width,
                  parseFloat(parts[i + 4]) / height
                ],
                false
              );
              i += 4; // move up four positions
              break;

            case 'C':
              // Cubic Bezier contains two control points and two points. We don't care about
              // the control points, so only add the points.
              this.addPointToStroke(
                stroke,
                [
                  parseFloat(parts[i + 1]) / width,
                  parseFloat(parts[i + 2]) / height
                ],
                false
              );
              this.addPointToStroke(
                stroke,
                [
                  parseFloat(parts[i + 7]) / width,
                  parseFloat(parts[i + 8]) / height
                ],
                false
              );
              i += 8; // move up 8 positions
              break;
          }
        }
      }
    } else if (el.tagName == 'circle') {
      // setup brush config
      stroke.brushConfig = {
        size: parseFloat(el.getAttribute('r')) * 2,
        softness: parseFloat(el.getAttribute('brush-softness')),
        color: el.getAttribute('fill'),
        opacity: parseFloat(el.getAttribute('brush-opacity')),
        composite: el.getAttribute('brush-composite'),
        step: parseFloat(el.getAttribute('brush-step')),
        scale: [
          parseFloat(el.getAttribute('brush-sx')),
          parseFloat(el.getAttribute('brush-sy'))
        ],
        center: [
          parseFloat(el.getAttribute('brush-cx')),
          parseFloat(el.getAttribute('brush-cy'))
        ],
        rotate: parseFloat(el.getAttribute('brush-rotate'))
      };

      this.addPointToStroke(
        stroke,
        [
          parseFloat(el.getAttribute('cx')) / width,
          parseFloat(el.getAttribute('cy')) / height
        ],
        false
      );
    }
  }

  /**
   * draws all the Strokes to the canvas
   * @param {Brush} brush (optional)
   * @return {SketchPad} the SketchPad, support method chaining
   */
  draw(brush?) {
    brush = brush || this.currentBrush || this.newBrush();

    for (var i = 0, len = this.strokes.length; i < len; i++) {
      this.drawStroke(this.strokes[i], brush, true);
    }

    return this;
  }

  /**
   * draws all the Strokes to the canvas segment by segment
   * @param {int} time (optional) the total time of the animation in milliseconds
   * @return {SketchPad} the SketchPad, support method chaining
   */
  drawAnimated(time) {
    time = time || 1000;

    // cancel any previous drawTimers
    if (this.animationTimer) cancelAnimationFrame(this.animationTimer);

    var me = this,
      brush = this.currentBrush || this.newBrush(),
      then = Date.now(),
      //   startTime = then,
      strokeIdx = 0,
      segmentIdx = 0,
      stroke,
      segment,
      totalSegments,
      fpsInterval,
      fps,
      now,
      elapsed;

    // calculate the total amount of segments so we can dunamically adjust the drawing speed
    totalSegments = this.strokes.reduce(function (n, stroke) {
      return (n += stroke.segments.length);
    }, 0);

    fps = totalSegments / (time / 1000);
    fpsInterval = time / fps;

    var animate = function () {
      // calc elapsed time since last loop
      now = Date.now();
      elapsed = now - then;

      // if enough time has elapsed, draw the next frame
      if (elapsed > fpsInterval) {
        then = now - (elapsed % fpsInterval);

        var count = Math.floor(elapsed / fpsInterval),
          i = 0;

        // correct the count if we don't have more segments
        stroke = stroke || me.strokes[0];
        count = Math.min(count, stroke.segments.length - segmentIdx);

        // Draw the stroke segments
        for (i; i < count; i++) {
          stroke = me.strokes[strokeIdx];
          if (!stroke) break;
          segment = stroke.segments[segmentIdx];
          if (!segment) break;

          brush.set(stroke.brushConfig);
          me.drawStrokeSegment(stroke, segment, brush);
          segmentIdx += 1;

          // update the index for the next segment
          if (segmentIdx > stroke.segments.length - 1) {
            strokeIdx += 1;
            segmentIdx = 0;
          }
        }
      }

      // request another frame if we still have segments to draw
      if (strokeIdx < me.strokes.length) {
        requestAnimationFrame(animate);
      }
    };

    animate();

    return this;
  }

  /**
   * draws the entire Stroke to the canvas
   * @param {Stroke} stroke
   * @param {Brush} brush (optional)
   * @return {SketchPad} the SketchPad, support method chaining
   */
  drawStroke(stroke, brush, noCache) {
    if (!stroke || !(stroke instanceof Stroke))
      throw 'drawStroke expects a Stroke as the first parameter';
    brush = brush || this.currentBrush || this.newBrush();

    // if the stroke has a brush config (it has been drawn before) apply it to the brush.
    brush.set(stroke.brushConfig);

    var i = 0,
      len = stroke.segments.length,
      cacheIdx;

    if (noCache) {
      for (i = 0; i < len; i++) {
        this.drawStrokeSegment(stroke, stroke.segments[i], brush);
      }
    } else {
      // Note: the first storeCache is called by newStroke, so we can
      // always restore from it here.
      this.restoreCache();

      // we start caching after every stroke, or in the case of smoothing: when length > smoothDuration
      cacheIdx = len - (brush.config.smoothDuration || 1);

      for (i = Math.max(0, cacheIdx); i < len; i++) {
        if (i == cacheIdx) {
          this.drawStrokeSegment(stroke, stroke.segments[i], brush);
          this.storeCache();
        } else if (i > cacheIdx) {
          // draw segments that will be smoothed over time, so there is
          // no need to cache these.
          this.drawStrokeSegment(stroke, stroke.segments[i], brush);
        }
      }
    }

    // store the brush config so we can redraw when needed.
    stroke.brushConfig = Object.assign({}, brush.config);

    // debug
    if (this.debug.drawSplines) {
      this.drawStrokeProperties(stroke);
    }

    return this;
  }

  /**
   * draws a segment of the Stroke to the canvas
   * @param {Stroke} stroke
   * @param {Point|Line|Bezier} segment
   * @param {Brush} brush
   * @return {SketchPad} the SketchPad, support method chaining
   */
  drawStrokeSegment(stroke, segment, brush) {
    if (!stroke || !(stroke instanceof Stroke))
      throw 'drawStrokeSegment expects a Stroke as the first parameter';
    segment = typeof segment == 'number' ? stroke.getSegment(segment) : segment;
    brush = brush || this.currentBrush || this.newBrush();

    // store the brush config on this stroke, so we can use it
    // later if needed by undo / redo etc.
    stroke.brushConfig = Object.assign({}, brush.config);

    var ctx = this.canvas.ctx,
      step =
        this.config.values == 'relative'
          ? brush.step / this.canvas.width
          : brush.step,
      offset = 0,
      travelDistance,
      point;

    if (!segment) {
      console.error('drawStrokeSegment called without a valid segment!');
      return;
    }

    // draw a brush stamp if we only have one point in our stroke
    if (segment.isPoint && stroke.length == 0) {
      if (this.config.values == 'relative') {
        // make point absolute
        point = new Point(
          segment.x * this.canvas.width,
          segment.y * this.canvas.height
        );
      } else {
        point = segment;
      }

      brush.stamp(ctx, point);
    }

    // for segments that describe a line we want to evenly space out all brush stamps on
    // the entire stroke, therefore we need to know the exact length of all previous
    // segments, and continue where the last stamp would have occured.
    var index = stroke.segments.indexOf(segment),
      length = stroke.segments.slice(0, index).reduce(function (sum, curve) {
        return (sum += curve.length);
      }, 0);

    // calculate offset
    offset = step - (length % step);

    // stamp the brush onto the segment
    for (
      travelDistance = offset;
      travelDistance <= segment.length;
      travelDistance += step
    ) {
      point = segment.pointAtTravelDistance(travelDistance);

      // TODO: we could also do this with a matrix tarnsform on the canvas itself?

      // make values absolute
      if (this.config.values == 'relative') {
        point.x *= this.canvas.width;
        point.y *= this.canvas.height;
      }

      brush.stamp(ctx, point);
    }

    // debug
    if (this.debug.drawSplines) {
      this.drawSegmentProperties(segment);
    }

    return this;
  }

  /**
   * draws the curve properties for this stroke
   * @param {Object} cfg config object for drawProperties
   * @return {SketchPad} the SketchPad, support method chaining
   */
  drawStrokeProperties(stroke) {
    stroke.segments.forEach(function (segment) {
      this.drawSegmentProperties(segment);
    }, this);

    return this;
  }

  drawSegmentProperties(segment) {
    var s = segment;

    switch (s.type) {
      case 'point':
        drawPoint(this.canvas.ctx, s.rawX, s.rawY, {
          fillStyle: 'rgb(128,255,255)'
        });
        break;
      case 'line':
        drawLine(this.canvas.ctx, s.p1.rawX, s.p1.rawY, s.p2.rawX, s.p2.rawY, {
          strokeStyle: 'red'
        });
        drawPoint(this.canvas.ctx, s.p1.rawX, s.p1.rawY, {
          fillStyle: 'rgb(128,255,255)'
        });
        drawPoint(this.canvas.ctx, s.p2.rawX, s.p2.rawY, {
          fillStyle: 'rgb(128,255,255)'
        });
        break;
      case 'bezier':
        drawBezier(
          this.canvas.ctx,
          s.p1.rawX,
          s.p1.rawY,
          s.p2.rawX,
          s.p2.rawY,
          s.p3.rawX,
          s.p3.rawY,
          s.p4.rawX,
          s.p4.rawY,
          { strokeStyle: 'red' }
        );
        drawPoint(this.canvas.ctx, s.p1.rawX, s.p1.rawY, {
          fillStyle: 'rgb(128,255,255)'
        });
        drawPoint(this.canvas.ctx, s.p4.rawX, s.p4.rawY, {
          fillStyle: 'rgb(128,255,255)'
        });
        drawLine(this.canvas.ctx, s.p1.rawX, s.p1.rawY, s.p2.rawX, s.p2.rawY, {
          strokeStyle: 'rgb(255,128,255)'
        });
        drawLine(this.canvas.ctx, s.p3.rawX, s.p3.rawY, s.p4.rawX, s.p4.rawY, {
          strokeStyle: 'rgb(255,128,255)'
        });
        drawPoint(this.canvas.ctx, s.p2.rawX, s.p2.rawY, {
          fillStyle: 'rgba(255,128,255)'
        });
        drawPoint(this.canvas.ctx, s.p3.rawX, s.p3.rawY, {
          fillStyle: 'rgba(255,128,255)'
        });
        break;
    }

    return this;
  }

  toSVG() {
    var width = this.canvas.width,
      height = this.canvas.height,
      svg = `<svg viewBox="0 0 ${width} ${height}" width="${width}" height="${height}" version="1.1" sketchpad-version="${this.version}" xmlns="http://www.w3.org/2000/svg">`,
      strokes = [],
      collection,
      masterGroup,
      group,
      masks = [],
      mask,
      maskId: boolean | string = false,
      i = 0,
      j = 0,
      stroke,
      prevStroke,
      prevGroup,
      isEraser,
      svgElement;

    // helper method to understand if the stroke is an eraser stroke
    isEraser = function (s) {
      return s != undefined
        ? s.brushConfig.composite == 'destination-out'
        : undefined;
    };

    // todo: move this stuff to an SVG module!

    // SVG Object for easy serialisation
    svgElement = function (head, tail) {
      this.head = head || '';
      this.items = [];
      this.tail = tail || '';
    };
    svgElement.prototype = {
      push: function (o) {
        this.items.push(o);
      },
      unshift: function (o) {
        this.items.unshift(o);
      },
      toString: function () {
        return `${this.head}${this.items.join('')}${this.tail}`;
      }
    };

    // create collections of strokes and eraser-strokes
    for (i = 0; i < this.strokes.length; i++) {
      stroke = this.strokes[i];
      if (isEraser(stroke) != isEraser(prevStroke)) {
        collection = [];
        strokes.push(collection);
      }
      collection.push(stroke);
      prevStroke = stroke;
    }

    // we reverse the groups, so we know which strokes require a mask
    strokes.reverse();

    // process the groups
    for (i = 0; i < strokes.length; i++) {
      collection = strokes[i];

      // process eraser strokes
      if (isEraser(collection[0])) {
        // create a new mask element and add it to the beginning of the masks array
        mask = new svgElement();
        masks.unshift(mask);
        maskId = `mask-${masks.length}`;
        mask.head = `<mask id="${maskId}" x="-100%" y="-100%" width="300%" height="300%">`; // extend the mask boundaries to compensate for stroke width
        mask.tail = '</mask>';
        // add a white background
        mask.push(
          `<rect x="0" y="0" width="${this.canvas.width}" height="${this.canvas.height}" fill="white"/>`
        );
        // add the eraser stroke elements to the mask element
        for (j = 0; j < collection.length; j++) {
          stroke = collection[j];
          mask.push(
            stroke.toSVG({
              id: `stroke-${this.strokes.indexOf(stroke)}`,
              width: width,
              height: height
            })
          );
        }
      } else {
        // process regular strokes
        // create a new group element
        group = new svgElement();
        group.head = maskId ? `<g mask="url(#${maskId})">` : '<g>';
        group.tail = '</g>';
        // nest the group in the previous group, so the mask will apply there as well.
        if (prevGroup) {
          prevGroup.unshift(group);
        } else {
          masterGroup = group;
        }

        // add the strokes to the group
        for (j = 0; j < collection.length; j++) {
          stroke = collection[j];
          group.push(
            stroke.toSVG({
              id: `stroke-${this.strokes.indexOf(stroke)}`,
              width: width,
              height: height
            })
          );
        }

        prevGroup = group;
      }
    }

    // add all the masks
    if (masks.length)
      svg += `<defs>${masks.reverse().reduce(function (s, m) {
        return (s += m);
      }, '')}</defs>`;
    // add the stroke groups
    svg += masterGroup;

    svg += '</svg>';
    return svg;
  }

  fromSVG(data) {
    var parser = new DOMParser(),
      svgDom = parser.parseFromString(data, 'text/xml'),
      viewBoxWidth = (svgDom.documentElement as unknown as SVGSVGElement)
        .viewBox.baseVal.width,
      viewBoxHeight = (svgDom.documentElement as unknown as SVGSVGElement)
        .viewBox.baseVal.height,
      paths = Array.prototype.slice.call(svgDom.getElementsByTagName('path')),
      circles = Array.prototype.slice.call(
        svgDom.getElementsByTagName('circle')
      ),
      strokes = paths.concat(circles),
      i = 0,
      len = strokes.length;

    this.clear();
    this.reset();

    // sort the strokes by stroke-id
    strokes.sort(function (a, b) {
      if (parseFloat(a.id.split('-')[1]) > parseFloat(b.id.split('-')[1])) {
        return 1;
      }
      if (parseFloat(b.id.split('-')[1]) > parseFloat(a.id.split('-')[1])) {
        return -1;
      }
      return 0;
    });

    // create the strokes
    for (i; i < len; i++) {
      this.newStrokeFromSVG(strokes[i], viewBoxWidth, viewBoxHeight);
    }
  }

  setCursor(cursor, brush) {
    cursor = cursor || this.config.cursor;
    brush = brush || this.currentBrush;

    if (cursor == 'brush') {
      var cursorCSS = brush.getCursorSVG();
      this.canvas.style.cursor = cursorCSS;
    } else {
      // use css property
      this.canvas.style.cursor = cursor;
    }
  }
}
