import * as Sentry from '@sentry/browser';

import { actions as sessionActions } from '../ducks/session';
import { actions as nodeActions } from '../ducks/resource-nodes';
import { actions as consentActions } from '../ducks/cookie-consent';
import {
  consentOptions,
  cookieTypesShort
} from '../ducks/cookie-consent/state';
import { getResourceById } from '../ducks/request-status/utils/nodeHelpers';

export const makeSentryMiddleware =
  (Sentry) =>
  ({ getState, dispatch }) => {
    setUser(getState());

    return (next) => (action) => {
      switch (action.type) {
        case nodeActions.RECEIVE_RESOURCE:
          setTimeout(() => {
            const userData = getUserData(getState(), action);
            if (!userData) {
              return;
            }
            setUser(getState(), userData);
          });
          break;
        case sessionActions.LOGIN_SUCCESS:
        case sessionActions.SET_CURRENT_ACCOUNT:
        case consentActions.CLEAR_CONSENT:
        case consentActions.GIVE_CONSENT:
        case consentActions.REJECT_CONSENT:
        case consentActions.SYNCHRONIZE_CONSENT:
          setTimeout(() => {
            const userData = getUserData(getState(), action);
            setUser(getState(), userData);
          });
          break;

        case sessionActions.LOGOUT: {
          Sentry.setUser(null);
          break;
        }
        default:
          break;
      }

      return next(action);
    };
  };

const getUserData = (state, action = undefined) => {
  const { session, resourceNodes } = state;

  const user = session?.user;
  if (!user) {
    return;
  }
  if (action?.payload?.resourceType === 'user') {
    if (action.payload?.data.id === user) {
      return action.payload?.data;
    }
  }
  return getResourceById(resourceNodes, 'user', user);
};

const setUser = (
  state,
  userData = { email_address: undefined, full_name: undefined }
) => {
  const { session = {}, cookieConsent } = state;

  if (
    !cookieConsent ||
    cookieConsent[cookieTypesShort.ANALYTICS]?.state !==
      consentOptions.CONSENTED
  ) {
    Sentry.setUser(null);
    return;
  }

  const { user, account } = session;
  const { email_address, full_name } = userData;

  const u = {
    id: user,
    user,
    account,
    email: anon(email_address?.split('@')[1]),
    username: anon(full_name?.split(' ')[0])
  };

  Sentry.setUser(u);
};

const anon = (str = '') => {
  return str
    .split('')
    .map((a, idx) => (!(idx % 2) ? a : '_'))
    .join('');
};
