import React from 'react';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';

const createConfig = {
  resourceType: 'project-team-membership' as const,
  cacheInvalidator: (res, anc, rel) => [
    res,
    { type: undefined, id: undefined, relationship: 'project-team-memberships' }
  ]
};

export const withProjectTeamMemberships = (Wrapped) => (props) => {
  const [addTeamAsMember, addTeamAsMemberStatus] =
    useCreateResource__ALPHA(createConfig);

  return (
    <Wrapped
      {...props}
      addTeamAsMember={addTeamAsMember}
      addTeamAsMemberStatus={addTeamAsMemberStatus}
    />
  );
};
