/* eslint-disable eqeqeq */

/**
 * Creates an HTML Canvas Element and sets the dpr (devicePixelRatio) value for HDPI screens
 * @param {Number} width the width of the canvas
 * @param {Number} height the height of the canvas
 * @return {Element} the canvas Element
 */
export function createCanvas(width, height, hdpi = false) {
  var canvas = document.createElement('canvas'),
    dpr = hdpi ? window.devicePixelRatio || 1 : 1,
    ctx = canvas.getContext('2d');

  canvas.width = width * dpr;
  canvas.height = height * dpr;
  ctx.scale(dpr, dpr);

  return canvas;
}

/**
 * draws a point with soft edges on the canvas context
 * @param {Object} ctx the canvas context to draw on
 * @param {Point|Object} p1 the point to draw at
 * @param {Object} cfg config object with shape properties
 */
export function drawCircle(ctx, p1, cfg) {
  // default config object
  cfg = Object.assign(
    {
      size: 5,
      softness: 0.5,
      color: 'black',
      scaleX: 1,
      scaleY: 1,
      rotate: 0,
      centerX: 0.5,
      centerY: 0.5
    },
    cfg
  );

  ctx.save();

  // move to the center
  ctx.translate(ctx.canvas.width * cfg.centerX, ctx.canvas.width * cfg.centerX);
  // apply rotation and scale
  ctx.rotate(cfg.rotate * (Math.PI / 180));
  ctx.scale(cfg.scaleX, cfg.scaleY);
  // move back to the origin
  ctx.translate(
    -ctx.canvas.width * cfg.centerX,
    -ctx.canvas.width * cfg.centerX
  );

  // draw the disc
  ctx.beginPath();
  ctx.arc(p1.x, p1.y, cfg.size / 2, 0, 2 * Math.PI);

  // restore now so we get an undistorted stroke
  ctx.restore();

  ctx.stroke();
}

/**
 * draws a point with soft edges on the canvas context
 * @param {Object} ctx the canvas context to draw on
 * @param {Point|Object} p1 the point to draw at
 * @param {Object} cfg config object with shape properties
 */
export function drawDisc(ctx, p1, cfg) {
  // default config object
  cfg = Object.assign(
    {
      size: 5,
      softness: 0.5,
      color: 'black',
      scaleX: 1,
      scaleY: 1,
      rotate: 0,
      centerX: 0.5,
      centerY: 0.5
    },
    cfg
  );

  ctx.save();

  // move to the center
  ctx.translate(ctx.canvas.width * cfg.centerX, ctx.canvas.width * cfg.centerX);
  // apply rotation and scale
  ctx.rotate(cfg.rotate * (Math.PI / 180));
  ctx.scale(cfg.scaleX, cfg.scaleY);
  // move back to the origin
  ctx.translate(
    -ctx.canvas.width * cfg.centerX,
    -ctx.canvas.width * cfg.centerX
  );

  // apply the color to the context
  ctx.fillStyle = cfg.color;

  if (cfg.softness > 0) {
    // draw the colored background
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // define a gradient
    var gradient = ctx.createRadialGradient(
      p1.x,
      p1.y,
      (cfg.size / 2) * (1 - cfg.softness),
      p1.x,
      p1.y,
      cfg.size / 2
    );
    gradient.addColorStop(0, 'rgba(0,0,0,1)');
    gradient.addColorStop(1, 'rgba(0,0,0,0)');

    // use the gradient as fill
    ctx.fillStyle = gradient;

    // important for clipping the background color by the soft gradient
    ctx.globalCompositeOperation = 'destination-in';
  }

  // draw the disc
  ctx.beginPath();
  ctx.arc(p1.x, p1.y, cfg.size / 2, 0, 2 * Math.PI);
  ctx.fill();

  ctx.restore();
}

/**
 * draws the point for debug purposes.
 * @param {Object} ctx the canvas context to draw on
 * @param {Object} cfg styling config.
 * @return {CUBE.SketchPad.Point} the Line, support method chaining
 */
export function drawPoint(ctx, x, y, cfg) {
  cfg = cfg || {};

  ctx.save();
  ctx.globalAlpha = cfg.globalAlpha == undefined ? 1 : cfg.globalAlpha;
  ctx.fillStyle = cfg.fillStyle || 'rgba(128,255,255)';
  ctx.beginPath();
  ctx.arc(x, y, (ctx.lineWidth || 1) * 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.restore();
}

/**
 * draws the path line for debug purposes.
 * @param {Object} ctx the canvas context to draw on
 * @param {Object} cfg styling config.
 * @return {CUBE.SketchPad.Line} the Line, support method chaining
 */
export function drawLine(ctx, x1, y1, x2, y2, cfg) {
  cfg = cfg || {};

  ctx.save();
  ctx.globalAlpha = cfg.globalAlpha == undefined ? 1 : cfg.globalAlpha;
  ctx.strokeStyle = cfg.strokeStyle || 'red';
  ctx.lineWidth = cfg.lineWidth || 1;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
  ctx.restore();
}

export function drawBezier(ctx, x1, y1, x2, y2, x3, y3, x4, y4, cfg) {
  cfg = cfg || {};

  ctx.save();
  ctx.globalAlpha = cfg.globalAlpha == undefined ? 1 : cfg.globalAlpha;
  ctx.strokeStyle = cfg.strokeStyle || 'red';
  ctx.lineWidth = cfg.lineWidth || 1;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.bezierCurveTo(x2, y2, x3, y3, x4, y4);
  ctx.stroke();
  ctx.restore();
}
