import { ThemeContext } from '@emotion/react';
import { css } from '@emotion/css';
import { useContext, useMemo } from 'react';
import { Theme } from '../theme/themes';
import { CSSInterpolation } from '@mui/system';

type PropsWithTheme<P extends {}> = P & { theme: Theme };

type StyleDef = CSSInterpolation;

type Styles<C> = { [k in keyof C]: StyleDef | Styles<C> };

type StylesFn<P, C> = (props: PropsWithTheme<P>) => Styles<C>;

type ClassMap<C> = { [k in keyof C]: string };

export type WithTheme<P> = P & { theme: Theme };

/**
 * Map an object with keys that reflect classnames (and selectors) and values that contain Emotion compatible styles definitions. Similar to JSS and legacy material-ui/styles
 * @param stylesFn styles object or function that returns styles object
 * @returns object with keys that reflect the provided classnames and values that reflect the generated ones
 */
export const makeCSS = <P extends {}, C extends {}>(
  stylesFn: StylesFn<WithTheme<P>, C> | Styles<C>
) => {
  const useCSS = (props?: Partial<P>) => {
    const theme = useContext(ThemeContext);

    const styles: Styles<C> =
      typeof stylesFn === 'function'
        ? stylesFn({ ...props, theme } as PropsWithTheme<P>)
        : stylesFn;

    const deps =
      typeof stylesFn === 'function'
        ? [theme, ...(props ? Object.keys(props).map((k) => props[k]) : [])]
        : [];

    const classes = useMemo(() => {
      const compiled = {};
      const classNames = Object.keys(styles);

      const stack = { ...styles };

      // compile classes that don't contain refs
      classNames.forEach((className) => {
        if (!findRefs(styles[className]).length) {
          compiled[className] = css({
            ...styles[className],
            label: className
          });
        }
      });

      // remove already compiled classes from stack
      classNames.forEach((className) => {
        if (compiled[className]) {
          delete stack[className];
        }
      });

      Object.keys(stack).forEach((className) => {
        // find refs
        const refs = findRefs(stack[className]);

        refs.forEach((r) => {
          const refClassName = r.class;
          if (!compiled[refClassName]) {
            throw new Error('Ref in ref is not supported');
          }

          if (compiled[refClassName]) {
            // r.newKey = compiled[refClassName];
            r.newKey = r.key.replaceAll(r.match, `${compiled[refClassName]}`);

            r.parent[r.newKey] = r.parent[r.key];
            delete r.parent[r.key];
          }
        });
        compiled[className] = css({
          ...stack[className],
          label: className
        });
      });

      return compiled;
    }, [styles, deps]);

    return classes as ClassMap<C>;
  };
  return useCSS;
};

// matches dollar sign references
const refExp = /(\$[a-zA-Z0-9_-]+)/g;

type ClassDefs = {
  [key: string]: ClassDefs | string | number | unknown;
};

interface RefMatch {
  parent: ClassDefs;
  key: string;
  newKey: string;
  self: ClassDefs | keyof ClassDefs | unknown;
  match: string;
  class: string;
}

// find any keys that contain dollar sign references to other (unprocessed) classnames
const findRefs = (obj: ClassDefs, found: RefMatch[] = []): RefMatch[] => {
  if (!obj || typeof obj !== 'object') {
    return;
  }
  Object.keys(obj).forEach((k) => {
    // console.info('DEBUG k', k);
    const matches = k.match(refExp);
    if (matches?.length) {
      matches.forEach((m) => {
        found.push({
          parent: obj,
          key: k,
          newKey: k,
          self: obj[k],
          match: m,
          class: m.slice(1)
        });
      });
    }
    if (obj[k] && typeof obj[k] === 'object') {
      findRefs(obj[k] as ClassDefs, found);
    }
  });

  return found;
};
