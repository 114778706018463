import { Feature, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface FeatureFlag extends ResourceBase {
  feature: Feature;
  display_name: string;
  description: string;
  createdAt: string;
  enabled: boolean;
  relationships: {
    workspace: Related<'workspace'>;
  };
}

export const featureFlag: RawModel = {
  type: 'feature-flag',
  plural: 'feature-flags',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    description: 'string',
    display_name: 'string',
    enabled: 'boolean',
    feature: 'string'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'feature-flags'
    }
  }
};
