import { useMappedId__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { Folder } from '@cube3/common/model/schema/resources/folder';
import { Asset } from '@cube3/common/model/schema/resources/asset';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { deduped, useCreateRenderBatch } from './useCreateRenderBatch';
import { useCreateDownload } from './useCreateDownload';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

/** Click `Download` button to:
 * - create dry run and filter out failed files
 * - render batch (should skip this if render already exists)
 * - create download files
 */
export const useDownloadFiles = (
  presetId: string,
  workspaceId: string,
  /** from user selection */
  assets?: Asset[],
  folders?: Folder[]
) => {
  const tempId = useRef(uuidv4()).current;
  const batchId = useMappedId__ALPHA(tempId);
  const { resource: batchResource, retrieve } = useResource__ALPHA({
    resourceType: 'render-batch',
    resourceId: batchId
  });

  const {
    onCreateBatch,
    createBatchStatus,
    parsedRenderErrors,
    parsedRenderWarnings,
    serverError,
    alreadyRenderedAssetIds
  } = useCreateRenderBatch({
    workspaceId,
    presetId
  });
  /** assets failed with un-acceptable error, we only want to list 5 assets here
   * - only use to list them when hovering warning icon
   */
  const assetsNotDownloadable = useResource__ALPHA({
    resourceIdentifiers: parsedRenderErrors.failedAssetIds
      ?.slice(0, 5)
      ?.map((id) => ({ type: 'asset', id }))
  });

  /** assets failed with acceptable error, we only want to list 5 assets here
   * - only use to list them when hovering warning icon
   */
  const assetsWithWarnings = useResource__ALPHA({
    resourceIdentifiers: parsedRenderWarnings.failedAssetIds
      ?.slice(0, 5)
      ?.map((id) => ({ type: 'asset', id }))
  });

  const finishedRenderDryRun =
    createBatchStatus === statuses.FAILED ||
    createBatchStatus === statuses.SUCCESS;

  /** contains assets succeeded to render + failed with acceptable error during dry_run */
  const downloadableAssetIds = useMemo(() => {
    let downloadableAssetIds: string[] = undefined;
    // should only been set after dry_run is complete
    if (finishedRenderDryRun) {
      const selectedAssetIds = assets?.map((a) => a.id);
      /* contains already rendered assets */
      const succeeded = selectedAssetIds?.filter(
        (id) => !parsedRenderErrors.failedAssetIds?.includes(id)
      );
      /** special case: if download a folder that has unsupported file, it may return render_exists error for some assets
       * we don't want to add this assets here because we will send the folder id directly to create render-batch and downloads
       */
      const passedWithWarning = selectedAssetIds?.filter((id) =>
        parsedRenderWarnings.failedAssetIds?.includes(id)
      );

      downloadableAssetIds = succeeded.concat(passedWithWarning);
    }
    return deduped(downloadableAssetIds);
  }, [
    finishedRenderDryRun,
    assets,
    parsedRenderErrors.failedAssetIds,
    parsedRenderWarnings.failedAssetIds
  ]);

  const { onCreateDownload, waitingForDownload, download, downloadError } =
    useCreateDownload();

  /** 0. dry_run: true, create dry run when hovering iconWarning or click download */
  const onDryRun = useCallback(() => {
    if (!createBatchStatus) {
      onCreateBatch(
        true,
        assets?.map((a) => a.id),
        folders
      );
    }
  }, [assets, createBatchStatus, folders, onCreateBatch]);

  // 1. dry run
  /** has `Download` button been clicked */
  const [clicked, setClicked] = useState(false);
  const onDownload = useCallback(() => {
    onDryRun();
    setClicked(true);
  }, [onDryRun]);

  /** 2. Create render-batch if has available files
   * - only send assets that passed dry run
   * - always send folders since we don't know what items they have
   * - Ignore already rendered assets (do not render again) here
   */
  const isBatchCreated = useRef(false);
  useEffect(() => {
    if (!downloadableAssetIds?.length && !folders?.length) return;

    /** selected assets only, exclude the assets in the folders (because idk what's in the folders) */
    const notRenderedAssets = downloadableAssetIds?.filter(
      (id) => !alreadyRenderedAssetIds?.includes(id)
    );
    /** selected assets only, exclude the assets in the folders (because idk what's in the folders) */
    if (
      finishedRenderDryRun &&
      clicked &&
      !isBatchCreated.current &&
      (folders?.length || notRenderedAssets?.length)
    ) {
      onCreateBatch(false, notRenderedAssets, folders, tempId);
      isBatchCreated.current = true;
    }
  }, [
    alreadyRenderedAssetIds,
    clicked,
    downloadableAssetIds,
    folders,
    finishedRenderDryRun,
    onCreateBatch,
    tempId
  ]);

  // 3. poll batch resource
  useEffect(() => {
    if (
      batchId &&
      (!batchResource ||
        (batchResource.attributes.state !== 'success' &&
          batchResource.attributes.state !== 'failed'))
    ) {
      const timer = setTimeout(() => {
        retrieve();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [batchId, batchResource, retrieve]);

  /** 4. `POST: /downloads`
   * - if there are folders, should always add them to relationships
   * - if all assets have been already rendered, should create download directly instead of waiting for polling to complete
   */
  const isDownloadCreated = useRef(false);
  useEffect(() => {
    /** if all files have been already rendered, should create download immidiately */
    const allFilesAlreadyRendered = folders?.length
      ? false
      : downloadableAssetIds?.every((id) =>
          alreadyRenderedAssetIds?.includes(id)
        );

    if (
      !serverError &&
      clicked &&
      !isDownloadCreated.current && // to make sure only create once
      (downloadableAssetIds?.length || folders?.length) && // folers are always downloadable because we don't know if all files it contains are not available
      (allFilesAlreadyRendered || // all files have been already rendered
        batchResource?.attributes.state === 'success') // waiting for polling to be finished
    ) {
      onCreateDownload(downloadableAssetIds, folders, presetId);
      isDownloadCreated.current = true;
    }
  }, [
    alreadyRenderedAssetIds,
    batchId,
    batchResource,
    clicked,
    downloadableAssetIds,
    folders,
    onCreateDownload,
    presetId,
    serverError
  ]);

  return {
    onDownload,
    onDryRun,
    waitingForDownload,
    downloadResource: download,
    batchResource,
    batchId,
    /** un-acceptable errors */
    errors: {
      codes: parsedRenderErrors.errorCodes,
      assets: assetsNotDownloadable.resources,
      assetsCount: parsedRenderErrors.failedAssetIds?.length
    },
    /** acceptable errors */
    warnings: {
      codes: parsedRenderWarnings.errorCodes,
      assets: assetsWithWarnings.resources,
      assetsCount: parsedRenderWarnings.failedAssetIds?.length
    },
    serverError: serverError || downloadError,
    createBatchStatus,
    retrievingFailedAssets:
      assetsNotDownloadable.loading || assetsWithWarnings.loading
  };
};
