import { EdgeMutation } from '../../../../ducks/resource-edges/actions';

export const optimizeEdgeMutations = mutations => {
  const batches: Map<string, EdgeMutation[]> = mutations.reduce((acc, val) => {
    const key = `${val.ancestor.type}--${val.ancestor.id}--${val.relationshipLabel}--${val.mutationType}`;
    const batch = acc.get(key) || [];
    batch.push(val);
    acc.set(key, batch);
    return acc;
  }, new Map());

  return Array.from(batches.entries()).map(([key, batch]) => {
    const [
      ancestorType,
      ancestorId,
      relationshipLabel,
      mutationType
    ] = key.split('--');
    const config = {
      ancestor: { type: ancestorType, id: ancestorId },
      relationshipLabel,
      mutationType,
      resources: batch.map(m => m.resource),
      invalidatesCache: mergeCacheInvalidators(batch)
    };
    return config;
  });
};

const mergeCacheInvalidators = batch => {
  return batch
    .map(b => b.invalidatesCache)
    .reduce((acc, val) => {
      if (!acc) {
        return val;
      }
      if (!Array.isArray(acc) && Array.isArray(val)) {
        return val;
      }

      if (Array.isArray(acc) && Array.isArray(val)) {
        return acc.concat(val);
      }
      return acc;
    }, undefined);
};
