import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Theme, themes } from './themes';

type ThemeMode = 'dark' | 'light';

interface CubicleThemeContext {
  /** active theme */
  theme: Theme;
  /** whether active theme is darkmode */
  darkMode: boolean;
  /** method to override theme, e.g. via user interaction */
  setOverride: (mode: ThemeMode | null) => void;
  auto: boolean;
}

export const cubicleThemeContext =
  React.createContext<CubicleThemeContext | null>(null);

type ThemeProps = PropsWithChildren<{
  /** select the default theme. Options are 'light', 'dark' or 'auto', where 'auto' uses mediaqueries to decide based on user OS preference  */
  default?: ThemeMode | 'auto';
}>;

export const CubicleThemeProvider: React.FC<ThemeProps> = React.memo(
  (props) => {
    // track OS based theme preference using media queries
    const [osMode, setOsMode] = useState<ThemeMode>(
      window.matchMedia?.('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    );

    // allow developer / user override
    const [override, setOverride] = useState<ThemeMode | null>(
      props.default === 'auto' ? null : props.default
    );

    // watch changes in case OS setting changes (e.g. based on time of day)
    useEffect(() => {
      const target = window.matchMedia?.('(prefers-color-scheme: dark)');

      if (!target) {
        return;
      }

      const handleChange = (e) => {
        const newColorScheme = e.matches ? 'dark' : 'light';
        setOsMode(newColorScheme);
      };
      target.addEventListener('change', handleChange);
      return () => target.removeEventListener('change', handleChange);
    });

    // build theme state
    const value = useMemo(() => {
      const mode = override ? override : osMode;
      return {
        darkMode: mode === 'dark',
        theme: themes[mode],
        auto: !override,
        setOverride: (m) => setOverride(m)
      };
    }, [osMode, override, setOverride]);

    console.info('DEBUG theme', value);

    return (
      <cubicleThemeContext.Provider value={value}>
        {props.children}
      </cubicleThemeContext.Provider>
    );
  }
);

/**
 *
 * @returns current active theme state and helper methods
 */
export const useCubicleTheme = () => {
  return useContext(cubicleThemeContext);
};
