import { serializeParent } from '../../../../ducks/resource-edges/utils';
import { handleJSONApi } from '../../retrieve-middleware';
import { handleCreateBase } from './handleCreateBase';

export const handleCreateJSONApi = ({
  res,
  action,
  config,
  dispatch,
  uploadIds,
  getState
}) => {
  const resource = res.data.primary[0];

  handleCreateBase({
    resource,
    action,
    config,
    dispatch,
    uploadIds,
    getState
  });
  handleJSONApi({ res, action, config, dispatch });
  // dispatch(reqActionCreators.markSuccess(action));

  // check if there's a cache key containing the `included` parameter
  // if so, the cached resource stored using the `included` param key should also be updated when the same resource changes
  // solve the problem of newly created resources not being added to the page when uploading files
  const { resourceType, ancestorType, ancestorId, edgeLabel } = config || {};
  //console.log(res, action, config);
  if (
    // single resource
    resourceType ||
    // list
    (ancestorType && ancestorId && edgeLabel)
  ) {
    const state = getState && getState();
    const cachedResource = getResourceByParent(
      state,
      ancestorType,
      ancestorId,
      resourceType
    );
    const keysHasIncludedParam = Object.keys(cachedResource).filter((k) =>
      k.match(/included=/)
    );
    //console.log(Object.keys(cachedResource), keysHasIncludedParam, config);
    if (keysHasIncludedParam?.length) {
      keysHasIncludedParam.forEach((key) => {
        handleJSONApi({
          res: {
            ...res,
            data: {
              ...res.data,
              edges: res.data.edges.map((e) => {
                if (e.name && key.includes(e.name)) {
                  return { ...e, label: key };
                }
                return e;
              })
            }
          },
          action,
          config,
          dispatch
        });
      });
    }
  }
};

const getResourceByParent = (
  state,
  ancestorType,
  ancestorId,
  resourceType?
) => {
  if (!state) return {};
  const nodes = resourceType ? state.resourceNodes[resourceType] : {};
  const parentKey = serializeParent(ancestorType, ancestorId);
  const edges = state.resourceEdges[parentKey];
  return { ...nodes, ...edges };
};
