import React, { useContext } from 'react';

import { AddMemberComponent } from '@cube3/ui/src/Projects/projectMembers/AddMemberComponent';
import { ProjectTeamAdminsContext } from '../contexts/ProjectAdminsCheckContext';

const AddMemberComponentSmart = ({ canEdit, onClick, loading = false }) => {
  const { allAdminsCount } = useContext(ProjectTeamAdminsContext);

  return (
    <AddMemberComponent
      loading={loading}
      canEdit={canEdit}
      onClick={onClick}
      noAdmins={allAdminsCount === 0}
    />
  );
};

export default AddMemberComponentSmart;
