import React, {
  AllHTMLAttributes,
  HTMLAttributes,
  StyleHTMLAttributes
} from 'react';
import { makeCSS, WithTheme } from '../../../../utils/makeCSS';

const useCSS = makeCSS(
  ({ direction = 'row', align = 'stretch' }: WithTheme<ContainerProps>) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: direction,
      flexWrap: 'nowrap',
      alignItems: align,
      justifyContent: 'space-between'
      // overflow: 'hidden'
    }
  })
);

interface ContainerProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  // flex direction
  direction: 'row' | 'column';
  align?: 'center' | 'stretch';
  // style overrides
  style?: HTMLAttributes<HTMLDivElement>['style'];
}

// Flex container with predefined alignment and justification settings. Add Flex Items components as children
export const Container = (props: ContainerProps) => {
  const { children, style, direction, align, ...divProps } = props;

  const classes = useCSS(props);

  return (
    <div className={classes.root} style={style} {...divProps}>
      {children}
    </div>
  );
};

// Container variant with direction set to column
export const VBox = (props: Omit<ContainerProps, 'direction'>) =>
  Container({ ...props, direction: 'column' });

// Container variant with direction set to row
export const HBox = (props: Omit<ContainerProps, 'direction'>) =>
  Container({ ...props, direction: 'row' });
