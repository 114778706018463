import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { environment } from '@cube3/state/src/utils/environment';
import Desk from '@cube3/ui/src/desk';
import { ShortCutsProvider } from '@cube3/ui/src/keyboard-shortcuts/ShortCutsProvider';
import { ModalContainerProvider } from '@cube3/ui/src/Modal/ModalManagerUI';
import { DevConsole } from './components/app/debug/views/DevConsole';
import { ErrorBoundary } from './components/helpers/ErrorBoundary';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { CookieConsent } from './utils/CookieConsent';
import { OfflineNotification } from './utils/OfflineNotification';
import { DocumentTitle } from './utils/title/Title';
import {
  useVersionChecker,
  VersionNotification
} from './utils/VersionsChecker';
import { ShortCutsRootProvider } from '@cube3/ui/src/keyboard-shortcuts/ShortCutsRootProvider';
import { ModalCookieContextProvider } from './components/app/layout/Modals/ModalCookieContextProvider';
import ModalManager from './components/app/layout/Modals/ModalManager';
import { Analytics } from './utils/Analytics';
import { createBodyPortal } from './utils/createBodyPortal';
import { matchPath, useLocation } from 'react-router-dom';
import { urlStructureFigmaRoot } from './components/app/integrations/figma/urlStructureFigma';
import { Tour, TourProvider } from './components/app/layout/Tour/Tour';

const PrimaryRoutes = React.lazy(() => {
  return import('./components/app/routing/primary-routes');
});

const App: React.FC<{}> = (props) => {
  const [loading, setLoading] = useState(false);
  const [log, setLog] = useState<string | null>(null);

  const addLog = useCallback(
    (line: string) => {
      setLog((current) =>
        [current || []].concat(JSON.stringify(line)).join('\n')
      );
      return line;
    },
    [setLog]
  );

  useEffect(() => {
    // add promises here
    const steps: Promise<any>[] = [];

    if (steps.length) {
      setLoading(true);
      setLog('loading app..');

      Promise.all(steps)
        .then((res) =>
          setTimeout(() => {
            setLoading(false);
            setLog(null);
          }, 5000)
        )
        .catch((err) => {
          addLog(err);
        })
        .then((pass) => {
          setLoading(false);
        });
    }
  }, [addLog, setLoading, setLog]);

  const { show, snooze } = useVersionChecker({
    interval: environment !== 'production' ? 10 * 1000 : 30 * 60 * 1000
  });

  const offline = !useTypedSelector((state) => state.offlineDetection.online);

  useEffect(() => {
    if (offline) {
      const handleClick = (ev: MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
      };
      window.addEventListener('click', handleClick, true);
      window.addEventListener('mouseover', handleClick, true);
      return () => {
        window.removeEventListener('click', handleClick, true);
        window.removeEventListener('mouseover', handleClick, true);
      };
    }
  }, [offline]);

  const [deskRef, setDeskRef] = useState(null);

  const isFigma = !!matchPath(useLocation().pathname, urlStructureFigmaRoot);

  return (
    <TourProvider>
      <ModalContainerProvider>
        <ShortCutsRootProvider>
          <ShortCutsProvider name="app" boundaryEl={deskRef}>
            <Tour />
            <Desk
              ref={setDeskRef}
              offline={offline}
              showVersionBanner={show || offline}
              cookieConsentComponent={!isFigma && <CookieConsent />}
              appNotificationComponent={
                <>
                  {loading && 'loading...'}
                  {offline && <OfflineNotification />}
                  {show && !offline && (
                    <VersionNotification onSnooze={snooze} />
                  )}
                </>
              }
            >
              <ErrorBoundary name="app">
                {log && (
                  <pre
                    style={{
                      background: 'black',
                      fontFamily: 'monospace',
                      color: 'green',
                      fontSize: '1.6em',
                      lineHeight: '140%'
                    }}
                  >
                    {log}
                  </pre>
                )}
                <DocumentTitle />

                <ModalCookieContextProvider>
                  <Suspense fallback={null}>
                    <PrimaryRoutes />
                  </Suspense>
                  {/* <ModalsContainer /> */}
                  <ModalManager />
                </ModalCookieContextProvider>
                <Analytics />
                {/* <DevTools /> */}
                {environment !== 'production' &&
                  createBodyPortal(<DevConsole />, 'dev-console', null, 9999)}
              </ErrorBoundary>
            </Desk>
          </ShortCutsProvider>
        </ShortCutsRootProvider>
      </ModalContainerProvider>
    </TourProvider>
  );
};
export default App;
