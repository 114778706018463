import React, { useEffect, useState } from 'react';
import { renderWaveform } from './utils/renderWaveform';

const DEFAULT_HEIGHT = 64;

export type Samples = number[];

interface WaveformProps {
  /** sample data from the api */
  samples: Samples;
  /** how many samples to merge per line */
  merge?: number;
  /** normalized playback time (0.0 - 1.0 range) */
  playPos?: number;
  /** number of vertical pixels per line */
  height?: number;
}
/** renders sample data onto to two canvases and uses playback time to reveal either */
export const Waveform = (props: WaveformProps) => {
  const { samples, merge = 1, playPos = 0, height = DEFAULT_HEIGHT } = props;

  // track two canvas refs
  // - one for what has been played
  // - one for what has not yet been played
  const [playedCanvas, setPlayedCanvas] = useState<HTMLCanvasElement>();
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  // track whether the first render has finished and we can show something
  const [ready, setReady] = useState(false);

  // compare the width of the canvas relative to the amount samples
  const scale =
    canvas?.getBoundingClientRect().width / (samples?.length / merge) || 1;

  // render the sample data to the two canvases
  useEffect(() => {
    if (!playedCanvas || !canvas || !samples) {
      return;
    }

    // Played canvas
    playedCanvas.width = Math.round(samples.length / merge);
    playedCanvas.height = height;
    const playedCtx = playedCanvas.getContext('2d');
    playedCtx.scale(scale, scale);

    // Unplayed canvas
    canvas.width = Math.round(samples.length / merge);
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.scale(scale, scale);

    // render data in a deffered way to prevent some ui lock
    requestAnimationFrame(() => {
      renderWaveform(playedCtx, samples, merge, false, height);
      renderWaveform(ctx, samples, merge, true, height);
      setReady(true);
    });
  }, [canvas, samples, scale, height]);

  return (
    <>
      <canvas
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          clipPath: `polygon(0% 0%, ${playPos * 100}% 0%, ${
            playPos * 100
          }% 100%, 0% 100%)`,
          opacity: ready ? 1 : 0,
          transition: 'opacity 0.35s ease'
        }}
        ref={setPlayedCanvas}
      />
      <canvas
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          clipPath: `polygon(${playPos * 100}% 0%, 100% 0%, 100% 100%, ${
            playPos * 100
          }% 100%)`,
          opacity: ready ? 1 : 0,
          transition: 'opacity 0.35s ease'
        }}
        ref={setCanvas}
      />
    </>
  );
};
