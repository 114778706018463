
import { ReactComponent as Compose } from './collection/Compose.svg';
import { ReactComponent as Cube } from './collection/Cube.svg';
import { ReactComponent as Figma } from './collection/Figma.svg';
import { ReactComponent as File } from './collection/File.svg';
import { ReactComponent as Folder } from './collection/Folder.svg';
import { ReactComponent as Font } from './collection/Font.svg';
import { ReactComponent as Google_Docs } from './collection/Google Docs.svg';
import { ReactComponent as Google_Forms } from './collection/Google Forms.svg';
import { ReactComponent as Google_Sheets } from './collection/Google Sheets.svg';
import { ReactComponent as Google_Slides } from './collection/Google Slides.svg';
import { ReactComponent as Image } from './collection/Image.svg';
import { ReactComponent as Markdown } from './collection/Markdown.svg';
import { ReactComponent as Microsoft_Excel } from './collection/Microsoft Excel.svg';
import { ReactComponent as Microsoft_Powerpoint } from './collection/Microsoft Powerpoint.svg';
import { ReactComponent as Microsoft_Word } from './collection/Microsoft Word.svg';
import { ReactComponent as Music } from './collection/Music.svg';
import { ReactComponent as URL } from './collection/URL.svg';

export const fileIcons = {
  compose: Compose,
  cube: Cube,
  figma: Figma,
  file: File,
  folder: Folder,
  font: Font,
  google_Docs: Google_Docs,
  google_Forms: Google_Forms,
  google_Sheets: Google_Sheets,
  google_Slides: Google_Slides,
  image: Image,
  markdown: Markdown,
  microsoft_Excel: Microsoft_Excel,
  microsoft_Powerpoint: Microsoft_Powerpoint,
  microsoft_Word: Microsoft_Word,
  music: Music,
  uRL: URL,
} as const;