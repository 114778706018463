import React from 'react';
import CheckBoxStateless from '../../../../Buttons/CheckBoxStateless';

export const LinkSettingsCheckbox = (props) => {
  const { input, className, disabled, label } = props;

  return (
    <CheckBoxStateless
      marginCollapse={'left'}
      classes={{ root: className }}
      checked={!!input.checked}
      text={label}
      typographyProps={{
        typographyStyle: 'body2',
        color: 'contrast1'
      }}
      disabled={disabled}
      onChange={(checked) => input.onChange(checked)}
    />
  );
};
