import { Uploader } from '../../../wrapped-cube-client';
import { selectors__unoptimized as selectors } from '../../ducks/resource-edges';
import { actionCreators as nodeActionCreators } from '../../ducks/resource-nodes';
import { selectors as uploadSelectors } from '../../ducks/uploads';

export const handleAssetDelete = ({ resourceType, resourceId, getState }) => {
  const state = getState();
  const uploads = uploadSelectors.getUploadsByWorkspace(
    state,
    state.session.workspace
  );

  uploads?.forEach(u => {
    if (
      resourceId === u.asset_id ||
      (u.tusUrlStorage?.metadata?.targetResourceType === resourceType &&
        u.tusUrlStorage?.metadata?.targetResourceId === resourceId)
    ) {
      const { tusUpload } = u;
      Uploader.cancelUpload(u).then(r => {
        if (tusUpload) {
          Uploader.remove(tusUpload);
        }
      });
    }
  });
};

export const handleFolderDelete = ({ resourceId, getState, dispatch }) => {
  const state = getState();

  const uploads = uploadSelectors.getUploadsByWorkspace(
    state,
    state.session.workspace
  );

  uploads?.forEach(u => {
    if (u.asset_id && u.tusUpload && u.progress < 100) {
      dispatch(nodeActionCreators.retrieveResource('asset', u.asset_id));
    }

    // NOTE: we have these, because starting an upload opens the upload-queue panel
    // this triggers retrieving the ancestors, but is a bit brittle
    const ancestors = selectors.getResourceEdgesByLabel(
      state,
      u.asset_id,
      'asset',
      'ancestors',
      'folder'
    );
    const inAncestors = ancestors?.find(a => a.id === resourceId);
    if (inAncestors) {
      const { tusUpload } = u;
      Uploader.cancelUpload(u);
      if (tusUpload) {
        Uploader.remove(tusUpload);
      }
    }
  });
};
