import { actionCreators as nodeActionCreators } from '../../../ducks/resource-nodes';
import { actions as nodeActions } from '../../../ducks/resource-nodes';
import { actionCreators as reqActionCreators } from '../../../ducks/request-status';

import Client, {
  APIResource,
  legacyApiDataKeys as keys
} from '../../../../wrapped-cube-client';

import { copyFormId, formStates } from '../../redux-form-middleware';

import { handleFilesPreCreate, updateUpload } from '../../uploads-middleware';
import { uuidv4 } from '../../../../utils/uuid';
import { delegateRequestStatusHash } from '../../request-status-middleware';
import { invalidateCache } from '../mutate-middleware';

import { handleCreateLegacy } from './utils/handleCreateLegacy';
import { handleCreateJSONApi } from './utils/handleCreateJSONApi';
import { handleCreateByAncestorLegacy } from './utils/handleCreateByAncestorLegacy';

const createResource = ({ config, action, dispatch, getState }) => {
  const { uploadIds } = handleFilesPreCreate(dispatch, getState)(config);

  return Client.post(config.resourceType, {
    ...config.data,
    type: config.resourceType
  })
    .then((res: APIResource<any>) => {
      if (!res || !res.data) {
        console.warn(
          'Received empty resource creation response ... this may cause unexpected behaviour'
        );
        dispatch(reqActionCreators.markSuccess(action));
        return;
      }
      let prom;
      if (res[keys.LEGACY_API_RESOURCE]) {
        prom = Promise.resolve({
          res,
          action,
          config,
          dispatch,
          uploadIds
        }).then(handleCreateLegacy);
      } else {
        prom = Promise.resolve({
          res,
          action,
          config,
          dispatch,
          uploadIds
        }).then(handleCreateJSONApi);
      }
      if (config.invalidatesCache) {
        prom.then(() => {
          invalidateCache({
            cacheInvalidator: config.invalidatesCache,
            config,
            getState,
            dispatch
          });
        });
      }
      return prom;
    })
    .catch(err => {
      dispatch(
        copyFormId(action)(
          reqActionCreators.markFailed(action, err),
          formStates.FAILED
        )
      );

      if (config.files) {
        config.files.forEach((f, idx) => {
          const id = uploadIds[idx];
          updateUpload(
            id,
            {
              id: id,
              progress: 0,
              failed: true,
              file: f,
              display_name: f.name,
              tusUpload: null,
              active: false,
              asset_id: null
            },
            dispatch,
            getState
          );
        });
      }
    });
};

const createResourceByAncestor = ({ config, action, dispatch, getState }) => {
  const { uploadIds } = handleFilesPreCreate(dispatch, getState)(config);
  return Client.post(
    config.resourceType,
    {
      ...config.data,
      type: config.resourceType
    },
    {
      id: config.ancestorId,
      type: config.ancestorType,
      field: config.edgeLabel
    }
  )
    .then((res: APIResource<any>) => {
      let prom;
      if (res[keys.LEGACY_API_RESOURCE]) {
        prom = Promise.resolve({
          res,
          action,
          config,
          dispatch,
          uploadIds,
          getState
        }).then(handleCreateByAncestorLegacy);
      } else {
        prom = Promise.resolve({
          res,
          action,
          config,
          dispatch,
          uploadIds,
          getState
        }).then(handleCreateJSONApi);
      }
      if (config.invalidatesCache) {
        prom.then(() => {
          invalidateCache({
            cacheInvalidator: config.invalidatesCache,
            config,
            getState,
            dispatch
          });
        });
      }
      return prom;
    })
    .catch(err => {
      dispatch(
        copyFormId(action)(
          reqActionCreators.markFailed(action, err),
          formStates.FAILED
        )
      );

      if (config.files) {
        config.files.forEach((f, idx) => {
          const id = uploadIds[idx];
          updateUpload(
            id,
            {
              progress: 0,
              failed: true,
              file: f,
              display_name: f.name,
              tusUpload: null,
              active: false,
              asset_id: null
            },
            dispatch,
            getState
          );
        });
      }
      // return Promise.reject(err);
    });
};

export const createCreateMiddleware = ({ getState, dispatch }) => {
  return next => action => {
    const { meta, type } = action;

    if (!meta || !meta.apiClient) {
      return next(action);
    } else {
      const config = meta.apiClient;

      switch (type) {
        case nodeActions.CREATE_RESOURCE:
          // TODO: add uploads to single resource
          // though it is unused right now
          dispatch(reqActionCreators.markInFlight(action));
          createResource({ config, action, dispatch, getState });
          break;

        case nodeActions.CREATE_RESOURCE_BY_ANCESTOR:
          dispatch(reqActionCreators.markInFlight(action));
          createResourceByAncestor({ config, action, dispatch, getState });
          break;

        case nodeActions.CREATE_MANY_BY_ANCESTOR:
          dispatch(
            reqActionCreators.markInFlightByHash(
              action.meta?.requestStatus?.hash
            )
          );
          config.resources.map(resource => {
            dispatch(
              delegateRequestStatusHash(action)(
                copyFormId(action)(
                  nodeActionCreators.createResourceByAncestor(
                    config.resourceType,
                    resource.temporaryId || uuidv4(),
                    resource.data,
                    config.ancestorType,
                    config.ancestorId,
                    config.edgeLabel,
                    resource.files,
                    resource.invalidatesCache
                  )
                )
              )
            );
            return null;
          });
          break;

        case nodeActions.CREATE_MANY:
          dispatch(
            reqActionCreators.markInFlightByHash(
              action.meta?.requestStatus?.hash
            )
          );
          config.resources.map(resource => {
            dispatch(
              delegateRequestStatusHash(action)(
                copyFormId(action)(
                  nodeActionCreators.createResource(
                    config.resourceType,
                    resource.temporaryId || uuidv4(),
                    resource.data,
                    resource.files,
                    resource.invalidatesCache
                  )
                )
              )
            );
            return null;
          });
          break;

        default:
          break;
      }
      return next(action);
    }
  };
};
