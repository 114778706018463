import { Related } from '../../resource-types';
import { IntegrationType, VideoType } from '../../types';
import { RawModel } from '../schema';

export interface ExportTargetType {
  id: string;
  type: 'export-target-type';
  attributes: {
    display_name: string;
    tagline?: string;
    description?: string;
    display_image: string;
    service: 'Google' | 'Meta' | 'BROADCAST';
    begin_url: string | null;
    integration_types: IntegrationType[];
    supported_asset_types: VideoType[];
  };
  relationships: {
    workspace: Related<'workspace'>;
    provider: Related<'oauth2-provider'>;
  };
}

export const exportTargetType: RawModel = {
  type: 'export-target-type',
  plural: 'export-target-types',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['many', 'many'],
      reverse: 'export-target-type'
    },
    provider: {
      type: 'oauth2-provider',
      binding: ['one', 'many'],
      reverse: 'export-target-types'
    }
  }
};
