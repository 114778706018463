import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

const renderLoadingDefault = idx => {
  return 'loading';
};
const renderItemDefault = (item, idx) => {
  return item?.display_name || 'item';
};

// const pageRatioPrev = { current: { fromTop: 1, fromBottom: 0 } };
// const pageRatioNext = { current: { fromTop: 0, fromBottom: 1 } };

export const VirtualPagesPage = forwardRef<HTMLDivElement, any>(
  (
    {
      page,
      pageSize,
      rowSize,
      itemSize,
      height,
      renderLoading = renderLoadingDefault,
      renderItem = renderItemDefault,
      pageRatio,
      setSizeNeeded,
      pageRole,
      lastItem,
      allowPartialRender = false,
      debug
    },
    ref
  ) => {
    const { resourceList } = page || {};
    const pageNumber = page?.pageNumber !== undefined ? page.pageNumber : '?';
    const focusPage = pageRole === 'focus';

    const innerRef = useRef<HTMLDivElement>(null);

    const [percent, setPercent] = useState(0);

    const lastVerify = useRef(0);

    const updateItems = useCallback(() => {
      if (Date.now() - lastVerify.current > 100) {
        if (pageRole === 'next') {
          setPercent(
            1 - Math.max(Math.min(1, -1 * pageRatio.current.fromBottom), 0)
          );
        } else if (pageRole === 'previous') {
          setPercent(Math.max(Math.min(1, -1 * pageRatio.current.fromTop), 0));
        }
        lastVerify.current = Date.now();
      }
    }, [setPercent, pageRatio, pageRole]);

    const handleScroll = useCallback(() => {
      if (innerRef.current) {
        const itemCount = resourceList?.length || pageSize;
        const isEndPage = itemCount < pageSize;
        const isNegativePage = page?.pageNumber <= 0;

        const isBeforeFocus = pageRole === 'previous';

        const sizeNeeded = innerRef.current.offsetHeight;
        const difference = height - innerRef.current.offsetHeight;
        let offset;

        if (isEndPage) {
          // set main scrolling area limits
          if (isNegativePage) {
            // adjust top
            setSizeNeeded({ negative: sizeNeeded });
          } else {
            // adjust bottom
            setSizeNeeded({ positive: sizeNeeded });
          }
        }

        if (!focusPage) {
          if (isBeforeFocus) {
            // always align to bottom
            offset = difference;
          } else {
            // always align to top
            offset = 0;
          }
          updateItems();
        } else {
          let offsetRatio;
          if (sizeNeeded > height) {
            offsetRatio = Math.max(0, Math.min(1, pageRatio.current.fromTop));
            offset = offsetRatio * difference;
          } else {
            if (isNegativePage) {
              offset = difference;
            } else {
              offset = 0;
            }
          }
        }

        innerRef.current.style.transform = `translate(0, ${offset}px)`;
      }
    }, [
      pageRatio,
      innerRef,
      resourceList,
      page,
      focusPage,
      height,
      pageRole,
      pageSize,
      setSizeNeeded,
      updateItems
    ]);

    useEffect(() => {
      window.addEventListener('scroll', handleScroll, true);
      return () => window.removeEventListener('scroll', handleScroll, true);
    }, [handleScroll]);

    const lastEffect = useRef(0);

    useLayoutEffect(() => {
      if (
        resourceList &&
        innerRef.current &&
        lastEffect.current - Date.now() > 100
      ) {
        lastEffect.current = Date.now();
        handleScroll();
      }
    }, [resourceList, handleScroll, percent]);

    const handleInnerRef = useCallback(
      node => {
        innerRef.current = node;
        if (node) {
          handleScroll();
        }
      },
      [handleScroll]
    );

    const itemCount = resourceList?.length || pageSize;
    const isEndPage = itemCount < pageSize;

    const sliceEnd =
      pageRole === 'focus' || isEndPage
        ? pageSize
        : Math.round(percent * pageSize);

    return (
      <div
        ref={ref}
        style={{
          color: 'white',

          overflow: 'hidden',
          height,
          background: !debug ? undefined : focusPage ? '#588' : '#883'
        }}
      >
        <div
          ref={handleInnerRef}
          style={{
            position: 'relative',
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-evenly',
            background: !debug ? undefined : focusPage ? '377' : '#772'
          }}
        >
          {debug && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                color: 'white',
                fontSize: '200%',
                zIndex: 1000,
                textShadow: '2px 2px 2px black'
              }}
            >
              {pageNumber} - {pageRatio.current.fromTop} - {pageRole}
              {pageRatio.current.fromBottom} - {percent}
            </div>
          )}
          {pageSize &&
            (!page || !resourceList) &&
            [...Array(pageSize)].map((f, idx) => (
              <div
                key={`item-${idx}`}
                style={{
                  display: 'block',
                  height: itemSize.height,
                  width: itemSize.width ? itemSize.width : 'calc(100%)'
                }}
              >
                {renderLoading(idx)}
              </div>
            ))}

          {/* {page?.pageNumber <= 0 &&
            resourceList &&
            [...Array(pageSize)].map((f, idx) => (
              <div
                key={`placeholder-${idx}`}
                style={{
                  display:
                    idx < Math.max(0, pageSize - resourceList.length)
                      ? 'block'
                      : 'none',
                  height: itemSize.height,
                  width: itemSize.width ? itemSize.width : 'calc(100%)'
                }}
              ></div>
            ))} */}

          {page?.pageNumber <= 0 &&
            (resourceList || [...Array(pageSize)])
              .concat([])
              .reverse()
              .map((r, idx, arr) => {
                const should =
                  !allowPartialRender ||
                  (pageRole === 'previous'
                    ? idx >= pageSize - sliceEnd
                    : idx < sliceEnd);

                return (
                  <div
                    key={`item-${idx}`}
                    style={{
                      background: !debug
                        ? undefined
                        : idx % 2
                        ? 'red'
                        : 'crimson',
                      // height: itemSize.height,
                      minHeight: itemSize.height * 1,
                      width: itemSize.width ? itemSize.width : 'calc(100%)',
                      flexGrow: 0
                    }}
                  >
                    {should &&
                      (!r
                        ? renderLoading(idx)
                        : renderItem(
                            r,
                            idx,
                            idx === 0 ? lastItem : arr[idx - 1]
                          ))}
                  </div>
                );
              })}

          {page?.pageNumber > 0 &&
            (resourceList || [...Array(pageSize)]).map((r, idx, arr) => {
              const should =
                !allowPartialRender ||
                (pageRole === 'previous'
                  ? idx - sliceEnd >= 0
                  : idx < sliceEnd);

              return (
                <div
                  key={`item-${idx}`}
                  style={{
                    background: !debug ? undefined : idx % 2 ? 'blue' : 'navy',
                    // height: itemSize.height,
                    minHeight: itemSize.height * 1,
                    width: itemSize.width ? itemSize.width : 'calc(100%)',
                    flexGrow: 0
                  }}
                >
                  {!should &&
                    `${pageRole} ${idx} ${sliceEnd} ${pageSize -
                      sliceEnd}  ${percent}`}
                  {should &&
                    (!r
                      ? renderLoading(idx)
                      : renderItem(
                          r,
                          idx,
                          idx === 0 ? lastItem : arr[idx - 1]
                        ))}
                </div>
              );
            })}
          {/* {page?.pageNumber > 0 &&
            resourceList &&
            [...Array(pageSize)].map((f, idx) => (
              <div
                key={`placeholder-${idx}`}
                style={{
                  display:
                    idx < Math.max(0, pageSize - resourceList.length)
                      ? 'block'
                      : 'none',

                  height: itemSize.height,
                  width: itemSize.width ? itemSize.width : 'calc(100%)'
                }}
              ></div>
            ))} */}
        </div>
      </div>
    );
  }
);
