import React, { useState, useContext, useEffect } from 'react';
import PanZoom from '../ImagePreview/PanZoom';
import DocumentPreviewControls from './DocumentPreviewControls';
import Image from '../../images/Image';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import DetailviewFullscreenControls from '../DetailviewFullscreenControls';
import { PreviewHOCProps } from '../PreviewWrapper';
import { isMobile } from 'react-device-detect';
import Scrollbar, {
  ScrollPositionContext,
  ScrollParentContext
} from '../../Scrollbar/Scrollbar';
import DocumentPreviewMobile from './DocumentPreviewMobile';
import { useObserverHook } from './DocumentPreviewHelpers';
import { PanZoomProvider } from '../ImagePreview/PanZoomContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    containerImage: {
      position: 'relative',
      height: 0,
      overflow: 'hidden',
      marginBottom: '2px'
    },
    innerContainerImage: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0
    },
    image: {
      background: theme.customPalette.backgroundAccent.whiteAccent2,
      '& > img': {
        maxHeight: 'unset !important'
      }
    }
  })
);

interface DocumentPreviewProps extends PreviewHOCProps {
  id: string;
  asset_variant: string;
  name: string;
  display_name: string;
  pages: { url: string; width: number; height: number }[];
  thumbnail?: string;
  renderDetailviewFullscreenControls: () => void;
  gotoNextSibling?: () => void;
  gotoPrevSibling?: () => void;
  siblings: boolean;
  dimensions?: {
    width: number;
    height: number;
  };
  visiblePages;
}

export function DocumentPreview(props: DocumentPreviewProps) {
  let [current, setCurrent] = useState(1);
  const [autoScrollTo, setAutoScrollTo] = useState(false);
  const [amountOfPagesToRender, setAmountOfPagesToRender] = useState(2);

  const [
    containerRef,
    mainContainerRef,
    itemEl,
    addToItemsRefs,
    containerWidth
  ]: any = useObserverHook();

  const classes = useStyles(props);
  let { dimensions, visiblePages = 3 } = props;

  const {
    pages = [],
    thumbnail,
    toggleFullscreenAction,
    fullscreen,
    gotoNextSibling,
    gotoPrevSibling,
    display_name,
    siblings
  } = props;

  function goToPage(event) {
    let page;
    if (typeof event === 'number') {
      page = event;
    } else {
      let pageNumber = event.target.value;
      pageNumber = pageNumber.replace(/\D/g, '');
      if (pageNumber === '') {
        return null;
      }
      page = parseInt(pageNumber, 10);
    }

    if (page < 1 || page > props.pages.length) {
      page = current;
    }
    setCurrent(page);
    setAutoScrollTo(true);
  }

  if (pages.length === 0) {
    current = 0;
  }

  if (pages[current - 1]) {
    dimensions = {
      width: pages[current - 1].width,
      height: pages[current - 1].height
    };
  }

  // prevent issue with scrollbar rendering
  // TODO: figure out what's actually going on
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const t = setTimeout(() => setReady(true), 500);
    return () => clearTimeout(t);
  }, []);

  const paddingTop = (pages[0].height / pages[0].width) * 100;

  const ratio = containerWidth / pages[0].width;

  // get corrected height based on ratio
  const pageHeight = pages[0].height * ratio;

  if (isMobile) {
    return (
      <DocumentPreviewMobile
        fullscreen={fullscreen}
        display_name={display_name}
        thumbnail={thumbnail}
        pages={pages}
      />
    );
  }

  return (
    <div className={classes.container} ref={mainContainerRef}>
      <PanZoomProvider useWheel="x" key={fullscreen ? 'fullscreen' : 'regular'}>
        <PanZoom>
          <div
            ref={containerRef}
            style={{
              ...dimensions,
              position: 'relative',
              overflow: 'hidden',
              boxSizing: 'border-box'
            }}
          >
            {ready && (
              <Scrollbar rememberScroll={true} setScrollingBoolean={true}>
                <ScrollPositionContext.Consumer>
                  {({ scrollPosition, scrolling }) => {
                    return (
                      <>
                        <PageLoader
                          scrollPosition={scrollPosition}
                          pageHeight={pageHeight}
                          preloadPages={visiblePages}
                          amountOfPagesToRender={amountOfPagesToRender}
                          setAmountOfPagesToRender={setAmountOfPagesToRender}
                        />
                        {pages.map((page, index) => {
                          // get padding top to get correct height

                          const inView =
                            Math.abs(scrollPosition / pageHeight - index) <
                            visiblePages;

                          return (
                            <div
                              ref={addToItemsRefs}
                              key={page.url}
                              data-key={page.url}
                            >
                              {amountOfPagesToRender > index ? (
                                <div
                                  className={classes.containerImage}
                                  style={{
                                    paddingTop: `${paddingTop}%`
                                  }}
                                >
                                  <div className={classes.innerContainerImage}>
                                    {inView && (
                                      <Image
                                        dimensions={{
                                          width: page.width,
                                          height: page.height
                                        }}
                                        imagePath={page.url}
                                        background="checkerboard"
                                        placeholder={
                                          index === 1 ? thumbnail : undefined
                                        }
                                        fullSize={true}
                                        floatingEffect={false}
                                        className={classes.image}
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`${classes.containerImage} placeholder-div-pdf`}
                                  style={{
                                    paddingTop: `${paddingTop}%`
                                  }}
                                >
                                  <div
                                    className={classes.innerContainerImage}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}

                        <ScrollToComponent
                          autoScrollTo={autoScrollTo}
                          setAutoScrollTo={setAutoScrollTo}
                          pageId={pages[current - 1] && pages[current - 1].url}
                          itemEl={itemEl}
                          currentPage={current}
                          scrollPosition={scrollPosition}
                          setCurrent={setCurrent}
                          amountOfPages={pages.length}
                          scrolling={scrolling}
                        />
                      </>
                    );
                  }}
                </ScrollPositionContext.Consumer>
              </Scrollbar>
            )}
          </div>
        </PanZoom>

        {mainContainerRef.current && (
          <DocumentPreviewControls
            mainContainerRef={mainContainerRef.current}
            // zoom={zoom}
            // minZoom={initialZoomLevel}
            //maxZoom={3}
            // zoomIn={zoomIn}
            //zoomOut={zoomOut}
            current={current}
            totalPages={pages.length}
            toggleFullscreenAction={toggleFullscreenAction}
            fullscreen={fullscreen}
            goToPage={goToPage}
            next={() => {}}
            previous={() => {}}
            renderFullscreenControls={() => (
              <DetailviewFullscreenControls
                gotoNextSibling={gotoNextSibling}
                gotoPrevSibling={gotoPrevSibling}
                siblings={siblings}
                assetTitle={display_name}
              />
            )}
          />
        )}
      </PanZoomProvider>
    </div>
  );
}

// moved into component to prevent state update errors
const PageLoader = ({
  scrollPosition,
  pageHeight,
  preloadPages = 3,
  amountOfPagesToRender,
  setAmountOfPagesToRender
}) => {
  useEffect(() => {
    // get amount of pages when scrolled
    const addPagesBasedOnScroll = Math.ceil(
      (scrollPosition + pageHeight * preloadPages) / pageHeight
    );

    // if pages based on scroll are larger set new amount of pages to render
    addPagesBasedOnScroll > amountOfPagesToRender &&
      setAmountOfPagesToRender(addPagesBasedOnScroll);
  }, [
    scrollPosition,
    pageHeight,
    preloadPages,
    amountOfPagesToRender,
    setAmountOfPagesToRender
  ]);

  return null;
};

const ScrollToComponent = (props) => {
  const {
    autoScrollTo,
    setAutoScrollTo,
    pageId,
    itemEl,
    amountOfPages,
    setCurrent,
    currentPage,
    scrollPosition,
    scrolling
  } = props;
  const scrollParents = useContext(ScrollParentContext);
  const scrollParent = scrollParents[0];
  const scrollParentView = scrollParent && scrollParent.view;
  const totalHeight = scrollParent && scrollParent.view.scrollHeight;
  const pageNumber =
    Math.round((scrollPosition / totalHeight) * amountOfPages) + 1;

  /*
      Update page number when scrolling 
    */
  if (!autoScrollTo && scrolling && pageNumber && pageNumber !== currentPage) {
    setCurrent(pageNumber);
  }

  /*
      Scroll to specific PDF
    */
  if (scrollParentView && autoScrollTo) {
    const el = itemEl.current[pageId];
    scrollParentView.scrollTo({
      top: el.offsetTop,
      left: 0
      // behavior: 'smooth'
    });
    setAutoScrollTo(false);
  }

  return null;
};
