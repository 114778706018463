import React from 'react';
import { ModalCookieContext } from '@cube3/ui/src/Modal/ModalCookieContext';
import { selectors } from '@cube3/state/src/redux/ducks/cookie-consent';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';

interface Properties { }

/** Acts as a provider for modals in order to prevent prop drilling. Modals like `ModalNoticeUI` look at this context to see whether they should resize based on if the Cookie Banner is active */
export const ModalCookieContextProvider: React.FC<React.PropsWithChildren<Properties>> = props => {

    const hasChosen = useTypedSelector((state) => selectors.hasChosen(state))

    return (
        <ModalCookieContext.Provider value={{ hasConsented: hasChosen }}>
            {props.children}
        </ModalCookieContext.Provider>
    );
}
