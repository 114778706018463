import React from 'react';

import { Subtract } from '../../utils/typescript/subtract';
import {
  SelectionActions,
  SelectionData,
  useSelection,
  useSelectionActions
} from './useSelection';

export interface WrappedComponentProps extends SelectionActions {
  selection?: SelectionData;
}

export function withSelection<P extends WrappedComponentProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Subtract<P, WrappedComponentProps>> {
  const Wrapper: React.ComponentType<Subtract<
    P,
    WrappedComponentProps
  >> = function(props) {
    const selection = useSelection(true);
    const selectionActions = useSelectionActions();

    const allProps = {
      selection,
      ...selectionActions,
      ...props
    } as P;

    return <WrappedComponent {...allProps} />;
  };
  return Wrapper;
}

export const withInferedSelection = withSelection;
