import { useMemo } from 'react';

export const useScrollbarDimensions = ({ width, height }) => {
  return useMemo(() => {
    if (width || height) {
      const d: { width?: number | string; height?: number | string } = {};
      d.width =
        typeof width === 'number' || typeof width === 'string'
          ? width
          : undefined;
      d.height =
        typeof height === 'number' || typeof height === 'string'
          ? height
          : undefined;
      return d;
    }
  }, [width, height]);
};
