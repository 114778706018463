import { useState, useEffect, useCallback } from 'react';

function UseHover(): [
  { (node?: HTMLElement): void; current?: HTMLElement },
  boolean
] {
  const [hovered, setHovered] = useState(false);
  const [node, setNode] = useState<HTMLElement>(null);

  const onRef = useCallback(
    node => {
      setNode(node);
      if (!node) {
        setHovered(false);
      }
    },
    [setNode, setHovered]
  ) as { (node?: HTMLElement): void; current?: HTMLElement };

  onRef.current = node;

  const handleMouseOver = useCallback(() => setHovered(true), [setHovered]);
  const handleMouseOut = useCallback(() => setHovered(false), [setHovered]);
  useEffect(() => {
    if (node) {
      if (hovered) {
        node.addEventListener('mouseout', handleMouseOut);
      } else {
        node.addEventListener('mouseover', handleMouseOver);
      }

      return () => {
        if (hovered) {
          node.removeEventListener('mouseout', handleMouseOut);
        } else {
          node.removeEventListener('mouseover', handleMouseOver);
        }
      };
    }
  }, [node, hovered, handleMouseOut, handleMouseOver, setHovered]);

  return [onRef, hovered];
}

export default UseHover;
