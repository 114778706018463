import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toggleFullscreen } from '@cube3/state/src/redux/ducks/ui';

import { previewWrapper as unConnectedPreviewWrapper } from '@cube3/ui/src/previews/PreviewWrapper';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';

function previewWrapper<P extends {}>(
  WrappedComponent: React.ComponentType<P>
) {
  const FullscreenWrapped = unConnectedPreviewWrapper(WrappedComponent);

  return props => {
    const fullscreen = useTypedSelector(state => {
      return state.ui.fullscreen;
    });
    const fullscreenRequested = useTypedSelector(state => {
      return state.ui.fullscreenRequested;
    });

    const dispatch = useDispatch();

    const toggleFullscreenAction = useCallback(() => {
      dispatch(toggleFullscreen());
    }, [dispatch]);

    return (
      <FullscreenWrapped
        toggleFullscreenAction={toggleFullscreenAction}
        fullscreen={fullscreen}
        fullscreenRequested={fullscreenRequested}
        {...props}
      />
    );
  };
}

export default previewWrapper;
