import React from 'react';

import Label from '../../../Labels/Label';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MaybeTooltip from '../../../Tooltip/MaybeTooltip';
import CheckBoxStateless from '../../../Buttons/CheckBoxStateless';

import { FontWeightProperty } from 'csstype';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      marginTop: '-16px'
    },
    label: {
      backgroundColor: 'inherit',
      margin: '25px 0px 8px'
    },
    labelBold: {
      fontWeight: theme.typography.fontWeightBold as FontWeightProperty, 
      margin: '41px 0 16px' // 25 + 16
    }
  })
);

interface Props {
  input: any;
  className?: string;
  addToProjectsDisabled: boolean;
}

export const AddToProjectsCheckbox = (props: Props) => {
  const { input, addToProjectsDisabled, className = '' } = props;
  const classes = useStyles();
  return (
    <div className={className}>
      <Label
        text="ADVANCED"
        extraTypographyProps={{
          typographyStyle: 'body2',
          className: `${classes.label} ${classes.labelBold}`,
          color: 'contrast2'
        }}
      />
      <MaybeTooltip
        title={
          'This option is not available because you’re not an admin in any projects.'
        }
        disabled={!addToProjectsDisabled}
        placement="bottom"
      >
        <CheckBoxStateless
          marginCollapse={'left'}
          checked={input.checked}
          text={'Add new members to all projects in which you are an admin'}
          typographyProps={{
            typographyStyle: 'body2',
            color: 'contrast1'
          }}
          onChange={input.onChange}
          className={classes.checkbox}
          disabled={addToProjectsDisabled}
        />
      </MaybeTooltip>
    </div>
  );
};
