import React, { useMemo } from 'react';

import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';

export const withDefaultTeams = (Wrapped) => (props) => {
  const workspace = useResource__ALPHA({
    resourceType: 'workspace',
    resourceId: props.currentWorkspaceId
  }).resource;

  const defaultTeams = workspace?.relationships?.default_teams;

  const workspaceTeams = useResourceList__ALPHA({
    resourceId: props.currentWorkspaceId,
    resourceType: 'workspace',
    edgeType: 'team',
    edgeLabel: 'teams'
  }).resources;

  const defaultTeamsWithName = useMemo(() => {
    if (!defaultTeams || defaultTeams.length === 0) return [];

    if (defaultTeams && defaultTeams.length > 0) {
      return defaultTeams
        .map((team) => workspaceTeams?.find((t) => t.id === team.id))
        .filter(Boolean);
    }
  }, [defaultTeams, workspaceTeams]);

  return (
    <Wrapped
      {...props}
      workspaceTeams={workspaceTeams}
      defaultTeams={defaultTeamsWithName}
    />
  );
};
