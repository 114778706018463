import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
  memo
} from 'react';
import Scrollbar from '../../Scrollbar/Scrollbar';
import { VirtualPagesPage } from './VirtualPagesPage';

interface Props {
  /** current page that should be in view */
  focusPage: number;
  /** pages of data provided (!NOTE interface should become more general) */
  pages: { resourceList?: any[]; pageNumber: number }[];
  /** (minimum) dimensions of a tile */
  itemSize: { width?: number; height: number };
  itemsPerRow?: number;
  /** total negative pages that can be provided */
  minPages?: number;
  /** total positive pages that can be provided */
  maxPages: number;
  /** total negative items that can be provided */
  minItems?: number;
  /** total positive items that can be provided */
  maxItems: number;
  /** callback that gets called when optimal ammount of items per page has been calculated */
  onPageSizeDetermined(data?: {
    pageSize: number;
    itemsPerRow: number;
    rowsPerPage: number;
  }): void;
  /** callback that gets called when scrolling has brought new page into focus */
  onFocusPageChange(page: number): void;
  initialScrollOffset?: number;
  renderItem?(item: any, idx: number, itemBefore: any): JSX.Element;
  renderLoading?(idx: number): JSX.Element;
  debug?: boolean;
  divider?: React.ReactNode;
  emptyElement?: React.ReactNode;
  /**
   * Allow only items close to view to be rendered
   * NOTE: causes issues with items that change height
   */
  allowPartialRender?: boolean;
}

export const VirtualPages = memo<Props>(
  ({
    focusPage,
    pages,
    itemSize,
    itemsPerRow = 1,
    minPages = 0,
    maxPages,
    minItems = 0,
    maxItems,
    onPageSizeDetermined,
    onFocusPageChange,
    initialScrollOffset = 0,
    renderItem,
    renderLoading,
    divider,
    emptyElement,
    allowPartialRender = false,
    debug
  }) => {
    // track initializing phase, to set focuspage in controlled way when data becomes available
    const initializing = useRef(true);

    // container ref
    const [container, setContainer] = useState<HTMLElement>();

    // items per page
    const [pageSize, setPageSize] = useState<number>();

    // page dimensions
    const [pageHeight, setPageHeight] = useState<number>();

    // items per row
    const itemsPerRowCalc = useRef(0);

    // size needed by partial pages
    const [sizeNeeded, setSizeNeededReal] = useState({
      positive: null,
      negative: null
    });

    const setSizeNeeded = useCallback(
      ({ positive, negative }) => {
        if (positive && positive !== sizeNeeded.positive) {
          setSizeNeededReal({ ...sizeNeeded, positive });
        }
        if (negative && negative !== sizeNeeded.negative) {
          setSizeNeededReal({ ...sizeNeeded, negative });
        }
      },
      [sizeNeeded, setSizeNeededReal]
    );

    //
    const [scrollHeight, negativeHeight] = useMemo(() => {
      if (
        minPages === undefined ||
        minItems === undefined ||
        maxPages === undefined ||
        maxItems === undefined
      ) {
        return [pageHeight, undefined];
      }

      let negativeHeight = 0;
      let positiveHeight = 0;

      const minPagesNormalized = minPages * -1 + 1;

      if (minPagesNormalized !== 0 && minItems !== 0) {
        const partialHeight =
          Math.ceil(
            (minItems * -1 - (Math.abs(minPagesNormalized) - 1) * pageSize) /
              itemsPerRowCalc.current
          ) * itemSize.height;
        negativeHeight =
          pageHeight * (Math.abs(minPagesNormalized) - 1) +
          Math.min(
            pageHeight,
            Math.max(
              sizeNeeded.negative || container?.clientHeight,
              partialHeight
            )
          );
      }
      if (maxPages !== 0 && maxItems !== 0) {
        const partialHeight =
          Math.ceil(
            (maxItems - (maxPages - 1) * pageSize) / itemsPerRowCalc.current
          ) * itemSize.height;

        positiveHeight =
          pageHeight * (maxPages - 1) +
          Math.min(
            pageHeight,
            Math.max(
              sizeNeeded.positive || container?.clientHeight,
              partialHeight
            )
          );

        positiveHeight = !isNaN(positiveHeight) ? positiveHeight : 0;
      }

      return [negativeHeight + positiveHeight, negativeHeight];
    }, [
      itemSize.height,
      minPages,
      maxPages,
      pageHeight,
      maxItems,
      minItems,
      pageSize,
      container,
      sizeNeeded
    ]);

    // on container ref  we determine amount of
    //- items per page
    // - items per page row
    // - the size of the page itself
    const onRef = useCallback(
      (node: HTMLElement | null) => {
        if (node) {
          const containerSize = node;
          itemsPerRowCalc.current = !itemSize.width
            ? itemsPerRow
            : Math.floor(containerSize.clientWidth / itemSize.width);

          const rowsPerPage =
            Math.ceil(containerSize.clientHeight / itemSize.height) * 2;
          const pageSize = itemsPerRowCalc.current * rowsPerPage;
          const pageHeight = rowsPerPage * itemSize.height;
          // setContainerSize(containerSize);

          setContainer(node);
          onPageSizeDetermined({
            pageSize,
            itemsPerRow: itemsPerRowCalc.current,
            rowsPerPage
          });
          setPageSize(pageSize);
          setPageHeight(pageHeight);
        } else {
          // no node?
          setContainer(undefined);
          setPageSize(undefined);
          onPageSizeDetermined(undefined);
          setPageHeight(undefined);
        }
      },
      [
        itemsPerRow,
        setPageSize,
        setPageHeight,
        itemSize.height,
        itemSize.width,
        onPageSizeDetermined
      ]
    );

    // after pagecount data becomes available,
    // set the scrollposition to the proper page from url
    useEffect(() => {
      if (
        container &&
        pageHeight &&
        initializing.current &&
        maxItems !== undefined &&
        minItems !== undefined &&
        negativeHeight !== undefined
      ) {
        container.scrollTop =
          pageHeight * (focusPage - 1) +
          negativeHeight +
          initialScrollOffset * pageHeight;
        initializing.current = false;
      }
    }, [
      container,
      pageSize,
      initializing,
      focusPage,
      pageHeight,
      maxItems,
      minItems,
      negativeHeight,
      initialScrollOffset
    ]);

    const updating = useRef(null);

    if (updating !== null && updating.current === focusPage) {
      updating.current = null;
    }

    const pageRatioRef = useRef({ fromTop: 0, fromBottom: 1 });

    // set focusPage based on scrollPosition
    const onScroll = useCallback(
      ev => {
        if (!container || initializing.current) {
          return;
        }
        const calculatedFocusPage = Math.ceil(
          (container.scrollTop - (negativeHeight || 0) + pageHeight * 0.25) /
            pageHeight
        );

        pageRatioRef.current = { fromTop: 0, fromBottom: 1 };

        const scrollPos = container.scrollTop;
        const pageY =
          (negativeHeight || 0) + (calculatedFocusPage - 1) * pageHeight;
        const ratioDistance = pageHeight - container.clientHeight;
        const ratio = (scrollPos - pageY) / ratioDistance;

        pageRatioRef.current = {
          fromTop: ratio,
          fromBottom:
            pageHeight / ratioDistance -
            ratio -
            container.clientHeight / ratioDistance
        };

        if (
          focusPage !== calculatedFocusPage &&
          updating.current !== calculatedFocusPage
        ) {
          updating.current = calculatedFocusPage;
          onFocusPageChange(calculatedFocusPage);
        }
      },
      [container, focusPage, onFocusPageChange, pageHeight, negativeHeight]
    );

    // little hack to trigger remount if window size changes
    // const [key, setKey] = useState(
    //   `${window.innerWidth}x${window.innerHeight}`
    // );
    useEffect(() => {
      const onResize = () => {
        if (container) {
          onRef(container);
        }
      };
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }, [container, onRef]);

    const noItems =
      pages &&
      pages.filter(p => p.resourceList && p.resourceList.length === 0)
        .length === pages.length;

    return (
      <div
        style={{
          // background: 'black',
          height: '100%',
          // overflowY: 'scroll',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        }}

        // key={key}
      >
        <Scrollbar
          style={{
            // background: 'black',
            height: '100%',
            // overflowY: 'scroll',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
          }}
          ref={onRef}
          onScroll={onScroll}
        >
          {noItems && emptyElement}

          <div
            style={{
              height: scrollHeight,
              overflowY: 'hidden'
            }}
          >
            {debug && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 'auto',
                  right: 0,
                  color: 'white',
                  fontSize: '500%',
                  zIndex: 1000,
                  textShadow: '2px 2px 2px black'
                }}
              >
                {focusPage}
              </div>
            )}

            <div
              style={{
                transform: `translate(0, ${(focusPage - 2) * pageHeight +
                  (negativeHeight || 0)}px)`
              }}
            >
              <>
                <VirtualPagesPage
                  key={`page-previous`}
                  // key={`page-${focusPage - 1}`}
                  itemSize={itemSize}
                  rowSize={itemsPerRow}
                  pageSize={pageSize}
                  height={pageHeight}
                  page={pages?.filter(p => p.pageNumber === focusPage - 1)[0]}
                  renderItem={renderItem}
                  renderLoading={renderLoading}
                  debug={debug}
                  scrollParent={container}
                  pageRatio={pageRatioRef}
                  pageRole={'previous'}
                  setSizeNeeded={setSizeNeeded}
                  lastItem={pages
                    ?.filter(p => p.pageNumber === focusPage - 2)[0]
                    ?.resourceList?.slice(-1)}
                  allowPartialRender={allowPartialRender}
                />
              </>

              {focusPage === 1 &&
                pages?.filter(p => p.pageNumber === focusPage - 1)[0] &&
                divider}

              <VirtualPagesPage
                key={`page-focuspage`}
                // key={`page-${focusPage}`}
                itemSize={itemSize}
                rowSize={itemsPerRow}
                pageSize={pageSize}
                height={pageHeight}
                page={pages?.filter(p => p.pageNumber === focusPage)[0]}
                renderItem={renderItem}
                renderLoading={renderLoading}
                debug={debug}
                scrollParent={container}
                pageRatio={pageRatioRef}
                pageRole={'focus'}
                setSizeNeeded={setSizeNeeded}
                lastItem={pages
                  ?.filter(p => p.pageNumber === focusPage - 1)[0]
                  ?.resourceList?.slice(-1)}
                allowPartialRender={allowPartialRender}
              />

              {focusPage === 0 && divider}

              <>
                <VirtualPagesPage
                  key={`page-next`}
                  // key={`page-${focusPage + 1}`}
                  itemSize={itemSize}
                  rowSize={itemsPerRow}
                  pageSize={pageSize}
                  height={pageHeight}
                  page={pages?.filter(p => p.pageNumber === focusPage + 1)[0]}
                  renderItem={renderItem}
                  renderLoading={renderLoading}
                  debug={debug}
                  scrollParent={container}
                  pageRatio={pageRatioRef}
                  pageRole={'next'}
                  setSizeNeeded={setSizeNeeded}
                  lastItem={pages
                    ?.filter(p => p.pageNumber === focusPage)[0]
                    ?.resourceList?.slice(-1)}
                  allowPartialRender={allowPartialRender}
                />
              </>
            </div>
          </div>
        </Scrollbar>
      </div>
    );
  }
);
