import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export type ApprovalStatusType = 'approved' | 'rejected' | '';
export interface Approval extends ResourceBase {
  type: 'approval';
  status: ApprovalStatusType;
  approver_name: string;
  relationships: {
    node: Related<'content-tree-node'>;
    share: Related<'share'>;
  };
}

export const approval: RawModel = {
  type: 'approval',
  plural: 'approvals',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    status: 'string'
  },
  relationships: {
    node: {
      type: 'content-tree-node',
      binding: ['one', 'many'],
      reverse: 'approvals'
    },
    share: {
      type: 'share',
      binding: ['one', 'many'],
      reverse: 'approvals'
    }
  }
};
