import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ResourceType,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { actionCreators } from '../../ducks/resource-nodes';
import { uuidv4 } from '../../../utils/uuid';
import { RequestStatuses, selectors } from '../../ducks/request-status';
import { selectors as tempIdSelectors } from '../../ducks/temp-id';
import { FSA } from '../../flux-standard-action';
import { addRequestStatusHash } from '../../middleware/request-status-middleware';
import { FormCompatibleError } from '../../../wrapped-cube-client/error-types';

type ActionDecorator<T extends FSA> = (action: T) => T;

interface NewResource {
  type: ResourceType;
  temporaryId?: string;
  [key: string]: any;
}

interface UseCreateResourceConfig<D extends FSA> {
  resourceType: ResourceType;
  ancestor?: ResourceIdentifier;
  relationship?: string;
  cacheInvalidator: (
    mutatedResource: ResourceIdentifier,
    ancestor: ResourceIdentifier,
    relationship?: string
  ) => ResourceIdentifier[] | null;
  actionDecorators?: ActionDecorator<D>[];
}

const emptyArray = [];

export const useCreateResource__ALPHA = ({
  resourceType,
  ancestor = undefined,
  relationship = undefined,
  cacheInvalidator,
  actionDecorators = emptyArray
}: UseCreateResourceConfig<any>) => {
  // const [creating, setCreating] = React.useState();
  const [watchAction, setWatchAction] = React.useState<FSA>();

  const dispatch = useDispatch();
  const createManyResources = React.useCallback(
    (resources) => {
      const action = ancestor
        ? actionCreators.createManyByAncestor(
            resourceType,
            ancestor.type,
            ancestor.id,
            relationship,
            resources.map((r) => {
              const { files, temporaryId, ...data } = r;
              return {
                temporaryId,
                data,
                files,
                invalidatesCache: cacheInvalidator
                  ? cacheInvalidator(r, ancestor, relationship)
                  : emptyArray
              };
            })
          )
        : actionCreators.createMany(
            resourceType,
            resources.map((r) => {
              const { files, temporaryId, ...data } = r;
              return {
                temporaryId,
                data,
                files,
                invalidatesCache: cacheInvalidator
                  ? cacheInvalidator(r, ancestor, relationship)
                  : emptyArray
              };
            })
          );

      const hash = `${action.type}__${uuidv4()}`;
      const decoratedAction = [
        ...actionDecorators,
        addRequestStatusHash(hash)
      ].reduce((a, dec) => dec(a), action);
      dispatch(decoratedAction);
      setWatchAction(decoratedAction);
      return hash;
    },
    [
      resourceType,
      ancestor,
      relationship,
      actionDecorators,
      dispatch,
      setWatchAction,
      cacheInvalidator
    ]
  );

  const createStatus = useSelector(
    ({ requestStatus: state }: { requestStatus: {} }) =>
      selectors.getStatus(state, watchAction)
  );

  const createError = useSelector(
    ({ requestStatus: state }: { requestStatus: {} }) =>
      selectors.getErrors(state, watchAction)
  );

  const createHandler = React.useCallback(
    (resource: NewResource | NewResource[]) => {
      const resources = [].concat(resource);

      if (!resource || resources.length < 1) {
        console.warn('Nothing to create');
        return;
      }

      return createManyResources(resources);
    },
    [createManyResources]
  );

  return [createHandler, createStatus, createError] as [
    typeof createHandler,
    RequestStatuses,
    FormCompatibleError
  ];
};

export const useMappedId__ALPHA = (tempId: string): string | undefined => {
  const mappedId = useSelector(
    ({ tempIdLookup: state }: { tempIdLookup: {} }) =>
      tempIdSelectors.getRealIdFromTempId(state, tempId)
  );
  return mappedId;
};

/**
 *    EXAMPLE USE:
 *    const form  = () => {
 *        const [createResource, createResourceStatus] = useCreateResource__ALPHA({
 *            resourceType: 'folder',
 *            ancestor: {type:'folder', id: '<id>'},
 *            relationship: 'folders'
 *        });
 *        const [inputValue, setInputValue] = React.useState();
 *
 *        const handleInputChange = React.useCallback((ev) => {
 *          setInputValue(ev.value);
 *        }, [setInputValue]);
 *        const  handleSubmit= React.useCallback((ev) => {
 *            createResource({
 *               display_name: inputValue
 *            });
 *        }, [inputValue]);
 *        return (
 *            <form onSubmit={handleSubmit}>
 *               <input type="text" onChange={handleInputChange} value={inputValue}
 *               <button>Ok</button>
 *            </form>
 *        )
 *    }
 */
