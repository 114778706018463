import React from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from './../../Modals/ModalManager';
import { withModalActions, ModalActionProps } from '../../Modals/modalActions';
import { connect } from 'react-redux';
import withAccountActions, {
  AccountActionsProps
} from '@cube3/state/src/redux/components/Administration/withAccountActions';

// forms
import { reduxForm, getFormValues, Field, InjectedFormProps } from 'redux-form';

import GenericFieldWithErrors from '../../../../forms/helpers/fields/GenericFieldWithErrors';

// UI
import { compose } from '../../../../../utils/component-helpers';
import ChangePasswordModalUI from '@cube3/ui/src/Layout/AccountSettings/Modals/ChangePasswordModalUI';

// helpers
import {
  isRequired,
  makeValidator,
  strongPassword,
  ComplexErrors
} from '../../../../forms/helpers/validators';

import PasswordStrength from '../PasswordStrength';
import { RequestError } from '@cube3/common/model/resource-types';

/// @Author :
/// <Summary>
/// Form for changing the user name which has the user placehodler name a validator for
/// for an empty field.
/// </Summary>

type Properties = ModalReceiverProps &
  ModalActionProps &
  FormValues &
  AccountActionsProps &
  InjectedFormProps;

interface FormValues {
  current_password?: string;
  password?: string;
  passwordConfirm?: string;
}

const passwordValidators = makeValidator([isRequired, strongPassword]);

const mapStateToProps = (state) => {
  const values = getFormValues('changePasswordUserAccount')(state);

  return {
    ...values,
    formValues: values
  };
};

class ChangePasswordModal extends React.PureComponent<Properties> {
  componentDidUpdate() {
    if (this.props.submitSucceeded) {
      // show a modal displaying that the password has changed successfully.
      this.props.openModal('accountsettings_changePasswordConfirmed');
    }
  }

  render() {
    const {
      // HOC functions
      closeAllModals,
      valid,
      handleSubmit,
      submitting,
      error
    } = this.props;

    let errorMsg: string;
    const err = error as unknown; // because redux thinks its a string we have to cast it first
    const errors: RequestError[] = err as RequestError[];

    // when the form errors on submit...
    if (errors) {
      if (
        errors[0].detail ===
        'crypto/bcrypt: hashedPassword is not the hash of the given password'
      ) {
        errorMsg = 'Current password is incorrect. Please check your password.';
      } else {
        errorMsg = errors[0].detail;
      }
    }

    return (
      <ChangePasswordModalUI
        onSubmit={handleSubmit}
        onCloseEvent={() => closeAllModals()}
        submitDisabled={!valid || submitting}
        loading={submitting}
        submitError={errorMsg}
        oldPassRenderProps={() => (
          <Field
            component={GenericFieldWithErrors}
            name={'current_password'}
            type="password"
            validate={isRequired}
            props={{
              label: 'Current password',
              inputProps: { autocomplete: 'current-password' }
            }}
            startSelected={true}
          />
        )}
        newPassRenderProps={() => (
          <Field
            component={GenericFieldWithErrors}
            name={'password'}
            type="password"
            validate={passwordValidators}
            props={{
              label: 'New password',
              showErrors: false,
              inputProps: { autocomplete: 'new-password' },
              renderTip: ({ meta }) =>
                (meta.touched && meta.error && meta.error.length) ||
                meta.focus ? (
                  <PasswordStrength errors={meta.error} />
                ) : undefined
            }}
          />
        )}
        confirmPassRenderProps={() => (
          <Field
            component={GenericFieldWithErrors}
            name={'passwordConfirm'}
            validate={isRequired} // This is important!
            props={{
              label: 'Confirm new password',
              type: 'password',
              inputProps: { autocomplete: 'new-password' },
              showErrors: 'touched'
            }}
          />
        )}
      />
    );
  }
}

export default compose(ChangePasswordModal)(
  withModalActions,
  withAccountActions({
    accountId: ({ modalContext }) => modalContext
  }),
  connect(mapStateToProps, null),
  reduxForm<FormValues, Properties, ComplexErrors>({
    form: 'changePasswordUserAccount',
    destroyOnUnmount: true,
    validate: (values, props) => {
      if (
        values.password &&
        values.passwordConfirm &&
        values.password !== values.passwordConfirm
      ) {
        return {
          password: [],
          passwordConfirm: 'password fields should match',
          _error: 'Password fields should match'
        };
      }
    },
    onSubmit: (args: FormValues, dispatch, props) => {
      const realProps = props as Properties; // since the redux props type is incomplete, we will define the real type
      realProps.changePassword(
        args.current_password,
        args.password,
        'changePasswordUserAccount'
      );
    }
  })
);
