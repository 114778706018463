import React from 'react';

import Cube3Link from '../../../routing/Cube3Link';
import { Activity } from '@cube3/common/model/schema/resources/activities';

import { useActivityContextWithPath } from '../hooks/useActivityContext';

interface Properties {
  projectActivity: Activity;
  onClickLink: () => void;
}

export const ActivityItemLink: React.FunctionComponent<Properties> = (
  props
) => {
  const { projectActivity, onClickLink } = props;

  const { related_resources: context } = projectActivity.relationships;

  const { pathname: contextPathname, name: contextName } =
    useActivityContextWithPath(context);

  // TODO there is currently an issue with library items that are deleted, because their information cannot be retrieved with the current
  // api implementation. Also getting the deleted item resource is unforunately also not an option, because the id
  // of a deleted item is not the same as the id of an asset.

  return (
    <Cube3Link
      onClick={() => onClickLink()}
      pathname={contextPathname}
      underline={'hover'}
    >
      {contextName}
    </Cube3Link>
  );
};
