/* eslint-disable eqeqeq */

import { Point } from './point';

/**
 * @class Line
 * Line, taking two (Point) points, or an array of (Point) points.
 *
 * @constructor
 * @param {Point|Array} p1 Start point of the Line, or an Array of [x,y] values.
 * @param {Point} p2 End point of the Line.
 */
export class Line {
  type: 'line';
  isLine: boolean;
  isPoint: boolean;
  points: [Point, Point];
  length: number;
  p1: Point;
  p2: Point;

  constructor(p1, p2) {
    this.type = 'line';
    this.isLine = true;

    // support array argument assignment
    if (p1 instanceof Array) {
      p2 = p1[1];
      p1 = p1[0];
    }

    this.p1 = p1;
    this.p2 = p2;

    this.points = [this.p1, this.p2];
    this.length = p1.distance(p2);
  }

  /**
   * takes a float between 0 and 1 and returns a (Point) on the curve.
   * @param {Float} t the time value
   * @return {Point} point
   */
  time(t) {
    t = Math.max(0, Math.min(1, t));
    return new Point(this.x(t), this.y(t));
  }

  /**
   * takes a float between 0 and this.length and returns a (Point) on the curve.
   * this is similar to the time function, but with an absolute length for d.
   * @param {Float} d the travel distance (or length) on this curve
   * @return {Point} point
   */
  pointAtTravelDistance(d) {
    d = Math.max(0, Math.min(this.length, d));
    return this.time(d / this.length || 1);
  }

  /**
   * returns the x position for time t on a curve in linear space
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Float} x position for t
   */
  x(t) {
    t = Math.max(0, Math.min(1, t));
    return this.p1.x + (this.p2.x - this.p1.x) * t;
  }

  /**
   * returns the y position for time t on a curve in linear space
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Float} y position for t
   */
  y(t) {
    t = Math.max(0, Math.min(1, t));
    return this.p1.y + (this.p2.y - this.p1.y) * t;
  }

  needsUpdate() {
    return this.p1.moved || this.p2.moved;
  }

  /**
   * when the positions of the points change, we will update
   * our properties to match
   */
  update() {
    this.length = this.p1.distance(this.p2);

    // reset point moved state
    this.p1.moved = false;
    this.p2.moved = false;

    return this;
  }

  toSVG(prevSegment, width, height) {
    width = width || 1;
    height = height || 1;

    var svg = [],
      round = function (n, d?) {
        d = d == undefined ? 3 : d;
        return (
          Math.round((n + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d)
        );
      };

    if (!prevSegment) {
      // start with a Move To command
      svg.push(`M ${round(this.p1.x * width)} ${round(this.p1.y * height)}`);
    }

    svg.push(`L ${round(this.p2.x * width)} ${round(this.p2.y * height)}`);

    return svg.join(' ');
  }
}
