import MenuItem from '../Buttons/MenuItem';
import { iconFileRequest } from '../icons/GeneralSVG';
import { Submenu } from '../menus/Submenu';
import React, { useCallback, useRef } from 'react';
import { Divider } from '../Surface/Divider';
import {
  isMenuEntry,
  isMenuGroup,
  MenuEntry,
  MenuGroup
} from './CommandBar/CommandBarUI/types';
import MenuButton from '../Buttons/MenuButton';
import { ButtonTypes } from '../themes/custom-theme';
import { PopoverMenuClassType } from '../menus/PopOverMenu/PopoverMenu';

interface Props {
  entry: any;
  classes?: PopoverMenuClassType;
  closeFn?: Function;
  variant?: ButtonTypes;
  'data-type'?: 'librarydeselectoverride';
}

export const RenderSubmenuButton = (props: Props) => {
  const { entry, closeFn, classes } = props;

  if (!entry.visible) return null;
  if (entry.isDivider)
    return <Divider key={entry.title} direction="horizontal" />;

  const handleClick = useCallback(
    (ev) => {
      closeFn();
      return entry.onClick(ev, entry.extraClickArgs);
    },
    [entry.onClick, entry.extraClickArgs, closeFn]
  );

  return (
    <div className={classes?.root}>
      <MenuItem
        text={entry.title}
        dataType={props['data-type']}
        key={entry.title}
        disabled={entry.disabled}
        disabledReason={entry.disabledReason}
        path={entry.icon ? entry.icon : iconFileRequest}
        onClick={handleClick}
        className={classes?.menuButton}
        shortCutTip={entry.shortCut?.tip}
        extraSVGProps={entry.extraSVGProps}
        extraButtonProps={entry.extraButtonProps}
      />
    </div>
  );
};

export const RenderTooltip = ({ entry, className = '' }) => {
  return (
    <MenuButton
      text={entry.title}
      disabled={true}
      key={entry.title}
      disabledReason={entry.disabledReason}
      onClick={() => console.warn('Disabled')}
      path={entry.icon ? entry.icon : undefined}
      isDropDown={true}
      fullWidth={true}
      className={className}
    />
  );
};

export const RenderSubmenuContent = (props: Props) => {
  const { entry, closeFn, variant, classes } = props;
  const lastEntry = useRef(null);
  lastEntry.current = null;

  if (!entry.visible) return null;
  if (entry.disabled)
    return <RenderTooltip entry={entry} className={classes?.menuButton} />;

  return (
    <Submenu
      key={entry.title}
      text={entry.title}
      variant={variant}
      classProps={classes}
      leftSvgPath={entry.icon ? entry.icon : undefined}
      childrenRenderProps={(close, isOpen) => {
        return (
          <React.Fragment>
            {entry.children &&
              (entry.children as Array<MenuEntry | MenuGroup>).map((child) => {
                if (isMenuEntry(child)) {
                  if (child.component) {
                    return (
                      <div
                        key={child.title}
                        data-type="librarydeselectoverride"
                        style={{ minWidth: 225 }}
                      >
                        {child.component(closeFn ? closeFn : close, isOpen)}
                      </div>
                    ); // closeFn: commandbar buttons use different handleClose function
                  }
                  return (
                    <RenderSubmenuButton
                      key={child.title}
                      entry={child}
                      closeFn={closeFn ? closeFn : close}
                      classes={classes}
                      data-type="librarydeselectoverride"
                    />
                  );
                }
                if (isMenuGroup(child))
                  return child.entries.map((cEntry) => (
                    <RenderSubmenuButton
                      key={cEntry.title}
                      entry={cEntry}
                      closeFn={closeFn ? closeFn : close}
                      classes={classes}
                      data-type="librarydeselectoverride"
                    />
                  ));

                return null;
              })}
          </React.Fragment>
        );
      }}
    />
  );
};
