import React, { useState, useCallback } from 'react';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { useModalActions } from '../../../../app/layout/Modals/modalActions';
import Dropzone from 'react-dropzone';
import { compose } from '@cube3/state/src/utils/component-helpers';
import {
  withUploads,
  WithUploadsInjectedProps
} from '@cube3/state/src/redux/components/withUploads';
import DropzoneUI from '@cube3/ui/src/helpers/DropzoneUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalReceiverProps } from '../../../../app/layout/Modals/ModalManager';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { StatusBlip } from '@cube3/ui/src/StatusBlip';
import { iconCheck, iconClose } from '@cube3/ui/src/icons/GeneralSVG';
import { createStyles, makeStyles } from '@material-ui/core';
import { CircularProgress } from '@cube3/ui/src/Progress/CircularProgress';
import Surface from '@cube3/ui/src/Surface/Surface';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import Scrollbar from '@cube3/ui/src/Scrollbar/Scrollbar';

interface Props extends WithUploadsInjectedProps, ModalReceiverProps {}

const AttachmentsUploadModalBase: React.ComponentType<Props> = (props) => {
  const modalActions = useModalActions();

  const {
    modalContext,
    uploadFilesToParentResource,
    uploadsForParentResource,
    removeUpload,
    clearParentResourceUploads
  } = props;

  const { input } = modalContext;

  const [stagedUploads, setStagedUploads] = useState<File[]>(null);

  const stageUploads = useCallback(
    (files) => {
      setStagedUploads([...(stagedUploads || []), ...files]);
    },
    [setStagedUploads, stagedUploads]
  );

  const handleUploadClick = useCallback(() => {
    if (stagedUploads?.length) {
      uploadFilesToParentResource(stagedUploads);
      setStagedUploads(null);
    }
  }, [uploadFilesToParentResource, stagedUploads, setStagedUploads]);

  const handleCancelClick = useCallback(() => {
    if (
      !stagedUploads?.length ||
      !uploadsForParentResource?.filter((u) => u.progress < 100).length
    ) {
      modalActions.previousModal();
    }
  }, [modalActions, stagedUploads, uploadsForParentResource]);

  const handleDoneClick = useCallback(() => {
    if (
      !stagedUploads?.length ||
      !uploadsForParentResource?.filter((u) => u.progress < 100 && !u.failed)
        .length
    ) {
      const successful = uploadsForParentResource
        ?.filter((u) => u.progress === 100 && !u.failed)
        .map((u) => u.asset_id);
      setStagedUploads(null);
      clearParentResourceUploads();
      input.onChange([...(input.value || []), ...successful]);
      input.onBlur();
      modalActions.previousModal();
    }
  }, [
    modalActions,
    stagedUploads,
    setStagedUploads,
    input,
    uploadsForParentResource,
    clearParentResourceUploads
  ]);

  const showDoneButton =
    !stagedUploads?.length &&
    uploadsForParentResource?.length &&
    !uploadsForParentResource.filter((u) => u.progress < 100 && !u.failed)
      .length;

  const showUploadButton =
    !showDoneButton &&
    (stagedUploads?.length || uploadsForParentResource?.length);

  const handleRemoveClick = useCallback(
    (upload) => {
      if (stagedUploads?.includes(upload)) {
        setStagedUploads(stagedUploads.filter((u) => u !== upload));
      } else if (uploadsForParentResource?.includes(upload)) {
        removeUpload(upload);
      }
    },
    [stagedUploads, uploadsForParentResource, setStagedUploads, removeUpload]
  );

  return (
    <ModalMenuUI
      title="Upload files"
      onCloseEvent={() => {
        modalActions.previousModal();
        input.onBlur();
      }}
      footerRightComponent={
        <>
          {!showDoneButton && (
            <Button text="Cancel" onClick={handleCancelClick} />
          )}
          {!!showUploadButton && (
            <Button
              text="Upload"
              colorVariant="filled1"
              onClick={handleUploadClick}
              disabled={!stagedUploads || !stagedUploads.length}
            />
          )}
          {!!showDoneButton && (
            <Button
              text="Done"
              colorVariant="filled1"
              onClick={handleDoneClick}
            />
          )}
        </>
      }
    >
      <AttachmentsDropzone
        stageUploads={stageUploads}
        stagedUploads={stagedUploads}
        activeUploads={uploadsForParentResource}
        removeUpload={handleRemoveClick}
      />
    </ModalMenuUI>
  );
};

interface AttachmentDropzoneBaseProps {
  stageUploads(files: File[]): void;
  removeUpload(upload: File | Upload): void;
  stagedUploads: File[];
  activeUploads?: Upload[];
}

const useStylesDropzone = makeStyles((theme) =>
  createStyles({
    root: {},
    topArea: {
      padding: `0px ${theme.customSpacing.padding[12]}`
    },
    dropArea: {
      minHeight: 260,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      alignItems: 'stretch',
      justifyItems: 'stretch'
    },
    dropMessageInside: { alignSelf: 'center', justifySelf: 'center' },
    dropMessageTop: { height: 32, display: 'flex', alignItems: 'center' },
    surfaceWrapper: { display: 'flex', flexFlow: 'column nowrap' },
    surface: {
      flexGrow: 1,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      alignItems: 'stretch',
      justifyItems: 'stretch'
    }
  })
);

const AttachmentsDropzone: React.ComponentType<AttachmentDropzoneBaseProps> = (
  props
) => {
  const { stageUploads, stagedUploads, activeUploads, removeUpload } = props;
  const disabledReason = undefined;

  const classes = useStylesDropzone(props);

  return (
    <Dropzone
      noKeyboard={true}
      noClick={true} // to prevent native file selector when clicking in dropzone container.
      preventDropOnDocument={true} // to prevent previewing in the browser
      noDragEventsBubbling={true} // to prevent accidental parent selection when hovering over folder.
      // when files are dropped, trigger event with files.
      onDrop={(acceptedFiles, rejected, e) => {
        if (!disabledReason) {
          stageUploads(acceptedFiles);
        }
      }}
    >
      {({ getRootProps, getInputProps, open, isDragActive, isDragAccept }) => {
        return (
          <>
            {(!!stagedUploads?.length || !!activeUploads?.length) && (
              <div className={classes.topArea}>
                {!!stagedUploads?.length && !activeUploads?.length && (
                  <Typography
                    typographyStyle="heading4"
                    typographyProps={{ gutterBottom: true }}
                  >
                    {stagedUploads.length} files
                  </Typography>
                )}
                {!!activeUploads?.length && (
                  <Typography
                    typographyStyle="heading4"
                    typographyProps={{ gutterBottom: true }}
                  >
                    {
                      activeUploads.filter(
                        (u) => u.progress === 100 && !u.failed
                      ).length
                    }{' '}
                    of {activeUploads.length} files done uploading
                  </Typography>
                )}
                <Typography
                  typographyProps={{ gutterBottom: true }}
                  className={classes.dropMessageTop}
                >
                  {isDragAccept ? (
                    <>Drop files here</>
                  ) : (
                    <span>
                      Drag files here or{' '}
                      <Button
                        disableHover={true}
                        marginCollapse="both"
                        onClick={open}
                        text="select files"
                      />{' '}
                      from your device
                    </span>
                  )}
                </Typography>
              </div>
            )}

            <DropzoneUI
              getRootProps={getRootProps}
              isDragActive={isDragActive}
              disabled={disabledReason}
              shouldShowSnackbar="error"
              isMainArea={true}
              libraryItemType={'contract-attachments'}
            >
              <input {...getInputProps()} />

              <div className={classes.dropArea}>
                {!stagedUploads?.length && !activeUploads?.length && (
                  <div className={classes.dropMessageInside}>
                    <Typography>
                      {isDragAccept ? (
                        <>Drop files here</>
                      ) : (
                        <span>
                          Drag files here or{' '}
                          <Button
                            disableHover={true}
                            marginCollapse="both"
                            onClick={open}
                            text="select files"
                          />{' '}
                          from your device
                        </span>
                      )}
                    </Typography>
                  </div>
                )}

                {(!!stagedUploads?.length || !!activeUploads?.length) && (
                  <div className={classes.surfaceWrapper}>
                    <Surface
                      border={true}
                      background="transparent"
                      cornerRadius="mediumEmphasis"
                      corners="rounded"
                      className={classes.surface}
                    >
                      <Scrollbar>
                        <AttachmentUploadListUI>
                          {stagedUploads?.map((u: any, idx: number) => {
                            return (
                              <AttachmentUploadListItemUI
                                key={idx}
                                name={u.path.split('/').slice(-1)[0]}
                                onClickRemove={() => {
                                  removeUpload(u);
                                }}
                              />
                            );
                          })}
                          {activeUploads?.map((u) => {
                            return (
                              <AttachmentUploadListItemUI
                                key={u.id}
                                name={u.display_name}
                                progress={u.progress}
                                failed={u.failed}
                                onClickRemove={
                                  (u.failed || u.progress === 100) &&
                                  (() => {
                                    removeUpload(u);
                                  })
                                }
                              />
                            );
                          })}
                        </AttachmentUploadListUI>
                      </Scrollbar>
                    </Surface>
                  </div>
                )}
              </div>
            </DropzoneUI>
          </>
        );
      }}
    </Dropzone>
  );
};

const withParentResource = (Wrapped) => (props) =>
  (
    <Wrapped
      {...props}
      parentResource={props.modalContext.metadataForResource}
    />
  );

export const AttachmentsUploadModal = compose(AttachmentsUploadModalBase)(
  withParentResource,
  withUploads({
    parentResource: ({ parentResource }) => parentResource
  })
);

const useStylesItem = makeStyles(() =>
  createStyles({
    root: {
      listStyle: 'none',
      '&:last-child $divider': {
        display: 'none'
      }
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px'
    },
    filename: {
      flexGrow: 1
    },
    divider: {}
  })
);
const useStylesList = makeStyles(() =>
  createStyles({
    root: {
      listStyle: 'none',
      padding: 0,
      margin: 0
    }
  })
);

const AttachmentUploadListUI = (props) => {
  const classes = useStylesList(props);
  const { children } = props;
  return <ul className={classes.root}>{children}</ul>;
};

const AttachmentUploadListItemUI = (props) => {
  const { name, progress, failed, onClickRemove } = props;

  const classes = useStylesItem(props);

  return (
    <li className={classes.root}>
      <div className={classes.item}>
        {/* <pre>{JSON.stringify(u, null, 2)}</pre> */}
        <div className={classes.filename}>
          <Typography>{name}</Typography>
        </div>
        {progress > 0 && progress < 100 && !failed && (
          <CircularProgress value={progress} size={24} />
        )}{' '}
        {progress === 100 && (
          <StatusBlip
            size="big"
            color="success"
            icon={iconCheck}
            iconViewBox={32}
          />
        )}
        {failed && <StatusBlip size="big" color="danger" icon={iconClose} />}
        <Button
          disabled={!onClickRemove}
          path={iconClose}
          onClick={onClickRemove}
        />
      </div>
      <div className={classes.divider}>
        <Divider color="contrast3" />
      </div>
    </li>
  );
};
