const specialCharRegx = /[^A-Za-zÀ-ȕ0-9]/g;

export type TextsType = {
  [row: string]: string[];
};

export const reverseString = (str: string) => {
  return str.split('').reverse().join('');
};

/** parse a full string text into array of words + special characters in order
 * - @word: a string of letters or numbers or mixed of both
 * - ep: 'hello_2022' -> ['hello','_','2022']
 */
export const getWords = (fullStr: string) => {
  const groups = [];
  let word = '';
  for (let i = 0; i < fullStr.length; i++) {
    if (fullStr[i].match(specialCharRegx)) {
      if (word) {
        groups.push(word);
      }
      word = '';
      groups.push(fullStr[i]);
    } else {
      word += fullStr[i];
    }
    // push the last word
    if (i === fullStr.length - 1 && !!word) {
      groups.push(word);
    }
  }
  return groups;
};

/** convert words array to string
 * - ep: ['hello','_','2022'] -> 'hello_2022'
 */
export const wordsToString = (array: Array<string>) => {
  if (!array || array.length === 0) return '';
  let newStr = '';
  for (let i = 0; i < array.length; i++) {
    newStr += array[i];
  }
  return newStr;
};

export const countWords = (texts: TextsType) => {
  const values = Object.values(texts);
  if (!values) return 0;
  const count = values.reduce(
    (acc: number, array: string[]) => acc + array.length,
    0
  ) as number;
  return count;
};

export const getLongestWord = (fullStr: string) => {
  if (!fullStr) return '';
  const words = fullStr.split(specialCharRegx);

  let longest = '';
  for (let i = 0; i < words.length; i++) {
    if (words[i].length > longest.length) {
      longest = words[i];
    }
  }
  return longest;
};

export const reverseObject = (obj: TextsType) => {
  const keys = Object.keys(obj);
  if (keys.length === 0) return {};
  const result = {};

  for (let i = 0; i < keys.length; i++) {
    result[i] = obj[keys.length - i];
  }
  return result;
};
