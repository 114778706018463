import { ExportBatch } from '@cube3/common/model/schema/resources/export-batch';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useMemo } from 'react';
import { useExportStatus } from './useExportStatus';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';

const TARGET_NAME = {
  broadcast: 'TV ad',
  youtube: 'YouTube',
  meta: 'Meta',
  cm360: 'CM360',
  googleads: 'Google Ads',
  contentful: 'Contentful'
} as const;

const mapTargetParam: {
  [key in keyof typeof TARGET_NAME]: {
    param: string;
    lookup: string;
  };
} = {
  broadcast: { param: '', lookup: '' },
  youtube: { param: 'channel', lookup: 'channels' },
  cm360: { param: 'profile_id', lookup: 'profiles' },
  meta: { param: 'ad_account_id', lookup: 'adAccounts' },
  googleads: { param: 'customer_id', lookup: 'customers' },
  contentful: { param: 'space_id', lookup: 'spaces' }
} as const;

/** A hook to normalize the exports info for the different target-types
 * so that it can be used in the `MediaQueueTile` component
 */
export const useExportQueueListItemConfig = (
  exportBatch: ExportBatch,
  assetId?: string
) => {
  const exportTargetTypeId =
    exportBatch.relationships['export-target-types'][0].id;

  const { assetIdentifiers, item } = useMemo(() => {
    const singleExport = assetId
      ? exportBatch?.attributes.exports?.find((exp) => exp.asset_id === assetId)
      : undefined;

    return {
      /** ensure that the exports always has the same data shape as `ExportBatch` for convenience
       */
      item: {
        ...exportBatch,
        attributes: {
          ...exportBatch.attributes,
          integration_parameters: singleExport
            ? singleExport.parameters[exportTargetTypeId]
            : exportBatch.attributes.integration_parameters
        }
      },
      assetIdentifiers: assetId
        ? [{ type: 'asset' as const, id: assetId }]
        : exportBatch
        ? exportBatch.relationships.assets
        : []
    };
  }, [exportBatch, assetId, exportTargetTypeId]);

  const assets = useResource__ALPHA<'asset'>({
    resourceIdentifiers: assetIdentifiers
  }).resources;

  const [accoutId] = useCurrentAccount(true);
  const { first: platform } = useResourceList__ALPHA(
    useMemo(
      () => ({
        resourceId: accoutId,
        resourceType: 'account',
        edgeType: 'export-target',
        edgeLabel: 'export-targets',
        params: {
          filter: { exportTargetTypeId }
        }
      }),
      [accoutId, exportTargetTypeId]
    )
  );

  const { status, label, progress } = useExportStatus(item);

  const config = useMemo(() => {
    const params = item.attributes.integration_parameters;

    // define the `chanelName` for the different export-taret-type, needed for UI
    const platformId = mapTargetParam[exportTargetTypeId];
    const id = params[platformId.param];
    const iConfig = platform?.attributes.integration_config;
    const name: string = iConfig?.[platformId.lookup]?.find(
      (c) => c.id === id
    )?.display_name;

    const assetName: string = assets?.[0].display_name;
    const exportTitle: string =
      params.title || params.name || params.creative_name;
    /** true if the export only contains one asset */
    const single = assets?.length === 1;
    return {
      title: single ? exportTitle || assetName : `${assets?.length} assets`,
      subTitle:
        single && exportTitle && exportTitle !== assetName
          ? assetName
          : undefined,
      thumbnails: assets?.map((item) => item.display_image) || [],
      label,
      status,
      progress,
      /** `[targetName] • [chanelName]` */
      targetName: (TARGET_NAME[exportTargetTypeId] ||
        exportTargetTypeId) as string,
      /** `[targetName] • [chanelName]` */
      chanelName: name,
      created_at: item.attributes.created_at,
      asset: assets?.[0]
    };
  }, [assets, platform, item, exportTargetTypeId]);

  return config;
};
