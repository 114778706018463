import { ExportsParameters } from '@cube3/common/model/schema/resources/export-batch';

export const findLast = (
  field,
  items: ExportsParameters<'broadcast'>[],
  highest = false
) => {
  const mapped = items
    .map((i) => i.parameters.broadcast?.[field])
    .filter(Boolean);
  if (highest) {
    return mapped?.length ? Math.max(...mapped) : undefined;
  } else {
    return mapped[0];
  }
};
