import { capitalize } from '@material-ui/core';

const letterExp = /^[a-zA-Z]$/;
const numberExp = /^[0-9]$/;
const lookup = {
  ' ': 'Space',
  '-': 'Minus',
  '=': 'Equal',
  '[': 'BracketLeft',
  ']': 'BracketRight',
  ';': 'Semicolon',
  "'": 'Quote',
  '\\': 'Backslash',
  '.': 'Period',
  ',': 'Comma',
  '`': 'Backquote',
  '?': 'Slash',
  '/': 'Slash'
};

export const normalizeKey = (key) => {
  switch (true) {
    case letterExp.test(key):
      return `Key${key.toUpperCase()}`;
    case numberExp.test(key):
      return `Digit${key}`;
    case !!lookup[key]:
      return lookup[key];
    default:
      return capitalize(key);
  }
};

export const makeIdentifier = (key, modifiers = {}) => {
  return `${key}__${Object.keys(modifiers)
    .filter((k) => modifiers[k])
    .sort()
    .join('__')}`;
};

export const isValidURL = (value: string) => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,24}\b(?:[-a-zA-Z0-9()!@:%_\+.~#?&\/=]*)$/;

  return urlRegex.test(value);
};
