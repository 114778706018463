import { useCallback, useMemo, useState } from 'react';
import { lookupColor } from '@cube3/ui/src/tags/colors';
import { tagColorOrder } from '@cube3/ui/src/tags/types';
import { findMatchingTag, tagsEqual } from './utils/findMatchingTag';
import { Tag } from '@cube3/common/model/schema/resources/tag';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';

export type TagsSearchQuery = {
  text: string;
  color?: string;
  category?: {
    id: string;
    display_name: string;
  };
};

export type TagSuggestion = {
  tag: EditableTag | Tag;
  match: boolean;
  exact: boolean;
  new: boolean;
  applied: boolean;
};
interface Props {
  allTags: Tag[];
  selectedTags: EditableTag[];
}

export const useTagSuggestions = ({ allTags, selectedTags }: Props) => {
  const [query, setQuery] = useState<TagsSearchQuery>({ text: '' });
  const handleInputChange = useCallback(
    (query: TagsSearchQuery) => {
      setQuery((prev) => {
        const textChangedOnly = query.text !== prev.text;
        return {
          ...query,
          // trick: set `category` to `undefined` here to make create new tag with existing name still available,
          // because we now take category into account when `findMatchingTag`
          category: textChangedOnly ? undefined : query.category
        };
      });
    },
    [setQuery]
  );

  /** generate a list of suggestions based on (recent) user input */
  const suggestions = useMemo(() => {
    // exact match
    const match =
      findMatchingTag(allTags)(query) || findMatchingTag(selectedTags)(query);

    // partial matches
    const partialMatches = [
      ...selectedTags,
      ...allTags.filter((t) => !findMatchingTag(selectedTags)(t))
    ]
      .filter((t) => {
        const textMatch =
          t.text &&
          query.text &&
          (t.text.toLowerCase().includes(query.text.toLowerCase()) ||
            query.text.toLowerCase().includes(t.text.toLowerCase()));

        const categoryMatch =
          (t as EditableTag)?.category?.display_name &&
          query.text &&
          (t as EditableTag).category?.display_name
            .toLowerCase()
            .includes(query.text.toLowerCase());

        return !tagsEqual(t, match) && (textMatch || categoryMatch);
      })
      .sort(sortTagSuggestionsByText);

    // if no matches present recently added tags
    const alphabetically =
      query.text || match || partialMatches.length > 0
        ? []
        : allTags
            .filter((t) => !findMatchingTag(selectedTags)(t))
            .sort(sortTagSuggestionsByText);

    return [
      match
        ? {
            tag: match,
            match: true,
            exact: true,
            new: false,
            applied: !!findMatchingTag(selectedTags)(match)
          }
        : undefined,
      ...partialMatches.map((m) => ({
        tag: m,
        match: true,
        exact: false,
        new: false,
        applied: !!findMatchingTag(selectedTags)(m)
      })),
      ...alphabetically.map((t) => ({
        tag: t,
        match: false,
        exact: false,
        new: false,
        applied: false
      }))
    ].filter((t) => !!t);
  }, [allTags, selectedTags, query]);

  return { suggestions, handleInputChange, query };
};

export const sortTagSuggestionsByDate = (a, b) => {
  if (!a.text && !b.text) {
    return (
      tagColorOrder.indexOf(lookupColor(a)) -
      tagColorOrder.indexOf(lookupColor(b))
    );
  }
  if (!a.text) {
    return -1;
  }
  if (!b.text) {
    return 1;
  }
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

const numberExp = /^\d+/;

export const sortTagSuggestionsByText = (a, b) => {
  if (!a.text && !b.text) {
    return (
      tagColorOrder.indexOf(lookupColor(a)) -
      tagColorOrder.indexOf(lookupColor(b))
    );
  }
  if (!a.text) {
    return -1;
  }
  if (!b.text) {
    return 1;
  }

  const aStartsWithNumber = numberExp.test(a);
  const bStartsWithNumber = numberExp.test(b);
  if (aStartsWithNumber && bStartsWithNumber) {
    return parseInt(b) - parseInt(a);
  }

  return b.text < a.text ? 1 : b.text > a.text ? -1 : 0;
};
