import { FormCompatibleError, parseJSONAPIErrors } from './error-types';

export interface ResponseLike {
  [key: string]: string | {} | string[] | {}[];
}

export interface FinalJsonShape {
  ok: boolean;
  json: {
    error: false | FormCompatibleError;
    [key: string]: string | {} | string[] | {}[];
  };
}

const tryToParse = (str) => {
  let json;
  try {
    json = JSON.parse(str).error;
  } catch (err) {
    json = str.split('\n')[0];
  }
  return json;
};

const makeError = (errorJson, status) => {
  const parsedError =
    typeof errorJson === 'string'
      ? tryToParse(errorJson)
      : errorJson.error || errorJson.errors;

  if (parsedError && Array.isArray(parsedError)) {
    return parseJSONAPIErrors(parsedError);
  } else if (parsedError && typeof parsedError === 'object') {
    return parsedError as FormCompatibleError;
  } else if (parsedError) {
    return { _error: parsedError } as FormCompatibleError;
  } else if (Number(status) === 502) {
    return { _error: ['Bad gateway'] } as FormCompatibleError;
  } else {
    return { _error: ['unknown server error'] } as FormCompatibleError;
  }
};

export const normalizePromise = (promise: Promise<ResponseLike | {}>) => {
  return new Promise((resolve, reject) => {
    promise
      .then((res): FinalJsonShape => {
        // response is normalized to make failures fit the pipeline
        return { ok: true, json: { ...res, error: false } };
      })
      .catch((errorResponse) => {
        let normalizedError;
        console.error('ERROR ... (normalize-api-promise.ts)', {
          errorResponse
        });
        if (
          typeof errorResponse.json === 'function' &&
          (errorResponse.headers.get('Content-Type').match(/text\/json/) ||
            errorResponse.headers
              .get('Content-Type')
              .match(/application\/vnd\.api\+json/)) // json api
        ) {
          normalizedError = errorResponse.json();
        } else if (errorResponse.text === 'function') {
          normalizedError = errorResponse.text();
        } else if (errorResponse.response && errorResponse.response.data) {
          normalizedError = errorResponse.response.data;
        } else if (errorResponse.code === 'ERR_CANCELED') {
          normalizedError = errorResponse.code;
        } else {
          normalizedError = errorResponse;
        }

        return Promise.resolve(normalizedError).then((errorJson) => {
          // send a normalized error response
          return {
            ok: false,
            json: {
              error: makeError(errorJson, errorResponse?.response?.status),
              raw: JSON.stringify(errorJson, null, 2)
            }
          };
        });
      })
      .then(({ ok, json }: FinalJsonShape) => {
        // handle final response type
        if (ok) {
          resolve(json);
        } else if (json.error !== false) {
          const error: FormCompatibleError = json.error;
          // eslint-disable-next-line
          reject({
            _error: [
              Object.keys(error).length
                ? 'Request returned with errors'
                : 'Unknown error... Please try refreshing or contact support@cube-cloud.com'
            ],
            ...error
          });
        }
      });
  });
};
