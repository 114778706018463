import { actions as requestStatusActions } from '../ducks/request-status';
import { actions as edgeActions } from '../ducks/resource-edges';
import { actions as nodeActions } from '../ducks/resource-nodes';
import { serialize } from '../ducks/request-status/utils/serialize';

export const cancelRetrieveMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case requestStatusActions.CANCEL_RETRIEVE:
        cancelRetrieve(action.payload.action);
        break;

      case edgeActions.RETRIEVE_RESOURCE_EDGES:
      case edgeActions.RETRIEVE_MORE_RESOURCE_EDGES:
      case nodeActions.RETRIEVE_RESOURCE: {
        const abortSignal = makeCancelable(action);
        //   abortSignal.onabort = console.error;

        action.meta = action.meta || {};
        action.meta.cancelable = {
          abortSignal,
          removeSignal: () => removeCancelable(action)
        };

        break;
      }

      default:
        break;
      // Call the next dispatch method in the middleware chain.
    }
    const results = next(action);
    return results;
  };

const abortControllers = new Map();
const getController = (key) => {
  const ac = abortControllers.get(key);

  return ac;
};

const addController = (key) => {
  const controller = new AbortController();
  abortControllers.set(key, controller);
  return controller;
};

const removeController = (key) => {
  abortControllers.delete(key);
};

const makeCancelable = (action) => {
  const key = serialize(action);
  const controller = getController(key);
  if (!controller) {
    return addController(key).signal;
  }
  return controller.signal;
};

const removeCancelable = (action) => {
  const key = serialize(action);
  removeController(key);
};

const cancelRetrieve = (action) => {
  const key = serialize(action);

  const controller = getController(key);
  if (controller) {
    controller.abort();
  }
};
