import { ResourceBase, PublicResource } from '@cube3/state/types';
import { getNodeDisplayImageSrc } from '@cube3/ui/src/human/nodeDisplayImageResolver';

import shareImage from '@cube3/ui/src/assets/images/Library/share.svg?url';
import downloadImage from '@cube3/cubicle/src/core/atoms/Icon/collection/download.svg?url';

interface Config {
  resource: ResourceBase;
  context: PublicResource;
  action: string;
}

export const getActivityImage = ({ action, resource, context }: Config) => {
  console.log('getActivityImage', action, context);

  if (context?.display_image) {
    return context.display_image;
  }

  switch (action) {
    case 'ShareCreated':
      return shareImage;
    case 'AssetDownloaded':
      return downloadImage;
    default:
      return getNodeDisplayImageSrc(resource);
  }
};
