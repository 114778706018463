import React, { useCallback, useEffect, useRef } from 'react';
import { useModalActions } from '../../../../Modals/modalActions';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { ProjectTemplate } from '@cube3/common/model/schema/resources/project-template';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Project } from '@cube3/common/model/schema/resources/project';
import { getErrorMessage } from '../../../../../../forms/helpers/errors';
import { RenameModalStatelessUI } from '@cube3/ui/src/modals/RenameModalStatelessUI';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

interface Props {
  modalContext: {
    /** from project template context menu */
    projectTemplate?: ProjectTemplate;
    /** from project template setting modal (no project type provided)*/
    visitingProject?: Project;
  };
}
/**
 * - rename project-template
 * - rename donor_project
 */
export const RenameProjectTemplateModal = (props: Props) => {
  const { projectTemplate, visitingProject } = props.modalContext;
  const defaultValue =
    projectTemplate?.display_name || visitingProject?.display_name;

  const [workspaceId] = useCurrentWorkspace();
  const { previousModal, closeAllModals } = useModalActions();

  const allProjectTemplates = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'project-template',
    edgeLabel: 'project-templates'
  });

  const projectId =
    projectTemplate?.relationships.donor_project?.id || visitingProject?.id;
  const templateId =
    projectTemplate?.id ||
    (visitingProject &&
      allProjectTemplates.resources?.find(
        (ty) => ty.relationships.donor_project?.id === visitingProject.id
      )?.id);
  const donorProject = useResource__ALPHA({
    resourceId: projectId,
    resourceType: 'project'
  });

  const [mutateResource, mutateResourceStatus, mutateError] =
    useMutateResource__ALPHA({
      cacheInvalidator: useCallback(
        (r) => [
          r,
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'project-templates'
          },
          {
            type: 'folder',
            id: (visitingProject || donorProject.resource)?.relationships.node
              .id
          } // To keep FolderName up to date
        ],
        [workspaceId, visitingProject]
      )
    });

  /** 1. mutate donor_project display_name */
  const submitted = useRef(false);
  const onRename = useCallback(
    (name: string) => {
      mutateResource({
        type: 'project',
        id: projectId,
        display_name: name
      });
      submitted.current = true;
    },
    [mutateResource, projectId]
  );

  /** 2. if succeeded to mutate donor_project display_name, should mutate project-template name */
  useEffect(() => {
    if (donorProject.resource && submitted.current) {
      mutateResource({
        type: 'project-template',
        id: templateId,
        display_name: donorProject.resource.display_name
      });
      submitted.current = false;
    }
  }, [donorProject.resource, mutateResource]);

  useEffect(() => {
    if (mutateResourceStatus === statuses.SUCCESS && !mutateError) {
      closeAllModals();
    }
  }, [mutateResourceStatus, closeAllModals, mutateError]);

  const error = mutateError ? getErrorMessage(mutateError) : '';

  const loading =
    mutateResourceStatus &&
    mutateResourceStatus !== statuses.SUCCESS &&
    mutateResourceStatus !== statuses.FAILED;
  return (
    <RenameModalStatelessUI
      title="Rename template"
      loading={loading}
      onCloseEvent={previousModal}
      onSubmit={onRename}
      error={error}
      defaultValue={defaultValue}
    />
  );
};
