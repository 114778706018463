import React from 'react';
// UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Surface from '../Surface/Surface';
import { ContentContainerContext } from './WorkspaceLayoutTemplate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: WorkspaceContentsProps) => ({
      alignItems: 'stretch',
      width: '100%',
      display: 'flex',
      height: '100%'
      // marginLeft: '36px',
      // marginRight: '32px'
    }),
    navigationContainer: {
      width: 260 - 48,
      alignItems: 'stretch',
      display: 'flex',
      marginTop: 8,
      marginRight: '24px',
      flexShrink: 0,
      flexGrow: 0,
      height: '100%'
    },
    contentContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexGrow: 1,
      flexShrink: 1,
      height: '100%',
      width: '100%',
      '& > *': {
        width: '100%'
      },
      '& > div#snackbarPortal': {
        width: 'auto'
      }
      // paddingLeft: 8,
      // paddingRight: 8
    }
  })
);

interface WorkspaceContentsProps {
  navigationComponent?: JSX.Element;
  contentComponent?: JSX.Element;
  containerRef?: React.RefObject<HTMLDivElement>;
}

function WorkspaceContents(props: WorkspaceContentsProps) {
  const { containerRef } = props;
  const classes = useStyles(props);
  const { root, navigationContainer, contentContainer } = classes;
  const { navigationComponent, contentComponent } = props;

  return (
    <Surface background={'transparent'} className={root}>
      {navigationComponent && (
        <div className={navigationContainer}>{navigationComponent}</div>
      )}

      {contentComponent && (
        <div className={contentContainer} ref={containerRef}>
          <ContentContainerContext.Provider value={containerRef}>
            {contentComponent}
          </ContentContainerContext.Provider>
        </div>
      )}
    </Surface>
  );
}

export default WorkspaceContents;
