import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useContext } from 'react';

const DEBUG = false;
// const DEBUG = true;

const MAX_DURATION = DEBUG ? 10000 : 1000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'fade-enter': {
      transition: `opacity ${MAX_DURATION * 0.2}ms ease-in ${MAX_DURATION *
        0.2}ms`,
      opacity: 0,
      position: 'absolute',
      display: 'block !important',
      background: DEBUG ? 'green' : undefined
    },

    'fade-enter-active': {
      opacity: 1,
      pointerEvents: 'none'
    },
    'fade-exit': {
      opacity: 1,
      transition: `opacity ${MAX_DURATION * 0.2}ms ease-out`,
      position: 'absolute',
      display: 'block !important',
      background: DEBUG ? 'red' : undefined
    },
    'fade-exit-active': {
      opacity: 0,
      pointerEvents: 'none'
    },
    'fade-exit-done': {
      display: 'none'
    },

    'slide-enter': {
      transition: `transform ${MAX_DURATION * 0.45}ms ease ${MAX_DURATION *
        0.3}ms, opacity ${MAX_DURATION * 0.45}ms linear ${MAX_DURATION *
        0.3}ms`,
      transform: 'translate3d(10vw,0,0)',
      opacity: 0,
      position: 'absolute',
      display: 'block !important',
      background: DEBUG ? 'green' : undefined
    },

    'slide-back-enter': {
      transition: `transform ${MAX_DURATION * 0.45}ms ease ${MAX_DURATION *
        0.3}ms, opacity ${MAX_DURATION * 0.45}ms linear ${MAX_DURATION *
        0.3}ms`,
      transform: 'translate3d(-10vw,0,0)',
      opacity: 0,
      position: 'absolute',
      display: 'block !important',
      background: DEBUG ? 'green' : undefined
    },

    'slide-enter-active': {
      transform: 'translate3d(0,0,0)',
      opacity: 1,
      pointerEvents: 'none'
    },
    'slide-enter-done': {
      transform: 'translate3d(0,0,0)',
      opacity: 1
    },

    'slide-exit': {
      transform: 'translate3d(0,0,0)',
      opacity: 1,
      transition: `transform ${MAX_DURATION * 0.55}ms, opacity ${MAX_DURATION *
        0.45}ms linear`,
      position: 'absolute',
      display: 'block !important',
      background: DEBUG ? 'red' : undefined
    },
    'slide-exit-active': {
      transform: 'translate3d(-20vw,0,0)',
      opacity: 0,
      pointerEvents: 'none'
    },
    'slide-back-exit-active': {
      transform: 'translate3d(20vw,0,0)',
      opacity: 0,
      pointerEvents: 'none'
    },
    'slide-exit-done': {
      display: 'none'
    }
  }));

type AnimatableProps = TransitionProps ;


const Animatable: React.FC<Partial<AnimatableProps>> = ( props ) => {
    const {
      animationName = 'slide',
      direction = 'forward'
    } = props;

    const classes = useStyles();

    const directionalAnimationName = `${animationName}-${direction}`;
    const skip = direction === 'none';

    return (
      <CSSTransition
        timeout={MAX_DURATION}
        in={props.in}
        appear={skip ? false : props.appear}
        exit={skip ? false : props.exit}
        enter={skip ? false : props.enter}
        onEnter={node => node.scrollTop}
        data-type="librarydeselectoverride"
        unmountOnExit={true}
        mountOnEnter={true}
        classNames={{
          appear:
            classes[`${directionalAnimationName}-enter`] ||
            classes[`${animationName}-enter`],
          appearActive:
            classes[`${directionalAnimationName}-enter-active`] ||
            classes[`${animationName}-enter-active`],
          enter:
            classes[`${directionalAnimationName}-enter`] ||
            classes[`${animationName}-enter`],
          enterActive:
            classes[`${directionalAnimationName}-enter-active`] ||
            classes[`${animationName}-enter-active`],
          enterDone:
            classes[`${directionalAnimationName}-enter-done`] ||
            classes[`${animationName}-enter-done`],
          exit:
            classes[`${directionalAnimationName}-exit`] ||
            classes[`${animationName}-exit`],
          exitActive:
            classes[`${directionalAnimationName}-exit-active`] ||
            classes[`${animationName}-exit-active`],
          exitDone:
            classes[`${directionalAnimationName}-exit-done`] ||
            classes[`${animationName}-exit-done`]
        }}
      >
        {props.children}
      </CSSTransition>
    );
}

const childFactory = direction => child => {
  return { ...child, props: { ...child.props, direction } };
};

export const AnimationGroup = ({ direction = undefined, ...props }) => (
  <TransitionGroup
    {...props}
    appear={true}
    component={null}
    childFactory={childFactory(direction)}
  />
);
export const AnimationItem = Animatable;

export const animationContext = React.createContext(null);

export const AnimationStateProvider = props => {
  const { state, children } = props;
  return <animationContext.Provider value={state} children={children} />;
};

export const useAnimationState = () => {
  return useContext(animationContext);
};
