/* React imports */
import * as React from 'react';
import App from '../app';
import '@cube3/ui/src//assets/fonts/inter/inter.css';
import Loadable from 'react-loadable';
import Fontobserver from 'fontfaceobserver';
import { theme } from '@cube3/ui/src/themes/custom-theme';
import Desk from '@cube3/ui/src/desk';
import background1 from '@cube3/ui/src/assets/images/theme/Base1.jpg';
import background2 from '@cube3/ui/src/assets/images/theme/Base2.jpg';
import Cube3Button from '@cube3/ui/src/Buttons/Cube3Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { apiRoot } from '@cube3/state/src/config';

import projectImage from '@cube3/ui/src/assets/images/Library/project_new.svg?url';
import projectDone from '@cube3/ui/src/assets/images/Library/project_archived.svg?url';
import projectUnknown from '@cube3/ui/src/assets/images/Library/project_unknown.svg?url';
import folderImage from '@cube3/ui/src/assets/images/Library/folder.svg?url';
import fileImage from '@cube3/ui/src/assets/images/Library/file.svg?url';
import audioFileIcon from '@cube3/ui/src/assets/images/Library/audio.svg?url';
import archiveFileIcon from '@cube3/ui/src/assets/images/Library/archive.svg?url';
import composeIcon from '@cube3/ui/src/assets/images/Library/compose.svg?url';
import SupportEmailComponent from '@cube3/ui/src/Email/SupportEmailComponent';

let autoRetried = false;
let retried = false;
let ignored = false;

let Preloader = Loadable.Map({
  loader: {
    Fontobserver: () => {
      // After a couple of seconds allow the app to load anyway (with fallback font)
      if (ignored) {
        console.error(
          'Ignored missing typeface',
          theme.typography.fontFamily.split(',')[0]
        );

        return Promise.resolve(null);
      }

      const promises = [400, 600, 700].map((weight) => {
        const fo = new Fontobserver(theme.typography.fontFamily.split(',')[0], {
          weight
        });
        return fo.load('abc123XYZ$%@', 3000);
      });

      return Promise.all(promises).catch((err) => {
        console.error(
          'Failed to load typeface',
          theme.typography.fontFamily.split(',')[0],
          err
        );
        return Promise.reject('Primary typeface failed to load');
      });
    },
    Gateway: () => {
      const a = document.createElement('a');
      a.setAttribute('href', apiRoot);
      const api = new URL(a.href);
      return fetch(`${api.origin}${apiRoot}`)
        .then((res) => {
          return res.status !== 200 || res.headers.get('Content-Type')
            ? Promise.reject(`Api Gateway unaccessible at ${apiRoot}`)
            : Promise.resolve('Api Gateway ready');
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(`Api Gateway unaccessible at ${apiRoot}`);
        });
    },
    Icons: () => {
      const images = [
        // { name: 'test', url: 'http://localhost:101/tes.png' },
        { name: 'Project', url: projectImage },
        { name: 'Archived Project', url: projectDone },
        { name: 'Indeterminate Project', url: projectUnknown },
        { name: 'Folder', url: folderImage },
        { name: 'Asset', url: fileImage },
        { name: 'Audio Asset', url: audioFileIcon },
        { name: 'Archive Asset', url: archiveFileIcon },
        { name: 'Compose Asset', url: composeIcon }
      ].map((image) => {
        return new Promise((res, rej) => {
          const img = new Image();
          img.onload = res;
          img.onerror = (err) => {
            console.error(err);
            return rej(`${image.name}" icon failed to load`);
          };
          img.src = image.url;
        });
      });

      return Promise.all(images);
    },
    Surface: () => {
      const images = [
        // { name: 'test', url: 'http://localhost:101/tes.png' },
        { name: 'background1', url: background1 },
        { name: 'background2', url: background2 }
      ].map((image) => {
        return new Promise((res, rej) => {
          const img = new Image();
          img.onload = res;
          img.onerror = (err) => {
            console.error(err);
            return rej(`Image "${image.name}" failed to load`);
          };
          img.src = image.url;
        });
      });

      return Promise.all(images);
    }
  },
  loading: (props) => {
    if (props.error) {
      console.error('preloader error:', props.error);

      if (!autoRetried) {
        autoRetried = true;
        props.retry();
        return <Desk />;
      }

      const handleSupportClick = () => {
        window.open(
          'mailto:support@cube-cloud.com',
          '_blank',
          'noopener noreferrer'
        );
      };

      const handleRetry = () => {
        if (retried) {
          ignored = true;
        }
        retried = true;
        props.retry();
      };

      const canIgnore =
        retried && props.error === 'Primary typeface failed to load';

      return (
        <Desk>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column wrap',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
              flexShrink: 1
            }}
          >
            <div
              style={{
                width: '48ex',
                maxWidth: '100vw',
                flexShrink: 1,
                padding: 8,
                boxSizing: 'border-box'
              }}
            >
              <Typography
                typographyStyle="heading1"
                typographyProps={{ gutterBottom: true }}
              >
                Error loading Cube application resources
              </Typography>
              <Typography
                typographyStyle="body1"
                color="contrast2"
                typographyProps={{ gutterBottom: true }}
              >
                One or more required resources could not be retrieved.
                <ul>
                  <li>{props.error}</li>
                </ul>
              </Typography>
              <Typography typographyStyle="body1" color="contrast2">
                If this happens again, it may indicate an issue with:
                <ul>
                  <li>Your internet connection</li>
                  <li>Cube backend services</li>
                </ul>
              </Typography>
              <Divider gutter={true} />
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row-reverse wrap',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  marginBottom: 64
                }}
              >
                <Cube3Button
                  text={canIgnore ? 'Ignore' : 'Retry'}
                  onClick={handleRetry}
                />
                <Cube3Button
                  colorVariant="ghost1"
                  onClick={handleSupportClick}
                  text="Contact support"
                />
              </div>
              <Typography
                typographyStyle="link2"
                color="contrast3"
                typographyProps={{ align: 'left' }}
              >
                <SupportEmailComponent />
              </Typography>
            </div>
          </div>
        </Desk>
      );
    } else {
      return <Desk />;
    }
  },

  render() {
    return <App />;
  }
});

export default Preloader;
