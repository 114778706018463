import { uuidv4 } from '../../../utils/uuid';
import { actionCreators as edgeActionCreators } from '../../ducks/resource-edges';
import { updateUpload } from './updateUpload';

/**
 * Add placeholder uploads and connect to parrent resource
 * @param dispatch rootstore method
 * @param getState rootstore method
 */
export const handleFilesPreCreate = (dispatch, getState) => params => {
  if (!params.files) {
    return { uploadIds: [] };
  }
  const uploadIds = params.files.map(f => f.id || uuidv4());

  params.files.forEach((f, idx) => {
    // TODO: clean up this logic
    if (f.id) {
      return; // expect this already has been handled in uploads-middlware
    }

    updateUpload(
      f.id || uploadIds[idx],
      {
        progress: 0,
        failed: false,
        file: f.raw,
        display_name: f.name,
        tusUpload: undefined,
        active: false,
        asset_id: null
      },
      dispatch,
      getState
    );

    dispatch(
      edgeActionCreators.receiveResourceEdges(
        params.ancestorId,
        params.ancestorType,
        'file-uploads',
        'file-upload',
        [
          {
            id: f.id || uploadIds[idx],
            type: 'file-upload'
          }
        ],
        { merge: true }
      )
    );
  });

  return { uploadIds };
};
