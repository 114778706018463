import React from 'react';
import { Typography } from '../typography/Typography';

export const WaterfallValue = (props) => {
  const { value: wrappedValue = {} } = props;
  const { value } = wrappedValue;

  if (!value) {
    return null;
  }

  return (
    <div>
      <Typography variant="body1" component="span" display="inline">
        {Object.keys(value)[0]}
      </Typography>
      {value[Object.keys(value)[0]]?.length > 0 && (
        <Typography
          variant="body2"
          color="contrast2"
          component="span"
          display="inline"
        >
          {': '}
          {value[Object.keys(value)[0]].join(', ')}
        </Typography>
      )}
    </div>
  );
};
