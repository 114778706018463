import { YoutubePlaylist } from '@cube3/common/model/types';
import { useCallback } from 'react';

export const visibilities = ['Private', 'Unlisted', 'Public'];

export const initialExportOptionsState = {
  title: undefined as string,
  description: undefined as string,
  visibility: 'Private',
  tags: [],
  recipients: [],
  category: undefined as string,
  playlists: [] as YoutubePlaylist[]
};

export type ExportOptionsState = typeof initialExportOptionsState;

export interface ExportOptionsFormState extends ExportOptionsState {
  invalid: boolean;
}

type ExportOptionsType =
  | 'CHANGE_TITLE'
  | 'ADD_DESCRIPTION'
  | 'CHANGE_VISIBILITY'
  | 'ADD_TAG'
  | 'DELETE_TAG'
  | 'ADD_RECIPIENT'
  | 'DELETE_RECIPIENT'
  | 'ADD_CATEGORY'
  | 'REINITIALIZE';

type ExportOptionsPayload<T> = T extends 'REINITIALIZE'
  ? ExportOptionsState
  : string;

export interface ExportOptionsAction<T = unknown> {
  type: T;
  payload?: ExportOptionsPayload<T>;
}

export const exportOptionsReducer = (
  state: ExportOptionsState,
  action: ExportOptionsAction
) => {
  switch (action.type) {
    case 'REINITIALIZE':
      return action.payload
        ? { ...(action as ExportOptionsAction<'REINITIALIZE'>).payload }
        : state;
    case 'CHANGE_TITLE':
      return {
        ...state,
        title: action.payload
      };
    case 'ADD_DESCRIPTION':
      return {
        ...state,
        description: action.payload
      };
    case 'CHANGE_VISIBILITY':
      return {
        ...state,
        visibility: action.payload
      };
    case 'ADD_TAG':
      return {
        ...state,
        tags: [...state.tags, action.payload]
      };
    case 'DELETE_TAG':
      return {
        ...state,
        tags: !action.payload
          ? state.tags.slice(0, -1)
          : state.tags.filter((val) => val !== action.payload)
      };
    case 'CHANGE_PLAYLISTS':
      return {
        ...state,
        playlists: state.playlists.find((p) => p.id === action.payload['id'])
          ? state.playlists.filter((p) => p.id !== action.payload['id'])
          : [...state.playlists, action.payload]
      };
    case 'ADD_RECIPIENT':
      return {
        ...state,
        recipients: [...state.recipients, action.payload]
      };
    case 'DELETE_RECIPIENT':
      return {
        ...state,
        recipients: !action.payload
          ? state.recipients.slice(0, -1)
          : state.recipients.filter((val) => val !== action.payload)
      };
    case 'ADD_CATEGORY':
      return {
        ...state,
        category: action.payload
      };
    default:
      return state;
  }
};

export const useExportDataHandlers = (dispatch) => {
  const onChangeTitle = useCallback(
    (e) => {
      dispatch({
        type: 'CHANGE_TITLE',
        payload: e.target.value
      });
    },
    [dispatch]
  );

  const onChangeDescription = useCallback(
    (e) => {
      dispatch({
        type: 'ADD_DESCRIPTION',
        payload: e.target.value
      });
    },
    [dispatch]
  );

  const onAddTag = useCallback(
    (val) => dispatch({ type: 'ADD_TAG', payload: val }),
    [dispatch]
  );

  const onDeleteTag = useCallback(
    (val) => dispatch({ type: 'DELETE_TAG', payload: val }),
    [dispatch]
  );

  const onChangeVisibility = useCallback(
    (val) => dispatch({ type: 'CHANGE_VISIBILITY', payload: val }),
    [dispatch]
  );

  const onChangePlaylists = useCallback(
    (val) => dispatch({ type: 'CHANGE_PLAYLISTS', payload: val }),
    [dispatch]
  );

  const onAddRecipient = useCallback(
    (val) => dispatch({ type: 'ADD_RECIPIENT', payload: val }),
    [dispatch]
  );

  const onDeleteRecipient = useCallback(
    (val) => dispatch({ type: 'DELETE_RECIPIENT', payload: val }),
    [dispatch]
  );

  return {
    onChangeTitle,
    onChangeDescription,
    onChangeVisibility,
    onChangePlaylists,
    onAddTag,
    onDeleteTag,
    onAddRecipient,
    onDeleteRecipient
  };
};
