import React, { useCallback, useRef, useState } from 'react';

// hooks
import { useModalActions } from '../../Modals/modalActions';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  QualityCheckStatesType,
  useMergeQualityCheckStatus
} from '../hooks/useTVadMergeQualityCheckStatus';
import { Asset } from '@cube3/common/model/schema/resources/asset';

// UI
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import TVadExportQualityCheckListItem, {
  AssetValidationType
} from '../prefabs/TVadExportQualityCheckListItem';

export interface BroadcastModalContextProps {
  assets: Asset[];
  exportTargets: Array<{ id: string; type: 'export-target' }>;
  exportTargetType: {
    type: 'export-target-type';
    id: string;
  };
}
type Props = {
  modalContext: BroadcastModalContextProps;
};

export const TVadExportQualityCheckModal = (props: Props) => {
  const { assets, exportTargets, exportTargetType } = props.modalContext;
  const [qualityCheckStates, setQualityCheckStates] =
    useState<QualityCheckStatesType>({});

  const { previousModal, goBackModals, openModal } = useModalActions();

  const config = useRef({
    edgeType: 'validator' as const,
    edgeLabel: 'validators',
    params: {
      filter: {
        //  mime_type: mime_types.ASSET_VIDEO,
        exportTargetTypeId: exportTargetType.id
      }
    }
  }).current;
  const { resources: validators, loading: retrievingValidators } =
    useResourceList__ALPHA(config);

  const onNext = useCallback(() => {
    openModal('export_TVad_data', { assets, exportTargets, exportTargetType });
  }, [openModal, assets, exportTargets, exportTargetType]);

  const onCancel = useCallback(() => {
    goBackModals(2);
  }, [goBackModals]);

  const allStatus = useMergeQualityCheckStatus(qualityCheckStates);

  /** get the latest validation results */
  const updateStates = useCallback(
    (assetId: string, validation: AssetValidationType) => {
      setQualityCheckStates((prev) => {
        if (prev[assetId]) {
          delete prev[assetId];
        }
        return { ...prev, [assetId]: validation };
      });
    },
    [setQualityCheckStates]
  );

  const isChecking =
    allStatus.failed.length + allStatus.passed.length < assets.length;

  return (
    <ModalMenuUI
      extraWide={true}
      title="Export TV ad • Quality check"
      onCloseEvent={previousModal}
      loading={!validators || retrievingValidators}
      footerLeftComponent={
        <Button text={'Cancel'} colorVariant={'ghost2'} onClick={onCancel} />
      }
      footerRightComponent={
        <>
          <Button
            text={'Previous'}
            onClick={previousModal}
            colorVariant={'ghost2'}
          />
          <Button
            disabled={!!allStatus.failed.length || isChecking}
            colorVariant={'filled1'}
            text={'Next'}
            onClick={onNext}
          />
        </>
      }
    >
      <div style={{ padding: '12px 0' }}>
        <Typography>
          {isChecking
            ? 'Quality check in progress...'
            : 'Quality check finished.'}
        </Typography>
        <div style={{ height: '16px' }}></div>

        {assets.length > 1 && (
          <>
            {!!allStatus.passed.length && (
              <Typography gutter={true} color="contrast2" variant="body2">
                {renderMessage(
                  'passed',
                  allStatus.passed.length,
                  assets.length
                )}
              </Typography>
            )}
            {!!allStatus.failed.length && (
              <Typography color="danger1" gutter={true}>
                {renderMessage(
                  'failed',
                  allStatus.failed.length,
                  assets.length
                )}
              </Typography>
            )}{' '}
          </>
        )}
      </div>
      <Divider />

      <div style={{ padding: '12px 0' }}>
        {assets.map((asset) => (
          <TVadExportQualityCheckListItem
            key={asset.id}
            asset={asset}
            validators={validators}
            updateStates={updateStates}
          />
        ))}
      </div>
    </ModalMenuUI>
  );
};

const renderMessage = (
  type: 'failed' | 'passed',
  failedCount: number,
  allCount: number
) => {
  return `${failedCount} out of ${allCount} ${
    allCount > 1 ? 'assets' : 'asset'
  } ${failedCount > 1 ? 'have' : 'has'} ${type} quality check.`;
};
