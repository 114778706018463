import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface ForgotPassword extends ResourceBase {
  email_address: string;
}

export const forgotPassword: RawModel = {
  type: 'forgot-password',
  plural: 'forgot-password',
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
