import { useCallback, useEffect, useRef } from 'react';
import { isValidURL } from './utils';
import { clearClipboard } from '@cube3/common/model/customEvents';
import { environment } from '../../../state/src/utils/environment';

export const usePasteUrlFromClipboard = () => {
  const clipboardUrl = useRef('');

  const handlePaste = useCallback((ev: ClipboardEvent) => {
    // ignore paste event if any input type field is active
    const focusEl = document.activeElement;
    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(focusEl.nodeName)) {
      return;
    }
    ev.preventDefault();
    const eventData = document.hasFocus()
      ? ev.clipboardData?.getData?.('text')
      : undefined;
    if (isValidURL(eventData)) {
      clipboardUrl.current = eventData;
    } else {
      clipboardUrl.current = '';
    }
    console.info('DEBUG - paste event', eventData);
  }, []);

  const onClearClipboardUrl = useCallback(() => {
    if (document.hasFocus() && environment !== 'test') {
      navigator.clipboard.writeText('');
    }
    clipboardUrl.current = '';
    console.info('DEBUG - clear url', clipboardUrl.current);
  }, []);

  useEffect(() => {
    window.addEventListener(clearClipboard, onClearClipboardUrl, true);
    window.addEventListener('paste', handlePaste, true);
    return () => {
      window.removeEventListener(clearClipboard, onClearClipboardUrl, true);
      window.removeEventListener('paste', handlePaste, true);
    };
  }, [onClearClipboardUrl, handlePaste]);

  return { clipboardUrl, onClearClipboardUrl };
};
