import { Tag } from '@cube3/common/model/schema/resources/tag';
import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { useSelection } from '@cube3/state/src/redux/components/useSelection';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import React, { useCallback, useMemo } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { Scrollbar } from '@cube3/cubicle/src/core/atoms/Scrollbar';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { Typography } from '@cube3/ui/src/typography/Typography';

interface Props {
  modalContext: {
    tag: Tag;
    onCrossClick: () => void;
  };
}

export const RemoveTagFromAssetsNotice = (props: Props) => {
  const { tag, onCrossClick } = props.modalContext;

  const { selection } = useSelection();

  const { previousModal } = useModalActions();

  const { resources: selectedNodes } = useResource__ALPHA({
    resourceIdentifiers: selection?.map((s) => ({
      type: 'content-tree-node',
      id: s.id
    })),
    strategy: 'fetch-on-mount'
  });

  const appliedNodes = useMemo(() => {
    if (!selectedNodes?.length || !tag) return [];
    return selectedNodes.filter(
      (sl) => !!sl.relationships.tags?.find((slt) => slt.id === tag.id)
    );
  }, [selectedNodes, tag]);

  const removeTag = useCallback(() => {
    onCrossClick();
    previousModal();
  }, [previousModal, onCrossClick]);

  return (
    <ModalNoticeUI
      title={`Are you sure you want to remove ${tag.text} tag`}
      loading={!tag || !selectedNodes}
      footerRightComponent={
        <>
          <Button
            onClick={previousModal}
            background="secondary"
            buttonStyle="ghost"
            label=" Cancel"
          />

          <Button
            background="primary"
            buttonStyle="solid"
            onClick={removeTag}
            label="Remove tag"
          />
        </>
      }
    >
      {selection?.length === 0 ? (
        <Typography>No selection</Typography>
      ) : appliedNodes?.length ? (
        <Scrollbar autoHeight={true} autoHeightMax={320}>
          <Typography color="contrast2">
            {`The ${tag.text} is used on ${appliedNodes.length} ${
              appliedNodes.length > 1 ? 'assets' : 'asset'
            }. Are you sure you want to remove it?`}
          </Typography>
          <ul
            style={{
              margin: 0,
              padding: '8px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: 4
            }}
          >
            {appliedNodes.map((an) => (
              <Typography key={an.id} color="contrast2">
                <li>{an.display_name}</li>
              </Typography>
            ))}
          </ul>
        </Scrollbar>
      ) : null}
    </ModalNoticeUI>
  );
};
