import React, { useCallback } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useClassName } from '../utils/useClassName';
import { Typography } from '../typography/Typography';
import { TagColor, TagSize, TagUI } from './types';
import { lookupColor, TAG_COLORS } from './colors';
import GeneralSVG, { iconClose } from '../icons/GeneralSVG';
import { useCubicleTheme } from '@cube3/cubicle/src/theme/CubicleThemeProvider';

interface Props {
  /** color of the tag */
  color?: TagColor;
  /** size of the tag */
  size?: TagSize;
  /** text label */
  text?: string;
  /** only show a round tag, without text */
  dotOnly?: boolean;
  /** makes it look as if the next tag overlaps this one */
  bite?: boolean;
  /** disable negative margin that bite tags have */
  lastBite?: boolean;
  /** add whitespace gutter below tag */
  gutter?: boolean;
  /** show interaction prompts like hover effects, cursor changes */
  interactive?: boolean;
  /** callback for when tag is clicked */
  onClick?(ev: React.MouseEvent): void;
  /** callback, that when set, adds a cross icon to the tag that triggers it when clicked */
  onCrossClick?(ev: React.MouseEvent): void;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.surfaceCorners.circular,
        marginLeft: 4,
        marginRight: 4,
        overflow: 'hidden',
        position: 'relative'
      },
      gutter: {
        marginBottom: 8
      },
      dotOnly: {},
      'size-regular': {
        height: 24,
        padding: '0px 12px',

        '&$dotOnly': {
          width: 24
        }
      },
      'size-small': {
        height: 16,
        padding: '0px 6px',
        '&$dotOnly': {
          width: 16
        }
      },
      'color-red': { backgroundColor: TAG_COLORS.RED },
      'color-orange': { backgroundColor: TAG_COLORS.ORANGE },
      'color-yellow': { backgroundColor: TAG_COLORS.YELLOW },
      'color-green': { backgroundColor: TAG_COLORS.GREEN },
      'color-blue': { backgroundColor: TAG_COLORS.BLUE },
      'color-purple': { backgroundColor: TAG_COLORS.VIOLET },
      'color-violet': { backgroundColor: TAG_COLORS.VIOLET },
      'color-grey': { backgroundColor: TAG_COLORS.GREY },
      'color-gray': { backgroundColor: TAG_COLORS.GREY },
      'color-white': { backgroundColor: TAG_COLORS.WHITE },
      'color-black': { backgroundColor: TAG_COLORS.BLACK },
      'color-indigo': { backgroundColor: TAG_COLORS.INDIGO },
      'color-ghost': {
        backgroundColor: TAG_COLORS.GHOST,
        border: '1px solid rgba(255,255,255,0.6)'
      },
      bite: {
        marginLeft: 0,
        '&$size-regular': {
          clipPath: `path('M0,0 L27,-1 A12,12,0,0,0,27,25 L0,24')`,
          marginRight: -8
        },
        '&$size-small': {
          clipPath: `path('M0,0 L19,-1 A8,8,0,0,0,19,17 L0,16')`,
          marginRight: -4
        },
        '&$lastBite': {
          marginRight: 0,
          clipPath: 'none'
        }
      },
      lastBite: {},
      hasCross: {
        '&:not($dotOnly)': {
          '&$size-regular': {
            paddingRight: 22
          },
          '&$size-small': {
            paddingRight: 13.5
          }
        }
      },
      cross: {
        position: 'absolute',
        left: 'auto',
        right: 0,
        top: 0,
        width: 24,
        height: 24,
        '$color-ghost &': {
          top: -1,
          right: -1
        },
        '$size-small &': {
          width: 16,
          height: 16
        },

        '& svg': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }
      },
      interactive: {
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.7
        },
        '&:active': {
          opacity: 0.4
        }
      }
    }),
  { name: 'C3Tag' }
);

export const Tag: React.FunctionComponent<Props> = (props) => {
  const {
    color: colorProp = 'ghost',
    text,
    dotOnly = false,
    bite = false,
    size = 'regular',
    lastBite = false,
    gutter = true,
    interactive = false,
    onClick,
    onCrossClick
  } = props;
  const classes = useStyles();

  const color = lookupColor(colorProp);

  const crossClasses = useClassName(classes.cross, classes.interactive);

  const handleCrossClick = useCallback(
    (ev) => {
      onCrossClick(ev);
      ev.stopPropagation();
    },
    [onCrossClick]
  );

  return (
    <div
      className={useClassName(
        classes.root,
        color && classes[`color-${color}`],
        size && classes[`size-${size}`],
        dotOnly && classes.dotOnly,
        bite && classes.bite,
        lastBite && classes.lastBite,
        gutter && classes.gutter,
        (interactive || onClick) && classes.interactive,
        onCrossClick && classes.hasCross
      )}
      onClick={onClick}
    >
      {text && !dotOnly && (
        <>
          <Typography
            color="contrast1"
            typographyStyle={size === 'regular' ? 'body2' : 'body3'}
            surface={color !== 'ghost' ? 'onBrand' : 'onBase'}
          >
            <span>{text}</span>
          </Typography>
          {onCrossClick && (
            <div onClick={handleCrossClick} className={crossClasses}>
              <GeneralSVG
                size="small"
                path={iconClose}
                color="contrast1"
                surface={color !== 'ghost' ? 'onBrand' : 'onBase'}
              />
            </div>
          )}
        </>
      )}
      {(!text || dotOnly) && (
        <>
          <Typography typographyStyle={size === 'regular' ? 'body2' : 'body3'}>
            {!onCrossClick ? ' ' : null}
          </Typography>
          {onCrossClick && (
            <div onClick={handleCrossClick} className={crossClasses}>
              <GeneralSVG
                size="small"
                path={iconClose}
                color="contrast1"
                surface={color !== 'ghost' ? 'onBrand' : 'onBase'}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
