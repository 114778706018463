export const recipientsEqual = (newArray, oldArray) => {
  if (!newArray && !oldArray) {
    return true;
  }

  if (Array.isArray(newArray) !== Array.isArray(oldArray)) {
    return false;
  }

  if (Array.isArray(newArray)) {
    // different lengths, so something changed for sure
    if (newArray.length !== oldArray.length) {
      return false;
    }

    if (
      // total unique emails in two arrays combined differs from current value, so something changed for sure
      Array.from(new Set([...oldArray, newArray].map(e => e.email_address)))
        .length !== newArray.length
    ) {
      return false;
    }
  }
  // something may have changed, but only in the order of items
  return true;
};
