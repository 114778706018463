import React, { useCallback, useEffect } from 'react';

import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { compose } from '../../../../../utils/component-helpers';
import { reduxForm, FieldArray, clearFields, getFormValues } from 'redux-form';
import { useModalActions } from '../../Modals/modalActions';

import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  MultipleResourcePicker,
  SuggestionResource
} from '../prefabs/MultipleResourcePicker/MultipleResourcePicker';
import { useDispatch } from 'react-redux';
import Button from '@cube3/ui/src/Buttons/Button';
import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';

import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { getChanges } from '@cube3/state/src/redux/middleware/cube-client-middleware/contract-submit-middleware/utils/getChanges';
import { useContractSuggestions } from '../prefabs/MultipleResourcePicker/variants/useContractSuggestions';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { ClearOn } from '@cube3/ui/src/inputChipPicker/SuggestionsContainer/SuggestionsContainer';

export const AttachExistingContractModalBase = (props) => {
  const {
    modalContext,
    form,
    handleSubmit,
    pristine,
    submitSucceeded,
    submitting
  } = props;
  const { parentResource } = modalContext;
  const modalActions = useModalActions();

  const parentResourceContracts = useResourceList__ALPHA({
    resourceType: parentResource.type,
    resourceId: parentResource.id,
    edgeType: 'contract',
    edgeLabel: 'contracts'
  });

  const query = useTypedSelector((state) => {
    return (
      getFormValues(form)(state) &&
      getFormValues(form)(state)[`attached_contracts_inputValue`]
    );
  });

  const [suggestions, excluded, suggestionsLoading] = useContractSuggestions({
    query,
    selected: parentResourceContracts.resources
  });

  const dispatch = useDispatch();

  const clearInputHandler = useCallback(() => {
    dispatch(clearFields(form, true, true, 'attached_contracts_inputValue'));
  }, [dispatch, form]);

  useEffect(() => {
    if (submitSucceeded) {
      modalActions.goBackModals(2);
    }
  }, [submitSucceeded, modalActions]);

  return (
    <ModalNoticeUI
      title="Attach existing contract"
      component="form"
      onCloseEvent={modalActions.previousModal}
      loading={submitting}
      extraComponentProps={
        //important for forms
        {
          onSubmit: handleSubmit
        }
      }
      footerRightComponent={
        pristine ? (
          <Button
            colorVariant="filled1"
            text="Done"
            onClick={modalActions.previousModal}
          />
        ) : (
          <>
            <Button
              colorVariant="ghost2"
              text="Cancel"
              onClick={modalActions.previousModal}
              disabled={submitting}
            />
            <Button
              extraButtonProperties={{ type: 'submit' }}
              colorVariant="filled1"
              text="Attach"
              disabled={submitting}
            />
          </>
        )
      }
    >
      <div style={{ minHeight: 500 }}>
        <FieldArray
          component={MultipleResourcePicker}
          name="attached_contracts"
          props={{
            suggestedResources: suggestions as SuggestionResource[],
            excludedResources: excluded as SuggestionResource[],
            clearInput: clearInputHandler,
            form,
            clearOn: ['blur'] as ClearOn,
            excludedReasonText: 'Already attached',
            suggestionsLoading,
            placeholder: 'Start typing a contract name...'
          }}
        />
      </div>
    </ModalNoticeUI>
  );
};

// NOTE: this would allow showing/deleting existing attached contracts in the input
//
// const withInitialValues = Wrapped => props => {
//   const { modalContext } = props;
//   const { parentResource } = modalContext;

//   const parentResourceContracts = useResourceList__ALPHA({
//     resourceType: parentResource.type,
//     resourceId: parentResource.id,
//     edgeType: 'contract',
//     edgeLabel: 'contracts',
//     params: {
//       page: { size: 50 }
//     },
//     allPages: true
//   });

//   if (parentResourceContracts.status !== statuses.SUCCESS) {
//     return (
//       <ModalNoticeUI
//         title="Attach existing contract"
//         component="form"
//         loading={true}
//       >
//         {' '}
//         <div style={{ minHeight: 500 }} />
//       </ModalNoticeUI>
//     );
//   }

//   return (
//     <Wrapped
//       {...props}
//       initialValues={{ attached_contracts: parentResourceContracts.resources }}
//     />
//   );
// };

const withSubmit = (Wrapped) => (props) => {
  const {
    // initialValues,
    modalContext
  } = props;
  const { parentResource } = modalContext;

  const [mutate] = useMutateRelationship__ALPHA({
    ancestor: parentResource,
    relationship: 'contracts',
    cacheInvalidator: (res, mut, anc, rel) => [
      {
        id: res.id,
        type: 'contract',
        relationship: `${anc.type}s`
      },
      {
        id: anc.id,
        type: anc.type,
        relationship: 'contracts'
      }
    ],

    actionDecorators: [
      addFormId('attach_contracts', {
        formState: undefined,
        useRequestStatus: true,
        delegate: true
      })
    ]
  });

  const handleSubmit = useCallback(
    (values) => {
      const changes = getChanges(
        // initialValues.attached_contracts,
        [],
        values.attached_contracts
      );

      mutate(changes);
    },
    [
      // initialValues,
      mutate
    ]
  );

  return <Wrapped {...props} onSubmit={handleSubmit} />;
};

interface Fields {
  attached_contracts: ResourceIdentifier[];
}

export const AttachExistingContractModal = compose(
  AttachExistingContractModalBase
)(
  // withInitialValues,
  withSubmit,
  reduxForm<Fields, { formSubmit(values: Fields): void }>({
    form: 'attach_contracts'
  })
);
