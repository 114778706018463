import React, { useCallback } from 'react';

import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { ProjectMemberUserDetailUI } from '@cube3/ui/src/Projects/Modals/ProjectMemberUserDetailUI';
import { useModalActions } from '../../Modals/modalActions';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useTeamSuggestions } from '../../AdminCenter/views/WorkspaceTeamsView/hooks/useTeamSuggestions';
import ProjectTeamRow from '@cube3/ui/src/Teams/TeamSuggestionRowUI';
import { ProjectTeamMembership } from '@cube3/common/model/schema/resources/project-team-membership';
import { PROJECT_ROLE_TYPES } from '@cube3/state/types';

interface ProjectTeamDetailProps {
  projectTeam: ProjectTeamMembership;
  canEdit: boolean;
  isLastAdmin?: boolean;
}

const ProjectTeamDetail = (props: ProjectTeamDetailProps) => {
  const { projectTeam, canEdit, isLastAdmin } = props;

  const { openModal } = useModalActions();
  const teamId = projectTeam?.relationships?.team.id;

  const team = useResource__ALPHA({
    resourceType: 'team',
    resourceId: teamId
  }).resource;

  const { members, loading } = useTeamSuggestions(team?.id);

  const onChangeRoleClick = useCallback(
    (role) => {
      if (isLastAdmin && role.role !== 'Admin') {
        openModal('projects_last_admin', { remove: false }, false);
      } else {
        openModal('projects_change_team_role', {
          role,
          projectTeamId: projectTeam?.id,
          team
        });
      }
    },
    [isLastAdmin, openModal, projectTeam, team]
  );

  const onRemoveTeamClick = useCallback(() => {
    if (isLastAdmin) {
      openModal(
        'projects_last_admin',
        {
          remove: true,
          title: 'Unable to remove member'
        },
        false
      );
    } else {
      openModal('projects_remove_team', {
        projectTeamId: projectTeam?.id,
        team
      });
    }
  }, [isLastAdmin, openModal, projectTeam, team]);

  const isAdmin = projectTeam?.project_member_role === PROJECT_ROLE_TYPES.Admin;

  return (
    <ProjectMemberUserDetailUI
      key={team?.display_name}
      isAdmin={isAdmin}
      isMember={!isAdmin}
      memberDetailComponent={
        <ProjectTeamRow
          team={team}
          members={members}
          loading={loading}
          style={{ margin: 16, marginRight: `${isAdmin ? '16px' : '58px'}` }} // to align avatars list
        />
      }
      canEdit={canEdit}
      onRoleChange={onChangeRoleClick}
      onRemoveMemberClick={onRemoveTeamClick}
    />
  );
};

export const ProjectTeamsList = ({ projectId, canEdit, adminsCount }) => {
  const projectTeamMemberships = useResourceList__ALPHA({
    edgeType: 'project-team-membership',
    edgeLabel: 'project-team-memberships',
    params: {
      filter: { project: projectId }
    },
    strategy: 'fetch-on-mount'
  }).resources;

  return (
    <>
      {projectTeamMemberships?.map((res) => (
        <ProjectTeamDetail
          key={res.id}
          isLastAdmin={
            adminsCount === 1 &&
            res.project_member_role === PROJECT_ROLE_TYPES.Admin
          }
          projectTeam={res}
          canEdit={canEdit}
        />
      ))}
    </>
  );
};
