import { Typography } from '@cube3/ui/src/typography/Typography';
import { detector } from '@cube3/state/src/redux/middleware/offline-detection-middleware';
import SupportEmailComponent from '@cube3/ui/src/Email/SupportEmailComponent';
import Surface from '@cube3/ui/src/Surface/Surface';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export const OfflineNotification = () => {
  const [countDown, setCountDown] = useState(null);

  useEffect(() => {
    const i = setInterval(
      () => setCountDown(detector.getSecondsToNextPoll()),
      500
    );
    return () => clearInterval(i);
  });

  return (
    <Surface background="base1" gradient={'lamp'} floating={true}>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 8,
          position: 'relative'
        }}
      >
        <div>
          <Typography variant="body1" color="danger1">
            Connection interrupted. This may be an issue with your internet
            connection.
          </Typography>
          <Typography variant="body3" color="contrast2">
            Any changes you make cannot be saved. Contact <SupportEmailComponent />
            if this problem persists.
          </Typography>
        </div>
        {!isMobile && (
          <div style={{ position: 'absolute', right: 32, top: 16 }}>
            {countDown > 2 && (
              <Typography color="contrast3">{`(retry in ${countDown} seconds)`}</Typography>
            )}
            {countDown <= 2 && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="contrast3">retrying...</Typography>{' '}
                <CircularProgress size={14} />
              </div>
            )}
          </div>
        )}
        {/* <div style={{ width: '2em' }} /> */}
        {/* <Button
            onClick={() => window.location.reload()}
            colorVariant="filled1"
            size="small"
          >
            Reload
          </Button>
          <div style={{ width: '1em' }} />
          <Button onClick={onSnooze}>Snooze...</Button> */}
      </div>
    </Surface>
  );
};
