import React, { useCallback } from 'react';
import { Typography } from '../../typography/Typography';
import CustomIconButton from '../../Buttons/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  iconArrowDown,
  iconArrowRight,
  iconCamera
} from '../../icons/GeneralSVG';
import { getSurfaceColor } from '../../Buttons/Cube3Button';

interface Props {
  activeFrame: number;
  canTakeSnapshot: boolean;
  showSnapshotsList: boolean;
  shortCutTips?: any;
  stepFrame: (step: number) => void;
  onToggle: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    frameText: {
      minWidth: 90,
      textAlign: 'center'
    },
    iconArrowLeft: {
      transform: 'scaleX(-1)'
    },
    iconArrowUp: {
      transform: 'rotate(180deg)'
    },
    iconsContainer: {
      borderRadius: 4,
      display: 'flex',
      flexWrap: 'nowrap',
      '&:hover': {
        backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
          getSurfaceColor('ghost1', theme).color,
          getSurfaceColor('ghost1', theme).base,
          ['hover1']
        )
      }
    },
    iconButton: {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset'
      }
    },
    iconChevron: {
      marginLeft: '-20px'
    }
  })
);

export const FrameControls: React.FunctionComponent<Props> = React.memo(
  props => {
    const {
      activeFrame,
      canTakeSnapshot = false,
      showSnapshotsList = false,
      shortCutTips,
      onToggle,
      stepFrame
    } = props;
    const classes = useStyles();
    const onPreviousFrame = useCallback(() => stepFrame(-1), [stepFrame]);
    const onNextFrame = useCallback(() => stepFrame(1), [stepFrame]);

    return (
      <div className={classes.container}>
        <CustomIconButton
          aria-label="frame backwards"
          colorVariant="ghost3"
          size={'regular'}
          path={iconArrowRight}
          className={classes.iconArrowLeft}
          onClick={onPreviousFrame}
          title={shortCutTips && `Go back 1 frame (${shortCutTips.back})`} // tooltip
        />
        <Typography color="contrast1" className={classes.frameText}>
          frame {activeFrame}
        </Typography>
        <CustomIconButton
          aria-label="frame forwards"
          colorVariant="ghost3"
          size={'regular'}
          path={iconArrowRight}
          onClick={onNextFrame}
          title={shortCutTips && `Go forward 1 frame (${shortCutTips.forward})`} // tooltip
        />
        {canTakeSnapshot && (
          <span onClick={onToggle} className={classes.iconsContainer}>
            <CustomIconButton
              colorVariant={showSnapshotsList ? 'ghost1' : 'ghost3'}
              size="regular"
              path={iconCamera}
              extraIconButtonProps={{
                classes: { root: classes.iconButton }
              }}
            />
            <CustomIconButton
              colorVariant={showSnapshotsList ? 'ghost1' : 'ghost3'}
              path={iconArrowDown}
              className={showSnapshotsList ? classes.iconArrowUp : ''}
              size="regular"
              extraIconButtonProps={{
                classes: {
                  root: `${classes.iconButton} ${classes.iconChevron}`
                }
              }}
            />
          </span>
        )}
      </div>
    );
  }
);
