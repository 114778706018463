import React, { useRef, useState } from 'react';
import Image from '../../images/Image';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '../../typography/Typography';
import { ScrollPositionContext } from '../../Scrollbar/Scrollbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullscreen: {
      alignSelf: 'center',
      width: '100%'
    },
    container: {
      position: 'relative',
      minHeight: '30vh',
      width: '100%'
    },
    title: {
      height: '20px',
      left: '28px',
      right: '28px',
      top: '21px',
      color: theme.customPalette.primary.contrastColor,
      position: 'absolute',
      zIndex: 1,
      ...theme.typographyStyles.heading2,
      letterSpacing: '1px'
    },
    containerImage: {
      position: 'relative',
      height: 0,
      overflow: 'hidden',
      marginBottom: '2px'
    },
    innerContainerImage: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0
    },
    mobileImage: {
      '& > img': {
        maxHeight: 'unset !important'
      }
    }
  })
);

interface DocumentPreviewMobileProps {
  display_name: string;
  pages: { url: string; width: number; height: number }[];
  thumbnail?: string;
  fullscreen: boolean;
}

function DocumentPreviewMobile(props: DocumentPreviewMobileProps) {
  const [amountOfPagesToRender, setAmountOfPagesToRender] = useState(0);
  const classes = useStyles(props);
  const containerRef = useRef<HTMLDivElement>(null);
  const { pages = [], thumbnail, fullscreen, display_name } = props;

  return (
    <div
      className={fullscreen ? classes.fullscreen : classes.container}
      ref={containerRef}
    >
      {fullscreen && (
        <Typography
          className={classes.title}
          component="span"
          typographyStyle={'heading1'}
        >
          {display_name}
        </Typography>
      )}
      <ScrollPositionContext.Consumer>
        {({ scrollPosition }) => {
          return pages.map((page, index) => {
            // get container with
            const containerWidth = containerRef.current
              ? containerRef.current.offsetWidth
              : 0;

            // get padding top to get correct height
            const paddingTop = (page.height / page.width) * 100;

            const ratio = containerWidth / page.width;

            // get corrected height based on ratio
            const pageHeight = page.height * ratio;

            // get amount of pages when scrolled
            const addPagesBasedOnScroll = Math.ceil(
              (scrollPosition + pageHeight) / pageHeight
            );

            // if pages based on scroll are larger set new amount of pages to render
            addPagesBasedOnScroll > amountOfPagesToRender &&
              setAmountOfPagesToRender(addPagesBasedOnScroll);

            return amountOfPagesToRender > index ? (
              <div
                className={classes.containerImage}
                style={{
                  paddingTop: `${paddingTop}%`
                }}
                key={page.url}
              >
                <div className={classes.innerContainerImage}>
                  <Image
                    imagePath={page.url}
                    background="checkerboard"
                    placeholder={index === 1 ? thumbnail : undefined}
                    fullSize={true}
                    floatingEffect={false}
                    className={classes.mobileImage}
                  />
                </div>
              </div>
            ) : (
              <div
                key={page.url}
                className={`${classes.containerImage} placeholder-div-pdf`}
                style={{
                  paddingTop: `${paddingTop}%`
                }}
              >
                <div className={classes.innerContainerImage} />
              </div>
            );
          });
        }}
      </ScrollPositionContext.Consumer>
    </div>
  );
}

export default DocumentPreviewMobile;
