import { useFeaturesRoot } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { usePrivilegesRoot } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useMemo } from 'react';
import { TourSteps } from '../tourSteps';

/** A hook use workspace-featureflags and privileges to determine which steps should appear */
export const useBuildTourSteps = (initialState: TourSteps) => {
  const { features: activeFeatures, loading } = useFeaturesRoot();
  const enabledPermissions = usePrivilegesRoot();

  const steps = useMemo(() => {
    return Object.keys(initialState)
      .map((key) => {
        const { requiredFeature, requiredPrivilege } = initialState[key];
        const hasFeature = requiredFeature
          ? activeFeatures?.includes(requiredFeature)
          : true;
        const hasPris = requiredPrivilege
          ? enabledPermissions?.includes(requiredPrivilege)
          : true;
        if (hasFeature && hasPris) {
          return initialState[key].step;
        }
        return undefined;
      })
      .filter(Boolean);
  }, [activeFeatures, enabledPermissions, loading]);

  return steps;
};
