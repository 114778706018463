import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  createStyles,
  Fade,
  makeStyles,
  Popover
} from '@material-ui/core';
import { Surface } from '../../Surface/Surface';

const useStyles = makeStyles(theme =>
  createStyles({
    popover: {
      pointerEvents: 'none'
    },
    paperRoot: {
      background: 'transparent',
      borderRadius: 6,
      ...theme.customMixins.surfaceShadows({
        elevation: 2
      }),
      '& > * ': {
        pointerEvents: 'all'
      }
    }
  })
);

export const TagsTooltip = props => {
  const { trigger, disabled, children, side, delay = 1000 } = props;
  const classes = useStyles(props);
  const timeout = useRef(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (trigger && !disabled && children) {
      const handleOver = ev => {
        const elementsArray = document.elementsFromPoint(
          ev.clientX,
          ev.clientY
        );
        if (elementsArray.includes(trigger)) {
          if (ev.type === 'mouseover' && !open && !timeout.current) {
            timeout.current = setTimeout(() => {
              timeout.current = null;
              setOpen(true);
            }, delay);
          }
        } else {
          clearTimeout(timeout.current);
          timeout.current = null;
          setOpen(false);
        }
      };
      window.addEventListener('mousemove', handleOver);
      trigger.addEventListener('mouseover', handleOver);

      return () => {
        window.removeEventListener('mousemove', handleOver);
        trigger.removeEventListener('mouseover', handleOver);
      };
    }
  }, [trigger, open, disabled, children, delay]);

  return (
    <Popover
      classes={{ root: classes.popover }}
      disableAutoFocus={true}
      hideBackdrop={true}
      BackdropComponent={null}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
      anchorEl={trigger}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: side
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: side
      }}
      PaperProps={{
        square: true,
        elevation: 0,
        classes: { root: classes.paperRoot },
        onMouseDown: ev => ev.preventDefault()
      }}
      TransitionComponent={Fade}
    >
      <Surface
        corners="rounded"
        background="base2"
        floating={true}
        gradient="lamp"
      >
        <Box sx={{ width: 'fit-content', maxWidth: 220, padding: 8 }}>
          {children}
        </Box>
      </Surface>
    </Popover>
  );
};
