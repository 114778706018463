import React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import Label from '../../../Labels/Label';
import { createStyles, makeStyles, MenuItem, Theme } from '@material-ui/core';

export type ProjectRole = {
  display_name: string;
  value: string;
};

interface Props {
  className?: string;
  selectedRole: string;
  onChange: Function;
  roles: ProjectRole[];
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      ...theme.typographyStyles.body2,
      WebkitFontSmoothing: 'antialiased'
    },
    label: {
      backgroundColor: 'inherit',
      margin: '25px 0px 8px'
    }
  })
);

export const ProjectRoleSelectComponent = (props: Props) => {
  const {
    selectedRole,
    className = '',
    onChange,
    roles,
    disabled = false
  } = props;
  const classes = useStyles();

  return (
    <div className={className}>
      <Label
        text="Project role"
        extraTypographyProps={{
          typographyStyle: 'body2',
          className: classes.label,
          color: 'contrast2'
        }}
      />
      <SelectComponent
        disabled={disabled || roles.length === 1}
        labelName={'role'}
        value={roles.length === 1 ? roles[0].display_name : selectedRole}
        onChange={onChange}
      >
        {roles.map(role => (
          <MenuItem
            value={role.display_name}
            classes={{ root: classes.menuItem }}
            key={role.value}
          >
            {role.display_name}
          </MenuItem>
        ))}
      </SelectComponent>
    </div>
  );
};
