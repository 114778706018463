import { makeStyles, Theme, createStyles } from '@material-ui/core';
import Cube3Button from '../Buttons/Cube3Button';
import { iconPaste, iconDuplicate } from '../icons/GeneralSVG';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGruop: {
      display: 'flex',
      marginTop: 24
    },
    copyButton: { marginRight: 24 }
  })
);
export const CopyPasteButtonsUI = ({
  onCopy,
  onPaste,
  copyDisabled,
  pasteDisabled
}) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonGruop}>
      <Cube3Button
        text="Copy all values"
        pathLeftSvg={iconDuplicate}
        onClick={onCopy}
        colorVariant="ghost1"
        marginCollapse="left"
        disabled={copyDisabled}
        className={classes.copyButton}
      />

      <Cube3Button
        text="Paste all values"
        pathLeftSvg={iconPaste}
        onClick={onPaste}
        colorVariant="ghost1"
        marginCollapse="left"
        disabled={pasteDisabled}
      />
    </div>
  );
};
