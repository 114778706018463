import folderImage from '../assets/images/Library/folder.svg?url';
import assetImage from '../assets/images/Library/file.svg?url';
import projectImage from '../assets/images/Library/project_new.svg?url';
import projectDone from '../assets/images/Library/project_archived.svg?url';
import audioFileImage from '../assets/images/Library/audio.svg?url';
import archiveFileImage from '../assets/images/Library/archive.svg?url';
import composeFileImage from '../assets/images/Library/compose.svg?url';

import { assetMimeTypes } from '@cube3/common/model/types';
import markdownFileImage from '../assets/images/Library/markdown.svg?url';
import fontFileImage from '../assets/images/Library/font.svg?url';
import wordFileImage from '../assets/images/Library/word.svg?url';
import excelFileImage from '../assets/images/Library/excel.svg?url';
import pptFileImage from '../assets/images/Library/ppt.svg?url';
import urlFileImage from '../assets/images/Library/file-url.svg?url';
import figmaFileImage from '../assets/images/Library/file-figma.svg?url';
import cubeFileImage from '../assets/images/Library/file-cube.svg?url';
import docsFileImage from '../assets/images/Library/file-google-docs.svg?url';
import sheetsFileImage from '../assets/images/Library/file-google-sheets.svg?url';
import formFileImage from '../assets/images/Library/file-google-form.svg?url';
import slidesFileImage from '../assets/images/Library/file-google-slides.svg?url';

export const mapUrlThumbnails = {
  url: urlFileImage,
  'google docs url': docsFileImage,
  'google sheets url': sheetsFileImage,
  'google forms url': formFileImage,
  'google slides url': slidesFileImage,
  'figma url': figmaFileImage,
  'cube url': cubeFileImage,
  'microsoft word url': wordFileImage,
  'microsoft excel url': excelFileImage,
  'microsoft ppt url': pptFileImage
};

export type DisplayImageResourceConfig = {
  type?: string;
  mime_type?: string;
  display_image?: string;
  media?: Array<{ type: string; url: string }>;
  isArchived?: boolean;
  extension?: string;
};

/** Returns a path to a display image based on the asset type. This function exists
 * because some assets from the API do not have a display_image but a display image can be resolved
 * based on the passed type (e.g. folder / project / audio )*/
export function getNodeDisplayImageSrc(
  resource: DisplayImageResourceConfig
): string {
  if (!resource) {
    return assetImage;
  }

  if (resource.display_image) {
    return resource.display_image;
  }

  if (resource.media?.find((m) => m.type === 'thumbnail')) {
    return resource.media.find((m) => m.type === 'thumbnail').url;
  }
  if (resource.extension) {
    const key = resource.extension.toLocaleLowerCase();
    if (mapUrlThumbnails[key]) {
      return mapUrlThumbnails[key];
    }
    switch (key) {
      case 'md':
        return markdownFileImage;
      case 'otf':
      case 'ttf':
      case 'woff':
      case 'woff2':
        return fontFileImage;
      case 'xlsx':
      case 'xls':
        return excelFileImage;
      case 'docx':
      case 'doc':
        return wordFileImage;
      case 'pptx':
      case 'ppt':
        return pptFileImage;
      case 'fig':
        return figmaFileImage;
      default:
        break;
    }
  }

  switch (
    resource.mime_type ||
    (resource as { resource_type: 'string' }).resource_type ||
    resource.type
  ) {
    case assetMimeTypes.FOLDER:
    case 'folder':
      return folderImage;

    case 'asset':
      return assetImage;

    case assetMimeTypes.URL:
      return urlFileImage;

    case 'project':
      // TODO if we want to use this also in project view, we should extend this statement because projects have more than 1 state.
      return resource.isArchived ? projectDone : projectImage;

    case 'workspace':
      return cubeFileImage;

    case assetMimeTypes.AUDIO:
      return audioFileImage;

    case assetMimeTypes.ARCHIVE:
      return archiveFileImage;

    case assetMimeTypes.COMPOSE:
      return composeFileImage;

    default:
      return assetImage;
  }
}
