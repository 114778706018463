import React, { useCallback } from 'react';

// hooks
import { useModalActions } from '../../Modals/modalActions';

// UI
import DeleteForeverModalUI from '@cube3/ui/src/Layout/TrashBin/DeleteForeverModalUI';
import { useSelectionActions } from '@cube3/state/src/redux/components/useSelection';
import { useTrashBinCommander } from '../Hooks/useTrashBinCommander';

/**
 * @summary: Shows a modal and connects button events to handlers
 * @author Simon
 * @description: Checks if there are files selected and connects HOCs to open modals
 */

type Properties = {
  modalContext: {
    multipleSelected: boolean;
    allSelected: boolean;
  };
};

export const DeleteForeverModal: React.FC<Properties> = (props) => {
  const { multipleSelected, allSelected } = props.modalContext;

  const { closeAllModals } = useModalActions();

  const { clearItems, clearActiveItem } = useSelectionActions();
  const { deleteFilesPermanent } = useTrashBinCommander();

  const onDelete = useCallback(() => {
    deleteFilesPermanent();
    closeAllModals();
    clearActiveItem();
    clearItems();
  }, [deleteFilesPermanent, closeAllModals, clearActiveItem, clearItems]);

  return (
    <DeleteForeverModalUI
      multipleFiles={multipleSelected}
      allFiles={allSelected}
      onCloseEvent={closeAllModals}
      onClickDelete={onDelete}
    />
  );
};

export default DeleteForeverModal;
