// export const LOGIN_ERROR = 'LOGIN_ERROR';
// export const LOGIN_ERROR_BETA = 'LOGIN_ERROR_BETA';
// export const REQUIRED_ERROR = 'REQUIRED_ERROR';
// export const EMAIL_SYNTAX_ERROR = 'EMAIL_SYNTAX_ERROR';
// export const WEAK_PASSWORD_ERROR_LENGTH = 'WEAK_PASSWORD_ERROR_LENGTH';
// export const MAXIMUM_PASSWORD_ERROR_LENGTH = 'MAXIMUM_PASSWORD_ERROR_LENGTH';
// export const WEAK_PASSWORD_ERROR_CONSECUTIVE =
//   'WEAK_PASSWORD_ERROR_CONSECUTIVE';
// export const WEAK_PASSWORD_ERROR_SEQUENTIAL = 'WEAK_PASSWORD_ERROR_SEQUENTIAL';
// export const WEAK_PASSWORD_ERROR_DICTIONARY = 'WEAK_PASSWORD_ERROR_DICTIONARY';
// export const SHOULD_MATCH_VALUE = 'SHOULD_MATCH_VALUE';
// export const INVITE_TOKEN_ERROR = 'INVITE_TOKEN_ERROR';
// export const DUPLICATE_RECIPIENTS_ERROR = 'DUPLICATE_RECIPIENTS_ERROR';
// export const INVALID_RECIPIENTS_ERROR = 'INVALID_RECIPIENTS_ERROR';

// predetermined error codes agreed uppon with backend
export const serverErrorCodes = {
  ATTRIBUTE_UNIQUE_CONSTRAINT: 'ATTRIBUTE_UNIQUE_CONSTRAINT',
  // Login
  HTTP_UNAUTHORIZED: 'HTTP_UNAUTHORIZED',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_ERROR_BETA: 'LOGIN_ERROR_BETA',
  AUTHORIZATION_MISSING_ERROR: 'AUTHORIZATION_MISSING_ERROR',
  CUSTOMER_MISSING_ERROR: 'CUSTOMER_MISSING_ERROR',
  MAX_LOGIN_ATTEMPTS: 'MAX_LOGIN_ATTEMPTS',
  IDENTITY_PROVIDER_NOT_ALLOWED: 'IDENTITY_PROVIDER_NOT_ALLOWED',
  // Shares
  SHARELINK_PASSWORD_HEADER_MISSING: 'SHARELINK_PASSWORD_HEADER_MISSING',
  SHARELINK_PASSWORD_INCORRECT: 'SHARELINK_PASSWORD_INCORRECT',
  SHARELINK_ALREADY_GRANTED: 'SHARELINK_ALREADY_GRANTED',
  // Downloads
  ASSET_NUMBER_ERR: 'EXCEEDING_MAX_ASSET_COUNT',
  DOWNLOAD_SIZE_ERR: 'EXCEEDING_MAX_SIZE',
  DOWNLOAD_NO_FILES: 'DOWNLOAD_NO_FILES',
  // Workspace members
  INVITE_TOKEN_EXPIRED_ERROR: 'INVITE_TOKEN_EXPIRED_ERROR',
  INVITE_TOKEN_INVALID_ERROR: 'INVITE_TOKEN_INVALID_ERROR',
  INVITE_TOKEN_ACCEPTED_ERROR: 'INVITE_TOKEN_ACCEPTED_ERROR',
  INVITE_USER_DATA_ERROR: 'INVITE_USER_DATA_ERROR',
  NOT_ALLOWED_LAST_ADMIN: 'NOT_ALLOWED_LAST_ADMIN',
  WORKSPACE_FULL: 'WORKSPACE_FULL',
  WORKSPACE_ALREADY_MEMBER: 'WORKSPACE_ALREADY_MEMBER',
  // Workspace teams
  DUPLICATE_NAME: 'HTTP_CONFLICT'
} as const;

export type ServerErrorCode =
  (typeof serverErrorCodes)[keyof typeof serverErrorCodes];

// error codes for use with redux form validators
export const validationErrorCodes = {
  REQUIRED_ERROR: 'REQUIRED_ERROR',
  EMAIL_SYNTAX_ERROR: 'EMAIL_SYNTAX_ERROR',
  WEAK_PASSWORD_ERROR_LENGTH: 'WEAK_PASSWORD_ERROR_LENGTH',
  MAXIMUM_PASSWORD_ERROR_LENGTH: 'MAXIMUM_PASSWORD_ERROR_LENGTH',
  WEAK_PASSWORD_ERROR_CONSECUTIVE: 'WEAK_PASSWORD_ERROR_CONSECUTIVE',
  WEAK_PASSWORD_ERROR_SEQUENTIAL: 'WEAK_PASSWORD_ERROR_SEQUENTIAL',
  WEAK_PASSWORD_ERROR_DICTIONARY: 'WEAK_PASSWORD_ERROR_DICTIONARY',
  SHOULD_MATCH_VALUE: 'SHOULD_MATCH_VALUE',
  DUPLICATE_RECIPIENTS_ERROR: 'DUPLICATE_RECIPIENTS_ERROR',
  INVALID_RECIPIENTS_ERROR: 'INVALID_RECIPIENTS_ERROR'
};

export interface JSONApiError {
  code?: string;
  title?: string;
  detail?: string;
  status?: string | number;
  source?: { pointer?: string };
  meta?: string;
}

export type JSONApiErrors = JSONApiError[];

type NestedJSONApiErrors = { _error: JSONApiError[] };
// where keys are Resource property fields
// for use with forms / validation errors
export interface FormCompatibleError<
  T = string | string[] | JSONApiError | JSONApiError[]
> {
  _error?: T;
  [key: string]: T;
}

export type ErrorTypes =
  | string
  | JSONApiErrors
  | FormCompatibleError
  | NestedJSONApiErrors[];

export const getErrorData = (
  errorBody: ErrorTypes,
  key: keyof JSONApiError
) => {
  if (!errorBody) return [];

  // handle string type error bodies
  if (typeof errorBody === 'string') return [errorBody];

  // handle JSONApi compatible error bodies
  if (Array.isArray(errorBody)) {
    if ((errorBody as JSONApiError[])[0][key]) {
      return errorBody.map((e) => e[key]);
    } else {
      return getErrorData((errorBody as NestedJSONApiErrors[])[0], key);
    }
  }

  // handle redux form compatible error bodies
  try {
    if (Object.getPrototypeOf(errorBody) === Object.prototype) {
      return Object.keys(errorBody).reduce((acc, k) => {
        let e;
        if (Array.isArray(errorBody[k])) {
          // error type: FormCompatibleError<string[]>
          if (typeof errorBody[k][0] === 'string') {
            e = errorBody[k] as string[];
            return [...acc, ...e];
          }
          // error type: FormCompatibleError<JSONApiErrors>
          e = errorBody[k] as JSONApiErrors;
          const codes = e.map((e) => e[key]);
          return [...acc, ...codes];
        } else {
          // error type: FormCompatibleError<string>
          if (typeof errorBody[k] === 'string') {
            return [...acc, errorBody[k]];
          }
          // error type: FormCompatibleError<JSONApiError>
          e = errorBody[k] as JSONApiError;
          return [...acc, e[key]];
        }
      }, []);
    }
  } catch (e) {
    console.warn('Error body could not be parsed', errorBody);
  }
  console.warn('Error body could not be recognized', errorBody);
  return ['Unknown error - please try again.'];
};

export const getErrorCodes = (errorBody: ErrorTypes) =>
  getErrorData(errorBody, 'code');

// check if an error body matches a certain error code
export const hasError = (errorBody: ErrorTypes, errorCode) => {
  if (!errorBody) return false;
  return !!getErrorCodes(errorBody)?.find((c) => c === errorCode);
};

export const errorStatusToCode = () => undefined;

// parse json api errors to be compatible with redux form
// TODO: move to different layer maybe
// TODO: parse all errors to normalized JSONApi errors
export const parseJSONAPIErrors = (
  errors: JSONApiErrors
): FormCompatibleError<JSONApiError[] | string> => {
  try {
    const fce = errors.reduce<FormCompatibleError<JSONApiError[]>>(
      (acc, val) => {
        if (!val) {
          return acc;
        }

        const field =
          val.source && val.source.pointer !== '/'
            ? val.source.pointer.split('/').reverse()[0]
            : '_error';
        acc[field] = acc[field] || [];

        return {
          ...acc,
          [field]: [
            ...acc[field],
            {
              code: val.code,
              title: val.title,
              detail: val.detail,
              status: val.status,
              source: val.source,
              meta: val.meta
            }
          ]
        };
      },
      {}
    );
    return fce;
  } catch (e) {
    console.warn('Error parsing JSONApi errors', e);
    return {
      _error: 'Parsing errors failed'
    };
  }
};
