import React, { useCallback, useEffect, useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import CheckBoxStateless from '@cube3/ui/src/Buttons/CheckBoxStateless';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

interface Props {
  modalContext: {
    userId: string;
    attributeName: string;
    value: boolean;
  };
}

const LABEL = {
  newsletter_accepted: 'Allow Cube to send product updates emails',
  marketing_accepted: 'Allow Cube to send marketing mails'
};
export const ChangeMailPreferenceModal = (props: Props) => {
  const { userId, attributeName, value: initialValue } = props.modalContext;
  const { closeAllModals } = useModalActions();

  const [checked, setChecked] = useState(initialValue);

  const [mutateUser, mutateStatus] = useMutateResource__ALPHA({
    cacheInvalidator: null
  });

  const onConfirm = useCallback(() => {
    mutateUser({
      id: userId,
      type: 'user',
      [attributeName]: checked
    });
  }, [userId, mutateUser, checked, attributeName]);

  const onChange = useCallback(() => {
    setChecked((prev) => !prev);
  }, [setChecked]);

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      closeAllModals();
    }
  }, [mutateStatus, closeAllModals]);

  return (
    <ModalNoticeUI
      title={'Change mail preferences'}
      loading={
        mutateStatus &&
        mutateStatus !== statuses.FAILED &&
        mutateStatus !== statuses.SUCCESS
      }
      onCloseEvent={closeAllModals}
      footerRightComponent={
        checked !== initialValue ? (
          <>
            <Button
              text={'Cancel'}
              colorVariant={'ghost2'}
              onClick={closeAllModals}
            />
            <Button
              text={'Confirm'}
              colorVariant={'filled1'}
              onClick={onConfirm}
            />
          </>
        ) : (
          <Button
            text={'Done'}
            colorVariant={'filled1'}
            onClick={closeAllModals}
          />
        )
      }
    >
      <CheckBoxStateless
        checked={checked}
        text={LABEL[attributeName]}
        onChange={onChange}
      />
    </ModalNoticeUI>
  );
};
