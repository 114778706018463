import { serialize } from './serialize';

/** get an array of request statuses related to a single resource (this includes relationship statuses) */
export const getResourceById = (state, resourceType, resourceId) =>
  state[resourceType] && state[resourceType][resourceId];

const getNode = (state, apiClient, payload, requestStatusMetadata) => {
  let node;

  if (requestStatusMetadata?.hash) {
    node = getResourceById(state, undefined, undefined);
  } else {
    const { resourceType, resourceId } = apiClient || payload;
    node = getResourceById(state, resourceType, resourceId);
  }
  return node;
};

const getAction = (state, payloadAction) => {
  const { meta, payload } = payloadAction;
  const { apiClient, requestStatus } = meta;

  if (!apiClient && !payload) {
    return null;
  }

  const node = getNode(state, apiClient, payload, requestStatus);

  if (node) {
    const serializedAction = serialize(payloadAction);
    // console.info("SERIALIZED GET", serializedAction);
    const select = node.filter(req => req === serializedAction);
    return select.length > 0 ? select[0] : null;
  }
  return null;
};

export const hasAction = (state, payloadAction) => {
  const has = !!getAction(state, payloadAction);
  return has;
};

export const insertAction = (state, payloadAction) => {
  const serializedAction = serialize(payloadAction);

  const { meta = {}, payload } = payloadAction;
  const { apiClient, requestStatus } = meta;
  const { resourceType, resourceId } = apiClient || payload;
  const node = getNode(state, apiClient, payload, requestStatus);

  if (hasAction(state, payloadAction)) {
    return state;
  } else {
    const newState = {
      ...state,
      [resourceType]: {
        ...state[resourceType],
        [resourceId]: node ? [...node, serializedAction] : [serializedAction]
      }
    };
    return newState;
  }
};

export const dropAction = (state, payloadAction) => {
  const serializedAction = serialize(payloadAction);
  const { meta = {}, payload } = payloadAction;
  const { apiClient, requestStatus } = meta;
  const { resourceType, resourceId } = apiClient || payload;
  const node = getNode(state, apiClient, payload, requestStatus);

  if (hasAction(state, payloadAction)) {
    const newState = {
      ...state,
      [resourceType]: {
        ...state[resourceType],
        [resourceId]: node ? node.filter(req => req !== serializedAction) : node
      }
    };

    return newState;
  } else {
    return state;
  }
};

const retrieveRelationshipExp = /^cube3\/resource-edges\/RETRIEVE_RESOURCE_EDGES__\{.*/;

export const dropResource = (state, payloadAction) => {
  const { meta = {}, payload } = payloadAction;
  const { apiClient, requestStatus } = meta;
  const { resourceType, resourceId } = apiClient || payload;
  const node = getNode(state, apiClient, payload, requestStatus);

  if (node) {
    const newState = {
      ...state,
      [resourceType]: {
        ...state[resourceType],
        // leave invalidated relationship requests
        // TODO: store separately
        [resourceId]: (state[resourceType][resourceId] || []).filter(status => {
          const shouldkeep = retrieveRelationshipExp.test(status);
          return shouldkeep;
        })
      }
    };

    return newState;
  } else {
    return state;
  }
};

export const dropActionSet = (state, payloadAction) => {
  if (retrieveRelationshipExp.test(payloadAction.type)) {
    // TODO: determine sets of payload actions that make sense and don't break things
    return dropAction(state, payloadAction);
  } else {
    return dropResource(state, payloadAction);
  }
};
