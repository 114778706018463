import { useEffect, useMemo, useRef, useState } from 'react';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { UseSuggestionsResponse, AssetSuggestion } from '../types';
import FileIcon from '@cube3/ui/src/assets/images/Library/file.svg?url';

const emptyArray = [];

export const useContractAssetSuggestions = (
  config
): UseSuggestionsResponse<AssetSuggestion> => {
  const { query: directQuery, selected } = config;

  const [query, setQuery] = useState();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  // debounce the input a bit (until meili is ready)
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setQuery(directQuery);
    }, 300);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [directQuery]);

  const suggestionType = 'asset';

  const [workspaceId] = useCurrentWorkspace();

  /** overfetch initially */
  const pageSize = 30;

  /* start with one page, load more if needed */
  const [pages, setPages] = useState([1]);

  /* if the query changes reset the number of pages to retrieve */
  useEffect(() => {
    setPages([1]);
  }, [query]);

  /* TODO: replace after meili search has landed */
  const params = useMemo(() => {
    return {
      display_name: query,
      search: {
        query: suggestionType, // folder, video etc...
        searchInId: undefined, // id of folder
        searchType: undefined // metadata of name
      },
      page: { size: pageSize }
    };
  }, [query, suggestionType]);

  // get the search results.
  // TODO: needs small refactor after meili search lands
  const searchResults = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: query ? workspaceId : undefined,
    edgeType: 'search-result',
    edgeLabel: 'search-results',
    params,
    pages
  });

  const typed = !!directQuery;

  /* parse the results and filter out those that are already selected in ui */
  const suggestions = useMemo((): UseSuggestionsResponse<AssetSuggestion> => {
    const loading =
      searchResults.loading || (typed && !searchResults.resources);

    if (!searchResults.resources) {
      return [emptyArray, emptyArray, loading];
    }

    const sugs = searchResults.resources.map(c => {
      return {
        display_name: c.display_name,
        display_image: c.display_image || FileIcon,
        id: c.resource_id,
        type: c.resource_type,
        imageType: 'asset' as 'asset'
      };
    });

    return [
      sugs
        .filter(s => !selected.filter(sel => sel.id === s.id).length)
        .slice(0, 8),
      sugs.filter(s => selected.filter(sel => sel.id === s.id).length),
      loading
    ];
  }, [searchResults.resources, selected, searchResults.loading, typed]);

  useEffect(() => {
    if (
      searchResults.resources?.length &&
      searchResults.resources.length % pageSize === 0 &&
      Math.floor(searchResults.resources.length / pageSize) === pages.length
    ) {
      if (suggestions[0].length === 0) {
        setPages([...pages, pages.length + 1]);
      }
    }
  }, [pages, setPages, searchResults.resources, suggestions]);

  return suggestions;
};
