import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '../../typography/Typography';
import { UserInfo } from '../UserTypes';

/// <Summary>
/// Shows the fullname of the user vertically centered in a textbox
/// When the fullname is not available, falls back to email address.
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minWidth: 150,
      height: 20
    },
    textBox: {
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  }));

interface Properties {
  classes?: {[key: string]: string};
  userInfo: UserInfo;
}

const UserName: React.FC<Properties> = ( props ) => {
  const { userInfo } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.textBox}>
        {userInfo.full_name ? (
          <Typography className={classes.textBox} typographyStyle={'body1'}>
            {userInfo.full_name}
          </Typography>
        ) : (
          <Typography className={classes.textBox} typographyStyle={'body1'}>
            {userInfo.email_address}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default UserName;
