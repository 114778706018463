import Cookies from 'js-cookie'; // TODO: replace with a consentual cookies/storage module
import { environment } from '../../utils/environment';

import {
  actions,
  setableCookieTypes,
  consentOptions,
  compareConsentVersions,
  actionCreators
} from '../ducks/cookie-consent/';

import { SetableCookieConsentState } from '../ducks/cookie-consent/state';

const secureCookies = environment !== 'development';

// humand readable consent types
const CONSENTED = 'consented';
const REJECTED = 'rejected';

// map human readable consent types to redux namespaced consent types
const getConsentType = (string) => {
  switch (string) {
    case CONSENTED:
      return consentOptions.CONSENTED;
    case REJECTED:
      return consentOptions.REJECTED;
    default:
      return consentOptions.UNSET;
  }
};

// turn redux namespaced Cookie types into human readable consent types;
const getSimpleConsentType = (cookieType) => {
  return cookieType
    .split('/')
    .slice(-1)
    .map((str) => str.toLowerCase())[0];
};

// turn redux namespaced Cookie types into human readable cookie names
const getCookieMapping = (cookieType) => {
  return cookieType
    .split('/')
    .slice(-1)
    .map((str) => str.toLowerCase())
    .concat('consent')
    .join('_');
};

// map consent cookies to partial redux cookies-consent state slice
export const mapCookiesToState = () => {
  const state = Object.keys(setableCookieTypes).reduce((acc, cat) => {
    const data = Cookies.getJSON(getCookieMapping(cat)) || {};
    return {
      ...acc,
      [cat]: {
        state: getConsentType(data.state),
        version: data.version,
        timestamp: data.timestamp ? new Date(data.timestamp) : undefined
      }
    };
  }, {});
  return state as SetableCookieConsentState;
};

// placeholder that can be exported
// will be set inside enhancer later on
let methods = { getAllowed: undefined, addListener: undefined };

let isReady;
const readyPromise = new Promise<() => any>((res) => {
  isReady = res;
});

// NOTE: this is not a HOC
// createConsentSubscriber helper is curried with a an array of consent cookie types
// the return function should be called with a
// setterFn: function that sets the cookies and or  local storage (assuming consent)
// cleanupFn: function that removes cookies and or local storage when consent is withdrawn
export const createConsentSubscriber =
  (requiredCookieTypes = [], needsRemember = false) =>
  (setterFn, cleanupFn) => {
    let off;
    readyPromise.then((getState) => {
      const allowed = methods.getAllowed && methods.getAllowed();
      const remember = getState().session?.remember;

      const rememberAllowed = !needsRemember || remember;

      const fails =
        requiredCookieTypes.map((t) => allowed[t]).filter((c) => !c).length > 0;
      if (fails || !rememberAllowed) {
        console.warn('cookie not allowed, due to consent restraints', {
          allowed,
          requiredCookieTypes,
          remember,
          needsRemember
        });
        cleanupFn();
      } else {
        console.warn('consent previously given');
        setterFn();
      }

      off = methods.addListener((state, oldState) => {
        const remember = getState().session?.remember;
        const rememberAllowed = !needsRemember || remember;

        const newAllowed = methods.getAllowed();
        const newFails =
          requiredCookieTypes.map((t) => newAllowed[t]).filter((c) => !c)
            .length > 0;

        if (newFails || !rememberAllowed) {
          console.warn('consent withdrawn, cleanup function called', {
            newAllowed,
            requiredCookieTypes,
            remember,
            needsRemember
          });
          cleanupFn();
          // off();
        } else {
          console.warn('consent given');
          setterFn();
        }
      });
    });
    return () => readyPromise.then((getState) => off());
  };

// subcribe to store an do callback if selected state slice changes
// this will be used in the enhancer to hook up callbacks
const observeStore = (store, select, onChange) => {
  let currentState;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      const oldState = currentState;
      currentState = nextState;
      onChange(currentState, oldState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};

// Cookie consent enhancer
// doesn't really enhance the store (pass through),
// but it does add a callback to cookie consent state changes
export const cookieConsentEnhancer =
  (stateKey = 'cookieConsent', options = { pollInterval: 500 }) =>
  (createStore) => {
    return (...args) => {
      const store = createStore(...args);

      let callbacks = [];

      // connect callbacks
      methods.addListener = (cb) => {
        callbacks =
          callbacks.indexOf(cb) === -1 ? [...callbacks, cb] : callbacks;
        return () => (callbacks = callbacks.filter((c) => c !== cb));
      };

      // get object literal with allowed consent types set to true
      methods.getAllowed = () => {
        const fromState = store.getState()[stateKey];

        return Object.keys(setableCookieTypes).reduce(
          (acc, cat) =>
            fromState[cat] && fromState[cat].state === consentOptions.CONSENTED
              ? { ...acc, [getSimpleConsentType(cat)]: true }
              : { ...acc, [getSimpleConsentType(cat)]: false },
          {}
        );
      };

      isReady(store.getState);

      // check if redux state is in sync with consent cookies
      // (in case user manually changes them)
      const isSync = (fromState, fromCookies) => {
        const mismatched = Object.keys(fromCookies).filter((cat) => {
          const mismatchAvailability = !!fromCookies[cat] !== !!fromState[cat];
          const mismatchState =
            fromCookies[cat]?.state !== fromState[cat]?.state;
          const mismatchHasVersion =
            !!fromCookies[cat]?.version !== !!fromState[cat]?.version;
          const mismatchHasTimestamp =
            !!fromCookies[cat]?.timestamp !== !!fromState[cat]?.timestamp;
          const mismatchVersion =
            fromCookies[cat]?.version &&
            compareConsentVersions(
              fromCookies[cat]?.version,
              fromState[cat]?.version
            ) !== 0;

          const mismatch =
            mismatchAvailability ||
            mismatchState ||
            mismatchHasVersion ||
            mismatchHasTimestamp ||
            mismatchVersion;

          if (mismatch) {
            console.warn(
              'COOKIE MISMATCH',
              mismatch,
              cat,
              fromCookies[cat],
              fromState[cat],
              {
                mismatchAvailability,
                mismatchState,
                mismatchHasVersion,
                mismatchHasTimestamp,
                mismatchVersion
              }
            );
          }
          return mismatch;
        });
        if (mismatched.length > 0) {
          return false;
        }
        return true;
      };

      let sync = {
        isSyncing: false,
        syncAction: undefined
      };

      let timeout;

      observeStore(
        store,
        (state) => ({
          cookies: state[stateKey],
          remember: state.session?.remember
        }),
        (state, oldState = { cookies: undefined, remember: undefined }) => {
          if (
            state.cookies === oldState.cookies &&
            state.remember === oldState.remember
          ) {
            return;
          }
          if (
            sync.isSyncing &&
            sync.syncAction &&
            isSync(state, sync.syncAction.payload)
          ) {
            sync.isSyncing = false;
            sync.syncAction = undefined;
          }
          callbacks.forEach((cb) => {
            cb(state, oldState);
          });
        }
      );

      const runCheck = (syncRef) => {
        const fromState = store.getState()[stateKey];
        const fromCookies = mapCookiesToState();
        if (!syncRef.isSyncing && !isSync(fromState, fromCookies)) {
          syncRef.isSyncing = true;
          const action = actionCreators.syncConsent(mapCookiesToState());
          syncRef.syncAction = action;
          store.dispatch(action);
        }
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => runCheck(syncRef), options.pollInterval);
      };

      // poll cookies to see if user manually deletes them
      runCheck(sync);

      return store;
    };
  };

// cookie consent middlware
// handles the consent setting / rejecting actions by setting consent cookies
// and deriving new state from them so the state is controlled by the cookies
// This is important because the user might manually clear consent cookies...
export default (stateKey = 'cookieConsent', options = {}) => {
  const cookieConsentmiddleware = ({ getState, dispatch }) => {
    return (next) => (action) => {
      const { type, payload, meta } = action;

      if (meta && meta.middleware && meta.middleware.cookieConsent) {
        switch (type) {
          // Consent
          case actions.GIVE_CONSENT:
            payload.categories.forEach((cat) =>
              Cookies.set(
                getCookieMapping(cat),
                {
                  state: CONSENTED,
                  version: getState()[stateKey].currentVersion,
                  timestamp: new Date().toISOString()
                },
                { expires: 90, secure: secureCookies, sameSite: 'strict' }
              )
            );
            dispatch(actionCreators.syncConsent(mapCookiesToState()));
            break;

          // Rejection
          case actions.REJECT_CONSENT:
            payload.categories.forEach((cat) =>
              Cookies.set(
                getCookieMapping(cat),
                {
                  state: REJECTED,
                  version: getState()[stateKey].currentVersion,
                  timestamp: new Date().toISOString()
                },
                { expires: 90, secure: secureCookies, sameSite: 'strict' }
              )
            );
            dispatch(actionCreators.syncConsent(mapCookiesToState()));
            break;

          // Clearing
          case actions.CLEAR_CONSENT:
            payload.categories.forEach((cat) =>
              Cookies.remove(getCookieMapping(cat))
            );
            dispatch(actionCreators.syncConsent(mapCookiesToState()));
            break;

          // Accepting new consent Version
          case actions.UPDATE_CONSENT_VERSION:
            Object.keys(setableCookieTypes).forEach((cat) => {
              const current = Cookies.getJSON(getCookieMapping(cat));
              if (current) {
                Cookies.set(
                  cat,
                  {
                    ...current,
                    version: getState()[stateKey].currentVersion,
                    timestamp: new Date().toISOString()
                  },
                  { expires: 90, secure: secureCookies, sameSite: 'strict' }
                );
              }
            });
            dispatch(actionCreators.syncConsent(mapCookiesToState()));
            break;
        }
      }
      let returnValue = next(action);
      return returnValue;
    };
  };
  return cookieConsentmiddleware;
};
