import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useCallback, useMemo, useRef } from 'react';

export const useExportOptions = <IP extends {}>({
  selection,
  exportTargetId
}) => {
  const tempId = useRef(uuidv4());
  const mappedId = useMappedId__ALPHA(tempId.current);
  const [worksapceId] = useCurrentWorkspace();

  const [createExport, createExportStatus] = useCreateResource__ALPHA({
    resourceType: 'export-batch',
    ancestor: { type: 'workspace', id: worksapceId },
    relationship: 'export-batches',
    cacheInvalidator: useCallback(
      () => [
        {
          type: 'workspace',
          id: worksapceId,
          relationship: 'export-batches'
        }
      ],
      [worksapceId]
    )
  });

  const handleCreateExport = useCallback(
    ({
      recipients,
      integration_parameters
    }: {
      recipients: string[];
      integration_parameters: IP;
    }) => {
      if (selection && selection.length > 0 && integration_parameters) {
        const assetsIdentifiers = selection.map((item) => ({
          id: item.id,
          type: item.type
        }));

        createExport({
          type: 'export-batch',
          temporaryId: tempId.current,
          notification_recipients: recipients,
          integration_parameters,
          relationships: {
            assets: {
              data: assetsIdentifiers
            },
            'export-targets': {
              data: [
                {
                  type: 'export-target',
                  id: exportTargetId
                }
              ]
            }
          }
        });
      }
    },
    [createExport, exportTargetId, selection]
  );

  return useMemo(
    () => ({
      exportBatchId: mappedId,
      createExportStatus,
      handleCreateExport
    }),
    [createExportStatus, mappedId, handleCreateExport]
  );
};
