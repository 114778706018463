import { actions, selectors, actionIntents } from '../ducks/clipboard';
import { actionCreators as edgesActionCreators } from '../ducks/resource-edges';

export const clipboardMiddleware = ({
  getState,
  dispatch
}) => next => action => {
  // Call the next dispatch method in the middleware chain.
  switch (action.type) {
    case actions.PASTE_ITEMS:
      const items = selectors.getClipboardItems(getState().clipboard);
      const intent = selectors.getClipboardIntent(getState().clipboard);

      // TODO: find a more general / less ugly way to handle this
      switch (intent) {
        case actionIntents.MOVE_INTENT:
          dispatch(edgesActionCreators.uglyMoveItems(items, action.payload));
          break;
        case actionIntents.COPY_INTENT:
          dispatch(edgesActionCreators.uglyCopyItems(items, action.payload));
          break;
        default:
          break;
      }
      return next(action);

    default:
      return next(action);
  }
};

export default clipboardMiddleware;
