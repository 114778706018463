import {
  normalizeResourceEdgesConfig,
  parseResourceEdgesConfig,
  WithResourceEdgesConfig
} from './configHelpers';

import { LibraryItem as LibraryItemType } from '@cube3/common/model/resource-types';
import { Upload as UploadType } from '@cube3/common/model/schema/resources/file-upload';
import React, { useMemo } from 'react';
import { statuses } from '../ducks/request-status';
import { useResourceList__ALPHA } from './Hooks/useResourceList';

export type LibraryItem = LibraryItemType;
export type Upload = UploadType;

// TODO: withResourceEdges is not working well with `CategoryScrollingLayout`, causing maximum rendering issue
export const withResourceEdges = <P extends {}>(
  config: WithResourceEdgesConfig
) => {
  // we make sure the config is in a predictable shape
  const normalizedConfig = normalizeResourceEdgesConfig<P>(config);

  const withConnectedResourceList = (WrappedComnent) => (props) => {
    const parsedConfig = parseResourceEdgesConfig(normalizedConfig, props);

    // parse and map retrieved state based on config
    const mapDataToProps = React.useCallback(
      (data) => {
        const { mapper: mapping, parser } = parsedConfig;
        const parsed = parser({ ...data, resourceEdges: data.resources });

        if (typeof mapping === 'string') {
          // simple mapping to string (legacy way)
          // loading prop is set for convenience
          // NOTE: we now also inject <string>Status and <string>Retrieve
          const stringMapped = {
            [mapping]: parsed.resourceEdges || parsed.resources,
            [`${mapping}Retrieve`]: parsed.retrieve,
            [`${mapping}Status`]: parsed.status,
            loading: parsed.status === statuses.IN_FLIGHT || parsed.loading
          };

          return stringMapped;
        } else if (typeof mapping === 'object') {
          // map parsed data fields to propsnames via an Object
          // see configHelpers for explanation
          return Object.keys(mapping)
            .map((k) => {
              return { [mapping[k]]: parsed[k] };
            })
            .reduce((acc, val) => {
              return { ...acc, ...val };
            }, {});
        } else {
          // default mapping
          // NOTE: if you parse but don't map,
          // you might get undefineds
          const defaultMapped = {
            ...parsed,
            edgesStatus: parsed.status,
            edges: parsed.resourceEdges || parsed.resources,
            edgesRetrieve: parsed.retrieve,
            loading: parsed.status === statuses.IN_FLIGHT || parsed.loading
          };
          return defaultMapped;
        }
      },
      [parsedConfig.mapper, parsedConfig.parser]
    );

    const resourceList = useResourceList__ALPHA(parsedConfig);

    // build new props using mapping/parsing config
    const mappedProps = useMemo(() => {
      return mapDataToProps(resourceList);
    }, [mapDataToProps, resourceList]);

    return <WrappedComnent {...props} {...mappedProps} />;
  };

  return withConnectedResourceList;

  // const enhancerFunction = compose(
  //   // withResourceState: regular redux connect function,
  //   // but we generate our STP and DTP
  //   // via a function that has access to the normalized
  //   // config (so we can access own props)
  //   withResourceEdgesState(normalizedConfig),
  //   // withResourceLifeCycle: the innermost wrapping adds
  //   // lifecycle hooks, so state gets retrieved
  //   // when the component mounts. Here the retrieved state
  //   // also gets parsed and mappped
  //   withResourceEdgesLifeCycle(normalizedConfig)
  // );
  // // withResource returns an enhancer function
  // // call it on your component
  // return enhancerFunction;
};
