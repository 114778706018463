import { Feature } from '@cube3/common/model/resource-types';
import { CubeAnchorLinkProps } from '@cube3/cubicle/src/core/atoms/CubeAnchorLink';
import { APIPrivileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import React, { ReactNode } from 'react';
import hello from '@cube3/ui/src/assets/images/hello.png';
import { Step } from 'react-joyride';

type OriginalStep = Step;

export interface CustomStep extends OriginalStep {
  extraContent?: {
    text: string;
    image?: string;
    video?: string;
    link?: CubeAnchorLinkProps & {
      text: string;
    };
  };
}

export interface TourSteps {
  [name: string]: {
    step: CustomStep;
    requiredFeature?: Feature;
    requiredPrivilege?: APIPrivileges;
  };
}

export const workspaceViewSteps: TourSteps = {
  start: {
    step: {
      target: 'body',
      content: null,
      extraContent: {
        text: "Welcome to Cube. Here's a quick tour.",
        image: hello
      },
      title: 'Welcome to Cube',
      disableBeacon: true
    }
  },
  // Projects
  projectsOverview: {
    step: {
      target: '#tour-steps__Projects',
      content:
        'Projects are special folders in Cube where you store and manage files that belong to a specific campaign or initiative.',
      placement: 'right-start',
      title: 'Projects',
      disableBeacon: true
    },
    requiredFeature: '/WORKSPACE/PROJECTS',
    requiredPrivilege: 'PROJECT_READ'
  },
  // Reviews
  reviewsOverview: {
    step: {
      target: '#tour-steps__Reviews',
      title: 'Reviews',
      content:
        'Reviews allow stakeholders to leave comments and ratings on files, making collecting feedback easy.',
      placement: 'right-start'
    },
    requiredFeature: '/WORKSPACE/SHARING/REVIEWS'
  },
  // Contracts
  contractsOverview: {
    step: {
      target: '#tour-steps__Contracts',
      content:
        'With Contracts, you can attach licensing information (such as usage rights and buyouts) to projects and files.',
      placement: 'right-start',
      title: 'Contracts',
      disableBeacon: true
    },
    requiredFeature: '/WORKSPACE/CONTRACTS',
    requiredPrivilege: 'CONTRACT_READ'
  },
  // Search
  search: {
    step: {
      target: '#tour-steps__search',
      content:
        "Need to find a specific project, folder or file? The search bar's here for you.",
      placement: 'right-start',
      title: 'Search'
    }
  },
  // Media queue
  mediaQueue: {
    step: {
      target: '#tour-steps__media-queue',
      content:
        'The media queue shows the progress of your uploads and exports.',
      title: 'Media queue'
    }
  }
};
