import { useEffect } from 'react';

/**
 * Disable mouse interaction with childelements while scrolling (e.g. to preven unnecessary rerenders from hover effects)
 * @param config.disableInteraction  whether to disable interaction
 * @param config.containerRef  scroll container ref
 * @param config.scrolling whether the container is being scrolled
 */
export const useDisableScrollInteraction = ({
  disableInteraction,
  containerRef,
  scrolling
}) => {
  useEffect(() => {
    if (disableInteraction && containerRef) {
      if (scrolling) {
        (
          containerRef.container.firstElementChild
            .firstElementChild as HTMLDivElement
        ).style.pointerEvents = 'none';
      } else {
        (
          containerRef.container.firstElementChild
            .firstElementChild as HTMLDivElement
        ).style.pointerEvents = undefined;
        63;
      }
    }
  }, [scrolling, containerRef, disableInteraction]);
};
