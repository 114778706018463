import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import {
  selectors as statusSelectors,
  statuses
} from '@cube3/state/src/redux/ducks/request-status';
import { actionCreators as requestCreators } from '@cube3/state/src/redux/ducks/resource-edges';
import { useCallback, useEffect, useState } from 'react';
import { change } from 'redux-form';

import { useModalActions } from '../../../Modals/modalActions';
import { useDispatch } from 'react-redux';
import { isEmptyValue } from './utils/isEmptyValue';
import { isEqualValue } from './utils/isEqualValue';
import { customId } from './utils/customId';

export const usePaste = ({
  contractId,
  isDraft,
  availableKeys,
  formIds,
  clipboardValues,
  allFormValues
}) => {
  const [paste, setPaste] = useState(false);
  const [secondPaste, setSecondPaste] = useState(false);

  const { prompt } = useModalActions();
  const dispatch = useDispatch();

  const retrieveContractValuesStatus = useTypedSelector((state) =>
    statusSelectors.getStatus(
      state.requestStatus,
      requestCreators.retrieveResourceEdges(
        contractId,
        'contract',
        'contract-values',
        'contract-value'
      )
    )
  );

  const onPaste = useCallback(() => {
    setPaste(true);
  }, [setPaste]);

  const applyPaste = useCallback(
    (ignoreConflicts = false) => {
      const conflicts = [];

      formIds?.forEach((id) => {
        availableKeys[customId(id)]?.forEach((k) => {
          if (
            Object.hasOwnProperty.call(clipboardValues, k) &&
            clipboardValues[k]
          ) {
            // only allow to change contract_type when contract is draft
            if (!isDraft && k === 'key_contract_type') return;

            if (!isEqualValue(allFormValues[k], clipboardValues[k])) {
              if (isEmptyValue(allFormValues[k])) {
                dispatch(change(id, k, clipboardValues[k]));
              } else {
                conflicts.push(change(id, k, clipboardValues[k]));
              }
            }
          }
        });
      });

      if (!ignoreConflicts && conflicts.length > 0) {
        prompt((close) => {
          return {
            title: 'Replace existing values?',
            text: 'Some fields alread contain values. Do you want to replace these with the data on the clipboard?',
            onClose: () => close(),
            buttonText: 'Keep current',
            renderTwoButtons: true,
            secondButtonClickHandler: () => {
              conflicts.forEach((c) => {
                dispatch(c);
              });
              close();
            },
            secondButtonText: 'Replace',
            secondButtonColorVariant: 'filledDanger'
          };
        });
      }
    },
    [
      formIds,
      availableKeys,
      clipboardValues,
      isDraft,
      allFormValues,
      dispatch,
      prompt
    ]
  );

  // when pasting values to a new contract, 'auto-fill' fields after contract_type has been pasted.
  useEffect(() => {
    // past into existing contract or metadata
    if (paste && !isDraft) {
      applyPaste();
      setPaste(false);
    }

    // new contract before type is set needs a second paste once the fields for the type have loaded
    if (paste && isDraft) {
      // applyPaste without asking to override conflicts, so the modal only shows once
      applyPaste(true);
      setPaste(false);

      // prime the system to paste again when the form fields have loaded
      setSecondPaste(true);
    }

    // second paste only runs for new contracts and applies any conflict overrides
    if (
      secondPaste &&
      isDraft &&
      contractId &&
      retrieveContractValuesStatus === statuses.SUCCESS
    ) {
      applyPaste();
      setSecondPaste(false);
    }
  }, [isDraft, contractId, paste, secondPaste, retrieveContractValuesStatus]);

  return onPaste;
};
