import React, { useMemo } from 'react';
import { ModalWindowUI } from '@cube3/ui/src/Modal/ModalWindowUI';

import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';

import { useGeneralForm } from '../hooks/useGeneralFormConfig';
import { CreateOrEditContractModal } from '../prefabs/CreateOrEditContractModal';

interface Props {
  modalContext: {
    contractId: string;
    /** when creating a duplicate from an existing contract, this value will be true */
    isDraft?: boolean;
  };
}

export const EditContractModal: React.FunctionComponent<Props> = props => {
  const { contractId, isDraft } = props.modalContext;

  const contract = useResource__ALPHA({
    resourceType: 'contract',
    resourceId: contractId
  });

  const keyGroups = useResourceList__ALPHA({
    resourceType: 'contract-type',
    resourceId: contract.resource?.relationships.contract_type.id,
    edgeType: 'contract-key-group',
    edgeLabel: 'contract-key-groups'
  });

  // const values = useResourceList__ALPHA({
  //   resourceType: 'contract',
  //   resourceId: contractId,
  //   edgeType: 'contract-value',
  //   edgeLabel: 'contract-values'
  // });

  // we handcraft a category for general settings, that doesn't get it's field definitions from the api
  // It also need a custom submit function
  const { category: general } = useGeneralForm(contractId, isDraft);

  const categories = useMemo(() => {
    return keyGroups.resources && general
      ? [general, ...keyGroups.resources]
      : undefined;
  }, [keyGroups.resources, general]);

  const title = `Edit Contract: ${contract?.resource?.display_name}`;

  if (!general) {
    return <ModalWindowUI title={title} loading={true} />;
  }

  return (
    <CreateOrEditContractModal
      isDraft={isDraft}
      title={title}
      categories={categories}
      contractId={contractId}
      general={general}
    />
  );
};
