import React, { useCallback } from 'react';
import MenuButton from '../../../Buttons/MenuButton';
import { PopoverMenu } from '../../../menus/PopOverMenu/PopoverMenu';
import { isMenuEntry, isMenuGroup, MenuEntry, MenuGroup } from './types';

import { iconFileRequest } from '../../../icons/GeneralSVG';
import { useCommandBarBaseStyles } from './CommandBarBaseUI';
import {
  RenderSubmenuButton,
  RenderSubmenuContent,
  RenderTooltip
} from '../../RenderSubmenuContent';

interface ButtonsProps {
  config: MenuEntry[];
}

const RenderMenuButton = (props) => {
  const { entry } = props;
  const { menubutton } = useCommandBarBaseStyles(props);

  const handleClick = useCallback(
    (ev) => {
      return entry.onClick(ev, entry.extraClickArgs);
    },
    [entry.onClick, entry.extraClickArgs]
  );

  if (!entry.visible) return null;

  return (
    <MenuButton
      key={entry.title}
      dataType={props['data-type']}
      text={entry.title}
      disabled={entry.disabled}
      disabledReason={entry.disabledReason}
      path={entry.icon ? entry.icon : iconFileRequest}
      onClick={handleClick}
      colorVariant={entry.colorVariant || 'ghost1'}
      extraSVGProps={entry.extraSVGProps}
      className={menubutton}
    />
  );
};

const RenderPopoverMenu = (props) => {
  const { menubutton, menugroup } = useCommandBarBaseStyles(props);
  const { entry, closeFn } = props;

  if (!entry.visible) return null;
  if (entry.disabled)
    return <RenderTooltip entry={entry} className={menubutton} />;

  return (
    <PopoverMenu
      key={entry.title}
      buttonText={entry.title}
      classProps={{ menuButton: menubutton, root: menugroup }}
      menuIconPath={entry.icon ? entry.icon : undefined}
      childrenRenderProps={(close, isOpen) => {
        return (
          <React.Fragment>
            {entry.children &&
              (entry.children as Array<MenuEntry | MenuGroup>).map((child) => {
                if (isMenuEntry(child)) {
                  if (child.component) {
                    return child.component(closeFn ? closeFn : close, isOpen); // closeFn: commandbar buttons use different handleClose function
                  }
                  // the second layer popover menu
                  if (child.children) {
                    return (
                      <RenderSubmenuContent
                        entry={child}
                        key={child.title}
                        classes={{ menuButton: menubutton, root: menugroup }}
                        closeFn={close}
                        data-type="librarydeselectoverride"
                      />
                    );
                  }
                  return (
                    <RenderSubmenuButton
                      key={child.title}
                      entry={child}
                      closeFn={close}
                      classes={{ menuButton: menubutton, root: menugroup }}
                      data-type="librarydeselectoverride"
                    />
                  );
                }
                if (isMenuGroup(child))
                  return child.entries.map((cEntry) => (
                    <RenderSubmenuButton
                      key={cEntry.title}
                      entry={cEntry}
                      closeFn={close}
                      classes={{ menuButton: menubutton, root: menugroup }}
                      data-type="librarydeselectoverride"
                    />
                  ));
                return null;
              })}
          </React.Fragment>
        );
      }}
    />
  );
};

export const CommandBarButtonsUI: React.FC<ButtonsProps> = React.memo(
  (props) => {
    const { config } = props;
    const { menubutton } = useCommandBarBaseStyles(props);

    return (
      <React.Fragment>
        {config &&
          config.map((entry) => {
            return (
              <div className={menubutton} key={entry.title}>
                {entry.children ? (
                  <RenderPopoverMenu key={entry.title} entry={entry} />
                ) : (
                  <RenderMenuButton
                    entry={entry}
                    data-type="librarydeselectoverride"
                  />
                )}
              </div>
            );
          })}
      </React.Fragment>
    );
  }
);
