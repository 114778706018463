export const useAlreadyMemberCheck = (
  currentEmailAddressesInWorkspace,
  emails,
  emailInput
) => {
  /*  check if the email already exists in the workspace */
  const isAlreadyMember =
    currentEmailAddressesInWorkspace.indexOf(emailInput) !== -1 ||
    emails.some(
      (email: { value: string }) =>
        currentEmailAddressesInWorkspace.indexOf(email.value) !== -1
    );

  /* array of emails that are in the workspace and also added as chip in invite members box  */
  const errorMembersEmailsArray = emails.filter(email => {
    return currentEmailAddressesInWorkspace.indexOf(email.value) !== -1;
  });

  const alreadyMemberError = isAlreadyMember
    ? errorMembersEmailsArray.length >= 0 && errorMembersEmailsArray.length < 2
      ? `The account linked to ${
          errorMembersEmailsArray[0]
            ? errorMembersEmailsArray[0].value
            : emailInput
        } is already a member of this workspace.`
      : `The accounts linked to${errorMembersEmailsArray.map(
          email => ` ${email.value}`
        )} are already members of this workspace.`
    : undefined;

  return { alreadyMemberError, isAlreadyMember };
};
