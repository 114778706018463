import React, { MutableRefObject } from 'react';

import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText, {
  FormHelperTextProps
} from '@material-ui/core/FormHelperText';
import InputBase, { InputProps } from '@material-ui/core/Input';
import FormLabel, { FormLabelProps } from '@material-ui/core/FormLabel';
import Select, { SelectProps } from '@material-ui/core//Select';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '../../typography/Typography';

const isAllowedKey = (key) => {
  return !!key.match(
    /(?:Backspace|Delete|Home|End|Tab|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|[0-9-.])/
  );
};

const isAllowedCombo = (ev) => {
  return (
    ['c', 'v', 'x'].find((k) => k === ev.key) && (ev.metaKey || ev.ctrlKey)
  );
};

// TODO: hopefully one day remove :|
const firefoxNumberSanitizer = (ev) => {
  if (!isAllowedKey(ev.key) && !isAllowedCombo(ev)) {
    console.warn('manually blocking character for number input', ev.key, ev);
    ev.preventDefault();
  }
};

export const styles = (theme: Theme) => {
  const borderColor =
    theme.palette.type === 'light'
      ? 'rgba(0, 0, 0, 0.23)'
      : 'rgba(255, 255, 255, 0.23)';

  return createStyles({
    /* Styles applied to the root element. */
    root: {
      padding: `0px ${theme.customSpacing.padding[16]}`,
      minHeight: 40,
      ...theme.typographyStyles.body1,
      marginTop: '0px',
      position: 'relative',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      borderStyle: 'solid',
      borderWidth: '1px',
      boxSizing: 'border-box',
      borderColor: theme.customPalette.line.onBase1.contrast3,
      background: theme.customPalette.surfaceAccent.onBase.shade2,
      overflow: 'hidden',
      '& input:-webkit-autofill': {
        // NOTE: super ugly hack because chrome won't let you style stored credentials
        '-webkit-box-shadow': `0 0 0px 1000px #333333 inset`,
        '-webkit-text-fill-color': theme.palette.text.primary
      },

      '&:before, &:after': {
        display: 'none'
      },
      '&:hover:not($disabled):not($focused):not($error)': {
        borderColor: theme.customPalette.primary.lineWhiteHighEmphasis,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: borderColor
        }
      },
      '&$focused': {
        borderColor: theme.customPalette.surface.brand1
      },
      '&$error': {
        borderColor: theme.customPalette.surface.danger1
      },
      '&$disabled': {
        borderColor: theme.customPalette.line.onBase1.contrast2,
        opacity: 0.6
      }
    },
    dense: {
      height: `32px !important`,
      minHeight: `32px !important`,
      padding: `0px 8px !important`,
      '& input': {
        padding: `2px 0px 2px !important`
      }
    },
    withLabelLeft: {
      flexDirection: 'row',
      alignItems: 'center',
      '& > label': {
        marginRight: theme.customSpacing.margin[32]
      }
    },
    formLabel: {
      ...theme.typographyStyles.body2,
      color: theme.customPalette.text.onBase.contrast2,
      marginBottom: theme.customSpacing.margin[8],
      paddingLeft: 0
    },
    // /* Styles applied to the root element if the component is focused. */
    focused: {},
    /* Styles applied to the root element if `disabled={true}`. */
    disabled: {},

    /* Styles applied to the root element if `error={true}`. */
    error: {},
    // /* Styles applied to the root element if `multiline={true}`. */
    multiline: ({ fullWidth }: any) => ({
      width: fullWidth ? '100%' : 360,
      minHeight: '60px',
      padding: `${theme.customSpacing.padding[8]} ${theme.customSpacing.padding[16]}`,
      boxSizing: 'border-box', // Prevent padding issue with fullWidth.
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'unset'
    }),
    // /* Styles applied to the `input` element. */
    input: {
      ...theme.typographyStyles.body1,
      color: theme.customPalette.text.onBase.contrast1,
      '&::placeholder': {
        color: theme.customPalette.text.onBase.contrast3
      },
      '&::selection': {
        background: theme.customPalette.surfaceAccent.onBase.selected2
      }
    },
    /* Styles applied to the `input` element if `margin="dense"`. */
    inputMarginDense: {
      paddingTop: 15,
      paddingBottom: 15
    },
    formControl: {
      'label + &': {
        marginTop: 0
      }
    },
    formControlHelper: {}
  });
};

export interface Cube3TextFieldProps extends WithStyles<typeof styles> {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string | number;
  error?: boolean;

  fullWidth?: boolean;
  helperText?: string | React.ReactElement;
  id?: string;

  inputRef?: MutableRefObject<'input'> | ((node: HTMLInputElement) => void);
  label?: string | React.ReactElement;
  multiline?: boolean;
  name?: string;
  placeholder?: string;
  required?: boolean;
  rows?: string | number;
  rowsMax?: string | number;
  select?: boolean;
  [key: string]: any;
  // spreader props
  formLabelProps?: FormLabelProps;
  selectProps?: SelectProps;
  formHelperTextProps?: FormHelperTextProps;
  formControlProps?: FormControlProps;
  inputBaseProps?: InputProps;

  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;

  type?: string;
  value?: string | number | boolean | string[] | number[] | boolean[];
  disabled?: boolean;

  withLabelLeft?: boolean;
  // events
  onBlur?(ev: React.FocusEvent): void;
  onChange?(ev: React.ChangeEvent<HTMLInputElement>): void;
  onFocus?(ev: React.FocusEvent): void;
  innerRef: React.RefObject<HTMLInputElement>;
  dense?: boolean;
}

class Cube3TextField extends React.PureComponent<
  Cube3TextFieldProps,
  { focus: boolean }
> {
  private labelRef;
  private labelNode;
  private inputRef;
  private caret = null;
  constructor(props: Cube3TextFieldProps) {
    super(props);
    this.labelRef = React.createRef();

    this.state = {
      focus: false
    };
  }

  toggleFocus = () => {
    this.setState(({ focus }) => ({
      focus: !focus
    }));
  };

  handleRef = (node) => {
    this.inputRef = node;
    if (this.props.inputRef) {
      if (typeof this.props.inputRef === 'function') {
        this.props.inputRef(node);
      } else if (this.props.inputRef.current) {
        this.props.inputRef.current = node;
      }
    }
  };

  queue: string[] = [];

  handleChange = (ev) => {
    if (this.props.onChange) {
      // store the caret position
      // we need to replace it later because it jumps
      // to the end
      if (this.caret === null) {
        this.caret = this.inputRef?.selectionStart;
      }
      this.props.onChange(ev);
    }
  };

  componentDidUpdate() {
    if (
      this.inputRef?.value &&
      this.caret &&
      document.activeElement === this.inputRef
    ) {
      // set the caret position to what we stored
      // NOTE: we only do this if the element has focus
      // to prevent an issue with safari, where input become unfocusable
      this.inputRef?.setSelectionRange(this.caret, this.caret);
      this.caret = null;
    }
  }

  render() {
    const {
      classes,
      autoComplete,
      autoFocus,
      children,
      className,
      defaultValue,
      error,
      formHelperTextProps,
      fullWidth,
      helperText,
      id,
      formLabelProps,
      inputRef,
      label,
      multiline,
      name,
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      placeholder,
      required,
      rows,
      rowsMin,
      rowsMax,
      select,
      selectProps,
      type,
      value,
      formControlProps,
      startAdornment,
      endAdornment,
      withLabelLeft,
      'data-cy': dataCy,
      numberControls,
      dense,
      ...other
    } = this.props;

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

    const InputElement: JSX.Element = (
      <InputBase
        classes={{
          root: dense ? `${classes.dense} ${classes.root}` : classes.root,
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
          multiline: classes.multiline,
          input: classes.input,
          inputMarginDense: classes.inputMarginDense,
          formControl: classes.formControl
        }}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={className}
        value={value}
        error={error}
        fullWidth={fullWidth}
        id={id}
        inputRef={this.handleRef}
        multiline={multiline}
        name={name}
        onBlur={(e: any) => {
          this.toggleFocus();
          onBlur(e);
          onChange(e);
        }}
        onFocus={(e: any) => {
          this.toggleFocus();
          onFocus(e);
        }}
        onChange={this.handleChange}
        onKeyDown={type === 'number' ? firefoxNumberSanitizer : undefined}
        placeholder={placeholder}
        required={required}
        rows={rows}
        rowsMin={rowsMin}
        rowsMax={rowsMax}
        minRows={rowsMin}
        maxRows={rowsMax}
        type={type === 'number' ? 'text' : type}
        aria-describedby={helperTextId}
        startAdornment={startAdornment ? startAdornment : undefined}
        endAdornment={endAdornment ? endAdornment : undefined}
        inputProps={{ 'data-cy': dataCy }}
      />
    );

    return (
      <FormControl
        className={[className, withLabelLeft && classes.withLabelLeft].join(
          ' '
        )}
        error={error}
        fullWidth={fullWidth}
        required={required}
        {...formControlProps}
        {...other}
      >
        {label && (
          <FormLabel
            classes={{ root: classes.formLabel }}
            htmlFor={id}
            innerRef={this.labelRef}
            {...formLabelProps}
          >
            <Typography typographyStyle={'body2'} color="contrast2">
              {label}
            </Typography>
          </FormLabel>
        )}
        {select ? (
          <Select
            aria-describedby={helperTextId}
            value={value}
            input={InputElement}
            {...selectProps}
          >
            {children}
          </Select>
        ) : numberControls ? (
          <div style={{ position: 'relative' }}>
            {InputElement}
            {numberControls}
          </div>
        ) : (
          InputElement
        )}
        {helperText && (
          <FormHelperText
            id={helperTextId}
            {...formHelperTextProps}
            component={'div'}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Cube3TextField);
