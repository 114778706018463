import React from 'react';
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';
import { useModalActions } from '../../Modals/modalActions';

interface Props {
  modalContext: { remove: boolean; lastMember: boolean; title?: string };
}

const TITLE_REMOVE = 'Unable to leave Project';
const TITLE_CHANGE = 'Cannot change role';
const MESSAGE_MAIN = 'A Project requires at least one Admin.';
const MESSAGE_LAST_MEMBER = 'A Project requires at least one Member.';
const MESSAGE_REMOVE =
  'If you no longer need this Project, you can delete it through the settings.';
const MESSAGE_CHANGE =
  // 'Please first assign another Admin before demoting yourself';
  'Make someone else a Project Admin before trying again.';

export const ProjectLastAdminNotice: React.FunctionComponent<Props> = props => {
  const { modalContext } = props;
  const { remove, lastMember, title } = modalContext;

  const modalActions = useModalActions();

  return (
    <GenericNoticeModalUI
      onClose={() => {
        modalActions.previousModal();
      }}
      title={title ? title : remove ? TITLE_REMOVE : TITLE_CHANGE}
      text={lastMember ? MESSAGE_LAST_MEMBER : MESSAGE_MAIN}
      textSecondary={
        lastMember ? undefined : remove ? MESSAGE_REMOVE : MESSAGE_CHANGE
      }
      buttonColorVariant="filled1"
    />
  );
};
