import React from 'react';
import fileImage from '../assets/images/Library/file.svg?url';
import Button from '../Buttons/Button';
import Time from '../human/Time';
import { LibraryItemOverlay } from '../library/LibraryItemOverlay';
import ProgressWithText from '../library/ProgressWithText/ProgressWithText';
import { MediaQueueTile } from '../Prefabs/MediaQueue/MediaQueueTile';
import { ThumbnailGroup } from '../thumbnails/ThumbnailGroup';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

interface Props {
  thumbnails: string[];
  /** title of singular export */
  title: string;
  /** if no export title, use assets name */
  subTitle?: string;
  path?: JSX.Element;
  status: string;
  statusProgress?: number;
  statusLabel?: string;
  channelName?: string;
  targetName: string;
  gradient?: 'none' | 'lamp';
  created_at: string | Date;
  onClickViewAll?: () => void;
  highlight?: boolean | string;
  multiTargets?: JSX.Element;
}

const fallbackThumb = [fileImage];
const parseToMediaTileStatus = {
  pending: 'pending',
  running: 'active',
  processing: 'active',
  failed: 'failed',
  success: 'success'
} as const;

const parseToUploadState = {
  pending: 'processing_pending',
  running: 'processing',
  processing: 'processing',
  success: 'processing_complete',
  failed: 'processing_failed'
} as const;

export const ExportQueueListItemUI: React.FC<Props> = (props) => {
  const {
    status,
    statusLabel,
    statusProgress,
    thumbnails,
    path,
    title,
    targetName,
    channelName,
    created_at,
    gradient,
    subTitle,
    onClickViewAll,
    highlight,
    multiTargets
  } = props;

  const destination = `${targetName || ''}${
    targetName && channelName ? ' • ' : ''
  }${channelName || ''}`;

  return (
    <li style={{ listStyle: 'none' }}>
      <MediaQueueTile
        highlight={highlight}
        gradient={gradient}
        thumb={
          <LibraryItemOverlay
            front={
              status !== 'success' &&
              status !== 'failed' && (
                <ProgressWithText
                  uploadState={parseToUploadState[status]}
                  gridview={false}
                  disabledByUpload={true}
                  disableTooltip={true}
                />
              )
            }
            back={
              <ThumbnailGroup
                thumbnails={thumbnails || fallbackThumb}
                ratio="sixteenToNine"
                width={64}
                cornerRadius="none"
              />
            }
          />
        }
        title={title}
        subTitle={subTitle}
        contextRow={path}
        destination={destination}
        timestampRow={
          created_at ? (
            <span>
              Last activity <Time date={created_at} />
            </span>
          ) : (
            <Shimmer width={120} height={20} />
          )
        }
        status={parseToMediaTileStatus[status]}
        statusLabel={statusLabel}
        statusProgress={statusProgress}
        multiTargets={multiTargets}
        viewDetails={
          onClickViewAll && (
            <Button
              onClick={onClickViewAll}
              text="view details"
              colorVariant={'ghost1'}
              marginCollapse={'left'}
              size="small"
              extraButtonProperties={{
                style: { height: '25px', marginLeft: '-8px', padding: '8px' }
              }}
            />
          )
        }
      />
    </li>
  );
};
