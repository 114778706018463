import React, { useMemo, useState, useEffect, useContext } from 'react';
import { RequestStatuses, statuses } from '../../ducks/request-status';
import { Feature } from '@cube3/common/model/resource-types';
import { useCurrentWorkspace } from '../Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from './useResourceList';

export const HARDCODED_FEATURES = [];

interface UseFeaturesConfig {
  features?: Feature[];
}

const emptyArray = [];

const usePollFeatures = () => {
  const [preReleaseFeatures, setPreReleaseFeatures] = useState(
    JSON.parse(localStorage.getItem('PRE_RELEASE_FEATURES')) || emptyArray
  );

  const key = preReleaseFeatures.join();

  useEffect(() => {
    let t;

    const loop = () => {
      t = setTimeout(loop, 5000);
      const loadedFeatures =
        JSON.parse(localStorage.getItem('PRE_RELEASE_FEATURES')) || emptyArray;
      const loadedKey = loadedFeatures.join();

      if (key !== loadedKey) {
        setPreReleaseFeatures(loadedFeatures);
      }
    };

    t = setTimeout(loop, 5000);
    return () => clearTimeout(t);
  }, [key]);

  return preReleaseFeatures;
};

const featureDefaults = {
  features: [] as Feature[],
  loading: false
};

const workspaceFeaturesContext = React.createContext(featureDefaults);

export const WorkspaceFeaturesProvider = ({ children, bypass = false }) => {
  const activeFeatures = useFeaturesRoot();

  return (
    <workspaceFeaturesContext.Provider
      value={!bypass ? activeFeatures : featureDefaults}
      children={children}
    />
  );
};

export const useFeaturesRoot = () => {
  const hardcodedFeatures = HARDCODED_FEATURES;

  const preReleaseFeatures = usePollFeatures();

  const [workspaceId] = useCurrentWorkspace();

  const workspaceFeatures = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'feature-flag',
    edgeLabel: 'feature-flags'
  });

  const merged = useMemo(() => {
    return Array.from(
      new Set([
        ...hardcodedFeatures,

        ...(workspaceFeatures.resources || emptyArray)
          .filter((f) => f.enabled)
          .map((f) => f.feature),
        ...preReleaseFeatures
      ])
    );
  }, [hardcodedFeatures, preReleaseFeatures, workspaceFeatures.resources]);

  // useImmutablePropsCheck({
  //   workspaceId,
  //   features,
  //   hardcodedFeatures,
  //   userFeatures,
  //   customerFeatures,
  //   preReleaseFeatures,
  //   workspaceFeatures: workspaceFeatures.resources
  // });

  return {
    features: merged as Feature[],
    loading: !(
      [statuses.SUCCESS, statuses.FAILED] as RequestStatuses[]
    ).includes(workspaceFeatures.status)
  };
};

/**
 *
 * @param config object containing at least a 'features' property, that is an array of features
 * @returns array indicating per requested feature wheter it's available. values are undefined while features are loading
 */
export const useFeatures = ({
  features: requestedFeatures = emptyArray
}: UseFeaturesConfig) => {
  const { features: activeFeatures, loading } = useContext(
    workspaceFeaturesContext
  );

  const enabled = useMemo(() => {
    return requestedFeatures.map((f) =>
      loading ? undefined : activeFeatures.includes(f)
    );
  }, [activeFeatures, requestedFeatures, loading]);
  return enabled;
};

/**
 *  HOC version of hook
 */
export const withFeatures = (config) => (Wrapped) => {
  return (props) => {
    const features = useFeatures(config);

    return <Wrapped {...props} features={features} />;
  };
};
