// HOC
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
// interfaces
import { Feature, ShareBase, VerifyType } from '@cube3/state/types';

import { dateNormalizer } from '@cube3/ui/src/helpers/FieldNormalizers';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
// ui
import {
  FileRequestLinkSettingsForm,
  FileRequestFormProps
} from '@cube3/ui/src/Prefabs/FileRequests/Modals/FileRequestLinkSettingsForm';
import { useModalActions } from '../../Modals/modalActions';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { ComplexErrors } from '../../../../forms/helpers/validators';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
// import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
// helpers
import { compose } from '../../../../../utils/component-helpers';
import { useLinkSettingsActions } from '../hooks/useLinkSettingsActions';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';
import LinkSettingsUI from '@cube3/ui/src/Prefabs/shareLink/Modals/LinkSettings/LinkSettingsUI';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';

/// <Summary>
/// Functional component that retrieves the share for a resource (asset etc) and displays them
/// in a settings screen with options
/// </Summary>

interface HOCMappedProps {
  workspace: Workspace;
  fileRequest: ShareBase;
  mutateResource: Function;
  mutateStatus: string;
}

type Properties = HOCMappedProps &
  FileRequestFormProps &
  InjectedFormProps<FileRequestFormProps, Properties, ComplexErrors> &
  ModalReceiverProps<{ id: string }>;

const mapStateToProps = (state, ownProps: Properties) => {
  const values = getFormValues(ownProps.modalContext.id)(state);

  const request: ShareBase = ownProps.fileRequest;

  // map the values of the retrieved shares to the form values to prefill the form.
  const initialValues: FileRequestFormProps = request && {
    verifyUser: request.verify_user || request.verify !== VerifyType.never,
    verifyType:
      request.verify !== VerifyType.never
        ? request.verify
        : VerifyType.recipients_only,
    allowRequestAccess: request.allow_request_access,
    expiryDate: request.expires_at
      ? moment(request.expires_at).endOf('day').toISOString()
      : moment()
          .add(
            ownProps.workspace
              ? ownProps.workspace.default_filerequests_expiration_duration
              : 14,
            'day'
          )
          .toISOString() // default expiry date is 2 weeks (this actually never should execute.)
  };

  return {
    form: ownProps.modalContext.id,
    initialValues,
    ...values
  };
};
const emptyArray = [];

const REQUIRED_FEATURES = {
  features: ['/WORKSPACE/SHARING/MAGIC_LINK'] as Feature[]
};

const FileRequestSettingsModal: React.FunctionComponent<Properties> = (
  props
) => {
  const {
    modalContext,
    // form
    verifyUser,
    verifyType,
    allowRequestAccess,
    expiryDate,
    dirty,
    reset,
    // HOC
    fileRequest,
    mutateResource,
    mutateStatus
  } = props;

  const [hasVerifyUserFeature] = useFeatures(REQUIRED_FEATURES);

  const resourceId = modalContext.id;
  const { openModal } = useModalActions();

  const {
    onClickAddUser,
    onClickDeleteLink,
    onClickManageAccess,
    onCloseEvent
  } = useLinkSettingsActions({
    id: resourceId,
    intent: 'request'
  });

  const onClickEditTitle = React.useCallback(() => {
    if (!fileRequest) return;
    openModal('filerequest_change_name', {
      type: 'share',
      id: fileRequest.id,
      display_name: fileRequest.display_name,
      extraAttributes: { email_addresses: fileRequest?.email_addresses }
    });
  }, [openModal, fileRequest]);

  const onClickSaveChanges = React.useCallback(() => {
    mutateResource({
      type: 'share',
      id: resourceId,
      //  intent: 'request',
      verify: !verifyUser ? VerifyType.never : verifyType,
      allow_request_access: allowRequestAccess,
      expires: true,
      expires_at: dateNormalizer(expiryDate),
      email_addresses: fileRequest?.email_addresses
    });
  }, [
    resourceId,
    mutateResource,
    fileRequest,
    expiryDate,
    verifyUser,
    verifyType,
    allowRequestAccess,
    dateNormalizer
  ]);

  React.useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      onCloseEvent();
    }
  }, [mutateStatus, onCloseEvent]);

  return (
    <LinkSettingsUI
      linkType="request"
      title={fileRequest?.display_name}
      dirty={dirty}
      onClickSaveChanges={onClickSaveChanges}
      onCloseEvent={onCloseEvent}
      onClickDeleteLink={onClickDeleteLink}
      onClickManageAccess={onClickManageAccess}
      onClickEditTitle={onClickEditTitle}
      onClickAddUser={onClickAddUser}
      onReset={reset}
      showLink={false}
      email_addresses={fileRequest?.email_addresses || emptyArray}
      createdDate={fileRequest?.created_at}
      linkSettingsForm={
        <FileRequestLinkSettingsForm
          expiryDate={expiryDate || fileRequest?.expires_at}
          verifyUser={verifyUser}
          verifyType={verifyType}
          allowRequestAccess={allowRequestAccess}
          hasVerifyUserFeature={hasVerifyUserFeature}
        />
      }
    />
  );
};

const withConnectedResource = (Wrapped) => (props) => {
  const fileRequestId = props.modalContext.id;
  const fileRequest = useResource__ALPHA({
    resourceId: fileRequestId,
    resourceType: 'share'
  });

  const [workspaceId, workspace] = useCurrentWorkspace();

  const [mutateResource, mutateStatus] = useMutateResource__ALPHA(
    React.useMemo(
      () => ({
        cacheInvalidator: (r) => [
          r,
          { type: 'workspace', id: workspaceId, relationship: 'shares' }
        ],
        actionDecorators: [
          addFormId(fileRequestId, {
            formState: undefined,
            useRequestStatus: true
          })
        ]
      }),
      []
    )
  );

  const loading = !fileRequest.resource || fileRequest.loading || !workspace;

  return loading ? (
    <ModalMenuUI loading={true} />
  ) : (
    <Wrapped
      {...props}
      fileRequest={fileRequest.resource}
      workspace={workspace}
      mutateResource={mutateResource}
      mutateStatus={mutateStatus}
    />
  );
};

export default compose(FileRequestSettingsModal)(
  withConnectedResource,
  connect(mapStateToProps),
  reduxForm<FileRequestFormProps, Properties, ComplexErrors>({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })
);
