import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React from 'react';
import { useModalActions } from '../../Modals/modalActions';

export const LoggedOutAlertModal = () => {
  const modalActions = useModalActions();
  return (
    <ModalNoticeUI
      title="Please reauthenticate"
      footerRightComponent={
        <Button
          onClick={modalActions.closeAllModals}
          text="Close"
          colorVariant="filled1"
        />
      }
    >
      <Typography color="contrast1">
        This session was closed. This happens when:
      </Typography>
      <Typography color="contrast2">
        <ul>
          <li>A long running session has expired</li>
          <li>You have logged out elsewhere</li>
          <li>A network error prevented session verification</li>
        </ul>
      </Typography>
    </ModalNoticeUI>
  );
};
