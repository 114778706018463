import React, { useCallback } from 'react';
import { Select } from '@cube3/cubicle/src/core/atoms/Select/Select';
import { Option } from '@cube3/cubicle/src/core/atoms/Select/Option';
import { Icon } from '@cube3/cubicle/src/core/atoms/Icon';

export const VerificationTypePicker = (props) => {
  const { input, className, disabled } = props;
  return (
    <div className={className}>
      <Select value={input.value} disabled={disabled}>
        <Option
          value="everyone"
          onClick={() => input.onChange('everyone')}
          startAdornment={<Icon icon="link" />}
        >
          Anyone with this link
        </Option>
        <Option
          value="recipients_only"
          onClick={() => input.onChange('recipients_only')}
          startAdornment={<Icon icon="group" />}
        >
          Recipients only
        </Option>
      </Select>
    </div>
  );
};
