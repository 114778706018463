import React from 'react';
import KeyValueRow, { KeyValueTable } from '../../InfoPane/KeyValueRow';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Time, { TooltipPlacement } from '../../human/Time';
import Bytes from '../../human/Bytes';
import FileType from '../../human/FileType';
import Duration from '../../human/Duration';
import Bitrate from '../../human/Bitrate';
import { KeyValue } from '../../metadata/MetadataReadonlyUI';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';

/// <Summary>
/// Takes a single object which has some main properties
/// and renders the title and the fields. Also tries to
/// them into human common human components. Since the
/// passed field value can be anything, we need to check
/// if the object passed is of type string.
/// </Summary>

export interface PropertyGroup {
  groupName: string;
  fields: KeyValue[];
}
interface Props {
  properties: KeyValue[];
  keyStyles?: string;
  maxLength?: number;
  placement?: TooltipPlacement;
  title?: string;
  extraElement?: JSX.Element;
  renderSpecialItem?: (item: KeyValue) => JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // marginBottom: 30
    },
    title: {
      fontWeight: 400
      //   textTransform: 'uppercase'
    },
    keyStyle: {
      minWidth: 150
    },
    valueStyle: {
      minWidth: 300
    }
  })
);

const renderPropertyItem = (item: KeyValue, placement) => {
  // reads the given item consisting out of of key and value
  // and tries to resolve them to specific components,
  // falls back to deault key value no specific component is available.

  switch (item.key) {
    // in case we find a key lastmodified, render a time component.
    case 'Last modified':
      return (
        <Time
          date={item.value}
          placement={placement}
          now={new Date()}
          label="Last modified at"
        />
      );
    case 'Creation date':
      return (
        <Time
          date={item.value}
          placement={placement}
          now={new Date()}
          label="Created at"
        />
      );
    case 'Duration':
      return (
        <Duration duration={item.value} toolTip={true} placement={placement} />
      );

    case 'Size':
      return (
        <Bytes
          size={item.value}
          inFormat="B"
          fullForm={false}
          placement={placement}
          toolTip={true}
        />
      );

    case 'Bit Rate':
      return (
        <Bitrate
          size={item.value}
          inFormat="B"
          fullForm={false}
          placement={placement}
          toolTip={true}
        />
      );

    // in case we find a key mimetype, render the filetype component.
    case 'MimeType':
      return <FileType mimeType={item.value} />;

    // in case we find a key pages, render null as we don't have a good way to deal with PDF's yet
    case 'Pages':
      return null;

    // if we have no specific component for this entry, render the key and the value.
    default:
      return item.value;
  }
};

const PropertiesRenderer = (props: Props) => {
  const {
    properties,
    keyStyles = '',
    maxLength,
    placement = 'top',
    title = undefined,
    extraElement = undefined
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Title */}
      {title && (
        <Typography variant="md" weight="label">
          {title}
        </Typography>
      )}
      {/* Keyvalues: map over the given properties and try to resolve them to a specific component.*/}
      <KeyValueTable>
        {properties &&
          properties.length > 0 &&
          properties.map((item, index) => {
            if (maxLength && index >= maxLength) return null;
            return (
              <KeyValueRow
                classes={{ key: keyStyles }}
                key={item.key + index}
                propertyKey={item.key}
                propertyValue={renderPropertyItem(item, placement)}
              />
            );
          })}
        {extraElement}
      </KeyValueTable>
    </div>
  );
};

export default PropertiesRenderer;
