import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

/* UI */
import { ModalNoticeUI } from '../../../Modal/ModalNoticeUI';
import Button from '../../../Buttons/Button';
import { Typography } from '../../../typography/Typography';
import SelectComponent from '../../../select/SelectComponent';
import Label from '../../../Labels/Label';
import { Field } from 'redux-form';
import {
  Suggestion,
  ClearInputHandler
} from '../../../forms/textfields/AutoCompleteInput';
import { ProjectRoleSelectComponent } from '../SubComponents/ProjectRoleSelectComponent';
import { AddToProjectsCheckbox } from '../SubComponents/AddToProjectsCheckbox';

/// <Summary>
/// Shows email confirmation UI with new user email address and done button.
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {},
    menuItem: {
      ...theme.typographyStyles.body2,
      WebkitFontSmoothing: 'antialiased'
    },
    label: {
      backgroundColor: 'inherit',
      margin: '25px 0px 8px'
    },
    projectRole: {
      marginLeft: '32px' //checkbox width
    }
  }));

interface InviteMemberModalUIProps {
  onCloseEvent: () => void;
  saveChanges: (ev: React.FormEvent) => void;
  emailSuggestions: Suggestion[];
  clearInputField: ClearInputHandler;
  children?: React.ReactElement;
  submitDisabled: boolean;
  loading: boolean;
  roles: any;
  addToProjects?: boolean;
  addToProjectsDisabled?: boolean;
  projectRoles: any;
  handleChangeProjectRole: (callback: Function) => Function;
  // invite to teams
  AddToTeams?: JSX.Element;
}

function InviteMemberModal(props: InviteMemberModalUIProps) {
  const {
    onCloseEvent,
    saveChanges,
    children,
    submitDisabled,
    loading,
    roles,
    addToProjects,
    addToProjectsDisabled,
    projectRoles,
    handleChangeProjectRole,
    AddToTeams
  } = props;

  const classes = useStyles();

  return (
    <ModalNoticeUI
      loading={loading}
      title={'Invite to Workspace'}
      onCloseEvent={() => onCloseEvent()}
      component="form" //important for forms
      extraComponentProps={
        //important for forms
        {
          onSubmit: saveChanges
        }
      }
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            onClick={onCloseEvent}
          />
          <Button
            text={'Send invite'}
            colorVariant={'filled1'}
            extraButtonProperties={{ type: 'submit' }}
            disabled={submitDisabled}
          />
        </>
      }
    >
      <Typography color="contrast2" typographyStyle="body2">
        Fill in the email address of the people you’d like to invite. They will
        receive an invite to join the workspace.
      </Typography>
      <Label
        text="Email Address"
        extraTypographyProps={{
          typographyStyle: 'body2',
          className: classes.label,
          color: 'contrast2'
        }}
      />
      {children}
      <Label
        text="Role"
        extraTypographyProps={{
          typographyStyle: 'body2',
          className: classes.label,
          color: 'contrast2'
        }}
      />
      <Field
        name="role"
        component={({ input }) => (
          <SelectComponent
            labelName={input.name}
            value={input.value}
            formControlProps={{ classes: { root: classes.formControl } }}
            onChange={input.onChange}
            data-cy="selectRole"
          >
            {roles?.map(role => (
              <MenuItem
                value={role}
                classes={{ root: classes.menuItem }}
                key={role.id}
              >
                {role.display_name}
                {!role.billable && (
                  <span style={{ marginLeft: 8 }}>
                    <Typography color="contrast3" variant="body3">
                      (free)
                    </Typography>
                  </span>
                )}
              </MenuItem>
            ))}
          </SelectComponent>
        )}
      />
      {AddToTeams}

      <Field
        name="addToProjects"
        type="checkbox"
        component={AddToProjectsCheckbox}
        props={{
          addToProjectsDisabled: addToProjectsDisabled
        }}
      />
      {addToProjects && (
        <Field
          name="addToProjectsRole"
          component={({ input }) => (
            <ProjectRoleSelectComponent
              selectedRole={input.value}
              onChange={handleChangeProjectRole(input.onChange)}
              className={classes.projectRole}
              roles={projectRoles}
            />
          )}
        />
      )}
    </ModalNoticeUI>
  );
}

export default InviteMemberModal;
