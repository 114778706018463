import { Samples } from '../Waveform';

// merge multiple samples for an x,y coordinate
export const mergeSamples = (
  /** sample data */
  samples: Samples = [],
  /** x coordinate in samples space */
  x: number,
  /** y coordinate */
  y: number,
  /** amount of samples to merge */
  merge: number,
  /** max y coordinate */
  max: number
) => {
  // get slice of samples for x
  const set = samples.slice(x, x + merge);

  // count the samples that match or exceed the y value
  const on = set.filter(
    (v) =>
      // sort of works with dBFS values (need to validate)
      (v + 48) / 48 >= y / (max / 2)
    // NOTE: also tried with dc_offset values:
    // Math.pow(v, 3) >= y / (HEIGHT / 2)
    // and raw pcim values:
    // (20 * (Math.log(v ) / Math.log(10)) + 60) / 60 >= y / (HEIGHT / 2)
  );

  // get value for coordinate by dividing number of active samples by total samples in slice
  return on.length / set.length;
};
