import { legacyApiDataKeys as keys } from '../../../../../wrapped-cube-client';

import { actionCreators as nodeActionCreators } from '../../../../ducks/resource-nodes';
import { handleCreateBase } from './handleCreateBase';

export const handleCreateLegacy = ({
  res,
  action,
  config,
  dispatch,
  uploadIds,
  getState
}) => {
  const resource = res[keys.LEGACY_API_RESOURCE];
  handleCreateBase({
    resource,
    action,
    config,
    dispatch,
    uploadIds,
    getState
  });

  dispatch(nodeActionCreators.receiveResource(config.resourceType, resource));
};
