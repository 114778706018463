import { generatePath } from 'react-router';

export interface LibraryRouteParams {
  workspaceId: string;
  libraryId: string;
  folderId?: string;
}

export interface ProjectRouteParams {
  workspaceId: string;
  projectId: string;
  folderId?: string;
}

export interface ContractRouteParams {
  workspaceId: string;
  contractId?: string;
}
export interface ReviewRouteParams {
  token: string;
}

// known base paths
export const urlStructureWorkspace = '/workspace/:workspaceId';
export const urlStructureActiveItem = '/:resourceType/:resourceId';
export const urlStructureActiveFolder = '/folder/:folderId';
export const urlStructureActiveAsset = `/asset/:assetId/detail`;
export const urlStructureReview = '/review/:token';
// nodes
export const urlStructureNode =
  `${urlStructureWorkspace}/node/:nodeId` as const;
// for library
export const urlStructureLibrary =
  `${urlStructureWorkspace}/library/:libraryId` as const;
export const urlStructureLibraries =
  `${urlStructureWorkspace}/libraries` as const;
export const urlStructureLibraryAsset =
  `${urlStructureLibrary}${urlStructureActiveAsset}` as const;
export const urlStructureLibraryFolder =
  `${urlStructureLibrary}${urlStructureActiveFolder}` as const;
export const urlStructureLibraryCompose =
  `${urlStructureLibrary}/compose/:composeId` as const;

// for admin-center
export const urlStructureAdminCenter =
  `${urlStructureWorkspace}/admin-center/:subroute?` as const;
export const urlStructureProjectTemplates =
  `${urlStructureWorkspace}/admin-center/project-templates` as const;
export const urlStructureProjectTemplatesProject =
  `${urlStructureProjectTemplates}/project/:projectId` as const;
export const urlStructureProjectTemplatesFolder =
  `${urlStructureProjectTemplatesProject}${urlStructureActiveFolder}` as const;
export const urlStructureProjectTemplatesAssetDetail =
  `${urlStructureProjectTemplatesProject}${urlStructureActiveAsset}` as const;
// tags management
export const urlStructureTagManagement =
  `${urlStructureWorkspace}/admin-center/tag-management` as const;

export const urlStructureLibraryOrProject = `${urlStructureWorkspace}/:contextType(project|library)/:contextId`;
// for projects
export const urlStructureProjectsOverview =
  `${urlStructureWorkspace}/projects/:tab(active|browse-all)?` as const;
export const urlStructureProject =
  `${urlStructureWorkspace}/project/:projectId` as const;
export const urlStructureProjectContent =
  `${urlStructureProject}${urlStructureActiveItem}` as const;
export const urlStructureProjectAsset =
  `${urlStructureProject}${urlStructureActiveAsset}` as const;
export const urlStructureProjectFolder =
  `${urlStructureProject}${urlStructureActiveFolder}` as const;
export const urlStructureProjectOrList =
  `${urlStructureWorkspace}/project(s)?/` as const;
// for account
export const urlStructureAccountSettings = '/account/settings';
export const urlStructurePolicies = '/legal/policies';
// overview routes
export const urlStructureTrashbin = `${urlStructureWorkspace}/bin` as const;
export const urlStructureSearch = `${urlStructureWorkspace}/search/` as const;
export const urlStructureSharesOverview =
  `${urlStructureWorkspace}/shared/` as const;
export const urlStructureFileRequestsOverview =
  `${urlStructureWorkspace}/filerequests` as const;
/** legacy review  */
export const urlStructureReviewLinksOverview =
  `${urlStructureWorkspace}/review-links` as const;
/** new review feature */
export const urlStructureReviewsOverview =
  `${urlStructureWorkspace}/reviews` as const;

export const urlStructureShareRedirect = `/shares/:shareId`;
export const urlStructureShareRedirectSettings = `/shares/:shareId/settings`;
export const urlStructureShareRedirectSettingsOverview = `/shares/:shareId/settings/overview`;

export const urlStructureContractsOverview =
  `${urlStructureWorkspace}/contracts` as const;
export const urlStructureContractView =
  `${urlStructureContractsOverview}/:contractId` as const;
export const urlStructureFavoriteOverview =
  `${urlStructureWorkspace}/favorites` as const;

// external routes
export const urlStructureShareLink = '/share-link/:linkHash';
export const urlStructureShareLinkFolder =
  `${urlStructureShareLink}${urlStructureActiveFolder}` as const;
export const urlStructureShareLinkAsset =
  `${urlStructureShareLink}${urlStructureActiveAsset}` as const;
export const urlStructureReviewLink = '/review-link/:linkHash';
export const urlStructureFileRequest = '/file-request/:linkHash';
export const urlStructureDisclaimerView = '/url/' as const;
export const urlStructureMagicLink = '/magic-link/:id/token/:token' as const;
export const urlStructureApproveAccess =
  '/shares/:id/approve-access/:token' as const;
export const urlStructureReviewLinkAsset =
  '/review/:token/asset/:assetId' as const;

//
export const urlStructureUnauthorized =
  `${urlStructureWorkspace}/unauthorized` as const;

export const getAssetUrl = ({
  assetId,

  workspaceId
}) => {
  if (!assetId || !workspaceId) {
    throw new Error('Could not build asset path');
  }

  return generatePath(urlStructureNode, {
    nodeId: assetId,
    workspaceId
  });
};
