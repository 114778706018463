import React, { useState, useLayoutEffect } from 'react';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      backgroundColor: theme.customPalette.primary.dark,
      borderRadius: theme.surfaceCorners.highEmphasis,
      wordBreak: 'break-all',
      padding: '24px',
      boxSizing: 'border-box'
    },
    left: {
      marginRight: '20px'
    },
    right: {
      marginLeft: '20px'
    },
    bottom: {
      marginTop: '20px'
    },
    top: {
      marginBottom: '20px'
    },
    arrow: {
      borderColor: `transparent ${theme.customPalette.primary.dark} transparent transparent`,
      position: 'absolute',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    },
    'arrow-left': {
      borderWidth: '10px 0 10px 10px',
      margin: '0 10px 0 0',
      top: 'calc(50%)'
    },
    'arrow-right': {
      borderWidth: '10px 10px 10px 0',
      margin: '0 0 0 10px',
      top: 'calc(50%)'
    },
    'arrow-top': {
      borderWidth: '10px 10px 0 10px',
      margin: '0 0 10px 0',
      left: 'calc(50%)'
    },
    'arrow-bottom': {
      borderWidth: '0 10px 10px 10px',
      margin: '10px 0 0 0',
      left: 'calc(50%)'
    }
  }));

interface StyledPopperBaseProps {
  placement?: 'left' | 'right' | 'top' | 'bottom';
  open?: boolean;
  disablePortal?: boolean;
  anchorEl?: Element;
  onClose?(ev: Event): void;
}

const StyledPopperBase: React.ComponentType<React.PropsWithChildren<StyledPopperBaseProps>> = ({
  placement = 'right',
  open = true,
  onClose,
  disablePortal = false,
  anchorEl,
  children,
}) => {
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const [el, setEl] = useState(null);

  const classes = useStyles();

  useLayoutEffect(() => {
    const refBB = (anchorEl && anchorEl.getBoundingClientRect()) || {
      width: undefined,
      height: undefined,
      top: undefined,
      left: undefined
    };

    setWidth(refBB.width);
    setHeight(refBB.height);
    setTop(refBB.top);
    setLeft(refBB.left);
  }, [anchorEl]);

  useLayoutEffect(
    () => {
      const refBB = anchorEl && anchorEl.getBoundingClientRect();

      setEl(
        anchorEl
          ? {
            clientWidth: refBB.width,
            clientHeight: refBB.height,
            getBoundingClientRect: () => anchorEl.getBoundingClientRect()
          }
          : undefined
      );
    },
    [width, height, top, left, children, anchorEl]
  );

  return (
    <Popper
      open={open}
      placement={placement as PopperPlacementType}
      disablePortal={disablePortal}
      anchorEl={el}
      style={{ zIndex: 1400 }}
    >
      {({ ...rest }) => {
        return (
          <React.Fragment>
            <Paper className={[classes.popper, classes[placement]].join(' ')}>
              {children}
            </Paper>
            <div
              className={[classes.arrow, classes[`arrow-${placement}`]].join(
                ' '
              )}
            />
          </React.Fragment>
        );
      }}
    </Popper>
  );
};

export default StyledPopperBase;