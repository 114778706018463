import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'stretch',
      height: '100%',
      width: 'auto',
      flexGrow: 1,
      '& > *': {
        flexGrow: 0,
        flexShrink: 0
      }
    }
  })
);

export const Vertical: React.FC<React.PropsWithChildren<{}>> = props => {
  const { children } = props;

  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default Vertical;
