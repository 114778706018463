import { getContentType } from './JSONApi-interceptors';

function requestInterceptor(req) {
  return req;
}

const responseInterceptor = res => {
  // NOTE: we need this hack to get the headers. See https://github.com/axios/axios/issues/5089
  const headers = JSON.parse(JSON.stringify(res.config.headers));
  if (!headers.Accept.includes(getContentType(res).type))
    console.error(
      `Missmatching headers for request to ${res.config.url}. Expected: ${headers.Accept} -- Got: ${res.headers['content-type']}`
    );
  return res;
};

export const acceptCheckinterceptors = {
  requestInterceptor: requestInterceptor,
  responseInterceptor: responseInterceptor
};
