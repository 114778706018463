import { ProjectMember } from '@cube3/common/model/schema/resources/project-member';
import { useTimer } from '@cube3/ui/src/helpers/useTimer';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';
import React, { useContext, useMemo } from 'react';
import { makeStyles, createStyles, Fade } from '@material-ui/core';
import { ProjectMemberUserDetail } from './ProjectMemberUserDetail';
import { ProjectTeamsList } from './ProjectTeamsList';
import { ProjectTeamAdminsContext } from '../contexts/ProjectAdminsCheckContext';
import { PROJECT_ROLE_TYPES } from '@cube3/state/types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    // containers for shimmer
    shimmerMemberShell: {
      display: 'flex',
      width: '100%',
      height: '54px', // TODO theme
      alignItems: 'center'
    },
    shimmerAvatar: {
      marginLeft: '10px',
      width: '28px',
      height: '28px'
    },
    shimmerTxt: {
      height: '18px',
      marginLeft: '10px',
      width: '40%'
    }
  })
);

interface ProjectMemberUsersProps {
  loading?: boolean;
  projectId: string;
  canEdit: boolean;
  hasTeamFeature?: boolean;
  projectMembers: ProjectMember[];
  currentAccountId: string;
  memberInTeams?: string[];
  isProjectPublic?: boolean;
}

const ShimmerLoaders = () => {
  const classes = useStyles();
  const shimmerLoaders: JSX.Element[] = [];

  // push the shimmer elements
  for (let index = 0; index < 3; index++) {
    shimmerLoaders.push(
      <div key={'shimmer' + index} className={classes.shimmerMemberShell}>
        <div className={classes.shimmerAvatar}>
          <Shimmer variant={'rounded'} />
        </div>

        <div className={classes.shimmerTxt}>
          <Shimmer />
        </div>
      </div>
    );
  }

  const toReturn = <> {shimmerLoaders} </>;
  return toReturn;
};
/** A list of project members, includes
 * - accounts
 * - hasTeamFeature && teams
 */
export const ProjectMembersList = (props: ProjectMemberUsersProps) => {
  const {
    projectMembers,
    projectId,
    loading,
    hasTeamFeature = false,
    canEdit,
    currentAccountId,
    memberInTeams,
    isProjectPublic
  } = props;

  const [showShimmer, setShowShimmer] = React.useState<boolean>(false);

  const members = useMemo(() => {
    if (projectMembers?.length === 0) return [];
    return projectMembers.map((m) => ({
      ...m.relationships.account,
      role: m.project_member_role,
      projectMemberId: m.id
    }));
  }, [projectMembers]);

  const membersCount = hasTeamFeature
    ? projectMembers?.length + (memberInTeams ? memberInTeams.length : 0)
    : projectMembers?.length;

  const { allAdminsCount } = useContext(ProjectTeamAdminsContext);

  // only show the shimmer components after some time to reduce eye strain
  useTimer(500, () => setShowShimmer(true));

  return (
    <Fade timeout={500} in={projectMembers && !loading}>
      <div>
        {/* If we did not retrieve the project members yet, draw some shimmer elements */}
        {loading && showShimmer && <ShimmerLoaders />}

        {!loading && hasTeamFeature && (
          <ProjectTeamsList
            projectId={projectId}
            canEdit={canEdit}
            adminsCount={allAdminsCount}
          />
        )}
        {/* If we retrieved the project members */}
        {!loading &&
          members.length > 0 &&
          members.map((member, index) => (
            <ProjectMemberUserDetail
              key={`${index}_${member.id}`}
              account={member}
              isAdmin={member.role === PROJECT_ROLE_TYPES.Admin}
              isMember={member.role === PROJECT_ROLE_TYPES.Member}
              membersCount={membersCount}
              projectId={projectId}
              isCurrentAccount={currentAccountId === member.id}
              canEdit={canEdit}
              adminsCount={allAdminsCount}
              memberInTeams={memberInTeams}
              isProjectPublic={isProjectPublic}
            />
          ))}
      </div>
    </Fade>
  );
};
