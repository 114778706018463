import { useMemo } from 'react';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { findMatchingTag } from './utils/findMatchingTag';

const page = { size: -1 };

export const useRetrieveTags = ({
  targetResources,
  categoryId = undefined
}) => {
  const [workspaceId] = useCurrentWorkspace();

  const targetResourceIds = useMemo<string>(() => {
    return targetResources.map((t) => t.id).join(',');
  }, [targetResources]);

  /** tags applied to all selected items */
  const commonTags = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'tag',
    edgeLabel: 'tags',
    strategy: 'prefer-cache',
    params: {
      filter: {
        nodes: targetResourceIds,
        on_all_nodes: true,
        with_ai_tags: true
      },
      page
    }
  });
  /** tags applied to some selected items, includes `commonTags` */
  const partialTags = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'tag',
    edgeLabel: 'tags',
    strategy: 'prefer-cache',
    params: {
      filter: {
        nodes: targetResourceIds,
        with_ai_tags: true
      },
      page
    }
  });

  /** by default AI tags are not returned unless pass a filter[with_ai_tags]=true */
  const allTags = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'tag',
    edgeLabel: 'tags',
    strategy: 'prefer-cache',
    params: categoryId
      ? {
          page,
          filter: { category_id: categoryId }
        }
      : { page }
  });

  /** tags that are not applied to all selected resources */
  const unApplied = useMemo(() => {
    return (
      allTags.resources?.filter(
        (t) => !findMatchingTag(commonTags.resources)(t)
      ) || []
    );
  }, [allTags.resources, commonTags.resources]);

  const anyLoading =
    commonTags.status !== statuses.SUCCESS ||
    allTags.status !== statuses.SUCCESS ||
    partialTags.status !== statuses.SUCCESS;

  return {
    /** AI tags are excluded by default */
    allTags,
    commonTags,
    partialTags,
    anyLoading,
    unApplied
  };
};
