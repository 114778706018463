import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useState, useCallback, useEffect } from 'react';

/// <Summary>
/// Displays an array of elements inline. Can resize container when the content is smaller than the needed width
/// You can pass an array of JSX Elements to this component like user avatar, but also an array of
/// <div> hi </div> elements. This component assumes that each element is the same width and height.
///
/// If an element does not fit on the screen, a renderprop will be rendered which gives the component
/// that uses container chips the amount of overflow, so you can render that in a similar element.
/// Check storybook for examples.

/// The component assumes that the overflow chip is the same size and margin as the other elements.
/// <Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      backgroundColor: 'none'
    },
    el: {
      display: 'inline-block' // important, because this resizes to the generic content of the container.
    }
  })
);

interface ContainerChipsProps {
  renderElements: JSX.Element[];
  renderPropsOverFlowChip: (amountoverflow: number) => JSX.Element;
  resizeContainerWhenContentSmaller?: boolean;
}

export function ContainerChips(props: ContainerChipsProps) {
  const {
    renderElements = [],
    renderPropsOverFlowChip,
    resizeContainerWhenContentSmaller = false
  } = props;

  // hooks (state storage)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [containerRef, setContainerRef] = useState<HTMLDivElement>(undefined);
  const [chipsRef, setChipsRef] = useState<HTMLDivElement>(undefined);

  const updateContainerRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      if (node.clientWidth) {
        setContainerRef(node);
      }
    }
  }, []);

  const updateChipRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      if (node.clientWidth) {
        setChipsRef(node);
      }
    }
  }, []);

  // the window event listener notifies us about updates in the windows size, which is useful
  // because the ref will not update sometimes, thus creating undesired behaviour where chips
  // are rendered on the next line. We are not doing anything with the value, but setting the
  // value will create a rerender, which is what we want to do.
  useEffect(() => {
    const handler = (event) => {
      if (event) {
        setWindowWidth(window.innerWidth);
      }
    };

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, []);

  const chipWidth: number = chipsRef ? chipsRef.clientWidth : 0;
  const containerWidth: number = containerRef ? containerRef.clientWidth : 0;

  // do some calculations on how many elements can fit in the container.
  const howManyItemsCanfit: number = Math.floor(containerWidth / chipWidth - 1);
  const overFlowItemAmount: number = Math.floor(
    renderElements.length - howManyItemsCanfit
  );
  const neededPxSpace = chipWidth * renderElements.length;
  const canContainerResizeSmaller = neededPxSpace < containerWidth;
  const overflows: boolean = neededPxSpace > containerWidth;

  // define the first chip so we render that in isolation with a ref, so we know how big the elements are
  const FirstChip: JSX.Element = renderElements[0];

  const classes = useStyles();

  return (
    windowWidth && (
      <div
        style={{
          width:
            resizeContainerWhenContentSmaller && canContainerResizeSmaller
              ? neededPxSpace + 1
              : '100%'
        }}
        ref={updateContainerRef}
      >
        {/* First render one element to get the width so we can calculate how many items fit */}
        <div className={classes.el} ref={updateChipRef}>
          {FirstChip}
        </div>

        {renderElements.map((item, index) => {
          // the first chip is rendered above as a reference so we will skip the first index to prevent duplication.
          if (index === 0) {
            return null;
          }
          if (!overflows) {
            return (
              <div key={Math.random()} className={classes.el}>
                {' '}
                {item}{' '}
              </div>
            );
          } else {
            if (index < howManyItemsCanfit) {
              return (
                <div key={Math.random()} className={classes.el}>
                  {' '}
                  {item}{' '}
                </div>
              );
            }
          }

          return null;
        })}

        {overflows && renderPropsOverFlowChip && (
          <div className={classes.el}>
            {renderPropsOverFlowChip(overFlowItemAmount)}
          </div>
        )}
      </div>
    )
  );
}
export default ContainerChips;
