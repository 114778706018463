import React from 'react';
import { YoutubeExportChannelsModal } from './YoutubeExportChannelsModal';
import { MetaAdAccountsModal } from './MetaAdAccountsModal';
import { CM360AccountsModal } from './CM360AccountsModal';
import { GoogleAdsAccountsModal } from './GoogleAdsAccountsModal';
import { ContentfulAccountsModal } from './ContentfulAccountsModal';

export type Platform =
  | 'YouTube'
  | 'Meta'
  | 'CM360'
  | 'Google Ads'
  | 'Contentful';
interface Props {
  modalContext: {
    exportTargetTypeId: string;
    platform?: Platform;
  };
}

const platforms = {
  YouTube: YoutubeExportChannelsModal,
  Meta: MetaAdAccountsModal,
  CM360: CM360AccountsModal,
  GoogleAds: GoogleAdsAccountsModal,
  Contentful: ContentfulAccountsModal
};

export const SocialExportModal = (props: Props) => {
  const { modalContext } = props;
  const { platform } = modalContext;

  const Component = platforms[platform];

  return Component ? <Component {...props} /> : null;
};
