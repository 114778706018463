import { Tag } from '@cube3/common/model/schema/resources/tag';
import { ChangeTagConfig } from '@cube3/cubicle/src/core/molecules/TagManagement/CreateTagRow';
import { CreateTagRowUI } from '@cube3/cubicle/src/core/molecules/TagManagement/types';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useCallback } from 'react';
import { normalizeTagsInput } from '../../Tags/prefabs/TagsInput';

export const useTagActions = () => {
  const [workspaceId] = useCurrentWorkspace();

  const [deleteResource, deleteResourceStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      () => [{ type: 'workspace', id: workspaceId, relationship: 'tags' }],
      [workspaceId]
    )
  });

  const [mutateResource, mutateResourceStatus, mutateError] =
    useMutateResource__ALPHA({
      cacheInvalidator: useCallback(
        () => [
          {
            type: undefined,
            id: undefined,
            relationship: 'tag-categories'
          },
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'tags'
          }
        ],
        [workspaceId]
      )
    });
  const [createTag, createTagStatus] = useCreateResource__ALPHA({
    resourceType: 'tag',
    ancestor: { type: 'workspace', id: workspaceId },
    relationship: 'tags',
    cacheInvalidator: useCallback(
      (r) => [{ type: 'workspace', id: workspaceId, relationship: 'tags' }],
      [workspaceId]
    )
  });

  const [mutateTagOnAsset, mutateStatus] = useMutateRelationship__ALPHA({
    // ancestor: { type: 'content-tree-node', id: identifier.id },
    relationship: 'tags',
    cacheInvalidator: useCallback(
      (res, mut, anc, rel) => [
        { type: anc.type, id: anc.id, relationship: 'tags' },
        { type: 'asset', id: anc.id, relationship: 'tags' },
        { type: 'folder', id: anc.id, relationship: 'tags' },
        { type: 'workspace', id: workspaceId, relationship: 'tags' }
      ],
      []
    ),
    options: { optimize: true }
  });

  const onCreateTags = useCallback(
    (tags) => {
      if (!tags?.length) return;
      tags.forEach((t) =>
        createTag({
          type: 'tag',
          text: normalizeTagsInput(t.text),
          color: t.color,
          relationships: {
            category: t.categoryId
              ? {
                  data: {
                    type: 'tag-category',
                    id: t.categoryId
                  }
                }
              : null
          }
        })
      );
    },
    [createTag]
  );

  const onAddTagToAsset = useCallback(
    (tags) => {
      if (!tags?.length) return;
      mutateTagOnAsset(
        tags.map((t) => {
          return {
            ancestor: { id: t.id, type: 'content-tree-node' },
            resource: t,
            mutationType: 'remove'
          };
        })
      );
    },
    [mutateTagOnAsset]
  );

  const onDeleteTag = useCallback(
    (id: string) => {
      if (id) {
        deleteResource({
          type: 'tag',
          id: id
        });
      }
    },
    [deleteResource]
  );

  const onDeleteTagCategory = useCallback(
    (id: string) => {
      if (id) {
        deleteResource({
          type: 'tag-category',
          id: id
        });
      }
    },
    [deleteResource]
  );

  const onRenameTagCategory = useCallback(
    (id: string, display_name: string) => {
      mutateResource({
        type: 'tag-category',
        id,
        display_name
      });
    },
    [mutateResource]
  );

  const onMutateTag = useCallback(
    (id: string, config: ChangeTagConfig) => {
      if (id && config) {
        mutateResource({
          type: 'tag',
          id: id,
          text: config.text,
          color: config.color === 'ghost' ? '' : config.color,
          relationships: config.categoryId
            ? {
                category: {
                  data: {
                    type: 'tag-category',
                    id: config.categoryId
                  }
                }
              }
            : undefined
        });
      }
    },
    [deleteResource]
  );

  /** fire mutate tag request when
   * - text input lose focus
   * - select different color
   * - select a different category for existed tags
   */
  const onChange = useCallback(
    (tag: Tag | CreateTagRowUI) => {
      return (config: ChangeTagConfig) => {
        const { text, color, categoryId } = config;
        if (tag['error']) {
          console.error(`Tag ${JSON.stringify(tag)} already exist`);
          return;
        }
        if (
          (tag as CreateTagRowUI).dirty ||
          !hasSameValue(text, tag.text) || // text has been changed
          (color && color !== tag.color) || // color has been changed
          (categoryId &&
            (!(tag as Tag).relationships ||
              categoryId !== (tag as Tag).relationships.category.id))
        ) {
          onMutateTag(tag.id, {
            ...config
          });
        }
      };
    },
    [onMutateTag]
  );

  return {
    onDeleteTag,
    onDeleteTagCategory,
    onMutateTag,
    onChange,
    onCreateTags,
    onAddTagToAsset,
    onRenameTagCategory,
    createTagStatus,
    mutateStatus,
    deleteResourceStatus,
    mutateResourceStatus,
    mutateError
  };
};

const hasSameValue = (v1, v2) => {
  if (!v1 && !v2) return true;
  return v1 === v2;
};
