import React from 'react';

/* UI */
import { ModalNoticeUI } from '../../../../Modal/ModalNoticeUI';
import { Typography } from '../../../../typography/Typography';
import WorkspaceLogo from '../../../../../../main/src/components/app/layout/AppBar/WorkspaceLogo';

export const unavailableReasons = {
  expired: {
    msg: 'This link has expired and no longer works',
    tip: 'You can ask the sender to extend the expiration date, or send you a new link.'
  },
  default: {
    msg: 'This link is not available. Maybe the sender deleted it, or the url contains a typo.',
    tip: 'You can ask the sender to send you a new link.'
  },
  noRequest: {
    msg: 'Your email address is not in the list of recipients for this link, and you are not allowed to request access directly.',
    tip: 'You can try contacting the sender directly to manually get added as recipient.'
  }
};

interface Props {
  title?: string;
  footerRightComponent?: JSX.Element;
  reason?: keyof typeof unavailableReasons;
  onClose(): void;
}

const ShareLinkUnavailable: React.FC<Props> = (props) => {
  const { title, reason = 'default', footerRightComponent, onClose } = props;

  return (
    <>
      <div style={{ padding: 16 }}>{<WorkspaceLogo />}</div>
      <ModalNoticeUI
        onCloseEvent={onClose}
        showCloseButton={true}
        title={title || 'Link not available'}
        footerRightComponent={
          footerRightComponent || (
            <Typography typographyStyle="body3" color="contrast3">
              {unavailableReasons[reason].tip}
            </Typography>
          )
        }
      >
        <Typography color="contrast2" typographyProps={{ gutterBottom: true }}>
          {unavailableReasons[reason].msg}
        </Typography>
      </ModalNoticeUI>
    </>
  );
};

export default ShareLinkUnavailable;
