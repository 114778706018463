import React, { useCallback } from 'react';
import SettingsPage from '@cube3/ui/src/Layout/AccountSettings/Templates/SettingsPageUI';
import GenericIntegration from '@cube3/ui/src/assets/images/account/integration.svg?url';

// HOC

import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { useCurrentUser } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';

import { useMemo } from 'react';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useConnectedAccounts } from '../hooks/useConnectedAccounts';
import { useModalActions } from '../../Modals/modalActions';
import { ConnectedAccountsListingBlock } from '../prefabs/ConnectedAccountsListingBlock';
import { Typography } from '@cube3/ui/src/typography/Typography';
import {
  makeGenericTagline,
  TAGLINES
} from '../../AdminCenter/views/WorkspaceIntegrationsView/config/export-target-types';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

interface Props {}

export const AccountSettingsIntegrations: React.FC<Props> = (props) => {
  const [userId] = useCurrentUser(true);

  const { resources: exportTargetTypes } = useResourceList__ALPHA({
    edgeType: 'export-target-type',
    edgeLabel: 'export-target-types'
  });

  const { resources: accounts } = useResourceList__ALPHA({
    resourceType: 'user',
    resourceId: userId,
    edgeType: 'account',
    edgeLabel: 'accounts'
  });

  const { resources: connectedAccounts } = useResourceList__ALPHA({
    resourceType: 'user',
    resourceId: userId,
    edgeType: 'export-target',
    edgeLabel: 'export-targets',
    strategy: 'fetch-on-mount'
  });

  const byType = useMemo(() => {
    if (!accounts || !connectedAccounts) {
      return {};
    }
    return connectedAccounts
      .filter((c) => c.attributes.integration_type === 'account')
      .reduce((acc, val) => {
        const ett = val.relationships['export-target-type'].id;

        acc[ett] = (acc[ett] || []).concat({
          exportTarget: val,
          account: accounts.find(
            (a) => a.id === val.relationships.belongs_to.id
          )
        });

        return acc;
      }, {});
  }, [connectedAccounts, accounts]);

  return (
    <SettingsPage
      title={'Connected accounts'}
      undertitle={'Manage how Cube integrates with external platforms'}
    >
      {exportTargetTypes
        .filter((ett) => {
          return ett.attributes.integration_types.includes('account');
        })
        .map((ett) => {
          return (
            <>
              {
                <UserIntegrations
                  exportTargetTypeId={ett.id}
                  connectedAccounts={byType[ett.id]}
                />
              }
            </>
          );
        })}
      {!exportTargetTypes.find((ett) => {
        return ett.attributes.integration_types.includes('account');
      }) && (
        <div>
          <Typography variant="heading2" color="contrast2">
            No Account integrations available
          </Typography>
          <Typography color="contrast3">
            None of your workspaces provide Account based integrations
          </Typography>
        </div>
      )}
    </SettingsPage>
  );
};

const UserIntegrations = ({ exportTargetTypeId, connectedAccounts }) => {
  const { resource: exportTargetType } = useResource__ALPHA({
    resourceId: exportTargetTypeId,
    resourceType: 'export-target-type'
  });

  const wsIds = useMemo(() => {
    return connectedAccounts?.map((ca) => ca.account?.relationships.workspace);
  }, [connectedAccounts]);

  const { resources: workspaces } = useResource__ALPHA<'workspace'>({
    resourceIdentifiers: wsIds
  });

  const name = exportTargetType?.attributes.display_name;
  const logo = exportTargetType?.attributes.display_image || GenericIntegration;

  const description =
    exportTargetType?.attributes.tagline ||
    TAGLINES[name] ||
    makeGenericTagline(exportTargetType);

  const {
    handleConnect,
    handleDisconnect
    // connectedAccounts
  } = useConnectedAccounts(exportTargetType);

  const extendedAccounts = useMemo(() => {
    return connectedAccounts?.map((ca) => {
      return {
        ...ca,
        workspace: workspaces?.find(
          (o) => o.id === ca.account?.relationships.workspace?.id
        )
      };
    });
  }, [connectedAccounts, workspaces]);

  const makeCols = useCallback((ca) => {
    return ca && ca.workspace && ca.exportTarget
      ? [
          ca.exportTarget?.display_name,
          <Typography
            key={ca.exportTarget.id}
            color="contrast2"
            variant="body3"
          >
            {ca.workspace?.display_name + ' workspace'}
          </Typography>
        ]
      : undefined;
  }, []);

  const { openModal, previousModal } = useModalActions();

  const handleConnectClick = useCallback(() => {
    openModal(
      'connected_accounts_pick_workspace',
      {
        exportTargetType: exportTargetType,
        service: name,
        onCancel: previousModal,
        onConfirm: (workspaceId) => {
          handleConnect(workspaceId);
          previousModal();
        }
      },
      false
    );
  }, [name, previousModal, handleConnect, openModal, exportTargetType]);

  const handleDisconnectClick = useCallback(
    (ca) => {
      openModal(
        'connected_accounts_confirm_remove',
        {
          service: name,
          onCancel: previousModal,
          onConfirm: () => {
            handleDisconnect(ca.exportTarget?.id);
            previousModal();
          }
        },
        false
      );
    },
    [name, previousModal, handleDisconnect, openModal]
  );

  if (!exportTargetType) {
    return <Shimmer></Shimmer>;
  }

  return (
    <div>
      <>
        <ConnectedAccountsListingBlock
          loading={!exportTargetType}
          name={name}
          description={description}
          logo={logo}
          mainButtonClick={handleConnectClick}
          accounts={extendedAccounts}
          makeCols={makeCols}
          accountButtonClick={handleDisconnectClick}
        />
        <Divider />
      </>
    </div>
  );
};
