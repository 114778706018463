// Lookup using urls as key
const cache = {};

/**
 * Little helper that turns images into data urls
 * Mostly for speeding up local development where
 * browser network cache is disabled
 * @param url image url to cache as data url
 * @returns
 */
export const toDataUrl = url => {
  // prefer cached data url
  if (cache[url]) {
    return cache[url];
  } else {
    // if no cached data url fetch the image
    const img = new Image();
    img.src = url;
    // when loaded generate a data url
    img.addEventListener('load', function(event) {
      // add to cache
      cache[url] = getDataUrl(event.currentTarget);
    });
    return url;
  }
};
// target canvas
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

function getDataUrl(img) {
  // copy dimensions
  canvas.width = img.width;
  canvas.height = img.height;
  // draw to canvas
  ctx.drawImage(img, 0, 0);
  // return data url
  return canvas.toDataURL('image/jpeg');
}
