import React from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { useModalActions } from '../../../layout/Modals/modalActions';

// UI
import { compose } from '../../../../../utils/component-helpers';
import ConfirmDiscardUnsavedMetaDataModalUI from '@cube3/ui/src/metadata/Modals/ConfirmDiscardUnsavedMetaDataModalUI';

interface PrivateProps extends ModalReceiverProps {}

/**
 * @summary: Show a dialog to the user if it wants to discard the unsaved changes, if yes, it destroys the forms
 * @author Simon
 */

function ConfirmDiscardUnsavedMetaDataModal(props: PrivateProps): JSX.Element {
  // we expect that the generic contextualinfo is a passed reference to to the destroy function in EditMetaData
  const destroyForms = props.modalContext as Function;

  // declare and initialize hooks
  const modalActions = useModalActions();

  return (
    <ConfirmDiscardUnsavedMetaDataModalUI
      onClose={() => {
        destroyForms();
        modalActions.goBackModals(2);
      }}
      // if the user wants to continue editing, return to the meta data page which is still kept in state.
      onCancel={() => {
        modalActions.previousModal();
      }}
    />
  );
}

export default compose(ConfirmDiscardUnsavedMetaDataModal)();
// place wrappers here
