/* eslint-disable eqeqeq */

import { ExtendedCanvasData } from '../../types/context-types';
import { createCanvas, drawCircle, drawDisc } from './canvas';

export class BrushConfig {
  // size in diameter
  size: number;
  // softness of the edge, 0 for hard, 1 for soft
  softness: number;
  // brush color
  color: string;
  // brush opacity
  opacity: number;
  // see CSS globalCompositeOperation for options
  composite: 'source-over' | 'destination-out';
  // the distance between two brush stamps on a curve
  step: number;
  // the [x,y] scaling of the brush stamp
  scale: [number, number];
  // the center of any transformations
  center: [number, number];
  // the rotation of the brush stamp
  rotate: number;
  // TODO: add the to toSVG as well?
  // stroke smoothing
  // [0-1] the amount of tension applied to stroke points
  smoothTension?: number;
  // [0-n] the amount of points to apply smoothing to
  smoothDuration?: number;
  // future config implementations:
  dynamicSize?: number;
  dynamicOpacity?: number;
}

/**
 * @class Brush
 * A brush contains visual properties to draw a Point, Line or Bezier curve.
 * current supported properties are:
 * - size: {Float} size of the brush in pixels. Defaults to 24.
 * - softness: {Float} value between 0 and 1, where 1 is soft, and 0 is hard. Defaults to 0.5
 * - opacity: {Float} value between 0 and 1, where 1 is fully opaque, and 0 transparent. Defaults to 1.
 * - color: {String} any CSS color value. Defaults to "black".
 * - composite: {String} any CSS GlobalCompositeOperation value. Defaults to "source-over".
 * - step: {Float} the distance between two brush stamps on a segment, defaults to 2.
 * - scale: {Array} scale transform applied to the brush stamp. Defaults to [1,1].
 * - center: {Array} center origin for transforms applied to the brush stamp. Defaults to [0.5,0.5].
 * - rotate: {Float} rotation transform applied to the brush stamp. Defaults to 0.
 *
 * Note: a Brush creates its own Canvas Element that it uses to store the visual representation
 * of the brush stamp on.
 * @constructor
 * @param {Object} cfg Optional config object
 */
export class Brush extends BrushConfig {
  config: BrushConfig;
  type: 'brush';
  isBrush: boolean;
  canvas: HTMLCanvasElement & ExtendedCanvasData;
  ctx: CanvasRenderingContext2D;
  defaultConfig: BrushConfig;

  constructor(cfg) {
    super();
    this.type = 'brush';
    this.isBrush = true;

    // a brush has a canvas to draw an image on
    this.canvas = createCanvas(24, 24);
    this.ctx = this.canvas.getContext('2d');

    this.defaultConfig = {
      size: 9, // size in diameter
      softness: 0.5, // softness of the edge, 0 for hard, 1 for soft
      color: 'rgb(225, 65, 82)', // brush color
      opacity: 1, // brush opacity
      composite: 'source-over', // see CSS globalCompositeOperation for options
      step: 2, // the distance between two brush stamps on a curve
      scale: [0.4, 1], // the [x,y] scaling of the brush stamp
      center: [0.5, 0.5], // the center of any transformations
      rotate: -32, // the rotation of the brush stamp

      // TODO: add the to toSVG as well?
      // stroke smoothing
      smoothTension: 0.8, // [0-1] the amount of tension applied to stroke points
      smoothDuration: 20, // [0-n] the amount of points to apply smoothing to

      // future config implementations:
      dynamicSize: 0,
      dynamicOpacity: 0
    };

    // setup the default config
    this.config = Object.assign({}, this.defaultConfig);
    Object.assign(this, this.config);

    // load the provided config
    if (cfg) this.set(cfg);

    // draw the brush stamp
    this.updateBrushImage();
  }

  /**
   * sets any of the Brush properties.
   * @param {Object} cfg the config object
   * @return {Brush} the Brush, support method chaining
   */
  set(cfg) {
    var changed = false,
      prop;

    // assign all values to config
    for (prop in cfg) {
      // make sure only native props of this.config are set
      if (
        Object.hasOwnProperty.call(this.config, prop) &&
        cfg[prop] != this.config[prop]
      ) {
        this.config[prop] = cfg[prop];
        changed = true;
      }
    }

    if (changed) {
      // apply the config to the brush
      Object.assign(this, this.config);

      // draw the brush stamp
      this.updateBrushImage();
    }

    return this;
  }

  /**
   * updates the brush canvas and stamped image
   * @return {Brush} the Brush, support method chaining
   */
  updateBrushImage() {
    // update the size of the canvas, adding 1px margin around the brush
    // to compensate for anti aliasing artifacts.
    this.canvas.width = this.size + 2;
    this.canvas.height = this.size + 2;

    // draw the shape on the brush canvas
    drawDisc(
      this.ctx,
      { x: this.size / 2 + 1, y: this.size / 2 + 1 },
      {
        color: this.color,
        size: this.size,
        softness: this.softness,
        scaleX: this.scale[0],
        scaleY: this.scale[1],
        rotate: this.rotate,
        centerX: this.center[0],
        centerY: this.center[1]
      }
    );

    return this;
  }

  /**
   * stamp takes a canvas context and stamps the brush on the x, y coordinates.
   * @param {Object} ctx the canvas context
   * @param {Point|Object} p1 the point with x, y values to stamp on
   * @return {Brush} the Brush, support method chaining
   */
  stamp(ctx, p1) {
    var scale = 1,
      opacity = 1,
      sx = 0,
      sy = 0,
      sWidth = this.canvas.width,
      sHeight = this.canvas.height,
      dWidth = sWidth * scale,
      dHeight = sHeight * scale,
      dx = p1.x - dWidth / 2,
      dy = p1.y - dHeight / 2;

    ctx.save();

    if (ctx.globalCompositeOperation != this.composite)
      ctx.globalCompositeOperation = this.composite;

    if (ctx.globalAlpha != this.opacity * opacity)
      ctx.globalAlpha = this.opacity * opacity;

    ctx.drawImage(
      this.canvas,
      sx,
      sy,
      sWidth,
      sHeight,
      dx,
      dy,
      dWidth,
      dHeight
    );

    ctx.restore();

    return this;
  }

  /**
   * renders a custom cursor image based on the brush properties
   */
  getCursorCSS() {
    // create off-screen canvas
    var cursor = document.createElement('canvas'),
      ctx = cursor.getContext('2d'),
      margin = 4,
      x = this.size / 2 + margin / 2,
      y = this.size / 2 + margin / 2;

    // todo: setup hdpi using image set?
    // .cursor {
    //     cursor: url("cursor.png") 0 0, pointer; /* Legacy */
    //     cursor: url("cursor.svg") 0 0, pointer; /* FF */
    //     cursor: -webkit-image-set(url("cursor.png") 1x, url("cursor@2x.png") 2x) 0 0, pointer; /* Webkit */
    // }

    cursor.width = this.size + margin;
    cursor.height = this.size + margin;

    ctx.save();

    // draw the white outline
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 3;

    drawCircle(
      ctx,
      { x: x, y: y },
      {
        size: this.size,
        scaleX: this.scale[0],
        scaleY: this.scale[1],
        rotate: this.rotate,
        centerX: this.center[0],
        centerY: this.center[1]
      }
    );

    // draw the white outline
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;

    drawCircle(
      ctx,
      { x: x, y: y },
      {
        size: this.size,
        scaleX: this.scale[0],
        scaleY: this.scale[1],
        rotate: this.rotate,
        centerX: this.center[0],
        centerY: this.center[1]
      }
    );

    ctx.restore();

    return `url(${cursor.toDataURL()}) ${x} ${y}, crosshair`;
  }

  /**
   * renders a custom cursor image based on the brush properties
   */
  getCursorSVG() {
    // create off-screen canvas
    var margin = 4,
      w = this.size + margin,
      h = w,
      x = w / 2,
      y = h / 2,
      rx = (this.size / 2) * this.scale[0],
      ry = (this.size / 2) * this.scale[1],
      svgString;

    svgString = `%3Csvg width='${w}' height='${h}' viewBox='0 0 ${w} ${h}' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' transform='rotate(${
      this.rotate
    } ${this.center[0] * w} ${
      this.center[1] * h
    })'%3E%3Cellipse stroke='white' stroke-width='3' cx='${x}' cy='${y}' rx='${rx}' ry='${ry}' /%3E%3Cellipse stroke='black' stroke-width='1' cx='${x}' cy='${y}' rx='${rx}' ry='${ry}' /%3E%3C/g%3E%3C/svg%3E`;

    return `url("data:image/svg+xml,${svgString}") ${x} ${y}, crosshair`;
  }
}
