import React, { useState } from 'react';

// UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  DatePickerProps
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Button from '../Buttons/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      border: `1px solid ${theme.customPalette.line.onBase2.contrast1}`,
      width: 200,
      '& > fieldset': {
        border: 'none'
      },
      ...theme.typographyStyles.body2,
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      height: 48
    },
    popoverOpen: {
      border: `1px solid ${theme.customPalette.line.onBase2.contrast2}`
    },
    textField: {
      width: 200,
      backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
        theme.customPalette.primary.main,
        'primary',
        ['blackResting2', 'blackResting2']
      ),
      borderRadius: 8,
      ...theme.typographyStyles.body2
    },
    popoverPaper: {
      marginLeft: -43,
      marginTop: 5
    }
  })
);

interface DatePickerFieldUIProps extends DatePickerProps {
  rootClassName?: string;
  popoverPaperClassName?: string;
  textFieldClassName?: string;
  showClearButton?: boolean;
  handleClear?: () => void;
}

function DatePickerFieldUI(props: DatePickerFieldUIProps) {
  const [isOpen, setOpenState] = useState(false);

  const handleOpen = () => {
    setOpenState(true);
  };

  const handleClose = () => {
    setOpenState(false);
  };

  const classes = useStyles({});
  const { root, popoverPaper, popoverOpen, textField } = classes;
  const {
    format = 'D MMM YYYY',
    placeholder = 'Select a date',
    variant = 'inline',
    autoOk = true,
    fullWidth = false,
    inputVariant = 'outlined',
    popoverPaperClassName,
    textFieldClassName,
    rootClassName,
    InputProps = {
      classes: {
        root: [root, props.rootClassName, isOpen && popoverOpen].join(' ')
      }
    },
    showClearButton = true,
    handleClear,
    ...pickerProps
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div style={{ display: 'flex' }}>
        <DatePicker
          format={format}
          placeholder={placeholder}
          InputProps={InputProps}
          variant={variant}
          className={
            textFieldClassName === undefined ? textField : textFieldClassName
          }
          inputVariant={inputVariant}
          fullWidth={fullWidth}
          onOpen={handleOpen}
          onClose={handleClose}
          PopoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            classes: { paper: [popoverPaper, popoverPaperClassName].join(' ') }
          }}
          autoOk={autoOk}
          {...pickerProps}
          label={undefined}
        />
        {showClearButton && pickerProps.value && (
          <div style={{ marginLeft: 8, marginTop: 4 }}>
            <Button disabled={pickerProps.disabled} onClick={handleClear}>
              Clear
            </Button>
          </div>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}

export { DatePickerFieldUI };
