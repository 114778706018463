import React from 'react';
// UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { CircularProgress } from '../Progress/CircularProgress';

import { ModalMenuUI } from '../Modal/ModalMenuUI';
import Button from '../Buttons/Button';
import { Typography } from '../typography/Typography';
import MenuButton from '../Buttons/MenuButton';
import { iconEdit } from '../icons/GeneralSVG';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    contents: {},
    buttonMargin: {
      marginLeft: '8px'
    },
    headerClassName: {
      borderBottom: `1px solid ${theme.customPalette.primary.lineWhiteLowEmphasis}`,
      paddingBottom: 24
    },
    headerClassNameNoMetadata: {
      paddingBottom: 24
    },
    footerClassName: {
      minHeight: 90,
      marginBottom: 4
    },
    loadingStyles: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
);

interface ProjectsMetadataUIProps {
  loading: boolean;
  renderMetaDataForms: () => JSX.Element;
  onCloseEvent(): void;
  onEditClick(): void;
  canEdit: boolean;
  disabledReason?: string;
  metaData?: Object;
  children?: React.ReactNode;
}

function ProjectsMetadataUI(props: ProjectsMetadataUIProps) {
  const classes = useStyles({});
  const { loadingStyles, contents } = classes;
  const {
    onCloseEvent,
    onEditClick,
    renderMetaDataForms,
    loading,
    metaData,
    canEdit,
    disabledReason
  } = props;

  return (
    <ModalMenuUI
      title={'Project metadata'}
      multiplyContentSpace={10}
      showCloseButton={false}
      onCloseEvent={() => onCloseEvent()}
      footerLeftComponent={
        <MenuButton
          onClick={onEditClick}
          disabled={!canEdit}
          disabledReason={!canEdit && disabledReason}
          path={iconEdit}
          text="Edit"
          variant="withIcon"
          colorVariant="ghost1"
        />
      }
      footerRightComponent={
        <Button
          text={'Done'}
          colorVariant="filled1"
          onClick={() => onCloseEvent()}
        />
      }
    >
      {!metaData ? (
        <Typography typographyStyle={'body2'} color="contrast2">
          No project metadata yet
        </Typography>
      ) : loading ? (
        <div className={loadingStyles}>
          <CircularProgress />
        </div>
      ) : (
        <div className={contents}>{renderMetaDataForms()}</div>
      )}
    </ModalMenuUI>
  );
}

export default ProjectsMetadataUI;
