import { environment } from '../../../state/src/utils/environment';
import { ResourceType } from '../resource-types';

type BindingFragment = 'one' | 'many';
interface Binding {
  /** current resource */
  [0]: BindingFragment;
  /** relationship target */
  [1]: BindingFragment;
}

interface Relationship {
  name: string;
  type: ResourceType[];
  binding: Binding;
  reverse: string;
  required: boolean | number;
}

interface RawRelationship {
  /** relationship label */
  name?: string;
  /** resource type the relationship points to (or array of types) */
  type: ResourceType | ResourceType[];
  /** relationship type
   * A two item array that describes either a
   * one-to-one, one-to-many, many-to-one, or many-to-many relationship.
   * E.g. ['one', 'many'].
   * Where the first entry describes the resource the current relationship points to,
   * and the second entry refers to the resource described by the current model
   */
  binding: Binding;
  /** reverse relationship label (link back) */
  reverse?: string;
  /** wheter relationship can be null, or even has a minimum count (array can be used to set reverse as well)   */

  required?: boolean | number | [boolean | number, boolean | number];
  // when using the tuple-like syntax the first entry is used for the current relationship, the second for the reverse
}

interface Model {
  type: ResourceType;
  attributes: { [key: string]: any };
  relationships: { [key: string]: Relationship };
  plural: string;
  serverType?: string;
  JSONApi: boolean;
  useWorker: boolean;
  noEndpoint: boolean;
  canonicalPath: string;
}

export interface RawModel {
  /** singular lowercase resource type */
  type: ResourceType;
  /** atrributes */
  attributes?: { [key: string]: any };
  relationships?: { [key: string]: RawRelationship };
  /** plural lowercase resource type */
  plural: string;
  serverType?: string;
  JSONApi?: boolean;
  /** perform requests in web worker when possible */
  useWorker?: boolean;
  noEndpoint?: boolean;
  canonicalPath?: string;
}

type SemanticVersion = [number, number, number];

export interface RawSchema {
  version?: SemanticVersion;
  resources: RawModel[];
}

export interface NormalizedSchema {
  version: SemanticVersion;
  resources: Model[];
}

export class Schema {
  public resources: Model[] = [];
  public version: SemanticVersion = [0, 0, 0];
  private resourcesIndex;

  constructor(schema: NormalizedSchema) {
    this.version = schema.version;
    this.resources = schema.resources;
    // optimized for some lookups
    this.resourcesIndex = this.resources.reduce((acc, val) => {
      acc[val.type] = val;
      return acc;
    }, {});
  }

  getSingular(type: string) {
    const lookedUp = this.resources.filter((r) => r.plural === type)[0];
    if (!lookedUp && !this.getResource(type)) {
      const res = this.resources.find(
        (r) => r.serverType === type || r.canonicalPath === type
      );
      if (res) return res.type;
      if (environment === 'development') {
        throw new Error(`Resource type:${type} doesn't exist`);
      } else {
        // eslint-disable-next-line
        console.error(new Error(`Resource type:${type} doesn't exist`));
        return type?.slice(-1) === 's' ? type.slice(0, -1) : type;
      }
    }
    return (lookedUp && lookedUp.type) || type;
  }

  getPlural(type: string) {
    const plural = this.getResource(type)?.plural;
    if (!plural) {
      if (environment !== 'production') {
        throw new Error(`Plural of resource type: '${type}' is undefined!`);
      } else {
        // eslint-disable-next-line
        console.error(
          new Error(`Plural of resource type: '${type}' is undefined!`)
        );
        return type?.slice(-1) === 's' ? type : `${type}s`;
      }
    }
    return plural;
  }

  getCanonicalPath(type: string) {
    return this.getResource(type)?.canonicalPath || this.getPlural(type);
  }

  getServerType(type) {
    return this.getResource(type)?.serverType || this.getResource(type).type;
  }

  getResource(type) {
    return this.resources.filter((m) => m.type === type)[0];
  }

  guessResource(type) {
    return (
      this.resourcesIndex[type] ||
      this.resources.find(
        (m) =>
          m.type === type ||
          m.serverType === type ||
          m.type === this.getSingular(type) ||
          m.serverType === this.getSingular(type)
      )
    );
  }

  getRelationship(type, name) {
    const model = this.getResource(type);
    if (!model) {
      return undefined;
    }
    return model.relationships[name];
  }

  getReverse(type, name) {
    const rel = this.getRelationship(type, name);
    return rel.reverse;
  }

  validateResourceIdentifier(ri, name) {
    if (!ri) {
      return [new Error(`no resource identifier ${name}`)];
    }
    const { type, id } = ri;
    if (!type) {
      return [new Error(`resource identifier ${name} has no type`)];
    }
    if (!id) {
      return [new Error(`resource identifier ${name} has no id`)];
    }
    return [];
  }

  validateResource(resource) {
    // console.log(`validating ${resource.type} -- ${resource.id}`, resource);
    const errors = [];

    const { type } = resource;

    if (!type) {
      errors.push(new Error('resource has no type'));
      this.warn(errors);
      return false;
    }

    const model = this.getResource(type);

    if (!model) {
      errors.push(new Error(`no resource model for type ${type}`));
      this.warn(errors);
      return false;
    }

    const { attributes = resource, relationships } = resource;
    attributes.id = attributes.id || resource.id;

    // try to ignore resource identifiers that get returned from relationship endpoints
    if (
      Object.keys(attributes).length > 2 &&
      attributes.id &&
      attributes.type
    ) {
      // check if attributes match model
      for (const attribute of Object.keys(model.attributes)) {
        if (!attributes.hasOwnProperty(attribute)) {
          errors.push(
            new Error(`attribute ${attribute} not found on resource`)
          );
        }
      }
    }

    if (errors.length) {
      this.warn(errors);
      return false;
    }

    for (const relationshipKey of Object.keys(model.relationships)) {
      const relationshipDefinition = model.relationships[relationshipKey];
      const r = relationships[relationshipKey];

      if (
        !relationships.hasOwnProperty(relationshipKey) &&
        relationshipDefinition.required
      ) {
        errors.push(
          new Error(`relationship ${relationshipKey} not found on resource`)
        );
      }

      const needed = Number(relationshipDefinition.required);
      if (
        relationshipDefinition.required &&
        (!r || (Array.isArray(r) && r.length < needed))
      ) {
        errors.push(
          new Error(
            `relationship ${relationshipKey} requires ${
              relationshipDefinition.binding[0] === 'one'
                ? 'an entry'
                : `at least ${needed} entr${needed === 1 ? 'y' : 'ies'}`
            }  `
          )
        );
      }

      if (
        r &&
        Array.isArray(r) === (relationshipDefinition.binding[0] !== 'many')
      ) {
        errors.push(
          new Error(
            `relationship ${relationshipKey} binding should be ${relationshipDefinition.binding[0]}`
          )
        );
      }
    }

    if (errors.length) {
      this.warn(errors);
      return false;
    }

    return true;
  }

  validateRelationship(relationship) {
    const errors = [];
    const { name, from, to } = relationship;

    errors.push(...this.validateResourceIdentifier(from, 'from'));
    errors.push(...this.validateResourceIdentifier(to, 'to'));

    if (errors.length > 0) {
      this.warn(errors);
      return false;
    }

    const { type } = from;

    const model = this.getRelationship(type, name);

    if (!model) {
      errors.push(
        new Error(`no relationship model for ${name} of type ${type}`)
      );
      this.warn(errors);
      return false;
    }

    if (model.type.indexOf(relationship.to.type) === -1) {
      errors.push(
        new Error(
          `relationship model for ${name} of expects type ${model.type.join(
            ' | '
          )} but got ${relationship.to.type}`
        )
      );
      this.warn(errors);
      return false;
    }

    return true;
  }

  warn = (errors) => {
    if (environment === 'development') {
      console.warn('SCHEMA WARNING', { errors });
    }
  };
}

export const stubModel = (singular: string, plural: string) => {
  return {
    type: singular,
    plural: plural,
    JSONApi: true,
    useWorker: false
  } as RawModel;
};
