import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Button from '../../Buttons/Button';
import Label from '../../Labels/Label';
import { ModalMenuUI } from '../../Modal/ModalMenuUI';
import SelectComponent from '../../select/SelectComponent';
import { Typography } from '../../typography/Typography';

interface RoleUI {
  id?: string;
  billable: boolean;
  display_name?: string;
}

interface Props {
  roles: RoleUI[];
  isCurrentAccount: boolean;
  currentRole: RoleUI;
  userName: string;
  loading?: boolean;
  onRoleConfirm: Function;
  newRole: RoleUI;
  onRoleChange: (role: string) => void;
  onCloseEvent: () => void;
  errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      ...theme.typographyStyles.body2,
      WebkitFontSmoothing: 'antialiased'
    },
    label: {
      backgroundColor: 'inherit',
      margin: '25px 0px 8px'
    },
    checkbox: {
      marginTop: '-16px'
    }
  })
);

export const ChangeWorkspaceRoleModalUI: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const {
    roles,
    isCurrentAccount,
    currentRole,
    userName,
    newRole,
    onRoleChange,
    onCloseEvent,
    onRoleConfirm,
    errorMessage,
    children
  } = props;

  const classes = useStyles();

  return (
    <ModalMenuUI
      title={'Change workspace role'}
      onCloseEvent={onCloseEvent}
      footerRightComponent={
        <>
          {newRole === currentRole ? (
            <Button
              text="Done"
              colorVariant="filled1"
              onClick={() => onCloseEvent()}
            />
          ) : (
            <>
              <Button
                text="Cancel"
                onClick={onCloseEvent}
                colorVariant="ghost2"
              />
              <Button
                text="Save"
                colorVariant="filled1"
                disabled={!!errorMessage}
                onClick={() => {
                  onRoleConfirm(newRole);
                }}
              />
            </>
          )}
        </>
      }
    >
      {errorMessage ? (
        <Typography color="danger1" variant="body2">
          {errorMessage}
        </Typography>
      ) : (
        <Typography
          color="contrast1"
          typographyStyle="body2"
        >{`Set a new workspace role for ${
          isCurrentAccount ? 'yourself' : userName
        }.`}</Typography>
      )}
      <>
        <Label
          text="Workspace role"
          extraTypographyProps={{
            typographyStyle: 'body2',
            className: classes.label,
            color: 'contrast2'
          }}
        />
        <SelectComponent
          labelName={'Role'}
          value={newRole.display_name}
          onChange={onRoleChange}
        >
          {roles.map((role) => (
            <MenuItem
              value={role.display_name}
              key={role.id}
              classes={{ root: classes.menuItem }}
            >
              {role.display_name}
              {!role.billable && (
                <span style={{ marginLeft: 8 }}>
                  <Typography color="contrast3" variant="body3">
                    (free)
                  </Typography>
                </span>
              )}
            </MenuItem>
          ))}
        </SelectComponent>

        {children}
      </>
    </ModalMenuUI>
  );
};
