import React, { useCallback, useEffect, useRef } from 'react';
import { useModalActions } from '../../../../Modals/modalActions';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useProjectTemplateAction } from '../hooks/useProjectTemplateAction';
import { getErrorMessage } from '../../../../../../forms/helpers/errors';
import { RenameModalStatelessUI } from '@cube3/ui/src/modals/RenameModalStatelessUI';

/** Create new template:
 * 1. first create a project
 * 2. then create a `project-template` resource, use the new project as `donor_project`
 */
export const CreateProjectTemplateModal = () => {
  const tempId = useRef(uuidv4()).current;
  const newProjectId = useMappedId__ALPHA(tempId);
  const { previousModal, closeAllModals } = useModalActions();
  const [workspaceId] = useCurrentWorkspace();

  const [createProject, createProjectStatus, createError] =
    useCreateResource__ALPHA({
      resourceType: 'project',
      ancestor: {
        type: 'workspace',
        id: workspaceId
      },
      cacheInvalidator: useCallback(
        (r) => [
          r,
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'project-templates'
          }
        ],
        [workspaceId]
      )
    });

  const { onCopy, createStatus } = useProjectTemplateAction();

  const onCreate = useCallback(
    (displayName: string) => {
      createProject({
        type: 'project',
        temporaryId: tempId,
        display_name: displayName
      });
    },
    [createProject]
  );

  useEffect(() => {
    if (newProjectId && !createStatus) {
      // create a `project-template` resource and use the newly created project as its `donor_project`
      onCopy([{ id: newProjectId, type: 'project' }]);
    }
  }, [newProjectId, createStatus, onCopy]);

  useEffect(() => {
    if (createStatus === statuses.SUCCESS) {
      closeAllModals();
    }
  }, [createStatus, closeAllModals]);

  const createProjectLoading =
    createProjectStatus &&
    createProjectStatus !== statuses.SUCCESS &&
    createProjectStatus !== statuses.FAILED;
  const createTemplateLoading =
    createStatus &&
    createStatus !== statuses.SUCCESS &&
    createStatus !== statuses.FAILED;

  const error = createError ? getErrorMessage(createError) : '';

  return (
    <RenameModalStatelessUI
      title="Create template"
      loading={createProjectLoading || createTemplateLoading}
      onCloseEvent={previousModal}
      onSubmit={onCreate}
      error={error}
      submitButtonLabel="Create"
    />
  );
};
