import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { actionCreators } from '../../ducks/resource-edges';
import {
  EdgeMutation,
  MutateManyResourceEdgesOptions,
  mutations as mutationsTypes
} from '../../ducks/resource-edges/actions';
import { uuidv4 } from '../../../utils/uuid';
import { selectors } from '../../ducks/request-status';
import { FSA } from '../../flux-standard-action';
import { addRequestStatusHash } from '../../middleware/request-status-middleware';
import { mergeStatuses } from '../../utils/mergeStatuses';

type ActionDecorator<T extends FSA> = (action: T) => T;

type EdgeMutationTypeLoose = EdgeMutation['mutationType'] | 'add' | 'remove';
interface UseMutateRelationshipConfig<D extends FSA> {
  ancestor?: ResourceIdentifier;
  relationship?: string;
  cacheInvalidator: (
    mutatedResource: ResourceIdentifier,
    mutationType: EdgeMutationTypeLoose,
    ancestorResource: ResourceIdentifier,
    relationship: string
  ) => ResourceIdentifier[] | null;
  actionDecorators?: ActionDecorator<D>[];
  options?: MutateManyResourceEdgesOptions;
}

type EdgeMutationLoose = Omit<EdgeMutation, 'mutationType'> & {
  mutationType: EdgeMutationTypeLoose;
};

const emptyArray = [];
const emptyObject = {};

export const useMutateRelationship__ALPHA = ({
  ancestor = undefined,
  relationship = undefined,
  cacheInvalidator,
  actionDecorators = emptyArray,
  options = emptyObject
}: UseMutateRelationshipConfig<any>) => {
  // const [creating, setCreating] = React.useState();
  const [watchActions, setWatchActions] = React.useState(emptyArray);

  const dispatch = useDispatch();

  const ancestorId = ancestor?.id;
  const ancestorType = ancestor?.type;

  const mutateManyRelationships = React.useCallback(
    (mutations) => {
      const action = actionCreators.mutateManyResourceEdges({
        mutations: mutations.map((r) => ({
          ...r,
          invalidatesCache:
            cacheInvalidator &&
            cacheInvalidator(
              r.resource,
              r.mutationType,
              r.ancestor,
              r.relationship
            )
        })),
        options: {
          JSONApi: options.JSONApi !== undefined ? options.JSONApi : true,
          optimize: options.optimize
        }
      });
      const hash = `${action.type}__${uuidv4()}`;

      const decoratedAction = [
        ...actionDecorators,
        addRequestStatusHash(hash)
      ].reduce((a, dec) => dec(a), action);

      setWatchActions((a) => [...a, decoratedAction]);
      dispatch(decoratedAction);

      return hash;
    },
    [
      actionDecorators,
      cacheInvalidator,
      dispatch,
      setWatchActions,
      options.optimize,
      options.JSONApi
    ]
  );

  const mutateStatus = useSelector(
    ({ requestStatus: state }: { requestStatus: {} }) => {
      const all = watchActions.map((a) => selectors.getStatus(state, a));
      return mergeStatuses(all);
    }
  );

  const mutateHandler = React.useCallback(
    (mutation: Partial<EdgeMutationLoose> | Partial<EdgeMutationLoose>[]) => {
      const mutations = [].concat(mutation).map((m) => {
        let mutationType;
        switch (m.mutationType) {
          case 'add':
            mutationType = mutationsTypes.MUTATION_ADD;
            break;
          case 'remove':
            mutationType = mutationsTypes.MUTATION_REMOVE;
            break;
          case 'update':
            mutationType = mutationsTypes.MUTATION_REPLACE;
            break;
          case mutationsTypes.MUTATION_REMOVE:
          case mutationsTypes.MUTATION_ADD:
          case mutationsTypes.MUTATION_REPLACE:
            mutationType = m.mutationType;
            break;
          default:
            throw new Error('mutation is missing type');
        }

        return {
          ancestor: { id: ancestorId, type: ancestorType },
          relationshipLabel: relationship,
          ...m,
          mutationType
        };
      });

      if (!mutation || mutations.length < 1) {
        console.warn('Nothing to mutate');
        return;
      }

      if (
        mutations.filter((r) => !r.ancestor?.id || !r.ancestor?.type).length
      ) {
        throw new Error('mutation is missing ancestor');
      }
      if (
        mutations.filter((r) => !r.resource?.id || !r.resource?.type).length
      ) {
        throw new Error('mutation is missing resource');
      }
      if (mutations.filter((r) => !r.relationshipLabel).length) {
        throw new Error('mutation is missing relationshipLabel');
      }

      return mutateManyRelationships(mutations);
    },
    [mutateManyRelationships, relationship, ancestorId, ancestorType]
  );

  return [mutateHandler, mutateStatus] as [typeof mutateHandler, string];
};
