export const isLocalhost = (hostname) =>
  Boolean(
    hostname === 'localhost' ||
      hostname === 'cube.localhost' ||
      // [::1] is the IPv6 localhost address.
      hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

const envTypes = ['development', 'staging', 'test', 'production'] as const;
type EnvType = (typeof envTypes)[number];

export const environment: EnvType =
  envTypes.find(
    (e) => e === window.localStorage.getItem('ENVIRONMENT_OVERRIDE')
  ) ||
  (([
    'cube3.staging.lab.ambassadors.studio',
    'stg.c3.nl020.ambassadors.systems',
    'stg.c3.cube-platform.ambassadors.systems',
    'dev.c3.cube-platform.ambassadors.systems',
    'c3.staging.ambassadors.systems',
    'app.staging.ambassadors.systems',
    'cube.staging.ambassadors.systems',
    'cube3.staging.ambassadors.systems',
    'dev.staging.ambassadors.systems'
  ].includes(window.location.hostname)
    ? 'staging'
    : process.env.NODE_ENV === 'development' ||
      ['dev.c3.cube-platform.ambassadors.systems'].includes(
        window.location.hostname
      ) ||
      isLocalhost(window.location.hostname)
    ? 'development'
    : process.env.NODE_ENV === 'test' ||
      window.location.hostname.includes('nip.io') ||
      window.location.hostname.includes('traefik')
    ? 'test'
    : process.env.NODE_ENV || 'production') as EnvType);

export const legacyHost = 'http://legacy.cube.localhost';
