import { getColor } from '@cube3/ui/src/previews/AudioPreview/Spectogram/utils/getColor';
import { mergeSamples } from './mergeSamples';
import { Samples } from '../Waveform';

/** render waveform samples to canvas context */
export const renderWaveform = (
  /** canvas context2d to render to */
  ctx: CanvasRenderingContext2D,
  /** audio waveform sample data */
  samples: Samples,
  /** amount of samples to merge per line */
  merge: number,
  /** render greyscale version */
  bw: boolean,
  /** vertical amount of pixels */
  max: number
) => {
  const col = ctx.createImageData(1, max);

  // render every vertical line
  for (let x = 0; x < samples.length; x += merge) {
    // render every pixel of the line
    for (let y = 0; y < max / 2; y++) {
      // get color data for pixel based on merged samples
      let [r, g, b, a] = getColor(mergeSamples(samples, x, y, merge, max));

      // optionally convert to greyscale
      if (bw) {
        r = g = b = (r + g + b) / 3;
        a = a + 0;
      }

      // render pixels in "mirrored" style
      // NOTE: uses mono sample data
      // TODO: get samples per stereo channel

      // channel A
      col.data[0 + (32 - y) * 4] = r;
      col.data[1 + (32 - y) * 4] = g;
      col.data[2 + (32 - y) * 4] = b;
      col.data[3 + (32 - y) * 4] = a;

      // channel B
      col.data[32 * 4 + 0 + y * 4] = r;
      col.data[32 * 4 + 1 + y * 4] = g;
      col.data[32 * 4 + 2 + y * 4] = b;
      col.data[32 * 4 + 3 + y * 4] = a;
    }

    // get the pixel x coordinate
    const realX = Math.floor(x / merge);

    // write line to canvas
    ctx.putImageData(col, realX, 0);
  }
};
