// #region imports
import * as React from 'react';

// import Redux & Redux actions
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../ducks/session';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';
import { useTypedSelector } from '../Hooks/useTypedSelector';
import { useResource__ALPHA } from '../Hooks/useResource';
import { Subtract } from '../../../utils/typescript/subtract';
import { deprecate } from '../../../utils/deprecate';
import { useCurrentShareLink } from '../Hooks/useCurrentShareLink';
import { RequestStatuses } from '../../ducks/request-status';
// #endregion

/**
* @summary: Convenience wrapper to get workspace info and set the current workspace
* @description: Gets the current workspace info with withResource and injects it into
                the wrapping component. 
*/

// #region interfaces
// export the interface with the props you are going to inject in the component you will wrap.
export interface WorkspaceProps {
  currentWorkspace: Workspace;
  currentWorkspaceId: string;
  setCurrentWorkspace: (workSpaceId: string) => void;
}

const withCurrentWorkspace__DEPRECATED =
  <P extends WorkspaceProps>(
    WrappedComponent: React.ComponentType<Partial<P>>
  ): React.ComponentType<Subtract<P, WorkspaceProps>> =>
  (props) => {
    const [workspaceId, workspace, setWorkspaceId] = useCurrentWorkspace();

    const newProps = {
      ...props,
      currentWorkspaceId: workspaceId,
      currentWorkspace: workspace,
      setCurrentWorkspace: setWorkspaceId
    } as P;

    return <WrappedComponent {...newProps} />;
  };

export const useCurrentWorkspace = (): [
  /** current workspace Id */
  string | undefined,
  /** current workspace resource */
  Workspace | undefined,
  /** callback to set current workspace id */
  (workspaceId: string) => void,
  /** retrieve current workspace status */
  RequestStatuses | undefined
] => {
  const dispatch = useDispatch();
  const workspaceId = useTypedSelector((state) => {
    return state.session.workspace;
  });
  const setWorkspaceId = React.useCallback(
    (id) => {
      dispatch(actionCreators.setCurrentWorkspace(id));
    },
    [dispatch]
  );
  const workspace = useResource__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId
  });

  const share = useCurrentShareLink();
  return [
    workspaceId || (share && share.relationships.workspace.id),
    workspace.resource,
    setWorkspaceId,
    workspace.status
  ];
};

export const withCurrentWorkspace = deprecate(
  withCurrentWorkspace__DEPRECATED,
  'use useCurrentWorkspace hook'
);

export default withCurrentWorkspace;
