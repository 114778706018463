import { uuidv4 } from '../../utils/uuid';

('use strict');

import { AxiosError, AxiosRequestConfig } from 'axios';

// NOTE: copied from 'axios/lib/core/settle' since that is not importable
/**
 * Resolve or reject a Promise based on response status.
 *
 * @param {Function} resolve A function that resolves the promise.
 * @param {Function} reject A function that rejects the promise.
 * @param {object} response The response.
 *
 * @returns {object} The response.
 */
export default function settle(resolve, reject, response) {
  const validateStatus = response.config.validateStatus;
  if (!response.status || !validateStatus || validateStatus(response.status)) {
    resolve(response);
  } else {
    reject(
      new AxiosError(
        'Request failed with status code ' + response.status,
        [AxiosError.ERR_BAD_REQUEST, AxiosError.ERR_BAD_RESPONSE][
          Math.floor(response.status / 100) - 4
        ],
        response.config,
        response.request,
        response
      )
    );
  }
}

let interceptor;

if (window.Worker) {
  const callbacks = new Map();

  const waitForResponse = (id, req) => {
    return new Promise((res, rej) => {
      callbacks.set(id, { res, rej, req });
    });
  };

  const handleResponse = (message) => {
    if (callbacks.has(message.data.id)) {
      const cb = callbacks.get(message.data.id);

      if (cb) {
        if (message.data.error) {
          cb.rej({ ...message.data.response, config: cb.req, request: cb.req });
        } else {
          cb.res({ ...message.data.response, config: cb.req, request: cb.req });
        }
      }
    }
  };

  const w = new Worker(
    /* webpackChunkName: "axiso worker" */ new URL(
      './axios.worker',
      import.meta.url
    )
  );

  w.onmessage = handleResponse;

  // w.postMessage('Hi');

  const requestInterceptor = function (req: AxiosRequestConfig) {
    if (req.headers['X-Use-Worker'] === 'true') {
      delete req.headers['X-Use-Worker'];

      try {
        req.adapter = [
          (c) => {
            return new Promise(function (resolve, reject) {
              const id = uuidv4();
              try {
                const {
                  adapter,
                  env,
                  signal,
                  transformRequest,
                  transformResponse,
                  validateStatus,
                  ...config
                } = c;

                w.postMessage({
                  id,
                  req: config
                });
              } catch (e) {
                console.info(
                  'DEBUG webWorker interceptor error postMessage',
                  e
                );
              }

              const handleAbort = () => {
                req.signal?.removeEventListener('abort', handleAbort);
                w.postMessage({ id, abort: true });
              };
              req.signal?.addEventListener('abort', handleAbort);

              waitForResponse(id, c)
                .then((response) => {
                  settle(resolve, reject, response);
                })
                .catch((err) => {
                  settle(resolve, reject, err);
                })
                .finally(() => {
                  req.signal?.removeEventListener('abort', handleAbort);
                });

              // From here:
              //  - response transformers will run
              //  - response interceptors will run
            });
          },
          'xhr',
          'http'
        ];
      } catch (e) {
        console.info('DEBUG webWorker interceptor error', e);
      }
    } else {
      delete req.headers['X-Use-Worker'];
    }

    //console.info('DEBUG requestr interceptor', req);

    return req;
  };
  const requestErrorInterceptor = function (error) {
    //console.info('DEBUG requestError interceptor', error);
    if (error.isAxiosError) {
      return error;
    }
    return Promise.reject(error);
  };
  const responseInterceptor = function (res) {
    //console.info('DEBUG response interceptor', res);
    return res;
  };
  const responseErrorInterceptor = function (error) {
    // console.info('DEBUG responseError interceptor', error);
    if (error.isAxiosError) {
      return error;
    }
    return Promise.reject(error);
  };

  interceptor = {
    requestInterceptor,
    requestErrorInterceptor,
    responseInterceptor,
    responseErrorInterceptor
  };

  // …
}

export const webWorkerInterceptor = interceptor;
