import React from 'react';
import { TAG_COLORS } from './colors';

type ColorKeys = keyof typeof TAG_COLORS;

export type TagColor = Lowercase<ColorKeys>;

export type TagSize = 'regular' | 'small';

export interface TagUI {
  text?: string;
  color?: TagColor;
  category?: string;
  onClick?(ev: React.MouseEvent): void;
  onCrossClick?(ev: React.MouseEvent): void;
}

export const tagColorOrder: TagColor[] = [
  'ghost',
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'violet'
];
