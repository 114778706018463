import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface MetadataForm extends ResourceBase {
  category: string;
  category_name: string;
  display_name: string;
  fields: MetadataFormField[];
  created_at: string;
  deleted_at: string;
  error: boolean;
}
export interface MetadataFormField {
  id: string;
  key?: string; // not present use display_name
  json_schema: string;
  display_name: string;
  value_type:
    | 'string'
    | 'number'
    | 'email'
    | 'date'
    | 'select'
    | 'multiselect'
    | 'waterfall_select'
    | 'textarea'
    | 'resource_picker';
  required?: boolean;
  /** Field display name for exports feature */
  system_name?: string;
  read_only?: boolean;
  uses_suggestions?: boolean;
}
export interface MetadataCategory extends ResourceBase {
  category: string;
  category_name: string;
  display_name: string;
  metadata_fields: MetadataFormField[];
  created_at: string;
  deleted_at: string;
  error: boolean;
}

export const metadataCategory: RawModel = {
  type: 'metadata-category',
  plural: 'metadata-categories',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    fields: 'array'
  },
  relationships: {
    'metadata-category_for': {
      type: ['asset', 'project'],
      binding: ['many', 'many'],
      reverse: 'metadata-categories',
      required: false
    }
  }
};
