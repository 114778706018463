import {
  makeValidator,
  maxCharacters,
  noSpaceAtStart
} from '../../../../forms/helpers/validators';
import moment from 'moment';

export const generateCustomExportFileName = (...values) => {
  const now = moment().format('DD-MM-YYYY');
  let name = '';
  values.forEach((v: string | number) => {
    if (v !== undefined && v !== '') {
      const valid = exportFileNameParser(v.toString());
      name += valid + '_';
    }
  });
  return `${name}${now}_HD`;
};
/** exp: "Crème Brułée" -> "Creme Brulee" */
export const removeDiacritics = (str: string) => {
  return (
    str &&
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\u0142/g, 'l') // 'ł' -> 'l'
      .replace(/\u00D8/g, 'O') // Ø -> O
      .replace(/\u00F8/g, 'o') // ø -> o
  );
};

export const isRequired = (value, all, props, name) => {
  if (value === undefined || value === '') {
    return 'Field can not be empty';
  }
};

export const isInteger = (value) => {
  if (value && value.toString().includes('.')) {
    return 'Field must be a integer';
  }
};

export const isSpaceIncluded = (value) => {
  const hasSpacERegexp = /\s/g;
  if (value && hasSpacERegexp.test(value)) {
    return (
      noSpaceAtStart(value) ||
      'Spaces are not permitted in file name and must be replaced by a hyphen (-) '
    );
  }
};

export const consistOfUTFEightCharacters = (
  value: string,
  all,
  props,
  name
) => {
  if (!value) return undefined;
  const invalidLettersRegexp = /[^a-zA-Z0-9_-]/g;
  if (invalidLettersRegexp.test(value)) {
    return 'File name can only contain numbers (0-9), letters (a-z, A-Z) and hyphens (-)';
  }
};

/** returns error if includes any non-ascii chars
 * - but diacritics are allowed: àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜçÇßØøÅåÆæ
 */
export const isSpecialCharIncluded = (val) => {
  // eslint-disable-next-line no-control-regex
  const regex = /[^\x00-\x7fÀ-ÖØ-öø-ÿ]/g;
  if (val && regex.test(val)) {
    return 'Some special characters are not allowed. Use numbers, letters, spaces and hyphens';
  }
};

export const exportFileNameParser = (val) => {
  if (val) {
    const notWordRegexp = /[^\w-]|_/g;
    return removeDiacritics(val).replace(notWordRegexp, '-');
  }
  return val;
};

export const customFieldValidator = makeValidator([
  isRequired,
  isSpecialCharIncluded
]);
export const lengthValidators = makeValidator([isRequired, isInteger]);
export const exportFileNameValidators = makeValidator(
  [
    isRequired,
    isSpaceIncluded,
    consistOfUTFEightCharacters,
    (value) => maxCharacters(value, 100)
  ],
  true
);

const filenamePattern = /^(?:[a-zA-Z0-9-]+_){5}(?:[a-zA-Z0-9-]+)$/;

export const exportFilenameWarn = (val, allValues, props) => {
  if (val) {
    const warnings = [];

    if (props.previousNames.includes(val)) {
      warnings.push(
        `This file name was already used before. Make it unique by changing the version number`
      );
    }

    if (!allValues.export_filename_checkbox) {
      return warnings;
    }

    const underscores = val.split('_').length - 1;

    if (underscores !== 5 || !filenamePattern.test(val)) {
      warnings.push(
        'The file name should (usually) match "product_title_length_version_date_quality"'
      );
    }

    if (!val.match(new RegExp(`^([a-zA-Z0-9-]+_){2}${allValues.length}_`))) {
      warnings.push(
        `The file name should (usually) include the length (${allValues.length}) of the asset`
      );
    }

    if (
      !val.match(
        new RegExp(`^([a-zA-Z0-9-]+_){3}VERSION-${allValues.version}_`)
      )
    ) {
      warnings.push(
        `The file name should (usually) include the version (VERSION-${allValues.version})  of the asset`
      );
    }

    return warnings;
  }
};

/** show warning if the length deviates from the duration of the asset */
export const videoLengthWarn = (duration: number) => {
  return (val) => {
    if (
      (val || val === 0) &&
      duration &&
      Number(val) !== Math.round(duration / 1000)
    ) {
      return `The length doesn't match the duration of the asset: ${Math.round(
        duration / 1000
      )}`;
    }
  };
};
