import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '../../typography/Typography';
import { ExportOptionRow } from './ExportOptionRow';
import { QuestionMarkCircle } from './QuestionMarkCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    readonlyTitle: { lineHeight: '40px' }
  })
);

export const ExportInputTitle = ({ title, required, onChange }) => {
  const classes = useStyles();

  return (
    <ExportOptionRow
      label="Title"
      required={required}
      value={title}
      placeholder="Enter title"
      type={required ? 'text' : 'readonly'}
      disabled={false}
      input={{}}
      meta={{
        touched: false,
        error: required && !title ? 'required' : undefined,
        warning: ''
      }}
      component={
        required ? undefined : (
          <Typography
            color="contrast1"
            typographyStyle="body2"
            className={classes.readonlyTitle}
          >
            (Same as file name){' '}
            <QuestionMarkCircle
              disabled={false}
              title="You cannot change the title when exporting multiple videos at once. Export the video one by one if you need a different title."
            />
          </Typography>
        )
      }
      onChange={onChange}
    />
  );
};
