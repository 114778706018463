import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React from 'react';

export const WorkspaceLimitsModal = props => {
  const { maxAccounts, handleClose } = props;

  return (
    <ModalNoticeUI
      title="Workspace Account limit reached"
      footerRightComponent={
        <Button colorVariant="filled1" onClick={handleClose}>
          Done
        </Button>
      }
    >
      <Typography gutter={true} color="contrast1" variant="body1">
        This Workspace has reached the maximum number of allowed Accounts (
        {maxAccounts}) for its billing plan.
      </Typography>

      <SeatSuggestions />
    </ModalNoticeUI>
  );
};

export const SeatSuggestions = () => (
  <Typography color="contrast2" variant="body3">
    To free up seats you can:
    <ul>
      <li>Remove members that are no longer active</li>
      <li>Revoke invitations that have not been accepted</li>
      <li>Contact sales@cube-cloud.com to upgrade your plan</li>
    </ul>
  </Typography>
);
