import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export type ContractForm = ResourceBase & {};

export const contractForm: RawModel = {
  type: 'contract-form',
  plural: 'contract-forms',
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
