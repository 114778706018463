import React, { useState } from 'react';
import { useSelect } from 'downshift';
import {
  SuggestionPopover,
  SuggestionsListInner
} from '@cube3/ui/src/inputChipPicker/SuggestionsList';
import { SuggestionsItemBase } from '@cube3/ui/src/inputChipPicker/SuggestionsItem';
import { Typography } from '@cube3/ui/src/typography/Typography';
import GeneralSVG, {
  iconArrowDown,
  iconCheck
} from '@cube3/ui/src/icons/GeneralSVG';

type ItemType = {
  id: string;
  display_name: string;
};

interface Props {
  items: ItemType[];
  /** selected display_name */
  value: string;
  onChange(val: string): void;
}

export const TagsInputCategoryPicker = (props: Props) => {
  const { items, value, onChange } = props;

  const [anchorRef, setAnchorRef] = useState(null);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    selectItem,
    openMenu,
    closeMenu
  } = useSelect<string>({
    items: items.map((i) => i.display_name),
    defaultHighlightedIndex: 0,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
    }
  });

  const menuProps = {
    ...getMenuProps(),
    tabIndex: undefined // prevent element from grabbing focus, because it is nested inside another downshift dropdown
  };
  const toggleButtonProps = getToggleButtonProps({
    ref: setAnchorRef
  });

  return (
    <div {...menuProps}>
      <div
        {...toggleButtonProps}
        onClick={(ev) => {
          ev.stopPropagation();
          openMenu();
        }}
      >
        <div
          style={{
            display: 'grid',
            justifyContent: 'start',
            alignItems: 'center',
            gridTemplateColumns: 'auto 24px',
            gridTemplateRows: '1fr',
            cursor: 'pointer'
          }}
        >
          <Typography color={'contrast1'}>{value}</Typography>
          <GeneralSVG path={iconArrowDown} />
        </div>

        <SuggestionPopover anchorEl={anchorRef} align="center" open={isOpen}>
          <SuggestionsListInner title="Tag category">
            {items.map((option, index) => {
              const item = option.display_name;
              const active = item === value;

              return (
                <SuggestionsItemBase
                  key={option.id}
                  {...getItemProps({ item, index })}
                  onClick={(ev) => {
                    selectItem(item);
                    closeMenu();
                    ev.stopPropagation();
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      justifyContent: 'start',
                      alignItems: 'center',
                      gridTemplateColumns: '24px auto 1fr ',
                      gridTemplateRows: '1fr'
                    }}
                    onMouseDown={(ev) => ev.preventDefault()}
                  >
                    {active ? (
                      <GeneralSVG
                        path={iconCheck}
                        color="brand1"
                        svgProps={{ viewBox: '0 0 32 32' }}
                      />
                    ) : (
                      <span />
                    )}
                    <Typography color={active ? 'brand1' : 'contrast1'}>
                      {item}
                    </Typography>
                  </div>
                </SuggestionsItemBase>
              );
            })}
          </SuggestionsListInner>
        </SuggestionPopover>
      </div>
    </div>
  );
};
