import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import {
  PROJECT_ROLE_TYPES,
  ProjectStatus
} from '@cube3/common/model/resource-types';
import { ProjectMember } from '@cube3/common/model/schema/resources/project-member';
import { useCurrentAccount } from '../Administration/withCurrentUserAccount';
import {
  UseResourceListResponse,
  useResourceList__ALPHA
} from './useResourceList/useResourceList';

export const projectRoles = [
  {
    display_name: 'Admin',
    value: PROJECT_ROLE_TYPES.Admin
  },
  {
    display_name: 'Member',
    value: PROJECT_ROLE_TYPES.Member
  }
];

type ProjectRoleFilter = 'Admin' | 'Member' | 'All';

type CountOnlyResponse = { count: number };
type FullResponse = CountOnlyResponse & {
  tooManyProjects: boolean;
} & UseResourceListResponse<ProjectMember>;

interface Config<T> {
  accountId?: string;
  projectRole?: ProjectRoleFilter;
  projectStatus?: ProjectStatus | 'All';
  projectIds?: string;
  countOnly?: T;
  safeAmmount?: number;
}

/** returns projects list which the accountId is an admin/member/both of
 * @param accountId: use current account id as default
 * @param projectRole: default as 'Admin'
 * @param projectStatus: default as 'ProjectStatusOpen'
 * @param projectIds: optionally filter on fixed set of ids (comma separated)
 * @param countOnly: only return the count (reduces the request size in large workspaces)
 * @param safeAmmount: only retrieve all membership pages if total is less than this ammount
 */
export const useProjectMembershipsList = <T extends boolean = false>({
  accountId,
  projectRole,
  projectStatus,
  projectIds = undefined,
  countOnly = false as T,
  safeAmmount = 200
}: Config<T>): T extends true ? CountOnlyResponse : FullResponse => {
  const [currentAccountId] = useCurrentAccount(true);

  const role = projectRole === 'All' ? undefined : projectRole;

  const params = useMemo(() => {
    return {
      page: countOnly
        ? { size: 1 }
        : !projectIds
        ? { size: 100 }
        : { size: projectIds.split(',').length },
      filter: {
        project_ids: projectIds,
        role: role ? PROJECT_ROLE_TYPES[role] : PROJECT_ROLE_TYPES.Admin,
        status: projectStatus
          ? projectStatus === 'All'
            ? undefined
            : projectStatus
          : 'ProjectStatusOpen'
      }
    };
  }, [role, projectStatus, countOnly, projectIds]);

  const [safeProjectCount, setSafeProjectCount] = useState<boolean>(null);

  const projectMemberships = useResourceList__ALPHA({
    resourceType: 'account',
    resourceId: accountId ? accountId : currentAccountId,
    edgeType: 'project-member',
    edgeLabel: 'project-members',
    strategy: projectIds === '' ? 'cache-only' : 'fetch-on-mount',
    params,
    allPages: !countOnly && safeProjectCount
  });

  const {
    pageCounts: { itemCount }
  } = projectMemberships;

  useEffect(() => {
    if (itemCount && itemCount < safeAmmount) {
      setSafeProjectCount(true);
    } else {
      setSafeProjectCount(false);
    }
  }, [setSafeProjectCount, itemCount, safeAmmount]);

  return countOnly
    ? ({
        count: itemCount
      } as T extends true ? CountOnlyResponse : FullResponse)
    : ({
        count: itemCount,
        tooManyProjects: safeProjectCount === false,
        ...projectMemberships
      } as T extends true ? CountOnlyResponse : FullResponse);
};
