import React from 'react';

// types and HOC
import { ModalReceiverProps } from '../ModalManager';
import {
  withInferedSelection,
  WrappedComponentProps
} from '@cube3/state/src/redux/components/withSelection';
import withAddFolderActions, {
  AddFolderActionProps
} from '@cube3/state/src/redux/components/withAddFolderActions';
import {
  withModalActions,
  ModalActionProps
} from '../../Modals/modalActions';

// redux form
import {
  isRequired,
  makeValidator,
  maxCharacters,
  noSpaceAtStart
} from '../../../../forms/helpers/validators';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import GenericFieldWithErrors from '../../../../forms/helpers/fields/GenericFieldWithErrors';

// helpers
import { compose } from '../../../../../utils/component-helpers';

// UI
import AddFolderPrompUI from '@cube3/ui/src/Modal/AddFolderPromptUI';

/// @Author : Simon @ AmbassadorsLAB
/// <Summary>
/// Combine UI and HOC to make a functional delete confirmation prompt.
/// This prompt opens through modal manager or through by calling openPrompt
/// on withDeleteactions.tsx
/// </Summary>

// define props that can be given to the component.

type InjectedProps = ModalReceiverProps &
  WrappedComponentProps &
  ModalActionProps &
  FormValues &
  AddFolderActionProps;

interface Props extends InjectedProps, InjectedFormProps {}

interface FormValues {
  folderName: string;
}

// #region redux form values to props mapping
const mapStateToProps = state => {
  const values = getFormValues('addFolder')(state);

  return {
    ...values,
    formValues: values
  };
};
// #endregion
const maxChars = (value: string) => maxCharacters(value, 256);
const stackedErrors = makeValidator([maxChars, isRequired, noSpaceAtStart]);

class AddFolderPrompt extends React.PureComponent<Props> {
  componentDidUpdate() {
    if (this.props.submitSucceeded) {
      this.props.closeAllModals();
    }
  }

  render() {
    const { handleSubmit, previousModal, valid, submitting } = this.props;
    return (
      <AddFolderPrompUI
        onSubmit={handleSubmit}
        loading={submitting}
        onCloseEvent={() => previousModal()}
        submitDisabled={!valid || submitting}
      >
        <Field
          inputProps={{ autoFocus: true }}
          startSelected={true}
          name={'folderName'}
          validate={stackedErrors}
          component={GenericFieldWithErrors}
        />
      </AddFolderPrompUI>
    );
  }
}

export default compose(AddFolderPrompt)(
  // please note that exporting like this does not expose the properties.
  withInferedSelection,
  withAddFolderActions,
  withModalActions,
  reduxForm<FormValues, InjectedProps>({
    form: 'addFolder',
    onSubmit: (values, dispatch, props) => {
      props.addFolder(values.folderName, props.form);
    }
  }),
  connect(mapStateToProps, null)
);
