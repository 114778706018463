import React, { useContext, useEffect } from 'react';
// hooks
import { useVideoActualSize } from '@cube3/ui/src/Hooks/useVideoActualSize';

import DrawingCanvas from '@cube3/annotate/src/components/DrawingCanvas';
import Annotation from '@cube3/annotate/src/Annotation';
import {
  ReviewAssetViewerContext,
  ReviewToolContext
} from '../ReviewTool/Context/reviewToolContext';
import { showAnnotation } from '@cube3/annotate/src/hooks/useAnnotationTime';
import { VideoLayerProps } from '@cube3/ui/src/previews/VideoPreview/MediaPlayer';

export const AnnotationLayer = (props: VideoLayerProps) => {
  const {
    playedSeconds,
    videoElement,
    frameRate,
    playing,
    fullscreen,
    toggleFullscreenAction
  } = props;
  const {
    setDrawnSvg,
    setPlaying,
    drawnSvg,
    activeComment,
    drawingBarToggled,
    toggleDrawingBar
  } = useContext(ReviewToolContext);
  const { setTimestamp } = useContext(ReviewAssetViewerContext);

  useEffect(() => {
    setTimestamp(playedSeconds);
  }, [playedSeconds]);

  useEffect(() => {
    setPlaying(playing);
  }, [playing]);

  const format = useVideoActualSize(videoElement);

  const shouldShow = frameRate
    ? showAnnotation(activeComment, playedSeconds, frameRate / 1000 / 2)
    : true;

  return (
    <>
      {drawingBarToggled ? (
        <DrawingCanvas
          saveAnnotation={setDrawnSvg}
          drawnSvg={drawnSvg}
          format={format}
          annotationToggled={drawingBarToggled}
          playedSeconds={playedSeconds}
          fullscreen={fullscreen}
          toggleDrawingBar={toggleDrawingBar}
          toggleFullscreenAction={toggleFullscreenAction}
        />
      ) : activeComment?.drawing_svg && shouldShow ? (
        <Annotation annotation={activeComment} format={format} />
      ) : null}
    </>
  );
};
