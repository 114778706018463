/**
 *
 * This module is very specifically taylored to our caddy http server Docker image (see dockerfile)
 * we use caddy's template feature to inject env vars into a js file at runtime
 * subsequently we can inject the js into the html via script tags at which point it will be evaluated and we can access it
 *
 */

declare global {
  interface Window {
    __env_vars: { [key: string]: any };
  }
}

const getDefined = (value, fallback) => {
  if (value !== undefined) {
    return value;
  } else if (fallback !== undefined) {
    return fallback;
  }
  return undefined;
};

const ignoreCaddyNoValue = (value) => {
  if (value === undefined || value === '<no value>') {
    return;
  } else {
    return value;
  }
};

window['__env_vars'] = window['__env_vars'] || {};

export const getEnvVar = (varName) => {
  const fromBuild = getDefined(
    process.env[varName],
    process.env[`REACT_APP_${varName}`]
  );

  const fromRuntime = getDefined(
    ignoreCaddyNoValue(window['__env_vars'][varName]),
    ignoreCaddyNoValue(window['__env_vars'][`REACT_APP_${varName}`])
  );

  if (fromBuild && !fromRuntime) {
    window['__env_vars'][varName] = fromBuild;
  }

  return fromRuntime || fromBuild || undefined;
};

export const injectEnvVars = (envFile) => {
  const d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0];
  if (!s) {
    return Promise.resolve(null); // otherwise tests break
  }

  g.type = 'text/javascript';
  g.async = false;
  g.defer = false;
  g.src = envFile;

  return new Promise((res) => {
    g.addEventListener('load', () => {
      res(window.__env_vars);
    });
    s.parentNode.insertBefore(g, s);
  });
};
