import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  useCurrentAccount,
  useCurrentUser
} from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useCallback } from 'react';
import { ExportTargetType } from '@cube3/common/model/schema/resources/export-target-type';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import Client from '@cube3/state/src/wrapped-cube-client';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useDispatch } from 'react-redux';
import { actionCreators } from '@cube3/state/src/redux/ducks/request-status';
import { apiRoot } from '@cube3/state/src/config';

// TODO: remove when we get this directly from API

const mapDisplayName = (name) => {
  switch (name) {
    case 'YouTube':
      return 'Google';
    default:
      return name;
  }
};

export const isTrusted = (messageOrigin) => {
  const sameOrigin = messageOrigin === window.location.origin;
  const localDev =
    window.location.origin === 'http://localhost:9000' ||
    window.location.origin === 'http://localhost' ||
    window.location.origin === 'http://cube.localhost';
  const localMessage = [
    'http://localhost',
    'http://localhost:9000',
    'http://cube.localhost'
  ].includes(messageOrigin);

  return sameOrigin || (localDev && localMessage);
};

export const useConnectedAccounts = (exportTargetType: ExportTargetType) => {
  const [userId] = useCurrentUser();
  const [workspaceId] = useCurrentWorkspace();

  const { retrieve: retrieveUserTargets } = useResourceList__ALPHA({
    resourceId: userId,
    resourceType: 'user',
    edgeType: 'export-target',
    edgeLabel: 'export-targets'
    // params
  });
  const [accountId] = useCurrentAccount();

  const dispatch = useDispatch();
  const invalidateAccountTargets = useCallback(() => {
    dispatch(
      actionCreators.invalidateRelationship({
        type: 'account',
        id: accountId,
        relationship: 'export-targets'
      })
    );
  }, [accountId, dispatch]);

  // open oauth login steps in external window
  const startAuthFlow = useCallback(
    (url, service) => {
      const w = window.open(url, '_blank', getWindowDimensions(service));

      window.addEventListener('message', (ev) => {
        if (ev.isTrusted && !isTrusted(ev.origin)) {
          return;
        }
        if (!ev.data?.integration_id) {
          return;
        }
        setTimeout(() => {
          w?.close();
        }, 1250);

        // retrieve();
        retrieveUserTargets();
        invalidateAccountTargets();
      });
    },

    [retrieveUserTargets, invalidateAccountTargets]
  );

  const service =
    exportTargetType?.relationships.provider?.id ||
    exportTargetType?.attributes.service ||
    mapDisplayName(exportTargetType?.attributes.display_name);

  const beginUrl =
    exportTargetType?.attributes.begin_url ||
    `${apiRoot}/oauth2/${service}/setup_integration/begin`;

  const handleConnect = useCallback(
    (workspaceIdOverride) => {
      let cookiePath = beginUrl.split('/').slice(0, -1).join('/');
      if (cookiePath[0] !== '/') {
        cookiePath = new URL(cookiePath).pathname;
      }

      // call api to get a login url for the oauth flow
      Client.auth.setIntegrationCookies({
        workspaceId: workspaceIdOverride || workspaceId,
        path: cookiePath
      });
      startAuthFlow(beginUrl, service);
    },
    [startAuthFlow, service, beginUrl, workspaceId, beginUrl]
  );

  const [remove] = useDeleteResource__ALPHA({
    cacheInvalidator: () => [
      { type: 'workspace', id: workspaceId, relationship: 'export-targets' },
      { type: 'user', id: userId, relationship: 'export-targets' }
    ]
  });

  const handleDisconnect = (exportTargetId) => {
    remove({ id: exportTargetId, type: 'export-target' });
  };

  return {
    handleConnect: exportTargetType ? handleConnect : undefined,
    handleDisconnect
  };
};

const getWindowDimensions = (service) => {
  switch (service) {
    case 'Meta':
      return 'popup,width=720,height=800,top=100,left=100';

    default:
      return 'popup,width=520,height=600,top=100,left=100';
  }
};
