import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { iconDelete } from '../../../../icons/GeneralSVG';
import { Typography } from '../../../../typography/Typography';
import Button from '../../../../Buttons/Button';
import { ModalMenuUI } from '../../../../Modal/ModalMenuUI';
import { Divider } from '../../../../Surface/Divider';
import { LinkSettingsRecipientsUI } from './LinkSettingsRecipientsUI';
import { LinkSettingsCreatedDate } from './LinkSettingsCreatedDate';
import { iconEdit } from '@cube3/cubicle/src/icons/GeneralSVG';
import { ShareIntentType } from '@cube3/common/model/resource-types';
import { usePreventDirtyClose } from './hooks/usePreventDirtyClose';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      width: '100%',
      display: 'flex'
    },
    subTitle: {
      marginBottom: theme.customSpacing.margin[16]
    },
    restContainer: {
      width: '100%'
    },
    separation: {
      margin: `${theme.customSpacing.margin[24]} 0px`
    },
    avatar: {
      marginRight: '4px'
    }
  })
);

interface LinkSettingsProps {
  // events
  onCloseEvent: () => void;
  onReset: () => void;
  onClickManageAccess: () => void;
  onClickSaveChanges: () => void;
  onClickDeleteLink: () => void;
  onClickAddUser: () => void;
  onCopyLink?: () => void;
  onClickOpenLink?: () => void;

  // file request
  onClickEditTitle?: () => void;
  title?: string;

  //
  /** if should show personal link */
  showLink?: boolean;
  linkSettingsForm: JSX.Element;
  dirty?: boolean;
  createdDate: string;
  email_addresses: string[];
  submitDisabled?: boolean;
  loading?: boolean;
  linkType?: ShareIntentType;
}

const LinkSettingsUI: React.FC<LinkSettingsProps> = (props) => {
  const {
    onCloseEvent,
    onReset,
    onClickDeleteLink,
    onClickSaveChanges,
    onClickOpenLink,
    onClickManageAccess,
    onClickAddUser,
    onCopyLink,
    onClickEditTitle,
    showLink = true,
    title,
    dirty,
    linkSettingsForm,
    email_addresses,
    createdDate,
    submitDisabled,
    loading,
    linkType
  } = props;

  const classes = useStyles();

  const { extraModalProps, promptElement } = usePreventDirtyClose({
    dirty,
    handleCloseEvent: onCloseEvent,
    promptMessage:
      'Changes to the Link settings have not been saved. Closing will discard current settings.',
    promptTitle: 'Cancel Link editing?'
  });

  return (
    <>
      <ModalMenuUI
        loading={loading}
        title={'Link Settings'}
        multiplyContentSpace={linkType === 'request' ? 11 : 10}
        onCloseEvent={onCloseEvent}
        extraModalProps={extraModalProps}
        footerRightComponent={
          <>
            {!dirty ? (
              <Button
                onClick={onCloseEvent}
                text={'Done'}
                colorVariant={'filled1'}
              />
            ) : (
              <>
                <Button
                  onClick={() => {
                    onCloseEvent();
                    onReset();
                  }}
                  text={'Cancel'}
                  colorVariant="ghost2"
                />
                <Button
                  text={'Save Changes'}
                  disabled={submitDisabled}
                  extraButtonProperties={{
                    type: 'submit'
                  }}
                  colorVariant={'filled1'}
                  onClick={onClickSaveChanges}
                />
              </>
            )}
          </>
        }
        footerLeftComponent={
          <Button
            text="Delete link"
            path={iconDelete}
            colorVariant={'danger'}
            onClick={() => onClickDeleteLink()}
          />
        }
      >
        {/* Header: created data */}
        <div className={classes.section}>
          <LinkSettingsCreatedDate
            createdDate={createdDate}
            onClickOpenLink={onClickOpenLink}
            onCopyLink={onCopyLink}
            showLink={showLink}
          />
        </div>
        <Divider classes={{ horizontal: classes.separation }} />

        {/* Title */}
        {title && (
          <>
            <div className={classes.section}>
              <div className={classes.restContainer}>
                <Typography
                  component="span"
                  color="contrast1"
                  typographyStyle={'heading3'}
                  className={classes.subTitle}
                >
                  Title
                </Typography>
                <Typography
                  className={classes.subTitle}
                  component="span"
                  color="contrast2"
                  // eslint-disable-next-line react/style-prop-object
                  typographyStyle={'heading2'}
                >
                  {title}
                </Typography>

                <Button
                  path={iconEdit}
                  text="Edit"
                  onClick={onClickEditTitle}
                  colorVariant={'ghost1'}
                  marginCollapse={'left'}
                />
              </div>
            </div>

            <Divider classes={{ horizontal: classes.separation }} />
          </>
        )}

        {/* Who can access  */}
        <div className={classes.section}>
          <LinkSettingsRecipientsUI
            email_addresses={email_addresses}
            onClickAddUser={onClickAddUser}
            onClickManageAccess={onClickManageAccess}
          />
        </div>
        <Divider classes={{ horizontal: classes.separation }} />
        {/* Options */}
        <div className={classes.section}>
          <div className={classes.restContainer}>
            <Typography
              component="span"
              color="contrast1"
              typographyStyle={'heading3'}
              className={classes.subTitle}
            >
              Options
            </Typography>
            {linkSettingsForm}
          </div>
        </div>
      </ModalMenuUI>
      {promptElement}
    </>
  );
};

export default LinkSettingsUI;
