import * as React from 'react';
// helpers
import { compose } from '../../../utils/component-helpers';
// HOCS that wrap this hoc
import { withResource } from '../withResource';

// import Redux & Redux actions
import { connect, useDispatch } from 'react-redux';

import { CubeReduxState } from '../../ducks/CubeReduxState';
import { actionCreators } from '../../ducks/session';

// interfaces
import { Account } from '@cube3/common/model/schema/resources/account';
import { User } from '@cube3/common/model/schema/resources/user';
import { useResource__ALPHA } from '../Hooks/useResource';
import { useTypedSelector } from '../Hooks/useTypedSelector';
import { statuses } from '../../ducks/request-status';
import { deprecate } from '../../../utils/deprecate';

/**
 * @summary: Convenience wrapper that injects account info from the current user
 * @author Simon
 * @description: The component uses withauth user to get the current user ID and then uses
 * withresource to get more account info and injects it in to the wrapping component.
 */

// #region interfaces

// export the interface with the props you are going to inject in the component you will wrap.
export interface WithCurrentUserAccountProps {
  setCurrentAccount: (id: string) => void;
  currentAccount: Account;
  currentUser: User;
}

// use this interface for all the props that you could not inherit from HOC's interfaces
interface HOCmapped {
  userId: string;
  currentAccount: Account;
  currentUser: User;
}

// Properties for the HOC (not for the component that hoc this HOC wraps)
type Properties = HOCmapped & ReceiverStateProps & DispatcherProps;

// redux interfaces
interface ReceiverStateProps {
  currentAccountId: string;
}

interface DispatcherProps {
  setCurrentAccount: (id: string) => void;
}
// #endregion

// #region Redux setup
const mapStateToProps = (state: CubeReduxState) => {
  const mapping: ReceiverStateProps = {
    currentAccountId: state.session.account
  };

  return mapping;
};

const HOCComponent =
  (WithUserAccount) =>
  (props: Properties): JSX.Element => {
    const { setCurrentAccount, currentAccount, currentUser } = props;

    // define the props here that you are going to give to the wrapped component.
    const mapping: WithCurrentUserAccountProps = {
      setCurrentAccount: setCurrentAccount,
      currentAccount: currentAccount,
      currentUser: currentUser
    };

    return <WithUserAccount {...mapping} {...props} />;
  };

export default deprecate((WithUserAccount) => {
  return compose(WithUserAccount)(
    connect(mapStateToProps),
    withResource({
      resourceType: 'account',
      resourceId: (props: Properties) => props.currentAccountId,
      mapper: 'currentAccount'
    }),
    withResource({
      resourceType: 'user',
      resourceId: (props: Properties) =>
        props.currentAccount && props.currentAccount.relationships.user.id,
      mapper: 'currentUser'
    }),
    HOCComponent // this component
  );
}, 'HOC deprecated use useCurrentAccount or useCurrentUser hooks ');

/** Returns user user info about the current user, such as the email adress   
@example const user = useCurrentUser();
if(accountInfo) {
    console.log(accountInfo.email_address);
}  
*/
export function useCurrentUser(eager = false): [string, User] {
  const userId = useTypedSelector((state) => state.session.user);
  const user = useResource__ALPHA({ resourceId: userId, resourceType: 'user' });

  if (user.status === statuses.SUCCESS) {
    return [userId, user.resource];
  } else if (eager) {
    return [userId, user.resource];
  } else {
    return [undefined, undefined];
  }
}

type UseCurrentAccount = (
  /** return data even if not all parts have loaded */
  eager?: boolean
) => [
  /** Account id */
  string,
  /** Account resource */
  Account,
  /** Set current account by id */
  (options: { accountId: string }) => void
];

export const useCurrentAccount: UseCurrentAccount = (eager = false) => {
  const accountId = useTypedSelector((state) => state.session.account);
  const account = useResource__ALPHA({
    resourceId: accountId,
    resourceType: 'account'
  });
  const dispatch = useDispatch();
  const setCurrentAccount = React.useCallback(
    ({ accountId, workspaceId }) => {
      dispatch(actionCreators.setCurrentAccount({ accountId, workspaceId }));
    },
    [dispatch]
  );

  if (account.status === statuses.SUCCESS) {
    return [accountId, account.resource, setCurrentAccount];
  } else if (eager) {
    return [accountId, account.resource, setCurrentAccount];
  } else {
    return [undefined, undefined, undefined];
  }
};
