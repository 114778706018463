/* eslint-disable eqeqeq */

import { Point } from './point';

/**
 * @class Bezier
 * Cubic Bezier curve taking 4 (Point) points where p1 and p4 describe the start and end of the
 * curve, and p2 and p3 are the control points. A Bezier curve will always pass through p1 and p4, but
 * never through p2 and p3.
 * Note: a Quadratic Bezier curve can be described as a Cubic Bezier curve where p2 == p3.
 *
 * @constructor
 * @param {Point|Array} p1 Start point of the Bezier curve, or an Array of [x,y] values.
 * @param {Point} p2 First control point of the bezier Curve.
 * @param {Point} p3 Second control point of the bezier Curve.
 * @param {Point} p4 End point of the Bezier curve
 */
export class Bezier {
  type: 'bezier';
  isBezier: true;
  p1: Point;
  p2: Point;
  p3: Point;
  p4: Point;
  points: Point[];
  arcLengths: number[];
  length: number;
  accuracy: number;

  constructor(p1, p2, p3, p4) {
    this.type = 'bezier';
    this.isBezier = true;

    // support array argument assignment
    if (p1 instanceof Array) {
      p4 = p1[3];
      p3 = p1[2];
      p2 = p1[1];
      p1 = p1[0];
    }

    this.p1 = p1;
    this.p2 = p2;
    this.p3 = p4 == undefined ? p2 : p3;
    this.p4 = p4 == undefined ? p3 : p4;

    this.points = [this.p1, this.p2, this.p3, this.p4];
    this.arcLengths = [];
    this.length = 0;

    this.init();
  }

  init() {
    // Note: a point on a Bezier curve can be described by a time function, but this function does not
    // behave linear. To approximate the length and construct a linear time function, we cut the curve
    // into n (accuracy) parts and store the linear distance between the start and end of the part in
    // the arcLengths array, effectively creating a map of distances which we can use as a reverse lookup.

    // set the accuracy to estimate the length of the curve.
    // Note: we construct the accuracy to not be bigger than the linear distance between p1 and p4, so the
    // equazion will be cheaper for shorter lines.
    if (this.p1.isRelative()) {
      this.accuracy = Math.min(
        100,
        Math.max(1, Math.floor(this.p1.distance(this.p4) / this.p1.getScale()))
      );
    } else {
      this.accuracy = Math.min(
        100,
        Math.max(1, Math.floor(this.p1.distance(this.p4)))
      );
    }

    this.arcLengths = new Array(this.accuracy);

    var ox = this.nx(0),
      oy = this.ny(0),
      clen = 0,
      i = 0,
      x,
      y,
      dx,
      dy;

    for (i = 0; i <= this.accuracy; i++) {
      x = this.nx(i / this.accuracy);
      y = this.ny(i / this.accuracy);
      dx = ox - x;
      dy = oy - y;
      clen += Math.sqrt(dx * dx + dy * dy);
      this.arcLengths[i] = clen;
      ox = x;
      oy = y;
    }

    this.length = clen;
  }

  /**
   * takes a float between 0 and 1 and returns a (Point) on the curve.
   * @param {Float} t the time value
   * @return {Point} point
   */
  time(t) {
    t = Math.max(0, Math.min(1, t));
    return new Point(this.x(t), this.y(t));
  }

  /**
   * takes a float between 0 and this.length and returns a (Point) on the curve.
   * this is similar to the time function, but with an absolute length for d.
   * @param {Float} d the travel distance (or length) on this curve
   * @return {Point} point
   */
  pointAtTravelDistance(d) {
    d = Math.max(0, Math.min(this.length, d));
    return this.time(d / this.length || 1);
  }

  /**
   * returns the x position for time t on a curve in linear space
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Float} x position for t
   */
  x(t) {
    t = Math.max(0, Math.min(1, t));
    return this.nx(this.linear(t));
  }

  /**
   * returns the y position for time t on a curve in linear space
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Float} y position for t
   */
  y(t) {
    t = Math.max(0, Math.min(1, t));
    return this.ny(this.linear(t));
  }

  /**
   * returns a point on the curve using a non-linear time function
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Point} point
   */
  nativeTime(t) {
    t = Math.max(0, Math.min(1, t));
    return new Point(this.nx(t), this.ny(t));
  }

  /**
   * returns the x position for time t on a curve. (non-linear)
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Float} x position for t
   */
  nx(t) {
    t = Math.max(0, Math.min(1, t));
    return (
      Math.pow(1 - t, 3) * this.p1.x +
      3 * t * Math.pow(1 - t, 2) * this.p2.x +
      3 * t * t * (1 - t) * this.p3.x +
      t * t * t * this.p4.x
    );
  }

  /**
   * returns the y position for time t on a curve. (non-linear)
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Float} y position for t
   */
  ny(t) {
    t = Math.max(0, Math.min(1, t));
    return (
      Math.pow(1 - t, 3) * this.p1.y +
      3 * t * Math.pow(1 - t, 2) * this.p2.y +
      3 * t * t * (1 - t) * this.p3.y +
      t * t * t * this.p4.y
    );
  }

  /**
   * maps the time value against the arcLengths array such that it can be used to return a
   * point on the curve in linear time (travel distance).
   * @param {Float} t the time (or length / travel distance) on this curve
   * @return {Float} time in linear space
   */
  linear(t) {
    t = Math.max(0, Math.min(1, t));
    var targetLength = t * this.length,
      low = 0,
      high = this.accuracy,
      index = 0,
      lengthBefore;

    while (low < high) {
      index = low + (((high - low) / 2) | 0);
      if (this.arcLengths[index] < targetLength) {
        low = index + 1;
      } else {
        high = index;
      }
    }

    if (this.arcLengths[index] > targetLength) {
      index--;
    }

    lengthBefore = this.arcLengths[index];

    if (lengthBefore === targetLength) {
      return index / this.accuracy;
    } else {
      // interpolate the value between two indexes
      return (
        (index +
          (targetLength - lengthBefore) /
            (this.arcLengths[index + 1] - lengthBefore)) /
        this.accuracy
      );
    }
  }

  needsUpdate() {
    return this.p1.moved || this.p2.moved || this.p3.moved || this.p4.moved;
  }

  /**
   * when the positions of the points change, we will update
   * our properties to match
   */
  update(stroke) {
    // we need to replace the control points first, for that we need to p1 of the
    // previous segment.
    var i = stroke.segments.indexOf(this),
      len = stroke.segments.length,
      prevSegment = i > 0 ? stroke.segments[i - 1] : undefined,
      nextSegment = i < len - 1 ? stroke.segments[i + 1] : undefined;

    this.p2 = prevSegment
      ? stroke.getControlPoints(prevSegment.p1, this.p1, this.p4)[1]
      : this.p1;
    this.p3 = nextSegment
      ? stroke.getControlPoints(this.p1, this.p4, nextSegment.p4)[0]
      : this.p4;
    // TODO: nextSegment here always expects a Bezier!

    this.init();

    // reset point moved state
    this.p1.moved = false;
    this.p2.moved = false;
    this.p3.moved = false;
    this.p4.moved = false;

    return this;
  }

  toSVG(prevSegment, width, height) {
    width = width || 1;
    height = height || 1;

    var svg = [],
      round = function (n, d?) {
        d = d == undefined ? 3 : d;
        return (
          Math.round((n + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d)
        );
      };

    if (!prevSegment) {
      // start with a Move To command
      svg.push(`M ${round(this.p1.x * width)} ${round(this.p1.y * height)}`);
    } else if (prevSegment.isBezier) {
      // we can use the continued Bezier path syntax, skipping the first control point
      svg.push(
        `S ${round(this.p3.x * width)} ${round(this.p3.y * height)} ${round(
          this.p4.x * width
        )} ${round(this.p4.y * height)}`
      );
    } else {
      // use regular Bezier path syntax
      svg.push(
        `C ${round(this.p2.x * width)} ${round(this.p2.y * height)} ${round(
          this.p3.x * width
        )} ${round(this.p3.y * height)} ${round(this.p4.x * width)} ${round(
          this.p4.y * height
        )}`
      );
    }

    return svg.join(' ');
  }
}
