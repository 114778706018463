// import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
// import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
// import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';
// import { useCallback } from 'react';
// import moment from 'moment';

// import { Fields } from './useGeneralFormConfig';
// import {
//   getChanges,
//   isEqual
// } from '@cube3/state/src/redux/middleware/cube-client-middleware/contract-submit-middleware/';

export const useGeneralSubmit = ({ contractId, form, initialValues }) => {
  return null;

  // const [mutateResource] = useMutateResource__ALPHA({
  //   cacheInvalidator: () => [{ type: 'contract', id: contractId }],
  //   actionDecorators: [
  //     addFormId(form, {
  //       formState: undefined,
  //       useRequestStatus: true,
  //       delegate: true,
  //       data: action => {
  //         return { ...action.payload.data };
  //       },
  //       errorFormatter: (error, data, form, action) => {
  //         return {
  //           [`key_${data.field_id}`]: error['_error'].map(
  //             e => e.code || e.detail || e
  //           )
  //         };
  //       }
  //     })
  //   ]
  // });

  // const [mutateAssetsRelationship] = useMutateRelationship__ALPHA({
  //   cacheInvalidator: () => [{ type: 'contract', id: contractId }],
  //   ancestor: { type: 'contract', id: contractId },
  //   relationship: 'assets',
  //   actionDecorators: [
  //     addFormId(form, {
  //       formState: undefined,
  //       useRequestStatus: true,
  //       delegate: true,
  //       data: action => {
  //         return { ...action.meta.apiClient };
  //       },
  //       errorFormatter: (error, data, form, action) => {
  //         return {
  //           [`key_${data.field_id}`]: error['_error'].map(
  //             e => e.code || e.detail || e
  //           )
  //         };
  //       }
  //     })
  //   ]
  // });
  // const [mutateProjectsRelationship] = useMutateRelationship__ALPHA({
  //   cacheInvalidator: () => [{ type: 'contract', id: contractId }],
  //   ancestor: { type: 'contract', id: contractId },
  //   relationship: 'projects',
  //   actionDecorators: [
  //     addFormId(form, {
  //       formState: undefined,
  //       useRequestStatus: true,
  //       delegate: true,
  //       data: action => {
  //         return { ...action.meta.apiClient };
  //       },
  //       errorFormatter: (error, data, form, action) => {
  //         return {
  //           [`key_${data.field_id}`]: error['_error'].map(
  //             e => e.code || e.detail || e
  //           )
  //         };
  //       }
  //     })
  //   ]
  // });

  // // const currentContract = useResource__ALPHA({
  // //   resourceType: 'contract',
  // //   resourceId: contractId
  // // });

  // // const contractType = currentContract.resource?.relationships.contract_type.id;

  // const generalSubmit = useCallback(
  //   values => {
  //     let changedAttributes = false;

  //     // const keys = Object.keys(values);
  //     const keys = ['key_display_name', 'key_duration'];
  //     // check if we need to perform an update of the contracts resource iteself
  //     keys.forEach(key => {
  //       if (!initialValues || !isEqual(values[key], initialValues[key])) {
  //         changedAttributes = true;
  //       }
  //     });

  //     // remove the prefix from the attribute names
  //     const vals = keys.reduce((acc, key) => {
  //       const k = key.replace(/(?:field_|key_)/, '');
  //       acc[k] = values[key];
  //       return acc;
  //     }, {}) as Fields;

  //     const contractTypeChanged =
  //       values.key_contract_type?.id &&
  //       values.key_contract_type.id !== initialValues?.key_contract_type?.id;

  //     if (changedAttributes || contractTypeChanged) {
  //       console.warn(
  //         '%c MUTATING CONTRACT',
  //         'color: #ffa; background-color: #32A'
  //       );

  //       mutateResource({
  //         type: 'contract',
  //         id: contractId,
  //         display_name: vals.display_name,
  //         expires: !!vals.duration.expires,
  //         starts_at: vals.duration.expires
  //           ? moment(vals.duration.starts_at)
  //               .startOf('day')
  //               .toISOString()
  //           : null,
  //         expires_at: vals.duration.expires ? vals.duration.expires_at : null,
  //         status: 'CONTRACT_STATUS_FINAL',
  //         relationships: contractTypeChanged
  //           ? {
  //               contract_type: {
  //                 data: {
  //                   id: values.key_contract_type?.id,
  //                   type: 'contract-type'
  //                 }
  //               }
  //             }
  //           : undefined
  //       });

  //       const assetChanges = getChanges(
  //         initialValues.key_attached_assets,
  //         values.key_attached_assets
  //       );
  //       const projectChanges = getChanges(
  //         initialValues.key_attached_projects,
  //         values.key_attached_projects
  //       );

  //       if (assetChanges?.length) {
  //         console.warn(
  //           '%c MUTATING CONTRACT ASSETS',
  //           'color: #ffa; background-color: #32A'
  //         );

  //         mutateAssetsRelationship(assetChanges);
  //       }

  //       if (projectChanges?.length) {
  //         console.warn(
  //           '%c MUTATING CONTRACT ASSETS',
  //           'color: #ffa; background-color: #32A'
  //         );
  //         mutateProjectsRelationship(projectChanges);
  //       }

  //       if (
  //         vals.contract_type?.id &&
  //         vals.contract_type.id !== initialValues?.contract_type?.id
  //       ) {
  //         // TODO: maybe implement type switching, right now it's not a ux requirement
  //       }
  //     }
  //   },
  //   [
  //     contractId,
  //     mutateProjectsRelationship,
  //     initialValues,
  //     mutateResource,
  //     // contractType,
  //     mutateAssetsRelationship
  //   ]
  // );

  // return generalSubmit;
};
