import * as React from 'react';
import Cube3Button, { Cube3ButtonProps } from './Cube3Button';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// Button with just a left icon which overrides styling in buttonwithtextandicon
/// </Summary>

// const styles = (theme: Theme) =>
//   createStyles({
//     root: {
//       paddingLeft: '16px',
//       paddingRight: '16px'
//     },
//     root_dropdown: {
//       paddingRight: '8px'
//     },
//     root_icon: {
//       paddingLeft: '12px'
//     },
//     textBox: {
//       marginLeft: '0px',
//       marginRight: '0px'
//     },
//     textBox_dropdown: {
//       marginRight: '4px'
//     },

//     textBox_icon: {
//       marginLeft: '8px'
//     }
//   });

interface Properties extends Cube3ButtonProps {
  path?: string;
  isDropDown?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

function Button(props: Properties) {
  const {
    text,
    path,
    isDropDown = false,
    children,
    colorVariant = 'ghost1',
    ...restProps
  } = props;

  const label =
    text || (typeof children === 'string' ? (children as 'string') : undefined);

  return (
    <Cube3Button
      // classes={{
      //   button: `${classes.root}
      //             ${isDropDown && classes.root_dropdown}
      //             ${path && classes.root_icon}`,
      //   textStyling: `${classes.textBox}
      //             ${isDropDown && classes.textBox_dropdown}
      //             ${path && classes.textBox_icon}`
      // }}
      variant={
        isDropDown && path
          ? 'dropdownWithIcon'
          : isDropDown
          ? 'dropdown'
          : path
          ? 'withIcon'
          : 'regular'
      }
      pathLeftSvg={path && label && path}
      pathCenterSvg={!label ? path : undefined}
      colorVariant={colorVariant}
      text={label}
      children={typeof children !== 'string' && children}
      {...restProps}
    />
  );
}

export default Button;
