// /// <reference path="./index.d.ts" />
import * as tus from 'tus-js-client';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';
import { WorkerHttpStack } from './WorkerHttpStack';

interface Config extends tus.UploadOptions {
  metadata?: {};
  chunkSize?: number;
  endpoint: string;
  retryDelays: number[];
  removeFingerprintOnSuccess?: boolean;
  onError?(err: Error): void;
  onProgress?(bytesUploaded: number, bytesTotal: number): void;
  onSuccess?(): void;
  fingerprint?(fingerprintFile, options): Promise<string>;
  resume: boolean;
  /** trigger update of the linked "file-upload" resource */
  triggerReduxUpdate?(update: Partial<Upload>): void;
}

/**
 * @summary Extendedupload combines `tus upload` behaviour and extends it by exposing extra methods.
 */

let workerStack;
if (window.Worker) {
  workerStack = new WorkerHttpStack();
}

export class ExtendedUpload extends tus.Upload {
  public eta = Infinity;
  public msLeft = undefined;
  public _fingerprint;
  private lastGet = 0;
  private progressSamples = [];
  public lastProgress = { bytesUploaded: null, bytesTotal: null };
  public throttle = { timestamp: 0, timeout: null };
  public _aborted = false;
  public triggerReduxUpdate;
  public lastRequest;

  // eslint-disable-next-line
  constructor(file, { triggerReduxUpdate, ...restConfig }: Config) {
    const options = restConfig;
    if (workerStack) {
      options.httpStack = workerStack;
    }

    super(file, options);
    this.triggerReduxUpdate = triggerReduxUpdate
      ? triggerReduxUpdate
      : () => {
          console.warn('NOOP, method must be provided when instancing');
        };
    // this.id = Math.floor(Math.random() * Math.floor(30000000));
  }

  // consider removing
  public doSample(progress: string) {
    this.progressSamples = [
      ...this.progressSamples.slice(-100),
      { progress, timestamp: Date.now() }
    ];
  }

  public start() {
    this.progressSamples = [];
    return super.start();
  }

  /** Returns a estimation on the time left on the upload in milliseconds */
  public getTimeLeftMiliseconds() {
    const filtered = this.progressSamples
      .filter((s) => Date.now() - s.timestamp < 10 * 60 * 1000)
      .slice(-5);

    if (filtered.find((s) => s.progress === '100.00')) {
      return 0;
    }

    const ppsSamples = filtered.map((s, idx, arr) => {
      if (idx === 0) {
        return 0;
      }
      const deltaProgress = s.progress - arr[idx - 1].progress;
      const deltaTime = s.timestamp - arr[idx - 1].timestamp;
      const pps = (1000 / deltaTime) * deltaProgress;

      return pps;
    });

    const avgPps =
      ppsSamples.reduce((acc, val) => {
        return acc + val;
      }, 0) / ppsSamples.length;

    const msLeft =
      ((100 - filtered[filtered.length - 1]?.progress || 100) / avgPps) * 1000;

    const smoothedMsLeft = this.msLeft
      ? msLeft * 0.3 + this.msLeft * 0.7
      : msLeft;
    this.msLeft =
      smoothedMsLeft && smoothedMsLeft !== Infinity
        ? smoothedMsLeft
        : undefined;
    return Math.max(0, smoothedMsLeft);
  }

  /** Returns an estimation on the time left in `epochtime` */
  getEta(force: boolean = false) {
    const now = Date.now();

    if (now - this.lastGet > 5000 || force) {
      this.eta = Date.now() + this.getTimeLeftMiliseconds();
      this.lastGet = now;
    }

    return this.eta;
  }
}

// this is way to hack around having to create a class declaration (temporary)
export type UploadShape = ExtendedUpload;
