import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAnimationState } from '../Animation/Animatable';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { ShortCutsProvider } from '../keyboard-shortcuts/ShortCutsProvider';
import { useClassName } from '../utils/useClassName';

interface FullscreenProps {
  enabled: boolean;
  requested: boolean;
  style?: {};
  onUncontrolledFullscreenLeave: () => void;
  parentNode?: HTMLElement;
}

export const fullscreenElement = document.createElement('div');
fullscreenElement.setAttribute('id', 'fullscreenElement');
fullscreenElement.style.background = '#161616';
fullscreenElement.classList.add('reparentable-fullscreen-div');

const fullscreenContext = React.createContext(fullscreenElement);
export const FullscreenProvider = fullscreenContext.Provider;
export const FullscreenConsumer = fullscreenContext.Consumer;

// This component is used to move the fullscreen portal component accross the DOM tree.
// This way we can both switch to fullscreen without unmounting previewers and losing state,
// and switch betweem siblings without losing fullscreen mode.
// There are some animation drawbacks though.
// NOTE: You should probably never need to use this component anywhere else.
export const Reparentable: React.FC<{
  el: HTMLElement;
  detached: boolean;
}> = React.memo((props) => {
  const { el, detached = false } = props;
  const [ref, setRef] = useState(null);

  const handleRef = useCallback(
    (node) => {
      setRef(node);
    },
    [setRef]
  );

  useEffect(() => {
    if (ref && el && !detached) {
      ref.appendChild(el);

      return () => {
        if (ref === el?.parentElement) {
          document.body.appendChild(el);
        }
      };
    }
  }, [ref, el, detached]);

  return <div ref={handleRef} />;
});

const useStyles = makeStyles(
  createStyles({
    wrapperBase: {
      transition: 'opacity 0.4s ease 0.1s',
      opacity: 1,
      transform: 'scale(1)'
    },
    wrapperTransition: {
      opacity: 0
    },
    wrapperEnabled: {
      height: '100% !important',
      width: '100% !important',
      backgroundColor: 'black !important',
      display: isMobile ? 'flex !important' : 'initial'
    }
  })
);

let ids = 0;

export const FullScreen: React.FC<React.PropsWithChildren<FullscreenProps>> =
  React.memo((props) => {
    const fsElement = useContext(fullscreenContext);

    const { enabled, requested, children, style } = props;

    const id = useLazyRef(() => ids++).current;

    const classes = useStyles();

    const wrapperClassName = useClassName(
      classes.wrapperBase,
      enabled !== requested && classes.wrapperTransition,
      requested && classes.wrapperEnabled
    );

    const PreviewWrapper = useMemo(
      () => (
        <div key="preview-wrapper" className={wrapperClassName} style={style}>
          {children}
        </div>
      ),
      [children, style, wrapperClassName]
    );

    const animationState = useAnimationState();

    const animating =
      animationState === 'entering' ||
      animationState === 'exiting' ||
      animationState === 'exited';

    return (
      <div>
        <style>
          {`
                #fullscreenElement > *:nth-last-child(2) > * {
                  display: none !important;
                  background: black;
                  z-index: 2;
                }

                #fullscreenElement > *:nth-child(2) {
                  z-index: 5;
                }
              `}
        </style>

        <ShortCutsProvider
          name={!animating && enabled && `previewer.${id}`}
          boundaryEl={fsElement}
          grabFocus={true}
          // isolate={(requested && 'down') || undefined}
          returnFocus={true}
        >
          <Reparentable el={fsElement} detached={requested} />
          {ReactDOM.createPortal(PreviewWrapper, fsElement)}
        </ShortCutsProvider>
      </div>
    );
  });

const useLazyRef = (initFn) => {
  const uninitialized = Symbol();

  const ref = useRef<any>(uninitialized);

  if (typeof initFn === 'function' && ref.current === uninitialized) {
    ref.current = initFn();
  }

  return ref;
};
