import { FSA } from '../flux-standard-action';

export interface MetadataClipboardState {
  metadata: { [key: string]: any };
  contract: { [key: string]: any };
}
const initialState: MetadataClipboardState = { metadata: {}, contract: {} };
const ns = 'cube3/metadata-clipboard';

// Actions
const COPY_ALL_VALUES = `${ns}/COPY_ALL_VALUES`;
export const actions = { COPY_ALL_VALUES };

const copyAllValues = formValues => ({
  type: actions.COPY_ALL_VALUES,
  payload: formValues
});
export const actionCreators = { copyAllValues };

const getAllValues = state => state;
const getValuesByType = (state, type: 'metadata' | 'contract') => state?.[type];
export const selectors = { getAllValues, getValuesByType };

export const metadataClipboard = (state = initialState, action: FSA) => {
  const { type, payload } = action;
  switch (type) {
    case actions.COPY_ALL_VALUES:
      return { ...state, [payload.type]: payload.data };
    default:
      return state;
  }
};
