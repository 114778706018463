import { ExtendedUpload, UploadShape } from './extendedupload';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';

import { QueueManager } from './queueManager';
import { Config, OptionalConfig, defaultConfig } from './config';
import { UrlStorage, ResumableUpload } from './urlStorage';
import { FSA } from '../../redux/flux-standard-action';
import { actionCreators } from '../../redux/ducks/resource-nodes';
import { actions } from '../../redux/ducks/offline-detection';

type AllowedMetadata = string | number | boolean;

export type NestedMetadata = {
  [key: string]: AllowedMetadata;
};
interface Context {
  id?: string;
  targetId: string;
  targetType: string;
  ancestorId: string;
  ancestorType: string;
  resumeContext: 'target' | 'ancestor';
  workspace: string;
  tusMetadata?: NestedMetadata;
}

type EnhancerFn = (action: FSA) => void;
type Enhancer = (next: EnhancerFn) => EnhancerFn;
export type EnhancerMaker = (store: any) => Enhancer;

// upload management class only handles uploads for
// middleware asset resources in content-tree right now
class Uploader {
  private config: Config;

  private queues: QueueManager;
  private urlStorage: UrlStorage;

  private enhancerMakers: EnhancerMaker[] = [];
  private enhancers: Enhancer[];
  private dispatch;

  constructor(config: Config) {
    this.config = { metadata: {}, ...defaultConfig, ...config };
    this.queues = new QueueManager();
    //window['_queueManager'] = this.queues; // for debuging

    // keeps track of active and completed uploads
    this.urlStorage = new UrlStorage();

    // set up enhancer middleware so uploads state can also be modified via redux
    this.enhancerMakers.push(this.urlStorage.getEnhancer());
    this.enhancerMakers.push(this.offlineEnhancer);

    // wait for state to be loaded from local/session storage
    this.urlStorage.readyPromise.then(() => {
      this.urlStorage.cleanCompleted(
        this.config.keepCompletedDays * 24 * 60 * 60 * 1000
      );
      this.urlStorage.resetActive();
    });
  }

  offlineEnhancer = (store) => {
    return (next) => (action) => {
      switch (action.type) {
        case actions.OFFLINE:
          this.pauseAll();
          break;
        case actions.ONLINE:
          this.resumeAll();
          break;
        default:
          break;
      }
      return next(action);
    };
  };

  clearStorage() {
    this.stopAll().then(() => {
      this.urlStorage.clearStorage();
      this.queues.clearAll();
    });
  }

  initEnhancers(store) {
    this.dispatch = store.dispatch;
    this.enhancers = this.enhancerMakers
      .filter((em) => !!em)
      .map((em) => em(store));
  }

  getEnhancer() {
    return (store) => {
      this.initEnhancers(store);
      return (next) => {
        return (action) => {
          this.enhancers.reduceRight((acc, val) => {
            return val(acc);
          }, next)(action);
        };
      };
    };
  }

  public cancelUpload = (upload: Upload, update = true) => {
    const { tusUpload } = upload;
    const promise = tusUpload ? tusUpload.abort(false) : Promise.resolve();

    return promise.then(() => {
      if (tusUpload) {
        if (this.queues.has('uploading', tusUpload)) {
          this.queues.remove('uploading', tusUpload);
        }

        if (this.queues.has('queued', tusUpload)) {
          this.queues.remove('queued', tusUpload);
        }
        if (this.queues.has('userPaused', tusUpload)) {
          this.queues.remove('userPaused', tusUpload);
        }
      }
      if (update) {
        this.dispatch(
          actionCreators.receiveResource('file-upload', {
            ...upload,
            tusUpload: undefined,
            file: undefined,
            active: false,
            failed: upload.progress < 100 ? true : upload.failed
          })
        );
      }
      this.attemptNextUploadInQueue();
    });
  };

  /** Pauses an upload that is in progress */
  public pauseUpload = (upload: Upload) => {
    const { tusUpload } = upload;

    if (!this.queues.has('uploading', tusUpload)) {
      console.warn('requested upload was not found, cannot pause', this.queues);
      return;
    }
    this.dispatch(
      actionCreators.receiveResource('file-upload', {
        ...upload,
        paused: true
      })
    );

    tusUpload
      .abort(false)
      .then(() => {
        this.queues.move('uploading', 'userPaused', tusUpload);
        this.dispatch(
          actionCreators.receiveResource('file-upload', {
            ...upload,
            paused: true
          })
        );
        this.attemptNextUploadInQueue();
      })
      .catch((err) => console.error(err));

    // put the upload on the paused stack
  };

  /** Resumes an upload that is manually paused by the user or starts the upload that was on the queue waiting to start  */
  unpauseUpload = (upload: Upload) => {
    const { tusUpload } = upload;

    if (this.queues.has('userPaused', tusUpload)) {
      // put the upload to the queue
      this.queues.move('userPaused', 'queued', tusUpload);

      this.attemptNextUploadInQueue(true);

      this.dispatch(
        actionCreators.receiveResource('file-upload', {
          ...upload,
          paused: false
        })
      );

      return;
    }

    if (this.queues.has('queued', tusUpload)) {
      // place it at the start of the queue.
      this.queues.move('queued', 'queued', tusUpload);

      this.attemptNextUploadInQueue(true);

      this.dispatch(
        actionCreators.receiveResource('file-upload', {
          ...upload,
          paused: false
        })
      );

      return;
    }

    console.warn('requested upload was not found, cannot resume');
  };

  /** Checks if the requested upload is in the paused array. */
  isUserPaused = (upload: Upload): boolean => {
    return this.queues.has('userPaused', upload.tusUpload);
  };

  isQueued = (upload: Upload): boolean => {
    return this.queues.has('queued', upload.tusUpload);
  };

  stopAll() {
    const aborts = this.queues.getQueue('uploading').map((u) => {
      if (u.throttle.timeout) {
        clearTimeout(u.throttle.timeout);
      }
      return u.abort(false);
    });
    return Promise.all(aborts);
  }

  pauseAll() {
    const aborts = this.queues.getQueue('uploading').map((u) => {
      if (u.throttle.timeout) {
        clearTimeout(u.throttle.timeout);
      }
      this.queues.move('uploading', 'queued', u);
      return u.abort(false);
    });
    return Promise.all(aborts);
  }

  resumeAll() {
    for (let i = 0; i < this.config.maxInProgressUploads; i++) {
      const nextUpload = this.queues.getQueue('queued')[i];
      if (nextUpload) {
        this.attemptNextUploadInQueue(true);
      }
    }
  }

  getResumableFromParent(
    file: File,
    context: Context,
    configOverrides: OptionalConfig = {}
  ): Promise<ResumableUpload | {}> {
    const config = this.makeConfig(file, context, configOverrides);

    const fpPromise = config.fingerprint(file, config);
    return fpPromise.then((fp) => {
      return this.urlStorage.findUploadsInAncestor(fp).then((previous) => {
        const items = previous;

        return items || [];
      });
    });
  }

  /** Check if the upload metadata is present in local storage so we can resume the upload */
  canResume(assetId: string) {
    return this.urlStorage.findUploadsByAssetId(assetId).then((uploads) => {
      return uploads.length > 0;
    });
  }

  /** Grabs the next following upload from the queue and attempts to upload the file, respecting the max amount of simultaneous uploads from the config. */
  attemptNextUploadInQueue = (
    /** Forces to start the first one in the queue, while pausing another upload if it exceeds the max amount of downloads */
    forceNext?: boolean
  ) => {
    if (forceNext) {
      if (this.queues.any('queued')) {
        const nextUpload = this.queues.newest('queued');

        if (!nextUpload) {
          return;
        }

        // start the upload
        nextUpload.findPreviousUploads().then((previousUploads) => {
          if (previousUploads.length > 0) {
            nextUpload.resumeFromPreviousUpload(previousUploads[0]);
          }
          nextUpload.start();

          // if the current uploads exceeds the max allowed uploads...
          if (
            this.queues.getQueue('uploading').length >=
            this.config.maxInProgressUploads
          ) {
            const uploadToPause = this.queues.oldest('uploading');
            uploadToPause.abort(false);

            this.queues.move('uploading', 'queued', uploadToPause);
          }

          this.queues.move('queued', 'uploading', nextUpload);
        });
      }
    }

    // check if there is a queue and check if in progress...
    else if (
      this.queues.getQueue('queued').length > 0 &&
      this.queues.getQueue('uploading').length <
        this.config.maxInProgressUploads
    ) {
      // grab the next of the queue
      const nextUpload = this.queues.oldest('queued');

      if (!nextUpload) {
        return;
      }

      // start the upload
      nextUpload.findPreviousUploads().then((previousUploads) => {
        if (previousUploads.length > 0) {
          nextUpload.resumeFromPreviousUpload(previousUploads[0]);
        }

        nextUpload.start();

        // move it to the other stack
        this.queues.move('queued', 'uploading', nextUpload);
      });
    }
  };

  remove(upload) {
    if (upload._urlStorageKey) {
      this.urlStorage.removeUpload(upload._urlStorageKey);
    } else {
      console.warn('Tried to remove upload without key', { upload });
    }
  }

  makeConfig(
    file: File,
    context: Context,
    configOverrides: OptionalConfig = {}
  ) {
    const {
      onError: oe,
      onSuccess: os,
      onProgress: op,
      ...overrides
    } = configOverrides;
    const { resume, onError, onProgress, onSuccess, ...config } = this.config;
    return {
      ...config,
      ...overrides,
      resume: true,
      storeFingerprintForResuming: true,
      urlStorage: this.urlStorage,
      fingerprint: configOverrides.fingerprint || this.urlStorage.fingerprint,
      metadata: {
        ...context.tusMetadata,
        ...this.config.metadata,
        id: context.id,
        filename: file.name,
        filetype: file.type,
        targetResourceId: context.targetId,
        targetResourceType: context.targetType,
        ancestorResourceId: context.ancestorId,
        ancestorResourceType: context.ancestorType,
        library_id: context.targetId,
        resumeContext: context.resumeContext
      }
    };
  }

  makeHandlers(
    upload: UploadShape,
    configOverrides: OptionalConfig = {},
    resolve,
    reject
  ) {
    return {
      onProgress: (bytesUploaded: number, bytesTotal: number) => {
        const now = Date.now();
        const delta = now - upload.throttle.timestamp;

        upload.lastProgress = { bytesUploaded, bytesTotal };

        const handler = () => {
          upload.throttle.timestamp = Date.now();

          const cb = configOverrides.onProgress || this.config.onProgress;

          upload.doSample(
            (
              (upload.lastProgress.bytesUploaded /
                upload.lastProgress.bytesTotal) *
              100
            ).toFixed(2)
          );
          upload.getEta();

          if (cb) {
            return cb(
              upload.lastProgress.bytesUploaded,
              upload.lastProgress.bytesTotal
            );
          }
        };

        if (delta < 1000) {
          if (upload.throttle.timeout !== null) {
            clearTimeout(upload.throttle.timeout);
          }

          upload.throttle.timeout = setTimeout(handler, 1000 - delta);
        } else {
          if (upload.throttle.timeout !== null) {
            clearTimeout(upload.throttle.timeout);
          }
          handler();
        }
      },
      onSuccess: () => {
        // prevent throttled progress events from messing up state afterwards
        if (upload.throttle.timeout) {
          clearTimeout(upload.throttle.timeout);
        }
        const cb = configOverrides.onSuccess || this.config.onSuccess;

        // move the upload from uploading to finished
        this.queues.move('uploading', 'finished', upload);

        // we want to keep them around now until after they have processed
        this.urlStorage.markCompleted(upload._fingerprint);

        cb(upload);

        // resolve the promise.
        resolve(upload);

        // attempt the next upload in the queue
        this.attemptNextUploadInQueue();
      },
      onError: (error: any) => {
        // prevent old requests from from triggering error
        if (
          error.originalRequest?._method === 'PATCH' &&
          error.originalRequest !== upload.lastRequest
        ) {
          return;
        }

        if (upload.throttle.timeout) {
          clearTimeout(upload.throttle.timeout);
        }

        console.warn('Failed upload after error', {
          error,
          offline: navigator.onLine
        });

        const cb = configOverrides.onError || this.config.onError;

        // move the upload from uploading to failed
        this.queues.move('uploading', 'failed', upload);

        if (cb) {
          setTimeout(() => cb(upload, error));
        }
        reject(error);
        // attempt the next upload in the queue
        this.attemptNextUploadInQueue();
      },
      // keep track of what was the last chunk request
      onBeforeRequest: function (req) {
        // var xhr = req.getUnderlyingObject();
        if (req._method === 'PATCH') {
          upload.lastRequest = req;
        }
      }
      // onAfterResponse: function(req, res) {
      //   var xhr = req.getUnderlyingObject();
      // }
    };
  }

  /** Upload a single resource */
  resourceUpload(
    file: File,
    context: Context,
    configOverrides: OptionalConfig = {},
    previousIndex = undefined
  ) {
    // TODO this is bad, we should actually use a class declaration for extended upload and go from there but its been bugging me all day to get that properly working.

    const upload: UploadShape = new ExtendedUpload(file, {
      ...this.makeConfig(file, context, configOverrides)
      // use tus callbacks
    });
    const promise = this.makeUploadReadyPromise((resolve, reject, ready) => {
      // make a new extended upload to add to the current list.

      upload.options = {
        ...upload.options,
        ...this.makeHandlers(upload, configOverrides, resolve, reject)
      };

      // we do a little hacking here to generate a fingerprint so we can properly start the download again after a refresh or a connection timeout
      // by first starting it and the pausing it again so the fingerprints exist for later comparison.
      upload.findPreviousUploads().then((previousUploads) => {
        if (previousUploads.length > 0) {
          upload.resumeFromPreviousUpload(previousUploads[previousIndex || 0]);
        }

        this.urlStorage.markActive(upload._fingerprint);

        // pause the upload.
        upload.abort(false);

        // add it to the queue
        this.queues.add('queued', upload);

        // attempt to start the upload, respecting the queue.
        this.attemptNextUploadInQueue();
        ready();
      });
    });

    return {
      upload,
      promise
    };
  }

  makingUpload = Promise.resolve();
  makeUploadReadyPromise(fn) {
    this.makingUpload = this.makingUpload.then(
      () =>
        new Promise((res, rej) => {
          return new Promise((ready) => {
            fn(res, rej, ready);
          });
        })
    );
    return this.makingUpload;
  }
}

/**
 *  export a preconfigured uploader instance for use in clients / middleware
 */
const uploader = new Uploader(defaultConfig);
export default uploader;
