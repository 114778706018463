import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  MESSAGE_FULL,
  MESSAGE_FULL_SELECT_ALTERNATIVE
} from '../InviteMemberModal';
import { Role } from '@cube3/common/model/schema/resources/role';

const accountParams = { page: { size: -1 } };

const invitationParams = {
  page: { size: -1 },

  filter: {
    accepted: false,
    expired: false
  }
};

export const useWorkspaceLimits = (roles, selectedRole, emailCount) => {
  const [workspaceId] = useCurrentWorkspace();

  const { first: workspace } = useResource__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    strategy: 'fetch-on-mount'
  });

  // should check the role if is billable
  const { resources: accounts } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'account',
    edgeLabel: 'accounts',
    strategy: 'fetch-on-mount',
    params: accountParams
  });

  const { resources: invitations } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'invitation',
    edgeLabel: 'invitations',
    params: invitationParams
  });

  const nonBillableRoles = roles?.filter((r) => !r.billable).length > 0;

  /** number of accepted invites with billable role */
  const numberOfAccounts = accounts?.filter((a) =>
    billableRole(roles, a.relationships.role.id)
  )?.length;

  // TODO: use "billable" filter on invitations when api lands
  /** number of pending invites */
  const numberOfInvitations = nonBillableRoles
    ? invitations?.filter((i) => billableRole(roles, i.relationships.role.id))
        .length
    : invitations?.length;

  const billableRoleSelected = selectedRole?.billable;

  const maxAccounts = workspace.maximum_allowed_accounts;

  const workspaceLimitsError =
    maxAccounts > 0 &&
    billableRoleSelected &&
    (emailCount + numberOfInvitations + numberOfAccounts > maxAccounts ||
      numberOfInvitations + numberOfAccounts >= maxAccounts)
      ? nonBillableRoles
        ? MESSAGE_FULL_SELECT_ALTERNATIVE
        : MESSAGE_FULL
      : undefined;

  return {
    billableRoleSelected,
    workspaceLimitsError,
    nonBillableRoles,
    maxAccounts
  };
};

const billableRole = (roles: Role[], roleId: string) => {
  return roles?.find((role) => role.id === roleId)?.billable;
};
