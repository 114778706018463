import { ShareBase } from '../../resource-types';
import { RawModel } from '../schema';

export type DownloadType = 'preview' | 'source';

export interface Share extends ShareBase {
  // allow downloading assets via share
  download_enabled: boolean;
  download_type?: DownloadType;

  // extra (out of band) password protection
  password_enabled: boolean;
  password?: string;
  // show created_at and updated_at
  show_dates: boolean;
  watermark: boolean;
  show_tags: boolean;
}

export const share: RawModel = {
  type: 'share',
  plural: 'shares',
  JSONApi: true,
  attributes: {
    id: 'UUID'
    // display_name: 'string',
    // display_image: 'URL'
  },
  relationships: {
    node: {
      type: 'content-tree-node',
      binding: ['one', 'many']
    },
    workspaces: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'shares',
      required: [false, false]
    }
  }
};
