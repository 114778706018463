import { connect } from 'react-redux';
import { selectors } from '../ducks/session';
import { CubeReduxState } from '../ducks/CubeReduxState';

/**
 * @summary : Convenience HOC that injects authenticated status.
 */

export interface AuthenticatedUserProps {
  authenticated: boolean;
  userId: string;
}

export interface AuthenticatedProps {
  authenticated: any;
}

// injects authenticated status
const stateToProps = (state: CubeReduxState) => ({
  authenticated: selectors.isAuthenticated(state.session)
});

// expands on the above helper, by also adding the user id stored in the id_token
const withUserStateToProps = (state: CubeReduxState) => ({
  authenticated: selectors.isAuthenticated(state.session),
  userId: selectors.getUserId(state.session)
});

export default connect(stateToProps);
export const withAuth = connect(stateToProps);
export const withAuthUser = connect(withUserStateToProps);
