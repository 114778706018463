import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import {
  RequestStatuses,
  statuses
} from '@cube3/state/src/redux/ducks/request-status';
import { Folder } from '@cube3/common/model/schema/resources/folder';
import {
  DOWNLOAD_ERROR_CODES,
  downloadErrors
} from '@cube3/ui/src/library/Downloads/downloadErrors';
import { useCallback, useEffect, useMemo, useRef } from 'react';

interface Props {
  presetId: string;
  workspaceId?: string;
}

export const useCreateRenderBatch = ({ workspaceId, presetId }: Props) => {
  const [createDryRun, createBatchStatus, createDryRunError] =
    useCreateResource__ALPHA({
      ancestor: workspaceId && {
        type: 'workspace',
        id: workspaceId
      },
      resourceType: 'render-batch',
      cacheInvalidator: null
    });

  const onCreateBatch = useCallback(
    (
      dryRun: boolean,
      assets?: string[],
      folders?: Folder[],
      tempId?: string
    ) => {
      createDryRun({
        type: 'render-batch',
        temporaryId: tempId,
        dry_run: dryRun,
        relationships: {
          workspace: {
            data: workspaceId
              ? {
                  type: 'workspace',
                  id: workspaceId
                }
              : null
          },
          'render-preset': {
            data: {
              type: 'render-preset',
              id: presetId
            }
          },
          assets: {
            data: assets?.map((id) => ({ type: 'asset', id }))
          },
          folders: {
            data: folders?.map((f) => ({ type: 'folder', id: f.id }))
          }
        }
      });
    },
    [createDryRun, presetId, workspaceId]
  );

  /** cache dry run errors */
  const renderDryRunErrorRef = useRef(undefined);
  useEffect(() => {
    if (!renderDryRunErrorRef.current && createDryRunError) {
      renderDryRunErrorRef.current = createDryRunError;
    }
  }, [createDryRunError]);

  /** Render dry run error res:
   * - acceptable error:
   *    - RENDER_ALREADY_EXISTS: downloadable, don't show warning
   *    - RENDER_ORIGINAL_QUALITY: downloadable, should show warning
   * - not acceptable error: not downloadable and should show warning
   * - special case:
   *    - an asset can have both acceptable and un-acceptable error -> LOW_TV-AD is more important
   */
  const { alreadyRendered, error, warning } = useMemo(() => {
    const alreadyRendered: string[] = [];
    let error = {};
    let warning = {};

    const errorState = createDryRunError
      ? createDryRunError[0]
      : renderDryRunErrorRef.current?.[0];
    if (errorState) {
      error = Object.keys(errorState)?.reduce((acc, key) => {
        errorState[key]?.forEach((err) => {
          if (!err.meta?.acceptable) {
            acc[key] = err;
          }
        });
        return acc;
      }, {});

      // if an asset has un-acceptable error, should not add it to warning
      warning = Object.keys(errorState)?.reduce((acc, key) => {
        errorState[key]?.forEach((err) => {
          if (err.meta?.acceptable && !error[key]) {
            if (err.code === DOWNLOAD_ERROR_CODES.RENDER_ALREADY_EXISTS) {
              alreadyRendered.push(key);
            } else {
              acc[key] = err;
            }
          }
        });
        return acc;
      }, {});
    }
    return { error, warning, alreadyRendered };
  }, [createDryRunError]);

  const { parsedRenderErrors, parsedRenderWarnings, serverError } =
    useMemo(() => {
      /** un-acceptable errors, should not be downloadable */
      const e = parseCreateBatchErrors(error, createBatchStatus);
      /** acceptable error, should still be downloadable */
      const w = parseCreateBatchErrors(warning, createBatchStatus);
      return {
        parsedRenderErrors: e,
        parsedRenderWarnings: w,
        serverError: e.serverError
      };
    }, [createBatchStatus, error, warning]);

  return {
    onCreateBatch,
    createBatchStatus,
    alreadyRenderedAssetIds: alreadyRendered,
    parsedRenderErrors,
    parsedRenderWarnings,
    serverError
  };
};

export const deduped = (items: string[]) => {
  return items?.filter(
    (i, index) => items?.findIndex((v) => v === i) === index
  );
};

/**
 * @param parsedError: {[asset_id]: RequestError}
 */
const parseCreateBatchErrors = (
  parsedError,
  createBatchStatus: RequestStatuses
) => {
  const errorCodes = [];
  /** the assets that caused dry run failures(exclude already rendered error) */
  let failedAssetIds: string[];
  let serverError: string = '';

  if (parsedError && Object.keys(parsedError).length) {
    Object.keys(parsedError).forEach((assetId) => {
      const { code, source, title } = parsedError[assetId];
      code && errorCodes.push(code);
      // unknow error
      if (code && !source?.pointer) {
        serverError = title || downloadErrors.server_error;
      } else if (source?.pointer) {
        if (!failedAssetIds) {
          failedAssetIds = [];
        }
        failedAssetIds.push(assetId);
      }
    });
  } else if (createBatchStatus === statuses.SUCCESS) {
    failedAssetIds = [];
  }

  return {
    errorCodes: deduped(errorCodes),
    failedAssetIds: deduped(failedAssetIds) || [],
    serverError
  };
};
