const mergeByKey = (key, interceptorsArray = []) => {
  const joinedInterceptors = interceptorsArray
    .map((byKey) => byKey[key])
    .filter((i) => !!i);

  return function (input) {
    return joinedInterceptors.reduce((transformedInput, interceptor) => {
      if (key === 'requestInterceptor') {
        // req is already transformed, prevent double encoding, etc.
        if (transformedInput.retries > 0) {
          return transformedInput;
        }
      }
      // exit error interceptor chain early if error is resolved
      if (
        key === 'requestErrorInterceptor' ||
        key === 'responseErrorInterceptor'
      ) {
        if (!transformedInput.isAxiosError) {
          console.warn('error resolved, skipping interceptor', {
            transformedInput,
            interceptor
          });
          return transformedInput;
        }
      }
      return interceptor.bind(this)(transformedInput);
    }, input);
  };
};

export const mergeInterceptors = (interceptorsArray = []) => {
  return {
    requestInterceptor: function (req) {
      return mergeByKey('requestInterceptor', interceptorsArray).bind(this)(
        req
      );
    },
    requestErrorInterceptor: function (error) {
      return mergeByKey(
        'requestErrorInterceptor',
        [...interceptorsArray].reverse()
      ).bind(this)(error);
    },
    responseInterceptor: function (res) {
      return mergeByKey('responseInterceptor', interceptorsArray).bind(this)(
        res
      );
    },
    responseErrorInterceptor: function (error) {
      return mergeByKey(
        'responseErrorInterceptor',
        [...interceptorsArray].reverse()
      ).bind(this)(error);
    }
  };
};
