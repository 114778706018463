import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import { Typography } from '../typography/Typography';
import IconButton from '../Buttons/IconButton';
import {
  iconKeyboardArrowLeft,
  iconKeyboardArrowRight
} from '../icons/GeneralSVG';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      height: '20px',
      left: '28px',
      right: '28px',
      top: '21px',
      color: theme.customPalette.primary.contrastColor,
      position: 'absolute',
      zIndex: 1,
      ...theme.typographyStyles.heading2,
      letterSpacing: '1px'
    },
    scrim: {
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: `linear-gradient(
        180deg, 
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.4) 15%, 
        rgba(0, 0, 0, 0.08) 25%, 
        rgba(0, 0, 0, 0) 30%, 
        rgba(0, 0, 0, 0) 70%, 
        rgba(0, 0, 0, 0.08) 75%, 
        rgba(0, 0, 0, 0.6) 85%, 
        rgba(0, 0, 0, 0.8) 100%
      )`,
      zIndex: -1
    },
    button: {
      position: 'absolute',
      top: 'calc(50% - 46px)',
      bottom: 'calc(50% - 46px)',
      zIndex: 2
    },
    previousButton: {
      left: '20px'
    },
    nextButton: {
      right: '20px'
    }
  }));

interface DetailviewFullscreenControlsProps {
  gotoNextSibling: () => void;
  gotoPrevSibling: () => void;
  assetTitle: string;
  siblings: boolean;
  enableScrim?: boolean;
}

const DetailviewFullscreenControls: React.FC<DetailviewFullscreenControlsProps> = props => {
  const {
    gotoPrevSibling,
    gotoNextSibling,
    assetTitle,
    siblings,
    enableScrim = false
  } = props;

  const classes = useStyles();

  return (
    <>
      {!isMobile && (
        <Typography
          className={classes.title}
          component="span"
          typographyStyle={'heading1'}
        >
          {assetTitle}
        </Typography>
      )}

      {enableScrim && <div className={classes.scrim} />}

      {siblings && !isMobile && (
        <>
          <IconButton
            size={'regular'}
            path={iconKeyboardArrowLeft}
            onClick={gotoPrevSibling}
            colorVariant="ghost2"
            className={[classes.previousButton, classes.button].join(' ')}
          />
          <IconButton
            size={'regular'}
            path={iconKeyboardArrowRight}
            onClick={gotoNextSibling}
            colorVariant="ghost2"
            className={[classes.nextButton, classes.button].join(' ')}
          />
        </>
      )}
    </>
  );
};

export default DetailviewFullscreenControls;
