import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import SnackbarLibrary from '../library/LibrarySnackbar';
import { useClassName } from '../utils/useClassName';

// TODO: replace  <div id="snackbarPortal" /> with maybe contextAPI

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 1,
      flexGrow: 1,
      position: 'relative'
    },
    libraryDropActive: {
      '&:focus': {
        outline: 'none'
      }
    },
    libraryDropResting: {
      outline: 0
    },
    overlayDropOnMainArea: {
      position: 'absolute',
      top: 5,
      right: 5,
      left: 5,
      bottom: 5,
      pointerEvents: 'none',
      zIndex: 99,
      boxSizing: 'border-box',
      borderRadius: theme.surfaceCorners.mediumEmphasis,
      opacity: 1,
      transition: 'opacity 0.2s ease-in 0.1s'
    },
    overlayDropActive: {
      backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
        'transparent',
        'secondary',
        ['dragOver']
      ),
      border: `1px ${theme.customPalette.secondary.light} solid`
    },
    overlayDropActiveDisabled: {
      border: `1px ${theme.customPalette.surface.danger1} solid`
    },
    overlayDropResting: {
      opacity: 0,
      transition: 'opacity 0.2s ease-out'
    },
    // for list view
    nested: {
      display: 'contents'
    }
  }));

interface DropzoneUIProps {
  getRootProps: Function;
  isDragActive: boolean;
  libraryItemType: string;
  folderName?: string;
  disabled: boolean | string;
  isMainArea?: boolean;
  shouldShowSnackbar?: boolean | 'error';
}

const DropzoneUI: React.ComponentType<React.PropsWithChildren<
  DropzoneUIProps
>> = props => {
  const {
    getRootProps,
    isDragActive,
    libraryItemType,
    folderName,
    isMainArea,
    shouldShowSnackbar = true,
    disabled
  } = props;

  const classes = useStyles()

  const dropOnMainAreaClasses = useClassName(
    classes.overlayDropOnMainArea,
    isDragActive
      ? disabled
        ? classes.overlayDropActiveDisabled
        : classes.overlayDropActive
      : classes.overlayDropResting
  );

  return (
    <div
      {...getRootProps()}
      className={useClassName(
        classes.root,
        !isMainArea && classes.nested,
        isDragActive ? classes.libraryDropActive : classes.libraryDropResting
      )}
      data-type={libraryItemType}
      data-cy={isMainArea ? 'dropzone-container' : 'dropzone-nested'}
    >
      {props.children}
      <SnackbarLibrary
        shouldShowSnackbar={shouldShowSnackbar}
        disabled={disabled}
        isDragActive={isDragActive}
        message={
          disabled
            ? typeof disabled === 'string'
              ? disabled
              : 'Uploading not allowed here'
            : `Drop files to upload ${
                folderName ? 'into ' + folderName : 'here'
              }`
        }
      />
      {isMainArea && <div className={dropOnMainAreaClasses} />}
    </div>
  );
};

export default DropzoneUI;
