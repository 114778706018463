import React, { PropsWithChildren } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '../typography/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    suggestionRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 56,
      padding: '0px 16px',
      cursor: 'pointer',
      backgroundColor: theme.customPalette.primary.light,
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      '&:first-child': {
        paddingTop: 8
      },
      '&:last-child': {
        marginBottom: 8
      }
    },
    suggestionHighlighted: {
      backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
        theme.customPalette.primary.light,
        'primary',
        ['hover']
      )
    },
    suggestionDisabled: {
      opacity: 0.4,
      pointerEvents: 'none'
    },
    suggestionDisabledReason: {
      opacity: 0.4,
      pointerEvents: 'none'
    }
  })
);

export interface Suggestion {
  id: string;
  primaryText: string;
  secondaryText?: string;
  avatarUrl?: string;
}

interface Props<S = Suggestion> {
  index?: number;
  suggestion?: S;
  isHighlighted?: boolean;
  downShiftItemProps?: Function;
  handleSuggestionItemClick?: Function;
  isDisabled?: boolean;
  disabledReasonText?: string;
}

const noop = () => ({});

export default function DownshiftSuggestionsItem<S>(
  props: PropsWithChildren<Props<S>>
) {
  const {
    index,
    suggestion,
    isHighlighted = false,
    downShiftItemProps = noop,
    handleSuggestionItemClick,
    isDisabled = false,
    disabledReasonText
  } = props;

  if (
    (index === undefined || suggestion === undefined) &&
    downShiftItemProps !== noop
  ) {
    // need
    throw new Error('invalid config');
  }

  const downshiftProps = {
    ...downShiftItemProps({
      item: suggestion,
      index,
      disabled: isDisabled
    })
  };

  if (handleSuggestionItemClick) {
    downshiftProps.onClick = handleSuggestionItemClick;
  }

  return (
    <SuggestionsItemBase
      disabled={isDisabled}
      highlighted={isHighlighted}
      {...downshiftProps}
      disabledReasonText={disabledReasonText}
    >
      {props.children}
    </SuggestionsItemBase>
  );
}

interface SuggestionsItemBaseProps {
  highlighted?: boolean;
  onClick(): void;
  disabled?: boolean;
  disabledReasonText?: string;
}

export const SuggestionsItemBase: React.FC<
  React.PropsWithChildren<SuggestionsItemBaseProps>
> = (props) => {
  const {
    highlighted = false,
    onClick,
    disabled = false,
    disabledReasonText = 'disabled',
    ...restProps
  } = props;

  const classes = useStyles({});
  const { suggestionRoot, suggestionHighlighted, suggestionDisabled } = classes;

  return (
    <li
      className={[
        suggestionRoot,
        disabled ? suggestionDisabled : highlighted ? suggestionHighlighted : ''
      ].join(' ')}
      onClick={onClick}
      {...restProps}
      data-type="librarydeselectoverride" // prevent click to clear selection
    >
      {props.children}
      {disabled && disabledReasonText && (
        <Typography
          typographyStyle={'heading4'}
          className={classes.suggestionDisabledReason}
        >
          {disabledReasonText}
        </Typography>
      )}
    </li>
  );
};
