// cache
const opacityLut = new Map();

/**
 * @param val normalized opacity value
 * @returns colorized rgba pixel value
 */
export const getColor = (val: number) => {
  // try cache
  let o = opacityLut.get(val.toFixed(2));

  if (o === undefined) {
    // make value a bit more distinct
    o = Math.pow(val, 0.2);

    // add to cache
    opacityLut.set(val.toFixed(2), o);
  }
  return [249 + o * 8, 102 + o * 128, 0 + o * 64, o * 255];
};
