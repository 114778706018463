import { useEffect, useMemo, useRef, useState } from 'react';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { ContractSuggestion, UseSuggestionsResponse } from '../types';

const emptyArray = [];

export const useContractSuggestions = (
  config
): UseSuggestionsResponse<ContractSuggestion> => {
  const { query: directQuery, selected } = config;

  const [query, setQuery] = useState();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  // debounce the input a bit (until meili is ready)
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setQuery(directQuery?.toLowerCase());
    }, 300);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [directQuery]);

  const [workspaceId] = useCurrentWorkspace();

  /** overfetch initially */
  const pageSize = 30;

  /* start with one page, load more if needed */
  const [pages, setPages] = useState([1]);

  /* if the query changes reset the number of pages to retrieve */
  useEffect(() => {
    setPages([1]);
  }, [query]);

  /* TODO: replace after meili search has landed */
  const params = useMemo(() => {
    return {
      page: { size: pageSize },
      sort: '-created_at'
    };
  }, [pageSize]);

  // get the search results.

  const searchResults = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'contract',
    edgeLabel: 'contracts',
    params,
    pages,
    strategy: 'fetch-on-mount'
  });
  const contractTypes = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'contract-type',
    edgeLabel: 'contract-types'
  });

  const typed = !!directQuery;

  /* parse the results and filter out those that are already selected in ui */
  const suggestions = useMemo((): UseSuggestionsResponse<
    ContractSuggestion
  > => {
    const loading =
      searchResults.loading ||
      contractTypes.loading ||
      (typed && !searchResults.resources);

    if (!searchResults.resources) {
      return [emptyArray, emptyArray, loading];
    }

    const filtered = searchResults.resources.filter(
      c => !selected.filter(sel => sel.id === c.id).length
    );

    const queried = filtered.filter(c =>
      c.display_name.toLocaleLowerCase().includes(query)
    );

    const sugs = query ? queried : filtered.slice(0, 8);
    const mapped = sugs.map(c => ({
      ...c,
      __contractType: contractTypes.resources?.filter(
        t => t.id === c.relationships.contract_type.id
      )[0].display_name
    }));

    return [
      mapped,
      selected,
      loading
      // .map(c => {
      //   return { value: c.display_name, id: c.id };
      // })
    ];
  }, [
    searchResults.resources,
    selected,
    query,
    typed,
    contractTypes.resources,
    searchResults.loading,
    contractTypes.loading
  ]);

  const totalPages = searchResults.pageCounts?.pageCount;
  const totalItems = searchResults.pageCounts?.itemCount;

  useEffect(() => {
    if (
      searchResults.resources?.length &&
      searchResults.resources.length % pageSize === 0 &&
      Math.floor(searchResults.resources.length / pageSize) === pages.length
    ) {
      if (
        query &&
        suggestions[0].length <= totalItems &&
        pages.length < totalPages
      ) {
        setPages([...pages, pages.length + 1]);
      }
    }
  }, [
    pages,
    setPages,
    searchResults.resources,
    suggestions,
    query,
    totalItems,
    totalPages
  ]);

  return suggestions;
};
