import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  ExportChannelsPicker,
  useExportChannelsStyles
} from '@cube3/ui/src/exports/ExportChannelsPicker';
import {
  YoutubeChannelType,
  YouTubeConfig
} from '@cube3/common/model/resource-types';
import { ExportTarget } from '@cube3/common/model/schema/resources/export-target';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { useModalActions } from '../../Modals/modalActions';

interface ExportAccountType extends YouTubeConfig {
  id: string;
  display_name: string;
}

const emptyArray = [];

export const YoutubeExportChannelsModal = (props) => {
  const { modalContext } = props;
  const { exportTargetTypeId } = modalContext;

  const [selectedAccount, setSelectedAccount] =
    useState<ExportAccountType>(null);
  const [selectedChannel, setSelectedChannel] =
    useState<YoutubeChannelType>(null);

  const { previousModal, openModal } = useModalActions();
  const [currentAccountId] = useCurrentAccount();

  const params = useMemo(() => {
    return {
      filter: {
        exportTargetTypeId: exportTargetTypeId // NOTE: not implemented
      }
    };
  }, [exportTargetTypeId]);

  const exportTargets = useResourceList__ALPHA({
    resourceId: currentAccountId,
    resourceType: 'account',
    edgeType: 'export-target',
    edgeLabel: 'export-targets',
    params,
    strategy: 'fetch-on-mount'
  }).resources as ExportTarget<'youtube'>[];

  const accounts = useMemo(() => {
    if (!exportTargets) return [];
    return exportTargets.map((res) => {
      return {
        id: res.id,
        display_name: res.attributes.display_name,
        channels: res.attributes.integration_config.channels,
        categories: res.attributes.integration_config.categories,
        playlists: res.attributes.integration_config.playlists
      };
    });
  }, [exportTargets]);

  /** the channels of the selected account */
  const channels = (selectedAccount && selectedAccount.channels) || emptyArray;
  /** the categories of the selected account */
  const categories = selectedAccount && selectedAccount.categories;

  const handleNext = useCallback(() => {
    openModal(
      'social_export_options',
      {
        channel: selectedChannel,
        categories: categories,
        exportTargetId: selectedAccount?.id,
        exportTargetTypeId: exportTargetTypeId,
        playlists: selectedAccount?.playlists,
        platform: 'YouTube'
      },
      false
    );
  }, [
    categories,
    openModal,
    selectedAccount,
    selectedChannel,
    exportTargetTypeId
  ]);

  const handleManageIntegration = useCallback(() => {
    // open modal version of Account’s Integrations page
    openModal('connected_accounts_modal', null, false);
  }, [openModal]);

  const handleSelectAccount = useCallback((account) => {
    setSelectedAccount(account);
    setSelectedChannel(
      account.channels && account.channels.length === 1
        ? account.channels[0]
        : undefined
    );
  }, []);

  useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setSelectedAccount(accounts[0]);
    }
    if (
      selectedAccount &&
      !accounts.find((ac) => ac.id === selectedAccount?.id)
    ) {
      setSelectedAccount(null);
    }
  }, [accounts, selectedAccount]);

  useEffect(() => {
    if (channels.length === 1) {
      setSelectedChannel(channels[0]);
    }
    if (channels.length === 0) {
      setSelectedChannel(null);
    }
  }, [channels]);

  const classes = useExportChannelsStyles();

  return (
    <ModalMenuUI
      title="Export to YouTube • Select Channel"
      onCloseEvent={previousModal}
      classes={{ contents: classes.modalContents }}
      wide={true}
      footerLeftComponent={
        <Button
          text={'Manage integration'}
          onClick={handleManageIntegration}
          colorVariant={'ghost1'}
        />
      }
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={previousModal}
            disabled={false}
          />
          <Button
            disabled={!selectedChannel}
            colorVariant={'filled1'}
            text={'Next'}
            onClick={handleNext}
          />
        </>
      }
    >
      <ExportChannelsPicker
        title="Channel"
        noChannelsMessage="This YouTube account does not have any channels. Visit YouTube.com
        to set up a channel first."
        accounts={accounts}
        channels={channels}
        selectedAccount={selectedAccount}
        selectedChannel={selectedChannel}
        onManageIntegration={handleManageIntegration}
        onSelectAccount={handleSelectAccount}
        onSelectChannel={setSelectedChannel}
      />
    </ModalMenuUI>
  );
};
