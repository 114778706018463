import { Uploader } from '../../../wrapped-cube-client';
import { actionCreators as edgeActionCreators } from '../../ducks/resource-edges';
import { updateUpload } from './updateUpload';

/**
 * Start uploads after asset has been created and track progress
 * @param dispatch rootstore method
 * @param getState rootstore method
 */

export const handleFilesPostCreate =
  (dispatch, getState, idx) =>
  (params, resource, uploadIds, resetCreatedAt = true) => {
    params.files.forEach((f, idx) => {
      dispatch(
        edgeActionCreators.receiveResourceEdges(
          resource.id,
          params.resourceType,
          'file-uploads',
          'file-upload',
          [
            {
              id: uploadIds[idx],
              type: 'file-upload'
            }
          ]
        )
      );
    });

    const uploads = params.files.map((f, idx) => {
      const id = uploadIds[idx];

      // Create an Extended Tus Upload
      const upload = Uploader.resourceUpload(
        f.raw,
        {
          id,
          targetId: resource.id,
          targetType: params.resourceType,
          ancestorId: params.ancestorId,
          ancestorType: params.ancestorType,
          resumeContext: 'ancestor',
          workspace: getState().session.workspace,
          tusMetadata: f.metadata
        },
        {
          triggerReduxUpdate: (update = {}) => {
            updateUpload(id, update, dispatch, getState);
          },
          onProgress: (bytes, totalBytes) => {
            updateUpload(
              id,
              {
                progress: parseFloat(((bytes / totalBytes) * 100).toFixed(2)),
                failed: false,
                file: f.raw,
                display_name: f.name,
                tusUpload: upload.upload,
                active: true,
                asset_id: resource.id
              },
              dispatch,
              getState
            );
          },
          onError: (upload) => {
            updateUpload(
              id,
              {
                progress: 0,
                failed: true,
                file: f.raw,
                display_name: f.name,
                tusUpload: uploads[idx].upload,
                active: false,
                asset_id: resource.id
              },
              dispatch,
              getState
            );
          }
        },
        idx
      );

      if (resetCreatedAt) {
        updateUpload(
          id,
          {
            progress: 0,
            failed: false,
            file: f.raw,
            display_name: f.name,
            tusUpload: upload.upload,
            active: false,
            asset_id: resource.id,
            created_at: Date.now()
          },
          dispatch,
          getState
        );
      } else {
        updateUpload(
          id,
          {
            progress: 0,
            failed: false,
            file: f.raw,
            display_name: f.name,
            tusUpload: upload.upload,
            active: false,
            asset_id: resource.id
          },
          dispatch,
          getState
        );
      }

      return upload;
    });

    /** wait for all uploads connected to asset to finish (right now we only allow 1) */
    return Promise.all(uploads.map((u, idx) => u.promise))
      .then((results) => {
        params.files.forEach((f, idx) => {
          const id = uploadIds[idx];

          updateUpload(
            id,
            {
              progress: 100,
              failed: false,
              file: f.raw,
              display_name: f.name,
              tusUpload: uploads[idx].upload,
              active: false,
              asset_id: resource.id
            },
            dispatch,
            getState
          );
        });

        return Promise.resolve();
      })
      .catch((err) => {
        params.files.forEach((f, idx) => {
          const id = uploadIds[idx];
          updateUpload(
            id,
            {
              progress: 0,
              failed: true,
              file: f.raw,
              display_name: f.name,
              tusUpload: uploads[idx].upload,
              active: false,
              asset_id: resource.id
            },
            dispatch,
            getState
          );
        });
        return Promise.reject(err);
      });
  };
