import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { findMatchingTag } from './utils/findMatchingTag';
import { useTagCategories } from '../../../TagManagement/hooks/useTagCategories';

export const useTagMutations = ({
  targetResources,
  allTags,
  inputTags,
  toDelete
}) => {
  const [workspaceId] = useCurrentWorkspace();

  const [failed, setFailed] = useState(false);
  const [createTags, setCreateTags] = useState([]);
  const [mutationQueued, setMutationQueued] = useState(false);

  const [create, createStatus] = useCreateResource__ALPHA({
    resourceType: 'tag',
    ancestor: { type: 'workspace', id: workspaceId },
    relationship: 'tags',
    cacheInvalidator: useCallback(
      (r) => [{ type: 'workspace', id: workspaceId, relationship: 'tags' }],
      [workspaceId]
    )
  });

  const [mutate, mutateStatus] = useMutateRelationship__ALPHA({
    // ancestor: { type: 'content-tree-node', id: identifier.id },
    relationship: 'tags',
    cacheInvalidator: useCallback(
      (res, mut, anc, rel) => [
        { type: anc.type, id: anc.id, relationship: 'tags' },
        { type: 'asset', id: anc.id, relationship: 'tags' },
        { type: 'folder', id: anc.id, relationship: 'tags' },
        { type: 'workspace', id: workspaceId, relationship: 'tags' },
        { type: 'tag', id: res.id },
        { type: 'tag', id: res.id, relationship: 'nodes' } // used in DeleteTagModal
      ],
      []
    ),
    options: { optimize: true }
  });

  const { getTagsWithCategory } = useTagCategories();
  /** insert `category` display name here to make sure `findMatchingTag` to get the correct result
   * - does not contain AI tags
   */
  const allTagsWithCategoryName = useMemo(() => {
    return getTagsWithCategory(allTags.resources);
  }, [allTags.resources]);

  /** perform actions needed to persist the changes */
  useEffect(() => {
    if (createStatus === statuses.FAILED || mutateStatus === statuses.FAILED) {
      setFailed(true);
      return;
    }

    // do nothing if not in "commit mode"
    if (!mutationQueued) {
      return;
    }

    // initiate creation of new tags, if any are staged
    if (
      !createStatus ||
      (createStatus === statuses.SUCCESS && createTags.length)
    ) {
      const ct = createTags.map((t) =>
        t.category
          ? {
              type: 'tag' as const,
              text: t.text,
              color: t.color || '',
              relationships: {
                category: {
                  data: {
                    type: 'tag-category',
                    id: t.category.id
                  }
                }
              }
            }
          : { type: 'tag' as const, ...t }
      );
      create(ct);
      setCreateTags([]);
    }

    /** filter out AI tags */
    const aiTags = inputTags.filter((it) => it.category.editable === false);
    /** AI tags are excluded */
    const matchedEditableTags = inputTags.filter(
      (nt) =>
        // nt.category could be `undefined` if it's new tag
        !(nt.category.editable === false) &&
        findMatchingTag(allTagsWithCategoryName)(nt)
    );

    // if all tags have been created, start mutating the relationships
    if (
      createTags.length === 0 &&
      (!createStatus || createStatus === statuses.SUCCESS) &&
      (!mutateStatus || mutateStatus === statuses.SUCCESS) &&
      allTags.status !== statuses.IN_FLIGHT &&
      allTags.status !== statuses.INVALIDATED &&
      // NOTE: `workspace/tags` doesn't return AI tags by default, but `asset/tags` does
      aiTags.length + matchedEditableTags.length === inputTags.length
    ) {
      // for all target nodes add and or remove tags from relationships
      targetResources.forEach((t) => {
        mutate(
          matchedEditableTags.map((at) => {
            const r = findMatchingTag(allTagsWithCategoryName)(at) || at;
            return {
              ancestor: { id: t.id, type: 'content-tree-node' },
              resource: r,
              mutationType: 'add'
            };
          })
        );

        // AI tags are only deletable
        mutate(
          toDelete.map((nt) => {
            return {
              ancestor: { id: t.id, type: 'content-tree-node' },
              resource: { type: 'tag', id: nt.id },
              mutationType: 'remove'
            };
          })
        );
      });
      // switch back modes again
      setMutationQueued(false);
    }
  }, [
    targetResources,
    mutationQueued,
    allTags,
    inputTags,
    createTags,
    create,
    createStatus,
    mutate,
    toDelete,
    mutateStatus,
    setFailed
  ]);

  /** trigger committing the tag mutations to the api */
  const handleSave = useCallback(() => {
    // separate the tags that need to get created first
    const createTags = inputTags.filter(
      (nt) =>
        // nt.category could be `undefined` if it's new tag
        !(nt.category.editable === false) &&
        !findMatchingTag(allTagsWithCategoryName)(nt)
    );

    setCreateTags(createTags);
    // switch to commit mode
    setMutationQueued(true);
  }, [inputTags, setCreateTags, setMutationQueued, allTagsWithCategoryName]);

  return {
    setCreateTags,
    anyProcessing:
      !!mutationQueued ||
      mutateStatus === statuses.IN_FLIGHT ||
      createStatus === statuses.IN_FLIGHT,
    handleSave,
    saveSucceeded: mutateStatus === statuses.SUCCESS,
    saveFailed: failed
  };
};
