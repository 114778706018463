import React from 'react';
import { Typography } from '../typography/Typography';
import KeyValueRow, { KeyValueTable } from '../InfoPane/KeyValueRow';

interface Properties {
  /** The title of the metadata category  */
  title: string;

  formFields: {
    id: string;
    json_schema: string;
    display_name: string;
    value_type:
      | 'string'
      | 'number'
      | 'email'
      | 'date'
      | 'multiselect'
      | 'select'
      | 'textarea'
      | 'resource_picker'
      | 'waterfall_select';
    required?: boolean;
    /** Field display name for exports feature */
    system_name?: string;
    read_only?: boolean;
    uses_suggestions?: boolean;
  }[];
  values: {
    [key: string]: any;
  };
  emptyElement?: React.ReactNode;
  resolverComponent?: React.ComponentType<ResolverProps>;
}

export interface KeyValue {
  key: string;
  value: any;
}

export interface ResolverProps {
  dataType?: string;
  dataFormat?: string;
  keyValuePair: KeyValue;
}

/** Renders a series of `KeyValueRow` with `HumanReadableResolver` */
export const MetadataReadOnlyUI = (props: Properties) => {
  const { formFields, values, title, emptyElement, resolverComponent } = props;

  // only display fields that have a value.
  const filledFields = formFields.filter((field) => values[field.id]);
  const Resolver = resolverComponent || DefaultResolver;

  return (
    <div>
      <Typography typographyStyle={'heading2'}>{title}</Typography>
      <KeyValueTable>
        {filledFields.map((field) => {
          return (
            <KeyValueRow
              key={field.id}
              propertyKey={field.display_name}
              propertyValue={
                <Resolver
                  dataType={field.value_type}
                  dataFormat={JSON.parse(field.json_schema || null)?.format}
                  keyValuePair={{
                    key: field.display_name,
                    value: values[field.id]
                  }}
                />
              }
            />
          );
        })}
        {filledFields.length < 1 && (
          <tr>
            <td>
              <Typography typographyStyle={'body2'} color="contrast2">
                {emptyElement || `no ${title} metadata has been set`}
              </Typography>
            </td>
          </tr>
        )}
      </KeyValueTable>
    </div>
  );
};

const DefaultResolver: React.FunctionComponent<ResolverProps> = (props) => (
  <pre>{JSON.stringify(props, null, 2)}</pre>
);
