import { ns } from './config';

import { CookieCategory } from './state';

const GIVE_CONSENT = `${ns}/GIVE_CONSENT`;
const REJECT_CONSENT = `${ns}/REJECT_CONSENT`;
const CLEAR_CONSENT = `${ns}/CLEAR_CONSENT`;
const UPDATE_CONSENT_VERSION = `${ns}/UPDATE_CONSENT_VERSION`;
const SYNCHRONIZE_CONSENT = `${ns}/SYNCHRONIZE_CONSENT`;

export const actions = {
  GIVE_CONSENT,
  REJECT_CONSENT,
  CLEAR_CONSENT,
  UPDATE_CONSENT_VERSION,
  SYNCHRONIZE_CONSENT
} as const;

const meta = {
  middleware: {
    cookieConsent: {}
  }
};

const giveConsent = (categories: CookieCategory | CookieCategory[]) => {
  return {
    type: GIVE_CONSENT,
    payload: {
      categories: [].concat(categories)
    },
    meta
  };
};

const rejectConsent = (categories: CookieCategory | CookieCategory[]) => {
  return {
    type: REJECT_CONSENT,
    payload: {
      categories: [].concat(categories)
    },
    meta
  };
};

const clearConsent = (categories: CookieCategory | CookieCategory[]) => {
  return {
    type: CLEAR_CONSENT,
    payload: {
      categories: [].concat(categories)
    },
    meta
  };
};

const updateConsent = () => {
  return {
    type: UPDATE_CONSENT_VERSION,
    payload: {},
    meta
  };
};

const syncConsent = stateFromCookies => {
  return {
    type: SYNCHRONIZE_CONSENT,
    payload: stateFromCookies,
    meta
  };
};

export const actionCreators = {
  giveConsent,
  rejectConsent,
  clearConsent,
  updateConsent,
  syncConsent
};
