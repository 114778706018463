import {
  actions as requestStatusActions,
  actionCreators as requestStatusActionCreators
} from '../ducks/request-status';
import { uuidv4 } from '../../utils/uuid';

const inFlightCounter = {};
const errorCounter = {};
const errors = {};

export const addRequestStatusHash = (hash = uuidv4()) => action => {
  return {
    ...action,
    meta: {
      ...action.meta,
      requestStatus: {
        ...(action.meta && action.meta.requestStatus),
        hash
      }
    }
  };
};

export const delegateRequestStatusHash = delegatorAction => action => {
  if (delegatorAction.meta && delegatorAction.meta.requestStatus) {
    const { hash } = delegatorAction.meta.requestStatus;
    const { resourceType, resourceId } =
      action.payload || (action.meta && action.meta.apiClient);

    return {
      ...action,
      meta: {
        ...action.meta,
        requestStatus: {
          ...(action.meta && action.meta.requestStatus),
          delegatorHash: hash,
          resource: { resourceType, resourceId }
        }
      }
    };
  } else {
    return action;
  }
};

const removeHash = hash => {
  delete inFlightCounter[hash];
  delete errorCounter[hash];
  if (errors[hash]) {
    delete errors[hash];
  }
};

export const requestStatusMiddleware = ({ dispatch }) => next => action => {
  if (action.meta && action.meta.requestStatus) {
    const { delegatorHash, resource } = action.meta.requestStatus;
    if (delegatorHash && resource) {
      const handleAllDone = () => {
        if (!errorCounter[delegatorHash] || errorCounter[delegatorHash] === 0) {
          dispatch(
            requestStatusActionCreators.markSuccessByHash(delegatorHash)
          );
        } else {
          dispatch(
            requestStatusActionCreators.markFailedByHash(
              delegatorHash,
              errors[delegatorHash]
            )
          );
        }
        removeHash(delegatorHash);
      };

      switch (action.type) {
        case requestStatusActions.MARK_IN_FLIGHT:
          inFlightCounter[delegatorHash] =
            (inFlightCounter[delegatorHash] || 0) + 1;
          break;

        case requestStatusActions.MARK_SUCCESS:
          inFlightCounter[delegatorHash] = inFlightCounter[delegatorHash]
            ? inFlightCounter[delegatorHash] - 1
            : null;
          if (inFlightCounter[delegatorHash] === 0) {
            handleAllDone();
          }
          break;

        case requestStatusActions.MARK_FAILED:
          inFlightCounter[delegatorHash] = inFlightCounter[delegatorHash]
            ? inFlightCounter[delegatorHash] - 1
            : 0;
          errorCounter[delegatorHash] = (errorCounter[delegatorHash] || 0) + 1;
          if (action.error) {
            const hashErrors = errors[delegatorHash] || [];
            errors[delegatorHash] = hashErrors.concat(action.error);
          }
          if (inFlightCounter[delegatorHash] === 0) {
            handleAllDone();
          }
          break;

        default:
          break;
      }
    }
  }
  return next(action);
};

export default requestStatusMiddleware;
