import React, { HTMLAttributes, StyleHTMLAttributes } from 'react';

interface Props {
  style?: HTMLAttributes<'div'>['style'];
}

export const Padding: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, style = {} } = props;

  return <div style={{ padding: 16, ...style }}>{children}</div>;
};
