import * as React from 'react';
import { uniq } from 'underscore';

// redux
import { connect, useDispatch } from 'react-redux';
import { FieldArray } from 'redux-form';

// redux-form
import {
  reduxForm,
  getFormValues,
  InjectedFormProps,
  clearFields,
  destroy,
  arrayRemoveAll
} from 'redux-form';

// helpers
import { compose } from '../../../../../utils/component-helpers';

// main
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { EmailSuggestionsContainer } from '../../../../forms/EmailSuggestionContainer';

// state
import {
  FileRequest,
  Reviewlink,
  ShareIntentType
} from '@cube3/common/model/resource-types';
import { Share } from '@cube3/common/model/schema/resources/share';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';

// ui
import {
  EmailMessagebox,
  EmailMessageBoxFormProps
} from '@cube3/ui/src/Prefabs/Forms/EmailMessageBox';

import { InvitePeopleModalUI } from '@cube3/ui/src/Prefabs/Modals/Sharables/InvitePeopleModalUI';

import {
  isRequired,
  validRecipients,
  makeValidator,
  ComplexErrors
} from '../../../../forms/helpers/validators';
import { useModalActions } from '../../Modals/modalActions';
import {
  useEmailSuggestions,
  WithEmailSuggestionsProps
} from '../hooks/useEmailSuggestions';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { LinkSettingsFormProperties } from '@cube3/ui/src/Prefabs/shareLink/Forms/LinkSettingsForm';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

/// <Summary>
/// Functional component that retrieves the share for a resource (asset etc) and displays them
/// in a settings screen with options
/// </Summary>

export interface FormRecipient {
  email_address: string;
  id: string;
  full_name?: string;
  profile_picture?: string;
}

interface LinkSettingFields
  extends EmailMessageBoxFormProps,
    Partial<LinkSettingsFormProperties> {}

const validateShareLinkInvitePeople = (values, props) => {
  return {
    emails: makeValidator([isRequired, validRecipients])(
      values.emails,
      values,
      props,
      'emails'
    )
  };
};

const mapStateToProps = (state, ownProps: Properties) => {
  const values = getFormValues(ownProps.modalContext.id)(state);

  // define retrieved resource.
  const currentResource = ownProps.retrievedResource;

  const initialValues: LinkSettingFields = {
    message: currentResource?.message
  };

  return {
    form: ownProps.modalContext.id,
    initialValues: {
      ...ownProps.retrievedResource,
      ...initialValues
    },
    ...values
  };
};

interface HOCMappedProps extends WithEmailSuggestionsProps {
  mutateResource?: Function;
  mutateStatus: string;
  retrievedResource: Share | FileRequest | Reviewlink;
}

type Properties = HOCMappedProps &
  LinkSettingFields &
  ModalReceiverProps<{ id: string; intent: ShareIntentType }> &
  InjectedFormProps;

const InvitePeople: React.FunctionComponent<Properties> = (props) => {
  const {
    modalContext,
    // HOC
    retrievedResource,
    mutateStatus,
    mutateResource,
    emailSuggestions,
    clearEmailInput,
    loading,
    // form
    emailSearchInput,
    valid,
    message,
    emails
  } = props;

  const { id, intent } = modalContext;
  const { previousModal, openModal } = useModalActions();

  const [excludedEmails /*filteredEmails*/] = React.useMemo(() => {
    if (!emailSuggestions || !retrievedResource.email_addresses) {
      return [[], []];
    }
    const alreadyInShare = retrievedResource.email_addresses;

    const include = emailSuggestions.map(
      (email) => alreadyInShare.indexOf(email.email_address) < 0
    );
    return [
      emailSuggestions.filter((val, idx) => !include[idx]),
      emailSuggestions.filter((val, idx) => include[idx])
    ];
  }, [emailSuggestions, retrievedResource.email_addresses]);

  const dispatch = useDispatch();
  const closeModalHandler = React.useCallback(() => {
    dispatch(arrayRemoveAll(id, 'emails'));
    dispatch(clearFields(id, false, false, 'message'));
    dispatch(clearFields(id, false, false, 'emails'));
    previousModal();
  }, [dispatch, previousModal, arrayRemoveAll, clearFields, id]);

  const onClickSendLink = React.useCallback(() => {
    if (!emails?.length) return;
    const emailStrings = emails.map((element) => element.email_address);
    let mergedEmails: string[] = emailStrings.concat(
      retrievedResource?.email_addresses
    );
    // // since the input field does not filter any existing email addresses, we will now
    // // remove duplicate email addressses gotten from the share and newly entered emails
    mergedEmails = uniq(mergedEmails);
    mutateResource({
      type: 'share',
      id,
      email_addresses: mergedEmails,
      message: message
    });
  }, [emails, mutateResource, retrievedResource, message]);

  React.useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      openModal('confirm_link_sent', {
        token: retrievedResource.token,
        intent
      });
      dispatch(destroy(id));
    }
  }, [
    mutateStatus,
    openModal,
    retrievedResource,
    dispatch,
    destroy,
    id,
    intent
  ]);

  return (
    <InvitePeopleModalUI
      loading={loading}
      submitButtonDisabled={!valid}
      onClickSendLink={onClickSendLink}
      onCloseEvent={closeModalHandler}
      onClickCancel={closeModalHandler}
      emailBoxRenderProps={() => (
        <EmailMessagebox
          EmailFieldsComponent={
            <FieldArray
              name="emails"
              props={{
                emailSuggestions,
                clearEmailInput,
                form: id,
                emailSearchInput,
                excludedEmails
              }}
              component={EmailSuggestionsContainer}
            />
          }
          message={message}
        />
      )}
    />
  );
};

const withConnectedResource = (Wrapped) => (props) => {
  const { id: resourceId } = props.modalContext;

  const { resource: retrievedResource, loading } = useResource__ALPHA<'share'>({
    resourceType: 'share',
    resourceId
  });
  const [workspaceId] = useCurrentWorkspace();

  const [mutateResource, mutateStatus] = useMutateResource__ALPHA(
    React.useMemo(
      () => ({
        cacheInvalidator: (r) => [
          r,
          {
            type: 'workspace',
            id: workspaceId,
            relationship: 'shares'
          }
        ],
        actionDecorators: [
          addFormId(resourceId, {
            formState: undefined,
            useRequestStatus: true
          })
        ]
      }),
      [workspaceId]
    )
  );
  const emailSuggestionProps = useEmailSuggestions(props.emailSearchInput);

  return (
    <Wrapped
      {...props}
      {...emailSuggestionProps}
      loading={emailSuggestionProps.loading || !retrievedResource || loading}
      retrievedResource={retrievedResource}
      mutateResource={mutateResource}
      mutateStatus={mutateStatus}
    />
  );
};

export default compose(InvitePeople)(
  withConnectedResource,
  connect(mapStateToProps),
  reduxForm<LinkSettingFields, Properties, ComplexErrors>({
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate: validateShareLinkInvitePeople
  })
);
