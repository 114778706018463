import React, { useContext } from 'react';
// hooks
import { DrawingCanvasContext } from '../../contexts/annotationContexts';
import MaybeTooltip from '@cube3/ui/src/Tooltip/MaybeTooltip';
import { Button } from '@cube3/cubicle/src/core/atoms/Button/Button';

export const Reset = (props) => {
  const { sketchPad } = useContext(DrawingCanvasContext);

  const onClick = () => {
    sketchPad?.clear();
    sketchPad?.reset();
    props.onSave(sketchPad?.toSVG());
  };

  return (
    <>
      {sketchPad && (
        <MaybeTooltip title="Remove all strokes">
          <Button
            label="Clear"
            background="secondary"
            buttonStyle="ghost"
            onClick={onClick}
          />
        </MaybeTooltip>
      )}
    </>
  );
};
