import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';

/// <Summary>
/// container which centers an element.
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // backgroundColor: 'white',
      zIndex: 1300,
      position: 'fixed',
      // top: '50%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
      outline: 'initial !important'
    }
  })
);

const modalContainerContext = React.createContext<
  [HTMLElement, (node: HTMLElement) => void]
>([document.body, null]);

interface ModalManagerUIProps {}

const ModalManagerUI = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ModalManagerUIProps>
>((props, ref) => {
  const classes = useStyles();

  const setContainer = useSetModalContainer();
  const handleRef = useCallback(
    node => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
      setContainer(node);
    },
    [setContainer, ref]
  );

  return (
    <>
      <div className={classes.container} ref={handleRef} />
      <div className={classes.container}>{props.children}</div>
    </>
  );
});

export const ModalContainerProvider = ({ children }) => {
  return (
    <modalContainerContext.Provider value={useState(null)}>
      {children}
    </modalContainerContext.Provider>
  );
};

export const useModalContainer = () => {
  return useContext(modalContainerContext)[0];
};
export const useSetModalContainer = () => {
  return useContext(modalContainerContext)[1];
};

export default ModalManagerUI;
