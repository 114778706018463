import * as React from 'react';
/* UI */
import { ModalNoticeUI } from '../../../Modal/ModalNoticeUI';
import Button from '../../../Buttons/Button';
import { Typography } from '../../../typography/Typography';
import SupportEmailComponent from '../../../Email/SupportEmailComponent';

interface InviteMemberModalUIProps {
  onCloseEvent: () => void;
  loading: boolean;
}

function MembersMissingModal(props: InviteMemberModalUIProps) {
  const { onCloseEvent, loading } = props;

  return (
    <ModalNoticeUI
      loading={loading}
      title={'Missing role configurations'}
      onCloseEvent={() => onCloseEvent()}
      component="form" //important for forms
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            onClick={onCloseEvent}
          />
        </>
      }
    >
      <Typography color="contrast2" typographyStyle="body2">
        Workspace roles have not been configured. Please contact
        <SupportEmailComponent />
      </Typography>
    </ModalNoticeUI>
  );
}

export default MembersMissingModal;
