import { AssetMedia, AssetMimeTypes, AssetProcessingStates } from '../../types';
import { PublicResource, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Asset extends PublicResource, ResourceBase {
  duration: number;
  file_name: string;
  file_size: number;
  mime_type: AssetMimeTypes;
  processing_status: AssetProcessingStates;
  media: AssetMedia;
  relationships: {
    node: Related<'content-tree-node'>;
    tags: Related<'tag'>[];
    parent: Related<'content-tree-node'>;
    url: Related<'url'>;
  };
}

export const asset: RawModel = {
  type: 'asset',
  plural: 'assets',
  JSONApi: true,
  useWorker: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    display_image: 'URL',
    mime_type: 'string',
    file_name: 'string',
    duration: 'number',
    media: 'array'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'assets'
    },
    node: {
      type: 'content-tree-node',
      binding: ['one', 'one'],
      reverse: 'resource'
    },
    tags: {
      type: 'tag',
      binding: ['many', 'one'],
      reverse: 'assets'
    },
    parent: {
      type: 'content-tree-node',
      binding: ['one', 'many'],
      reverse: 'children'
    },
    url: {
      type: 'url',
      binding: ['one', 'one'],
      reverse: 'assets'
    }
  }
};
