import React, { useMemo } from 'react';
import {
  makeStyles,
  StyledComponentProps,
  createStyles,
  Theme
} from '@material-ui/core';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

import { SizeVariations } from '../../themes/custom-theme';

import { colorVariationsElements } from '../../themes/custom-theme';
import { iconPaths } from './paths';
import { iconFiles } from './files';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// ..
/// </Summary>

export type surfaces = 'onBase' | 'onBrand' | 'onDanger';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex'
      },
      small: {
        width: theme.sizes.icons.small,
        height: theme.sizes.icons.small
      },
      regular: {
        width: theme.sizes.icons.regular,
        height: theme.sizes.icons.regular
      },
      big: {
        width: theme.sizes.icons.big,
        height: theme.sizes.icons.big
      },
      svgStyling: {},
      // iconColor: ({ color = 'contrast1', surface = 'onBase' }: CallProps) => ({
      //   color: `${theme.customPalette.text[surface][color]}`
      // }),
      ...Object.keys(theme.customPalette.text).reduce((acc, val) => {
        Object.keys(theme.customPalette.text[val]).forEach((col) => {
          acc[`color-${val}-${col}`] = {
            color: `${theme.customPalette.text[val][col]}`
          };
        });
        return acc;
      }, {})
    }),
  { name: 'C3GeneralSVG' }
);

type SvgIconType = typeof SvgIcon;
interface CustomSvgIcon extends SvgIconType {}

interface ComponentProps {
  color?: colorVariationsElements;
  size?: SizeVariations;
  className?: string;
  secondPath?: string;
  thirdPath?: string;
  surface?: surfaces;
  svgProps?: SvgIconProps<CustomSvgIcon, { component?: CustomSvgIcon }>;
}

// interface for calling the component directly.
interface CallProps extends ComponentProps {
  path: string;
}

const GeneralSVG = React.memo((props: CallProps) => {
  const {
    path,
    className,
    secondPath,
    thirdPath,
    color = 'contrast1',
    surface = 'onBase',
    size = 'regular',
    svgProps
  } = props;
  const classes = useStyles(props);
  const { svgStyling } = classes;
  const iconColor = classes[`color-${surface}-${color}`];

  const p1 = path ? iconPaths[path] : undefined;
  const p2 = secondPath ? iconPaths[secondPath] : undefined;
  const p3 = thirdPath ? iconPaths[thirdPath] : undefined;

  // Try to detect if paths go outside default viewbox of 24 x 24
  const viewBox = useMemo(() => {
    const coords = [p1, p2, p3]
      .join(' ')
      .replace(/[a-zA-Z]/g, ' ')
      .split(/\s+/)
      .map((s) => parseFloat(s));
    if (coords.find((c) => c > 24)) {
      // console.warn(
      //   'coordinates outside of range... extending viewbox',
      //   path,
      //   secondPath,
      //   thirdPath
      // );
      return '0 0 32 32';
    }
  }, [p1, p2, p3]);

  if (iconFiles[path]) {
    const Icon = iconFiles[path];
    return (
      <SvgIcon
        classes={{
          root: `${classes[size]} ${iconColor} ${svgStyling} ${className}`
        }}
        {...svgProps}
        component={Icon}
      />
    );
  }

  return (
    <SvgIcon
      classes={{
        root: `${classes[size]} ${iconColor} ${svgStyling} ${className}`
      }}
      viewBox={viewBox}
      {...svgProps}
    >
      <path d={p1} />
      {p2 && <path d={p2} />}
      {p3 && <path d={p3} />}
    </SvgIcon>
  );
});

// common icons

export default GeneralSVG;
// use this interface when using a spread operator to spread these properties over another component using GeneralSVG.
export interface GeneralSVGProperties
  extends ComponentProps,
    StyledComponentProps {
  path?: string;
}
