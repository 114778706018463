import React from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorText from './ErrorText';

function formatHHMMSS(number: number) {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number;
  }
}

function formatMilliseconds(number: number) {
  number = Math.round(number);
  if (number < 10) {
    return `00${number}`;
  } else if (number < 100) {
    return `0${number}`;
  } else {
    return `${number}`;
  }
}

interface DurationProps {
  toolTip: boolean;
  duration: string;
  placement: any;
}

const formatTimestamp = (h: number, m: number, s: number, ms: number) => {
  if (h >= 1) {
    if (m === 0) return `${h} hr`;
    if (m < 59) {
      return s >= 30
        ? `${h}:${formatHHMMSS(m + 1)} hr`
        : `${h}:${formatHHMMSS(m)} hr`;
    }
    if (m === 59) {
      return s >= 30 ? `${h + 1} hr` : `${h}:${formatHHMMSS(m)} hr`;
    }
  } else if (m >= 1) {
    if (s === 0) return `${m} min`;
    if (s < 59)
      return ms >= 500
        ? `${m}:${formatHHMMSS(s + 1)} min`
        : `${m}:${formatHHMMSS(s)} min`;
    if (s === 59)
      return ms >= 500
        ? m + 1 === 60
          ? '1 hr'
          : `${m + 1} min`
        : `${m}:${formatHHMMSS(s)} min`;
  } else if (s >= 1) {
    if (s < 59) return ms >= 500 ? `${s + 1} sec` : `${s} sec`;
    if (s === 59) return ms >= 500 ? `${m + 1} min` : `${s} sec`;
  } else {
    return ms + ' ms';
  }
};
// console.log('----------- hr -------------');
// console.log('02:5:29.000 - ', formatTimestamp(2, 5, 29, 0));
// console.log('02:5:30.000 - ', formatTimestamp(2, 5, 30, 0));
// console.log('01:58:30.000 - ', formatTimestamp(1, 58, 30, 0));
// console.log('01:59:29.500 - ', formatTimestamp(1, 59, 29, 500));
// console.log('01:59:59.500 - ', formatTimestamp(1, 59, 59, 500));
// console.log('01:59:59.499 - ', formatTimestamp(1, 59, 59, 499));
// console.log('01:05:00.500 - ', formatTimestamp(1, 5, 0, 500));
// console.log('----------- min -------------');
// console.log('00:05:00.500 - ', formatTimestamp(0, 5, 0, 500));
// console.log('00:05:05.500 - ', formatTimestamp(0, 5, 5, 500));
// console.log('00:01:30.400 - ', formatTimestamp(0, 1, 30, 400));
// console.log('00:01:30.500 - ', formatTimestamp(0, 1, 30, 500));
// console.log('00:50:50.500 - ', formatTimestamp(0, 50, 50, 500));
// console.log('00:59:59.400 - ', formatTimestamp(0, 59, 59, 400));
// console.log('00:59:59.500 - ', formatTimestamp(0, 59, 59, 500));
// console.log('----------- sec -------------');
// console.log('00:00:53.215 - ', formatTimestamp(0, 0, 53, 215));
// console.log('00:00:53.715 - ', formatTimestamp(0, 0, 53, 715));
// console.log('----------- ms -------------');
// console.log('00:00:0.777 - ', formatTimestamp(0, 0, 0, 777));

export default function Duration(props: DurationProps) {
  const { placement, toolTip, duration } = props;
  const durationInteger = parseFloat(duration);
  const momentDurationObject = moment.duration(durationInteger, 'milliseconds');

  if (momentDurationObject.days() > 0 || !durationInteger) {
    return <ErrorText errorText="Duration unavailable" />;
  }

  const hours = momentDurationObject.hours();
  const minutes = momentDurationObject.minutes();
  const seconds = momentDurationObject.seconds();
  const milliseconds = momentDurationObject.milliseconds();

  const timestamp = `${formatHHMMSS(hours)}:${formatHHMMSS(
    minutes
  )}:${formatHHMMSS(seconds)}.${formatMilliseconds(milliseconds)}`;

  return (
    <Tooltip
      title={timestamp}
      placement={placement}
      disableFocusListener={true}
      disableTouchListener={true}
      disableHoverListener={!toolTip}
    >
      <span>{formatTimestamp(hours, minutes, seconds, milliseconds)}</span>
    </Tooltip>
  );
}
