import { StatusColor } from './StatusBlip';

interface Expirable {
  expires?: boolean;
  expires_at?: string; // iso timestamp
  starts_at?: string; // iso timestamp
  expires_soon?: boolean;
  extended?: boolean;
}

export const getStatusColor = (
  expirable: Expirable,
  EXPIRES_SOON_OFFSET_DAY = 31
): StatusColor => {
  if (expirable.expires && new Date(expirable.starts_at) > new Date()) {
    return 'neutral';
  }

  if (expirable.expires && new Date(expirable.expires_at) < new Date()) {
    return 'danger';
  }

  if (
    expirable.expires_soon !== undefined
      ? expirable.expires_soon
      : expirable.expires &&
        new Date(expirable.expires_at) <
          new Date(Date.now() + 1000 * 60 * 60 * 24 * EXPIRES_SOON_OFFSET_DAY)
  ) {
    return 'warning';
  }

  if (expirable.extended) {
    return 'cool';
  }

  return 'success';
};

export const getStatusTooltip = (color: StatusColor) => {
  switch (color) {
    case 'warning':
      return 'Expires soon ';
    case 'cool':
      return 'Active';
    case 'danger':
      return 'Expired';
    case 'neutral':
      return 'Not yet started';
    default:
      return 'Active';
  }
};
