import { createSlice } from '../../utils/createSlice';
import { actions } from './actions';
import { State, initialState } from './state';
import { ns } from './config';

const { slice } = createSlice<State, typeof ns>(() => {
  return {
    handlers: {
      /**
       *  open a modal by name
       */
      [actions.OPEN_MODAL]: (
        draft,
        { payload: { page, resource, hideBelow } }
      ) => {
        draft.modalStack.push({ modal: page, resource, hideBelow });
      },
      /**
       * close all modals
       */
      [actions.CLOSE_MODAL]: draft => {
        draft.modalStack = [];
      },
      /**
       *  close one modal and maybe replace one
       */
      // TODO figure out what this is used for
      [actions.PREVIOUS_PAGE]: (draft, action) => {
        draft.modalStack = draft.modalStack.slice(0, -1);
        const modal = draft.modalStack[draft.modalStack.length - 1];
        if (modal && action.payload.resource) {
          const newCurrentModalObject = {
            ...modal,
            resource:
              typeof modal.resource === 'object'
                ? { ...modal.resource, ...action.payload.resource }
                : action.payload.resource
          };
          draft.modalStack[draft.modalStack.length - 1] = newCurrentModalObject;
        }
      },
      /**
       *  Close N amount of modals
       */
      [actions.GO_BACK_PAGES]: (draft, { payload: { amountOfPages } }) => {
        draft.modalStack = draft.modalStack.slice(0, -amountOfPages);
      },

      /**
       * block modal from closing
       */
      [actions.BLOCK_MODAL]: draft => {
        draft.blocked = true;
        draft.snapshot = [...draft.modalStack];
      },

      /**
       * unblock modal from closing
       */
      [actions.UNBLOCK_MODAL]: (draft, { payload: { revertToSnapshot } }) => {
        draft.blocked = false;

        if (revertToSnapshot) {
          draft.modalStack = [...draft.snapshot];
        }

        draft.snapshot = [];
      }
    },
    initialState,
    namespace: ns
  };
});

export const reducer = slice[ns];
