import React from 'react';
import { makeStyles, Theme, createStyles, Tooltip } from '@material-ui/core';
import MuiTab, { TabProps } from '@material-ui/core/Tab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      textTransform: 'initial',
      minWidth: 72,
      marginRight: theme.spacing(0.5),
      fontFamily: theme.typography.fontFamily,
      ...theme.typographyStyles.body2,
      fontWeight: 'bold',
      '&$tabSelected': {
        color: theme.customPalette.secondary.main
      }
    },
    tabSelected: {}
  })
);

interface Props extends TabProps {
  disabledReason?: string;
}

export const Tab: React.FunctionComponent<Props> = props => {
  const { disabledReason, disabled, ...muiProps } = props;
  const classes = useStyles(props);

  return (
    <Tooltip
      title={disabledReason}
      disableHoverListener={!disabled || !disabledReason}
    >
      <span>
        <MuiTab
          disabled={disabled}
          {...muiProps}
          disableRipple={true}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected
          }}
        ></MuiTab>
      </span>
    </Tooltip>
  );
};
