import { apiRoot } from '../../config';
import {
  actionCreators,
  actions as requestActions
} from '../ducks/request-status';
import { environment } from '../../utils/environment';
import { OfflineDetection, urlCheck } from '../../utils/OfflineDetection';
import { actions } from '../ducks/offline-detection';
import { hasError } from '../../wrapped-cube-client';

let offline = false;

const apiActions = /(:?cube3\/resource-edges|cube3\/resource-nodes|cube3-client\/session|cube3\/clipboard|CUBE3\/CONTRACT_SUBMIT_MIDDLEWARE)/;

const buffer = [];

export const detector = new OfflineDetection({
  pollInterval: environment === 'development' ? 10_000 : 60_000,
  checks: [urlCheck({ url: '/' }), urlCheck({ url: `${apiRoot}` })]
});

const offlineDetectionMiddleware = ({ getState, dispatch }) => {
  detector.subscribe((event, state) => {
    dispatch({
      type: state.online ? actions.ONLINE : actions.OFFLINE,
      payload: state
    });
  });

  // Promise.resolve(1).then(() =>
  //   dispatch({
  //     type: actions.ONLINE,
  //     payload: {
  //       online: true,
  //       lastOnline: Date.now()
  //     }
  //   })
  // );

  return next => action => {
    if (action.type === actions.CHECK_OFFLINE) {
      detector.checkOnline();
    }

    if (action.type === requestActions.MARK_FAILED) {
      if (
        hasError(action.error, 'unknown server error') ||
        hasError(action.error, 'Bad gateway')
      ) {
        detector.checkOnline().then(online => {
          if (!online) {
            dispatch(actionCreators.markInFlight(action.payload));

            buffer.push(action.payload);
          } else {
            next(action);
          }
        });
        return;
      }
    }

    if (action.type === actions.OFFLINE) {
      offline = true;
      return next(action);
    }

    if (action.type === actions.ONLINE) {
      offline = false;

      buffer.forEach(a => {
        dispatch(actionCreators.markUnknown(a));
      });

      buffer.length = 0;

      return next(action);
    }

    if (apiActions.test(action.type) && offline) {
      console.warn(
        'Dropping action because app has no connection to api',
        action
      );
      dispatch(actionCreators.markInFlight(action));
      buffer.push(action);

      return;
    }

    // Call the next dispatch method in the middleware chain.
    let returnValue = next(action);

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
};

export default offlineDetectionMiddleware;
