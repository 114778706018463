import { getEnvVar } from '../../utils/getEnvVar';
import { environment } from '@cube3/state/src/utils/environment';

const versionExp = new RegExp(/.*?(__html_version__[^<]*?)[<]/, 'm');

export const APP_SERVED_VERSION_UNKNOWN = 'APP_SERVED_VERSION_UNKNOWN';
export const APP_VERSION_DIFFERENCE = 'APP_VERSION_DIFFERENCE';

// make sure we get fresh data
var headers = new Headers();
headers.append('pragma', 'no-cache');
headers.append('cache-control', 'no-cache');

export const getServedVersion = () => {
  // no version hash available in dev server
  if (environment === 'development') {
    return Promise.resolve('development');
  }

  // load the index.html which includes a version hash
  return fetch('/', { headers })
    .then((res) => res.text())
    .then((html) => html.match(versionExp)?.[1].split('=')[1].split(/['"]/)[1]);
};

export const getRunningVersion = () => {
  if (environment === 'development') {
    return 'development';
  }
  // get version hash that was set when the app initialized
  return getEnvVar('RELEASE_VERSION');
};

// check if running version matches served version
export const verifyRunningVersion = () => {
  return (
    getServedVersion()
      // for testing
      // .then(v => Math.random())
      .catch((err) => {
        return Promise.reject(new Error(APP_SERVED_VERSION_UNKNOWN));
      })
      .then((version) => {
        if (version !== getRunningVersion()) {
          return Promise.reject(new Error(APP_VERSION_DIFFERENCE));
        }
        return version;
      })
  );
};
