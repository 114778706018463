import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { CircularProgress } from '../../Progress/CircularProgress';

// ui
import { ModalMenuUI } from '../../Modal/ModalMenuUI';
import Button from '../../Buttons/Button';

/**
 * @summary: Add project members UI
 * @author Simon
 * @description:
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    buttonMargin: {
      marginRight: '10px'
    },
    circularProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    }
  }));

interface Properties {
  submitButtonDisabled: boolean;
  onCloseEvent: () => void;
  onSubmit: (ev: React.FormEvent) => void;
  accountBoxRenderer: React.ReactElement;
  loading: boolean;
}

const AddProjectMembersModalUI: React.FC<Properties> = props => {
  const {
    onCloseEvent,
    submitButtonDisabled,
    onSubmit,
    accountBoxRenderer,
    loading
  } = props;

  const classes = useStyles();

  return (
    <ModalMenuUI
      title={'Add people'}
      multiplyContentSpace={7}
      onCloseEvent={onCloseEvent}
      component="form"
      extraComponentProps={{ onSubmit }}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={onCloseEvent}
          />
          <Button
            text={'Add'}
            extraButtonProperties={{ type: 'submit' }}
            colorVariant={'filled1'}
            disabled={submitButtonDisabled}
          />
        </>
      }
    >
      {accountBoxRenderer}
      {loading && (
        <CircularProgress classes={{ root: classes.circularProgress }} />
      )}
    </ModalMenuUI>
  );
};

export default AddProjectMembersModalUI;
