import React from 'react';
import { createContext, useState } from 'react';
import { PanZoomWheelOptions, usePanZoom } from './hooks/usePanZoom';

const MIN_ZOOM_DEFAULT = -1;
const MAX_ZOOM_DEFAULT = 2;
interface PanZoomContextType {
  //setComponents: ({ wrapper, content }) => void;
  //zoom
  zoom: number;
  zoomIn: () => void;
  zoomOut: () => void;
  zoomReset: () => void;

  wrapperRef;
  panRef;
  zoomRef;
  canDrag;
  onDragStart;
  minZoom;
  maxZoom: number;

  canZoomIn: boolean;
  canZoomOut: boolean;
  canFitZoom: boolean;
  // pan
  //   positionState: { x: number; y: number };
  //   setPositionState: (state) => void;
  //   isPanningAllowed: boolean;
  //   onPanningStart: (event: React.MouseEvent<HTMLElement>) => void;
}

interface PanZoomComponents {
  wrapper: HTMLDivElement;
  content: HTMLDivElement;
}

interface Props {
  min?: number;
  max?: number;
  useWheel?: PanZoomWheelOptions;
}

export const PanZoomContext = createContext<PanZoomContextType>(null);

export const PanZoomProvider: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    min = MIN_ZOOM_DEFAULT,
    max = MAX_ZOOM_DEFAULT,
    useWheel = false
  } = props;

  const [fitZoom, setFitZoom] = useState(null);

  const [zoomLevel, setZoomLevel] = useState(0);

  const zoomIn = React.useCallback(() => {
    setZoomLevel(Math.min(zoomLevel + 1, max));
  }, [zoomLevel, max]);

  const zoomOut = React.useCallback(() => {
    setZoomLevel(Math.max(zoomLevel - 1, min));
  }, [zoomLevel, min]);

  const zoomReset = React.useCallback(() => {
    setZoomLevel(0);
  }, [setZoomLevel]);

  const {
    zoom,
    wrapperRef,
    panRef,
    zoomRef,
    canDrag,
    isZooming,
    onDragStart
  } = usePanZoom(
    fitZoom ? fitZoom * Math.pow(1.5, zoomLevel) : 1,
    setFitZoom,
    useWheel
  );

  return (
    <PanZoomContext.Provider
      value={{
        minZoom: min,
        maxZoom: max,
        zoom: zoom,
        wrapperRef,
        panRef,
        zoomRef,
        canDrag: !isZooming && canDrag,
        onDragStart,
        zoomIn,
        zoomOut,
        zoomReset,
        canZoomIn: !isZooming && zoomLevel < max,
        canZoomOut: !isZooming && zoomLevel > min,
        canFitZoom: !isZooming && zoomLevel !== 0
      }}
    >
      {props.children}
    </PanZoomContext.Provider>
  );
};
