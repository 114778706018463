import { PublicResource } from '../../resource-types';
import { RawModel } from '../schema';

export interface Properties extends PublicResource {
  fields: { key: string; value: string }[];
  source: 'ffprobe';
}

export const properties: RawModel = {
  type: 'properties',
  plural: 'properties',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
