import React, { useMemo } from 'react';
import GeneralSVG from '../../icons/GeneralSVG';

const ProgressBadgeRenderer = ({ form, allRequiredFields = 4 }) => {
  const mappedErrors = useMemo(() => {
    if (!form) return;
    /** errors because doesn’t match the field type */
    let errorsCount = 0;
    /** errors because the field is empty */
    let emptyFieldsCount = 0;
    let allValid = true;

    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        const value = form[key];
        const error = value._error || value;

        if (error.includes('Field can not be empty')) {
          emptyFieldsCount += 1;
        } else {
          errorsCount += 1;
        }
        allValid = false;
      }
    }
    return {
      emptyFieldsCount,
      errorsCount,
      allValid
    };
  }, [form]);

  const path = (() => {
    switch (true) {
      case mappedErrors.allValid:
        return 'progressDone';
      case !!mappedErrors.errorsCount:
        return 'progressError';
      case mappedErrors.emptyFieldsCount === allRequiredFields:
        return 'progressZero';
      case mappedErrors.emptyFieldsCount < 2:
        return 'progressTwoThirds';
      default:
        return 'progressOneThird';
    }
  })();

  return <GeneralSVG path={path} />;
};

export default ProgressBadgeRenderer;
