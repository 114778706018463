import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { useTagActions } from '../hooks/useTagActions';
import Cube3TextField from '@cube3/ui/src/forms/textfields/Cube3TextField';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { getErrorMessage } from '../../../../forms/helpers/errors';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';

export const RenameTagCategoryModal = (props) => {
  const { category } = props.modalContext;
  const [input, setInput] = useState(category?.display_name);

  const { closeAllModals } = useModalActions();
  const { onRenameTagCategory, mutateResourceStatus, mutateError } =
    useTagActions();

  const [workspaceId] = useCurrentWorkspace();

  const { resources: categories, loading } = useResourceList__ALPHA(
    useMemo(
      () => ({
        edgeType: 'tag-category',
        edgeLabel: 'tag-categories',
        params: {
          filter: {
            workspace: workspaceId
          }
        }
      }),
      [workspaceId]
    )
  );

  const onChange = useCallback(
    (e) => {
      setInput(e.target.value);
    },
    [setInput]
  );

  const onSave = useCallback(() => {
    if (!category) return;
    onRenameTagCategory(category.id, input);
  }, [category, input, onRenameTagCategory]);

  useEffect(() => {
    if (mutateResourceStatus === statuses.SUCCESS) {
      closeAllModals();
    }
  }, [closeAllModals, mutateResourceStatus]);

  const dirty = category?.display_name !== input;
  const hasConflict = !!categories
    ?.filter((c) => c.id !== category?.id)
    .find((c) => c.display_name.toLowerCase() === input.toLowerCase());

  const error = hasConflict
    ? 'This name is already in use'
    : mutateError
    ? getErrorMessage(mutateError)
    : undefined;

  return (
    <ModalNoticeUI
      title={'Rename tag category'}
      onCloseEvent={closeAllModals}
      loading={loading || mutateResourceStatus === statuses.IN_FLIGHT}
      footerRightComponent={
        <>
          <Button
            label="Cancel"
            onClick={closeAllModals}
            background="secondary"
            buttonStyle="ghost"
          />
          <Button
            label={!dirty ? 'Done' : 'Save'}
            disabled={!dirty || error}
            background="primary"
            buttonStyle="solid"
            onClick={onSave}
          />
        </>
      }
    >
      <Cube3TextField
        value={input}
        onChange={onChange}
        placeholder="Rename"
        error={error}
        fullWidth={true}
      />
      <Typography customColor="#da3143">{error}</Typography>
    </ModalNoticeUI>
  );
};
