import React from 'react';
import ErrorText from './ErrorText';
import * as  db from 'mime-db'; // https://www.npmjs.com/package/mime-db

/// @Author : Simon @ AmbassadorsLAB
/// <Summary>
/// Converts mimetype information into human readable info. Falls back to 'Unknown filetype' if fails
/// Mimetypes for reference :
/// http://www.iana.org/assignments/media-types/media-types.xhtml
/// </Summary>

// define props that can be given to the component.
interface FileTypeProps {
  mimeType: string;
}

class FileType extends React.PureComponent<FileTypeProps> {
  // returns audio / video etc. The base of the file. If not found, returns empty string.
  GetBaseFileType(): string {
    // get the mimetype from the given props and convert it to lower case since are extensions
    // that we look for are all in lower case (will mismatch if not)
    var rawMimeType = this.props.mimeType.toLowerCase();

    // define our supported prefixes that we would like to show the name of
    var supportedPrefixesArray: string[] = [
      'font',
      'image',
      'audio',
      'text',
      'video'
    ];

    // split the string into 2 parts: Prefix and suffix. / will be deleted.
    var seperatedStringArray = rawMimeType.split('/');

    // try to get the index of one of the supported prefixes, if does not exists, returns -1
    var resultingIndex: number = supportedPrefixesArray.indexOf(
      seperatedStringArray[0]
    );
    var resultingPrefix: string;

    if (resultingIndex === -1) {
      resultingPrefix = '';
    } else {
      resultingPrefix = supportedPrefixesArray[resultingIndex];
    }

    return resultingPrefix;
  }

  // returns mp4 / ogg etc based on mimetype database call. If not found, returns empty string.
  GetFileExtension(): string {
    // get the mimetype info from our imported database.
    var dbInfo = db[this.props.mimeType];

    // define empty fallback string if no extension will be found in the database.
    var resultingExtension = '';

    // check if the is in the result and if info has
    // extensions property because it not always returns an extension
    if (dbInfo && dbInfo.extensions) {
      if (dbInfo.extensions.length > 0) {
        // try to get the extension with the imported information.
        resultingExtension = dbInfo.extensions[0];
      }
    }

    return resultingExtension.toUpperCase();
  }

  // Returns prefix + base. if base is an unkown type we do not want to display, convert it into File.
  GetHumanFileType(): string {
    var prefix = this.GetFileExtension();
    var suffix = this.GetBaseFileType();

    // if we cannot find the name in de dbinfo
    if (suffix === '' && prefix === '') {
      return '';
    }

    // Since base can be empty because convert it into file if empty
    if (suffix === '') {
      suffix = 'File';
    }

    return prefix + ' ' + suffix;
  }

  render() {
    // gets human readable filetype from mimeType.
    var humanReadableFileType = this.GetHumanFileType();

    // if the file extension operation was unsuccesfull (empty), render an error component.
    if (humanReadableFileType === '') {
      return <ErrorText errorText="Type unavailable" />;
    }

    // if everything went well, render the resulting filetype.
    return <span>{humanReadableFileType}</span>;
  }
}

export default FileType;
