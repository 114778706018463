import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme =>
  createStyles({
    mobile: {},
    root: (props: Properties) => ({
      position: 'absolute',
      backgroundColor: '#111',
      display: 'flex',
      flexFlow: 'column wrap',
      width: props.width ? props.width : 'auto',
      height: props.height ? props.height : 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      left: 0,
      top: 0
    })
  })
);

interface Properties {
  height?: string | number;
  width?: string | number;
}

const ContainerPreview: React.FC<React.PropsWithChildren<Properties>> = props => {
  const classes = useStyles(props);

  return (
    <div
      className={isMobile ? classes.mobile : classes.root}
      data-cy="preview-container"
    >
      {props.children}
    </div>
  );
};

export default ContainerPreview;
