import React from 'react';
// components
import Brushes, { BrushesRecievedProps } from './Brushes';
import { Reset } from './Reset';
import { RedoUndo } from './RedoUndo';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';

interface Props extends BrushesRecievedProps {
  // sketchPad: SketchPad;
  onSave?: (svg) => void;
  closeDrawingBar: () => void;
}

// TODO: make it draggable?
const DrawingToolbar = React.memo((props: Props) => {
  /**
   * TODO:
   * the toolbar design/styling will ultimately depend on how we choose to implement it within the Preview container
   */

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 16,
        left: ' 50%',
        transform: 'translateX(-50%)',
        padding: '4px 8px',
        borderRadius: 16,
        background: 'rgb(28, 28, 28)', // theme.color.background.neutral
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        border: '1px solid rgba(255,255,255,0.04)',
        gap: '8px'
      }}
      data-pw="drawing-tool-bar"
    >
      <RedoUndo type={'undo'} onSave={props.onSave} />
      <RedoUndo type={'redo'} onSave={props.onSave} />
      <Brushes {...props} />
      <Reset onSave={props.onSave} />
      <Button
        label="Cancel"
        iconRight="close"
        background="secondary"
        buttonStyle="ghost"
        onClick={props.closeDrawingBar}
      />
    </div>
  );
});

export default DrawingToolbar;
