import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import Scrollbar from '../Scrollbar/Scrollbar';
import { Tab } from './Tab';
import { Tabs } from './Tabs';
import { TabsProvider } from './tabsContext';
import { useTabs } from './hooks/useTabs';
import { useClassName } from '../utils/useClassName';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      display: 'flex',
      flexFlow: 'column nowrap',
      height: '100%'
    },
    buttonWrapper: {
      flexGrow: 0,
      flexShrink: 0
    },
    marginCollapse: {
      marginLeft: -12,
      marginRight: -12
    },
    listWrapper: {
      flexGrow: 1,
      flexShrink: 1
    },
    autoHeight: {
      height: 500
    }
  })
);

export interface TabConfig {
  label: string;
  disabled?: boolean;
  disabledReason?: string;
  visible?: boolean;
  id?: string;
}

interface Props {
  tabs: TabConfig[];
  onTabChange?(
    val: TabConfig,
    idx: number,
    methods: { cancel(): void; resume(): void }
  ): void;
  initialTab?: TabConfig;
  autoHeightScroll?: boolean;
  marginCollapse?: boolean;
  activeTab?: TabConfig;
}

export const TabsLayout: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    tabs,
    initialTab,
    onTabChange,
    children,
    autoHeightScroll = true,
    marginCollapse = false,
    activeTab: controlledActiveTab = undefined
  } = props;
  const classes = useStyles(props);

  const { activeTab, handleTabChange, actionCallback } = useTabs({
    tabs,
    initialTab: initialTab || controlledActiveTab,
    onTabChange
  });

  useEffect(() => {
    if (controlledActiveTab && tabs[activeTab] !== controlledActiveTab) {
      const index = tabs.findIndex(
        t =>
          t.id === controlledActiveTab.id ||
          t.label === controlledActiveTab.label
      );
      if (!tabs[index]) {
        return;
      }

      handleTabChange(null, index);
    }
  }, [tabs, activeTab, controlledActiveTab, handleTabChange]);

  return (
    <TabsProvider active={tabs[activeTab]}>
      <div className={classes.root}>
        <div
          className={useClassName(
            classes.buttonWrapper,
            marginCollapse && classes.marginCollapse
          )}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            action={actionCallback}
          >
            {tabs.map((t, idx) => {
              return (
                t.visible !== false && (
                  <Tab
                    key={t.label}
                    label={t.label}
                    disabled={t.disabled}
                    disabledReason={t.disabledReason || ''}
                    value={idx}
                  />
                )
              );
            })}
          </Tabs>
        </div>
        <div className={classes.listWrapper}>
          <Scrollbar
            autoHeight={autoHeightScroll}
            autoHeightMin={300}
            autoHeightMax={700}
          >
            <div className={autoHeightScroll ? classes.autoHeight : undefined}>
              {children}
            </div>
          </Scrollbar>
        </div>
      </div>
    </TabsProvider>
  );
};
