import { UploadShape } from './extendedupload';
import { UrlStorage } from './urlStorage';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';

/** Default config used when creating a new uploader instance */
export const defaultConfig: Config = {
  endpoint: '/uploads/',
  retryDelays: [0, 5000],
  metadata: {
    uploadOrigin: 'cube3-frontend'
  },
  // chunkSize: environment === 'development' ? 128 * 1024 : 64 * 1024 * 1024,
  chunkSize: Infinity,
  onError: function (upload, error: Error) {
    // eslint-disable-next-line
    console.log('Failed because: ' + error, upload);
  },
  onProgress: function (bytesUploaded: number, bytesTotal: number) {
    // var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
    // eslint-disable-next-line
    //console.log(bytesUploaded, bytesTotal, percentage + '%');
  },
  onSuccess: function (upload) {
    // eslint-disable-next-line
    //console.log('Download %s from %s', upload.file.name, upload.url);
  },
  maxInProgressUploads: 2,
  resume: true,
  keepCompletedDays: 7
};

export interface Config {
  chunkSize?: number;
  endpoint: string;
  retryDelays: number[];
  removeFingerprintOnSuccess?: boolean;
  onError(upload: UploadShape, err: Error): void;
  onProgress(bytesUploaded: number, bytesTotal: number): void;
  onSuccess(upload: UploadShape): void;
  maxInProgressUploads: number;
  resume: boolean;
  keepCompletedDays: number;
  metadata?: {};
  fingerprint?(fingerprintFile, options): Promise<string>;
  urlStorage?: UrlStorage;
  triggerReduxUpdate?(update?: Partial<Upload>): void;
}

export interface OptionalConfig {
  metadata?: {};
  chunkSize?: number;
  endpoint?: string;
  retryDelays?: number[];
  removeFingerprintOnSuccess?: boolean;
  onError?(upload: UploadShape, err: Error): void;
  onProgress?(bytesUploaded: number, bytesTotal: number): void;
  onSuccess?(upload: UploadShape): void;
  fingerprint?(fingerprintFile, options): Promise<string>;
  maxInProgressUploads?: number;
  resume?: boolean;
  keepCompletedDays?: number;
  urlStorage?: UrlStorage;
  triggerReduxUpdate?(update?: Partial<Upload>): void;
}
