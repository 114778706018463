import * as React from 'react';

// extend this interface on your component that you are decorating
export interface CustomFadeProperties {
  focus?: () => void;
}

export interface CustomFadeProps {
  fullscreen: boolean;
  playing?: boolean;
  mainContainerRef: HTMLDivElement;
}

export interface CustomFadeState {
  in: boolean;
  lock: boolean;
}

const customFadeWrapper = <P extends {}>(
  WrappedComponent: React.ComponentType<P>
) =>
  class CustomFade extends React.PureComponent<
    P & CustomFadeProps,
    CustomFadeState
  > {
    fadeOutTimeout;
    state = {
      in: true,
      lock: false
    };

    componentDidMount() {
      this.handleFade(4000);
      this.props.mainContainerRef?.addEventListener(
        'mousemove',
        this.handleMouseMove
      );
    }

    componentDidUpdate(prevProps: CustomFadeProps) {
      const { playing } = this.props;
      if (prevProps.playing !== playing) {
        if (playing) {
          this.handleFade();
        }
      }
    }

    handleMouseMove = () => {
      clearTimeout(this.fadeOutTimeout);
      this.handleFade();
      if (!this.state.in) {
        this.setState({
          in: true
        });
      }
    };

    handleMouseMoveControls = () => {
      if (this.state.in) {
        clearTimeout(this.fadeOutTimeout);
      } else {
        this.setState({ in: true });
      }
      this.handleFade();
    };

    handleFade = (time = 3000) => {
      this.fadeOutTimeout = setTimeout(() => {
        this.setState({
          in: false
        });
        /* eslint-disable-next-line */
      }, time);
    };

    lock = () => {
      this.setState({ lock: true, in: true });
    };

    unLock = () => {
      clearTimeout(this.fadeOutTimeout);
      this.setState({ lock: false, in: true });
      this.handleFade();
    };

    componentWillUnmount() {
      clearTimeout(this.fadeOutTimeout);
      this.props.mainContainerRef?.removeEventListener(
        'mousemove',
        this.handleMouseMove
      );
    }

    render() {
      return (
        //  NOTE: disabled Fade component because it forces a reflow, which messes with other animations
        //
        // <Fade
        //   in={this.state.in}
        //   timeout={{ enter: 200, exit: 750 }}
        //   unmountOnExit={false}
        //   mountOnEnter={false}
        //   appear={false}
        // >
        <div
          style={{
            opacity: this.state.in || this.state.lock ? 1 : 0,
            transition: `opacity ${
              this.state.in || this.state.lock ? 100 : 250
            }ms ease-in`
          }}
          onMouseEnter={this.lock}
          onMouseOver={this.lock}
          onMouseLeave={this.unLock}
        >
          <WrappedComponent
            in={this.state.in || this.state.lock}
            focus={this.unLock}
            {...this.props}
          />
        </div>
        // </Fade>
      );
    }
  };

export default customFadeWrapper;
