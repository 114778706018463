import { createStyles, makeStyles } from '@material-ui/core';

export const useCustomStyles = makeStyles((theme) =>
  createStyles({
    paddingBottom: (props: any) => ({
      paddingBottom: props.paddingBottom
    })
  })
);

const useTeamSettingsStyles = makeStyles(() =>
  createStyles({
    inputBase: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'initial',
      marginBottom: 8
    },
    labelContainer: { display: 'flex', alignItems: 'center' },
    inputLabel: {
      textAlign: 'start',
      marginRight: 12,
      marginBottom: 8,
      width: 'auto'
    },
    required: {
      marginBottom: -2
    }
  })
);

export { useTeamSettingsStyles };
