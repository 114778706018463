import React, { useCallback } from 'react';

import ProjectSettingsModalUI from '@cube3/ui/src/Projects/Modals/ProjectSettingsModalUI';
import { ModalReceiverProps } from '../../../../Modals/ModalManager';
import { useModalActions } from '../../../../Modals/modalActions';
import { Project } from '@cube3/common/model/schema/resources/project';
import { useSetProjectIcon } from '../../../../Projects/hooks/useSetProjectIcon';

interface Properties extends ModalReceiverProps {
  modalContext: Project;
}

export const ProjectTemplateSettingsModal: React.FC = (props: Properties) => {
  const project = props.modalContext;
  const { closeAllModals, openModal } = useModalActions();

  const onDelete = useCallback(() => {
    openModal('workspace_project_template_delete', {
      visitingProject: project
    });
  }, [openModal, project]);

  const onChangeName = useCallback(() => {
    openModal('workspace_project_template_rename', {
      visitingProject: project
    });
  }, [openModal, project]);

  const setProjectIconConfig = useSetProjectIcon(project);

  return (
    <ProjectSettingsModalUI
      onCloseEvent={closeAllModals}
      onClickDeleteProject={onDelete}
      onClickEditProject={onChangeName}
      projectName={project?.display_name}
      isTemplate={true}
      {...setProjectIconConfig}
    />
  );
};
