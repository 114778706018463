import moment from 'moment';
import React from 'react';
import ErrorText from './ErrorText';

/// <Summary>
/// Component that accepts a timestring in either RFC822 format
/// or ISO 8601 format. The component will render the format as
/// a year number.
/// All other formats given will be rendered as date unavailable.
///
/// Accepted examples:
/// ISO 8601 Example : 2011-10-10T14:48:00
/// ISO 8601 Example : 2011-10-10
/// RFC822   Example : Mon, 25 Dec 1995 13:30:00 GMT
/// RFC822   Example : Mon, 25 Dec 1995
/// More info: https://stackoverflow.com/questions/25362713/rfc-2822-and-iso-8601-date-format-regex
/// </Summary>

interface Props {
  /** Css classes injected in the `span` component */
  className?: string;
  /** The date to display, given in `Date` or `string` (ISO or RFC format) */
  date: string | Date;
}

export const Year = React.memo(({ date = new Date(), className }: Props) => {
  // try to convert the date string into epoch time.

  const dateObj =
    date && Object.getPrototypeOf(date) === Date ? date : new Date(date || 0);
  const valid = !Number.isNaN(dateObj.valueOf());

  // check if the time is epoch (number)
  if (!valid) {
    return <ErrorText errorText="Year unavailable" />;
  }

  return <span className={className}>{`${moment(dateObj).year()}`}</span>;
});
