import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  useCurrentAccount,
  useCurrentUser
} from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useDispatch } from 'react-redux';
import { clearFields } from 'redux-form';
import { User } from '@cube3/common/model/schema/resources/user';
import { ClearInputHandler } from '@cube3/ui/src/forms/textfields/AutoCompleteInput';
import {
  actionCreators,
  statuses
} from '@cube3/state/src/redux/ducks/request-status';
import { useThrottledQuery } from './useThrottledQuery';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';
import { Recipient } from '@cube3/common/model/schema/resources/recipient';

export const useEmailSuggestionsBase = (query) => {
  const dispatch = useDispatch();

  const [currentAccount] = useCurrentAccount();

  const params = useMemo(() => {
    return query?.trim()
      ? {
          filter: {
            query: query.trim()
          },
          page: { size: -1 }
        }
      : undefined;
  }, [query]);

  const recipients = useResourceList__ALPHA({
    resourceType: 'account',
    resourceId: currentAccount,
    edgeType: 'recipient',
    edgeLabel: 'recipients',
    params
  });

  useEffect(() => {
    return () => {
      dispatch(
        actionCreators.invalidateRelationship({
          type: 'account',
          id: currentAccount,
          relationship: 'recipients'
        })
      );
    };
  }, []);
  return recipients;
};

interface ExtendedRecipient extends Recipient {
  full_name?: string;
  profile_picture?: string;
  userId?: string;
  accountId?: string;
}

/**
 *
 * @param recipients Array of Recipient items
 * @param eager don't wait for users to resolve, but pass through non-extended recipients until they resolve
 * @returns array of Recipient objects extendend with full_name, profile_picture and user/account IDs when applicable
 */
export const useExtendedRecipients = (
  recipients: Recipient[],
  eager = true
): { recipients: ExtendedRecipient[]; loading: boolean } => {
  const accounts = useResourceList__ALPHA({
    resourceId: useCurrentWorkspace()[0],
    resourceType: 'workspace',
    edgeType: 'account',
    edgeLabel: 'accounts',
    strategy: 'prefer-cache',
    params: useRef({ page: { size: -1 } }).current
  });

  const userIds = useMemo(() => {
    return accounts.resources.map((a) => a.relationships.user);
  }, [accounts.resources]);

  const users = useResource__ALPHA({
    resourceIdentifiers: userIds,
    strategy: 'prefer-cache'
  });

  const extended = useMemo(() => {
    if (
      eager &&
      recipients &&
      (!hasResolved(users.status) || !hasResolved(accounts.status))
    ) {
      return recipients;
    }

    return recipients?.map((r) => {
      const user = users.resources?.find((u) => {
        return u.email_address === r.email_address;
      });

      return {
        ...r,
        full_name: user?.full_name,
        profile_picture: user?.profile_picture,
        display_name: user?.full_name,
        userId: user?.id,
        accountId: user
          ? accounts.resources.find((a) => a.relationships.user.id === user.id)
              .id
          : undefined
      };
    });
  }, [eager, users.resources, recipients, accounts.resources]);

  return {
    recipients: extended,
    loading: !extended
  };
};

export const useEmailSuggestions = (
  directQuery: string,
  excludeSelf: boolean = false
) => {
  const dispatch = useDispatch();

  const query = useThrottledQuery(directQuery);

  const recipients = useEmailSuggestionsBase(query);

  const { recipients: extended, loading } = useExtendedRecipients(
    recipients.resources
  );

  const [, user] = useCurrentUser();

  const emailSuggestions = useMemo(() => {
    return extended?.filter(
      (r, idx, arr) =>
        arr.findIndex((r2) => r2.id === r.id) === idx &&
        (!excludeSelf || r.email_address !== user.email_address)
    ); // filter out any duplicates that might be returned by api
  }, [extended, user, excludeSelf]);

  const clearEmailInput = React.useCallback(
    (form, keepTouched, persistentSubmitErrors, ...fields) => {
      dispatch(
        clearFields(form, keepTouched, persistentSubmitErrors, ...fields)
      );
    },
    [dispatch]
  );

  const currentUser = useCurrentUser()[1];

  return {
    loading: !recipients.resources || recipients.loading || loading,
    emailSuggestions: emailSuggestions,
    currentUser,
    clearEmailInput
  };
};

export interface WithEmailSuggestionsProps {
  loading: boolean;
  emailSuggestions: {
    email_address: string;
    id: string;
    full_name?: string;
    profile_picture?: string;
  }[];
  currentUser: User;
  clearEmailInput: ClearInputHandler;
}

export const withEmailSuggestions = (excludeSelf) => (Wrapped) => (props) => {
  const mappedProps = useEmailSuggestions(props.emailSearchInput, excludeSelf);
  return <Wrapped {...props} {...mappedProps} />;
};
