import React from 'react';
import Surface from '../../Surface/Surface';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '../../typography/Typography';
import { useClassName } from '../../utils/useClassName';
import { iconClose, iconDelete } from '../../icons/GeneralSVG';
import Button from '../../Buttons/Button';
import TruncatedText from '../../human/Truncate';
import { Tooltip } from '@material-ui/core';
import MediaQueueTileStatusUI from './subComponents/MediaQueueTileStatusUI';

export type MediaQueueTileStatus = 'success' | 'failed' | 'active' | 'pending';

interface Props {
  thumb: React.ReactNode;
  title: string;
  subTitle?: string;
  destination?: string; // required by ExportQueueListItem
  /** TV broadcast: to list status for each  destination */
  multiTargets?: JSX.Element;
  contextRow: React.ReactNode;
  timestampRow: React.ReactNode;
  status?: MediaQueueTileStatus;
  statusLabel?: string;
  statusProgress?: number;
  faded?: boolean;
  gradient?: 'none' | 'lamp';
  onCloseClick?(): void;
  onCloseClickTooltip?: string;
  onCloseClickIcon?: string;
  highlight?: boolean | string;
  /** Export: click to view export status details */
  viewDetails?: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', padding: 8 * 2 },
    thumb: { flexGrow: 0, flexShrink: 0, width: 8 * 8, marginRight: 8 * 2 },
    content: { flexGrow: 1, flexShrink: 0 },
    faded: { opacity: 0.16 },
    statusSpinnerTypography: {
      lineHeight: 1
    },
    actionButtons: {
      position: 'absolute',
      right: 8,
      top: 8
    },
    highlight: {
      position: 'absolute',
      left: 16,
      bottom: 16,
      zIndex: 100
    },
    multiTargets: {
      border: `1px solid ${theme.customPalette.line.onBase1.contrast4}`,
      borderRadius: theme.surfaceCorners.lowEmphasis,
      display: 'flex',
      flexFlow: 'column nowrap',
      marginTop: '8px',
      maxWidth: 480
    },
    destinationRow: {
      display: 'flex',
      flexFlow: 'row wrap',
      padding: '8px 16px',
      justifyContent: 'space-between'
    },
    destination: {
      marginRight: 8,
      marginBottom: 8
    },
    withBorderBottom: {
      borderBottom: `1px solid ${theme.customPalette.line.onBase1.contrast4}`
    }
  })
);

export const MediaQueueTile: React.FunctionComponent<Props> = (props) => {
  const {
    thumb,
    title,
    subTitle,
    destination = undefined,
    multiTargets,
    contextRow,
    timestampRow,
    status,
    statusLabel,
    statusProgress,
    faded,
    gradient = 'none',
    onCloseClick,
    onCloseClickTooltip,
    onCloseClickIcon,
    highlight,
    viewDetails
  } = props;
  const classes = useStyles(props);

  return (
    <Surface
      style={{
        width: '100%',
        backgroundColor: gradient && gradient === 'none' && 'transparent'
      }}
      gradient={gradient}
    >
      <div className={useClassName(classes.root, faded && classes.faded)}>
        {highlight && (
          <div className={classes.highlight}>
            <Typography variant="body3" color="brand1">
              {typeof highlight === 'string' ? highlight : 'New'}
            </Typography>
          </div>
        )}
        <div className={classes.actionButtons}>
          {onCloseClick && (
            <Tooltip
              title={
                onCloseClickTooltip ||
                (status === 'success' ? 'Hide' : 'Delete')
              }
              placement="top"
            >
              <span>
                <Button
                  colorVariant="ghost2"
                  pathCenterSvg={
                    onCloseClickIcon ||
                    (status === 'success' ? iconClose : iconDelete)
                  }
                  onClick={onCloseClick}
                />
              </span>
            </Tooltip>
          )}
        </div>
        <div className={classes.thumb}>{thumb}</div>
        <div className={classes.content}>
          <Typography
            typographyStyle="heading3"
            typographyProps={{ gutterBottom: true }}
          >
            <TruncatedText str={title} toolTip={true} placement="top" />
          </Typography>
          {subTitle && (
            <Typography
              typographyStyle="body3"
              color="contrast2"
              typographyProps={{ gutterBottom: true }}
            >
              <TruncatedText str={subTitle} toolTip={true} placement="top" />
            </Typography>
          )}
          <Typography
            typographyStyle="body2"
            color="contrast2"
            typographyProps={{ gutterBottom: true }}
          >
            {contextRow}
          </Typography>

          {destination && (
            <Typography
              typographyStyle="body2"
              color="contrast2"
              typographyProps={{ gutterBottom: true }}
            >
              {destination}
            </Typography>
          )}

          <Typography
            typographyStyle="body2"
            color="contrast2"
            typographyProps={{ gutterBottom: true }}
          >
            {timestampRow}
          </Typography>
          {multiTargets || (
            <MediaQueueTileStatusUI
              status={status}
              statusProgress={statusProgress}
              statusLabel={statusLabel}
            />
          )}
          {viewDetails}
        </div>
      </div>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre>; */}
    </Surface>
  );
};
