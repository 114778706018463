import React from 'react';
import { TabConfig } from './TabsLayout';

interface ProviderProps {
  active?: TabConfig;
}

export const tabsContext = React.createContext(null);

export const TabsProvider: React.FC<React.PropsWithChildren<ProviderProps>> = props => {
  const { active, children } = props;
  return <tabsContext.Provider value={active}>{children}</tabsContext.Provider>;
};
