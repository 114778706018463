import {
  actionTypes,
  startSubmit,
  stopSubmit,
  change,
  setSubmitSucceeded,
  setSubmitFailed,
  isSubmitting,
  hasSubmitFailed,
  hasSubmitSucceeded,
  initialize,
  getFormInitialValues,
  getFormNames
} from 'redux-form';
import { actions as requestStatusActions } from '../ducks/request-status';
import { FSA } from '../flux-standard-action';

const SUBMITTING = 'cube3/redux-form-middleware/form-states/SUBMITTING';
const SUCCESS = 'cube3/redux-form-middleware/form-states/SUCCESS';
const FAILED = 'cube3/redux-form-middleware/form-states/FAILED';

export const formStates = {
  SUBMITTING,
  SUCCESS,
  FAILED
} as const;

type FormStates = (typeof formStates)[keyof typeof formStates];

interface AddFormIdOptions<E = any, D = any> {
  formState?: FormStates;
  useRequestStatus?: boolean;
  errorFormatter?(errors: E, data: D, formId: string, action: FSA): unknown;
  delegate?: boolean;
  data?: D;
}

export const hasFormId = (action) => {
  const { meta = {} } = action;
  const { reduxForm } = meta;
  return reduxForm;
};

export const addFormId = (
  formId: string,
  {
    formState = formStates.SUBMITTING,
    useRequestStatus = false,
    errorFormatter = (errors, data, formId, action) => errors,
    delegate = false,
    data = undefined
  }: AddFormIdOptions = {}
) => {
  if (!formId) {
    return (action) => action;
  }

  return (action) => {
    const { meta = {} } = action;
    const { reduxForm = {} } = meta;

    return {
      ...action,
      meta: {
        ...meta,
        reduxForm: {
          ...reduxForm,
          form: formId,
          formState,
          useRequestStatus,
          errorFormatter,
          delegate,
          data: typeof data === 'function' ? data(action) : data,
          datafn: typeof data === 'function' ? data : undefined
        }
      }
    };
  };
};

export const copyFormId = (originalAction) => {
  const form = hasFormId(originalAction);
  const { form: formId, useRequestStatus, delegate, ...config } = form || {};

  return (
    action,
    formState: FormStates | undefined = undefined,
    copyDelegate = false
  ) => {
    if (formId) {
      return addFormId(formId, {
        ...config,
        formState,
        useRequestStatus,
        delegate: copyDelegate ? delegate : false,
        data: delegate && config.datafn ? config.datafn(action) : config.data
      })(action);
    } else {
      return action;
    }
  };
};

const submittingCounter = {};

const formErrors = {};

const mergeErrors = (errors) => {
  return errors.reduce((acc, error) => {
    return {
      ...acc,
      ...error
    };
  }, {});
};

export const reduxFormMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.meta?.reduxForm) {
      const { form, formState, errorFormatter, delegate, data } =
        action.meta.reduxForm;

      if (delegate) {
        return next(action);
      }

      const handleAllDone = () => {
        if (!formErrors[form] || formErrors[form].length === 0) {
          dispatch(stopSubmit(form));
          const sss = setSubmitSucceeded(form);
          dispatch({ ...sss, meta: { ...sss.meta, legit: true } });
        } else {
          const errors = mergeErrors(formErrors[form]);
          dispatch(stopSubmit(form, errors));
          dispatch(setSubmitFailed(form, ...Object.keys({ ...errors })));
        }

        delete submittingCounter[form];
        delete formErrors[form];
      };

      switch (formState) {
        case formStates.SUBMITTING:
          submittingCounter[form] = submittingCounter[form]
            ? submittingCounter[form] + 1
            : 1;
          dispatch(startSubmit(form));
          return next(action);

        case formStates.SUCCESS:
          submittingCounter[form] = submittingCounter[form]
            ? submittingCounter[form] - 1
            : 0;
          if (submittingCounter[form] === 0) {
            handleAllDone();
          }
          return next(action);

        case formStates.FAILED:
          submittingCounter[form] -= 1;
          formErrors[form] = (formErrors[form] || []).concat(
            errorFormatter(action.error, data, form, action)
          );
          if (submittingCounter[form] === 0) {
            handleAllDone();
          }

          return next(action);

        default:
          break;
      }
    }

    const state = getState();

    let subjectAction;
    switch (action.type) {
      // NOTE: this is a general interceptor for redux-form forms
      // as the onSubmit handler always dispatches a SET_SUBMIT_SUCCEEDED
      // when you don't return a promise from the callback

      case actionTypes.SET_SUBMIT_SUCCEEDED:
        if (action.meta && action.meta.legit) {
          return next(action);
        } else {
          console.warn(
            'intercepted action to prevent false succeeded state ',
            action
          );
          return { ...action, type: 'noop' };
        }

      case actionTypes.INITIALIZE: {
        const block =
          action.meta &&
          (isSubmitting(action.meta.form)(state) ||
            hasSubmitSucceeded(action.meta.form)(state) ||
            hasSubmitFailed(action.meta.form)(state));
        if (action.meta && (!block || action.meta.legit)) {
          return next(action);
        } else {
          console.warn(
            'intercepting action to prevent succeeded state getting lost ',
            action
          );
          const overrideAction = initialize(
            action.meta.form,
            action.payload,
            true,
            {
              keepDirty: true,
              keepSubmitSucceeded: true,
              updateUnregisteredFields: true
            }
          );
          overrideAction.meta.legit = true;
          dispatch(overrideAction);
          return { ...action, type: 'noop' };
        }
      }

      case requestStatusActions.MARK_SUCCESS:
        subjectAction = action.payload && action.payload.action;
        if (
          subjectAction &&
          hasFormId(subjectAction) &&
          subjectAction.meta.reduxForm.useRequestStatus &&
          !subjectAction.meta.reduxForm.delegate
        ) {
          const extendedAction = addFormId(subjectAction.meta.reduxForm.form, {
            formState: formStates.SUCCESS
          })(action);
          dispatch(extendedAction);
          return;
        }
        return next(action);

      case requestStatusActions.MARK_FAILED:
        subjectAction = action.payload && action.payload.action;
        if (
          subjectAction &&
          hasFormId(subjectAction) &&
          subjectAction.meta.reduxForm.useRequestStatus &&
          !subjectAction.meta.reduxForm.delegate
        ) {
          const extendedAction = addFormId(subjectAction.meta.reduxForm.form, {
            formState: formStates.FAILED
          })(action);
          dispatch(extendedAction);
          return;
        }
        return next(action);

      case actionTypes.CHANGE:
        if (
          action.meta.redux_middleware_ignore ||
          !action.meta.field.match(/(?:field_|key_)/)
        ) {
          return next(action);
        }
        {
          const forms = getFormNames()(getState());
          forms.forEach((f) => {
            if (f === action.meta.form) {
              return;
            }

            if (!getFormInitialValues(f)) {
              return;
            }

            const init = getFormInitialValues(f)(getState());
            const fields = init && Object.keys(init);

            if (fields?.includes(action.meta.field)) {
              const a = change(f, action.meta.field, action.payload);
              a.meta.redux_middleware_ignore = true;
              dispatch(a);
            }
          });
        }
        return next(action);

      default:
        return next(action);
    }
  };

export default reduxFormMiddleware;
