import React from 'react';
import {
  validationErrorCodes as ERROR,
  serverErrorCodes as SERROR,
  hasError
} from '@cube3/state/src/wrapped-cube-client';
import { Typography } from '@cube3/ui/src/typography/Typography';

export const getErrorMessage = (error, passThrough = true) => {
  switch (true) {
    case hasError(error, SERROR.INVITE_TOKEN_INVALID_ERROR):
      return 'Invitation is not valid';
    case hasError(error, SERROR.INVITE_TOKEN_ACCEPTED_ERROR):
      return 'Invitation is no longer valid';
    case hasError(error, SERROR.INVITE_USER_DATA_ERROR):
      return 'Required user data was not provided';
    case hasError(error, SERROR.ATTRIBUTE_UNIQUE_CONSTRAINT):
    case hasError(error, SERROR.DUPLICATE_NAME):
      return 'This name has already been taken. Please use a unique name.';
    case hasError(error, ERROR.REQUIRED_ERROR):
      return 'This field is required';
    case hasError(error, ERROR.EMAIL_SYNTAX_ERROR):
      return 'Invalid email address';
    case hasError(error, ERROR.SHOULD_MATCH_VALUE):
      return 'Fields do not match';
    case hasError(error, ERROR.DUPLICATE_RECIPIENTS_ERROR):
      return 'Duplicate recipients are not allowed';
    case hasError(error, ERROR.INVALID_RECIPIENTS_ERROR):
      return 'Invalid email used';

    default:
      return !passThrough
        ? 'Something went wrong. Please contact support@cube-cloud.com'
        : typeof error === 'string'
        ? error
        : error._error && error._error[0]
        ? error._error[0].detail
        : error.title || error.detail || error.code;
  }
};

export function getErrors(errorMessage: string, metaError: string) {
  return errorMessage
    ? errorMessage
    : metaError !== undefined
    ? [].concat(metaError)
    : metaError;
}

export const Errors = ({ errors, show = true }) => {
  return show && errors ? (
    <Typography color={'danger1'} typographyStyle={'body2'}>
      {[]
        .concat(errors)
        .filter((e) => e !== null)
        .map((e) => (
          <div key={e}>{getErrorMessage(e) || e}</div>
        ))}
    </Typography>
  ) : null;
};

export default Errors;
