import React from 'react';
import { Typography } from '@cube3/ui/src/typography/Typography';
import CheckedRoundedCheckbox from '@cube3/ui/src/icons/CheckedRoundedCheckbox';
import { validationErrorCodes as VERROR } from '@cube3/state/src/wrapped-cube-client/error-types';
import { makeStyles, createStyles, useTheme, Theme } from '@material-ui/core/';

// This component shows the password strength in a popper besides a password input form

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    align: {
      display: 'flex'
    },
    titletype: {
      marginBottom: '16px'
    },
    checktype: {
      paddingLeft: '10px',
      color: theme.customPalette.primary.textWhiteMediumEmphasis
    }
  })
);

interface Properties {
  errors: string[];
}

interface RenderInterface {
  text: string;
  error: string;
}

const renderValues: RenderInterface[] = [
  {
    text: 'Use 8 characters or more.',
    error: VERROR.WEAK_PASSWORD_ERROR_LENGTH
  },
  {
    text: 'Do not use characters consecutively (aaa, 111)',
    error: VERROR.WEAK_PASSWORD_ERROR_CONSECUTIVE
  },
  {
    text: 'Do not use characters sequentially (abc, 123)',
    error: VERROR.WEAK_PASSWORD_ERROR_SEQUENTIAL
  },
  {
    text: 'Do not use easily guessable words',
    error: VERROR.WEAK_PASSWORD_ERROR_DICTIONARY
  },
  {
    text: 'Use 64 characters or less.',
    error: VERROR.MAXIMUM_PASSWORD_ERROR_LENGTH
  }
];

function PasswordStrength<T extends Properties>({ errors }: T) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Typography className={classes.titletype} typographyStyle={'body1'}>
        Password strength
      </Typography>

      {renderValues.map((item, index, array) => {
        return (
          <div key={index} className={classes.align}>
            <CheckedRoundedCheckbox
              fillCheckMark={
                errors.indexOf(item.error) > -1
                  ? theme.customPalette.primary.lineWhiteMediumEmphasis
                  : theme.customPalette.secondary.main
              }
              fillCircle=""
              renderCircle={false}
            />
            <Typography className={classes.checktype} typographyStyle={'body2'}>
              {item.text}
            </Typography>
          </div>
        );
      })}
    </>
  );
}

export default PasswordStrength;
