import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { CubeReduxState } from '../../ducks/CubeReduxState';

/**
* @summary: Functions for redux
* @author Simon
*/

/** Returns a typed useSelector from the redux store with CubeReduxState.

Usage:
```
const visitingId = useTypedSelector((state) => state.selections.visiting.id);
```
*/
export const useTypedSelector: TypedUseSelectorHook<CubeReduxState> = useSelector;