import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useMemo
} from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useImageDimensions } from '../Hooks/useImageDimensions';
import { useClassName } from '../utils/useClassName';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      overflow: 'hidden',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      boxSizing: 'border-box',
      border: '1px solid transparent'
    },
    show: {
      border: `1px solid ${theme.customPalette.line.onBase1.shadow1}`
    },
    thumb: {
      backgroundPosition: `0px 0px`,
      transformOrigin: '0 0'
      // background: 'green',
    }
  })
);

const limitDefaults = {
  maxWidth: undefined,
  maxHeight: undefined,
  top: undefined,
  left: undefined
};

interface Limits {
  maxWidth?: number;
  maxHeight?: number;
  top?: number;
  left?: number;
}

interface Props {
  className?: string;
  src: string;
  count?: number;
  restPos?: number;
  value?: number;
  alwaysShow?: boolean;
  getLimits?(): Limits;
  forceRatio?: number;
}

export const ScrollableThumbnail = React.memo(
  forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
      src,
      count = 50,
      restPos = 15,
      value,
      alwaysShow = false,
      getLimits,
      forceRatio = false
    } = props;

    const dimensions = useImageDimensions(src);

    const classes = useStyles();

    const [pos, setPos] = useState<number>(null);
    const [show, setShow] = useState<boolean>(alwaysShow);
    const [isHoverOn, setIsHoverOn] = useState<boolean>(false);

    const [boundingRef, setBoundingRef] = useState<HTMLDivElement>(null);

    const onBoundingRef = useCallback(
      (node) => {
        setBoundingRef(node);

        if (typeof ref === 'function') {
          ref(node);
        } else if (
          ref &&
          typeof ref === 'object' &&
          ref.hasOwnProperty('current')
        ) {
          ref.current = node;
        }
      },

      [setBoundingRef, ref]
    );

    const scrubHandler = useCallback(
      (ev) => {
        if (!boundingRef) {
          return;
        }

        const bounds = boundingRef.parentElement?.getBoundingClientRect();
        const x = ev.clientX;
        const y = ev.clientY;

        if (
          !bounds ||
          x < bounds.left ||
          x > bounds.left + bounds.width ||
          y < bounds.top ||
          y > bounds.top + bounds.height
        ) {
          setIsHoverOn(false);
          return;
        }

        const newPos = (x - bounds.left) / (bounds.right - bounds.left);
        setIsHoverOn(true);
        setShow(true);
        setPos(Math.min(Math.max(0, Math.floor(newPos * count)), count - 1));
      },
      [boundingRef, setShow, setPos, count]
    );

    const hasValue = value !== undefined;

    useEffect(() => {
      if (hasValue) {
        return;
      }
      window.addEventListener('mousemove', scrubHandler);
      return () => window.removeEventListener('mousemove', scrubHandler);
    }, [scrubHandler, hasValue]);

    const forceUpdate: () => void = React.useState<{}>()[1].bind(null, {});

    useEffect(() => {
      window.addEventListener('resize', forceUpdate);
      return () => window.removeEventListener('resize', forceUpdate);
    }, [forceUpdate]);

    useEffect(() => {
      if (isHoverOn) return;

      const timeout = setTimeout(() => setShow(false), 750);
      return () => clearTimeout(timeout);
    }, [pos, isHoverOn]);

    const { width: cellWidth, height: stripHeight = 0 } = dimensions || {};
    const cellHeight = stripHeight / count;

    const limits = getLimits?.() || limitDefaults;
    const { maxWidth, maxHeight, top, left } = limits;

    const transform = useMemo(() => {
      const cellRatio = forceRatio || cellWidth / cellHeight;

      const frameRatio =
        maxWidth && maxHeight ? maxWidth / maxHeight : cellRatio;

      const cellWider = cellRatio > frameRatio;
      const cellTaller = cellRatio < frameRatio;
      const sameRatio = cellRatio === frameRatio;

      const heightConstrained =
        cellTaller || (maxHeight && !maxWidth) || (sameRatio && maxHeight);

      const widthConstrained =
        cellWider || (maxWidth && !maxHeight) || (sameRatio && maxWidth);

      const selfScale = heightConstrained
        ? maxHeight / cellHeight
        : widthConstrained
        ? maxWidth / cellWidth
        : 1;

      return {
        scale: selfScale,
        width: widthConstrained
          ? maxWidth
          : maxHeight
          ? maxHeight * cellRatio
          : cellWidth,
        height: heightConstrained
          ? maxHeight
          : maxWidth
          ? maxWidth / cellRatio
          : cellHeight,
        top: top || 0,
        left: left || 0
      };
    }, [top, left, maxHeight, maxWidth, cellWidth, cellHeight, forceRatio]);

    return (
      <div
        ref={onBoundingRef}
        style={{
          width: isNaN(transform?.width) ? 0 : transform?.width,
          height: isNaN(transform?.height) ? 0 : transform?.height,
          top: transform?.top,
          left: transform?.left
        }}
        className={useClassName(
          classes.root,
          show || (alwaysShow && classes.show)
        )}
      >
        <div
          style={{
            transform: `scale(${transform?.scale})`,
            transformOrigin: '0 0'
          }}
        >
          <div
            className={classes.thumb}
            style={{
              opacity: show || alwaysShow ? 1 : 0,
              transition:
                show || alwaysShow
                  ? 'opacity 0.15s ease-out'
                  : 'opacity 0.7s ease-out',

              backgroundImage: `url('${src}')`,
              width: `${cellWidth}px`,
              height: `${stripHeight}px`,
              transform: `translate3d(0%, ${
                Math.ceil(
                  -(pos !== null ? pos : value !== undefined ? value : restPos)
                ) * cellHeight
              }px, 0px) `
            }}
          ></div>
        </div>
      </div>
    );
  })
);

ScrollableThumbnail.displayName = 'ScrollableThumbnail';
