import * as React from 'react';

interface CheckedRoundedCheckboxProps {
  fillCircle: string;
  fillCheckMark: string;
  renderCircle?: boolean;
  path?: string;
}

const CheckedRoundedCheckbox: React.FC<CheckedRoundedCheckboxProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      {props.renderCircle && (
        <circle fill={props.fillCircle} cx="12" cy="12" r="12" />
      )}
      <path
        fill={props.fillCheckMark}
        d={props.path || 'M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'}
      />
    </svg>
  );
};

export default CheckedRoundedCheckbox;
