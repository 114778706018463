import { HttpRequest, HttpResponse, HttpStack } from 'tus-js-client';
import { uuidv4 } from '../../utils/uuid';

('use strict');

export class WorkerHttpStack implements HttpStack {
  createRequest(method: string, url: string): HttpRequest {
    return new WorkerRequest(method, url);
  }
  getName(): string {
    return 'workerHttpStack';
  }
}

class WorkerRequest implements HttpRequest {
  private worker;
  private _method;
  private _url;
  private _headers;

  constructor(method, url) {
    this.worker = new XhrWorker(method, url);

    this._method = method;
    this._url = url;
    this._headers = {};
  }

  getMethod() {
    return this._method;
  }

  getURL() {
    return this._url;
  }

  setHeader(header, value) {
    this.worker.setRequestHeader(header, value);
    this._headers[header] = value;
  }

  getHeader(header) {
    return this._headers[header];
  }

  setProgressHandler(progressHandler) {
    this.worker.onprogress((loaded) => {
      progressHandler(loaded);
    });
  }

  send(body = null) {
    return new Promise<HttpResponse>((resolve, reject) => {
      this.worker
        .send(body)
        .then((res) => {
          resolve(
            new WorkerResponse({
              worker: this.worker,
              status: res.status,
              responseText: res.responseText,
              getResponseHeader: (header) => {
                return (
                  res.responseHeaders[header] ||
                  res.responseHeaders[header.toLowerCase()]
                );
              }
            })
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  abort() {
    this.worker.abort();
    // this._xhr.abort();
    return Promise.resolve();
  }

  getUnderlyingObject() {
    return this.worker;
  }
}

class WorkerResponse implements HttpResponse {
  private res;
  constructor(res) {
    this.res = res;
  }

  getStatus() {
    return this.res.status;
  }

  getHeader(header) {
    return this.res.getResponseHeader(header);
  }

  getBody() {
    return this.res.responseText;
  }

  getUnderlyingObject() {
    return this.res.worker;
  }
}

class XhrWorker {
  private id;
  private progressCallback;
  private status;
  private resolve;
  private reject;
  static uploadWorker = new Worker(
    /* webpackChunkName: "upload worker" */ new URL(
      './xhr.worker',
      import.meta.url
    )
  );

  constructor(method, url) {
    this.id = uuidv4();
    this.open(method, url);
    XhrWorker.uploadWorker.addEventListener('message', this.handleMessage);
  }

  pm(command, args) {
    XhrWorker.uploadWorker.postMessage({ command, args, id: this.id });
  }

  open(method, url) {
    this.pm('open', { method, url });
  }

  setRequestHeader(header, value) {
    this.pm('setRequestHeader', { header, value });
  }

  send(body) {
    this.pm('send', { body });

    return new Promise((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    });
  }

  abort() {
    this.pm('abort', {});

    return this.status;
  }

  onprogress(handler) {
    this.progressCallback = handler;
  }

  handleMessage = (ev: MessageEvent) => {
    if (ev.data.id !== this.id) {
      return;
    }

    switch (ev.data.command) {
      case 'progress':
        this.progressCallback(ev.data.args.loaded);
        break;
      case 'error':
        this.reject?.(ev.data.args.error);
        break;
      case 'response':
        this.resolve?.(ev.data.args);
        break;
      default:
        console.warn('unknown command from worker', ev.data.command);
        break;
    }
  };
}
