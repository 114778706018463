import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Divider } from '../Surface/Divider';
import { isMobile } from 'react-device-detect';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

/// <Summary>
/// UI Footer for manage access that can show compnennts centered
/// and styled.
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    root: {
      height: '64px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
      flexShrink: 0 // to prevent modal contents deforming the height.
      // '$mobile&': {
      //   position: 'fixed',
      //   bottom: 0,
      //   width: '100%'
      // }
    },
    contents: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginTop: 'auto',
      marginBottom: 'auto'
    },
    left: {
      display: 'flex', // for inline display
      marginLeft: theme.customSpacing.margin[8],
      marginRight: 'auto'
    },
    right: {
      display: 'flex', // for inline display
      marginLeft: 'auto', // align right
      marginRight: theme.customSpacing.margin[4], // margin should be 16px but we reserve 8 for each button.
      '& > *': {
        marginRight: theme.customSpacing.margin[12]
      }
    },
    divider: {
      alignSelf: 'start',
      width: '100%'
    }
  }));

interface Properties {
  footerLeftComponent?: JSX.Element;
  footerRightComponent?: JSX.Element;
  classes?: ClassNameMap;
}

const ModalFooter: React.FC<Properties> = ( props ) => {
  const { footerLeftComponent, footerRightComponent } = props;

  const classes = useStyles(props);

  return (
    <div className={`${classes.root} ${isMobile ? classes.mobile : ''}`}>
      {/* Draw Divider */}
      <div className={classes.divider}>
        <Divider background={'base2'} color={'contrast2'} />
      </div>

      {/* Draw any buttons */}
      <div className={classes.contents}>
        {footerLeftComponent && (
          <div className={classes.left}>{footerLeftComponent}</div>
        )}

        {footerRightComponent && (
          <div className={classes.right}>{footerRightComponent}</div>
        )}
      </div>
    </div>
  );
}

export const ModalFooterUI = ModalFooter;
