import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { selectors } from '@cube3/state/src/redux/ducks/metadata-clipboard';

import { usePaste } from './usePaste';
import { useCopy } from './useCopy';
import { useMemo } from 'react';
import { isEmptyForm } from './utils/isEmptyForm';
import { selectFormIds } from './utils/selectFormIds';
import { selectAllFormValues } from './utils/selectAllFormValues';
import { useHasDifferentValues } from './utils/useHasDifferentValues';
import { useAvailableKeys } from './useAvailableKeys';

export type FormValues = {
  [key: string]: unknown;
};

interface Props {
  categories: Array<unknown>;
  type: 'metadata' | 'contract';
  isDraft?: boolean;
  contractId?: string;
}

export const useCopyPasteMetadata = ({
  categories,
  type,
  isDraft,
  contractId
}: Props) => {
  // from redux form state
  const formIds = useTypedSelector(selectFormIds);
  const allFormValues: FormValues = useTypedSelector(selectAllFormValues);

  // from clipboard state
  const clipboardValues: FormValues = useTypedSelector((state) =>
    selectors.getValuesByType(state.metadataClipboard, type)
  );

  // store all fields/keys for each category
  const availableKeys = useAvailableKeys({ categories, type });

  const hasDifferentValues = useHasDifferentValues({
    clipboardValues,
    allFormValues,
    availableKeys,
    formIds,
    isDraft
  });

  // create callbacks
  const onCopy = useCopy({ type, allFormValues });
  const onPaste = usePaste({
    contractId,
    isDraft,
    availableKeys,
    formIds,
    clipboardValues,
    allFormValues
  });

  return useMemo(
    () => ({
      onCopy,
      onPaste,
      pasteDisabled: !hasDifferentValues,
      copyDisabled: isEmptyForm(allFormValues)
    }),
    [onCopy, onPaste, hasDifferentValues, allFormValues]
  );
};
