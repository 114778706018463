import { PublicResource, Related, VerifyType } from '../../resource-types';
import { RawModel } from '../schema';

export interface WorkspacePreferences {
  default_shares_download_type:
    | 'DOWNLOAD_TYPE_PREVIEW'
    | 'DOWNLOAD_TYPE_ORIGINAL';
  default_shares_download_enabled: boolean;
  default_shares_expiration_enabled: boolean;
  default_shares_expiration_duration: number;
  default_shares_show_dates: boolean;
  default_shares_show_tags: boolean;
  default_shares_password_enabled: boolean;
  default_shares_verify_user: boolean;
  default_shares_verify_type: VerifyType;
  default_shares_allow_request_access: boolean;
  default_filerequests_expiration_duration: number;
  default_contract_recipients: string[];
  default_project_template_id: string;
  project_template_required: boolean;
  project_navigation_fields: string[];
  first_contract_reminder: number;
  second_contract_reminder: number;
  third_contract_reminder: number;
  default_labeling: boolean;
}

export interface Workspace extends PublicResource, WorkspacePreferences {
  organization_id: string;
  maximum_active_projects: number;
  maximum_allowed_accounts: number;
  used_storage: number;
  maximum_allowed_storage: number;
  plan_name: string;
  relationships: {
    default_teams: Related<'team'>[];
    //preference: Related<'workspace-preference'>;
  };
}

export const workspace: RawModel = {
  type: 'workspace',
  plural: 'workspaces',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {
    default_teams: {
      type: 'team',
      binding: ['many', 'one'],
      reverse: 'workspace'
    }
  }
};
