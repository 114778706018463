import React from 'react';

import { EditSettingModal } from './EditSettingsModal';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback } from 'react';
import { useModalActions } from '../../../../Modals/modalActions';
import { useEffect } from 'react';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

interface Props {}

const formId = 'edit_default_shares_allow_requesting';

export const EditShareAllowRequestingModal: React.FC<Props> = () => {
  const workspace = useCurrentWorkspace()[1];
  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    actionDecorators: [addFormId(formId, { delegate: true })],
    cacheInvalidator: null
  });

  const { previousModal } = useModalActions();

  const handleSave = useCallback(
    (data) => {
      mutate({
        type: 'workspace',
        id: workspace?.id,
        default_shares_allow_request_access:
          data.default_shares_allow_requesting
      });
    },
    [mutate, workspace]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  return (
    <EditSettingModal
      onSave={handleSave}
      loading={!workspace || mutateStatus === statuses.IN_FLIGHT}
      form={formId}
      initialValues={{
        default_shares_allow_requesting:
          workspace.default_shares_allow_request_access
      }}
      config={{
        fields: [
          {
            type: 'boolean',
            name: 'default_shares_allow_requesting',
            value_type: 'boolean',
            // validate: validator,
            label: 'Allow requesting access'
          }
        ]
      }}
      title={'Allow requesting access default'}
    />
  );
};
