import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { ReviewToolContext } from '../ReviewTool/Context/reviewToolContext';
// Interfaces
import {
  assetMimeTypes,
  assetProcessingStates
} from '@cube3/common/model/types';
/* Import components */
import ImagePreview from '@cube3/ui/src/previews/ImagePreview/ImagePreview';
import VideoPreview from '@cube3/ui/src/previews/VideoPreview/MediaPlayer';
import { AudioPreview } from '@cube3/ui/src/previews/AudioPreview';
import { DocumentPreview } from '@cube3/ui/src/previews/DocumentPreview';
import { FallbackPreview } from '@cube3/ui/src/previews/FallbackPreview';
import PreviewHoc from './PreviewHoc';
import PreviewVideoHoc from './PreviewVideoHoc';
import { CubeReduxState } from '@cube3/state/src/redux/ducks/CubeReduxState';
import { Modal } from '@cube3/state/src/redux/ducks/modals/state';
import { AnnotationLayer } from '../Annotation/AnnotationLayer';
// Import Hooks
import { matchPath, useLocation } from 'react-router-dom';
import { urlStructureReview } from '../../routing/routingPaths';
import { Spectogram2 } from './DetailView/Preview/Spectogram2';
import { usePollReviewComments } from '../ReviewTool/Hooks/usePollReviewComments';

/* Wrap components with previewHOC */
const ConnectedImagePreview = PreviewHoc(ImagePreview);
const ConnectedDocumentPreview = PreviewHoc(DocumentPreview);
const ConnectedVideoPreview = PreviewVideoHoc(VideoPreview);
const ConnectedAudioPreview = PreviewHoc(AudioPreview);
const ConnectedFallbackPreview = PreviewHoc(FallbackPreview);

const videoPreviewLayers = [AnnotationLayer];

const mapStateToProps = (state: CubeReduxState) => {
  return {
    fullscreen: state.ui.fullscreen,
    fullscreenRequested: state.ui.fullscreenRequested,
    modalStack: state.modals.modalStack
  };
};

interface PreviewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asset: any;
  gotoNextSibling?: Function;
  gotoPrevSibling?: Function;
  siblings?: boolean;
  loading?: boolean;
  // props recieved from state
  fullscreen: boolean;
  fullscreenRequested: boolean;
  modalStack: Modal[];
  disableSnapshot?: boolean;
}

const audioLayers = [Spectogram2];

function Preview(props: PreviewProps) {
  const {
    asset = { type: '' },
    gotoNextSibling = () => null,
    gotoPrevSibling = () => null,
    siblings = false,
    loading,
    modalStack,
    disableSnapshot
  } = props;

  const { pathname } = useLocation();
  const onReviewRoute = matchPath(pathname, urlStructureReview);

  const pages =
    asset &&
    asset.media &&
    asset.media
      .filter((m) => m.type === 'poster')
      .sort((pA, pB) => {
        return parseInt(pA.page) - parseInt(pB.page);
      });
  const source = asset?.media?.find((m) => m.type === 'source');
  const preview = asset?.media?.find((m) => m.type === 'preview');
  const poster = pages && pages[0];
  const thumbnail = asset?.media?.find((m) => m.type === 'thumbnail');
  const strip = asset?.media?.find((m) => m.type === 'strip');
  const src = preview || poster;

  const dimensions = poster
    ? { width: poster.width, height: poster.height }
    : undefined;

  // we will check if there are any other modals active and prop drill that to videopreviewcontrols
  // so we can prevent keyboard input for the video player.
  const otherModalsActive: boolean =
    modalStack && modalStack.length > 0 ? true : false;

  // review view
  const { unresolvedComments: comments } = usePollReviewComments(
    onReviewRoute ? asset.id : undefined
  );

  const { drawingBarToggled, playerRef, toggleDrawingBar, setActiveComment } =
    useContext(ReviewToolContext) || {};

  //

  switch (
    !asset.media || asset.processing_status !== assetProcessingStates.COMPLETE
      ? 'not_ready'
      : asset.mime_type
  ) {
    case assetMimeTypes.VIDEO:
      return (
        <ConnectedVideoPreview
          key={`preview-asset-${asset.id}`}
          {...asset}
          src={src && src.url}
          poster={poster && poster.url}
          status={asset.state}
          thumbnail={thumbnail && thumbnail.url}
          dimensions={{ width: source.width, height: source.height }}
          gotoNextSibling={gotoNextSibling}
          gotoPrevSibling={gotoPrevSibling}
          siblings={siblings}
          otherModalsActive={otherModalsActive}
          stripSrc={strip?.url}
          disableSnapshot={disableSnapshot}
          // review view
          layers={onReviewRoute && videoPreviewLayers}
          toggleDrawingBar={onReviewRoute ? toggleDrawingBar : undefined}
          drawingBarToggled={onReviewRoute && drawingBarToggled}
          comments={comments}
          setActiveComment={setActiveComment}
          playerRef={playerRef}
        />
      );

    case assetMimeTypes.IMAGE:
      return (
        <ConnectedImagePreview
          key={`preview-asset-${asset.id}`}
          {...asset}
          src={src && src.url}
          status={asset.state}
          thumbnail={thumbnail && thumbnail.url}
          gotoNextSibling={gotoNextSibling}
          gotoPrevSibling={gotoPrevSibling}
          siblings={siblings}
          dimensions={{ width: source.width, height: source.height }}
        />
      );

    case assetMimeTypes.DOCUMENT_PDF:
      // in some cases processing (and bad database) does not return pages
      // in the source, so only render preview when pages are available.
      if (pages && pages.length > 0) {
        return (
          <ConnectedDocumentPreview
            key={`preview-asset-${asset.id}`}
            {...asset}
            pages={pages}
            status={asset.state}
            thumbnail={thumbnail && thumbnail.url}
            dimensions={dimensions}
            gotoNextSibling={gotoNextSibling}
            gotoPrevSibling={gotoPrevSibling}
            siblings={siblings}
          />
        );
      }
      break;

    case assetMimeTypes.AUDIO:
      return (
        <ConnectedAudioPreview
          key={`preview-asset-${asset.id}`}
          {...asset}
          src={src && src.url}
          status={asset.state}
          thumbnail={thumbnail && thumbnail.url}
          gotoNextSibling={gotoNextSibling}
          gotoPrevSibling={gotoPrevSibling}
          siblings={siblings}
          otherModalsActive={otherModalsActive}
          layers={audioLayers}
        />
      );
  }

  // because we hit some cases in the switch statement but choose to do conditional rendering within the case,
  // we will make sure make sure that we always render the fallback in case switch statement returns nothing.
  return (
    <ConnectedFallbackPreview
      key={`preview-asset-${asset.id}`}
      {...asset}
      status={asset.state}
      gotoNextSibling={gotoNextSibling}
      gotoPrevSibling={gotoPrevSibling}
      siblings={siblings}
      loading={loading}
    />
  );
}

export default connect(mapStateToProps, {})(Preview);
