import { useCallback, useState } from 'react';

import { useTagMutations } from './useTagMutations';
import { useEditableTags } from './useEditableTags';
import { useRetrieveTags } from './useRetrieveTags';
import { useTagSuggestions } from './useTagSuggestions';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { useTagCategories } from '../../../TagManagement/hooks/useTagCategories';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';

interface UseTagsManagerConfig {
  // resources the tags will be applied to
  targetResources: ResourceIdentifier[];
  // whether user is allowed to modify tag resource data after creation
  canModify?: boolean;
}

export const useTagsManager = ({
  targetResources,
  canModify
}: UseTagsManagerConfig) => {
  /** get persisted state */
  const { allTags, partialTags, commonTags, anyLoading } = useRetrieveTags({
    targetResources
  });

  const [inputTags, setInputTags] = useState<EditableTag[]>([]);

  const { getTagsWithCategory, generalCategory } = useTagCategories();
  /** retrieve tags we need for display purposes */
  const {
    toDelete,
    toAdd,
    toCreate,
    anyChanges,
    selectedTags,
    editablePartialTags
  } = useEditableTags({
    inputTags,
    setInputTags,
    partialTags,
    allTags,
    canModify,
    commonTags
  });

  /** get callback to trigger commiting staged mutations */

  const { anyProcessing, handleSave, saveSucceeded, saveFailed } =
    useTagMutations({
      targetResources,
      allTags,
      inputTags,
      toDelete
    });

  /** propagate input state to staged mutations */

  const handleInputSubmit = useCallback(
    (tag, defaultCategory?) => {
      setInputTags(
        [].concat(inputTags).concat({
          ...tag,
          category: tag.category || defaultCategory || generalCategory
        })
        // .filter(t => !t.text && !t.color)
      );
    },
    [setInputTags, inputTags, generalCategory]
  );

  /** get a list of suggestions */
  const { handleInputChange, suggestions, query } = useTagSuggestions({
    allTags: getTagsWithCategory(allTags.resources),
    selectedTags
  });

  return {
    anyChanges,
    anyLoading,
    anyProcessing,
    handleSave,
    handleInputChange,
    handleInputSubmit,
    query,
    inputTags: selectedTags,
    toCreate,
    toDelete,
    toAdd,
    suggestions,
    saveSucceeded,
    saveFailed,
    debug: {},
    editablePartialTags
  };
};
