import React, {
  ChangeEvent,
  useState,
  useEffect,
  useLayoutEffect
} from 'react';
//
import { Slider } from '@material-ui/core';
//
import { useStyles } from './VideoPreviewControls';
import { ScrollableThumbnail } from '../../thumbnails/ScrollableThumbnail';
import { Comment } from '@cube3/common/model/schema/resources/comment';
import { CommentsGroup } from './PlaybackSliderComments';

export interface WithAnnotationPlaybackSliderProps {
  comments?: Comment[];
  onClickComment?: (comment) => void;
}
interface Props extends WithAnnotationPlaybackSliderProps {
  played: number;
  playing: boolean;
  duration: number;
  onSeekChange(ev: ChangeEvent<{}>, val: number | number[]): void;
  buffered?: TimeRanges;
  stripSrc?: string;
  frameRate?: number;
}

const handleGetLimits = () => ({ maxHeight: 80 });

export const PlaybackSlider: React.FC<Props> = React.memo((props) => {
  const {
    played,
    duration,
    onSeekChange,
    buffered,
    stripSrc,
    comments,
    onClickComment,
    frameRate
  } = props;

  const classes = useStyles(props);

  const [sliderRef, setSliderRef] = useState(null);
  const [stripRef, setStripRef] = useState(null);
  const [hoverPos, setHoverPos] = useState(0);
  const [showStrip, setShowStrip] = useState(false);

  useEffect(() => {
    if (sliderRef) {
      let to;
      let posTo;
      const handleMouseMove = (ev) => {
        if (to) {
          clearTimeout(to);
        }
        if (posTo) {
          return;
          // clearTimeout(posTo);
        }
        let x = Math.max(
          0,
          Math.min(
            ev.clientX - sliderRef.getBoundingClientRect().left,
            sliderRef.getBoundingClientRect().width
          )
        );

        posTo = setTimeout(() => {
          setHoverPos(x);
          setShowStrip(true);
          to = setTimeout(() => {
            setShowStrip(false);
            to = null;
          }, 1500);
          posTo = null;
        }, 1000 / 30);
      };

      sliderRef.addEventListener('mousemove', handleMouseMove);

      return () => {
        sliderRef.removeEventListener('mousemove', handleMouseMove);
        if (to) {
          clearTimeout(to);
        }
        if (posTo) {
          clearTimeout(to);
        }
      };
    }
  }, [sliderRef]);

  const [sliderBB, setSliderBB] = useState(
    sliderRef?.getBoundingClientRect() || {}
  );
  const [stripBB, setStripBB] = useState(
    stripRef?.getBoundingClientRect() || {}
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setSliderBB(sliderRef?.getBoundingClientRect() || {});
      setStripBB(stripRef?.getBoundingClientRect() || {});
    });

    if (sliderRef) {
      resizeObserver.observe(sliderRef);
    }
    if (stripRef) {
      resizeObserver.observe(stripRef);
    }
    return () => {
      if (sliderRef) {
        resizeObserver.unobserve(sliderRef);
      }
      if (stripRef) {
        resizeObserver.unobserve(stripRef);
      }
    };
  }, [sliderRef, stripRef]);

  useLayoutEffect(() => {
    setSliderBB(sliderRef?.getBoundingClientRect() || {});
    setStripBB(stripRef?.getBoundingClientRect() || {});
  }, [sliderRef, stripRef]);

  return (
    <div style={{ position: 'relative', width: '100%' }} ref={setSliderRef}>
      <Slider
        onChange={onSeekChange}
        //   onChangeCommitted={handleOnChangeCommit}
        step={0.01}
        max={duration}
        classes={{
          root: classes.slider,
          track: classes.sliderTrack,
          thumb: classes.thumb,
          rail: classes.rail
        }}
        value={played * duration}
      />
      {sliderRef && stripSrc && (
        <div
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            overflow: 'hidden',
            top: 0,
            left: 0,
            width: stripBB.width,
            height: stripBB.height,
            transform: `translate(${
              hoverPos - (hoverPos / sliderBB.width) * stripBB.width
            }px, -${stripBB.height}px )`
          }}
        >
          <ScrollableThumbnail
            ref={setStripRef}
            src={stripSrc}
            count={40}
            alwaysShow={showStrip}
            value={Math.floor((hoverPos / sliderBB.width) * 40)}
            getLimits={handleGetLimits}
          />
        </div>
      )}
      {sliderRef && (
        <CommentsGroup
          comments={comments}
          onClickComment={onClickComment}
          duration={duration}
          sliderWidth={sliderBB.width}
          frameRate={frameRate}
        />
      )}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: 2,
          top: 14,
          left: 0,
          opacity: 0.48,
          pointerEvents: 'none'
        }}
      >
        {buffered &&
          [...Array(buffered.length)].map((t, idx) => {
            const s = buffered.start(idx);
            const e = buffered.end(idx);
            return (
              <div
                key={idx}
                style={{
                  position: 'absolute',
                  background: played * duration > s ? '#F9D649' : '#CCC',
                  height: '100%',
                  top: 0,
                  left: percent(s / duration),
                  width: percent((e - s) / duration)
                }}
              />
            );
          })}
      </div>
    </div>
  );
});

const percent = (n) => `${n * 100}%`;
