import React from 'react';
import { fileIcons } from './files';
import { makeCSS } from '../../../utils/makeCSS';
import { cx } from '@emotion/css';
import { SizeVariants } from '../../../theme/themes';

/**The FileIcons are being used by the Thumbnail component
 * to display the type of file the content has.
 */

export interface FileIconsProps {
  /**The name of the file category: */
  fileType?: string;
  /**Different sizes that the fileicon can have */
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg | SizeVariants.xl;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      display: 'flex',
      alignSelf: 'stretch',
      flexShrink: 0
    },
    'size-sm': {
      width: theme.spacing[5],
      height: theme.spacing[5]
    },
    'size-md': {
      width: theme.spacing[8],
      height: theme.spacing[8]
    },
    'size-lg': {
      width: theme.spacing[16],
      height: theme.spacing[16]
    },
    'size-xl': {
      width: theme.spacing[32],
      height: theme.spacing[32]
    }
  };
});

export const FileIcons = (props: FileIconsProps) => {
  const { fileType: name, size = SizeVariants.lg } = props;

  const classes = useCSS();

  const FileIcons = fileIcons[name];
  if (!FileIcons) {
    return null;
  }
  return (
    <FileIcons
      className={cx(classes.root, classes[`size-${size}`])}
      focusable={false}
    />
  );
};
