import { ContentTreeNode } from '@cube3/common/model/schema/resources/content-tree-node';
import { useContextPath } from '@cube3/state/src/redux/components/Hooks/useContextPath';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { mergeStatuses } from '@cube3/state/src/redux/utils/mergeStatuses';

export const useActivitySubject = (subjectRID) => {
  const isNode = subjectRID.type === 'content-tree-node';

  const activitySubject = useResource__ALPHA({
    resourceId: subjectRID?.id,
    resourceType: subjectRID?.type
  });

  const node = activitySubject.resource as ContentTreeNode;

  const isDeleted = node?.deleted_at;

  const nodeResource = useResource__ALPHA({
    resourceId: !isDeleted ? node?.relationships?.resource?.id : undefined,
    resourceType: node?.relationships?.resource?.type
  });

  const loading = activitySubject.loading || nodeResource.loading;
  const status = mergeStatuses(
    [activitySubject.status, nodeResource.status],
    true
  );

  const name = isNode
    ? nodeResource.resource?.display_name
    : activitySubject.resource?.display_name ||
      activitySubject.resource?.full_name;

  const isAsset = isNode
    ? node?.relationships.resource?.type === 'asset'
    : subjectRID.type === 'asset';

  const [nodePathname] = useContextPath({
    resourceId: isNode ? node?.relationships.resource?.id : subjectRID?.id,
    deleted: !!node?.deleted_at,
    includeResource: true,
    includeDetail: isAsset
  });

  return {
    name: name,
    resource: nodeResource.resource || activitySubject.resource,
    pathname: nodePathname,
    loading,
    status
  };
};
