import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { mutations } from '@cube3/state/src/redux/ducks/resource-edges';
import { compose } from '@cube3/state/src/utils/component-helpers';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFields, getFormValues, reduxForm } from 'redux-form';
import { WorkspaceTeamMembersPicker } from '../prefabs/WorkspaceTeamMembersPicker';

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearEmailInput: clearFields
    },
    dispatch
  );
};

const mapStateToProps = (state, ownProps) => {
  const values = getFormValues('add_team_member')(state);
  return {
    form: ownProps.form,
    formValues: values
  };
};
const emptyObj = {};
const AddTeamMemberModal = props => {
  const { modalContext, form, formValues, valid, clearEmailInput } = props;
  const { team, alreadyMembers, workspaceId } = modalContext;
  const { previousModal } = useModalActions();

  const [addMembers, addMembersStatus] = useMutateRelationship__ALPHA({
    ancestor: { type: 'team', id: team.id },
    relationship: 'members',
    cacheInvalidator: useCallback(
      (res, mut, anc, rel) => [
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'accounts'
        },
        { type: anc.type, id: anc.id }
      ],
      [workspaceId]
    ),
    options: { optimize: true }
  });
  const { accountSearchInput, accounts } = formValues || emptyObj;
  const onClickAdd = useCallback(() => {
    addMembers(
      accounts.map(m => ({
        resource: m,
        mutationType: mutations.MUTATION_ADD
      }))
    );
  }, [addMembers, accounts]);

  const loading =
    addMembersStatus &&
    addMembersStatus !== statuses.SUCCESS &&
    addMembersStatus !== statuses.FAILED;

  useEffect(() => {
    if (addMembersStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [addMembersStatus, previousModal]);

  return (
    <ModalNoticeUI
      title="Add Member"
      loading={loading}
      component="form"
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button text="Cancel" colorVariant="ghost2" onClick={previousModal} />
          <Button
            text="Add"
            colorVariant="filled1"
            onClick={onClickAdd}
            disabled={!valid || !accounts || !accounts.length}
          />
        </>
      }
    >
      <div style={{ minHeight: 400 }}>
        <WorkspaceTeamMembersPicker
          label="Members"
          inputFieldPlaceholder="Enter a user name"
          form={form}
          clearEmailInput={clearEmailInput}
          accountSearchInput={accountSearchInput}
          excludedAccounts={alreadyMembers}
          autoFocus={true}
        />
      </div>
    </ModalNoticeUI>
  );
};

export default compose(AddTeamMemberModal)(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'add_team_member',
    destroyOnUnmount: true
  })
);
