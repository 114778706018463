import React from 'react';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import Chip from '@cube3/ui/src/chips/Chip';

export const ResourceChip = props => {
  const { resource } = props;
  const res = useResource__ALPHA({
    resourceType: resource.type,
    resourceId: resource.id
  });
  if (!res.resource) {
    return null; // TODO: maybe add better loading states
  }
  return <Chip {...props} input={{ value: res.resource?.display_name }} />;
};
