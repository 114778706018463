import React, { useMemo } from 'react';
import { GenericFieldWithErrorsProps } from './GenericFieldWithErrors';
import {
  Field,
  clearFields,
  getFormValues,
  getFormSyncErrors
} from 'redux-form';

// UI
import SuggestionsContainer from '@cube3/ui/src/inputChipPicker/SuggestionsContainer';
import Chip from '@cube3/ui/src/chips/Chip';
import WrappedSuggestionsInputField from '@cube3/ui/src/forms/WrappedSuggestionsInputField';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ClearOn } from '@cube3/ui/src/inputChipPicker/SuggestionsContainer/SuggestionsContainer';
/* 

  This component is a a generic multiple input field that works with redux FieldArray. To use this component provide the following: 

  form: id/name of the redux form. 
  fields: Array of the items that are selected.
 clearInputField: Function that is used to clear the input field.
 inputValue: The current value of the input field.
  placeholder: Placeholder text.
  
  optional:
  allowAddingOfNewSuggestions: Boolean to enable or disable adding of new suggestions. 
  suggestions: Array of suggestions.
  inputType: Input type.
  validator: Validator function used to validate input.

*/

interface MultipleInputFieldProps extends GenericFieldWithErrorsProps {
  fields: any;
  suggestions?: string[];
  placeholder: string;
  validator?: Function;
  allowAddingOfNewSuggestions?: boolean;
  inputType?: string;
  clearOn?: ClearOn;
}

const emptyArray = [];

const itemToString = (i) => {
  return i ? i : '';
};

function MultipleInputField(props: MultipleInputFieldProps) {
  const {
    fields,
    placeholder = 'Start typing for suggestions',
    suggestions = [],
    validator,
    allowAddingOfNewSuggestions = true,
    inputType,
    meta,
    clearOn
  } = props;

  const selectedSuggestions = fields ? fields : emptyArray;
  const inputName = React.useRef(`${fields.name}__input` || uuidv4());
  const inputValue = useSelector((state) => {
    const values = getFormValues(meta.form)(state);
    return values && values[inputName.current];
  });
  const inputError = useSelector((state) => {
    const errors = getFormSyncErrors(meta.form)(state);
    return errors && errors[inputName.current];
  });

  const dispatch = useDispatch();
  const clearInputField = React.useCallback(
    () => dispatch(clearFields(meta.form, true, true, inputName.current)),
    [dispatch, inputName, meta.form]
  );

  /*  filter suggestions against selected selected items array   */
  const filteredSuggestions = useMemo(() => {
    const selectedSuggestionIdArray =
      selectedSuggestions.getAll() || emptyArray;

    return suggestions
      .filter((item) => selectedSuggestionIdArray.indexOf(item) === -1)
      .filter(
        (item) =>
          !inputValue || item.toLowerCase().includes(inputValue.toLowerCase())
      );
  }, [suggestions, selectedSuggestions, inputValue]);

  return (
    <SuggestionsContainer
      allSuggestions={filteredSuggestions}
      inputValue={inputValue}
      itemToString={itemToString}
      newSuggestionPrimaryKey={'id'}
      allowAddingOfNewSuggestions={allowAddingOfNewSuggestions}
      clearOn={clearOn}
      keysListenersArrayToAddNewSuggestions={['Tab', ',', ';', 'Enter']}
      addItemToSelectionArray={(item) => {
        return fields.push(item.value || item);
      }}
      removeItemFromSelectionArray={(index) => fields.remove(index)}
      selectedSuggestions={selectedSuggestions}
      clearInputField={clearInputField}
      renderSelectedItem={(selectedSuggestion, index) => {
        return (
          <Field
            key={selectedSuggestion}
            name={selectedSuggestion}
            type="text"
            component={Chip}
            // parse={ (val) => val.value }
            // format={ (val) => ({value: val, id:val}) }
            removeItem={() => {
              fields.remove(index);
            }}
            index={index}
          />
        );
      }}
      inputError={!!inputError}
      renderInputChipPicker={(
        handleInputKeyDown,
        selectHighlightedItem,
        highlightedIndex,
        isOpen,
        reset,
        inputValue,
        inputField,
        getInputProps,
        filteredSuggestions,
        setHighlightedIndex,
        openMenu,
        closeMenu
      ) => (
        <Field
          name={inputName.current}
          validate={validator}
          component={WrappedSuggestionsInputField}
          props={{
            handleInputKeyDown,
            selectHighlightedItem,
            highlightedIndex,
            isOpen,
            reset,
            inputValue,
            inputField,
            getInputProps,
            filteredSuggestions,
            inputFieldPlaceholder:
              inputValue || selectedSuggestions.length > 0 ? '' : placeholder,
            setHighlightedIndex,
            openMenu,
            closeMenu,
            type: inputType
          }}
        />
      )}
      renderSuggestionItem={(suggestion) => (
        <Typography>{suggestion.value || suggestion}</Typography>
      )}
    />
  );
}

const MultipleInputFieldReduxForm = (props) => {
  return <MultipleInputField {...props} />;
};

export default MultipleInputFieldReduxForm;
