import { uuidParser } from '@cube3/state/src/utils/uuidparser';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { GenericFailure } from '@cube3/ui/src/Prefabs/errors-handling/GenericFailure';
// import Button from '@cube3/ui/src/Buttons/Button';
// import { ModalFooterUI } from '@cube3/ui/src/Modal/ModalFooter';
import ModuleLoader from '@cube3/ui/src/Prefabs/Loaders/ModuleLoader';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { getEnvVar } from '../../../../../utils/getEnvVar';
import { useModalActions } from '../../Modals/modalActions';
import { useBroadcastLegacyActions } from '../hooks/useBroadcastLegacyActions';
import { useIOSIframeTouch } from '@cube3/compose/src/utils/useIOSIframeTouch';
import { legacyHost } from '@cube3/state/src/utils/environment';
interface Props {
  token: string;
  assetIds: ResourceIdentifier[];
}

export const BroadcastLegacyIframe: React.FunctionComponent<Props> = (
  props
) => {
  useIOSIframeTouch();

  const { token, assetIds } = props;

  const modalActions = useModalActions();

  const [loadMessage] = useState<string>('Opening Export manager');

  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const legacyIds = useMemo(
    () => assetIds.map((a) => uuidParser(a.id)),
    [assetIds]
  );

  const url = getEnvVar('UPLOAD_LINK_HOST') || legacyHost;
  const LEGACY_PATH_PREFIX = getEnvVar('LEGACY_PATH_PREFIX') || '';

  const [error, setError] = useState(false);
  const errorTimeout = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    return () => {
      if (errorTimeout.current) {
        clearTimeout(errorTimeout.current);
      }
    };
  }, []);

  const onLoad = useCallback(() => {
    errorTimeout.current = setTimeout(() => setError(true), 15000);
  }, [setError]);

  const onReady = useCallback(() => {
    setIsLoading(false);
    clearTimeout(errorTimeout.current);
  }, [setIsLoading]);

  const onConfirm = useCallback(() => {
    modalActions.closeAllModals();
  }, [modalActions]);

  const onCancel = useCallback(() => {
    modalActions.closeAllModals();
  }, [modalActions]);

  const [
    handleLoad
    // handleConfirm
  ] = useBroadcastLegacyActions({
    iframe: iframeRef,
    libraryItemIds: legacyIds,
    token,
    url,
    onLoad,
    onReady,
    onCancel,
    onConfirm
  });

  const iframeUrl = `${url}${LEGACY_PATH_PREFIX}/broadcast_c2.html`;

  return (
    <>
      {!error && isLoading && <ModuleLoader message={loadMessage} />}

      {!error && (
        <iframe
          allowFullScreen={true}
          allowTransparency={true}
          ref={setIframeRef}
          style={{
            border: 'none',
            width: '100%',
            height: '100%',
            // height: 'calc(100% - 64px)',
            visibility: isLoading ? 'hidden' : 'visible'
          }}
          src={iframeUrl}
          // src={`http://localhost:8080/debug/iframe`} // for message debugging
          title="embedded cube2 broadcast exports"
          onLoad={handleLoad}
          referrerPolicy="origin-when-cross-origin"
        />
      )}
      <GenericFailure message="Couldn't start export manager" show={error} />
      {/* <ModalFooterUI
        footerRightComponent={
          <Button
            onClick={handleConfirm}
            text="confirm"
            colorVariant="filled1"
          />
        }
      /> */}
    </>
  );
};
