import React, { useMemo } from 'react';
import { CommandBarGenericUI } from '@cube3/ui/src/library/CommandBar/CommandBarUI';
import { useModalActions } from '../../../Modals/modalActions';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { useButtonConfig } from '../../../CommandBar/useButtonConfig';

import { attachContractButtonFactory } from './button-factories/attachContractButtonFactory';
import { detachContractButtonFactory } from './button-factories/detachContractButtonFactory';
import { editContractButtonFactory } from './button-factories/editContractButtonFactory';

interface Props {
  contract?: ResourceIdentifier;
  parentResource: ResourceIdentifier;
}

export const AttachedContractsCommandBar: React.FC<Props> = (props) => {
  const { contract, parentResource } = props;

  const modalActions = useModalActions();

  const primaryButtonFactories = useMemo(
    () => [
      attachContractButtonFactory(() =>
        modalActions.openModal(
          'attach_contract_prompt',
          { parentResource },
          false
        )
      ),
      detachContractButtonFactory(
        () =>
          modalActions.openModal(
            'detach_contract_prompt',
            {
              parentResource,
              contract
            },
            false
          ),
        contract
      )
    ],
    [contract, parentResource, modalActions]
  );

  const secondaryButttonFactories = useMemo(
    () => [
      editContractButtonFactory(
        () =>
          modalActions.openModal('edit_contract', {
            contractId: contract.id
          }),
        contract
      )
    ],
    [contract, modalActions]
  );

  const primaryButtonConfig = useButtonConfig(primaryButtonFactories);
  const secondaryButtonConfig = useButtonConfig(secondaryButttonFactories);

  return (
    <CommandBarGenericUI
      primaryButtonsConfig={primaryButtonConfig}
      secondaryButtonsConfig={secondaryButtonConfig}
    />
  );
};
