import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import React, { useCallback, useMemo } from 'react';

import { mapActivitiesToDateGroups } from './utils/mapActivitiesToDateGroups';
import { ActivityGroup } from './prefabs/ActivityGroup';
import { LoadMoreBumper } from '@cube3/ui/src/utils/withLazyLoad';
import { useModalActions } from '../Modals/modalActions';

export const ProjectActivity = (props) => {
  const { projectId } = props;

  const [pages, setPages] = React.useState([1]);
  const pagesize = 20;
  const pageParams = React.useRef({ page: { size: pagesize } });

  const loadMore = React.useCallback(() => {
    setPages([...pages, pages.length + 1]);
  }, [pages, setPages]);

  const projectActivitiesResource2 = useResourceList__ALPHA({
    strategy: 'fetch-on-mount',
    resourceId: projectId,
    resourceType: 'project',
    edgeType: 'activity',
    edgeLabel: 'activities/v2',
    params: pageParams.current,
    pages
  });

  const projectActivitiesResource = useResourceList__ALPHA({
    strategy: 'cache-only',
    resourceId: projectId,
    resourceType: 'project',
    edgeType: 'activity',
    edgeLabel: 'activities',
    params: pageParams.current,
    pages
  });

  const mappedActivies = useMemo(
    () =>
      mapActivitiesToDateGroups(
        projectActivitiesResource.resources
          ? projectActivitiesResource.resources
          : undefined
      ),
    [projectActivitiesResource.resources]
  );

  const showLoadMore =
    !projectActivitiesResource2.loading &&
    projectActivitiesResource2.pageCounts &&
    pages.length < projectActivitiesResource2.pageCounts.pageCount;

  const { closeAllModals } = useModalActions();

  const closeModals = useCallback(() => {
    closeAllModals();
  }, [closeAllModals]);

  return (
    <>
      <ActivityGroup
        title={'This week'}
        activities={mappedActivies.thisWeekArray}
        onClickLink={closeModals}
      />

      <ActivityGroup
        title={'Last week'}
        activities={mappedActivies.lastWeekArray}
        onClickLink={closeModals}
      />

      <ActivityGroup
        title={'This month'}
        activities={mappedActivies.thisMonthArray}
        onClickLink={closeModals}
      />

      <ActivityGroup
        title={'Last month'}
        activities={mappedActivies.lastMonthArray}
        onClickLink={closeModals}
      />

      <ActivityGroup
        title={'Earlier this year'}
        activities={mappedActivies.earlierThisYearArray}
        onClickLink={closeModals}
      />

      <ActivityGroup
        title={'Last year'}
        activities={mappedActivies.lastYearArray}
        onClickLink={closeModals}
      />

      <ActivityGroup
        title={'A long time ago'}
        activities={mappedActivies.longAgoArray}
        onClickLink={closeModals}
      />
      {showLoadMore && (
        <LoadMoreBumper
          once={false}
          callback={(visible) => (visible ? loadMore() : undefined)}
        />
      )}
    </>
  );
};
