// #region imports
import React, { useMemo } from 'react';

// helpers
import { compose } from '../../../../../utils/component-helpers';

// HOCS
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { withModalActions, useModalActions } from '../../Modals/modalActions';

// UI
import ProjectSettingsModalUI from '@cube3/ui/src/Projects/Modals/ProjectSettingsModalUI';
// hooks
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useArchiveProjects } from '../useArchiveProjects';
import { useSetProjectIcon } from '../hooks/useSetProjectIcon';
import { useSetProjectLabeling } from '../hooks/useSetProjectLabeling';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { Feature } from '@cube3/common/model/resource-types';

const requiredFeatures = {
  features: ['/WORKSPACE/LABELING'] as Feature[]
};
// #endregion

interface Properties extends ModalReceiverProps {}
// #endregion

function ProjectSettingsModal(props: Properties): JSX.Element {
  const { closeAllModals, openModal } = useModalActions();
  const project = useResource__ALPHA({
    resourceType: 'project',
    resourceId: props.modalContext?.id
  }).resource;

  const projectTemplateId = project?.relationships.project_template?.id;

  const [workspaceId] = useCurrentWorkspace();
  const projectTemplate = useResource__ALPHA({
    resourceType: 'project-template',
    resourceId: projectTemplateId,
    strategy: 'fetch-on-mount'
  }).resource;

  const projects = useMemo(() => [project], [project]);
  const handleArchiveProject = useArchiveProjects({
    projects: projects,
    action:
      project?.project_status === 'ProjectStatusArchived'
        ? 'unarchive'
        : 'archive'
  });

  const setProjectIconConfig = useSetProjectIcon(project);
  const setProjectLabelingConfig = useSetProjectLabeling(project);
  const [hasLabelingFeature] = useFeatures(requiredFeatures);

  return (
    <ProjectSettingsModalUI
      projectTemplate={projectTemplate?.display_name}
      onCloseEvent={closeAllModals}
      onClickDeleteProject={() =>
        openModal('projects_deleteproject', { project, workspaceId })
      }
      onClickEditProject={() =>
        openModal('projects_changeName', {
          ...project,
          modalTitle: 'Change name'
        })
      }
      onClickArchiveProject={handleArchiveProject}
      isArchived={project?.project_status === 'ProjectStatusArchived'}
      projectName={project?.display_name}
      {...setProjectIconConfig}
      {...setProjectLabelingConfig}
      project={project}
      hasLabelingFeature={hasLabelingFeature}
    />
  );
}

export default compose(ProjectSettingsModal)(
  withModalActions

  // place wrappers here
);
