import { ExportsParameters } from '@cube3/common/model/schema/resources/export-batch';

const stateIndex = {
  failed: 0,
  rendering: 1,
  pending: 2,
  processing: 3,
  success: 4
};

export const swapKeyAndValue = (object: {}) => {
  const result = {};
  for (const key in object) {
    if (Object.hasOwn(object, key)) {
      const value = object[key].toString();
      result[value] = key;
    }
  }
  return result;
};

/** priority: failed > rendering > pending > processing > success */
export const getLowerExportState = (
  exports: ExportsParameters<'broadcast'>[]
) => {
  if (!exports?.length) return undefined;

  const lowerIndex = Math.min(...exports.map((e) => stateIndex[e.state]));

  return exports.find((e) => stateIndex[e.state] === lowerIndex);
};
