import React from 'react';

/// <Summary>
/// Helper function that resolves a word to the amount given.
/// For instance:
/// <Amount word = {expired contract} count={conracts.length}/>   (for instance 2)
/// Resolves to '2 expired contracts'
/// </Summary>

const lut = {
  asset: 'assets'
};

const revLut = Object.keys(lut).reduce(
  (acc, val) => ({ ...acc, [lut[val]]: val }),
  {}
);

const guess = (word, reverse = false) => {
  if (!reverse) {
    let last = word.substr(-1);
    if (last === 's' || last === 'z' || word.substr(-2) === 'sh') {
      return `${word}es`;
    } else if (last === 'y') {
      return `${word.slice(0, -1)}ies`;
    } else {
      return `${word}s`;
    }
  } else {
    let last = word.substr(-3);
    if (last === 'ies') {
      return `${word.slice(0, -3)}y`;
    } else if (last.substr(-2) === 'es') {
      return `${word.slice(0, -2)}`;
    } else {
      return `${word.slice(0, -1)}`;
    }
  }
};

interface Props {
  /** thing that's being counted */
  word: string;
  /** ammount of things */
  count: number | string;
  /** word is plural */
  reverse?: boolean;
}

const Amount: React.FC<Props> = ({
  word = 'thing',
  count = '0',
  reverse = false
}) => {
  const plural = reverse ? word : lut[word] || guess(word);
  const singular = !reverse ? word : revLut[word] || guess(word, reverse);
  const number = typeof count === 'number' ? count : parseInt(count, 10);
  return (
    <React.Fragment>{`${count} ${
      number === 1 ? singular : plural
    }`}</React.Fragment>
  );
};

export default Amount;
