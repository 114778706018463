import { Folder } from '@cube3/common/model/schema/resources/folder';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { getErrorCodes } from '@cube3/state/src/wrapped-cube-client';
import { downloadErrors } from '@cube3/ui/src/library/Downloads/downloadErrors';
import { useCallback, useEffect, useRef, useState } from 'react';

const createDownloadConfig = {
  resourceType: 'download' as const,
  cacheInvalidator: null,
  strategy: 'cache-only'
};

/** create download and inform user of downloaded files */
export const useCreateDownload = () => {
  const [waitingForDownload, setWaitingForDownload] = useState<
    boolean | 'failed'
  >(false);
  const [downloadError, setDownloadError] = useState('');

  const tempId = useRef(uuidv4()).current;
  const downloadId = useMappedId__ALPHA(tempId);

  const [createDownload, createDownloadStatus, createDownloadError] =
    useCreateResource__ALPHA(createDownloadConfig);
  const download = useResource__ALPHA({
    resourceId:
      createDownloadStatus === statuses.SUCCESS ? downloadId : undefined,
    resourceType: 'download'
  });

  const createDownloadCallback = useCallback(
    (assets?: string[], folders?: Folder[], presetId?: string) => {
      setWaitingForDownload(true);

      createDownload({
        type: 'download' as const,
        temporaryId: tempId,
        relationships: {
          assets: {
            data: assets?.map((id) => ({ type: 'asset', id }))
          },
          folders: {
            data: folders?.map((f) => ({ type: 'folder', id: f.id }))
          },
          'render-preset': {
            data: {
              type: 'render-preset',
              id: presetId
            }
          }
        }
      });
    },
    [createDownload, tempId]
  );

  useEffect(() => {
    if (download.first) {
      const a = document.createElement('a');
      a.setAttribute('data-cy', 'codeDownloadButton');
      a.setAttribute('href', download.first.download_url);
      a.setAttribute('download', '');
      document.getElementsByTagName('body')[0].appendChild(a);
      a.click();
      setWaitingForDownload(false);
    }
  }, [download]);

  useEffect(() => {
    if (
      createDownloadError ||
      createDownloadStatus === statuses.FAILED ||
      (createDownloadStatus === statuses.SUCCESS && !download.first)
    ) {
      setWaitingForDownload('failed');
      const errorCode =
        getErrorCodes(createDownloadError)?.[0] || 'server_error';
      const error = downloadErrors[errorCode];
      setDownloadError(error);
    }
  }, [createDownloadError, createDownloadStatus, download.first]);

  return {
    waitingForDownload,
    onCreateDownload: createDownloadCallback,
    download: download.first,
    downloadError
  };
};
