import * as React from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { SizeVariants } from '../../../theme/themes';
import MaybeTooltip from '../Tooltip/MaybeTooltip';
import { withCubicleFallback } from '../../../utils/CubicleFallback';

const colorVariants = [
  'red',
  'black',
  'blue',
  'gray',
  'green',
  'indigo',
  'orange',
  'violet',
  'white',
  'yellow'
] as const;

export type BackgroundColor = (typeof colorVariants)[number];
export type AvatarShape = 'round-shape' | 'square-shape';

/**Avatar represents the users' avatar icon */
export interface AvatarProps {
  /**User initial */
  userInitial?: string;
  /**User email adress */
  tooltip?: React.ReactNode;
  /**Display or hide tooltip */
  hideTooltip?: boolean;
  /**Avatar size variant */
  size?:
    | SizeVariants.xs
    | SizeVariants.sm
    | SizeVariants.md
    | SizeVariants.lg
    | SizeVariants.xl;
  /**Avatar background  color variant */
  background?: BackgroundColor;
  /** Avatar shape variants */
  avatarShape?: AvatarShape;
  /**Status of whether the user is online or not */
  onlineStatus?: boolean;
  /**Image for when the users wants to use an image as their avatar background. */
  imageUrl?: string;
  /**Prop for the AvatarPair component to inherit the style of the Avatar component*/
  defineAvatarPair?: JSX.Element;
  /**Avatars' border (p.s.: mostly used for the smaller avatar in AvatarPair component)*/
  border?: boolean;
}

/**Avatar style */
const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    avatar: {
      background: theme.color.background.red.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      height: '100%',
      textTransform: 'uppercase',
      borderRadius: theme.borderRadius.full,
      fontFamily: theme.fontFamilies.body,
      color: theme.color.text.light['01'],
      fontWeight: theme.fontWeights['semi-bold']
    },

    /*users' online status icon */
    statusIcon: {
      position: 'absolute',
      display: 'flex',
      flexShrink: 0,
      boxSizing: 'border-box',
      background: theme.color.background.positive,
      borderRadius: theme.borderRadius.full,
      border: `2px solid ${theme.color.background.neutral}`,
      bottom: 0,
      right: 0
    },
    /*__sizes__*/
    'size-xs': {
      width: theme.spacing[4],
      height: theme.spacing[4],
      fontSize: theme.fontSize['0']
    },
    'size-sm': {
      width: theme.spacing[6],
      height: theme.spacing[6],
      fontSize: theme.fontSize['1']
    },
    'size-md': {
      width: theme.spacing[8],
      height: theme.spacing[8],
      fontSize: theme.fontSize['3']
    },
    'size-lg': {
      width: theme.spacing[12],
      height: theme.spacing[12],
      fontSize: theme.fontSize['5']
    },
    'size-xl': {
      width: theme.spacing[16],
      height: theme.spacing[16],
      fontSize: theme.fontSize['7']
    },
    /*__colors__*/
    red: {
      background: theme.color.background.red.main,
      color: theme.color.text.light['01']
    },
    black: {
      background: theme.color.background.black,
      border: `2px solid ${theme.color.background.neutral}`,
      color: theme.color.text.dark['01']
    },
    blue: {
      background: theme.color.background.blue.main
    },
    gray: {
      background: theme.color.background.gray.main
    },
    green: {
      background: theme.color.background.green.main
    },

    indigo: {
      background: theme.color.background.indigo.main
    },
    orange: {
      background: theme.color.background.orange.main
    },
    violet: {
      background: theme.color.background.violet.main
    },
    white: {
      background: theme.color.background.white
    },
    yellow: {
      background: theme.color.background.yellow.main
    },
    /*__shapes__*/
    'round-shape': {
      borderRadius: theme.borderRadius.full
    },
    'square-shape': {
      borderRadius: theme.borderRadius.md
    },
    borderAvatar: {
      border: `2px solid ${theme.color.background.neutral}`,
      boxSizing: 'border-box'
    },
    /*smaller avatar for the AvatarPair component*/
    smallAvatar: {
      position: 'absolute',
      bottom: theme.spacing['0'],
      right: -5
    }
  };
});

/**
 * Style for the Avatar's status icon when the shape is round*/
const useCSSForStatus = makeCSS(({ theme }) => {
  return {
    'size-xs': {
      width: theme.spacing['2'],
      height: theme.spacing['2'],
      bottom: -3,
      right: -3
    },
    'size-sm': {
      width: 10,
      height: 10,
      bottom: -2,
      right: -3
    },
    'size-md': {
      width: 14,
      height: 14,
      bottom: -3,
      right: -4
    },
    'size-lg': {
      width: theme.spacing['4'],
      height: theme.spacing['4'],
      bottom: -1,
      right: -3
    },
    'size-xl': {
      width: theme.spacing['4'],
      height: theme.spacing['4'],
      bottom: theme.spacing['0.5']
    }
  };
});

/**
 * Style for Avatar's status icon when the shape is square*/
const useCSSForStatusSquare = makeCSS(({ theme }) => {
  return {
    'size-xs': {
      width: theme.spacing['2'],
      height: theme.spacing['2'],
      bottom: -3,
      right: -4
    },
    'size-sm': {
      width: 10,
      height: 10,
      bottom: -2,
      right: -3
    },
    'size-md': {
      width: 14,
      height: 14,
      bottom: -3,
      right: -3
    },
    'size-lg': {
      width: theme.spacing['4'],
      height: theme.spacing['4'],
      bottom: -2,
      right: -3
    },
    'size-xl': {
      width: theme.spacing['4'],
      height: theme.spacing['4'],
      bottom: -3,
      right: -4
    }
  };
});

/** use the users initial for define the background color of the avatar*/
export function initialToColor(userInitial) {
  //turn initial into a number
  const initialToNumber =
    userInitial.toLowerCase().charCodeAt(0) % colorVariants.length;
  //use that number in the array of the colors
  const colorFromInitial = colorVariants[initialToNumber];
  return colorFromInitial;
}

const Avatar = (props: AvatarProps) => {
  const {
    userInitial = 'A',
    tooltip,
    hideTooltip = false,
    size = SizeVariants.lg,
    background = initialToColor(userInitial),
    avatarShape = 'round-shape',
    onlineStatus,
    imageUrl,
    defineAvatarPair,
    border
  } = props;

  /*only letters and letters with diactitics */
  const regex = /[^A-Za-zÀ-ȕ0-9]+/;

  const classes = useCSS(props);
  const statusClasses = useCSSForStatus();
  const statusClassesSquare = useCSSForStatusSquare();

  const avatarImage = imageUrl;

  /*makes sure the image is loaded */
  const [imageLoaded, setImageLoaded] = React.useState(false);

  React.useEffect(() => {
    setImageLoaded(false);
    const image = new Image();

    image.onload = () => {
      setImageLoaded(true);
    };
    image.src = avatarImage;
    return () => {
      image.onload = undefined;
    };
  }, [avatarImage]);

  const conntainerStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: imageLoaded ? `url( "${avatarImage}")` : 'none'
  };
  const userName =
    userInitial?.length > 1 && !userInitial.includes('@')
      ? userInitial
      : undefined;
  return (
    <>
      <MaybeTooltip
        disabled={hideTooltip}
        title={
          <div>
            {userName ? (
              <>
                {userName} <br />
              </>
            ) : null}
            {tooltip}
          </div>
        }
        placement={'right-start'}
      >
        <div
          style={conntainerStyle}
          className={`${classes.root} ${classes.avatar} ${
            classes[`size-${size}`]
          } ${classes[background]} ${classes[avatarShape]} 
         ${border ? classes.borderAvatar : ''}`}
        >
          {/*makes sure that the userInitial is only one letter and it only contains letters*/}
          {/*when the background of the avatar is an image, dont use userInitial*/}
          {imageUrl ? null : ( //make sure that when it's just whitespace added to the imageUrl, the userInitial will still be displayed
            <div>{userInitial.replace(regex, '').substring(0, 1)}</div>
          )}

          {/* ovverride the style of the AvatarPair component*/}
          <div className={classes.smallAvatar}>{defineAvatarPair} </div>

          {/**if online status is true */}
          {onlineStatus && (
            <div>
              {/* if shape is Square use IconAvatarStatusSquare style, 
              which uses the same icon with different margins */}
              {avatarShape === 'round-shape' ? (
                <div
                  className={`${classes.statusIcon} ${
                    statusClasses[`size-${size}`]
                  }`}
                ></div>
              ) : (
                <div
                  className={`${classes.statusIcon} ${
                    statusClassesSquare[`size-${size}`]
                  }`}
                ></div>
              )}
            </div>
          )}
        </div>
      </MaybeTooltip>
    </>
  );
};

const Default = withCubicleFallback(Avatar);
export { Default as Avatar };
