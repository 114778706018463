import { useCallback, useEffect, useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { useDispatch } from 'react-redux';
import { actionCreators } from '@cube3/state/src/redux/ducks/session';
import Client from '@cube3/state/src/wrapped-cube-client';
import { useAuth } from '@cube3/state/src/redux/components/Hooks/useAuth';

const noop = () => {};

export const useLogout = () => {
  const { openModal } = useModalActions();
  const dispatch = useDispatch();

  const [activeSessions, setActiveSessions] = useState(null);
  const [error, setError] = useState(null);
  const [shouldLogout, setShouldLogout] = useState(false);

  const retrieveActiveSessions = useCallback(() => {
    setActiveSessions(null);

    Client.auth
      .getActiveSessions()
      .then((res) => setActiveSessions(res))
      .catch((e) => setError(e)); // trigger logout all, to be on the safe side
  }, [setActiveSessions, setError]);

  const endAllSessions = useCallback(() => {
    dispatch(actionCreators.logout({ allSessions: true }));
  }, [dispatch]);

  const endThisSession = useCallback(() => {
    dispatch(actionCreators.logout({ allSessions: false }));
  }, [dispatch]);

  const logout = useCallback(() => {
    retrieveActiveSessions();
    setShouldLogout(true);
  }, [retrieveActiveSessions, setShouldLogout]);

  useEffect(() => {
    const hasSessionState = activeSessions !== null || error !== null;

    if (!shouldLogout || !hasSessionState) {
      return;
    }

    if (error) {
      console.error('No valid session data. Loging out all sessions...');
      endAllSessions();
    }

    if (activeSessions.length > 1) {
      openModal('session_logout_multiple');
    } else {
      endAllSessions();
    }
  }, [activeSessions, error, shouldLogout]);

  const { authenticated: hasAuth } = useAuth({ setPath: noop });

  return {
    logout,
    retrieveActiveSessions,
    sessions: activeSessions,
    endAllSessions,
    endThisSession,
    hasAuth
  };
};
