import { ExportsParameters } from '@cube3/common/model/schema/resources/export-batch';

/** One asset now has one target, here to merge the "exports" of one asset with different targets
 * @returns:  {[assetId]: [export1, export2, ...] }
 */
export const mergeExportTargets = (exports: ExportsParameters[]) => {
  if (exports && exports.length) {
    const merged = exports.reduce((acc, exp) => {
      const assetId = exp.asset_id;
      if (!acc[assetId]) {
        acc[assetId] = [exp];
      } else {
        acc[assetId].push(exp);
      }
      return acc;
    }, {});

    return merged;
  }
  return undefined;
};
