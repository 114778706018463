import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useMemo } from 'react';

export const useTVBroadcastDestination = () => {
  const [workspaceId] = useCurrentWorkspace();
  const {
    resources: workspaceIntegrations,
    loading: integrationsLoading
  } = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'export-target-type',
    edgeLabel: 'export-target-types'
  });

  const exportTargetTypeId = workspaceIntegrations?.find(
    ett => ett.attributes.display_name === 'Broadcast'
  )?.id;

  const exportTargetConfig = useMemo(() => {
    return {
      resourceId: workspaceId,
      resourceType: 'workspace' as 'workspace',
      edgeType: 'export-target' as 'export-target',
      edgeLabel: 'export-targets',
      params: {
        filter: {
          exportTargetTypeId
        }
      }
    };
  }, [workspaceId, exportTargetTypeId]);
  const {
    resources: broadcast,
    loading: broadcastLoading
  } = useResourceList__ALPHA(exportTargetConfig);

  const destination = useMemo(() => {
    if (!broadcast) return [];
    return broadcast.map(res => ({
      id: res.id,
      display_name: res.attributes.display_name
    }));
  }, [broadcast]);

  return {
    destination,
    loading: broadcastLoading || integrationsLoading,
    exportTargetTypeId
  };
};
