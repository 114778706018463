import React from 'react';

import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useAccountsUser } from '../hooks/useAccountsUser';
import { Account } from '@cube3/common/model/schema/resources/account';
// UI
import { Typography } from '@cube3/ui/src/typography/Typography';
import { ProjectAddAccountsField } from '../../../../../../app/layout/Projects/ProjectAddMembersField';
import { useCustomStyles } from '@cube3/ui/src/styles';

export interface MemersPickerProp {
  label?: string;
  clearEmailInput: Function;
  form: string;
  accountSearchInput: string;
  inputFieldPlaceholder?: string;
  excludedAccounts?: Account[];
  autoFocus?: boolean;
}

export const WorkspaceTeamMembersPicker = (props: MemersPickerProp) => {
  const {
    form,
    clearEmailInput,
    accountSearchInput,
    label,
    excludedAccounts,
    autoFocus = false
  } = props;
  const [workspaceId] = useCurrentWorkspace();
  const classes = useCustomStyles({ paddingBottom: 8 });

  const suggestedAccounts = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'account',
    edgeLabel: 'accounts',
    params: {
      filter: {
        search: accountSearchInput || ''
      }
    }
  });

  const suggestedAccountsWithNames = useAccountsUser(
    suggestedAccounts.resources
  );

  return (
    <div style={{ marginTop: 24 }}>
      {label && (
        <Typography
          color="contrast2"
          typographyStyle="body2"
          className={classes.paddingBottom}
        >
          {label}
        </Typography>
      )}
      <ProjectAddAccountsField
        form={form}
        allSuggestion={suggestedAccountsWithNames.resources}
        searchInput={accountSearchInput}
        clearInput={clearEmailInput}
        excludedSuggestion={excludedAccounts}
        autoFocus={autoFocus}
      />
    </div>
  );
};
