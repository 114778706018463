import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { withAuthUser } from '@cube3/state/src/redux/components/withAuth';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { compose } from '@cube3/state/src/utils/component-helpers';
import { Role } from '@cube3/common/model/schema/resources/role';
// UI
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';
// types and the HOCstuff
import { useModalActions } from '../../../layout/Modals/modalActions';
import { ModalReceiverProps } from '../../../layout/Modals/ModalManager';
import React, { useEffect, useRef } from 'react';

interface ChangeRoleConfirmationModalProps extends ModalReceiverProps {
  changeRole?: Function;
  roles: Role[];
  userId: string;
  changeRoleStatus: string;
}

function ChangeRoleConfirmationModal(
  props: ChangeRoleConfirmationModalProps
): JSX.Element {
  const modalActions = useModalActions();
  const { changeRole = () => {}, modalContext, userId } = props;

  const [workspaceId] = useCurrentWorkspace();

  const { resources: roles } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'role',
    edgeLabel: 'roles'
  });

  // close modal when request was made
  const modalClosed = useRef<boolean>(false);
  const { changeRoleStatus } = props;
  useEffect(() => {
    if (
      (changeRoleStatus === statuses.SUCCESS ||
        changeRoleStatus === statuses.FAILED) &&
      !modalClosed.current
    ) {
      modalClosed.current = true;
      modalActions.goBackModals(2);
    }
  }, [changeRoleStatus, modalActions]);

  // filter new role from available roles
  const newRole = roles.filter(
    (role) => role.display_name === modalContext.selectedRole
  );

  // get the id from the new role
  const newRoleId = newRole.length && newRole[0].id;

  const defaultText = `Are you sure you want to change ${modalContext.user.full_name}'s role to ${modalContext.selectedRole}?`;
  const dangerText = `Are you sure you want to change your own role to ${modalContext.selectedRole}?`;
  const danger = userId === modalContext.user.id;

  return (
    <GenericNoticeModalUI
      renderTwoButtons={true}
      onClose={() => {
        modalActions.previousModal();
      }}
      title={danger ? 'Change your role?' : 'Change role?'}
      text={danger ? dangerText : defaultText}
      buttonText="Cancel"
      buttonColorVariant="ghost2"
      secondButtonText="Yes"
      secondButtonColorVariant={danger ? 'filledDanger' : 'filled1'}
      secondButtonClickHandler={() => {
        changeRole({
          type: 'account',
          id: modalContext.account.id,
          relationships: {
            role: {
              data: { type: 'role', id: newRoleId }
            }
          }
        });
      }}
    />
  );
}

const withChangeRole = (Wrapped) => (props) => {
  const [mutateResource, mutateResourceStatus] = useMutateResource__ALPHA({
    actionDecorators: [
      addFormId('updateAccount', {
        formState: undefined,
        useRequestStatus: true
      })
    ],
    cacheInvalidator: (role) => {
      return [{ type: 'account', id: props.modalContext.account.id }];
    }
  });
  return (
    <Wrapped
      {...props}
      changeRole={mutateResource}
      changeRoleStatus={mutateResourceStatus}
    />
  );
};

export default compose(ChangeRoleConfirmationModal)(
  withChangeRole,
  withAuthUser
);
