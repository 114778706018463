import React, { useCallback } from 'react';

// helpers
import { useModalActions } from '../../Modals/modalActions';
import { compose } from '@cube3/state/src/utils/component-helpers';

// UI
import Button from '@cube3/ui/src/Buttons/Button';
import CheckBoxStateless from '@cube3/ui/src/Buttons/CheckBoxStateless';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { List, ListItem } from '@material-ui/core';
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Asset } from '@cube3/common/model/schema/resources/asset';
import { useBroadcastMetadataForms } from '../hooks/useTVBroadcastMetadataForms';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { useTVBroadcastDestination } from '../hooks/useTVBroadcastDestination';

interface ModalReceivedProps {
  modalContext: {
    assets: Asset[];
  };
}
interface HOCMappedProps {
  loading?: boolean;
  destination: Array<{ id: string; display_name: string }>;
  exportTargetTypeId: string;
}
interface Properties
  extends ModalReceivedProps,
    HOCMappedProps,
    InjectedFormProps {
  formValues: string[];
}

const mapFormStateToProps = (state, ownProps: Properties) => {
  const values = getFormValues('export_TVad_destination')(state);
  const formValues = values
    ? Object.keys(values).reduce((acc, key) => {
        if (values[key]) {
          return [...acc, key];
        } else {
          return acc;
        }
      }, [])
    : [];
  const initialValues =
    ownProps.destination && ownProps.destination.length === 1
      ? {
          [ownProps.destination[0].display_name]: true
        }
      : undefined;

  return {
    values,
    formValues,
    initialValues
  };
};

const TVadExportDestinationModal = (props: Properties) => {
  const { modalContext, formValues, destination, loading, exportTargetTypeId } =
    props;
  const { assets } = modalContext;
  const { previousModal, openModal } = useModalActions();

  const { destroyAllForms } = useBroadcastMetadataForms(assets);

  const onNext = useCallback(() => {
    const exportTargets = destination
      .filter((des) => formValues.includes(des.display_name))
      ?.map((e) => ({ type: 'export-target', id: e.id }));

    openModal('export_TVad_qualityCheck', {
      exportTargets,
      assets,
      exportTargetType: { id: exportTargetTypeId, type: 'export-target-type' }
    });
  }, [assets, formValues, destination, exportTargetTypeId, openModal]);

  const onClose = useCallback(() => {
    previousModal();
    destroyAllForms();
  }, [destroyAllForms, previousModal]);

  const disableNext =
    !assets || !assets.length || !formValues || !formValues.length;

  return (
    <ModalMenuUI
      extraWide={true}
      title="Export TV ad • Select destination"
      onCloseEvent={onClose}
      loading={loading}
      footerRightComponent={
        <>
          <Button text={'Cancel'} colorVariant={'ghost2'} onClick={onClose} />
          <Button
            disabled={disableNext}
            colorVariant={'filled1'}
            text={'Next'}
            onClick={onNext}
          />
        </>
      }
    >
      <Typography color="contrast2">Select a destination</Typography>
      <div style={{ height: 8 }}></div>
      {!loading && (
        <List style={{ paddingLeft: 0 }}>
          {destination.length &&
            destination.map((des) => (
              <ListItem key={des.id} style={{ padding: '4px 0' }}>
                <Field
                  name={des.display_name}
                  component={({ input }) => (
                    <CheckBoxStateless
                      text={des.display_name}
                      marginCollapse={'left'}
                      checked={input.checked}
                      onChange={(checked) => input.onChange(checked)}
                    />
                  )}
                  type="checkbox"
                />
              </ListItem>
            ))}
        </List>
      )}
    </ModalMenuUI>
  );
};

const withExportDestinations = (Wrapped) => (props) => {
  const destinationConfig = useTVBroadcastDestination();
  return <Wrapped {...props} {...destinationConfig} />;
};

export default compose(TVadExportDestinationModal)(
  withExportDestinations,
  connect(mapFormStateToProps, null),
  reduxForm({
    form: 'export_TVad_destination',
    destroyOnUnmount: true
  })
);
