import * as React from 'react';
import MaybeTooltip, { MaybeTooltipProps } from '../Tooltip/MaybeTooltip';
import { GeneralSVGProperties } from '../icons/GeneralSVG';
import Cube3Button, { Cube3ButtonProps } from './Cube3Button';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// Button with just a left icon which overrides styling in buttonwithtextandicon
/// </Summary>

// const styles = (theme: Theme) =>
//   createStyles({
//     root: {
//       paddingLeft: '16px',
//       paddingRight: '16px',
//       borderRadius: '8px'
//     },
//     root_dropdown: {
//       paddingRight: '8px'
//     },
//     root_icon: {
//       paddingLeft: '12px'
//     },
//     textBox: {
//       marginLeft: '0px',
//       marginRight: '0px'
//     },
//     textBox_dropdown: {
//       marginRight: '4px'
//     },
//     textBox_icon: {
//       marginLeft: '8px'
//     }
//   });

interface Properties extends Cube3ButtonProps, MaybeTooltipProps {
  path?: string;
  isDropDown?: boolean;
  extraSVGProps?: GeneralSVGProperties;
}

function MenuButton(props: Properties) {
  const {
    path,
    colorVariant = 'covertDropdown',
    isDropDown = false,
    extraButtonProperties,
    extraSVGProps,
    disableFocus = true,
    disableHover = false,
    disabled = false,
    disabledReason = '',
    disabledReasonPlacement = 'bottom',
    variant = 'regular'
  } = props;

  return (
    <MaybeTooltip
      disabled={!disabled && !!!disabledReason}
      title={disabled && disabledReason}
      placement={disabledReasonPlacement}
    >
      <Cube3Button
        variant={isDropDown ? 'dropdownWithIcon' : variant}
        pathLeftSvg={path}
        colorVariant={colorVariant}
        extraButtonProperties={extraButtonProperties}
        extraSVGProps={extraSVGProps}
        disableFocus={disableFocus}
        disableHover={disableHover}
        {...props}
      />
    </MaybeTooltip>
  );
}

export default MenuButton;
