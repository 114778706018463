import React, { useCallback, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Tooltip,
  MenuItem
} from '@material-ui/core';

/* UI */
import { ModalNoticeUI } from '../../Modal/ModalNoticeUI';
import { Typography } from '../../typography/Typography';
import { Divider } from '../../Surface/Divider';
import Button from '../../Buttons/Button';
import MaybeTooltip from '../../Tooltip/MaybeTooltip';
import TruncatedText from '../../human/Truncate';
import { Icon } from '@cube3/cubicle/src/core/atoms/Icon/Icon';
import SelectComponent from '../../select/SelectComponent';
import { Thumbnail } from '@cube3/cubicle/src/core/atoms/Thumbnail/Thumbnail';
import {
  projectIconName,
  projectIcons
} from '@cube3/common/model/schema/resources/project';
import { Checkbox } from '@cube3/cubicle/src/core/atoms/Checkbox/Checkbox';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';

/** @author: Simon  */

interface Properties {
  onCloseEvent: () => void;
  onClickEditProject: () => void;
  onClickDeleteProject: () => void;
  onClickArchiveProject?: () => void;
  onSave?: () => void;
  dirty?: boolean;
  projectName: string;
  isArchived?: boolean;
  projectTemplate?: string;
  isTemplate?: boolean;
  /** project icon */
  icon?: string;
  setIcon?: (icon: keyof typeof projectIconName) => void;
  onSaveLabeling?: (labeling: boolean) => void;
  project?: any;
  hasLabelingFeature?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      width: '600px'
    },
    sameline: {
      marginTop: '5px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      minHeight: 32
    },
    statusLine: {
      alignItems: 'baseline'
    },
    left: {
      width: '30%'
    },
    middle: {
      width: '200px'
    },
    right: {
      marginLeft: 'auto'
    },
    icons: {
      justifyContent: 'space-between'
    }
  })
);

/** Draws a modal for project settings */
export default function ProjectSettingsModalUI(props: Properties): JSX.Element {
  const classes = useStyles(props);

  const [labelingSet, setLabeling] = useState(props.project?.allow_labeling);

  const {
    onCloseEvent,
    projectName,
    onClickDeleteProject,
    onClickEditProject,
    onClickArchiveProject,
    onSave,
    dirty = false,
    isArchived,
    projectTemplate,
    isTemplate = false,
    icon,
    setIcon,
    onSaveLabeling,
    hasLabelingFeature
  } = props;

  const onChange = useCallback(
    (val) => {
      setLabeling(val);
      onSaveLabeling(val);
    },
    [setLabeling, onSaveLabeling]
  );

  return (
    <ModalNoticeUI
      onCloseEvent={onCloseEvent}
      title={'Project Settings'}
      footerRightComponent={
        <>
          <Button
            onClick={onCloseEvent}
            colorVariant={'ghost2'}
            text={'Cancel'}
          />
          <Button
            onClick={dirty ? onSave : onCloseEvent}
            colorVariant={'filled1'}
            text={dirty ? 'Save' : 'Done'}
          />
        </>
      }
    >
      <div className={`${classes.sameline} ${classes.icons}`}>
        <Typography color={'contrast2'} className={classes.left}>
          Icon
        </Typography>
        <Icon icon={projectIconName[icon]} />
        <SelectComponent
          labelName={'Project icons'}
          value={icon}
          onChange={setIcon}
          placeholder="Pick a project icon"
          data-pw="select-project-icon"
        >
          {projectIcons.map((v) => {
            return (
              <MenuItem value={v} key={v}>
                <Icon icon={projectIconName[v]} />
                <div style={{ width: 8 }} />
                <Typography>{v} </Typography>
              </MenuItem>
            );
          })}
        </SelectComponent>
      </div>

      <Divider />

      <div className={classes.sameline}>
        <Typography color={'contrast2'} className={classes.left}>
          Name
        </Typography>

        <Typography className={classes.middle}>
          <TruncatedText str={projectName} lines={2} />
        </Typography>

        <MaybeTooltip
          title="This option is not available because this project is archived"
          disabled={!isArchived}
          className={classes.right}
        >
          <Button
            onClick={onClickEditProject}
            colorVariant={'ghost1'}
            text={'Edit'}
            disabled={isArchived}
            extraButtonProperties={{ 'data-cy': 'changeProjectsettings' }}
          />
        </MaybeTooltip>
      </div>

      <Divider />

      {!isTemplate && isArchived !== undefined && (
        <Tooltip
          title={
            isArchived
              ? 'Unarchiving puts the project on the active projects list'
              : 'Archiving hides the project from the active projects list'
          }
          placement="bottom-end"
        >
          <div className={`${classes.sameline} ${classes.statusLine}`}>
            <Typography color={'contrast2'} className={classes.left}>
              Status
            </Typography>

            <div className={classes.middle}>
              <Typography>{isArchived ? 'Archived' : 'Active'}</Typography>
              <Typography color={'contrast2'}>
                {`This project and its contents ${
                  isArchived ? 'cannot' : 'can'
                } be modified.`}
              </Typography>
            </div>

            <Button
              onClick={onClickArchiveProject}
              classes={{ root: classes.right }}
              colorVariant="ghost1"
              text={isArchived ? 'Unarchive' : 'Archive'}
              extraButtonProperties={{ 'data-cy': 'archiveProject' }}
            />
          </div>
        </Tooltip>
      )}

      {hasLabelingFeature && (
        <>
          <Divider />
          <div className={`${classes.sameline}`}>
            <Typography color={'contrast2'} className={classes.left}>
              AI Tagging
            </Typography>
            <div className={`${classes.middle}`}>
              <Checkbox
                size={SizeVariants.md}
                state={labelingSet}
                onChange={onChange}
                disabled={false}
                indeterminateValue={false}
              />
            </div>
          </div>
        </>
      )}

      {projectTemplate && (
        <>
          <div className={classes.sameline}>
            <Typography color={'contrast2'} className={classes.left}>
              Project template
            </Typography>

            <Typography color={'contrast1'} className={classes.middle}>
              {projectTemplate}
            </Typography>
          </div>
          <Divider />
        </>
      )}

      {!projectTemplate && <div className={classes.sameline}></div>}

      <div style={{ height: 24 }} />

      <Tooltip
        title={
          isArchived
            ? 'This option is not available because this project is archived'
            : isTemplate
            ? ''
            : 'Deleting permanently removes a project from the workspace'
        }
        placement="bottom-end"
      >
        <div className={classes.sameline}>
          <Typography color={'contrast2'} className={classes.left}>
            Delete {isTemplate ? 'template' : 'project'}
          </Typography>
          <Button
            onClick={onClickDeleteProject}
            classes={{ root: classes.right }}
            colorVariant="danger"
            text={'Delete'}
            disabled={isArchived}
            extraButtonProperties={{ 'data-cy': 'deleteProject' }}
          />
        </div>
      </Tooltip>
    </ModalNoticeUI>
  );
}
