import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '@cube3/ui/src/typography/Typography';
import Cube3Button from '@cube3/ui/src/Buttons/Cube3Button';
import Thumbnail from '@cube3/ui/src/thumbnails/Thumbnail';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

interface Props<T = any> {
  logo: string | React.ReactNode;
  name: string;
  description?: string;
  mainButtonClick?(): void;
  mainButtonLabel?: string;
  accountButtonClick?(acc: T): void;
  accountButtonLabel?: string;
  accounts: T[];
  makeCols?(T): string[];
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 8,
      paddingTop: '20px',
      paddingBottom: '20px'
    },
    row: {
      display: 'flex',
      alignItems: 'center'
    },
    left: {
      width: '30%'
    },
    middle: {
      width: '40%',
      marginRight: 20
    },
    right: {
      marginLeft: 'auto'
    },
    thumbnail: {
      width: 64,
      marginRight: 20,
      borderRadius: theme.surfaceCorners.mediumEmphasis,
      overflow: 'hidden'
    },
    connected: {
      marginLeft: 84
    },
    account: {
      padding: `8px 0`
    }
  })
);

export const ConnectedAccountsListingBlock: React.FC<Props> = (props) => {
  const {
    logo,
    name,
    description,
    mainButtonClick,
    mainButtonLabel = 'Connect account',
    accountButtonClick,
    accountButtonLabel = 'Disconnect',
    accounts,
    makeCols = (ca) => (ca ? [ca.display_name] : undefined),
    loading
  } = props;

  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.root}>
        <Shimmer height={32} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.thumbnail}>
          {typeof logo === 'string' ? (
            <Thumbnail
              src={logo}
              ratio="oneToOne"
              cover={true}
              showCheckerboard={false}
            />
          ) : (
            logo
          )}
        </div>

        <div className={classes.middle}>
          <Typography color="contrast1" variant="heading2">
            {name}
          </Typography>
          {description && (
            <Typography color={'contrast2'}>{description}</Typography>
          )}
        </div>

        {mainButtonClick && (
          <Cube3Button
            className={classes.right}
            colorVariant="ghost1"
            text={mainButtonLabel}
            onClick={mainButtonClick}
          />
        )}
      </div>
      <div className={classes.connected}>
        {accounts?.map((ca, idx, arr) => {
          return (
            <>
              <Divider />
              <div className={`${classes.account} ${classes.row}`}>
                {makeCols(ca)?.map((c) => (
                  <div key={c} className={classes.middle}>
                    <Typography>{c}</Typography>
                  </div>
                ))}
                {!makeCols(ca) && <Shimmer height={32} width={128} />}
                {accountButtonClick && (
                  <div className={classes.right}>
                    <Cube3Button
                      disabled={!ca || !makeCols(ca)}
                      className={classes.right}
                      colorVariant="danger"
                      text={accountButtonLabel}
                      onClick={() => accountButtonClick(ca)}
                    />
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
