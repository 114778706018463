import { AssetMimeTypes, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface RenderPreset extends ResourceBase {
  type: 'render-preset';
  attributes: {
    display_name: string;
    group: string;
    height: number;
    width: number;
    supported_mimetypes: AssetMimeTypes[];
  };
  relationships: {
    workspace: Related<'workspace'>;
  };
}

export const renderPreset: RawModel = {
  type: 'render-preset',
  plural: 'render-presets',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    group: 'string',
    height: 'number',
    width: 'number'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'render-presets'
    }
  }
};
