import { isIFrame } from '@cube3/compose/src/utils/isIFrame';
import { useCurrentUser } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { LegacyTokens } from '@cube3/state/src/wrapped-cube-client/authService';
import { useCallback, useEffect, useMemo } from 'react';

const init = (
  iframe: HTMLIFrameElement,
  token: LegacyTokens,
  url: string,
  email: string
) => {
  iframe.contentWindow.postMessage(
    { fn: 'init', args: [{ ...token, email }] },
    url
  );
};
const confirm = (iframe: HTMLIFrameElement, url: string) => {
  iframe.contentWindow.postMessage({ fn: 'confirm', args: undefined }, url);
};

const startModule = (
  iframe: HTMLIFrameElement,
  libraryItemIds: string,
  url: string
) => {
  iframe.contentWindow.postMessage(
    { fn: 'start', args: [{ assets: libraryItemIds }] },
    url
  );
};

export const useBroadcastLegacyActions = ({
  iframe,
  token,
  libraryItemIds,
  url,
  onCancel,
  onConfirm,
  onLoad,
  onReady
}) => {
  const user = useCurrentUser()[1];
  const email = user?.email_address;

  const iframeValid = useMemo(() => {
    return isIFrame(iframe) && iframe.contentWindow;
  }, [iframe]);

  const handleReady = useCallback(() => {
    startModule(iframe, libraryItemIds, url);
    onReady();
  }, [iframe, libraryItemIds, url, onReady]);

  const handleMessage = useCallback(
    e => {
      if (e.origin !== new URL(url).origin) {
        console.error('Invalid message origin', e);
        return;
      }

      e.data === 'ready' && handleReady();
      e.data === 'cancel' && onCancel();
      e.data === 'confirm' && onConfirm();
    },
    [onCancel, onConfirm, handleReady, url]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  const handleLoad = useCallback(() => {
    init(iframe, token, url, email);
    onLoad();
  }, [iframe, url, token, email, onLoad]);

  const handleConfirm = useCallback(() => {
    confirm(iframe, url);
  }, [iframe, url]);

  return iframeValid ? [handleLoad, handleConfirm] : [];
};
