import { PublicResource, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Url extends PublicResource, ResourceBase {
  location: string;
}

export const url: RawModel = {
  type: 'url',
  plural: 'url',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    location: 'url'
  },
  relationships: {}
};
