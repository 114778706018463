import React from 'react';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useModalActions } from '../../Modals/modalActions';

import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import MenuButton from '@cube3/ui/src/Buttons/MenuButton';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { iconAdd, iconClipShare } from '@cube3/ui/src/icons/GeneralSVG';
import Button from '@cube3/ui/src/Buttons/Button';

interface Props extends ModalReceiverProps {
  modalContext: { parentResource: ResourceIdentifier };
}

export const AttachContractPrompt: React.FunctionComponent<Props> = (props) => {
  const { modalContext } = props;
  const { parentResource } = modalContext;
  const modalActions = useModalActions();

  return (
    <ModalNoticeUI
      title={`Attach contract to ${parentResource.type}`}
      onCloseEvent={() => modalActions.previousModal()}
      component="form"
      footerRightComponent={
        <Button
          colorVariant="ghost2"
          text="Cancel"
          onClick={() => modalActions.previousModal()}
        />
      }
    >
      <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
        <MenuButton
          text="Create new contract"
          path={iconAdd}
          variant={'withIcon'}
          colorVariant="ghost3"
          size="big"
          onClick={() =>
            modalActions.openModal('create_contract', {
              parentResource
            })
          }
        />
        <Divider />
        <MenuButton
          text="Attach existing contract"
          path={iconClipShare}
          variant={'withIcon'}
          colorVariant="ghost2"
          size="big"
          onClick={() =>
            modalActions.openModal(
              'attach_existing_contract',
              {
                parentResource
              },
              false
            )
          }
        />
      </div>
    </ModalNoticeUI>
  );
};
