import React from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { UseStyles } from '../UseStylesHelper/UseStylesHelper';

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        display: 'block',
        width: '100%',
        height: 'auto',
        position: 'relative',
        paddingTop: `100%`,
        textAlign: 'center'
      },
      oneToOne: {},
      fourToThree: {
        paddingTop: `${100 / (4 / 3)}%`
      },
      sixteenToNine: {
        paddingTop: `${100 / (16 / 9)}%`
      },
      sixteenToEleven: {
        paddingTop: `${100 / (16 / 11)}%`
      }
    }),
  { name: 'C3ThumbnailContainerRatio' }
);

interface Properties extends UseStyles<typeof useStyles> {
  ratio: string;
}

export const ThumbnailContainerRatio: React.FC<React.PropsWithChildren<Properties>> = props => {
  const { ratio } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes[ratio]}`}>{props.children}</div>
  );
};
