import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '@cube3/ui/src/themes/custom-theme';

import { CubicleThemeProvider } from '@cube3/cubicle/src/theme/CubicleThemeProvider';
import { CubicleToMuiThemeProvider } from '@cube3/cubicle/src/theme/CubicleToMuiThemeProvider';
import {
  CubicleFallbackProvider,
  FallbackModes,
  registerFallback
} from '@cube3/cubicle/src/utils/CubicleFallback';

// fallbacks
// not ready cubicle components
import MarkerButtonLegacy from '@cube3/ui/src/Buttons/MarkerButton';
import { MarkerButton } from '@cube3/cubicle/src/core/atoms/Button/MarkerButton';

import { Dialog } from '@cube3/cubicle/src/core/templates/Dialog/Dialog';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { LinkUnavailableNotice } from '@cube3/cubicle/src/core/organisms/Modals/LinkUnavailableNotice';
import ShareLinkUnavailable from '@cube3/ui/src/Prefabs/shareLink/Modals/ShareLinkExpired/ShareLinkUnavailable';
import { List as CubicleList } from '@cube3/cubicle/src/icons/List';
import { MenuItem as CubicleMenuItem } from '@cube3/cubicle/src/core/atoms/MenuItem';
import { ListItem as CubicleListItem } from '@cube3/cubicle/src/core/atoms/ListItem';

/// ready to use Cubicle components
import { Time as TimeLegacy } from '@cube3/ui/src/human';
import { Time } from '@cube3/cubicle/src/core/utility/Time';
import { Truncate } from '@cube3/cubicle/src/core/utility/Truncate';
import TruncateLegacy from '@cube3/ui/src/human/Truncate';
import { Scrollbar } from '@cube3/cubicle/src/core/atoms/Scrollbar';
import ScrollbarLegacy from '@cube3/ui/src/Scrollbar/Scrollbar';
// import { Button, ButtonProps } from '@cube3/cubicle/src/core/atoms/Button/';
// import ButtonLegacy from '@cube3/ui/src/Buttons/Button';
// import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
// import { Typography as TypographyLegacy } from '@cube3/ui/src/typography/Typography';
import { Thumbnail } from '@cube3/cubicle/src/core/atoms/Thumbnail/Thumbnail';
import ThumbnailLegacy from '@cube3/ui/src/thumbnails/Thumbnail';
import { Tag, ChipProps } from '@cube3/cubicle/src/core/atoms/Tag/Tag';
import { Tag as TagLegacy } from '@cube3/ui/src/tags/Tag';
import { Avatar, AvatarProps } from '@cube3/cubicle/src/core/atoms/Avatar';
import AvatarLegacy from '@cube3/ui/src/User/UserAvatar/UserAvatar';
import { CircularProgress, List, ListItem, MenuItem } from '@material-ui/core';
import { Loader } from '@cube3/cubicle/src/core/atoms/Loader/Loader';
import { Properties } from '@cube3/cubicle/src/core/molecules/Properties';
import PropertiesRenderer from '@cube3/ui/src/detailView/Properties/PropertyRenderer';
import Select from '@cube3/cubicle/src/core/atoms/Select/Select';
import SelectLegacy from '@cube3/ui/src/select/SelectComponent';

//#region Not ready Cubicle components

registerFallback(MarkerButton, MarkerButtonLegacy, false, (props) => props);

registerFallback(Dialog, ModalNoticeUI, false, (props) => props);
registerFallback(Loader, CircularProgress, false, (props) => ({
  variant: props.variant,
  color: 'primary',
  size: '3rem'
}));
registerFallback(CubicleList, List, false, (props) => props);
registerFallback(CubicleListItem, ListItem, false, (props) => props);
registerFallback(CubicleMenuItem, MenuItem, false, (props) => props);

//#region Ready Cubicle components

// registerFallback(Button, ButtonLegacy, true, (props: ButtonProps) => {
//   const iconLeft = parseIconPath[props.iconLeft as string] || props.iconLeft;
//   const iconRight = parseIconPath[props.iconRight as string] || props.iconRight;

//   return {
//     ...props,
//     text: props.label,
//     pathLeftSvg: props.label && iconLeft,
//     pathRightSvg: props.label && iconRight,
//     pathCenterSvg: !props.label && (iconLeft || iconRight),
//     colorVariant:
//       props['colorVariant'] ||
//       parseButtonBG[props.background]?.[props.buttonStyle] ||
//       parseButtonBG[props.background] ||
//       'ghost1'
//   };
// });
// registerFallback(Typography, TypographyLegacy, true, (props) => {
//   const color = props.color + '-' + props.colorVariant;

//   return {
//     ...props,
//     typographyStyle: parsedTypographyProps[props.variant],
//     color: props.color === 'primary' ? 'brand1' : parsedTypographyProps[color]
//   };
// });
registerFallback(Select, SelectLegacy, true, (props) => props);
registerFallback(Thumbnail, ThumbnailLegacy, true, (props) => props);
registerFallback(Time, TimeLegacy, true, (props) => props);
registerFallback(Truncate, TruncateLegacy, true, (props) => props);
registerFallback(Scrollbar, ScrollbarLegacy, true, (props) => props);
registerFallback(
  LinkUnavailableNotice,
  ShareLinkUnavailable,
  false,
  (props) => ({ logo: props.logo, title: props.title })
);
registerFallback(Tag, TagLegacy, true, (props: ChipProps) => ({
  text: props.label,
  color: props.color,
  size: props.size === 'sm' ? 'small' : 'regular'
}));

registerFallback(Avatar, AvatarLegacy, true, (props: AvatarProps) => ({
  textPrimary: props.userInitial?.[0],
  userInfo: { email_address: props.tooltip, profile_picture: props.imageUrl },
  size: parsedAvatarSize[props.size] || 'small'
}));
registerFallback(Properties, PropertiesRenderer, true, (props) => props);

// end fallbacks

export const MergedThemeProvider = (props) => {
  const { children } = props;
  return (
    <CubicleFallbackProvider initialFallbackMode={FallbackModes.ONLY_READY}>
      <CubicleThemeProvider default="dark">
        <CubicleToMuiThemeProvider>
          <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </CubicleToMuiThemeProvider>
      </CubicleThemeProvider>
    </CubicleFallbackProvider>
  );
};

const parsedAvatarSize = {
  sm: 'small',
  md: 'medium',
  lg: 'large'
};
