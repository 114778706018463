import {
  EdgeMutation,
  mutations
} from '../../../../ducks/resource-edges/actions';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';

/**
 * Get changes between two arrays of ResourceIdentifiers
 * @param oldArray
 * @param newArray
 */

export const getChanges = (
  oldArray: ResourceIdentifier[],
  newArray: ResourceIdentifier[]
): EdgeMutation[] => {
  if (!oldArray || !newArray) {
    return;
  }

  const changes = [];

  const merged = [...oldArray, ...newArray];

  merged
    .map((item) => ({ id: item.id, type: item.type }))
    .forEach((item) => {
      const inOld = oldArray.filter(
        (o) => o.id === item.id && o.type === item.type
      ).length;
      const inNew = newArray.filter(
        (n) => n.id === item.id && n.type === item.type
      ).length;

      if (inOld && !inNew) {
        changes.push({
          mutationType: mutations.MUTATION_REMOVE,
          resource: item
        });
      } else if (!inOld && inNew) {
        changes.push({ mutationType: mutations.MUTATION_ADD, resource: item });
      }
    });

  return changes;
};
