import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export type CommentResolutionType = 'resolve' | 'unresolve';
export interface Comment extends ResourceBase {
  type: 'comment';
  author_name: string;
  resolution?: CommentResolutionType;
  message: string;
  start?: number;
  end?: number;
  drawing_svg?: string;

  relationships: {
    node: Related<'content-tree-node'>;
    thread?: Related<'comment'>;
  };
}

export const comment: RawModel = {
  type: 'comment',
  plural: 'comments',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    author_name: 'string',
    resolution: 'string',
    message: 'string',
    start: 'date',
    end: 'date',
    drawing_svg: 'string'
  },
  relationships: {
    thread: {
      type: 'comment',
      binding: ['one', 'many'],
      reverse: 'comments'
    },
    node: {
      type: 'content-tree-node',
      binding: ['one', 'many'],
      reverse: 'comments'
    }
  }
};
