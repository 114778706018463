import React, { useCallback, useState } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Collapse,
  CircularProgress
} from '@material-ui/core';
import { isMobile, isIOS } from 'react-device-detect';

/* import components */
import CustomFade, { CustomFadeProperties } from '../../helpers/CustomFade';
import { FrameControls } from './FrameControls';
import { SnapshotsList } from './SnapshotControls';

// hooks
import { PlaybackButtons } from './PlaybackButtons';
import { FullscreenButton } from './FullscreenButton';
import { VolumeControls } from './VolumeControls';
import {
  PlaybackSlider,
  WithAnnotationPlaybackSliderProps
} from './PlaybackSlider';
import { PlaybackTimeDisplay } from './PlaybackTimeDisplay';
import {
  useVideoPlayerKeyboardShortcuts,
  videoPlayerShortCutsTips
} from './hooks/useVideoPlayerKeyboardShortcuts';
import { useClassName } from '../../utils/useClassName';
import CustomIconButton from '../../Buttons/IconButton';
import { iconGesture } from '../../icons/GeneralSVG';
import { Comment } from '@cube3/common/model/schema/resources/comment';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes fade-in': {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    mobile: {},
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      bottom: '0',
      paddingBottom: theme.customSpacing.padding[20],
      paddingLeft: theme.customSpacing.padding[24],
      paddingRight: theme.customSpacing.padding[24],
      paddingTop: theme.customSpacing.padding[32],
      '&$mobile': {
        padding: theme.customSpacing.padding[8],
        position: 'absolute',
        width: 'calc(100% - 16px)',
        bottom: 0
      }
    },
    fullscreen: {
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      backgroundColor: 'transparent'
    },
    buttonContainer: {
      marginTop: theme.customSpacing.margin[8],
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&$mobile': {
        marginTop: 0
      }
    },
    slider: {
      color: theme.customPalette.surfaceAccent.onBase.shade4,
      height: '6px',
      '$mobile &': {
        height: 0,
        padding: 0
      }
    },
    leftSection: {
      display: 'flex',
      alignItems: 'center'
    },
    rail: {
      opacity: 1,
      height: 4
    },
    frameControl: {
      visibility: 'hidden',
      pointerEvents: 'none'
    },
    leftButtonsContainer: {
      width: theme.sizing[144],
      marginRight: theme.customSpacing.margin[24],
      display: 'flex',
      justifyContent: 'space-between',
      '$mobile &': {
        width: 'auto',
        marginRight: 0
      }
    },
    durationContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: theme.sizing[96],
      alignItems: 'center',
      maxWidth: theme.sizing[96]
      // '$mobile &': {
      //   width: 'auto'
      // }
    },
    rightSection: {
      display: 'flex',
      alignItems: 'center'
    },
    volumeSlider: {
      margin: `0px ${theme.customSpacing.margin[24]} 0px ${theme.customSpacing.margin[12]}`,
      width: theme.sizing[96],
      color: theme.customPalette.line.onBase1.contrast4,
      '$mobile &': {
        margin: 0,
        width: theme.sizing[32]
      }
    },
    scrim: (showSnapshotsList) => {
      return {
        pointerEvents: 'none',
        position: 'absolute',
        height: '92px', // 20 bottom , 40 middle (content), 32 top
        left: 0,
        bottom: showSnapshotsList ? 178 : 0,
        right: 0,
        background: theme.customPalette.surfaceAccent.onBase.scrim3,
        zIndex: -1,
        transition: 'bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      };
    },

    sliderTrack: {
      height: '4px',
      backgroundColor: theme.customPalette.surface.brand1,
      borderRadius: theme.surfaceCorners.mediumEmphasis
    },
    thumb: {
      backgroundColor: theme.customPalette.surface.brand1,
      boxShadow: 'none !important',
      transformOrigin: '50% 50%',
      marginTop: '-4px'
    },
    trackAfter: {
      backgroundColor: theme.customPalette.surfaceAccent.onBase.shade4
    },
    trackBefore: {
      borderRadius: theme.surfaceCorners.mediumEmphasis,
      backgroundColor: theme.customPalette.surface.brand1
    },
    seeking: {
      filter: 'grayscale(60%) brightness(0.6)',
      cursor: 'wait',
      '& *': {
        cursor: 'wait'
      }
    },
    buffering: {},
    spinner: {
      position: 'absolute',
      top: 0,
      right: 24,
      animation: '$fade-in 0.75s ease-in 0.75s 1',
      animationFillMode: 'both',
      borderRadius: 999,
      background: 'rgba(0,0,0,0.32)',
      width: 20,
      height: 20,
      '& > div': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
      }
    },
    iconButton: {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: theme.customPalette.surfaceAccent.onBase.hover1
      }
    }
  })
);

interface ControlsProps
  extends CustomFadeProperties,
    WithAnnotationPlaybackSliderProps {
  playing: boolean;
  duration: number;
  playedSeconds: number;
  loop: boolean;
  volume: number;
  playPause: () => void;
  onSeekChange: (event: Object, value: any) => void;
  toggleLoop: () => void;
  jumpBack: () => void;
  jumpForward: () => void;
  stepFrame?: (step: number) => void;
  setVolume: (value: number) => void;
  toggleFullscreenAction: () => void;
  playedTime: string;
  played: number;
  durationDateFormat: string;
  fullscreen: boolean;
  fullscreenControls?: JSX.Element;
  replay: () => void;
  toggleMute: (newVal?: boolean) => void;
  muted: boolean;
  otherModalsActive: boolean;
  frameRate?: number;
  createSnapshot: () => void;
  goToTime?: (sec: number) => void;
  canTakeSnapshot: boolean;
  snapshots: any;
  activeFrame: number;
  error: string;
  seeking: boolean;
  buffering: boolean;
  buffered?: TimeRanges;
  stripSrc?: string;
  // review tool
  drawingBarToggled?: boolean;
  toggleDrawingBar?: () => void;
}

const Controls: React.FC<ControlsProps> = React.memo((props) => {
  const {
    playing,
    duration,
    playedSeconds,
    loop,
    volume,
    playPause,
    onSeekChange,
    toggleLoop,
    jumpBack,
    jumpForward,
    stepFrame,
    setVolume,
    playedTime,
    played,
    durationDateFormat,
    toggleFullscreenAction,
    fullscreen,
    fullscreenControls,
    replay,
    toggleMute,
    muted,
    otherModalsActive,
    focus,
    // for steping through a video frame by frame
    frameRate,
    createSnapshot,
    canTakeSnapshot,
    snapshots,
    goToTime,
    activeFrame,
    error,
    seeking,
    buffering,
    buffered,
    stripSrc,
    // review tools
    toggleDrawingBar,
    drawingBarToggled,
    comments,
    onClickComment
  } = props;

  useVideoPlayerKeyboardShortcuts({
    focus,
    otherModalsActive,
    playPause,
    toggleMute,
    jumpBack,
    jumpForward,
    toggleLoop,
    toggleFullscreenAction,
    stepFrame
  });

  const [showSnapshotsList, setShowSnapshotsList] = useState(false);
  const [lastCaptureSeconds, setLastCaptureSeconds] = useState<number[]>([]);
  const canCapture = !lastCaptureSeconds.includes(playedSeconds);

  const handleClickSnapshot = useCallback(() => {
    if (!canCapture) return;
    createSnapshot();
    setLastCaptureSeconds((prev) => [...prev, playedSeconds]);
  }, [canCapture, createSnapshot, playedSeconds]);

  const toggleSnapshotsList = useCallback(() => {
    if (snapshots && snapshots.length === 0 && playedSeconds === 0) {
      playPause();
      playPause();
    }
    setShowSnapshotsList(!showSnapshotsList);
  }, [playPause, playedSeconds, showSnapshotsList, snapshots]);

  const classes = useStyles(showSnapshotsList);

  const handleClickDrawingBar = useCallback(() => {
    toggleDrawingBar();
    if (playing) {
      playPause();
    }
  }, [playing, toggleDrawingBar, playPause]);
  return (
    <>
      <div
        className={useClassName(
          classes.container,
          fullscreen && classes.fullscreen,
          isMobile && classes.mobile,
          seeking && classes.seeking,
          buffering && classes.buffering
        )}
      >
        {(seeking || buffering) && (
          <div className={classes.spinner}>
            <div>
              <CircularProgress variant="indeterminate" size={16} />
            </div>
          </div>
        )}
        <div className={classes.scrim} />

        {fullscreen && fullscreenControls}

        <PlaybackSlider
          played={played}
          playing={playing}
          duration={duration}
          onSeekChange={onSeekChange}
          buffered={buffered}
          stripSrc={stripSrc}
          // review tools
          comments={comments}
          onClickComment={onClickComment}
          frameRate={frameRate}
        />

        <div className={classes.buttonContainer} data-prevent-deselect>
          <div className={classes.leftSection}>
            <div className={classes.leftButtonsContainer}>
              <PlaybackButtons
                finished={duration && playedSeconds === duration}
                playing={playing}
                loop={loop}
                jumpBack={jumpBack}
                toggleLoop={toggleLoop}
                replay={replay}
                playPause={playPause}
                shortCutTips={videoPlayerShortCutsTips.playControls}
              />
            </div>
            <div className={classes.durationContainer}>
              <PlaybackTimeDisplay
                playedTime={playedTime}
                durationDateFormat={durationDateFormat}
              />
            </div>
          </div>

          {!isMobile && frameRate && (
            <div className={playing ? classes.frameControl : ''}>
              <FrameControls
                stepFrame={stepFrame}
                activeFrame={activeFrame}
                onToggle={toggleSnapshotsList}
                showSnapshotsList={showSnapshotsList}
                canTakeSnapshot={canTakeSnapshot}
                shortCutTips={videoPlayerShortCutsTips.frameControls}
              />
            </div>
          )}
          {toggleDrawingBar && (
            <CustomIconButton
              colorVariant={drawingBarToggled ? 'ghost1' : 'ghost3'}
              size="regular"
              path={iconGesture}
              extraIconButtonProps={{
                classes: { root: classes.iconButton }
              }}
              onClick={handleClickDrawingBar}
            />
          )}

          <div className={classes.rightSection}>
            {!isMobile && (
              <VolumeControls
                volume={volume}
                muted={muted}
                toggleMute={toggleMute}
                setVolume={setVolume}
                shortCutTip={videoPlayerShortCutsTips.mute}
              />
            )}

            {!isIOS && (
              <FullscreenButton
                fullscreen={fullscreen}
                toggleFullscreenAction={toggleFullscreenAction}
                shortCutTip={videoPlayerShortCutsTips.fullscreen}
              />
            )}
          </div>
        </div>
      </div>
      {canTakeSnapshot && (
        <Collapse in={showSnapshotsList}>
          <SnapshotsList
            snapshots={snapshots}
            error={error}
            canCapture={!buffering && !seeking && canCapture}
            onClickSnapshot={handleClickSnapshot}
            onClickThumbnail={goToTime}
          />
        </Collapse>
      )}
    </>
  );
});

export const VideoPreviewControls = CustomFade(Controls);
export const VideoPreviewControlsMobile = Controls;
