import React, { useCallback, useEffect, useState } from 'react';
import { useSelection } from '@cube3/state/src/redux/components/useSelection';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import Button from '@cube3/ui/src/Buttons/Button';
import {
  ExportMultiAssetsInfo,
  ExportSingleAssetInfo
} from '../prefabs/ExportAssetsInfo';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { useExportOptions } from '../hooks/useExportOptions';
import { ExportOptionsForm } from '../prefabs/ExportOptionsForm';
import { ExportOptionsFormState } from '../state/exportOptionsState';
import { Padding } from '../../Contracts/prefabs/Padding';
import { useExportTargetDefaults } from '../../AdminCenter/views/WorkspaceIntegrationsView/hooks/useExportTargetDefaults';
import { useDispatch } from 'react-redux';
import { queueTabs, toggleMediaqueue } from '@cube3/state/src/redux/ducks/ui';
import {
  YouTubeParameters,
  YoutubePlaylist
} from '@cube3/common/model/resource-types';

interface Props {
  modalContext: {
    channel: {
      id: string;
      display_name: string;
      display_image?: string;
    };
    exportTargetId: string;
    exportTargetTypeId: string;
    categories?: { id: string; display_name: string }[];
    playlists?: YoutubePlaylist[];
  };
}

export const YoutubeExportOptionsModal: React.FC<Props> = (props) => {
  const { channel, exportTargetId, exportTargetTypeId, categories, playlists } =
    props.modalContext;
  const [formState, setFormState] = useState<ExportOptionsFormState>();
  const { previousModal, closeAllModals, goBackModals } = useModalActions();
  const { selection } = useSelection();
  const { createExportStatus, handleCreateExport, exportBatchId } =
    useExportOptions<YouTubeParameters>({
      selection,
      exportTargetId
    });

  const handleSubmit = useCallback(() => {
    const categoryId = categories?.find(
      (cat) => cat.display_name === formState.category
    )?.id;

    handleCreateExport({
      recipients: formState.recipients,
      integration_parameters: {
        ...formState,
        channel: channel.id,
        channel_name: channel.display_name,
        tags: formState.tags.length > 0 ? formState.tags : undefined,
        category_id: formState.category,
        playlists: formState.playlists?.map((p) => p.id),
        visibility: formState.visibility.toLowerCase()
      }
    });
  }, [categories, handleCreateExport, formState, channel]);

  const dispatch = useDispatch();

  // open export status view modal
  useEffect(() => {
    if (exportBatchId && createExportStatus === statuses.SUCCESS) {
      closeAllModals();
      dispatch(toggleMediaqueue(true, queueTabs.EXPORTS));
    }
  }, [
    channel,
    createExportStatus,
    exportBatchId,
    formState,
    closeAllModals,
    dispatch
  ]);

  const [defaults, defaultsStatus] =
    useExportTargetDefaults(exportTargetTypeId);

  const handleCancel = useCallback(() => {
    goBackModals(2);
  }, [goBackModals]);

  return (
    <ModalMenuUI
      title="Export to YouTube • Options"
      wide={true}
      multiplyContentSpace={10}
      loading={
        defaultsStatus !== statuses.SUCCESS ||
        (createExportStatus &&
          createExportStatus !== statuses.SUCCESS &&
          createExportStatus !== statuses.FAILED)
      }
      onCloseEvent={handleCancel}
      footerLeftComponent={
        <Button
          text={'Previous'}
          onClick={previousModal}
          colorVariant={'ghost2'}
        />
      }
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={handleCancel}
            disabled={false}
          />
          <Button
            disabled={formState?.invalid}
            colorVariant={'filled1'}
            text={'Export'}
            onClick={handleSubmit}
          />
        </>
      }
    >
      <Padding>
        {selection.length > 0 ? (
          <>
            {selection.length > 1 ? (
              <ExportMultiAssetsInfo selection={selection} />
            ) : (
              <ExportSingleAssetInfo selection={selection?.[0]} />
            )}
            <Divider gutter={true} />

            <Padding>
              <ExportOptionsForm
                selection={selection}
                // categories={categories}
                onFormChange={setFormState}
                defaults={defaults}
                playlists={playlists}
              />
            </Padding>
          </>
        ) : null}
      </Padding>
    </ModalMenuUI>
  );
};
