import { withModalActions as withModalActionsReal } from '@cube3/state/src/redux/components/withModalActions';
import {
  useModalActions as useModalActionsReal,
  ModalActionProps as ModalActionPropsReal
} from '@cube3/state/src/redux/Hooks/useModalActions';
import { useCallback } from 'react';
import { GenericNoticeModalUIProps } from '../../../../../../ui/src/modals/GenericNoticeModalUI';
//
import { ModalNames } from './modalIndex';
//
/* Some wrapping for convenience */
export interface ModalActionProps extends ModalActionPropsReal<ModalNames> {}

export const withModalActions = <P extends ModalActionProps>(
  Component: React.ComponentType<P>
) => withModalActionsReal<ModalNames, P>(Component);

export const useModalActions = () => {
  const modalActions = useModalActionsReal<ModalNames>();
  const { openModal, previousModal } = modalActions;

  const prompt = useCallback(
    (config: (previousModal: () => void) => GenericNoticeModalUIProps) => {
      openModal('generic_notice', config(previousModal), false);
    },
    [openModal, previousModal]
  );

  return { ...modalActions, prompt };
};
