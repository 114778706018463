import React, { useMemo } from 'react';

import { Divider } from '@cube3/ui/src/Surface/Divider';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { FieldArray } from 'redux-form';
import { TeamsPickerSuggestionContainer } from '../subComponents/multipleResourcePicker/TeamsPickerSuggestionContainer';

export const WorkspaceTeamsPicker = ({
  clearInputField,
  form,
  teamSearchInput,
  workspaceTeams
}) => {
  const suggestedTeams = useMemo(() => {
    if (!teamSearchInput) return workspaceTeams;
    return workspaceTeams.filter(team =>
      team.display_name.toLowerCase().includes(teamSearchInput.toLowerCase())
    );
  }, [workspaceTeams, teamSearchInput]);

  return (
    <div style={{ margin: '25px 0' }}>
      <Divider />
      <div style={{ height: 25 }}></div>
      <Typography color="contrast2" typographyStyle="body2">
        Add to teams...
      </Typography>
      <div style={{ height: 8 }}></div>
      <FieldArray
        name="teams"
        component={TeamsPickerSuggestionContainer}
        props={{
          clearInput: clearInputField,
          form,
          searchInput: teamSearchInput,
          allSuggestion: suggestedTeams,
          autoFocus: false,
          placeholder: 'Enter a team name'
        }}
      />
    </div>
  );
};
