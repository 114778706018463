import React from 'react';
import { iconCameraAdd, iconError } from '../../icons/GeneralSVG';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';

import MaybeTooltip from '../../Tooltip/MaybeTooltip';
import { Typography } from '../../typography/Typography';
import { StatusBlip } from '../../StatusBlip';
import MenuButton from '../../Buttons/MenuButton';

export type ThumbnailType = {
  thumbnail: string;
  captured_at: number;
};

export interface SnapshotType extends ThumbnailType {
  id: string;
  processing: boolean;
  failed: boolean;
  display_image?: string;
}

interface SnapshotsListProps {
  snapshots: SnapshotType[];
  canCapture: boolean;
  error: string;
  onClickSnapshot: () => void;
  onClickThumbnail: (playedSeconds: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.customPalette.surfaceAccent.onBase.scrim1
    },
    mobile: {},
    helperText: {
      padding: '24px 24px 0px 24px'
    },
    listItem: {
      position: 'relative',
      padding: '0 24px',
      animation: '$fade 1s linear'
    },
    imageContainer: {
      borderRadius: '5px',
      border: `solid 1px ${theme.customPalette.primary.iconWhiteMediumEmphasis}`,
      maxWidth: 160,
      maxHeight: 90
    },
    image: {
      borderRadius: 'inherit',
      maxWidth: 'inherit',
      maxHeight: 'inherit',
      objectFit: 'contain',
      objectPosition: 'center',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7
      }
    },
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      width: 'auto',
      background: theme.customPalette.backgroundAccent.blackAccent3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      animation: '$fade 1s linear'
    },
    snapshotsList: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      bottom: '0',
      padding: '24px 24px 20px 24px',
      listStyle: 'none',
      justifyContent: 'start',
      flexWrap: 'nowrap',
      height: theme.sizing[90],
      top: 'calc(100% - 90px)',
      left: 0,
      margin: 0,
      overflow: 'auto hidden',
      '&$mobile': {
        padding: theme.customSpacing.padding[8],
        position: 'absolute',
        width: 'calc(100% - 16px)',
        bottom: 0
      }
    },
    snapshotButton: {
      display: 'flex',
      flexDirection: 'column',
      border: `dashed 1px ${theme.customPalette.surface.brand1}`,
      padding: '12px 6px',
      width: 160,
      height: 90,
      '&:hover': {
        backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
          theme.customPalette.primary.light,
          'primary',
          ['active', 'hover']
        )
      }
    },
    '@keyframes fade': {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    }
  })
);

export const SnapshotsList: React.FunctionComponent<SnapshotsListProps> = (
  props
) => {
  const {
    onClickSnapshot,
    onClickThumbnail,
    canCapture = false,
    snapshots = [],
    error = undefined
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        color="contrast2"
        typographyStyle="body2"
        className={classes.helperText}
      >
        Snapshots will be saved to the parent folder
      </Typography>

      <ul className={classes.snapshotsList}>
        {snapshots.map((item, index) => {
          const loading = item.processing;
          const failed = error !== undefined;
          return (
            <li className={classes.listItem} key={index}>
              {loading && (
                <div className={classes.loadingOverlay}>
                  <CircularProgress size="2rem" />
                </div>
              )}
              {failed && (
                <MaybeTooltip title={error} disabled={false}>
                  <div className={classes.loadingOverlay}>
                    <StatusBlip size="medium" color="danger" icon={iconError} />
                  </div>
                </MaybeTooltip>
              )}
              <div className={classes.imageContainer}>
                <img
                  src={item.display_image ? item.display_image : item.thumbnail}
                  alt="snapshot"
                  onClick={() => onClickThumbnail(item.captured_at)}
                  className={classes.image}
                />
              </div>
            </li>
          );
        })}
        <MenuButton
          path={iconCameraAdd}
          text="Save current frame as new image"
          onClick={onClickSnapshot}
          className={classes.snapshotButton}
          isDropDown={false}
          disabled={!canCapture}
          variant={'withIcon'}
          size="regular"
        />
      </ul>
    </div>
  );
};
