import { Related } from '../../resource-types';
import { ExportTargetImplementations } from '../../types';
import { RawModel } from '../schema';

export type ExportStateType =
  | 'rendering' // = phase 'converting'
  | 'pending'
  | 'processing' // = hase 'transfering'
  | 'success'
  | 'failed';

export type ExportsParameters<
  T extends keyof ExportTargetImplementations = any
> = {
  asset_id: string;
  target_id: string;
  parameters: {
    [k in T]: ExportTargetImplementations[T]['intergration_parameters'];
  };
  state: ExportStateType;
};

export interface ExportBatch<
  T extends keyof ExportTargetImplementations = any
> {
  id: string;
  type: 'export-batch';
  attributes: {
    created_at: string;
    state: ExportStateType;
    progress: number;
    notification_recipients: string[];
    integration_parameters: ExportTargetImplementations[T]['intergration_parameters'];
    exports: ExportsParameters<T>[];
  };
  relationships: {
    assets: Related<'asset'>[];
    'export-targets': Related<'export-target'>[];
    'export-target-types': Related<'export-target-type'>[];
  };
}

export const exportBatch: RawModel = {
  type: 'export-batch',
  plural: 'export-batches',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
  },
  relationships: {
    assets: {
      type: 'asset',
      binding: ['many', 'one'],
      reverse: 'export-batch'
    },
    'export-targets': {
      type: 'export-target',
      binding: ['many', 'one'],
      reverse: 'export-batch'
    },
    'export-target-types': {
      type: 'export-target-type',
      binding: ['many', 'one'],
      reverse: 'export-batch'
    }
  }
};
