import { Related, ResourceBase, ResourceType } from '../../resource-types';
import { RawModel } from '../schema';

export interface MeiliSearchResult extends ResourceBase {
  mime_type: string; // TODO consider adding a finite set here 'MIME_TYPE_CUBE_FOLDER' | MIME_TYPE_CUBE_PROJECT  etc
  project_id: string;
  relationships: {
    resource: Related<ResourceType>;
  };
}

export const meiliSearchResult: RawModel = {
  type: 'meili-search-result',
  plural: 'meili-search-results',
  JSONApi: true,
  canonicalPath: 'search-results',
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
