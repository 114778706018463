import { Tag } from '@cube3/common/model/schema/resources/tag';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';

export const findMatchingTag =
  (set: Array<EditableTag | Tag>) => (t: Partial<EditableTag | Tag>) =>
    set.find((at) => tagsEqual(t, at));

export const tagsEqual = (t1, t2) => {
  if (!t1 || !t2) {
    return false;
  }

  const idMatch = t1.id && t1.id === t2.id;
  if (idMatch) {
    return true;
  }
  const labelMatch =
    !!t2.text && t1.text?.toLowerCase() === t2.text?.toLowerCase();

  const categoryMatch =
    (t1.category && t2.category && t1.category.id === t2.category.id) ||
    (t1.relationships &&
      t2.relationships &&
      t1.relationships.category.id === t2.relationships.category.id);

  if (labelMatch && categoryMatch) {
    return true;
  }
  const colorMatch =
    !t2.text && !t1.text && categoryMatch && t1.color && t1.color === t2.color;
  if (colorMatch) {
    return true;
  }
  return false;
};
