import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Team extends ResourceBase {
  type: 'team';
  display_name: string;
  display_picture?: string;
  relationships: {
    members: Related<'account'>[];
    workspace: Related<'workspace'>;
  };
}

export const team: RawModel = {
  type: 'team',
  plural: 'teams',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    display_image: 'URL'
  },
  relationships: {
    members: {
      type: 'account',
      binding: ['many', 'many'],
      reverse: 'teams'
    },
    workspace: {
      type: 'workspace',
      binding: ['many', 'many'],
      reverse: 'teams'
    }
  }
};
