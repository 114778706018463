import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import {
  Typography,
  TypographyProps
} from '../../../components/typography/Typography';
import CheckBox, { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import { colors } from '../Button/deprecated/types';
import GeneralSVG, {
  iconCheckbox,
  iconCheck,
  iconCheckboxIndeterminate,
  iconCheckboxFilled
} from '../../../icons/GeneralSVG';
import { useClassName } from '../../../utils/useClassName';

/// @Author: Simon @ AmbassadorsLAB
/// <Summary>
/// Checkbox with optional text property. Due to the need of being able to click the text aswell,
/// this version is stateless, which means it only sends the next state when clicked but does not
/// toggle when clicked (must be done from a controlling component)
/// </Summary>

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      checkBoxRoot: {
        width: '32px',
        height: '32px',
        padding: 0,
        marginLeft: 'initial',
        marginRight: 'initial',
        borderRadius: theme.surfaceCorners.lowEmphasis,
        '&:hover': {
          backgroundColor:
            theme.customPalette.surfaceState.getSurfaceStateColor(
              theme.customPalette.surface.base1,
              'onBase',
              ['hover1']
            ),
          '&$disableHover': {
            backgroundColor: 'inherit'
          }
        }
      },
      disableHover: {},
      marginCollapsLeft: {
        marginLeft: '-6px'
      },
      marginCollapseRight: {
        marginRight: '-6px'
      },
      checkedCheckboxContainer: {
        width: '18px',
        height: '18px',
        position: 'relative'
      },
      checkIcon: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%,0)',
        borderRadius: theme.surfaceCorners.lowEmphasis,
        width: '18px',
        height: '18px',
        backgroundColor: theme.customPalette.surface.brand1
      },
      labelRoot: {
        display: 'inline-flex',
        alignItems: 'center',
        alignContent: 'center'
      },
      enabled: {
        '&:hover': {
          cursor: 'pointer'
        }
      },
      disabled: {
        filter: 'grayscale(.5) brightness(0.5)'
      },
      typographyBox: {
        alignSelf: 'center',
        marginLeft: theme.customSpacing.margin[8],
        userSelect: 'none' // prevent highlighting of text
      }
    }),
  { name: 'C3CustomCheckBox' }
);
interface Properties {
  checked: boolean;
  text?: string;
  indeterminate?: boolean;
  disabled?: boolean;
  colorCheckBox?: colors;
  onChange?: (checked: boolean) => void;
  extraCheckBoxProperties?: CheckboxProps;
  typographyProps?: TypographyProps;
  classes?: { root: string };
  className?: string;
  marginCollapse?: 'none' | 'both' | 'left' | 'right';
  disableHover?: boolean;
}

function CustomCheckBox(props: Properties) {
  const {
    text,
    disabled = false,
    extraCheckBoxProperties,
    typographyProps,
    indeterminate,
    checked,
    onChange,
    className,
    disableHover,
    marginCollapse
  } = props;

  const classes = useStyles();

  return (
    <label
      className={useClassName(
        classes.labelRoot,
        classes.root,
        props.classes?.root,
        !disabled ? classes.enabled : classes.disabled
      )}
    >
      <CheckBox
        onChange={onChange ? () => onChange(!checked) : undefined}
        className={useClassName(
          classes.checkBoxRoot,
          disableHover && classes.disableHover,
          (marginCollapse === 'both' || marginCollapse === 'left') &&
            classes.marginCollapsLeft,
          (marginCollapse === 'both' || marginCollapse === 'right') &&
            classes.marginCollapseRight,
          className
        )}
        disabled={disabled}
        indeterminate={indeterminate}
        checked={checked}
        disableRipple={true}
        color={'default'}
        icon={<GeneralSVG size={'big'} path={iconCheckbox} color="contrast2" />}
        checkedIcon={
          <span className={classes.checkedCheckboxContainer}>
            <GeneralSVG
              size={'regular'}
              path={iconCheckboxFilled}
              color="brand1"
              surface="onBase"
              svgProps={{ viewBox: '0 0 32 32' }}
              className={classes.checkIcon}
            />
            <GeneralSVG
              size={'regular'}
              path={iconCheck}
              color="contrast1"
              surface="onBrand"
              svgProps={{ viewBox: '0 0 32 32' }}
              className={classes.checkIcon}
            />
          </span>
        }
        indeterminateIcon={
          <GeneralSVG
            size={'big'}
            path={iconCheckboxIndeterminate}
            color="brand1"
          />
        }
        {...extraCheckBoxProperties}
      />

      {text && (
        <Typography
          className={classes.typographyBox}
          color="contrast1"
          {...typographyProps}
        >
          {text}
        </Typography>
      )}
    </label>
  );
}

export default React.memo(CustomCheckBox);
