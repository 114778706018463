import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import Joyride, { Step, STATUS, EVENTS, ACTIONS } from 'react-joyride';
import { TourStep } from '@cube3/cubicle/src/core/molecules/TourStep';
import { useBuildTourSteps } from './Hooks/useBuildTourSteps';
import { useDispatch } from 'react-redux';
import { setTour } from '@cube3/state/src/redux/ducks/ui/actions';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { workspaceViewSteps } from './tourSteps';

export const Tour = React.memo(() => {
  const { steps, stepIndex, setStepIndex } = useContext(TourContext);

  const tour = useTypedSelector((state) => state.ui.tour);

  const dispatch = useDispatch();
  const onSetTour = useCallback(
    (touring: boolean) => {
      dispatch(setTour(touring));
    },
    [dispatch, setTour]
  );

  const handleJoyrideCallback = useCallback(
    (data) => {
      const { status, type, action, index } = data;
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        onSetTour(false);
        setStepIndex(0);
      } else if (
        ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
          type
        )
      ) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setStepIndex(stepIndex !== index ? stepIndex : nextStepIndex);
      }
    },
    [onSetTour, stepIndex]
  );

  return (
    <Joyride
      steps={steps}
      run={tour}
      continuous={true}
      callback={handleJoyrideCallback}
      showSkipButton={true}
      floaterProps={{
        hideArrow: true
      }}
      styles={{
        overlay: { height: '100vh' }
      }}
      tooltipComponent={(props) => (
        <TourStep {...props} setStepIndex={setStepIndex} />
      )}
      stepIndex={stepIndex}
      disableOverlayClose={true}
      disableCloseOnEsc={true}
      spotlightPadding={stepIndex === 0 && 0}
    />
  );
});

export const TourContext = createContext<{
  steps: Step[];
  /** a controlled tour */
  stepIndex: number;
  setStepIndex: (idx: number) => number | void;
}>(null);

export const TourProvider = (props) => {
  const [stepIndex, setStepIndex] = useState(0);

  const steps = useBuildTourSteps(workspaceViewSteps);

  const value = useMemo(() => {
    return {
      stepIndex,
      steps,
      setStepIndex
    };
  }, [stepIndex, steps, setStepIndex]);

  return (
    <TourContext.Provider value={value}>{props.children}</TourContext.Provider>
  );
};
