import React, { useCallback, useEffect, useState } from 'react';
import { makeCSS } from '../../utils/makeCSS';
import { cx } from '@emotion/css';
import { SizeVariants } from '../../theme/themes';

/*A visual indicator that lets the users choose with which color they want to draw on the panel*/
/**
 * We use the outline props purely for the design.
 * The prop allowClick adds an extra boxShadow, outlined prop must be true for allowClick to add it.
 * For now the style with the two boxShadows is only used for the draw controls of the video.
 */
export const COLORS = [
  'red',
  'orange',
  'yellow',
  'green',
  'indigo',
  'violet',
  'gray',
  'black',
  'white',
  'ghost'
] as const;

export type ColorVariant = (typeof COLORS)[number];
export interface ColorProps {
  /**If it's choosed, it added boxShadow to indicate it.*/
  active?: boolean;
  /**The color it can have*/
  color?: ColorVariant;
  /**The different sizes*/
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg;
  /**Use outline  */
  outlined?: boolean;
  /**Allow user interface, make component responsive*/
  allowClick?: boolean;
  /**Whether is clicked or not*/
  onClick?(newState: boolean): void;
}

const useCSS = makeCSS(({ theme }) => {
  const shadowOverrides = {
    black: theme.color.background.black,
    white: theme.color.background.white,
    violet: theme.color.violet['30'],
    yellow: theme.color.yellow['25']
  };

  const colorClasses = COLORS.reduce((colorClasses, color) => {
    const backgroundColor =
      theme.color.background[color]?.main || theme.color.background[color];

    const shadowColor = shadowOverrides[color] || theme.color[color]?.['35'];

    colorClasses[color] = {
      background: backgroundColor,
      boxShadow: `0px 0px 0px 2px ${theme.color.background.neutral}, 0px 0px 0px 4px ${shadowColor}`
    };

    if (color === 'ghost') {
      colorClasses[color] = {
        background: 'none',
        border: `1px solid ${theme.color.background.gray.main}`,
        boxShadow: `0px 0px 0px 2px ${theme.color.background.neutral}, 0px 0px 0px 4px ${theme.color.gray['35']}`
      };
    }
    return colorClasses;
  }, {});

  return {
    root: {
      position: 'relative',
      cursor: 'default',
      pointerEvents: 'none',
      display: 'flex',
      borderRadius: theme.borderRadius.full
    },
    allowClick: {
      cursor: 'pointer',
      pointerEvents: 'all'
    },

    ...colorClasses,
    //sizes
    'size-sm': {
      width: theme.spacing[2],
      height: theme.spacing[2]
    },
    'size-md': {
      width: theme.spacing[3],
      height: theme.spacing[3]
    },
    'size-lg': {
      width: theme.spacing[4],
      height: theme.spacing[4]
    },
    defaultState: {
      boxShadow: `0px 0px 0px 2px ${theme.color.background.neutral}`
    },
    noOutline: {
      boxShadow: 'none',
      outline: 'none'
    }
  };
});

export const Color = (props: ColorProps) => {
  const {
    active = false,
    color = 'indigo',
    size = SizeVariants.md,
    outlined = false,
    allowClick = false,
    onClick
  } = props;

  const classes = useCSS();

  const [clicked, setClicked] = useState(active || false);

  useEffect(() => {
    setClicked(active || false);
  }, [active]);

  const handleClick = useCallback(() => {
    setClicked(!clicked);
    if (onClick) {
      onClick(!clicked);
    }
  }, [onClick, clicked, setClicked]);

  return (
    <div
      onClick={handleClick}
      className={cx(
        classes.root,
        classes[color],
        classes[`size-${size}`],
        !outlined ? classes.noOutline : '',
        allowClick ? classes.allowClick : '',
        outlined ? (clicked ? '' : classes.defaultState) : classes.noOutline
      )}
    ></div>
  );
};
