import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '../typography/Typography';
import Color from 'color';
import checkerboardImage from '../assets/images/checkerboard.svg?url';
import { useSurfaceClasses } from '../Surface/Surface';
import { CircularProgress } from '../Progress/CircularProgress';

const styles = (theme: Theme) =>
  createStyles({
    mobile: {},
    root: {
      position: 'relative',
      '&$mobile': {
        position: 'unset'
      }
    },
    success: {
      // backgroundColor: theme.customPalette.success,
      '& $image': {
        filter: 'blur(0px)',
        transition: 'filter 0.5s ease'
      }
    },
    failed: {
      // backgroundColor: theme.customPalette.dangerError,
      '&$mobile': {
        // height: '30vh',
        minHeight: 'calc(30vh - 56px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    loading: {
      // backgroundColor: theme.customPalette.backgroundAccent.whiteAccent1,
      '& $image': {
        filter: 'blur(5px)'
      }
    },
    image: {
      display: 'block',
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      '$mobile &': {
        width: '100%',
        maxHeight: '60vh'
      }
    },
    fullSize: {
      maxHeight: 'none',
      maxWidth: 'none'
    },
    checkerboard: {
      background: `url('${checkerboardImage}')`,
      '$mobile &': {
        background: 'unset',
        backgroundColor: theme.customPalette.surface.shade2
      }
    },

    spinner: {},
    error: {},
    centered: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      '$mobile &': {
        position: 'unset',
        top: 'unset',
        left: 'unset',
        transform: 'unset'
        // top: 'calc(50% - 38px)' // minus controls bar
      }
    }
  });

const arrayToDataUrl = colorData => {
  let rgbaPixels = colorData;

  if (typeof colorData === 'string') {
    rgbaPixels = [
      [
        Color(colorData)
          .rgb()
          .array()
          .map((p, idx) => (idx === 3 ? p * 255 : p))
      ]
    ];
  }

  const canvas = document.createElement('canvas');

  const rgbaPixelsFlat = rgbaPixels.reduce((acc, val) => {
    return [...acc, ...val];
  }, []);
  canvas.height = rgbaPixels.length;
  canvas.width = rgbaPixels[0].length;

  document.body.appendChild(canvas);
  const ctx = canvas.getContext('2d');
  const peekData = ctx.createImageData(canvas.width, canvas.height);
  for (let i = 0; i < peekData.data.length; i += 4) {
    peekData.data[i + 0] = rgbaPixelsFlat[Math.floor(i / 4)][0]; // R value
    peekData.data[i + 1] = rgbaPixelsFlat[Math.floor(i / 4)][1]; // G value
    peekData.data[i + 2] = rgbaPixelsFlat[Math.floor(i / 4)][2]; // B value
    peekData.data[i + 3] =
      rgbaPixelsFlat[Math.floor(i / 4)][3] !== undefined
        ? rgbaPixelsFlat[Math.floor(i / 4)][3]
        : 255; // A value
  }
  ctx.putImageData(peekData, 0, 0);
  document.body.removeChild(canvas);
  return canvas.toDataURL();
};

// example placeholder
// const placeholder = [
//     [[0,0,0], [0,128,128], [0,0,255]],
//     [[0,255,0], [128,128,0], [255,0,0]]
// ];

const ImageLoader = ({
  src,
  alt,
  fallback = undefined,
  peekHeader = 'Image-Peek',
  peekPlaceholder = undefined,
  classes,
  className = '',
  background = undefined,
  peekUrl = undefined,
  dimensions = { width: undefined, height: undefined },
  fullSize = false,
  floatingEffect = false
}) => {
  const backgroundClass =
    background === 'checkerboard' ? classes.checkerboard : '';
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [peek, setPeek] = useState(undefined);

  // classes for when floating effect is true: corner radius, borders and elevation
  const floatingEffectClasses = useSurfaceClasses({
    floating: true,
    corners: 'rounded',
    floatingHeight: 2,
    cornerRadius: 'lowEmphasis'
  });

  useEffect(() => {
    setSuccess(false);
    setFailed(false);
    setPeek(undefined);

    if (!src) {
      return;
    }

    if (peekPlaceholder) {
      setPeek(arrayToDataUrl(peekPlaceholder));
    }

    if (peekUrl) {
      setPeek(peekUrl);
    }

    new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = resolve;
      image.onerror = reject;
      image.src = src;
    })
      .then(ev => {
        setSuccess(true);
        setFailed(false);
      })
      .catch(err => {
        setSuccess(false);
        setFailed(true);
      });
  }, [peekPlaceholder, peekUrl, src]);

  // sometimes the dimensions from `poster` !== the image's dimension, then the image will be stretched.
  const dim = useRef({ width: 'auto', height: dimensions.height }).current;

  if (success) {
    return (
      <div
        key={src}
        className={`${className} ${classes.root} ${classes.succes} ${
          floatingEffect ? floatingEffectClasses : ''
        } ${isMobile ? classes.mobile : ''}`}
      >
        <img
          key="image"
          className={[
            classes.image,
            backgroundClass,
            fullSize ? classes.fullSize : ''
          ].join(' ')}
          src={src}
          alt={alt}
          style={dim}
        />
      </div>
    );
  }

  if (failed) {
    return (
      <div
        key={src}
        className={`${className} ${classes.root} ${classes.failed} ${
          isMobile ? classes.mobile : ''
        }`}
      >
        {fallback && <img className={classes.image} src={fallback} alt={alt} />}
        {!fallback && (
          <Typography
            className={`${classes.error} ${classes.centered}`}
            typographyProps={{ align: 'center' }}
          >
            {' '}
            No image available{' '}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div
      key={src}
      className={[className, classes.root, classes.loading].join(' ')}
      style={dim}
    >
      {peek ? (
        <img
          key="image"
          className={[
            classes.image,
            backgroundClass,
            fullSize ? classes.fullSize : ''
          ].join(' ')}
          src={peek}
          alt={alt}
          style={dim}
        />
      ) : null}
      <div className={`${classes.spinner} ${classes.centered}`}>
        <CircularProgress variant="indeterminate" size={24} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ImageLoader);
