import { useState, useEffect, useRef, useMemo } from 'react';

export function getSizeOfImage(src: string, callback: Function, fromCache) {
  if (!src) {
    return () => null;
  }

  let canceled;
  let img = new Image();
  img.src = src;
  img.onload = function() {
    const properties = {
      type: 'success',
      width: img.width,
      height: img.height,
      fromCache
    };
    if (!canceled) {
      callback(properties);
    }
  };
  img.onerror = function(error) {
    if (!canceled) {
      callback(error);
    }
  };

  return () => {
    img.src = '';
    canceled = true;
  };
}

export function useImageDimensions(src) {
  const img = useRef(new Image());

  img.current?.setAttribute('decoding', 'async');

  const currentImg = img.current;

  let state = useMemo(() => {
    if (src) {
      currentImg.src = src;
    } else {
      currentImg.src = '';
    }

    if (currentImg.src && currentImg.complete) {
      return {
        type: 'success',
        width: currentImg.width,
        height: currentImg.height,
        fromCache: true
      };
    }
  }, [src, currentImg]);

  const [widthAndHeight, setWidthAndHeight] = useState<{
    type: string;
    width?: number;
    height?: number;
    fromCache?: boolean;
  }>(state);

  const cb = useRef(response => {
    if (response.type === 'error') {
      setWidthAndHeight({ type: 'error' });
    }
    if (response.type === 'success') {
      setWidthAndHeight({ ...response });
    }
  });

  const fromCache = state?.fromCache;

  useEffect(() => {
    let cancel = getSizeOfImage(src, cb.current, fromCache);
    return () => {
      cancel();
      setWidthAndHeight(null);
    };
  }, [src, fromCache]);

  return widthAndHeight;
}
