import { uuidv4 } from '../../utils/uuid';
import Client from '../../wrapped-cube-client';
import { collectConfigMetrics } from './endpoint-metrics';
import { Params } from '../../wrapped-cube-client/params';
import {
  ResourceIdentifier,
  ResourceType
} from '@cube3/common/model/resource-types';

type ValOrMaker<V = unknown, P = any> = V | ((props: P) => V | undefined);

// small helper to allow functions as config fields, that are called with props
export const functionize = <V = unknown, P extends {} = {}>(
  val: ValOrMaker<V, P>
): ((props: P) => V | undefined) =>
  (typeof val === 'function' ? val : (props) => val) as (props: P) => V;

export type CacheStrategy =
  | 'cache-only'
  | 'prefer-cache'
  // For the hooks, 'fetch-on-mount' fetches once per component mount as soon a a valid config is presented
  | 'fetch-on-mount'
  | 'fetch-on-update'
  | 'fetch-when-needed';
// config fields accept a function as value,
// it will be called with props as an arguments
// and should return the actual config value
export interface WithResourceConfig<P extends {}> {
  resourceType: ValOrMaker<ResourceType, P>;
  // type of the resource you want to retrieve
  resourceId: ValOrMaker<string, P>;
  // as defined in wrapped-cube-client mapping
  // the id of the resource (if this returns undefined, we wont fire a retrieve)
  mapper?: ValOrMaker<object | string, P>;
  // map the retrieved resource state, config can be (or return in case of function):
  // - string:
  //   * resource gets mapped to a prop conforming to the string
  //   * request status gets mapped to <string>Status
  //   * retriever method gets mapped to <string>Retrieve
  //   * a loading prop gets injected as well (alias for status === IN_FLIGHT)
  // - object:
  //   * object keys are used to create a mapping from the (parsed) state
  //   * e.g. { 'user' : 'resource' } maps "resource" in the state data to a "user" props
  //   * loading prop is NOT injected, to allow maximum control
  // - default:
  //   - resource = resource
  //   - status = status
  //   - retrieve = retrieve
  //   - loading = status === IN_FLIGHT
  parser?: Function;
  // lets you parse the retrieved state data and return it in another shape
  // you also have acces to the wrapped components own props
  // parser gets called with (data,props)
  mutable?: ValOrMaker<boolean, P>;
  // determines whether the resource if mutable, WIP
  strategy?: ValOrMaker<CacheStrategy, P>;
  // WIP strategies for retrieving data
}

export const normalizeResourceConfig = <P extends {}>(
  config: WithResourceConfig<P>
) => {
  // in many instances we want to use props to configure, what resource
  // to retrieve, thats why we allow functions in the config that get
  // injected with the props via one of the MANY wrappers

  collectConfigMetrics(config, 'resource');

  return {
    ...config,
    resourceType: functionize<ResourceType, P>(config.resourceType),
    resourceId: functionize<string, P>(config.resourceId),
    mapper: functionize(config.mapper),
    parser: config.parser ? config.parser : (data: unknown, props: P) => data,
    mutable: functionize(config.mutable),
    strategy: functionize(config.strategy || 'fetch-when-needed')
  };
};

type WithResourceConfigNormalized = ReturnType<typeof normalizeResourceConfig>;

// a helper to derive the final config from props
export const parseResourceConfig = <P extends {}>(
  config: WithResourceConfigNormalized,
  props: P
) => ({
  resourceType: config.resourceType(props),
  resourceId: config.resourceId(props),
  mutable: !!config.mutable(props),
  mapper: config.mapper(props),
  parser: (data: unknown) => config.parser(data, props),
  strategy: config.strategy(props)
});

// final config after parse
export interface ParsedResourceConfig {
  resourceType: ResourceType;
  resourceId: string;
  mutable: boolean;
  mapper: object | string;
  parser: Function;
  strategy: CacheStrategy;
}
// config fields accept a function as value,
// it will be called with props as an arguments
// and should return the actual config value
export interface WithResourceEdgesConfig {
  resourceType?: ValOrMaker<ResourceType>;
  // type of the "parent" resource you want to \
  // retrieve edges for
  resourceId?: ValOrMaker<string>;
  // as defined in wrapped-cube-client mapping
  // the id of the resource (if this returns undefined, we wont fire a retrieve)
  edgeType: ValOrMaker<ResourceType>;
  // type of the nested resource you want to retrieved
  edgeLabel?: ValOrMaker<string>;
  // defaults to plural of edgeType
  // this can be used to override the nested endpoint
  // the cube-client uses to retrieve the edges
  // e.g: {
  //   resourceType: 'user',
  //   resourceId: '1231231-ffff-34234-ffff',
  //   edgeType: 'user',
  //   edgeLabel: 'friends'
  // }
  // would retrieve data from
  // "<apiRoot>/users/1231231-ffff-34234-ffff/friends"
  //
  params?: ValOrMaker<object>;
  // additional query parameters
  // e.g. {
  //   filter:{
  //     only_best: true
  //   }
  // }
  // becomes ? filter[only_best]=true
  mapper?: ValOrMaker<object | string>;
  // map the retrieved resource state, config can be (or return in case of function):
  // - string:
  //   * resource gets mapped to a prop conforming to the string
  //   * request status gets mapped to <string>Status
  //   * retriever method gets mapped to <string>Retrieve
  //   * a loading prop gets injected as well (alias for status === IN_FLIGHT)
  // - object:
  //   * object keys are used to create a mapping from the (parsed) state
  //   * e.g. { 'user' : 'resource' } maps "resource" in the state data to a "user" props
  //   * loading prop is NOT injected, to allow maximum control
  // - default:
  //   - resource = resource
  //   - status = status
  //   - retrieve = retrieve
  //   - loading = status === IN_FLIGHT
  parser?: Function;
  // lets you parse the retrieved state data and return it in another shape
  // you also have acces to the wrapped components own props
  // parser gets called with (data,props)
  mutable?: ValOrMaker<boolean>;
  // determines whether the resource if mutable, WIP
  strategy?: ((props: any) => CacheStrategy) | CacheStrategy;
  // WIP strategies for retrieving data
  allPages?: ValOrMaker<boolean>;
}

const hasNoResource = (config: WithResourceEdgesConfig) => {
  return !config.resourceType && !config.resourceId;
};

export const normalizeResourceEdgesConfig = <P extends {}>(
  config: WithResourceEdgesConfig
) => {
  // in many instances we want to use props to configure, what resource
  // to retrieve, thats why we allow functions in the config that get
  // injected with the props via one of the MANY wrappers
  collectConfigMetrics(config, 'list');

  return {
    ...config,
    resourceType: functionize(config.resourceType),
    resourceId: functionize(config.resourceId),
    rootEdges: functionize(hasNoResource(config)),
    edgeType: functionize(config.edgeType),
    edgeLabel: functionize(config.edgeLabel),
    params: functionize(config.params),
    mapper: functionize(config.mapper),
    parser: config.parser ? config.parser : (data: unknown, props: P) => data,
    mutable: functionize(config.mutable),
    strategy: functionize(config.strategy || 'fetch-when-needed'),
    allPages: functionize(config.allPages)
  };
};

type WithResourceEdgesConfigNormalized = ReturnType<
  typeof normalizeResourceEdgesConfig
>;

// a helper to derive the final config from props
export const parseResourceEdgesConfig = <P extends {}>(
  config: WithResourceEdgesConfigNormalized,
  props: P
) => ({
  resourceType: config.resourceType(props),
  resourceId: config.resourceId(props),
  rootEdges: config.rootEdges(props),
  edgeType: config.edgeType(props),
  edgeLabel:
    config.edgeLabel(props) ||
    Client.pluralize(config.edgeType(props) as string),
  params: (config.params(props) || {}) as Params,
  mutable: !!config.mutable(props),
  mapper: config.mapper(props),
  parser: (data: unknown) => config.parser(data, props),
  strategy: config.strategy(props),
  allPages: config.allPages(props)
});

export interface ParsedResourceEdgesConfig {
  resourceType?: ResourceType;
  resourceId?: string;
  rootEdges: boolean;
  edgeType: ResourceType;
  edgeLabel: string;
  params: Params;
  mutable: boolean;
  mapper: object | string;
  parser: Function;
  strategy: CacheStrategy;
  allPages?: boolean;
}
// config fields accept a function as value,
// it will be called with props as an arguments
// and should return the actual config value
export interface WithCreateResourceConfig {
  tempId?: ValOrMaker<string>;
  // temporary id if you don't like the generated one, for reasons
  resourceType: ValOrMaker<ResourceType>;
  // type of resource you want to create
  ancestorType?: ValOrMaker<ResourceType>;
  // type of the "parent" resource you want to
  // add a nested resource to (optional)
  ancestorId?: ValOrMaker<string>;
  // ID of the "parent" resource you want to
  // add a nested resource to (optional)
  edgeLabel?: ValOrMaker<string>;
  // only used when adding a nested resource
  // defaults to plural of resourceType
  // this can be used to override the nested endpoint
  // the cube-client uses to create the resource
  // e.g: {
  //   ancestorType: 'user',
  //   ancestorId: '1231231-ffff-34234-ffff',
  //   resourceType: 'user',
  //   edgeLabel: 'friends'
  // }
  // would post data to
  // "<apiRoot>/users/1231231-ffff-34234-ffff/friends"
  //
  mapper?: ValOrMaker<object | string>;
  // map the retrieved resource state, config can be (or return in case of function):
  // - string:
  //   * createResource fn gets mapped to create<capitalized string>
  //   * createdResourceId gets mapped to created<capitalized string>Id
  //   * request status gets mapped to <string>Status
  //   * temporary gets mapped to <string>TemporaryId
  //   * a loading prop gets injected as well (alias for status === IN_FLIGHT)
  // - object:
  //   * object keys are used to create a mapping from the (parsed) state
  //   * e.g. { 'user' : 'resource' } maps "resource" in the state data to a "user" props
  //   * loading prop is NOT injected, to allow maximum control
  // - default:
  //   - createResource = createResource
  //   - createdResourceId = createdResourceId
  //   - createStatus = status
  //   - temporary = temporary
  //   - loading = status === IN_FLIGHT
}

// in many instances we want to use props to configure, what resource
// to retrieve, thats why we allow functions in the config that get
// injected with the props via one of the MANY wrappers
export const normalizeCreateResourceConfig = (
  config: WithCreateResourceConfig
) => {
  collectConfigMetrics(config, 'list');

  return {
    resourceType: functionize(config.resourceType),
    tempId: functionize(config.tempId),
    ancestorType: functionize(config.ancestorType),
    ancestorId: functionize(config.ancestorId),
    edgeLabel: functionize(config.edgeLabel),
    mapper: functionize(config.mapper)
  };
};

type WithCreateResourceConfigNormalized = ReturnType<
  typeof normalizeCreateResourceConfig
>;

// a helper to derive the final config from props
export const parseCreateResourceConfig = <P extends {}>(
  config: WithCreateResourceConfigNormalized,
  props: P
) => {
  return {
    resourceType: config.resourceType(props),
    tempId: config.tempId(props) || uuidv4(),
    ancestorType: config.ancestorType(props),
    ancestorId: config.ancestorId(props),
    edgeLabel:
      config.edgeLabel(props) ||
      Client.pluralize(config.resourceType(props) as string),
    mapper: config.mapper(props)
  };
};

// uploads

export interface WithUploadsConfig {
  parentFolderId?: ValOrMaker<string>;
  parentResource?: ValOrMaker<ResourceIdentifier>;
}

export const normalizeUploadsConfig = (config: WithUploadsConfig) => {
  return {
    parentFolderId: functionize(config.parentFolderId),
    parentResource: functionize(config.parentResource)
  };
};

type WithUploadsConfigNormalized = ReturnType<typeof normalizeUploadsConfig>;

export const parseUploadsConfig = <P extends {}>(
  config: WithUploadsConfigNormalized,
  props: P
) => ({
  parentFolderId:
    config.parentFolderId(props) ||
    (config.parentResource(props)?.type === 'folder'
      ? config.parentResource(props)?.id
      : undefined),
  parentResource: config.parentResource(props)
});

export interface WithTokenLoginConfig {
  token: string;
  reviewLink?: boolean;
}

// token login
export const normalizeTokenLoginConfig = (config: WithTokenLoginConfig) => ({
  token: functionize(config.token),
  reviewLink: functionize(config.reviewLink)
});

type WithTokenLoginConfigNormalized = ReturnType<
  typeof normalizeTokenLoginConfig
>;

export const parseTokenLoginConfig = <P extends {}>(
  config: WithTokenLoginConfigNormalized,
  props: P
) => ({
  token: config.token(props),
  reviewLink: config.reviewLink(props)
});
