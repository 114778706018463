import { MediaPlayer } from '@cube3/ui/src/previews/VideoPreview/MediaPlayer';
import { useCallback, useEffect, useRef, useState } from 'react';

const DRAG_TRESHOLD = 10;

// generate event listeners to control video.currenttime by dragging mouse
export const useDragSeek = (videoRef: MediaPlayer) => {
  const [dragging, setDragging] = useState<MouseEvent>(null);
  const handleMouseDown = useCallback((ev) => {
    ignoreClick.current = false;
    setDragging(ev);
  }, []);

  const ignoreClick = useRef(false);

  useEffect(() => {
    if (!dragging || !videoRef) {
      return;
    }

    const onSeek = (ev = dragging) => {
      const time = getSecondsFromPos(
        dragging.target as HTMLDivElement,
        ev,
        videoRef
      );

      if (isDrag(dragging, ev)) {
        videoRef.seekTo(time, 'seconds');
      }
    };

    onSeek();

    const onUp = (ev) => {
      if (isDrag(dragging, ev)) {
        ignoreClick.current = true;
      }
      setDragging(null);
    };

    window.addEventListener('mousemove', onSeek, true);
    window.addEventListener('mouseup', onUp);
    // window.document.addEventListener('mouseleave', onUp);
    // window.addEventListener('mouseout', onUp);

    return () => {
      window.removeEventListener('mousemove', onSeek, true);
      window.removeEventListener('mouseup', onUp);
      // window.document.removeEventListener('mouseleave', onUp);

      // window.removeEventListener('mouseout', onUp);
    };
  }, [dragging, videoRef]);

  // double click seeks and plays
  const handleDoubleClick = useCallback(
    (ev) => {
      const time = getSecondsFromPos(ev.target as HTMLDivElement, ev, videoRef);

      videoRef.seekTo(time, 'seconds');

      videoRef.playPause(true, false);
    },
    [videoRef]
  );

  // click  plays / pauses
  const handleClick = useCallback(
    (ev) => {
      if (ignoreClick.current) {
        return;
      }

      videoRef.playPause();
    },
    [videoRef]
  );

  return { handleMouseDown, handleClick, handleDoubleClick };
};

// determine wheter to treat event as click or drag
const isDrag = (initial, current) => {
  return (
    current.timestamp - initial.timestamp > 350 ||
    Math.max(
      Math.abs(current.clientX - initial.clientX),
      Math.abs(current.clientY - initial.clientY)
    ) >= DRAG_TRESHOLD
  );
};

// determine the time based on the position of the mouse event within a container
const getSecondsFromPos = (container: HTMLDivElement, ev, videoRef) => {
  const bb = container.getBoundingClientRect();

  const pos = (ev.clientX - bb.x) / bb.width;

  // return time in seconds
  return videoRef.getDuration() * pos;
};
