import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

/* UI */
import { ModalNoticeUI } from '../../../Modal/ModalNoticeUI';
import Button from '../../../Buttons/Button';
import { Typography } from '../../../typography/Typography';

/// <Summary>
/// Base Modal for conformation
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    fieldSpacing: {
      marginTop: '20px',
      marginBottom: '20px'
    }
  }));

interface Properties {
  submitDisabled: boolean;
  submitError: string;
  onSubmit: (ev: React.FormEvent) => void;
  onCloseEvent: () => void;
  oldPassRenderProps: () => JSX.Element;
  newPassRenderProps: () => JSX.Element;
  confirmPassRenderProps: () => JSX.Element;
  loading: boolean;
}

const ChangePasswordModalUI: React.FC<Properties> = ( props ) => {
  const {
    onCloseEvent,
    onSubmit,
    submitDisabled,
    oldPassRenderProps,
    newPassRenderProps,
    confirmPassRenderProps,
    submitError,
    loading
  } = props;

  const classes = useStyles();

  return (
    <ModalNoticeUI
      component="form" //important for forms
      extraComponentProps={
        //important for forms
        {
          onSubmit
        }
      }
      title={'Change your password'}
      onCloseEvent={onCloseEvent}
      loading={loading}
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            onClick={onCloseEvent}
          />
          <Button
            disabled={submitDisabled}
            text={'Save'}
            colorVariant={'filled1'}
            extraButtonProperties={{ type: 'submit' }} //important for forms
          />
        </>
      }
    >
      <div className={classes.fieldSpacing}>{oldPassRenderProps()}</div>

      <div className={classes.fieldSpacing}>{newPassRenderProps()}</div>

      <div className={classes.fieldSpacing}>{confirmPassRenderProps()}</div>

      {submitError && (
        <Typography color={'danger1'} className={classes.fieldSpacing}>
          {submitError}
        </Typography>
      )}
    </ModalNoticeUI>
  );
};

export default ChangePasswordModalUI;
