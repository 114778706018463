import { useEffect, useRef, useState } from 'react';

export const useThrottledQuery = (directQuery) => {
  const [query, setQuery] = useState(null);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  // debounce the input a bit (until a faster api is used in prod)
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (!query && directQuery) {
      setQuery(directQuery);
    } else {
      timeout.current = setTimeout(() => {
        setQuery(directQuery);
      }, 300);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [directQuery]);
  return query;
};
