import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { User } from '@cube3/common/model/schema/resources/user';
import { useMemo } from 'react';
import { Account } from '@cube3/common/model/schema/resources/account';

const emptyArray = [];
/** insert user info to accounts */
export const useAccountsUser = (
  accounts: Account[],
  excludedAccounts = emptyArray
) => {
  const availableAccounts = useMemo(() => {
    if (excludedAccounts.length === 0) {
      return accounts;
    }
    const existedIds = excludedAccounts.map((a) => a.id);
    return accounts?.filter((user) => !existedIds.includes(user.id));
  }, [accounts, excludedAccounts]);

  const userIds = useMemo(() => {
    return availableAccounts?.map((ac) =>
      ac.relationships.user.data
        ? ac.relationships.user.data
        : ac.relationships.user
    );
  }, [availableAccounts]);

  const accountUsers = useResource__ALPHA<'user'>({
    resourceIdentifiers: userIds as any
  });

  const accountsWithUserInfo = useMemo(() => {
    if (
      !availableAccounts ||
      !accountUsers.resources ||
      !accountUsers.resources.length
    ) {
      return [];
    }

    return availableAccounts.map((ac) => {
      const user: User = accountUsers.resources.filter(
        (u) =>
          u.id ===
          (ac.relationships.user.data
            ? ac.relationships.user.data.id
            : ac.relationships.user.id)
      )[0];
      return {
        ...ac,
        full_name: user.full_name,
        email_address: user.email_address,
        profile_picture: user.profile_picture
      };
    });
  }, [availableAccounts, accountUsers.resources]);

  return useMemo(
    () => ({ resources: accountsWithUserInfo, loading: accountUsers.loading }),
    [accountUsers.loading, accountsWithUserInfo]
  );
};
