import { customId } from './customId';
import { isEqualValue } from './isEqualValue';

export const useHasDifferentValues = ({
  clipboardValues,
  allFormValues,
  availableKeys,
  formIds,
  isDraft
}) => {
  return (
    clipboardValues &&
    !!formIds.find(
      (id) =>
        !!availableKeys[customId(id)]?.find((k) => {
          if (!isDraft && k === 'key_contract_type') return;
          return (
            Object.hasOwnProperty.call(clipboardValues, k) &&
            !isEqualValue(allFormValues[k], clipboardValues[k])
          );
        })
    )
  );
};
