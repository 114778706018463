import { SelectionItemInterface } from './types';

// state definition

export type SelectionsState = {
  selection: SelectionItemInterface[];
  active?: SelectionItemInterface;
  visiting?: SelectionItemInterface;
  inverted: boolean;
};

export type State = SelectionsState;

export const initialState = {
  selection: [],
  active: null,
  visiting: null,
  inverted: false
};
