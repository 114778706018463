import Color from 'color';

/*  
    This function is used to get the right color of a surfaceState. 
    The function has 3 parameters:
    1. baseColor (this is the base color of the element that will receive the surfaceState color).
    2. colorType (this is either primary or secondary, based on this there will be different styles applied).
    3. states (this is an array with the actual state or states of a element, 
       so for example ['hover'] or ['selected', 'Hover']). 


  NOTE: The base color isn't used now. We will review this at a later stage.     
  */

const cache = new Map();

export function getSurfaceStateColor(
  baseColor: string,
  colorType: string,
  states: Array<string>
) {
  const key = `${baseColor}--${colorType}__${states.join('_')}`;

  let c = cache.get(key);
  if (c) {
    return c;
  }

  /*  This is a object with the different individual state colors for primary */
  const statesPrimary = {
    blackResting1: { color: 'rgb(0, 0, 0)', weight: 0.32 },
    blackResting2: { color: 'rgb(0, 0, 0)', weight: 0.16 },
    whiteResting1: { color: 'rgb(255, 255, 255)', weight: 0.04 },
    whiteResting2: { color: 'rgb(255, 255, 255)', weight: 0.02 },
    hover: { color: 'rgb(255, 255, 255)', weight: 0.04 },
    focus: { color: 'rgb(255, 255, 255)', weight: 0.08 },
    // selected: { color: 'rgb(77, 137, 255)', weight: 0.2 },
    selected: { color: 'rgba(249, 214, 73)', weight: 0.1 },
    // active: { color: 'rgb(77, 137, 255)', weight: 0.4 },
    active: { color: 'rgba(249, 214, 73)', weight: 0.2 },
    pressed: { color: 'rgb(255, 255, 255)', weight: 0.16 },
    dragged: { color: 'rgb(255, 255, 255)', weight: 0.08 },
    // dragOver: { color: 'rgb(77, 137, 255)', weight: 0.08 }
    dragOver: { color: 'rgba(249, 214, 73)', weight: 0.04 }
  };

  /*  This is a object with the different individual state colors for secondary */
  const statesSecondary = {
    blackResting1: { color: 'rgba(0, 0, 0)', weight: 0.32 },
    blackResting2: { color: 'rgba(0, 0, 0)', weight: 0.16 },
    whiteResting1: { color: 'rgba(255, 255, 255)', weight: 0.04 },
    whiteResting2: { color: 'rgba(255, 255, 255)', weight: 0.02 },
    hover: { color: 'rgba(255, 255, 255)', weight: 0.04 },
    focus: { color: 'rgba(255, 255, 255)', weight: 0.08 },
    // selected: { color: 'rgba(77, 137, 255)', weight: 0.2 },
    selected: { color: 'rgba(249, 214, 73)', weight: 0.1 },
    // active: { color: 'rgba(77, 137, 255)', weight: 0.4 },
    active: { color: 'rgba(249, 214, 73)', weight: 0.2 },
    pressed: { color: 'rgba(255, 255, 255)', weight: 0.16 },
    dragged: { color: 'rgba(255, 255, 255)', weight: 0.08 },
    // dragOver: { color: 'rgba(77, 137, 255)', weight: 0.08 }
    dragOver: { color: 'rgba(249, 214, 73)', weight: 0.04 },
    dragOverDanger: { color: 'rgba(249, 73, 108)', weight: 0.04 }
  };

  /*  This is a object with the different individual state colors for secondary */
  const onBase = {
    hover1: { color: 'rgba(255, 255, 255)', weight: 0.04 },
    selected1: { color: 'rgba(249, 214, 73)', weight: 0.12 }
  };

  /*  This is a object with the different individual state colors for secondary */
  const onBrand = {
    hover1: { color: 'rgba(255, 255, 255)', weight: 0.08 },
    selected1: { color: 'rgba(249, 214, 73)', weight: 0.32 }
  };

  /*  This is a object with the different individual state colors for secondary */
  const onDanger = {
    hover1: { color: 'rgba(255, 255, 255)', weight: 0.08 },
    interaction2: { color: 'rgba(249, 214, 73)', weight: 0.32 }
  };

  /*  This is a object used to select either primary or secondary based on the parameter */
  const colorTypes = {
    primary: statesPrimary,
    secondary: statesSecondary,
    onBase: onBase,
    onBrand: onBrand,
    onDanger: onDanger
  };

  /*  
      Map over the states and add the colors to the base color.
    */

  /*  The base color of the element that will receive a state color  */
  // let color = Color(baseColor);
  let color = Color(`rgba(0,0,0,0)`);
  let alpha = 0;

  /*  TEMPORARY FIX   */
  if (colorType === 'onBrand' || colorType === 'onDanger') {
    alpha = 1;
    color = Color(baseColor);
  }

  states.forEach(surfaceState => {
    color = color.mix(
      Color(colorTypes[colorType][surfaceState].color),
      colorTypes[colorType][surfaceState].weight
    );
    alpha += colorTypes[colorType][surfaceState].weight;
  });

  color = color.alpha(alpha);

  /*  
      return the color.
    */

  c = color.rgb().string();
  cache.set(key, c);
  return c;
}
