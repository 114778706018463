import React, { useCallback, useMemo } from 'react';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { ConnectedAccountsListingBlock } from '../../../../layout/AccountPages/prefabs/ConnectedAccountsListingBlock';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useModalActions } from '../../../../layout/Modals/modalActions';
import { useConnectedAccounts } from '../../../../layout/AccountPages/hooks/useConnectedAccounts';
import { Typography } from '@cube3/ui/src/typography/Typography';
import UserAvatar from '@cube3/ui/src/User/UserAvatar/UserAvatar';

interface Props {
  exportTargetTypeId: string;
}

export const RevokeConnectedAccountsView: React.FC<Props> = props => {
  const { exportTargetTypeId } = props;
  const [workspaceId] = useCurrentWorkspace();

  const exportTargetType = useResource__ALPHA({
    resourceType: 'export-target-type',
    resourceId: exportTargetTypeId
  }).first;

  const membersParams = useMemo(() => {
    return {
      filter: {
        exportTargetTypeId: exportTargetTypeId // NOTE: not implemented
      }
    };
  }, [exportTargetTypeId]);

  const members = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'export-target',
    edgeLabel: 'export-targets',
    params: membersParams
  }).resources;

  const name = exportTargetType?.attributes.display_name;

  const { openModal, previousModal } = useModalActions();

  const { handleDisconnect } = useConnectedAccounts(exportTargetType);

  const handleRevokeClick = useCallback(
    ca => {
      openModal(
        'connected_accounts_confirm_revoke',
        {
          service: name,
          exportTarget: ca,
          onCancel: previousModal,
          onConfirm: () => {
            handleDisconnect(ca.id);
            previousModal();
          }
        },
        false
      );
    },
    [name, previousModal, handleDisconnect, openModal]
  );

  const { resources: accounts } = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'account',
    edgeLabel: 'accounts'
  });

  const accIds = useMemo(() => {
    return accounts.map(a => a.relationships.user);
  }, [accounts]);

  const { resources: users } = useResource__ALPHA({
    resourceIdentifiers: accIds
  });

  const grouped = useMemo(() => {
    if (!members?.length || !accounts?.length || !users?.length) {
      return;
    }

    return members.reduce((acc, val) => {
      let m = acc.find(et => et.belongs_to === val.relationships.belongs_to.id);
      if (!m) {
        m = {
          belongs_to: val.relationships.belongs_to.id,
          user: users.find(
            u =>
              u.id ===
              accounts.find(a => a.id === val.relationships.belongs_to.id)
                .relationships.user.id
          ),
          targets: []
        };
        acc.push(m);
      }
      m.targets.push(val);
      return acc;
    }, []);
  }, [members, accounts, users]);

  if (!grouped?.length) {
    return <Typography>No accounts have been connected</Typography>;
  }

  return (
    <>
      {grouped.map(ac => {
        return (
          <>
            <ConnectedAccountsListingBlock
              loading={!exportTargetType}
              name={ac.user.full_name}
              logo={<UserAvatar userInfo={ac.user} />}
              accounts={ac.targets}
              accountButtonClick={handleRevokeClick}
              accountButtonLabel="Revoke"
            />
          </>
        );
      })}
    </>
  );
};
