import React, { useCallback, useMemo } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import Surface, { SurfaceProperties } from './Surface';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

/// <Summary>
/// Renders a div with generic contents.
/// Wraps surface
/// You can subscribe events
/// Possible uses: As a background with highlight
/// Also check out storybook for some examples
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      pointerEvents: 'none',
      opacity: 0.4
    },
    root: {
      // in resting mode the background should be not visible.
      background: 'none'
    },
    resting: {
      // transition: 'background-color .25s ease-in-out'
    },
    highLighted: {
      // transition: 'background-color .25s ease-in-out',
      backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
        theme.customPalette.primary.light,
        'secondary',
        ['focus']
      )
    },
    interactable: {
      cursor: 'pointer'
    },
    nointeraction: {}
  })
);

interface Properties {
  highlightOnHover?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClickEvent?: () => void;
  surfaceProps?: SurfaceProperties;
  disabled?: boolean;
  classes?: Partial<ClassNameMap>;
}

const InteractableSurface: React.FC<React.PropsWithChildren<Properties>> = props => {
  const {
    onMouseLeave,
    onMouseOver,
    highlightOnHover = true,
    onClickEvent,
    surfaceProps,
    disabled,
    children,
    classes: injectedClasses
  } = props;

  const [hovered, setHovered] = React.useState(false);

  // seperate functions as workaround for compiler errors.
  const triggerLeave = useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave();
    }

    if (highlightOnHover) {
      setHovered(false);
    }
  }, [onMouseLeave, highlightOnHover, setHovered]);

  const triggerEnter = useCallback(() => {
    if (onMouseOver) {
      onMouseOver();
    }

    if (highlightOnHover) {
      setHovered(true);
    }
  }, [onMouseOver, highlightOnHover, setHovered]);

  const ownClasses = useStyles(props);

  const classes = useMemo(() => {
    if (injectedClasses) {
      return { ...injectedClasses, ...ownClasses };
    } else {
      return ownClasses;
    }
  }, [ownClasses, injectedClasses]);

  // combine the set classes and set them to the surface component.
  const parsedClass = `${classes.root}  
        ${onClickEvent ? classes.interactable : classes.nointeraction}   
        ${hovered && highlightOnHover ? classes.highLighted : classes.resting}`;

  // the state is passed to the children to able to access the hovered boolean.
  const childrenWithProps = React.Children.map(children, child => {
    return !child ||
      Array.isArray(child) ||
      ['boolean', 'string', 'number'].includes(typeof child) ||
      typeof (child as React.ReactElement).type === 'string'
      ? child
      : React.cloneElement(child as React.ReactElement, { hovered });
  });

  return (
    <div
      onMouseOver={triggerEnter}
      onMouseLeave={triggerLeave}
      onClick={onClickEvent}
      className={disabled ? classes.container: undefined}
    >
      <Surface {...surfaceProps} className={parsedClass}>
        {childrenWithProps}
      </Surface>
    </div>
  );
};

export default InteractableSurface;
