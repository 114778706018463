import { AssetMimeTypes, Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Favorite extends ResourceBase {
  type: 'favorite';
  attributes: {
    created_at: string;
    updated_at: string;
    node_id: string;
    mime_type?: AssetMimeTypes;
  };
  relationships: {
    account: Related<'account'>;
    nodes: Related<'content-tree-node'>; // why is this not a relationship?
  };
}

export const favorite: RawModel = {
  type: 'favorite',
  plural: 'favorites',
  useWorker: true,
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    node_id: 'string'
  },
  relationships: {
    account: {
      type: 'account',
      binding: ['one', 'many'],
      reverse: 'favorites'
    }
  }
};
