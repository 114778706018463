import React from 'react';
import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core/';
import { usePhoneFeatureContext } from './context/usePhoneFeatureContext';

// component that displays an email link to the support team (support@cube-cloud.com).
// If the phoneFeatureFlag is set to true, it also displays a phone number as a tooltip when hovering over the email link.

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    supportLink: {
      cursor: 'pointer',
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      '&:hover, &:active, &:focus': {
        color: theme.customPalette.primary.textWhiteHighEmphasis
      }
    }
  })
);

const supportEmail = 'support@cube-cloud.com';
const phoneNumber = '+31-20-435-7172';

export const SupportEmailComponent = () => {
  const phoneFeatureFlag = usePhoneFeatureContext();
  const classes = useStyles();

  const onSupportClick = () => {
    window.open(
      'mailto:support@cube-cloud.com',
      '_blank',
      'noopener noreferrer'
    );
  };

  return (
    <>
      {phoneFeatureFlag ? (
        <Tooltip
          title={`Or call us at ${phoneNumber}`}
          disableFocusListener
          disableTouchListener
        >
          <span
            className={classes.supportLink}
            onClick={onSupportClick}
            role="link"
          >
            {supportEmail}
          </span>
        </Tooltip>
      ) : (
        <span className={classes.supportLink} onClick={onSupportClick}>
          {supportEmail}
        </span>
      )}
    </>
  );
};

export default SupportEmailComponent;
