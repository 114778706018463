import React from 'react';
import wrappedCubeClient from '@cube3/state/src/wrapped-cube-client';
import { useEffect, useState } from 'react';
import { Waveform } from './Waveform';
import { useDragSeek } from './hooks/useDragSeek';
import audioFileIcon from '@cube3/ui/src/assets/images/Library/audio.svg?url';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { MediaPlayer } from '@cube3/ui/src/previews/VideoPreview/MediaPlayer';
import GeneralSVG from '@cube3/ui/src/icons/GeneralSVG';
import Button from '@cube3/ui/src/Buttons/Button';

interface Spectogram2Props {
  /* (audio) asset id */
  assetId: string;
  /** current playback time in seconds (currenttime) */
  playbackTime?: number;
  /** ref to the MediaPlayer instance */
  videoRef: MediaPlayer;
}

/**
 * Render a waveform for the audio previewer
 * Retrieves samples data from `/assets/<id>/waveform/`
 * Allows the user to change the currenttime by dragging
 */
export const Spectogram2 = (props: Spectogram2Props) => {
  const { assetId, playbackTime = 0, videoRef } = props;

  const [waveform, setWaveform] = useState(null);
  const [error, setError] = useState(null);

  // retrieve asset waveform data from api
  useEffect(() => {
    wrappedCubeClient.auth
      .request({ url: `/assets/${assetId}/waveform` })
      .then((data) => setWaveform(data.data))
      .catch((err) => setError(err));
  }, [assetId]);

  // normalized playback time (0.0 - 1.0 range)
  const duration = videoRef?.getDuration() || 1;
  const playPos = playbackTime / duration;

  // add event listeners to control current time by dragging mouse
  const { handleMouseDown, handleClick, handleDoubleClick } =
    useDragSeek(videoRef);

  if (
    error ||
    (Array.isArray(waveform?.samples) && waveform?.samples.length === 0)
  ) {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      >
        <div>
          <img alt="audio file icon" src={audioFileIcon} />
        </div>
        <div>
          <Typography color="contrast3" variant="body3">
            Unable to show audio waveform
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          margin: '48px 24px',
          flexGrow: 1,
          flexBasis: `100%`,
          paddingTop: 'max(30%, 100px)',
          position: 'relative',
          cursor: 'pointer'
        }}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        <Waveform samples={waveform?.samples} merge={4} playPos={playPos} />

        {playbackTime === 0 && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%) scale(2)'
            }}
          >
            <Button
              pathCenterSvg="iconPlay"
              extraSVGProps={{ svgProps: { viewBox: '0 0 32 32' } }}
              colorVariant="ghost1"
            />
          </div>
        )}

        {waveform && (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              background:
                'linear-gradient(0deg, transparent 0%, white 2%, white 98%, transparent 100%)',
              width: 1,
              top: 0,
              left: `${playPos * 100}%`,
              transform: 'translate(-1px, 0)'
            }}
          />
        )}
      </div>
    </div>
  );
};
