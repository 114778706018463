import React from 'react';
import { uuidv4 } from '../../../state/src/utils/uuid';

const componentIndex = [];
const identifierIndex = [];

const getIdentifier = (Component) => {
  const idx = componentIndex.indexOf(Component);
  if (idx >= 0) {
    return identifierIndex[idx];
  }
  return addIdentifier(Component);
};

const addIdentifier = (Component) => {
  const id = uuidv4();
  componentIndex.push(Component);
  identifierIndex.push(id);
  return id;
};

// look up table for fallback components, that get dynamically registered, using the registerFallback method
// the key is based on the React componentType property
const fallbacks: Record<string, Fallback> = {};

/**
 * Register a fallback component for a Cubicle component (that isn't ready for production)
 * @param ComponentType the Cubicle componentType
 * @param FallbackComponentType the fallback componentType
 * @param ready mark the Cubicle component ready
 * @param convertProps provide a callback to map the Cubicle components props interface to the fallback components props interface
 */
export const registerFallback = (
  ComponentType,
  FallbackComponentType,
  ready = false,
  convertProps
) => {
  fallbacks[getIdentifier(ComponentType.original)] = {
    Component: FallbackComponentType,
    convertProps,
    ready
  };
};

/**
 * Fallback modes give us control over when we want to switch over to using Cubicle components
 */
export enum FallbackModes {
  'ONLY_FALLBACK' = 'ONLY_FALLBACK', // always show the fallback
  'ONLY_READY' = 'ONLY_READY', // only show the fallback if the Cubicle component hasn't been marked ready
  'NEVER_FALLBACK' = 'NEVER_FALLBACK' // never show the fallback, even if the Cubicle component isn't marked ready
}

interface CubicleFallbacksContext {
  /**
   * when to show the fallback component instead of the Cubicle one
   */
  fallbackMode:
    | FallbackModes.ONLY_FALLBACK
    | FallbackModes.ONLY_READY
    | FallbackModes.NEVER_FALLBACK;
  /**
   * helper to check if a Cubicle component is marked ready
   */
  isReady(component: React.ComponentType): boolean;
  /**
   * get a fallback component for a Cubicle component
   */
  getFallback(component: React.ComponentType): React.ComponentType | undefined;
}

/**
 * provide access to fallback components via context
 */
const cubicleFallbacksContext = React.createContext<CubicleFallbacksContext>({
  fallbackMode: FallbackModes.ONLY_READY,
  isReady: (component: React.ComponentType) => false,
  getFallback: (component: React.ComponentType) => {
    return undefined;
  }
});

/**
 * Fallback configuration for a Cubicle component
 */
interface Fallback {
  /**
   * fallback component
   */
  Component: React.ComponentType;
  /**
   * callback for mapping the Cubicle component's props interface to the legacy one
   */
  convertProps?(props: any): any;
  /**
   * boolean for marking the Cubicle component ready
   */
  ready: boolean;
}

/**
 * allow setting up boundaries with different fallback modes and toggling the fallback mode dynamically
 */

export const CubicleFallbackProvider = ({
  children,
  initialFallbackMode = FallbackModes.ONLY_READY
}) => {
  const [fallbackMode, setFallbackMode] = React.useState(initialFallbackMode);

  // look up a fallback in the "registry"
  const getFallback = React.useCallback((Component) => {
    const fb = fallbacks[getIdentifier(Component)];

    if (!fb) {
      return undefined;
    }

    return (props) => {
      return (
        <fb.Component {...(fb.convertProps ? fb.convertProps(props) : props)} />
      );
    };
  }, []);

  // look up a readiness status in the "registry"
  const isReady = React.useCallback((Component) => {
    return fallbacks[getIdentifier(Component)]?.ready;
  }, []);

  const value = React.useMemo(() => {
    return {
      fallbackMode,
      getFallback,
      isReady,
      setFallbackMode
    };
  }, [fallbackMode, getFallback, isReady, setFallbackMode]);

  return (
    <cubicleFallbacksContext.Provider value={value}>
      {children}
    </cubicleFallbacksContext.Provider>
  );
};

export const useCubicleFallbacks = () => {
  return React.useContext(cubicleFallbacksContext);
};

interface Props {
  CubicleComponent: React.FunctionComponent;
}

/**
 * Wrapping component that conditionally renders either the Cubicle component or the fallback, based on the settings on the context
 */
export const CubicleFallback = React.memo(<P extends Props>(props: P) => {
  const { CubicleComponent } = props;

  type OriginalProps = Omit<P, 'CubicleComponent'>;

  const { fallbackMode, isReady, getFallback } = useCubicleFallbacks();

  const shouldFallback =
    fallbackMode === FallbackModes.ONLY_FALLBACK ||
    (fallbackMode === FallbackModes.ONLY_READY && !isReady(CubicleComponent));

  const Fallback: React.FunctionComponent<OriginalProps> = getFallback(
    CubicleComponent
  ) as React.FunctionComponent<OriginalProps>;

  if (shouldFallback) {
    if (Fallback) {
      return <Fallback {...props} />;
    } else {
      return (
        <h1>
          No fallback for{' '}
          {CubicleComponent.name || CubicleComponent.displayName || 'component'}
        </h1>
      );
    }
  } else {
    return <CubicleComponent {...(props as OriginalProps)} />;
  }
});

// memoize
const overrides = {};

/**
 * Higher order component for setting up the fallbacks
 * This way once all components are ready, we can just
 * remove this part from the Cubicle components,
 * without having to go over the entire codebase
 */
export const withCubicleFallback = function <P>(Component: P): P {
  const id = getIdentifier(Component);

  const override =
    overrides[id] ||
    ((props) => <CubicleFallback CubicleComponent={Component} {...props} />);

  overrides[id] = override;

  override.original = Component;
  return override as P;
};
