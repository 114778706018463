import React, { useContext } from 'react';
import { useImageDimensions } from '../Hooks/useImageDimensions';
import { getNodeDisplayImageSrc } from '../human/nodeDisplayImageResolver';
import { ScrollPositionContext } from '../Scrollbar/Scrollbar';
import Thumbnail, { ThumbnailRatioType } from './Thumbnail';
import { FillVariant } from './Thumbnail-image-container';

interface ThumbnailVariantsProps {
  src?: string;
  stripSrc?: string;
  cover: boolean;
  resource?: {
    type?: string;
    mime_type?: string;
    display_image?: string;
    media?: Array<{ type: string; url: string }>;
    resource_type?: string;
  };
  ratio: ThumbnailRatioType;
  fillVariant?: FillVariant;
  showCheckerboard?: boolean;
  bordered?: boolean;
}

export const ratios = {
  oneToOne: 1,
  fourToThree: 4 / 3,
  sixteenToNine: 16 / 9,
  sixteenToEleven: 16 / 11
};

export const ThumbnailVariants = React.memo((props: ThumbnailVariantsProps) => {
  const {
    src,
    cover,
    resource,
    fillVariant,
    showCheckerboard,
    bordered,
    ratio,
    stripSrc
  } = props;

  let thumbnail = src ? src : getNodeDisplayImageSrc(resource);
  let thumbnailProperties = useImageDimensions(thumbnail);

  const { scrolling } = useContext(ScrollPositionContext);

  const border =
    bordered !== undefined
      ? bordered
      : resource &&
        (resource.type === 'folder' || resource.resource_type === 'folder')
      ? false
      : src
      ? true
      : false;

  if (!thumbnailProperties) {
    return (
      <Thumbnail
        cover={cover}
        bordered={border}
        ratio={ratio}
        fillVariant={fillVariant}
        showCheckerboard={false}
      />
    );
  }

  if (thumbnailProperties.type === 'error') {
    return (
      <Thumbnail
        src={thumbnail}
        bordered={bordered}
        cover={cover}
        ratio={ratio}
        fillVariant={fillVariant}
        showCheckerboard={false}
      />
    );
  }

  return (
    <Thumbnail
      src={thumbnail}
      stripSrc={scrolling ? undefined : stripSrc}
      cover={cover}
      bordered={bordered}
      ratio={ratio}
      fillVariant={fillVariant}
      showCheckerboard={showCheckerboard}
      widthLagerThanHeight={
        thumbnailProperties.width / thumbnailProperties.height > ratios[ratio]
      }
    />
  );
});

ThumbnailVariants.displayName = 'ThumbnailVariants';
