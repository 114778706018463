import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Folder } from '@cube3/common/model/schema/resources/folder';
import { Asset } from '@cube3/common/model/schema/resources/asset';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { capitalize } from '@material-ui/core';
import React from 'react';
import { DownloadFormatRow } from './DownloadFormatRowSmart';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

export interface Props {
  allPresets: RenderPreset[];
  presets: RenderPreset[];
  assets?: Asset[];
  folders?: Folder[];
  groupName?: string;
  loading?: boolean;
  /** check if has permission to download preview/original file */
  noPermission?: (preset) => string;
}

const DownloadFormatsList = (props: Props) => {
  const {
    groupName,
    allPresets,
    presets,
    assets,
    folders,
    loading,
    noPermission
  } = props;

  return loading ? (
    <Shimmer height={100} width={500} />
  ) : allPresets?.length ? (
    <div style={{ paddingBottom: 24 }}>
      {groupName ? (
        <div style={{ marginLeft: '-12px', paddingTop: 12 }}>
          <Typography color="contrast3" typographyStyle="body1">
            {capitalize(groupName)}
          </Typography>
        </div>
      ) : null}
      {allPresets.map((preset) => (
        <DownloadFormatRow
          mimeTypeUnSupported={mimeTypeUnSupportedCheck(preset, presets)}
          key={preset.id}
          preset={preset}
          assets={assets}
          folders={folders}
          disabledReason={noPermission ? noPermission(preset) : ''}
        />
      ))}
    </div>
  ) : null;
};

export const mimeTypeUnSupportedCheck = (
  preset: RenderPreset,
  intersectPresets: RenderPreset[]
) => {
  return !intersectPresets.find((p) => p.id === preset.id);
};

export default DownloadFormatsList;
