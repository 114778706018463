import { initialExportOptionsState } from '../../../../../../app/layout/Exports/state/exportOptionsState';
import { useCallback, useMemo } from 'react';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { ExportTargetDefault } from '@cube3/common/model/schema/resources/export-target-default';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

const mutateConfig = { cacheInvalidator: null };

export const useExportTargetDefaults = (exportTargetTypeId) => {
  const [workspaceId] = useCurrentWorkspace();
  const params = useMemo(() => {
    return {
      filter: {
        export_target_typeId: exportTargetTypeId
      }
    };
  }, [exportTargetTypeId]);

  const { first: exportTargetDefault, status } = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'export-target-default',
    edgeLabel: 'export-target-defaults',
    params
  });

  // const categories =
  //   (exportTargetDefault as ExportTargetDefault<undefined, YouTubeConfig>)
  //     ?.attributes.integration_config.categories || [];

  const defaultParams = (exportTargetDefault as ExportTargetDefault<'youtube'>)
    ?.attributes.integration_parameters;

  const defaultRecipients = (
    exportTargetDefault as ExportTargetDefault<'youtube'>
  )?.attributes.notification_recipients;

  const defaults = useMemo(() => {
    return defaultParams
      ? {
          ...initialExportOptionsState,
          ...defaultParams,
          recipients: defaultRecipients
        }
      : undefined;
  }, [defaultParams, defaultRecipients]);

  const [mutate, mutateStatus] = useMutateResource__ALPHA(mutateConfig);

  const handleDefaultsChange = useCallback(
    (config) => {
      mutate({
        id: exportTargetDefault.id,
        type: exportTargetDefault.type,
        integration_parameters: {
          tags: config.tags,
          visibility: config.visibility?.toLowerCase()
        },
        notification_recipients: config.recipients
      });
    },
    [mutate, exportTargetDefault]
  );

  return [defaults, status, handleDefaultsChange, mutateStatus];
};
