// #region imports
import * as React from 'react';
import {
  reduxForm,
  getFormValues,
  InjectedFormProps,
  Field,
  change
} from 'redux-form';
import { connect, useDispatch } from 'react-redux';

// helpers
import { compose } from '../../../../../utils/component-helpers';

// state
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { withModalActions, ModalActionProps } from '../../Modals/modalActions';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import withCurrentWorkspace, {
  useCurrentWorkspace,
  WorkspaceProps
} from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

// UI
import AddProjectModalUI from '@cube3/ui/src/Projects/Modals/AddProjectModalUI';
import GenericFieldWithErrors from '../../../../forms/helpers/fields/GenericFieldWithErrors';
import {
  makeValidator,
  isRequired,
  maxCharacters
} from '../../../../forms/helpers/validators';
import SelectFieldComponent from '../../../../forms/helpers/fields/SelectFieldComponent';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { GenericFormFieldRow } from '@cube3/ui/src/forms/GenericFormField';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  RequestStatuses,
  statuses
} from '@cube3/state/src/redux/ducks/request-status';
import {
  hasError,
  serverErrorCodes as SERROR
} from '@cube3/state/src/wrapped-cube-client';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';
import { MetadataCategory } from '@cube3/common/model/schema/resources/metadata-category';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';
import { urlStructureProject } from '../../../routing/routingPaths';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import {
  ProjectIconType,
  projectIconName
} from '@cube3/common/model/schema/resources/project';
import { Icon } from '@cube3/cubicle/src/core/atoms/Icon';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ProjectTemplate } from '@cube3/common/model/schema/resources/project-template';

import { Checkbox } from '@cube3/cubicle/src/core/atoms/Checkbox/Checkbox';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { Feature } from '@cube3/common/model/resource-types';

const requiredFeatures = {
  features: ['/WORKSPACE/LABELING'] as Feature[]
};

// #region interfaces

interface ProjectTemplateOption {
  id: string;
  display_name: string;
  icon?: number;
  relationships: {
    doner_project: {
      type: 'project';
      id: string;
    };
  };
}
interface ProjectMetadata {
  retrievedFormsStatus: string;
  retrievedForms: MetadataCategory[];
}
type FormValues = {
  form: string;
  projectName?: string;
  projectTemplate: ProjectTemplateOption;
  projectIcon: { prefix: React.ReactNode; display_name: ProjectIconType };
  allowLabeling?: boolean;
};
interface HOCMapped {
  handleSubmit: (e: React.SyntheticEvent) => void;

  addProject?: Function;
  addProjectStatus?: string;
  projectTemplateOptions: ProjectTemplateOption[];
  loading?: boolean;
  tempId?: string;
}

type Properties = HOCMapped &
  FormValues &
  InjectedFormProps &
  ModalActionProps &
  ModalReceiverProps &
  WorkspaceProps &
  ProjectMetadata;
// #endregion

const projectIcons = [
  {
    display_name: 'Project',
    prefix: <Icon icon={projectIconName.Project} />
  },
  {
    display_name: 'Creative Automation',
    prefix: <Icon icon={projectIconName['Creative Automation']} />
  },
  {
    display_name: 'Library',
    prefix: <Icon icon={projectIconName.Library} />
  }
];

const mapStateToProps = (state, ownProps) => {
  const values = getFormValues('add_project')(state);
  return {
    form: ownProps.form,
    formValues: values,
    initialValues: {
      ...ownProps.initialValues,
      projectIcon: projectIcons[0]
    },
    ...values
  };
};

export const getErrorText = (e) => {
  if (!e) {
    return;
  }

  switch (true) {
    case hasError(e, SERROR.ATTRIBUTE_UNIQUE_CONSTRAINT):
      return 'A Project with this name already exists. Please choose a different name, that is unique within the workspace.';
    default:
      return 'Unknown server error... if this problem persists please contact support@cube-cloud.com';
  }
};

const EMPTY_TYPE = 'EMPTY_TYPE';

const maxChars = (value: string) => maxCharacters(value, 128);
const stackErrors = makeValidator([isRequired, maxChars]);

const AddProjectModal: React.FC<Properties> = React.memo((props) => {
  const {
    projectTemplateOptions,
    handleSubmit,
    previousModal,
    openModal,
    closeAllModals,
    submitting,
    submitSucceeded,
    loading,
    valid,
    error,
    anyTouched,
    modalContext,
    tempId,
    retrievedForms,
    currentWorkspaceId,

    addProject,
    addProjectStatus,
    // form values
    projectTemplate,
    projectName,
    projectIcon,
    allowLabeling
  } = props;

  const [hasLabelingFeature] = useFeatures(requiredFeatures);

  const { filterValues } = modalContext;
  /** check if should edit metadata before create project */
  const shouldEditMetadata = React.useMemo(() => {
    const hasRequiredFields =
      retrievedForms &&
      retrievedForms.findIndex(
        (field) =>
          field?.metadata_fields?.filter((f) => !!f.required).length > 0
      ) > -1;
    const hasPrefilledValue =
      Object.keys(filterValues).filter((key) => !!filterValues[key]).length > 0;
    return {
      hasRequiredFields,
      hasPrefilledValue,
      should: hasRequiredFields || hasPrefilledValue
    };
  }, [filterValues, retrievedForms]);

  /** go to edit metadata fields modal */
  const onNext = React.useCallback(() => {
    addProject({
      type: 'project',
      display_name: projectName,
      icon: projectIcons.findIndex(
        (p) => p.display_name === projectIcon?.display_name
      ),
      dry_run: true,
      allow_labeling: allowLabeling
    });
  }, [addProject, projectName, projectIcon, allowLabeling]);

  React.useEffect(() => {
    if (addProjectStatus === statuses.SUCCESS) {
      openModal(
        'metadata_edit_required_fields',
        {
          createProject: handleSubmit,
          filterValues,
          tempId,
          retrievedForms: retrievedForms,
          hasRequiredFields: shouldEditMetadata.hasRequiredFields
        },
        false
      );
    }
  }, [
    addProjectStatus,
    filterValues,
    handleSubmit,
    openModal,
    retrievedForms,
    shouldEditMetadata.hasRequiredFields,
    tempId
  ]);

  const history = useHistory();
  const mappedId = useMappedId__ALPHA(tempId);

  React.useEffect(() => {
    if (!shouldEditMetadata.should && submitSucceeded) {
      closeAllModals();
      history.push(
        generatePath(urlStructureProject, {
          workspaceId: currentWorkspaceId,
          projectId: mappedId
        })
      );
    }
  }, [
    closeAllModals,
    shouldEditMetadata,
    submitSucceeded,
    history,
    currentWorkspaceId,
    mappedId
  ]);

  // update icon when changing project template
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (
      !projectTemplate ||
      projectTemplate.id === EMPTY_TYPE ||
      !projectTemplateOptions?.length
    )
      return;
    const icon = projectTemplateOptions.find(
      (t) => t.id === projectTemplate.id
    )?.icon;
    const iconUI = projectIcons[icon];
    dispatch(change('add_project', 'projectIcon', iconUI));
  }, [projectTemplate, projectTemplateOptions, dispatch, change]);

  return (
    <AddProjectModalUI
      submitButtonLabel={shouldEditMetadata.should ? 'Next' : 'Create'}
      onSubmit={shouldEditMetadata.should ? onNext : handleSubmit}
      onCloseEvent={previousModal}
      valid={valid && !error}
      error={getErrorText(error)}
      loading={loading}
      submitting={submitting}
      nameField={
        <Field
          name="projectName"
          component={GenericFieldWithErrors}
          autoFocus={false}
          validate={stackErrors}
          props={{
            label: 'Project name',
            showLabel: false,
            showErrors: anyTouched ? true : 'touched'
          }}
        />
      }
      typeField={
        <Field
          name="projectTemplate"
          component={WrappedSelect}
          props={{
            label: 'Template',
            options: projectTemplateOptions,
            showErrors: anyTouched ? true : 'touched',
            width: {
              input: 300
            }
          }}
          validate={isRequired}
        />
      }
      iconField={
        <Field
          name="projectIcon"
          component={WrappedSelect}
          props={{
            options: projectIcons,
            showErrors: anyTouched ? true : 'touched',
            width: {
              input: 260
            }
          }}
        />
      }
      icon={
        <Icon icon={projectIconName[projectIcon?.display_name || 'Project']} />
      }
      hasLabelingFeature={hasLabelingFeature}
      tagField={
        <Field
          name="allowLabeling"
          component={({ input }) => (
            <Checkbox
              state={allowLabeling}
              onChange={(checked) => input.onChange(checked)}
            />
          )}
        />
      }
    />
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    label: {
      fontFamily: theme.typography.fontFamily,
      ...theme.typographyStyles.body2,
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      marginLeft: 8,
      marginTop: 16,
      marginBottom: 8
    }
  })
);

export const WrappedSelect = (props) => {
  const classes = useStyles(props);

  return (
    <GenericFormFieldRow {...props} genericFormFieldStyles={classes}>
      <SelectFieldComponent {...props} />
    </GenericFormFieldRow>
  );
};

const withCreateProject = (Wrapped) => (props) => {
  const [accountId] = useCurrentAccount();
  const { currentWorkspaceId } = props;
  const tempId = React.useRef(uuidv4());
  const config = useMemo(() => {
    return {
      resourceType: 'project' as const,
      ancestor: { type: 'workspace' as const, id: currentWorkspaceId },
      relationship: 'projects',
      actionDecorators: [
        addFormId('add_project', {
          formState: undefined,
          useRequestStatus: true,
          delegate: true
        })
      ],
      cacheInvalidator: () => {
        return [
          {
            type: 'workspace' as const,
            id: currentWorkspaceId,
            relationship: 'projects'
          },
          {
            type: 'account' as const,
            id: accountId,
            relationship: 'project-members'
          }
        ];
      }
    };
  }, [currentWorkspaceId, accountId]);

  const [createResource, createResourceStatus] =
    useCreateResource__ALPHA(config);
  return (
    <Wrapped
      {...props}
      addProject={createResource}
      addProjectStatus={createResourceStatus}
      tempId={tempId.current}
    />
  );
};

const withProjectTemplateOptions = (Wrapped) => (props) => {
  const [workspaceId, workspace, _, workspaceStatus] = useCurrentWorkspace();

  const projectTemplates = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'project-template',
    edgeLabel: 'project-templates'
  });

  // use to get `icon` for each template
  const donorProjects = useResource__ALPHA({
    resourceIdentifiers: useMemo(
      () =>
        projectTemplates.resources?.map((p) => p.relationships.donor_project),
      [projectTemplates.resources]
    )
  }).resources;

  const allowEmpty = !workspace || !workspace.project_template_required;

  const projectTemplateOptions = React.useMemo(() => {
    const withEmptyOption = allowEmpty
      ? [
          { display_name: 'Use no template', id: EMPTY_TYPE },
          ...(projectTemplates.resources || [])
        ]
      : projectTemplates.resources || [];

    return withEmptyOption.map((option) => {
      const isDefault = option.id === workspace?.default_project_template_id;
      return {
        ...option,
        suffix: isDefault ? 'DEFAULT' : undefined,
        icon:
          option.id === EMPTY_TYPE
            ? 0
            : donorProjects?.find(
                (d) =>
                  d.id ===
                  (option as ProjectTemplate).relationships.donor_project.id
              )?.icon
      };
    });
  }, [projectTemplates.resources, allowEmpty, workspace, donorProjects]);

  const initializing =
    ([statuses.SUCCESS, statuses.FAILED] as RequestStatuses[]).indexOf(
      projectTemplates.status
    ) < 0 ||
    ([statuses.SUCCESS, statuses.FAILED] as RequestStatuses[]).indexOf(
      workspaceStatus
    ) < 0;

  const defaultType =
    workspace &&
    projectTemplateOptions?.find(
      (t) => t.id === workspace.default_project_template_id
    );
  const firstType = projectTemplateOptions[0];

  const initialValues = useMemo(() => {
    return {
      projectTemplate: defaultType || firstType
    };
  }, [defaultType, firstType]);

  return (
    <Wrapped
      enableReinitialize={initializing}
      {...props}
      loading={props.loading || initializing}
      initialValues={initialValues}
      projectTemplateOptions={projectTemplateOptions}
    />
  );
};

interface AddProjectFields {
  projectName: string;
  projectTemplate?: { id: string };
  allowLabeling?: boolean;
}

export default compose(AddProjectModal)(
  withCurrentWorkspace,
  withResourceEdges({
    resourceType: 'workspace',
    resourceId: (props) => props.currentWorkspaceId,
    edgeType: 'metadata-category',
    edgeLabel: 'metadata-categories',
    params: { filter: { categories_for: 'project' } },
    mapper: 'retrievedForms',
    strategy: 'fetch-on-mount'
  }),
  withModalActions,
  withProjectTemplateOptions,
  connect(mapStateToProps, {}),
  withCreateProject,
  reduxForm<AddProjectFields, HOCMapped>({
    form: 'add_project',
    destroyOnUnmount: true,
    onSubmit: (values, dispatch, props) => {
      /** add project type if applicable */
      const relationships = {
        project_template:
          values.projectTemplate?.id === EMPTY_TYPE
            ? undefined
            : {
                data: {
                  id: values.projectTemplate.id,
                  type: 'project-template'
                }
              }
      };

      /** submit the request */
      props.addProject({
        temporaryId: props.tempId,
        display_name: values.projectName,
        icon: projectIcons.findIndex(
          (p) => p.display_name === values.projectIcon.display_name
        ),
        allow_labeling: values.allowLabeling,
        relationships
      });

      return new Promise((res) => null);
    }
  })
);
