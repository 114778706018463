import React, { useMemo, useRef } from 'react';
import { ModalWindowUI } from '@cube3/ui/src/Modal/ModalWindowUI';

import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useGeneralForm } from '../hooks/useGeneralFormConfig';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import { CreateOrEditContractModal } from '../prefabs/CreateOrEditContractModal';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

interface Props {
  modalContext: {
    parentResource?: ResourceIdentifier<'asset' | 'project'>;
  };
}

export const CreateContractModal: React.FunctionComponent<Props> = (props) => {
  const { parentResource } = props.modalContext;

  const tempId = useRef(uuidv4());

  const { category: general } = useGeneralForm(tempId.current, true);

  const contractType = useSelector((state) => {
    const values = general && getFormValues(general.id)(state);
    return values && values['key_contract_type'];
  });

  const keyGroups = useResourceList__ALPHA({
    resourceType: 'contract-type',
    resourceId: contractType?.id,
    edgeType: 'contract-key-group',
    edgeLabel: 'contract-key-groups'
  });

  const categories = useMemo(() => {
    return general ? [general, ...(keyGroups.resources || [])] : undefined;
  }, [keyGroups.resources, general]);

  const workspace = useCurrentWorkspace()[1];

  if (!general || !workspace) {
    return <ModalWindowUI title={`New Contract`} loading={true} />;
  }

  const title = 'Create contract';

  return (
    <CreateOrEditContractModal
      title={title}
      categories={categories}
      general={general}
      parentResource={parentResource}
      tempId={tempId.current}
    />
  );
};
