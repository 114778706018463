import React, { PropsWithChildren } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { cx } from '@emotion/css';
import { Theme } from '../../../theme/themes';

const useSCSS = makeCSS(
  ({
    theme,
    colorVariant = '01',
    weight = 'body',
    lineHeight = 'tight',
    customColor
  }: Props & { theme: Theme }) => {
    /**tabular-nums slashed-zero
     * typography is defined like this inside the theme
     *  "typography": {"label": {"trim": {"sm": "500 12px/100% Inter"},
     *                           "tight": {"sm": "500 12px/1.43 Inter"}},
     *                 "body": {"trim": {"sm": "400 12px/100% Inter"}}
     *                 }
     */

    const fontSizes = Object.keys(theme.typography[weight][lineHeight]).reduce(
      (fs, k) => {
        return {
          ...fs,
          [`variant-${k}`]: {
            font: theme.typography[weight][lineHeight][k],
            fontVariantNumeric: 'lining-nums tabular-nums slashed-zero',
            fontFeatureSettings: "'ss02' on",
            textRendering: 'optimizeLegibility',
            fontSmooth: 'always'
          }
        };
      },
      {}
    );
    return {
      root: {
        fontFamily: theme.fontFamilies.body,
        fontSynthesis: 'none'
      },
      ...fontSizes,
      customColor: {
        color: customColor
      },
      textDecorator: {
        textDecoration: 'underline'
      },
      'color-default': {
        color: theme.color.text[colorVariant]
      },
      'color-light': {
        color: theme.color.text.light[colorVariant]
      },
      'color-dark': {
        color: theme.color.text.dark[colorVariant]
      }
    };
  }
);

// This is needed now so that using this component(ready===false) doesn't cause errors when passing props
interface LegacyTypographyProps {
  className?: string;
}
export interface Props extends PropsWithChildren, LegacyTypographyProps {
  variant?: keyof Theme['typography']['label']['trim'];
  /**Styling refers to the weight and the underline */
  weight?: 'body' | 'label'; //body = 40(regular) label = 500(medium)
  color?: 'default' | 'light' | 'dark';
  colorVariant?: '01' | '02' | '03';
  lineHeight?: 'trim' | 'tight' | 'normal' | 'loose';
  customColor?: string;
  textDecorator?: boolean;
}

const Typography = (props: Props) => {
  const {
    children,
    variant = 'md',
    color = 'default',
    customColor,
    textDecorator
  } = props;

  const classes = useSCSS(props);

  return (
    <span
      className={cx(
        classes.root,
        classes[`variant-${variant}`],
        customColor ? classes.customColor : classes[`color-${color}`],
        textDecorator ? classes.textDecorator : ''
      )}
    >
      {children}
    </span>
  );
};

export { Typography };
