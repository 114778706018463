import moment from 'moment';
/// <Summary>
/// Component that accepts a timestring in either RFC822 format
/// or ISO 8601 format. The component will render the format as
/// a human readable date. At the moment dates will be rendered
/// European style : DD/MM/YYYY
/// All other formats given will be rendered as date unavailable.
///
/// Accepted examples:
/// ISO 8601 Example : 2011-10-10T14:48:00
/// ISO 8601 Example : 2011-10-10
/// RFC822   Example : Mon, 25 Dec 1995 13:30:00 GMT
/// RFC822   Example : Mon, 25 Dec 1995
/// More info: https://stackoverflow.com/questions/25362713/rfc-2822-and-iso-8601-date-format-regex
/// </Summary>

export const makeDateString = ({
  date,
  dateObj = undefined,
  fullForm = undefined,
  now = undefined,
  relativeTime = false,
  noTime = false
}) => {
  let year, month, day, time;

  dateObj =
    dateObj !== undefined
      ? dateObj
      : date && Object.getPrototypeOf(date) === Date
      ? date
      : new Date(date || 0);

  // if this moment is the same as the given date object...
  if (moment(now).isSame(date, 'day')) {
    time = !noTime;
  } else if (moment(now).isSame(date, 'year')) {
    day = true;
    month = true;
  } else {
    if (fullForm) {
      time = true;
    }
    year = true;
    day = true;
    month = true;
  }

  const yearString = moment(dateObj).format('YYYY');
  let monthString = moment(dateObj).format('MMM');
  if (
    moment(dateObj).format('M') === '5' ||
    moment(dateObj).format('M') === '6' ||
    moment(dateObj).format('M') === '7'
  ) {
    monthString = moment(dateObj).format('MMMM');
  }
  const dayString = moment(dateObj).format('D');
  const timeString = moment(dateObj).format('HH:mm');
  const fullFormDate: string = !noTime
    ? `${dayString} ${monthString} ${yearString}, ${timeString}`
    : `${dayString} ${monthString} ${yearString}`;

  if (!relativeTime) {
    if (fullForm) {
      return { dateString: fullFormDate, fullFormDate };
    } else {
      const dateString = [
        day && dayString,
        month && monthString,
        year && yearString,
        time && timeString
      ]
        .filter((s) => s)
        .join(' ');
      return { dateString, fullFormDate };
    }
  } else {
    const relativeString =
      day || time
        ? `(${moment(dateObj).fromNow()})`
        : moment(dateObj).fromNow();
    return { dateString: relativeString, fullFormDate };
  }
};

/** Checks if the passed date falls into the current week nr */
export function getIfThisWeek(date: Date): boolean {
  return moment(new Date()).week() === moment(date).week();
}

/** Checks if the passed date falls into the previous week nr */
export function getIfLastWeek(date: Date): boolean {
  return moment(new Date()).week() - 1 === moment(date).week();
}

/** Checks if the passed date falls into the current month nr */
export function getIfThisMonth(date: Date): boolean {
  return moment(new Date()).month() === moment(date).month();
}

/** Checks if the passed date falls into the previous month nr */
export function getIfLastMonth(date: Date): boolean {
  return moment(new Date()).month() - 1 === moment(date).month();
}

/** Checks if the passed date falls into the current year */
export function getIfThisYear(date: Date): boolean {
  return moment(new Date()).year() === moment(date).year();
}

/** Checks if the passed date falls into the previous year */
export function getIfLastYear(date: Date): boolean {
  return moment(new Date()).year() - 1 === moment(date).year();
}
