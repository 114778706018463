import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export type MetadataProperty = ResourceBase & {};

export const metadataProperty: RawModel = {
  type: 'metadata-property',
  plural: 'metadata-properties',
  canonicalPath: 'properties',
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
