/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Errors, { getErrors } from '../errors';
import Vertical from '@cube3/ui/src/Layout/vertical';
import StyledPopper from '@cube3/ui/src/helpers//StyledPopper';
import Cube3TextField from '@cube3/ui/src/forms/textfields/Cube3TextField';

import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

export interface ExtendedFieldMetaProps extends WrappedFieldMetaProps {
  asyncValidated: boolean;
}

export interface GenericFieldWithErrorsProps {
  input: WrappedFieldInputProps;
  meta: ExtendedFieldMetaProps;
  showLabel?: boolean;
  label: string;
  type: string;
  placeholder?: string;
  autoComplete?: string;
  showAsyncValid: boolean;
  disabled: boolean;
  /**
   * when to show validation errors
   * - true          : always show
   * - false         : never show
   * - 'touched'     : show if field is marked touched
   * - 'intentTouched: show if field is marked touched, but ignore intial touched state from autofocus
   */
  showErrors: boolean | 'touched' | 'intentTouched';
  renderTip(props: GenericFieldWithErrorsProps): React.ReactElement;
  inputProps?: WrappedFieldInputProps;
  // select field contents when component mounts
  startSelected?: boolean;
  errorMessage?: string;
  patern?: boolean;
  multiline?: boolean;
  // focus field when component mounts
  autoFocus?: boolean;
  // number type
  numberControls?: JSX.Element;
  min?: number;
  max?: number;
  step?: number;
}

// eslint-disable-next-line
class GenericFieldWithErrors extends React.PureComponent<
  GenericFieldWithErrorsProps,
  { ref: React.RefObject<HTMLDivElement>; focus: boolean }
> {
  state = {
    focus: false,
    ref: React.createRef<HTMLDivElement>()
  };

  onInputRef = (node) => {
    if (node && this.props.startSelected) {
      node.select();
    }
  };

  ignoreTouch: boolean | null = null;
  componentDidUpdate(oldProps) {
    if (this.props.meta.active && !oldProps.meta.active) {
      if (this.ignoreTouch === null) {
        this.ignoreTouch = true;
      } else if (this.ignoreTouch === true) {
        this.ignoreTouch = false;
      }
    }
  }

  render() {
    const {
      input,
      meta,
      showLabel = true,
      label,
      type,
      placeholder,
      autoComplete,
      autoFocus,
      showAsyncValid,
      disabled,
      renderTip,
      showErrors = 'touched',
      inputProps,
      errorMessage,
      multiline,
      numberControls
    } = this.props;

    const errors = getErrors(errorMessage, meta.error);

    const isTouched = meta.touched;
    const isIntentTouched = meta.touched && (!autoFocus || !this.ignoreTouch);

    const shouldShow =
      (showErrors === true ||
        (showErrors === 'touched' && isTouched) ||
        (showErrors === 'intentTouched' && isIntentTouched)) &&
      !meta.active;
    const shouldList =
      showErrors === true ||
      (showErrors === 'touched' && isTouched) ||
      (showErrors === 'intentTouched' && isIntentTouched);

    const tip = renderTip && renderTip(this.props);

    return (
      <div ref={this.state.ref}>
        <Vertical>
          <Cube3TextField
            label={showLabel && label}
            error={errors && shouldShow}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            type={type}
            fullWidth={true}
            placeholder={placeholder || label}
            helperText={errors && shouldList && <Errors errors={errors} />}
            formHelperTextProps={{ error: !!errors }}
            disabled={disabled}
            {...input}
            {...inputProps}
            inputRef={this.onInputRef}
            multiline={multiline}
            rowsMin={multiline ? 3 : 1}
            numberControls={numberControls}
          />

          {/* {errors &&
              (meta.touched && !meta.active) && (
                <StyledPopper placement="left" open={true}>
                  <Errors errors={errors} />
                </StyledPopper>
              )} */}
          {meta.asyncValidating && (
            <StyledPopper
              placement="left"
              anchorEl={this.state.ref.current}
              open={!!this.state.ref.current}
            >
              &#8987;
            </StyledPopper>
          )}

          {tip && (
            <StyledPopper
              placement="right"
              anchorEl={this.state.ref.current}
              open={!!this.state.ref.current}
            >
              {tip}
            </StyledPopper>
          )}
          {/* NOTE: this uses a redux-form plugin (see root reducer) */}
          {showAsyncValid &&
            meta.asyncValidated &&
            !meta.asyncValidating &&
            !errors && (
              <StyledPopper
                placement="left"
                anchorEl={this.state.ref.current}
                open={!!this.state.ref.current}
              >
                &#10004;
              </StyledPopper>
            )}
        </Vertical>
      </div>
    );
  }
}

export default GenericFieldWithErrors;
