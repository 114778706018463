import { serialize } from './serialize';

export const errorAction = (state, action, payloadAction) => {
  if (action.error) {
    const id = serialize(payloadAction);
    return {
      ...state,
      [id]: action.error || action.errors
    };
  }
  return state;
};
