import { Theme, makeStyles, createStyles } from '@material-ui/core';
import React from 'react';
import { Typography } from '../../../../cubicle/src/components/typography/Typography';
import MediaQueueTileStatusUI from '../../Prefabs/MediaQueue/subComponents/MediaQueueTileStatusUI';
import { MediaQueueTileStatus } from '../../Prefabs/MediaQueue/MediaQueueTile';

export const useTargetStatusStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      border: `1px solid ${theme.customPalette.line.onBase1.contrast4}`,
      borderRadius: theme.surfaceCorners.lowEmphasis,
      display: 'flex',
      flexFlow: 'column nowrap',
      marginTop: '8px'
    },
    row: {
      display: 'flex',
      flexFlow: 'row wrap',
      padding: '8px 16px',
      justifyContent: 'space-between'
    },
    name: {
      marginRight: 8
    },
    withBorderBottom: {
      borderBottom: `1px solid ${theme.customPalette.line.onBase1.contrast4}`
    }
  })
);

/** TV broadcast: to list status for each  destination */
export type TVadStationWithStatusType = {
  id: string;
  display_name: string;
  status: MediaQueueTileStatus;
  label: string;
  progress?: number;
};
interface Props {
  target: TVadStationWithStatusType;
  withBorderBottom: boolean;
}

const normalizeState = (state: string) =>
  state === 'success' ? 'success' : state === 'failed' ? 'failed' : 'pending';

export const TVadTargetsStatusRowUI: React.FC<Props> = (props) => {
  const { target, withBorderBottom } = props;
  const classes = useTargetStatusStyles();

  return (
    <div
      className={`${classes.row} ${
        withBorderBottom && classes.withBorderBottom
      }`}
    >
      <Typography color="contrast1" className={classes.name}>
        {target.display_name}
      </Typography>
      <MediaQueueTileStatusUI
        status={normalizeState(target.status)}
        statusProgress={target.progress}
        statusLabel={target.label}
      />
    </div>
  );
};
