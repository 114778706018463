import React, { useCallback } from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from './../../Modals/ModalManager';
import { useModalActions } from '../../../layout/Modals/modalActions';

import { useDispatch } from 'react-redux';
import { actionCreators } from '@cube3/state/src/redux/ducks/session';

// UI
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';

interface PrivateProps extends ModalReceiverProps {}

/**
 * @summary: Notice displaying password changed which redirects user to login screen when clicked done
 * @author Simon
 */
function ChangePasswordConfirmedModal(props: PrivateProps): JSX.Element {
  const dispatch = useDispatch();
  const modalActions = useModalActions();

  const handleClickConfirm = useCallback(() => {
    modalActions.closeAllModals();
    // logout and send the user back to the loginscreen.
    dispatch(actionCreators.logout({ allSessions: true }));
  }, [dispatch, modalActions]);
  return (
    <ModalNoticeUI
      title={'Password changed'}
      footerRightComponent={
        <Button
          text={'Done'}
          colorVariant={'filled1'}
          onClick={handleClickConfirm}
        />
      }
    >
      <Typography>
        Your password has been changed successfully. Please sign in again using
        your new password.
      </Typography>
    </ModalNoticeUI>
  );
}

export default ChangePasswordConfirmedModal;
