import { PublicResource } from '../../resource-types';
import { RawModel } from '../schema';

export type MetadataValueTypes =
  | 'number'
  | 'string'
  | 'email'
  | 'date'
  | 'textarea'
  | 'select'
  | 'multiselect'
  | 'waterfall_select'
  | 'resource_picker'
  | 'generated'
  | 'custom';

export interface MetadataField extends PublicResource {
  json_schema: string;
  read_only: boolean;
  required: boolean;
  uses_suggestions: boolean;
  value_type: MetadataValueTypes;
}

export const metadataField: RawModel = {
  type: 'metadata-field',
  plural: 'metadata-fields',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    json_schema: 'string',
    read_only: 'boolean',
    required: 'boolean',
    uses_suggestions: 'boolean',
    value_type: 'string'
  },
  relationships: {}
};
