import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { ThumbnailGroup } from '@cube3/ui/src/thumbnails/ThumbnailGroup';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { List, ListItem } from '@material-ui/core';
import React from 'react';

type ListType = {
  id: string;
  display_image: string;
  display_name: string;
};

interface ModalProps {
  modalContext: {
    assets: ListType[];
  };
}

export const AllFilesModal: React.FC<ModalProps> = props => {
  const { assets } = props.modalContext;
  const { previousModal } = useModalActions();
  return (
    <ModalMenuUI
      title="All files"
      onCloseEvent={previousModal}
      footerRightComponent={
        <Button text="Done" colorVariant="filled1" onClick={previousModal} />
      }
    >
      <List>
        {assets &&
          assets.length > 0 &&
          assets.map((asset, index) => (
            <>
              <ListItem style={{ padding: '12px 0' }} key={asset.id}>
                <ThumbnailGroup
                  thumbnails={[asset.display_image]}
                  ratio="sixteenToNine"
                  cornerRadius="none"
                  width={80}
                />
                <div style={{ width: 24, height: 24 }}></div>
                <Typography color="contrast1" typographyStyle="heading2">
                  {asset.display_name}
                </Typography>
              </ListItem>
              {index < assets.length - 1 && <Divider />}
            </>
          ))}
      </List>
    </ModalMenuUI>
  );
};
