import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ButtonTypes, SizeVariations } from '../themes/custom-theme';
import UseHover from '../helpers/hooks/UseHover';
// UI
import GeneralSVG, {
  iconElepsis,
  GeneralSVGProperties,
  iconKeyboardArrowDown
} from '../icons/GeneralSVG';
import {
  Typography,
  colors,
  variants as typographyStyles,
  surfaces
} from '../typography/Typography';
import mergeRefs from '../helpers/MergeRefs';
import { colorVariationsElements } from '../themes/custom-theme';
import { useClassName } from '../utils/useClassName';

/*   Button color variants    */
export type ColorVariants =
  | 'filled1'
  | 'filled2'
  | 'filled3'
  | 'filledDanger'
  | 'danger'
  | 'ghost1'
  | 'ghost2'
  | 'ghost3'
  | 'covertDropdown';

/*   Function to get the button background color    */
export function getSurfaceColor(colorVariant: ColorVariants, theme: Theme) {
  switch (colorVariant) {
    case 'filled1':
      return { color: theme.customPalette.surface.brand1, base: 'onBrand' };
    case 'filled2':
      return { color: theme.customPalette.surface.base1, base: 'onBase' };
    case 'filledDanger':
      return { color: theme.customPalette.surface.danger1, base: 'onDanger' };
    case 'ghost1':
      return { color: 'inherit', base: 'onBase' };
    case 'ghost2':
      return { color: 'inherit', base: 'onBase' };
    case 'ghost3':
      return { color: 'inherit', base: 'onBase' };
    case 'danger':
      return { color: 'inherit', base: 'onBase' };
    case 'covertDropdown':
      return { color: 'inherit', base: 'onBase' };
    default:
      return { color: theme.customPalette.surface.base1, base: 'onBase' };
  }
}

/*   Function to get the typography styles  */
export function getTypographyStyles(
  colorVariant: ColorVariants,
  disabled: boolean,
  active: boolean
) {
  const colorVariants: {
    [key in ColorVariants]: {
      color: colors;
      typographyStyle: typographyStyles;
      surface: surfaces;
    };
  } = {
    filled1: {
      color: 'contrast1',
      typographyStyle: 'link1',
      surface: disabled ? 'onBase' : 'onBrand'
    },
    filled2: {
      color: 'contrast1',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    filled3: {
      color: disabled ? 'disabled' : 'contrast1',
      typographyStyle: 'link1',
      surface: disabled ? 'onBase' : 'onBrand'
    },
    filledDanger: {
      color: 'contrast1',
      typographyStyle: 'link1',
      surface: disabled ? 'onBase' : 'onDanger'
    },
    ghost1: {
      color: disabled ? 'contrast2' : active ? 'brand1' : 'brand2',
      typographyStyle: 'link2',
      surface: 'onBase'
    },
    ghost2: {
      color: disabled ? 'contrast2' : active ? 'brand1' : 'contrast2',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    ghost3: {
      color: disabled ? 'contrast2' : active ? 'brand1' : 'contrast2',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    danger: {
      color: disabled ? 'contrast2' : active ? 'danger1' : 'danger1',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    covertDropdown: {
      color: disabled ? 'contrast2' : 'brand2',
      typographyStyle: 'link2',
      surface: 'onBase'
    }
  };
  return colorVariants[colorVariant];
}

/*   Function to get the icon styles   */
export function getIconStyles(
  colorVariant: ColorVariants,
  disabled: boolean,
  active: boolean
) {
  const colorVariants: {
    [key in ColorVariants]: {
      color: colorVariationsElements;
      typographyStyle: typographyStyles;
      surface: surfaces;
    };
  } = {
    filled1: {
      color: 'contrast1',
      typographyStyle: 'link1',
      surface: disabled ? 'onBase' : 'onBrand'
    },
    filled2: {
      color: 'contrast1',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    filled3: {
      color: 'contrast1',
      typographyStyle: 'link1',
      surface: disabled ? 'onBase' : 'onBrand'
    },
    filledDanger: {
      color: 'contrast1',
      typographyStyle: 'link1',
      surface: disabled ? 'onBase' : 'onDanger'
    },
    ghost1: {
      color: disabled ? 'contrast2' : active ? 'brand1' : 'brand2',
      typographyStyle: 'link2',
      surface: 'onBase'
    },
    ghost2: {
      color: disabled ? 'contrast2' : active ? 'brand1' : 'contrast2',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    ghost3: {
      color: disabled ? 'contrast2' : active ? 'brand1' : 'contrast1',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    danger: {
      color: disabled ? 'contrast2' : active ? 'danger1' : 'danger1',
      typographyStyle: 'link1',
      surface: 'onBase'
    },
    covertDropdown: {
      color: disabled ? 'contrast2' : 'brand2',
      typographyStyle: 'link2',
      surface: 'onBase'
    }
  };
  return colorVariants[colorVariant];
}

/*   Function to get the outline color   */
export function getOutlineColor(colorVariant: ColorVariants, theme: Theme) {
  switch (colorVariant) {
    case 'filled1':
      return {
        color: theme.customPalette.line.onBrand.contrast4,
        base: 'onBrand'
      };
    case 'filled2':
      return { color: theme.customPalette.line.onBase1.brand1, base: 'onBase' };
    case 'filledDanger':
      return {
        color: theme.customPalette.line.onBrand.contrast4,
        base: 'onBrand'
      };
    case 'ghost1':
      return { color: theme.customPalette.line.onBase1.brand1, base: 'onBase' };
    case 'ghost2':
      return { color: theme.customPalette.line.onBase1.brand1, base: 'onBase' };
    case 'ghost3':
      return { color: theme.customPalette.line.onBase1.brand1, base: 'onBase' };
    case 'covertDropdown':
      return { color: theme.customPalette.line.onBase1.brand1, base: 'onBase' };
    default:
      return { color: theme.customPalette.surface.base1, base: 'onBase' };
  }
}

/*   Function to get the disabled styles   */
export function getDisabledStyles(colorVariant: ColorVariants, theme: Theme) {
  if (
    colorVariant === 'filled1' ||
    colorVariant === 'filled2' ||
    colorVariant === 'filledDanger'
  ) {
    return theme.customPalette.surfaceAccent.onBase.shade4;
  } else {
    return 'inherit';
  }
}

/*   Temp function to set margin left   */
function getColapsingMargin(colorVariant: ColorVariants, variant: ButtonTypes) {
  // if (variant === 'navMenuItem') {
  //   return 'initial';
  // }
  if (
    colorVariant === 'ghost1' ||
    colorVariant === 'ghost2' ||
    colorVariant === 'ghost3' ||
    colorVariant === 'covertDropdown'
  ) {
    if (variant === 'regular' || variant === 'dropdown') {
      return '-16px';
    } else {
      return '-12px';
    }
  } else {
    return 'initial';
  }
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},

      colorOverride: {},

      buttonRoot: ({
        colorVariant = 'filled1',
        variant = 'regular',
        size = 'regular',
        fullWidth = false,
        disableHover = false,
        disableFocus = false,
        marginCollapse = undefined
      }: Cube3ButtonProps) => ({
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
        borderRadius: theme.surfaceCorners.lowEmphasis,
        // minWidth: theme.sizing[64],
        height: theme.sizing[32],
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: getSurfaceColor(colorVariant, theme).color,
        ...theme.buttons[variant][size],
        width: fullWidth ? '100%' : 'intial',
        '&:hover:not($disableHover)': disableHover
          ? {}
          : {
              backgroundColor:
                theme.customPalette.surfaceState.getSurfaceStateColor(
                  getSurfaceColor(colorVariant, theme).color,
                  getSurfaceColor(colorVariant, theme).base,
                  ['hover1']
                )
            },

        '&:active': {
          opacity: 0.8
        },
        transition: 'opacity 20ms ease',

        marginLeft:
          marginCollapse === 'both' || marginCollapse === 'left'
            ? getColapsingMargin(colorVariant, variant)
            : 'initial',
        marginRight:
          marginCollapse === 'both' || marginCollapse === 'right'
            ? getColapsingMargin(colorVariant, variant)
            : 'initial',
        // '&::-moz-focus-inner': {
        //   border: 0
        // },
        // DISABLED FOR NOW. IT WORKS IN SOME PLACES BUT NEEDS MORE WORK.
        // '&:focus': {
        //   boxShadow: disableFocus
        //     ? 'none'
        //     : `0px 0px 0px 1px ${getOutlineColor(colorVariant, theme).color}`
        // },
        textDecoration: 'none'
      }),

      disableHover: {},

      iconButton: {
        '&$buttonRoot': {
          padding: '0px'
        }
      },
      disabledClass: ({ colorVariant = 'filled1' }: Cube3ButtonProps) => ({
        opacity: theme.customPalette.opacity.disabled1,
        cursor: 'default',
        pointerEvents: 'none',
        backgroundColor: getDisabledStyles(colorVariant, theme),
        '&:hover': {
          backgroundColor: getDisabledStyles(colorVariant, theme)
        }
      }),
      svgStyling: {},
      // svg position
      leftSvg: ({ variant = 'regular' }: Cube3ButtonProps) => ({
        marginRight:
          variant === 'navMenuItem'
            ? theme.customSpacing.margin[12]
            : theme.customSpacing.margin[8]
      }),
      centerSvg: ({ fullWidth, variant = 'regular' }: Cube3ButtonProps) => ({
        marginRight: fullWidth
          ? 'auto'
          : variant === 'navMenuItem'
          ? theme.customSpacing.margin[12]
          : theme.customSpacing.margin[8],
        marginLeft: fullWidth
          ? 'auto'
          : variant === 'navMenuItem'
          ? theme.customSpacing.margin[12]
          : theme.customSpacing.margin[8]
      }),
      rightSvg: {},
      textStyling: {},
      dropdownSvgStyling: ({ colorVariant = 'filled1' }) => ({
        width: theme.sizing[24],
        opacity: colorVariant === 'covertDropdown' ? 0 : 'initial'
      }),
      dropdownSvgStylingConvert: () => ({
        opacity: 'initial'
      }),
      shortCutTip: {
        marginLeft: 'auto',
        order: 2,
        paddingLeft: 12
      }
    }),
  { name: 'C3Cube3Button' }
);

export interface Cube3ButtonProps {
  variant?: ButtonTypes;
  colorVariant?: ColorVariants;
  size?: SizeVariations;
  text?: string;
  typographyClassName?: string;
  svgStyles?: string;
  pathRightSvg?: string;
  pathCenterSvg?: string;
  pathLeftSvg?: string;
  extraSVGProps?: GeneralSVGProperties;
  dataType?: 'librarydeselectoverride' | 'none';
  disabled?: boolean;
  extraButtonProperties?: {
    component?: string | React.ElementType;
    href?: string;
    download?: boolean;
    datatype?: string;
    [key: string]: any;
  };
  active?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  fullWidth?: boolean;
  disableHover?: boolean;
  disableFocus?: boolean;
  className?: string | {};
  forwardRef?: any;
  classes?: { rightSvg?: string; root?: string; colorOverride?: string | {} };
  marginCollapse?: 'none' | 'both' | 'left' | 'right';
  component?: string | React.ElementType;
  name?: string;
  children?: React.ReactNode;
  shortCutTip?: string;
  'data-cy'?: string;
}

function Cube3Button(props: Cube3ButtonProps) {
  const [hoverRef, isHovered] = UseHover();

  const {
    variant,
    colorVariant = 'filled1',
    text,
    typographyClassName,
    pathLeftSvg,
    pathRightSvg,
    pathCenterSvg,
    extraSVGProps,
    svgStyles,
    dataType,
    disabled,
    extraButtonProperties = { buttonRef: null },
    active,
    onClick,
    className,
    component: Component = 'button',
    name,
    children,
    shortCutTip,
    disableHover
  } = props;
  const classes = useStyles(props);
  const {
    buttonRoot,
    disabledClass,
    dropdownSvgStylingConvert,
    root,
    colorOverride
  } = classes;

  const typographyStyles = getTypographyStyles(colorVariant, disabled, active);
  const iconStyles = getIconStyles(colorVariant, disabled, active);
  const { buttonRef, fullWidth, ...rest } = extraButtonProperties;

  return (
    <Component
      name={name}
      ref={mergeRefs(hoverRef, buttonRef)}
      data-type={dataType}
      data-cy={props['data-cy']}
      disabled={disabled}
      className={useClassName(
        root,
        buttonRoot,
        disableHover && classes.disableHover,
        className,
        pathCenterSvg && !children && !text && classes.iconButton,
        disabled && disabledClass,
        colorOverride
      )}
      onClick={onClick ? (event) => onClick(event) : undefined}
      type="button"
      {...rest}
    >
      {pathLeftSvg && (
        <GeneralSVG
          className={`${classes.svgStyling} ${classes.leftSvg} ${svgStyles}`}
          path={pathLeftSvg}
          {...extraSVGProps}
          {...iconStyles}
        />
      )}
      {text ? (
        <>
          <Typography
            className={typographyClassName}
            color={typographyStyles?.color}
            typographyStyle={typographyStyles?.typographyStyle}
            surface={typographyStyles?.surface}
          >
            {text}
          </Typography>
          {shortCutTip && (
            <Typography
              variant="body3"
              className={classes.shortCutTip}
              color={'contrast2'}
              surface={typographyStyles?.surface}
            >
              {shortCutTip}
            </Typography>
          )}
        </>
      ) : pathCenterSvg ? (
        <GeneralSVG
          className={`${classes.svgStyling} ${classes.centerSvg} ${svgStyles}`}
          path={pathCenterSvg}
          {...extraSVGProps}
          {...iconStyles}
        />
      ) : children ? (
        children
      ) : (
        // ABOSULTE MINIMUM
        <GeneralSVG
          className={`${classes.svgStyling} ${classes.leftSvg} ${svgStyles}`}
          path={iconElepsis}
          {...extraSVGProps}
          {...iconStyles}
        />
      )}
      {pathRightSvg && (
        <GeneralSVG
          className={`${classes.svgStyling} ${classes.rightSvg} ${svgStyles}`}
          path={pathRightSvg}
          {...extraSVGProps}
          {...iconStyles}
        />
      )}
      {(variant === 'dropdown' || variant === 'dropdownWithIcon') && (
        <GeneralSVG
          className={`${classes.svgStyling} ${
            classes.dropdownSvgStyling
          } ${svgStyles} ${isHovered && dropdownSvgStylingConvert}`}
          path={iconKeyboardArrowDown}
          {...extraSVGProps}
          {...iconStyles}
        />
      )}
    </Component>
  );
}

export default Cube3Button;
