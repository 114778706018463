import { Color } from 'csstype';

/**
 * Stack css colors using the "linear-gradient" trick
 * CSS doesn't allow multiple background colors to be mixed, but by using linear gradients,
 * the colors are treated as background-images, which can be stacked using alpha blending.
 *
 * The output for a single color input can also be used to layer a semi-transparent color over a background-image.
 */
export const stackColors = (colors: Color | Color[], gradient?: boolean) => {
  if ((typeof colors === 'string' || colors?.length === 1) && !gradient) {
    return typeof colors === 'string' ? colors : colors[0];
  }
  return []
    .concat(colors)
    .reverse()
    .map((c) => {
      return alreadyGradient(c)
        ? c
        : `linear-gradient(180deg, ${c} 0%,${c} 100%)`;
    })
    .join(', ');
};

const gradientExp = /(?:linear-gradient|radial-gradient)/;
const alreadyGradient = (c) => gradientExp.test(c);
