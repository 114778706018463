import React, { createContext, useCallback, useRef } from 'react';
import Surface from '../Surface/Surface';
import PageLayout from './PageLayout';
import PageHeader from './PageHeader';
import ScopeBar from './ScopeBar';
import WorkspaceContents from './WorkspaceContents';

interface WorkspaceLayoutTemplateProps {
  errorBoundaryComponent?: React.ComponentType;
  workspaceLogo?: React.ComponentType;
  breadcrumbs?: React.ComponentType;
  accountIndicators?: React.ComponentType;
  searchBox?: React.ComponentType;
  navigationDrawer?: React.ComponentType;
  contentPane?: React.ComponentType;
  infoPane?: React.ComponentType;
  scopeHeader?: React.ComponentType;
  contentPaneTransparent?: boolean;
  onAdminCenterPath?: boolean;
  inProjectTemplateView?: boolean;
}

export const configureIfCan =
  (ErrorBoundary: React.ComponentType<any> = React.Fragment) =>
  (Component) => {
    return Component ? (
      ErrorBoundary !== React.Fragment ? (
        <ErrorBoundary name="workspaceLayout">
          <Component />
        </ErrorBoundary>
      ) : (
        <Component />
      )
    ) : null;
  };
export const ContentContainerContext = createContext(undefined);

const WorkspaceLayoutTemplateBase = React.memo(
  ({
    errorBoundaryComponent = undefined,
    workspaceLogo,
    breadcrumbs,
    accountIndicators,
    searchBox,
    navigationDrawer,
    scopeHeader,
    contentPane,
    contentPaneTransparent,
    onAdminCenterPath,
    inProjectTemplateView
  }: WorkspaceLayoutTemplateProps) => {
    const ifCan = useCallback(configureIfCan(errorBoundaryComponent), [
      errorBoundaryComponent
    ]);
    const containerRef = useRef(undefined);
    return (
      <PageLayout
        headerComponent={
          <PageHeader
            workspaceLogoComponent={ifCan(workspaceLogo)}
            breadCrumbsComponent={ifCan(breadcrumbs)}
            avatarComponent={ifCan(accountIndicators)}
            extraSpaceLogo={true}
          />
        }
        scopeBarComponent={
          (!onAdminCenterPath || inProjectTemplateView) && (
            <ScopeBar
              searchComponent={
                inProjectTemplateView ? (
                  <SearchBoxReplacement />
                ) : (
                  ifCan(searchBox)
                )
              }
              scopeHeaderComponent={ifCan(scopeHeader)}
            />
          )
        }
        contentComponent={
          <WorkspaceContents
            containerRef={containerRef}
            navigationComponent={ifCan(navigationDrawer)}
            contentComponent={
              contentPaneTransparent ? (
                <Surface background={'transparent'}>
                  {ifCan(contentPane)}
                </Surface>
              ) : (
                <Surface
                  corners={'roundedTop'}
                  floating={true}
                  gradient={'lamp'}
                  background={'base2'}
                >
                  {ifCan(contentPane)}
                </Surface>
              )
            }
          />
        }
      />
    );
  }
);

export default WorkspaceLayoutTemplateBase;

const SearchBoxReplacement = () => {
  return <div style={{ width: 212, marginRight: 24 }}></div>; // same style as searchBox
};
