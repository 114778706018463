export const makeClipboardReport = (validation, asset) => {
  const { errors = [], warnings = [] } = validation || {};

  const passed = errors.length === 0;

  const errorsText = errors.map(e => `- ${e}`).join('\n\r');
  const warningsText = warnings.map(w => `- ${w}`).join('\n\r');

  const text = passed
    ? `"${asset.display_name}" passed the quality check with the following warnings:

${warningsText}   

Export can proceed with warnings, but some stations might still reject the asset.
`
    : `"${
        asset.display_name
      }" did not pass the quality check for the following reasons:

${errorsText}   

Fix these errors, upload a new file, and try exporting again.

${
  warnings.length
    ? `Addtional warnings: 

${warningsText}   

Export can proceed with warnings, but some stations might still reject the asset.
`
    : ''
}`;

  return text;
};
