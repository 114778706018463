import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import React, { useCallback, useEffect } from 'react';
import { useModalActions } from '../modalActions';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { compose } from '@cube3/state/src/utils/component-helpers';
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import {
  isRequired,
  makeValidator,
  maxCharacters,
  noSpaceAtStart
} from '../../../../forms/helpers/validators';
import GenericFieldWithErrors from '../../../../forms/helpers/fields/GenericFieldWithErrors';
import { Asset } from '@cube3/common/model/schema/resources/asset';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { Url } from '@cube3/common/model/schema/resources/url';
import { connect } from 'react-redux';
import { getErrorCodes } from '@cube3/state/src/wrapped-cube-client';
import { useCreateUrlAsset } from '../../CommandBar/hooks/useCreateUrlAsset';
import { useMutateUrlAsset } from '../../CommandBar/hooks/useEditUrlAsset';
import { isValidURL } from '@cube3/ui/src/keyboard-shortcuts/utils';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';

interface MappedHOCProps {
  url: Url;
}
interface ModalRecievedProps {
  modalContext: {
    intent: 'create' | 'edit';
    // mutation
    asset: Asset;
    // creation
    parentId: string;
    clipboardUrl?: string;
  };
}
type FormValues = {
  name: string;
  location: string;
};
type Props = MappedHOCProps &
  ModalRecievedProps &
  InjectedFormProps &
  FormValues;

const form = 'asset_create_or_edit_url';
const mapStateToProps = (state, ownProps: Props) => {
  const { url, modalContext } = ownProps;
  const values = getFormValues(form)(state);

  const prefilledUrl = modalContext?.clipboardUrl;
  const prefilledName =
    prefilledUrl && `${new URL(prefilledUrl).hostname} link`;

  return {
    ...values,
    initialValues: {
      name: ownProps.modalContext.asset?.display_name || prefilledName,
      location: url?.location || prefilledUrl
    }
  };
};

const maxChars = (value: string) => maxCharacters(value, 256);
const nameValidator = makeValidator([maxChars, isRequired, noSpaceAtStart]);
const urlValidator = makeValidator([
  maxChars,
  isRequired,
  noSpaceAtStart,
  (val) => {
    const regex = /^https?:\/\//;
    const startWithHTTP = regex.test(val);
    return isValidURL(val) || val === undefined || val === ''
      ? undefined
      : !startWithHTTP
      ? 'Url should start with http:// or https://'
      : 'Invalid url';
  }
]);

export const Modal: React.FC<Props> = (props) => {
  const {
    dirty,
    name,
    valid,
    location,
    submitting,
    submitSucceeded,
    submitFailed,
    url,
    modalContext,
    error
  } = props;
  const { intent, asset, parentId } = modalContext;

  const { previousModal } = useModalActions();

  const { onCreate, urlId } = useCreateUrlAsset(parentId, name, location, form);
  const onMutate = useMutateUrlAsset(asset, url, form);

  const onSubmit = useCallback(() => {
    if (intent === 'create') {
      onCreate();
    } else {
      onMutate(name, location);
    }
  }, [intent, onCreate, onMutate, name, location]);

  useEffect(() => {
    if (
      submitSucceeded &&
      ((intent === 'create' && urlId) || intent === 'edit')
    ) {
      previousModal();
    }
  }, [submitSucceeded, intent, urlId, previousModal]);

  const errorMessage = getErrorCodes(error);

  const disabled = !valid || submitFailed;
  const loading = (asset && asset.relationships.url && !url) || submitting;
  useGlobalSubmit(onSubmit, disabled || loading);

  return (
    <ModalNoticeUI
      onCloseEvent={previousModal}
      loading={loading}
      title={intent === 'create' ? 'New URL' : 'Edit URL'}
      footerRightComponent={
        <>
          <Button
            onClick={previousModal}
            label="Cancel"
            //colorVariant="ghost2"
            background="secondary"
            buttonStyle="ghost"
          />
          <Button
            disabled={disabled}
            background="primary"
            buttonStyle="solid"
            label={intent === 'create' || dirty ? 'Save' : 'Done'}
            onClick={onSubmit}
          />
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: 16
        }}
      >
        <div style={{ marginLeft: '-8px', width: 60 }}>
          <Typography>Name</Typography>
        </div>
        <div style={{ flex: 1, marginLeft: 32 }}>
          <Field
            inputProps={{ autoFocus: true }}
            name={'name'}
            validate={nameValidator}
            component={GenericFieldWithErrors}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline'
        }}
      >
        <div style={{ marginLeft: '-8px', width: 60 }}>
          <Typography>Location</Typography>
        </div>
        <div style={{ flex: 1, marginLeft: 32 }}>
          <Field
            name={'location'}
            validate={urlValidator}
            component={GenericFieldWithErrors}
          />
        </div>
      </div>
      <div style={{ marginLeft: '-8px', marginTop: 8 }}>
        {errorMessage && (
          <Typography color="danger1">{errorMessage}</Typography>
        )}
      </div>
    </ModalNoticeUI>
  );
};

const withURLResource = (Wrapped) => (props: ModalRecievedProps) => {
  const { asset } = props.modalContext;
  const url = useResource__ALPHA({
    resourceId: asset ? asset.relationships.url?.id : undefined,
    resourceType: 'url'
  }).resource;

  return <Wrapped {...props} url={url} />;
};

export const CreateOrEditUrlModal = compose(Modal)(
  withURLResource,
  connect(mapStateToProps),
  reduxForm({
    form: form,
    destroyOnUnmount: true
  })
);
