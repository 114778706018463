import { rafUpdateBatcher, timeoutUpdateBatcher, notify } from './batcher';

const exclude = /@@redux-form/;
const slow = /(:?cube3\/resource-edges|cube3\/resource-nodes)/;

const shouldBatch = action => {
  // return true;
  return !exclude.test(action.type);
};

const shouldBatchLowPriority = action => {
  // return true;
  return slow.test(action.type);
};

export default () => next => action => {
  const resolved = next(action);

  if (!shouldBatch(action)) {
    notify();
  } else {
    if (!shouldBatchLowPriority(action)) {
      rafUpdateBatcher();
    } else {
      timeoutUpdateBatcher();
    }
  }

  return resolved;
};
