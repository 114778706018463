import { legacyApiDataKeys as keys } from '../../../../../wrapped-cube-client';

import { actionCreators as nodeActionCreators } from '../../../../ducks/resource-nodes';
import { actionCreators as edgeActionCreators } from '../../../../ducks/resource-edges';
import { handleCreateBase } from './handleCreateBase';

export const handleCreateByAncestorLegacy = ({
  res,
  action,
  config,
  dispatch,
  uploadIds,
  getState
}) => {
  const resource = res[keys.LEGACY_API_RESOURCE];

  handleCreateBase({
    resource,
    action,
    config,
    dispatch,
    uploadIds,
    getState
  });

  dispatch(
    nodeActionCreators.receiveResourceStub(config.resourceType, resource)
  );
  dispatch(
    edgeActionCreators.receiveResourceEdges(
      config.ancestorId,
      config.ancestorType,
      config.edgeLabel,
      config.resourceType,
      [
        {
          id: resource.id,
          type: config.resourceType
        }
      ],
      { merge: true }
    )
  );
};
