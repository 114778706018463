import * as React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';

/** Returns the corresponding key from custom-theme based on the given background color */
const getBackgroundKey = (background: backgrounds, theme: Theme) => {
  switch (background) {
    case 'base1':
      return theme.customPalette.line.onBase1;

    case 'base2':
      return theme.customPalette.line.onBase2;

    case 'brand':
      return theme.customPalette.line.onBrand;

    default:
      console.warn(
        'Tried to get the key for a background was not defined on base keys in Divider',
        background
      );
      return undefined;
  }
};

/** Returns the color value of the requested background with dividercolor    */
const getDividerColor = (
  background: backgrounds,
  requestedColor: dividerColors,
  theme: Theme
) => {
  const backgroundKey = getBackgroundKey(background, theme);

  // if getbasekey was not successfull, return an easy to recognize color, so it's easier to debug the problem
  if (!backgroundKey) {
    return 'pink';
  }

  // try to read the value of the requested color
  const color = backgroundKey[requestedColor];

  // if the background exists, but the color does not...
  if (!color) {
    console.warn(
      'The requested color',
      requestedColor,
      'does not exist on this background'
    );
    return 'pink';
  }

  return color;
};

// const getColorKey = (

const styles = (theme: Theme) =>
  createStyles({
    horizontal: {
      height: '1px',
      width: '100%'
    },
    vertical: {
      height: '100%',
      width: '1px'
    },
    // picks a color from the custom theme object.
    color: (props: Props) => {
      const { background = 'base2', color = 'contrast2' } = props;

      return {
        background: getDividerColor(background, color, theme)
      };
    },
    gutter: {
      marginBottom: theme.sizing[16]
    }
  });

type dividerColors =
  | 'contrast1'
  | 'contrast2'
  | 'contrast3'
  | 'contrast4'
  | 'shadow1'
  | 'brand1'
  | 'brand2';
type backgrounds = 'base1' | 'base2' | 'brand';

export interface DividerProperties {
  /** Classes apply on the root `div` component */
  className?: string;

  /** The color applied to the `Divider`. Defaults to `contrast2` */
  color?: dividerColors;

  /** The background the `Divider` is used on, falls back to `base2` */
  background?: backgrounds;

  /** Setting the direction applies another class to the `Divider`, defaults to `horizontal` */
  direction?: 'horizontal' | 'vertical';

  /** Add an extra margine to the bottom` */
  gutter?: boolean;
}

interface Props extends DividerProperties {
  classes?: {
    horizontal?: string;
    vertical?: string;
    color?: string;
    gutter?: string;
  };
}

class DividerComp extends React.PureComponent<React.PropsWithChildren<Props>> {
  render() {
    const {
      classes,
      direction = 'horizontal',
      className,
      gutter = false
    } = this.props;

    return (
      <div
        className={`${classes[direction]} ${classes.color} ${className} ${
          gutter ? classes.gutter : ''
        }`}
      >
        {this.props.children}
      </div>
    );
  }
}

/**
 * @summary Draws a `div` representing a line.
 * @author Simon
 */
export const Divider = withStyles(styles, { withTheme: true })(DividerComp);
