export const projectStatusTypes = {
  ACTIVE: 'ProjectStatusOpen' as const,
  ARCHIVED: 'ProjectStatusArchived' as const,
  ALL: null
};

export const publicTypes = {
  PUBLIC: true,
  PRIVATE: false,
  ALL: null
};

// project status view options
export const statusViews = [
  { display_name: 'Active', value: projectStatusTypes.ACTIVE },
  { display_name: 'Archived', value: projectStatusTypes.ARCHIVED },
  { display_name: 'All', value: projectStatusTypes.ALL }
];

// project access view options
export const accessViews = [
  { display_name: 'Private', value: publicTypes.PRIVATE },
  { display_name: 'Visible to workspace members', value: publicTypes.PUBLIC },
  { display_name: 'All', value: publicTypes.ALL }
];

export const specialIds = ['__status__', '__access__'];

// TODO find a way to get from subset;
export const getSpecialOptions = (id, projects) => {
  if (specialIds.includes(id)) {
    if (id === '__status__') {
      const opts = projects
        ?.map((p) => p.project_status)
        .filter((s) => s !== undefined)
        .reduce((acc: Set<string>, val) => {
          acc.add(val);
          return acc;
        }, new Set());

      // const opts = new Set(Object.keys(projectStatusTypes).map(k => projectStatusTypes[k]));

      return opts
        ? Array.from(opts.values()).map((s) =>
            statusViews.find((v) => v.value === s)
          )
        : undefined;
    }
    if (id === '__access__') {
      const opts = projects
        ?.map((p) => p.is_public)
        .filter((s) => s !== undefined)
        .reduce((acc: Set<boolean>, val) => {
          acc.add(val);
          return acc;
        }, new Set());

      // const opts = new Set(Object.keys(publicTypes).map(k => publicTypes[k]));

      return opts
        ? Array.from(opts.values()).map((s) =>
            accessViews.find((v) => v.value === s)
          )
        : undefined;
    }
  }
};

export const specialTag = (values, id) => {
  if (specialIds.includes(id)) {
    if (id === '__status__') {
      switch (values[id]) {
        case projectStatusTypes.ACTIVE:
          return 'active';
        case projectStatusTypes.ARCHIVED:
          return 'archived';
        case undefined:
          return 'all';
        default:
          return undefined;
      }
    }

    if (id === '__access__') {
      switch (values[id]) {
        case true:
          return 'public';
        case false:
          return 'private';
        case undefined:
          return 'all';
        default:
          return undefined;
      }
    }
  }
};
