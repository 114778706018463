import { useMemo } from 'react';

import {
  PublicResource,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { ContractKeyGroup } from '@cube3/common/model/schema/resources/contract-key-group';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { ContractDurationField } from '../prefabs/ContractDurationField';

export const useGeneralForm = (contractId, draft = false) => {
  const contractTypes = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: useCurrentWorkspace()[0],
    edgeType: 'contract-type',
    edgeLabel: 'contract-types'
  });

  return useMemo(() => {
    if (!contractTypes.resources?.length) {
      return { category: undefined };
    }

    const category = {
      id: `contract_general_${contractId}`,
      display_name: 'General',
      keys: [
        {
          id: 'display_name',
          value_type: 'string',
          display_name: 'Description',
          json_schema: JSON.stringify({
            type: 'string',
            required: true,
            minLength: 1
          }),
          required: true
        },
        {
          id: 'duration',
          value_type: 'custom',
          component: ContractDurationField,
          display_name: 'Period',
          json_schema: JSON.stringify({
            type: 'object',
            properties: {
              expires: { type: 'boolean' }
            },

            if: {
              properties: { expires: { const: true } }
            },
            then: {
              properties: {
                expires: { type: 'boolean' },
                starts_at: { type: 'string', format: 'date-time' },
                expires_at: { type: 'string', format: 'date-time' }
              },
              required: ['starts_at', 'expires_at']
            },
            else: {
              properties: {
                expires: { type: 'boolean' },
                starts_at: { type: ['string', 'null'] },
                expires_at: { type: ['string', 'null'] }
              }
            }
          })
        },
        // {
        //   id: 'expires',
        //   value_type: 'select',
        //   display_name: 'Period',
        //   json_schema:
        //     '{"type": "string", "enum": ["Specific period", "Never expires" ]}'
        // },
        // {
        //   id: 'starts_at',
        //   value_type: 'date',
        //   display_name: 'Start date',
        //   json_schema: '{"type":"string"}'
        // },
        // {
        //   id: 'expires_at',
        //   value_type: 'date',
        //   display_name: 'End date',
        //   json_schema: '{"type":"string"}'
        // },
        {
          id: 'attached_projects',
          value_type: 'resource_picker',
          display_name: 'Attached projects',
          json_schema: JSON.stringify({
            type: 'array',
            title: 'ContractProjects',
            items: { type: 'string' }
          })
        },
        {
          id: 'attached_assets',
          value_type: 'resource_picker',
          display_name: 'Attached assets',
          json_schema: JSON.stringify({
            type: 'array',
            title: 'ContractAssets',
            items: { type: 'string' }
          })
        },
        {
          id: 'notification_recipients',
          value_type: 'resource_picker',
          display_name: 'Send expiration notifications to',
          json_schema: JSON.stringify({
            type: 'array',
            title: 'ContractRecipients',
            items: {
              type: 'object',
              properties: {
                display_name: { type: 'string' },
                id: { type: 'string' },
                email_address: { type: 'string', format: 'email' }
              },
              required: ['email_address']
            }
          })
        },
        {
          id: 'contract_type',
          value_type: 'select',
          display_name: 'Contract type',
          read_only: !draft,
          json_schema: JSON.stringify({
            type: 'object',
            properties: {
              display_name: {
                type: 'string'
              },
              id: { type: 'string' }
            },
            required: true,
            enum: contractTypes.resources?.map((t) => ({
              id: t.id,
              display_name: t.display_name,
              type: t.type
            }))
          }),
          required: true
        }
      ]
    } as ContractKeyGroup;

    return {
      category
    };
  }, [contractId, contractTypes.resources, draft]);
};

export interface Fields {
  display_name: string;
  duration: {
    expires: boolean;
    starts_at: string;
    expires_at: string;
  };
  attached_projects: ResourceIdentifier[];
  attached_assets: ResourceIdentifier[];
  notification_recipients: string[];
  contract_type: PublicResource;
}
