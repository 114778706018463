import { ContentTreeContext } from '@cube3/common/model/resource-types';
import { ns } from './config';
import { QueueTab } from './types';

export const TRACK_FULLSCREEN = `${ns}/TRACK_FULLSCREEN`;
export const TOGGLE_FULLSCREEN = `${ns}/TOGGLE_FULLSCREEN`;
//
export const TOGGLE_GRIDVIEW = `${ns}/TOGGLE_GRIDVIEW`;
export const TOGGLE_LISTVIEW = `${ns}/TOGGLE_LISTVIEW`;
export const SET_MAX_GRID_ITEMS = `${ns}/SET_MAX_GRID_ITEMS`;
export const SET_MAX_COLUMNS = `${ns}/SET_MAX_COLUMNS`;

export const SET_ORDERTYPE = `${ns}/SET_ORDERTYPE`;
export const SET_ORDERDIRECTION = `${ns}/SET_ORDERDIRECTION`;

export const SET_TOUR = `${ns}/SET_TOUR`;

export const TOGGLE_INFOPANE = `${ns}/TOGGLE_INFOPANE`;
export const TOGGLE_MEDIAQUEUE = `${ns}/TOGGLE_MEDIAQUEUE`;
export const SET_UPLOAD_QUEUE_BUSY = `${ns}/SET_UPLOAD_QUEUE_BUSY`;

export const actions = {
  TRACK_FULLSCREEN,
  TOGGLE_FULLSCREEN,
  TOGGLE_GRIDVIEW,
  TOGGLE_LISTVIEW,
  SET_MAX_GRID_ITEMS,
  SET_MAX_COLUMNS,
  SET_ORDERTYPE,
  SET_ORDERDIRECTION,
  TOGGLE_INFOPANE,
  TOGGLE_MEDIAQUEUE,
  SET_UPLOAD_QUEUE_BUSY,
  SET_TOUR
};

export function toggleFullscreen() {
  return {
    type: TOGGLE_FULLSCREEN
  };
}

export function trackFullscreen(enabled) {
  return {
    type: TRACK_FULLSCREEN,
    payload: enabled
  };
}

export function toggleInfoPane() {
  return {
    type: TOGGLE_INFOPANE
  };
}

export function toggleMediaqueue(
  force: boolean = undefined,
  tab: QueueTab = undefined
) {
  return {
    type: TOGGLE_MEDIAQUEUE,
    payload: {
      tab,
      force
    }
  };
}

export function toggleGridView() {
  return {
    type: TOGGLE_GRIDVIEW
  };
}

export function toggleListView() {
  return {
    type: TOGGLE_LISTVIEW
  };
}
export function setMaxGridItems(count) {
  return {
    type: SET_MAX_GRID_ITEMS,
    payload: count
  };
}

export function setMaxColumns(count) {
  return {
    type: SET_MAX_COLUMNS,
    payload: count
  };
}

export function setSortType(value: {
  field: string;
  listType: ContentTreeContext;
}) {
  return {
    type: SET_ORDERTYPE,
    payload: {
      listType: value.listType || 'library',
      value: value.field
    }
  };
}

export function setOrderDirection(value: {
  fieldParent: string;
  field: string;
  listType: ContentTreeContext;
}) {
  return {
    type: SET_ORDERDIRECTION,
    payload: {
      listType: value.listType || 'library',
      value: value.field,
      fieldParent: value.fieldParent
    }
  };
}

export function setUploadQueueBusy(busy: boolean) {
  return {
    type: SET_UPLOAD_QUEUE_BUSY,
    payload: busy
  };
}

export const setTour = (tour: boolean) => {
  return {
    type: SET_TOUR,
    payload: tour
  };
};
export const actionCreators = {
  toggleFullscreen,
  trackFullscreen,
  toggleGridView,
  toggleListView,
  setMaxGridItems,
  setMaxColumns,
  setOrderDirection,
  setSortType,
  toggleMediaqueue,
  setUploadQueueBusy,
  setTour
};
