import { assetMimeTypes } from '@cube3/common/model/types';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useCallback, useEffect, useMemo, useRef } from 'react';

/**
 * @param parentId : parent node id
 * @param name : url display name
 * @param location : url location
 * @param form : form name
 * @returns submit callback
 */
export const useCreateUrlAsset = (
  parentId: string,
  name: string,
  location: string,
  form: string
) => {
  const tempAssetId = useRef(uuidv4()).current;
  const mappedAssetId = useMappedId__ALPHA(tempAssetId);
  const tempUrlId = useRef(uuidv4()).current;
  const mappedUrlId = useMappedId__ALPHA(tempUrlId);

  const [createAsset] = useCreateResource__ALPHA(
    useMemo(
      () => ({
        resourceType: 'asset',
        cacheInvalidator: (r) => [r],
        actionDecorators: [
          addFormId(form, {
            formState: undefined,
            useRequestStatus: true,
            delegate: true
          })
        ]
      }),
      [addFormId, form]
    )
  );
  const [createUrl, createUrlStatus] = useCreateResource__ALPHA(
    useMemo(
      () => ({
        ancestor: {
          type: 'asset',
          id: mappedAssetId
        },
        resourceType: 'url',
        cacheInvalidator: () => [
          {
            type: 'folder',
            id: parentId,
            relationship: 'assets'
          },
          {
            type: 'asset',
            id: mappedAssetId
          }
        ],
        actionDecorators: [
          addFormId(form, {
            formState: undefined,
            useRequestStatus: true,
            delegate: true
          })
        ]
      }),
      [addFormId, form, parentId, mappedAssetId]
    )
  );

  /** first create an asset with a special mime type */
  const onCreate = useCallback(() => {
    createAsset({
      type: 'asset',
      display_name: name,
      mime_type: assetMimeTypes.URL,
      temporaryId: tempAssetId,
      relationships: {
        parent: {
          data: {
            type: 'node',
            id: parentId
          }
        }
      }
    });
  }, [createAsset, name, parentId, tempAssetId]);

  // then create the `url`
  useEffect(() => {
    if (mappedAssetId && !createUrlStatus) {
      createUrl({
        type: 'url',
        temporaryId: tempUrlId,
        location: location
      });
    }
  }, [mappedAssetId, createUrlStatus, createUrl, location]);

  return {
    onCreate,
    urlId: mappedUrlId
  };
};
