import { FSA } from '../../flux-standard-action';
import { actions } from './actions';
import { UiScreenState, UiScreenDefaultState } from './state';
import { ASC, DESC } from './state';

export function reducer(
  state: UiScreenState = UiScreenDefaultState,
  action: FSA
) {
  switch (action.type) {
    case actions.TOGGLE_FULLSCREEN:
      // ignore toggles while switching
      if (state.fullscreen !== state.fullscreenRequested) {
        return state;
      }
      return {
        ...state,
        fullscreenRequested: !state.fullscreenRequested
      };

    case actions.TRACK_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.payload,
        fullscreenRequested: action.payload // normalize
      };
    case actions.TOGGLE_GRIDVIEW:
      return {
        ...state,
        gridView: true
      };
    case actions.TOGGLE_LISTVIEW:
      return {
        ...state,
        gridView: false
      };

    case actions.SET_MAX_GRID_ITEMS:
      return action.payload !== state.maxGridItems
        ? {
            ...state,
            maxGridItems: action.payload
          }
        : state;

    case actions.SET_MAX_COLUMNS:
      return action.payload !== state.maxColumns
        ? {
            ...state,
            maxColumns: action.payload
          }
        : state;

    case actions.SET_ORDERTYPE:
      if (
        state.sorting[action.payload.listType].orderBy === action.payload.value
      ) {
        return {
          ...state,
          sort: {
            ...state,
            sorting: {
              ...state.sorting,
              [action.payload.listType]: {
                orderBy: action.payload.value,
                orderDirection:
                  state.sorting[action.payload.listType].orderDirection === DESC
                    ? ASC
                    : DESC
              }
            }
          }
        };
      } else {
        return {
          ...state,
          sorting: {
            ...state.sorting,
            [action.payload.listType]: {
              ...state.sorting[action.payload.listType],
              orderBy: action.payload.value
            }
          }
        };
      }
    case actions.SET_ORDERDIRECTION:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          [action.payload.listType]: {
            orderDirection: action.payload.value,
            orderBy: action.payload.fieldParent
          }
        }
      };
    case actions.TOGGLE_INFOPANE:
      return {
        ...state,
        infoPaneToggled: !state.infoPaneToggled
      };

    case actions.TOGGLE_MEDIAQUEUE: {
      const newToggleState =
        action.payload.force !== undefined
          ? action.payload.force
          : !state.mediaQueueToggled;
      return {
        ...state,
        mediaQueueToggled: newToggleState,
        mediaQueueTab: action.payload.tab || state.mediaQueueTab,
        mediaQueueLastOpened: !state.mediaQueueToggled
          ? Date.now()
          : newToggleState
          ? state.mediaQueueLastOpened
          : undefined
      };
    }

    case actions.SET_UPLOAD_QUEUE_BUSY:
      if (action.payload === state.uploadQueueBusy) {
        return state;
      }
      return { ...state, uploadQueueBusy: action.payload };

    case actions.SET_TOUR:
      return {
        ...state,
        tour: action.payload
      };
    default:
      return state;
  }
}
