import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import {
  ExportChannelsPicker,
  useExportChannelsStyles
} from '@cube3/ui/src/exports/ExportChannelsPicker';
import {
  GoogleAdsCustomer,
  GoogleAdsConfig
} from '@cube3/common/model/resource-types';
import { ExportTarget } from '@cube3/common/model/schema/resources/export-target';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { useModalActions } from '../../Modals/modalActions';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';

const emptyArray = [];

export const GoogleAdsAccountsModal = (props) => {
  const { modalContext } = props;
  const { exportTargetTypeId } = modalContext;

  const [selectedAccount, setSelectedAccount] =
    useState<ExportTarget<'googleads'>>(null);
  const [selectedCustomer, setSelectedCustomer] =
    useState<GoogleAdsCustomer>(null);

  const { previousModal, openModal } = useModalActions();
  const [currentAccountId] = useCurrentAccount();

  const params = useMemo(() => {
    return {
      filter: {
        exportTargetTypeId: exportTargetTypeId // NOTE: not implemented
      }
    };
  }, [exportTargetTypeId]);

  const {
    resources: exportTargets,
    status: exportTargetStatus
  }: { resources?: ExportTarget<'googleads'>[]; status?: string } =
    useResourceList__ALPHA({
      resourceId: currentAccountId,
      resourceType: 'account',
      edgeType: 'export-target',
      edgeLabel: 'export-targets',
      params,
      strategy: 'fetch-on-mount'
    });

  /** the customers of the selected account */
  const customers =
    (selectedAccount &&
      selectedAccount.attributes.integration_config.customers) ||
    emptyArray;

  const handleNext = useCallback(() => {
    openModal(
      'social_export_options',
      {
        customer: selectedCustomer,
        exportTargetId: selectedAccount?.id,
        exportTargetTypeId: exportTargetTypeId,
        platform: 'Google Ads'
      },
      false
    );
  }, [openModal, selectedAccount, selectedCustomer, exportTargetTypeId]);

  const handleManageIntegration = useCallback(() => {
    // open modal version of Account’s Integrations page
    openModal('connected_accounts_modal', null, false);
  }, [openModal]);

  const handleSelectAccount = useCallback((account) => {
    setSelectedAccount(account);
    setSelectedCustomer(
      account.channels && account.channels.length === 1
        ? account.channels[0]
        : undefined
    );
  }, []);

  useEffect(() => {
    if (!selectedAccount && exportTargets?.length > 0) {
      setSelectedAccount(exportTargets[0]);
    }
    if (
      selectedAccount &&
      !exportTargets.find((ac) => ac.id === selectedAccount?.id)
    ) {
      setSelectedAccount(null);
    }
  }, [exportTargets, selectedAccount]);

  useEffect(() => {
    if (customers.length === 1) {
      setSelectedCustomer(customers[0]);
    }
    if (customers.length === 0) {
      setSelectedCustomer(null);
    }
  }, [customers]);

  const classes = useExportChannelsStyles();

  return (
    <ModalMenuUI
      title="Export to Google Ads • Select Account"
      loading={!hasResolved(exportTargetStatus)}
      onCloseEvent={previousModal}
      classes={{ contents: classes.modalContents }}
      wide={true}
      footerLeftComponent={
        <Button
          text={'Manage integration'}
          onClick={handleManageIntegration}
          colorVariant={'ghost1'}
        />
      }
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={previousModal}
            disabled={false}
          />
          <Button
            disabled={!selectedCustomer}
            colorVariant={'filled1'}
            text={'Next'}
            onClick={handleNext}
          />
        </>
      }
    >
      {hasResolved(exportTargetStatus) && (
        <ExportChannelsPicker<
          ExportTarget<'googleads'>,
          GoogleAdsConfig['customers'][number]
        >
          title="Google Ads Customer"
          noChannelsMessage="This Google Ads account does not have any customers."
          accounts={exportTargets}
          channels={customers}
          selectedAccount={selectedAccount}
          selectedChannel={selectedCustomer}
          onManageIntegration={handleManageIntegration}
          onSelectAccount={handleSelectAccount}
          onSelectChannel={setSelectedCustomer}
        />
      )}
    </ModalMenuUI>
  );
};
