import React, { useMemo, useCallback } from 'react';
//
import { Typography } from '@cube3/ui/src/typography/Typography';
import Cube3TextField from '@cube3/ui/src/forms/textfields/Cube3TextField';
import SuggestionsList from '@cube3/ui/src/inputChipPicker/SuggestionsList';
import SuggestionsItem from '@cube3/ui/src/inputChipPicker/SuggestionsItem';
import { useCombobox } from 'downshift';
import { TagsInputColorpicker } from './TagsInputColorpicker';
import { TagUI } from '@cube3/ui/src/tags/types';
import { Tag as TagResource } from '@cube3/common/model/schema/resources/tag';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { TagsInputCategoryPicker } from './TagsInputCategoryPicker';
import { TagCategory } from '@cube3/cubicle/src/core/atoms/Tag/TagCategory';
import { TagsList } from '@cube3/cubicle/src/core/atoms/Tag/TagsList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { TagsSearchQuery } from '../hooks/useTagsManager/useTagSuggestions';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';

interface Props {
  /** handle click tag event */
  onChange: (tag: EditableTag, defaultCategory?) => void;
  /** used for creating new tags: handle color/category input change */
  onInputChange: (query: TagsSearchQuery) => void;
  suggestions: {
    tag: EditableTag;
    match: boolean;
    exact: boolean;
    new: boolean;
    applied: boolean;
  }[];
  query: TagsSearchQuery;
  inputTags: EditableTag[];
}

const removeExp = /((?:[^a-zA-Z0-9-_ ]|^\s))/g;
export const normalizeTagsInput = (text: string) =>
  text.slice(0, 32).replace(removeExp, '');

const requiredPermissions = ['WORKSPACE_WRITE'] as Privileges[];

export const TagsInput = (props: Props) => {
  const { onChange, onInputChange, suggestions, query, inputTags } = props;
  const [canEditWorkspace] = useWorkspacePermissions(requiredPermissions);

  const [workspaceId] = useCurrentWorkspace();
  const { resources: categories } = useResourceList__ALPHA(
    useMemo(
      () => ({
        edgeType: 'tag-category',
        edgeLabel: 'tag-categories',
        params: {
          filter: {
            workspace: workspaceId
          }
        }
      }),
      [workspaceId]
    )
  );

  /** Available categories to select */
  const categoryOptions = useMemo(() => {
    /** all the editable categories */
    const all = categories
      ?.filter((ct) => ct.editable)
      .map((c) => ({
        display_name: c.display_name,
        id: c.id
      }));
    /** tags will be created */
    const toCreate = inputTags?.filter((t) => !t.id && t.text === query.text);
    /** the matched suggestions that has the same text as the search query */
    const textMatched = suggestions?.filter(
      (s) => s.match && s.tag.text === query.text
    );

    /** filter out matching categories to prevent duplicate tags from being created */
    const filtered = all.filter(
      (ac) =>
        !textMatched.find((s) => s.tag.category.id === ac.id) &&
        !toCreate.find((tc) => tc.category?.id === ac.id)
    );

    return filtered;
  }, [categories, suggestions, query, inputTags]);

  /** if there's no matched tag, provide an option to create a new tag  */
  const suggestionsWithNew = useMemo(() => {
    if (canEditWorkspace && query?.text && !!categoryOptions?.length) {
      return [
        {
          tag: query,
          match: true,
          exact: true,
          new: true,
          applied: false
        },
        ...suggestions
      ];
    }
    return suggestions;
  }, [suggestions, query, canEditWorkspace, categoryOptions]);

  const handleInputValueChange = useCallback(
    ({ inputValue = '' }) => {
      onInputChange({
        text: normalizeTagsInput(inputValue),
        color: query.color,
        category: query.category
      });
    },
    [query, onInputChange]
  );

  const {
    getInputProps,
    getMenuProps,
    getItemProps,
    getComboboxProps,
    isOpen,
    highlightedIndex,
    inputValue,
    openMenu
  } = useCombobox<{ tag: Partial<TagUI | TagResource>; applied: boolean }>({
    items: suggestionsWithNew,
    onInputValueChange: handleInputValueChange,
    initialInputValue: '',
    defaultHighlightedIndex: 0,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      // Prevent comboBox from remembering we selected an item

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (changes.selectedItem) {
            // store it so we can fire onSelect on the next update
            if (!changes.selectedItem.applied) {
              onChange(
                changes.selectedItem.tag as EditableTag,
                categoryOptions[0]
              );
              return {
                ...changes,
                inputValue: '',
                isOpen: true,
                selectedItem: null
              };
            } else {
              return state;
            }
          }
          return changes;

        default:
          return changes;
      }
    }
  });

  return (
    <>
      <div {...getComboboxProps()}>
        <Cube3TextField
          placeholder="Search or create tags"
          fullWidth={true}
          inputProps={{ value: inputValue }}
          {...getInputProps({
            refKey: 'inputRef',
            onFocus: () => {
              if (!isOpen) {
                openMenu();
              }
            },
            autoFocus: false
          })}
          autoFocus={false}
          value={normalizeTagsInput(inputValue)}
          startAdornment={
            inputTags?.length ? (
              <div style={{ marginBottom: 8 }}>
                <TagsList tags={inputTags} />
              </div>
            ) : undefined
          }
          multiline={true}
        />

        <SuggestionsList
          downshiftMenuProps={getMenuProps}
          title={!query?.text ? 'All tags' : undefined}
        >
          {isOpen ? (
            <>
              {suggestionsWithNew.map((s, idx) => {
                return (
                  <SuggestionsItem
                    key={idx + JSON.stringify(s.tag)}
                    isDisabled={s.applied}
                    disabledReasonText={'Already applied'}
                    isHighlighted={idx === highlightedIndex}
                    downShiftItemProps={getItemProps}
                    index={idx}
                    suggestion={s}
                  >
                    <div
                      style={{
                        display: 'grid',
                        justifyContent: 'start',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto 1fr auto ',
                        gridTemplateRows: '1fr',
                        width: '100%'
                      }}
                    >
                      {s.new ? (
                        <Typography typographyStyle="heading4">
                          Create
                        </Typography>
                      ) : (
                        <span />
                      )}
                      {(!s.new || s.tag.text) && (
                        <TagCategory
                          tag={s.tag}
                          category={
                            s.tag.category?.display_name ||
                            categoryOptions[0].display_name
                          }
                          readonly={s.tag.category?.['editable'] === false}

                          // onClick={!s.applied ? () => onChange(s.tag) : undefined}
                        />
                      )}
                      {s.new ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <TagsInputColorpicker
                            value={s.tag.color}
                            onChange={(c) =>
                              onInputChange({
                                category: s.tag.category as {
                                  id: string;
                                  display_name: string;
                                },
                                text: inputValue,
                                color: c
                              })
                            }
                          />
                          {/** select category  */}
                          <TagsInputCategoryPicker
                            value={
                              s.tag.category?.display_name ||
                              categoryOptions[0].display_name
                            }
                            onChange={(val) => {
                              onInputChange({
                                ...s.tag,
                                text: inputValue,
                                category: categoryOptions.find(
                                  (c) => c.display_name === val
                                )
                              });
                            }}
                            items={categoryOptions}
                          />
                        </div>
                      ) : (
                        <span />
                      )}
                    </div>
                  </SuggestionsItem>
                );
              })}
            </>
          ) : null}
        </SuggestionsList>
      </div>
    </>
  );
};
