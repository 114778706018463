import React, { useCallback } from 'react';

import SuggestionsInputField from '../inputChipPicker/SuggestionsInputField';
import { Suggestion } from '../inputChipPicker/SuggestionsItem';

interface WrappedSuggestionsInputFieldProps {
  handleInputKeyDown: Function;
  selectHighlightedItem: Function;
  highlightedIndex: number;
  isOpen: boolean;
  reset: Function;
  getInputProps: Function;
  filteredSuggestions: Suggestion[];
  inputFieldPlaceholder: string;
  input: {
    name: string;
    onBlur(ev: FocusEvent): void;
    onFocus(ev: FocusEvent): void;
  };
  setHighlightedIndex: Function;
  openMenu: Function;
  closeMenu: Function;
  inputField?: any;
  meta: { valid: boolean };
  type?: string;
  autoFocus?: boolean;
}

const WrappedSuggestionsInputField = (
  props: WrappedSuggestionsInputFieldProps
) => {
  const {
    handleInputKeyDown,
    selectHighlightedItem,
    highlightedIndex,
    isOpen,
    reset,
    getInputProps,
    filteredSuggestions,
    inputFieldPlaceholder,
    input,
    setHighlightedIndex,
    openMenu,
    inputField,
    meta,
    type,
    closeMenu,
    autoFocus = false
  } = props;

  const { valid } = meta;
  const { onBlur, onFocus } = input;

  const handleKeyDown = useCallback(
    event =>
      handleInputKeyDown({
        ref: inputField,
        event,
        selectHighlightedItem,
        highlightedIndex,
        openMenu,
        isOpen,
        reset,
        setHighlightedIndex,
        valid,
        filteredSuggestions,
        closeMenu
      }),
    [
      handleInputKeyDown,
      inputField,
      selectHighlightedItem,
      highlightedIndex,
      openMenu,
      isOpen,
      reset,
      setHighlightedIndex,
      valid,
      filteredSuggestions,
      closeMenu
    ]
  );

  const handleBlur = useCallback(
    event => {
      onBlur(event);
      handleInputKeyDown({
        ref: inputField,
        event,
        selectHighlightedItem,
        highlightedIndex,
        isOpen,
        reset,
        setHighlightedIndex,
        valid,
        filteredSuggestions
      });
      closeMenu();
    },
    [
      closeMenu,
      onBlur,
      inputField,
      selectHighlightedItem,
      highlightedIndex,
      isOpen,
      reset,
      setHighlightedIndex,
      valid,
      filteredSuggestions,
      handleInputKeyDown
    ]
  );

  const handleFocus = useCallback(
    event => {
      !isOpen && openMenu();
      onFocus(event);
    },
    [onFocus, openMenu, isOpen]
  );

  return (
    <SuggestionsInputField
      {...getInputProps({
        onKeyDown: handleKeyDown,
        placeholder: inputFieldPlaceholder,
        name: input.name,
        ...input,
        onBlur: handleBlur,
        onFocus: handleFocus
      })}
      onKeyUp={openMenu}
      error={!meta.valid}
      type={type}
      isOpen={isOpen}
      autoFocus={autoFocus}
    />
  );
};

export default WrappedSuggestionsInputField;
