import { createSlice } from '../../utils/createSlice';
import { actions } from './actions';
import { State, initialState } from './state';
import { ns } from './config';
import {
  dropAction,
  dropActionSet,
  getResourceById,
  hasAction,
  insertAction
} from './utils/nodeHelpers';
import { errorAction } from './utils/errorAction';

const { slice } = createSlice<State, typeof ns>(() => {
  return {
    handlers: {
      [actions.MARK_UNKNOWN]: (draft, action) => {
        const { payloadAction, valid } = destructureAction(action);
        if (!valid) {
          return;
        }

        draft.success = dropAction(draft.success, payloadAction);
        draft.failed = dropAction(draft.failed, payloadAction);
        draft.inFlight = dropAction(draft.inFlight, payloadAction);
        draft.invalidated = dropActionSet(draft.invalidated, payloadAction);

        return;
      },

      [actions.MARK_IN_FLIGHT]: (draft, action) => {
        const { payloadAction, valid } = destructureAction(action);
        if (!valid) {
          return;
        }

        if (!hasAction(draft.inFlight, payloadAction)) {
          draft.success = dropAction(draft.success, payloadAction);
          draft.failed = dropAction(draft.failed, payloadAction);
          draft.inFlight = insertAction(draft.inFlight, payloadAction);
          draft.invalidated = dropActionSet(draft.invalidated, payloadAction);

          return;
        }
      },
      [actions.MARK_SUCCESS]: (draft, action) => {
        const { payloadAction, valid } = destructureAction(action);
        if (!valid) {
          return;
        }

        if (!hasAction(draft.success, payloadAction)) {
          draft.success = insertAction(draft.success, payloadAction);
          draft.failed = dropAction(draft.failed, payloadAction);
          draft.inFlight = dropAction(draft.inFlight, payloadAction);
          draft.invalidated = dropActionSet(draft.invalidated, payloadAction);
          return;
        }
      },
      [actions.MARK_FAILED]: (draft, action) => {
        const { payloadAction, valid } = destructureAction(action);
        if (!valid) {
          return;
        }

        if (!hasAction(draft.failed, payloadAction)) {
          draft.success = dropAction(draft.success, payloadAction);
          draft.failed = insertAction(draft.failed, payloadAction);
          draft.inFlight = dropAction(draft.inFlight, payloadAction);
          draft.invalidated = dropActionSet(draft.invalidated, payloadAction);
          draft.errors = errorAction(draft.errors, action, payloadAction);
          return;
        }
      },
      [actions.INVALIDATE_RESOURCE]: (draft, action) => {
        const { payload } = destructureAction(action);

        draft.invalidated[payload.type] = draft.invalidated[payload.type] || {};
        draft.invalidated[payload.type][payload.id] = [
          ...(getResourceById(draft.invalidated, payload.type, payload.id) ||
            []),
          ...(getResourceById(draft.success, payload.type, payload.id) || []),
          ...(getResourceById(draft.failed, payload.type, payload.id) || []),
          ...(getResourceById(draft.inFlight, payload.type, payload.id) || [])
        ];

        draft.success[payload.type] = draft.success[payload.type] || {};
        draft.failed[payload.type] = draft.failed[payload.type] || {};
        draft.inFlight[payload.type] = draft.inFlight[payload.type] || {};
        draft.success[payload.type][payload.id] = [];
        draft.failed[payload.type][payload.id] = [];
        draft.inFlight[payload.type][payload.id] = [];
      },
      [actions.INVALIDATE_RELATIONSHIP]: (draft, action) => {
        const { payload } = destructureAction(action);

        draft.invalidated[payload.type] = draft.invalidated[payload.type] || {};
        draft.invalidated[payload.type][payload.id] = [
          ...(getResourceById(draft.invalidated, payload.type, payload.id) ||
            []),
          ...(
            getResourceById(draft.success, payload.type, payload.id) || []
          ).filter(filterByRelatationship(payload.relationship)),
          ...(
            getResourceById(draft.failed, payload.type, payload.id) || []
          ).filter(filterByRelatationship(payload.relationship)),
          ...(
            getResourceById(draft.inFlight, payload.type, payload.id) || []
          ).filter(filterByRelatationship(payload.relationship))
        ];

        draft.success[payload.type] = draft.success[payload.type] || {};
        draft.failed[payload.type] = draft.failed[payload.type] || {};
        draft.inFlight[payload.type] = draft.inFlight[payload.type] || {};
        draft.success[payload.type][payload.id] = (
          getResourceById(draft.success, payload.type, payload.id) || []
        ).filter(filterByRelatationship(payload.relationship, true));
        draft.failed[payload.type][payload.id] = (
          getResourceById(draft.failed, payload.type, payload.id) || []
        ).filter(filterByRelatationship(payload.relationship, true));
        draft.inFlight[payload.type][payload.id] = (
          getResourceById(draft.inFlight, payload.type, payload.id) || []
        ).filter(filterByRelatationship(payload.relationship, true));
      }
    },
    initialState,
    namespace: ns
  };
});

/**
 *
 * @param action request status related actions
 * @returns normalized action config
 */
const destructureAction = action => {
  const { payload = {}, meta = {} } = action;
  let { action: payloadAction } = payload || {};
  const { requestStatus = {} } = meta;

  payloadAction = payloadAction || {
    type: action.type,
    payload: requestStatus.resource,
    meta: { requestStatus }
  };

  const invalid =
    !payloadAction && !(requestStatus.hash && requestStatus.resource);

  return { payloadAction, requestStatus, action, payload, valid: !invalid };
};

const filterByRelatationship = (relationship, invert = false) => {
  const exp = /edgeLabel.*?([a-zA-Z-]*?)\?/;
  return cachekey => {
    const m = cachekey.match(exp);

    return invert ? !m || m[1] !== relationship : m && m[1] === relationship;
  };
};

export const reducer = slice[ns];
