import axios, { AxiosRequestConfig, AxiosAdapter } from 'axios';
import { flattenParams } from './params';

const defaults = {
  requestInterceptor: (req) => req,
  requestErrorInterceptor: (error) => Promise.reject(error),
  responseInterceptor: (res) => res,
  responseErrorInterceptor: (error) => Promise.reject(error)
};

interface EndpointConfig extends AxiosRequestConfig {
  apiRoot: string;
  canonicalPath: string;
  JSONApi?: boolean;
  useWorker?: boolean;
  parse?: boolean;
  adapter?: AxiosAdapter;
  requestInterceptor?<R>(req: R): R;
  requestErrorInterceptor?<R>(err: R): Promise<R>;
  responseInterceptor?<R>(res: R): R;
  responseErrorInterceptor?<R>(err: R): Promise<R>;
}

export class ApiEndpoint {
  config;
  axios;
  JSONApi;
  useWorker;
  parse;

  constructor(config: EndpointConfig) {
    if (!config.apiRoot || !config.canonicalPath) {
      throw new Error('Endpoint was misconfigured');
    }
    this.config = { ...defaults, ...config };
    this.axios = axios.create({
      baseURL: config.apiRoot,
      adapter: config.adapter,
      headers: {
        common: {
          Accept: 'application/json',
          'X-Use-Worker': config.useWorker ? 'true' : undefined
        }
      }
    });
    this.JSONApi = !!config.JSONApi;
    this.useWorker = !!config.useWorker;
    this.parse = !!config.parse;
    this.axios.interceptors.request.use(
      this.config.requestInterceptor.bind(this),
      this.config.requestErrorInterceptor.bind(this)
    );
    this.axios.interceptors.response.use(
      this.config.responseInterceptor.bind(this),
      this.config.responseErrorInterceptor.bind(this)
    );
  }

  list = (endpointOverride = undefined, params, abortSignal = undefined) => {
    const endpoint = endpointOverride || this.config.canonicalPath;
    const trailing =
      endpointOverride && endpointOverride.indexOf('?') > -1 ? '' : '/';
    return this.axios.get(`/${endpoint}${trailing}`, {
      params: params && flattenParams(params),
      signal: abortSignal
    });
  };

  listBy = (
    by,
    id,
    endpointOverride = undefined,
    params,
    abortSignal = undefined
  ) => {
    // TODO: allow cleaner overriding of the endpoint + queryparams via config object
    // NOTE: need to serialize this into a label for the redux edges middleware as well
    // if(endpointOverride && typeof endpointOverride === 'object'){
    // ...
    //   return this.axios.get(`/${by}/${id}/${endpoint}/`, {params} );
    // }
    const endpoint = endpointOverride || this.config.canonicalPath;

    const trailing =
      endpointOverride && endpointOverride.indexOf('?') > -1 ? '' : '/';
    let path = `/${by}/${id}/${endpoint}${trailing}`;
    switch (`${by}/<id>/${endpointOverride}`) {
      case 'libraries/<id>/nodes':
        path = endpointOverride;
        break;

      default:
        break;
    }

    return this.axios.get(`${path}`, {
      params: params && flattenParams(params),
      signal: abortSignal,
      transformResponse: [].concat(axios.defaults.transformResponse, (data) => {
        // legacy response
        if (Array.isArray(data)) {
          return data;
        }

        try {
          return {
            ...data,
            meta: { ...data.meta, __parent_resource: { by, id } }
          };
        } catch (e) {
          return data;
        }
      })
    });
  };

  get = (id, endpointOverride = undefined, abortSignal = undefined) => {
    const trailing =
      endpointOverride && endpointOverride.indexOf('?') > -1 ? '' : '/';
    const endpoint = endpointOverride || id;
    return this.axios.get(
      `/${this.config.canonicalPath}/${endpoint}${trailing}`,
      { signal: abortSignal }
    );
  };

  post = (data) => {
    return this.axios.post(`/${this.config.canonicalPath}`, data);
  };

  postBy = (data, id, type, endpointOverride = undefined) => {
    const endpoint = endpointOverride || this.config.canonicalPath;
    const trailing =
      endpointOverride && endpointOverride.indexOf('?') > -1 ? '' : '/';
    return this.axios.post(`/${type}/${id}/${endpoint}${trailing}`, data, {
      transformRequest: [].concat(
        axios.defaults.transformRequest,
        (data, headers) => {
          // NOTE: this was added because contract attachments asset type endpoint is JSONApi
          // while the rest of assets still isn't
          // TODO: remove asap
          if (type === 'contracts' && endpointOverride === 'attachments') {
            console.warn('OVERRIDING ASSET JSONAPI BEHAVIOR');
            headers['Content-Type'] = 'application/vnd.api+json';
            headers['Accept'] = 'application/vnd.api+json';
            return JSON.stringify({
              data: { type: 'asset', attributes: { ...JSON.parse(data) } }
            });
          }
          return data;
        }
      )
    });
  };

  delete = (id) => {
    return this.axios.delete(`/${this.config.canonicalPath}/${id}/`);
  };

  put = (id, data) => {
    return this.axios.put(`/${this.config.canonicalPath}/${id}/`, data);
  };

  patch = (id, data) => {
    return this.axios.patch(`/${this.config.canonicalPath}/${id}/`, data);
  };

  /** update relationships */
  replaceRelated = (id, data, endpointOverride) => {
    const payload = [].concat(data);
    return this.axios.patch(endpointOverride, { data: payload });
  };

  move = (resourceIDs, id, type) => {
    return this.addJSONApi(resourceIDs, id, type, 'assets', '');
  };

  copy = (resourceIDs, id, type) => {
    return this.addJSONApi(resourceIDs, id, type, 'assets-copies', '');
  };

  /** add relationships */
  addRelated = (
    edgeResource,
    id,
    type,
    endpointOverride = undefined,
    forceJSONApi
  ) => {
    if (this.JSONApi || forceJSONApi) {
      const [relationshipName, params] = endpointOverride.split('?');
      return this.addJSONApi(edgeResource, id, type, relationshipName, params);
    }
    const endpoint =
      endpointOverride || `relationships/${this.config.canonicalPath}`;
    const trailing =
      endpointOverride && endpointOverride.indexOf('?') > -1 ? '' : '/';
    return this.axios.patch(
      `/${type}/${id}/${endpoint}${trailing}`,
      edgeResource
    );
  };
  addJSONApi = (
    edgeResource,
    targetResourceId,
    targetResourceType,
    relationshipName,
    params = ''
  ) => {
    const payload = [].concat(edgeResource);
    return this.axios.post(
      `/${targetResourceType}/${targetResourceId}/relationships/${relationshipName}${params}`,
      { data: payload }
    );
  };

  /** remove relationships */
  removeRelated = (
    edgeResource,
    id,
    type,
    endpointOverride = undefined,
    forceJSONApi
  ) => {
    if (this.JSONApi || forceJSONApi) {
      const [relationshipName, params] = endpointOverride.split('?');
      return this.removeJSONApi(
        edgeResource,
        id,
        type,
        relationshipName,
        params
      );
    }
    const endpoint =
      endpointOverride || `relationships/${this.config.canonicalPath}`;
    const trailing =
      endpointOverride && endpointOverride.indexOf('?') > -1 ? '' : '/';
    return this.axios.delete(
      `/${type}/${id}/${endpoint}${trailing}`,
      edgeResource
    );
  };

  removeJSONApi = (
    edgeResource,
    targetResourceId,
    targetResourceType,
    relationshipName,
    params = ''
  ) => {
    const payload = [].concat(edgeResource);
    return this.axios.delete(
      `/${targetResourceType}/${targetResourceId}/relationships/${relationshipName}${
        params ? '?' + params : ''
      }`,
      { data: { data: payload } }
    );
  };

  custom = (...args) => {
    return this.axios(...args);
  };
}
