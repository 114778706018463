import React from 'react';
// UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ModalWindowUI } from '../Modal/ModalWindowUI';
import Button from '../Buttons/Button';
import { Typography } from '../typography/Typography';

import { ModalFooterUI } from '../Modal/ModalFooter';
import {
  CategoryScrollingLayout,
  Category,
  CategoryRenderer
} from '../Layout/CategoryScrollingLayout';
import { CopyPasteButtonsUI } from '../metadata/CopyPasteButtonsUI';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    contents: {
      display: 'flex',
      margin: '24px'
    },
    formContent: {
      flexGrow: 1,
      maxHeight: '70vh'
    },
    categoryContent: {
      width: 350,
      paddingTop: 20,
      paddingBottom: 20
    },
    categoryTitle: {
      fontWeight: 'bold',
      marginBottom: 16
    },
    activeCategoryTitle: {
      color: theme.customPalette.secondary.main
    },
    categories: {
      cursor: 'pointer'
    },
    headerClassName: {
      borderBottom: `1px solid ${theme.customPalette.primary.lineWhiteLowEmphasis}`,
      paddingBottom: 24
    },
    headerClassNameNoMetadata: {
      paddingBottom: 24
    },
    footerClassName: {
      minHeight: 90,
      marginBottom: 4
    },
    extraMargin: {
      marginBottom: '70vh'
    },
    footer: {
      marginTop: 'auto'
    }
  })
);

interface MetadataFormField {
  key?: string;
  display_name: string;
}

interface MetadataForm {
  category: string;
  category_name: string;
  display_name: string;
  fields: MetadataFormField[];
  created_at: string;
  deleted_at: string;
  error: boolean;
  id?: string;
}

interface EditMetadataModalProps {
  loading: boolean;
  renderMetaDataForm: (form: MetadataForm) => JSX.Element | React.ReactNode;
  onCloseEvent: () => void;
  retrievedForms?: MetadataForm[];
  submitAllForms(): void;
  allFormsValid: boolean;
  allFormsSucceeded: boolean;
  allFormsPristine: boolean;
  // copy/paste metadata
  copyDisabled?: boolean;
  pasteDisabled?: boolean;
  onCopy?: () => void;
  onPaste?: () => void;
}

function EditMetadataModal(props: EditMetadataModalProps) {
  const classes = useStyles({});

  const {
    onCloseEvent,
    renderMetaDataForm,
    loading,
    retrievedForms,
    allFormsValid,
    submitAllForms,
    allFormsSucceeded,
    allFormsPristine,
    onCopy,
    onPaste,
    copyDisabled,
    pasteDisabled
  } = props;

  if (allFormsSucceeded) {
    onCloseEvent();
    return null;
  }

  return (
    <ModalWindowUI // TODO CHECK RENDERED MODAL
      loading={loading}
      title={'Edit metadata'}
      showCloseButton={false}
      onCloseEvent={() => onCloseEvent()}
    >
      <div className={classes.root}>
        {!retrievedForms ? (
          <Typography typographyStyle={'body2'} color="contrast2">
            No project metadata yet
          </Typography>
        ) : (
          <CategoryScrollingLayout
            navMenu={
              <CopyPasteButtonsUI
                onCopy={onCopy}
                onPaste={onPaste}
                copyDisabled={copyDisabled}
                pasteDisabled={pasteDisabled}
              />
            }
            categories={retrievedForms as Category[]}
            renderCategory={renderMetaDataForm as CategoryRenderer}
          />
        )}
        {/* Render footer */}
        <div className={classes.footer}>
          <ModalFooterUI
            footerRightComponent={
              <>
                {allFormsPristine && (
                  <Button
                    text={'Done'}
                    onClick={() => onCloseEvent()}
                    colorVariant={'filled1'}
                  />
                )}
                {!allFormsPristine && (
                  <>
                    <Button
                      text={'Cancel'}
                      colorVariant={'ghost2'}
                      onClick={() => onCloseEvent()}
                    />
                    <Button
                      disabled={!allFormsValid || allFormsPristine}
                      colorVariant={'filled1'}
                      text={'Save'}
                      onClick={() => {
                        submitAllForms();
                      }}
                    />
                  </>
                )}
              </>
            }
          />
        </div>
      </div>
    </ModalWindowUI>
  );
}

export default EditMetadataModal;
