import React, { useCallback } from 'react';
import Button from '../Buttons/Button';
import { Typography } from '../typography/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MasterDetail } from '../Layout/MasterDetail';

export const useExportChannelsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    modalContents: { margin: 0 },
    list: {
      margin: 0,
      padding: 0 // same as header
    },
    noChannelText: {
      width: '50%',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    listItem: {
      listStyle: 'none',
      cursor: 'pointer',
      padding: '12px 24px',
      '&:first-child': {
        cursor: 'auto'
      }
    },
    active: {
      background: theme.customPalette.surfaceAccent.onBase.selected1
    },
    noAccount: {
      width: '30%',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    noAccountButton: {
      marginTop: 12
    }
  })
);

export interface ConnectedAccount extends Item {
  // channels: ConnectedAccountChannel[];
}
export interface ConnectedAccountChannel extends Item {}

interface Props<
  A extends ConnectedAccount = ConnectedAccount,
  C extends ConnectedAccountChannel = ConnectedAccountChannel
> {
  title: string;
  noChannelsMessage: string;
  accounts: A[];
  channels: C[];
  selectedAccount: A;
  selectedChannel: C;
  onSelectAccount: (account: A) => void;
  onSelectChannel: (channel: C) => void;
  onManageIntegration(): void;
}

export const ExportChannelsPicker = <
  A extends ConnectedAccount = ConnectedAccount,
  C extends ConnectedAccountChannel = ConnectedAccountChannel
>(
  props: Props<A, C>
) => {
  const {
    title,
    noChannelsMessage,
    accounts,
    channels,
    selectedAccount,
    selectedChannel,
    onSelectAccount,
    onSelectChannel,
    onManageIntegration
  } = props;

  const classes = useExportChannelsStyles();

  return accounts.length === 0 ? (
    <div className={classes.noAccount}>
      <Typography color="contrast2">
        To use this, you need to set up this integration for your Cube account.
      </Typography>
      <Button
        text={'Manage integration'}
        onClick={onManageIntegration}
        colorVariant={'ghost1'}
        className={classes.noAccountButton}
      />
    </div>
  ) : (
    <MasterDetail
      listElement={
        <ListElement
          items={accounts}
          title="Account"
          selectedItem={selectedAccount}
          onClick={onSelectAccount}
        />
      }
      detailElement={
        !channels || channels.length === 0 ? (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Typography color="contrast2" className={classes.noChannelText}>
              {noChannelsMessage}
            </Typography>
          </div>
        ) : (
          <ListElement
            items={channels}
            title={title}
            selectedItem={selectedChannel}
            onClick={onSelectChannel}
          />
        )
      }
    />
  );
};

interface Item {
  id: string;
  display_name: string;
  [key: string]: any;
}

interface ListElementProps {
  items: Item[];
  title: string;
  selectedItem?: Item;
  onClick(e): void;
}

const ListElement = (props: ListElementProps) => {
  const { items, title, onClick, selectedItem } = props;
  const classes = useExportChannelsStyles();
  const isSelected = useCallback(
    (item) => selectedItem && selectedItem.id === item.id,
    [selectedItem]
  );
  return (
    <ul className={classes.list}>
      <Typography
        color="contrast3"
        typographyStyle="heading2"
        className={classes.listItem}
      >
        {title}
      </Typography>
      {items.map((item) => (
        <li
          key={item.id}
          className={[
            classes.listItem,
            isSelected(item) && classes.active
          ].join(' ')}
          onClick={() => onClick(item)}
        >
          <Typography
            color={isSelected(item) ? 'brand1' : 'contrast1'}
            typographyStyle="heading2"
          >
            {item.display_name || item.id}
          </Typography>
        </li>
      ))}
    </ul>
  );
};
