import React, { useCallback, useMemo, useState } from 'react';
import { Field, getFormSyncErrors, WrappedFieldArrayProps } from 'redux-form';
import { validEmailaddress, makeValidator } from './helpers/validators';
// UI
import EmailBox from '@cube3/ui/src/forms/EmailBox';
import Chip from '@cube3/ui/src/chips/Chip';
import SuggestionsInputField from '@cube3/ui/src/inputChipPicker/SuggestionsInputField';
import Errors, { getErrors } from './helpers/errors';
import Button from '@cube3/ui/src/Buttons/Button';
import { iconClose } from '@cube3/ui/src/icons/GeneralSVG';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';

const EmailBoxInput = ({ input, ...props }) => (
  <SuggestionsInputField {...input} {...props} open={true} />
);

const emptyArray: unknown[] = [];

interface EmailBoxSmartProps extends WrappedFieldArrayProps {
  keysListenersAddNewEmail?: string[];
  eventTypeArrayToAddNewEmail?: string[];
  valid?: boolean;
  inputValue: string | number;
  clearInputField: Function;
  newSuggestionPrimaryKey: string;
  form?: string;
  showErrors?: boolean;
  errorMessage?: string;
  currentEmailAddressesInWorkspace: string[];
}

function EmailBoxSmart(props: EmailBoxSmartProps) {
  const {
    fields,
    keysListenersAddNewEmail = ['Tab', ',', ';', 'Enter', ' '],
    eventTypeArrayToAddNewEmail = ['blur'],
    valid,
    inputValue,
    clearInputField,
    newSuggestionPrimaryKey = 'primaryText',
    form,
    showErrors = 'touched',
    errorMessage,
    currentEmailAddressesInWorkspace
  } = props;

  const inputErrors = useTypedSelector((state) =>
    getFormSyncErrors(form)(state)
  )?.['emailInput'];

  const errors =
    getErrors(errorMessage, props.meta.error) || inputErrors?._error;
  const shouldList =
    showErrors === true || (showErrors === 'touched' && props.meta.dirty);

  const selectedEmails = fields.getAll() ? fields.getAll() : emptyArray;
  const [excluded, setExcluded] = useState(emptyArray);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Backspace' && !event.target.value) {
        fields.remove(fields.length - 1);
      } else if (
        keysListenersAddNewEmail.includes(event.key) ||
        eventTypeArrayToAddNewEmail.includes(event.type)
      ) {
        event.preventDefault();
        if (valid && inputValue) {
          fields.push({
            [newSuggestionPrimaryKey]: inputValue,
            value: inputValue
          });
          clearInputField && clearInputField(form, true, true, 'emailInput');
        }
      }
    },
    [
      fields,
      valid,
      inputValue,
      clearInputField,
      keysListenersAddNewEmail,
      eventTypeArrayToAddNewEmail,
      newSuggestionPrimaryKey,
      form
    ]
  );

  const emailValidators = useMemo(
    () =>
      makeValidator([
        (value, all, props, errors) =>
          !value ? undefined : validEmailaddress(value, all, props, errors),
        (value, all, props, errors) =>
          !value
            ? undefined
            : selectedEmails.find((e) => e.value === value) &&
              'Address is already in list'
      ]),
    [selectedEmails]
  );

  const handleParse = useCallback(
    (value) => {
      const segments = value.split(/(?:,|;|\t|\n|\s)+/);
      if (segments.length < 2) {
        return value;
      }

      const valid = Array.from(
        new Set(segments.filter((s) => !emailValidators(s, null, null, null)))
      );
      const invalid = segments.filter((s) =>
        emailValidators(s, null, null, null)
      );

      valid.forEach((email) => {
        if (!email) {
          return;
        }
        fields.push({
          [newSuggestionPrimaryKey]: email,
          value: email
        });
      });

      clearInputField && clearInputField(form, true, true, 'emailInput');
      setExcluded(invalid);
    },
    [
      setExcluded,
      fields,
      clearInputField,
      newSuggestionPrimaryKey,
      form,
      emailValidators
    ]
  );

  return (
    <>
      <EmailBox
        helperText={errors && shouldList && <Errors errors={errors} />}
        errors={errors && shouldList}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', flex: 1 }}>
          {selectedEmails.map((field, index) => (
            <Field
              key={index + field.value}
              name={field.value}
              type="text"
              component={Chip}
              removeItem={() => fields.remove(index)}
              index={index}
              props={{
                input: { value: field.value },
                error:
                  currentEmailAddressesInWorkspace.indexOf(field.value) !==
                    -1 ||
                  selectedEmails.findIndex((f) => f.value === field.value) !==
                    index
              }}
            />
          ))}

          <Field
            name="emailInput"
            type="email"
            component={EmailBoxInput}
            validate={emailValidators}
            parse={handleParse}
            props={{
              onKeyDown: handleKeyDown,
              onBlur: handleKeyDown
            }}
            style={{ flexShrink: 1, flexGrow: 1 }}
          />
        </div>
      </EmailBox>
      {excluded.length > 0 && (
        <div
          style={{
            marginTop: 4,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Errors errors={`Excluded: ${excluded.join(', ')}`} />
          <Button
            path={iconClose}
            colorVariant="danger"
            onClick={() => setExcluded([])}
            size="small"
            marginCollapse="both"
          />
        </div>
      )}
    </>
  );
}

export default EmailBoxSmart;
