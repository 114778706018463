import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Fade
  // Divider
} from '@material-ui/core';

// helpers
import { useTimer } from '../helpers/useTimer';

//UI
import UserAvatar from '../User/UserAvatar/UserAvatar';
import { Typography } from '../typography/Typography';
import Cube3Button from '../Buttons/Cube3Button';
import MenuListComposition from '../menus/MenuListComposition/MenuListComposition';
import { useSurfaceClasses } from '../Surface/Surface';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userRow: {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'auto 1fr 1fr auto',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.customPalette.line.onBase1.contrast2}`,
      height: '54px'
    },
    userDetails: {
      margin: '0px 22px 0px 16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    role: {
      marginRight: 16
    },
    manage: {
      width: '180px',
      height: '54px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    menuContents: {
      width: '225px',
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    ellipsisButton: {
      padding: 0,
      paddingLeft: '8px',
      margin: '0 4px'
    }
  })
);

interface WorkspaceMemberRowProps {
  full_name?: string;
  display_name?: string;
  display_image?: string;
  role: string;
  workspaceName: string;
  loading: boolean;
  isCurrentAccount: boolean;
  roles: any;
  menuItems: JSX.Element;
}

function WorkspaceMemberRow(props: WorkspaceMemberRowProps) {
  const {
    full_name,
    display_name,
    display_image,
    role,
    workspaceName,
    loading,
    isCurrentAccount,
    menuItems
  } = props;

  const importedClasses = useSurfaceClasses({});

  const classes = useStyles();

  if (loading) {
    return <WorkspaceMemberRowShimmer />;
  }

  return (
    <Fade in={true}>
      <div className={classes.userRow} data-cy="userRow">
        <>
          <UserAvatar
            userInfo={{
              full_name: full_name,
              profile_picture: display_image
            }}
          />

          <div className={classes.userDetails}>
            <>
              <Typography typographyStyle={'body2'}>
                {display_name}
                {isCurrentAccount && ' (you)'}
              </Typography>
              <Typography color="contrast3" typographyStyle={'body2'}>
                {workspaceName}
              </Typography>
            </>
          </div>

          <Typography color="contrast3" typographyStyle={'body2'}>
            {role}
          </Typography>

          <div className={classes.role}>
            <MenuListComposition
              visible={true}
              toggleButton={(handleToggle, anchorEl, open) => (
                <Cube3Button
                  name="workspace member actions"
                  className={classes.ellipsisButton}
                  onClick={handleToggle}
                  colorVariant="ghost2"
                  extraButtonProperties={{
                    ref: anchorEl
                  }}
                />
              )}
              menuItems={menuItems}
              PopoverProps={{
                PaperProps: {
                  classes: {
                    root: [importedClasses, classes.menuContents].join(' ')
                  }
                }
              }}
            />
          </div>
        </>
      </div>
    </Fade>
  );
}

export const WorkspaceMemberRowShimmer = () => {
  const [show, setShow] = useState<boolean>(false);

  const classes = useStyles();

  useTimer(500, () => setShow(true));

  // only show the shimmer after a short while to reduce eyestrain.
  if (show) {
    return (
      <div className={classes.userRow}>
        <div style={{ width: 32, height: 32 }}>
          <Shimmer variant="rounded" />
        </div>

        <div className={classes.userDetails}>
          <div style={{ height: '2em' }}>
            <Shimmer />
          </div>
        </div>
        <div className={classes.role}>
          <div style={{ height: '1em' }}>
            <Shimmer />
          </div>
        </div>
        <div className={classes.manage}>
          <div style={{ height: 36 }}>
            <Shimmer />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default WorkspaceMemberRow;
