import {
  selectors as nodeSelectors,
  actionCreators
} from '../../ducks/resource-nodes';
import { mergeStatuses, getMergedStatuses } from '../../utils/mergeStatuses';
import { CacheStrategy } from '../configHelpers';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo, useRef } from 'react';
import {
  statuses,
  RequestStatuses,
  selectors,
  actionCreators as requestStatusActionCreators
} from '../../ducks/request-status';
import {
  ResourceType,
  ResourceIdentifier,
  ResourceTypeMap
} from '@cube3/common/model/resource-types';
import { environment } from '../../../utils/environment';
import { useCallback } from 'react';

const configValid = ({ resourceType, resourceId }) => {
  if (!resourceType || !resourceId) {
    return false;
  } else {
    return true;
  }
};

const shouldRetrieve = ({ strategy, mounting, resource, requestStatus }) => {
  const busy =
    requestStatus &&
    ![statuses.INVALIDATED, statuses.SUCCESS, statuses.FAILED].includes(
      requestStatus
    );
  const invalid = requestStatus === statuses.INVALIDATED;

  const requiresFetch =
    invalid ||
    (requestStatus !== statuses.SUCCESS &&
      requestStatus !== statuses.FAILED &&
      !resource) ||
    (resource && resource.__stub__);

  switch (strategy) {
    case 'cache-only':
      return false;
    case 'prefer-cache':
      if (resource && !invalid) {
        return false;
      }
      break;
    case 'fetch-on-mount':
      if (mounting && !busy) {
        return true;
      }
      break;
    case 'fetch-on-update':
      if (!busy) {
        return true;
      }
      break;
    case 'fetch-when-needed':
    default:
      return !busy && requiresFetch;
  }
  return !busy && requiresFetch;
};

/**
 *  Config for useResource hook that provides access to api resources, and fetches them if necessary
 */
interface UseResourceConfig<T extends ResourceType> {
  /**
   *  cache strategy
   */
  strategy?: CacheStrategy;
  /**
   *  Resource type used for legacy style config.
   */
  resourceType?: T | '';
  /**
   *  Resource id used for legacy style config.
   */
  resourceId?: string;
  /**
   *  array of resource identifiers
   */
  resourceIdentifiers?: ResourceIdentifier<T>[];
  /**
   * don't return resources until all have loaded
   * default = true
   */
  strict?: boolean;
}

/**
 *  useResource hook response
 */
export interface UseResourceResponse<
  T extends ResourceTypeMap[keyof ResourceTypeMap]
> {
  /**
   * first resource in result set (for convenience / legacy use)
   */

  resource?: T;
  /**
   *  resources in result set
   */
  resources?: T[];
  /**
   *  first resource in result set
   */
  first?: T;
  /**
   *  individual statuses for request set
   */
  statuses?: RequestStatuses[];
  /**
   *  overal status for all requests
   */
  status?: RequestStatuses;
  /**
   *  errors returned by any of the requested resources
   */
  errors?: any;
  /**
   *  one or more requests still in flight
   */
  loading: boolean;
  /**
   * provided config is valid
   * NOTE: requests wont be performed until all resource identifiers in config are valid
   * TODO: add option to already perform any valid requests
   */
  configValid: boolean;
  retrieve(): void;
  cancelRetrieve?(): void;
}

export const debugColors = [
  '#ddd',
  '#dda',
  '#aa6',
  '#ff0',
  '#fd0',
  '#fa0',
  '#f80',
  '#f60',
  '#f40',
  '#f20',
  '#f00',
  '#a02',
  '#804',
  '#406',
  '#206',
  '#208'
];

const emptyArray = [];

/**
 * Hook that provides access to api resources, and fetches them if necessary
 */
const useResource = <T extends ResourceType>({
  strategy = 'fetch-when-needed',
  resourceType = undefined,
  resourceId = undefined,
  resourceIdentifiers = emptyArray,
  strict = true
}: UseResourceConfig<T>): UseResourceResponse<ResourceTypeMap[T]> => {
  /* last results */
  const lastMerged = useRef(null);
  /* last statuses set */
  const lastStatuses = useRef(null);
  /* last selected resources */
  const lastResources = useRef(null);
  /* last errors encounterd */
  const lastErrors = useRef(null);
  /* callback to cancel active retrieve action */
  const cancelRetrieve = useRef(null);
  /* redux dispatch */
  const dispatch = useDispatch();
  /* to differentiate between first mount and later renders */
  const [mounted, setMounted] = useState(false);

  const warn = useRef(null);

  /* Merge legacy config with array of identifiers */
  const requests = useMemo(() => {
    if (warn.current && environment === 'development') {
      console.warn(
        '%c Requests array has changed. If you see this repeatedly please memoize your inputs',
        `color: ${
          debugColors[warn.current?.count || 0]
        }; text-shadow: 1px 1px 0px black;`,
        { resourceIdentifiers, resourceType, resourceId },
        warn.current
      );
    }
    warn.current = {
      resourceIdentifiers,
      resourceType,
      resourceId,
      count: (warn.current?.count || 0) + 1
    };

    const reqs = [...resourceIdentifiers];
    if (resourceType && resourceId) {
      reqs.unshift({ type: resourceType, id: resourceId });
    }

    return reqs;
  }, [resourceIdentifiers, resourceType, resourceId]);

  const valid =
    requests.filter(({ id, type }) =>
      configValid({ resourceId: id, resourceType: type })
    ).length === requests.length;

  const resources = useSelector((state) => {
    if (!valid) {
      return undefined;
    }
    let dirty =
      !lastResources.current ||
      requests.length !== lastResources.current.length;
    const res = requests.map(({ type, id }, idx) => {
      const re = nodeSelectors.getResourceById(state, type, id);
      if (dirty || lastResources.current[idx] !== re) {
        dirty = true;
      }
      return re;
    });
    if (dirty) {
      lastResources.current = res;
    }
    return lastResources.current;
  });

  const requestStatus = useSelector((state) => {
    if (!valid) {
      return undefined;
    }
    let dirty =
      !lastStatuses.current || requests.length !== lastStatuses.current.length;
    const statusMapped =
      strategy === 'fetch-on-mount' && !mounted
        ? requests.map((r) => undefined)
        : requests.map(({ type, id }, idx) => {
            const merged = getMergedStatuses(state, type, id);
            if (dirty || lastStatuses.current[idx] !== merged) {
              dirty = true;
            }
            return merged;
          });
    if (dirty) {
      lastStatuses.current = statusMapped;
    }
    return lastStatuses.current;
  });

  const errors = useSelector(
    ({ requestStatus: state }: { requestStatus: {} }) => {
      if (!valid || !requestStatus.includes(statuses.FAILED)) {
        return undefined;
      }
      let dirty =
        !lastErrors.current || requests.length !== lastErrors.current.length;
      const errorsMapped = requests.map(({ type, id }, idx) => {
        const err = selectors.getErrors(
          state,
          actionCreators.retrieveResource(type, id)
        );
        if (dirty || lastErrors.current[idx] !== err) {
          dirty = true;
        }
        return err;
      });
      if (dirty) {
        lastErrors.current = errorsMapped;
      }
      return lastErrors.current;
    }
  );

  const [forceRetrieve, setForceRetrieve] = useState(false);
  const retrieve = useCallback(
    () => setForceRetrieve(true),
    [setForceRetrieve]
  );

  useEffect(() => {
    const should = requests.filter(
      (config, idx) =>
        forceRetrieve ||
        shouldRetrieve({
          strategy,
          mounting: !mounted,
          resource: resources && resources[idx],
          requestStatus: requestStatus && requestStatus[idx]
        })
    );
    if (valid) {
      const retrieveActions = [];
      should.forEach(({ type, id }) => {
        const retrieveAction = actionCreators.retrieveResource(type, id);
        dispatch(retrieveAction);
        retrieveActions.push(retrieveAction);
      });

      if (retrieveActions.length) {
        cancelRetrieve.current = () => {
          retrieveActions.forEach((a) => {
            dispatch(requestStatusActionCreators.cancelRetrieve(a));
          });
        };
      }

      if (forceRetrieve) {
        setForceRetrieve(false);
      }

      if (!mounted && should.length) {
        setMounted(true);
      }
    }
  }, [
    requestStatus,
    valid,
    requests,
    resources,
    dispatch,
    mounted,
    strategy,
    forceRetrieve
  ]);

  const merged = useMemo(() => {
    return {
      resource: resources && resources[0],
      resources:
        resources && (!resources.filter((r) => !r).length || strict === false)
          ? resources
          : undefined,
      first: resources && resources[0],
      statuses: requestStatus,
      status: mergeStatuses(requestStatus, false),
      errors: errors,
      loading:
        requestStatus &&
        requestStatus.filter(
          (s) => s && s !== statuses.SUCCESS && s !== statuses.FAILED
        ).length > 0,
      configValid: valid,
      retrieve,
      cancelRetrieve: cancelRetrieve.current
    };
  }, [resources, requestStatus, valid, strict, errors, retrieve]);

  lastMerged.current = merged;

  return merged;
};

export { useResource as useResource__ALPHA };
