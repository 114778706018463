import React, { useState, useCallback } from 'react';
import 'intersection-observer'; // optional polyfill
import Observer from '@researchgate/react-intersection-observer';
import { ScrollBarsLike, ScrollParentContext } from '../Scrollbar/Scrollbar';

function getScrollParent(node): ScrollBarsLike {
  if (node == null) {
    return null;
  }

  if (node && node.dataset && node.dataset.scrollParent) {
    return { container: node } as ScrollBarsLike;
  } else {
    return getScrollParent(node.parentNode);
  }
}

export const withLazyLoad = (WrappedComponent) => {
  return (props) => {
    let scrollParents = React.useContext(ScrollParentContext);
    const { once, callback: callbackProp } = props;

    const [initiallyVisible, setInitiallyVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [first, setFirst] = useState(true);
    const [scrollParent, setScrollParent] = useState(
      scrollParents[scrollParents.length - 1]
    );
    const callback = useCallback(
      (ev, unObserve) => {
        if (!visible && ev.isIntersecting) {
          callbackProp && callbackProp(true);
          setVisible(true);
          if (once !== false) {
            unObserve();
          }
        }
        if (once === false) {
          if (visible && !ev.isIntersecting) {
            callbackProp && callbackProp(false);
            setVisible(false);
          }
        }
        if (first && ev.isIntersecting) {
          setInitiallyVisible(true);
          setFirst(false);
        }
      },
      [callbackProp, once, first, visible]
    );

    const scrollParentCb = useCallback((node) => {
      if (node !== null) {
        const sp = getScrollParent(node);
        setScrollParent(sp);
      }
    }, []);

    if (!scrollParent) {
      return <script ref={scrollParentCb} />;
    }

    const options = {
      onChange: callback,
      threshold: 0,
      root: scrollParent.container,
      rootMargin: '100% 0% 200% 0%'
    };

    return (
      <Observer {...options}>
        <WrappedComponent
          visible={visible}
          initiallyVisible={initiallyVisible}
          {...props}
        />
      </Observer>
    );
  };
};

class Bumper extends React.PureComponent {
  render() {
    return (
      <div style={{ position: 'relative', height: '10vh', width: '100%' }}>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            bottom: 0
          }}
        ></div>
      </div>
    );
  }
}

export const LoadMoreBumper = withLazyLoad(Bumper);
