// Extended (stackable) redux-form field errors
// By default redux-form only show one error at a time.
// To show multiple errors we use a custom decorator for the validators

import { validationErrorCodes as ERRORS } from '@cube3/state/src/wrapped-cube-client';

export type ComplexErrors =
  | string
  | string[]
  | { [key: string]: string | string[] };

type Validator = (
  value: any,
  allValues: any,
  props: any,
  name: string,
  minPasswordLength?: number
) => ComplexErrors | string;
type ValidatorsConfig = Validator | Validator[];

export const makeValidator = (
  validators: ValidatorsConfig,
  firstErrorOnly = false
): Validator => {
  const validatorsArray = validators ? [].concat(validators) : [];

  return (...validatorArgs): ComplexErrors | undefined => {
    const errorsArray = validatorsArray
      .reduce((errors, validator, all) => {
        return [...errors, ...[].concat(validator(...validatorArgs))];
      }, [])
      .filter((e) => e !== undefined);

    return errorsArray.length
      ? firstErrorOnly
        ? { _error: errorsArray[0] }
        : { _error: errorsArray }
      : undefined;
  };
};

export const fullName = (value) => {
  let valueArray = [];
  value && (valueArray = value.split(' '));

  return valueArray.length > 0 && valueArray.length < 2
    ? 'Full name should consist of two parts(first and surname)'
    : undefined;
};

const emptyStringExp = /^[\s\n\r]*$/m;

// List of reusable validators
export const isRequired = (value, all, props, name) => {
  if (value === null) {
    return ERRORS.REQUIRED_ERROR;
  }

  switch (typeof value) {
    case 'object':
    case 'number':
    case 'boolean':
      return value ? undefined : ERRORS.REQUIRED_ERROR;
    default:
      return !value || !value.length || emptyStringExp.test(value)
        ? ERRORS.REQUIRED_ERROR
        : undefined;
  }

  // @TODO also check if the value is not only space characters
};

/** Checks if the the given string exceeds the max amount of chacaters. */
export const maxCharacters = (
  value: string,
  /** The max amount of characters, defaults to 128   */
  amount?: number
) => {
  const maxChar = amount ? amount : 128;

  if (value && value.length > maxChar) {
    return `Must be ${maxChar} character${maxChar === 1 ? '' : 's'} or less`;
  } else {
    return undefined;
  }
};

/** Checks if there are not a space character at the start, which otherwise, ' ' as a name will pass the `isRequired` validator*/
export const noSpaceAtStart = (value: string) => {
  if (!value) {
    return undefined;
  }
  return value.charAt(0) === ' ' ? 'No space characters at start.' : undefined;
};

export const shouldMatch = (value1, value2): string => {
  if (value1 === value2) {
    return undefined;
  } else {
    return ERRORS.SHOULD_MATCH_VALUE;
  }
};

export const noWhitespaceRegexp = /[^\s]/;
export const emailRegexp =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const validEmailaddress = (value, all, props, name) => {
  return !value || !emailRegexp.test(value) || !noWhitespaceRegexp.test(value)
    ? ERRORS.EMAIL_SYNTAX_ERROR
    : undefined;
};

export const hasLowercaseRegexp = /[a-z]/;
export const hasUppercaseRegexp = /[A-Z]/;
export const hasNumberRegexp = /[0-9]/;
export const hasSpecialCharRegexp =
  // eslint-disable-next-line no-useless-escape
  /[-_\!@#\$\%\^&\*\(\)\+=~`\|\{\}\[\],\.\/\/<>\\;\:'"\?]/;
export const MIN_PASWORD_LENGTH = 8;
export const MIN_SHARE_LINK_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 64;
export const consecutive = /(.)\1\1/;
export const sequential_numbers = '01234567890987654321';

// a short list of common passwords used
// prettier-ignore
export const dictionary = [
  'Cube', 'cube', 'admin', 'password', 'PASSWORD', 'wachtwoord', 'P4SSW0RD', 'p4ssword', 'qwertz', 'dragon', 'iloveyou', 'monkey', 'aap', 'monkey', '1q2w3e4r', 'welkom', 
  'linkedin', 'welkom01', 'geheim', 'amsterdam', 'Welkom01', 'vakantie', 'willem'];
export const sequential_chars =
  /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)/;
// need sequential chars

export const strongPassword = (
  value,
  all,
  props,
  name,
  minPasswordLength = MIN_PASWORD_LENGTH
) => {
  const passwordErrors = [];

  if (!value || value.length < minPasswordLength) {
    passwordErrors.push(ERRORS.WEAK_PASSWORD_ERROR_LENGTH);
  }

  if (!value || value.length > MAX_PASSWORD_LENGTH) {
    passwordErrors.push(ERRORS.MAXIMUM_PASSWORD_ERROR_LENGTH);
  }

  if (!value || consecutive.test(value)) {
    passwordErrors.push(ERRORS.WEAK_PASSWORD_ERROR_CONSECUTIVE);
  }

  if (
    !value ||
    sequential_numbers.indexOf(value) !== -1 ||
    sequential_chars.test(value)
  ) {
    passwordErrors.push(ERRORS.WEAK_PASSWORD_ERROR_SEQUENTIAL);
  }

  if (!value || !!dictionary.find((word) => value.includes(word))) {
    passwordErrors.push(ERRORS.WEAK_PASSWORD_ERROR_DICTIONARY);
  }

  return passwordErrors;
};

export const passwordValidator = (
  password: string,
  minLength = MIN_SHARE_LINK_PASSWORD_LENGTH
) => {
  return strongPassword(password, {}, {}, '', minLength).length === 0;
};

export const validRecipients = (valueArray, all, props, name) => {
  if (!valueArray || valueArray.length === 0) {
    return;
  }

  if (
    valueArray.filter((e, idx) =>
      validEmailaddress(e && e.email_address, all, props, `${name}.${idx}`)
    ).length !== 0
  ) {
    return ERRORS.INVALID_RECIPIENTS_ERROR;
  }

  const emails = valueArray.map((v) => v.email_address);
  const unique = new Set(emails);
  const dif = unique.size !== emails.length;
  return dif ? ERRORS.DUPLICATE_RECIPIENTS_ERROR : undefined;
};
