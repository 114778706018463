import React from 'react';
//
import { Typography } from '../../typography/Typography';
//

interface Props {
  playedTime: string;
  durationDateFormat: string;
}

export const PlaybackTimeDisplay: React.FC<Props> = React.memo(props => {
  const { playedTime, durationDateFormat } = props;

  return (
    <>
      <Typography typographyStyle={'body2'} component="span">
        {playedTime}
      </Typography>
      <Typography typographyStyle={'body2'} component="span">
        /
      </Typography>
      <Typography typographyStyle={'body2'} component="span">
        {durationDateFormat}
      </Typography>
    </>
  );
});
