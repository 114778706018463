import { schema } from '@cube3/common/model/schema';

const metrics = {
  index: {},
  entries: [],
  unparsed: []
};

const configToString = (config) => {
  return `{\n\t${Object.keys(config)
    .map((k) => [k, config[k]])
    .map((c) => `${c[0]}: ${c[1].toString()}`)
    .join(',\n\t')}\n}`;
};

const collectList = (config) => {
  let path;
  if (
    typeof config.resourceType === 'string' &&
    typeof config.edgeLabel === 'string'
  ) {
    path = `/${schema.getPlural(config.resourceType)}/<id>/${
      config.edgeLabel
    }/`;
  } else if (typeof config.edgeLabel === 'string' && !config.resourceType) {
    path = `/${config.edgeLabel}/`;
  } else {
    metrics.unparsed.push({ config, configString: configToString(config) });
    return;
  }

  metrics.entries.push({
    type: 'list',
    path,
    config,
    configString: configToString(config)
    //operations: 'READ,CREATE,UPDATE' //
  });

  metrics.index[path] = (metrics.index[path] || 0) + 1;
};

const collectResource = (config) => {
  let path;

  if (typeof config.resourceType === 'string') {
    path = `/${schema.getPlural(config.resourceType)}/<id>/`;
  } else {
    metrics.unparsed.push({ config, configString: configToString(config) });
    return;
  }

  metrics.entries.push({
    type: 'resource',
    path,
    config,
    configString: configToString(config)

    //operations: 'READ,CREATE,UPDATE' //
  });

  metrics.index[path] = (metrics.index[path] || 0) + 1;
};

export const collectConfigMetrics = (config, configType) => {
  switch (configType) {
    case 'list':
      return collectList(config);
    case 'resource':
      return collectResource(config);
    default:
      console.warn('config type missing (no-op)');
  }
};

export const useEndpointMetrics = () => metrics; // TODO: make subscribable?
