import { FSA } from '../../../flux-standard-action';
import {
  PublicResource,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { ContractKeyGroup } from '@cube3/common/model/schema/resources/contract-key-group';
import {
  getFormInitialValues,
  getFormValues,
  isDirty,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit
} from 'redux-form';
import { actionCreators as requestStatusActionCreators } from '../../../ducks/request-status';
import {
  handleContractChanges,
  handleContractFinal
} from './handleContractChanges';
import { handleAttachedResourceChanges } from './handleAttachedResourceChanges';
import { handleContractFieldChanges } from './handleContractFieldChanges';

const CONTRACT_SUBMIT = 'CUBE3/CONTRACT_SUBMIT_MIDDLEWARE/CONTRACT_SUBMIT';

export const actions = {
  CONTRACT_SUBMIT
};

const contractSubmit = (
  forms: ContractKeyGroup[],
  contractId: string,
  parentResource?: ResourceIdentifier,
  allowSaveWithoutChanges?: boolean
): FSA => {
  return {
    type: CONTRACT_SUBMIT,
    payload: null,
    meta: {
      contractSubmit: {
        forms,
        contractId,
        parentResource,
        allowSaveWithoutChanges
      }
    }
  };
};

export const actionCreators = {
  contractSubmit
};

export interface GeneralFields {
  key_display_name: string;
  key_notification_recipients: {
    email_address: string;
    id?: string;
    display_name?: string;
  }[];
  key_duration: {
    expires: boolean;
    starts_at: string;
    expires_at: string;
  };
  key_attached_projects: ResourceIdentifier[];
  key_attached_assets: ResourceIdentifier[];
  key_contract_type: PublicResource;
}

export const createContractSubmitMiddleware = ({ getState, dispatch }) => {
  return (next) => (action) => {
    const { meta } = action;

    if (!meta?.contractSubmit) {
      return next(action);
    } else {
      const config = meta.contractSubmit;

      switch (action.type) {
        case CONTRACT_SUBMIT: {
          const { contractId, forms, parentResource, allowSaveWithoutChanges } =
            config;
          const state = getState(); // redux state

          /** set up cache invalidation for related resources */
          const invalidated: ResourceIdentifier[] = [
            { type: 'workspace', id: getState().session.workspace }
          ];
          invalidated.push({ type: 'contract', id: contractId });
          if (parentResource) {
            invalidated.push(parentResource);
          }

          /** check if general (intrinsic) contract fields have been edited */
          const generalForm = forms.filter(
            (f) => f.type !== 'contract-key-group'
          )[0];
          const generalDirty = isDirty(generalForm.id)(state);

          /** check if any of the field categories have been changed */
          const keyGroups = forms.filter(
            (f) => f.type === 'contract-key-group'
          );
          const dirtyGroups = keyGroups.filter((g) => isDirty(g.id)(state));

          // /** sanity check */
          if (
            !generalDirty &&
            !dirtyGroups.length &&
            !allowSaveWithoutChanges
          ) {
            console.warn(
              'Operation canceled: Attempted to submit pristine form'
            );
            return next(action);
          }

          /** set forms to submitting state for ui */
          forms.forEach((f) => dispatch(startSubmit(f.id)));

          /** chain of promises to submit data in right order */
          new Promise((resolve, reject) => {
            /**
             * if general setting were changed
             * - first submit changes to the contract (if applicable)
             * - then submit changes to relationships between contract and assets/projects (if applicable)
             * then if category fields where changed
             * - for every category (if applicable)
             *   - add new values
             *   - update values
             *   - remove values
             * finally
             * - set form states to succesful / failed
             * - invalidate caches
             */
            if (generalDirty) {
              const values = getFormValues(generalForm.id)(
                state
              ) as GeneralFields;
              const initialValues = getFormInitialValues(generalForm.id)(
                state
              ) as GeneralFields;

              handleContractChanges({
                initialValues,
                values,
                contractId
              })
                .then((res) => {
                  const handler = handleAttachedResourceChanges({
                    initialValues,
                    values,
                    contractId
                  });
                  return handler(res);
                })
                .then(resolve)
                .catch(reject);
            } else if (allowSaveWithoutChanges) {
              const values = getFormValues(generalForm.id)(
                state
              ) as GeneralFields;
              handleContractFinal({ contractId, values })
                .then(resolve)
                .catch(reject);
            } else {
              resolve(null);
            }
          })
            .then((res) => {
              const handler = handleContractFieldChanges({
                dirtyGroups,
                state,
                contractId,
                invalidated
              });
              return handler(res);
            })
            .then((res) => {
              forms.forEach((f) => {
                dispatch(stopSubmit(f.id));
                const sss = setSubmitSucceeded(f.id);
                dispatch({ ...sss, meta: { ...sss.meta, legit: true } });
              });
            })
            .catch((err) => {
              forms.forEach((f) => {
                dispatch(stopSubmit(f.id));
                dispatch(setSubmitFailed(f.id));
              });
            })
            .finally(() => {
              // invalidate all affected resource cache
              invalidated.forEach((r) => {
                dispatch(requestStatusActionCreators.invalidateResource(r));
              });
            });
          return next(action);
        }
        default:
          return next(action);
      }
    }
  };
};
