import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList/useResourceList';
import Button from '@cube3/ui/src/Buttons/Button';
import Duration from '@cube3/ui/src/human/Duration';
import Time from '@cube3/ui/src/human/Time';
import { ThumbnailGroup } from '@cube3/ui/src/thumbnails/ThumbnailGroup';
import { Typography } from '@cube3/ui/src/typography/Typography';
import React, { useCallback, useMemo } from 'react';
import { useModalActions } from '../../Modals/modalActions';

const emptyObj = {};
export const ExportSingleAssetInfo = ({
  selection,
  rootStyles = emptyObj,
  /** hide text: 'last modified' */
  hideText = false,
  width = 120
}) => {
  const assetProperties = useResourceList__ALPHA({
    resourceId: selection?.id,
    resourceType: 'asset',
    edgeType: 'properties',
    edgeLabel: 'properties',
    strategy: 'fetch-on-mount'
  }).first?.fields;

  const asset = useMemo(() => {
    if (selection) {
      const {
        duration,
        updated_at,
        display_name,
        display_image
      } = selection.attributes;
      const dimensions =
        assetProperties &&
        assetProperties.find(field => field.key === 'Dimensions')?.value;

      return {
        id: selection.id,
        duration,
        updated_at,
        dimensions,
        display_name,
        display_image: display_image ? [display_image] : []
      };
    }
  }, [assetProperties, selection]);

  return (
    <div style={{ display: 'flex', marginBottom: 16, ...rootStyles }}>
      <ThumbnailGroup
        thumbnails={asset.display_image}
        ratio="sixteenToNine"
        cornerRadius="none"
        width={width}
      />
      <span
        style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16 }}
      >
        {asset && (
          <>
            <Typography color="contrast1" typographyStyle="heading2">
              {asset.display_name}
            </Typography>
            <div style={{ width: '100%', height: 12 }}></div>
            <Typography color="contrast3">
              {asset.dimensions}
              {' • '}
              <Duration
                duration={asset.duration}
                toolTip={false}
                placement="bottom-start"
              />
              {' • '}
              {!hideText && 'last modified '}
              <Time
                date={asset.updated_at}
                fullForm={true}
                toolTip={false}
                placement="bottom-start"
              />
            </Typography>
          </>
        )}
      </span>
    </div>
  );
};

export const ExportMultiAssetsInfo = ({ selection }) => {
  const { openModal } = useModalActions();

  /** assets info */
  const assets = useMemo(() => {
    if (selection && selection.length > 0) {
      return selection.reduce(
        (acc, item) => {
          return {
            ids: [...acc.ids, item.id],
            displayImages: [
              ...acc.displayImages,
              item.attributes.display_image
            ],
            displayNames: [...acc.displayNames, item.attributes.display_name]
          };
        },
        { ids: [], displayImages: [], displayNames: [] }
      );
    }
  }, [selection]);

  const allFiles = useMemo(() => {
    if (!selection || selection.length === 0) return [];
    return selection.map(s => ({
      id: s.id,
      display_image: s.attributes.display_image,
      display_name: s.attributes.display_name
    }));
  }, [selection]);

  const handleOpenAllFiles = useCallback(() => {
    openModal('export_options__all_files', { assets: allFiles }, false);
  }, [allFiles, openModal]);

  return (
    <div style={{ display: 'flex', marginBottom: 16 }}>
      <ThumbnailGroup
        thumbnails={assets.displayImages}
        ratio="sixteenToNine"
        cornerRadius="none"
        width={120}
      />
      <span
        style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16 }}
      >
        {assets && (
          <>
            <Typography color="contrast1" typographyStyle="heading2">
              {assets.displayNames.length} assets
              <Button colorVariant={'ghost1'} onClick={handleOpenAllFiles}>
                (view all)
              </Button>
            </Typography>

            {assets.displayNames.slice(0, 3).map((name, index) => (
              <Typography
                color="contrast1"
                typographyStyle="heading2"
                key={index}
              >
                {name}
              </Typography>
            ))}
            {assets.displayNames.length > 3 && (
              <Typography color="contrast2" typographyStyle="body2">
                + {assets.displayNames.length - 3} other
              </Typography>
            )}
          </>
        )}
      </span>
    </div>
  );
};
