import React from 'react';
import Surface from '@cube3/ui/src/Surface/Surface';
import { useRouteMatch } from 'react-router-dom';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { urlStructureProject } from '../../../routing/routingPaths';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import Cube3Link from '../../../routing/Cube3Link';
import { Padding } from './Padding';
import { useModalActions } from '../../Modals/modalActions';

export interface ProjectUrlParams {
  projectId: string;
  workspaceId: string;
}

export const RelatedContractsNotice = () => {
  const { projectId } =
    useRouteMatch<ProjectUrlParams>(urlStructureProject)?.params || {};

  const contracts = useResourceList__ALPHA({
    resourceType: 'project',
    resourceId: projectId,
    edgeType: 'contract',
    edgeLabel: 'contracts'
  });

  const modalActions = useModalActions();

  if (!contracts.pageCounts?.itemCount) {
    return null;
  }

  return (
    <Padding>
      <Surface
        corners="rounded"
        cornerRadius="mediumEmphasis"
        background="transparent"
        border={true}
      >
        <Padding>
          <Typography
            typographyStyle="body1"
            color="contrast2"
            typographyProps={{ gutterBottom: true }}
          >
            {contracts.pageCounts.itemCount} contracts applied to the current
            project also{' '}
            {contracts.pageCounts.itemCount === 1 ? 'applies' : 'apply'} to this
            asset.
          </Typography>
          <Cube3Link
            // pathname={projectPath}
            underline={true}
            onClick={() =>
              modalActions.openModal('project_contracts', {
                type: 'project',
                id: projectId
              })
            }
          >
            <Typography typographyStyle="body2">
              View project contracts
            </Typography>
          </Cube3Link>
        </Padding>
      </Surface>
    </Padding>
  );
};
