import React, { useCallback, useEffect } from 'react';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { useModalActions } from '../../Modals/modalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';

import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { Contract } from '@cube3/common/model/schema/resources/contract';

import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

interface Props extends ModalReceiverProps {
  modalContext: { contract: Contract; parentResource: ResourceIdentifier };
}

export const DetachContractPrompt: React.FunctionComponent<Props> = (props) => {
  const { contract, parentResource } = props.modalContext;

  const modalActions = useModalActions();

  const [detachContract, detachContractStatus] = useMutateRelationship__ALPHA({
    ancestor: parentResource,
    relationship: 'contracts',
    cacheInvalidator: useCallback(
      (res, mut, anc, rel) => [
        {
          id: res.id,
          type: 'contract',
          relationship: `${anc.type}s`
        },
        {
          id: anc.id,
          type: anc.type,
          relationship: 'contracts'
        }
      ],
      []
    )
  });

  const handleClick = useCallback(() => {
    detachContract({
      mutationType: 'remove',
      resource: { id: contract.id, type: 'contract' }
    });
  }, [contract, detachContract]);

  useEffect(() => {
    if (detachContractStatus === statuses.SUCCESS) {
      modalActions.previousModal();
    }
  }, [detachContractStatus, modalActions]);

  return (
    <ModalNoticeUI
      title="Detach contract"
      loading={!!detachContractStatus}
      onCloseEvent={!detachContractStatus && modalActions.previousModal}
      footerRightComponent={
        <>
          <Button
            text="Cancel"
            colorVariant="ghost2"
            onClick={modalActions.previousModal}
            disabled={!!detachContractStatus}
          />
          <Button
            text="Detach"
            colorVariant="filledDanger"
            onClick={handleClick}
            disabled={!!detachContractStatus}
          />
        </>
      }
    >
      <Typography>
        Are you sure you want to detach this contract from this{' '}
        {parentResource.type}?
      </Typography>
    </ModalNoticeUI>
  );
};
