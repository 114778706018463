import React from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from './../../Modals/ModalManager';
import {
  withModalActions,
  ModalActionProps
} from '../../Modals/modalActions';
import withAccountActions, {
  AccountActionsProps
} from '@cube3/state/src/redux/components/Administration/withAccountActions';

// forms
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { isRequired } from '../../../../forms/helpers/validators';
import GenericFieldWithErrors from '../../../../forms/helpers/fields/GenericFieldWithErrors';

// UI
import { compose } from '../../../../../utils/component-helpers';
import SubmitCancelModalUI from '@cube3/ui/src/Layout/AccountSettings/Modals/SubmitCancelModalUI';

/// @Author :
/// <Summary>
/// Form for changing the user name which has the user placehodler name a validator for
/// for an empty field.
/// </Summary>

interface FormValues {
  full_name: string;
  password: string;
}

interface InjectedProps
  extends ModalReceiverProps,
    ModalActionProps,
    AccountActionsProps {}

interface Properties extends InjectedProps, InjectedFormProps<FormValues> {}

class ChangeNameModal extends React.PureComponent<Properties> {
  componentDidUpdate() {
    if (this.props.submitSucceeded) {
      this.props.previousModal();
    }
  }

  render() {
    const {
      // HOC functions
      previousModal,
      valid,
      handleSubmit,
      submitting
    } = this.props;

    return (
      <SubmitCancelModalUI
        title={'Change name'}
        onCloseEvent={() => previousModal()}
        submitDisabled={!valid || submitting}
        onSubmit={handleSubmit}
        loading={submitting}
      >
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Field
            component={GenericFieldWithErrors}
            name="full_name"
            validate={isRequired}
            autocomplete={false}
            // label="Name"
            startSelected={true}
            props={{
              autocomplete: 'name',
              label: 'New full name'
            }}
          />
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Field
            component={GenericFieldWithErrors}
            name={'password'}
            type="password"
            validate={isRequired}
            placeholder="Enter password to confirm identity..."
            props={{
              autoComplete: 'current-password',
              label: 'Password'
            }}
          />
        </div>
      </SubmitCancelModalUI>
    );
  }
}

export default compose(ChangeNameModal)(
  withModalActions,
  withAccountActions({
    accountId: ({ modalContext }) => modalContext
  }),
  Wrapped => props => (
    <Wrapped
      {...props}
      initialValues={{ full_name: props.user && props.user.full_name }}
    />
  ),
  reduxForm<FormValues, InjectedProps>({
    form: 'changeName',
    destroyOnUnmount: true,
    onSubmit: (values: FormValues, dispatch, props) => {
      const realProps = props; // since the redux props type is incomplete, we will define the real object
      realProps.changeFullName(values.full_name, values.password, 'changeName');
    }
  })
);
