import fscreen from 'fscreen';
import { trackFullscreen } from '../ducks/ui';

export const uiMiddleware = ({ getState, dispatch }) => {
  let handleChange;

  fscreen.addEventListener('fullscreenchange', ev => handleChange?.(ev));
  fscreen.addEventListener('fullscreenerror', ev =>
    dispatch(trackFullscreen(false))
  );

  return next => action => {
    if (action.type === 'cube3-client/ui/TOGGLE_FULLSCREEN') {
      const fullscreenElement = document.getElementById('fullscreenElement');

      handleChange = ev => {
        dispatch(trackFullscreen(fscreen.fullscreenElement !== null));
      };

      next(action);

      if (fscreen.fullscreenElement !== null) {
        fscreen.exitFullscreen();
      } else {
        let wait = () => {
          if (fullscreenElement.parentNode === document.body) {
            fscreen.requestFullscreen(fullscreenElement)?.catch(console.error);
          } else {
            requestAnimationFrame(wait);
          }
        };
        requestAnimationFrame(wait);
      }

      // const fullscreenElement = document.getElementById('fullscreenElement');
      // if (getState().ui.fullscreen) {
      //   if (fscreen.fullscreenElement) {
      //     fscreen.exitFullscreen();
      //   }
      // } else {
      //   fscreen.requestFullscreen(fullscreenElement);
      // }
    } else {
      next(action);
    }
  };
};
