import { enableMapSet } from 'immer';

enableMapSet();

// Exports
export { actions, actionCreators, mutations } from './actions';
export { reducer } from './reducer';
export {
  selectors as selectors__unoptimized,
  selectorCreators
} from './selectors';

export { serializeLabel } from './utils';

export { reducer as default } from './reducer';
