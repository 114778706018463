import * as React from 'react';

/* Import redux */
import { selectors } from '@cube3/state/src/redux/ducks/modals';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';

// generic wrapper (center modal) for components.
import ModalManagerUI from '@cube3/ui/src/Modal/ModalManagerUI';
import { modalIndex } from './modalIndex';
import { matchPath, useLocation } from 'react-router';
import { urlStructureWorkspace } from '../../routing/routingPaths';
import { WorkspacePermissionsProvider } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { ShortCutsProvider } from '@cube3/ui/src/keyboard-shortcuts/ShortCutsProvider';
import { useState } from 'react';
import { ErrorBoundary } from '../../../helpers/ErrorBoundary';
import ModalContextProvider from './ModalContextProvider';
import { WorkspaceFeaturesProvider } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { WorkspacePhoneFeatureProvider } from '../Workspace/contexts/PhoneFeatureContext';

/// @Author : Simon @ AmbassadorsLAB (inspired from Michiels sharelinkwizard)
/// <Summary>
/// Provider component that checks if redux state holds an active modal
/// if redux state does it...:
/// - Tries to render a component if it matches the enum knownModals.
/// - Tries to get any context given in state, for example a summary or
/// an asset and passes it on to the component
///
/// This component does not dispatch any events. It only cares about
/// if it should render a component or not from the recieved state.
///
/// ADDING MODALS
/// Add components by expanding the list in modalIndex.ts
/// OPENING MODALS
/// - You can open your modal by using the useModalActions hook (state package)
/// - You can then open a modal by wrapping your component with
/// withModalActions (state package) from the state package, extending its interface
/// and then calling a function like openModal('somemodal');
/// </Summary>

interface ModalWizardProps {}

export interface ModalReceiverProps<C = any> {
  // eslint-disable-next-line
  modalContext?: C;
}

const getModalComponent = activeModal => {
  return modalIndex[activeModal];
};

const ModalManager: React.FunctionComponent<ModalWizardProps> = React.memo(
  props => {
    const visibleModalStack = useTypedSelector(state => {
      return selectors.getVisibleModalStack(state);
    });

    const ModalElements = React.useMemo(() => {
      return visibleModalStack.map((m, index) => {
        const Component = getModalComponent(m.modal);
        return (
          <ModalContextProvider
            key={m.modal + index}
            isActiveModal={index === visibleModalStack.length - 1}
            currentModalndex={index}
          >
            <Component modalContext={m.resource} key={m.modal + index} />
          </ModalContextProvider>
        );
      });
    }, [visibleModalStack]);

    // NOTE: modalmanager renders outside the workspace layout, so we need to add this
    const { pathname } = useLocation();
    const notInWorkspace = !matchPath(pathname, urlStructureWorkspace);

    const [ref, setRef] = useState(null);

    return (
      <ErrorBoundary
        name="modalManager"
        extraInfo={visibleModalStack.map(m => m.modal).join(', ')}
      >
        <WorkspaceFeaturesProvider bypass={notInWorkspace}>
          <WorkspacePhoneFeatureProvider>
            <ShortCutsProvider
              name="modals"
              boundaryEl={ref}
              isolate={(visibleModalStack.length && 'down') || undefined}
              grabFocus={!!visibleModalStack.length}
              returnFocus={true}
            >
              <WorkspacePermissionsProvider bypass={notInWorkspace}>
                <ModalManagerUI ref={setRef}>{ModalElements}</ModalManagerUI>
              </WorkspacePermissionsProvider>
            </ShortCutsProvider>
          </WorkspacePhoneFeatureProvider>
        </WorkspaceFeaturesProvider>
      </ErrorBoundary>
    );
  }
);

export default ModalManager;
