import React from 'react';
import { LinkSettingsCheckbox } from './fields/LinkSettingsCheckbox';
import { useStyles } from './LinkSettingsForm';
import { VerificationTypePicker } from './fields/VerificationTypePicker';
import { Field } from 'redux-form';
import { VerifyType } from '@cube3/common/model/resource-types';
import { useClassName } from '@cube3/common/utils/useClassName';
import { Typography } from '../../../typography/Typography';

export interface VerfiyUserFieldsetProps {
  verifyUser: boolean;
  verifyType: VerifyType;
  allowRequestAccess: boolean;
  hasVerifyUserFeature?: boolean;
}

export const VerifyUserFieldset = (props: VerfiyUserFieldsetProps) => {
  const { hasVerifyUserFeature, verifyUser, verifyType, allowRequestAccess } =
    props;

  const classes = useStyles();

  return (
    <>
      {(hasVerifyUserFeature || verifyUser) && (
        <Field
          name="verifyUser"
          type="checkbox"
          component={LinkSettingsCheckbox}
          props={{ label: 'Require email verification' }}
          className={classes.inputPrimary}
          disabled={verifyUser && !hasVerifyUserFeature}
        />
      )}

      {verifyUser && (
        <>
          <Field
            name="verifyType"
            component={VerificationTypePicker}
            className={classes.inputIndent}
            disabled={!hasVerifyUserFeature}
          />
          <div style={{ position: 'relative' }}>
            {verifyType === VerifyType.everyone && (
              <div style={{ position: 'absolute', marginLeft: 48 }}>
                <Typography variant="body3" color="contrast2">
                  * Cube only checks if the user controls the email address they
                  provide
                </Typography>
              </div>
            )}
            <Field
              name="allowRequestAccess"
              type="checkbox"
              component={LinkSettingsCheckbox}
              props={{ label: 'Allow requesting access' }}
              className={`${classes.inputIndent} ${
                verifyType === VerifyType.everyone && classes.hidden
              }`}
              disabled={
                verifyType === VerifyType.everyone || !hasVerifyUserFeature
              }
            />
          </div>
        </>
      )}
    </>
  );
};
