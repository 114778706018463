import React, { useCallback, useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  Popover,
  Theme,
  PopoverProps
} from '@material-ui/core';
import { useSurfaceClasses } from '../../Surface/Surface';
import { contextMenuContext } from '../../library/ContextualMenu/ContextualMenuPopoverUI';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    surface: {
      width: 'max-content'
    }
  })
);

// pick open property from PopoverInterface
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type ExcludedPopoverProps = Omit<PopoverProps, 'open'>;

interface MenuListCompositionProps {
  toggleButton(
    handleToggle: () => void,
    anchorEl: React.MutableRefObject<HTMLElement>,
    open: boolean
  ): React.ReactNode;
  visible?: boolean;
  variant?: 'legacy' | 'context-menu';
  menuItems: React.ReactNode | (() => React.ReactNode);
  onClose?(): void;
  PopoverProps?: ExcludedPopoverProps;
}

function MenuListComposition(props: MenuListCompositionProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLElement>(null);

  // get CSS styles for floating surface. we have to inject them into popover directly.
  const surfaceStyles = useSurfaceClasses({
    floating: true,
    corners: 'rounded',
    gradient: 'lamp',
    background: 'base2',
    cornerRadius: 'mediumEmphasis',
    floatingHeight: 24
  });

  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const {
    toggleButton,
    menuItems,
    PopoverProps,
    onClose,
    variant = 'legacy',
    visible = true
  } = props;

  const closeHandler = useCallback(
    ev => {
      setOpen(false);
    },
    [setOpen]
  );

  const closeHandlerLegacy = useCallback(
    ev => {
      if (onClose) {
        onClose();
      }
      setOpen(false);
    },
    [onClose, setOpen]
  );

  useEffect(() => {
    if (open && !visible) {
      setOpen(false);
    }
  }, [visible, setOpen, open]);

  if (!menuItems) {
    console.warn('Empty menu rendered');
  }

  // if (typeof menuItems !== 'function') {
  //   console.warn('Menuitems is not a function', menuItems);
  // }

  if (!visible) {
    return null;
  }

  if (variant === 'legacy') {
    return (
      <div className={classes.root}>
        {toggleButton(handleToggle, anchorEl, open)}

        <Popover
          open={open}
          onClose={closeHandlerLegacy} // if we click outside the popover we want to close it.
          onClick={closeHandlerLegacy} // if we click an item in the popover we want to close it
          anchorEl={anchorEl.current}
          PaperProps={{ className: `${surfaceStyles} ${classes.surface}` }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          {...PopoverProps}
        >
          {menuItems && (typeof menuItems === 'function'
            ? menuItems()
            : menuItems)}
        </Popover>
      </div>
    );
  } else if (variant === 'context-menu') {
    return (
      <div className={classes.root}>
        {toggleButton(handleToggle, anchorEl, open)}

        {open && (
          <contextMenuContext.Provider
            value={{
              clientX: anchorEl.current?.getBoundingClientRect().left,
              clientY: anchorEl.current?.getBoundingClientRect().bottom,
              menuOpen: open,
              handleClose: closeHandler,
              handleClearAll: onClose
            }}
          >
            {menuItems && typeof menuItems === 'function' && menuItems()}
            {/* </Popover> */}
          </contextMenuContext.Provider>
        )}
      </div>
    );
  }
}

export default MenuListComposition;
