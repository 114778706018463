export const TAGLINES = {
  YouTube: 'Publish videos to YouTube',
  Broadcast: 'Deliver TVCs directly to Dutch stations'
};

export const makeGenericTagline = exportTargetType =>
  `Upload ${
    exportTargetType.attributes.supported_asset_types[0].split('.').slice(-1)[0]
  } assets to ${exportTargetType.attributes.display_name}`;

export const DESCRIPTIONS = {
  YouTube: `Export videos from Cube to YouTube directly. 
    You can choose the privacy settings, add tags and change the name.
    Accounts and channels must be configured per user. Users can connect their account via the Account Settings page.`,
  Broadcast: `Deliver TVCs directly to one or more Dutch broadcast stations.
    Videos will be submitted to quality checks, before delivery. If videos don't match the spec, you get notified.`
};
