export const initialState = {
  inFlight: {},
  success: {},
  failed: {},
  invalidated: {},
  errors: {}
};

export type State = typeof initialState;

const IN_FLIGHT = `cube3/request-status/statuses/IN_FLIGHT`;
const SUCCESS = `cube3/request-status/statuses/SUCCESS`;
const FAILED = `cube3/request-status/statuses/FAILED`;
const PRE_FLIGHT = `cube3/request-status/statuses/PRE_FLIGHT`;
const INVALIDATED = `cube3/request-status/statuses/INVALIDATED`;

export type RequestStatuses =
  | typeof IN_FLIGHT
  | typeof SUCCESS
  | typeof FAILED
  | typeof PRE_FLIGHT
  | typeof INVALIDATED;

export const statuses = {
  IN_FLIGHT,
  SUCCESS,
  FAILED,
  PRE_FLIGHT,
  INVALIDATED
} as const;
