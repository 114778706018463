import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useModalActions } from '../../../../Modals/modalActions';
import Button from '@cube3/ui/src/Buttons/Button';
import { compose } from '@cube3/state/src/utils/component-helpers';
import {
  clearFields,
  Field,
  getFormValues,
  InjectedFormProps,
  reduxForm
} from 'redux-form';

import GenericFieldWithErrors from '../../../../../../forms/helpers/fields/GenericFieldWithErrors';
import { isRequired } from '../../../../../..//forms/helpers/validators';
import { bindActionCreators } from 'redux';
import { ClearInputHandler } from '@cube3/ui/src/forms/textfields/AutoCompleteInput';
import { connect } from 'react-redux';
import { WorkspaceTeamMembersPicker } from '../prefabs/WorkspaceTeamMembersPicker';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useMutateRelationship__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateRelationship';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { actions } from '@cube3/state/src/redux/ducks/resource-nodes';
import { selectors } from '@cube3/state/src/redux/ducks/request-status';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { mutations } from '@cube3/state/src/redux/ducks/resource-edges';
import { getErrorMessage } from '../../../../../../forms/helpers/errors';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';

interface Props extends InjectedFormProps {
  clearEmailInput: ClearInputHandler;
  formValues: {
    accountSearchInput: string;
    accounts: ResourceIdentifier[];
    teamName: string;
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearEmailInput: clearFields
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  const values = getFormValues('create_new_team')(state);
  return {
    formValues: values
  };
};

const AddNewTeamModal: React.FC<Props> = (props) => {
  const { form, formValues, valid, anyTouched, clearEmailInput } = props;

  const { previousModal, closeAllModals } = useModalActions();
  const { accountSearchInput, teamName, accounts } = formValues || {};
  const [workspaceId] = useCurrentWorkspace();
  const [submitError, setSubmitError] = useState(undefined);

  const tempId = useRef(uuidv4()).current;
  const mappedId = useMappedId__ALPHA(tempId);

  const [canCreateTeam] = useWorkspacePermissions([
    'GROUP_READ',
    'GROUP_WRITE'
  ]);

  const [createTeam, createTeamStatus] = useCreateResource__ALPHA({
    resourceType: 'team',
    cacheInvalidator: useCallback(
      () => [
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'teams'
        }
      ],
      [workspaceId]
    )
  });

  const onClickCreate = useCallback(() => {
    if (canCreateTeam) {
      createTeam({
        type: 'team',
        temporaryId: tempId,
        display_name: teamName.trim(),
        relationships: {
          workspace: {
            data: {
              type: 'workspace',
              id: workspaceId
            }
          }
        }
      });
    }
  }, [canCreateTeam, createTeam, teamName, tempId, workspaceId]);

  const errors = useTypedSelector((state) =>
    selectors.getErrors(state.requestStatus, {
      type: actions.CREATE_RESOURCE,
      meta: { apiClient: { temporaryId: tempId } }
    })
  );

  const [addMembers, addMembersStatus] = useMutateRelationship__ALPHA({
    ancestor: { type: 'team', id: mappedId },
    relationship: 'members',
    cacheInvalidator: useCallback(
      (res, mut, anc) => [{ type: anc.type, id: anc.id }],
      []
    ),
    options: { optimize: true }
  });

  // Add members after the team is successfully created
  useEffect(() => {
    if (mappedId && accounts && accounts.length > 0 && !addMembersStatus) {
      addMembers(
        accounts.map((m) => ({
          resource: m,
          mutationType: mutations.MUTATION_ADD
        }))
      );
    }
  }, [addMembers, addMembersStatus, mappedId, accounts]);

  useEffect(() => {
    if (
      addMembersStatus === statuses.SUCCESS ||
      (!addMembersStatus && createTeamStatus === statuses.SUCCESS)
    ) {
      closeAllModals();
    }
  }, [closeAllModals, createTeamStatus, addMembersStatus]);

  const loading =
    (createTeamStatus &&
      createTeamStatus !== statuses.SUCCESS &&
      createTeamStatus !== statuses.FAILED) ||
    (addMembersStatus &&
      addMembersStatus !== statuses.SUCCESS &&
      addMembersStatus !== statuses.FAILED);

  useEffect(() => {
    if (createTeamStatus === statuses.FAILED) {
      setSubmitError(errors && getErrorMessage(errors));
    }
  }, [errors, createTeamStatus, setSubmitError]);

  const onChange = useCallback(() => {
    if (submitError) {
      setSubmitError(undefined);
    }
  }, [submitError, setSubmitError]);

  return (
    <ModalNoticeUI
      title="New team"
      loading={loading}
      component="form"
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button text="Cancel" colorVariant="ghost2" onClick={previousModal} />
          <Button
            text="Create"
            colorVariant="filled1"
            onClick={onClickCreate}
            disabled={!valid || !!submitError}
          />
        </>
      }
    >
      <div style={{ minHeight: 400 }}>
        <Field
          required={true}
          label="Team name"
          component={GenericFieldWithErrors}
          name="teamName"
          validate={isRequired}
          placeholder="Enter a team name"
          startSelected={true}
          props={{
            showErrors: anyTouched ? true : 'touched'
          }}
          onChange={onChange}
        />
        {submitError && (
          <Typography color="danger1" typographyStyle="body2">
            {submitError}
          </Typography>
        )}
        <WorkspaceTeamMembersPicker
          label="Members"
          inputFieldPlaceholder="Enter a user name"
          form={form}
          clearEmailInput={clearEmailInput}
          accountSearchInput={accountSearchInput}
        />
      </div>
    </ModalNoticeUI>
  );
};

export default compose(AddNewTeamModal)(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'create_new_team',
    destroyOnUnmount: true
  })
);
