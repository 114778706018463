import React, { useCallback } from 'react';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useModalActions } from '../../Modals/modalActions';

/**
 * - receive origin contract id
 * - return duplicate function, duplicate status
 * - When the request has succeeded, the contract editor is opened for the new (duplicate) contract
 */
export const useContractDuplication = (contractId: string) => {
  const [workspaceId] = useCurrentWorkspace();
  const { openModal } = useModalActions();
  const [loading, setLoading] = React.useState(false);

  const [createResource, createResourceStatus] = useCreateResource__ALPHA({
    resourceType: 'contract',
    ancestor: { type: 'workspace', id: workspaceId },
    relationship: 'contracts',
    cacheInvalidator: useCallback(
      () => [{ type: 'workspace', id: workspaceId, relationship: 'contracts' }],
      [workspaceId]
    )
  });
  // use the temporary id to get the actual id of duplicated contract
  const tempId = React.useRef(uuidv4());
  const mappedId = useMappedId__ALPHA(tempId.current);

  const duplicateContract = React.useCallback(() => {
    setLoading(true);
    createResource({
      type: 'contract',
      temporaryId: tempId.current,
      status: 'CONTRACT_STATUS_DRAFT',
      relationships: {
        'duplicate-of': {
          data: { type: 'contract', id: contractId }
        }
      }
    });
  }, [contractId, createResource, tempId]);

  React.useEffect(() => {
    if (
      !createResourceStatus ||
      createResourceStatus === statuses.FAILED ||
      createResourceStatus === statuses.SUCCESS
    ) {
      setLoading(false);
    }
    if (createResourceStatus && createResourceStatus === statuses.SUCCESS) {
      openModal('edit_contract', { contractId: mappedId, isDraft: true });
    }
  }, [createResourceStatus, mappedId, openModal, setLoading]);

  return {
    duplicateContract,
    loading
  };
};
