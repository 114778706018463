import React, { useState } from 'react';
import { useSelect } from 'downshift';
import {
  SuggestionPopover,
  SuggestionsListInner
} from '@cube3/ui/src/inputChipPicker/SuggestionsList';
import { SuggestionsItemBase } from '@cube3/ui/src/inputChipPicker/SuggestionsItem';
import { Typography } from '@cube3/ui/src/typography/Typography';
import GeneralSVG, {
  iconArrowDown,
  iconCheck
} from '@cube3/ui/src/icons/GeneralSVG';

import { Tag } from '@cube3/cubicle/src/core/atoms/Tag/Tag';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { COLORS, ColorVariant } from '@cube3/cubicle/src/core/atoms/Color';

const items = [...COLORS];

export const TagsInputColorpicker = (props) => {
  const { value, onChange } = props;

  const [anchorRef, setAnchorRef] = useState(null);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    selectItem,
    openMenu,
    closeMenu
  } = useSelect<ColorVariant>({
    items,
    defaultHighlightedIndex: 0,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
    }
  });

  const menuProps = {
    ...getMenuProps(),
    tabIndex: undefined // prevent element from grabbing focus, because it is nested inside another downshift dropdown
  };
  const toggleButtonProps = getToggleButtonProps({
    ref: setAnchorRef
  });

  return (
    <div {...menuProps}>
      <div
        {...toggleButtonProps}
        onClick={(ev) => {
          ev.stopPropagation();
          openMenu();
        }}
      >
        <div
          style={{
            display: 'grid',
            justifyContent: 'start',
            alignItems: 'center',
            gridTemplateColumns: '28px 24px',
            gridTemplateRows: '1fr',
            cursor: 'pointer'
          }}
        >
          <Tag
            color={value}
            size={SizeVariants.xs}
            chipStyle={!value || value === 'ghost' ? 'outlined' : 'filled'}
          />
          <GeneralSVG path={iconArrowDown} />
        </div>

        <SuggestionPopover anchorEl={anchorRef} align="center" open={isOpen}>
          <SuggestionsListInner title="Tag color">
            {items.map((item, index) => {
              const active = item === value || (!value && item === 'ghost');
              return (
                <SuggestionsItemBase
                  key={item}
                  {...getItemProps({ item, index })}
                  onClick={(ev) => {
                    selectItem(item);
                    closeMenu();
                    ev.stopPropagation();
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      justifyContent: 'start',
                      alignItems: 'center',
                      gridTemplateColumns: '24px 28px 1fr ',
                      gridTemplateRows: '1fr'
                    }}
                    onMouseDown={(ev) => ev.preventDefault()}
                  >
                    {active ? (
                      <GeneralSVG
                        path={iconCheck}
                        color="brand1"
                        svgProps={{ viewBox: '0 0 32 32' }}
                      />
                    ) : (
                      <span />
                    )}
                    <Tag
                      color={item}
                      size={SizeVariants.xs}
                      chipStyle={item === 'ghost' ? 'outlined' : 'filled'}
                    />
                    <Typography color={active ? 'brand1' : 'contrast1'}>
                      {item === 'ghost' ? 'No color' : item}
                    </Typography>
                  </div>
                </SuggestionsItemBase>
              );
            })}
          </SuggestionsListInner>
        </SuggestionPopover>
      </div>
    </div>
  );
};
