import { Typography } from '@cube3/ui/src/typography/Typography';
import Button from '@cube3/ui/src/Buttons/Button';
import Surface from '@cube3/ui/src/Surface/Surface';
import React, { useCallback, useEffect, useState } from 'react';
import {
  APP_VERSION_DIFFERENCE,
  //   APP_SERVED_VERSION_UNKNOWN,
  verifyRunningVersion
} from '../components/helpers/checkServedVersion';

interface UseVersionCheckerConfig {
  interval?: number;
}

export const useVersionChecker = ({
  interval = 60 * 60 * 1000
}: UseVersionCheckerConfig) => {
  const [error, setError] = useState(null);

  const onHandleSnooze = useCallback(() => {
    setError(null);
  }, [setError]);

  useEffect(() => {
    if (error) {
      return;
    }
    const i = setInterval(() => {
      verifyRunningVersion()
        .then(ver => setError(null))
        .catch(err => {
          if (err?.message === APP_VERSION_DIFFERENCE) {
            setError(err);
          } else {
            console.warn("Couldn't retrieve served app version", err);
          }
        });
    }, interval);
    return () => clearInterval(i);
  }, [interval, error]);

  return {
    snooze: onHandleSnooze,
    show: !!error,
    error: error?.message
  };
};

export const VersionNotification = props => {
  const { onSnooze } = props;
  return (
    <Surface background="base2" gradient={'lamp'} floating={true}>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 8
        }}
      >
        <div>
          <Typography variant="body1">New Cube version available</Typography>
          <Typography variant="body3" color="contrast2">
            Please save any changes before reloading.
          </Typography>
        </div>
        <div style={{ width: '2em' }} />
        <Button
          onClick={() => window.location.reload()}
          colorVariant="filled1"
          size="small"
        >
          Reload
        </Button>
        <div style={{ width: '1em' }} />
        <Button onClick={onSnooze}>Snooze...</Button>
      </div>
    </Surface>
  );
};
