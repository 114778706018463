import React, { PropsWithChildren, useCallback } from 'react';
import Downshift, {
  ControllerStateAndHelpers,
  StateChangeOptions
} from 'downshift';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SuggestionsList from '../SuggestionsList';
import SuggestionsItem, { Suggestion } from '../SuggestionsItem';
import {
  useDisabledSuggestionKeys,
  useFilteredSuggestions,
  useEnabledSuggestions,
  useIdentifySuggestion
} from './hooks/useSuggestions';
import {
  useKeyDownHandler,
  useClickHandler
} from './hooks/useSuggestionHandlers';
import { Typography } from '../../typography/Typography';
import { LinearProgress } from '@material-ui/core';

/**
 *
 *  This component is a a generic suggestions container. To use this component provide the following:
 *
 *  NOTE: for an example look at the ProjectMemberPickerSuggestionContainer component.
 */

export type ClearTrigger = 'add-suggestion' | 'add' | 'blur';
export type ClearOn = Array<ClearTrigger> | 'all';

interface Props<S = Suggestion> {
  /**  Array of suggestions.  */
  allSuggestions: Array<S | string>;
  /** Array of the suggestions that are already selected. */
  selectedSuggestions: Array<S | string>;
  /** Array of suggestions that should not be selectable (but sometimes shown) */
  excludedSuggestions?: Array<S | string>;
  /** indicates that suggestions are loading */
  suggestionsLoading?: boolean;
  /** text to show when rendering excluded items */
  excludedReasonText?: string;
  /** Render prop where you need to pass the component that represents the selected item. */
  renderSelectedItem: Function;
  /** Render prop where you need to pass the component that represents a suggestion item. */
  renderSuggestionItem: Function;
  /**  Render prop where you need to pass the component that represents the input field. */
  renderInputChipPicker: Function;
  /** A function that is used to add an item to the selection array. */
  addItemToSelectionArray: Function;
  /** A function that is used to remove an item to the selection array. */
  removeItemFromSelectionArray: Function;
  /** Function that is used to clear the input field. */
  clearInputField: Function;
  /** Clear input when field loses focus, an entry is added or both */
  clearOn?: ClearOn;
  /** The current value of the input field. */
  inputValue: string | number;
  /** The suggestions object property used to identify suggestions */
  newSuggestionPrimaryKey: string;
  /** Array of strings used determine on which key press a suggestion should be selected.  */
  keysListenersArrayToAddNewSuggestions?: string[];
  /** Array of event names that should trigger selecting a suggestion */
  eventTypeArrayToAddNewSuggestions?: string[];
  /** Boolean to enable or disable adding of new suggestions.  */
  allowAddingOfNewSuggestions?: boolean;
  /** Boolean to set disabled state of a suggestionsItem.  */
  allSuggestionsDisabled?: boolean;
  /** allow showing selected or excluded suggestions for warning purposes */
  showDisabled?: boolean;
  /** optional function used to determine wheter an item is already selected  */
  isSelectedFn?(sugestion: S | string, selected: Array<S | string>): boolean;
  /** optional function used to serialize suggestion objects to string for identifying purposes */
  itemToString(s: S): string;
  /** Whether typed input has error */
  inputError?: boolean;
  /** locked resources that cannot be removed */
  lockedResources?: any[];
  /** get updates on downshift state change */
  handleStateChange?(
    options: StateChangeOptions<S>,
    stateAndHelpers: ControllerStateAndHelpers<S>
  ): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      position: 'relative'
    },
    container: {
      margin: 'auto',
      background: theme.customPalette.surface.shade2
    },
    innerContainer: {
      borderRadius: 4,
      padding: '8px 8px 4px 8px',
      position: 'relative',
      borderColor: 'rgba(255, 255, 255, 0.32)',
      border: '1px solid',
      display: 'flex',
      flexWrap: 'wrap'
    },
    error: {
      borderColor: theme.customPalette.line.onBase1.danger1
    },
    innerContainerMenuOpen: {
      paddingBottom: 4,
      // borderBottom: 'none',
      borderBottomRightRadius: 'unset',
      borderBottomLeftRadius: 'unset'
    }
  })
);

// default function to transform item to string(THIS FUNCTION WILL CHANGE)
const itemToStringDefault = (i) => {
  return i ? i.id : '';
};

const emptyArray = [];
const CLEAR_ALL = ['add-suggestion', 'add-new', 'blur'];

// NOTE: the comma in the generic declaration is important
export const SuggestionsContainer = <S,>(
  props: PropsWithChildren<Props<S>>
) => {
  const inputField: { current: { focus: Function } } = React.createRef();
  const classes = useStyles({});
  const {
    allSuggestions,
    renderSelectedItem,
    renderInputChipPicker,
    selectedSuggestions = emptyArray,
    excludedSuggestions = emptyArray,
    addItemToSelectionArray,
    allSuggestionsDisabled,
    showDisabled = true,
    isSelectedFn,
    newSuggestionPrimaryKey,
    removeItemFromSelectionArray,
    inputValue,
    clearInputField,
    clearOn: clearOnRaw = emptyArray,
    keysListenersArrayToAddNewSuggestions,
    eventTypeArrayToAddNewSuggestions,
    allowAddingOfNewSuggestions,
    itemToString = itemToStringDefault,
    inputError,
    lockedResources,
    handleStateChange
  } = props;

  const clearOn = clearOnRaw === 'all' ? CLEAR_ALL : clearOnRaw;

  const identifySuggestion = useIdentifySuggestion(newSuggestionPrimaryKey);

  /*   create array of strings of the primary key of the selected and excluded suggestions  */
  const disabledSuggestionKeys = useDisabledSuggestionKeys(
    selectedSuggestions,
    excludedSuggestions,
    identifySuggestion
  );

  /*   create array of suggestions filtered by selected and excluded suggestions  */
  const filteredSuggestions = useFilteredSuggestions(
    allSuggestions,
    selectedSuggestions,
    excludedSuggestions,
    showDisabled,
    allSuggestionsDisabled,
    isSelectedFn,
    identifySuggestion
  );

  /*   create const of only un-selected and un-excluded suggestions array length  */
  const enabledSuggestions = useEnabledSuggestions(
    filteredSuggestions,
    disabledSuggestionKeys,
    identifySuggestion
  );

  // function to handle key down on input field
  const handleInputKeyDown = useKeyDownHandler(
    enabledSuggestions,
    disabledSuggestionKeys,
    addItemToSelectionArray,
    removeItemFromSelectionArray,
    inputValue,
    clearInputField,
    clearOn,
    selectedSuggestions,
    newSuggestionPrimaryKey,
    keysListenersArrayToAddNewSuggestions,
    eventTypeArrayToAddNewSuggestions,
    allowAddingOfNewSuggestions,
    lockedResources
  );

  /*  function to handle click on suggestion item.  */
  const handleSuggestionItemClick = useClickHandler(
    addItemToSelectionArray,
    clearInputField,
    clearOn
  );

  const {
    outerContainer,
    container,
    innerContainer,
    innerContainerMenuOpen,
    error
  } = classes;

  return (
    <Downshift
      onStateChange={handleStateChange}
      selectedItem={null}
      defaultHighlightedIndex={0}
      itemToString={itemToString}
    >
      {(downShiftProps) => {
        const {
          getInputProps,
          isOpen,
          highlightedIndex,
          selectHighlightedItem,
          setHighlightedIndex,
          reset,
          inputValue,
          openMenu,
          closeMenu
        } = downShiftProps;

        const sugesstionsArray = inputValue
          ? allSuggestions
          : filteredSuggestions;

        return (
          <div className={outerContainer}>
            <div className={container}>
              <div
                className={[
                  innerContainer,
                  isOpen ? innerContainerMenuOpen : '',
                  inputError ? error : ''
                ].join(' ')}
              >
                {selectedSuggestions.map((selectedSuggestion, index) => {
                  return (
                    renderSelectedItem &&
                    renderSelectedItem(selectedSuggestion, index)
                  );
                })}
                {renderInputChipPicker &&
                  renderInputChipPicker(
                    handleInputKeyDown,
                    selectHighlightedItem,
                    highlightedIndex,
                    isOpen,
                    reset,
                    inputValue,
                    inputField,
                    getInputProps,
                    filteredSuggestions,
                    setHighlightedIndex,
                    openMenu,
                    closeMenu
                  )}
              </div>
            </div>
            {isOpen && (
              <FilteredSuggestions
                sugesstionsArray={sugesstionsArray}
                identifySuggestion={identifySuggestion}
                handleSuggestionItemClick={handleSuggestionItemClick}
                {...props}
                {...downShiftProps}
              />
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

const FilteredSuggestions: React.FC<any> = React.memo((props) => {
  const {
    getMenuProps,
    sugesstionsArray,
    highlightedIndex,
    isSelectedFn,
    selectedSuggestions = [],
    excludedSuggestions = [],
    suggestionsLoading,
    allSuggestionsDisabled,
    excludedReasonText = 'Excluded',
    showDisabled,
    handleSuggestionItemClick,
    getItemProps,
    openMenu,
    reset,
    renderSuggestionItem,
    identifySuggestion,
    inputValue
  } = props;

  return (
    <SuggestionsList downshiftMenuProps={getMenuProps}>
      {suggestionsLoading && <LinearProgress variant="indeterminate" />}
      {sugesstionsArray.length === 0 && suggestionsLoading && (
        <SuggestionsItem isDisabled={true} disabledReasonText={null}>
          <Typography>Searching for suggestions</Typography>
        </SuggestionsItem>
      )}
      {sugesstionsArray.length === 0 && !inputValue && !suggestionsLoading && (
        <SuggestionsItem isDisabled={true} disabledReasonText={null}>
          <Typography>Start typing for suggestions</Typography>
        </SuggestionsItem>
      )}
      {sugesstionsArray.length === 0 && !suggestionsLoading && inputValue && (
        <SuggestionsItem isDisabled={true} disabledReasonText={null}>
          <Typography>{`No suggestions for "${inputValue}"`}</Typography>
        </SuggestionsItem>
      )}
      {sugesstionsArray.map((suggestion, index) => {
        const isHighlighted = highlightedIndex === index;
        const inSelected = isSelectedFn
          ? isSelectedFn(suggestion, selectedSuggestions)
          : selectedSuggestions
              .map((s) => identifySuggestion(s))
              .indexOf(identifySuggestion(suggestion)) > -1;
        const inExcluded = !!excludedSuggestions.find(
          (ex) => ex.id === suggestion.id
        );

        const disabled = allSuggestionsDisabled || inSelected || inExcluded;
        const disabledReasonText =
          !allSuggestionsDisabled &&
          (inSelected ? 'Already selected' : excludedReasonText);

        if (disabled && !showDisabled) {
          return null;
        }

        return (
          <SuggestionsItem
            key={suggestion.id ? suggestion.id : identifySuggestion(suggestion)}
            isHighlighted={isHighlighted}
            index={index}
            suggestion={suggestion}
            downShiftItemProps={getItemProps}
            isDisabled={disabled}
            disabledReasonText={disabledReasonText}
            handleSuggestionItemClick={() =>
              handleSuggestionItemClick({
                openMenu,
                getItemProps,
                index,
                suggestion,
                reset
              })
            }
          >
            {renderSuggestionItem && renderSuggestionItem(suggestion)}
          </SuggestionsItem>
        );
      })}
    </SuggestionsList>
  );
});

export default SuggestionsContainer;
