// React import
import React from 'react';
// components
import { Fade } from '@material-ui/core';
import { CircularProgress } from '../../Progress/CircularProgress';

import { Typography } from '../../typography/Typography';

interface ComposeLoaderProps {
  message?: string;
  delay?: number;
  fade?: number;
}

const ModuleLoader = (props: ComposeLoaderProps) => {
  const { message = 'loading', delay = 1000, fade = 800 } = props;
  const [showFallback, setShowFallback] = React.useState(false);

  React.useEffect(() => {
    const t = setTimeout(() => setShowFallback(true), delay);
    return () => clearTimeout(t);
  }, [delay]);

  return (
    <div
      style={{
        display: 'grid',
        opacity: 1,
        flexGrow: 1,
        alignItems: 'center',
        justifyItems: 'center',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
        width: '100%',
        height: '100%'
      }}
    >
      <Fade in={showFallback} timeout={fade}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            typographyStyle="heading4"
            color="contrast2"
            typographyProps={{ gutterBottom: true }}
          >
            <CircularProgress size={9} thickness={8} variant="indeterminate" />
            {' ' + message}
          </Typography>
        </div>
      </Fade>
    </div>
  );
};

export default ModuleLoader;
