// #region imports
import * as React from 'react';

// HOCS
import { useModalActions } from '../../../layout/Modals/modalActions';
import { useHistory } from 'react-router-dom';

// UI
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
// #endregion

/**
 * @summary : Dialog box asking to confirm the removal of a project member
 * @author Simon
 */

// #region interfaces

// use this interface for all the props that you could not inherit from HOC's interfaces

interface Properties {
  modalContext: {
    projectMemberId: string;
    isCurrentAccount?: boolean;
    memberInTeams?: string[];
    isProjectPublic?: boolean;
  };
}
// #endregion

function RemoveProjectMemberModal(props: Properties): JSX.Element {
  const { modalContext } = props;
  const [workspaceId] = useCurrentWorkspace();

  const {
    projectMemberId,
    isCurrentAccount = false,
    memberInTeams,
    isProjectPublic
  } = modalContext;
  const [
    deleteProjectMember,
    deleteProjectMemberStatus
  ] = useDeleteResource__ALPHA({
    cacheInvalidator: React.useCallback(
      r => [
        r,
        { type: 'workspace', id: workspaceId, relationship: 'projects' }
      ],
      [workspaceId]
    )
  });

  const [errorMsg, setErrorMsg] = React.useState<string>(undefined);
  const { previousModal, closeAllModals } = useModalActions();
  const routerHistory = useHistory();

  const removeMember = React.useCallback(() => {
    deleteProjectMember({
      type: 'project-member',
      id: projectMemberId
    });
  }, [deleteProjectMember, projectMemberId]);

  const inAnyTeam = memberInTeams && !!memberInTeams.length;

  React.useEffect(() => {
    if (deleteProjectMemberStatus === statuses.FAILED) {
      setErrorMsg('Something went wrong while trying to perform this action.');
    } else if (deleteProjectMemberStatus === statuses.SUCCESS) {
      /** if user removed itself in these cases, should redirect the user to the project overview page
       * - the project is not public
       * - the user is not a member of the project via teams
       */
      if (isCurrentAccount && !inAnyTeam && !isProjectPublic) {
        routerHistory.push('/workspace/' + workspaceId + '/projects');
        closeAllModals();
      } else {
        // if we removed another project member we should return to the project member page.
        previousModal();
      }
    }
  }, [
    closeAllModals,
    deleteProjectMemberStatus,
    isCurrentAccount,
    previousModal,
    routerHistory,
    workspaceId,
    inAnyTeam,
    isProjectPublic
  ]);

  const loading =
    deleteProjectMemberStatus &&
    deleteProjectMemberStatus !== statuses.FAILED &&
    deleteProjectMemberStatus !== statuses.SUCCESS;

  const notice = (() => {
    const inManyTeams = memberInTeams?.length > 1;
    const inOneTeam = memberInTeams?.length === 1;
    switch (true) {
      // leave project
      case isCurrentAccount && !inAnyTeam:
        return 'Are you sure you want to leave this project? You will no longer have access to this project or be able to modify its contents.';

      case isCurrentAccount && inOneTeam:
        return `Are you sure you want to leave this project? You can still access this project because you're a member of ${memberInTeams[0]} team.`;

      case isCurrentAccount && inManyTeams:
        return "Are you sure you want to leave this project? You can still access this project because you're a member of these teams:";

      // remove member
      case !isCurrentAccount && !inAnyTeam:
        return 'Are you sure you want to remove this member? They will no longer have access to this project or be able to modify its contents.';

      case !isCurrentAccount && inOneTeam:
        return `Are you sure you want to remove this member? They can still access this project because they're a member of ${memberInTeams[0]} team.`;

      case !isCurrentAccount && inManyTeams:
        return "Are you sure you want to remove this member? They can still access this project because they're a member of these teams:";

      default:
        return '';
    }
  })();

  return (
    <ModalNoticeUI
      loading={loading}
      title={isCurrentAccount ? 'Leave Project?' : 'Remove member?'}
      onCloseEvent={() => previousModal()}
      footerRightComponent={
        <>
          <Button
            disabled={loading}
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={() => previousModal()}
          />
          <Button
            disabled={loading || !!errorMsg}
            text={isCurrentAccount ? 'Leave' : 'Remove'}
            colorVariant="filledDanger"
            onClick={removeMember}
          />
        </>
      }
    >
      <Typography>{notice}</Typography>
      {memberInTeams?.length > 1 && (
        <ul style={{ paddingLeft: 16 }}>
          {memberInTeams.map(teamName => (
            <li key={teamName}>
              <Typography>{teamName}</Typography>
            </li>
          ))}
        </ul>
      )}

      {errorMsg && <Typography color={'danger1'}>{errorMsg}</Typography>}
    </ModalNoticeUI>
  );
}

export default RemoveProjectMemberModal;
