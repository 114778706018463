import React from 'react';

import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { compose } from 'redux';
import {
  WithEmailSuggestionsProps,
  withEmailSuggestions
} from '../hooks/useEmailSuggestions';

export interface WithDownloadPermissionsProps {
  canDownloadOriginal: boolean;
  canDownloadPreview: boolean;
}

export type WithResourcesProps = WithEmailSuggestionsProps &
  WithDownloadPermissionsProps;

const requiredPermission = [
  'DOWNLOAD_TYPE_ORIGINAL',
  'DOWNLOAD_TYPE_PREVIEW'
] as Privileges[];
export const withDownloadPermissions = (Wrapped) => (props) => {
  const [canDownloadOriginal, canDownloadPreview] =
    useWorkspacePermissions(requiredPermission);

  return (
    <Wrapped
      {...props}
      loading={props.loading || canDownloadOriginal === undefined}
      canDownloadOriginal={canDownloadOriginal}
      canDownloadPreview={canDownloadPreview}
    />
  );
};

export default compose(withDownloadPermissions, withEmailSuggestions(true));
