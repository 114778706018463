import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actionCreators } from '@cube3/state/src/redux/ducks/metadata-clipboard';

export const useCopy = ({ type, allFormValues }) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(actionCreators.copyAllValues({ type, data: allFormValues }));
  }, [allFormValues, dispatch, type]);
};
