import React, { useMemo } from 'react';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useExportStatus } from '../hooks/useExportStatus';
import { ExportQueueListItemUI } from '@cube3/ui/src/exports/ExportQueueListItemUI';
import { UploadBreadcrumbs } from '../../MediaQueue/prefabs/UploadBreadcrumbs';
import { useTVBroadcastDestination } from '../hooks/useTVBroadcastDestination';
import {
  TVadTargetsStatusRowUI,
  useTargetStatusStyles
} from '@cube3/ui/src/exports/subcomponents/TVadTargetStatusRowUI';
import { getLowerExportState } from '../utils/getLowerState';
import { ExportsParameters } from '@cube3/common/model/schema/resources/export-batch';

interface Props {
  gradient?: 'none' | 'lamp';
  createdAt: string;
  exports: ExportsParameters<'broadcast'>[];
}
/** Queue tile used in status modal, to show more detailed status */
export const TVadExportQueueTile: React.FC<Props> = React.memo((props) => {
  const { gradient, createdAt, exports } = props;

  const { resource: asset } = useResource__ALPHA({
    resourceId: exports?.[0].asset_id,
    resourceType: 'asset'
  });
  const exportWithLowerState = getLowerExportState(exports);

  const { status, label, progress } = useExportStatus({
    attributes: exportWithLowerState
  });

  const title =
    exportWithLowerState?.parameters.broadcast.export_filename ||
    asset?.display_name;

  const subTitle =
    !title || title === asset?.display_name ? undefined : asset?.display_name;

  /** TV station */
  const { destination } = useTVBroadcastDestination();
  const targetsWithName = useMemo(() => {
    if (!exports?.length) return undefined;
    return (
      exports
        .map((t) => ({
          ...t,
          display_name: destination?.find((des) => des.id === t.target_id)
            ?.display_name
        }))
        // sort by display name
        .sort((a, b) => {
          const nameA = a.display_name?.toUpperCase(); // ignore upper and lowercase
          const nameB = b.display_name?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        })
    );
  }, [destination, exports]);

  return (
    <ExportQueueListItemUI
      thumbnails={[asset?.display_image]}
      statusLabel={label}
      status={status}
      statusProgress={progress}
      targetName={'TV ad'}
      created_at={createdAt}
      gradient={gradient}
      title={title}
      path={<UploadBreadcrumbs asset={asset} />}
      subTitle={subTitle}
      multiTargets={<TargetsStatusList targets={targetsWithName} />}
    />
  );
});

export type TargetType = ExportsParameters & {
  display_name: string;
};
interface TargetsProps {
  targets: TargetType[];
}

const TargetsStatusList: React.FC<TargetsProps> = (props) => {
  const { targets } = props;
  const classes = useTargetStatusStyles();
  return (
    <div className={classes.list}>
      {targets?.length
        ? targets.map((t, index) => (
            <TargetStatusRow
              key={t.asset_id + index}
              withBorderBottom={index < targets.length - 1}
              target={t}
            />
          ))
        : null}
    </div>
  );
};

interface TargetStatusRowProps {
  target: TargetType;
  withBorderBottom: boolean;
}

const TargetStatusRow: React.FC<TargetStatusRowProps> = (props) => {
  const { target, withBorderBottom } = props;
  const parsedState = useExportStatus({ attributes: target }, false);
  return (
    <TVadTargetsStatusRowUI
      withBorderBottom={withBorderBottom}
      target={parsedState as any}
    />
  );
};
