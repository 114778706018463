import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
// ui
import ReviewLinkOverviewUI from '@cube3/ui/src/Prefabs/Reviewlink/Modals/ReviewLinkOverviewModalUI';
import { useModalActions } from '../../../layout/Modals/modalActions';
// helper class
import React from 'react';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { useReviewsList } from '../../ReviewTool/Hooks/useReviewsList';

/// <Summary>
/// Functional component that retrieves the review links for a resource (asset etc) and displays them and allows the ability to create a new review link
/// in a UI
/// </Summary>

interface PublicProps {}

type Properties = PublicProps & ModalReceiverProps;

function ReviewLinkOverviewModal(props: Properties): JSX.Element {
  const { openModal, previousModal } = useModalActions();

  const isProject = props.modalContext.type === 'project';

  const project = useResource__ALPHA({
    resourceType: isProject ? 'project' : undefined,
    resourceId: props.modalContext.id
  });

  const id = isProject
    ? project.resource?.relationships.node.id
    : props.modalContext.id;
  const type = isProject ? 'folder' : props.modalContext.type;

  const { reviewLinks, loading, finishLoading } = useReviewsList({ type, id });

  React.useEffect(() => {
    if (finishLoading && reviewLinks?.length === 0) {
      openModal(
        'reviewlink_new',
        {
          type: type,
          id: id,
          first: true
        },
        false
      );
    }
  }, [reviewLinks, finishLoading, openModal, type, id]);

  const [tookLong, setTookLong] = React.useState(false);
  React.useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setTookLong(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [loading, setTookLong]);

  return reviewLinks.length > 0 ? (
    <ReviewLinkOverviewUI
      loading={loading || !id}
      onClickNew={() => openModal('reviewlink_new', { type: type, id: id })}
      onCloseEvent={previousModal}
      reviewLinks={reviewLinks}
    />
  ) : tookLong ? (
    <ModalNoticeUI loading={true}>
      <div style={{ height: 60 }} />
    </ModalNoticeUI>
  ) : null;
}

export { ReviewLinkOverviewModal };
