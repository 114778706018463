import { FormValues } from '../useCopyPasteMetadata';
import { isEmptyValue } from './isEmptyValue';

/** filter out the fields that has no values */
export const fieldsHaveValues = (fields: FormValues) => {
  if (!fields) return {};
  return Object.keys(fields).reduce((acc, k) => {
    if (!isEmptyValue(fields[k])) {
      return { ...acc, [k]: fields[k] };
    }
    return acc;
  }, {});
};
