import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//
import { selectors } from '../ducks/uploads';
import { actionCreators } from '../ducks/uploads';
import { actionCreators as nodeActionCreators } from '../ducks/resource-nodes';
import {
  parseUploadsConfig,
  normalizeUploadsConfig,
  WithUploadsConfig
} from './configHelpers';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';

export interface WithUploadsInjectedProps {
  uploadsForParentResource?: Upload[];
  uploadsInFolder?: Upload[];
  allUploads?: Upload[];
  uploadFilesToFolder(files: File[]): void;
  uploadFilesToParentResource(files: File[]): void;
  clearParentResourceUploads(): void;
  removeUpload(upload: Upload): void;
}

const stp = (config) => (state, ownProps) => {
  const { parentFolderId, parentResource } = parseUploadsConfig(
    config,
    ownProps
  );

  return {
    // we try to grab the resource from our (normalized) redux store
    uploadsForParentResource: selectors.getUploadsByParentResource(
      state,
      parentResource
    ),
    uploadsInFolder: selectors.getUploadsByFolder(state, parentFolderId),
    allUploads: selectors.getAllUploads(state)
  };
};

const dtp = (config) => (dispatch, ownProps) => {
  const { parentFolderId, parentResource } = parseUploadsConfig(
    config,
    ownProps
  );

  const mapping = {
    uploadFilesToFolder: (files) =>
      actionCreators.uploadFilesToFolder(files, parentFolderId),
    uploadFilesToParentResource: (files) =>
      actionCreators.uploadFilesToParentResource(files, parentResource),
    clearParentResourceUploads: () =>
      actionCreators.clearParentResourceUploads(parentResource),
    removeUpload: (upload) =>
      nodeActionCreators.disposeResource('file-upload', upload.id, true)
  };

  return bindActionCreators(mapping, dispatch);
};

const withUploadState = (config) => {
  return connect(stp(config), dtp(config));
};

export const withUploads = (config: WithUploadsConfig) => (Component) => {
  const normalizedConfig = normalizeUploadsConfig(config);

  return withUploadState(normalizedConfig)(Component);
};
