import ChipField from '@cube3/ui/src/chips/Chip';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import MultipleResourcePicker, {
  MultipleResourcePickerGeneralProps
} from './MultipleResourcePickerBase';
import { TeamSugggestionItemRow } from '../../prefabs/TeamSuggestionItemRow';

export const TeamsPickerSuggestionContainer: React.FC<MultipleResourcePickerGeneralProps> = props => {
  const { fields } = props;
  const renderSelectedItem = useCallback(
    (team, index) => {
      return (
        <Field
          type="text"
          component={ChipField}
          props={{
            input: { value: team?.display_name },
            badge: 'Team',
            maxWidth: 320,
            removeItem: () => {
              fields.remove(index);
            }
          }}
        />
      );
    },
    [fields]
  );

  const renderSuggestionItem = useCallback(
    suggestion => (
      <TeamSugggestionItemRow team={suggestion} style={{ marginRight: 8 }} />
    ),
    []
  );

  return (
    <MultipleResourcePicker
      {...props}
      fieldName={'teamSearchInput'}
      renderSuggestionItem={renderSuggestionItem}
      renderSelectedItem={renderSelectedItem}
    />
  );
};
