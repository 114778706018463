import { ReactComponent as Test } from '../collection/test_icon.svg';
import { ReactComponent as Refresh } from '../collection/refresh.svg';
import { ReactComponent as MediaQueue } from '../collection/media_queue.svg';
import { ReactComponent as Tag } from '../collection/tag.svg';
import { ReactComponent as QuickNav } from '../collection/quickNav1.svg';
import { ReactComponent as YouTube } from '../collection/youtube.svg';
import { ReactComponent as Jigsaw } from '../collection/Jigsaw.svg';
import { ReactComponent as ProgressDone } from '../collection/progress_done.svg';
import { ReactComponent as ProgressZero } from '../collection/progress_zero.svg';
import { ReactComponent as ProgressOneThird } from '../collection/progress_one_third.svg';
import { ReactComponent as ProgressTwoThirds } from '../collection/progress_two_thirds.svg';
import { ReactComponent as ProgressError } from '../collection/progress_error.svg';
import { ReactComponent as IconWarn } from '../collection/iconWarn.svg';
import { ReactComponent as IconChatBubble } from '../collection/iconChatBubble.svg';
import { ReactComponent as IconSchedule } from '../collection/iconSchedule.svg';
import { ReactComponent as IconEraser } from '../collection/iconEraser.svg';
import { ReactComponent as IconEraserFill } from '../collection/iconEraserFill.svg';
import { ReactComponent as IconRedo } from '../collection/iconRedo.svg';
import { ReactComponent as IconRemove } from '../collection/iconRemove.svg';
import { ReactComponent as IconGesture } from '../collection/iconGesture.svg';
import { ReactComponent as IconEditUnFilled } from '../collection/iconEditUnFilled.svg';

export const iconFiles = {
  test: Test,
  refresh: Refresh,
  mediaQueue: MediaQueue,
  tag: Tag,
  quickNav: QuickNav,
  youtube: YouTube,
  jigsaw: Jigsaw,
  iconWarn: IconWarn,
  // progress badges
  progressDone: ProgressDone,
  progressZero: ProgressZero,
  progressOneThird: ProgressOneThird,
  progressTwoThirds: ProgressTwoThirds,
  progressError: ProgressError,
  //
  iconChatBubble: IconChatBubble,
  iconSchedule: IconSchedule,
  iconRemove: IconRemove,
  iconGesture: IconGesture,
  iconEditUnFilled: IconEditUnFilled,
  iconEraserFill: IconEraserFill,
  iconEraser: IconEraser,
  iconRedo: IconRedo
} as const;
