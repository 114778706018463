import * as React from 'react';

/* UI */
import { ModalNoticeUI } from '../../Modal/ModalNoticeUI';
import Button from '../../Buttons/Button';
import { Typography } from '../../typography/Typography';

/**
 * @summary Confirmation dialog which asks used if it wants to discard unsaved changes
 * @author Simon
 */

interface Properties {
  onCancel: () => void;
  onClose: () => void;
}

const ConfirmDiscardUnsavedMetaDataModalUI: React.FC<Properties> = ( props ) => {
  const { onCancel, onClose } = props;

  return (
    <ModalNoticeUI
      title={'Discard changes?'}
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost2'}
            text={'Cancel'}
            onClick={() => onCancel()}
          />

          <Button
            text={'Discard'}
            colorVariant="filledDanger"
            onClick={() => onClose()}
          />
        </>
      }
    >
      <Typography>Your changes will not be saved.</Typography>
    </ModalNoticeUI>
  );
};

export default ConfirmDiscardUnsavedMetaDataModalUI;
