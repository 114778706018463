import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import React, { useMemo } from 'react';

export const withJoinAccountsUsers = Wrapped => {
  // we currently don't use the user resources directly, but need them to be in the redux store
  return ({ accounts, ...props }) => {
    const userIds = useMemo(
      () =>
        accounts &&
        accounts.map((a, idx) => ({
          id: a.relationships.user.id || a.relationships.user.data.id,
          type: 'user'
        })),
      [accounts]
    );

    const users = useResource__ALPHA({ resourceIdentifiers: userIds });

    return (
      <Wrapped
        key="invite-form"
        {...props}
        // users={users.resources}
        loading={!users.resources || users.loading}
      />
    );
  };
};
