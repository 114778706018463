import React from 'react';
import GenericFormField, {
  GenericFormFieldProps,
  GenericFormFieldRow
} from '../forms/GenericFormField';
import { WithStyles, withStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: 16,
    marginRight: 20
  },
  labelContainer: {},
  label: {
    fontFamily: theme.typography.fontFamily,
    ...theme.typographyStyles.body2,
    color: theme.customPalette.primary.textWhiteMediumEmphasis,
    width: 200,
    marginRight: 48,
    textAlign: 'right' as 'right',
    marginTop: 10
  },
  inputContainer: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  input: {
    ...theme.typographyStyles.body2,
    color: theme.customPalette.primary.textWhiteHighEmphasis,
    backgroundColor: 'rgba(0, 0, 0, 0.24)',
    width: '100%',
    height: 48,
    border: `1px solid ${theme.customPalette.primary.lineWhiteMediumEmphasis}`,
    borderRadius: 8,
    textIndent: 5,
    '&:focus': {
      outline: 'unset'
    }
  },
  error: {
    marginTop: 10,
    fontFamily: theme.typography.fontFamily,
    color: theme.customPalette.dangerError
  },
  warning: {
    marginTop: 10,
    fontFamily: theme.typography.fontFamily,
    color: theme.customPalette.text.onBase.warning
  },
  required: {
    marginTop: 0,
    ...theme.typographyStyles.body3,
    color: theme.customPalette.dangerError
  },
  requiredValid: {
    color: theme.customPalette.success
  }
});

type MetadataFormFieldProps = GenericFormFieldProps & WithStyles<typeof styles>;

const MetadataFormFieldBase: React.ComponentType<MetadataFormFieldProps> = ({
  classes,
  ...props
}) => {
  return <GenericFormField genericFormFieldStyles={classes} {...props} />;
};

const MetadataFormFieldRowBase: React.ComponentType<MetadataFormFieldProps> = ({
  classes,
  ...props
}) => {
  return (
    <GenericFormFieldRow
      genericFormFieldStyles={classes}
      required={props.required}
      {...props}
    />
  );
};

export const MetadataFormFieldRow = withStyles(styles)(
  MetadataFormFieldRowBase
);

export const MetadataFormField = withStyles(styles)(MetadataFormFieldBase);
