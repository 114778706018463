/** Maps over the activities and puts each activity to their own date group */
import {
  getIfThisWeek,
  getIfLastWeek,
  getIfThisMonth,
  getIfLastMonth,
  getIfThisYear,
  getIfLastYear
} from '@cube3/common/utils/makeDateString';

import { Activity } from '@cube3/common/model/schema/resources/activities';

export const mapActivitiesToDateGroups = (activities: Activity[]) => {
  const thisWeekArray: Activity[] = [];
  const lastWeekArray: Activity[] = [];
  const thisMonthArray: Activity[] = [];
  const lastMonthArray: Activity[] = [];
  const earlierThisYearArray: Activity[] = [];
  const lastYearArray: Activity[] = [];
  const longAgoArray: Activity[] = [];

  // the activities are already sorted on date by the api. all we have to do is go over them and put them
  // in the correct arrays
  activities &&
    activities.forEach((item) => {
      const itemDate = new Date(item.created_at);

      const isThisWeek = getIfThisWeek(itemDate);

      if (isThisWeek) {
        thisWeekArray.push(item);
        return;
      }

      const inLastWeek = getIfLastWeek(itemDate);

      if (inLastWeek) {
        lastWeekArray.push(item);
        return;
      }

      const inThisMonth = getIfThisMonth(itemDate);

      if (inThisMonth) {
        thisMonthArray.push(item);
        return;
      }

      const inLastMonth = getIfLastMonth(itemDate);

      if (inLastMonth) {
        lastMonthArray.push(item);
        return;
      }

      const earlierThisYear = getIfThisYear(itemDate);

      if (earlierThisYear) {
        earlierThisYearArray.push(item);
        return;
      }

      const inLastYear = getIfLastYear(itemDate);

      if (inLastYear) {
        lastYearArray.push(item);
        return;
      }

      // everything older.
      longAgoArray.push(item);
    });

  return {
    thisWeekArray,
    lastWeekArray,
    thisMonthArray,
    lastMonthArray,
    earlierThisYearArray,
    lastYearArray,
    longAgoArray
  };
};
