import React from 'react';

import { Field, WrappedFieldArrayProps, getFormValues } from 'redux-form';

import { makeValidator } from '../../../../../forms/helpers/validators';

// UI
import SuggestionsContainer from '@cube3/ui/src/inputChipPicker/SuggestionsContainer';
import { ResourceSuggestionItem } from '@cube3/ui/src/inputChipPicker/accountPicker/ResourceSuggestionItem';
import WrappedSuggestionsInputField from '@cube3/ui/src/forms/WrappedSuggestionsInputField';
import {
  PublicResource,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { ResourceChip } from './ResourceChip';

import { ClearOn } from '@cube3/ui/src/inputChipPicker/SuggestionsContainer/SuggestionsContainer';

// allow empty input or valid input
const inputValidator = makeValidator([
  (value, all, props, errors) => undefined
]);

export type SuggestionResource = PublicResource;

export interface Props extends WrappedFieldArrayProps<ResourceIdentifier> {
  clearInput(): void;
  form: string;
  controlledInputValue?: string;
  suggestedResources?: SuggestionResource[];
  excludedResources?: SuggestionResource[];
  lockedResources?: SuggestionResource[];
  placeholder?: string;
  excludedReasonText?: string;
  suggestionsLoading?: boolean;
  showDisabled?: boolean;
  clearOn?: ClearOn;
}

const emptyArray: ResourceIdentifier[] = [];

const itemToString = (i) => (i ? `${i.type}-${i.id}` : '');

const isSelected = (suggestion, selected) =>
  selected.filter((s) => s.type === suggestion.type && s.id === suggestion.id)
    .length > 0;

const isLocked = (suggestion, locked) =>
  locked.filter((s) => s.type === suggestion.type && s.id === suggestion.id)
    .length > 0;

export const MultipleResourcePicker: React.FunctionComponent<Props> = (
  props
) => {
  const {
    clearInput,
    form,
    fields,
    suggestedResources = emptyArray,
    controlledInputValue,
    excludedResources = emptyArray,
    lockedResources = emptyArray,
    placeholder = `Start typing for suggestions...`,
    excludedReasonText = 'Already selected',
    suggestionsLoading,
    showDisabled = false,
    clearOn
  } = props;

  const selectedSuggestions = fields.getAll() || emptyArray;
  const excludedSuggestions = excludedResources;

  const inputValues = useTypedSelector((state) => {
    return (
      getFormValues(form)(state) &&
      getFormValues(form)(state)[`${fields.name}_inputValue`]
    );
  });

  return (
    <SuggestionsContainer
      inputValue={controlledInputValue || inputValues}
      allSuggestions={suggestedResources}
      suggestionsLoading={suggestionsLoading}
      selectedSuggestions={selectedSuggestions}
      excludedSuggestions={excludedSuggestions}
      excludedReasonText={excludedReasonText}
      showDisabled={!!(controlledInputValue || inputValues) || showDisabled}
      isSelectedFn={isSelected}
      keysListenersArrayToAddNewSuggestions={['Tab', ',', ';', 'Enter']}
      addItemToSelectionArray={(item) =>
        fields.push({ id: item.id, type: item.type })
      }
      removeItemFromSelectionArray={(index) => fields.remove(index)}
      allowAddingOfNewSuggestions={false}
      newSuggestionPrimaryKey={'id'}
      clearInputField={clearInput}
      clearOn={clearOn}
      itemToString={itemToString}
      lockedResources={lockedResources}
      renderSelectedItem={(selectedSuggestion, index) => {
        return (
          <Field
            key={itemToString(selectedSuggestion)}
            name={itemToString(selectedSuggestion)}
            type="text"
            component={ResourceChip}
            removeItem={() => {
              fields.remove(index);
            }}
            index={index}
            props={{
              resource: {
                id: selectedSuggestion.id,
                type: selectedSuggestion.type
              },
              disabled: isLocked(selectedSuggestion, lockedResources)
            }}
          />
        );
      }}
      renderInputChipPicker={(
        handleInputKeyDown,
        selectHighlightedItem,
        highlightedIndex,
        isOpen,
        reset,
        inputValue,
        inputField,
        getInputProps,
        filteredSuggestions,
        setHighlightedIndex,
        openMenu,
        closeMenu
      ) => (
        <Field
          name={`${fields.name}_inputValue`}
          type="inputValue"
          validate={inputValidator}
          component={WrappedSuggestionsInputField}
          props={{
            handleInputKeyDown,
            selectHighlightedItem,
            highlightedIndex,
            isOpen,
            reset,
            inputValue,
            inputField,
            getInputProps,
            filteredSuggestions,
            inputFieldPlaceholder:
              inputValue || selectedSuggestions.length > 0 ? '' : placeholder,
            setHighlightedIndex,
            openMenu,
            closeMenu
          }}
        />
      )}
      renderSuggestionItem={(suggestion) => (
        <ResourceSuggestionItem
          resource={suggestion}
          primaryText={suggestion.display_name}
          // secondaryText={suggestion.id}
          imageUrl={suggestion.profile_picture || suggestion.display_image}
          imageType={suggestion.image_type}
        />
      )}
    />
  );
};
