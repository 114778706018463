import React, { useCallback, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Button from '../../Buttons/Button';
import CheckedRoundedCheckbox from '../../icons/CheckedRoundedCheckbox';
import { CircularProgress } from '../../Progress/CircularProgress';
import { Typography } from '../../typography/Typography';
import SupportEmailComponent from '../../Email/SupportEmailComponent';
import { iconClosePath } from '../../icons/GeneralSVG';
import { iconPaste } from '../../icons/GeneralSVG';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    container: {
      paddingLeft: '32px',
      paddingTop: '8px'
    },
    list: { paddingLeft: '16px' },
    icon: {
      marginRight: '10px'
    },
    warningIcon: {
      marginRight: '10px',
      display: 'inline-block',
      color: theme.customPalette.text.onBrand.contrast1,
      background: theme.customPalette.text.onBase.warning,
      width: 20,
      height: 20,
      borderRadius: '20px',
      textAlign: 'center'
    }
  })
);

export const Checking = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.icon}>
        <CircularProgress style={{ marginLeft: 12 }} size={24} />
      </span>
      <Typography color="brand1">Checking...</Typography>
    </div>
  );
};

export const CheckPassed = ({ text = '' }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.icon}>
        <CheckedRoundedCheckbox
          fillCheckMark="black"
          renderCircle={true}
          fillCircle="#4DFFB4"
        />
      </span>

      {text && <Typography color="success">{text}</Typography>}
    </div>
  );
};

export const CheckPassedWithWarnings = ({ warnings, onCopy }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <span className={classes.warningIcon}>!</span>
        <Typography color="warning">Check passed with warnings</Typography>
      </div>
      <div className={classes.container}>
        <Typography color="contrast2">
          This asset passed the quality check with the following warnings:
        </Typography>
        <ul className={classes.list}>
          {warnings.map((txt) => (
            <li key={txt}>
              <Typography color="contrast2">{txt}</Typography>
            </li>
          ))}
        </ul>

        <Typography color="warning">
          Export can proceed with warnings, but some stations might still reject
          the asset.
        </Typography>
      </div>

      {!!warnings.length && (
        <>
          <div style={{ height: 16 }}></div>
          <CopyButton onCopy={onCopy} />
        </>
      )}
    </>
  );
};

const emptyArray = [];
export const CheckFailed = ({
  errors = emptyArray,
  serverError,
  warnings = emptyArray,
  onCopy = undefined
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <span
          style={{
            marginRight: '10px'
          }}
        >
          <CheckedRoundedCheckbox
            fillCheckMark="#000000"
            renderCircle={true}
            fillCircle="#F9496C"
            path={iconClosePath}
          />
        </span>
        <Typography color="danger1">Check failed</Typography>
      </div>
      <div className={classes.container}>
        {(!!errors.length || !!warnings.length) && (
          <>
            <Typography color="contrast2">
              This asset did not pass the quality check for the following
              reasons:
            </Typography>
            <ul className={classes.list}>
              {errors.map((txt) => (
                <li key={txt}>
                  <Typography color="contrast2">{txt}</Typography>
                </li>
              ))}
            </ul>

            {!serverError && (
              <Typography color="danger1">
                Fix these errors, upload a new file, and try exporting again.
              </Typography>
            )}

            {serverError && !!errors.length && (
              <>
                <Typography color="danger1">
                  Could not run all quality checks. Please fix reported errors,
                  upload a new file and try exporting again.
                </Typography>
                <Typography color="contrast2">
                  If problems persist, contact support at{' '}
                  <SupportEmailComponent />
                </Typography>
              </>
            )}

            {!!warnings.length && (
              <>
                <div style={{ height: 16 }}></div>
                <Typography color="contrast2">Additional warnings:</Typography>
                <ul className={classes.list}>
                  {warnings.map((txt) => (
                    <li key={txt}>
                      <Typography color="contrast3">Warning: {txt}</Typography>
                    </li>
                  ))}
                </ul>

                <Typography color="warning">
                  Export can proceed with warnings, but some stations might
                  still reject the asset.
                </Typography>
              </>
            )}
          </>
        )}
        {serverError && !errors.length && (
          <Typography color="danger1">
            Failed to start quality check, please try again later or contact
            support at <SupportEmailComponent />
          </Typography>
        )}
      </div>

      {(!!errors.length || !!warnings.length) && (
        <>
          <div style={{ height: 16 }}></div>
          <CopyButton onCopy={onCopy} />
        </>
      )}
    </>
  );
};

const CopyButton = (props) => {
  const { onCopy } = props;
  const [textCopy, setTextCopy] = useState('Copy');
  const handleCopy = useCallback(() => {
    onCopy && onCopy();
    setTextCopy('Copied');
  }, [onCopy]);

  useEffect(() => {
    if (textCopy !== 'Copy') {
      const timer = setTimeout(() => setTextCopy('Copy'), 1200);
      return () => clearTimeout(timer);
    }
  }, [textCopy]);

  return (
    <Button
      pathLeftSvg={iconPaste}
      text={textCopy}
      colorVariant={'ghost1'}
      onClick={handleCopy}
    />
  );
};
export const CopyButtonComponent = CopyButton;
