import * as React from 'react';
import Surface from '../Surface/Surface';
import { Typography } from '../typography/Typography';

/*    
STYLING props:

titleStyles: styles for the title.
formContainerStyles: styles for the t.
*/

export interface GenericReduxFormProps extends React.ComponentProps<'form'> {
  title?: string;
  titleStyles?: string;
  formContainerStyles?: string;
}

export const GenericReduxFormUI: React.FC<GenericReduxFormProps> = props => {
  const { title, titleStyles, formContainerStyles, children, ...rest } = props;

  // TODO: add Typography component

  return (
    <div
      className={formContainerStyles}
      style={{ marginRight: 24, marginBottom: 36 }}
    >
      <Surface
        border={true}
        cornerRadius="mediumEmphasis"
        corners="roundedTop"
        style={{ width: '100%' }}
        background="transparent"
      >
        <div style={{ paddingTop: 16, paddingLeft: 16 }}>
          <Typography typographyStyle={'heading1'} className={titleStyles}>
            {title}
          </Typography>
        </div>
      </Surface>
      <Surface
        border={'appended'}
        cornerRadius="lowEmphasis"
        corners="roundedBottom"
        background="transparent"
        style={{ width: '100%' }}
      >
        <div style={{ paddingTop: 16, paddingLeft: 16 }}>
          <form {...rest}>{children}</form>
        </div>
      </Surface>
    </div>
  );
};

export default GenericReduxFormUI;
