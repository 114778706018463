import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { actionCreators } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCommentResolution } from '../utils/getCommentResolution';

const debug = false;
const params = { page: { size: -1 } };
/**
 *
 * @param nodeId
 * @param withPolling : default to false, if true will poll comments every 5 seconds
 * @returns
 */
export const usePollReviewComments = (nodeId: string, withPolling = false) => {
  const dispatch = useDispatch();

  const { resources: comments } = useResourceList__ALPHA({
    resourceType: 'content-tree-node',
    resourceId: nodeId,
    edgeType: 'comment',
    edgeLabel: 'comments',
    params
  });

  const getReplies = useCallback(
    (id: string) => {
      return comments?.filter((c) => c.relationships.thread?.id === id) || [];
    },
    [comments]
  );

  const threads = useMemo(() => {
    return comments?.filter((res) => !res.relationships.thread);
  }, [comments]);

  const resolvedComments = useMemo(() => {
    if (!threads?.length) return [];
    const resolved = [];
    threads.forEach((item) => {
      const replies = getReplies(item.id);
      const isResolved = getCommentResolution(replies) === 'resolve';
      if (isResolved) {
        resolved.push(item);
      }
    });

    return resolved;
  }, [getReplies, threads]);

  const unresolvedComments = useMemo(() => {
    if (!threads?.length) return [];
    const result = [];
    threads.forEach((item) => {
      const replies = getReplies(item.id);
      if (getCommentResolution(replies) !== 'resolve') {
        result.push(item);
      }
    });
    return result;
  }, [getReplies, threads]);

  // perform some polling on the comments to keep them up to date
  const [polling, setPolling] = useState(0);
  useEffect(() => {
    if (!nodeId || !withPolling || debug) return;
    const timer = setTimeout(() => {
      dispatch(
        actionCreators.invalidateRelationship({
          type: 'content-tree-node',
          id: nodeId,
          relationship: 'comments'
        })
      );
      setPolling((prev) => prev + 1);
    }, 10000);
    return () => clearTimeout(timer);
  }, [nodeId, dispatch, polling, withPolling]);

  return {
    /** contains all `comment` resources, contains replies */
    comments,
    /** exclude replies */
    threads,
    resolvedComments,
    unresolvedComments,
    getReplies
  };
};
