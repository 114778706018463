import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo
} from 'react';
import { ScrollParentContext } from '../../Scrollbar/Scrollbar';

/**
 *  logic is divided into 3 units
 *
 *  - a hook for use in navigation components that gets current state and clickhandlers to connect
 *  - a hook for use in the scrolling area that does all the tracking of refs and their position and deals with user interaction
 *  - a sectionScrollingContext that allow the units above, to share information
 *
 */

interface UseSectionScrollingNavResponse {
  /* id of the active section */
  activeSection: string;
  targetSection: string;
  /* click handler for section nav items */
  handleClick(id: string): void;
}

interface UseSectionScrollingAreaConfig {
  /* list of sections to tack and create refCallbacks for */
  sections: [{ id: string }];
}

interface UseSectionScrollingAreaResponse {
  /* id of the active section */
  activeSection: string;
  /* object where each key maps to a section id and has a ref callback as value */
  refs: { [key: string]: (node: HTMLElement) => void };
}

/* used to create some threshold values the intersectionObserver looks for */
const buildThresholdList = (steps = 20) => {
  let thresholds = [];
  let numSteps = steps;

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};

/* increase this value to evaluate more often (if you see glitches with large sections heights )*/
const thresholdList = buildThresholdList(20);

const sectionScrollingContext = React.createContext({
  handleClick: null,
  setHandleClick: null,
  activeSection: null,
  setActiveSection: null,
  targetSection: null,
  setTargetSection: null
});

/* Overarching context that needs to be shared between nav area and scroll area */
export const SectionScrollingProvider = ({ children }) => {
  const [handleClick, setHandleClick] = useState<(id: string) => void>(null);
  /* section deemed active based on scrollposition */
  const [activeSection, setActiveSection] = useState<string>(null);
  /* section deemed active by user clicking on it's nav item, used as override when rendering */
  const [targetSection, setTargetSection] = useState<string>(null);

  /**
   * Smooth scrollTo animations can be interupted,
   * so we clear the targetSection after a timeout.
   * This prevents it from locking the override in a wrong state.
   */
  const timeout = useRef<ReturnType<typeof setTimeout>>(null);
  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  /* wrapped in a useCallback to prevent dep cycle triggering infinite loop */
  const handleSetTargetSection = useCallback(
    id => {
      setTargetSection(id);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => setTargetSection(null), 1000);
    },
    [setTargetSection]
  );

  const context = useMemo(() => {
    return {
      handleClick,
      setHandleClick: handler => {
        /** workaround: useState setter interprets a function as value,
         *  as something to be called to calc new state
         */
        setHandleClick(() => handler);
      },
      activeSection,
      setActiveSection,
      targetSection,
      setTargetSection: handleSetTargetSection
    };
  }, [
    activeSection,
    setActiveSection,
    handleClick,
    setHandleClick,
    targetSection,
    handleSetTargetSection
  ]);

  return (
    <sectionScrollingContext.Provider value={context} children={children} />
  );
};

/* Hook used by the navigation sidebar */
export function useSectionScrollingNav(): UseSectionScrollingNavResponse {
  const { activeSection, targetSection, handleClick } = useContext(
    sectionScrollingContext
  );

  return useMemo(() => {
    return { activeSection, targetSection, handleClick };
  }, [activeSection, handleClick, targetSection]);
}

/**
 * Hook used by the scrolling area containing the sections
 * This should be called from a component that is nested inside the (custom) Scrollbar component
 * TODO: see if the scrollparent context can be factored out
 */
export function useSectionScrollingArea(
  config: UseSectionScrollingAreaConfig
): UseSectionScrollingAreaResponse {
  const {
    activeSection,
    setActiveSection,
    targetSection,
    setTargetSection,
    handleClick,
    setHandleClick
  } = useContext(sectionScrollingContext);

  const { sections } = config;

  /* get the current Scrollbars component that is the closest scrollparent  */
  const scrollParents = useContext(ScrollParentContext);
  /* used in ref callback for section elements */
  const observer = useRef<IntersectionObserver>();
  /* id indexed section element refs */
  const itemRefs = useRef<{ [key: string]: HTMLElement }>({});
  /**
   * id indexed section ratios, used because we calculate a ratio
   * based on how far an element has been scrolled into view
   */
  const ratioRefs = useRef<{ [key: string]: number }>({});

  /* we track these as refs as well so we don't have to recreate the observer every time */
  const activeSectionRef = useRef<string>(activeSection);
  const targetSectionRef = useRef<string>(targetSection);
  const setActiveSectionRef = useRef<(id: string) => void>(setActiveSection);
  activeSectionRef.current = activeSection;
  targetSectionRef.current = targetSection;
  setActiveSectionRef.current = setActiveSection;

  const scrollParent = scrollParents[0]?.view;
  /* clickhandler that scrolls the scrollparent and (temporarily) sets a targetSection */
  const clickHandler = useCallback(
    id => {
      if (scrollParent && itemRefs.current[id]) {
        setTargetSection(id);

        let top = itemRefs.current[id].offsetTop;
        top = top < 64 ? 0 : top;

        scrollParent.scrollTo({
          top,
          behavior: 'smooth'
        });
        // itemRefs.current[id].scrollTo();
      }
    },
    [scrollParent, setTargetSection]
  );

  /* push the clickhandler to the context provider*/
  if (clickHandler && handleClick !== clickHandler) {
    setHandleClick(clickHandler);
  }

  /**
   * core hook that is only rerun if the scrollparent changes.
   *
   * Here we create the intersectionObserver
   * and handle the intersection events.
   *
   * Because intersectionObserver runs in the background and has low precision,
   * we merge the data from multiple events into a cache, to determine which
   * of the sections can be deemed active.
   *
   */
  useEffect(() => {
    if (!scrollParent) {
      return;
    }

    const observerConfig = {
      root: scrollParent,
      threshold: thresholdList,
      rootMargin: '0px 0px 0px 0px'
    };

    /* callback for intersectionObserver */
    const handleIntersection = function(entries) {
      /**
       *  here we link the section ids  to the intersection entries
       *  and calculate the custom ratio based on how far the top of a
       *  section has been scrolled into view
       */
      const extendedEntries = entries.map(entry => {
        const rootRatio =
          (entry.rootBounds.bottom - entry.boundingClientRect.top) /
          entry.rootBounds.height;
        let id;

        for (let key in itemRefs.current) {
          if (itemRefs.current.hasOwnProperty(key)) {
            if (entry.target === itemRefs.current[key]) {
              id = key;
            }
          }
        }

        return {
          entry,
          rootRatio,
          id
        };
      });

      /**
       *  now we filter out duplicate entries for the same section
       *  and only look at the lates one
       */
      extendedEntries
        /* ignore entries completely out of view (TODO: check if this causes cache errors)*/
        .filter(({ entry }) => entry.isIntersecting)
        /* get the latest first */
        .sort((a, b) => {
          return b.entry.time - a.entry.time;
        })
        /* filter out any but the first entry a specific section id */
        .filter(({ id }, idx, arr) => {
          let latest = true;
          arr.forEach(({ id: qId }, qIdx) => {
            if (qId === id) {
              if (qIdx < idx) {
                latest = false;
              }
            }
          });
          return latest;
        })
        /* store the ratios in our cache, so we can combine the latest */
        .forEach(({ id, rootRatio }) => {
          ratioRefs.current[id] = rootRatio;
        });

      /**
       * To make sure small sections at he end of the scrollable range,
       * can still become active, we slide the threshold ratio down as we reach
       * the end of the range
       */
      const scrollBottom = scrollParent.scrollTop + scrollParent.clientHeight;
      const ignoreHeight =
        scrollParent.scrollHeight - scrollParent.clientHeight;
      const factor =
        Math.max(0, scrollBottom - ignoreHeight) / scrollParent.clientHeight;
      const threshold = 0.7 - factor * 0.7;

      /**
       *  We go over the sections we have ratios for in the cache
       *  and pick the one that is over, but closest to, the threshold
       */
      let currentBest;
      let fallback;
      for (let key in ratioRefs.current) {
        if (ratioRefs.current.hasOwnProperty(key)) {
          const r = ratioRefs.current[key];
          if (r > threshold && (r <= 1.0 || !activeSectionRef.current)) {
            if (!currentBest || r < currentBest.r) {
              currentBest = { id: key, r };
            }
          }
          if (r > threshold) {
            if (!fallback || r < fallback.r) {
              fallback = { id: key, r };
            }
          }
        }
      }

      currentBest = currentBest || fallback;

      /* Update the activeSection in the context provider */
      if (currentBest && activeSectionRef.current !== currentBest.id) {
        if (setActiveSectionRef.current) {
          setActiveSectionRef.current(currentBest.id);
        }
        /**
         * if this was a programmatic scroll and we've reached the
         * target, we clear the target override
         */
        if (currentBest.id === targetSectionRef.current) {
          setTargetSection(null);
        }
      }
    };

    /* create the observer */
    observer.current = new IntersectionObserver(
      handleIntersection,
      observerConfig
    );

    /* start watching section refs */
    for (let key in itemRefs.current) {
      if (itemRefs.current.hasOwnProperty(key)) {
        observer.current.observe(itemRefs.current[key]);
      }
    }
  }, [scrollParent, setTargetSection]);

  /**
   * Build a list of section element refs.
   * If the section is added after the observer has started
   * we also start observing it.
   */
  const onSectionRef = (id, node) => {
    if (node) {
      itemRefs.current[id] = node;
    } else {
      delete itemRefs.current[id];
    }
    if (observer.current && node) {
      observer.current.observe(node);
    }
  };

  /* create a list of section ref callbacks for the list of sections from the config */
  const onSectionCallbacks = useMemo(() => {
    return sections.reduce((acc, val) => {
      acc[val.id] = node => onSectionRef(val.id, node);
      return acc;
    }, {});
  }, [sections]);

  return {
    activeSection: activeSection,
    refs: onSectionCallbacks
  };
}
