import { Related, ResourceBase } from '../../resource-types';
import { ExportTargetImplementations } from '../../types';
import { RawModel } from '../schema';

export interface ExportTargetDefault<
  T extends keyof ExportTargetImplementations = any
> extends ResourceBase {
  id: string;
  type: 'export-target-default';
  attributes: {
    notification_recipients: string[];
    integration_parameters: T extends unknown
      ? any
      : ExportTargetImplementations[T]['intergration_parameters'];
    integration_config: T extends unknown
      ? any
      : ExportTargetImplementations[T]['integration_config'];
  };
  relationships: {
    'export-target-type': Related<'export-target-type'>;
    workspace: Related<'workspace'>;
  };
}

export const exportTargetDefault: RawModel = {
  type: 'export-target-default',
  plural: 'export-target-defaults',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'export-target-defaults'
    },
    export_target_type: {
      type: 'export-target-type',
      binding: ['one', 'many'],
      reverse: 'export-target-defaults'
    }
  }
};
