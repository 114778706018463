import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MaybeTooltip from '../../Tooltip/MaybeTooltip';
import { Typography } from '../../typography/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    questionMark: {
      display: 'inline-block',
      width: 20,
      height: 20,
      borderRadius: '50%',
      background: theme.customPalette.backgroundAccent.whiteAccent2,
      lineHeight: '20px',
      textAlign: 'center'
    }
  })
);

export const QuestionMarkCircle = ({
  title,
  disabled,
  placement = 'bottom-start' as any,
  className = ''
}) => {
  const classes = useStyles();
  return (
    <MaybeTooltip
      disabled={disabled}
      className={[classes.questionMark, className].join(' ')}
      placement={placement}
      title={title}
    >
      <Typography color="contrast3" typographyStyle="heading4">
        ?
      </Typography>
    </MaybeTooltip>
  );
};
