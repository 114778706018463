import * as React from 'react';

/* Import redux */
import { compose } from '../../../../../utils/component-helpers';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

/* Import components */
import ManageAccessModalUI from '@cube3/ui/src/Prefabs/Modals/Sharables/ManageAccessModalUI';

// sharelink interface
import { Share } from '@cube3/common/model/schema/resources/share';
import {
  Reviewlink,
  FileRequest,
  ShareIntentType
} from '@cube3/common/model/resource-types';

// hooks
import { useModalActions } from '../../Modals/modalActions';
import { useAuth } from '@cube3/state/src/redux/components/Hooks/useAuth';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import {
  RequestStatuses,
  statuses
} from '@cube3/state/src/redux/ducks/request-status';

/// <Summary>
/// Modle for manage access for share links.
/// Shows current users for share link and provides ability to
/// re-send an activation link for a share or delete users from
/// a share.
/// </Summary>

const mapStateToProps = (state, ownProps: Properties) => {
  return {
    form: ownProps.modalContext.id
  };
};

interface WithResourceProps {
  resource: Share | Reviewlink | FileRequest;
  mutateResource: Function;
  mutateStatus: RequestStatuses;
  loading?: boolean;
}

interface ModalReceiverProps {
  modalContext: {
    id: string;
    intent: ShareIntentType;
  };
}

type Properties = WithResourceProps & ModalReceiverProps;

const emptyArray = [];
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const ManageAccess: React.FunctionComponent<Properties> = (props) => {
  const { resource, mutateResource, mutateStatus, loading, modalContext } =
    props;
  const { id, intent } = modalContext;
  const { openModal, previousModal } = useModalActions();

  const auth = useAuth({ setPath: noop });

  const resendInvite = React.useCallback(
    (email_address) => {
      mutateResource({
        id,
        type: 'share',
        intent: intent,
        resend_emails: [email_address]
      });
    },
    [mutateResource, id, intent]
  );

  const onClickAddUser = React.useCallback(
    () => openModal('sharelink_invitepeople', { id, intent }),
    [openModal, id, intent]
  );
  const onRemoveAccess = React.useCallback(
    (email_address: string) =>
      openModal(
        'sharelink_removeaccess',
        {
          id,
          intent,
          email_address,
          lastRecipient: resource?.email_addresses?.length === 1,
          node: resource?.relationships.node.id
        },
        false
      ),
    [openModal, id, intent, resource]
  );

  // when the add request is successfull...
  React.useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      // go to the success modal.
      openModal('confirm_link_sent', {
        isResent: true,
        intent
      });
    }
  }, [intent, mutateStatus, openModal]);

  return (
    <ManageAccessModalUI
      loading={loading}
      email_addresses={resource?.email_addresses || emptyArray}
      onCloseEvent={previousModal}
      onClickAddUser={onClickAddUser}
      onRemoveAccess={onRemoveAccess}
      onResendLink={resendInvite}
      authEmailAddress={auth.user.email_address}
    />
  );
};
const config = {
  cacheInvalidator: (r) => [r]
};
const withMutateResource = (Wrapped) => (props: Properties) => {
  const { id } = props.modalContext;
  const resource = useResource__ALPHA({
    resourceId: id,
    resourceType: 'share'
  });

  const [mutateResource, mutateStatus] = useMutateResource__ALPHA(config);

  return (
    <Wrapped
      {...props}
      resource={resource.resource}
      mutateResource={mutateResource}
      mutateStatus={mutateStatus}
      loading={
        !resource.resource ||
        resource.loading ||
        (mutateStatus &&
          mutateStatus !== statuses.SUCCESS &&
          mutateStatus !== statuses.FAILED)
      }
    />
  );
};

export default compose(ManageAccess)(
  withMutateResource,
  connect(mapStateToProps),
  reduxForm({ destroyOnUnmount: false, forceUnregisterOnUnmount: true })
);
