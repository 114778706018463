// #region imports
import * as React from 'react';

// helpers
import { compose } from '../../../../../utils/component-helpers';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';

// UI
import ProjectMetadataUI from '@cube3/ui/src/Projects/ProjectsMetadataUI';
import { MetadataForm } from '@cube3/common/model/schema/resources/metadata-category';

// HOCS
import { withModalActions, ModalActionProps } from '../../Modals/modalActions';
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import withCurrentWorkspace, {
  WorkspaceProps
} from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { MetadataReadOnlySmart } from '../../ContentPane/DetailView/MetaData/MetadataReadOnlySmart';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useCurrentProject } from '@cube3/state/src/redux/components/Hooks/useCurrentProject';
import {
  archivedProjectTooltip,
  readOnlyProjectTooltip
} from '../../CommandBar/factories/tooltip';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';

type Properties = ModalActionProps &
  ModalReceiverProps &
  RouteComponentProps &
  WorkspaceProps &
  ProjectMetadata;

interface ProjectMetadata {
  retrievedFormsStatus: string;
  retrievedForms: MetadataForm[];
}

const requiredPermissions = ['METADATA_WRITE'] as Privileges[];

function ProjectMetadata(props: Properties): JSX.Element {
  const {
    previousModal,
    retrievedForms = [],
    retrievedFormsStatus,
    modalContext,
    openModal
  } = props;

  const [canEditMetadata] = useWorkspacePermissions(requiredPermissions);

  const onEditClick = React.useCallback(() => {
    openModal('metadata_edit', modalContext, false);
  }, [modalContext, openModal]);

  const { isProjectArchived, isProjectRoleReadOnly } = useCurrentProject();

  const disabledReason = (() => {
    switch (true) {
      case !canEditMetadata:
        return 'This account has no permission to edit metadata';
      case isProjectRoleReadOnly:
        return readOnlyProjectTooltip;
      case isProjectArchived:
        return archivedProjectTooltip;
      default:
        return undefined;
    }
  })();

  return (
    <ProjectMetadataUI
      onCloseEvent={previousModal}
      canEdit={canEditMetadata && !isProjectRoleReadOnly && !isProjectArchived}
      disabledReason={disabledReason}
      onEditClick={onEditClick}
      loading={retrievedFormsStatus === statuses.SUCCESS ? false : true}
      metaData={retrievedForms}
      renderMetaDataForms={() => (
        <>
          {retrievedForms &&
            retrievedForms.map((form) => (
              <MetadataReadOnlySmart
                key={form.id}
                metadataFormId={form.id}
                metadataForResource={{
                  id: modalContext.id,
                  type: modalContext.type
                }}
              />
            ))}
        </>
      )}
    />
  );
}

export default compose(ProjectMetadata)(
  withRouter,
  withCurrentWorkspace,
  withModalActions,
  withResourceEdges({
    resourceType: 'project',
    resourceId: (props: Properties) => props.modalContext.id,
    edgeType: 'metadata-category',
    edgeLabel: 'metadata-categories',
    mapper: 'retrievedForms'
  })
);
