import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

/* Import components */
import Time from '../../../human/Time';
import { Typography } from '../../../typography/Typography';
import { iconFileRequest } from '../../../icons/GeneralSVG';
import ContainerChips2 from '../../../chips/ContainerChipsNew';
import IconButton from '../../../Buttons/IconButton';
import UserAvatar from '../../../User/UserAvatar/UserAvatar';
import InteractableSurface from '../../../Surface/InteractableSurface';
import Truncate from '../../../human/Truncate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '5px 0px 0px 0px'
    },
    avatars: {
      marginLeft: '5px',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      height: '20px'
    },
    title: {
      minWidth: '20ex'
    },
    sameline: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        flexShrink: 0,
        '&:not(:first-child)': {
          marginLeft: 8
        }
      }
    },
    contents: {
      margin: '4px'
    },
    avatar: {
      marginRight: '2px'
    },
    dates: {
      marginLeft: 8
    }
  }));

export interface FileRequestUIProps {
  title?: string;
  created_at: string;
  expires_at: string;
  expired: boolean;
  email_addresses: string[];
  onClickFilerequest: () => void;
}

type Properties = FileRequestUIProps;

const FileRequestItemC: React.FC<Properties> = props => {
  const {
    created_at,
    email_addresses = [],
    expires_at,
    expired,
    onClickFilerequest,
    title
  } = props;

  const classes = useStyles();

  // map email addresses to elements for container chips.
  let users: JSX.Element[];

  if (email_addresses && email_addresses.length) {
    users = email_addresses.map(item => {
      const info = {
        email_address: item
      };

      return (
        <UserAvatar
          size={'small'}
          classes={{ root: classes.avatar }}
          key={item}
          userInfo={info}
        />
      );
    });
  }

  let expiredText: string;
  if (expired) {
    expiredText = 'Link expired ';
  }

  if (!expired) {
    expiredText = 'Will expire ';
  }

  if (!expires_at) {
    expiredText = 'Never expires';
  }

  return (
    <InteractableSurface
      classes={{ root: classes.container }}
      surfaceProps={{ corners: 'rounded' }}
      highlightOnHover={true}
      onClickEvent={() => onClickFilerequest()}
    >
      <div className={classes.contents} data-cy={'filerequestUI'}>
        <div className={classes.sameline}>
          <IconButton
            onClick={() => onClickFilerequest()}
            path={iconFileRequest}
            colorVariant={'ghost2'}
            disableHover={true}
          />
          {title && (
            <div className={classes.title}>
              <Typography component="span" color="contrast1">
                <Truncate str={title} toolTip={true} />
              </Typography>
            </div>
          )}

          <div className={classes.avatars}>
            {users && users.length && (
              <ContainerChips2
                renderElements={users}
                resizeContainerWhenContentSmaller={true}
                renderPropsOverFlowChip={amountoverflow => (
                  <UserAvatar
                    classes={{ root: classes.avatar }}
                    size={'small'}
                    textPrimary={`+${amountoverflow.toString()}`}
                    textSecondary={amountoverflow.toString() + ' others'}
                  />
                )}
              />
            )}
          </div>
        </div>
        <div className={classes.dates}>
          <Typography component="span" color="contrast3">
            Created{' '}
            <Time
              date={created_at}
              fullForm={false}
              toolTip={true}
              placement="bottom"
              now={new Date()}
              relativeTime={true}
            />
          </Typography>

          <Typography
            component="span"
            color={expired ? 'danger1' : 'contrast2'}
          >
            {expiredText}

            {expires_at && (
              <Time
                date={expires_at}
                fullForm={false}
                toolTip={true}
                placement="bottom-start"
                now={new Date()}
                relativeTime={true}
              />
            )}
          </Typography>
        </div>
      </div>
    </InteractableSurface>
  );
};

/** File request item as seen in the `FileRequestsOverviewModal` or in `Infopane`  */
const FileRequestUI = FileRequestItemC;
export { FileRequestUI };
