import { Comment } from '@cube3/state/types';
import { MediaPlayer } from '@cube3/ui/src/previews/VideoPreview/MediaPlayer';
import React, { useMemo, useState } from 'react';
import { createContext } from 'react';

interface ReviewToolContextType {
  drawingBarToggled: boolean;
  activeComment: Comment;
  drawnSvg: string;
  player: MediaPlayer; // mediaPlayer class instance
  playing: boolean;
  setPlaying: (playing) => void;
  playerRef: (playerProps) => void;
  setActiveComment: (comment: Comment) => void;
  setDrawnSvg: (svg) => void;
  toggleDrawingBar: (toggle?: boolean) => void;
}
export const ReviewToolContext = createContext<ReviewToolContextType>(null);

export const ReviewToolContextProvider = (props) => {
  const [drawingBarToggled, toggleDrawingBar] = useState(false);
  const [drawnSvg, setDrawnSvg] = useState(undefined);
  const [activeComment, setActiveComment] = useState(undefined);
  const [playerRef, setPlayerRef] = useState(undefined);
  const [playing, setPlaying] = useState(false);

  const context = useMemo(() => {
    return {
      drawingBarToggled,
      drawnSvg,
      activeComment,
      player: playerRef,
      playing,
      setPlaying,
      playerRef: setPlayerRef,
      toggleDrawingBar: (toggle?) =>
        toggleDrawingBar((prev) => (toggle === undefined ? !prev : toggle)),
      setDrawnSvg,
      setActiveComment
    };
  }, [
    drawingBarToggled,
    drawnSvg,
    activeComment,
    playerRef,
    playing,
    setPlaying,
    setPlayerRef,
    toggleDrawingBar,
    setDrawnSvg,
    setActiveComment
  ]);

  return (
    <ReviewToolContext.Provider value={context}>
      {props.children}
    </ReviewToolContext.Provider>
  );
};

export const ReviewAssetViewerContext = createContext<{
  timestamp: number;
  setTimestamp: (time: number) => void;
}>(null);
export const ReviewAssetViewerContextProvider = (props) => {
  const [timestamp, setTimestamp] = useState(0);

  const context = useMemo(() => {
    return {
      timestamp,
      setTimestamp
    };
  }, [timestamp, setTimestamp]);

  return (
    <ReviewAssetViewerContext.Provider value={context}>
      {props.children}
    </ReviewAssetViewerContext.Provider>
  );
};
