import React from 'react';
import { PhoneFeatureContext } from '@cube3/ui/src/Email/context/usePhoneFeatureContext';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { Feature } from '@cube3/common/model/resource-types';

// Provides the components that are outside of the main folder with the value of phoneFeature flag.

export const WorkspacePhoneFeatureProvider = (props) => {
  const { children } = props;

  const REQUIRED_FEATURES = {
    features: ['/WORKSPACE/SUPPORT/PHONE'] as Feature[]
  };

  const [supportPhoneFeature] = useFeatures(REQUIRED_FEATURES);

  return (
    <PhoneFeatureContext.Provider value={supportPhoneFeature}>
      {children}
    </PhoneFeatureContext.Provider>
  );
};
