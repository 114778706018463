import React from 'react';

// types and the HOCstuff
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';

// UI
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';
import { ModalReceiverProps } from '../../../../../../app/layout/Modals/ModalManager';

interface WorkspaceMemberInvitedProps extends ModalReceiverProps {
  modalContext: { workspaceId: string; inviteCount: number };
}

function WorkspaceMemberInvited(
  props: WorkspaceMemberInvitedProps
): JSX.Element {
  const { inviteCount } = props.modalContext;
  const modalActions = useModalActions();

  return (
    <GenericNoticeModalUI
      onClose={() => modalActions.closeAllModals()}
      title={
        inviteCount === 1 ? `Invite has been sent` : `Invites have been sent`
      }
      text="You will be notified when recipients join the workspace."
    />
  );
}

export default WorkspaceMemberInvited;
