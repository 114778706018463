import React, { useCallback, useEffect, useState } from 'react';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import {
  selectors,
  statuses
} from '@cube3/state/src/redux/ducks/request-status';
import { actions } from '@cube3/state/src/redux/ducks/resource-nodes';
import { ExportOptionRow } from '@cube3/ui/src/exports/subcomponents/ExportOptionRow';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { getErrorMessage } from '../../../../../../forms/helpers/errors';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { iconDelete } from '@cube3/ui/src/icons/GeneralSVG';
import { Team } from '@cube3/common/model/schema/resources/team';
import { useTeamSettingsStyles } from '@cube3/ui/src/styles';

interface Props {
  modalContext: {
    team: Team;
    workspaceId: string;
  };
}

const TeamSettingsModal = (props: Props) => {
  const classes = useTeamSettingsStyles();
  const { team, workspaceId } = props.modalContext;
  const { display_name, id } = team;
  const { previousModal, openModal } = useModalActions();
  const [newTeamName, setTeamName] = useState(display_name);
  const [errorState, setError] = useState(undefined);

  const [mutateTeam, mutateTeamStatus] = useMutateResource__ALPHA({
    cacheInvalidator: useCallback(
      () => [
        {
          type: 'workspace',
          id: workspaceId,
          relationships: 'teams'
        }
      ],
      [workspaceId]
    )
  });

  const onClickSave = useCallback(() => {
    mutateTeam({
      type: 'team',
      id: team.id,
      display_name: newTeamName.trim()
    });
  }, [mutateTeam, team.id, newTeamName]);

  useEffect(() => {
    if (mutateTeamStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateTeamStatus, previousModal]);

  const onClickDelete = useCallback(() => {
    openModal('workspace_teams_delete', { team }, false);
  }, [openModal, team]);

  const errors = useTypedSelector((state) =>
    selectors.getErrors(state.requestStatus, {
      type: actions.MUTATE_RESOURCE,
      meta: { apiClient: { type: 'team', id } }
    })
  );

  const submitError = errorState && getErrorMessage(errorState);
  const requiredError = !newTeamName && 'This field is required';
  const errorMsg = requiredError || submitError;

  const onChangeTeamName = useCallback(
    (event) => {
      if (errorMsg) {
        setError(undefined);
      }
      setTeamName(event.target.value);
    },
    [errorMsg]
  );
  useEffect(() => {
    if (mutateTeamStatus === statuses.FAILED) {
      setError(errors && getErrorMessage(errors));
    }
  }, [errors, mutateTeamStatus]);

  const loading =
    mutateTeamStatus &&
    mutateTeamStatus !== statuses.SUCCESS &&
    mutateTeamStatus !== statuses.FAILED;

  const hasNameChanged = !newTeamName || newTeamName.trim() !== display_name;
  return (
    <ModalNoticeUI
      title="Team Settings"
      onCloseEvent={previousModal}
      loading={loading}
      footerRightComponent={
        !hasNameChanged ? (
          <Button text="Done" colorVariant="filled1" onClick={previousModal} />
        ) : (
          <>
            <Button
              text="Cancel"
              colorVariant="ghost2"
              onClick={previousModal}
            />
            <Button
              text="Save changes"
              colorVariant="filled1"
              onClick={onClickSave}
              disabled={!!errorMsg}
            />
          </>
        )
      }
      footerLeftComponent={
        <Button
          pathLeftSvg={iconDelete}
          text="Delete team"
          colorVariant={'danger'}
          onClick={onClickDelete}
        />
      }
    >
      <div style={{ minHeight: 200 }}>
        <ExportOptionRow
          label="Team name"
          value={newTeamName}
          placeholder="Enter a team name"
          type={'text'}
          classes={{
            container: classes.inputBase,
            labelContainer: classes.labelContainer,
            label: classes.inputLabel,
            required: classes.required
          }}
          onChange={onChangeTeamName}
        />
        {!!errorMsg && <Typography color="danger1">{errorMsg}</Typography>}
      </div>
    </ModalNoticeUI>
  );
};

export default TeamSettingsModal;
