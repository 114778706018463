import { useMemo } from 'react';
import { statuses } from '../../../redux/ducks/request-status';
import { useResourceList__ALPHA } from './useResourceList';

interface UsePrivilegeConfig {
  roleId: string;
  requestedPrivs: string[];
}

export const usePrivilege = ({
  roleId,
  requestedPrivs
}: UsePrivilegeConfig) => {
  const { resources: privs, status } = useResourceList__ALPHA({
    strategy: 'prefer-cache',
    resourceType: 'role',
    resourceId: roleId,
    edgeType: 'privilege',
    edgeLabel: 'privileges'
  });

  return useMemo(() => {
    if (!roleId) {
      return requestedPrivs.map(r => false);
    }
    if (status === statuses.SUCCESS) {
      return requestedPrivs.map(r => {
        // if roleId exist at this point, WORKSPACE_READ permision is assumed
        return r === 'WORKSPACE_READ'
          ? true
          : privs?.filter(a => a.key === r).length > 0;
      });
    }

    return requestedPrivs.map(r => undefined);
  }, [privs, status, requestedPrivs, roleId]);
};
