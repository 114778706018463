import React, { useMemo, useRef } from 'react';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export const ExpiresDatePicker = fieldProps => {
  const minDate = useRef(moment().add(1, 'day'));
  const initialFocusedDate = useMemo(() => moment(fieldProps.input.value), [
    fieldProps.input.value
  ]);

  return (
    <C3DatePicker
      {...fieldProps}
      autoOk={true}
      initialFocusedDate={initialFocusedDate}
      minDate={minDate.current}
      inputVariant="outlined"
      fullWidth={true}
      disablePast={true}
      variant="inline"
    />
  );
};

const C3DatePicker = props => {
  const {
    input,
    initialFocusedDate,
    inputVariant,
    autoOk,
    disablePast,
    minDate,
    fullWidth
  } = props;

  const pickerProps = {
    initialFocusedDate,
    inputVariant,
    disablePast,
    autoOk,
    minDate,
    fullWidth
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        key="expiration-date"
        {...pickerProps}
        value={input.value || initialFocusedDate}
        onChange={moment => input.onChange(moment.toDate())}
      />
    </MuiPickersUtilsProvider>
  );
};
