import React, { useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useEditTeamsCheck } from '../hooks/useEditTeamsCheck';
import { useAccountsUser } from '../hooks/useAccountsUser';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { ManageTeamMemberRow } from '@cube3/ui/src/Workspace/ManageTeamMemberRow';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { Team } from '@cube3/common/model/schema/resources/team';
import Scrollbar from '@cube3/ui/src/Scrollbar/Scrollbar';
import { AddMemberComponent } from '@cube3/ui/src/Projects/projectMembers/AddMemberComponent';

interface Props {
  modalContext: {
    team: Team;
    workspaceId: string;
  };
}
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '10px 0'
    },
    addMemberButton: {
      paddingLeft: 8
    },
    addMemberIcon: {
      marginRight: 14
    }
  })
);
export const ManageTeamMembersModal = (props: Props) => {
  const { team, workspaceId } = props.modalContext;
  const { previousModal, openModal } = useModalActions();
  const canEditTeam = useEditTeamsCheck()[1];
  const classes = useStyles();

  const teamAccounts = useResourceList__ALPHA({
    resourceType: 'team',
    resourceId: team?.id,
    edgeType: 'account',
    edgeLabel: 'members'
  });

  const { resources: members, loading } = useAccountsUser(
    teamAccounts.resources
  );

  const onClickAdd = useCallback(() => {
    openModal(
      'workspace_teams_add_members',
      {
        team,
        alreadyMembers: members,
        workspaceId
      },
      false
    );
  }, [members, openModal, team, workspaceId]);

  const onClickRemove = useCallback(
    (member) => {
      return () =>
        openModal(
          'workspace_teams_remove_members',
          { team, member, workspaceId },
          false
        );
    },
    [openModal, team, workspaceId]
  );

  return (
    <ModalNoticeUI
      title="Manage Team Members"
      extraSurfaceProperties={{ style: { minWidth: 600 } }}
      loading={loading || teamAccounts.loading}
      onCloseEvent={previousModal}
      footerRightComponent={
        <Button text="Done" colorVariant="filled1" onClick={previousModal} />
      }
    >
      <AddMemberComponent
        onClick={onClickAdd}
        canEdit={!!canEditTeam}
        disabledReason="Only workspace admins can add members."
        styles={classes}
      />

      {members && members.length > 0 ? (
        <Scrollbar autoHeight={true} autoHeightMin={230}>
          {members.map((member) => (
            <ManageTeamMemberRow
              key={member.id}
              member={member}
              onClick={onClickRemove}
            />
          ))}
        </Scrollbar>
      ) : (
        <Typography typographyStyle={'body2'}>
          No member has been added yet.
        </Typography>
      )}
    </ModalNoticeUI>
  );
};
