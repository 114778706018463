import React from 'react';
import * as Sentry from '@sentry/browser';
import { createConsentSubscriber } from '@cube3/state/src/redux/middleware/cookie-consent-middleware';
import { getServedVersion, getRunningVersion } from './checkServedVersion';
import SupportEmailComponent from '@cube3/ui/src/Email/SupportEmailComponent';

interface ErrorBoundaryProps {
  name?: string;
  extraInfo?: string;
}
interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  info?: {
    componentStack?: string;
  };
  show: boolean;
  eventId: string;
  consent: boolean;
  versionMismatch: boolean;
}

const styles = {
  background: '#353532',
  color: 'rgba(255, 255, 255, 0.64)',
  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  // maxHeight: '40em',
  minHeight: '25em',
  // maxWidth: '40em',
  minWidth: '25em',
  fontFamily: 'Inter, sans-serif',
  padding: '32px',
  boxShadow: 'inset 5px 10px 80px -40px black, inset 1px 2px 5px -3px black',
  // overflow: 'scroll',
  borderRadius: 4,
  flexGrow: 1
};

const buttonStyles = {
  background: '#FAE180',
  color: 'rgba(0, 0, 0, 0.8)',
  minWidth: '10em',
  fontFamily: 'Inter, sans-serif',
  border: 'none',
  height: '2.5em',
  borderRadius: 4,
  textTransform: 'uppercase' as 'uppercase',
  margin: '0.5ex',
  fontWeight: 'bold' as 'bold',
  cursor: 'pointer'

  // overflow: 'scroll',
};

const secondaryStyles = {
  ...buttonStyles,
  color: 'rgba(255, 255, 255, 0.64)',
  background: 'rgba(255, 255, 255, 0.08)'
};
const supportButtonStyles = {
  ...buttonStyles,
  textTransform: undefined,
  height: '1.8em',
  color: 'rgba(255, 255, 255, 0.64)',
  background: 'rgba(255, 255, 255, 0.04)'
};

export class ErrorBoundary extends React.PureComponent<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  private unsub;
  private appVersion;

  state = {
    hasError: false,
    error: undefined,
    info: undefined,
    show: false,
    eventId: null,
    consent: false,
    versionMismatch: false
  };

  componentDidMount() {
    this.appVersion = getRunningVersion();
    this.unsub = createConsentSubscriber(['functional', 'analytics'])(
      () => this.setState({ ...this.state, consent: true }),
      () => this.setState({ ...this.state, consent: false })
    );
  }
  componentWillUnmount() {
    this.unsub && this.unsub();
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.warn('ErrorBoundary triggered', [error], [info]);
    this.setState({ error, info });

    if (error.name?.match(/ChunkLoadError/i)) {
      getServedVersion()
        .then(version => {
          if (this.appVersion && version !== this.appVersion) {
            console.warn('App version', this.appVersion);
            console.warn('Served version', version);
            this.setState({ versionMismatch: true });
          }
        })
        .catch(err =>
          console.error('Could not retrieve served app version', err)
        );
    }

    if (this.state.consent) {
      Sentry.withScope(scope => {
        scope.setExtras({
          boundaryName: this.props.name,
          reactErrorInfo: info,
          extraInfo: this.props.extraInfo
        });
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  show = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  render() {
    const { show, versionMismatch, hasError } = this.state;

    if (hasError) {
      if (versionMismatch) {
        return (
          <div style={styles}>
            <h1>Update</h1>
            <p>Cube has been updated.</p>
            <p>In order to continue please reload...</p>
            <p>
              If you think this is an error contact{' '}
              <button
                style={supportButtonStyles}
                onClick={() =>
                  window.open(
                    'mailto:support@cube-cloud.com',
                    '_blank',
                    'noopener noreferrer'
                  )
                }
              >
              <SupportEmailComponent />
              </button>{' '}
            </p>
            <button
              style={buttonStyles}
              onClick={() => window.location.reload()}
            >
              Reload
            </button>
          </div>
        );
      }

      // You can render any custom fallback UI
      return (
        <div style={styles}>
          <h4>{this.state.eventId}</h4>
          <h1>Error{this.props.name ? ` in ${this.props.name}` : ''}</h1>
          <p>Part of the application has stopped working</p>
          <p>Please reload the application...</p>

          <p>
            If the error persists contact{' '}
            <button
              style={supportButtonStyles}
              onClick={() =>
                window.open(
                  'mailto:support@cube-cloud.com',
                  '_blank',
                  'noopener noreferrer'
                )
              }
            >
              <SupportEmailComponent />
            </button>{' '}
          </p>
          {this.state.consent && (
            <button
              style={secondaryStyles}
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report feedback
            </button>
          )}
          <button style={secondaryStyles} onClick={this.show}>
            {show ? 'Hide' : 'Show'} details
          </button>
          <button style={buttonStyles} onClick={() => window.location.reload()}>
            Reload
          </button>
          {show && (
            <div>
              <h2>
                {(this.state.error && this.state.error.message) ||
                  'No error message'}
              </h2>
              <pre>
                {(this.state.error && this.state.error.stack) ||
                  'No error stack'}
              </pre>
              <h2>Component Stack</h2>
              <pre>
                {(this.state.info && this.state.info.componentStack) || null}
              </pre>
              <h2>Raw</h2>
              <p>{this.props.extraInfo}</p>
              <pre>{JSON.stringify(this.state.error, null, 2)}</pre>
              <pre>{JSON.stringify(this.state.info, null, 2)}</pre>
            </div>
          )}
        </div>
      );
    }
    return <>{this.props.children}</>;
  }
}
