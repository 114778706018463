import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentWorkspace } from '../redux/components/Administration/withCurrentWorkspace';
import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '../redux/components/Hooks/useCreateResource';
import { useResource__ALPHA } from '../redux/components/Hooks/useResource';
import { statuses } from '../redux/ducks/request-status';
import { uuidv4 } from '../utils/uuid';
import { getErrorData } from '../wrapped-cube-client';

type ThumbnailType = {
  thumbnail: string;
  captured_at: number;
};

interface SnapshotType extends ThumbnailType {
  id: string;
  temp?: boolean;
  processing?: boolean;
}

export const useVideoSnapshot = (assetId: string) => {
  // array of created snapshots
  const [snapshots, setSnapshots] = useState<SnapshotType[]>([]);
  // error message, if anything breaks
  const [error, setError] = useState<string>(undefined);

  // use a temp id that can be mapped once the server returns the real generated id
  const tempId = useRef(uuidv4());
  const id = useMappedId__ALPHA(tempId.current);

  // configure export resource creator
  const [createExport, createExportStatus, createErrors] =
    useCreateResource__ALPHA({
      resourceType: 'snapshot',
      ancestor: { type: 'asset', id: assetId },
      relationship: 'snapshots',
      cacheInvalidator: null
    });

  // load the newly created export resource, so we can access new asset id later
  const createdExport = useResource__ALPHA({
    resourceId: id,
    resourceType: 'snapshot'
  });

  const [pendingThumbnail, setPendingThumbnail] =
    useState<ThumbnailType | null>(null);

  // handler to create the snapshots
  const createSnapshot = useCallback(
    (playedSeconds: number, thumbnail: ThumbnailType) => {
      if (!createExport || !assetId) {
        setError('Snapshot tool not ready');
        return;
      }

      tempId.current = uuidv4();
      // create still image export resource
      createExport({
        temporaryId: tempId.current,
        type: 'snapshot',
        timestamp: playedSeconds
      });

      // track which thumbnail we are creating
      setPendingThumbnail(thumbnail);

      // combine request data and asset id from response
      const newSnapshot = {
        id: `${tempId.current}`,
        temp: true,
        processing: true,
        ...thumbnail
      };

      // update snapshots list
      setSnapshots((prev) => [...prev, newSnapshot]);
    },
    [assetId, createExport, tempId, setPendingThumbnail, setSnapshots]
  );

  // add successful snapshot to snapshots list
  useEffect(() => {
    if (createExportStatus === statuses.SUCCESS && createdExport.first) {
      const id = createdExport.first?.relationships.asset?.id;
      if (!id) {
        setError('Snapshot created without Asset id');
        return;
      }

      // combine request data and asset id from response
      const newSnapshot = {
        id: `${id}`,
        ...pendingThumbnail
      };

      // update snapshots list
      setSnapshots((prev) => [
        ...prev.filter((t) => t.id !== tempId.current),
        newSnapshot
      ]);
    }
  }, [
    createExportStatus,
    createdExport.first,
    pendingThumbnail,
    setSnapshots,
    setError
  ]);

  // propagate any errors
  useEffect(() => {
    if (createErrors) {
      setError(getErrorData(createErrors, 'detail'));
    }
  }, [createErrors, setError]);

  return useMemo(() => {
    return {
      error,
      snapshots,
      createSnapshot
    };
  }, [createSnapshot, error, snapshots]);
};
