import { useEffect, useRef, useState, useCallback } from 'react';

export const useObserverHook = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const itemEl = useRef({});

  // observer config
  const observerConfig = {
    root: containerRef.current,
    rootMargin: '0px 0px 0px 0px'
  };

  // width of the container
  const containerWidth: number = containerRef.current
    ? containerRef.current.clientWidth
    : 0;

  useEffect(() => {}, [containerRef]);

  // function that is called when an element is intersecting
  const handleIntersection = useCallback((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
      }
    });
  }, []);

  // new intersection observer object
  const observer = new IntersectionObserver(handleIntersection, observerConfig);

  // function to add the elements in observer stack
  const addToItemsRefs = useCallback(
    el => {
      if (el && !(el in itemEl.current)) {
        itemEl.current[el.getAttribute('data-key')] = el;
        observer.observe(el);
      }
    },
    [observer]
  );

  return [
    containerRef,
    mainContainerRef,
    itemEl,
    addToItemsRefs,
    containerWidth
  ];
};

export const useZoom = () => {
  const [initialZoomLevel, setInitialZoomLevel] = useState(null);
  const [zoom, setZoom] = useState(1);

  const setZoomValue = useCallback(
    localZoom => {
      let mobileZoom = initialZoomLevel ? initialZoomLevel : localZoom;
      setZoom(localZoom);
      setInitialZoomLevel(mobileZoom);
    },
    [initialZoomLevel]
  );

  const zoomIn = useCallback(() => {
    let localZoom = Math.min(zoom * 1.5, 3);
    setZoom(localZoom);
  }, [zoom]);

  const zoomOut = useCallback(() => {
    let localZoom = Math.max(zoom * (1 / 1.5), initialZoomLevel);
    setZoom(localZoom);
  }, [initialZoomLevel, zoom]);

  return [setZoomValue, zoomIn, zoomOut, zoom, initialZoomLevel];
};
