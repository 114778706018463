import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export interface ProjectTemplate extends PublicResource {
  relationships: {
    donor_project: Related<'project'>;
  };
}

export const projectTemplate: RawModel = {
  type: 'project-template',
  plural: 'project-templates',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {
    donor_project: {
      type: 'project',
      binding: ['one', 'one'],
      reverse: 'project-template'
    }
  }
};
