import React, { useCallback, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Input from '@material-ui/core/Input';

import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';

import Select, {
  SelectProps as SelectPropsInterface
} from '@material-ui/core/Select';

import { useSurfaceClasses } from '../Surface/Surface';
import Scrollbar from '../Scrollbar/Scrollbar';
import Cube3IconButton from '../Buttons/IconButton';
import MaybeTooltip from '../Tooltip/MaybeTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      ...theme.typographyStyles.body2,
      transform: 'unset',
      position: 'unset',
      marginLeft: 0,
      marginBottom: 4,
      color: `${theme.customPalette.primary.textWhiteMediumEmphasis} !important`,
      WebkitFontSmoothing: 'antialiased'
    },
    labelAsPlaceholder: {
      ...theme.typographyStyles.body1,
      marginLeft: 16,
      color: `${theme.customPalette.primary.textWhiteMediumEmphasis} !important`,
      WebkitFontSmoothing: 'antialiased',
      lineHeight: '6px',
      top: '-8px'
    },
    input: ({ width }: { width: SelectWidthType }) => ({
      ...theme.typographyStyles.body1,
      color: theme.customPalette.text.onBase.contrast1,
      padding: '0px 16px',
      height: 40,
      width: width?.input || 210,
      position: 'relative',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      borderStyle: 'solid',
      borderWidth: '1px',
      boxSizing: 'border-box',
      borderColor: theme.customPalette.line.onBase1.contrast3,
      background: theme.customPalette.surfaceAccent.onBase.shade2,
      overflow: 'hidden',
      WebkitFontSmoothing: 'antialiased',
      '&:before, &:after': {
        display: 'none'
      },
      'label + &': {
        marginTop: 0
      }
    }),
    inputDisabled: ({ width }: { width: SelectWidthType }) => ({
      ...theme.typographyStyles.body1,
      color: theme.customPalette.text.onBase.contrast1,
      padding: '0px 16px',
      height: 40,
      width: width?.input || 210,
      position: 'relative',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      // borderStyle: 'solid',
      // borderWidth: '1px',
      boxSizing: 'border-box',
      // borderColor: theme.customPalette.line.onBase1.contrast3,
      background: theme.customPalette.surfaceAccent.onBase.shade2,
      overflow: 'hidden',
      WebkitFontSmoothing: 'antialiased',
      '&:before, &:after': {
        display: 'none'
      },
      'label + &': {
        marginTop: 0
      }
    }),
    icon: {
      color: theme.customPalette.icon.onBase.contrast2
    },
    iconDisabled: {
      color: theme.customPalette.icon.onBase.contrast2,
      display: 'none '
    },
    select: {
      marginTop: 0,
      '&:focus': {
        backgroundColor: 'unset'
      }
    },
    denseRoot: {
      height: `32px !important`,
      padding: `0px 8px !important`
    },
    dense: {
      paddingRight: `6px !important`,
      paddingTop: '2px !important',
      paddingBottom: '2px !important'
    },
    menu: ({ width }: { width: SelectWidthType }) => ({
      width: width?.menu || '240px',
      maxHeight: '50vh',
      overflowY: 'auto'
    })
  })
);

type SelectWidthType = {
  input?: number;
  menu?: number;
};
interface SelectProps {
  labelName: string;
  value: string | string[];
  children: React.ReactNode;
  selectProps?: SelectPropsInterface;
  formControlProps?: FormControlProps;
  inputLabelProps?: InputLabelProps;
  onChange?: Function;
  onFocus?(ev: React.FocusEvent<any>): void;
  onBlur?(ev: React.FocusEvent<any>): void;
  showLabel?: boolean;
  placeholder?: string;
  'data-cy'?: string;
  multiple?: boolean;
  disabled?: boolean;
  renderValue?(val: any): string | React.ReactNode;
  showClearButton?: boolean;
  clearButtonBefore?: boolean;
  clearButtonInside?: boolean;
  required?: boolean;
  width?: SelectWidthType;
  dense?: boolean;
  /** start `InputAdornment` for input component. */
  startAdornment?: React.ReactNode;
}

// default value renderer
// - renders a tooltip for array type values (since they usually get shortened wit elipses )
const renderValueDefault = (val) => {
  if (Array.isArray(val)) {
    return (
      <MaybeTooltip
        title={
          <ul
            style={{
              padding: 0,
              listStyleType: 'none'
            }}
          >
            {val.map((v) => (
              <li key={v} style={{ listStyle: 'none' }}>
                {v}
              </li>
            ))}
          </ul>
        }
      >
        <span>{val.join(', ')}</span>
      </MaybeTooltip>
    );
  } else {
    return (val && val['display_name']) || val;
  }
};

function SelectComponent(props: SelectProps) {
  const {
    labelName,
    selectProps,
    formControlProps,
    inputLabelProps,
    showLabel,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    children,
    multiple,
    disabled,
    renderValue,
    showClearButton = false,
    clearButtonBefore = false,
    clearButtonInside = false,
    width,
    dense,
    startAdornment
  } = props;
  const importedClasses = useSurfaceClasses({});
  const [internalValue, setInternalValue] = React.useState(value);

  // trigger external change handler but also update internal state
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ name?: string; value?: any }>) => {
      let newValue = event.target.value;
      onChange && onChange(newValue);
      setInternalValue(newValue);
    },
    [onChange]
  );

  // clear value
  const handleClear = React.useCallback(() => {
    onChange('');
  }, [onChange]);

  // when we want to render the clear button inside the
  // input, we need to be able to pass it some props.
  // NOTE: reuses the renderValueDefault function internally
  const renderValueDefaultClear = useCallback(
    (val) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {val && (
          <div style={{ marginLeft: -4, marginRight: 0 }}>
            <Cube3IconButton
              title="Clear field"
              path="iconClose"
              disabled={disabled}
              onMouseDown={(ev) => {
                // take back control from that dastardly select field
                ev.preventDefault();
                ev.stopPropagation();
              }}
              onClick={(ev) => {
                handleClear();
              }}
              colorVariant="ghost1"
            />
          </div>
        )}
        {renderValueDefault(val)}
      </div>
    ),
    [handleClear, showClearButton]
  );

  const classes = useStyles({ width });

  // keep track of controlled value changes and update internal state
  React.useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value !== '' ? value : undefined);
    }
  }, [value, internalValue]);

  return (
    <FormControl {...formControlProps}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {value && showClearButton && clearButtonBefore && (
          <div style={{ marginLeft: 4, marginRight: 8 }}>
            <Cube3IconButton
              title="Clear field"
              path="iconClose"
              disabled={disabled}
              onClick={handleClear}
              colorVariant="ghost1"
            />
          </div>
        )}

        <div>
          {showLabel && (
            <InputLabel
              shrink={false}
              disableAnimation={false}
              classes={{ root: classes.inputLabel }}
              {...inputLabelProps}
            >
              {labelName}
            </InputLabel>
          )}
          {placeholder &&
            (internalValue === undefined ||
              (internalValue && internalValue.length === 0)) && (
              <InputLabel
                shrink={false}
                disableAnimation={false}
                focused={false}
                classes={{ root: classes.labelAsPlaceholder }}
                {...inputLabelProps}
              >
                {placeholder}
              </InputLabel>
            )}
          <Select
            multiple={multiple}
            MenuProps={{
              // PaperProps: {
              //   style: {
              //     maxHeight: '50vh',
              //     overflowY: 'scroll'
              //   }
              // },
              PopoverClasses: {
                paper: [importedClasses, classes.menu].join(' ')
              },
              PaperProps: {
                component: ScrollPaper as any
              }
            }}
            value={internalValue !== undefined ? internalValue : null}
            onChange={handleChange}
            input={
              <Input
                classes={{
                  root: `${disabled ? classes.inputDisabled : classes.input} ${
                    dense ? classes.denseRoot : ''
                  }`
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                name={labelName}
                startAdornment={startAdornment}
              />
            }
            renderValue={
              renderValue ||
              (!showClearButton || !clearButtonInside
                ? renderValueDefault
                : renderValueDefaultClear)
            }
            displayEmpty={true}
            name={labelName}
            classes={{
              icon: disabled ? classes.iconDisabled : classes.icon,
              select: dense
                ? `${classes.select} ${classes.dense}`
                : classes.select
            }}
            {...selectProps}
            SelectDisplayProps={
              selectProps &&
              ({
                'data-cy': selectProps['data-cy']
              } as React.HTMLAttributes<HTMLDivElement>)
            }
            data-cy={props['data-cy']}
            disabled={disabled}
          >
            {children}
          </Select>
        </div>

        {value &&
          showClearButton &&
          !clearButtonBefore &&
          !clearButtonInside && (
            <div style={{ marginLeft: 4, marginRight: 8 }}>
              <Cube3IconButton
                title="Clear field"
                path="iconClose"
                disabled={disabled}
                onClick={handleClear}
                colorVariant="ghost1"
              />
            </div>
          )}
      </div>
    </FormControl>
  );
}

const ScrollPaper = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        width: 260,
        position: 'absolute'
      }}
    >
      <Scrollbar {...props} autoHeight={true} width="100%" />
    </div>
  ) as any;
});

export default SelectComponent;
