import moment from 'moment';

export const expiredCheck = expiryDate => {
  if (expiryDate === null) {
    return false;
  }
  const expiryTime = moment(expiryDate).endOf('day');
  const today = moment();
  const inPast: boolean = today.diff(expiryTime, 'seconds') > 0;
  return inPast;
};
