import { JSONApiInterceptorResponse } from '../../../wrapped-cube-client/interceptors/JSONApi-interceptors';
import Client from '../../../wrapped-cube-client';
import { actionCreators as requestActionCreators } from '../../ducks/request-status';
import { actionCreators } from '../../ducks/uploads';
import { actionCreators as uiActionCreators } from '../../ducks/ui';

export const handleTree = (parentFolderId, tree, metadata, dispatch) => {
  dispatch(uiActionCreators.setUploadQueueBusy(true));

  const structure = tree.allFolders
    // make sure we create the folder promises starting at the lowest depth
    // so nested folders alway have a parent folder promise to wait on
    .sort((a, b) => a.depth - b.depth)
    // create a look-up for folder creation promises, using temp uuids as keys
    .reduce(
      (acc, val) => {
        // add promise for folder creation to look-up
        acc[val.tempId] = new Promise((resolve) => {
          // we need to wait for parent folder to be created, before we can
          // create nested folders
          acc[val.parent.tempId].then((parentId) => {
            Client.post(
              'folder',
              { display_name: val.name, type: 'folder' },
              { type: 'folder', id: parentId, field: 'folders' }
            ).then((res: JSONApiInterceptorResponse) => {
              const id = res.data.primary[0].id;
              // resolve the id, so nested folder can use it as parentId
              resolve(id);
            });
          });
        });
        return acc;
      },
      { [tree.tempId]: Promise.resolve(parentFolderId) }
    );

  Promise.all(Object.keys(structure).map((k) => structure[k]))
    .then((res) => {
      // for all folders (including root) create regular file upload actions
      [...tree.allFolders, tree].forEach((f) => {
        // hook into the folder creation promises (that have already finished)
        // to get the folder Ids
        structure[f.tempId].then((fId) => {
          dispatch(actionCreators.uploadFilesToFolder(f.files, fId, metadata));
          // trigger reload of the root folder
          dispatch(
            requestActionCreators.invalidateRelationship({
              type: 'folder',
              id: parentFolderId,
              relationship: 'folders'
            })
          );
        });
      });
    })
    .then((done) => dispatch(uiActionCreators.setUploadQueueBusy(false)));
};
