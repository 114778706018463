export interface Modal {
  modal: string;
  resource: any;
  hideBelow: boolean;
};

export type State = {
  modalStack: Modal[];
  snapshot: Modal[];
  blocked: boolean;
};

export const initialState: State = {
  modalStack: [],
  snapshot: [],
  blocked: false,
};