import { Dispatch } from 'redux';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { handleFilesPostCreate } from '../../../uploads-middleware';

interface ApiClientConfig {
  targetType: string;
  ancestorId: string;
  ancestorType: string;
  files: File[];
}

interface HandleFilesConfig {
  resource: ResourceIdentifier;
  config: ApiClientConfig;
  dispatch: Dispatch;
  uploadIds: string[];
  getState(): any;
}
/**
 *
 *
 */
export const handleFiles = ({
  resource,
  config,
  dispatch,
  uploadIds,
  getState
}: HandleFilesConfig): Promise<boolean | void> => {
  if (!config.files) {
    return Promise.resolve();
  } else {
    if (config.files.length > 1) {
      console.warn(
        'only one upload per resource allowed (upload id === resource id)'
      );
    }

    handleFilesPostCreate(dispatch, getState, 0)(config, resource, uploadIds);

    return Promise.resolve(true);
  }
};
