import React, { useCallback, useEffect, useRef } from 'react';
import { useModalActions } from '../../../../Modals/modalActions';

import {
  useCreateResource__ALPHA,
  useMappedId__ALPHA
} from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useProjectTemplateAction } from '../hooks/useProjectTemplateAction';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { RenameModalStatelessUI } from '@cube3/ui/src/modals/RenameModalStatelessUI';
import { getErrorMessage } from '../../../../../../forms/helpers/errors';

export const DuplicateProjectTemplateModal = (props) => {
  const { projectTemplateId } = props.modalContext;

  const { previousModal, closeAllModals } = useModalActions();
  const [workspaceId] = useCurrentWorkspace();
  const tempId = useRef(uuidv4()).current;
  const newProjectId = useMappedId__ALPHA(tempId);
  const { onCopy, createStatus: copyStatus } = useProjectTemplateAction();

  const [createProject, createProjectStatus, createError] =
    useCreateResource__ALPHA({
      ancestor: {
        type: 'workspace',
        id: workspaceId
      },
      resourceType: 'project',
      cacheInvalidator: useCallback(
        (r, anc) => [
          r,
          { ...anc, relationship: 'projects' },
          { ...anc, relationship: 'project-templates' }
        ],
        []
      )
    });

  /** Only support single selection
   * 1. First create a Project using the template by setting the project_template relationship.
   */
  const onDuplicateTemplate = useCallback(
    (displayName: string) => {
      createProject({
        type: 'project',
        temporaryId: tempId,
        display_name: displayName,
        relationships: {
          project_template: {
            data: {
              type: 'project-template',
              id: projectTemplateId
            }
          }
        }
      });
    },
    [createProject, projectTemplateId]
  );
  /** 2. Then create a new projectTemplate with a donor_project relationship that points to the created Project. */
  useEffect(() => {
    if (newProjectId && !copyStatus) {
      onCopy([{ id: newProjectId, type: 'project' }]);
    }
  }, [newProjectId, copyStatus, onCopy]);

  useEffect(() => {
    if (
      createProjectStatus === statuses.SUCCESS &&
      copyStatus === statuses.SUCCESS
    ) {
      closeAllModals();
    }
  }, [createProjectStatus, copyStatus, closeAllModals]);

  const error = createError ? getErrorMessage(createError) : '';

  return (
    <RenameModalStatelessUI
      title="Duplicate template"
      loading={
        copyStatus &&
        copyStatus !== statuses.SUCCESS &&
        copyStatus !== statuses.FAILED
      }
      onCloseEvent={previousModal}
      onSubmit={onDuplicateTemplate}
      error={error}
      submitButtonLabel="Create"
    />
  );
};
