import React, { useContext } from 'react';
import { tabsContext } from './tabsContext';
import { TabConfig } from './TabsLayout';

interface TabViewProps {
  forTab: string | TabConfig;
}

export const TabView: React.FC<React.PropsWithChildren<TabViewProps>> = props => {
  const { children, forTab } = props;

  const activeTab = useContext(tabsContext);
  const active =
    (forTab && forTab === activeTab) ||
    (activeTab?.id &&
      ((forTab as string) === activeTab?.id ||
        (forTab as TabConfig).id === activeTab?.id));

  return active ? <>{children}</> : null;
};
