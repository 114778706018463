import { useSelection } from '@cube3/state/src/redux/components/useSelection';
import { useModalActions } from '../../Modals/modalActions';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useDeleteActions } from '@cube3/state/src/redux/components/Hooks/useDeleteActions';
import { useCallback, useMemo } from 'react';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';

const requiredPermissions = ['LIBRARY_WRITE'] as Privileges[];

export const useTrashBinCommander = () => {
  const { selection, inverted } = useSelection();
  const { openModal } = useModalActions();

  const [canWriteLibrary] = useWorkspacePermissions(requiredPermissions);
  const [workspaceId] = useCurrentWorkspace();

  const {
    restoreFromInvertedSelection,
    restoreFromSelection,
    permanentlyDeleteFromInvertedSelection,
    permanentlyDeleteFromSelection
  } = useDeleteActions();

  const { pageCounts } = useResourceList__ALPHA(
    useMemo(
      () => ({
        resourceType: 'workspace',
        resourceId: workspaceId,
        edgeType: 'content-tree-node',
        edgeLabel: 'deleted-nodes',
        strategy: 'fetch-on-mount',
        params: {
          page: { size: 1, number: 1 }
        }
      }),
      [workspaceId]
    )
  );
  const allDeletedNodes = pageCounts.itemCount;

  const multipleSelected = inverted
    ? !selection.length || allDeletedNodes - selection.length > 1
    : selection.length > 1;

  const anySelecetd = inverted
    ? !selection.length || allDeletedNodes > selection.length
    : !!selection.length;

  const restoreBinFiles = useCallback(() => {
    if (inverted) {
      restoreFromInvertedSelection(true);
    } else {
      restoreFromSelection(true);
    }
  }, [inverted, restoreFromInvertedSelection, restoreFromSelection]);

  /**  if nothing is selected, clicking `Delete forever` button will delete everything */
  const deleteAll = !selection.length;
  const deleteFilesPermanent = useCallback(() => {
    if (inverted || deleteAll) {
      permanentlyDeleteFromInvertedSelection(true);
    } else {
      permanentlyDeleteFromSelection(true);
    }
  }, [
    inverted,
    deleteAll,
    permanentlyDeleteFromInvertedSelection,
    permanentlyDeleteFromSelection
  ]);

  const openDeletePermanentlyPrompt = useCallback(() => {
    openModal('assets_delete_permanent', {
      multipleSelected,
      allSelected: deleteAll
    });
  }, [openModal, multipleSelected, deleteAll]);

  return {
    restoreBinFiles,
    deleteFilesPermanent,
    openDeletePermanentlyPrompt,
    multipleSelected,
    deleteForeverDisabled: !canWriteLibrary,
    deleteForeverDisabledReason:
      !canWriteLibrary && 'This account has no permission to delete forever',
    restoreButtonVisible: canWriteLibrary && anySelecetd
  };
};
