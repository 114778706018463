import React from 'react';
import { YoutubeExportOptionsModal } from './YoutubeExportOptionsModal';
import {
  CM360Profile,
  MetaAdAccount,
  YoutubePlaylist,
  GoogleAdsCustomer,
  ContentfulSpace
} from '@cube3/state/types';
import { MetaExportOptionsModal } from './MetaExportOptionsModal';
import { Platform } from './SocialExportModal';
import { CM360ExportOptionsModal } from './CM360ExportOptionsModal';
import { GoogleAdsExportOptionsModal } from './GoogleAdsExportOptionsModal';
import { ContentfulExportOptionsModal } from './ContentfulExportOptionsModal';

interface ModalContextBase {
  platform?: Platform;
  exportTargetId: string;
  exportTargetTypeId: string;
}
interface YouTubeModalContext {
  channel: {
    id: string;
    display_name: string;
    display_image?: string;
  };

  categories?: { id: string; display_name: string }[];
  playlists?: YoutubePlaylist[];
}

interface MetaModalContext {
  adAccount: MetaAdAccount;
}

interface CM360ModalContext {
  profile: CM360Profile;
}

interface GoogleAdsModalContext {
  customer: GoogleAdsCustomer;
}

interface ContentfulModalContext {
  space: ContentfulSpace;
}

interface Props<
  C =
    | YouTubeModalContext
    | MetaModalContext
    | CM360ModalContext
    | GoogleAdsModalContext
    | ContentfulModalContext
> {
  modalContext: ModalContextBase & C;
}

export const SocialExportOptionsModal = (props: Props) => {
  const { modalContext } = props;
  const { platform } = modalContext;

  if (platform === 'YouTube') {
    return (
      <YoutubeExportOptionsModal {...(props as Props<YouTubeModalContext>)} />
    );
  }

  if (platform === 'Meta') {
    return <MetaExportOptionsModal {...(props as Props<MetaModalContext>)} />;
  }

  if (platform === 'CM360') {
    return <CM360ExportOptionsModal {...(props as Props<CM360ModalContext>)} />;
  }

  if (platform === 'Google Ads') {
    return (
      <GoogleAdsExportOptionsModal
        {...(props as Props<GoogleAdsModalContext>)}
      />
    );
  }

  if (platform === 'Contentful') {
    return (
      <ContentfulExportOptionsModal
        {...(props as Props<ContentfulModalContext>)}
      />
    );
  }

  return null;
};
