import { createConsentSubscriber } from '../../redux/middleware/cookie-consent-middleware';
import { uuidv4 } from '../../utils/uuid';
import { KeyValStorage } from './storage';

const storageSingletons = {};

/**
 * A proxy that uses either local storage or session storage,
 * based on cookies consent settings. It mainly has the same
 * methods as the browser's Storage class, but prefixes all the
 * keys with a (configurable) namespace, and uses that to limits actions / results as well.
 */
export class BrowserStorage implements KeyValStorage {
  /** entry keys get prefixed with this */
  private namespace;
  private namespaceExp;
  /** if cookies are allowed we can use (persistent) local storage, instead of session storage */
  private cookiesAllowed = null;

  // a helper to make sure
  static getInstance(namespace: string) {
    const existing = storageSingletons[namespace];
    if (!existing) {
      const singleton = new BrowserStorage({ namespace });
      storageSingletons[namespace] = singleton;
      return singleton;
    }
    return existing;
  }

  constructor({ namespace }: { namespace?: string } = {}) {
    if (storageSingletons[namespace]) {
      throw new Error(
        'Creating duplicate instances for the same namespace causes issues when switching backends. Use BrowserStorage.getInstance(<namespace>) instead'
      );
    }
    this.namespace = namespace || uuidv4();
    this.namespaceExp = new RegExp(`^${this.namespace}::`);
    this.remember();

    createConsentSubscriber(['functional'], true)(
      () => this.setCookiesAllowed(true),
      () => this.setCookiesAllowed(false)
    );
  }

  readyPromise = Promise.resolve(true);

  /** check localstorag for old session */
  remember() {
    const state = JSON.parse(
      window.localStorage.getItem('session::state') || 'null'
    );
    if (state?.session?.remember) {
      this.cookiesAllowed = true;
    }
  }

  setCookiesAllowed(allowed: boolean) {
    if (this.cookiesAllowed === null) {
      this.cookiesAllowed = allowed;
      return;
    }

    if (this.cookiesAllowed !== allowed) {
      /**
       * We switch backends and move over all the entries
       */
      const entries = this.getAll();
      const keys = this.getKeys();

      this.clear(); // clear old storage
      this.cookiesAllowed = allowed; // switches storage backends
      this.clear(); // clear new storage
      const storage = this.getBackend();
      for (let i = 0; i < entries.length; i++) {
        storage.setItem(keys[i], JSON.stringify(entries[i]));
      }
    }
  }

  getBackend() {
    return this.cookiesAllowed ? localStorage : sessionStorage;
  }

  getItem(key: string) {
    const nsKey = this.namespacedKey(key);
    const storage = this.getBackend();
    const item = storage.getItem(nsKey);
    return item ? JSON.parse(item) : null;
  }

  getAll() {
    const storage = this.getBackend();
    const keys = this.getKeys();
    const results = [];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const item = storage.getItem(key);
      results.push(JSON.parse(item));
    }

    return results;
  }

  getKeys() {
    const storage = this.getBackend();
    const results = [];
    for (let i = 0; i < storage.length; i++) {
      const key = storage.key(i);
      if (this.namespaceExp.test(key)) {
        results.push(key);
      }
    }
    return results;
  }

  setItem(key: string, value: any) {
    const nsKey = this.namespacedKey(key);
    const storage = this.getBackend();
    storage.setItem(nsKey, JSON.stringify(value));
  }

  removeItem(key: string) {
    const nsKey = this.namespacedKey(key);
    const storage = this.getBackend();
    storage.removeItem(nsKey);
  }

  clear() {
    const storage = this.getBackend();
    const keys = this.getKeys();
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      storage.removeItem(key);
    }
  }

  getState() {
    return this.getAll();
  }

  namespacedKey(key: string) {
    return `${this.namespace}::${key}`;
  }
}
