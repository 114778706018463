import { useEffect } from 'react';

const noop = () => {};

/**
 * A workaround for iOS that allows using touch events inside an iframe.
 * For details:
 * - https://stackoverflow.com/questions/41869122/touch-events-within-iframe-are-not-working-on-ios
 */
export const useIOSIframeTouch = () => {
  useEffect(() => {
    window.addEventListener('touchstart', noop); // in top window
    return () => window.removeEventListener('touchstart', noop);
  }, []);
};
