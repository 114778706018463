import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
// components
import { Typography } from '../typography/Typography';
import Color from 'color';
import MaybeTooltip from '../Tooltip/MaybeTooltip';

const getShadeColor = (color) => {
  let shadeColor = Color(color);
  shadeColor = shadeColor.alpha(0.7);

  return shadeColor;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { margin: '0 12px', position: 'relative' },
    button: {
      position: 'absolute',
      borderRadius: '50%',
      cursor: 'pointer',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    innerButton: {
      width: '16px',
      height: '16px',
      border: 'none',
      backgroundColor: (props: ButtonProps) => props.color || 'grey',
      pointerEvents: 'none'
    },
    outerButton: ({ color }) => ({
      width: '22px',
      height: '22px',
      border: `2px solid ${getShadeColor(color)}`,
      background: 'none'
    }),
    hidden: {
      opacity: 0,
      '&:hover': {
        opacity: 0.48
      }
    }
  })
);

interface ButtonProps {
  text?: string;
  onClick?(): void;
  color?: string;
  active?: boolean;
  tooltip?: string;
}

const MarkerButton = (props: ButtonProps) => {
  const { text, onClick = () => {}, active = false, tooltip } = props;
  const classes = useStyles(props);

  return (
    <MaybeTooltip title={tooltip}>
      <div
        className={classes.root}
        data-pw={`marker-button-${text || tooltip}`}
      >
        <button
          onClick={onClick}
          className={`${classes.button} ${classes.outerButton} ${
            !active ? classes.hidden : ''
          }`}
        >
          <Typography typographyStyle="body2" color="contrast1">
            {text}
          </Typography>
        </button>
        <div className={`${classes.button} ${classes.innerButton}`}>
          <Typography typographyStyle="body2" color="contrast1">
            {text}
          </Typography>
        </div>
      </div>
    </MaybeTooltip>
  );
};

export default MarkerButton;
