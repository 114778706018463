import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { FeatureFlagsPanel } from './FeatureFlagsPanel';

import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { actionCreators } from '@cube3/state/src/redux/ducks/request-status';
import { useDispatch } from 'react-redux';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';

const consoleBaseStyle = {
  boxSizing: 'border-box' as const,
  position: 'fixed' as const,
  top: 0,
  left: 0,
  height: '100vh',
  width: '100vw',
  backgroundColor: '#ccc',
  transition: 'transform 0.35s ease-in-out, opacity 0.35s linear',
  transform: 'translate3d(0,-100vw,0)',
  pointerEvents: 'none' as const,
  opacity: 0,
  padding: '2rem',
  color: '#333',
  fontFamily: 'sans-serif',
  overflowY: 'scroll' as const
};

const peekRef = { peek: (val) => {} };

export const peek = (val) => {
  peekRef.peek(val);
};

const DevConsole = () => {
  const [consoleActive, setConsoleActive] = useState(false);

  const toggleConsole = useCallback(
    (ev) => {
      if (
        ev.ctrlKey &&
        ev.shiftKey &&
        (ev.keyCode === 192 ||
          ev.which === 192 ||
          ev.key === '``' ||
          ev.key === '~')
      ) {
        setConsoleActive(!consoleActive);
      }
    },
    [consoleActive, setConsoleActive]
  );

  useEffect(() => {
    window.addEventListener('keyup', toggleConsole);
    return () => window.removeEventListener('keyup', toggleConsole);
  }, [toggleConsole]);

  const consoleStyle = useMemo(() => {
    if (!consoleActive) {
      return consoleBaseStyle;
    }
    return {
      ...consoleBaseStyle,
      transform: 'translate3d(0,0,0)',
      opacity: 1,
      pointerEvents: 'all' as const
    };
  }, [consoleActive]);

  const [peek, setPeek] = useState(undefined);

  useEffect(() => {
    peekRef.peek = setPeek;
  }, [setPeek]);

  return (
    <>
      {peek && (
        <div
          style={{
            position: 'fixed',
            top: 10,
            right: 10,
            background: '#333',
            color: '#ddd'
          }}
        >
          {peek}
        </div>
      )}
      <div style={consoleStyle}>
        <h1>Dev Console</h1>
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          <ConsolePanel
            title="Feature Flags"
            hint="You may need to refresh for some of these"
          >
            <FeatureFlagsPanel />
          </ConsolePanel>
          <ReduxLogging />
          <Invalidator />
          <button
            style={{
              position: 'absolute',
              top: '2rem',
              right: '2rem',
              left: 'auto',
              display: 'block'
            }}
            onClick={() => setConsoleActive(false)}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

const ConsolePanel = ({ title, hint = undefined, children }) => (
  <div
    style={{
      margin: '0.5rem',
      border: '1px solid #eee',
      borderTopColor: '#cee',
      borderBottomColor: '#b88',
      borderRadius: '4px',
      padding: '1rem 2rem',
      boxShadow: '0px 4px 10px -4px rgba(16,0,10,0.5)'
    }}
  >
    <h2>{title}</h2>
    {hint && <small>{hint}</small>}
    <div>{children}</div>
  </div>
);

const ReduxLogging = () => {
  const [logging, setLogging] = useState<boolean>(
    !!JSON.parse(localStorage.getItem('ENABLE_REDUX_LOGGING'))
  );

  const handleChange = useCallback(() => {
    localStorage.setItem(
      'ENABLE_REDUX_LOGGING',
      JSON.stringify(logging === undefined ? false : !logging)
    );
    setLogging(JSON.parse(localStorage.getItem('ENABLE_REDUX_LOGGING')));
  }, [logging]);

  const state = useTypedSelector((state) => state);

  const [path, setPath] = useState('');

  return (
    <ConsolePanel title="Redux Logging" hint="Messages appear in console">
      <div>
        <label>
          <input
            name="redux-logging"
            type="checkbox"
            checked={!!logging}
            onChange={handleChange}
          />
          Enable redux logging
        </label>
      </div>
      <hr />
      <Treewalker path={path} onItemClick={setPath} object={state} />
    </ConsolePanel>
  );
};

const ERROR = new Error('bad path');

const Treewalker = (props) => {
  const { object, path, onItemClick } = props;

  const subtree = path
    ? path.split('/').reduce((node, key) => {
        const sub = node[key];
        if (!sub) {
          return ERROR;
        }

        return sub;
      }, object)
    : object;

  if (subtree === ERROR) {
    return (
      <div>
        <button
          onClick={() =>
            onItemClick(
              path ? path.split('/').slice(0, -1).join('/') : undefined
            )
          }
          disabled={!path}
        >
          back
        </button>
        <input value={path} onChange={(ev) => onItemClick(ev.target.value)} />
        <h1>Invalid path</h1>
      </div>
    );
  }

  const properties = Object.keys(subtree).map((k) => ({
    key: Array.isArray(subtree) ? parseInt(k) : k,
    value: subtree[k]
  }));

  return (
    <div>
      <button
        onClick={() =>
          onItemClick(path ? path.split('/').slice(0, -1).join('/') : undefined)
        }
        disabled={!path}
      >
        back
      </button>
      <input value={path} onChange={(ev) => onItemClick(ev.target.value)} />
      <ul>
        {properties.map((p) => {
          return (
            <li key={p.key}>
              {typeof p.key === 'number'
                ? `${p.key} (${
                    p.value['display_name'] ||
                    p.value['name'] ||
                    p.value['id'] ||
                    'array item'
                  })`
                : p.key}{' '}
              {isObject(p.value) ? (
                <button
                  onClick={() => onItemClick(path ? `${path}/${p.key}` : p.key)}
                >
                  …
                </button>
              ) : (
                <pre>{JSON.stringify(p.value, null, 2)}</pre>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const isObject = (val) => {
  if ((typeof val === 'object' || typeof val === 'function') && val !== null) {
    return true;
  }
  return false;
};

const Invalidator = () => {
  const [type, setType] = useState('');
  const [id, setId] = useState('');

  const dispatch = useDispatch();

  const handleTypeChange = useCallback(
    (ev) => setType(ev.target.value),
    [setType]
  );
  const handleIdChange = useCallback((ev) => setId(ev.target.value), [setId]);

  const handleClick = useCallback(() => {
    dispatch(
      actionCreators.invalidateResource({ type, id } as ResourceIdentifier)
    );
  }, [dispatch, type, id]);

  return (
    <ConsolePanel
      title="Resource invalidator"
      hint="sets request statuses to invalid to force refetch"
    >
      <div>
        <label>
          type <input name="type" onChange={handleTypeChange} value={type} />
        </label>
      </div>
      <div>
        <label>
          id <input name="id" onChange={handleIdChange} value={id} />
        </label>
      </div>
      <div>
        <button disabled={!type || !id} onClick={handleClick}>
          Invalidate
        </button>
      </div>
    </ConsolePanel>
  );
};

export { DevConsole };
