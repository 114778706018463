import React, { useEffect } from 'react';

import { useModalActions } from '../../../layout/Modals/modalActions';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

/**
 * @summary: Modal for changing project visibility, redirects user to ProjectSettingsModal
 */

const SET_PUBLIC = 'SET_PUBLIC';
const SET_PRIVATE = 'SET_PRIVATE';

export const changeProjectAccessActions = {
  SET_PUBLIC,
  SET_PRIVATE
} as const;

type Action = keyof typeof changeProjectAccessActions;

interface Props {
  modalContext: {
    action: Action;
    projectIds: string[];
    workspaceName: string;
    multiple?: boolean;
  };
}
const mutateConfig = { cacheInvalidator: null };
const ChangeProjectAccessModal: React.FunctionComponent<Props> = props => {
  const {
    action,
    projectIds,
    workspaceName,
    multiple = false
  } = props.modalContext;
  const { previousModal } = useModalActions();

  const [mutate, mutateStatus] = useMutateResource__ALPHA(mutateConfig);
  const loading =
    mutateStatus &&
    mutateStatus !== statuses.SUCCESS &&
    mutateStatus !== statuses.FAILED;

  const handleStatusChange = React.useCallback(() => {
    if (projectIds.length === 0) return;
    projectIds?.forEach(id => {
      mutate({
        id: id,
        type: 'project',
        is_public: action === SET_PUBLIC
      });
    });
  }, [projectIds, action, mutate]);

  useEffect(() => {
    if (mutateStatus && mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [loading, mutateStatus, previousModal]);

  return (
    <ModalNoticeUI
      title={'Change project visibility?'}
      loading={loading}
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button
            text="Do not change"
            colorVariant="ghost2"
            onClick={previousModal}
          />
          <Button
            text="Change"
            colorVariant="filled1"
            onClick={handleStatusChange}
          />
        </>
      }
    >
      {action === SET_PUBLIC ? (
        <>
          <Typography typographyStyle="body2">
            {multiple
              ? 'Some projects selected are currently listed as private and can be accessed by project members only.'
              : ' This project is currently listed as private and can be accessed by project members only.'}
          </Typography>
          <div style={{ height: 16 }}></div>
          <Typography typographyStyle="body2">
            Would you like to make this project visible to all {workspaceName}{' '}
            workspace members?
          </Typography>
        </>
      ) : (
        <>
          <Typography typographyStyle="body2">
            {multiple
              ? `Some projects selected can be accessed by all ${workspaceName} workspace members currently.`
              : ` All ${workspaceName} workspace members can access this project
            currently.`}
          </Typography>
          <div style={{ height: 16 }}></div>
          <Typography typographyStyle="body2">
            Would you like to limit access to project members only?
          </Typography>
        </>
      )}
    </ModalNoticeUI>
  );
};

export default ChangeProjectAccessModal;
