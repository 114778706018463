import { Chip } from '@cube3/ui/src/chips/Chip';
import { ExportInputPicker } from '@cube3/ui/src/exports/subcomponents/ExportInputPicker';
import { ExportInputRadios } from '@cube3/ui/src/exports/subcomponents/ExportInputRadios';
// import { ExportInputSelect } from '@cube3/ui/src/exports/subcomponents/ExportInputSelect';
import { ExportInputTitle } from '@cube3/ui/src/exports/subcomponents/ExportInputTitle';
import { ExportOptionRow } from '@cube3/ui/src/exports/subcomponents/ExportOptionRow';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { Tag } from '@cube3/ui/src/tags/Tag';
import { validEmailaddress } from '../../../../forms/helpers/validators';
import React, { useCallback, useEffect, useReducer } from 'react';
import {
  ExportOptionsAction,
  ExportOptionsState,
  exportOptionsReducer,
  initialExportOptionsState,
  useExportDataHandlers,
  visibilities
} from '../state/exportOptionsState';
import { ExportMultiSelectCheckBoxes } from '@cube3/ui/src/exports/subcomponents/ExportMultiSelectCheckBoxes';
import { SelectionItemInterface } from '@cube3/state/src/redux/ducks/selections';
import { YoutubePlaylist } from '@cube3/common/model/types';

interface ExportOptionsFormProps {
  /** All playlists from export-target `integration_config` */
  playlists?: YoutubePlaylist[];
  selection: SelectionItemInterface[];
  onFormChange: (value) => void;
  defaults: ExportOptionsState;
  onlyFields?: string[];
}

export const ExportOptionsForm = ({
  onlyFields,
  playlists: allPlaylists,
  selection,
  // categories,
  onFormChange,
  defaults = initialExportOptionsState
}: ExportOptionsFormProps) => {
  const [formValue, dispatch] = useReducer(exportOptionsReducer, defaults);

  useEffect(() => {
    dispatch({
      type: 'REINITIALIZE',
      payload: defaults
    } as ExportOptionsAction<'REINITIALIZE'>);
  }, [defaults, dispatch]);

  useEffect(() => {
    onFormChange({
      ...formValue,
      dirty: JSON.stringify(formValue) !== JSON.stringify(defaults)
    });
  }, [formValue, onFormChange, defaults]);

  const {
    title,
    visibility,
    description,
    tags,
    recipients,
    category,
    playlists
  } = formValue;

  //  pre-fill title
  if (formValue.title === undefined && selection.length === 1) {
    dispatch({
      type: 'CHANGE_TITLE',
      payload: selection[0]['attributes']['display_name']
    });
  }

  useEffect(() => {
    onFormChange({
      ...formValue,
      invalid: selection.length === 1 && (!title || title.trim() === '')
    });
  }, [category, formValue, onFormChange, selection.length, title]);

  const {
    onChangeTitle,
    onChangeDescription,
    onChangeVisibility,
    onChangePlaylists,
    onAddTag,
    onDeleteTag,
    onAddRecipient,
    onDeleteRecipient
  } = useExportDataHandlers(dispatch);

  const shouldShow = (field) => {
    return !onlyFields || onlyFields.includes(field);
  };

  return (
    <div>
      {shouldShow('title') && (
        <ExportInputTitle
          title={title}
          required={selection.length === 1 ? true : false}
          onChange={onChangeTitle}
        />
      )}
      {shouldShow('description') && (
        <ExportOptionRow
          label="Description"
          value={description}
          placeholder="Enter description"
          onChange={onChangeDescription}
        />
      )}

      {shouldShow('visibility') && (
        <ExportInputRadios
          type="radio"
          label="Visibility"
          value={visibility}
          radios={visibilities}
          onChange={onChangeVisibility}
          flexDirection={'row'}
        />
      )}
      {shouldShow('tags') && (
        <ExportInputPicker
          label="Tags"
          type="multiple input"
          items={tags}
          placeholder="Enter Tags"
          onAdd={onAddTag}
          onDelete={onDeleteTag}
          component={(val) => (
            <Tag
              text={val}
              onCrossClick={() =>
                dispatch({ type: 'DELETE_TAG', payload: val })
              }
            />
          )}
        />
      )}
      {shouldShow('playlists') && (
        <ExportMultiSelectCheckBoxes
          label="Playlists"
          type="multiple select checkboxes"
          options={allPlaylists}
          value={playlists as YoutubePlaylist[]}
          onChange={onChangePlaylists}
        />
      )}

      {/* NOTE: disabled until we figure out the youtube api */}
      {/* <ExportInputSelect
        label="Category"
        placeholder="Select a category"
        type="select"
        value={category}
        options={categories}
        onChange={val =>
          dispatch({
            type: 'ADD_CATEGORY',
            payload: val
          })
        }
      /> */}

      {shouldShow('recipients') && (
        <>
          <Divider gutter={true} />
          <ExportInputPicker
            label="Send export notifications to"
            type="multiple input"
            items={recipients}
            placeholder="Enter email address"
            validator={(val) => validEmailaddress(val, null, null, null)}
            onAdd={onAddRecipient}
            onDelete={onDeleteRecipient}
            component={(val) => (
              <Chip
                maxWidth={490}
                text={val}
                onCrossClick={() =>
                  dispatch({ type: 'DELETE_RECIPIENT', payload: val })
                }
              />
            )}
          />
        </>
      )}
    </div>
  );
};
