import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

/* Import components */
import { ModalNoticeUI } from './ModalNoticeUI';
import Button from '../Buttons/Button';
import { Typography } from '../typography/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      minHeight: 200
    },
    text: {
      marginBottom: 10
    },
    fields: {
      marginTop: '20px',
      marginBottom: '20px'
    },
    submitButton: {
      marginLeft: 10
    }
  }));

interface AddFolderPromptUIProps {
  onSubmit: (ev: React.FormEvent) => void;
  onCloseEvent: () => void;
  submitDisabled: boolean;
  loading: boolean;
}

const AddFolderPromptUI: React.FC<React.PropsWithChildren<AddFolderPromptUIProps>> = props => {
  const {
    onCloseEvent,
    onSubmit,
    submitDisabled,
    children,
    loading
  } = props;

  const classes = useStyles();

  return (
    <ModalNoticeUI
      // TODO CHECK MIN HEIGHT IN RENDERED MODAL
      onCloseEvent={() => onCloseEvent()}
      title={'Add folder'}
      loading={loading}
      component="form" //important for forms
      extraComponentProps={
        //important for forms
        {
          onSubmit
        }
      }
      extraModalProps={{
        disableAutoFocus: true
      }}
      footerRightComponent={
        <>
          <Button
            onClick={() => onCloseEvent()}
            text="Cancel"
            colorVariant="ghost2"
          />

          <Button
            disabled={submitDisabled}
            colorVariant={'filled1'}
            text="Add"
            extraButtonProperties={{
              classes: { root: classes.submitButton },
              type: 'submit'
            }}
          />
        </>
      }
    >
      <Typography className={classes.text}>
        Enter a name for the new folder
      </Typography>
      {children}
    </ModalNoticeUI>
  );
};

export default AddFolderPromptUI;
