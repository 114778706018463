import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Cube3Button from '../../../../Buttons/Cube3Button';
import Cube3TextField from '../../../../forms/textfields/Cube3TextField';
import { Typography } from '../../../../typography/Typography';

/**
 * @param passwordValidator (password:string, minLength?:number)=> boolean
 * @param minLength for share link is 6(default), account password is 8
 * @returns string: combination of 6 digits & uppercase letters
 */
export const generateValidPassword = (
  passwordValidator,
  minLength: number = 6
) => {
  const generatePassword = () => {
    let pwd = '';
    while (pwd.length < minLength) {
      pwd += Math.random()
        .toString(36)
        .substr(2, 1);
    }
    return pwd.toUpperCase();
  };
  let password = generatePassword();
  let isValid = passwordValidator(password);
  while (!isValid) {
    password = generatePassword();
    isValid = passwordValidator(password);
  }

  return password;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    passwordContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.customSpacing.margin[8]
    },
    passwordInput: {
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      margin: `0 ${theme.customSpacing.margin[4]} 0 ${theme.customSpacing.margin[16]}`,
      flexGrow: 1
    },
    errorMessage: {
      marginBottom: theme.customSpacing.margin[8],
      marginLeft: theme.customSpacing.margin[32],
      color: theme.customPalette.surface.danger1
    }
  })
);

export const PasswordInputComponent = props => {
  const { input, password, meta, passwordValidator } = props;
  const classes = useStyles();

  const error = meta.invalid && meta.error.length > 0;

  const errorMessage = (error: string) => {
    switch (error) {
      case 'WEAK_PASSWORD_ERROR_LENGTH':
        return 'Use 6 characters or more';

      case 'WEAK_PASSWORD_ERROR_CONSECUTIVE':
        return 'Do not use characters consecutively (aaa, 111)';

      case 'WEAK_PASSWORD_ERROR_SEQUENTIAL':
        return 'Do not use characters sequentially (abc, 123)';

      case 'WEAK_PASSWORD_ERROR_DICTIONARY':
        return 'Do not use easily guessable words';

      case 'MAX_LENGTH_PASSWORD_ERROR':
        return 'Use 64 characters or less';
      default:
        return undefined;
    }
  };

  return (
    <>
      <div className={classes.passwordContainer}>
        <Cube3TextField
          {...props}
          error={error}
          className={classes.passwordInput}
          value={password || input.value}
          onChange={input.onChange}
        />
        <Cube3Button
          colorVariant="ghost1"
          text={'Regenerate'}
          onClick={() =>
            input.onChange(generateValidPassword(passwordValidator))
          }
        />
      </div>
      <Typography className={classes.errorMessage} typographyStyle={'body2'}>
        {error && errorMessage(meta.error[0])}
      </Typography>
    </>
  );
};
