import { Reviewlink } from '@cube3/common/model/resource-types';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { Feature, ResourceIdentifier } from '@cube3/state/types';
import { expiredCheck } from '@cube3/common/utils/expiredCheck';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ReviewLinkUIProps } from '@cube3/ui/src/Prefabs/Reviewlink/Modals/ReviewLinkOverviewModalUI';

const requiredFeatures = {
  features: ['/WORKSPACE/SHARING/REVIEWS'] as Feature[]
};
const params = {
  filter: {
    intent: 'review'
  }
};
const paramsLegacy = {
  filter: {
    intent: 'review-legacy'
  }
};
/** return both legacy review-links and new review-links (if has the feature) */
export const useReviewsList = (identifier: ResourceIdentifier) => {
  const [hasReviewsFeature] = useFeatures(requiredFeatures);

  const { openModal } = useModalActions();

  const retrievedReviews = useResourceList__ALPHA({
    resourceType: identifier?.type,
    resourceId: identifier?.id,
    edgeType: 'share',
    edgeLabel: 'shares',
    params
  });

  const retrievedLegacyReviews = useResourceList__ALPHA({
    resourceType: identifier?.type,
    resourceId: identifier?.id,
    edgeType: 'share',
    edgeLabel: 'shares',
    params: paramsLegacy
  });

  const getReviewLinksUI = useCallback(
    (
      reviewlinks: Reviewlink[],
      intent: 'review' | 'review-legacy'
    ): ReviewLinkUIProps[] => {
      const newinfo: ReviewLinkUIProps[] = reviewlinks.map((item) => {
        const expiryTime = moment(item.expires_at).endOf('day');

        const uiInfo: ReviewLinkUIProps = {
          created_at: item.created_at,
          email_addresses: item.email_addresses,
          expired: expiredCheck(item.expires_at),
          expires: item.expires,
          expires_at: expiryTime.format(),
          onClick: () =>
            openModal('reviewlink_settings', {
              id: item.id,
              intent
            })
        };
        return uiInfo;
      });

      return newinfo;
    },
    [openModal, expiredCheck]
  );

  const info = useMemo(() => {
    const legacyReviews = getReviewLinksUI(
      retrievedLegacyReviews.resources,
      'review-legacy'
    );
    const reviews = getReviewLinksUI(retrievedReviews.resources, 'review');
    return hasReviewsFeature
      ? [].concat(legacyReviews, reviews)
      : legacyReviews;
  }, [
    retrievedLegacyReviews.resources,
    getReviewLinksUI,
    hasReviewsFeature,
    retrievedReviews.resources
  ]);

  return {
    reviewLinks: info,
    finishLoading:
      retrievedLegacyReviews.status === statuses.SUCCESS &&
      (hasReviewsFeature ? retrievedReviews.status === statuses.SUCCESS : true),
    loading:
      (retrievedLegacyReviews.status &&
        retrievedLegacyReviews.status !== statuses.SUCCESS &&
        retrievedLegacyReviews.status !== statuses.FAILED) ||
      (hasReviewsFeature &&
        retrievedReviews.status &&
        retrievedReviews.status !== statuses.SUCCESS &&
        retrievedReviews.status !== statuses.FAILED)
  };
};
