import { KeyConfig } from '../../../keyboard-shortcuts/reducer';
import React from 'react';

export interface MenuEntry {
  id?: string;
  title: string;
  icon?: string;
  visible?: boolean;
  disabled?: boolean;
  disabledReason?: string;
  children?: MenuConfig;
  isDivider?: boolean;
  component?: (closeFn: Function, isOpen: boolean) => JSX.Element;
  onClick?(ev: React.MouseEvent, extra?: any): void;
  extraClickArgs?: any;
  shortCut?: KeyConfig;
}

export interface MenuGroup {
  groupName?: string;
  entries: MenuEntry[];
}

export type MenuConfig = MenuEntry[] | MenuGroup[];

/** type guards */

export const isMenuEntry = (
  variableToCheck: any
): variableToCheck is MenuEntry =>
  (variableToCheck as MenuEntry).title !== undefined;

export const isMenuGroup = (
  variableToCheck: any
): variableToCheck is MenuGroup =>
  (variableToCheck as MenuGroup).entries !== undefined;
