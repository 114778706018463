import React from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { withModalActions, ModalActionProps } from '../../Modals/modalActions';
import { connect } from 'react-redux';
import withAccountActions, {
  AccountActionsProps
} from '@cube3/state/src/redux/components/Administration/withAccountActions';
import { CubeReduxState } from '@cube3/state/src/redux/ducks/CubeReduxState';

// forms
import { reduxForm, getFormValues, Field, InjectedFormProps } from 'redux-form';
import {
  isRequired,
  validEmailaddress,
  makeValidator
} from '../../../../forms/helpers/validators';
import GenericFieldWithErrors from '../../../../forms/helpers/fields/GenericFieldWithErrors';

// interfaces
import { RequestError } from '@cube3/common/model/resource-types';

// UI
import { compose } from '../../../../../utils/component-helpers';
import ChangeEmailModalUI from '@cube3/ui/src/Layout/AccountSettings/Modals/ChangeEmailModalUI';

/**
 * @summary: Modal with form for changing the user email adress
 * @author Simon
 * @description: Uses redux form and the withChangeEmail HOC and checks retrieved statuses.
 * The
 */

// #region form setup
interface FormValues {
  email_address?: string; // this field should be prefilled with the current acount email (spec behaviour)
  email_confirm?: string;
  password_check?: string;
}

const emailValidators = makeValidator([validEmailaddress, isRequired]);
// #endregion

interface InjectedProps
  extends ModalReceiverProps,
    ModalActionProps,
    FormValues,
    AccountActionsProps {}

interface Properties
  extends InjectedProps,
    InjectedFormProps<FormValues, InjectedProps> {}

const mapStateToProps = (state: CubeReduxState) => {
  const values = getFormValues('changeUserEmail')(state);
  return {
    ...values,
    formValues: values
  };
};

class ChangeNameModal extends React.Component<Properties> {
  componentDidUpdate() {
    if (this.props.submitSucceeded) {
      this.props.openModal(
        'accountsettings_confirmEmail',
        this.props.email_address
      );
    }
  }

  render() {
    const { previousModal, valid, handleSubmit, submitting, error } =
      this.props;

    let errorMsg = 'Something went wrong while trying to process your request.';

    const err = error as unknown; // because redux thinks its a string (but its an array of objects) we have to cast it first
    const errors: RequestError[] = err as RequestError[];

    // when the form errors on submit...
    if (errors) {
      if (errors[0].status?.includes('403')) {
        errorMsg =
          'You are not allowed to change your email adress because your user role does not allow this';
      } else {
        if (
          errors[0].detail ===
          'crypto/bcrypt: hashedPassword is not the hash of the given password'
        ) {
          errorMsg =
            'Current password is incorrect. Please check your password.';
        }
      }
    }

    return (
      <ChangeEmailModalUI
        onCloseEvent={() => previousModal()}
        submitError={error && errorMsg}
        submitDisabled={!valid || submitting}
        loading={submitting}
        onSubmit={handleSubmit}
        newEmailRenderProps={() => (
          <Field
            component={GenericFieldWithErrors}
            name={'email_address'}
            type="text"
            validate={emailValidators}
            autocomplete={false}
            label="New email address"
            startSelected={true}
          />
        )}
        confirmEmailRenderProps={() => (
          <Field
            component={GenericFieldWithErrors}
            validate={emailValidators}
            type="text"
            name={'email_confirm'}
            placeholder="Retype email address to avoid mistakes..."
            props={{
              label: 'Confirm email address'
            }}
          />
        )}
        passwordRenderProps={() => (
          <Field
            component={GenericFieldWithErrors}
            name={'password_check'}
            type="password"
            validate={isRequired}
            placeholder="Enter password to confirm identity..."
            props={{
              autoComplete: 'current-password',
              label: 'Password'
            }}
          />
        )}
      />
    );
  }
}

export default compose(ChangeNameModal)(
  withModalActions,
  withAccountActions({
    accountId: ({ modalContext }) => modalContext
  }),
  connect(mapStateToProps, null),
  (Wrapped) => (props) =>
    (
      <Wrapped
        {...props}
        initialValues={{
          email_address: props.user && props.user.email_address
        }}
      />
    ),
  reduxForm<FormValues, InjectedProps>({
    form: 'changeUserEmail',
    destroyOnUnmount: true,
    validate: (values, props) => {
      const foundErrors: FormValues = {};
      if (values.email_address !== values.email_confirm) {
        foundErrors.email_confirm = 'Email fields should match';
      }
      return foundErrors;
    },
    onSubmit: (values, dispatch, props) => {
      props.requestEmailChange(
        values.email_address,
        values.password_check,
        'changeUserEmail'
      );
    }
  })
);
