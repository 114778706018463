import React, { useCallback, useEffect, useId, useRef } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

interface SuggestionsInputFieldProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  name: string;
  onChangeHandler: (event: React.ChangeEvent) => void;
  onChange: (event: React.ChangeEvent) => void;
  inputValue: string;
  ref: any;
  onFocus: (event: React.ChangeEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  inputFieldPlaceholder?: string;
  isOpen?: boolean;
  value?: string;
  autoFocus?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      border: 'none',
      display: 'inline',
      flexGrow: 1,
      minHeight: 27,
      marginBottom: 4,
      marginLeft: 7,
      background: 'inherit',
      color: theme.customPalette.primary.textWhiteHighEmphasis,
      ...theme.typographyStyles.body2,
      '&::placeholder': {
        color: theme.customPalette.text.onBase.contrast3
      },
      '&::selection': {
        background: theme.customPalette.surfaceAccent.onBase.selected2
      },
      '&:focus': {
        outline: 'initial',
        outlineOffset: 'initial'
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 100px ${theme.customPalette.surface.base2} inset`,
        WebkitTextFillColor: theme.customPalette.primary.textWhiteHighEmphasis
      },
      '&:before, &:after': {
        display: 'none'
      }
    }
  })
);

export default function SuggestionsInputField(
  props: SuggestionsInputFieldProps
) {
  const inputEl = useRef<HTMLInputElement>(null);
  const classes = useStyles({});
  const { inputField } = classes;
  const { className = '', error, isOpen, autoFocus, ...restProps } = props;

  const hasFocus = useRef(false);

  useEffect(() => {
    if (isOpen) {
      if (!hasFocus.current) {
        window.getSelection().empty();
        inputEl.current.focus();
        inputEl.current.select();
        hasFocus.current = true;
      }
    } else {
      if (hasFocus.current) {
        hasFocus.current = false;
      }
    }
  }, [isOpen]);

  const uniqueId = useId();

  const handleRef = useCallback((node) => {
    inputEl.current = node;
  }, []);

  return (
    <input
      className={[inputField, className].join(' ')}
      {...restProps}
      ref={handleRef}
      autoFocus={autoFocus}
      // NOTE: THIS is a hack to prevent autocomplete (since we already provide our own)
      // Browsers ignore autocomplete="off"
      // see https://stackoverflow.com/questions/43132693/how-to-turn-off-html-input-form-field-suggestions
      name={`${restProps.name}__${uniqueId}`}
      autoComplete="new-password"
    />
  );
}
