import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { useClassName } from '@cube3/common/utils/useClassName';

const getMargin =
  (theme) =>
  ({ margin }: Props): number | string => {
    switch (margin) {
      case 'large':
        return theme.customSpacing.margin[12];
      case 'medium':
        return theme.customSpacing.margin[4];
      case 'small':
      default:
        return theme.customSpacing.margin[1];
    }
  };

const getWidth = ({ width }: Props) => width || '100%';
const getHeight = ({ height }: Props) => height || '100%';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes fade-in': {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    root: {
      width: getWidth,
      height: getHeight,
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      padding: getMargin(theme),
      boxSizing: 'border-box'
    },
    fadeIn: {
      animationFillMode: 'both',
      animation: '$fade-in 0.75s ease-in 0.75s 1'
    },
    inner: {
      background: theme.customPalette.backgroundAccent.whiteAccent2, // TODO GET THEME COLOR
      width: '100%',
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
      borderRadius: theme.surfaceCorners.lowEmphasis,
      // insert on top of element.
      '&::after': {
        animation: '$wave 2.3s linear infinite',
        // animationDelay: '0.5s',
        background: `linear-gradient(90deg, transparent, ${theme.customPalette.backgroundAccent.whiteAccent1}, transparent)`,
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1
      },
      '& > *': {
        opacity: '0',
        pointerEvents: 'none'
      }
    },
    light: {
      background: 'none',
      '&::after': {
        animation: '$wave 3.5s linear infinite',
        background: `linear-gradient(90deg, transparent, ${theme.customPalette.backgroundAccent.whiteAccent2}, transparent)`
      }
    },
    feather: {
      background: 'none',
      '&::after': {
        animation: 'none',
        background: theme.customPalette.backgroundAccent.whiteAccent2,
        opacity: 0.8
      }
    },
    heavy: {
      background: theme.customPalette.backgroundAccent.whiteAccent1, // TODO GET THEME COLOR
      '&::after': {
        background: `linear-gradient(90deg, transparent, ${theme.customPalette.backgroundAccent.whiteAccent1},  ${theme.customPalette.backgroundAccent.whiteAccent2})`
      }
    },
    // animate the transform of the
    '@keyframes wave': {
      '0%': {
        transform: 'translate3d(-150%, 0, 0)' // we let it fade a little bit further so there is more pause between the iterations
      },
      '100%': {
        transform: 'translate3d(150%, 0, 0)' // we let it fade a little bit further so there is more pause between the iterations
      }
    },
    txt: {
      visibility: 'hidden' // to display something, check if this is needed
    },
    rounded: {
      borderRadius: '999px'
    }
  })
);

interface Props {
  variant?: 'rounded' | 'rectangle';
  width?: string | number;
  height?: string | number;
  margin?: 'small' | 'medium' | 'large';
  weight?: 'feather' | 'light' | 'medium' | 'heavy';
  fadeIn?: boolean;
}

/** Draws UI for Shimmer, takes 100% of parent component, so make sure to wrap Shimmer in child */
export const Shimmer: React.FC<React.PropsWithChildren<Props>> = React.memo(
  (props) => {
    const {
      children,
      variant = 'rectangle',
      weight = 'medium',
      fadeIn = true
    } = props;
    const classes = useStyles(props);

    return (
      <div className={classes.root}>
        <div
          className={useClassName(
            classes.inner,
            variant === 'rounded' && classes.rounded,
            weight === 'feather' && classes.feather,
            weight === 'light' && classes.light,
            weight === 'heavy' && classes.heavy,
            fadeIn && classes.fadeIn
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);
export default Shimmer;
