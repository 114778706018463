import React, { useEffect, useState } from 'react';
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import SelectComponent from '@cube3/ui/src/select/SelectComponent';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useCurrentUser } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { MenuItem } from '@material-ui/core';
import Button from '@cube3/ui/src/Buttons/Button';
import { useCallback } from 'react';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

export const ConfirmRemoveConnectedAccountModal = (props) => {
  const { modalContext } = props;
  const { service, onConfirm, onCancel } = modalContext;
  return (
    <GenericNoticeModalUI
      title={`Disconnect ${service} account`}
      text={`You will no longer be able to export using this ${service} account`}
      renderTwoButtons={true}
      secondButtonClickHandler={onConfirm}
      buttonText="Cancel"
      secondButtonText="Disconnect"
      secondButtonColorVariant="filledDanger"
      onClose={onCancel}
    />
  );
};

export const ConfirmRevokeConnectedAccountModal = (props) => {
  const { modalContext } = props;
  const { service, onConfirm, onCancel, exportTarget } = modalContext;

  const account = useResource__ALPHA({
    resourceId: exportTarget?.relationships.belongs_to.id,
    resourceType: 'account'
  }).first;

  const name = useResource__ALPHA({
    resourceId: account?.relationships.user.id,
    resourceType: 'user'
  }).first?.full_name;

  return (
    <GenericNoticeModalUI
      loading={!name}
      title={`Revoke ${service} authorization`}
      text={`${name} will no longer be able to export using this ${service} account`}
      renderTwoButtons={true}
      buttonText="Cancel"
      secondButtonClickHandler={onConfirm}
      secondButtonText="Revoke"
      secondButtonColorVariant="filledDanger"
      onClose={onCancel}
    />
  );
};

export const ConnectAccountPickWorkspaceModal = (props) => {
  const { modalContext } = props;
  const { service, onConfirm, onCancel, exportTargetType } = modalContext;

  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const { resources: workspaces, status: workspacesStatus } =
    useResourceList__ALPHA({
      resourceId: exportTargetType.id,
      resourceType: 'export-target-type',
      edgeType: 'workspace',
      edgeLabel: 'workspaces'
    });

  const [userId] = useCurrentUser();
  const { resources: accounts } = useResourceList__ALPHA({
    resourceId: userId,
    resourceType: 'user',
    edgeType: 'account',
    edgeLabel: 'accounts'
  });

  const workspaceAccounts = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: selectedWorkspace,
    edgeType: 'account',
    edgeLabel: 'accounts'
  });

  const account = workspaceAccounts.resources?.find((a) =>
    accounts?.find((a2) => a2.id === a.id)
  );

  const privileges = useResourceList__ALPHA({
    resourceType: 'role',
    resourceId: account?.relationships.role.id,
    edgeType: 'privilege',
    edgeLabel: 'privileges'
  });

  const allowed = isAllowed(exportTargetType, privileges);

  const handleConnectClick = useCallback(() => {
    onConfirm(selectedWorkspace);
  }, [selectedWorkspace, onConfirm]);

  const privilegeLoaded = privileges.status === statuses.SUCCESS && account;

  useEffect(() => {
    if (workspaces?.length === 1) {
      if (selectedWorkspace && onConfirm && privilegeLoaded && allowed) {
        onConfirm(selectedWorkspace);
      } else {
        if (!selectedWorkspace) {
          setSelectedWorkspace(workspaces[0].id);
        }
      }
    }
  }, [workspaces, selectedWorkspace, onConfirm, privilegeLoaded, allowed]);

  if (workspacesStatus !== statuses.SUCCESS || workspaces.length === 1) {
    if (privilegeLoaded && !allowed) {
      return (
        <ModalNoticeUI
          title={`Connect ${service} account`}
          onCloseEvent={onCancel}
          footerRightComponent={
            <>
              <Button onClick={onCancel}>Close</Button>
            </>
          }
        >
          <Typography color="danger1">
            {"Your role doesn't have export privileges in this workspace"}
          </Typography>
        </ModalNoticeUI>
      );
    }
    return null;
  }

  const loading =
    !exportTargetType || workspaceAccounts.loading || privileges.loading;

  if (!workspaces.length) {
    return (
      <ModalNoticeUI
        loading={loading}
        title={`${service} not available`}
        onCloseEvent={onCancel}
        footerRightComponent={
          <>
            <Button onClick={onCancel} colorVariant="filled1">
              Close
            </Button>
          </>
        }
      >
        <Typography>
          {`This integration hasn't been activated for any of your workspaces.
          Visit the workspace Admin Center for more details.`}
        </Typography>
      </ModalNoticeUI>
    );
  }

  return (
    <ModalNoticeUI
      loading={loading}
      title={`Connect ${service} account`}
      onCloseEvent={onCancel}
      footerRightComponent={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            onClick={handleConnectClick}
            colorVariant="filled1"
            disabled={!selectedWorkspace || !allowed}
          >
            Connect
          </Button>
        </>
      }
    >
      {privilegeLoaded && !allowed && (
        <Typography color="danger1">
          {"Your role doesn't have export privileges in this workspace"}
        </Typography>
      )}
      <SelectComponent
        labelName={'Pick a workspace'}
        value={selectedWorkspace}
        onChange={setSelectedWorkspace}
        showLabel={true}
        placeholder="Pick a Workspace"
        data-cy="select-connect-to-workspace"
        renderValue={(workspaceId) =>
          workspaces.find((w) => w.id === workspaceId)?.display_name
        }
      >
        {workspaces.map((w) => (
          <MenuItem key={w.id} value={w.id}>
            <Typography>{w.display_name} </Typography>
          </MenuItem>
        ))}
      </SelectComponent>
    </ModalNoticeUI>
  );
};

const isAllowed = (exportTargetType, privileges) => {
  switch (exportTargetType.display_name) {
    case 'YouTube':
      return privileges.resources?.find((p) => p.key === 'EXPORT_YOUTUBE');
    case 'Meta':
    case 'Facebook':
      return privileges.resources?.find((p) => p.key === 'EXPORT_META');
    case 'CM360':
    case 'cm360':
      return privileges.resources?.find((p) => p.key === 'EXPORT_CM360');
    default:
      return privileges.resources?.find(
        (p) =>
          p.key === `EXPORT_${exportTargetType.display_name?.toUpperCase()}` ||
          p.key === `EXPORT_${exportTargetType.id?.toUpperCase()}`
      );
  }
};
