import { Tag } from '@cube3/common/model/schema/resources/tag';
import { findMatchingTag } from './findMatchingTag';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';

/**
 * @param set set of tags
 * @param tag tag
 * @returns new array containing set and tag
 */
export const addUniqueTag = (
  set: Array<EditableTag | Tag>,
  tag: EditableTag | Tag
): Array<EditableTag | Tag> => {
  if (!findMatchingTag(set)(tag)) {
    return [...set, tag];
  }
  return set;
};
