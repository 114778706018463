import { FSA } from '../flux-standard-action';
import { selectors as nodeSelectors } from './resource-nodes';
import { makeParameterizedSelector } from '../utils/makeParameterizedSelector';
import {
  getResourceEdgesByLabelMemo,
  getResourceEdgeStub
} from './resource-edges/selectors';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';

// State
// {
//  [resourceId]: { [connectedResourceId]:{...edgeMeta}, ... }
//  ...
// }
const initialState = {
  uploads: []
};

// Actions
const UPLOAD_FILES_TO_FOLDER = 'cube3/uploads/UPLOAD_FILES_TO_FOLDER';
const UPLOAD_FILES_TO_PARENT_RESOURCE =
  'cube3/uploads/UPLOAD_FILES_TO_PARENT_RESOURCE';
const CLEAR_PARENT_RESOURCE_UPLOADS =
  'cube3/uploads/CLEAR_PARENT_RESOURCE_UPLOADS';
const UPLOAD_WITH_FOLDER_STRUCTURE =
  'cube3/uploads/UPLOAD_WITH_FOLDER_STRUCTURE';

const actions = {
  UPLOAD_FILES_TO_FOLDER,
  UPLOAD_FILES_TO_PARENT_RESOURCE,
  CLEAR_PARENT_RESOURCE_UPLOADS,
  UPLOAD_WITH_FOLDER_STRUCTURE
};

// ActionCreators

const uploadFilesToFolder = (files, parentFolderId, metadata?) => {
  return {
    type: actions.UPLOAD_FILES_TO_FOLDER,
    payload: { files, parentFolderId, metadata },
    meta: {}
  };
};
const uploadWithFolderStructure = (tree, parentFolderId, metadata?) => {
  return {
    type: actions.UPLOAD_WITH_FOLDER_STRUCTURE,
    payload: { tree, parentFolderId, metadata },
    meta: {}
  };
};

const uploadFilesToParentResource = (
  files,
  parentResource: ResourceIdentifier,
  metadata?
) => {
  return {
    type: actions.UPLOAD_FILES_TO_PARENT_RESOURCE,
    payload: { files, parentResource, metadata },
    meta: {}
  };
};
const clearParentResourceUploads = (parentResource: ResourceIdentifier) => {
  return {
    type: actions.CLEAR_PARENT_RESOURCE_UPLOADS,
    payload: { parentResource },
    meta: {}
  };
};

const actionCreators = {
  uploadFilesToFolder,
  uploadFilesToParentResource,
  clearParentResourceUploads,
  uploadWithFolderStructure
};

// Selectors

const emptyArray = [];

const getUploadsByParentResourceMemo = makeParameterizedSelector(
  'getUploadsByParentResource',
  (parentResource) => {
    if (!parentResource) {
      return (query) => query((state) => undefined);
    }
    return (query) => {
      const uploads = query(
        (state) =>
          getResourceEdgesByLabelMemo(
            state,
            parentResource.id,
            parentResource.type,
            'file-uploads',
            'file-upload'
          ) || emptyArray
      );

      return uploads
        .map((e) => query((state) => getResourceEdgeStub(state, e)))
        .filter((n) => !n.__deleted__);
    };
  },
  (parentResource) =>
    parentResource ? `${parentResource.type}-${parentResource.id}` : null
);

const getUploadsByParentResource = (state, parentResource) => {
  return getUploadsByParentResourceMemo(parentResource)(state);
};

const getUploadsByFolder = (state, parentFolderId) => {
  if (!parentFolderId) {
    return;
  }
  return getUploadsByParentResourceMemo({
    type: 'folder',
    id: parentFolderId
  })(state);
};

const getUploadsByWorkspace = (state, workspaceId) => {
  if (!workspaceId) {
    return;
  }
  return getUploadsByParentResourceMemo({
    type: 'workspace',
    id: workspaceId
  })(state);
};

const getAllUploads = nodeSelectors.makeGetResourcesByType('file-upload');

const selectors = {
  getUploadsByParentResource: getUploadsByParentResource,
  getUploadsByFolder: getUploadsByFolder,
  getUploadsByWorkspace: getUploadsByWorkspace,
  getAllUploads: getAllUploads
};

// Reducers
// NOTE: everything happens in the middlewares now
const edgesReducer = (state = initialState, action: FSA) => {
  const { type } = action;

  switch (type) {
    // case UPLOAD_FILES_TO_FOLDER:
    //   return {
    //     uploads: [
    //       ...state.uploads,
    //       ...payload.files.map(f => ({
    //         parentFolderId: payload.parentFolderId,
    //         file: f
    //       }))
    //     ]
    //   };

    default:
      return state;
  }
};

// Exports
export { actions, actionCreators, selectors };
export { edgesReducer as reducer };
export default edgesReducer;
