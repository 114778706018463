import React from 'react';
import { Chip } from '@cube3/ui/src/chips/Chip';
import { Bytes, Time, Year } from '@cube3/ui/src/human';
import FileType from '@cube3/ui/src/human/FileType';
import {
  ResolverProps,
  KeyValue
} from '@cube3/ui/src/metadata/MetadataReadonlyUI';
import { ResourceChip } from '../app/layout/Contracts/prefabs/MultipleResourcePicker/ResourceChip';
import { useModalActions } from '../app/layout/Modals/modalActions';
import { FORMAT_C3_YEAR_DATE } from '@cube3/state/src/redux/components/Hooks/metadataHooks';
import { WaterfallValue } from '@cube3/ui/src/human/WaterfallValue';
import { Cube3AnchorLink } from '../app/routing/Cube3Link';
import { isValidURL } from '@cube3/ui/src/keyboard-shortcuts/utils';

/// @ Author : Simon @AmbassadorsLab
/// <Summary>
/// Combines human readable components in 1 and will resolve
/// a key and value to a component. If you would like to access
/// specific behaviour, like displaying time in a different format,
/// then please import that component individually.
///
/// Possible entries:
/// {key: "Type", value: "application/zip"} will resolve to ZIP File
/// {key: "File Type", value: "application/zip"} will resolve to ZIP File
/// {key: "Last opened", value: "2018-08-08"} will resolve to 8 Aug 2018
/// </Summary>

interface Properties extends ResolverProps {
  keyValuePair: KeyValue;
  dataType?:
    | 'date'
    | 'mimetype'
    | 'filesize'
    | 'string'
    | 'multiselect'
    | 'waterfall_select'
    | 'multiple_email'
    | 'resources'
    | 'resource_picker'
    | 'number'
    | 'email'
    | 'asset' // attachments
    | 'textarea'
    | 'text';
  dataFormat?: string;
}

interface Chip {
  name: string;
  onClick(): void;
}

const HumanReadableResolver: React.FunctionComponent<Properties> = (props) => {
  const { keyValuePair, dataType, dataFormat } = props;
  // // return the keypair value if we are dealing with an object or other types than string.
  // if (!isSingleStringValue) {
  //   return <div>{keyValuePair.value}</div>;
  // }

  const modalActions = useModalActions();

  switch (dataType) {
    case 'date':
      if (dataFormat === FORMAT_C3_YEAR_DATE) {
        return <Year date={keyValuePair.value as string} />;
      }

      return (
        <Time
          date={keyValuePair.value as string}
          toolTip={true}
          placement="top"
          noTime={true}
        />
      );
    case 'filesize':
      return (
        <Bytes
          size={keyValuePair.value as string}
          inFormat="B"
          fullForm={false}
          placement="top"
          toolTip={true}
        />
      );
    case 'mimetype':
      return <FileType mimeType={keyValuePair.value as string} />;

    case 'multiple_email':
      return (
        <>
          {(keyValuePair.value as string[])?.join(', ')}
          {!(keyValuePair.value || (keyValuePair.value as any[])?.length) &&
            'none'}
        </>
      );
    case 'multiselect':
      return keyValuePair.value.join(', ');

    case 'waterfall_select':
      return <WaterfallValue value={keyValuePair.value} />;

    case 'resources':
    case 'resource_picker':
      return (
        <>
          {(keyValuePair.value as Chip[])?.map((a) => {
            return <Chip key={a.name} text={a.name} onClick={a.onClick} />;
          })}
          {!(keyValuePair.value || (keyValuePair.value as any[])?.length) &&
            'none'}
        </>
      );

    case 'asset':
      return (
        <>
          {(keyValuePair.value as Chip[])?.map((assetId) => {
            return (
              <ResourceChip
                key={assetId}
                resource={{ id: assetId, type: 'asset' }}
                onClick={() => {
                  modalActions.openModal('attachment_preview', { assetId });
                }}
              />
            );
          })}
          {!(keyValuePair.value || (keyValuePair.value as any[])?.length) &&
            'none'}
        </>
      );
    case 'textarea':
      return <pre style={{ margin: 0 }}>{keyValuePair.value}</pre>;
    case 'text':
    case 'string':
      if (isValidURL(keyValuePair.value)) {
        return (
          <Cube3AnchorLink
            href={keyValuePair.value}
            target="_blank"
            underline={true}
          >
            {keyValuePair.value}
          </Cube3AnchorLink>
        );
      }
      break;
    default:
      break;
  }

  // check if we can find a match by comparing the field key with known keys
  switch (keyValuePair.key) {
    case 'Last Opened':
    case 'Expiration Date':
    case 'Last Modified':
      return (
        <Time
          date={new Date(keyValuePair.value as string)}
          fullForm={false}
          toolTip={false}
          placement="top"
        />
      );

    case 'Size':
      return (
        <Bytes
          size={keyValuePair.value as string}
          inFormat="B"
          fullForm={false}
          placement="top"
          toolTip={true}
        />
      );

    case 'File Type':
    case 'Type':
      return <FileType mimeType={keyValuePair.value as string} />;

    // if we have no specific component for this entry, just return the value.
    default:
      return <div>{keyValuePair.value}</div>;
  }
};

export default HumanReadableResolver as React.ComponentType<ResolverProps>;
