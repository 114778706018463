import { ThemeProvider } from '@emotion/react';
import { useCubicleTheme } from './CubicleThemeProvider';
import { createTheme } from '@mui/system';
import React, { PropsWithChildren, useMemo } from 'react';

export const CubicleToMuiThemeProvider: React.FC<PropsWithChildren> =
  React.memo((props) => {
    const { theme } = useCubicleTheme();

    const muiTheme = useMemo(() => {
      return { ...theme, ...createTheme({}), spacing: theme.spacing };
    }, [theme]);

    return <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>;
  });
