import React, { useCallback } from 'react';

// UI
import { Typography } from '@cube3/ui/src/typography/Typography';
import { GenericFieldWithErrorsProps } from '../GenericFieldWithErrors';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { SelectProps as SelectPropsInterface } from '@material-ui/core/Select';
import { Field } from 'redux-form';
import { SelectLevel1 } from './SelectLevel1';
import { SelectLevel2 } from './SelectLevel2';
import GeneralSVG from '@cube3/ui/src/icons/GeneralSVG';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form';

/**
 * special drilldown type component where the option picked in the first field
 * impacts the options available in the second field
 *
 * field config can look like this
 * ```
 *    {
 *         id: 'category',
 *         value_type: 'waterfall_select',
 *         display_name: 'Category',
 *         required: true,
 *         json_schema: `{
 *           "$schema": "https://json-schema.org/draft/2019-09/schema",
 *           "type": "object",
 *           "properties": {
 *             "Social": {
 *               "type": "array",
 *               "minItems": 1,
 *               "items": {
 *                 "type": "string",
 *                 "enum": [
 *                   "Instagarb",
 *                   "Toktok"
 *
 *                 ]
 *               }
 *             },
 *             "Web": {
 *               "type": "array",
 *               "minItems": 1,
 *               "items": {
 *                 "type": "string",
 *                 "enum": [
 *                   "Vlog",
 *                   "Banner"
 *                 ]
 *               }
 *             }
 *           },
 *              "oneOf": [
 *             {
 *               "required": [
 *                 "Social"
 *               ]
 *             },
 *             {
 *               "required": [
 *                 "Web"
 *               ]
 *             }
 *           ]
 *         }`
 *       }
 *       ```
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      minHeight: '20px',
      minWidth: '50px',
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      borderColor: theme.customPalette.primary.textWhiteMediumEmphasis
    },
    menugroup: {
      minWidth: '225px',
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  })
);

interface SelectFieldComponentProps extends GenericFieldWithErrorsProps {
  // object that describes the options for level1 and suboptions for all the potential level2's
  options: { [keys: string]: string[] };
  // passed on to Select Component
  selectProps?: SelectPropsInterface;
  // wheter clear buttons should be shown, when applicable (usually false if field is required)
  showClearButton?: boolean;
}

function WaterfallSelectFieldComponent(props: SelectFieldComponentProps) {
  const classes = useStyles(props);
  const {
    placeholder = 'Select an option',
    input,
    meta,
    options,
    selectProps,
    disabled,
    showErrors = true,
    showLabel,
    label,
    showClearButton = false
  } = props;

  const dispatch = useDispatch();

  // make sure the main field is marked as touched when the second dropdown was changed
  // otherwise validation errors remain hidden
  const handleTouch = useCallback(() => {
    dispatch(touch(meta.form, input.name));
  }, [meta.form, dispatch, input.name]);

  // we render two selectfields that both call the onChange handler of the main Field component
  // because otherwise the main field doesn't trigger parsers / formatters validators, when the selections change
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Field
          name={`${input.name}.input.level1`}
          component={SelectLevel1}
          props={{
            parentInput: input,
            options,
            classes,
            placeholder,
            selectProps,
            disabled,
            showLabel,
            label,
            showClearButton
          }}
        />
        {options[input.value?.input.level1 as string]?.length > 0 && (
          <>
            <GeneralSVG path="iconKeyboardArrowRight" />
            {input.value?.input.level1 ? (
              <Field
                name={`${input.name}.input.level2`}
                component={SelectLevel2}
                onBlur={handleTouch}
                props={{
                  parentInput: input,
                  options,
                  classes,
                  selectProps: {
                    ...selectProps,
                    MenuProps: {
                      variant: 'menu'
                    }
                  },
                  showLabel,
                  label,
                  showClearButton
                }}
              />
            ) : (
              <GeneralSVG path="iconElepsis" />
            )}
          </>
        )}
      </div>

      {(showErrors === true ||
        (showErrors === 'touched' &&
          (meta.touched || input.value.input.level1))) &&
        meta.error && <Typography color="danger1">{meta.error}</Typography>}
    </>
  );
}

export default WaterfallSelectFieldComponent;
