import React, { useCallback } from 'react';
import { ExportInputPicker } from '@cube3/ui/src/exports/subcomponents/ExportInputPicker';
import { validEmailaddress } from '../../../../forms/helpers/validators';
import { Chip } from '@cube3/ui/src/chips/Chip';

interface Props {
  recipients: string[];
  setRecipients: React.Dispatch<React.SetStateAction<string[]>>;
}

export const NotificationRecipientsField = ({
  recipients,
  setRecipients
}: Props) => {
  const addRecipient = useCallback(
    (val: string) => {
      setRecipients(prev => {
        if (!prev.includes(val)) {
          return [...prev, val];
        }
        return prev;
      });
    },
    [setRecipients]
  );

  const deleteRecipient = useCallback(
    (val?: string) => {
      setRecipients(prev => {
        if (!val) {
          return prev && prev.slice(0, -1);
        }
        return prev.filter(email => email !== val);
      });
    },
    [setRecipients]
  );

  return (
    <ExportInputPicker
      label="Send export notifications to"
      type="multiple input"
      items={recipients}
      placeholder="Enter email address"
      validator={val => validEmailaddress(val, null, null, null)}
      onAdd={addRecipient}
      onDelete={deleteRecipient}
      component={val => (
        <Chip maxWidth={490} text={val} onCrossClick={deleteRecipient} />
      )}
    />
  );
};
