import React, { useCallback, useMemo, useState } from 'react';
import { ModalNoticeUI } from '../../../../../Modal/ModalNoticeUI';
import Button from '../../../../../Buttons/Button';
import { Typography } from '../../../../../typography/Typography';

export const usePreventDirtyClose = ({
  dirty,
  handleCloseEvent,
  promptTitle = 'Cancel editing?',
  promptMessage = 'Closing will discard current changes'
}) => {
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);

  const handleBackdropClick = useCallback(
    (ev) => {
      ev.stopPropagation();

      if (dirty) {
        setShowConfirmCancel(true);
      } else {
        handleCloseEvent();
      }
    },
    [dirty, setShowConfirmCancel, handleCloseEvent]
  );

  const extraModalProps = useMemo(() => {
    return {
      onBackdropClick: handleBackdropClick,
      disableBackdropClick: dirty
    };
  }, [handleBackdropClick, dirty]);

  const promptElement = useMemo(() => {
    return showConfirmCancel ? (
      <ModalNoticeUI
        title={promptTitle}
        footerRightComponent={
          <>
            <Button onClick={() => setShowConfirmCancel(false)}>Cancel</Button>
            <Button
              onClick={() => handleCloseEvent()}
              colorVariant="filledDanger"
            >
              Discard
            </Button>
          </>
        }
      >
        <Typography>{promptMessage}</Typography>
      </ModalNoticeUI>
    ) : null;
  }, [
    showConfirmCancel,
    handleCloseEvent,
    setShowConfirmCancel,
    promptMessage,
    promptTitle
  ]);

  return { extraModalProps, promptElement };
};
