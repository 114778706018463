import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export type basePath =
  | '/'
  | '/share-link/'
  | '/review/'
  | '/workspace/'
  | '/account/settings/'
  | '/account/login/'
  | '/account/sign-up/'
  | '/review-link/'
  | '/account/sign-out/';

/** Simple helper with basepaths that pushes to react history  
@example const linkOpener = useOpenInternalLink();
linkOpener.openLink('/review-link/')
*/
export function useOpenInternalLink() {
  const history = useHistory();

  const openHardLink = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  // gives you a suggestion on what base path to choose, leaving out human error. Would be great to extend these parameters with subpaths,
  // but that grows more complex since you can then choose invalid paths
  /** Pushes new route to `react-router` */
  const openLink = useCallback(
    (basepath?: basePath, suffix?: string) => {
      if (basepath) {
        history.push(basepath.toString() + suffix);
      } else {
        history.push(suffix);
      }
    },
    [history]
  );

  // NOTE: if in the future we are going to use this to open links outside of the app hostname/domain, we have to make sure we don't send window.opener info.
  // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/open
  /** opens the link in a new tab in the browser. This avoids react router, so we have to get the location origin */
  const openInNewTab = useCallback(
    (
      basePath: basePath,
      suffix?: string,
      /** Allow unsafe access to opener window and opener sessionStorage  */
      withOpener?: { __UNSAFE: true }
    ): void => {
      window.open(
        window.location.origin + basePath.toString() + suffix,
        '_blank',
        withOpener?.__UNSAFE === true ? undefined : 'noopener noreferrer'
      );
    },
    []
  );

  return { openLink, openInNewTab, openHardLink };
}

export interface WithOpenLinkProps {
  openHardLink: (url: string) => void;
  openLink: (basePath: basePath, suffix?: string) => void;
  openInNewTab: (
    basePath: basePath,
    suffix?: string,
    /** Allow unsafe access to opener window and opener sessionStorage  */
    withOpener?: { __UNSAFE: true }
  ) => void;
}

const withOpenInternalLink =
  <P extends Partial<WithOpenLinkProps>>(
    WrappedComponent: React.ComponentType<P>
  ) =>
  (props) => {
    const mapping = useOpenInternalLink();
    return <WrappedComponent {...props} {...mapping} />;
  };

export default withOpenInternalLink;
