import { Related } from '../../resource-types';
import { ExportTargetImplementations, IntegrationType } from '../../types';
import { RawModel } from '../schema';

export interface ExportTarget<
  T extends keyof ExportTargetImplementations = any
> {
  id: string;
  type: 'export-target';
  display_name: string;
  attributes: {
    display_name: string;
    integration_type: IntegrationType;
    integration_config: T extends unknown
      ? any
      : ExportTargetImplementations[T]['integration_config'];
  };
  relationships: {
    'export-target-type': Related<'export-target-type'>;
    belongs_to: null | Related<'workspace' | 'user' | 'account'>;
  };
}

export const exportTarget: RawModel = {
  type: 'export-target',
  plural: 'export-targets',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      binding: ['one', 'many'],
      reverse: 'export-target'
    },
    user: {
      type: 'user',
      binding: ['one', 'many'],
      reverse: 'export-target'
    },
    account: {
      type: 'account',
      binding: ['one', 'many'],
      reverse: 'export-target'
    }
  }
};
