import { useState, useEffect, useRef } from 'react';

function UseElementResize() {
    const elementRef = useRef(null)
    const [width, setWidth] = useState(0)

    function updateWidth() {
        if (elementRef.current) {
            let element: HTMLElement = elementRef.current;
            setWidth(element.clientWidth)
        }
    }

    useEffect(() => {
        updateWidth()
        window.addEventListener('resize', updateWidth, true);
        return () => {
            window.removeEventListener('resize', updateWidth, true)
        }
    })
    return {
        width,
        elementRef
    }
}

export default UseElementResize;