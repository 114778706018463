import { PublicResource, Related } from '../../resource-types';
import { RawModel } from '../schema';

export interface WorkspacePreferences extends PublicResource {
  default_shares_download_type:
    | 'DOWNLOAD_TYPE_PREVIEW'
    | 'DOWNLOAD_TYPE_ORIGINAL';
  default_shares_download_enabled: boolean;
  default_shares_expiration_enabled: boolean;
  default_shares_expiration_duration: number;
  default_shares_show_dates: boolean;
  default_shares_show_tags: boolean;
  default_shares_password_enabled: boolean;
  default_filerequests_expiration_duration: number;
  default_contract_recipients: string[];
  default_project_template_id: string;
  project_template_required: boolean;
  project_navigation_fields: string[];
  first_contract_reminder: number;
  second_contract_reminder: number;
  third_contract_reminder: number;
  relationships: {
    default_teams: Related<'team'>[];
    legacy_library_project: Related<'project'>;
  };
  default_labeling: boolean;
}

export const workspacePreference: RawModel = {
  type: 'workspace-preference',
  plural: 'workspace-preferences',
  JSONApi: true,
  serverType: 'workspace-preference',
  attributes: {
    id: 'UUID',
    default_shares_download_type: 'string',
    default_contract_recipients: 'stringArray'
  },
  relationships: {
    workspace: {
      type: 'workspace',
      reverse: 'workspace-preferences',
      binding: ['one', 'one']
    }
  }
};
