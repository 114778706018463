import { ConsentVersion } from './state';

export const getCurrentConsentVersion = (fromEnv): ConsentVersion => {
  if (fromEnv) {
    return fromEnv.split('.').map(nr => parseInt(nr)) as ConsentVersion;
  } else {
    console.warn(
      'No cookie consent version set (REACT_APP_COOKIE_CONSENT_VERIONS env var)'
    );
    return [-1, -1, -1];
  }
};

export const compareConsentVersions = (
  a: ConsentVersion,
  b: ConsentVersion
) => {
  if (a && !b) {
    return 1;
  }

  if (!a && b) {
    return -1;
  }

  if (a[0] - b[0] !== 0) {
    return Math.max(-1, Math.min(1, a[0] - b[0]));
  }

  if (a[1] - b[1] !== 0) {
    return Math.max(-1, Math.min(1, a[1] - b[1]));
  }

  if (a[2] - b[2] !== 0) {
    return Math.max(-1, Math.min(1, a[2] - b[2]));
  }
  return 0;
};
