import React, { useCallback, useState } from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import checkerboardImage from '../assets/images/checkerboard.svg?url';
import assetImage from '../assets/images/Library/file.svg?url';
import { useClassName } from '../utils/useClassName';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundImage: 'none',
      position: 'absolute',
      backgroundRepeat: 'repeat',
      maxWidth: '100%',
      maxHeight: '100%'
    },
    checkerboard: {
      backgroundImage: `url('${checkerboardImage}')`
    }
  })
);

interface Props extends React.ImgHTMLAttributes<{}> {
  showCheckerboard?: boolean;
}

export const ImageThumbnailFit = React.forwardRef<HTMLImageElement, Props>(
  (props, ref) => {
    const classes = useStyles();

    const { showCheckerboard, ...restProps } = props;

    const className = useClassName(
      classes.root,
      showCheckerboard && classes.checkerboard
    );

    const [error, setError] = useState(false);
    const handleError = useCallback(() => {
      setError(true);
    }, [setError]);

    if (error) {
      return (
        <img
          loading="lazy"
          decoding="async"
          alt={props.alt}
          className={className}
          {...restProps}
          src={assetImage}
          ref={ref}
        />
      );
    }

    return (
      <img
        loading="lazy"
        decoding="async"
        alt={props.alt}
        className={className}
        {...restProps}
        ref={ref}
        onError={handleError}
      />
    );
  }
);

ImageThumbnailFit.displayName = 'ImageThumbnailFit';
