import React from 'react';
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';
import { useModalActions } from '../../Modals/modalActions';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { PROJECT_ROLE_TYPES } from '@cube3/state/types';

interface Role {
  role: 'Admin' | 'Member';
}

interface Props {
  modalContext: {
    projectMemberId: string;
    isYou: boolean;
    name: string;
    newRole: Role;
  };
}
const mutateConfig = { cacheInvalidator: null };

export const ProjectChangeRolePrompt: React.FunctionComponent<Props> = (
  props
) => {
  const { modalContext } = props;

  const { isYou, name, newRole, projectMemberId } = modalContext;

  const modalActions = useModalActions();
  const [mutate] = useMutateResource__ALPHA(mutateConfig);

  const handleRoleChange = React.useCallback(
    (role: Role) => {
      const newRole = PROJECT_ROLE_TYPES[role.role];
      mutate({
        id: projectMemberId,
        type: 'project-member',
        project_member_role: newRole
      });
    },
    [mutate, projectMemberId]
  );

  const defaultText = `Are you sure you want to change ${name}'s role to ${newRole.role}?`;
  const dangerText = `Are you sure you want to change your own role to ${newRole.role}?`;

  return (
    <GenericNoticeModalUI
      renderTwoButtons={true}
      onClose={() => {
        modalActions.previousModal();
      }}
      title={isYou ? 'Change your role?' : 'Change role?'}
      text={isYou ? dangerText : defaultText}
      buttonText="Cancel"
      buttonColorVariant="ghost2"
      secondButtonText="Yes"
      secondButtonColorVariant={isYou ? 'filledDanger' : 'filled1'}
      secondButtonClickHandler={() => {
        handleRoleChange(newRole);
        modalActions.previousModal();
      }}
    />
  );
};
