import React from 'react';
import { Divider } from '../../Surface/Divider';
import { Typography } from '../../typography/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Time } from '../../human';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transform: 'translate(0,12px)',
      display: 'flex',
      alignItems: 'center'
    },
    label: {
      flexShrink: 0
    },
    spacer: { width: 16 },
    divider: {
      flexGrow: 1,
      flexShrink: 1,
      width: '100%'
    }
  })
);

interface Props {
  /** optional iso timestamp string used for label  */
  date?: string;
}

export const ContractListDivider: React.FunctionComponent<Props> = props => {
  const { date } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <Typography color="brand1" typographyStyle="body2">
          {date ? <Time noTime={true} date={date} /> : 'Today'}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.divider}>
        <Divider color="brand1" />{' '}
      </div>
    </div>
  );
};
