import { SketchPad } from '../../modules/sketchPad/sketchpad';

export const drawArrow = (sketchPad: SketchPad, stroke, brush, format) => {
  const points: any[] = stroke.current.points
    .map((p) => [p.rawX, p.rawY])
    .reverse();

  if (points.length < 2) {
    sketchPad.drawStroke(stroke.current, brush, false);
    return;
  }

  const lastIdx = 0;

  const sampleIdx =
    points.findIndex((p) => distance(points[lastIdx], p) > 40 * format.scale) ||
    Math.min(points.length - 1, 4);

  const lastPoints = [points[sampleIdx], points[lastIdx]];

  const dir = normalize(difference(lastPoints[0], lastPoints[1]));

  const angle = 0.18 * Math.PI;
  const arm1 = rotate(dir, angle - Math.random() * Math.PI * 0.02);
  const arm2 = rotate(dir, -angle + Math.random() * Math.PI * 0.02);

  const s = format.scale * 80;

  //   console.info('DEBUG stroke', { dir, angle, arm1, arm2, s }, stroke.current);

  sketchPad.drawStroke(stroke.current, brush, false);

  drawArm(sketchPad, brush, lastPoints[1], arm1, s * (1 + Math.random() * 0.1));
  stroke.current = drawArm(
    sketchPad,
    brush,
    lastPoints[1],
    arm2,
    s * (1 + Math.random() * 0.1)
  );
};

const drawArm = (sketchPad, brush, from, dir, scale) => {
  const stroke = sketchPad?.newStroke(brush);

  sketchPad.addPointToStroke(stroke, from, true);

  sketchPad.addPointToStroke(
    stroke,
    [from[0] + dir[0] * scale, from[1] + dir[1] * scale],
    true
  );

  sketchPad.drawStroke(stroke, brush, false);

  return stroke;
};

const rotate = (vec2, angle) => {
  const xRotated = Math.cos(angle) * vec2[0] - Math.sin(angle) * vec2[1];
  const yRotated = Math.sin(angle) * vec2[0] + Math.cos(angle) * vec2[1];
  return [xRotated, yRotated];
};

const normalize = (vec2) => {
  const l = length(vec2);
  return [vec2[0] / l, vec2[1] / l];
};

const length = (vec2) => {
  return Math.sqrt(vec2[0] * vec2[0] + vec2[1] * vec2[1]);
};

const difference = (p1, p2) => {
  return [p1[0] - p2[0], p1[1] - p2[1]];
};
const distance = (p1, p2) => {
  return length(difference(p1, p2));
};
