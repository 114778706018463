import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { AssetMimeTypes } from '@cube3/common/model/resource-types';
import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';
import { Asset } from '@cube3/common/model/schema/resources/asset';
import { useMemo } from 'react';

export const useRenderPresets = (
  assets: Asset[],
  listAll: boolean,
  mimeTypes?: AssetMimeTypes[]
) => {
  const [workspaceId] = useCurrentWorkspace();

  /** An intersect presets of selected assets */
  const params = useMemo(
    () => ({
      filter: {
        asset_ids: assets?.map((a) => a.id)?.join(',') || ''
      }
    }),
    [assets]
  );
  /** An union of presets for selected media types */
  const params_union = useMemo(() => {
    return listAll
      ? undefined
      : {
          filter: {
            mime_type: mimeTypes?.join(',')
          }
        };
  }, [listAll, mimeTypes]);

  /** workspace presets: only available on certain workspaces */
  const workspacePresets = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params: params_union
  });
  /** workspace presets: filter by asset id should only return presets that have support for all the assets */
  const intersectWorkspacePresets = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params
  });

  /** custom formats(contains preview/original type) */
  const customPresets = useResourceList__ALPHA({
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params: params_union
  });
  /** custom: filter by asset id should only return presets that have support for all the assets*/
  const intersectCustomPresets = useResourceList__ALPHA({
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params
  });

  const loading =
    intersectCustomPresets.loading ||
    customPresets.loading ||
    workspacePresets.loading ||
    intersectWorkspacePresets.loading;

  const allPresets = combinePresets(
    workspacePresets.resources,
    customPresets.resources
  );
  /** All presets will be listed in `Download file` modal */
  const groupedPresets = getGroupedPresets(allPresets);

  /** Presets that have support for all selected assets */
  const availablePresets = combinePresets(
    intersectCustomPresets.resources,
    intersectWorkspacePresets.resources
  );

  return useMemo(
    () => ({
      groupedPresets,
      allPresetsCount: allPresets.length,
      availablePresets,
      loading
    }),
    [groupedPresets, allPresets, availablePresets, loading]
  );
};

export const getGroupedPresets = (presets: RenderPreset[]) => {
  let grouped = {};
  if (presets?.length) {
    grouped = presets.reduce((acc, preset) => {
      const groupName = preset.attributes.group;
      if (groupName) {
        const saved = acc[groupName];
        if (!saved) {
          acc[groupName] = [preset];
        } else if (!saved.find((p) => p.id === preset.id)) {
          acc[groupName].push(preset);
        }
      }

      return acc;
    }, {});
  }
  return grouped as { [groupName: string]: RenderPreset[] };
};

export const combinePresets = (arr1, arr2) => {
  if (!arr1?.length && !arr2?.length) return [];
  return [].concat(arr1 || [], arr2 || []);
};
