import { ProjectMember } from '@cube3/common/model/schema/resources/project-member';
import React from 'react';

const emptyArray = [];

export const useAddExistingToAllProjects = ({
  loading,
  targetProjects = emptyArray,
  noCompare,
  projectMembershipsAdmin = emptyArray,
  projectMembershipsMember = emptyArray,
  userName,
  totalProjects,
  projectRole
}) => {
  const projects = React.useMemo(() => {
    if (loading) {
      return { loaded: targetProjects?.length || 0, total: totalProjects };
    }

    if (noCompare) {
      return {
        disableAdd: false,
        noCompare: true,
        willAdd_count: totalProjects
      };
    }

    /** projects that this user has been added as 'Admin' in current workspace */
    const alreadyAdmin = projectMembershipsAdmin.filter((item) =>
      targetProjects.find(
        (i) => item.relationships.project.id === i.relationships.project.id
      )
    );

    /** projects that this user has been added as 'Member' in current workspace*/
    const alreadyMember = projectMembershipsMember.filter((item) =>
      targetProjects.find(
        (i) => item.relationships.project.id === i.relationships.project.id
      )
    );

    /** projects that this user will be added as 'Member' */
    const willAdd_asMember = filterProjects(targetProjects, [
      ...alreadyMember,
      ...alreadyAdmin
    ]);

    /** projects that this member will be added as 'Admin' */
    const willAdd_asAdmin = filterProjects(targetProjects, alreadyAdmin);

    /** count of projects that will be added / upgraded  */
    const willAdd_count =
      projectRole === 'Member'
        ? willAdd_asMember.length
        : willAdd_asAdmin.length;

    /** check if has added this member to all active projects in current workspace */
    const isInAllProjects =
      alreadyAdmin.length + alreadyMember.length === targetProjects.length;

    const isAdminInAllProjects = willAdd_asAdmin.length === 0;

    /** Disable add cases:
     * - select 'Member', but has added this user to all projects
     * - has added this user to all projects as admin role
     */
    const disableAdd =
      (projectRole === 'Member' && isInAllProjects) || isAdminInAllProjects;

    const upgradeToAdminCount = willAdd_asAdmin.length - alreadyMember.length;

    return {
      alreadyAdmin_count: alreadyAdmin.length,
      alreadyMember_count: alreadyMember.length,
      willAdd_asAdmin_count: willAdd_asAdmin.length,
      willAdd_asMember_count: willAdd_asMember.length,
      willAdd_count,
      upgrade_toAdmin_count: upgradeToAdminCount,
      isInAllProjects,
      isAdminInAllProjects,
      disableAdd
    };
  }, [
    noCompare,
    targetProjects,
    projectMembershipsAdmin,
    projectMembershipsMember,
    projectRole,
    loading,
    totalProjects
  ]);

  const { affectedMessage, confirmedMessage } = getMessages(
    userName,
    projectRole,
    projects
  );

  return {
    confirmedMessage,
    disableAdd: projects.disableAdd,
    affectedMessage
  };
};

/** filter out the projects that have been added from all available projects */
const filterProjects = (
  srcProjects: ProjectMember[],
  projects: ProjectMember[]
) => {
  if (projects.length === 0) return srcProjects;

  return srcProjects.filter(
    (item) =>
      !projects.find(
        (i) => i.relationships.project.id === item.relationships.project.id
      )
  );
};

const amountString = (length: number) => {
  return length === 1 ? '1 project' : `${length} projects`;
};

const getMessages = (userName, projectRole, projects) => {
  let affectedMessage;

  let confirmedMessage = `${userName} has been added to ${amountString(
    projects.willAdd_count
  )}. They have been notified.`;

  const upgradeToAdmin =
    projectRole === 'Admin' && projects.alreadyMember_count > 0;

  const upgradeMsg = `upgrade them to an admin role in ${amountString(
    projects.alreadyMember_count
  )} in which you are an admin.`;

  const defaultMessage = `This will add ${userName} as a${
    projectRole === 'Admin' ? 'n' : ''
  } ${projectRole.toLocaleLowerCase()} to ${amountString(
    projects.willAdd_count
  )} in which you are an admin.`;

  switch (true) {
    case projects.noCompare:
      console.warn(
        'Skipped comparisson due to large ammount of projects in list'
      );
      affectedMessage = defaultMessage;
      break;

    case projects.hasOwnProperty('loaded') && projects.total:
      affectedMessage = `Checking ${projects.loaded} out of ${projects.total} projects`;
      break;

    case projects.hasOwnProperty('loaded'):
      affectedMessage = `Checking project memberships `;
      break;

    case projects.isAdminInAllProjects:
      affectedMessage = `${userName} is already an admin in all projects in which you are an admin.`;
      break;

    // upgrade member role to admin + add to new projects as admin
    case upgradeToAdmin && projects.upgrade_toAdmin_count > 0:
      confirmedMessage = `${userName} has been added to ${amountString(
        projects.upgrade_toAdmin_count
      )}, and upgraded to an admin role in ${amountString(
        projects.alreadyMember_count
      )}. They have been notified.`;

      affectedMessage = `This will add ${userName} as an admin role to ${amountString(
        projects.upgrade_toAdmin_count
      )}, and ${upgradeMsg}`;
      break;

    // upgrade member role to admin in all exsiting projects
    case upgradeToAdmin && projects.upgrade_toAdmin_count === 0:
      confirmedMessage = `${userName} has been upgraded to an admin role in ${amountString(
        projects.alreadyMember_count
      )}. They have been notified.`;

      affectedMessage = `This will ${upgradeMsg}`;
      break;

    case projectRole === 'Member' && projects.isInAllProjects:
      affectedMessage = `${userName} has already been added to all projects in which you are admin.`;
      break;

    default:
      affectedMessage = defaultMessage;
      break;
  }

  return { affectedMessage, confirmedMessage };
};
