import { Asset } from '@cube3/common/model/schema/resources/asset';
import CheckBoxStateless from '@cube3/ui/src/Buttons/CheckBoxStateless';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import NumberField from '../../../../forms/helpers/fields/NumberField';
import TextField from '../../../../forms/helpers/fields/TextField';
import { getFieldConfig } from '../../../../forms/utils/getFieldConfig';
import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { compose } from '../../../../../utils/component-helpers';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { removeExtensionFromString } from '@cube3/ui/src/human/getExtensionFromString';
import {
  FieldConfig,
  WrappedFieldComponent
} from '../../ContentPane/DetailView/MetaData/EditMetadataFormSmart';
import { useTVBroadcastMetadataConfig } from '../hooks/useTVBroadcastMetadataConfig';
import {
  exportFileNameValidators,
  exportFileNameParser,
  generateCustomExportFileName,
  customFieldValidator,
  lengthValidators,
  videoLengthWarn,
  exportFilenameWarn
} from '../utils/broadcastFieldValidators';
import { findLast } from '../utils/findLast';
import { ExportsParameters } from '@cube3/common/model/schema/resources/export-batch';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

interface ReceivedProps {
  asset: Asset;
  metadataFields: FieldConfig[];
  broadcastValues: { [system_name: string]: unknown };
  visible: boolean;
  exportTargets: Array<{ id: string; type: 'export-target' }>;
  exportTargetType: {
    type: 'export-target-type';
    id: string;
  };
  recipients?: string[];
  batchId?: string;
  disableSubmit?: boolean;
}

export interface ExportDataFormValues {
  title: string;
  client: string;
  product_service: string;
  length: number;
  version: string;
  export_filename_checkbox?: boolean;
  export_filename?: string;
}

type Props = ReceivedProps &
  InjectedFormProps & {
    formValues: ExportDataFormValues;
  };

const mapStateToProps = (
  state,
  props: ReceivedProps & {
    assetBroadcastValues: { [system_name: string]: unknown };
  }
) => {
  const { asset } = props;

  const form = `tv_broadcast_data_${asset.id}`;
  const values = getFormValues(form)(state);

  return {
    form,
    formValues: values
  };
};

interface WithInitialValuesInjectedProps {
  initialValues: {
    title: string;
    client: string;
    product_service: string;
    version: number;
    export_filename_checkbox: boolean;
  };
  previousExports: ExportsParameters<'broadcast'>[];
}

const withInitialValues = (Wrapped) => (props) => {
  const {
    asset,
    broadcastValues: projectBroadcastValues,
    assetBroadcastValues,
    exportTargets
  } = props;

  const params = useRef({
    filter: {
      export_target_type: props.exportTargetType.id
    }
  });

  const previousExportBatches = useResourceList__ALPHA({
    resourceId: props.asset.id,
    resourceType: props.asset.type,
    edgeType: 'export-batch',
    edgeLabel: 'export-batches',
    params: params.current,
    strategy: 'fetch-on-mount'
  });

  const previousExports = useMemo(() => {
    if (!previousExportBatches.first) return [];
    return previousExportBatches.first?.attributes.exports?.filter(
      (exp) => exp.asset_id === asset?.id
    ) as ExportsParameters<'broadcast'>[];
  }, [previousExportBatches.first, asset]);

  const previousExportsForTargets = previousExports?.filter(
    (e) => !!exportTargets.find((t) => e.target_id === t.id)
  );

  const length = asset.duration
    ? Math.round(asset.duration / 1000)
    : assetBroadcastValues.length
    ? parseInt(assetBroadcastValues.length)
    : undefined;
  const titleFromFile = removeExtensionFromString(asset.display_name);

  const lastVersion =
    assetBroadcastValues.version ||
    findLast('version', previousExportsForTargets, true);

  const initialValues = {
    title: exportFileNameParser(
      assetBroadcastValues.title ||
        findLast('title', previousExports) ||
        titleFromFile
    ),
    client: exportFileNameParser(
      assetBroadcastValues.client ||
        projectBroadcastValues.client ||
        findLast('client', previousExports)
    ),
    product_service: exportFileNameParser(
      assetBroadcastValues.product_service ||
        projectBroadcastValues.product_service ||
        findLast('product_service', previousExports)
    ),
    length,
    version: lastVersion ? parseInt(String(lastVersion)) + 1 : 1,
    export_filename_checkbox: false
  };

  const previousNames = useMemo(
    () =>
      previousExportsForTargets?.map(
        (e) => e.parameters.broadcast.export_filename
      ),
    [previousExportsForTargets]
  );

  if (previousExportBatches.status !== statuses.SUCCESS) {
    return null;
  }

  return (
    <Wrapped
      {...props}
      initialValues={initialValues}
      previousExports={previousExports}
      previousNames={previousNames}
    />
  );
};

/** Create a redux-form for each asset with “broadcast” `metadata-category` fields
 * - prefill metadata fields with project metadata values + asset properties
 */
const TVadExportDataForm: React.FC<Props & WithInitialValuesInjectedProps> = (
  props
) => {
  const { visible, asset, formValues, metadataFields, change } = props;

  const isFilenameEnabled = formValues && formValues.export_filename_checkbox;

  /** The export file name is derived from the values entered in the fields.
   * if checkbox is turned of, make sure that is updated as the values in the fields change.
   */
  const exportFileName = formValues
    ? generateCustomExportFileName(
        formValues.product_service,
        formValues.title,
        formValues.length,
        `VERSION-${formValues.version}`
      )
    : '';

  const initialized = useRef(false);
  useEffect(() => {
    if (isFilenameEnabled) {
      if (!initialized.current && !formValues?.export_filename) {
        change('export_filename', exportFileName);
        initialized.current = true;
      }
    } else {
      change('export_filename', exportFileName);
      initialized.current = true;
    }
  }, [change, exportFileName, formValues, isFilenameEnabled]);

  return (
    <div style={{ display: visible ? 'initial' : 'none' }}>
      {/* client, product field */}

      {metadataFields &&
        metadataFields.map((field) => (
          <FieldComponent key={field.name} field={field} />
        ))}

      {/* length field*/}

      <Field
        name="length"
        props={{
          component: NumberField,
          required: true,
          showError: true,
          type: 'number'
        }}
        component={WrappedFieldComponent}
        label={'Length in seconds'}
        requiredLabel={'Detected automatically'}
        warn={videoLengthWarn(asset.duration)}
        validate={lengthValidators}
        placeholder="Enter length in seconds"
        parse={(val) => val && Number(val)}
        disabled={true}
      />

      {/* version field */}

      <Field
        name="version"
        props={{
          component: NumberField,
          required: true,
          showError: true,
          type: 'number',
          min: 1,
          step: 1
        }}
        component={WrappedFieldComponent}
        label={'Version'}
        placeholder="Enter version of delivery"
        parse={(val) => val && Number(val)}
        disabled={false}
      />

      <Divider gutter={true} />

      {/* custom filename checkbox*/}

      <Field
        name={'export_filename_checkbox'}
        label="Export file name"
        props={{
          component: ({ input }) => (
            <div style={{ padding: '4px 0' }}>
              <CheckBoxStateless
                text={'Use custom export file name'}
                marginCollapse={'left'}
                checked={input.checked}
                onChange={(checked) => input.onChange(checked)}
              />
            </div>
          )
        }}
        component={WrappedFieldComponent}
        type="checkbox"
      />
      <div style={{ marginTop: '-16px' }}>
        {/* custom filename field*/}

        <Field
          name={'export_filename'}
          type={'text'}
          disabled={!formValues || !formValues.export_filename_checkbox}
          props={{
            component: TextField,
            required: false,
            showError: true
          }}
          validate={exportFileNameValidators}
          warn={exportFilenameWarn}
          component={WrappedFieldComponent}
          placeholder="Enter custom export file name"
        />
      </div>
    </div>
  );
};

const normalizedFieldName = {
  client: 'Advertiser',
  product_service: 'Product'
};

const FieldComponent = ({ field }) => {
  const { component: FieldComponent, props: extraProps } =
    getFieldConfig(field);
  return (
    <Field
      name={field.system_name}
      type={field.type}
      key={field.name}
      props={{
        ...extraProps,
        component: FieldComponent,
        required: true
      }}
      component={WrappedFieldComponent}
      label={normalizedFieldName[field.system_name] || field.label}
      formFieldType={field.type}
      disabled={field.disabled ? field.disabled : false}
      validate={customFieldValidator}
      parse={field.parse}
    />
  );
};

const withAssetBroadcastValues = (Wrapped) => (props) => {
  const { broadcastValues: assetBroadcastValues, loading } =
    useTVBroadcastMetadataConfig({
      id: props.asset.id,
      type: props.asset.type
    });

  if (!assetBroadcastValues || loading) {
    return null;
  }

  return <Wrapped {...props} assetBroadcastValues={assetBroadcastValues} />;
};

export default compose(TVadExportDataForm)(
  withAssetBroadcastValues,
  connect(mapStateToProps, {}),
  withInitialValues,
  reduxForm<
    {},
    ReceivedProps & {
      handleSubmit: Function;
    }
  >({
    destroyOnUnmount: false
  })
) as (props: ReceivedProps) => React.ReactElement;
