import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../ducks/session';

export interface CurrentAccountProps {
  currentAccountId: string;
  setCurrentAccount: (id: string) => void;
}

const accountSelectors = (state, ownProps) => ({
  currentAccountId: state.session.account
});

const accountActions = (dispatch, ownProps) =>
  bindActionCreators(
    {
      setCurrentAccount: actionCreators.setCurrentAccount
    },
    dispatch
  );

export const withCurrentAccount = wrappedComponent =>
  connect(accountSelectors, accountActions)(wrappedComponent);
