import React from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from '../../Modals/ModalManager';
import { withModalActions, ModalActionProps } from '../../Modals/modalActions';

import { ResourceType } from '@cube3/common/model/resource-types';

// UI
import { Typography } from '@cube3/ui/src/typography/Typography';
import { compose } from '../../../../../utils/component-helpers';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';

/// @Author : Simon Meijer
/// <Summary>
/// displayed when calling 'asset_cannot_open'
/// </Summary>

interface PrivateProps extends ModalReceiverProps, ModalActionProps {}

class CannotOpenItemModal extends React.PureComponent<PrivateProps> {
  render() {
    const { closeAllModals, modalContext } = this.props;

    // we expect openmodal (contenttree) to be called with asset type context
    let trashBinItemType: ResourceType | 'file' = modalContext;

    if (!modalContext) {
      trashBinItemType = 'file';
    }

    return (
      <ModalNoticeUI
        onCloseEvent={() => closeAllModals()}
        title={'Cannot open'}
        footerRightComponent={
          <Button
            onClick={() => closeAllModals()}
            colorVariant={'filled1'}
            text="OK"
          />
        }
      >
        <Typography>
          You cannot open this {trashBinItemType} because it is in the bin. To
          view this {trashBinItemType}, you need to restore it from the bin.
        </Typography>
      </ModalNoticeUI>
    );
  }
}

export default compose(CannotOpenItemModal)(withModalActions);
