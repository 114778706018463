import React from 'react';
import { Typography } from '@cube3/ui/src/typography/Typography';
import {
  actionCreators,
  cookieTypes,
  setableCookieTypes,
  consentOptions,
  selectors
} from '@cube3/state/src/redux/ducks/cookie-consent';
import { CookieCategory } from '@cube3/state/src/redux/ducks/cookie-consent/state';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import CheckBoxStateless from '@cube3/ui/src/Buttons/CheckBoxStateless';
import { compose } from '../../../../../utils/component-helpers';
import { useModalActions } from '../../../layout/Modals/modalActions';
import Button from '@cube3/ui/src/Buttons/Cube3Button';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { Cube3AnchorLink } from '../../../routing/Cube3Link';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';

export const categories = {
  ESSENTIAL: `cube3/cookie-consent/cookie-types/ESSENTIAL`,
  FUNCTIONAL: `cube3/cookie-consent/cookie-types/FUNCTIONAL`,
  ANALYTICS: `cube3/cookie-consent/cookie-types/ANALYTICS`,
  MARKETING: `cube3/cookie-consent/cookie-types/MARKETING`
};

export const prettyCookieCategories = {
  [categories.ESSENTIAL]: {
    name: 'Essential',
    purpose: `These cookies are essential to keep the website secure and to make sure you are who you say you are. We also use these to keep track of your cookie preferences. They always need to be on for this website to work.`
  },
  [categories.FUNCTIONAL]: {
    name: 'Functional',
    purpose: `These cookies are used to maintain your logged in session, even if you close the browser. An example of this is the "remember me" button on the login page, but we also use these for things like resumable uploads. You can disable them, but you will have to log in every time you reload the site.`
  },
  [categories.ANALYTICS]: {
    name: 'Analytics',
    purpose: `These cookies measure how you use the website. It allows us to learn from this information to help improve your experience. Disabling these will not affect how the site works, but we won't be able to fine tune our feature set towards your use case.`
  }
  // marketing cookies should be disabled in settings while they are not being used yet.
  // [categories.MARKETING]: {
  //     name: 'Marketing',
  //     purpose: `We don't use these yet. (Some sites use these to create a marketing profile from your behaviour, so they can send targeted ads.)`
  // }
};

interface Properties extends InjectedFormProps {}

const CookieSettingsModal: React.FunctionComponent<Properties> = props => {
  const consentState = useTypedSelector(state =>
    selectors.getConsentState(state)
  );

  // TODO: implement ui once we start using versioned policy documents
  // const versionMismatch = useTypedSelector(state =>
  //   selectors.isVersionMismatch(state)
  // );

  const modalActions = useModalActions();
  const cookieStatementUrl = '/legal/policies/cookie-statement';

  const { handleSubmit, pristine } = props;

  return (
    <ModalMenuUI
      title={'Cookie settings'}
      multiplyContentSpace={6}
      onCloseEvent={() => modalActions.closeAllModals()}
      footerRightComponent={
        <>
          <Button
            colorVariant={'ghost1'}
            text="Cancel"
            onClick={() => modalActions.closeAllModals()}
          />
          <Button
            disabled={pristine}
            onClick={ev => {
              handleSubmit(ev);
              modalActions.closeAllModals();
            }}
            colorVariant={'filled1'}
            text="Save"
          />
        </>
      }
    >
      <section data-text-selectable={true}>
        <Typography
          typographyStyle="body1"
          typographyProps={{ gutterBottom: true }}
        >
          You can choose which cookies we can use. See our{' '}
          <Cube3AnchorLink
            href={cookieStatementUrl}
            underline={false}
            target="_blank"
          >
            <Typography display="inline" color={'brand2'}>
              Cookie Statement{' '}
            </Typography>
          </Cube3AnchorLink>
          for an overview of cookies.
        </Typography>
        <form onSubmit={handleSubmit}>
          <ul style={{ paddingLeft: 0 }}>
            {Object.keys(cookieTypes)
              .map(k => ({ key: k, value: consentState[k] }))
              .map((kv, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'start',
                      marginBottom: 12
                    }}
                  >
                    <Field
                      name={kv.key}
                      component={({ input }) => (
                        <CheckBoxStateless
                          disabled={!setableCookieTypes[kv.key]}
                          marginCollapse={'left'}
                          checked={input.checked}
                          onChange={checked => input.onChange(checked)}
                        />
                      )}
                      type="checkbox"
                    />
                    <div style={{ marginLeft: 12 }}>
                      <Typography typographyStyle="heading4">
                        {prettyCookieCategories[kv.key].name}
                      </Typography>
                      <Typography typographyStyle="body2" color="contrast2">
                        {prettyCookieCategories[kv.key].purpose}
                      </Typography>
                    </div>
                  </li>
                );
              })}
          </ul>
        </form>
      </section>
    </ModalMenuUI>
  );
};

// wrapper for getting the stored consent values.
const initialConsentValues = Wrapped => props => {
  // get the consent state as stored.
  const consentState = useTypedSelector(state =>
    selectors.getConsentState(state)
  );

  // map over the known keys object and map them to an array and filter out current version as it is not relevant to
  const storedValues = Object.keys(consentState)
    .map(k => ({ key: k, value: consentState[k] }))
    .filter(item => item.key !== 'currentVersion');

  let initialValues = {};

  // map over the consent states and determine of the boxes should be checked or not, and structure a new object out of the array.
  storedValues.map(item => {
    const checked =
      item.value?.state === consentOptions.CONSENTED ||
      item.value?.state === consentOptions.REQUIRED;
    initialValues = {
      ...initialValues,
      [item.key]: checked
    };
    return null;
  });

  return <Wrapped {...props} initialValues={initialValues} />;
};

export default compose(CookieSettingsModal)(
  initialConsentValues,
  reduxForm<{}, Properties>({
    form: 'cookiesettings',
    destroyOnUnmount: true,
    onSubmit: (values, dispatch, props) => {
      for (const [key, value] of Object.entries(values)) {
        if (value === true) {
          dispatch(actionCreators.giveConsent(key as CookieCategory));
        }
        if (value === false) {
          dispatch(actionCreators.rejectConsent(key as CookieCategory));
        }
      }
    }
  })
);
