import React, { useCallback } from 'react';

import { ExportBatch } from '@cube3/common/model/schema/resources/export-batch';

import { useModalActions } from '../../Modals/modalActions';
import { ExportQueueListItemUI } from '@cube3/ui/src/exports/ExportQueueListItemUI';
import { UploadBreadcrumbs } from '../../MediaQueue/prefabs/UploadBreadcrumbs';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { toggleMediaqueue } from '@cube3/state/src/redux/ducks/ui';
import { useDispatch } from 'react-redux';
import { useExportQueueListItemConfig } from '../hooks/useExportQueueListItemConfig';

interface Props {
  gradient?: 'none' | 'lamp';
  /** use to list the export for a single asset */
  assetId?: string;
  exportBatch: ExportBatch;
}

/** Component used to display exports details, handle the exports of both single asset and multiple assets. */
export const ExportQueueListItem = React.memo((props: Props) => {
  const { assetId, exportBatch, gradient } = props;
  const { openModal } = useModalActions();

  const numberOfAssets = assetId ? 1 : exportBatch.relationships.assets?.length;

  const {
    targetName,
    created_at,
    chanelName,
    label,
    asset,
    status,
    subTitle,
    title,
    progress,
    thumbnails
  } = useExportQueueListItemConfig(exportBatch, assetId);

  const dispatch = useDispatch();
  /** click to view all exports details, visibility:
   * - broadcast(TV ad): always visible
   * - the rest: only visible when exporting multiple assets
   */
  const onClickViewAll = useCallback(() => {
    openModal('export_status', {
      exportBatchId: exportBatch && exportBatch.id,
      title: targetName
    });
    // otherwise the status modal will be displayed under the media queue
    dispatch(toggleMediaqueue(false));
  }, [dispatch, exportBatch, openModal, targetName]);

  const queueOpened = useTypedSelector((state) => {
    return state.ui.mediaQueueLastOpened;
  });

  const newish = queueOpened
    ? queueOpened - new Date(created_at).getTime() < 10 * 1000
    : false;

  return (
    <ExportQueueListItemUI
      thumbnails={thumbnails}
      statusLabel={label}
      status={status}
      statusProgress={progress}
      targetName={targetName}
      channelName={chanelName}
      created_at={created_at}
      title={title}
      subTitle={subTitle}
      gradient={gradient}
      highlight={newish}
      onClickViewAll={
        targetName === 'TV ad' || numberOfAssets > 1
          ? onClickViewAll
          : undefined
      }
      path={
        numberOfAssets === 1 && asset && <UploadBreadcrumbs asset={asset} />
      }
    />
  );
});
