import { Feature } from '@cube3/common/model/resource-types';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';

const requiredFeatures = {
  features: [
    '/WORKSPACE/SHARING/WATERMARK',
    '/WORKSPACE/SHARING/MAGIC_LINK'
  ] as Feature[]
};

export const useSharesFeatures = () => {
  const [hasWatermarkFeature, hasVerifyUserFeature] =
    useFeatures(requiredFeatures);

  return {
    hasWatermarkFeature,
    hasVerifyUserFeature
  };
};
