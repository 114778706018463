import React, { useRef, useMemo } from 'react';
import { generateValidPassword } from '@cube3/ui/src/Prefabs/shareLink/Forms/fields/PasswordFieldComponent';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { getFormValues } from 'redux-form';
import { passwordValidator } from '../../../../forms/helpers/validators';
import moment from 'moment';
import { EmailMessageBoxFormProps } from '@cube3/ui/src/Prefabs/Forms/EmailMessageBox';
import { LinkSettingsFormProperties } from '@cube3/ui/src/Prefabs/shareLink/Forms/LinkSettingsForm';
import { FormRecipient } from '../Modals/ShareNewLinkModal';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useShareVerifyDefaults } from '../hooks/useShareVerifyDefaults';

export interface ShareLinkFormFields
  extends EmailMessageBoxFormProps,
    LinkSettingsFormProperties {
  emails?: FormRecipient[];
  expires: boolean;
  expiryDate: string;
  message_enabled: boolean;
  message?: string;
  downloadFiles: boolean;
  passwordEnabled: boolean;
  password?: string;
  watermark: boolean;
}

export const withSharelinkNewValues = (WrappedComponent) => (props) => {
  const uniqueId = useRef(uuidv4()).current;

  const values = useTypedSelector((state) => getFormValues(uniqueId)(state));

  const initialPassword = useRef(
    generateValidPassword(passwordValidator)
  ).current;

  const { canDownloadOriginal, canDownloadPreview } = props;

  const workspace = useCurrentWorkspace()[1];

  const { verifyUser, verifyType, allowRequestAccess } =
    useShareVerifyDefaults();

  const initialSettingsFormvalues: ShareLinkFormFields = useMemo(
    () =>
      !workspace
        ? undefined
        : {
            expires: workspace?.default_shares_expiration_enabled,
            message_enabled: false,
            downloadFiles:
              (canDownloadOriginal || canDownloadPreview) &&
              workspace?.default_shares_download_enabled,
            expiryDate: moment()
              .endOf('day')
              .add(
                workspace ? workspace.default_shares_expiration_duration : 30,
                'day'
              )
              .toISOString(),
            verifyUser,
            verifyType,
            allowRequestAccess,
            passwordEnabled: !!workspace?.default_shares_password_enabled,
            password: initialPassword,
            watermark: false
          },
    [
      workspace,
      initialPassword,
      canDownloadOriginal,
      canDownloadPreview,
      verifyUser,
      verifyType,
      allowRequestAccess
    ]
  );

  const injected = {
    form: uniqueId,
    initialValues: initialSettingsFormvalues,
    ...values,
    emailSearchInput: undefined
  };

  return <WrappedComponent {...props} {...injected} />;
};
