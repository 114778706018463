import {
  RelationshipIdentifier,
  ResourceIdentifier
} from '@cube3/common/model/resource-types';

import { actionCreators as reqActionCreators } from '../../../../ducks/request-status';
import { selectors__unoptimized as edgeSelectors } from '../../../../ducks/resource-edges';

export const invalidateCache = ({
  cacheInvalidator,
  config,
  getState,
  dispatch
}) => {
  const related =
    !Array.isArray(cacheInvalidator) && config.resourceId && config.resourceType
      ? edgeSelectors.getRelatedResources(getState(), {
          type: config.resourceType,
          id: config.resourceId
        })
      : [];

  const invalid = !Array.isArray(cacheInvalidator) ? related : cacheInvalidator;
  const deduped = invalid
    .filter((r) => {
      if (!r) {
        console.warn(
          `Corrupted cache invalidator for ${config?.resourceId} ${config?.resourceType}`,
          config,
          invalid
        );
        return false;
      }
      return true;
    })
    .map(
      (r) =>
        `${r.type || r.resourceType}---${r.id || r.resourceId}${
          r.relationship ? `---${r.relationship}` : ''
        }`
    )
    .filter((s, idx, arr) => idx === arr.indexOf(s))
    .map((s) => s.split('---'))
    .map((f) => ({ type: f[0], id: f[1], relationship: f[2] }));

  deduped.forEach((item) => {
    if (item.relationship) {
      dispatch(
        reqActionCreators.invalidateRelationship({
          id: item.id,
          type: item.type,
          relationship: item.relationship
        } as RelationshipIdentifier)
      );
    } else {
      dispatch(
        reqActionCreators.invalidateResource({
          id: item.id,
          type: item.type
        } as ResourceIdentifier)
      );
    }
  });
};
