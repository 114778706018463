import {
  anySelected,
  getSelection,
  getVisiting,
  SelectionItemInterface
} from '@cube3/state/src/redux/ducks/selections';
import { MenuEntry } from '@cube3/ui/src/library/CommandBar/CommandBarUI';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useMemo } from 'react';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentShareLink } from '@cube3/state/src/redux/components/Hooks/useCurrentShareLink';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { ContentTreeNode } from '@cube3/common/model/schema/resources/content-tree-node';
import { Feature } from '@cube3/common/model/resource-types';
import { Share } from '@cube3/common/model/schema/resources/share';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { urlStructureTrashbin } from '../../routing/routingPaths';
import { useCurrentProject } from '@cube3/state/src/redux/components/Hooks/useCurrentProject';
import { Privileges as PrivilegesType } from '@cube3/state/src/redux/components/Hooks/privileges';

/** Interface that standartizes the input to the hook */
export interface ButtonFactory {
  factory(config: ButtonFactoryConfig): MenuEntry;
  subfactories?: ButtonFactory[];
}

export type MenuVariant = 'command-bar' | 'context-menu';

/** Interface that defines the input the hook passes to the button factory */
export interface ButtonFactoryConfig {
  variant: MenuVariant;
  selection: SelectionItemInterface[]; // NOTE: selection falls back to the "active" item for legacy reasons
  anySelected: boolean; // this is the way to properly detect if any items are selected
  visiting: SelectionItemInterface;
  location: Location;
  featureFlags: FeatureFlags;
  clipboardItems: any[];
  deletedItems: ContentTreeNode[];
  shareLink: Share;
  sharedResource: ContentTreeNode;
  privileges: Privileges;
  isProjectRoleReadOnly: boolean;
  isProjectArchived: boolean;
}

/** Interface that standartizes the feature flags in ButtonFactoryConfig */
export interface FeatureFlags {
  fileRequest: boolean;
  reviewLinks: boolean;
  reviews: boolean;
  broadcastLegacy: boolean;
  youtubeExport: boolean;
  metaExport: boolean;
  cm360Export: boolean;
  googleAdsExport: boolean;
  contentfulExport: boolean;
  tvAdExport: boolean;
  tags: boolean;
  favorites: boolean;
}

/** Interface that standartizes the privileges in ButtonFactoryConfig */
export interface Privileges {
  canEditMetadata: boolean;
  canViewMetadata: boolean;
  canWriteContract: boolean;
  canWriteShares: boolean;
  canWriteLibrary: boolean;
  canExportBroadcast: boolean;
  canExportYoutube: boolean;
  canExportMeta: boolean;
  canExportCM360: boolean;
  canExportGoogleAds: boolean;
  canExportContentful: boolean;
  canDownloadOriginal: boolean;
  canDownloadPreview: boolean;
  canApplyTags: boolean;
  canWriteWorkspace: boolean;
}

const deletedParams = { sort: '-trashed_at', page: { size: 25 } };
const requiredFeatures = {
  features: [
    '/WORKSPACE/SHARING/FILE_REQUESTS',
    '/WORKSPACE/SHARING/REVIEW_LINKS',
    '/WORKSPACE/SHARING/REVIEWS',
    '/WORKSPACE/EXPORTS/BROADCAST_LEGACY',
    '/WORKSPACE/EXPORTS/YOUTUBE',
    '/WORKSPACE/EXPORTS/META',
    '/WORKSPACE/EXPORTS/CM360',
    '/WORKSPACE/EXPORTS/GOOGLEADS',
    '/WORKSPACE/EXPORTS/CONTENTFUL',
    '/WORKSPACE/EXPORTS/BROADCAST',
    '/WORKSPACE/TAGS',
    '/WORKSPACE/FAVORITES'
  ] as Feature[]
};
const requiredPrivileges = [
  'METADATA_WRITE',
  'METADATA_READ',
  'CONTRACT_WRITE',
  'SHARE_LINK',
  'LIBRARY_WRITE',
  'EXPORT_BROADCAST',
  'EXPORT_YOUTUBE',
  'EXPORT_META',
  'EXPORT_CM360',
  'EXPORT_GOOGLEADS',
  'EXPORT_CONTENTFUL',
  'DOWNLOAD_TYPE_ORIGINAL',
  'DOWNLOAD_TYPE_PREVIEW',
  'APPLY_TAGS',
  'WORKSPACE_WRITE'
] as PrivilegesType[];

/** Hook that extracts common necessary data for button factories and passes
 * it to the factories with a shape defined by the interface ButtonFactoryConfig
 */
export const useButtonConfig = (
  factories: ButtonFactory[],
  variant: MenuVariant = 'command-bar'
): MenuEntry[] => {
  const location = useLocation();
  const selection = useSelector((state) => getSelection(state));
  const visiting = useSelector((state) => getVisiting(state));
  const anySelectedItems = useSelector((state) => anySelected(state));
  const clipboardItems = useSelector((state) => (state as any).clipboard.items);

  const [
    fileRequest,
    reviewLinks,
    reviews,
    broadcastLegacy,
    youtubeExport,
    metaExport,
    cm360Export,
    googleAdsExport,
    contentfulExport,
    tvAdExport,
    tags,
    favorites
  ] = useFeatures(requiredFeatures);
  const features: FeatureFlags = useMemo(
    () => ({
      fileRequest,
      reviewLinks,
      reviews,
      broadcastLegacy,
      youtubeExport,
      metaExport,
      cm360Export,
      googleAdsExport,
      contentfulExport,
      tvAdExport,
      tags,
      favorites
    }),
    [
      fileRequest,
      reviewLinks,
      reviews,
      broadcastLegacy,
      youtubeExport,
      metaExport,
      cm360Export,
      googleAdsExport,
      contentfulExport,
      tags,
      tvAdExport,
      favorites
    ]
  );
  const [workspaceId] = useCurrentWorkspace();

  const inBin = matchPath(location.pathname, urlStructureTrashbin);

  const deleted = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: inBin && workspaceId,
    edgeType: 'content-tree-node',
    edgeLabel: 'deleted-nodes',
    strategy: 'fetch-on-mount',
    params: deletedParams
  });

  const shareLink = useCurrentShareLink();
  const sharedResource = useResource__ALPHA({
    resourceType: 'content-tree-node',
    resourceId: shareLink?.relationships['node']['id']
  });

  const [
    canEditMetadata,
    canViewMetadata,
    canWriteContract,
    canWriteShares,
    canWriteLibrary,
    canExportBroadcast,
    canExportYoutube,
    canExportMeta,
    canExportCM360,
    canExportGoogleAds,
    canExportContentful,
    canDownloadOriginal,
    canDownloadPreview,
    canApplyTags,
    canWriteWorkspace
  ] = useWorkspacePermissions(requiredPrivileges);

  // Update the visiting project status to the context
  const { isProjectArchived, isProjectRoleReadOnly } = useCurrentProject();

  return useMemo(() => {
    const config = {
      variant,
      selection: selection,
      anySelected: anySelectedItems,
      visiting: visiting,
      location: location,
      featureFlags: features,
      clipboardItems: clipboardItems ? clipboardItems : [],
      deletedItems: deleted?.resources,
      shareLink: shareLink,
      sharedResource: sharedResource?.first,
      privileges: {
        canEditMetadata,
        canViewMetadata,
        canWriteContract,
        canWriteShares,
        canWriteLibrary,
        canExportBroadcast,
        canExportYoutube,
        canExportMeta,
        canExportCM360,
        canExportGoogleAds,
        canExportContentful,
        canDownloadOriginal,
        canDownloadPreview,
        canApplyTags,
        canWriteWorkspace
      },
      isProjectArchived: isProjectArchived,
      isProjectRoleReadOnly: isProjectRoleReadOnly
    };
    return factories.map((fct) => makeButtons(fct, config));
  }, [
    factories,
    variant,
    anySelectedItems,
    selection,
    visiting,
    location,
    features,
    clipboardItems,
    deleted,
    shareLink,
    sharedResource,
    canEditMetadata,
    canViewMetadata,
    canWriteContract,
    canWriteShares,
    canWriteLibrary,
    canExportBroadcast,
    canExportYoutube,
    canExportMeta,
    canExportCM360,
    canExportGoogleAds,
    canExportContentful,
    canDownloadOriginal,
    canDownloadPreview,
    canApplyTags,
    canViewMetadata,
    canWriteWorkspace,
    isProjectArchived,
    isProjectRoleReadOnly
  ]);
};

/** Function that recursively passes the config to factories, subfactories, sub-subfactories, etc. */
const makeButtons = (
  factory: ButtonFactory,
  config: ButtonFactoryConfig
): MenuEntry => {
  const menuEntry = factory.factory(config);
  menuEntry.children = factory.subfactories
    ? factory.subfactories.map((fct) => makeButtons(fct, config))
    : undefined;

  return menuEntry;
};
