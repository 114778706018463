import React from 'react';
import { Dialog } from '../../templates/Dialog/Dialog';
import { Typography } from '../../atoms/Typography/Typography';
import Button from '../../atoms/Button/Button';
import { withCubicleFallback } from '../../../utils/CubicleFallback';

export const linkUnavailableReasons = {
  expired: {
    msg: 'This link has expired and no longer works',
    tip: 'You can ask the sender to extend the expiration date, or send you a new link.'
  },
  default: {
    msg: 'This link is not available. Maybe the sender deleted it, or the url contains a typo.',
    tip: 'You can ask the sender to send you a new link.'
  }
};

interface Props {
  logo: React.ReactNode;
  title: string;
  footerRightComponent?: JSX.Element;
  reason?: keyof typeof linkUnavailableReasons;
}

const LinkUnavailableNotice: React.FC<Props> = (props) => {
  const { logo, title, reason = 'default', footerRightComponent } = props;
  return (
    <>
      <div style={{ padding: 16 }}>{logo}</div>
      <Dialog
        title={title || 'Share Link not available'}
        footerRightComponent={
          footerRightComponent || (
            <Typography variant="sm" color="dark" colorVariant="03">
              {linkUnavailableReasons[reason].tip}
            </Typography>
          )
        }
      >
        <Typography color="dark" colorVariant="02">
          {linkUnavailableReasons[reason].msg}
        </Typography>
      </Dialog>
    </>
  );
};

const Default = withCubicleFallback(LinkUnavailableNotice);
export { Default as LinkUnavailableNotice };
export default Default;
