import { useRef, useEffect } from 'react';

type UseTitle = (
  /**
   *  String to put into document title
   */
  title: string,
  /**
   * Whether to revert tot the previous title
   * when the component calling the hook unmounts
   */
  clearOnUnmount?: boolean
) => void;

/**
 * Hook to render string into document title
 */
export const useTitle: UseTitle = (title, clearOnUnmount = true) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const oldTitle = defaultTitle.current;
    return () => {
      if (clearOnUnmount) {
        document.title = oldTitle;
      }
    };
  }, [clearOnUnmount]);
};
