import * as React from 'react';
import GenericReduxFormUI, {
  GenericReduxFormProps
} from '../forms/GenericReduxFormUi';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
    '& > form': {
      width: '100%'
    }
  },
  title: {
    ...theme.typographyStyles.heading2,
    color: theme.customPalette.primary.textWhiteHighEmphasis,
    alignSelf: 'flex-start',
    marginBottom: theme.customSpacing.margin[16]
  }
}));

type EditMetaDataFormUIProps = GenericReduxFormProps;

const EditMetadataFormUI: React.ComponentType<EditMetaDataFormUIProps> = React.memo(
  props => {
    const { title, ...rest } = props;

    const classes = useStyles();

    return (
      <GenericReduxFormUI
        title={title}
        titleStyles={classes.title}
        formContainerStyles={classes.formContainer}
        {...rest}
      />
    );
  }
);

export default EditMetadataFormUI;
