import { useClassName } from '@cube3/ui/src/utils/useClassName';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import GenericFieldWithErrors, {
  GenericFieldWithErrorsProps
} from './GenericFieldWithErrors';

type NumberFieldProps = GenericFieldWithErrorsProps & {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      cursor: 'pointer',
      width: 24,
      height: 24,
      borderRadius: 24,
      color: theme.customPalette.text.onBase.contrast1,
      border: 'none',
      background: theme.customPalette.backgroundAccent.whiteAccent1,
      '&:hover:not(:disabled)': {
        background: theme.customPalette.backgroundAccent.whiteAccent2
      },
      '&:disabled': {
        color: theme.customPalette.text.onBase.contrast3
      }
    },
    margin: {
      margin: '0 2px'
    }
  })
);

const formatNumber = (value: number | string, maxLength: number = 5) => {
  const stringVal = value.toString();
  const isDecimal = stringVal.includes('.');
  if (isDecimal) {
    const [int, dec] = stringVal.split('.');
    return int + '.' + dec.slice(0, maxLength);
  }
  return value;
};

const startWithZero = (val: string | number) => {
  return val === '-' || val === '0.' || val === '-0' || val === '-0.';
};

const longPressStartDelay = 1000;
const longPressExcuteDelay = 150;

function NumberField(props: NumberFieldProps) {
  const {
    placeholder = 'Enter a number',
    input,
    meta,
    label,
    type,
    showAsyncValid,
    disabled,
    renderTip,
    showErrors = 'touched',
    inputProps,
    showLabel = false,
    min = 0,
    max,
    step = 1
  } = props;
  const classes = useStyles();

  const [internalValue, setInternalValue] = useState(input.value);
  const [startLongPress, setStartLongPress] = useState<'-' | '+'>(undefined);
  const longPressTimer = useRef(null);

  const handleChange = useCallback(
    (e) => {
      const val = e.target.value;

      if (Number.isNaN(Number(val))) {
        if (min < 0 && startWithZero(val)) {
          setInternalValue(val);
        } else {
          input.onChange(internalValue);
        }
      } else {
        const num =
          Number(val) === 0 && !startWithZero(val) && val !== '' ? 0 : val;
        input.onChange(formatNumber(num));
        setInternalValue(formatNumber(num));
      }
    },
    [input, internalValue, min]
  );

  const handleAdd = useCallback(() => {
    setInternalValue((prev) => {
      const newVal = Number(prev || 0) + step;
      return max && newVal > max ? formatNumber(max) : formatNumber(newVal);
    });
  }, [max, step]);

  const handleMinus = useCallback(() => {
    setInternalValue((prev) =>
      formatNumber(Math.max(min, Number(prev || 0) - step))
    );
  }, [min, step]);

  const onLongPressStart = useCallback((sign: '-' | '+') => {
    const timer = setTimeout(
      () => setStartLongPress(sign),
      longPressStartDelay
    );

    longPressTimer.current = timer;
  }, []);

  const onLongPressEnd = useCallback(() => {
    setStartLongPress(undefined);
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  }, []);

  useEffect(() => {
    if (Number(internalValue) !== Number(input.value)) {
      input.onChange(internalValue);
    }
  }, [input, internalValue]);

  // long press button to keep incrementing / decrementing
  useEffect(() => {
    let timerId;
    if (startLongPress) {
      const callback = startLongPress === '+' ? handleAdd : handleMinus;
      callback();
      timerId = setInterval(callback, longPressExcuteDelay);
    }
    return () => clearInterval(timerId);
  }, [handleAdd, handleMinus, startLongPress]);

  useEffect(() => {
    window.addEventListener('mouseup', onLongPressEnd);
    return () => window.removeEventListener('mouseup', onLongPressEnd);
  }, [onLongPressEnd]);

  const tooltip =
    step > 1
      ? `Enter a multiple of ${step}`
      : min >= 0
      ? 'Enter a positive number'
      : placeholder;

  return (
    <GenericFieldWithErrors
      input={{ ...input, value: internalValue, onChange: handleChange }}
      meta={meta}
      showAsyncValid={showAsyncValid}
      label={label}
      showLabel={showLabel}
      placeholder={tooltip}
      type={type}
      disabled={disabled}
      inputProps={inputProps}
      showErrors={showErrors}
      renderTip={renderTip}
      numberControls={
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            padding: 8 // (40 - 24 * 2)/2
          }}
        >
          <button
            type="button"
            disabled={disabled}
            className={useClassName(classes.button, classes.margin)}
            onClick={handleMinus}
            onMouseDown={() => onLongPressStart('-')}
            onMouseUp={onLongPressEnd}
          >
            -
          </button>
          <button
            type="button"
            disabled={disabled}
            className={useClassName(classes.button, classes.margin)}
            onClick={handleAdd}
            onMouseDown={() => onLongPressStart('+')}
            onMouseUp={onLongPressEnd}
          >
            +
          </button>
        </div>
      }
    />
  );
}

export default NumberField;
