import * as React from 'react';
import { Typography } from '../typography/Typography';

export interface GenericFormFieldClasses {
  container?: string;
  label?: string;
  inputContainer?: string;
  input?: string;
  error?: string;
  warning?: string;
  required?: string;
  requiredValid?: string;
  labelContainer?: string;
}

export interface GenericFormFieldProps extends React.PropsWithChildren {
  input: {};
  disabled: boolean;
  label: string;
  type: string;
  meta: { touched: boolean; error: string; warning: string };
  genericFormFieldStyles?: GenericFormFieldClasses;
  min?: string;
  max?: string;
  required?: boolean;
  requiredLabel?: string;
  showError?: boolean;
  alwaysShowRequiredError?: boolean;
}

export const GenericFormFieldRow: React.FC<
  React.PropsWithChildren<GenericFormFieldProps>
> = (props) => {
  const {
    label,
    // type,
    genericFormFieldStyles = {
      container: '',
      label: '',
      inputContainer: '',
      input: '',
      error: '',
      warning: ''
    },
    // disabled,
    meta: { touched, error, warning },
    children,
    required = undefined,
    requiredLabel = 'Required',
    showError,
    alwaysShowRequiredError = true
  } = props;

  return (
    <div className={genericFormFieldStyles.container}>
      {/* <pre> {JSON.stringify(props.meta, null, 2)}</pre> */}

      <label className={genericFormFieldStyles.labelContainer}>
        <Typography
          className={genericFormFieldStyles.label}
          typographyStyle={'body2'}
        >
          {label}
        </Typography>
        {required && (
          <Typography
            className={`${genericFormFieldStyles.label} ${
              genericFormFieldStyles.required
            } ${!error ? genericFormFieldStyles.requiredValid : ''}`}
            typographyStyle={'body3'}
          >
            {requiredLabel}
          </Typography>
        )}
      </label>
      <div className={genericFormFieldStyles.inputContainer}>
        {children}
        {(showError ||
          touched ||
          (alwaysShowRequiredError && error === 'Required')) &&
          ((error && (
            <Typography
              typographyStyle={'body2'}
              className={genericFormFieldStyles.error}
            >
              {!Array.isArray(error)
                ? error
                : error.map((e) => <div key={e}>{e}</div>)}
            </Typography>
          )) ||
            (warning && (
              <Typography
                typographyStyle={'body2'}
                className={genericFormFieldStyles.warning}
              >
                {!Array.isArray(warning)
                  ? warning
                  : warning.map((w) => <div key={w}>{w}</div>)}
              </Typography>
            )))}
      </div>
    </div>
  );
};

export const GenericFormFieldInput = (props) => {
  const {
    label,
    type,
    genericFormFieldStyles = {
      container: '',
      label: '',
      inputContainer: '',
      input: '',
      error: '',
      warning: ''
    },
    input = undefined,
    disabled,
    min,
    max
    // meta: {
    //   /*touched, error, warning */
    // }
  } = props;

  return (
    <input
      type={type}
      placeholder={label}
      className={genericFormFieldStyles.input}
      disabled={disabled}
      min={min}
      max={max}
      {...input}
    />
  );
};

export default (props) => {
  return (
    <GenericFormFieldRow {...props}>
      <GenericFormFieldInput {...props} />
    </GenericFormFieldRow>
  );
};
