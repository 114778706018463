import React from 'react';

export const Rewind10 = props => {
  const { className } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.6299 22.1301C19.188 22.141 18.7497 22.0485 18.3499 21.8601C17.9948 21.6799 17.6893 21.4156 17.4599 21.0901C17.2084 20.7105 17.0319 20.2862 16.9399 19.8401C16.8217 19.2881 16.7647 18.7247 16.7699 18.1601C16.7658 17.5956 16.8228 17.0324 16.9399 16.4801C17.0337 16.0347 17.2101 15.6107 17.4599 15.2301C17.6893 14.9047 17.9948 14.6403 18.3499 14.4601C18.7497 14.2718 19.188 14.1793 19.6299 14.1901C20.0453 14.1585 20.462 14.2338 20.8401 14.4089C21.2182 14.5839 21.5452 14.8529 21.7899 15.1901C22.3055 16.077 22.5489 17.096 22.4899 18.1201C22.5489 19.1443 22.3055 20.1633 21.7899 21.0501C21.5545 21.4021 21.2315 21.6867 20.8528 21.8761C20.4741 22.0655 20.0526 22.153 19.6299 22.1301ZM19.6299 20.9101C19.8315 20.9222 20.0323 20.8769 20.2093 20.7796C20.3863 20.6823 20.5321 20.5368 20.6299 20.3601C20.8688 19.8924 20.9955 19.3754 20.9999 18.8501V17.4701C21.0215 16.9544 20.9222 16.4407 20.7099 15.9701C20.5981 15.7989 20.4453 15.6582 20.2655 15.5608C20.0857 15.4634 19.8844 15.4124 19.6799 15.4124C19.4753 15.4124 19.2741 15.4634 19.0942 15.5608C18.9144 15.6582 18.7616 15.7989 18.6499 15.9701C18.4375 16.4407 18.3382 16.9544 18.3599 17.4701V18.8501C18.3392 19.3691 18.4385 19.8858 18.6499 20.3601C18.7452 20.5345 18.8877 20.6784 19.061 20.7757C19.2342 20.8729 19.4314 20.9195 19.6299 20.9101Z"
          className={className}
        />
        <path
          d="M10.6302 22V20.82H12.6302V15.63L10.7702 16.63L10.2202 15.57L12.5402 14.27H14.0002V20.77H15.7802V22H10.6302Z"
          className={className}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 2L9 7L14 12L15.4 10.6L12.8 8H16C17.9778 8 19.9112 8.58649 21.5557 9.6853C23.2002 10.7841 24.4819 12.3459 25.2388 14.1732C25.9957 16.0004 26.1937 18.0111 25.8079 19.9509C25.422 21.8907 24.4696 23.6725 23.0711 25.0711C21.6725 26.4696 19.8907 27.422 17.9509 27.8079C16.0111 28.1937 14.0004 27.9957 12.1732 27.2388C10.3459 26.4819 8.78412 25.2002 7.6853 23.5557C6.58649 21.9112 6 19.9778 6 18H4C4 20.3734 4.70379 22.6935 6.02236 24.6668C7.34094 26.6402 9.21509 28.1783 11.4078 29.0866C13.6005 29.9948 16.0133 30.2324 18.3411 29.7694C20.6689 29.3064 22.8071 28.1635 24.4853 26.4853C26.1635 24.8071 27.3064 22.6689 27.7694 20.3411C28.2324 18.0133 27.9948 15.6005 27.0866 13.4078C26.1783 11.2151 24.6402 9.34094 22.6668 8.02236C20.6935 6.70379 18.3734 6 16 6H12.8L15.4 3.4L14 2Z"
          className={className}
        />
      </g>
      <defs>
        <clipPath>
          <path d="M0 0H32V32H0V0Z" className={className} />
        </clipPath>
      </defs>
    </svg>
  );
};
