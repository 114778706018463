import React, { useCallback, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { Typography } from '../../typography/Typography';
import Button from '../../Buttons/Button';
import SupportEmailComponent from '../../Email/SupportEmailComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      alignItems: 'center',
      textAlign: 'center',
      justifyItems: 'center',

      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
      height: '100%',
      width: '100%'
    },
    messageBlock: {
      textAlign: 'left',
      width: '60ex',
      maxWidth: '100%',
      height: 'auto'
    },
    buttons: {
      marginTop: 24,
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
);

const MAX_ATTEMPTS = 1;

interface Props {
  /** whether the error should be displayed */
  show: boolean;
  /** optional error message used in subheader */
  message?: string;
  /** optional callback to retry some logic (defaults to `window.location.reload`) */
  onRetry?(): void;
  /** optional label text for retry button (defaults to `reload`) */
  retryLabel?: string;
}

export const GenericFailure: React.FunctionComponent<Props> = props => {
  const { message, onRetry, retryLabel, show } = props;

  const classes = useStyles(props);
  const [attempts, setAttempts] = useState(0);

  const handleRetry = useCallback(() => {
    if (onRetry && attempts < MAX_ATTEMPTS) {
      onRetry();
      setAttempts(attempts + 1);
    } else {
      window.location.reload();
    }
  }, [attempts, setAttempts, onRetry]);

  if (!show) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.messageBlock}>
        <Typography
          typographyProps={{ gutterBottom: true }}
          typographyStyle="heading1"
        >
          Error{attempts > 0 ? ' (retried)' : ''}
        </Typography>
        <Typography
          typographyProps={{ gutterBottom: true }}
          typographyStyle="heading3"
        >
          {message || 'Cube has run into a problem'}
        </Typography>
        <Typography typographyProps={{ gutterBottom: true }} color="contrast2">
          Please try{' '}
          {onRetry && attempts < MAX_ATTEMPTS ? `again` : `reloading`}.
          {!onRetry || attempts >= MAX_ATTEMPTS
            ? 'If the issue persists, contact support.'
            : ''}
        </Typography>
        <div className={classes.buttons}>
          <Button
            onClick={() =>
              window.open(
                'mailto:support@cube-cloud.com',
                '_blank',
                'noopener noreferrer'
              )
            }
          >
            <SupportEmailComponent />
          </Button>
          <Button onClick={handleRetry} colorVariant="filled1">
            {retryLabel && attempts < MAX_ATTEMPTS ? retryLabel : `Reload`}
          </Button>
        </div>
      </div>
    </div>
  );
};
