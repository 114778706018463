import React from 'react';
import { makeCSS } from '../../utils/makeCSS';

export const useCubeAnchorLinkStyles = makeCSS(({ theme }) => {
  return {
    root: {
      textDecoration: 'none',
      color: theme.color.text.dark['01'], // '#fff'
      '&:visited': {
        color: theme.color.text.dark['03'] // 'rgba(255,255,255,0.48)'
      },
      '&:hover, &:active, &:focus': {
        color: theme.color.text.dark['02'] // 'rgba(255,255,255,0.64)'
      }
    },
    disabled: {
      pointerEvents: 'none'
    },
    'underline-true': {
      display: 'inline-block',
      borderBottom: `solid 1px ${theme.color.text.dark['01']}`,
      '&:hover, &:active, &:focus': {
        borderBottomColor: theme.color.text.dark['03']
      }
    },
    'underline-hover': {
      display: 'inline-block',
      borderBottom: `solid 1px transparent`,
      '&:hover, &:active, &:focus': {
        borderBottomColor: theme.color.text.dark['02']
      }
    },
    inline: {
      display: 'inline'
    },
    'underline-false': {}
  };
});

export interface CubeAnchorLinkProps {
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
  underline?: boolean;
  injectedClassName?: string;
  disabled?: boolean;
}
export const CubeAnchorLink: React.FC<
  React.PropsWithChildren<CubeAnchorLinkProps>
> = (props) => {
  const {
    href,
    target = '_blank',
    underline = false,
    injectedClassName = undefined,
    disabled = false
  } = props;
  const classes = useCubeAnchorLinkStyles(props);

  return (
    <a
      className={`${classes.root} ${classes[`underline-${underline}`]} ${
        classes.inline
      }  ${injectedClassName} ${disabled ? classes.disabled : ''}`}
      href={href}
      target={target}
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};
