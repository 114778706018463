import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { Typography } from '../../typography/Typography';
import UserAvatar from '../../User/UserAvatar/UserAvatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    suggestionTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      flexGrow: 1,
      marginLeft: 12
    }
  })
);

interface AccountSuggestionItemProps {
  primaryText: string;
  secondaryText: string;
  avatarUrl: string;
  disabledText?: string;
}

export default function AccountSuggestionItem(
  props: AccountSuggestionItemProps
) {
  const { primaryText, secondaryText, avatarUrl, disabledText } = props;
  const classes = useStyles({});
  const { suggestionTextContainer } = classes;

  return (
    <>
      <UserAvatar
        userInfo={{
          full_name: primaryText,
          email_address: secondaryText,
          profile_picture: avatarUrl
        }}
      />
      <div className={suggestionTextContainer}>
        <Typography color="contrast1" typographyStyle={'body2'}>
          {primaryText}
        </Typography>
        {secondaryText && !primaryText && (
          <Typography color="contrast1" typographyStyle={'body2'}>
            {secondaryText}
          </Typography>
        )}
        {secondaryText && primaryText && (
          <Typography color="contrast2" typographyStyle={'body2'}>
            {secondaryText}
          </Typography>
        )}
      </div>
      {disabledText && (
        <Typography color="contrast2" typographyStyle={'body2'}>
          {disabledText}
        </Typography>
      )}
    </>
  );
}
