import * as React from 'react';
/* UI */
import { ModalNoticeUI } from '../../../Modal/ModalNoticeUI';
import Button from '../../../Buttons/Button';

/// <Summary>
/// Base Modal for conformation
/// </Summary>

interface Properties {
  title: string;
  submitDisabled?: boolean;
  submitReplaced?: boolean;
  onCloseEvent: () => void;
  onSubmit: (ev: React.FormEvent) => void;
  loading: boolean;
}

class Component extends React.Component<React.PropsWithChildren<Properties>> {
  render() {
    const {
      title,
      onCloseEvent,
      onSubmit,
      submitDisabled = false,
      submitReplaced = false,
      loading
    } = this.props;

    return (
      <ModalNoticeUI
        component="form" //important for forms
        extraComponentProps={
          //important for forms
          {
            onSubmit
          }
        }
        title={title}
        onCloseEvent={() => onCloseEvent()}
        loading={loading}
        footerRightComponent={
          <>
            <Button
              colorVariant={'ghost2'}
              text={'Cancel'}
              onClick={() => onCloseEvent()}
            />
            {!submitReplaced && (
              <Button
                disabled={submitDisabled}
                text={'Confirm'}
                colorVariant={'filled1'}
                extraButtonProperties={{ type: 'submit' }} //important for forms
              />
            )}
            {submitReplaced && (
              <Button
                colorVariant={'filled1'}
                text={'Done'}
                onClick={() => onCloseEvent()}
              />
            )}
          </>
        }
      >
        {this.props.children}
      </ModalNoticeUI>
    );
  }
}

export default Component;
