import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { withCubicleFallback } from '../../../utils/CubicleFallback';
import {
  InputFieldTempate,
  InputFieldTempateProps
} from '../InputField/InputFieldTempate';
import { cx } from '@emotion/css';
import { SizeVariants, Theme } from '../../../theme/themes';
import { ContextMenu } from '../../molecules/ContextMenu';
import UseOutsideClick from '../../../helpers/hooks/UseOutsideClick';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

/**
 *The Select component allows users pick a value from a predefined list of options.
 Each option item inside the Select component needs to be wrap with the Option component.
 */
export interface SelectProps
  extends React.PropsWithChildren,
    Pick<InputFieldTempateProps, 'width'> {
  /**Label of the options or first option to be displayed */
  label?: string;
  /** selected value */
  value: string;
  disabled?: boolean;
}

const useCSS = makeCSS(({ theme, width }: SelectProps & { theme: Theme }) => {
  return {
    root: {
      cursor: 'pointer',
      width: width || '100%',
      position: 'relative'
    },
    contextMenu: {
      display: 'flex',
      alignContent: 'start',
      position: 'absolute',
      zIndex: 999,
      width: 'inherit'
    },
    placeholder: {
      pointerEvents: 'none'
    },
    extraPadding: {
      paddingTop: theme.spacing[3],
      paddingBottom: theme.spacing[3]
    }
  };
});

const Select = (props: SelectProps) => {
  const { label = 'Options', children, value, width, disabled } = props;

  const classes = useCSS({ width });

  const state = false;

  const [icon, setIcon] = useState<'unfold_more' | 'unfold_less'>(
    'unfold_more'
  );
  const [opened, setOpened] = useState(state || false);
  const [selectedOption, setSelectedOption] = useState<React.ReactNode>(null);

  useEffect(() => {
    setOpened(state || false);
  }, [state]);

  const handleOnOpen = useCallback(() => {
    setOpened(!opened);

    setIcon(opened ? 'unfold_more' : 'unfold_less');
  }, [opened, setOpened]);

  const handleSelect = useCallback(
    (option: React.ReactNode) => {
      setSelectedOption(option);
    },
    [setSelectedOption]
  );

  React.useEffect(() => {
    React.Children.forEach(children, (child) => {
      if ((child as ReactElement).props.value === value) {
        setSelectedOption(child);
      }
    });
  }, [value]);

  //close options menu when clicked anywhere else
  const handleClickOutside = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const ref = UseOutsideClick(handleClickOutside);

  return (
    <div ref={ref} className={`${classes.root}`}>
      <div onClick={!disabled ? handleOnOpen : undefined}>
        <InputFieldTempate
          width={width}
          label={''}
          size={SizeVariants.md}
          iconRight={icon}
          criticalState={false}
          disabled={disabled}
        >
          <div
            className={cx(
              classes.placeholder,
              selectedOption === null ? classes.extraPadding : ''
            )}
          >
            {selectedOption === null
              ? label
              : React.cloneElement(selectedOption as ReactElement)}
          </div>
        </InputFieldTempate>
      </div>
      <div className={`${classes.contextMenu}`} onClick={handleOnOpen}>
        {opened && (
          <ContextMenu width={'100%'}>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child as React.ReactElement, {
                onSelect: () => handleSelect(child)
              });
            })}
          </ContextMenu>
        )}
      </div>
    </div>
  );
};

const Default = withCubicleFallback(Select);
export default Default;
export { Default as Select };
