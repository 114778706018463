import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import React, { useMemo } from 'react';
import { FieldArray } from 'redux-form';
import AccountsTeamsMixSuggestionContainer from './Subcomponents/AccountsTeamsMixSuggestionContainer';
import { removeDiacritics as removeDiacriticsHelper } from '../Exports/utils/broadcastFieldValidators';

const emptyArray = [];

/** contains only accounts */
export const ProjectAddAccountsField = ({
  allSuggestion,
  searchInput,
  clearInput,
  form,
  excludedSuggestion = emptyArray,
  placeholder = 'Enter a user name',
  autoFocus = false,
  suggestionsLoading = false
}) => {
  return (
    <FieldArray
      name="accounts"
      component={AccountsTeamsMixSuggestionContainer}
      props={{
        clearInput,
        form,
        searchInput,
        allSuggestion,
        excludedSuggestion,
        placeholder,
        suggestionsLoading,
        autoFocus
      }}
    />
  );
};

/** helper functions */
const matchInputValue = (pool: string[], inputValue: string = '') => {
  return pool.some(item => item.includes(removeDiacriticsHelper(inputValue)))
};
const removeDiacritics = (str: string = '') => {
  return removeDiacriticsHelper(str.toLocaleLowerCase())
}

/** contains accounts and teams as project members */
export const ProjectAddMembersField = ({
  projectId,
  suggestedAccounts,
  searchInput,
  clearInput,
  form,
  suggestionsLoading = false,
  excludedSuggestion = emptyArray,
  placeholder = 'Enter a team or user name',
  autoFocus = false
}) => {
  const [workspaceId] = useCurrentWorkspace();

  const {
    resources: allTeams,
    loading: allTeamsLoading
  } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'team',
    edgeLabel: 'teams'
  });

  const { resources: alreadyMemberTeams } = useResourceList__ALPHA({
    edgeType: 'project-team-membership',
    edgeLabel: 'project-team-memberships',
    params: {
      filter: { project: projectId }
    },
    strategy: 'fetch-on-mount'
  });

  const allSuggestion = useMemo(() => {
    const all = [].concat(allTeams, suggestedAccounts).filter(Boolean);
    const added = alreadyMemberTeams?.map(res => res.relationships.team?.id);
    const filtered = all.filter(team => !added.includes(team?.id));
    if (searchInput) {
      const inputValue = searchInput.toLocaleLowerCase().trim();
      const queried = all.filter(
        res => {
          const display_name = removeDiacritics(res?.display_name);
          const full_name = removeDiacritics(res?.full_name);

          return matchInputValue([display_name, full_name], inputValue);
        }
      );

      return queried;
    }
    return filtered;
  }, [allTeams, alreadyMemberTeams, searchInput, suggestedAccounts]);

  const allExcluded = useMemo(() => {
    const added = alreadyMemberTeams?.map(res => res.relationships.team?.id);
    const excludedTeams = allTeams.filter(team => added.includes(team.id));

    return [].concat(excludedSuggestion, excludedTeams);
  }, [alreadyMemberTeams, allTeams, excludedSuggestion]);

  return (
    <FieldArray
      name="accounts"
      component={AccountsTeamsMixSuggestionContainer}
      props={{
        clearInput,
        form,
        searchInput,
        allSuggestion,
        excludedSuggestion: allExcluded,
        placeholder,
        suggestionsLoading: suggestionsLoading || allTeamsLoading,
        autoFocus
      }}
    />
  );
};
