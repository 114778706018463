import { UploadShape } from './extendedupload';

interface Config {}

const queueNames = [
  'uploading',
  'finished',
  'failed',
  'queued',
  'userPaused'
] as const;

type UploadStacks = typeof queueNames[number];

export class QueueManager {
  queued: UploadShape[] = [];
  userPaused: UploadShape[] = [];
  uploading: UploadShape[] = [];
  failed: UploadShape[] = [];
  finished: UploadShape[] = [];

  // constructor(config: Config = undefined) {}

  /**
   * retrieve a queue array by name
   * @param queueName Name of the queue ()
   */
  getQueue(queueName: UploadStacks) {
    if (queueNames.includes(queueName)) {
      return this[queueName];
    }
  }

  /**
   * replace a queue with a new array (immutably)
   * @param queueName Name of the queue ()
   * @param queue New array with uploads
   */
  replaceQueue(queueName: UploadStacks, queue: UploadShape[]) {
    if (queueNames.includes(queueName)) {
      this[queueName] = queue;
    }
  }

  /**
   * check if a queue contains a specific upload
   * @param queueName Name of the queue ()
   * @param queryUpload ExtendedUpload object to look for
   */
  has(queueName: UploadStacks, queryUpload: UploadShape) {
    return !!this.find(queueName, queryUpload).length;
  }

  /**
   * check if a queue contains any uploads
   * @param queueName Name of the queue ()
   */
  any(queueName: UploadStacks) {
    return !!this.getQueue(queueName).length;
  }

  /** make a filter function
   * @param queryUpload ExtendedUpload object to look for
   * @param method include or exclude the queryUpload from the result set
   */
  makeFilter(
    queryUpload: UploadShape,
    method: 'include' | 'exclude' = 'include'
  ) {
    const queryFile = queryUpload.file as File;
    return upload => {
      const uploadFile = upload.file as File;
      const match =
        uploadFile.name === queryFile.name && upload.url === queryUpload.url;
      if (method === 'exclude') {
        return !match;
      }
      return match;
    };
  }

  /**
   * get a list of occurences of an upload in a queue
   * @param queueName Name of the queue ()
   * @param queryUpload ExtendedUpload object to look for
   */
  find(queueName: UploadStacks, queryUpload: UploadShape) {
    const queue = this.getQueue(queueName);
    const filter = this.makeFilter(queryUpload);
    const uploads = queue.filter(filter);
    if (uploads.length > 1) {
      console.warn(
        'found multiple identical uploads on the same stack. This should not happen!'
      );
    }
    return uploads;
  }

  /**
   * get a list excluding occurences of an upload in a queue
   * @param queueName Name of the queue ()
   * @param queryUpload ExtendedUpload object to exclude
   */
  except(queueName: UploadStacks, queryUpload: UploadShape) {
    const queue = this.getQueue(queueName);
    const filter = this.makeFilter(queryUpload, 'exclude');
    const uploads = queue.filter(filter);
    if (queue.length - uploads.length > 1) {
      console.warn(
        'found multiple identical uploads on the same stack. This should not happen!'
      );
    }
    return uploads;
  }

  /**
   * Add an upload to a queue
   * @param queueName Name of the queue ()
   * @param upload ExtendedUpload object to add
   */
  add(queueName: UploadStacks, upload: UploadShape) {
    const currentQueue = this.getQueue(queueName);
    this.replaceQueue(queueName, [...currentQueue, upload]);
    upload.triggerReduxUpdate();
  }

  /**
   * Remove an upload from a queue
   * @param queueName Name of the queue ()
   * @param upload ExtendedUpload object to remove
   */
  remove(queueName: UploadStacks, upload: UploadShape) {
    const excluded = this.except(queueName, upload);
    this.replaceQueue(queueName, excluded);
    upload.triggerReduxUpdate();
  }

  /**
   * Move an upload from one queue to another
   * @param sourceQueueName Name of the source queue ()
   * @param targetQueueName Name of the target queue ()
   * @param upload ExtendedUpload object to move
   */
  move(
    sourceQueueName: UploadStacks,
    targetQueueName: UploadStacks,
    upload: UploadShape
  ) {
    if (this.has(sourceQueueName, upload)) {
      this.remove(sourceQueueName, upload);
    } else {
      console.warn("source queue didn't have upload", upload, sourceQueueName);
    }

    if (this.has(targetQueueName, upload)) {
      console.warn('target queue already had upload', upload, targetQueueName);
      this.remove(targetQueueName, upload);
    }
    this.add(targetQueueName, upload);
    upload.triggerReduxUpdate();
  }

  /**
   * Get newest/latest entry of a queue
   * @param queueName Name of the queue ()
   */
  newest(queueName: UploadStacks) {
    const queue = this.getQueue(queueName);
    return queue.slice(-1)[0];
  }

  /**
   * Get oldest/earliest entry of a queue
   * @param queueName Name of the queue ()
   */
  oldest(queueName: UploadStacks) {
    const queue = this.getQueue(queueName);
    return queue[0];
  }
  clearAll() {
    queueNames.forEach(q => {
      this.replaceQueue(q, []);
    });
  }
}
