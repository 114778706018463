// import design tokens that get pushed from figma
import darkTheme, {
  Theme as RawTheme
} from '@cube3/design-tokens/build/js/dark-tokens';
import lightTheme from '@cube3/design-tokens/build/js/light-tokens';
import { proxifyTheme, Proxified } from './utils/proxifyTheme';

export const themes = {
  dark: proxifyTheme(darkTheme as RawTheme),
  light: proxifyTheme(lightTheme as RawTheme)
};

export type Theme = Proxified<RawTheme>;

export enum SizeVariants {
  '3xs' = '3xs',
  '2xs' = '2xs',
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl',
  '2xl' = '2xl',
  '3xl' = '3xl',
  '4xl' = '4xl',
  '5xl' = '5xl',
  '6xl' = '6xl',
  '7xl' = '7xl',
  '8xl' = '8xl'
}
