import React, { useCallback } from 'react';

// types and the HOCstuff
import { useModalActions } from '../../../layout/Modals/modalActions';
import { useHistory } from 'react-router-dom';
import { setTour } from '@cube3/state/src/redux/ducks/ui/actions';
import { useDispatch } from 'react-redux';

// UI
import GenericNoticeModalUI from '@cube3/ui/src/modals/GenericNoticeModalUI';
import { ModalReceiverProps } from '../../../layout/Modals/ModalManager';

interface RegistrationSucceededModalProps extends ModalReceiverProps {
  modalContex: {
    handleLogin(): void;
  };
}

function RegistrationSucceededModal(
  props: RegistrationSucceededModalProps
): JSX.Element {
  const { modalContext = { joinWorkspaceRoute: false } } = props;
  const modalActions = useModalActions();

  const history = useHistory();
  const dispatch = useDispatch();

  /* 
  When a invited user is already a cube user, then the flow 
  goes via the joinWorkspaceRoute. In that case we only want 
  to close the modal and re-route. Otherwise we trigger a login call.
  */
  const handleCloseAndRedirect = useCallback(() => {
    if (modalContext.joinWorkspaceRoute) {
      history.push('/');
      modalActions.closeAllModals();
    } else {
      modalContext.handleLogin();
      modalActions.closeAllModals();
    }
    dispatch(setTour(true));
  }, [modalContext, history, modalActions.closeAllModals, dispatch, setTour]);

  return (
    <GenericNoticeModalUI
      onClose={handleCloseAndRedirect}
      title={`Welcome to ${props.modalContext.workspace}`}
      text="Your account has been created. You can now start collaborating."
      buttonText="Continue"
    />
  );
}

export default RegistrationSucceededModal;
