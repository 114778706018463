export const isEqual = (a, b) => {
  if (typeof a !== typeof b) {
    return false;
  }

  if ((typeof a !== 'object' || a === null) && a !== b) {
    return false;
  }

  if (typeof a === 'object') {
    let merged = { ...b, ...a };
    for (let k in merged) {
      if (merged.hasOwnProperty(k)) {
        if (!isEqual(a[k], b[k])) {
          return false;
        }
      }
    }
  }

  return true;
};
