import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  useFormatMetadataCategory,
  useFormatMetadataValues
} from '@cube3/state/src/redux/components/Hooks/metadataHooks';
import { useCurrentProject } from '@cube3/state/src/redux/components/Hooks/useCurrentProject';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useMemo, useRef } from 'react';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';

/**
 * @returns
 * - metadataFields: formatted 'broadcast' metadata-category fields
 * - initialValues: { [field_name]: matched project metadata values }
 * - loading: loading state
 */
export const useTVBroadcastMetadataConfig = (
  rid?: ResourceIdentifier<'asset'>
) => {
  const { projectId } = useCurrentProject();
  const [workspaceId] = useCurrentWorkspace();

  const metadataForm = useResourceList__ALPHA(
    useRef({
      resourceType: 'workspace' as const,
      resourceId: workspaceId,
      edgeType: 'metadata-category' as const,
      edgeLabel: 'metadata-categories',
      params: {
        filter: {
          categories_for: 'broadcast'
        }
      }
    }).current
  );

  const metadataValues = useResourceList__ALPHA({
    resourceId: rid ? rid.id : projectId,
    resourceType: rid ? rid.type : 'project',
    edgeType: 'metadata-value',
    edgeLabel: 'metadata-values'
  });

  const metadataFields = useFormatMetadataCategory(metadataForm.first, true);
  const formattedValues = useFormatMetadataValues(
    metadataValues.resources,
    metadataForm.first
  );

  /** convert { [field_id]: value } to { [system_name]: value } */
  const broadcastValues = useMemo(() => {
    if (!formattedValues || !metadataFields) return {};
    return Object.keys(formattedValues).reduce((acc, id) => {
      const field = metadataFields.find((f) => f.id === id);
      const name = field?.system_name || field?.display_name;
      return { ...acc, [name]: formattedValues[id] };
    }, {});
  }, [formattedValues, metadataFields]);

  return {
    metadataFields,
    broadcastValues,
    loading:
      (rid || projectId) &&
      (metadataForm.status !== statuses.SUCCESS ||
        metadataValues.status !== statuses.SUCCESS)
  };
};
