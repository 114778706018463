//
import { FSA } from '../flux-standard-action';

export interface State {
  online: boolean;
  lastOnline: number;
}

const initialState = {
  online: true,
  lastOnline: undefined
};

const ns = 'cube3/offline-detection';

const ONLINE = `${ns}/ONLINE`;
const OFFLINE = `${ns}/OFFLINE`;
const CHECK_OFFLINE = `${ns}/CHECK_OFFLINE`;

const actions = {
  ONLINE,
  OFFLINE,
  CHECK_OFFLINE
};

const online = () => ({
  type: ONLINE,
  payload: {
    timestamp: Date.now()
  }
});

const offline = () => ({
  type: OFFLINE
});

const checkOffline = () => ({
  type: CHECK_OFFLINE
});

const actionCreators = {
  online,
  offline,
  checkOffline
};

// TODO: write better memoize, that serializes input action for check
const isOnline = state => state.online;
const isOffline = state => !state.online;
const offlineSince = state => (!state.online ? state.lastOnline : null);

const selectors = {
  isOnline,
  isOffline,
  offlineSince
};

// Main reducer

const reducer = (state: State = initialState, action: FSA) => {
  const { type, payload = {} } = action;

  switch (type) {
    case ONLINE:
      return {
        online: true,
        lastOnline: payload.timestamp
      };

    case OFFLINE:
      return {
        ...state,
        online: false
      };

    default:
      return state;
  }
};

export { actions, actionCreators, selectors };
export { reducer };
export default reducer;
