import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import EmailBox from '../../forms/EmailBox';
import Cube3TextField from '../../forms/textfields/Cube3TextField';
import { ExportOptionRow } from './ExportOptionRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inputField: {
      border: 'none',
      background: 'inherit',
      marginTop: '-8px',
      height: 'auto',
      minHeight: 40
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      width: '100%',
      overflow: 'hidden'
    }
  })
);

interface Props {
  label?: string;
  type?: string;
  required?: boolean;
  items: string[];
  placeholder?: string;
  validator?: (val) => any;
  /** Chip component */
  component: (val: string, onDelete?: Props['onDelete']) => JSX.Element;
  onAdd: (val: string) => void;
  onDelete: (val: string) => void;
}

const keysListeners = ['Tab', ',', ';', 'Enter', ' '];
export const handleKeyDown = ({
  evt,
  inputValue,
  values,
  onClear,
  onAdd,
  onDelete,
  isValid = true
}) => {
  if (!inputValue && values.length === 0) return;

  if (evt.type === 'blur' && isValid) {
    evt.preventDefault();
    if (inputValue && !values.find((t) => t === inputValue)) {
      onAdd(inputValue);
      onClear();
      return;
    }
  }

  if (evt.key === 'Backspace' && !inputValue) {
    onDelete(inputValue);
  } else if (keysListeners.includes(evt.key) && inputValue && isValid) {
    evt.preventDefault();
    if (inputValue && !values.find((t) => t === inputValue)) {
      onAdd(inputValue);
      onClear();
    }
  }
};

const emptyArray = [];

export const ExportInputPicker = (props: Props) => {
  const {
    items = emptyArray,
    placeholder,
    validator,
    component,
    onAdd,
    onDelete
  } = props;
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  const handleCommit = (evt) =>
    handleKeyDown({
      evt,
      inputValue,
      values: items,
      onClear: () => setInputValue(''),
      onAdd,
      onDelete,
      isValid: validator && !validator(inputValue)
    });

  return (
    <ExportOptionRow
      {...props}
      component={
        <EmailBox errors={inputValue && validator && validator(inputValue)}>
          <div className={classes.inputContainer}>
            {items.map((val) => component(val, onDelete))}
            <div style={{ flexShrink: 1, flexGrow: 1 }}>
              <Cube3TextField
                value={inputValue}
                placeholder={placeholder}
                fullWidth={true}
                classes={{ root: classes.inputField }}
                onChange={(e) =>
                  e.type !== 'blur' && setInputValue(e.target.value.trim())
                }
                onBlur={handleCommit}
                onKeyDown={handleCommit}
              />
            </div>
          </div>
        </EmailBox>
      }
    />
  );
};
