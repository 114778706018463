import React, { ReactElement } from 'react';

// UI
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import FormHelperText, {
  FormHelperTextProps
} from '@material-ui/core/FormHelperText';
import Scrollbar from '../Scrollbar/Scrollbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 'auto'
    },
    innerContainer: (props: EmailBoxProps) => ({
      background: theme.customPalette.surface.shade2,
      borderRadius: 4,
      padding: '8px 8px 4px 8px',
      position: 'relative',
      borderColor: props.errors
        ? theme.customPalette.surface.danger1
        : props.disabled
        ? 'rgba(255, 255, 255, 0.12)'
        : 'rgba(255, 255, 255, 0.23)',
      border: '1px solid',
      display: 'flex',
      flexWrap: 'wrap'
    })
  })
);

interface EmailBoxProps {
  children?: ReactElement;
  helperText?: string | ReactElement;
  formHelperTextProps?: FormHelperTextProps;
  errors: boolean;
  disabled?: boolean;
}

function EmailBox(props: EmailBoxProps) {
  const classes = useStyles(props);
  const { container, innerContainer } = classes;
  const { children, helperText, formHelperTextProps } = props;

  return (
    <div className={container}>
      <div className={innerContainer}>
        <Scrollbar autoHeight={true}>
          <div style={{ display: 'flex' }}>{children}</div>
        </Scrollbar>
      </div>
      {helperText && (
        <FormHelperText {...formHelperTextProps} component={'div'}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}

export default EmailBox;
