import React from 'react';
import { MultipleResourcePicker } from '../MultipleResourcePicker';
import { useContractAssetSuggestions } from './useContractAssetSuggestions';

const CLEAR_ON = ['blur', 'add-new'];

export const ContractAssetsPicker = props => {
  const { selected, query } = props;

  const [suggested, excluded, suggestionsLoading] = useContractAssetSuggestions(
    {
      context: undefined,
      selected,
      query
    }
  );

  return (
    <MultipleResourcePicker
      {...props}
      clearOn={CLEAR_ON}
      suggestedResources={suggested}
      excludedResources={excluded}
      placeholder="Start typing an asset name..."
      suggestionsLoading={suggestionsLoading}
    />
  );
};
