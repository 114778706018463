// TODO: make pollers only poll when idle

type Checker = () => Promise<{}>;

interface ConfigInterface {
  pollInterval?: number;

  checks?: Checker[];
}

const navigatorCheck = () =>
  new Promise((res, rej) => {
    if (navigator.onLine) {
      res(true);
    } else {
      rej(new Error('navigator is offline'));
    }
  });

export class OfflineDetection {
  private config: ConfigInterface = {
    pollInterval: 5000,
    checks: []
  };

  private state = {
    online: false,
    timestamp: Date.now()
  };

  private internals = {
    subscriptions: [],
    timeout: null,
    nextPoll: null
  };

  constructor(config: ConfigInterface) {
    this.config = {
      ...this.config,
      ...config,
      checks: [navigatorCheck, ...config.checks]
    };
    this.init();
  }

  subscribe(cb: Function) {
    const filtered = this.internals.subscriptions.filter((s) => s !== cb);
    if (filtered.length < this.internals.subscriptions.length) {
      console.warn('Callback was already subscribed', cb);
    } else {
      this.checkOnline();
    }
    if (this.internals.subscriptions.length === 0) {
      this.poll();
    }
    this.internals.subscriptions = [...filtered, cb];
    return () => this.unsubscribe(cb);
  }

  unsubscribe(cb: Function) {
    this.internals.subscriptions = this.internals.subscriptions.filter(
      (s) => s !== cb
    );
    if (this.internals.subscriptions.length === 0) {
      this.stopPoll();
    }
  }

  private init() {
    if (typeof window.addEventListener === 'function') {
      window.addEventListener(
        'online',
        (ev) => {
          this.checkOnline();
        },
        false
      );

      window.addEventListener(
        'offline',
        (ev) => {
          this.checkOnline();
        },
        false
      );

      var connection =
        // eslint-disable-next-line
        navigator['connection'] ||
        // eslint-disable-next-line
        navigator['mozConnection'] ||
        // eslint-disable-next-line
        navigator['webkitConnection'];
      if (connection) {
        connection.addEventListener(
          'change',
          (ev) => {
            this.checkOnline();
          },
          false
        );
      }
    } else {
      console.warn(`Browser event based offline detection is not available`);
    }
  }

  private poll = () => {
    this.checkOnline();
    window.clearTimeout(this.internals.timeout);
    this.internals.timeout = window.setTimeout(
      this.poll,
      this.config.pollInterval,
      (this.internals.nextPoll = Date.now() + this.config.pollInterval)
    );
  };

  private stopPoll = () => {
    if (this.internals.timeout) {
      window.clearTimeout(this.internals.timeout);
    }
    this.internals.nextPoll = null;
  };

  checkOnline() {
    return Promise.all(this.config.checks.map((c) => c()))
      .then((res) => true)
      .catch((err) => {
        return false;
      })
      .then((online) => {
        if (this.state.online !== online) {
          this.setState({ online, timestamp: Date.now() });
        }
        if (!online) {
          console.warn('Browser appears to be offline');
        }
        return online;
      });
  }

  getSecondsToNextPoll() {
    return this.internals.nextPoll
      ? Math.ceil((this.internals.nextPoll - Date.now()) / 1000)
      : undefined;
  }

  private setState(statePartial: Object) {
    const oldState = this.state;
    this.state = { ...oldState, ...statePartial };

    this.internals.subscriptions.forEach((cb) => {
      cb(this.state.online ? 'online' : 'offline', this.state);
    });
  }
}

export const urlCheck =
  ({ url = '/', timeout = 30_000 } = {}) =>
  () => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onerror = reject;
      xhr.ontimeout = reject;

      xhr.onload = () => {
        const response = xhr.responseText.trim();
        if (response === undefined || xhr.status > 399) {
          reject(new Error(`${url} could not be reached`));
        } else {
          resolve(true);
        }
      };

      xhr.open('GET', url);
      xhr.timeout = timeout;
      xhr.send();
    });
  };
