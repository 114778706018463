import React from 'react';

import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useModalActions } from '../../Modals/modalActions';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Tag } from '@cube3/common/model/schema/resources/tag';
import { TagsList } from '@cube3/cubicle/src/core/atoms/Tag/TagsList';
import { CreateTagRowUI } from '@cube3/cubicle/src/core/molecules/TagManagement/types';
import { Typography } from '@cube3/ui/src/typography/Typography';

interface Props {
  modalContext: {
    /** the selected tags that you want to change category */
    conflictingTags: Tag[];
    category: string;
  };
}

export const MoveTagsNotice = (props: Props) => {
  const { conflictingTags, category } = props.modalContext;
  const { previousModal } = useModalActions();

  return (
    <ModalNoticeUI
      title={`Change category`}
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button
            label="Close"
            onClick={previousModal}
            background="secondary"
            buttonStyle="solid"
          />
        </>
      }
    >
      {conflictingTags?.length ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="contrast2">
            Conflicting tags have been found:
          </Typography>
          <TagsList tags={conflictingTags as CreateTagRowUI[]} />
          <div style={{ height: 24 }} />
          <Typography color="contrast2">
            Please rename or delete these tags before moving them to {category}
          </Typography>
        </div>
      ) : null}
    </ModalNoticeUI>
  );
};
