import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { UseStyles } from '../../UseStylesHelper/UseStylesHelper';
import { ThumbnailVariants } from '../../thumbnails/ThumbnailVariants';

import { Typography } from '../../typography/Typography';
import InteractableSurface from '../../Surface/InteractableSurface';
import { Divider } from '../../Surface/Divider';
import { Time } from '../../human/Time';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '99px',
      display: 'flex',
      alignItems: 'center'
    },
    contents: {
      display: 'flex',
      marginTop: '15px',
      marginBottom: '15px'
    },
    thumbnailcontainer: {
      display: 'flex',
      alignItems: 'center',
      width: '90px'
    },
    thumbnail: {
      marginLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      width: '70px',
      marginRight: '10px'
    },
    thumbnailWithHover: {
      cursor: 'pointer',
      marginLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      width: '70px',
      marginRight: '10px'
    },
    descriptionContainer: {
      display: 'block',
      '&  span': {
        fontWeight: 700
      }
    },
    descriptionContainerWithHover: {
      display: 'block',
      '&  span': {
        fontWeight: 700
      },
      '&  span:hover': {
        borderBottom: '1px solid white',
        cursor: 'pointer'
      }
    },
    line: {
      marginBottom: '3px'
    },
    linkRow: {
      display: 'flex'
    }
  });

// note that useStyles, using makestyles is seperated from the styles funciton so we can properly extend in properties.
const useStyles = makeStyles(styles);

interface Properties extends UseStyles<typeof useStyles> {
  occuredAt: string;
  subjectName: string;
  underTitle: string;
  sourceImage: string | JSX.Element;
  targetLinkComponent?: JSX.Element;
  onClickAsset: () => void;
}

export const ProjectActivityItemUI: React.FunctionComponent<Properties> = (
  props
) => {
  const classes = useStyles(props);

  const {
    occuredAt,
    subjectName,
    underTitle,
    sourceImage,
    targetLinkComponent,
    onClickAsset
  } = props;

  return (
    <>
      <InteractableSurface
        classes={{ root: classes.root }}
        highlightOnHover={true}
      >
        <div className={classes.contents}>
          <div className={classes.thumbnailcontainer}>
            <div
              onClick={onClickAsset ? () => onClickAsset() : undefined}
              className={
                onClickAsset ? classes.thumbnailWithHover : classes.thumbnail
              }
            >
              {typeof sourceImage === 'string' ? (
                <ThumbnailVariants
                  fillVariant={'none'}
                  showCheckerboard={false}
                  cover={true}
                  ratio={'sixteenToNine'}
                  src={sourceImage}
                />
              ) : (
                sourceImage
              )}
            </div>
          </div>

          <div
            className={
              onClickAsset
                ? classes.descriptionContainerWithHover
                : classes.descriptionContainer
            }
          >
            {/* Asset name */}
            <Typography typographyStyle={'body1'}>
              <span onClick={onClickAsset ? () => onClickAsset() : undefined}>
                {subjectName}
              </span>
            </Typography>

            {/* undertitle */}
            <Typography
              className={classes.line}
              typographyStyle={'body3'}
              color="contrast2"
            >
              {underTitle} <Time date={occuredAt} />
            </Typography>

            {/* Link */}
            {targetLinkComponent && (
              <div className={classes.linkRow}>{targetLinkComponent}</div>
            )}
          </div>
        </div>
      </InteractableSurface>

      <Divider />
    </>
  );
};
