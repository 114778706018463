import React, { useCallback, useMemo } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { ContractTileUI } from '@cube3/ui/src/Prefabs/contracts/ContractTileUI';
import { getStatusColor } from '@cube3/ui/src/StatusBlip/getStatusColor';
import { useContractSelection } from '../hooks/useContractSelection';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { Contract } from '@cube3/common/model/schema/resources/contract';

import { generatePath, useHistory } from 'react-router';
import {
  getAssetUrl,
  urlStructureProject
} from '../../../routing/routingPaths';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

interface Props {
  contract: Contract;
  variant?: 'slim' | 'regular';
  expiresSoonOffsetDay?: number;
}

export const ContractTile = React.memo(
  ({ contract, variant = 'regular', expiresSoonOffsetDay }: Props) => {
    const modalActions = useModalActions();

    const contractType = useResource__ALPHA({
      resourceId: contract.relationships.contract_type?.id,
      resourceType: contract.relationships.contract_type?.type
    });

    const attachedAssets = useResourceList__ALPHA({
      resourceId: variant === 'regular' && contract.id,
      resourceType: contract.type,
      edgeType: 'asset',
      edgeLabel: 'assets'
    });

    const attachedProjects = useResourceList__ALPHA({
      resourceId: variant === 'regular' && contract.id,
      resourceType: contract.type,
      edgeType: 'project',
      edgeLabel: 'projects'
    });

    const history = useHistory();
    const [workspaceId] = useCurrentWorkspace();

    const attachments = useMemo(() => {
      if (
        attachedAssets.status !== statuses.SUCCESS ||
        attachedProjects.status !== statuses.SUCCESS
      ) {
        return undefined;
      }

      const all = [...attachedProjects.resources, ...attachedAssets.resources];

      return all.map((r) => ({
        name: r.display_name,
        type: r.type,
        onClick: (ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          const path =
            r.type === 'project'
              ? generatePath(urlStructureProject, {
                  workspaceId,
                  projectId: r.id
                })
              : getAssetUrl({
                  assetId: r.id,
                  workspaceId
                });
          history.push(path, { from: 'previous' });
        }
      }));
    }, [
      attachedAssets.resources,
      attachedProjects.resources,
      attachedAssets.status,
      attachedProjects.status,
      history,
      workspaceId
    ]);

    const manageContract = useCallback(
      () =>
        modalActions.openModal('manage_contract', { contractId: contract.id }),
      [contract, modalActions]
    );

    const [selection, setSelection] = useContractSelection();
    const selectContract = useCallback(() => {
      if (!selection || selection.id !== contract.id) {
        setSelection(contract);
      }
    }, [setSelection, selection, contract]);

    return (
      <ContractTileUI
        statusColor={getStatusColor(contract, expiresSoonOffsetDay)}
        name={contract.display_name}
        type={contractType.resource?.display_name}
        attachments={attachments}
        selected={selection && selection.id === contract.id}
        onClick={variant !== 'slim' ? manageContract : selectContract}
        showBorder={variant !== 'slim'}
        showAttachments={variant !== 'slim'}
        showDate={true}
        startsDate={contract.starts_at}
        expiresDate={contract.expires_at}
      />
    );
  }
);
