import { actionCreators as edgeActionCreators } from '../../ducks/resource-edges';
import { updateUpload } from './updateUpload';

/** add file stub to render something imediately, before asset's been created */
export const addFileStub = (
  file,
  parentResource,
  dispatch,
  getState,
  createdAt = undefined,
  workspaceId
) => {
  updateUpload(
    file.id,
    {
      type: 'file-upload',
      progress: 0,
      failed: false,
      file: file.raw,
      display_name: file.name,
      tusUpload: undefined,
      active: false,
      asset_id: null,
      created_at: null,
      initiated_at: new Date(createdAt).getTime()
    },
    dispatch,
    getState
  );

  if (parentResource) {
    dispatch(
      edgeActionCreators.receiveResourceEdges(
        parentResource.id,
        parentResource.type,
        'file-uploads',
        'file-upload',
        [
          {
            id: file.id,
            type: 'file-upload'
          }
        ],
        { merge: true }
      )
    );
  }

  dispatch(
    edgeActionCreators.receiveResourceEdges(
      workspaceId,
      'workspace',
      'file-uploads',
      'file-upload',
      [
        {
          id: file.id,
          type: 'file-upload'
        }
      ],
      { merge: true }
    )
  );
};
