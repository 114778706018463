import { statuses } from '../ducks/request-status';
import { selectors as requestStatusSelectors } from '../ducks/request-status';
import { actionCreators } from '../ducks/resource-nodes';

export const mergeStatuses = (statusArray, ignoreUnset = true) => {
  if (!statusArray || !Array.isArray(statusArray)) {
    return undefined;
  }

  if (!ignoreUnset && statusArray.filter((s) => !s).length) {
    return undefined;
  }

  if (statusArray.filter((s) => s === statuses.IN_FLIGHT).length) {
    return statuses.IN_FLIGHT;
  }

  if (statusArray.filter((s) => s === statuses.INVALIDATED).length) {
    return statuses.INVALIDATED;
  }

  if (statusArray.filter((s) => s === statuses.FAILED).length) {
    return statuses.FAILED;
  }

  if (statusArray.filter((s) => s === statuses.SUCCESS).length) {
    return statuses.SUCCESS;
  }

  return statusArray.filter((s) => s)[0];
};

// TODO: move into request status duck?
export const getMergedStatuses = (state, resourceType, resourceId) => {
  const requestStatus = requestStatusSelectors.getStatus(
    state.requestStatus,
    actionCreators.retrieveResource(resourceType, resourceId)
    // NOTE: above action isn't dispatched, but serialized, and used as identifier
  );
  const mutateStatus = requestStatusSelectors.getStatus(
    state.requestStatus,
    actionCreators.mutateResource(resourceType, resourceId, {}) // TODO: find a way to identify a mutation
    // NOTE: above action isn't dispatched, but serialized, and used as identifier
  );
  const deleteStatus = requestStatusSelectors.getStatus(
    state.requestStatus,
    actionCreators.deleteResource(resourceType, resourceId) // TODO: find a way to identify a mutation
    // NOTE: above action isn't dispatched, but serialized, and used as identifier
  );

  return mergeStatuses([mutateStatus, deleteStatus, requestStatus]);
};
