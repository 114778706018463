import React from 'react';
import { withCubicleFallback } from '../../../utils/CubicleFallback';

/** Clickable icon */
const MarkerButton = (props) => {
  return <>{props.children}</>;
};

const Default = withCubicleFallback(MarkerButton);
export { Default as MarkerButton };
