import React from 'react';

import { EditSettingModal } from './EditSettingsModal';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback } from 'react';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useEffect } from 'react';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

interface Props {}

const formId = 'edit_default_shares_download_type';

export const EditShareDownloadTypeModal: React.FC<Props> = () => {
  const workspace = useCurrentWorkspace()[1];
  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    actionDecorators: [addFormId(formId, { delegate: true })],
    cacheInvalidator: null
  });

  const { previousModal } = useModalActions();

  const handleSave = useCallback(
    (data) => {
      mutate({
        type: 'workspace',
        id: workspace?.id,
        ...data
      });
    },
    [mutate, workspace]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  return (
    <EditSettingModal
      onSave={handleSave}
      loading={!workspace || mutateStatus === statuses.IN_FLIGHT}
      form={formId}
      initialValues={{
        default_shares_download_type: workspace.default_shares_download_type
      }}
      config={{
        fields: [
          {
            type: 'select',
            name: 'default_shares_download_type',
            extraProps: {
              options: downloadOptions
            },
            parse: (val) => val.id,
            format: (val) => downloadOptions.find((o) => o.id === val),
            value_type: 'boolean',
            // validate: validator,
            label: 'Download type'
          }
        ]
      }}
      title={'Downloads type default'}
    />
  );
};

const downloadOptions = [
  { display_name: 'Preview files only', id: 'DOWNLOAD_TYPE_PREVIEW' },
  { display_name: 'Original files only', id: 'DOWNLOAD_TYPE_ORIGINAL' }
];
