import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

// ui
import { ModalMenuUI } from '../../../Modal/ModalMenuUI';
import Button from '../../../Buttons/Button';
import { Typography } from '../../../typography/Typography';
import { Divider } from '../../../Surface/Divider';

// forms and textfields
import LinkSettingsContent from '../LinkSettingsContent';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';
import { usePreventDirtyClose } from '../../shareLink/Modals/LinkSettings/hooks/usePreventDirtyClose';

/// <Summary>
/// UI component for share link overview
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerButtonMargin: {
      marginLeft: '8px'
    },
    content: {
      minHeight: '70vh'
    },
    container: {
      padding: `0px 0px ${theme.customSpacing.padding[24]} 0px`
    },
    subTitle: {
      marginBottom: theme.customSpacing.margin[16]
    },
    optionsText: {
      paddingBottom: 12
    },
    cancelButton: {
      color: theme.customPalette.primary.textWhiteLowEmphasis,
      marginRight: '10px',
      border: `${theme.customPalette.primary.lineWhiteLowEmphasis} 1px solid`,
      '&:hover': {
        backgroundColor: 'initial'
      }
    },
    submitButton: {
      backgroundColor: theme.customPalette.secondary.light,
      color: theme.customPalette.primary.textWhiteLowEmphasis,
      '&:hover': {
        backgroundColor: theme.customPalette.secondary.light
      }
    }
  })
);

interface Properties {
  loading: boolean;

  // events
  onCloseEvent: () => void;
  onClickCreate: () => void;

  // render props
  renderPropsEmailBox: () => JSX.Element;
  renderPropsLinkSettings: () => JSX.Element;

  submitDisabled: boolean;
  dirty: boolean;
}

const ReviewLinkNewUI: React.FC<Properties> = (props) => {
  const {
    loading,
    onCloseEvent,
    onClickCreate,
    submitDisabled,
    renderPropsEmailBox,
    renderPropsLinkSettings,
    dirty
  } = props;

  const classes = useStyles();

  const { extraModalProps, promptElement } = usePreventDirtyClose({
    dirty,
    handleCloseEvent: onCloseEvent,
    promptMessage:
      'This Review has not been sent. Closing will discard current settings.',
    promptTitle: 'Cancel Review creation?'
  });

  const disabledCheck = React.useCallback(() => {
    return (
      submitDisabled ||
      loading ||
      (document.activeElement.tagName === 'INPUT' &&
        document.activeElement.ariaAutoComplete === 'list') // this is the downshift email suggestions dropdown
    );
  }, []);

  useGlobalSubmit(onClickCreate, disabledCheck);

  return (
    <>
      <ModalMenuUI
        loading={loading}
        title={'New review link'}
        multiplyContentSpace={10}
        onCloseEvent={() => onCloseEvent()}
        extraModalProps={extraModalProps}
        footerRightComponent={
          <>
            <Button
              text={'Cancel'}
              colorVariant="ghost2"
              onClick={() => onCloseEvent()}
            />
            <Button
              text={'Send link'}
              classes={{ root: classes.footerButtonMargin }}
              colorVariant={'filled1'}
              onClick={() => onClickCreate()}
              disabled={submitDisabled}
            />
          </>
        }
      >
        <div className={classes.container}>
          <Typography
            component="span"
            typographyStyle={'heading3'}
            color="contrast2"
            className={classes.subTitle}
          >
            Send links to
          </Typography>

          {renderPropsEmailBox()}
        </div>

        <Divider />

        <LinkSettingsContent containerStyles={classes.container}>
          <Typography
            component="span"
            typographyStyle={'heading3'}
            color="contrast2"
            className={classes.subTitle}
          >
            Options
          </Typography>
          {renderPropsLinkSettings()}
        </LinkSettingsContent>
      </ModalMenuUI>
      {promptElement}
    </>
  );
};

export default ReviewLinkNewUI;
