import React, { useCallback, useEffect, useState } from 'react';
import { useSelection } from '@cube3/state/src/redux/components/useSelection';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import Button from '@cube3/ui/src/Buttons/Button';
import {
  ExportMultiAssetsInfo,
  ExportSingleAssetInfo
} from '../prefabs/ExportAssetsInfo';
import { Divider } from '@cube3/ui/src/Surface/Divider';
import { useExportOptions } from '../hooks/useExportOptions';
import { ExportOptionsForm } from '../prefabs/ExportOptionsForm';
import { ExportOptionsFormState } from '../state/exportOptionsState';
import { Padding } from '../../Contracts/prefabs/Padding';
import { useExportTargetDefaults } from '../../AdminCenter/views/WorkspaceIntegrationsView/hooks/useExportTargetDefaults';
import { useDispatch } from 'react-redux';
import { queueTabs, toggleMediaqueue } from '@cube3/state/src/redux/ducks/ui';
import { MetaParameters } from '@cube3/state/types';

interface Props {
  modalContext: {
    adAccount: {
      id: string;
      display_name: string;
    };
    exportTargetId: string;
    exportTargetTypeId: string;
  };
}

export const MetaExportOptionsModal: React.FC<Props> = (props) => {
  const { adAccount, exportTargetId, exportTargetTypeId } = props.modalContext;
  const [formState, setFormState] = useState<ExportOptionsFormState>();
  const { previousModal, closeAllModals, goBackModals } = useModalActions();
  const { selection } = useSelection();

  const { createExportStatus, handleCreateExport, exportBatchId } =
    useExportOptions<MetaParameters>({ selection, exportTargetId });

  const handleSubmit = useCallback(() => {
    handleCreateExport({
      recipients: formState.recipients,
      integration_parameters: {
        name: formState.title,
        adAccountID: adAccount.id
      }
    });
  }, [handleCreateExport, adAccount, formState]);

  const dispatch = useDispatch();

  // open export status view modal
  useEffect(() => {
    if (exportBatchId && createExportStatus === statuses.SUCCESS) {
      closeAllModals();
      dispatch(toggleMediaqueue(true, queueTabs.EXPORTS));
    }
  }, [
    adAccount,
    createExportStatus,
    exportBatchId,
    formState,
    closeAllModals,
    dispatch
  ]);

  const [defaults, defaultsStatus] =
    useExportTargetDefaults(exportTargetTypeId);

  const handleCancel = useCallback(() => {
    goBackModals(2);
  }, [goBackModals]);

  return (
    <ModalMenuUI
      title="Export to Meta • Options"
      wide={true}
      multiplyContentSpace={10}
      loading={
        defaultsStatus !== statuses.SUCCESS ||
        (createExportStatus &&
          createExportStatus !== statuses.SUCCESS &&
          createExportStatus !== statuses.FAILED)
      }
      onCloseEvent={handleCancel}
      footerLeftComponent={
        <Button
          text={'Previous'}
          onClick={previousModal}
          colorVariant={'ghost2'}
        />
      }
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={handleCancel}
            disabled={false}
          />
          <Button
            disabled={formState?.invalid}
            colorVariant={'filled1'}
            text={'Export'}
            onClick={handleSubmit}
          />
        </>
      }
    >
      <Padding>
        {selection.length > 0 ? (
          <>
            {selection.length > 1 ? (
              <ExportMultiAssetsInfo selection={selection} />
            ) : (
              <ExportSingleAssetInfo selection={selection?.[0]} />
            )}
            <Divider gutter={true} />

            <Padding>
              <ExportOptionsForm
                onlyFields={['title', 'recipients']}
                selection={selection}
                // categories={categories}
                onFormChange={setFormState}
                defaults={defaults}
              />
            </Padding>
          </>
        ) : null}
      </Padding>
    </ModalMenuUI>
  );
};
