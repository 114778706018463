import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModalActions } from '../../Modals/modalActions';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Button } from '@cube3/cubicle/src/core/atoms/Button/Button';
import { CreateTags } from '@cube3/cubicle/src/core/molecules/TagManagement/CreateTagsList';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { TagCategory } from '@cube3/common/model/schema/resources/tag-category';
import { getErrorMessage } from '../../../../forms/helpers/errors';
import {
  CreateTagRowUI,
  initialUiTag
} from '@cube3/cubicle/src/core/molecules/TagManagement/types';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { normalizeTagsInput } from '../../Tags/prefabs/TagsInput';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';

interface Props {
  modalContext: {
    category: TagCategory;
  };
}

export const CreateTagsModal = (props: Props) => {
  const { category } = props.modalContext;
  const { previousModal } = useModalActions();
  const [workspaceId] = useCurrentWorkspace();

  const [tags, setTags] = useState({ ...initialUiTag });

  const [createTag, createTagStatus, createTagError] = useCreateResource__ALPHA(
    useMemo(
      () => ({
        ancestor: {
          type: 'workspace',
          id: workspaceId
        },
        resourceType: 'tag',
        cacheInvalidator: () => [
          { type: 'workspace', id: workspaceId, relationship: 'tags' }
        ]
      }),
      [workspaceId]
    )
  );

  const { resources: allTags } = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'tag',
    edgeLabel: 'tags',
    params: {
      filter: {
        category_id: category?.id
      }
    }
  });

  const onSave = useCallback(() => {
    if (category && Object.keys(tags).length) {
      Object.keys(tags).forEach((key) => {
        const newTag: CreateTagRowUI = tags[key];

        createTag({
          text: normalizeTagsInput(newTag.text),
          color: newTag.color === 'ghost' ? '' : newTag.color,
          type: 'tag',
          relationships: {
            category: {
              data: {
                type: 'tag-category',
                id: category.id
              }
            }
          }
        });
      });
    }
  }, [category, tags, workspaceId]);

  const validator = useCallback(
    (tag: CreateTagRowUI) => {
      if (!allTags?.length) return '';
      if (allTags.find((t) => t.text === tag.text)) {
        return 'This name is already in use.';
      }
    },
    [allTags]
  );

  useEffect(() => {
    if (createTagStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [createTagStatus, previousModal]);

  const error = createTagError && getErrorMessage(createTagError);

  const emptyTags =
    !Object.keys(tags).length ||
    Object.keys(tags).every((k) => !!tags[k].deleted);

  const formValid =
    !error && Object.keys(tags).every((k) => !tags[k].error) && !emptyTags;

  const loading = createTagStatus && !hasResolved(createTagStatus);

  useGlobalSubmit(onSave, !formValid || loading);
  return (
    <ModalMenuUI
      title={`Create tags for ${category?.display_name}`}
      onCloseEvent={previousModal}
      component={'form'}
      loading={loading}
      footerRightComponent={
        <>
          <Button
            label="Cancel"
            onClick={previousModal}
            background="secondary"
            buttonStyle="ghost"
          />
          <Button
            label="Save"
            disabled={!formValid}
            background="primary"
            buttonStyle="solid"
            onClick={onSave}
          />
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 48,
          gap: '8px'
        }}
      >
        {error && <Typography color="danger1">{error}</Typography>}
        <Typography variant="heading3">Tags</Typography>
        <CreateTags tags={tags} setTags={setTags} validator={validator} />
      </div>
    </ModalMenuUI>
  );
};
