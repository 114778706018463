import React from 'react';
import DatePicker from '../../../../forms/helpers/fields/DatePicker';
import { Field, WrappedFieldProps } from 'redux-form';
import SelectFieldComponent from '../../../../forms/helpers/fields/SelectFieldComponent';
import { MetadataFormFieldRow } from '@cube3/ui/src/metadata/MetadataFormField';
import { dateParser } from '@cube3/state/src/redux/components/Hooks/metadataHooks';
import { Typography } from '@cube3/ui/src/typography/Typography';

interface Props extends WrappedFieldProps {}

const options = ['Specific period', 'Never expires'];
const parser = (val) => val === 'Specific period';
const formatter = (val) => (val ? 'Specific period' : 'Never expires');

export const ContractDurationField: React.FunctionComponent<Props> = (
  props
) => {
  const { input, meta } = props;

  return (
    <>
      <Field
        name={`${input.name}.expires`}
        component={(props) => (
          <SelectFieldComponent
            {...props}
            data-cy="select-new-contract-period"
          />
        )}
        options={options}
        format={formatter}
        parse={parser}
      />

      {input.value?.expires && (
        <>
          <Field
            name={`${input.name}.starts_at`}
            component={(props) => {
              return (
                <MetadataFormFieldRow {...props} label="Starts at">
                  <DatePicker {...props} data-cy="date-picker-start-date" />
                </MetadataFormFieldRow>
              );
            }}
            parse={dateParser}
          />
          <Field
            name={`${input.name}.expires_at`}
            component={(props) => {
              return (
                <MetadataFormFieldRow {...props} label="Expires at">
                  <DatePicker
                    {...props}
                    data-cy="date-picker-expiring-date"
                    minDate={input.value?.starts_at || undefined}
                  />
                </MetadataFormFieldRow>
              );
            }}
            parse={dateParser}
          />
        </>
      )}
      {meta.error && (
        <Typography color="danger1">Please fill out all dates</Typography>
      )}
    </>
  );
};
