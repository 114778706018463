import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Divider } from '../Surface/Divider';
import { isMobile } from 'react-device-detect';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    root: {
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      height: '100%'
    },
    divider: {
      flexGrow: 0,
      flexShrink: 0,
      '$mobile &': {
        display: 'none'
      }
    },
    header: {
      height: '56px',
      width: '100%',
      flexGrow: 0,
      flexShrink: 0,
      '$mobile &': {
        height: 'auto'
      }
    },
    scopeBar: {
      height: '56px',
      width: 'calc(100% - 48px)',
      flexGrow: 0,
      flexShrink: 0,
      marginTop: '16px',
      marginLeft: '24px',
      marginRight: '24px',
      '$mobile &': {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        height: theme.sizing[40]
      }
    },
    contents: {
      height: 'calc( 100% - 137px)', // NOTE: this is a fix to prevent safari from setting the wrong height on the Scrollbars down in the tree
      width: 'calc(100% - 48px)',
      flexGrow: 1,
      flexShrink: 1,

      // all pages share the these margins for contents.
      marginTop: '8px',
      marginLeft: '24px',
      marginRight: '24px',
      marginBottom: '24px',
      '$mobile &': {
        marginLeft: 0,
        marginRight: 0,
        width: '100%',
        height: '100%'
      }
    },
    narrow: {
      maxWidth: '60rem',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  })
);

interface Properties {
  headerComponent: JSX.Element;
  contentComponent: JSX.Element;
  scopeBarComponent?: JSX.Element;
  classes?: ClassNameMap;
  narrowContents?: boolean;
}

/** Dictates base layout for every screen in Cube */
const PageLayout = (props: Properties) => {
  const {
    headerComponent,
    scopeBarComponent,
    contentComponent,
    narrowContents
  } = props;

  const classes = useStyles(props);

  return (
    <div className={`${classes.root} ${isMobile ? classes.mobile : ' '}`}>
      <div className={classes.header}>{headerComponent}</div>

      <Divider className={classes.divider} />

      {scopeBarComponent && (
        <div
          className={`${classes.scopeBar} ${
            narrowContents ? classes.narrow : ''
          }`}
        >
          {scopeBarComponent}
        </div>
      )}

      <div
        className={`${classes.contents} ${
          narrowContents ? classes.narrow : ''
        }`}
      >
        {contentComponent}
      </div>
    </div>
  );
};

export default PageLayout;
