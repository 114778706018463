//
export const convertSecondsToDateFormat = (seconds: number) => {
  const date = new Date(null);
  date.setSeconds(seconds);
  // if (seconds >= 3600) {
  //   time = date.toISOString().slice(11, 19);
  // } else {
  //   time = date.toISOString().slice(14, 19);
  // }
  const time = date.toISOString().slice(11, 19);
  return time;
};

const timeCache = new Map();

// cached version
// TODO: figure out if its worth the memory overhead
export const convertSecondsToDateFormatCached = (seconds: number) => {
  const cached = timeCache.get(seconds);
  if (cached) {
    return cached;
  } else {
    const time = convertSecondsToDateFormat(seconds);
    timeCache.set(seconds, time);
    return time;
  }
};

// preload cache
[...new Array(300)].forEach((_, idx) => {
  convertSecondsToDateFormat(idx);
});
