import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
//
import { reducer as ui } from './ducks/ui';
import modals from './ducks/modals';
// import { routerReducer } from './ducks/router';
import session from './ducks/session';
import selections from './ducks/selections';
import search from './ducks/search';
import resourceNodes from './ducks/resource-nodes';
import resourceEdges from './ducks/resource-edges';
import requestStatus from './ducks/request-status';
import uploads from './ducks/uploads';
import clipboard from './ducks/clipboard';
import tempIdLookup from './ducks/temp-id';
import offlineDetection from './ducks/offline-detection';
import cookieConsent from './ducks/cookie-consent/';

import { actions as sessionActions } from './ducks/session';
import { metadataClipboard } from './ducks/metadata-clipboard';
import reviewChanges from './ducks/review-changes';

const makeReducer = (config) => {
  return combineReducers({
    form: formReducer.plugin({}),
    // router: routerReducer,
    session,
    // library,
    ui,
    modals,
    selections,
    search,
    resourceNodes,
    resourceEdges,
    requestStatus,
    tempIdLookup,
    uploads,
    clipboard,
    metadataClipboard,
    offlineDetection,
    reviewChanges,
    ...cookieConsent(undefined, config.cookieConsentVersions)
  });
};

let appReducer;

export const rootReducer = (config) => (state, action) => {
  if (action.type === sessionActions.RESET) {
    state = undefined;
  }

  if (!appReducer) {
    appReducer = makeReducer(config);
  }

  return appReducer(state, action);
};
