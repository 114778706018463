import { makeSelector } from '@taskworld.com/rereselect';
import { SelectionsState } from './state';
import { getResourceEdgeStub } from '../resource-edges/selectors';

export const getSelection = makeSelector((query) => {
  const selectionItems = query((state) => {
    return (state.selections as SelectionsState).selection;
  });

  const nodes = selectionItems?.map((e) =>
    query((state) => getResourceEdgeStub(state, e))
  );
  // .filter(s => !s.__deleted__)
  // .map(resource =>
  //   query(state =>
  //     getResourceEdgeStub(
  //       state,
  //       resource.relationships[relationship].data
  //         ? resource.relationships[relationship].data
  //         : resource.relationships[relationship]
  //     )
  //   )
  // );
  return nodes;
});

export const getVisiting = makeSelector((query) => {
  const visiting = query((state) => state.selections.visiting);
  return query((state) => visiting && getResourceEdgeStub(state, visiting));
});

export const getActive = makeSelector((query) => {
  const active = query((state) => state.selections.active);
  return query((state) => active && getResourceEdgeStub(state, active));
});
// selectors
export const getActiveItemId = (selections) => {
  if (selections.active) {
    return selections.active.id;
  }
  if (selections.visiting) {
    return selections.visiting.id;
  }
};

// selectors
export const getActiveItemMri = (state) => {
  if (state.selections.active) {
    return state.selections.active;
  }
  if (state.selections.visiting) {
    return state.selections.visiting;
  }
};
// selectors
export const getVisitingItem = (selections) => {
  if (!selections.visiting) return null;
  return selections.visiting;
};

export const isInSelectionArray = (selectedItems, item) => {
  return !!selectedItems?.find((i) => i.id === item.id && i.type === item.type);
};

export const isItemSelected = (state, item) => {
  return state.selections.inverted
    ? !isInSelectionArray(state.selections.selection, item)
    : isInSelectionArray(state.selections.selection, item);
};

export const anySelected = (state) => {
  return state.selections.inverted
    ? true
    : state.selections.selection?.length > 0;
};

export const getInverted = (state) => {
  return state.selections.inverted;
};

export const selectors = {
  getSelection,
  getVisiting,
  getActive,
  getActiveItemId,
  getActiveItemMri,
  getVisitingItem,
  isInSelectionArray,
  isItemSelected,
  anySelected,
  getInverted
};
