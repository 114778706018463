import React from 'react';

// types and the HOCstuff
import { ModalReceiverProps } from './../../Modals/ModalManager';
import {
  ModalActionProps,
  useModalActions
} from '../../Modals/modalActions';

// UI
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import Button from '@cube3/ui/src/Buttons/Button';
import { Typography } from '@cube3/ui/src/typography/Typography';

/// @Author :
/// <Summary>
/// A notice displaying
/// </Summary>

interface PrivateProps extends ModalReceiverProps, ModalActionProps {}

const ConfirmEmailModal: React.FC<PrivateProps> = props => {
  const { modalContext: emailAddress } = props;
  const { closeAllModals } = useModalActions();

  return (
    <ModalNoticeUI
      title={'Confirm your email address'}
      onCloseEvent={() => closeAllModals()}
      footerRightComponent={
        <Button
          text={'Done'}
          colorVariant={'filled1'}
          onClick={() => closeAllModals()}
        />
      }
    >
      <Typography>
        We have sent you a confirmation link to <b> {emailAddress} </b>. Click
        the link to finish changing your email address.
      </Typography>
    </ModalNoticeUI>
  );
};

export default ConfirmEmailModal;
