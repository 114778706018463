import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useMemo } from 'react';
import { combinePresets } from './useRenderPresets';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { RenderPreset } from '@cube3/common/model/schema/resources/render-preset';

/** Get allowed download types for sharelink */
export const useShareDownloads = (shareId: string) => {
  const [workspaceId] = useCurrentWorkspace();

  const params = useMemo(
    () => ({
      filter: {
        share_id: shareId
      }
    }),
    [shareId]
  );

  const workspacePresets = useResourceList__ALPHA({
    resourceId: workspaceId,
    resourceType: 'workspace',
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params
  });
  const customPresets = useResourceList__ALPHA({
    edgeType: 'render-preset',
    edgeLabel: 'render-presets',
    params
  });
  const loading =
    !workspacePresets.status ||
    !customPresets.status ||
    (workspacePresets.status !== statuses.SUCCESS &&
      workspacePresets.status !== statuses.FAILED) ||
    (customPresets.status !== statuses.SUCCESS &&
      customPresets.status !== statuses.FAILED);

  return {
    sharedDownloadFormats: !shareId
      ? []
      : (combinePresets(
          workspacePresets.resources,
          customPresets.resources
        ) as RenderPreset[]),
    loading
  };
};
