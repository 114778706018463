import { AssetMimeTypes } from '../../types';
import { RawModel } from '../schema';

/** TVad: represents a predefined check that will be applied to the asset */
export interface Validator {
  type: 'validator';
  id: string;
  attributes: {
    display_name: string;
    mime_type: AssetMimeTypes;
  };
}

export const validator: RawModel = {
  type: 'validator',
  plural: 'validators',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string',
    mime_type: 'string'
  }
};
