import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { Upload } from '@cube3/common/model/schema/resources/file-upload';
import { ExtendedUpload } from './extendedupload';
import { ReduxUploadStorage } from './reduxUploadStorage';
import { KeyValStorage } from './storage';

export interface ResumableUpload {
  size: number | null;
  metadata: { [key: string]: any };
  creationTime: string;
  urlStorageKey: string;
  completionTime: string | null;
  active: boolean;
}

interface TusUrlStorage {
  findAllUploads(): Promise<Array<ResumableUpload>>;
  findUploadsByFingerprint(
    fingerprint: string
  ): Promise<Array<ResumableUpload>>;
  removeUpload(urlStorageKey: string): Promise<void>;
  // Returns the URL storage key, which can be used for removing the upload.
  addUpload(fingerprint: string, upload: ResumableUpload): Promise<string>;
}

const PREFIX = 'tus2';
const SECTION_MARKER = '**';

const defaultConfig = { onChange: () => {} }; // noop function
export class UrlStorage implements TusUrlStorage {
  private storageProxy: KeyValStorage = new ReduxUploadStorage();
  private config;
  public readyPromise;
  constructor(config = defaultConfig) {
    this.config = config;
    this.readyPromise = this.storageProxy.readyPromise;
  }

  findAllUploads(): Promise<Array<ResumableUpload>> {
    return new Promise((res) => {
      const entries = this.storageProxy.getAll();
      res(entries);
    });
  }
  findUploadsByFingerprint(
    fingerprint: string
  ): Promise<Array<ResumableUpload>> {
    const key = this.makeKey(fingerprint);

    return this.findAllUploads().then((entries) => {
      const filtered = entries.filter(
        (e) => !e.completionTime && e.urlStorageKey === key
      );

      return filtered;
    });
  }

  findUploadsByAssetId(assetId: string): Promise<Array<ResumableUpload>> {
    return this.findUploadsByTarget({ type: 'asset', id: assetId });
  }

  findUploadsByTarget(
    identifier: ResourceIdentifier
  ): Promise<Array<ResumableUpload>> {
    return this.findAllUploads().then((entries) => {
      return entries.filter(
        (e) =>
          !e.completionTime &&
          e.metadata.targetResourceId === identifier.id &&
          e.metadata.targetResourceType === identifier.type
      );
    });
  }

  findUploadsInAncestor(
    fingerprint: string,
    finished: boolean = false
  ): Promise<Array<ResumableUpload>> {
    return this.findAllUploads().then((entries) => {
      return entries.filter(
        (e) =>
          (finished ? true : !e.completionTime) &&
          this.partialMatch(
            e.urlStorageKey,
            this.makeKey(fingerprint),
            true,
            true,
            false,
            true
          )
      );
    });
  }

  removeUpload(urlStorageKey: string): Promise<void> {
    return new Promise((res) => {
      this.storageProxy.removeItem(urlStorageKey);
      res();
      this.config.onChange();
    });
  }

  removeTusUploads() {
    this.storageProxy.getState().forEach((us: Upload, idx, all) => {
      if (us.tusUpload) {
        us.tusUpload.triggerReduxUpdate({
          tusUpload: undefined,
          file: {}
        });
      }
    });
  }

  clearStorage() {
    this.removeTusUploads();
    const promise = this.findAllUploads().then((uploads) => {
      return Promise.all(
        uploads.map((u) => {
          return this.removeUpload(u.urlStorageKey);
        })
      );
    });
    promise.then(() => {
      this.config.onChange();
    });
    return promise;
  }

  cleanCompleted(retentionTime: number): Promise<void[]> {
    const minTimestamp = Date.now() - retentionTime;

    const promise = this.findAllUploads().then((uploads) => {
      return Promise.all(
        uploads
          .filter((u) => {
            const timestamp = u.completionTime || u.creationTime;
            return timestamp && new Date(timestamp).getTime() < minTimestamp;
          })
          .map((u) => {
            console.warn('Removing old upload from queue', u);
            return this.removeUpload(u.urlStorageKey);
          })
      );
    });

    // notify subscriber
    promise.then((res) => {
      this.config.onChange();
    });

    return promise;
  }

  resetActive(): Promise<void> {
    return this.findAllUploads().then((uploads) => {
      if (uploads.length) {
        for (let i = 0; i < uploads.length; i++) {
          const key = uploads[i].urlStorageKey;
          if (key) {
            const item = this.storageProxy.getItem(key);
            if (item) {
              item.active = true;
              this.storageProxy.setItem(key, item);
            }
          }
        }
      }
    });
  }

  // Returns the URL storage key, which can be used for removing the upload.
  addUpload(fingerprint: string, upload: ResumableUpload): Promise<string> {
    return new Promise((res) => {
      const key = this.makeKey(fingerprint);
      upload.urlStorageKey = key;
      this.storageProxy.setItem(key, upload);
      res(key);
      this.config.onChange();
    });
  }

  markCompleted(fingerprint: string) {
    this.findUploadsByFingerprint(fingerprint).then((uploads) => {
      if (uploads.length) {
        for (let i = 0; i < uploads.length; i++) {
          const key = this.makeKey(fingerprint);
          const item = this.storageProxy.getItem(key);

          item.completionTime = new Date().toUTCString();
          this.storageProxy.setItem(key, item);
        }
      }
      this.config.onChange();
    });
  }
  // active uploads have a matching Extendedupload instance
  markActive(fingerprint: string) {
    this.findUploadsByFingerprint(fingerprint).then((uploads) => {
      if (uploads.length) {
        for (let i = 0; i < uploads.length; i++) {
          const key = this.makeKey(fingerprint);
          const item = this.storageProxy.getItem(key);
          if (item) {
            item.active = true;
            this.storageProxy.setItem(key, item);
          }
        }
      }
      this.config.onChange();
    });
  }

  /** Unique identifier for resuming failed uploads */
  fingerprint = (fingerprintFile, options) => {
    return Promise.resolve(
      [
        [
          fingerprintFile.name,
          fingerprintFile.type,
          fingerprintFile.size,
          fingerprintFile.lastModified
        ].join('-'),

        [
          'resourceId',
          options.metadata.targetResourceId || 'NULL',
          'resourceType',
          options.metadata.targetResourceType || 'NULL'
        ].join('-'),
        [
          'ancestorId',
          options.metadata.ancestorResourceId || 'NULL',
          'ancestorType',
          options.metadata.ancestorResourceType || 'NULL'
        ].join('-'),
        ['resumeContext', options.metadata.resumeContext].join('-'),
        ['workspace', options.metadata.workspace].join('-'),
        // 'resourceId',
        // options.metadata.resumeContext === 'ancestor'
        //   ? options.metadata.ancestorResourceId
        //   : options.metadata.targetResourceId,
        // 'resourceType',
        // options.metadata.resumeContext === 'ancestor'
        //   ? options.metadata.ancestorResourceType
        //   : options.metadata.targetResourceType,
        [options.endpoint].join('-')
      ].join(SECTION_MARKER)
    );
  };

  /**
   * Match only parts of the urlStorage key, returns false if any of the required sections dont match
   * @param keyA urlStorage key to compare
   * @param keyB urlStorage key to compare with
   * @param sections rest args ...sections that should match [prefix, file, target resource, parent resource, resume context, tus upload endpoint]
   * @returns whether requested sections match
   */
  partialMatch(keyA, keyB, ...sections) {
    const splitA = keyA.split(SECTION_MARKER);
    const splitB = keyB.split(SECTION_MARKER);

    for (let i = 0; i < sections.length; i++) {
      if (sections[i]) {
        if (splitA[i] !== splitB[i]) {
          return false;
        }
      }
    }
    return true;
  }

  makeKey(fingerprint: string): string {
    return [PREFIX, fingerprint].join(SECTION_MARKER);
  }

  makeEntry(fingerprint: string, upload: ExtendedUpload): ResumableUpload {
    return {
      size: upload.file ? (upload.file as File).size : null,
      metadata: upload.options.metadata,
      creationTime: new Date().toUTCString(),
      urlStorageKey: this.makeKey(fingerprint),
      completionTime: null,
      active: false
    };
  }

  getEnhancer() {
    if (this.storageProxy.getEnhancer) {
      return this.storageProxy.getEnhancer();
    }
  }
}
