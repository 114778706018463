import { ResourceIdentifier } from '@cube3/common/model/resource-types';
import { normalizePromise } from './normalize-api-promise';
import { schema } from '@cube3/common/model/schema';

export interface ApiMapper {
  [key: string]: (...params: any) => any;
}

interface AuthErrorHandlers {
  noAuth(): void;
  noAccess(): void;
  noBeta(): void;
}

interface AuthMethods extends ApiMapper {
  handleAuthErrors?(config: AuthErrorHandlers): void;
}

interface ComposeMethods extends ApiMapper {
  request(config: { [key: string]: any }): Promise<any>;
  handler(method: string, params: { [key: string]: any }): Promise<any>;
}

export interface MappingConfig {
  COMPOSE?: ComposeMethods;
  AUTH?: AuthMethods;
  [key: string]: ApiMapper;
}

export class RequestMaker {
  private mapping: MappingConfig;
  public auth: AuthMethods;
  public compose: ComposeMethods;

  constructor(mappingConfig: MappingConfig) {
    const { AUTH, COMPOSE, ...mapping } = mappingConfig;

    this.mapping = mapping;
    // NOTE: we create a new object cause otherwise the overrides for login, registerAccount
    // become self referential, leading to infinite loops
    this.auth = { ...AUTH };
    this.compose = COMPOSE;
    this.auth.login = (...args) => normalizePromise(AUTH.login(...args));
    this.auth.registerAccount = (...args) =>
      normalizePromise(AUTH.registerAccount(...args));
  }

  get(resource: string, id: string | {}, abortSignal = undefined) {
    return normalizePromise(this.getMapping(resource).get?.(id, abortSignal));
  }

  list(
    resource: string,
    by: { id?: string; type?: string; field?: string } = {},
    params = undefined,
    abortSignal = undefined
  ) {
    const byNormalized = {
      id: by.id,
      field:
        by.field ||
        this.getMapping(resource).canonicalPath ||
        this.pluralize(resource),
      type:
        by.type &&
        (this.getMapping(by.type).canonicalPath || this.pluralize(by.type))
    };

    const respPromise = normalizePromise(
      this.getMapping(resource)
        .list?.(byNormalized, params, abortSignal)
        .then((res) => {
          return res;
        })
    );

    return respPromise;
  }

  many(resource: string, ids: string[], abortSignal = undefined) {
    return normalizePromise(
      Promise.all(
        ids.map((id) => this.getMapping(resource).get?.(id, abortSignal))
      ).then((items) => ({ results: items }))
    );
  }

  post(
    resource: string,
    body: {},
    by: { id?: string; type?: string; field?: string } = {}
  ) {
    const byNormalized = {
      id: by.id,
      field:
        by.field ||
        this.getMapping(resource).canonicalPath ||
        this.pluralize(resource),
      type:
        by.type &&
        (this.getMapping(by.type).canonicalPath || this.pluralize(by.type))
    };

    return normalizePromise(
      this.getMapping(resource).post?.(body, byNormalized)
    );
  }

  // eslint-disable-next-line
  put(resource: string, { id, ...body }: { id: string; [key: string]: any }) {
    // console.info('called post', resource, body);
    return normalizePromise(this.getMapping(resource).put?.(id, body));
  }
  // eslint-disable-next-line
  patch(resource: string, { id, ...body }: { id: string; [key: string]: any }) {
    // console.info('called post', resource, body);
    return normalizePromise(this.getMapping(resource).patch?.(id, body));
  }

  update(
    resource: string,
    { id, ...body }: { id: string; [key: string]: any }
  ) {
    const realMapping = this.mapping[resource];
    if (realMapping.patch) {
      return normalizePromise(
        this.getMapping(resource).patch?.(id, { id, ...body })
      );
    } else {
      return normalizePromise(
        this.getMapping(resource).put?.(id, { id, ...body })
      );
    }
  }

  delete(resource: string, id: string) {
    // console.info('called get', resource, id);
    return normalizePromise(this.getMapping(resource).delete?.(id));
  }

  move(
    resource: string,
    by: { id?: string; type?: string; field?: string } = {},
    resourceIds: ResourceIdentifier[]
  ) {
    const byNormalized = {
      id: by.id,
      field: by.field || this.pluralize(resource),
      type: by.type && this.pluralize(by.type)
    };
    return normalizePromise(
      this.getMapping(resource).move?.(resourceIds, byNormalized)
    );
  }

  copy(
    resource: string,
    by: { id?: string; type?: string; field?: string } = {},
    resourceIds: ResourceIdentifier[]
  ) {
    const byNormalized = {
      id: by.id,
      field: by.field || this.pluralize(resource),
      type: by.type && this.pluralize(by.type)
    };
    return normalizePromise(
      this.getMapping(resource).copy?.(resourceIds, byNormalized)
    );
  }

  addRelated(
    resource: string,
    by: { id?: string; type?: string; field?: string } = {},
    edgeConfig: ResourceIdentifier | ResourceIdentifier[],
    forceJSONApi
  ) {
    const byNormalized = {
      id: by.id,
      field: by.field || this.pluralize([].concat(edgeConfig)[0].type),
      type:
        by.type &&
        (this.getMapping(by.type).canonicalPath || this.pluralize(by.type))
    };
    return normalizePromise(
      this.getMapping(resource).addRelated?.(
        edgeConfig,
        byNormalized,
        forceJSONApi
      )
    );
  }

  removeRelated(
    resource: string,
    by: { id?: string; type?: string; field?: string } = {},
    edgeConfig: ResourceIdentifier | ResourceIdentifier[],
    forceJSONApi
  ) {
    const byNormalized = {
      id: by.id,
      field: by.field || this.pluralize([].concat(edgeConfig)[0].type),
      type:
        by.type &&
        (this.getMapping(by.type).canonicalPath || this.pluralize(by.type))
    };

    return normalizePromise(
      this.getMapping(resource).removeRelated?.(
        edgeConfig,
        byNormalized,
        forceJSONApi
      )
    );
  }

  replaceRelated(
    edgeType: string,
    by: { id?: string; type?: string; field?: string } = {},
    edgeConfig: ResourceIdentifier | ResourceIdentifier[]
  ) {
    const byNormalized = {
      id: by.id,
      field: by.field,
      type:
        by.type &&
        (this.getMapping(by.type).canonicalPath || this.pluralize(by.type))
    };
    const endpointOverride = `/${byNormalized.type}/${byNormalized.id}/relationships/${byNormalized.field}`;

    return normalizePromise(
      this.getMapping(edgeType).replaceRelated?.(
        edgeType,
        edgeConfig,
        endpointOverride
      )
    );
  }

  getMapping(resourceType: string) {
    const filledMapping = {
      plural: schema.getPlural(resourceType),
      canonicalPath: schema.getCanonicalPath(resourceType),
      ...this.mapping[resourceType]
    } as {
      plural: string;
      canonicalPath: string;
    } & ApiMapper;
    return filledMapping;
  }

  pluralize(resourceType: string) {
    return this.getMapping(resourceType).plural;
  }
}
