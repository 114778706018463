import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Oauth2 extends ResourceBase {
  service?: 'Google';
}

export const oauth2: RawModel = {
  type: 'oauth2',
  plural: 'oauth2',
  JSONApi: true,
  attributes: {
    id: 'UUID'
  }
};
