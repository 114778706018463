import React from 'react';
import { connect } from 'react-redux';
import { compose } from '../../../../../utils/component-helpers';
import { bindActionCreators } from 'redux';
import { withResource } from '@cube3/state/src/redux/components/withResource';
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';

// forms
import {
  reduxForm,
  getFormValues,
  InjectedFormProps,
  clearFields,
  arrayRemoveAll,
  Field
} from 'redux-form';

// UI
import withCurrentWorkspace from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { withModalActions } from '../../Modals/modalActions';
import AddProjectMembersModalUI from '@cube3/ui/src/Projects/Modals/AddProjectMembersModalUI';
import withCurrentUserAccount from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { PROJECT_ROLE_TYPES } from '@cube3/common/model/resource-types';
import {
  Suggestion,
  ClearInputHandler
} from '@cube3/ui/src/forms/textfields/AutoCompleteInput';
import { WrappedSelect } from './AddProjectModal';
import { isRequired } from '../../../../forms/helpers/validators';
import { useAccountsUser } from '../../AdminCenter/views/WorkspaceTeamsView/hooks/useAccountsUser';
import {
  ProjectAddAccountsField,
  ProjectAddMembersField
} from '../ProjectAddMembersField';
import { withProjectTeamMemberships } from '../HOCs/withProjectTeamMemberships';
import { withCreateProjectMember } from '../HOCs/withCreateProjectMember';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { Project } from '@cube3/common/model/schema/resources/project';
import { Account } from '@cube3/common/model/schema/resources/account';
import { ProjectMember } from '@cube3/common/model/schema/resources/project-member';

interface ProjectMemberPickerProps extends InjectedFormProps {
  match?: { params: { workspaceId: string } };
  onChange?: Function;
  retrievedProject: Project;
  accountSuggestions: Suggestion[];
  account: Suggestion;
  clearEmailInput: ClearInputHandler;
  onSubmit: Function;
  handleSubmit: () => void;
  submitSucceeded: boolean;
  dispatch?: Function;
  currentProjectMembers: Account[];
  currentProjectMembersMutate: Function;
  members: ProjectMember[];
  canEditTeam?: boolean;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearEmailInput: clearFields
    },
    dispatch
  );
};

const mapStateToProps = (state, ownProps: ProjectMemberPickerProps) => {
  const values = getFormValues('ProjectMemberPicker')(state);
  return {
    form: ownProps.form,
    formValues: values
  };
};

const memberTypeOptions = [
  { display_name: 'Admin', id: PROJECT_ROLE_TYPES.Admin },
  { display_name: 'Member', id: PROJECT_ROLE_TYPES.Member }
];
const emptyArray = [];
const emptyObject = {};
function ProjectMemberPicker(props: any) {
  const {
    clearEmailInput,
    form,
    suggestedAccounts = emptyArray,
    previousModal,
    handleSubmit,
    submitting,
    submitSucceeded,
    dispatch,
    valid,
    members,
    formValues,
    loading,
    anyTouched,
    retrievedProject,
    addTeamAsMemberStatus,
    modalContext: { hasTeamFeature }
  } = props;

  const { accounts, accountSearchInput } = formValues || emptyObject;

  const { resources: suggestedAccountsWithNames, loading: retrievingUsers } =
    useAccountsUser(suggestedAccounts);

  const [excludedAccounts /*filteredAccounts*/] = React.useMemo(() => {
    if (!suggestedAccountsWithNames || !members) {
      return [[], []];
    }

    const alreadyMemberAccounts = members.map(
      (m) => m.relationships.account.id
    );

    const include = suggestedAccountsWithNames.map(
      (acc) => alreadyMemberAccounts.indexOf(acc.id) < 0
    );
    return [
      suggestedAccountsWithNames.filter((val, idx) => !include[idx]),
      suggestedAccountsWithNames.filter((val, idx) => include[idx])
    ];
  }, [suggestedAccountsWithNames, members]);

  const isSubmitDisabled = (): boolean => {
    return accountSearchInput && !valid
      ? true
      : accountSearchInput !== undefined
      ? true
      : accounts && accounts.length > 0
      ? false
      : true;
  };

  const closeModalHandler = React.useCallback(() => {
    previousModal();
    dispatch(arrayRemoveAll('ProjectMemberPicker', 'accounts'));
    dispatch(
      clearFields('ProjectMemberPicker', false, false, 'accountSearchInput')
    );
  }, [dispatch, previousModal]);

  if (submitSucceeded || addTeamAsMemberStatus === statuses.SUCCESS) {
    closeModalHandler();
    return null;
  }

  return (
    <AddProjectMembersModalUI
      accountBoxRenderer={
        <>
          {hasTeamFeature ? (
            <ProjectAddMembersField
              projectId={retrievedProject?.id}
              suggestedAccounts={suggestedAccountsWithNames}
              form={form}
              searchInput={accountSearchInput}
              clearInput={clearEmailInput}
              excludedSuggestion={excludedAccounts}
              suggestionsLoading={loading || retrievingUsers}
              autoFocus={true}
            />
          ) : (
            <ProjectAddAccountsField
              form={form}
              allSuggestion={suggestedAccountsWithNames}
              searchInput={accountSearchInput}
              clearInput={clearEmailInput}
              excludedSuggestion={excludedAccounts}
              autoFocus={true}
            />
          )}
          <Field
            name="role"
            component={WrappedSelect}
            props={{
              label: 'Role',
              options: memberTypeOptions,
              showErrors: anyTouched ? true : 'touched'
            }}
            validate={isRequired}
          />
        </>
      }
      onSubmit={(ev) => {
        return handleSubmit(ev);
      }}
      submitButtonDisabled={submitting || isSubmitDisabled()}
      onCloseEvent={() => closeModalHandler()}
      loading={loading || submitting || !suggestedAccountsWithNames}
    />
  );
}

export default compose(ProjectMemberPicker)(
  withCurrentUserAccount,
  // get the project to be able to mutate it
  withResource<{ modalContext: { projectId: string } }>({
    resourceType: 'project',
    resourceId: ({ modalContext }) => modalContext?.projectId,
    mapper: 'retrievedProject'
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withModalActions,
  withCurrentWorkspace,
  withResourceEdges({
    resourceType: 'project',
    resourceId: ({ retrievedProject }) => {
      return retrievedProject && retrievedProject.id;
    },
    edgeType: 'project-member',
    edgeLabel: 'members',
    mapper: 'members'
  }),
  withCreateProjectMember,
  withProjectTeamMemberships,
  reduxForm({
    form: 'ProjectMemberPicker',
    initialValues: {
      role: memberTypeOptions[1]
    },
    destroyOnUnmount: true,
    validate: (values: any, props: any) => {
      return {};
    },
    onSubmit: (values: any, dispatch, props: any) => {
      const accounts = values.accounts.filter((res) => res.type === 'account');
      const teams = values.accounts.filter((res) => res.type === 'team');

      props.addMembers(
        accounts.map((acc) => ({
          type: 'project-member',
          project_member_role: values.role.id,
          relationships: {
            account: {
              data: { id: acc.id, type: 'account' }
            }
          }
        }))
      );

      if (props.modalContext?.hasTeamFeature && teams.length > 0) {
        props.addTeamAsMember(
          teams.map((team) => ({
            type: 'project-team-membership',
            project_member_role: values.role.id,
            relationships: {
              project: {
                data: { type: 'project', id: props.retrievedProject.id }
              },
              team: {
                data: { id: team.id, type: 'team' }
              }
            }
          }))
        );
      }

      return new Promise((res) => null);
    }
  }),
  withResourceEdges({
    edgeType: 'account',
    resourceType: 'workspace',
    resourceId: (props: any) => props.currentWorkspaceId,
    edgeLabel: 'accounts',
    params: ({ formValues }) => {
      return {
        filter: {
          search:
            formValues && formValues.accountSearchInput
              ? formValues.accountSearchInput.trim()
              : ''
        }
      };
    },
    mapper: 'suggestedAccounts'
  })
);
