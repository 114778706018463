import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import InteractableSurface from '../../Surface/InteractableSurface';
import Cube3Button from '../../Buttons/Cube3Button';
import { iconAdd } from '../../icons/GeneralSVG';
import { Typography } from '../../typography/Typography';
import SupportEmailComponent from '../../Email/SupportEmailComponent';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    addMemberButton: {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset !important'
      }
    },
    newContainer: {
      display: 'flex',
      alignItems: 'center',
      height: theme.sizing[56],
      margin: '16px 0'
    },
    addMemberIcon: {
      margin: '0px 20px 0px 8px'
    },
    noAdmin: {
      paddingLeft: 16
    }
  })
);

interface Props {
  canEdit: boolean;
  disabledReason?: string;
  noAdmins?: boolean;
  styles?: {
    root?: string;
    addMemberButton?: string;
    addMemberIcon?: string;
  };
  loading?: boolean;
  onClick(): void;
}

export const AddMemberComponent = (props: Props) => {
  const {
    canEdit,
    onClick,
    disabledReason,
    styles,
    noAdmins = false,
    loading
  } = props;
  const classes = useStyles();

  if (loading) {
    return <Shimmer height={56} width={'100%'} />;
  }

  return (
    <InteractableSurface
      highlightOnHover={canEdit}
      onClickEvent={canEdit && onClick}
      surfaceProps={{ corners: 'rounded' }}
      classes={{ root: `${classes.newContainer} ${styles?.root}` }}
    >
      {canEdit && (
        <Cube3Button
          text={`Add Member`}
          pathLeftSvg={iconAdd}
          colorVariant="ghost1"
          disableHover={true}
          disabled={!canEdit}
          className={`${classes.addMemberButton} ${styles?.addMemberButton}`}
          extraSVGProps={{
            className: `${classes.addMemberIcon} ${styles?.addMemberIcon}`
          }}
        />
      )}
      {!noAdmins && !canEdit && (
        <Typography
          color="contrast3"
          typographyStyle="body2"
          className={classes.noAdmin}
        >
          {disabledReason || 'Only Project Admins can add members.'}
        </Typography>
      )}
      {noAdmins && !canEdit && (
        <Typography
          color="warning"
          typographyStyle="body2"
          className={classes.noAdmin}
        >
          This Project has no Admins left. Contact <SupportEmailComponent /> to
          rectify.
        </Typography>
      )}
    </InteractableSurface>
  );
};
