import React, { useCallback, useEffect } from 'react';

import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';
import { changeProjectAccessActions } from './Modals/ChangeProjectAccessModal';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useSelectionActions } from '@cube3/state/src/redux/components/useSelection';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { projectStatusTypes } from './config/filters';

interface Props {
  action: 'archive' | 'unarchive';
  projects: Array<any>;
  multiple?: boolean;
}

const emptyArray = [];
/**  returns a callback for
 * - archive/unarchive multiple/single projects
 * - change projects access if should
 */
export const useArchiveProjects = ({
  action,
  projects = emptyArray,
  multiple = false
}: Props) => {
  const { openModal } = useModalActions();
  const [workspaceId, workspace] = useCurrentWorkspace();
  const [mutateResource, mutateStatus] = useMutateResource__ALPHA({
    cacheInvalidator: useCallback(
      () => [
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'projects'
        }
      ],
      [workspaceId]
    )
  });

  const { toggleSelect, clearActiveItem } = useSelectionActions();

  const sorted = React.useMemo(() => {
    const privateProjects = projects
      .filter((p) => !p.is_public)
      .map((p) => p.id);
    const publicProjects = projects
      .filter((p) => !!p.is_public)
      .map((p) => p.id);
    return {
      privateProjects,
      publicProjects
    };
  }, [projects]);

  const handler = React.useCallback(() => {
    if (projects.length === 0) return;
    // 1. archive/unarchive projects
    projects.forEach((p) => {
      mutateResource({
        id: p.id,
        type: 'project',
        project_status:
          action === 'archive'
            ? projectStatusTypes.ARCHIVED
            : projectStatusTypes.ACTIVE
      });
    });
    // 2. archive: if there are any private projects, suggest to set them to public
    if (action === 'archive' && sorted.privateProjects.length > 0) {
      openModal('projects_change_access', {
        action: changeProjectAccessActions.SET_PUBLIC,
        projectIds: sorted.privateProjects,
        workspaceName: workspace?.display_name,
        multiple
      });
    } else if (action === 'unarchive' && sorted.publicProjects.length > 0) {
      // 3. unarchive: if there are any public projects, suggest to set them to private
      openModal('projects_change_access', {
        action: changeProjectAccessActions.SET_PRIVATE,
        projectIds: sorted.publicProjects,
        workspaceName: workspace?.display_name,
        multiple
      });
    }
  }, [
    action,
    multiple,
    mutateResource,
    openModal,
    projects,
    sorted.privateProjects,
    sorted.publicProjects,
    workspace
  ]);

  useEffect(() => {
    if (multiple && mutateStatus === statuses.IN_FLIGHT) {
      // clear selection
      projects.forEach((p: any) => {
        toggleSelect(p);
      });
      clearActiveItem();
    }
  }, [toggleSelect, multiple, mutateStatus, projects, clearActiveItem]);

  return handler;
};
