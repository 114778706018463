import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';

import { useCallback, useMemo, useRef } from 'react';
import { BroadcastModalContextProps } from '../modals/TVadExportQualityCheckModal';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';

type Config = {
  recipients?: string[];
};

export const generateBroadcastFormId = (assetId: string) => {
  return `tv_broadcast_data_${assetId}`;
};

export const useBroadcastActions = ({
  assets,
  exportTargets
}: Pick<BroadcastModalContextProps, 'assets' | 'exportTargets'>) => {
  const allFormValues = useTypedSelector((state) => {
    const form = state.form;
    const val = Object.keys(form).map((id) => {
      if (id.match('tv_broadcast_data_')) {
        const assetId = id.split('_').reverse()[0];
        const values = form[id].values;
        if (values) {
          return {
            parameters: {
              title: values.title,
              client: values.client,
              product_service: values.product_service,
              length: values.length,
              version: values.version,
              export_filename: values.export_filename
            },
            asset_id: assetId
          };
        }
        return undefined;
      }
      return undefined;
    });
    return val.filter(Boolean);
  });

  const [workspaceId] = useCurrentWorkspace();
  const [createBatchResource, createBatchStatus] = useCreateResource__ALPHA(
    useRef({
      resourceType: 'export-batch' as const,
      ancestor: { type: 'workspace' as const, id: workspaceId },
      relationship: 'export-batches',
      cacheInvalidator: (r) => [r],
      actionDecorators: [
        ...assets.map((a) =>
          addFormId(generateBroadcastFormId(a.id), {
            formState: undefined,
            useRequestStatus: true,
            delegate: true
          })
        )
      ]
    }).current
  );

  const exportsData = useMemo(() => {
    const data = [];
    allFormValues.forEach((val) => {
      const config = exportTargets.map((t) => ({
        ...val,
        target_id: t.id
      }));
      data.push(...config);
    });
    return data as typeof allFormValues;
  }, [allFormValues, exportTargets]);

  const createBatch = useCallback(
    ({ recipients }: Config) => {
      createBatchResource({
        type: 'export-batch',
        exports: exportsData,
        notification_recipients: recipients.length ? recipients : undefined
      });
    },
    [createBatchResource, exportsData]
  );

  return { createBatch, createBatchStatus };
};
