import { ResourceIdentifier } from '../../resource-types';
import { RawModel } from '../schema';

export type Oauth2Provider = ResourceIdentifier & {};

export const oauth2Provider: RawModel = {
  type: 'oauth2-provider',
  plural: 'oauth2-providers',
  JSONApi: true,
  canonicalPath: 'oauth2/providers',
  attributes: {
    id: 'UUID'
  },
  relationships: {}
};
