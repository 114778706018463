import React from 'react';

import {
  makeValidator,
  maxCharacters
} from '../../../../../../forms/helpers/validators';
import { EditSettingModal } from './EditSettingsModal';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useMutateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useMutateResource';

import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { useCallback } from 'react';
import { useModalActions } from '../../../../../../app/layout/Modals/modalActions';
import { useEffect } from 'react';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';

interface Props {}

const maxChars = (value: string) => maxCharacters(value, 128);
const validator = makeValidator([maxChars]);
const formId = 'edit_workspace_name';

export const EditWorkspaceNameModal: React.FC<Props> = () => {
  const workspace = useCurrentWorkspace()[1];

  const [mutate, mutateStatus] = useMutateResource__ALPHA({
    actionDecorators: [addFormId(formId, { delegate: true })],
    cacheInvalidator: null
  });
  const { previousModal } = useModalActions();

  const handleSave = useCallback(
    data => {
      mutate({
        type: 'workspace',
        id: workspace.id,
        display_name: data.workspace_name
      });
    },
    [mutate, workspace]
  );

  useEffect(() => {
    if (mutateStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [mutateStatus, previousModal]);

  return (
    <EditSettingModal
      onSave={handleSave}
      loading={!workspace || mutateStatus === statuses.IN_FLIGHT}
      form={formId}
      initialValues={{ workspace_name: workspace.display_name }}
      config={{
        fields: [
          {
            type: 'text',
            name: 'workspace_name',
            value_type: 'string',
            validate: validator,
            label: 'Workspace name'
          }
        ]
      }}
      title={'Edit workspace name'}
    />
  );
};
