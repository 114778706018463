import { Tooltip, TooltipProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

type PlacementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
export interface TooltipReceivedProps {
  /* If 'true' the tooltip is not rendered */
  disabled?: boolean;
  title?: string | React.ReactNode;
  className?: string | {};
  placement?: PlacementType;
}
type InternalProps = TooltipReceivedProps & TooltipProps;
export interface MaybeTooltipProps {
  disabled?: boolean;
  disabledReason?: string;
  disabledReasonPlacement?: PlacementType;
}

const MaybeTooltip: FunctionComponent<InternalProps> = (props) => {
  const { disabled, title, placement, children, className } = props;

  if (disabled || !title) {
    return <span className={className}>{children}</span>;
  }

  return (
    <Tooltip className={className} title={title} placement={placement}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default MaybeTooltip;
