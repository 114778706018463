export const isEmptyValue = (val) => {
  switch (true) {
    case typeof val === 'object':
      return Object.keys(val).length === 0;
    case Array.isArray(val):
      return val.length === 0;
    default:
      return !val && val !== 0;
  }
};
