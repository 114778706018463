import { useEffect } from 'react';

/** Timer function that calls a callback that only triggers when the component is still mounted.
Usage:
```
useTimer(500, () => console.warn('hoi'));
```
*/
export function useTimer(timeOutMs: number, callback: () => void) {
  // use an effect that only triggerd when the component has not been unmounted.
  useEffect(() => {
    var wasUnmounted: boolean = false;

    const timeout = setTimeout(() => {
      if (!wasUnmounted) {
        callback();
      }
    }, timeOutMs);

    return () => {
      clearTimeout(timeout);
      wasUnmounted = true;
    };
  }, [timeOutMs, callback]);
}
