import React from 'react';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { useModalActions } from '../../Modals/modalActions';
import { ResourceIdentifier } from '@cube3/common/model/resource-types';

import { useCallback } from 'react';

import Button from '@cube3/ui/src/Buttons/Button';
import { useTagsManager } from '../hooks/useTagsManager';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { TagsInput } from '../prefabs/TagsInput';
import { useEffect } from 'react';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useWorkspacePermissions } from '@cube3/state/src/redux/components/Hooks/usePermission';
import { useFeatures } from '@cube3/state/src/redux/components/Hooks/useFeatures';
import { Privileges } from '@cube3/state/src/redux/components/Hooks/privileges';
import { PartialOnlyTagList } from '../prefabs/PartialOnlyTagList';

interface Props {
  modalContext: { targetResources: ResourceIdentifier[] };
}

const requiredPermissions = ['WORKSPACE_WRITE'] as Privileges[];

export const ManageTagsModal: React.FunctionComponent<Props> = (props) => {
  const { modalContext } = props;
  const { previousModal, openModal, closeAllModals } = useModalActions();
  const { targetResources } = modalContext;

  const [canEditWorkspace] = useWorkspacePermissions(requiredPermissions);
  const [modifyFeature] = useFeatures({ features: ['/WORKSPACE/TAGS/MODIFY'] });

  const {
    anyChanges,
    anyLoading,
    anyProcessing,
    handleSave,
    handleInputChange,
    handleInputSubmit,
    query,
    toAdd,
    toCreate,
    suggestions,
    saveSucceeded,
    saveFailed,
    inputTags,
    editablePartialTags
  } = useTagsManager({
    targetResources,
    canModify: modifyFeature && canEditWorkspace
  });

  const handleCloseEvent = useCallback(() => {
    if (anyChanges) {
      openModal('discard_tags_changes_prompt', closeAllModals, false);
    } else {
      previousModal();
    }
  }, [previousModal, openModal, closeAllModals, anyChanges]);

  useEffect(() => {
    if (saveSucceeded) {
      previousModal();
    }
  }, [saveSucceeded, previousModal]);

  if (saveFailed) {
    return (
      <ModalNoticeUI
        title="Error while processing"
        footerRightComponent={
          <>
            <Button
              text="Close"
              colorVariant="filledDanger"
              onClick={previousModal}
            />
          </>
        }
      >
        <Typography color="danger1">
          Something went wrong while processing your changes.
        </Typography>
        <Typography color="danger1">Please try again.</Typography>
      </ModalNoticeUI>
    );
  }

  const anyTags =
    !!toAdd?.length ||
    !!toCreate?.length ||
    !!inputTags?.length ||
    !!editablePartialTags?.length;

  return (
    <ModalMenuUI
      title={`Edit tags${
        targetResources.length > 1 ? ` for ${targetResources.length} items` : ''
      }`}
      onCloseEvent={handleCloseEvent}
      showCloseButton={true}
      loading={anyLoading || anyProcessing || saveSucceeded}
      footerRightComponent={
        <>
          {anyChanges ? (
            <Button
              text="Cancel"
              colorVariant="ghost2"
              onClick={handleCloseEvent}
              disabled={anyLoading || anyProcessing || saveSucceeded}
            />
          ) : null}
          <Button
            text={anyChanges ? 'Save' : 'Done'}
            colorVariant="filled1"
            onClick={anyChanges ? handleSave : handleCloseEvent}
            disabled={anyLoading || anyProcessing || saveSucceeded}
          />
        </>
      }
    >
      {/* <pre>{JSON.stringify(tags, null, 2)}</pre> */}
      <div style={{ height: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            width: '100%',
            alignItems: 'flex-start',
            paddingBottom: 32
          }}
        >
          <Typography
            typographyStyle="heading2"
            color="contrast1"
            typographyProps={{ align: 'center' }}
          >
            Tags applied to all items
          </Typography>

          <div style={{ width: '100%' }}>
            <TagsInput
              onChange={handleInputSubmit} // handle click tag(suggestionItem)
              onInputChange={handleInputChange} // used for creating new tag: handle change color/category
              suggestions={suggestions}
              query={query}
              inputTags={inputTags}
            />
          </div>

          <PartialOnlyTagList tags={editablePartialTags} />

          {!anyTags && (
            <div style={{ alignSelf: 'center' }}>
              <Typography
                typographyStyle="body1"
                color="contrast2"
                typographyProps={{ align: 'center' }}
              >
                No tags applied
              </Typography>
            </div>
          )}
        </div>
      </div>
    </ModalMenuUI>
  );
};
