import React, { useContext, createContext, useState } from 'react';
import { Contract } from '@cube3/common/model/schema/resources/contract';

type ContractSelectionContext = [
  /** currently selected contract */
  Contract | undefined,
  /** callback to set currently selected contract, or unset */
  (contract?: Contract) => void
];

/**
 * Contract selection context for use in master-detail like view
 * provides a basic useState return via context api
 */
export const contractSelectionContext = createContext<ContractSelectionContext>(
  [undefined, (contract = undefined) => {}]
);

/**
 * Provide contract selection context
 */
export const ContractSelectionProvider = ({ children }) => {
  const selectionState = useState<Contract>();

  return (
    <contractSelectionContext.Provider
      value={selectionState}
      children={children}
    />
  );
};

export const ContractSelectionConsumer = contractSelectionContext.Consumer;

/**
 * Access contract selection context via hook
 * @returns [current selection, callback([newSelection])]
 */
export const useContractSelection = (): ContractSelectionContext => {
  return useContext(contractSelectionContext);
};
