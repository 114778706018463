import { Tag } from '@cube3/common/model/schema/resources/tag';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList/useResourceList';
import { useCallback, useMemo } from 'react';

/** Group tags by category
 *
 * @param tags: `Tag[]`
 * @returns:
 *- groupedTags: `{ [key: categoryId | 'General' ]: Tag[] }`
 *- getCategoryNameById: `(id: string) => string`
 */
export const useTagCategories = (tags?: Tag[]) => {
  const [workspaceId] = useCurrentWorkspace();

  const categories = useResourceList__ALPHA(
    useMemo(
      () => ({
        edgeType: 'tag-category',
        edgeLabel: 'tag-categories',
        params: {
          filter: {
            workspace: workspaceId
          },
          page: {
            size: -1
          }
        }
      }),
      [workspaceId]
    )
  ).resources;

  const generalCategory = useMemo(() => {
    return categories?.find((c) => !!c.is_general);
  }, [categories]);

  const getCategory = useCallback(
    (id: string) => {
      return categories?.find((c) => c.id === id);
    },
    [categories]
  );

  const getCategoryByName = useCallback(
    (name: string) => {
      return categories?.find((c) => c.display_name === name);
    },
    [categories]
  );

  /** { [key: categoryId | 'General' ]: Tag[] } */
  const groupedTags = useMemo(() => {
    if (!tags?.length) return {};
    const grouped = tags.reduce((acc, tag) => {
      const cat = categories?.find(
        (c) => c.id === tag.relationships?.category?.id
      );
      /** split out the general tags to list them seperately */
      const isGeneral = cat?.is_general;
      const key = isGeneral ? cat?.display_name : cat?.id;
      if (!acc[key]) {
        acc[key] = [tag];
      } else {
        acc[key].push(tag);
      }
      return acc;
    }, {});

    return grouped;
  }, [tags, categories]);

  /** insert tag-category resource to every tag */
  const getTagsWithCategory = useCallback(
    (tags: Tag[]) => {
      if (!tags?.length) return [];
      return tags.map((t) => {
        const cat = getCategory(t.relationships.category?.id);
        return {
          ...t,
          category: cat
        };
      });
    },
    [getCategory]
  );

  return {
    groupedTags,
    generalCategory,
    getTagsWithCategory,
    getCategory,
    getCategoryByName,
    categories
  };
};
