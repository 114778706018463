import React from 'react';

// UI
import SelectComponent from '@cube3/ui/src/select/SelectComponent';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { GenericFieldWithErrorsProps } from './GenericFieldWithErrors';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { SelectProps as SelectPropsInterface } from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      minHeight: '20px',
      minWidth: '50px',
      color: theme.customPalette.primary.textWhiteMediumEmphasis,
      borderColor: theme.customPalette.primary.textWhiteMediumEmphasis
    },
    menugroup: {
      minWidth: '225px',
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    prefix: { paddingRight: '8px' },
    suffix: {
      padding: '0 4px',
      color: theme.customPalette.text.onBase.contrast1,
      backgroundColor: theme.customPalette.backgroundAccent.whiteAccent2,
      borderRadius: '4px',
      lineHeight: '16px',
      margin: '0 8px',
      fontFamily: 'Inter,sans-serif',
      fontSize: '12px',
      letterSpacing: '0.01em',
      fontWeight: 600
    }
  })
);

interface SelectFieldComponentProps extends GenericFieldWithErrorsProps {
  options: string[];
  selectProps?: SelectPropsInterface;
  multiple?: boolean;
  showClearButton?: boolean;
}

const emptyArray = [];

function SelectFieldComponent(props: SelectFieldComponentProps) {
  const classes = useStyles(props);
  const {
    placeholder = 'Select an option',
    input,
    meta,
    options = [input.value],
    selectProps,
    multiple,
    disabled,
    showErrors = true,
    showLabel,
    label,
    showClearButton = false
  } = props;

  if (
    !options ||
    !options.filter((o) =>
      !o.id ? o === input.value : o.id === input.value?.id
    ).length
  ) {
    console.warn(`Select value ${input.value} not in options`);
  }

  return (
    <>
      <SelectComponent
        showClearButton={showClearButton}
        showLabel={showLabel}
        labelName={label || input.name}
        value={
          !input.value && multiple
            ? emptyArray
            : options.filter((o) =>
                o.id ? o.id === input.value?.id : o === input.value
              )[0]
        }
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        onChange={input.onChange}
        placeholder={placeholder}
        selectProps={selectProps}
        multiple={multiple}
        disabled={disabled}
        data-cy={props['data-cy']}
        {...props}
      >
        {options.map((option, index) => (
          <MenuItem
            classes={{ root: classes.menuItem }}
            value={option}
            key={option.id || option + index}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {option.prefix ? (
                <span className={classes.prefix}>{option.prefix}</span>
              ) : null}
              <Typography typographyStyle={'body2'} color="contrast2">
                {option.display_name || option}
              </Typography>
              {option.suffix ? (
                <span className={classes.suffix}>{option.suffix}</span>
              ) : null}
            </div>
          </MenuItem>
        ))}
      </SelectComponent>
      {(showErrors === true || (showErrors === 'touched' && meta.touched)) &&
        meta.error && <Typography color="danger1">{meta.error}</Typography>}
    </>
  );
}

export default SelectFieldComponent;
