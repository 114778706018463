import React, { useCallback, useEffect } from 'react';
import Button from '@cube3/ui/src/Buttons/Button';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { Typography } from '@cube3/ui/src/typography/Typography';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';

interface ModalProps {
  modalContext: {
    isCurrentAccount: boolean;
    accountId: string;
  };
}

const RemoveWorkspaceMemberPrompt: React.FC<ModalProps> = props => {
  const { isCurrentAccount, accountId } = props.modalContext;
  const { previousModal } = useModalActions();
  const [deleteMember, deleteMemberStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      (r, wsId) => [
        {
          type: 'workspace',
          id: wsId,
          relationship: 'accounts'
        }
      ],
      []
    )
  });

  const handleDeleteWorkspaceMember = useCallback(() => {
    deleteMember({ type: 'account', id: accountId });
  }, [accountId, deleteMember]);

  const loading =
    deleteMemberStatus &&
    deleteMemberStatus !== statuses.FAILED &&
    deleteMemberStatus !== statuses.SUCCESS;

  useEffect(() => {
    if (deleteMemberStatus === statuses.SUCCESS) {
      previousModal();
    }
  }, [previousModal, deleteMemberStatus, loading]);

  const removeMemberText = isCurrentAccount
    ? 'Leave workspace'
    : 'Remove from workspace';

  return (
    <ModalNoticeUI
      title={removeMemberText}
      loading={loading}
      onCloseEvent={() => previousModal()}
      footerRightComponent={
        <>
          <Button
            text={'Cancel'}
            colorVariant={'ghost2'}
            onClick={() => previousModal()}
          />
          <Button
            text={'Remove'}
            colorVariant="filledDanger"
            onClick={handleDeleteWorkspaceMember}
          />
        </>
      }
    >
      <Typography>
        {isCurrentAccount
          ? 'Are you sure you want to leave workspace? You will no longer have access to this workspace.'
          : 'Are you sure you want to remove this member? They will no longer have access to this workspace.'}
      </Typography>
    </ModalNoticeUI>
  );
};

export default RemoveWorkspaceMemberPrompt;
